import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { cloneDeep, uniq } from 'lodash'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterCurrency, useFilterIgnoreNoAvailableSize, useFilterNoHarmonizedSize, useFilterNoHistoricalSize, useFilterNoSize, useFilterNoUnavailableSize, useFilterQuery, useFilterSortBy, useFilterTax, useFilterZalandodeepShopEmptyString } from 'hooks/useFilter'
import { dailyProductsInOutArg, fetchInOutProductsArg } from 'graphql/nornaapi'
import { SizedBox } from 'componentsv2/SizedBox'
import { Popover } from 'componentsv2/Popover'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { Spin } from 'componentsv2/Spin'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { MonthPicker } from 'componentsv2/form-elements/MonthPicker'
import { useCompetitorOptions } from 'hooks/useOptions'
import { useLoadingBar, useManualLoading } from 'hooks'
import { useFilterGender } from 'hooks/useFilter'
import { isNotEqual, isSuperArray, sortVendorList } from 'utils/array'
import { useFetch } from 'libs/hookRequest'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { downloadFileByAxios } from 'export'
import { UPDATE_NEWCOMPETITORVALUE, UPDATE_OLDCOMPETITORVALUE, isSubsetPayload } from 'features/filters/moduleDataSlice'
import { isCompetitorGroupChanged } from 'utils/group'
import { optionsInOutByCalendarExportFilename } from 'utils/exportUtils'
import { OPTIONS_IN_OUT_CALENDAR_NAME } from 'configs'
import { getCategoriesPayload, getCompetitorPayload, getDashboardPropertiesPayload, getGroupsPayload, getMarketPayload, getPriceFilterPayload, getSortByPayload, getTargetGroupPayload, getTaxPayload } from 'utils/queryPayload'
import { formatDateStr2 } from 'utils/dateUtils'
import { useModuleData } from 'hooks/useModuleData'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { useLookbook } from 'hooks/useLookbook'
import { storage } from 'utils/storage'
import { ALL_VENDORS, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { groupsConfig } from 'configs/groups'
import PerVendorProducts from './components/PerVendorProducts'
import { DailyProductProps, PerVendorProps, OptionsInAndOutByCalendarProps } from './types'
import styles from './styles.module.scss'
import { DailyProductInOutProducts } from './DailyProductInOutProducts'
import GraphButton from 'componentsv2/GraphButton'
import { Flex1 } from 'druikit'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_OPTIONS_IN_OUT_BY_MONTH } from 'features/filters/moduleDataSlice.const'
import { geneCsvData } from './utils'

const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
const monthArr = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]

/**
 * 旧版本这个模块叫做 Daily options In Out
 * 后来这个模块名称改为 Options in and out by calendar
 */
export const OptionsInAndOutByCalendar: FC<OptionsInAndOutByCalendarProps> = ({
    style = {},
}) => {
    useLoadingBar()
    const { manualLoading, showManualLoading } = useManualLoading()
    const [ filterGender ] = useFilterGender()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ filterTax ] = useFilterTax()
    const [ filterSortBy ] = useFilterSortBy()
    const [ filterNoSize ] = useFilterNoSize()
    const [ filterNoHistoricalSize ] = useFilterNoHistoricalSize()
    const [ filterNoUnavailableSize ] = useFilterNoUnavailableSize()
    const [ filterNoHarmonizedSize ] = useFilterNoHarmonizedSize()
    const [ filterZalandodeepShopEmptyString ] = useFilterZalandodeepShopEmptyString()
    const [ filterIgnoreNoAvailableSize ] = useFilterIgnoreNoAvailableSize()
    const { checked: lookbooks } = useLookbook()

    // query param: other params in filter
    const { comparisonQuery } = useFilterQuery({})

    const [ moduleData, setModuleData ] = useModuleData(OPTIONS_IN_OUT_CALENDAR_NAME) 
    const {
        fetchPayload = {},
        apiData = [],
        oldCompetitorValue = [],
        newCompetitorValue = [],
        year,
        month,
    } = moduleData

    /* ***************************** Form ****************************** */
    const { competitorOptions } = useCompetitorOptions()

    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: { competitorValue: filterCompetitor.map(item => item.vendor) },
        })
    }, [ filterCompetitor, [] ])

    const setYear = (value: number) => {
        setModuleData({ year: value })
    }

    const setMonth = (value: number) => {
        setModuleData({ month: value })
    }

    /* ***************************** Data ****************************** */
    const { postFn, data, loading, setData } = useFetch()
    const [ dataSource, setDataSource ] = useState<PerVendorProps[]>([])
    const [ , setCsvData ] = useCsvDataModuleData()

    const fetchData = () => {
        if (!filterCountry || !filterCurrency || !filterGender.length || !competitorOptions.length) return

        const query = cloneDeep(comparisonQuery)
        const properties = getDashboardPropertiesPayload(comparisonQuery)
        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})
        const competitorSellers = [ ...filterCompetitor ]
        query.competitors = getCompetitorPayload({ competitorSellers, returnType: 'vendor', isDashboard: true })
        query.market = getMarketPayload({ competitorSellers, competitorOptions, returnType: 'vendor' })
        query.groups = getGroupsPayload({ competitorSellers })
        const targetGroups = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })
        const payload = dailyProductsInOutArg(query, year, months[month], getCategoriesPayload(filterCategory), filterCountry, targetGroups, properties, priceFilter, lookbooks, filterNoSize, filterNoHistoricalSize, filterNoUnavailableSize, filterNoHarmonizedSize, filterZalandodeepShopEmptyString, filterIgnoreNoAvailableSize)

        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload, competitorType: 'string', pageType: 'dashboard' })) {
            showManualLoading()
            setData?.(apiData)
            return
        }

        setModuleData({ fetchPayload: cloneDeep(payload) })
        setModuleData({
            type: UPDATE_OLDCOMPETITORVALUE,
            payload: {
                competitorValue: payload.data.competitors,
                market: payload.data.market,
                groups: payload.data.groups,
            },
        })
        postFn(payload.url, payload)
    }

    const handleData = (apiData: any) => {
        if (!Array.isArray(apiData) || !apiData.length) return

        apiData = cloneDeep(apiData)
        if (!storage.getIsLookbook()) {
            apiData = apiData.filter(item => newCompetitorValue.includes(item.vendor))
        }

        const dataSorted = sortVendorList({
            vendorList: apiData,
            vendorField: 'vendor',
        })

        // sort here first line
        const newDataSource: PerVendorProps[] = dataSorted.map(item => {
            const dailyProductsInOut = Object.keys(item.daily_products_in_out).map(date => {
                const queryDateValid = isQueryDateValid({ 
                    vendor: item.vendor,
                    region: filterCountry,
                    competitorValue: newCompetitorValue,
                    date,
                })
                const productsIn = queryDateValid ? item.daily_products_in_out[date].products_in : 0
                const productsOut = queryDateValid ? item.daily_products_in_out[date].products_out : 0
                return {
                    date,
                    day: new Date(date).getDate(),
                    productsIn,
                    productsOut,
                }
            })
            const totalProductsIn = dailyProductsInOut.map(item => item.productsIn).reduce((curr, next) => curr + next, 0)
            const totalProductsOut = dailyProductsInOut.map(item => item.productsOut).reduce((curr, next) => curr + next, 0)
            return {
                vendor: item.vendor,
                totalProductsIn,
                totalProductsOut,
                dailyProductsInOut,
            }
        }) as PerVendorProps[]

        setDataSource(newDataSource)
        setCsvData({
            [CSV_DATA_OPTIONS_IN_OUT_BY_MONTH]: geneCsvData({ dataSource: newDataSource }),
        })
    }

    useDeepCompareEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [comparisonQuery, year, month, filterCategory, filterCurrency, filterCountry, filterGender, competitorOptions, lookbooks])

    useDeepCompareEffect(() => {
        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (isNotEqual(oldCompetitorValue, newCompetitorValue) && isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleData(cloneDeep(apiData))
            return
        }
        
        fetchData()
    }, [ newCompetitorValue ])

    useDeepCompareEffect(() => {
        if (!data) return

        handleData(cloneDeep(data))
        setModuleData({ apiData: cloneDeep(data) })
        // eslint-disable-next-line
    }, [data || []]);

    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        const competitorSellers = [ ...filterCompetitor ]
        payload.competitors = getCompetitorPayload({ competitorSellers, returnType: 'vendor', isDashboard: true })
        payload.market = getMarketPayload({ competitorSellers, competitorOptions, returnType: 'vendor' })
        payload.groups = getGroupsPayload({ competitorSellers })
        await downloadFileByAxios({
            filename: optionsInOutByCalendarExportFilename,
            payload: fetchPayload,
        })
    }

    /* ********************** 产品列表模态框 *************************** */
    const { postFn: fetchProducts, data: productData, loading: clickLoading } = useFetch()
    const [ clickVendor, setClickVendor ] = useState('')
    const [ productModalDate, setProductModalDate ] = useState('')

    const onClickGrid = (perVendorData, date, gridData) => {
        if (gridData.productsIn === 0 && gridData.productsOut === 0) return
        setClickVendor(perVendorData.vendor)
        setProductModalDate(date)

        /**
         * 问题：ref https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/111
         *
         * 解决方案：当点击 Market 日历时，传参的 vendor 字段要传 Competitor 所有选项(不包含Market)，还要包含customer自身，用逗号隔开
         * e.g vendor = "drykorn,dagmar,oscarjacobson,jlindeberg,sezane,vanessabruno,apc"
         */
        let { vendor } = perVendorData
        if (perVendorData.vendor === 'Market') {
            const competitorValue = competitorOptions.filter(item => item.key !== 'Market')
                .map(item => item.key)
            vendor = uniq(competitorValue).join(',')
        }
        const taxRate = getTaxPayload({ filterTax, region: filterCountry })
        const sortBy = getSortByPayload(filterSortBy)
        let groups = getGroupsPayload({ competitorSellers: [ ...filterCompetitor ] })
        const groupList = [
            MARKET_VENDOR.vendor,
            SELECTED_VENDORS,
            ALL_VENDORS,
            ...groupsConfig.map(item => item.name),
        ]
        if (!groupList.includes(vendor)) {
            groups = {}
        }
        const targetGroups = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })
        const properties = getDashboardPropertiesPayload(comparisonQuery)
        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})
        const query = fetchInOutProductsArg(comparisonQuery, vendor, date, getCategoriesPayload(filterCategory), filterCountry, targetGroups, filterCompetitor.map(item => item.vendor), taxRate, groups, sortBy, properties, priceFilter, lookbooks, filterNoSize)
        fetchProducts(query.url, query)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    /* ********************************** product detail modal **************************************** */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    useEffect(() => {
        if (!productData?.product_in) return
        const data = productData || {}
        const productInUrls = data.product_in?.map(item => item.nornaid)
        const productOutUrls = data.product_out?.map(item => item.nornaid)
        const dialogId = `Options in & out by calendar-${clickVendor}-${productModalDate}`
        const closeFn = dialogRef.current?.closeDialog
        
        dialogRef.current?.openDialog(dialogId,
            <DailyProductInOutProducts
                productOutUrls={productOutUrls}
                currency={filterCurrency}
                productModalDate={formatDateStr2(productModalDate, true)}
                clickVendor={clickVendor}
                closeFn={() => closeFn(dialogId)}
                productInUrls={productInUrls}
            />,

        )
        // eslint-disable-next-line
    }, [productData])

    /* ********************** 产品概览弹出框 *************************** */
    const [ overviewVisible, setOverviewVisible ] = useState(false)
    const [ overviewTarget, setOverviewTarget ] = useState()
    const [ overviewInfo, setOverviewInfo ] = useState<DailyProductProps>()

    const onEnterGrid = useCallback((e, info) => {
        if (info.productsIn === 0 && info.productsOut === 0) {
            return
        }
        setOverviewTarget(e.target)
        setOverviewInfo(info)
        setOverviewVisible(true)
    }, [])

    const onLeaveGrid = useCallback(() => {
        setOverviewVisible(false)
    }, [])

    const overviewPopoverEle =
        overviewVisible && (
            <Popover target={overviewTarget}>
                <div className={styles.popup}>
                    <div className={styles.time}>
                        {months[month]} {overviewInfo?.day} - {year}
                    </div>
                    <SizedBox height={20} />
                    <div className={styles.title}>
                        Options
                    </div>
                    <div className={styles.content}>
                        <div>Options in</div>
                        <div style={{ color: '#74B6B6' }}>{overviewInfo?.productsIn}</div>
                    </div>
                    <div className={styles.content}>
                        <div>Options out</div>
                        <div style={{ color: '#EC7765' }}>{overviewInfo?.productsOut}</div>
                    </div>
                </div>
            </Popover>
        )

    return (
        <div id="export-options-in-and-out-by-calendar-wrapper" style={{ minWidth: 1300, ...style }}>
            <ModuleTitle
                category="Dashboard"
                title={OPTIONS_IN_OUT_CALENDAR_NAME}
                date={`${monthArr[month]}. ${year}`}
            />
            <SizedBox height={20} />
            <Spin spinning={loading || manualLoading}>
                <div className={styles.search}>
                    <MonthPicker
                        label="Month"
                        placeholder="Month"
                        value={{ year, month }}
                        onChange={({ year, month }) => {
                            setMonth(month)
                            setYear(year)
                        }}
                        right={10}
                    />
                    <GraphButton />
                    <Flex1 />
                    <ExportDropdown
                        onExportExcel={onExportExcel}
                        selector="#export-options-in-and-out-by-calendar-wrapper"
                        fileName={optionsInOutByCalendarExportFilename}
                    />
                </div>
                <SizedBox height={20} />
                <div className={styles.vendorWrap}>
                    {
                        dataSource.map(item => (
                            <PerVendorProducts
                                key={item.vendor.toString()}
                                perVendorData={item}
                                year={year}
                                month={month}
                                onEnterGrid={onEnterGrid}
                                onLeaveGrid={onLeaveGrid}
                                onClickGrid={onClickGrid}
                                clickVendor={clickVendor}
                                clickDate={productModalDate}
                                clickLoading={clickLoading}
                                loading={false}
                                style={{ width: '25%' }}
                            />
                        ))
                    }
                </div>
            </Spin>
            {overviewPopoverEle}
            <Dialog ref={dialogRef} />
        </div>
    )
}
