import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Flex } from 'druikit'
import { useSoldOutVendorsModuleData } from 'features/filters/moduleDataSlice.hook'
import { ComparisonTooltipV2 } from 'componentsv2/business/ComparisonTooltip'
import Tooltip from './Tooltip'
import * as config from './config'
import styles from './Thead.module.scss'
import { getTableTitleTooltip } from 'utils'

const PrimaryText = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <span style={{ color: '#01A699' }}>{children}</span>
    )
}

const Thead = ({
    width,
}: {
    width?: number;
}) => {
    const [ moduleData ] = useSoldOutVendorsModuleData()
    const { isCompetitorView } = moduleData

    return (
        <div className={classNames([ 'sold-out-dashboard-table-header', styles.wrapper ])}>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.title}>
                        <td width={config.tabWidth} align="center">
                        </td>
                        <td 
                            width={config.optionsWidth} 
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth }}
                        >
                        </td>
                        <td
                            width={config.categoryWidth} 
                            className={classNames([ styles.fixedColumn ])} 
                            style={{ left: config.tabWidth + config.optionsWidth }}
                        >
                        </td>
                        <td width={config.sizeWidth} align="center">
                        </td>
                        <td width={config.sizeWidth * 6} align="left" className={styles.tableTitle}>
                            All sizes converted to alpha size chart
                        </td>
                        <td className="tableArrowTd bgWhite" width={config.arrowWidth}></td>
                    </tr>
                </tbody>
            </table>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.title}>
                        <td width={config.tabWidth} align="center">
                            {isCompetitorView ? 'Vendors' : 'Categories'}
                        </td>
                        <td 
                            width={config.optionsWidth} 
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth }}
                        >
                            <Tooltip title={getTableTitleTooltip('# Options')}>
                                # Options
                            </Tooltip>
                        </td>
                        <td
                            width={config.categoryWidth} 
                            className={classNames([ styles.fixedColumn ])} 
                            style={{ left: config.tabWidth + config.optionsWidth }}
                        >
                            {isCompetitorView ? 'Category' : 'Vendor'}
                        </td>
                        <td width={config.sizeWidth} align="center" style={{ paddingLeft: 0 }}>
                            <Tooltip 
                                title={(
                                    <div>
                                        The sold out metric measures the sold-out situation based on the availability of the existing base sizes and sizing systems that vendors use for their various products. The metric shows the share of the assortment that was out of stock during the selected time period, without any special blending and without any harmonization of size systems.  
                                        <br />
                                        <br />
                                        The sold out situation for an individual product is updated on average once per week with different products being updated on different dates. As such the aggregated sold-out situation is updated daily, with a 0-7 days time lag for individual products.
                                    </div>
                                )}
                                placement="bottom"
                                overlayInnerStyle={{ minWidth: 410 }}
                            >
                                Total sold out
                            </Tooltip>
                        </td>
                        <td width={config.sizeWidth} align="center" style={{ paddingLeft: 0 }}>
                            XS and smaller
                        </td>
                        <td width={config.sizeWidth} align="center" style={{ paddingLeft: 0 }}>
                            S
                        </td>
                        <td width={config.sizeWidth} align="center" style={{ paddingLeft: 0 }}>
                            M
                        </td>
                        <td width={config.sizeWidth} align="center" style={{ paddingLeft: 0 }}>
                            L
                        </td>
                        <td width={config.sizeWidth} align="center" style={{ paddingLeft: 0 }}>
                            XL
                        </td>
                        <td width={config.sizeWidth} align="center" style={{ paddingLeft: 0 }}>
                            XXL and larger
                        </td>
                        <td className="tableArrowTd bgWhite" width={config.arrowWidth}></td>
                    </tr>
                </tbody>
            </table>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.subtitle}>
                        <td width={config.tabWidth}></td>
                        <td 
                            width={config.optionsWidth}
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth }}
                        >
                            <Flex justifyContent="space-between" paddingRight={5}>
                                <span>#</span> 
                                <span>+/- <PrimaryText>%</PrimaryText></span>
                            </Flex>
                        </td>
                        <td 
                            width={config.categoryWidth}
                            className={classNames([ styles.fixedColumn ])} 
                            style={{ left: config.tabWidth + config.optionsWidth }}
                        />
                        
                        <td width={config.sizeValueWidth} align="center" style={{ paddingLeft: 0 }}>
                            <PrimaryText>
                                %
                            </PrimaryText>
                        </td>
                        <td width={config.sizeChangeWidth} align="center" style={{ paddingLeft: 0 }}>
                            <ComparisonTooltipV2 width={config.sizeChangeWidth}>
                                <div>
                                    +/- <PrimaryText>%</PrimaryText>
                                </div>
                            </ComparisonTooltipV2>
                        </td>
                        <td width={config.sizeValueWidth} align="center" style={{ paddingLeft: 0 }}>
                            <PrimaryText>
                                %
                            </PrimaryText>
                        </td>
                        <td width={config.sizeChangeWidth} align="center" style={{ paddingLeft: 0 }}>
                            <ComparisonTooltipV2 width={config.sizeChangeWidth}>
                                <div>
                                    +/- <PrimaryText>%</PrimaryText>
                                </div>
                            </ComparisonTooltipV2>
                        </td>
                        <td width={config.sizeValueWidth} align="center" style={{ paddingLeft: 0 }}>
                            <PrimaryText>
                                %
                            </PrimaryText>
                        </td>
                        <td width={config.sizeChangeWidth} align="center" style={{ paddingLeft: 0 }}>
                            <ComparisonTooltipV2 width={config.sizeChangeWidth}>
                                <div>
                                    +/- <PrimaryText>%</PrimaryText>
                                </div>
                            </ComparisonTooltipV2>
                        </td>
                        <td width={config.sizeValueWidth} align="center" style={{ paddingLeft: 0 }}>
                            <PrimaryText>
                                %
                            </PrimaryText>
                        </td>
                        <td width={config.sizeChangeWidth} align="center" style={{ paddingLeft: 0 }}>
                            <ComparisonTooltipV2 width={config.sizeChangeWidth}>
                                <div>
                                    +/- <PrimaryText>%</PrimaryText>
                                </div>
                            </ComparisonTooltipV2>
                        </td>
                        <td width={config.sizeValueWidth} align="center" style={{ paddingLeft: 0 }}>
                            <PrimaryText>
                                %
                            </PrimaryText>
                        </td>
                        <td width={config.sizeChangeWidth} align="center" style={{ paddingLeft: 0 }}>
                            <ComparisonTooltipV2 width={config.sizeChangeWidth}>
                                <div>
                                    +/- <PrimaryText>%</PrimaryText>
                                </div>
                            </ComparisonTooltipV2>
                        </td>
                        <td width={config.sizeValueWidth} align="center" style={{ paddingLeft: 0 }}>
                            <PrimaryText>
                                %
                            </PrimaryText>
                        </td>
                        <td width={config.sizeChangeWidth} align="center" style={{ paddingLeft: 0 }}>
                            <ComparisonTooltipV2 width={config.sizeChangeWidth}>
                                <div>
                                    +/- <PrimaryText>%</PrimaryText>
                                </div>
                            </ComparisonTooltipV2>
                        </td>
                        <td width={config.sizeValueWidth} align="center" style={{ paddingLeft: 0 }}>
                            <PrimaryText>
                                %
                            </PrimaryText>
                        </td>
                        <td width={config.sizeChangeWidth} align="center" style={{ paddingLeft: 0 }}>
                            <ComparisonTooltipV2 width={config.sizeChangeWidth}>
                                <div>
                                    +/- <PrimaryText>%</PrimaryText>
                                </div>
                            </ComparisonTooltipV2>
                        </td>
                        <td className="tableArrowTd bgWhite" width={config.arrowWidth}></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Thead
