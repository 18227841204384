import { PRICE_FILTER } from 'consts'
import { RoutesObj } from 'global/Routes'
import { useSelector, useDispatch } from 'react-redux'
import { isLookbookFn } from 'utils'
import { defaultPriceRange, filterDate, filterIgnoreNoAvailableSize, filterNoHarmonizedSize, filterNoHistoricalSize, filterNoSize, filterNoUnavailableSize, filterProperties, filterVendor, filterZalandodeepShopEmptyString, selecGender, selecSortBy, selectCategory, selectComparisonQuery, selectDate, selectFilterCountry, selectFilterCurrency, selectFilterIgnoreNoAvailableSize, selectFilterMaterial, selectFilterNoHarmonizedSize, selectFilterNoHistoricalSize, selectFilterNoSize, selectFilterNoUnavailableSize, selectFilterPriceFilter, selectFilterSize, selectFilterTax, selectFilterZalandodeepShopEmptyString, selectLaunchBeforeDate, selectProperty, selectVendor, updateCategories, updateFilterCountry, updateFilterCurrency, updateFilterGender, updateFilterPricePerspective, updatePriceRangeFilter, updateSizeFilter, updateTaxFilter } from 'features/filters/filtersSlice'
import { CompetitorReturnType, CompetitorSellerType, FilterMaterialType, FilterSizeType, FilterSortByType, TaxFilterType } from 'types'

export function useFilterQuery(props) {
  const queryCompariso: any = useSelector(selectComparisonQuery)
  const queryComparisoQuery = JSON.parse(JSON.stringify(queryCompariso))
  const { comparisonQuery } = queryCompariso
  return {
    queryCompariso,
    queryComparisoQuery,
    comparisonQuery,
  }
}

/**
 *  ## 判断是否禁用`Filter`
 * @returns 
 */
export function useFilter() {
  const excludePath = [
    RoutesObj.QA.URL,
    RoutesObj.DASHBOARD.URL,
    RoutesObj.DYNAMIC_DASHBOARD.URL,
    RoutesObj.DYNAMIC_DASHBOARD_REPORT.URL,
    RoutesObj.LINE_GRAPH_REPORT.URL,
    '/dashboard',
  ]
  let isLookbook = isLookbookFn()
  if (!isLookbook) {
    isLookbook = !!excludePath.filter(item => window.location.pathname.startsWith(item)).length
  }

  return {
    disabled:isLookbook,
  }
}

/**
 * Filter > Category
 * 
 * return all leaf categories
 */
export const useFilterCategory = (): [string[], (value: string[]) => void] => {
  const dispatch = useDispatch()
  const filterCategory = useSelector(selectCategory) 

  const setFilterCategory = (value: string[]) => {
    dispatch(updateCategories([ ...value ]))
  }

  return [
    filterCategory,
    setFilterCategory,
  ]
}

/**
 * Filter > Property
 */
export const useFilterProperty = () => {
  const dispatch = useDispatch()
  const filterProperty = useSelector(selectProperty) 

  const setFilterProperty = (value: Record<string, string[]>) => {
    dispatch(filterProperties(value))
  }

  return [
    filterProperty,
    setFilterProperty,
  ]
}

/**
 * Filter > Gender
 */
export const useFilterGender = (): [string[], (value: string[]) => void] => {
  const dispatch = useDispatch()
  const filterGender = useSelector(selecGender)

  const setFilterGender = (value: string[]) => {
    dispatch(updateFilterGender([ ...value ]))
  }

  return [
    filterGender,
    setFilterGender,
  ]
}

/**
 * Filter > Country
 */
export const useFilterCountry = (): [string, (value: string) => void] => {
  const dispatch = useDispatch()
  const filterCountry = useSelector(selectFilterCountry)

  const setFilterCountry = (value: string) => {
    dispatch(updateFilterCountry(value))
  }

  return [
    filterCountry,
    setFilterCountry,
  ]
}

/**
 * Filter > Currency
 */
export const useFilterCurrency = (): [string, (value: string) => void] => {
  const dispatch = useDispatch()
  const filterCurrency = useSelector(selectFilterCurrency)

  const setFilterCurrency = (value: string) => {
    dispatch(updateFilterCurrency(value))
    dispatch(updatePriceRangeFilter({ ...defaultPriceRange }))
  }

  return [
    filterCurrency,
    setFilterCurrency,
  ]
}

/**
 * Filter > Competitor 
 */
export const useFilterCompetitor = (opts?: { returnType?: CompetitorReturnType }): [CompetitorSellerType[], (value: CompetitorSellerType[]) => void] => {
  const dispatch = useDispatch()
  const filterCompetitor = useSelector(selectVendor) || []

  const setFilterCompetitor = (value: CompetitorSellerType[] = []) => {
    dispatch(filterVendor([ ...value ]))
  }

  return [
    filterCompetitor,
    setFilterCompetitor,
  ]
}

/**
 * Filter > Sort by
 */
export const useFilterSortBy = () : [FilterSortByType, (value: FilterSortByType) => void] => {
  const dispatch = useDispatch()
  const filterSortBy = useSelector(selecSortBy)

  const setFilterSortBy = (value: FilterSortByType) => {
    dispatch(filterSortBy(value))
  }

  return [
    filterSortBy,
    setFilterSortBy,
  ]
}

/**
 * Filter > Price perspective
 */
export const useFilterPricePerspective = (): [string, (value: string) => void] => {
  const dispatch = useDispatch()
  const filterPricePerspective = useSelector(selectFilterPriceFilter)

  const setFilterPricePerspective = (value: string) => {
    dispatch(updateFilterPricePerspective({ val: value, label: PRICE_FILTER[value] }))
  }

  return [
    filterPricePerspective,
    setFilterPricePerspective,
  ]
}

/**
 * Filter > Add sales tax
 */
export const useFilterTax = (): [TaxFilterType, (value: TaxFilterType) => void] => {
  const dispatch = useDispatch()
  const filterTax = useSelector(selectFilterTax)

  const setFilterTax = (value: TaxFilterType) => {
    dispatch(updateTaxFilter({ ...value }))
  }

  return [
    filterTax,
    setFilterTax,
  ]
}

/**
 * Filter > Launched after
 */
export const useFilterLaunchedAfter = () => {
  const dispatch = useDispatch()
  const filterLaunchedAfter = useSelector(selectDate)

  const setFilterLaunchedAfter = (value: string) => {
    dispatch(filterDate(value))
  }

  return [
    filterLaunchedAfter,
    setFilterLaunchedAfter,
  ]
}

export const useFilterNoSize = () => {
  const dispatch = useDispatch()
  const noSize = useSelector(selectFilterNoSize)

  const setFilterNoSize = (value: boolean) => {
    dispatch(filterNoSize(value))
  }

  return [
    noSize,
    setFilterNoSize,
  ]
}

export const useFilterNoHistoricalSize = () => {
  const dispatch = useDispatch()
  const noHistoricalSize = useSelector(selectFilterNoHistoricalSize)

  const setFilterNoHistoricalSize = (value: boolean) => {
    dispatch(filterNoHistoricalSize(value))
  }

  return [
    noHistoricalSize,
    setFilterNoHistoricalSize,
  ]
}

export const useFilterNoUnavailableSize = () => {
  const dispatch = useDispatch()
  const noUnavailableSize = useSelector(selectFilterNoUnavailableSize)

  const setFilterNoUnavailableSize = (value: boolean) => {
    dispatch(filterNoUnavailableSize(value))
  }

  return [
    noUnavailableSize,
    setFilterNoUnavailableSize,
  ]
}

export const useFilterNoHarmonizedSize = () => {
  const dispatch = useDispatch()
  const noHarmonizedSize = useSelector(selectFilterNoHarmonizedSize)

  const setFilterNoHarmonizedSize = (value: boolean) => {
    dispatch(filterNoHarmonizedSize(value))
  }

  return [
    noHarmonizedSize,
    setFilterNoHarmonizedSize,
  ]
}

export const useFilterZalandodeepShopEmptyString = () => {
  const dispatch = useDispatch()
  const zalandodeepShopEmptyString = useSelector(selectFilterZalandodeepShopEmptyString)

  const setFilterZalandodeepShopEmptyString = (value: boolean) => {
    dispatch(filterZalandodeepShopEmptyString(value))
  }

  return [
    zalandodeepShopEmptyString,
    setFilterZalandodeepShopEmptyString,
  ]
}

export const useFilterIgnoreNoAvailableSize = () => {
  const dispatch = useDispatch()
  const ignoreNoAvailableSize = useSelector(selectFilterIgnoreNoAvailableSize)

  const setFilterIgnoreNoAvailableSize = (value: boolean) => {
    dispatch(filterIgnoreNoAvailableSize(value))
  }

  return [
    ignoreNoAvailableSize,
    setFilterIgnoreNoAvailableSize,
  ]
}

export const useFilterLaunchBeforeDate = () => {
  const dispatch = useDispatch()
  const filterLaunchBeforeDate = useSelector(selectLaunchBeforeDate)

  const setFilterLaunchBeforeDate = (value: string) => {
    dispatch(filterLaunchBeforeDate(value))
  }

  return [
    filterLaunchBeforeDate,
    setFilterLaunchBeforeDate,
  ]
}

/**
 * Filter > Material filter
 */
export const useFilterMaterial = (): [FilterMaterialType, (value: FilterMaterialType) => void] => {
  const dispatch = useDispatch()
  const filterMaterial = useSelector(selectFilterMaterial)

  const setFilterMaterial = (value: FilterMaterialType) => {
    dispatch(filterMaterial(value))
  }

  return [
    filterMaterial,
    setFilterMaterial,
  ]
}

export const useFilterSize = () => {
  const dispatch = useDispatch()
  const filterSize = useSelector(selectFilterSize)

  const setFilterSize = (value: FilterSizeType) => {
    dispatch(updateSizeFilter(value))
  }

  return [
    filterSize,
    setFilterSize,
  ]
}
