import { Point } from './Point'

export class Circle extends Point {

    radius: number
  
    color: string
  
    isSelected: boolean
  
    constructor(x: number, y: number) {
      super(x, y)
      this.radius = 5
      this.color = 'transparent'
      this.isSelected = false
    }
  }
