import React, { FC, memo } from 'react'
import classnames from 'classnames'
import { ArrowIcon } from 'assets/icons/Arrow'
import { Text } from 'components/typography/Text'
import { selectMarket } from 'features/filters/filtersSlice'
import { useSelector } from 'react-redux'
import { ProductPriceProps } from './types'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'

/**
 * ## ProductPrice
 * 
 * 产品卡片中经常会显示价格相关字段，价格显示有两种视图
 * 
 * - 视图1：leftPrice(1200) === rightPrice(1200)，视图如下：
 *      1,200 SEK    （其中 SEK 黑色字体展示）
 * - 视图2：leftPrice(1200) !== rightPrice(800)，视图如下：
 *      1,200 -> 800 SEK  （其中 SEK 要蓝色字体显示）
 * 
 * ## 为什么参数名叫 leftPrice 和 rightPrice?
 * 
 * 目前系统中 price 相关字段有很多：price、discountPrice、originPrice、actualPrice、convertedPrice、convertedDiscountedPrice
 * 
 * 由于历史原因，不同接口返回的价格字段可能不相同
 * 
 * 我们希望使用本组件时抛开众多价格字段带来的干扰，简化做 leftPrice 和 rightPrice
 * 
 * ## 使用示例
 * 
 * ### 示例1 leftPrice > rightPrice
 * 
 * ```
 * // 假设接口返回数据如下
 * const data = { originPrice: 1200, actualPrice: 800 }
 * 
 * <ProductPrice 
 *      leftPrice={data.originPrice}
 *      rightPrice={data.actualPrice}
 * />
 * 
 * // 渲染出视图如下：
 * //     1,200 -> 800 SEK
 * ```
 * 
 * ### 示例2 leftPrice === rightPrice
 * 
 * ```
 * // 假设接口返回数据如下
 * const data = { originPrice: 1200, actualPrice: 1200 }
 *
 * <ProductPrice
 *      leftPrice={data.originPrice}
 *      rightPrice={data.actualPrice}
 * />
 *
 * // 渲染出视图如下：
 * //     1,200 SEK
 * ```
 * 
 * ### 示例3 leftPrice < rightPrice
 * 
 * ```
 * // 假设接口返回数据如下
 * const data = { originPrice: 800, actualPrice: 1200 }
 *
 * <ProductPrice
 *      leftPrice={data.originPrice}
 *      rightPrice={data.actualPrice}
 * />
 *
 * // 渲染出视图如下：
 * //     800 -> 1,200 SEK
 * ```
 */
export const ProductPrice: FC<ProductPriceProps> = memo(({
    style = {},         // 非必传项，样式对象
    className,          // 非必传项，类名
    leftPrice,          // 必传项，leftPrice 值应当大于等于 rightPrice
    rightPrice,         // 必传项
    currency,           // 非必传项，默认取 Filter 中勾选 Country 对应的币种
}) => {
    // Filter 中选中 Country 值
    const filterRegions = useSelector(selectMarket)
    // Filter 中选中 Country 对应的 currency 币种值
    const filterCurrency = filterRegions?.currency

    const leftPriceText = numberUtils.formatNumberByComma(leftPrice.toFixed(2))
    const rightPriceText = numberUtils.formatNumberByComma(rightPrice.toFixed(2))

    if (leftPrice === rightPrice) {
        return (
            <div className={classnames(styles.productPrice, className)} style={style}>
                <Text xs>
                    {leftPriceText}
                </Text>
                <Text xs uppercase className={styles.currency}>
                    {currency || filterCurrency}
                </Text>
            </div>
        )
    }
    
    return (
        <div className={classnames(styles.productPrice, className)} style={style}>
            <Text xs>
                {leftPriceText}
            </Text>
            <div className={styles.arrowIcon}>
                <ArrowIcon right />
            </div>
            <Text xs accent>
                {rightPriceText}
            </Text>
            <Text xs accent uppercase className={styles.currency}>
                {currency || filterCurrency}
            </Text>
        </div>
    )
})

ProductPrice.displayName = 'ProductPrice'
