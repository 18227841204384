import { useFetch } from 'libs/hookRequest'

type AddExcludedProductsType = {
  addExcludedProducts: Function;
  data: any;
  loading: boolean;
  error: string | undefined;
};

export const useAddExcludedProducts = (): AddExcludedProductsType => {
  const { postFn: addExcludedProducts, data, loading, error } = useFetch()

  return {
    addExcludedProducts: (id: string, excludedProducts: string[]) =>
      addExcludedProducts('url',{ data: { id, excludedProducts } }),
    data,
    loading,
    error,
  }
}
