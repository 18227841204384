import React, { CSSProperties, useState } from 'react'
import classnames from 'classnames'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ClosePopup } from 'components/close-popup'
import styles from './styles.module.scss'
import { ModalProps } from './types'
import { CloseIcon } from '../../assets/icons/Close'
import { QA_BUTTON_CORE_RUN_VALUE, QA_BUTTON_IN_OUT_FIX_VALUE, QA_BUTTON_PRICE_BACKPOLATION_VALUE, QA_BUTTON_QA_SIZE_VALUE, QA_BUTTON_SPEED_BENCHMARK_VALUE } from 'consts'

export const Modal = ({ children, isOpen }: ModalProps) => {
  const [ showModal, setModal ] = useState(false)

  const closeModal = () => {
    setModal(!showModal)
  }

  return (
    <ShouldRender shouldRender={isOpen}>
      <>
        <ClosePopup onClick={closeModal} />
        <div className={styles['modal-wrapper']}>
          <div className={styles['modal-content']}>{children}</div>
        </div>
      </>
    </ShouldRender>
  )
}

export const ModalV2 = ({ children, type = 0, title = '', isOpen, closeFn, sty }: ModalProps) => {
  let style: CSSProperties = { ...sty }
  if (
    [ 
      QA_BUTTON_PRICE_BACKPOLATION_VALUE, 
      QA_BUTTON_IN_OUT_FIX_VALUE, 
      QA_BUTTON_QA_SIZE_VALUE, 
      QA_BUTTON_CORE_RUN_VALUE,
      QA_BUTTON_SPEED_BENCHMARK_VALUE, 
    ].includes(title.replace('_dialog', ''))
  ) {
    style.background = 'none'
    style.boxShadow = 'none'
  }

  const closeModal = () => {
    closeFn?.()
  }

  return (
    <ShouldRender shouldRender={isOpen}>
      <div>
        <div
          className={classnames(styles['modal-wrapper'], type ? styles.v3 : styles.v2)}
          style={{
            ...type ? { padding: 0 } : {},
            ...style,
          }}
        >
          {type ? null : (
            <div className="flex flex-row flex-start">
              <div className="flex1">
                {title}
              </div>
              <div className={styles.fill}>
                <CloseIcon
                  onClick={() => {
                    // actionBox.close(1);
                    closeModal()
                  }}
                />
              </div>
            </div>
          )}
          <div className={styles['modal-content']}>{children}</div>
        </div>
      </div>
    </ShouldRender>
  )
}
ModalV2.defaultProps = {
  sty: {},
}
