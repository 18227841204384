import React, { FC, ReactNode, memo, useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react'
import classnames from 'classnames'
import { CaretOutlineIcon } from 'assets/icons/CaretOutline'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Button } from 'componentsv2/Button'
import { Text } from 'components/typography/Text'
import { ProductCard } from 'pages/SearchForOptions/components/ProductCard'
import { IS_LIVE_ENV, OPTIONS_NAME } from 'consts'
import { useQa } from 'hooks/useQa'
import { useWindowWidth } from 'hooks/useWindow'
import { Checkbox } from 'componentsv2/form-elements/Checkbox'
import { ObjectType } from 'types'
import { cloneDeep } from 'lodash'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { ProductClassifyProps, ProductProps } from './types'
import styles from './styles.module.scss'
import { searchFn, sortByVendors } from './utils'
import { getVendorNameByCode } from 'utils'
import { useInView } from 'react-intersection-observer'
import { numberUtils } from 'norna-uikit'

function conversion (baseArray,n){ 
    let len = baseArray.length
    let lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1)
    let res: any[] = []
    for (let i = 0; i < lineNum; i++) {
      // slice() 方法返回一个从开始到结束（不包括结束）选择的数组的一部分浅拷贝到一个新数组对象。且原始数组不会被修改。
      let temp = baseArray.slice(i * n, i * n + n)
      res.push(temp)
    }
     return res
}

/**
 * ## ProductClassify
 * 
 * 产品类别组件。classify 中文：把...分类
 * 
 * - Men/Women/Children 属于 classify，按 gender 分类
 * - Jeans/Shorts 属于 classify，按 category 分类
 * 
 * 页面视图如下：
 * 
 * ```
 * classifyName
 * 
 * options 12     product11  product21  product31  product41 ...
 *                product21  product22  product22  product42 ...
 *                product31  product32  product34  product34 ...
 * show less      product41  product42  product43  product44 ...
 * ```
 */
export const ProductClassify: FC<ProductClassifyProps> = memo(({
    classifyName,           // 分类名称
    enableSortByVendor = false,
    sortRules = [],
    enabledQa = false,
    taggedQaObj = {},
    products = [],          // 产品列表信息
}) => {
    // 用于监控浏览器是否 resize
    const windowWidth = useWindowWidth()
    // 一行展示多少个产品
    const [ columnCount, setColumnCount ] = useState(11)
    // 默认展示两行, 计算两行有多少个产品
    const [ doubleColumnCount, setDoubleColumnCount ] = useState(columnCount * 2)
    // 每个单元格宽度
    const cellWidth = 150
    // 每个单元格高度
    const h = 280
    const { search, batchUpdateCheckedProduct } = useQa()

    /**
     * 产品列表是否展开状态
     * - false 未展开状态。页面中能看到该分类(classify)的产品数量 <= 22条
     * - true 展开状态。页面中能看到该分类(classify)的产品数量 > 22条
     */
    const expanded = true // const [, setExpanded] = useState(true)

    // products list 
    const containerRef = useRef<HTMLDivElement>(null)
    /**
     * body
     * left(11%)    right(89%)
     * containerWidth 是 right 的实际距离, 虚拟列表需要指定具体宽度而不是百分数
     */
    const [ containerWidth, setContainerWidth ] = useState(window.innerWidth - 132)

    /**
     * 2022/07/15 
     * 
     * ## 问题
     * 
     * 由于使用虚拟列表, 比如总共有 13 个 ProductClassify
     * 页面上一次性只会显示前面几个 ProductClassify, 前面几个的宽度都是 rect.width
     * 这导致后面的 ProductClassify 获取到的 rect 都是 undefined, 后面几个的宽度都是默认值 window.innerWidth - 132
     * 
     * ## 解决
     * 
     * 对于 rect = undefined 的情况
     * 通过 dom 获取到第一个 ProductClassify 的宽度 
     */
    useLayoutEffect(() => {
        if (!windowWidth) return
        const rightWidthwidth = (windowWidth - 85 - 16 * 2) * 0.89
        setContainerWidth(rightWidthwidth)
        // 计算一行展示多少个产品
        let count = Math.floor(rightWidthwidth / cellWidth)
        if (count > 11) {
            count = 11
        }
        setColumnCount(count)
        setDoubleColumnCount(count * 2)
        // eslint-disable-next-line
    }, [windowWidth])

    // 全部产品数据，查到的数据放在内存中，并不是全部渲染到页面上
    // 测试发现 10000 条（1万条）产品数据大概占内存 2.3MB
    const [ allProductList, setAllProductList ] = useState<ProductProps[]>([])
    // 最终在页面上展示的产品列表数据
    const [ productList, setProductList ] = useState<ProductProps[]>([])

    useEffect(() => {
        if (!enableSortByVendor) {
            setProductList(cloneDeep(products.slice(0, doubleColumnCount)))
            setAllProductList(products)
        } else {
            const productData = sortByVendors(products, sortRules)
            setProductList(cloneDeep(productData.slice(0, doubleColumnCount)))
            setAllProductList(productData)
        }
    }, [products, enableSortByVendor, doubleColumnCount]) // eslint-disable-line

    /**
     * 点击 show more 或 show less 按钮
     */
    const onClickShowLessOrMoreBtn = () => {
        setProductList(allProductList.slice(0, doubleColumnCount))
    }

    const { checkedProduct } = useQa()

    /**
     * 点击 Load more products 按钮时触发事件
     * @param loadAll  load all products one time
     */
    const onClickLoadMoreBtn = (loadAll = false) => {
        setProductList(loadAll ? allProductList : allProductList.slice(0, productList.length + columnCount * 5))
    }

    const [ productListData, setProductListData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const data = productList.filter(item => {
            if (IS_LIVE_ENV) return true
            return searchFn(search, item.name)
        })
        setProductListData([ ...data ])
    }, [ productList, search ])

    const [ allProductListData, setAllProductListData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const data = allProductList.filter(item => {
            if (IS_LIVE_ENV) return true
            return searchFn(search, item.name)
        })
        setAllProductListData([ ...data ])
    }, [ allProductList, search ])

    // ------------------------------------------------------ select row line ---------------------------------------------------------------------------
    const [ selectLines, setSelectLines ] = useState<ObjectType<boolean>>({})
    const lineSelect = useCallback((rowIndex: number, columnCount: number, currentValue: boolean) => {
        batchUpdateCheckedProduct(new Array(columnCount).fill(1).map((zeroEle, productIndex) => {
            const num = productIndex + rowIndex * columnCount
            const product = productListData[num]
            return product
        }).filter(item => item).map(item => item.nornaid), !currentValue)
        setSelectLines({
            ...selectLines,
            [rowIndex]: !currentValue,
        })
    }, [ batchUpdateCheckedProduct, productListData, setSelectLines, selectLines ])

    // 总共有几行
    const rowNum = Math.ceil(productListData.length / columnCount)

    const calcLineSelectedAllStatus = useCallback(() => {
        const newLinesObj: any = Array.from({ length: rowNum }).reduce((res: any, item, rowIndex) => {
            // whether the current line include unselected checkbox or not
            const includeUnSelect = new Array(columnCount).fill(1).map((zeroEle, productIndex) => {
                const num = productIndex + rowIndex * columnCount
                const product = productListData[num]
                if (!product) return ''
                return checkedProduct?.[product.nornaid] || false
            }).filter(item => item !== '').includes(false)
            res[rowIndex] = !includeUnSelect
            return res
        }, {})
        setSelectLines({
            ...selectLines,
            ...newLinesObj,
        })

    }, [ checkedProduct, productListData, rowNum, selectLines, columnCount ])

    useDeepCompareEffect(() => {
        if (enabledQa) calcLineSelectedAllStatus()
    }, [ checkedProduct, enabledQa ])

    let bodyElement: ReactNode = null
    if (products.length === 0) {
        bodyElement = (
            <div className={styles.noData}>
                No data
            </div>
        )
    } else {
        bodyElement = (
            <div className={styles.body}>
                <div className={classnames(styles.left,  styles.expand )}>
                    <div className={styles.options}>
                        <span>{OPTIONS_NAME}</span>
                        <span className={styles.optionsTotal}>
                            {allProductList.length === 0 ? '' : numberUtils.formatNumberByComma(allProductList.length)}
                        </span>
                    </div>
                    {enabledQa ? Array.from({ length: rowNum }).map((item, rowIndex) => {
                        // whether the current line include unselected checkbox or not
                        const includeUnSelect = new Array(columnCount).fill(1).map((zeroEle, productIndex) => {
                            const num = productIndex + rowIndex * columnCount
                            const product = productListData[num]
                            if (!product) return ''
                            return checkedProduct?.[product.nornaid] || false
                        }).filter(item => item !== '').includes(false)
                        // current line checked or not
                        const currentValue = !includeUnSelect && selectLines[rowIndex]
                        return (
                            <div
                                key={rowIndex}
                                className={styles.selectLine}
                                style={{ top: rowIndex * h + 145, right: 0 }}
                            >
                                <Checkbox
                                    style={{ marginBottom: '20px' }}
                                    value={currentValue}
                                    onChange={() => lineSelect(rowIndex, columnCount, currentValue)}
                                />
                            </div>
                        )
                    }) : null}
                    {allProductList.length > doubleColumnCount && productListData.length > doubleColumnCount ? (
                        <div className={classnames([ styles.lessOrMoreBtn, allProductList.length <= doubleColumnCount && styles.hiddenLessOrMoreBtn ])}>
                            <button onClick={onClickShowLessOrMoreBtn} >
                                <CaretOutlineIcon isExpanded={expanded} />
                                <Text small accent>
                                    Show less
                                </Text>
                            </button>
                        </div>
                    ) : null}
                </div>
                <div className={styles.right}>
                    <div
                        className={classnames([ styles.productList ])}
                        style={{ 
                            height: h * conversion(productListData, columnCount).length, 
                            boxSizing: 'content-box', 
                            paddingTop: 55,
                        }}
                        ref={containerRef}
                    >
                        {
                            conversion(productListData, columnCount).map((rowData, rowIndex) => {
                                return (
                                    <RowData 
                                        key={rowIndex.toString()} 
                                        rowData={rowData}
                                        cellWidth={cellWidth}
                                        enabledQa={enabledQa}
                                        taggedQaObj={taggedQaObj}
                                        h={h}
                                    />
                                )
                            })
                        }
                    </div>
                    <ShouldRender shouldRender={expanded === true && allProductListData.length > productListData.length}>
                        <div className={styles.loadMoreBtn}>
                            <Button type="primary" onClick={() => onClickLoadMoreBtn()}>
                                Load more products
                            </Button>

                            {/* 2022/05/23 LIVE 环境也放开限制, 可以看到 Load all 按钮 */}
                            <Button style={{ marginLeft: 10 }} type="primary" onClick={() => onClickLoadMoreBtn(true)}>
                                Load all
                            </Button>
                        </div>
                    </ShouldRender>
                </div>
            </div>
        )
    }

    // ------------------------------------ select all ------------------------------------
    const [ selectAll, setSelectAll ] = useState(false)
    const selectAllFn = useCallback(() => {
        batchUpdateCheckedProduct(allProductListData.map(item => item.nornaid), !selectAll)
        setSelectAll(!selectAll)
    }, [ batchUpdateCheckedProduct, setSelectAll, selectAll, allProductListData ],
    )

    return (
        <>
            <div className={classnames(styles.classifyName, 'flex flex-align-center')}>
                {classifyName}
                {enabledQa ? (
                    <>
                        &nbsp;({numberUtils.formatNumberByComma(productListData.length || 0)})&nbsp;(
                        <span>
                            <Checkbox value={selectAll} onChange={selectAllFn} >Select all</Checkbox>
                        </span>
                        )
                    </>
                ) : null}

            </div>
            {bodyElement}
        </>
    )
})

function RowData({
    rowData,
    cellWidth,
    enabledQa,
    taggedQaObj,
    h,
}: any) {
    const { ref, inView } = useInView()

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: h,
            }}
            ref={ref}
        >
            {
                inView ? rowData.map(product => {
                    return (
                        <ProductCard
                            key={[ product.seller?.vendor, product.nornaid ].join(',')}
                            image={product.image}
                            style={{
                                height: 260,
                                width: cellWidth,
                                paddingTop: 15,
                                paddingBottom: 15,
                                marginBottom: 0,
                            }}
                            vendor={product.vendor}
                            vendorName={getVendorNameByCode(product?.seller?.vendor)}
                            vendorCode={product?.seller?.vendor}
                            brand={product.brand}
                            name={product.name}
                            lookbooks={product.lookbooks ?? []}
                            currency={product?.currency}
                            fullyConfirmed={product?.fullyConfirmed}
                            leftPrice={product.leftPrice}
                            rightPrice={product.rightPrice || product.leftPrice}
                            nornaid={product.nornaid}
                            enabledQa={enabledQa}
                            taggedQa={taggedQaObj?.[product.nornaid]}
                            sizePriceRange={product?.sizePriceRange}
                            latestSizePrice={product?.latestSizePrice}
                        />
                    )
                }) : null
            }
        </div>
    )
}

ProductClassify.displayName = 'ProductClassify'
