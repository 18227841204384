import React, { useState } from 'react'
import classnames from 'classnames'
import { DropDownInfoBoxProps, InfoBoxProps } from './types'
import styles from './styles.module.scss'
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined'

export const SelectionDetailItem = props => <div className={styles.selectionDetailItem}>
    <label> {props.label} </label>
    <div>{props.desc ?? 'none'}</div>
                                            </div>

const InfoBox = (props: InfoBoxProps) => {
    const [ infoBox, showBox ] = useState(false)
    return (
        <div
            className={styles.widgetInfoWrapper}

            onMouseLeave={infoBox ? () => showBox(!infoBox) : () => null}
        >
            {
                props?.showInfoIcon ? (
                    <span
                        style={{
                            height: '100%',
                            display: 'inline-flex',
                            alignItems: 'center',
                            ...props.iconSty,
                        }}
                        onMouseEnter={!infoBox ? () => showBox(!infoBox) : () => null}
                    >
                        <InfoOutlined
                            size={16}
                            color="#bbb"
                        />
                    </span>
                ) : (
                    <span
                        className={classnames(styles.infoIcon)}
                        style={props.iconSty||{}}
                        onMouseEnter={!infoBox ? () => showBox(!infoBox) : () => null}
                    >
                        i
                    </span>
                )
            }
            {
                infoBox ? (
                    <div
                        className={styles.widgetInfo}
                        style={props.containerSty || {}}
                    >
                        {/* { */}
                        {/*  props.title || <h4>Information</h4> */}
                        {/* } */}
                        <div>
                            {props.children}
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

InfoBox.SelectionDetailItem = SelectionDetailItem

function DropDownInfoBox(props: DropDownInfoBoxProps) {
    return (
        <InfoBox
            title={<div style={{
                lineHeight: '30px',
                background: 'rgb(204 204 204)',
                padding: '3px 10px',
                color: '#fff',
            }}
            >{props.title || 'Title'}
                   </div>}
        >
            <div style={{
                maxWidth: '450px',
                ...props.dropStyle ?? {},
            }}
            >
                {
                    props.list.map((item, index) => <InfoBox.SelectionDetailItem key={item.label} {...item} />)
                }
            </div>
        </InfoBox>
    )
}

export { InfoBox, DropDownInfoBox }

