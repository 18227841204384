import { Flex } from 'antd';
import { ASSORTMENT_MIX, DISCOUNT_EFFECT, FULL_PRICE_PERFORMANCE, PRODUCT_NEWNESS, SALES_PRICE_PERFORMANCE, SOLD_OUT_STATUS } from 'features/filters/dynamicDashboardSlice';
import { Divider, Text, Box } from 'druikit';
import React, { CSSProperties, ReactNode } from 'react'
import { ModalProductNewness } from '../ModalProductNewness';
import { ModalAssortmentMix } from '../ModalAssortmentMix';
import { ModalDiscountEffect } from '../ModalDiscountEffect';
import { ModalSoldOutStatus } from '../ModalSoldOutStatus';
import { ModalSalesPricePerformance } from '../ModalSalesPricePerformance';
import { ModalFullPricePerformance } from '../ModalFullPricePerformance';
import styles from './styles.module.scss'
import { storage } from 'utils/storage';
import { logoMapper } from 'componentsv2/VendorLogo';

const MetricModalContent = ({
    kpi,
    className,
    style,
}: {
    kpi: any;
    className?: string;
    style?: CSSProperties;
}) => {
    const customerVendor = storage.getCustomerVendor()
    let content: ReactNode = null
    let footerText = ''

    if (kpi === PRODUCT_NEWNESS) {
        content = <ModalProductNewness />
        footerText = 'Product newness'
    } else if (kpi === ASSORTMENT_MIX) {
        content = <ModalAssortmentMix />
        footerText = 'Assortment mix'
    } else if (kpi === DISCOUNT_EFFECT) {
        content = <ModalDiscountEffect />
        footerText = 'Discount effect'
    } else if (kpi === SOLD_OUT_STATUS) {
        content = <ModalSoldOutStatus />
        footerText = 'Sold out status'
    } else if (kpi === SALES_PRICE_PERFORMANCE) {
        content = <ModalSalesPricePerformance />
        footerText = 'Sales price performance'
    } else if (kpi === FULL_PRICE_PERFORMANCE) {
        content = <ModalFullPricePerformance />
        footerText = 'Full price performance'
    } else {
        content = null
    }

    return (
        <Flex vertical gap={10} className={className} style={style}>
            {content}
            <Flex align="center">
                <div className={styles.vendorWrapper}>
                    {logoMapper[customerVendor]}
                </div>
                <Divider vertical length={70} gap={20} />
                <Text fontSize={21} fontWeight="lighter" color="#585858">{footerText}</Text>
            </Flex>
            <Box height={40} />
        </Flex>
    )
}

export default MetricModalContent
