import React, { FC, CSSProperties, memo, useRef } from 'react'
import queryString from 'query-string'
import { Button } from 'componentsv2/Button'
import { SnackInfo } from 'componentsv2'
import { OpenIcon } from 'assets/icons/OpenIcon'
import { OPEN_IN_NEW_KEY, OPEN_IN_NEW_VALUE, SHARE_FILTER_KEY } from 'consts'
import { useSelector } from 'react-redux'
import { selectComparisonQuery } from 'features/filters/filtersSlice'
import { argFnSaveFilter } from 'graphql/nornaapi'
import { useFetch } from 'libs/hookRequest'
import { useUrlParams } from 'hooks'
import { ObjectProps } from 'types'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { useLookbook } from 'hooks/useLookbook'
import { OpenInNewButtonProps, HideOrShowWhenOpenInNewProps } from './types'
import { encodeObjectValue } from './utils'

/**
 * OpenInNewButton
 * 
 * 每个分析页都有一个 Open in new 按钮
 * 点击之后会打开一个新的浏览器窗口，不是新 tab
 * 新窗口会显示之前分析页的内容，只能查看内容，不能修改查询条件
 */
export const OpenInNewButton: FC<OpenInNewButtonProps> = memo(({
    pageParams = {},
    onPageParams,
    left,
    right,
    style = {},
    className,
}) => {
    const { isLookbook } = useLookbook()
    const defaultStyle: CSSProperties = { width: '150px' }

    const { pathObj, ...params } = useUrlParams()

    const snackRef = useRef<ObjectProps>({})
    const filter = useSelector(selectComparisonQuery)
    const { postFn: updateData } = useFetch()

    const onOpen = async () => {
        const query = argFnSaveFilter(JSON.stringify(filter))
        const updateDataRes = await updateData(query.url, query)
        if (!updateDataRes.error) {
            const strUrl = pathObj.linkUrlParam({
                ...params,
                ...encodeObjectValue(pageParams),
                ...encodeObjectValue(onPageParams?.()) || {},
                [SHARE_FILTER_KEY]: updateDataRes.data,
                [OPEN_IN_NEW_KEY]: OPEN_IN_NEW_VALUE,
            })
            window.open(strUrl, '', 'location=no')
        } else {
            snackRef.current?.open?.('Open failed')
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <Button
                left={left}
                right={right}
                icon={<OpenIcon />}
                type="primary"
                className={className}
                style={{ ...defaultStyle, ...style }}
                onClick={onOpen}
                disabled={isLookbook}
            >
                Open in new
            </Button>
            <SnackInfo ref={snackRef} />
        </div>
    )
})

OpenInNewButton.displayName = 'OpenInNewButton'

/**
 * HideWhenOpenInNew
 * 
 * 对于通过 Open in new 按钮打开的新的浏览器窗口，页面中有些元素需要隐藏。
 */
export const HideWhenOpenInNew: FC<HideOrShowWhenOpenInNewProps> = memo(({
    children,
}) => {
    const openInNew = whetherOpenInNew()

    return (
        <ShouldRender shouldRender={!openInNew}>
            {children}
        </ShouldRender>
    )
})

HideWhenOpenInNew.displayName = 'HideWhenOpenInNew'

/**
 * ShowWhenOpenInNew
 * 
 * 页面中有些元素只在通过 Open in new 按钮打开的新的浏览器窗口显示。
 */
export const ShowWhenOpenInNew: FC<HideOrShowWhenOpenInNewProps> = memo(({
    children,
}) => {
    const openInNew = whetherOpenInNew()

    return (
        <ShouldRender shouldRender={openInNew}>
            {children}
        </ShouldRender>
    )
})

ShowWhenOpenInNew.displayName = 'ShowWhenOpenInNew'

/**
 * whetherOpenInNew
 * 
 * 当前页面是否是通过 Open in new 按钮打开的新页面
 */
export const whetherOpenInNew = () => {
    const params: Record<string, any> = queryString.parse(window.location.search)
    return params[OPEN_IN_NEW_KEY] === OPEN_IN_NEW_VALUE
}
