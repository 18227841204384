import { SellerType } from 'types'
import { lsGet, lsSet } from './ls'

interface CategoryTreeListItem {
    name: string;
    isChecked: boolean;
    list?: CategoryTreeListItem[];
}

type CategoryTreeList = CategoryTreeListItem[]

interface CategoryTreeObj {
    [category: string]: CategoryTreeListItem;
}

export const storage = {
    /* ************************************************************************** */
    LOGIN_TIME: 'loginTime',
    setLoginTime() {
        lsSet(this.LOGIN_TIME, String(new Date().getTime()))
    },
    getLoginTime(): number {
        return Number(lsGet(this.LOGIN_TIME))
    },

    /* ************************************************************************** */
    /**
     * *** Deprecated ***
     */
    LOCKED: 'locked',
    setLocked(value: boolean) {
        lsSet(this.LOCKED, String(value))
    },
    getLocked(): boolean {
        const locked = lsGet(this.LOCKED)
        return locked === 'true'
    },

    /* ************************************************************************** */
    /**
     * The first time the system is accessed each day, 
     * the date value is reset to the value returned by the api
     * Refreshing the page at other times this day will not take the time returned by the api, 
     * but the time of the last modification
     */
    GLOBAL_DATE_VALUE: 'globalDateValue',
    setGlobalDateValue(value: string) {
        lsSet(this.GLOBAL_DATE_VALUE, String(value))
    },
    getGlobalDateValue() {
        return lsGet(this.GLOBAL_DATE_VALUE)
    },

    GLOBAL_COMPARISON_DATE_VALUE: 'globalComparisonDateValue',
    setGlobalComparisonDateValue(value: string) {
        lsSet(this.GLOBAL_COMPARISON_DATE_VALUE, String(value))
    },
    getGlobalComparisonDateValue() {
        return lsGet(this.GLOBAL_COMPARISON_DATE_VALUE)
    },

    RECORD_DATE: 'recordDate',
    setRecordDate(value: string) {
        lsSet(this.RECORD_DATE, String(value))
    },
    getRecordDate() {
        return lsGet(this.RECORD_DATE)
    },

    RECORD_COMPARISON_DATE: 'recordComparisonDate',
    setRecordComparisonDate(value: string) {
        lsSet(this.RECORD_COMPARISON_DATE, String(value))
    },
    getRecordComparisonDate() {
        return lsGet(this.RECORD_COMPARISON_DATE)
    },

    /* ************************************************************************** */
    CATEGORY_TREE_LIST: 'categoryTreeList',
    setCategoryTreeList(value: CategoryTreeList) {
        if (!Array.isArray(value)) return
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.CATEGORY_TREE_LIST, valueStr)
        } catch (e) {
            console.warn('CATEGORY_LIST setStorage error')
        }
    },
    getCategoryTreeList(): CategoryTreeList {
        const value = lsGet(this.CATEGORY_TREE_LIST) || []
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return []
        }
    },

    CATEGORY_TREE_OBJ: 'categoryTreeObj',
    setCategoryTreeObj(value: CategoryTreeObj) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.CATEGORY_TREE_OBJ, valueStr)
        } catch (e) {
            console.warn('CATEGORY_TREE_OBJ setStorage error')
        }
    },
    getCategoryTreeObj(): CategoryTreeObj {
        const value = lsGet(this.CATEGORY_TREE_OBJ) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {}
        }
    },

    /* ************************************************************************** */
    /**
     * The license of the currently logged-in system
     */
    CUSTOMER_VENDOR: 'customerVendor',
    setCustomerVendor(value: string) {
        lsSet(this.CUSTOMER_VENDOR, value)
    },
    getCustomerVendor(): string {
        return lsGet(this.CUSTOMER_VENDOR) || ''
    },

    /* ************************************************************************** */
    /**
     * test license: tigerofsweden
     */
    VENDOR_ORDER: 'vendorOrder',
    setVendorOrder(value: string[] = []) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.VENDOR_ORDER, valueStr)
        } catch (e) {
            console.warn('VENDOR_ORDER setStorage error')
        }
    },
    getVendorOrder() {
        const value = lsGet(this.VENDOR_ORDER) || []
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return []
        }
    },

    /* ************************************************************************** */
    /**
     * regionToCurrency
     */
    REGION_TO_CURRENCY: 'regionToCurrency',
    setRegionToCurrency(value: Record<string, string>) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.REGION_TO_CURRENCY, valueStr)
        } catch (e) {
            console.warn('REGION_TO_CURRENCY setStorage error')
        }
    },
    getRegionToCurrency() {
        const value = lsGet(this.REGION_TO_CURRENCY) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {}
        }
    },

    /* ************************************************************************** */
    SELLERS: 'sellers',
    setSellers(value: SellerType[]) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.SELLERS, valueStr)
        } catch (e) {
            console.warn('SELLERS setStorage error')
        }
    },
    getSellers() {
        const value = lsGet(this.SELLERS) || []
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return []
        }
    },

    CODENAMES: 'codenames',
    setCodenames(value: Record<string, string>) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.CODENAMES, valueStr)
        } catch (e) {
            console.warn('CODENAMES setStorage error')
        }
    },
    getCodenames(): Record<string, string> {
        const value = lsGet(this.CODENAMES) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {}
        }
    },

    NAMECODES: 'namecodes',
    setNamecodes(value: Record<string, string>) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.NAMECODES, valueStr)
        } catch (e) {
            console.warn('NAMECODES setStorage error')
        }
    },
    getNamecodes(): Record<string, string> {
        const value = lsGet(this.NAMECODES) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {}
        }
    },

    /* ************************************************************************** */
    CURRENT_RATE: 'currentRate',
    setCurrentRate(value: {[currency: string]: number}) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.CURRENT_RATE, valueStr)
        } catch (e) {
            console.warn('CURRENT_RATE setStorage error')
        }
    },
    getCurrentRate(): {[currency: string]: number} {
        const value = lsGet(this.CURRENT_RATE) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {}
        }
    },

    /* ************************************************************************** */
    // 从 /dashboard_get_target_group_region_currency/ 接口获取的数据
    CONFIGS: 'configs',
    setConfigs(value: Configs) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.CONFIGS, valueStr)
        } catch (e) {
            console.warn('CONFIGS setStorage error')
        }
    },
    getConfigs(): Configs {
        const value = lsGet(this.CONFIGS) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {
                currencys: [],
                regionToCurrency: {},
                regions: [],
                targets: [],
                unavailableFilter: [],
                unavailableMenu: [],
                uncurrencys: [],
                unregions: [],
                untargets: [],
            }
        }
    },

    /* ************************************************************************** */
    IS_LOOKBOOK: 'isLookbook',
    setIsLookbook(value: boolean) {
        lsSet(this.IS_LOOKBOOK, String(value))
    },
    getIsLookbook() {
        const value = lsGet(this.IS_LOOKBOOK)
        return value === 'true'
    },

    /* ************************************************************************** */
    PATHNAME_QUEUE: 'pathnameQueue',
    setPathnameQueue(value: string[]) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.PATHNAME_QUEUE, valueStr)
        } catch (e) {
            console.warn('PATHNAME_QUEUE setStorage error')
        }
    },
    getPathnameQueue() {
        const value = lsGet(this.PATHNAME_QUEUE) || []
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return []
        }
    },

    /* ************************************************************************** */
    NOT_SHOW_SUB_GROUPS_MAP: 'notShowSubGroupsMap',
    setNotShowSubGroupsMap(value: {[key: string]: string[]}) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.NOT_SHOW_SUB_GROUPS_MAP, valueStr)
        } catch (e) {
            console.warn('NOT_SHOW_SUB_GROUPS_MAP setStorage error')
        }
    },
    getNotShowSubGroupsMap(): {[currency: string]: number} {
        const value = lsGet(this.NOT_SHOW_SUB_GROUPS_MAP) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {}
        }
    },

    /* ************************************************************************** */
    ALERT_MESSAGES: 'alertMessages',
    setAlertMessages(value: string[]) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.ALERT_MESSAGES, valueStr)
        } catch (e) {
            console.warn('ALERT_MESSAGES setStorage error')
        }
    },
    getAlertMessages(): string[] {
        const value = lsGet(this.ALERT_MESSAGES) || []
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return []
        }
    },

    /* ************************************************************************** */
    IS_GATE: 'isGate',
    setIsGate(value: boolean) {
        lsSet(this.IS_GATE, String(value))
    },
    getIsGate() {
        const value = lsGet(this.IS_GATE)
        return value === 'true'
    },

    /* ************************************************************************** */
    PROPERTIES: 'properties',
    setProperties(value: {[category: string]: object}) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.PROPERTIES, valueStr)
        } catch (e) {
            console.warn('PROPERTIES setStorage error')
        }
    },
    getProperties(): {[currency: string]: number} {
        const value = lsGet(this.PROPERTIES) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {}
        }
    },

    /* ************************************************************************** */
    BLACKLIST: 'blacklist',
    setBlacklist(value: any[]) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.BLACKLIST, valueStr)
        } catch (e) {
            console.warn('BLACKLIST setStorage error')
        }
    },
    getBlacklist(): any[] {
        const value = lsGet(this.BLACKLIST) || []
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return []
        }
    },

    /* ************************************************************************** */
    FE_CONFIG: 'feConfig',
    setFeConfig(value: FeConfigType) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.FE_CONFIG, valueStr)
        } catch (e) {
            console.warn('FE_CONFIG setStorage error')
        }
    },
    getFeConfig(): FeConfigType {
        const value = lsGet(this.FE_CONFIG) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {} as FeConfigType
        }
    },

    /* ************************************************************************** */
    DYNAMIC_DASHBOARD_SETTINGS: 'dynamicDashboardSettings',
    setDynamicDashboardSettings(value = {}) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.DYNAMIC_DASHBOARD_SETTINGS, valueStr)
        } catch (e) {
            console.warn('DYNAMIC_DASHBOARD_SETTINGS setStorage error')
        }
    },
    getDynamicDashboardSettings(): {[currency: string]: number} {
        const value = lsGet(this.DYNAMIC_DASHBOARD_SETTINGS) || {}
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return {}
        }
    },

    /* ************************************************************************** */
    META_STRUCTURE_ID: 'metaStructureId',
    setMetaStructureId(value: string) {
        lsSet(this.META_STRUCTURE_ID, value)
    },
    getMetaStructureId(): string {
        return lsGet(this.META_STRUCTURE_ID) || ''
    },

    /* ************************************************************************** */
    ACCOUNT_ID: 'accountId',
    setAccountId(value: string) {
        lsSet(this.ACCOUNT_ID, value)
    },
    getAccountId(): string {
        return lsGet(this.ACCOUNT_ID) || ''
    },

    /* ************************************************************************** */
    EMAIL: 'email',
    setEmail(value: string) {
        lsSet(this.EMAIL, value)
    },
    getEmail(): string {
        return lsGet(this.EMAIL) || ''
    },

    /* ************************************************************************** */
    LICENSES: 'licenses',
    setLicenses(value: any[]) {
        try {
            const valueStr = JSON.stringify(value)
            lsSet(this.LICENSES, valueStr)
        } catch (e) {
            console.warn('LICENSES setStorage error')
        }
    },
    getLicenses() {
        const value = lsGet(this.LICENSES) || []
        if (typeof value !== 'string') {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return []
        }
    },
}

interface Configs {
    currencys: string[];
    regionToCurrency: {[region: string]: string};
    regions: string[];
    targets: string[];
    unavailableFilter: string[];    // 禁用的 Filter 选项
    unavailableMenu: string[];      // 禁用的菜单
    uncurrencys: string[];
    unregions: string[];
    untargets: string[];
}

interface FeConfigType {
    children_size_filters_enabled: boolean;
    dashboard_disselected_selected_vendors: boolean;
    filter_tax_enabled: boolean;
    first_dashboard_table_as_category_viewpoint: boolean;
    gender_size_filters_options: {key: string; description: string}[];
    hidden_categories_on_selected_children_size_filters: string[];
    hidden_categories_on_selected_color: string[];
    max_category_count: number;
    only_platform_name_on_search_options: boolean;
    original_picture_scale: string[];
    price_with_two_decimal_places: boolean;
    property_blacklist: string[];
    property_name_aliases: Record<string, string>;
    property_trouser_fit_enable: boolean;
    region_aliases: Record<string, string>;
    size_prices_product_card: string;
    pack_filter_enabled: boolean;
    only_show_size_products_for_competitors: string[];
}
