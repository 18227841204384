import React, { CSSProperties, ReactNode } from 'react'

export enum DropdownType  {
  SINGLE = 'single',
  MULTI  = 'multiply'
}
export interface DropdownProps {
  type?: DropdownType;// 'single' | 'multiply';             // 下拉框 (select) 类型，single 单选下拉框，multiply 多选下拉框
  disabled?: boolean;                       // 下拉框 (select) 是否禁用
  right?: number | boolean;                           // 下拉框 (select) marginRight 值
  left?: number | boolean;                            // 下拉框 (select) marginLeft 值
  style?: CSSProperties;                    // 下拉框 (select) 样式
  className?: string;                       // 下拉框 (select) 类名
  placeholder?: string;                     // 下拉选择器 (selector) 中 placeholder 文案，选择下拉框选项，placeholder 会被选中项替换
  constantPlaceholder?: string;             // 下拉选择器 (selector) 中 placeholder 文案，选择下拉框选项，下拉框中仍然显示 constantPlaceholder 值
  showSelected?: boolean;                   // 默认情况当 constantPlaceholder 不为空时，下拉选项不显示选中项（即便选中，文字也没有颜色）
  options: OptionProps[];                   // 下拉弹窗 (dropdown) 中下拉选项数组
  value?: string | string[];                // 下拉弹窗 (dropdown) 中选中项的 key 值
  title?: string;                           // 下拉弹窗 (dropdown) 标题
  label?: string;                           // 下拉弹窗 (dropdown) label
  dropdownStyle?: CSSProperties;            // 下拉弹窗 (dropdown) 样式
  selectorClassName?: string;
  showSearch?: boolean;                     // 下拉弹窗 (dropdown) ，是否显示搜索框
  onChange?: (value: string | string[]) => void;    // 下拉弹窗 (dropdown) 中下拉选项变化时触发事件
  footer?: ReactNode;                       // 
  // --------------------------- 多选下拉框选项 -----------------------------
  showClose?: boolean;                      // 显示关闭按钮
  showSelectAll?: boolean;                  // 是否显示 select all 按钮
  showClearAll?: boolean;                   // 是否显示 clear all 按钮
  showOkButton?: boolean;
  clearAllType?: 'remain' | 'empty';        // empty 一个不留, 在关闭窗口时做判断
  clearAllMinCount?: number;
  onOk?: (value: string[]) => void;         // 点击 ok 按钮触发事件

  minCount?:number;                         // 多选下拉框最最少选择数目
  maxCount?: number;                        // 多选下拉框最多选择数目
  customMaxHeight?: number;                 // customize the max height of panel
  // --------------------------- 下面几个属性待确定 -----------------------------
  customRender?: Function;
  isSelectorCenter?: boolean;
  labelStyle?: CSSProperties;
  onVisibleChanage?: (visible: boolean) => void;
};

export interface DropdownRefProps {
  close: () => void;
  getSelectorEl: () => HTMLDivElement | null;
}

export interface OptionProps {
  key: string;                  // 选项值
  description: string;          // 选项名称 
  isSelected?: boolean;         // 是否被选择
  isDisabled?: boolean;         // 是否禁选
  smallText?: string;           
  obj?: any;
};

export interface DropdownOptionProps {
  key: string;
  description: string;
  isDisabled?: boolean;
}

export interface OptionItemProps {
  children?: React.ReactNode;
  item: OptionProps;
  active?: boolean;
  showCheckbox?: boolean;                   // 是否显示复选框
  onSelect: (item: OptionProps) => void;
  customClass?: string; // custom class
  disabled?: boolean;
}

export interface OptionHeaderProps {
  title?: string;
  showClose?: boolean;
  onClick?: () => void;
}
