import { DISABLE_CANCEL_REQUEST } from 'consts'

/** *
 *  report tool api for single product 
 */
export const reportToolArg = ({ user, url, mask, field,unset=false, vendor = '', crossVariation = false }) => ({
    url: 'mask_product/',
    query: {
        field, // value from : material, color, property
        unset,
        cross_variation: crossVariation,
    },
    data: {
        user,
        url,
        vendor,
        mask,
    },
})

/** *
 *  report tool api for single product 
 */
 export const setFirstImageArg = ({ imageName, user }) => ({
    url: 'select_first_image/',
    query: {
        image_name:imageName, // value from : material, color, property
        user,
    },
    data: {
        
    },
})

/** *
 *  report tool api for single product 
 */
export const deleteImageArg = ({ imageName, user }) => ({
    url: '/delete_image_in_fe/',
    query: {
        image_name:imageName, // value from : material, color, property
        user,
    },
    data: {
        
    },
})

/**
 * 
 * @param   selectedTag    PropertyQaAnnotation
 * @param   param0 
 * @returns 
 */
export const getReportedArg = (nornaids: Array<string>, selectedTag: Array<string>) => ({
    url: '/get_mask_tag/',
    query: {
        // ,// PropertyQaAnnotation.join(',').toLowerCase(),
    },
    [DISABLE_CANCEL_REQUEST]:true,
    data: {
        
        products: { nornaids },
        select_tags: selectedTag,
    },
})

/**
 * batch report property
 * @param user                  current user
 * @param nornaids              nornaids of product
 * @param mask                  mask
 * @returns 
 */
export const batchReportArg = (user, mask: Array<any>, type: string, unset=false, products: {nornaid: string; vendor: string;}[]=[], crossVariation = false) => ({
    url: '/batch_mask_products/',
    query: {
        user,// value from : material, color, property
        field: type,// value from : material, color, property
        unset,
        cross_variation: crossVariation,
    },
    data: {
        products,
        mask,
    },
})

/**
 * ## batch confirm
 * 
 * @param user 
 * @param nornaids 
 * @param mask 
 * @param type 
 * @param unset 
 * @returns 
 */
export const batchConfirmReportArg = (user, nornaids: Array<string>, preliminaryConfirm = false) => ({
    url: '/batch_confirm_products/',
    query: {
        user,// value from : material, color, property
        qa_page:true,
        preliminary_confirm: preliminaryConfirm,
    },
    data: {
        nornaids,
    },
})
/**
 * batch report type
 */

export const BatchReportArgType = {
    PROPERTY: 'property',
    MATERIAL: 'material',
    SIZE: 'size',
}

/**
 * cancel report
 * @param user 
 * @param nornaids 
 * @param mask 
 * @returns 
 */
export const unsetReportArg = (nornaids: Array<string>) => ({
    url: '/unset_mask_tag/',
    query: {
    },
    data: {
        nornaids,
    },
})

// /submit_color_segmentation/

/** *
 * submit image annotation
 */
export const submitColorSegmentationReportArg = (
    productUrl: string,
    imageName: string,
    nornaid: string,
    polygon: Array<[number, number]>,
    w: number,
    h: number,
) => ({
    url: '/submit_color_segmentation/',
    query: {
        product_url: productUrl,
        image_name: imageName,
        image_height: Math.floor(h),
        image_width: Math.floor(w),
    },
    data: {
        product: {
            nornaid,
        },
        polygon,
    },
})

export const fetchColorSegmentationReportArg = (nornaid: string) => ({
    url: '/fetch_color_segmentation/',
    query: {
    },
    data: {
        nornaid,
    },
})
