import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { Dropdown } from 'componentsv2/form-elements/Dropdown'
import { SizedBox } from 'componentsv2/SizedBox'
import { Spin } from 'componentsv2/Spin'
import { ProductsModal } from 'components/ProductsModalV2'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { useFetch } from 'libs/hookRequest'
import { argFnGetPopularProductsProperties } from 'graphql/nornaapi'
import { useExcludeProductsComparisonQuery, useLoadingBar, useManualLoading, useScrollToTop } from 'hooks'
import { useCompetitorOptions } from 'hooks/useOptions'
import { TabItemWithChildProps } from 'componentsv2/TradingTable/types'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { cloneDeep } from 'lodash'
import { usePageDate } from 'hooks/usePageDate'
import { useWindowZoom } from 'hooks/useWindow'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { isSuperArray } from 'utils/array'
import { formatDateStr2 } from 'utils/dateUtils'
import { NornaTab as NornaTabV2, NornaTable as NornaTableV2, TableCell as TableCellV2, Th } from 'componentsv2/TradingTableV2'
import { isSubsetPayload, UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, UPDATE_NEWCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { downloadFileByAxios } from 'export'
import { isCompetitorGroupChanged } from 'utils/group'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { attributeRankingExportFilename } from 'utils/exportUtils'
import { ATTRIBUTE_RANKING_TABLE_NAME } from 'configs'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { useModuleData } from 'hooks/useModuleData'
import styles from './styles.module.scss'
import { geneCsvData, handleTableData, noNameWidth, noValueWidth, noWidth, propertyWidth } from './utils'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import GraphButton from 'componentsv2/GraphButton'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_ATTRIBUTE_RANKING } from 'features/filters/moduleDataSlice.const'
import { dateRangeUtils, numberUtils } from 'norna-uikit'
import { Flex } from 'componentsv2/Flex'
import { NA } from 'consts'
import classNames from 'classnames'

export const AttributeRanking = () => {
    useScrollToTop()
    useLoadingBar()
    const { pageDate: dateRangeValue } = usePageDate()
    const { manualLoading, showManualLoading } = useManualLoading()
    const [ filterCountry ] = useFilterCountry()
    const [ competitorValue ] = useFilterCompetitor()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const zoom = useWindowZoom()

    const [ moduleData, setModuleData ] = useModuleData(ATTRIBUTE_RANKING_TABLE_NAME)
    const {
        fetchPayload = {},
        apiData = {},
        oldCompetitorValue = [],
        newCompetitorValue = [],
        rangeValue = '150',
    } = moduleData

    /* ****************************** Export excel ******************************** */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        const competitorSellers = [ ...competitorValue ]
        payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...competitorSellers ] })
        payload.data.groups = getGroupsPayload({ competitorSellers })
        payload.data.market = getMarketPayload({ competitorSellers, competitorOptions })
        await downloadFileByAxios({
            filename: attributeRankingExportFilename,
            payload,
        })
    }

    /* ****************************** Form ******************************** */
    // Selected vendors
    const { competitorOptions } = useCompetitorOptions()

    /**
     * 更新 newCompetitorValue
     */
    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: { competitorValue: competitorValue.map(item => item.vendor) },
        })
    }, [ competitorValue, [] ])

    // Selected range
    const rangeOptions = [ '50', '100', '150' ].map(item => ({ key: item, description: `Top ${item}` }))

    const setRangeValue = (value: string) => {
        setModuleData({ rangeValue: value })
    }

    /* ****************************** Table ******************************** */
    const [ metricsTab, setMetricsTab ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata, setTabledata ] = useState<Array<any>>([])
    const [ metricsTab2, setMetricsTab2 ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata2, setTabledata2 ] = useState<Array<any>>([])
    const [ cateHeight, setCateHeight ] = useState(0)
    const [ expendHeight, setExpendHeight ] = useState(0)
    const [ closeHeight, setCloseHeight ] = useState(0)

    /* **************************** data ********************************* */
    const { postFn: fetch, loading, data, setData } = useFetch()
    const [ , setCsvData ] = useCsvDataModuleData()

    const columns2: any[] = [
        {
            dataIndex: 'property',
            width: propertyWidth,
            render: (text, record) => {
                return (
                    <TableCellV2
                        text={record?.categoryName}
                        record={record}
                    />
                )
            },
        },

        {
            dataIndex: 'no1Value',
            width: noWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell 
                            style={{ borderLeft: 'none' }}
                        />
                    )
                }

                return (
                    <TableCellV2
                        width={noWidth}
                        isEmptyBorderBottom
                        isEmptyBorderRight
                        style={{ padding: 0, borderLeft: 'none' }}
                    >
                        <TableCellV2 
                            text={record?.no1Name || NA} 
                            record={record} 
                            width={noNameWidth} 
                        />
                        <TableCellV2 
                            text={record?.no1Value || NA} 
                            record={record} 
                            width={noValueWidth}
                            style={{ flex: 'none' }}
                            className={classNames({
                                [styles.valueWidth]: true,
                                [styles.bgHover]: !!record?.no1Products?.length,
                                pointer: !!record?.no1Products?.length,
                            })}
                            isClickable={!!record?.no1Products?.length}
                            onClick={() => {
                                const title = `${record.vendorName}, ${record.categoryName}, ${record.no1Name}`
                                onShowProductModal({
                                    products: record?.no1Products || [],
                                    title,
                                })
                            }}
                        />
                    </TableCellV2>
                )
            },
        },

        {
            dataIndex: 'no2Value',
            width: noWidth,
            render: (text, record) => {
              if (record?.isLock) {
                return (
                  <TableLockCell 
                    isWhiteBg
                  />
                )
              }
      
              return (
                <TableCellV2
                  width={noWidth}
                  isEmptyBorderBottom
                  isEmptyBorderRight
                  isWhiteBg
                  style={{ padding: 0 }}
                >
                    <TableCellV2 
                        text={record?.no2Name || NA} 
                        record={record} 
                        width={noNameWidth} 
                    />
                    <TableCellV2 
                        text={record?.no2Value || NA} 
                        record={record} 
                        width={noValueWidth}
                        style={{ flex: 'none' }}
                        className={classNames({
                            [styles.valueWidth]: true,
                            [styles.bgHover]: !!record?.no2Products?.length,
                            pointer: !!record?.no2Products?.length,
                        })}
                        isClickable={!!record?.no2Products?.length}
                        onClick={() => {
                            const title = `${record.vendorName}, ${record.categoryName}, ${record.no2Name}`
                            onShowProductModal({
                                products: record?.no2Products || [],
                                title,
                            })
                        }}
                    />
                </TableCellV2>
              )
            },
        },

        {
            dataIndex: 'no3Value',
            width: noWidth,
            render: (text, record) => {
              if (record?.isLock) {
                return (
                  <TableLockCell />
                )
              }
      
              return (
                <TableCellV2
                  width={noWidth}
                  isEmptyBorderBottom
                  isEmptyBorderRight
                  style={{ padding: 0 }}
                >
                    <TableCellV2 
                        text={record?.no3Name || NA} 
                        record={record} 
                        width={noNameWidth} 
                    />
                    <TableCellV2 
                        text={record?.no3Value || NA} 
                        record={record} 
                        width={noValueWidth}
                        style={{ flex: 'none' }}
                        className={classNames({
                            [styles.valueWidth]: true,
                            [styles.bgHover]: !!record?.no3Products?.length,
                            pointer: !!record?.no3Products?.length,
                        })}
                        isClickable={!!record?.no3Products?.length}
                        onClick={() => {
                            const title = `${record.vendorName}, ${record.categoryName}, ${record.no3Name}`
                            onShowProductModal({
                                products: record?.no3Products || [],
                                title,
                            })
                        }}
                    />
                </TableCellV2>
              )
            },
        },

        {
            dataIndex: 'no4Value',
            width: noWidth,
            render: (text, record) => {
              if (record?.isLock) {
                return (
                  <TableLockCell 
                    isWhiteBg
                  />
                )
              }
      
              return (
                <TableCellV2
                  width={noWidth}
                  isEmptyBorderBottom
                  isEmptyBorderRight
                  isWhiteBg
                  style={{ padding: 0 }}
                >
                    <TableCellV2 
                        text={record?.no4Name || NA} 
                        record={record} 
                        width={noNameWidth} 
                    />
                    <TableCellV2 
                        text={record?.no4Value || NA} 
                        record={record} 
                        width={noValueWidth}
                        style={{ flex: 'none' }}
                        className={classNames({
                            [styles.valueWidth]: true,
                            [styles.bgHover]: !!record?.no4Products?.length,
                            pointer: !!record?.no4Products?.length,
                        })}
                        isClickable={!!record?.no4Products?.length}
                        onClick={() => {
                            const title = `${record.vendorName}, ${record.categoryName}, ${record.no4Name}`
                            onShowProductModal({
                                products: record?.no4Products || [],
                                title,
                            })
                        }}
                    />
                </TableCellV2>
              )
            },
        },

        {
            dataIndex: 'no5Value',
            width: noWidth,
            render: (text, record) => {
              if (record?.isLock) {
                return (
                  <TableLockCell />
                )
              }
      
              return (
                <TableCellV2
                  width={noWidth}
                  isEmptyBorderBottom
                  isEmptyBorderRight
                  style={{ padding: 0 }}
                >
                    <TableCellV2 
                        text={record?.no5Name || NA} 
                        record={record} 
                        width={noNameWidth} 
                    />
                    <TableCellV2 
                        text={record?.no5Value || NA} 
                        record={record} 
                        width={noValueWidth}
                        style={{ flex: 'none' }}
                        className={classNames({
                            [styles.valueWidth]: true,
                            [styles.bgHover]: !!record?.no5Products?.length,
                            pointer: !!record?.no5Products?.length,
                        })}
                        isClickable={!!record?.no5Products?.length}
                        onClick={() => {
                            const title = `${record.vendorName}, ${record.categoryName}, ${record.no5Name}`
                            onShowProductModal({
                                products: record?.no5Products || [],
                                title,
                            })
                        }}
                    />
                </TableCellV2>
              )
            },
        },
    ]

    const metricTabFn = a => {
        const metricsTab1: Array<TabItemWithChildProps> = a || metricsTab
        const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
        let closeHeightA = closeHeight
        if (exHeight.length >= 1) {
            if (exHeight[0].getBoundingClientRect().height < 10) {
                closeHeightA = exHeight[0].getBoundingClientRect().height
            }
        }
        let index = metricsTab1.findIndex(n => n.active === true)
        setCateHeight(43)
        let subIndex = 0
        if (index > -1) {
            metricsTab1[index].children.filter(n => n.active).forEach(item => {
                subIndex += item.categorycount
            })
        }
        index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
        subIndex += index
        setExpendHeight((subIndex + 1) * 43)
        setCloseHeight(closeHeightA)
        setMetricsTab(metricsTab1)
    }

    const fetchData = () => {
        if (!dateRangeValue || !competitorOptions?.length || !competitorValue?.length) {
            return
        }

        const query = cloneDeep(comparisonQuery)
        const competitorSellers= [ ...competitorValue ]
        query.competitor_sellers = getCompetitorPayload({ competitorSellers })
        query.market = getMarketPayload({ competitorSellers, competitorOptions })
        query.groups = getGroupsPayload({ competitorSellers })
        const payload = argFnGetPopularProductsProperties(query, dateRangeUtils.from(dateRangeValue), rangeValue)

        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload }) && Object.keys(apiData || {}).length) {
            showManualLoading()
            setData?.(apiData)
            return
        }

        setModuleData({
            type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE,
            payload: cloneDeep(payload),
        })
        fetch(payload.url, payload)
    }

    const handleData = (apiData = {}): any => {
        if (Object.keys(apiData || {}).length === 0) {
            setTabledata([])
            setMetricsTab([])
            return
        }

        const {
            tabledata: tabledata2,
            metricsTab: metricsTab2,
        } = handleTableData({
            apiData,
            competitorValue: newCompetitorValue,
            region: filterCountry,
            expandedRowKeys,
        })
        setTabledata2(tabledata2)
        setMetricsTab2(metricsTab2)

        setCsvData({
            [CSV_DATA_ATTRIBUTE_RANKING]: geneCsvData({ dataSource: tabledata2 }),
        })

        return {
            tabledata,
            metricsTab,
        }
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, dateRangeValue, rangeValue, competitorOptions ])

    useDeepCompareEffect(() => {
        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleData(cloneDeep(apiData))
            return
        }

        fetchData()
    }, [ newCompetitorValue ])

    useEffect(() => {
        if (!data) return

        handleData(cloneDeep(data))
        setModuleData({ apiData: cloneDeep(data) })
        // eslint-disable-next-line
    }, [data])

    /* ************************ 表格展开项 ************************** */
    const [ expandedRowKeys, setExpandedRowKeys ] = useState<string[]>([ '0' ])

    useEffect(() => {
        let keys = [ ...expandedRowKeys ]
        // 写递归没写出来, 后面脑子清醒时再尝试优化
        metricsTab.forEach((item1, index1) => {
            if (item1.active === true) {
                keys.push(String(index1))
            } else {
                keys = keys.filter(i => i !== String(index1))
                keys = keys.filter(i => !i.startsWith(`${index1}-`))
            }
            if (Array.isArray(item1.children) && item1.children.length > 0) {
                item1.children.forEach((item2, index2) => {
                    if (item2.active === true && keys.includes(String(index1))) {
                        keys.push(`${index1}-${index2}`)
                    } else {
                        keys = keys.filter(i => i !== `${index1}-${index2}`)
                    }
                })
            }
        })
        // 去重
        keys = Array.from(new Set(keys))
        setExpandedRowKeys(keys)
        // eslint-disable-next-line
    }, [metricsTab])

    /* ************************************ Lookbook ******************************************* */
    const { judgeActionOccurs, clearLookbookActions } = useLookbookAction(true, true)

    /** ****************************** 产品模态框 ************************************ */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    const onShowProductModal = ({ products = [], title }) => {
        if (!Array.isArray(products) || products.length === 0) return
        clearLookbookActions()
        const dialogId = `Popular_attribute_${title}`
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog(dialogId,
            <ProductsModal
                productUrls={products}
                onClose={() => {
                    closeFn(dialogId)
                    if (judgeActionOccurs()) fetchData()
                }}
                headerLeading={numberUtils.formatNumberByComma(products.length)}
                headerTitle={title}
                headerDate={formatDateStr2(dateRangeValue, true)}
            />)
    }

    return (
        <div className={styles.container}>
            <div className="export-table">
                <ModuleTitle category="ASSORTMENT" title={ATTRIBUTE_RANKING_TABLE_NAME} showDate />
                <SizedBox height={20} />
                <Spin spinning={loading || manualLoading} minHeight={300} zIndex={150}>
                    <div className={styles.form}>
                        <Dropdown
                            label="SELECTED RANGE"
                            options={rangeOptions}
                            value={rangeValue}
                            onChange={value => setRangeValue(value as string)}
                            right
                        />
                        <ExportDropdown
                            right
                            onExportExcel={onExportExcel}
                            selector=".export-table"
                            fileName={attributeRankingExportFilename}
                        />
                        <GraphButton />
                    </div>
                    <div className={classnames([ 'norna-container-fixed-width' ])} style={{ width: '1440px' }}>
                        <Flex className={styles.title} style={{ zIndex: 2 }}>
                            <div style={{ width: 145, textAlign: 'center' }}>
                                Vendors
                            </div>
                            <div style={{ width: 18, height: 1 }} />
                            <Th showTooltip={false} title="Attribute" width={propertyWidth} />
                            <Th showTooltip={false} title="1" width={noNameWidth} />
                            <Th showTooltip={false} title="%" style={{ color: '#01A699' }} width={noValueWidth} />
                            <Th showTooltip={false} title="2" width={noNameWidth} />
                            <Th showTooltip={false} title="%" style={{ color: '#01A699' }} width={noValueWidth} />
                            <Th showTooltip={false} title="3" width={noNameWidth} />
                            <Th showTooltip={false} title="%" style={{ color: '#01A699' }} width={noValueWidth} />
                            <Th showTooltip={false} title="4" width={noNameWidth} />
                            <Th showTooltip={false} title="%" style={{ color: '#01A699' }} width={noValueWidth} />
                            <Th showTooltip={false} title="5" width={noNameWidth} />
                            <Th showTooltip={false} title="%" style={{ color: '#01A699' }} width={noValueWidth} />
                        </Flex>
                        <div className={classnames([ styles.tableWrapper, 'popular-attributes' ])} style={{ zIndex: 1 }}>
                            <NornaTabV2
                                showHeader={false}

                                categoryToVendor={true}
                                metricsTab={metricsTab2}
                                cateHeight={cateHeight}
                                expendHeight={expendHeight}
                                metricTabFn={metricTabFn}
                                isDashboard={true}
                            />
                            <div style={{ width: 18 }}></div>
                            <NornaTableV2
                                showHeader={false}
                                dataSource={tabledata2}
                                columns={columns2}

                                isTd={true}
                                metricTabFn={metricTabFn}
                                metricsTab={metricsTab2}
                                categoryToVendor={true}
                            />
                        </div>
                    </div>
                </Spin>
                <div style={{ width: 1, height: 70, background: 'transparent' }} />
            </div>
            <Dialog ref={dialogRef} />
        </div>
    )
}

