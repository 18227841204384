import { CATEGORY_TOTAL, MARKET_VENDOR, SELECTED_CATEGORY, SELECTED_VENDORS } from 'consts'
import { getValueByOptions, getValueByQueryDateValid } from './pageUtils'

/**
 * 处理百分数保留小数位数问题
 * 
 * 大于等于 100 保留 0 位小数
 * 小于 100 保留 1 位小数
 */
export function handlePercentDecimal(value: number): string {
    return value.toFixed(Math.abs(value) >= 100 ? 0 : 1)
}

export function formatDashbaordValue(value, { queryDateValid, options }) {
    let newValue = value
    newValue = getValueByOptions(newValue, options)
    newValue = getValueByQueryDateValid(newValue, queryDateValid)
    return newValue
}

export function getTotalVendorLabel(data: any = {}) {
    const newData = { ...data }
    return newData[SELECTED_VENDORS] ? SELECTED_VENDORS : MARKET_VENDOR.vendor
}

export function getTotalCategoryLabel(data: any = {}) {
    const newData = { ...data }
    return newData[SELECTED_CATEGORY] ? SELECTED_CATEGORY : CATEGORY_TOTAL
}

export const getExpandedKeysByMetricTab = (metricsTab: any) => {
    const keys: string[] = []
    metricsTab.forEach((item1) => {
        if (item1.active === true) {
            const key1 = item1.expandId
            keys.push(key1)
            if (Array.isArray(item1.children) && item1?.children?.length) {
                item1.children.forEach((item2) => {
                    if (item2.active === true && keys.includes(key1)) {
                        const key2 = item2.expandId
                        keys.push(key2)
                    }
                })
            }
        }
    })
    return keys
}

export const getSubExpandedKeysByMetricTab = (metricsTab: any) => {
    const keys: string[] = []
    metricsTab.forEach((item) => {
        if (item?.subActive) {
            const key1 = item.expandId
            keys.push(key1)
            if (item?.children?.length) {
                item.children.forEach((item2) => {
                    if (item2.active === true && keys.includes(key1)) {
                        keys.push(item2.expandId)
                    }
                })
            }
        }
    })
    return keys
}

export const handleTableExpanded = (metricsTab, expandedRowKeys, subExpandedRowKeys) => {
    if (Array.isArray(metricsTab) && metricsTab.length) {
        expandedRowKeys.forEach(rowKey => {
            const keyArr = rowKey.split('__')
            if (keyArr.length === 1) {
                const key1 = keyArr[0]
                const index = metricsTab.findIndex(item => item.expandId === key1)
                if (index !== -1) {
                    metricsTab[index].active = true
                }
            } else if (keyArr.length === 2) {
                const [ firstLevelExpandId ] = keyArr
                const firstLevelIndex = metricsTab.findIndex(item => item.expandId === firstLevelExpandId)
                if (firstLevelIndex !== -1) {
                    const children = metricsTab[firstLevelIndex].children
                    const secondLevelIndex = children.findIndex(item => item.expandId === rowKey)
                    if (secondLevelIndex !== -1) {
                        metricsTab[firstLevelIndex].children[secondLevelIndex].active = true
                    }
                }
            }
        })
        subExpandedRowKeys.forEach(rowKey => {
            const keyArr = rowKey.split('__')
            if (keyArr.length === 1) {
                const key1 = keyArr[0]
                const index = metricsTab.findIndex(item => item.expandId === key1)
                if (index !== -1) {
                    metricsTab[index].subActive = true
                }
            }
            if (keyArr.length === 2) {
                const [ firstLevelExpandId ] = keyArr
                const firstLevelIndex = metricsTab.findIndex(item => item.expandId === firstLevelExpandId)
                if (firstLevelIndex !== -1) {
                    const children = metricsTab[firstLevelIndex].children
                    const secondLevelIndex = children.findIndex(item => item.expandId === rowKey)
                    if (secondLevelIndex !== -1) {
                        metricsTab[firstLevelIndex].children[secondLevelIndex].active = true
                    }
                }
            }
        })
    }
}
