import React, { useState } from 'react'
import { Text } from 'components/typography'
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import { QA_BUTTON_SPEED_BENCHMARK_LABEL } from 'consts/strings';
import styles from './SpeedBenchmarkDialog.module.scss'
import { Button, Table } from 'antd';
import { DISABLE_CANCEL_REQUEST } from 'consts';
import * as Request from 'libs/request'

const RequestTypeEnum = {
    shortQuery: 'Short query',
    middleQuery: 'Middle query',
    longQuery: 'Long query',
}

export default function SpeedBenchmark({
    closeModal,
}: {
    closeModal?: (value: boolean) => void;
}) {
    const handleCloseModal = () => closeModal?.(false)

    const [ dataSource, setDataSource ] = useState([
        { request: RequestTypeEnum.shortQuery, duration: 0, loading: false },
        { request: RequestTypeEnum.middleQuery, duration: 0, loading: false },
        { request: RequestTypeEnum.longQuery, duration: 0, loading: false },
    ])

    const columns = [
        {
            title: 'Request type',
            dataIndex: 'request',
            key: 'request',
        },
        {
            title: 'Duration (seconds)',
            dataIndex: 'duration',
            key: 'duration',
            render(text, record) {
                if (record.loading) {
                    return 'pending....'
                }
                return <span>{text}</span>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render(text, record) {
                return (
                    <div style={{ width: 200 }}>
                        <Button 
                            loading={record.loading}
                            onClick={() => onSendRequest(record.request)}
                        >
                            Send request
                        </Button>
                    </div>
                )
            },
        },
    ]

    const onSendRequest = async (request) => {
        if (request === RequestTypeEnum.shortQuery) {
            await onSendShortQueryRequest()
        }
        else if (request === RequestTypeEnum.middleQuery) {
            await onSendMiddleQueryRequest()
        }
        else if (request === RequestTypeEnum.longQuery) {
            await onSendLongQueryRequest()
        }
    }

    const onSendShortQueryRequest = async () => {
        const newDataSource = [ ...dataSource ]
        newDataSource[0].loading = true
        setDataSource(newDataSource)
        const result = await Request.getFn('/fixed_benchmark', 
            { performance: 'fast' },
            { data: { [DISABLE_CANCEL_REQUEST]: true } },
        )
        const seconds = result?.data?.time || 0    // 单位 s
        newDataSource[0].duration = seconds
        newDataSource[0].loading = false
        setDataSource(newDataSource)
    }

    const onSendMiddleQueryRequest = async () => {
        const newDataSource = [ ...dataSource ]
        newDataSource[1].loading = true
        setDataSource(newDataSource)
        const result = await Request.getFn('/fixed_benchmark',
            { performance: 'medium' },
            { data: { [DISABLE_CANCEL_REQUEST]: true } },
        )
        const seconds = result?.data?.time || 0    // 单位 s
        newDataSource[1].duration = seconds
        newDataSource[1].loading = false
        setDataSource(newDataSource)
    }

    const onSendLongQueryRequest = async () => {
        const newDataSource = [ ...dataSource ]
        newDataSource[2].loading = true
        setDataSource(newDataSource)
        const result = await Request.getFn('/fixed_benchmark', 
            { performance: 'slow' }, 
            { data: { [DISABLE_CANCEL_REQUEST]: true } },
        )
        const seconds = result?.data?.time || 0    // 单位 s
        newDataSource[2].duration = seconds
        newDataSource[2].loading = false
        setDataSource(newDataSource)
    }

    const onSendAllRequests = () => {
        onSendShortQueryRequest()
        onSendMiddleQueryRequest()
        onSendLongQueryRequest()
    }

    return (
        <>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <Text small>
                        {QA_BUTTON_SPEED_BENCHMARK_LABEL}
                    </Text>
                    <CloseIcon
                        className={styles.closeIcon}
                        onClick={handleCloseModal}
                    />
                </div>
                <div className={classNames(styles.body)}>
                    <div className={styles.form}>
                        <Button
                            loading={dataSource.some(item => item.loading)}
                            onClick={onSendAllRequests}
                        >
                            Send all requests
                        </Button>
                    </div>
                    <Table 
                        dataSource={dataSource}
                        columns={columns}
                        rowKey="request"
                        pagination={false}
                    />
                </div>
            </div>
        </>
    )
}
