import React, { useState } from 'react'
import { Flex } from 'druikit'
import { useCategoryValue, useCompetitorValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import { getVendorNameByCode, regionShortMappingFn } from 'utils'
import { cloneDeep } from 'lodash'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Tooltip } from 'antd'
import InfoOutlined from '../InfoOutlined'
import { storage } from 'utils/storage'
import { intersection } from 'utils/array'
import { useFilterCountry } from 'hooks/useFilter'

export const QueryNav = () => {
    const [ regionValue ] = useFilterCountry()
    const [ categoryValue ] = useCategoryValue()
    
    const [ competitorValue ] = useCompetitorValue()
    const [ competitorLabel, setCompetitorLabel ] = useState('')

    useDeepCompareEffect(() => {
        if (competitorValue.length === 0) {
            setCompetitorLabel('')
            return
        }
        if (competitorValue.length === 1) {
            setCompetitorLabel(getVendorNameByCode(competitorValue[0]))
            return
        }
        const vendorNames = competitorValue.map(c => getVendorNameByCode(c))
        setCompetitorLabel(`Competitors: ${vendorNames.join(', ')}`)
    }, [ competitorValue, [] ])

    const [ showCategoryInfo, setShowCategoryInfo ] = useState(false)

    useDeepCompareEffect(() => {
        const categoryTreeList = storage.getCategoryTreeList()
        const isShow = categoryTreeList.some(c => {
            if (categoryValue.includes(c.name) && Array.isArray(c?.list) && intersection(c.list.map(c2 => c2.name), categoryValue)?.length) {
                return true
            }
            return false
        })
        setShowCategoryInfo(isShow)
    }, [ categoryValue, [] ])

    return (
        <Flex 
            alignItems="baseline" 
            fullWidth 
            height={38} 
            backgroundColor="#74B6B6" 
            color="#fff" 
            lineHeight="38px" 
            paddingLeft={34} 
            paddingRight={33}
        >
            <div style={{ fontSize: 21 }}>Dynamic dashboard - {regionShortMappingFn(regionValue)}</div>
            <div 
                style={{ 
                    fontSize: 16, 
                    flex: 1, 
                    overflow: 'hidden',
                    whiteSpace: 'nowrap', 
                    textOverflow: 'ellipsis',
                    height: 38,
                    lineHeight: '38px', 
                }}
            >
                &nbsp; | &nbsp; { cloneDeep(categoryValue).sort().join(', ') }&nbsp; | &nbsp; { competitorLabel }
            </div>
            {
                showCategoryInfo ? (
                    <Tooltip 
                        title="You have selected both a main category and a sub category. As such the metrics that are shown below will only be for the main category as a whole." 
                        mouseEnterDelay={0} 
                        mouseLeaveDelay={0}
                        placement="bottom"
                    >
                        <div style={{ marginInline: 10, display: 'flex', alignItems: 'center' }}>
                            <InfoOutlined
                                size={16}
                                color="#fff"
                            />
                        </div>
                    </Tooltip>
                ) : null
            }
        </Flex>
    )
}
