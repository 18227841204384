import { useCallback, useEffect, useState } from 'react'
import { useFetch } from 'libs/hookRequest'
import { REQUEST_MY_LOOKBOOKS } from 'consts/request'

export const useGetMyLookbooks = () => {
  const [ hasMoreLookbooks ] = useState(false)
  const [ isFetchingMore ] = useState(false)

  const take = 20

  const { data, loading, error, getFn } = useFetch()

  const fetchMoreLookbooks = useCallback(() => {
    getFn(REQUEST_MY_LOOKBOOKS, {})
  }, [getFn]);//eslint-disable-line

  useEffect( () => {
      fetchMoreLookbooks()
  }, []);//eslint-disable-line

  return {
    data: data?.result || undefined,
    loading,
    error,
    hasMoreLookbooks: hasMoreLookbooks || (data ? data?.result.length === take : false),
    isFetchingMore,
    fetchMoreLookbooks,
    refetch: () => getFn(REQUEST_MY_LOOKBOOKS, {}),
  }
}
