import { getCompetitorSellersByRegion } from 'components/layout/Header/components/HeaderFilter/competitor.util'
import { SELECTED_VENDORS } from 'consts'
import { isDashboardExcludeSelectedVendors } from './feConfig'
import { storage } from './storage'

/**
 * Filter > Cateogry 获取全部值
 */
export function getAllFilterCategoryValue() {
    const categoryTreeList = storage.getCategoryTreeList()
    const categories = categoryTreeList.map(item => {
        if (Array.isArray(item.list) && item.list.length) {
            return item.list.map(item2 => item2.name)
        }
        return item.name
    }).flat(10)
    return categories
}

/**
 * Filter > Competitor 获取全部值
 */
export function getAllFilterCompetitorValue(filterCountry: string) {
    const sellers = getCompetitorSellersByRegion({ region: filterCountry, excludeSelf: false })
        .map(item => ({ vendor: item.vendor, region: item.region }))
    if (!isDashboardExcludeSelectedVendors()) {
        sellers.push({ vendor: SELECTED_VENDORS, region: filterCountry })
    }
    return sellers
}
