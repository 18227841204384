import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store.type'
import * as config from 'configs/moduleName'
import { groupsConfig } from 'configs/groups'
import { CATEGORY_TOTAL, ColorMaterialDefaultValue, IS_LIVE_ENV, MARKET_VENDOR, OPTIONS_NAME, SELECTED_VENDORS } from 'consts'
import { cloneDeep, isEqual, sortBy, uniq } from 'lodash'
import { intersection, isSuperArray } from 'utils/array'
import { isFirstDashboardTableAsCategoryViewpoint } from 'utils/feConfig'
import { ModuleDataState } from './moduleDataSlice.interface'
import { storage } from 'utils/storage'
import * as consts from './moduleDataSlice.const'

/**
 * 比较 competitor_sellers, market, groups 的 region 是否相同
 */
export const isDiffCountry = (payload: any) => {
    if (!payload?.data) return false
    const newPayload = cloneDeep(payload)
    
    const {
        competitor_sellers = [],
        customer_seller = {},
        market = [],
    } = newPayload?.data
    const region = customer_seller?.region
    if (
        !region
        || (competitor_sellers?.length && competitor_sellers?.[0]?.region !== region)
        || (market?.length && market?.[0]?.region !== region)
    ) {
        return true
    }
    return false
}

export const isCompetitorCached = (oldCompetitorValue, newCompetitorValue) => {
    const groupList = [ SELECTED_VENDORS, ...groupsConfig.map(itme => itme.name) ]
    if (!intersection(newCompetitorValue, groupList).length) {
        return isSuperArray(oldCompetitorValue, newCompetitorValue)
    }

    if (!oldCompetitorValue.find(item => item === SELECTED_VENDORS) && newCompetitorValue.find(item => item === SELECTED_VENDORS)) {
        return false
    }

    const oldCmptValue = oldCompetitorValue.filter(item => ![ SELECTED_VENDORS ].includes(item))
    const newCmptValue = newCompetitorValue.filter(item => ![ SELECTED_VENDORS ].includes(item))
    return JSON.stringify(oldCmptValue.sort()) === JSON.stringify(newCmptValue.sort())
}

export const getCompetitorValue = (competitorValue: string[], groups: any = {}) => {
    const newCompetitorValue = [ ...competitorValue ]
    if (!Object.keys(groups || {}).length) return newCompetitorValue
    newCompetitorValue.push(...Object.keys(groups || {}))
    return newCompetitorValue
}

/* ************************* methods relate to moduleData ******************************* */
export const isSubsetPayload = ({ 
    newPayload = {}, 
    oldPayload = {}, 
    pageType,
    competitorType,
}: { 
    newPayload: any;
    oldPayload: any;
    pageType?: 'sizeOfLine' | 'priceArchitecture' | 'dashboard' | 'searchForOptions' | 'others',
    competitorType?: 'string' | 'object';
}) => {
    if (competitorType !== 'string' && isDiffCountry(oldPayload)) {
        return false
    }

    /* **************************** special handling for [SIZE OF LINE] ******************************* */
    if (pageType === 'sizeOfLine') {
        if (!oldPayload?.data?.competitors) {
            return
        }

        newPayload = cloneDeep(newPayload)
        const newCompetitorValue = getCompetitorValue(newPayload.data.competitors, newPayload.data.groups)
        delete newPayload.data.competitors
        delete newPayload.data.market
        delete newPayload.data.groups
    
        oldPayload = cloneDeep(oldPayload)
        const oldCompetitorValue = getCompetitorValue(oldPayload.data.competitors, oldPayload.data.groups)
        delete oldPayload.data.competitors
        delete oldPayload.data.market
        delete oldPayload.data.groups

        if (isEqual(newPayload, oldPayload) && isCompetitorCached(oldCompetitorValue, newCompetitorValue)) {
            return true
        }

        return false
    }

    if (pageType === 'priceArchitecture') {
        if (!oldPayload?.data?.competitor_sellers) {
            return false
        }
    
        newPayload = cloneDeep(newPayload)
        newPayload.data.query.categories = sortBy(newPayload.data.query.categories)
        const newCompetitorValue = getCompetitorValue(newPayload.data.competitor_sellers.map(item => item.vendor), newPayload.data.groups)
        delete newPayload.data.competitor_sellers
        delete newPayload.data.market
        delete newPayload.data.groups
        delete newPayload.data.price_gap
    
        oldPayload = cloneDeep(oldPayload)
        oldPayload.data.query.categories = sortBy(oldPayload.data.query.categories)
        const oldCompetitorValue = getCompetitorValue(oldPayload.data.competitor_sellers.map(item => item.vendor), oldPayload.data.groups)
        delete oldPayload.data.competitor_sellers
        delete oldPayload.data.market
        delete oldPayload.data.groups
        delete oldPayload.data.price_gap
    
        if (isEqual(newPayload, oldPayload) && isCompetitorCached(oldCompetitorValue, newCompetitorValue)) {
            return true
        }
    
        return false
    }

    if (pageType === 'dashboard') {
        if (!oldPayload?.data?.competitors) {
            return false
        }
    
        newPayload = cloneDeep(newPayload)
        const newCompetitorValue = getCompetitorValue(newPayload.data.competitors, newPayload.data.groups)
        delete newPayload.data.competitors
        delete newPayload.data.market
        delete newPayload.data.groups
    
        oldPayload = cloneDeep(oldPayload)
        const oldCompetitorValue = getCompetitorValue(oldPayload.data.competitors, oldPayload.data.groups)
        delete oldPayload.data.competitors
        delete oldPayload.data.market
        delete oldPayload.data.groups
    
        if (isEqual(newPayload, oldPayload) && isCompetitorCached(oldCompetitorValue, newCompetitorValue)) {
            return true
        }
    
        return false
    }

    if (pageType === 'searchForOptions') {
        if (!oldPayload?.data?.competitor_sellers) {
            return false
        }
    
        newPayload = cloneDeep(newPayload)
        newPayload.data.query.categories = sortBy(newPayload.data.query.categories)
        const newCompetitorValue = getCompetitorValue(newPayload.data.competitor_sellers.map(item => item.vendor), newPayload.data.groups)
        delete newPayload.data.competitor_sellers
        delete newPayload.data.market
        delete newPayload.data.groups
    
        oldPayload = cloneDeep(oldPayload)
        oldPayload.data.query.categories = sortBy(oldPayload.data.query.categories)
        const oldCompetitorValue = getCompetitorValue(oldPayload.data.competitor_sellers.map(item => item.vendor), oldPayload.data.groups)
        delete oldPayload.data.competitor_sellers
        delete oldPayload.data.market
        delete oldPayload.data.groups
    
        if (isEqual(newPayload, oldPayload) && isCompetitorCached(oldCompetitorValue, newCompetitorValue)) {
            return true
        }
    
        return false
    } 

    if (!oldPayload?.data?.competitor_sellers) {
        return false
    }

    newPayload = cloneDeep(newPayload)
    newPayload.data.query.categories = sortBy(newPayload.data.query.categories)
    const newCompetitorValue = getCompetitorValue(newPayload.data.competitor_sellers.map(item => item.vendor), newPayload.data.groups)
    delete newPayload.data.competitor_sellers
    delete newPayload.data.market
    delete newPayload.data.groups

    oldPayload = cloneDeep(oldPayload)
    oldPayload.data.query.categories = sortBy(oldPayload.data.query.categories)
    const oldCompetitorValue = getCompetitorValue(oldPayload.data.competitor_sellers.map(item => item.vendor), oldPayload.data.groups)
    delete oldPayload.data.competitor_sellers
    delete oldPayload.data.market
    delete oldPayload.data.groups

    if (isEqual(newPayload, oldPayload) && isCompetitorCached(oldCompetitorValue, newCompetitorValue)) {
        return true
    }

    return false
}

export const getOldCompetitorValue = ({
    competitorValue = [],
    market,
    groups,
}: {
    competitorValue: string[];
    market: any;
    groups: any;
}): string[] => {
    const oldCompetitorValue = cloneDeep(competitorValue)
    
    if (!IS_LIVE_ENV && Array.isArray(market) && market?.length > 0) {
        oldCompetitorValue.push(MARKET_VENDOR.vendor)
    }

    if (Object.keys(groups || {}).length > 0) {
        oldCompetitorValue.push(...Object.keys(groups || {}))
    }
    
    return uniq(oldCompetitorValue)?.filter(item => item)?.sort() || []
}

export const getNewCompetitorValue = ({
    competitorValue = [],
}: {
    competitorValue: string[];
}): string[] => {
    const newCompetitorValue = cloneDeep(competitorValue)

    groupsConfig.forEach(group => {
        if (newCompetitorValue.filter(item => item.endsWith(group.suffix)).length > 1) {
            newCompetitorValue.push(group.name)
        }
    })

    return uniq(newCompetitorValue)?.filter(item => item)?.sort() || []
}

/* ************************* Module name ******************************* */
export const PRICING_DASHBOARD_TABLE = 'pricingDashboardTable'
export const PRICING_DASHBOARD_GRAPH = 'pricingDashboardGraph'
export const MARKET_OVERVIEW_TABLE = 'marketOverviewTable'
export const MARKET_OVERVIEW_GRAPH = 'marketOverviewGraph'
export const PRICE_SPREADS_TABLE = 'priceSpreadsTable'
export const PRICE_SPREADS_GRAPH = 'priceSpreadsGraph'
export const IN_OUT_DASHBOARD_TABLE = 'inOutDashboardTable'
export const IN_OUT_DASHBOARD_GRAPH = 'inOutDashboardGraph'
export const IN_OUT_DASHBOARD_CALENDAR = 'inOutDashboardCalendar'
export const IN_OUT_DASHBOARD_WEEKLY_TABLE = 'inOutDashboardWeeklyTable'

export const SEARCH_FOR_OPTIONS_COMPETITOR = 'searchForOptionsCompetitor'
export const SEARCH_FOR_OPTIONS_CATEGORY = 'searchForOptionsCategory'

export const UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE = 'updateFetchPayloadAndOldCompetitorValue'
export const UPDATE_OLDCOMPETITORVALUE = 'updateOldCompetitorValue'
export const UPDATE_NEWCOMPETITORVALUE = 'updateNewCompetitorValue'

/* ************************* Module default data ******************************* */
export const initData: ModuleDataState = {
    [PRICING_DASHBOARD_TABLE]: {
        fetchPayload: {},
        apiData: [],
        oldRegionValue: '',
        competitorValue: [],
        switchValue: true,
    },
    [PRICING_DASHBOARD_GRAPH]: {
        fetchPayload: {},
        apiData: {},
        oldCompetitorValue: [],
        newCompetitorValue: [],
        switchValue: false,
        metricValue: [ OPTIONS_NAME ],
    },
    [MARKET_OVERVIEW_TABLE]: {
        fetchPayload: {},
        apiData: {},
    },
    [MARKET_OVERVIEW_GRAPH]: {
        fetchPayload: {},
        apiData: {},
        categoryValue: CATEGORY_TOTAL,
        oldCompetitorValue: [],
        newCompetitorValue: [],
        switchValue: false,
        metricValue: [],
    },
    [PRICE_SPREADS_TABLE]: {
        fetchPayload: {},
        apiData: {},
    },
    [PRICE_SPREADS_GRAPH]: {
        fetchPayload: {},
        apiData: {},
        categoryValue: CATEGORY_TOTAL,
        oldCompetitorValue: [],
        newCompetitorValue: [],
        switchValue: false,
        metricValue: [],
    },
    [IN_OUT_DASHBOARD_TABLE]: {
        fetchPayload: {},
        apiData: {},
    },
    [IN_OUT_DASHBOARD_GRAPH]: {
        fetchPayload: {},
        apiData: {},
        categoryValue: CATEGORY_TOTAL,
        oldCompetitorValue: [],
        newCompetitorValue: [],
        switchValue: false,
        metricValue: [],
    },
    [config.DYNAMIC_DASHBOARD_NAME]: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
    },

    // Pricing
    [config.PRICE_DRIVERS_TABLE_NAME]: {
        fetchPayload: {},
        apiData: [],
        oldCompetitorValue: [],
        newCompetitorValue: [],
    },
    [config.PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME]: {
        fetchPayload: {},
        apiData: [],
        oldCompetitorValue: [],
        newCompetitorValue: [],
    },
    [config.PRICE_ARCHITECTURE_TABLE_NAME]: {
        fetchPayload: {},
        apiData: [],
        currentRate: {},
        oldCompetitorValue: [],
        newCompetitorValue: [],
        intervalValue: '',
        switchValue: false,
        verticalExpanedKeys: [],        // string[]
        horizontalExpandKeys: [],       // string[]
    },
    [config.DISCOUNTS_TABLE_NAME]: {
        fetchPayload: {},
        apiData: [],
        oldCompetitorValue: [],
        newCompetitorValue: [],
        switchValue: false,
    },
    [config.CROSS_REGION_PRICING_NAME]: {
        fetchPayload: {},
        apiData: {},    
        oldCompetitorValue: [],
        newCompetitorValue: [],
        regionValue: [],            // COUNTRIES dropdown selected value
        priceSampleValue: 'all',    // PRICE SAMPLE dropdown selected value: 'all' | 'identical'
        switchValue: false,         // true is Current，false is Fixed (The price of the average exchange rate over the last six months)
        indexRegion: '',
        indexCurrency: '',
    },

    // Assortment
    [config.CATEGORY_SPLIT_PIE_GRAPH_NAME]: {
        fetchPayload: {},
        apiData: [],    
        oldCompetitorValue: [],
        newCompetitorValue: [],
        categoryValue: [],          // category dropdown selected value, string[]
        switchValue: false,
        expandNamesObj: {},
    },
    [config.COLOR_SPLIT_PIE_GRAPH_NAME]: {
        fetchPayload: {},
        apiData: [],    
        oldCompetitorValue: [],
        newCompetitorValue: [],
        colorRangeValue: 'All colors',
        switchValue: false,
    },
    [config.MATERIAL_SPLIT_PIE_GRAPH_NAME]: {
        fetchPayload: {},
        apiData: [],    
        oldCompetitorValue: [],
        newCompetitorValue: [],
        material: { ...ColorMaterialDefaultValue },
        switchValue: false,
        isLimitMaterial: false,
    },
    [config.PRODUCT_RANKING_TABLE_NAME]: {
        fetchPayload: {},
        apiData: {},    
        oldCompetitorValue: [],
        newCompetitorValue: [],
    },
    [config.ATTRIBUTE_RANKING_TABLE_NAME]: {
        fetchPayload: {},
        apiData: {},
        oldCompetitorValue: [],
        newCompetitorValue: [],
        rangeValue: '150',
    },
    [config.SIZE_OF_LINE_TABLE_NAME]: {
        fetchPayload: {},
        apiData: {},    
        oldCompetitorValue: [],
        newCompetitorValue: [],
        competitorValue: [],
        genderValue: [],
        regionValue: '',
        switchValue: true,
    },
    [config.OPTIONS_IN_OUT_CALENDAR_NAME]: {
        fetchPayload: {},
        apiData: {},
        oldCompetitorValue: [],
        newCompetitorValue: [],
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
    },
    [config.WEEKLY_TABLE_NAME]: {
        fetchPayload: {},
        apiData: {},
        newCompetitorValue: [],
    },

    // Lookbooks
    [config.SEARCH_FOR_OPTIONS_NAME]: {
        showOldProducts: false,
        multiGender: false,
        multiMetaCategory: false,
        historicalSizeFilter: [],
    },
    [SEARCH_FOR_OPTIONS_COMPETITOR]: {
        fetchPayload: {},
        apiData: {},    
        oldCompetitorValue: [],
        newCompetitorValue: [],
    },
    [SEARCH_FOR_OPTIONS_CATEGORY]: {
        fetchPayload: {},
        apiData: {},    
        oldCompetitorValue: [],
    },

    // Dashboard
    [config.PRICE_INSIGHT_TABLE_NAME]: {
        fetchPayload: {},
        // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/832
        isCompetitorView: !isFirstDashboardTableAsCategoryViewpoint(),
        expandedRowKeys: [],        // string[]
        switchLoading: false,
    },
    [config.ASSORTMENT_INSIGHT_TABLE_NAME]: {
        fetchPayload: {},
        isCompetitorView: true,
        expandedRowKeys: [],        // string[]
        switchLoading: false,
    },
    [config.PRICE_SPREADS_TABLE_NAME]: {
        fetchPayload: {},
        isCompetitorView: true,
        expandedRowKeys: [],        // string[]
        switchLoading: false,
    },
    [config.IN_OUT_MONITORING_TABLE_NAME]: {
        fetchPayload: {},
        isCompetitorView: true,
        expandedRowKeys: [],        // string[]
        switchLoading: false,
    },
    [consts.MOST_POPULAR_LAUNCH_DAYS_MODULE_NAME]: {
        apiData: {},
        apiPayload: {},
        oldCompetitorValue: [],
        newCompetitorValue: [],
    },
    [consts.OPTIONS_IN_OUT_BY_MONTH_MODULE_NAME]: {
        apiData: {},
        apiPayload: {},
        oldCompetitorValue: [],
        newCompetitorValue: [],
    },
    [consts.SOLD_OUT_VENDORS_TABLE]: {
        isCompetitorView: true,
        switchLoading: false,
        vendorValue: [ storage.getCustomerVendor() ],
        metricValue: [ '<S sold out', 'S sold out' ],
        isMultipleSizes: true,
        fetchPayload: {},
        graphApiPayload: {},
        graphApiData: {},
        graphApiLoading: false,
        graphCategoryValue: [],
        tmpVendorValue: [ storage.getCustomerVendor() ],
        tmpMetricValue: [ '<S sold out', 'S sold out' ],
        tmpGraphCategoryValue: [],
        tmpIsMultipleSizes: true,
    },
    [config.CROSS_REGION_SOLD_OUT_NAME]: {
        isCompetitorView: true,
        vendorValue: [ storage.getCustomerVendor() ],
        metricValue: [ 'Germany sold out' ],
        isMultipleRegions: true,
        fetchPayload: {},
        graphApiPayload: {},
        graphApiData: {},
        graphApiLoading: false,
        graphCategoryValue: [],
        tmpVendorValue: [ storage.getCustomerVendor() ],
        tmpMetricValue: [ 'Germany sold out' ],
        tmpGraphCategoryValue: [],
        tmpIsMultipleRegions: true,
    },
    [consts.GRAPH_BUTTON_MODULE_NAME]: {
        apiData: {},
        apiPayload: {},
        apiLoading: false,
        switchValue: false,
        categoryValue: [],
        metricValue: [ 'Average price' ],
        competitorValue: [],
        regionValue: '',
        dateRangeValue: '',
        tmpCategoryValue: [],
        tmpMetricValue: [ 'Average price' ],
        tmpCompetitorValue: [],
        tmpRegionValue: '',
        tmpDateRangeValue: '',
        tmpSwitchValue: false,
        isFilterChanged: false,
    },
    [consts.PLATFORM_ANALYTICS_MODULE_NAME]: {
        tableApiData: {},
        tableApiPayload: {},
        tableApiLoading: false,
        rankingApiData: {},
        rankingApiPayload: {},
        rankingApiLoading: false,
        rankingModalVisible: false,
        modalApiData: {},
        modalApiPayload: {},
        modalApiLoading: false,
        modalVisible: false,
        modalAttachData: {
            region: '',
            categoryName: '',
            count: 0,
        },
        shopList: [],
        shopOptions: [],
        graphMetricValue: [],
        graphApiData: {},
        graphApiPayload: {},
        graphApiLoading: false,
        graphModalVisible: false,
        graphModalChartData: [],
        graphModalPriceScaleVisibleRange: undefined,
        graphModalPriceScaleMaxRange: undefined,
        isCompetitorView: true,
        competitorValue: [ 'Calvin Klein@zalandodeep', 'Tommy Hilfiger@zalandodeep' ],
    },
    [consts.CSV_DATA_MODULE_NAME]: {
        [consts.CSV_DATA_DYNAMIC_DASHBOARD]: '',
        [consts.CSV_DATA_PRICE_INSIGHTS]: '',
        [consts.CSV_DATA_ASSORTMENT_INSIGHTS]: '',
        [consts.CSV_DATA_PRICE_SPREADS]: '',
        [consts.CSV_DATA_IN_OUT_MONITORING]: '',
        [consts.CSV_DATA_MOST_POPULAR_LAUNCH_DAYS]: '',
        [consts.CSV_DATA_OPTIONS_IN_OUT_BY_MONTH]: '',
        [consts.CSV_DATA_PRICE_DRIVERS_INTRA_PERIOD]: '',
        [consts.CSV_DATA_PRICE_DRIVERS_PERIOD_TO_PERIOD]: '',
        [consts.CSV_DATA_PRICE_ARCHITECTURE]: '',
        [consts.CSV_DATA_DISCOUNTS]: '',
        [consts.CSV_DATA_CROSS_REGION_PRICING]: '',
        [consts.CSV_DATA_SOLD_OUT_ANALYTICS]: '',
        [consts.CSV_DATA_SOLD_OUT_CROSS_REGION]: '',
        [consts.CSV_DATA_CATEGORY_SPLIT]: '',
        [consts.CSV_DATA_COLOR_SPLIT]: '',
        [consts.CSV_DATA_MATERIA_SPLIT]: '',
        [consts.CSV_DATA_PRODUCT_RANKING_LAST_WEEK]: '',
        [consts.CSV_DATA_ATTRIBUTE_RANKING]: '',
        [consts.CSV_DATA_SIZE_OF_LINE]: '',
        [consts.CSV_DATA_MARKETPLACE_SELLER_ANALYTICS]: '',
    },
}

export const moduleDataSlice = createSlice({
    name: consts.SLICE_NAME,
    initialState: initData,
    reducers: {
        updateFetchPayloadAndOldCompetitorValue(state, action) {
            state[action.payload.moduleName].fetchPayload = action.payload.payload
            state[action.payload.moduleName].oldCompetitorValue = getOldCompetitorValue({
                competitorValue: action.payload.payload?.data?.competitor_sellers?.map(item => item.vendor) || [],
                market: action.payload.payload?.data?.market,
                groups: action.payload.payload?.data?.groups,
            })
        },
        updateFetchPayload(state, action) {
            state[action.payload.moduleName].fetchPayload = action.payload.payload
        },
        updateApiData(state, action) {
            state[action.payload.moduleName].apiData = action.payload.payload
        },
        updateModuleData(state, action) {
            if (action.payload.payload?.type === UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE) {
                const payload = action.payload.payload?.payload || {}
                state[action.payload.moduleName].fetchPayload = { ...payload }
                state[action.payload.moduleName].oldCompetitorValue = getOldCompetitorValue({
                    competitorValue: payload?.data?.competitors || payload?.data?.competitor_sellers?.map(item => item.vendor) || [],
                    market: payload?.data?.market,
                    groups: payload?.data?.groups,
                })
                return
            }
            if (action.payload.payload?.type === UPDATE_OLDCOMPETITORVALUE) {
                state[action.payload.moduleName].oldCompetitorValue = getOldCompetitorValue(action.payload.payload?.payload || {})
                return
            }
            if (action.payload.payload?.type === UPDATE_NEWCOMPETITORVALUE) {
                const newCompetitorValue = getNewCompetitorValue(action.payload.payload?.payload || {})
                state[action.payload.moduleName].newCompetitorValue = newCompetitorValue
                return
            }
            state[action.payload.moduleName] = {
                ...state[action.payload.moduleName],
                ...action.payload.payload,
            }
        },
        updateOldCompetitorValue(state, action) {
            /**
             * action.payload.payload = {
             *      competitorValue: string[];
             *      customerVendor: string;
             *      market: any;
             *      groups: any;
             * }
             */
            state[action.payload.moduleName].oldCompetitorValue = getOldCompetitorValue(action.payload.payload)
        },
        updateNewCompetitorValue(state, action) {
            /**
             * action.payload.payload = {
             *      competitorValue: string[];
             *      customerVendor?: string;
             * }
             */
            state[action.payload.moduleName].newCompetitorValue = getNewCompetitorValue(action.payload.payload)
        },
        updateModuleData2(state, action) {
            state[action.payload.moduleName] = {
                ...state[action.payload.moduleName],
                ...action.payload.payload,
            }
        },
    },
})

export const {
    updateFetchPayloadAndOldCompetitorValue,
    updateFetchPayload,
    updateApiData,
    updateOldCompetitorValue,
    updateNewCompetitorValue,
    updateModuleData,
    updateModuleData2,
} = moduleDataSlice.actions

export const selectModuleData = (state) => state.module

export const getPricingDashboardTableData = (state: RootState) => state.module[PRICING_DASHBOARD_TABLE]
export const getPricingDashboardGraphData = (state: RootState) => state.module[PRICING_DASHBOARD_GRAPH]
export const getMarketOverviewTableData = (state: RootState) => state.module[MARKET_OVERVIEW_TABLE]
export const getMarketOverviewGraphData = (state: RootState) => state.module[MARKET_OVERVIEW_GRAPH]
export const getPriceSpreadsGraphData = (state: RootState) => state.module[PRICE_SPREADS_GRAPH]
export const getInOutDashboardTableData = (state: RootState) => state.module[IN_OUT_DASHBOARD_TABLE]
export const getInOutDashboardGraphData = (state: RootState) => state.module[IN_OUT_DASHBOARD_GRAPH]
export const getDynamicDashboardData = (state: RootState) => state.module[config.DYNAMIC_DASHBOARD_NAME]

// Dashboard
export const getPriceInsightTableData = (state: RootState) => state.module[config.PRICE_INSIGHT_TABLE_NAME]
export const getAssortmentInsightTableData = (state: RootState) => state.module[config.ASSORTMENT_INSIGHT_TABLE_NAME]
export const getPriceSpreadsTableData = (state: RootState) => state.module[config.PRICE_SPREADS_TABLE_NAME]
export const getInOutMonitoringTableData = (state: RootState) => state.module[config.IN_OUT_MONITORING_TABLE_NAME]
export const getSoldOutVendorsTableData = (state: RootState) => state.module[consts.SOLD_OUT_VENDORS_TABLE]

// Pricing
export const getPriceHistoryTableData = (state: RootState) => state.module[config.PRICE_DRIVERS_TABLE_NAME]
export const getPriceDriversPeriodToPeriodTableData = (state: RootState) => state.module[config.PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME]
export const getPriceArchitectureTableData = (state: RootState) => state.module[config.PRICE_ARCHITECTURE_TABLE_NAME]
export const getDiscountsTableData = (state: RootState) => state.module[config.DISCOUNTS_TABLE_NAME]
export const getCrossRegionPricingData = (state: RootState) => state.module[config.CROSS_REGION_PRICING_NAME]
export const getCrossRegionSoldOutData = (state: RootState) => state.module[config.CROSS_REGION_SOLD_OUT_NAME]

// Assortment
export const getCategorySplitPieGraphData = (state: RootState) => state.module[config.CATEGORY_SPLIT_PIE_GRAPH_NAME]
export const getColorSplitPieGraphData = (state: RootState) => state.module[config.COLOR_SPLIT_PIE_GRAPH_NAME]
export const getMaterialSplitPieGraphData = (state: RootState) => state.module[config.MATERIAL_SPLIT_PIE_GRAPH_NAME]
export const getProductRankingTableData = (state: RootState) => state.module[config.PRODUCT_RANKING_TABLE_NAME]
export const getAttributeRankingTableData = (state: RootState) => state.module[config.ATTRIBUTE_RANKING_TABLE_NAME]
export const getSizeOfLineTableData = (state: RootState) => state.module[config.SIZE_OF_LINE_TABLE_NAME]
export const getInOutDashboardCalendarData = (state: RootState) => state.module[config.OPTIONS_IN_OUT_CALENDAR_NAME]
export const getInOutDashboardWeeklyTableData = (state: RootState) => state.module[config.WEEKLY_TABLE_NAME]

// Lookbooks
export const getSearchForOptionsData = (state: RootState) => state.module[config.SEARCH_FOR_OPTIONS_NAME]
export const getSearchForOptionsCompetitorData = (state: RootState) => state.module[SEARCH_FOR_OPTIONS_COMPETITOR]
export const getSearchForOptionsCategoryData = (state: RootState) => state.module[SEARCH_FOR_OPTIONS_CATEGORY]

export default moduleDataSlice.reducer
