import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { CheckProps } from './types'

export const CheckIcon = ({ color }: CheckProps) =>
    <svg className={classnames(styles.check,'material-filter')} style={color ? { fill: color } : {}} viewBox="0 0 448.8 448.8">
        <g id="check">
            <polygon points="142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55 		" />
        </g>
    </svg>

