import { cloneDeep } from 'lodash'
import { arrayUtils, numberUtils, stringUtils } from 'norna-uikit'
import { IS_LIVE_ENV } from 'consts'
import { getValueByQueryDateValid } from 'utils/pageUtils'
import { getFilterColorList } from 'utils/lockSellerUtils'
import { Colors, getVendorNameByCode } from 'utils'
import { handleColorOpacity } from 'utils/colorJson'

/**
 * 处理接口返回的数据结构, 改造成页面需要的数据结构
 * 多个地方会用到, 因此提出为公共函数
 */
export function handleApiData({
    apiData,
}: {
    apiData: any[];
}) {
    // 对 vendor 进行排序
    const result = [ ...apiData ]

    // 2022/03/01 新需求，'others', 'multi-color' 在 live 隐藏，在非 live 环境显示
    // 2022/06/14 新需求, 'multi-color' 在 live 显示, 'others' 在 live 依然不显示
    // 2022/06/17 新需求, 'others' 也在 live 显示
    const hideInLive: string[] = []
    const pieData: any = result.map(item => {
        const { queryDateValid, queryComparisonDateValid } = item.seller
        item.bucket_assortments = item.bucket_assortments
            .map(item => {
                if (item.name.toLowerCase() === 'multi-color') {
                    item.name = stringUtils.capitalize(item.name)
                }
                return item
            })
            .filter(item => {
                if (IS_LIVE_ENV) {
                    return !hideInLive.map(item => (item || '').toString().toLowerCase()).includes(item.name.toLowerCase())
                }
                return true
            })
        let list = [ ...item.bucket_assortments ]
        const others = list.find(item2 => item2.name.toLowerCase() === 'other')
        if (others) {
            list = list.filter(item2 => item2.name.toLowerCase() !== 'other')
            list.push(others)
        }

        /**
         * 2022/08/08
         * percent 需要保证是 100% 
         */
        if (list.length > 0) {
            // 从第2项开始将 percent 累和
            const othersSum = arrayUtils.fsum(list.slice(1).map(i => i.percent.current_value))
            // 用 1 减去累和值将差值补到第一项的 percent 中
            list[0].percent.current_value = numberUtils.subtract(1, othersSum)
        }

        if (list.length > 0) {
            // 从第2项开始将 percent 累和
            const othersSum = arrayUtils.fsum(list.slice(1).map(i => i.percent.comparison_value))
            // 用 1 减去累和值将差值补到第一项的 percent 中
            list[0].percent.comparison_value = numberUtils.subtract(1, othersSum)
        }

        list = list.map(item2 => {
            let countCurrentValue = numberUtils.formatNumberByComma(item2.count.current_value)
            countCurrentValue = getValueByQueryDateValid(countCurrentValue, queryDateValid)

            let countChangePercentage = (item2.count.change_percentage * 100).toFixed(1) + '%'
            countChangePercentage = getValueByQueryDateValid(countChangePercentage, queryComparisonDateValid)

            let percentCurrentValue = (item2.percent.current_value * 100).toFixed(1) + '%'
            percentCurrentValue = getValueByQueryDateValid(percentCurrentValue, queryDateValid)

            let percentChange = (item2.percent.change * 100).toFixed(1) + '%'
            percentChange = getValueByQueryDateValid(percentChange, queryComparisonDateValid)

            let averagePriceCurrentValue = item2.average_price.current_value
            averagePriceCurrentValue = getValueByQueryDateValid(averagePriceCurrentValue, queryDateValid)

            let averagePriceChangePercentage = (item2.average_price.change_percentage * 100).toFixed(1) + '%'
            averagePriceChangePercentage = getValueByQueryDateValid(averagePriceChangePercentage, queryComparisonDateValid)

            return {
                ...item2,
                backgroundColor: Colors[item2.name],
                label: stringUtils.capitalize(item2.name),
                prod: item2.products,
                /**
                 * disable 控制
                 * 产品数量大于0时，鼠标悬浮样式为手状，表示可点击
                 * 产品数量为0时，修改鼠标悬浮样式为 auto，表示不可点击
                 */
                disabled: !item2?.count.current_value,
                countCurrentValue,
                countChangePercentage,
                percentCurrentValue,
                percentChange,
                averagePriceCurrentValue,
                averagePriceChangePercentage,
            }
        })

        const unitList = list.map(item2 => ({
            ...item2,
            currentValue: item2.countCurrentValue,
            changePercentage: item2.countChangePercentage,
        }))

        const percentList = list.map(item2 => ({
            ...item2,
            currentValue: item2.percentCurrentValue,
            changePercentage: item2.percentChange,
        }))

        let datasets = [
            {
                label: item.bucket_assortments.map(item2 => item2.name),
                data: item.bucket_assortments.map(item2 => (item2?.percent.current_value || 0) * 100),
                backgroundColor: item.bucket_assortments.map(item2 => handleColorOpacity(Colors[item2.name], 0.7)),
                hoverBackgroundColor: item.bucket_assortments.map(item2 => handleColorOpacity(Colors[item2.name], 1)),
                borderColor: Array(item.bucket_assortments.length).fill('rgba(255, 255, 255, 1)'),
                borderWidth: 1,
            },
        ]
        if (item?.seller?.isLock) {
            const colorList = getFilterColorList()
            item.bucket_assortments = colorList.slice(0, 11).map(c => ({ name: c }))
            datasets = [
                {
                    label: item.bucket_assortments.map(item2 => item2.name),
                    data: item.bucket_assortments.map(() => 1),
                    backgroundColor: item.bucket_assortments.map(item2 => handleColorOpacity(Colors[item2.name], 0.7)),
                    hoverBackgroundColor: item.bucket_assortments.map(item2 => handleColorOpacity(Colors[item2.name], 1)),
                    borderColor: Array(item.bucket_assortments.length).fill('rgba(255, 255, 255, 1)'),
                    borderWidth: 1,
                },
            ]
        }

        return {
            charts: {
                datasets,
            },

            list,
            percentList,
            unitList,
            num: item.num_products,
            seller: item.seller,
        }
    })
    return pieData
}

/**
 * 处理 MultiColor
 * 
 * Switch 设置为 false 页面上不显示 Multi-color
 * 1. bucket_assortments 要过滤掉 multi-color 选项
 * 2. num_products 总数目要减去 multi-color 的数目
 * 3. bucket_assortments 中其它选项值的百分比要重新计算
 */
export function handleMultiColor({
    apiData,
    multiColorVisible,
}: {
    apiData: any[];
    multiColorVisible: boolean;
}) {
    apiData = cloneDeep(apiData)

    if (multiColorVisible === true) {
        return apiData
    }

    const newApiData = apiData.map(item => {
        const multiColor = item.bucket_assortments.find(item2 => item2.name === 'Multi-color')
        if (multiColor) {
            const multiColorCount = multiColor.count.current_value
            // 第2步逻辑完成
            item.num_products -= multiColorCount
            // 第1步逻辑完成
            item.bucket_assortments = item.bucket_assortments.filter(item2 => item2.name !== 'Multi-color')
            // 除去 Multi-color 后剩余颜色值总数目, 后面要重新计算百分比
            const totalCount = item.bucket_assortments.reduce((curr, next) => curr + next.count.current_value, 0)
            const comparisonTotalCount = item.bucket_assortments.reduce((curr, next) => curr + next.count.comparison_value, 0)
            // 第3步逻辑完成
            item.bucket_assortments = item.bucket_assortments.map(item2 => {
                const currentPercent = totalCount === 0 ? 0 : Number(item2.count.current_value / totalCount)
                const comparisonPercent = totalCount === 0 ? 0 : Number(item2.count.comparison_value / comparisonTotalCount)
                const change = currentPercent - comparisonPercent
                const changePercentage = currentPercent === 0 ? 0 : change / currentPercent
                item2.percent = {
                    current_value: Number(currentPercent.toFixed(3)),
                    comparison_value: Number(comparisonPercent.toFixed(3)),
                    change,
                    change_percentage: changePercentage,
                }
                return item2
            })
        }
        return item
    })
    return newApiData
}

/**
 * 过滤数据
 */
export function handleFilterData({
    data = [],
    categoryValue,
}: {
    data: any[];
    categoryValue: string[];
}) {
    let newData = cloneDeep(data)
    newData = newData.map(item => {
        item.bucket_assortments = item.bucket_assortments.filter(item2 => categoryValue.includes(item2.name))
        return item
    })
    return newData
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    switchValue = false,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        'Vendor',
        'Color',
        'Split %',
        '+/- % Units',
        'Average price',
        '+/- %',
    ]
    data.push(titleRow)

    dataSource.forEach(item => {
        const list = switchValue ? item?.unitList : item?.percentList
        const vendorName = getVendorNameByCode(item?.seller?.vendor)
        
        list.forEach(item2 => {
            const d = [
                vendorName,
                item2?.name.replace(',', ' '),
                switchValue ? item2?.countCurrentValue?.replace(',', '') : item2?.percentCurrentValue,
                switchValue ? item2?.countChangePercentage : item2?.percentChange,
                numberUtils.formatNumber(item2?.averagePriceCurrentValue, { decimal: 0 }),
                item2?.averagePriceChangePercentage,
            ]
            data.push(d)
        })
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
