import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoadingObj } from 'features/filters/filtersSlice'
import { AsyncFnType, UseFetchResType, UseFetchReturnType, UseFetchsResType, UseFetchsReturnType } from './types'
import * as Request from './request'
import { cloneDeep } from 'lodash'

const sleep = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('')
    }, timeout)
  })
}

export const useFetch: () => UseFetchReturnType = () => {
  const dispatch = useDispatch()
  const [ resData, setResData ] = useState<UseFetchResType>({
    data: null, loading: false, error: null,
  })

  const disabledLoadingPathList = [
    '/collections',
    'save_filter/',
    '/collection',
    '/convert_currency',
    '/get_mask_tag/',
    '/myLookbooks',
    'dashboard_get_target_group_region_currency/',
    '/qa/fe_authentication/',
    '/image_server_request',
  ]

  const setLoading = (path, loading) => {
    if (disabledLoadingPathList.includes(path)) return
    dispatch(setLoadingObj({ loadingObj: { [path]: loading } }))
  }

  const fetchFn: AsyncFnType = useCallback(async (path, arg, method) => {
    try {
      setResData({
        ...resData,
        loading: true,
      })
      setLoading(path, true)
      let res = await Request[`${method}Fn`](path, arg.variables || arg)

      if (res?.code === Request.ConstCode.NETWORK_ERROR && (res?.retry || 1) < 4) {
        await sleep(1000)  // 延时 1s 再发送
        const d = cloneDeep(arg.variables || arg)
        d.retry = (res?.retry || 1) + 1
        res = await Request[`${method}Fn`](path, d)

        if (res?.code === Request.ConstCode.NETWORK_ERROR && (res?.retry || 1) < 4) {
          await sleep(1000)  // 延时 1s 再发送
          const d = cloneDeep(arg.variables || arg)
          d.retry = (res?.retry || 1) + 1
          res = await Request[`${method}Fn`](path, d)
        }
      }

      /**
       * 取消重复请求，一定一定不能更改 loading 状态
       * 
       * 之前容易出现的问题：页面加载动画结束后几秒，页面数据才会更新
       * 
       * 原因分析：
       * 有的页面一进入页面会发多次相同的请求，原因是参数初始化需要时间；
       * 1. 第一个请求发出去，loading 会设置为 true
       * 2. 第一个请求还未拿到响应，参数初始化完成，触发 useEffect 又会发一个第二个相同的请求，此时 loading 又一次被设置为 true
       * 3. 第一个请求拿到响应，loading 设置为 false
       * 4. 第二个请求拿到响应，loading 设置为 false
       * 第2步到第4步是接口请求服务端过程，本应有加载动画的，但先前处理逻辑在第3步（第一个请求拿到响应）就关闭加载动画了
       * 第3步和第4步谁先拿到响应也是不确定的，因为请求是异步的，ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/191
       * 
       * 解决方法：
       * 1. 第二个请求发出时，如果第一个相同的请求未拿到响应，将第一个请求干掉
       * 2. 第一个请求如果是被取消掉的，一定不要更改 loading 为 false，因为第二个请求不管是成功还是异常最后都会设置 loading 为 false 形成逻辑闭环
       */
      if (res?.code === 0) {
        return { ...resData }
      }

      const newResData = {
        ...resData,
        loading: false,
        data: res.data,
      }
      setLoading(path, false)
      setResData(newResData)
      
      return newResData
    } catch (e) {
      console.error('e', e)
      const res = {
        ...resData,
        error: e,
        loading: false,
      }
      setLoading(path, false)
      setResData(res)
      return res
    }
    // eslint-disable-next-line
  }, [resData]);
  return {
    getFn: async (path, arg) => await fetchFn(path, arg || {}, 'get'),   //eslint-disable-line
    postFn: async (path, arg) => await fetchFn(path, arg, 'post'), //eslint-disable-line
    delFn: async (path, arg) => await fetchFn(path, arg, 'delete'),//eslint-disable-line
    putFn: async (path, arg) => await fetchFn(path, arg, 'put'),   //eslint-disable-line
    patchFn: async (path, arg) => await fetchFn(path, arg, 'patch'),   //eslint-disable-line

    ...resData,
    setData: data => {
      setResData({ ...resData, data })
    },
  }
}
/**
 * use requests
 */
export const useFetchs: () => UseFetchsReturnType = () => {
  const initData: UseFetchResType = { data: null, loading: false, error: null }

  const [ resData, setResData ] = useState<UseFetchsResType>({
    path: initData,
  })

  const request: AsyncFnType = useCallback(async (path, arg, method) => {
    const initData: UseFetchResType = { ...resData[path] || {} }
    try {
      initData.loading = true
      setResData({
        ...resData,
        [path]: initData,
      })
      const res = await Request[`${method}Fn`]?.(path, arg.variables || arg)
      initData.loading = false
      initData.data = res.data
      initData.error = null
      const methodRes = {
        // ...resData,
        [path]: initData,
      }
      setResData(pre => ({
        ...pre,
        ...methodRes,
      }))
    } catch (e) {
      initData.error = e
      initData.loading = false
      setResData({
        ...resData,
        [path]: initData,
      })
    }
    return {
      ...resData,
      [path]: initData,
    }
  }, [ resData ])

  return {
    getFn: async (path, arg = {}) => await request(path, arg || {}, 'get'),   //eslint-disable-line
    postFn: async (path, arg) => await request(path, arg, 'post'), //eslint-disable-line
    delFn: async (path, arg) => await request(path, arg, 'delete'),//eslint-disable-line
    putFn: async (path, arg) => await request(path, arg, 'put'),   //eslint-disable-line
    patchFn: async (path, arg) => await request(path, arg, 'patch'),   //eslint-disable-line
    ...resData,
  } as UseFetchsReturnType
}
