import React, { useEffect, useState, CSSProperties, ReactNode } from 'react'
import styles from './styles.module.scss'

export const DashboardDoughnutChart = ({
    radius = 70,
    value = 0,      // 0 - 100
    content,
}: {
    radius?: number;
    value?: number;
    content?: ReactNode;
}) => {
    const diameter = radius * 2

    const [ rotateObj, setRotateObj ] = useState({ left: 134, right: -135 })

    useEffect(() => {
        const angel = 3.6 * (value > 100 ? 100 : value)
        if (angel <= 180) {
            setRotateObj({
                left: 134,
                right: angel - 135,
            })
        } else {
            setRotateObj({
                left: angel - 180 + 135,
                right: 45,
            })
        }
    }, [ value ])

    const wrapperSty: CSSProperties = {
        width: radius + 'px', 
        height: diameter + 'px',
    }

    const circleSty: CSSProperties = {
        width: diameter + 'px', 
        height: diameter + 'px',
    }

    return (
        <div className={styles.chartWrapper} style={{ ...circleSty }}>
            {
                value > 0 ? (
                    <>
                        <div 
                            style={{ ...wrapperSty }}
                            className={styles.left}
                        >
                            <div
                                style={{ ...circleSty, transform: `rotateZ(${rotateObj.left}deg)` }}
                                className={styles.leftCircle} 
                            />
                        </div>
                        <div
                            style={{ ...wrapperSty }} 
                            className={styles.right}
                        >
                            <div
                                style={{ ...circleSty, transform: `rotateZ(${rotateObj.right}deg)` }}
                                className={styles.rightCircle}
                            />
                        </div>
                    </>
                ) : null
            }
            <div className={styles.innerCircle}>
                {content}
            </div>
            <div className={styles.outerCircle} />
        </div>
    )
}
