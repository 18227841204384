import React, { useContext } from 'react'
import LoadingBar from 'react-top-loading-bar'
import { ProgressBarContext } from './ProgressBarContext'

export const ProgressBar = () => {
  const { setLoadingBar } = useContext(ProgressBarContext)

  return (
      <LoadingBar
          height={3}
          color="#ec7765"
          onRef={ref => {
          if (ref) {
            setLoadingBar(ref)
          }
        }}
      />
  )
}
