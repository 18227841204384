import React from 'react'
import classnames from 'classnames'
import styles from 'components/typography/Title/styles.module.scss'
import { TitleProps } from 'components/typography/Title/types'

export const Title = ({ children, xxxl, xxl, xl, secondary, uppercase, style = {} }: TitleProps) => 
    <h1
        style={style}
        className={classnames(
        styles.title,
        xxxl && styles.xxxl,
        xxl && styles.xxl,
        xl && styles.xl,
        secondary && styles.secondary,
        uppercase && styles.uppercase,
      )}
    >
        {children}
    </h1>

