import { useFetch } from 'libs/hookRequest'
import { REQUEST_LOOKBOOK_REMOVE_PRODUCT } from 'consts/request'

export const useRemoveLookbookProduct = () => {
  const { patchFn: removeLookbookProduct, data, loading, error } = useFetch()

  return {
    removeLookbookProduct: async (productId: string, id: string) => {
      const res = await removeLookbookProduct(REQUEST_LOOKBOOK_REMOVE_PRODUCT, { query: { product_id: productId, id } })
      return res
    }
    ,
    data,
    loading,
    error,
  }
}
