export enum SpeakerEnum {
    questioner = 'questioner',
    responder = 'responder',
    error = 'error',
    loading = 'loading',
}

export enum ChatTypeEnum {
    gemini = 'gemini',
    gpt = 'gpt',
}
