import React, { CSSProperties, Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'
import { logoMapper } from 'componentsv2/VendorLogo'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { RectArrow } from 'componentsv2/RectArrow'
import { CaretIcon } from 'assets/icons/Caret'
import { InlineLoading } from 'assets/icons'
import { useRegionOptions } from 'hooks/useOptions'
import { regionShortMappingFn } from 'utils'
import { formatVendorName } from 'utils/sellers'
import { NA, OPTIONS_NAME } from 'consts'
import { DisplayText } from 'componentsv2/DisplayText'
import { useModuleData } from 'hooks/useModuleData'
import { PRICE_ARCHITECTURE_TABLE_NAME } from 'configs'
import { Flex } from 'componentsv2/Flex'
import { useFilterCountry } from 'hooks/useFilter'
import { ComparisonTooltipV2 } from 'componentsv2/business/ComparisonTooltip'
import { SizedBox } from 'componentsv2/SizedBox'
import { VendorProps } from './types'
import styles from './styles.module.scss'
import { LockIcon } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'

let dragVendor

const rowHeight = 47
const othersRegionWidth = 143
const lastRegionDistance = 15
const firstRegionWidth = 310
const otherRegionWidth = 143
const priceSummaryHeight = 70
const optionsTopDistance = 10

export const Vendor = ({
    dataSource,
    onHorizontalExpanded,
    onClick,
    onDrag,
    onlyShowHeader = false,
    hideHeader = false,
    onlyShowRegion = false,
    hideRegion = false,
    style = {},
}: {
    dataSource: VendorProps;
    onHorizontalExpanded?: (expaneded: boolean, vendor: string) => void;
    onClick?: (opt: any) => void;
    onDrag?: (dragVendor, dropVendor) => void;
    onlyShowHeader?: boolean;
    hideHeader?: boolean;
    onlyShowRegion?: boolean;
    hideRegion?: boolean;
    style?: CSSProperties;
}) => {
    const onDragStart = e => {
        dragVendor = e.currentTarget.getAttribute('data-vendor')
    }

    const onDragOver = e => {
        e.preventDefault()
    }

    const onDrop = e => {
        const dropVendor = e.currentTarget.getAttribute('data-vendor')
        if (dragVendor === dropVendor) return
        onDrag?.(dragVendor, dropVendor)
    }

    /* *************************** lazy load ********************************** */
    const vendorRef: any = React.createRef<HTMLElement>()

    const headerStyle: CSSProperties = {}
    if (onlyShowHeader === true) {
        headerStyle.width = (firstRegionWidth + dataSource.regions.slice(1).length * otherRegionWidth) - 10 + 'px'
    }

    /**
     * 2022/08/11
     * 有的 license 如 Joyshop 只有一个可用 region, 
     * 水平箭头的目的是查看其它 region 数据
     * 此时显示右箭头也没有意义, 直接隐藏掉
     */
    const { regionOptions } = useRegionOptions()
    const showHorizontalExpandIcon = regionOptions.filter(item => item.isDisabled === false).length > 1

    let header = (
        <div
            className={
                classnames({
                    [styles.vendorLogo]: true,
                    [styles.deepBorderRight]: dataSource.expanded,
                })
            }
            style={headerStyle}
            draggable
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            data-vendor={dataSource.vendor}
        >
            <ShouldRender shouldRender={logoMapper[dataSource.vendor]}>
                <div className={styles.vendorLogoWrapper}>{logoMapper[dataSource.vendor] || dataSource.name}</div>
                <div className={styles.vendorPlatformName}>{formatVendorName(dataSource.name).platform}</div>
            </ShouldRender>
            <ShouldRender shouldRender={!logoMapper[dataSource.vendor]}>
                <div className={styles.vendorLogoWrapper}>{formatVendorName(dataSource.name).name}</div>
                <div className={styles.vendorPlatformName}>{formatVendorName(dataSource.name).platform}</div>
            </ShouldRender>
        </div>
    )
    if (onlyShowHeader === false) {
        header = (
            <div style={{ padding: '16px 16px 16px 0px' }}>
                {header}
            </div>
        )
    } else {
        header = (
            <div style={{ padding: '16px 0px 0px 0px' }}>
                {header}
            </div>
        )
    }

    let othersRegionsWidth = 0
    if (dataSource.regions.length > 1 && dataSource.expanded === true) {
        othersRegionsWidth = dataSource.regions.slice(1).length * othersRegionWidth + lastRegionDistance
    }
    const vendorWrapperWidth = othersRegionsWidth + firstRegionWidth

    const [ innerExpanded, setInnerExpanded ] = useState<boolean>(dataSource.expanded)

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>
        if (dataSource.expanded === false) {
            timer = setTimeout(() => {
                setInnerExpanded(false)
            }, 300)
        } else {
            setInnerExpanded(true)
        }
        return () => {
            clearTimeout(timer)
        }
    }, [ dataSource.expanded ])

    return (
        <div ref={vendorRef} className={styles.vendor} style={{ ...style, minWidth: vendorWrapperWidth + 'px' }}>
            <div className="blockClass">
                {!hideHeader ? header : null}
                <ShouldRender shouldRender={onlyShowHeader === false || onlyShowRegion === true}>
                    <div className={classnames([ styles.vendorRegionWrap ])}>
                        <VendorRegion
                            className={styles.firstVendor}
                            dataSource={dataSource.regions[0]}
                            expanded={dataSource.expanded}
                            showExpanedIcon={showHorizontalExpandIcon}
                            onHorizontalExpanded={async () => {
                                if (dataSource.expanded !== innerExpanded) return
                                await onHorizontalExpanded?.(!dataSource.expanded, dataSource.vendor)
                            }}
                            onClick={onClick}
                            hideRegion={hideRegion}
                            onlyShowRegion={onlyShowRegion}
                        />
                        <main style={{ display: 'flex', width: othersRegionsWidth + 'px', transition: 'width .5s', overflow: 'hidden' }}>
                            {
                                dataSource.regions.length > 1 && (dataSource.expanded ? true : innerExpanded) && dataSource.regions.slice(1).map((item, index) => (
                                    <VendorRegion
                                        className={styles.otherRegion}
                                        key={dataSource?.vendor + item?.region}
                                        dataSource={item}
                                        showExpanedIcon={false}
                                        onClick={onClick}
                                        hideRegion={hideRegion}
                                        onlyShowRegion={onlyShowRegion}
                                        isLastRegion={index === dataSource.regions.length - 2}
                                    />
                                ))
                            }
                        </main>
                    </div>
                </ShouldRender>
            </div>
        </div>
    )
}

const getLockPositions = ({
    parentList = [],
    verticalExpanedKeys = [],
}) => {
    const lockPositions: number[] = []
    let horiStartY = 0
    let horiEndY = 0
    let height = 0
    
    parentList.forEach((item, index) => {
        if (verticalExpanedKeys.includes(parentList?.[index])) {
            horiEndY = height + rowHeight
            lockPositions.push(horiStartY + (horiEndY - horiStartY) / 2)
            height += 6 * rowHeight
            horiStartY = height
        } else {
            height += rowHeight
        }
    })

    horiEndY = height + 69
    lockPositions.push(horiStartY + (horiEndY - horiStartY) / 2)
    
    return lockPositions
}

export const VendorRegion = ({
    dataSource,
    expanded = false,
    showExpanedIcon = false,
    className,
    style = {},
    onHorizontalExpanded,
    onClick,
    hideRegion = false,
    onlyShowRegion = false,
    isLastRegion = false,
}: {
    dataSource: any;
    expanded?: boolean;
    showExpanedIcon?: boolean;
    className?: string;
    style?: CSSProperties;
    onHorizontalExpanded?: () => void;
    onClick?: (opt: any) => void;
    hideRegion?: boolean;
    onlyShowRegion?: boolean;
    isLastRegion?: boolean;
}) => {
    const [ moduleData ] = useModuleData(PRICE_ARCHITECTURE_TABLE_NAME)
    const {
        switchValue = false,
        verticalExpanedKeys = [],
    } = moduleData

    const [ loading, setLoading ] = useState(false)

    const [ filterCountry ] = useFilterCountry()
    const isFirstRegion = filterCountry === dataSource.region
    const isLock = dataSource.isLock
    const parentList = dataSource?.data?.map(item => item.name)
    const lockPositions = getLockPositions({ parentList, verticalExpanedKeys })

    return (
        <div
            className={classnames({
                [className || '']: true,
                [styles.lastRegion]: isLastRegion,
            })}
            style={{ position: 'relative', ...style }}
        >
            <ShouldRender shouldRender={!hideRegion || onlyShowRegion}>
                <div className={styles.regionWrap}>
                    <Flex
                        alignItems="center"
                        className={classnames({
                            [styles.firstRegionCurrentValue]: isFirstRegion,
                            [styles.otherRegionCurrentValue]: !isFirstRegion,
                        })}
                    >
                        <div
                            className={classnames({
                                [styles.regionName]: true,
                            })}
                        >
                            {regionShortMappingFn(dataSource.region)}
                        </div>
                        <ShouldRender shouldRender={showExpanedIcon}>
                            <div
                                className={styles.arrowWrap}
                                onClick={async () => {
                                    if (loading === true) return
                                    setLoading(true)
                                    await onHorizontalExpanded?.()
                                    setLoading(false)
                                }}
                            >
                                <ShouldRender shouldRender={loading === false}>
                                    <CaretIcon
                                        className={expanded === true ? styles.arrowLeft : styles.arrowRight}
                                        color="#74b6b6"
                                    />
                                </ShouldRender>
                                <ShouldRender shouldRender={loading === true}>
                                    <InlineLoading sty={{ width: '15px', height: '15px' }} />
                                </ShouldRender>
                            </div>
                        </ShouldRender>
                    </Flex>
                    {
                        isFirstRegion ? (
                            <div className={styles.firstRegionChange}>
                                <ComparisonTooltipV2 width={140} height={38}>
                                    <div style={{ width: '140px', height: '38px', lineHeight: '38px' }}>
                                        vs comparison period
                                    </div>
                                </ComparisonTooltipV2>
                            </div>
                        ) : (
                            <div
                                className={classnames({
                                    [styles.otherRegionChange]: !isFirstRegion,
                                    [styles.deepBorderRight]: isLastRegion,
                                })}
                                style={{ height: '38px' }}
                            />
                        )
                    }
                </div>
                <Flex alignItems="center" style={{ height: '30px' }}>
                    <div
                        className={classnames({
                            [styles.firstRegionCurrentValue]: isFirstRegion,
                            [styles.otherRegionCurrentValue]: !isFirstRegion,
                        })}
                    />
                    <div
                        className={classnames({
                            [styles.firstRegionChange]: isFirstRegion,
                            [styles.otherRegionChange]: !isFirstRegion,
                            [styles.deepBorderRight]: isLastRegion,
                        })}
                        style={{ fontSize: '12px' }}
                    >
                        +/-&nbsp;<span className={styles.colorPrimary}>%</span>&nbsp;{switchValue ? '' : 'units'}
                    </div>
                </Flex>
            </ShouldRender>

            <ShouldRender shouldRender={!onlyShowRegion && isLock}>
                <div style={{ position: 'relative' }}>
                    {
                        dataSource.data.map((item, index) => {
                            const key = String(item.countCurrentValue) + String(item.percentageCurrentValue) + String(index)
                            let childrenHeight = 0
                            if (verticalExpanedKeys.includes(item.name) && Array.isArray(item.children) && item.children?.length > 0) {
                                childrenHeight = item.children.length * rowHeight
                            }
                            const expanded = verticalExpanedKeys.includes(item.name) && Array.isArray(item.children) && item.children?.length > 0

                            return (
                                <Fragment key={key}>
                                    <div
                                        className={classnames({
                                            [styles.valueItem]: true,
                                            [styles.firstValueItem]: isFirstRegion,
                                            [styles.othersValueItem]: !isFirstRegion,
                                            [styles.lastRegion]: isLastRegion,
                                        })}
                                    >
                                        <div className={styles.currentValueColumn} />
                                        <div
                                            className={classnames({
                                                [styles.changeColumn]: true,
                                                [styles.deepBorderRight]: isLastRegion,
                                            })}
                                        />
                                        {
                                            isLastRegion ? (
                                                <div className={styles.lastRegionDistance} />
                                            ) : null
                                        }
                                    </div>
                                    <main 
                                        style={{ 
                                            transition: 'height .5s', 
                                            height: childrenHeight + 'px', 
                                            overflow: 'hidden', 
                                            width: '100%', 
                                            position: 'relative', 
                                        }}
                                    >
                                        <ShouldRender shouldRender={expanded}>
                                            {
                                                item?.children?.map((item2, index2) => {
                                                    const key = String(item2.countCurrentValue) + String(item2.percentageCurrentValue) + String(index2)

                                                    return (
                                                        <div
                                                            key={key}
                                                            className={classnames({
                                                                [styles.valueItem]: true,
                                                                [styles.verticalChildren]: true,
                                                                [styles.verticalBorderTop]: index2 === 0,   // 子表格上面加一条线
                                                                [styles.verticalBorderBottom]: item.children && index2 === item.children?.length - 1,   // 子表格下面加一条线
                                                                [styles.firstValueItem]: isFirstRegion,
                                                                [styles.othersValueItem]: !isFirstRegion,
                                                                [styles.lastRegion]: isLastRegion,
                                                            })}
                                                        >
                                                            <div
                                                                className={classnames({
                                                                    [styles.currentValueColumn]: true,
                                                                    [styles.firstChildRange]: index2 === 0,
                                                                })}
                                                            />
                                                            <div
                                                                className={classnames({
                                                                    [styles.changeColumn]: true,
                                                                    [styles.deepBorderRight]: isLastRegion,
                                                                    [styles.firstChildRange]: index2 === 0,
                                                                })}
                                                            />
                                                            {
                                                                isLastRegion ? (
                                                                    <div className={styles.lastRegionDistance} />
                                                                ) : null
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </ShouldRender>
                                        <div className={classnames({ 
                                            [styles.lockWrapper]: true,
                                            [styles.lockWrapperOfFirstRegion]: isFirstRegion,
                                            [styles.lockWrapperOfLastRegion]: isLastRegion, 
                                        })}>
                                            <LockIcon size={20} />
                                        </div>
                                    </main>
                                </Fragment>
                            )
                        })
                    }
                    <Flex
                        className={classnames({
                            [styles.valueItem]: true,
                            [styles.firstValueItem]: isFirstRegion,
                            [styles.othersValueItem]: !isFirstRegion,
                            [styles.borderBottom]: true,
                            [styles.lastRegion]: isLastRegion,
                        })}
                        style={{ height: priceSummaryHeight + 'px' }}
                    >
                        <div className={styles.currentValueColumn} />
                        <div
                            className={classnames({
                                [styles.changeColumn]: true,
                            })}
                            style={{ fontSize: '12px', paddingLeft: '0px', paddingTop: '35px', textAlign: 'center' }}
                        />
                        {
                            isLastRegion ? (
                                <div className={styles.lastRegionDistance} />
                            ) : null
                        }
                    </Flex>
                    {
                        !verticalExpanedKeys?.length && (
                            <div className={classnames({ 
                                [styles.lockWrapper]: true,
                                [styles.lockWrapperOfFirstRegion]: isFirstRegion,
                                [styles.lockWrapperOfLastRegion]: isLastRegion, 
                            })}>
                                <LockIcon size={20} />
                            </div>
                        )
                    }
                    {
                        !!verticalExpanedKeys?.length && lockPositions.map((top, index) => {
                            return (
                                <div
                                    key={index.toString()} 
                                    className={classnames({ 
                                        [styles.lockWrapper]: true,
                                        [styles.lockWrapperOfFirstRegion]: isFirstRegion,
                                        [styles.lockWrapperOfLastRegion]: isLastRegion, 
                                    })}
                                    style={{ top: (top - 0) + 'px' }}
                                >
                                    <LockIcon size={20} />
                                </div>
                            )
                        })
                    }
                </div>
                
                <SizedBox type="block" height={optionsTopDistance} />

                <div style={{ position: 'relative' }}>
                    <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                        &nbsp;
                    </Flex>
                    <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                        &nbsp;
                    </Flex>
                    <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                        &nbsp;
                    </Flex>
                    <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                        &nbsp;
                    </Flex>
                    <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                        &nbsp;
                    </Flex>
                    <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                        &nbsp;
                    </Flex>
                    <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                        <div className={classnames([ styles.currentValueColumn, styles.normalBg ])} />
                        <div className={classnames([ styles.changeColumn, styles.normalBg ])} />
                        {
                            isLastRegion ? (
                                <div className={classnames([ styles.lastRegionDistance, styles.normalBg ])} />
                            ) : null
                        }
                    </Flex>
                    <div className={classnames({ 
                        [styles.lockWrapper]: true,
                        [styles.lockWrapperOfFirstRegion]: isFirstRegion,
                        [styles.lockWrapperOfLastRegion]: isLastRegion, 
                    })}>
                        <LockIcon size={20} />
                    </div>
                </div>
                <div className={classnames({ 
                    [styles.verticalDashedLine]: true,
                    [styles.verticalDashedLineOfFirstRegion]: isFirstRegion,
                    [styles.verticalDashedLineOfLastRegion]: isLastRegion, 
                })} />
            </ShouldRender>

            <ShouldRender shouldRender={!onlyShowRegion && !isLock}>
                {
                    dataSource.data.map((item, index) => {
                        const currentValue = switchValue ? item.countCurrentValue : item.percentageCurrentValue
                        const changeValue = switchValue ? item.countChangePercentage : item.percentageChange
                        const key = String(item.countCurrentValue) + String(item.percentageCurrentValue) + String(index)
                        const disabled = (item.countCurrentValue === '0' && item.percentageCurrentValue === '0.0%') || currentValue === NA
                        let childrenHeight = 0
                        if (verticalExpanedKeys.includes(item.name) && Array.isArray(item.children) && item.children?.length > 0) {
                            childrenHeight = item.children.length * rowHeight
                        }

                        return (
                            <Fragment key={key}>
                                <div
                                    className={classnames({
                                        [styles.valueItem]: true,
                                        [styles.firstValueItem]: isFirstRegion,
                                        [styles.othersValueItem]: !isFirstRegion,
                                        [styles.lastRegion]: isLastRegion,
                                    })}
                                    onClick={() => {
                                        if (disabled) return
                                        onClick?.({ ...item })
                                    }}
                                >
                                    <div className={styles.currentValueColumn}>
                                        {
                                            isFirstRegion ? (
                                                <RectArrow
                                                    value={currentValue}
                                                    percent={Number(item.percentageCurrentValue?.replace('%', ''))}
                                                    disabled={disabled}
                                                    highlight={dataSource.active}
                                                />
                                            ) : (
                                                <div>{currentValue}</div>
                                            )
                                        }
                                    </div>
                                    <div
                                        className={classnames({
                                            [styles.changeColumn]: true,
                                            [styles.normalBg]: !isFirstRegion,
                                            [styles.deepBorderRight]: isLastRegion,
                                        })}
                                    >
                                        <DisplayText value={changeValue} showArrow fontSize={12} />
                                    </div>
                                    {
                                        isLastRegion ? (
                                            <div className={styles.lastRegionDistance} />
                                        ) : null
                                    }
                                </div>
                                <main style={{ transition: 'height .5s', height: childrenHeight + 'px', overflow: 'hidden', width: '100%' }}>
                                    <ShouldRender shouldRender={verticalExpanedKeys.includes(item.name) && Array.isArray(item.children) && item.children?.length > 0}>
                                        {
                                            item?.children?.map((item2, index2) => {
                                                const currentValue = switchValue === true ? item2.countCurrentValue : item2.percentageCurrentValue
                                                const changeValue = switchValue ? item2.countChangePercentage : item2.percentageChange
                                                const key = String(item2.countCurrentValue) + String(item2.percentageCurrentValue) + String(index2)
                                                const disabled = (item2.countCurrentValue === '0' && item2.percentageCurrentValue === '0.0%') || currentValue === NA

                                                return (
                                                    <div
                                                        key={key}
                                                        className={classnames({
                                                            [styles.valueItem]: true,
                                                            [styles.verticalChildren]: true,
                                                            [styles.verticalBorderTop]: index2 === 0,   // 子表格上面加一条线
                                                            [styles.verticalBorderBottom]: item.children && index2 === item.children?.length - 1,   // 子表格下面加一条线
                                                            [styles.firstValueItem]: isFirstRegion,
                                                            [styles.othersValueItem]: !isFirstRegion,
                                                            [styles.lastRegion]: isLastRegion,
                                                        })}
                                                        onClick={() => {
                                                            if (disabled) return
                                                            onClick?.({ ...item2 })
                                                        }}
                                                    >
                                                        <div
                                                            className={classnames({
                                                                [styles.currentValueColumn]: true,
                                                                [styles.firstChildRange]: index2 === 0,
                                                            })}
                                                        >
                                                            {
                                                                isFirstRegion ? (
                                                                    <RectArrow
                                                                        value={currentValue}
                                                                        percent={Number(item2.percentageCurrentValue?.replace('%', ''))}
                                                                        disabled={disabled}
                                                                        highlight={dataSource.active}
                                                                        style={{ height: '26px' }}
                                                                    />
                                                                ) : (
                                                                    <div>{currentValue}</div>
                                                                )
                                                            }
                                                        </div>
                                                        <div
                                                            className={classnames({
                                                                [styles.changeColumn]: true,
                                                                [styles.normalBg]: !isFirstRegion,
                                                                [styles.deepBorderRight]: isLastRegion,
                                                                [styles.firstChildRange]: index2 === 0,
                                                            })}
                                                        >
                                                            <DisplayText value={changeValue} showArrow />
                                                        </div>
                                                        {
                                                            isLastRegion ? (
                                                                <div className={styles.lastRegionDistance} />
                                                            ) : null
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </ShouldRender>
                                </main>
                            </Fragment>
                        )
                    })
                }
                <Flex
                    className={classnames({
                        [styles.valueItem]: true,
                        [styles.firstValueItem]: isFirstRegion,
                        [styles.othersValueItem]: !isFirstRegion,
                        [styles.borderBottom]: true,
                        [styles.lastRegion]: isLastRegion,
                    })}
                    style={{ height: priceSummaryHeight + 'px' }}
                >
                    <div className={styles.currentValueColumn} />
                    <div
                        className={classnames({
                            [styles.changeColumn]: true,
                        })}
                        style={{ fontSize: '12px', paddingLeft: '0px', paddingTop: '35px', textAlign: 'center' }}
                    >
                        +/-&nbsp;<span className={styles.colorPrimary}>%</span>
                    </div>
                    {
                        isLastRegion ? (
                            <div className={styles.lastRegionDistance} />
                        ) : null
                    }
                </Flex>
                <SizedBox type="block" height={optionsTopDistance} />
                <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                    <div className={styles.currentValueColumn}>
                        {dataSource.totalCurrentValue}
                    </div>
                    <div className={styles.changeColumn}>
                        <DisplayText value={dataSource.totalChangePercentage} showArrow fontSize={12} />
                    </div>
                </Flex>
                <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                    <div className={styles.currentValueColumn}>
                        {dataSource.avgPriceCurrentValue}
                    </div>
                    <div className={styles.changeColumn}>
                        <DisplayText value={dataSource.avgPriceChangePercentage} showArrow fontSize={12} />
                    </div>
                </Flex>
                <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                    <div className={styles.currentValueColumn}>
                        {dataSource.medianPriceCurrentValue}
                    </div>
                    <div className={styles.changeColumn}>
                        <DisplayText value={dataSource.medianPriceChangePercentage} showArrow fontSize={12} />
                    </div>
                </Flex>
                <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                    <div className={styles.currentValueColumn}>
                        {dataSource.frequentPriceCurrentValue}
                    </div>
                    <div className={styles.changeColumn}>
                        <DisplayText value={dataSource.frequentPriceChangePercentage} showArrow fontSize={12} />
                    </div>
                </Flex>
                <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                    <div className={styles.currentValueColumn}>
                        {dataSource.minPriceCurrentValue}
                    </div>
                    <div className={styles.changeColumn}>
                        <DisplayText value={dataSource.minPriceChangePercentage} showArrow fontSize={12} />
                    </div>
                </Flex>
                <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                    <div className={styles.currentValueColumn}>
                        {dataSource.maxPriceCurrentValue}
                    </div>
                    <div className={styles.changeColumn}>
                        <DisplayText value={dataSource.maxPriceChangePercentage} showArrow fontSize={12} />
                    </div>
                </Flex>
                <Flex className={classnames([ styles.valueItem, isFirstRegion ? styles.firstValueItem : styles.othersValueItem ])}>
                    <div className={classnames([ styles.currentValueColumn, styles.normalBg ])}>
                        {dataSource.index}
                    </div>
                    <div className={classnames([ styles.changeColumn, styles.normalBg ])} />
                    {
                        isLastRegion ? (
                            <div className={classnames([ styles.lastRegionDistance, styles.normalBg ])} />
                        ) : null
                    }
                </Flex>
            </ShouldRender>
        </div>
    )
}

export const VendorFirstColumn = ({
    dataSource,
    verticalExpanedKeys = [],
    onVerticalExpaned,
}: {
    dataSource: VendorProps[];
    verticalExpanedKeys: string[];
    onVerticalExpaned?: (value: string) => void;
}) => {
    if (dataSource.length === 0 || dataSource[0].regions.length === 0) {
        return null
    }

    return (
        <div className={styles.vendorFirstColumn}>
            {
                dataSource[0].regions[0].data.map((item, index) => {
                    const className = [ styles.valueItemWrap ]
                    const style: CSSProperties = {}
                    if (item.name.endsWith('-')) {
                        style.paddingLeft = '30px'
                    }

                    let childrenHeight = 0
                    if (Array.isArray(item?.children) && item?.children?.length > 0 && verticalExpanedKeys.includes(item.name)) {
                        childrenHeight = item?.children.length * rowHeight
                    }

                    return (
                        <Fragment key={item.name}>
                            <div className={classnames(className)} style={{ paddingLeft: '0px' }}>
                                <ShouldRender shouldRender={Array.isArray(item?.children) && item.children?.length > 1}>
                                    <div className={styles.arrowWrap} onClick={() => onVerticalExpaned?.(item.name)}>
                                        <CaretIcon
                                            className={verticalExpanedKeys.includes(item.name) ? styles.arrowTop : styles.arrowBottom}
                                            color="#74b6b6"
                                        />
                                    </div>
                                </ShouldRender>
                                <div
                                    className={styles.valueItem}
                                    style={style}
                                >
                                    {(item.name)}
                                </div>
                            </div>
                            <main style={{ transition: 'height .5s', height: childrenHeight + 'px', width: '100%', overflow: 'hidden' }}>
                                <ShouldRender shouldRender={Array.isArray(item?.children) && item?.children?.length > 0 && verticalExpanedKeys.includes(item.name)}>
                                    {
                                        item.children?.map((item2, index2) => {
                                            // className
                                            const className = [ styles.valueItem, styles.verticalChildren ]
                                            if (index2 === 0) {
                                                className.push(styles.verticalBorderTop)
                                            }
                                            if (item.children && index2 === item.children?.length - 1) {
                                                className.push(styles.verticalBorderBottom)
                                            }
                                            return (
                                                <div
                                                    className={classnames(className)}
                                                    style={{ paddingLeft: '30px' }}
                                                    key={item2.name}
                                                >{item2.name}
                                                </div>
                                            )
                                        })
                                    }
                                </ShouldRender>
                            </main>
                        </Fragment>
                    )
                })
            }
            <div className={classnames([ styles.valueItemWrap, styles.borderBottom ])} style={{ fontWeight: 'bold', color: '#000', height: priceSummaryHeight + 'px' }}>
                Price summary
            </div>
            <SizedBox type="block" height={optionsTopDistance} />
            <div className={styles.valueItemWrap}>
                {OPTIONS_NAME}
            </div>
            <div className={styles.valueItemWrap}>
                Average price
            </div>
            <div className={styles.valueItemWrap}>
                Median price
            </div>
            <div className={styles.valueItemWrap}>
                Most frequent price
            </div>
            <div className={styles.valueItemWrap}>
                Min price
            </div>
            <div className={styles.valueItemWrap}>
                Max price
            </div>
            <div className={classnames([ styles.valueItemWrap, styles.normalBg, styles.priceIndex ])}>
                Price index
            </div>
        </div>
    )
}
