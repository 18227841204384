import React, { useState } from 'react'
import { Flex } from 'druikit'
import { useApiData, useApiLoading, useDynamicDashboardRequests, usePovValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { handleCardDataSource, handleLineChartData } from 'pages/DynamicDashboard/utils'
import { useFetchDashboardProducts, useFetchGraphData } from 'pages/DynamicDashboard/hooks/useApi'
import { MetricBarChartCard, MetricCard, MetricLineChartCard, MetricProductCard, percentFormat } from '../MetricCard'
import { MetricNav } from '../MetricNav'
import { useFilterCurrency } from 'hooks/useFilter'
import { numberUtils } from 'norna-uikit'

export const ModalAssortmentMix = () => {
    const [ apiData ] = useApiData()
    const [ apiLoading ] = useApiLoading()
    const [ povValue ] = usePovValue()
    const [ currency ] = useFilterCurrency()
    const [ cardDataSource, setCardDataSource ] = useState<any[]>([])
    const [ assortmentSplitLineChartData, setAssortmentSplitLineChartData ] = useState<any[]>([])
    const [ marketIndexBarChartData, setMarketIndexBarChartData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const metricList = [ 'Assortment split', 'Average price', 'Market index' ]
        const cardDataSource = handleCardDataSource({ apiData, metricList, currency, customerVendor: povValue })
        if (cardDataSource?.[1]?.dataSource?.[2]?.description) {
            cardDataSource[1].dataSource[2].description = ''
            cardDataSource[2].dataSource[2].description = ''
        }
        setCardDataSource([ ...cardDataSource ])

        const marketIndexBarChartData = cardDataSource.map(item => {
            return {
                label: item.title,
                value: item.dataSource?.[2]?.value?.replace(',','')?.replace('%', ''),
            }
        })
        setMarketIndexBarChartData(marketIndexBarChartData)
    }, [ apiData, currency, povValue, {} ])

    const [ requests ] = useDynamicDashboardRequests()
    const {
        assortmentSplitLineGraphApiData: lineChartData,
        assortmentSplitLineGraphApiLoading: lineChartLoading,

        assortmentProductsApiData: products,
        assortmentProductsApiLoading: productsLoading,
    } = requests

    /* ************************** Assortment split graph 线图 ******************************* */
    useFetchGraphData('Split')

    useDeepCompareEffect(() =>  {
        if (!lineChartData) return
        const assortmentSplitLineChartData = handleLineChartData(lineChartData, 'Split', povValue)
        setAssortmentSplitLineChartData([ ...assortmentSplitLineChartData ])
    }, [ lineChartData, povValue, {} ])

    /* ************************** 产品卡片 ******************************* */
    useFetchDashboardProducts('All')

    // 测试数据
    // const d = [ 
    //     { label:'2024-02-05','pme-legend':0,albertoshop:0,Market:55 },
    //     { label:'2024-02-06','pme-legend':0,albertoshop:0,Market:2.6 },
    //     { label:'2024-02-07','pme-legend':0,albertoshop:0,Market:2.6 },
    //     { label:'2024-02-08','pme-legend':0,albertoshop:0,Market:2.6 },
    //     { label:'2024-02-09','pme-legend':0,albertoshop:0,Market:2.6 },
    // ]

    return (
        <>
            <MetricNav 
                name="Assortment mix"
                metric="Assortment mix"
            />
            <Flex justifyContent="space-between">
                {
                    cardDataSource.map(item => {
                        return (
                            <MetricCard
                                style={{ width: '32%' }}
                                loading={apiLoading}
                                key={item?.title || ''}
                                title={item?.title || ''}
                                dataSource={item?.dataSource || []}
                                showUnits={item.showUnits}
                            />
                        )
                    })
                }
            </Flex>
            <Flex justifyContent="space-between" id="dynamic-dashboard-middle-graph">
                <MetricBarChartCard
                    style={{ width: '38%' }}
                    loading={apiLoading}
                    title="Market index"
                    description="The assortment split of yourself divided by the assortment split of the Market. If the assortment split of a selected category is 5% for yourself and 10% for the Market, the Market index will be 50%, meaning that the selected category is only half as dominant within your own full assortment as it is within the assortment of the Market, including yourself."
                    dataSource={marketIndexBarChartData}
                    yAxisFormatter={percentFormat}
                />
                <MetricLineChartCard
                    style={{ width: '60%' }}
                    loading={lineChartLoading}
                    title="Assortment split over time"
                    dataSource={assortmentSplitLineChartData}
                    yAxisFormatter={value => numberUtils.formatNumber(value, { isCommaSymbol: true, isPercentSymbol: true, decimal: 1 })}
                    tooltipFormatter={value => numberUtils.formatNumber(value, { isCommaSymbol: true, isPercentSymbol: true, decimal: 1 })}
                />
            </Flex>
            <Flex justifyContent="space-between">
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} most popular options`}
                    dataSource={products.customerVendorProducts || []}
                    count={products.customerVendorCount}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} most popular options`}
                    dataSource={products.comparisonVendorProducts || []}
                    count={products.comparisonVendorCount}
                />
            </Flex>
        </>
    )
}
