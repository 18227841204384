import React, { FC, useState, useEffect, useLayoutEffect } from 'react'
import classnames from 'classnames'
import { Spin } from 'componentsv2/Spin'
import { SizedBox } from 'componentsv2/SizedBox'
import { LICENSE_BIMBA, PRODUCT_CARD_COLORS, PRODUCT_CARD_TYPE } from 'consts'
import { lsGet } from 'utils/ls'
import { useSelector } from 'react-redux'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { useWindowZoom } from 'hooks/useWindow'
import productStyles from 'components/Product/styles.module.scss'
import { setMarginTopByPageContainerMarginTop } from 'componentsv2/MenuBar/utils'
import { useProductSizesDetailsData } from 'hooks/api/useProductSizesDetailsData'
import * as cssConfig from 'configs/css'
import { Descriptions } from 'componentsv2/business/ProductCard/components/Descriptions'
import { ProductImageWrapper } from 'componentsv2/business/ProductCard/components/ProductImage'
import { ProductTitle } from 'componentsv2/business/ProductCard/components/ProductTitle'
import { ProductPriceRangeV2 } from 'componentsv2/business/ProductCard/components/ProductPrice'
import { ProductDateForSizePrice } from 'componentsv2/business/ProductCard/components/ProductDate'
import { ProductCardProps, ProductObj } from 'componentsv2/business/ProductCard/types'
import { formatColorObj, getTitle, handleSizePriceData } from 'componentsv2/business/ProductCard/utils'
import styles from './styles.module.scss'
import { ProductHeader } from 'componentsv2/business/ProductCard/components/ProductHeader'
import { PriceLineChart } from 'componentsv2/business/ProductCard/components/PriceLineChart'
import { FullPriceLineChart } from 'componentsv2/business/ProductCard/components/FullPriceLineChart'
import { Title } from 'componentsv2/business/ProductCard/components/Title'
import { SellerPerSize } from 'componentsv2/business/ProductCard/components/SellerPerSize'
import { Collapse } from 'componentsv2/business/ProductCard/components/Collapse'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { regionShortMappingFn } from 'utils'
import { formatPublishPrice } from './utils'
import { storage } from 'utils/storage'
import { numberUtils } from 'norna-uikit'

export const SizePriceProductCard: FC<ProductCardProps & { priceDetail: any[]; indexRegion: string }> = ({
    nornaid,
    toCurrency,
    closeProductDetails,
    priceDetail = [],
    indexRegion,
}) => {
    const zoom = useWindowZoom()
    const { notShowSubGroupsMap = {}, account = {} } = useSelector(getCacheData)
    const customerVendor = storage.getCustomerVendor()
    // 当前登录的 license
    const customerSellerVendor = account?.company?.code
    // 最近一天产品有数据的日期
    const latestDate = lsGet('latestDate') || ''

    // 所有产品，ProductDto 接口返回的产品数据结构，格式：{'nornaid1': ProductDto, 'nornaid2': ProductDto}
    const [ productObj, setProductObj ] = useState<ProductObj>({})
    // 当前显示的产品 ProductVo 页面上展示的产品数据结构
    const [ product, setProduct ] = useState<any>()

    // 根据 nornaid 查询产品接口
    // const { data: productList, loading } = useFetchProductDetail(nornaid, false, '', toCurrency, enabledQa)
    const { data: productList, loading } = useProductSizesDetailsData({ nornaid, toCurrency })

    // 数据结构做处理
    useEffect(() => {
        if (!Array.isArray(productList) || productList.length === 0) {
            return
        }

        const productListHandledOthers = productList.map(item => {
            // 只处理当前页面显示的 nornaid
            if (item.nornaid === nornaid) {
                // nornaid = '1'
                // item.peer_products = { '1': {display_color: 'Yellow', color_percent: {'Yellow': 1}}, '2': {display_color: 'Others', color_percent: {'Others': 1}}} }}
                // Object.keys(item.peer_products) = ['1', '2']
                Object.keys(item.peer_products).forEach(peerNornaid => {
                    const selfColor = item.peer_products[peerNornaid].display_color
                    if (selfColor === 'Others') {
                        // 过滤操作, 获取其它 nornaid 列表
                        productList.filter(i => i.nornaid !== nornaid)
                            .some(i2 => {
                                // othersColorList = ['Blue']
                                const othersColor = i2.peer_products[peerNornaid]
                                if (othersColor && othersColor.display_color && othersColor.display_color !== 'Others') {
                                    item.peer_products[peerNornaid] = othersColor
                                    return true
                                } 
                                return false
                            })
                    }
                })
            }
            return item
        })

        const newProductList = productListHandledOthers.map(item => {
            Object.keys(item.peer_products).forEach(nornaid => {
                // 2022/07/05 新改动, 颜色值逻辑都在后端处理, 数据结构变为
                // {peer_products: {nornaid1: { display_color: 'Black', color_percent: {Black: 1} }}}
                const color = item.peer_products[nornaid].display_color
                item.peer_products[nornaid] = {
                    rgb_color: PRODUCT_CARD_COLORS[color] || color,
                    quantity: 1,
                    name: formatColorObj(item.peer_products[nornaid].color_percent),
                }
            })
            return item
        })
        const nornaidList = newProductList.map(item => item.nornaid)
        let peerProducts = {}
        newProductList.forEach(item => {
            peerProducts = { ...peerProducts, ...item.peer_products }
        })
        newProductList.forEach(item => {
            item.peer_products = peerProducts
        })

        // 接口返回的产品列表转对象数据结构
        const productObj = newProductList.reduce((res, current) => {
            if (current?.peer_products) {
                // delete peerProducts which nornaid could not fetch product data
                Object.keys(current?.peer_products).forEach(nornaid => {
                    if (!nornaidList.includes(nornaid)) {
                        delete current.peer_products[nornaid]
                    }
                })
            }
            res[current.nornaid] = current
            return res
        }, {})
        setProductObj(productObj)
        // 页面展示的产品数据结构
        const productVo = handleSizePriceData({
            productDto: newProductList[0], latestDate, productObj, notShowSubGroupsMap,
        })
        setProduct(productVo)
    }, [ productList, latestDate, notShowSubGroupsMap, customerSellerVendor, nornaid ])

    useLayoutEffect(() => {
        setMarginTopByPageContainerMarginTop(productStyles.quickView)
    }, [])

    return (
        <Spin spinning={loading} className={styles.wrapper} style={{ justifyContent: 'center', overflowX: 'hidden' }}>
            <ProductHeader onClose={closeProductDetails} />
            <div className={classnames([ styles.body, 'norna-container-fixed-width' ])} onClick={e => e.stopPropagation()} data-type={PRODUCT_CARD_TYPE}>
                <ProductImageWrapper 
                    images={product?.images || []}
                    originalUrl={product?.originalUrl}
                    url={product?.url}
                    vendor={product?.vendor}
                    nornaid={nornaid}
                    enabledQa={false}
                />

                <SizedBox width={40 * zoom} />

                <div style={{ width: '500px' }}>
                    <Title>{getTitle(product?.urlState)} {indexRegion}</Title>

                    <ProductTitle title={product?.title} url={product?.url} vendor={product?.vendor || ''} />

                    <SizedBox height={30} />

                    <Descriptions column={2} style={{ gridTemplateColumns: '2fr 1fr', gridColumnGap: '20px' }}>
                        <ProductPriceRangeV2
                            minPrice={numberUtils.formatNumber(product?.sizePriceRange?.actual_price?.[0], { isCommaSymbol: true, decimal: 2 }) || ''}
                            maxPrice={numberUtils.formatNumber(product?.sizePriceRange?.actual_price?.[1], { isCommaSymbol: true, decimal: 2 }) || ''}
                            currency={product?.currency}
                            label="sales price range for sizes"
                        />
                        <Descriptions.Item 
                            label="average sales price of option" 
                            value={product?.averagePriceOption ? `${product?.averagePriceOption} ${product?.currency}` : '-'} 
                            valueFontSize={22}
                        />
                        <ProductPriceRangeV2
                            minPrice={numberUtils.formatNumber(product?.sizePriceRange?.original_price?.[0], { isCommaSymbol: true, decimal: 2 }) || ''}
                            maxPrice={numberUtils.formatNumber(product?.sizePriceRange?.original_price?.[1], { isCommaSymbol: true, decimal: 2 }) || ''}
                            currency={product?.currency}
                            label="full price range for sizes"
                        />
                        <Descriptions.Item 
                            label="average full price of option" 
                            value={product?.averageFullPrice ? `${product?.averageFullPrice} ${product?.currency}` : '-'} 
                            valueFontSize={22}
                        />
                        <ProductPriceRangeV2
                            minPrice={numberUtils.formatNumber(product?.sizePriceRange?.first_observed_original_price?.[0], { isCommaSymbol: true, decimal: 2 }) || ''}
                            maxPrice={numberUtils.formatNumber(product?.sizePriceRange?.first_observed_original_price?.[1], { isCommaSymbol: true, decimal: 2 }) || ''}
                            currency={product?.currency}
                            label="First observed full price range for sizes"
                        />
                        <Descriptions.Item 
                            label="Discount" 
                            value={product?.discount}
                            valueFontSize={22}
                            valueStyle={{ color: cssConfig.primaryColor }}
                        />
                    </Descriptions>

                    <ProductDateForSizePrice 
                        lastRelaunchDate={product?.lastRelaunchDate}
                        firstObservedDate={product?.firstObservedDate}
                        firstDiscountDate={product?.firstDiscountDate}
                    />

                    <Collapse title="Price details" bottom={20}>
                        <div className={styles.thead}>
                            <div className={styles.th}>COUNTRY</div>
                            <div className={styles.th}>PRICE</div>
                            <div className={styles.th}>INDEX</div>
                        </div>
                        {
                            priceDetail.map(item => (
                                <div className={styles.tr} key={item.region}>
                                    <div className={styles.td}>{regionShortMappingFn(item.region)}</div>
                                    <div className={styles.td}>{item.price}</div>
                                    <div className={styles.td}>{item.index}</div>
                                </div>
                            ))
                        }
                    </Collapse>

                    <ShouldRender shouldRender={customerVendor === LICENSE_BIMBA}>
                        <Collapse title="Price publishing details" bottom={20}>
                            <div className={styles.thead}>
                                <div className={styles.th}>COUNTRY</div>
                                <div className={styles.th}>FORMAT</div>
                            </div>
                            {
                                priceDetail.map(item => (
                                    <div className={styles.tr} key={item.region}>
                                        <div className={styles.td}>{regionShortMappingFn(item.region)}</div>
                                        <div className={styles.td}>{formatPublishPrice(item.currencyPriceMapper[item.currency], item.region, item.vendor)}</div>
                                    </div>
                                ))
                            }
                        </Collapse>
                    </ShouldRender>

                    <SellerPerSize 
                        sizePriceList={product?.sellerPerSize || []}
                        bottom={40}
                    />
                </div>

                <SizedBox width={60 * zoom} />

                <div style={{ width: '480px' }}>
                    <PriceLineChart
                        title={`Sales price history ${indexRegion}`}
                        dataSource={product?.priceHistoryList || []} 
                    />
                    <SizedBox height={40} />
                    <FullPriceLineChart
                        title={`Full price history ${indexRegion}`}
                        dataSource={product?.fullPriceHistoryList || []} 
                    />
                    <SizedBox height={40} />
                </div>
            </div>
        </Spin>
    )
}
