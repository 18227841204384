import React, { FC, memo, useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import { SearchIcon } from 'assets/icons/Search'
import { Input } from 'components/form-elements/Input'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { searchUrl } from 'graphql/nornaapi'
import { Message } from 'components/form-elements/Message'
import productStyles from 'components/Product/styles.module.scss'
import { selectComparisonQuery } from 'features/filters/filtersSlice'
import { useSelector } from 'react-redux'
import { ProductDetailsModal } from 'components/Product/components/ProductDetailsModal'
import { Loading } from 'components'
import { useFetch } from 'libs/hookRequest'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { UseDialogType } from 'hooks/useDialog'
import { isSearchForOptionsPage } from 'global/Routes'
import { getQaData } from 'features/filters/qaDataSlice'
import { SearchBarProps } from './types'
import styles from './styles.module.scss'
import { storage } from 'utils/storage'

export const SearchBar: FC<SearchBarProps> = memo(({
  onWidthChange,    // Search 宽度变化时触发，true 表示变宽，false 表示默认宽度
}) => {
  const [ searchValue, setSearchValue ] = useState('')
  const [ searchResults, setSearchResults ] = useState(false)
  const [ isOpenSearchBar, setIsOpenSearchBar ] = useState(false)
  const qaData = useSelector(getQaData)

  // 记录 Search 宽度是否变化
  const widthChangedRef = useRef(false)

  // 监听文本框值变化，记录 Search 宽度是否变化
  useEffect(() => {
    if (searchValue.length === 0 && widthChangedRef.current !== false) {   // false
      widthChangedRef.current = false
      onWidthChange?.(false)
    } else if (searchValue.length > 0 && widthChangedRef.current !== true) {
      widthChangedRef.current = true
      onWidthChange?.(true)
    }
    // eslint-disable-next-line
  }, [searchValue])

  // 获取右上角 Filter 中勾选的 region
  const { comparisonQuery } = useSelector(selectComparisonQuery)
  const query = comparisonQuery?.collection?.query
  const region = query?.regions?.key

  const { postFn: fetch, data, loading } = useFetch()

  const handleSearchbar = (value = '') => {
    if (value === storage.getEmail()) {
      return
    }
    setSearchValue(value)
    setSearchResults(Boolean(value))
    /**
     * nornaRegionInfo ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/125
     * 在 Analytics 页面，取值右上角 Filter > Country 选中值对应的 region
     * 在 Dashboard 这些没有 Filter 的页面中，取值 customer_seller 中的 region
     */
    if (value.includes('?')) {
      if (value.search('nornaRegionInfo') === -1) value = `${value.trim()}&nornaRegionInfo=${region || comparisonQuery?.customer_seller?.region}`
    } else {
      value = `${value.trim()}?nornaRegionInfo=${region || comparisonQuery?.customer_seller?.region}`
    }
    const query = searchUrl(value)
    if (qaData.qaPage) query.query.qa_page = qaData.qaPage

    fetch(query.url, query)
  }

  /* ******************************** product detail modal *************************************** */
  const dialogRef = useRef<DialogRefType>({} as DialogRefType)

  const handleProductSelect = () => {
    if (data?.nornaid) {
      setSearchValue('')
      /**
       * @todo
       * 1. 所有详情弹框合并
       */
      const closeFn = dialogRef.current?.closeDialog
      dialogRef.current?.openDialog?.('Search Product detail', (
        <div className={productStyles.quickView}>
          <ProductDetailsModal
            nornaid={data.nornaid}
            closeProductDetails={closeFn}
            vendorCode={data?.vendor}
          />
        </div>
      ), UseDialogType.PROUDUCT_DETAIL)
    }
  }

  const toggleSearchBar = () => {
    setIsOpenSearchBar(!isOpenSearchBar)
  }

  useEffect(() => {
    setSearchResults(Boolean(searchValue))
  }, [ searchValue ])

  return (
    <>
      <div
        className={classnames([ styles['search-bar'], isSearchForOptionsPage() ? '' : styles['search-bar2'] ])}
        style={{ width: searchValue.length > 1 ? '680px' : '240px' }}
      >
        <ShouldRender shouldRender={true}>

          <Input
            value={searchValue}
            onChange={handleSearchbar}
            autoComplete="off"
            placeholder="Search options"
            maxLength={1000}
          />
        </ShouldRender>
        <div className={styles['search-icon']} onClick={() => toggleSearchBar()}>
          <SearchIcon />
        </div>
        <ShouldRender shouldRender={searchResults}>
          <div className={styles['search-results']}>
            {
              loading ? (
                <div className={styles['search-loading']}>
                  <Loading />
                </div>
              ) : (
                <>
                  {
                    !data?.nornaid ? (
                      <div className={styles.error}>
                        <div>URL not found</div>
                        <div className={styles.message}>
                          <Message
                            info
                            message="Please enter a valid url"
                            onClick={() => setSearchValue('')}
                          />
                        </div>
                      </div>
                    ) : (
                      <div onClick={handleProductSelect} className={styles.searchValue}>{searchValue}</div>
                    )
                  }
                </>
              )
            }
          </div>
        </ShouldRender>
      </div>
      <ShouldRender shouldRender={searchResults}>
        <div className={styles['close-results']} onClick={() => setSearchResults(false)}>
          {searchResults}
        </div>
      </ShouldRender>
      <Dialog ref={dialogRef} />
    </>
  )
})
SearchBar.displayName = 'SearchBar'
