import React from 'react'
import styles from './styles.module.scss'

export const Title = ({
    children,
}) => {
    return (
        <div className={styles.moduleTitle}>{children}</div>
    )
}
