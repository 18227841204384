/**
* @file    SelectionSearchbar
* @date   2022-04-21
* @author byte_su@163.com
*/

import { InputUi } from 'components/form-elements/Input'
import { useQa } from 'hooks/useQa'
import React from 'react'

export const SelectionSearchbar = props => {

  const { setSearch, search, enableQa, isSelectionPage } = useQa()

  if (!enableQa || !isSelectionPage) return null

  return (
    <InputUi
      sty={{
        display: 'inline-block',
        width: '240px',
        margin: '0 10px', height: '34px',
      }}
      placeholder="Search"
      value={search}
      onChange={(value: string) => setSearch(value)}
    />
  )
}

SelectionSearchbar.displayName = 'SelectionSearchbar'
