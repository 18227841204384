import { useApiData, useApiLoading, useDynamicDashboardRequests, usePovValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import React, { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { MetricNav } from '../MetricNav'
import { Flex } from 'druikit'
import { MetricCard, MetricLineChartCard, MetricProductCard } from '../MetricCard'
import { handleCardDataSource, handleLineChartData } from 'pages/DynamicDashboard/utils'
import { useFetchGraphData, usePricePerformanceProducts } from 'pages/DynamicDashboard/hooks/useApi'
import { cloneDeep } from 'lodash'
import { MetricPriceSpreadChartCard } from '../MetricCard/MetricPriceSpreadCard'
import { getSymbolFromCurrency } from 'utils'
import { useFilterCurrency } from 'hooks/useFilter'
import { numberUtils } from 'norna-uikit'

export const ModalSalesPricePerformance = () => {
    const [ apiData ] = useApiData()
    const [ apiLoading ] = useApiLoading()
    const [ povValue ] = usePovValue()
    const [ currency ] = useFilterCurrency()
    const [ cardDataSource, setCardDataSource ] = useState<any[]>([])
    const [ priceSpreadChartData, setPriceSpreadChartData ] = useState<any[]>([])
    const [ salePricelineChartData, setSalePriceLineChartData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const metricList = [ 'Sales Lowest price', 'Sales Average price', 'Sales Highest price' ]
        const cardDataSource = handleCardDataSource({ apiData, metricList, currency, customerVendor: povValue })
        setCardDataSource([ ...cardDataSource ])

        const priceSpreadChartData = handlePriceSpreadChartData(cardDataSource)
        setPriceSpreadChartData([ ...priceSpreadChartData ])
    }, [ apiData, currency, povValue, {} ])

    const [ requests ] = useDynamicDashboardRequests()
    const {
        salesPriceLineGraphApiData: lineChartData,
        salesPriceLineGraphApiLoading: lineChartLoading,

        salesPriceProductsApiData: products,
        salesPriceProductsApiLoading: productsLoading,
    } = requests

    /* ************************** 线图 ******************************* */
    const metric = 'Sales average price'
    useFetchGraphData(metric)

    useDeepCompareEffect(() =>  {
        if (!lineChartData) return
        const discountEffectLineChartData = handleLineChartData(lineChartData, metric, povValue)
        setSalePriceLineChartData([ ...discountEffectLineChartData ])
    }, [ lineChartData, povValue, {} ])

    /* ************************** 产品卡片 ******************************* */
    usePricePerformanceProducts('sales')

    return (
        <>
            <MetricNav
                name="Sales price performance"
                metric="Sales price performance"
            />
            <Flex justifyContent="space-between">
                {
                    cardDataSource.map(item => {
                        return (
                            <MetricCard
                                style={{ width: '32%' }}
                                loading={apiLoading}
                                key={item?.title || ''}
                                title={item?.title || ''}
                                dataSource={item?.dataSource || []}
                                showUnits={item.showUnits}
                            />
                        )
                    })
                }
            </Flex>
            <Flex justifyContent="space-between" id="dynamic-dashboard-middle-graph">
                <MetricPriceSpreadChartCard
                    style={{ width: '38%' }}
                    loading={apiLoading}
                    title="Sales price spread"
                    dataSource={priceSpreadChartData}
                />
                <MetricLineChartCard
                    loading={lineChartLoading}
                    style={{ width: '60%' }}
                    title="Sales prices over time"
                    dataSource={salePricelineChartData}
                    yAxisFormatter={value => {
                        return numberUtils.formatNumber(value, { isCommaSymbol: true, decimal: 0 })
                    }}
                    tooltipFormatter={value => {
                        return numberUtils.formatNumber(value, { isCommaSymbol: true, decimal: 0 }) + getSymbolFromCurrency(currency)
                    }}
                />
            </Flex>
            <Flex justifyContent="space-between" flexWrap="wrap">
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} highest sales price`}
                    dataSource={products.customerVendorMaximumPriceProducts || []}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} highest sales price`}
                    dataSource={products.comparisonVendorMaximumPriceProducts || []}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} lowest sales price`}
                    dataSource={products.customerVendorMinimumPriceProducts || []}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} lowest sales price`}
                    dataSource={products.comparisonVendorMinimumPriceProducts || []}
                />
            </Flex>
        </>
    )
}

function handlePriceSpreadChartData(data) {
    let newData = cloneDeep(data)
    newData = newData.map(item => {
        return {
            label: item.title,
            lowestPrice: Number(item?.dataSource?.[0]?.value?.replace(',', '')),
            averagePrice: Number(item?.dataSource?.[1]?.value?.replace(',', '')),
            highestPrice: Number(item?.dataSource?.[2]?.value?.replace(',', '')),
        }
    })
    return newData
}

