import { DateFormats } from 'consts'
import { format } from 'date-fns'
import html2pdf from 'html-to-pdf-js'
import { NoteType } from './type'

export const NoteTypeMapping = {
    [NoteType.Release]: 'Release',
    [NoteType.Support]: 'Support & Contact',
    [NoteType.About]: 'About Norna',
}
/**
 * download note file
 * @param type 
 */
export const downLoadPDF = (type: NoteType) => {
    const fileName = NoteTypeMapping[type] + '_' + format(new Date(), DateFormats.DEFAULT) + '.pdf'

    const element: any = document.getElementById('release')

    if (element) {
        const elewidth = element ? element.clientWidth : 900
        const eleheight = element ? element.clientHeight : 1200
        const opt = {
            margin: 50,
            filename: fileName,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                scale: 4,
                useCORS: true,

            },
            pagebreak: { mode: [ 'avoid-all', 'css', 'legacy' ] },
            allowTaint: true,
            useCORS: true,
            jsPDF: { unit: 'px', format: [ elewidth, eleheight + 30 ], orientation: 'portrait' },
        }

        html2pdf().set(opt).from(element).save().then(() => {
        })
        // html2pdf(element);

        // const fileName = 'Report_' + format(new Date(), DateFormats.DEFAULT) + '.pdf';
        // const element: any = document.getElementById('Release');
        // const opt = getPDFOption(fileName, 1);
        // let doc = html2pdf().set(opt).from(element).toPdf();
        // const subelement: any = document.getElementById('releaseTitle');
        // const subelement1: any = document.getElementById('releaseDe');

        // doc = doc.get('pdf').then(pdf => {
        //   pdf.addPage();
        // }).set(getPDFOption(fileName, 0)).from(subelement).toContainer().toCanvas().toPdf();

        // doc = doc.get('pdf').then(pdf => {
        //   pdf.addPage();
        // }).set(getPDFOption(fileName, 0)).from(subelement1).toContainer().toCanvas().toPdf();
        // doc.save().then(() => {
        //   setTimeout(() => {
        //   }, 10000);
        // })
        //   .catch(e => {
        //     console.log('Exporting PDF--finished');
        //   });
    }
}
/**
 * ## update size of note dialog
 * 
 * @param releaseClass 
 * @param contentClass 
 * @param contentaClass 
 */
export const updateSize = (releaseClass: string, contentClass: string, contentaClass: string, type: NoteType) => {
    const filterDisplay = document.querySelector('.filterDisplay')
    if (filterDisplay) {
        const release: HTMLDivElement = document.querySelector(`.${releaseClass}`) as HTMLDivElement
        const content: HTMLDivElement = document.querySelector(`.${contentClass}`) as HTMLDivElement
        const contenta: HTMLDivElement = document.querySelector(`.${contentaClass}`) as HTMLDivElement
        if (type === NoteType.Support) {
            if (release) {
                release.style.height = '400px'
                release.style.top = `calc((100vh - ${filterDisplay.clientHeight}px - 400px)/2 + ${filterDisplay.clientHeight}px)`

            }
            if (content) content.style.maxHeight = '400px'
            if (contenta) contenta.style.maxHeight = '400px'
        } else {
            if (release) {
                release.style.height = `calc(100vh - ${filterDisplay.clientHeight}px)`
                release.style.top = `${filterDisplay.clientHeight}px`
            }
            if (content) content.style.maxHeight = `calc(100vh - ${filterDisplay.clientHeight}px)`
            if (contenta) contenta.style.maxHeight = `calc(100vh - ${filterDisplay.clientHeight}px)`
        }

        release.style.opacity = '1'

    }
}
