
import { getFilterGenderAllValue } from 'components/layout/Header/components/HeaderFilter/gender.util'
import { CATEGORY_TOTAL, IS_LIVE_ENV } from 'consts'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { cleanFilter } from 'features/filters/filtersSlice'
import { updateCheckedLookbook } from 'features/filters/lookbookDataSlice'
import { RoutesObj } from 'global/Routes'
import { cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DISABLE_CATEGORY_IN_LIVE, getInitCategories, sortCategoryFn } from 'utils'

export function useClearFilter() {
    const dispatch = useDispatch()
    const cacheData = useSelector(getCacheData)

    let categoryList = cloneDeep(cacheData.metaCategories.list)
    const { pathname } = useLocation()
    if (pathname !== RoutesObj.POPULAR_PRODUCTS.URL) {
        categoryList.push({
            isChecked: false,
            list: [],
            name: CATEGORY_TOTAL,
        })
    }
    const { ourSeller } = cacheData
    categoryList = (ourSeller.company.code === 'marimekko' ? sortCategoryFn(categoryList, 'name', 'list') : categoryList).filter(item => {
        /** 
        * live 环境隐藏掉DISABLE_CATEGORY_IN_LIVE 中的categories
        */
        if (IS_LIVE_ENV && DISABLE_CATEGORY_IN_LIVE.indexOf(item.name) > -1) {
            return false
        }
        return true
    })

    return {
        clearFilter() {
            dispatch(cleanFilter({
                // 清空时，默认选择第一个category
                categories: getInitCategories(categoryList),
                targets: getFilterGenderAllValue(),
            }))
            // clear lookbook
            dispatch(updateCheckedLookbook([]))
        },
    }
}
