import React from 'react'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { IS_TEST_ENV } from 'consts'
import { Collapse } from '../Collapse'
import { ProductSizes } from '../ProductSizes'

export const NornaSize = ({
    nornaSize = [],
    bottom = 0,
}: {
    nornaSize?: string[];
    bottom?: number;
}) => {
    return (
        <ShouldRender shouldRender={IS_TEST_ENV}>
            <Collapse title="Norna size" showArrow={false} bottom={bottom}>
                <ProductSizes sizeList={nornaSize} />
            </Collapse>
        </ShouldRender>
    )
}
