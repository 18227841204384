import { getDateRangeValueByPeriod } from 'componentsv2/form-elements/DatePicker'
import * as string from './GlobalDatePicker.const'
import { formatDate, getDateObject, getMinimumDate } from 'utils/dateUtils'

/**
 * 获取日期键值对映射
 */
export function getDateKeyValueMapper() {
    const mapper = {
        today: String(getDateRangeValueByPeriod('today')),
        yesterday: String(getDateRangeValueByPeriod('yesterday')),
        last_7_days: String(getDateRangeValueByPeriod('oneLatestWeekAgo')),
        last_week: String(getDateRangeValueByPeriod('oneWholeWeekAgo')),
        last_month: getDateRangeValueByPeriod('oneWholeMonthAgo'),
        last_quarter: getDateRangeValueByPeriod('oneWholeQuarterAgo'),
        today_on_previous_period: getDateRangeValueByPeriod('yesterday'),
        yesterday_on_previous_period: getDateRangeValueByPeriod('twoYesterday'),
        last_7_days_on_previous_period: getDateRangeValueByPeriod('twoLatestWeeksAgo'),
        last_week_on_previous_period: getDateRangeValueByPeriod('twoWholeWeeksAgo'),
        last_month_on_previous_period: getDateRangeValueByPeriod('twoWholeMonthsAgo'),
        last_quarter_on_previous_period: getDateRangeValueByPeriod('twoWholeQuartersAgo'),
        today_on_year: getDateRangeOfLastYear(String(getDateRangeValueByPeriod('today'))),
        yesterday_on_year: getDateRangeOfLastYear(String(getDateRangeValueByPeriod('yesterday'))),
        last_7_days_on_year: getDateRangeOfLastYear(String(getDateRangeValueByPeriod('oneLatestWeekAgo'))),
        last_week_on_year: getDateRangeOfLastYear(String(getDateRangeValueByPeriod('oneWholeWeekAgo'))),
        last_month_on_year: getDateRangeOfLastYear(getDateRangeValueByPeriod('oneWholeMonthAgo')),
        last_quarter_on_year: getDateRangeOfLastYear(getDateRangeValueByPeriod('oneWholeQuarterAgo')),
    }
    return mapper
}

export function getDateValueKeyMapper() {
    const mapper = {
        [String(getDateRangeValueByPeriod('today'))]: string.PERIOD_TODAY,
        [String(getDateRangeValueByPeriod('yesterday'))]: string.PERIOD_YESTERDAY,
        [String(getDateRangeValueByPeriod('oneLatestWeekAgo'))]: string.PERIOD_LAST_7_DAYS,
        [String(getDateRangeValueByPeriod('oneWholeWeekAgo'))]: string.PERIOD_LAST_WEEK,
        [getDateRangeValueByPeriod('oneWholeMonthAgo')]: string.PERIOD_LAST_MONTH,
        [getDateRangeValueByPeriod('oneWholeQuarterAgo')]: string.PERIOD_LAST_QUARTER,
    }
    return mapper
}

/**
 * date = '2022-07-01_2022-09-30'
 */
export function getCalendarDateValue(date = '', isRange = true) {
    let calendarValue = {}
    if (isRange) {
        let [ fromDateStr, endDateStr ] = date?.split('_')
        if (!endDateStr && !date.endsWith('_')) {
            endDateStr = fromDateStr
        }
        calendarValue = {
            from: fromDateStr ? getDateObject(new Date(fromDateStr)) : null,
            to: endDateStr ? getDateObject(new Date(endDateStr)) : null,
        }
    } else {
        let current = date
        // '2022-07-12_' -> '2022-07-12'  在其它选择日期范围的页面只选了一个日期值，然后跳转单日期页面
        if (current.endsWith('_')) {
            current = current.replace('_', '')
        }
        // '2022-07-12_2022-07-15' -> '2022-07-15' 日期范围取最后一个日期值
        if (current.indexOf('_')) {
            current = current.split('_')[current.split('_').length - 1]
        }
        calendarValue = getDateObject(new Date(current))
    }
    return calendarValue
}

/**
 * 获取上一年日期
 */
export function getDateRangeOfLastYear(dateRange: string) {
    return dateRange
        .split('_')
        .map(date => {
            const d = new Date(date)
            d.setFullYear(d.getFullYear() - 1)
            const dStr = formatDate(d.toString()).ymd
            return dStr
        })
        .join('_')
}

/**
 * 获取有效日期, 和最小日期比较
 */
export function getValidDateRange(dateRange: string) {
    const minDate = getMinimumDate()
    return dateRange
        .split('_')
        .map(d => {
            return Date.parse(d) < Date.parse(minDate) ? minDate : d
        })
        .join('_')
}

export function handleDateDropdownGroupValue({
    tmpDate,
    comparisonTmpDate,
    compareToValue,
    operType = 'period',
}: {
    tmpDate: string;
    comparisonTmpDate: string;
    compareToValue: string;
    operType?: 'compareTo' | 'period';
}) {
    // date 不是完整的值
    if (tmpDate.endsWith('_')) {
        if (compareToValue) {
            return {
                periodValue: '',
                compareToValue,
                comparisonTmpDate,
            }
        } else {
            return {
                periodValue: '',
                compareToValue: '',
                comparisonTmpDate,
            }
        }
    } 
    // date 是完整的值
    else {
        // 看 date 是否有预设值
        const valueKeyMapper = getDateValueKeyMapper()
        const datePreset = valueKeyMapper[tmpDate]

        // 有预设值
        if (datePreset) {
            if (compareToValue === string.COMPARE_TO_YEAR_ON_YEAR) {
                if (comparisonTmpDate === getDateRangeOfLastYear(tmpDate)) {
                    return {
                        periodValue: datePreset,
                        compareToValue,
                        comparisonTmpDate: getDateRangeOfLastYear(tmpDate),
                    }
                }
                return {
                    periodValue: datePreset,
                    compareToValue: '',
                    comparisonTmpDate,
                }
            } 
            else if (compareToValue === string.COMPARE_TO_PREVIOUS_PERIOD) {
                const keyValueMapper = getDateKeyValueMapper()
                if (datePreset === string.PERIOD_TODAY) {
                    if (keyValueMapper.today_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue,
                            comparisonTmpDate: keyValueMapper.today_on_previous_period,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                }
                else if (datePreset === string.PERIOD_YESTERDAY) {
                    if (keyValueMapper.yesterday_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue,
                            comparisonTmpDate: keyValueMapper.yesterday_on_previous_period,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                }
                else if (datePreset === string.PERIOD_LAST_7_DAYS) {
                    if (keyValueMapper.last_7_days_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue,
                            comparisonTmpDate: keyValueMapper.last_7_days_on_previous_period,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                }
                else if (datePreset === string.PERIOD_LAST_WEEK) {
                    if (keyValueMapper.last_week_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue,
                            comparisonTmpDate: keyValueMapper.last_week_on_previous_period,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                } 
                else if (datePreset === string.PERIOD_LAST_MONTH) {
                    if (keyValueMapper.last_month_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue,
                            comparisonTmpDate: keyValueMapper.last_month_on_previous_period,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                } 
                else if (datePreset === string.PERIOD_LAST_QUARTER) {
                    if (comparisonTmpDate === keyValueMapper.last_quarter_on_previous_period) {
                        return {
                            periodValue: datePreset,
                            compareToValue: '',
                            comparisonTmpDate,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue,
                        comparisonTmpDate: keyValueMapper.last_quarter_on_previous_period,
                    }
                }
                return {
                    periodValue: datePreset,
                    compareToValue,
                    comparisonTmpDate,
                }
            } 
            // compareToValue 没有值
            else {
                const keyValueMapper = getDateKeyValueMapper()
                if (datePreset === string.PERIOD_TODAY) {
                    if (keyValueMapper.today_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_PREVIOUS_PERIOD,
                            comparisonTmpDate,
                        }
                    }
                    else if (keyValueMapper.today_on_year === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_YEAR_ON_YEAR,
                            comparisonTmpDate,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                }
                else if (datePreset === string.PERIOD_YESTERDAY) {
                    if (keyValueMapper.yesterday_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_PREVIOUS_PERIOD,
                            comparisonTmpDate,
                        }
                    }
                    else if (keyValueMapper.yesterday_on_year === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_YEAR_ON_YEAR,
                            comparisonTmpDate,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                }
                else if (datePreset === string.PERIOD_LAST_7_DAYS) {
                    if (keyValueMapper.last_7_days_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_PREVIOUS_PERIOD,
                            comparisonTmpDate,
                        }
                    }
                    else if (keyValueMapper.last_7_days_on_year === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_YEAR_ON_YEAR,
                            comparisonTmpDate,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                }
                else if (datePreset === string.PERIOD_LAST_WEEK) {
                    if (keyValueMapper.last_week_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_PREVIOUS_PERIOD,
                            comparisonTmpDate,
                        }
                    }
                    else if (keyValueMapper.last_week_on_year === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_YEAR_ON_YEAR,
                            comparisonTmpDate,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                } 
                else if (datePreset === string.PERIOD_LAST_MONTH) {
                    if (keyValueMapper.last_month_on_previous_period === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_PREVIOUS_PERIOD,
                            comparisonTmpDate,
                        }
                    }
                    else if (keyValueMapper.last_month_on_year === comparisonTmpDate) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_YEAR_ON_YEAR,
                            comparisonTmpDate,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                } 
                else if (datePreset === string.PERIOD_LAST_QUARTER) {
                    if (comparisonTmpDate === keyValueMapper.last_quarter_on_previous_period) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_PREVIOUS_PERIOD,
                            comparisonTmpDate,
                        }
                    }
                    else if (comparisonTmpDate === keyValueMapper.last_quarter_on_year) {
                        return {
                            periodValue: datePreset,
                            compareToValue: string.COMPARE_TO_YEAR_ON_YEAR,
                            comparisonTmpDate,
                        }
                    }
                    return {
                        periodValue: datePreset,
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                }
                return {
                    periodValue: datePreset,
                    compareToValue,
                    comparisonTmpDate,
                }
            }
        }
        // 没有预设值
        else {
            if (compareToValue === string.COMPARE_TO_YEAR_ON_YEAR) {
                if (operType === 'period') {
                    return {
                        periodValue: '',
                        compareToValue,
                        comparisonTmpDate: getDateRangeOfLastYear(tmpDate),
                    }
                } else {
                    if (comparisonTmpDate === getDateRangeOfLastYear(tmpDate)) {
                        return {
                            periodValue: '',
                            compareToValue,
                            comparisonTmpDate,
                        }
                    }
                    return {
                        periodValue: '',
                        compareToValue: '',
                        comparisonTmpDate,
                    }
                }
            }
            return {
                periodValue: '',
                compareToValue: '',
                comparisonTmpDate,
            }
        }
    }
}
