import React, { useContext } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { ActionBoxContext } from 'components/common/InfoBox/context'
import { ActionBoxTypes } from 'components/common/InfoBox/InfoActionContext'
import classnames from 'classnames'
import { setMarginTopByPageContainerMarginTop } from 'componentsv2/MenuBar/utils'
import { ProductDetailsModal } from './components/ProductDetailsModal'
import styles from './styles.module.scss'

const DIALOG_ID = 4
export function useProductDialog() {

  const actionBox = useContext(ActionBoxContext)
  const closeQuickView = () => {
    actionBox?.close?.(DIALOG_ID)
  }
  const openDetailDialog = ({ toCurrency, nornaid, enabledQa = false, vendorCode }) => {
    actionBox.open && actionBox.open({
      id: DIALOG_ID,
      left: 0,
      top: 0,
      title: '',
      close: () => {
        actionBox?.close?.(DIALOG_ID)
      },
      type: ActionBoxTypes.DIALOGV3,
      bodyComponent: (
        <ClickAwayListener
          onClickAway={() => {
            actionBox?.close?.(DIALOG_ID)
          }}
        >
          <>
            <div className={classnames(styles.quickView)}>
              <ProductDetailsModal
                nornaid={nornaid}
                enabledQa={enabledQa}
                closeProductDetails={closeQuickView}
                toCurrency={toCurrency}
                vendorCode={vendorCode}
              />
            </div>
            <div className={styles.productDetailsOverlay} onClick={closeQuickView} />
          </>
        </ClickAwayListener>
      ),
    })
    
    setMarginTopByPageContainerMarginTop(styles.quickView)

  }
  return {
    openDetailDialog,
  }
}

