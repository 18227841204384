import React from 'react'
import { TextareaProps } from './types'
import styles from './styles.module.scss'

export const Textarea = ({
  name = '',
  value,
  onChange,
  placeholder,
  disabled,
  maxLength,
  onEnterKey,
}: TextareaProps) => 
    <textarea
        className={styles.textArea}
        name={name}
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyDown={event => onEnterKey && onEnterKey(event)}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
    />
  
