import { numberUtils } from 'norna-uikit'

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        'Vendor',
        'Category',
        'Product name',
        'Original price',
        'Actual price',
        'Currency',
    ]
    data.push(titleRow)

    dataSource.forEach(item => {
        const vendorName = item.name
        const categoryName = item.category
        item?.products?.forEach(product => {
            const dataRow = [
                vendorName,
                categoryName,
                product?.name,
                numberUtils.formatNumber(product?.originalPrice, { decimal: 2 }),
                numberUtils.formatNumber(product?.actualPrice, { decimal: 2 }),
                product?.currency,
            ]
            data.push(dataRow)
        })
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
