import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styles from './PreContextModal.module.scss'
import { getRoleResponse, getTextField, handleApiData } from './PreContextModal.util'
import Modal from 'componentsv2/Modal'
import PreContextCurd from './PreContextCurd'
import { cloneDeep } from 'lodash'
import { Button, Spin } from 'druikit'
import { Card, Flex } from 'antd'
import { stringUtils } from 'norna-uikit'
import { chatApiPrefix } from 'configs'
import AuthToken from 'utils/AuthToken'
import { ChatTypeEnum } from './types'
import { CONST_VARS } from 'libs/const'

const PreContextModal = ({
    onClose,
    chatType,
}) => {
    const [ dataSource, setDataSource ] = useState<any[]>([])
    const [ loading, setLoading ] = useState(false)
    const [ submitLoading, setSubmitLoading ] = useState(false)

    const fetchData = async () => {
        try {
            setLoading(true)
            const url = chatType === ChatTypeEnum.gemini 
                ? `${chatApiPrefix}/load_precontext` 
                : `${CONST_VARS.API_URL}/gpt/load_precontext`
            const result = await axios.request({
                url, 
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + AuthToken.getAccessToken(),
                },
            })
            const data = handleApiData(result?.data)
            setDataSource(data)
            setLoading(false)
            return result
        } catch (e) {
            setLoading(false)
            return null
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const onSubmit = async () => {
        const newDataSource = cloneDeep(dataSource)
        const data = newDataSource
            .filter(item => {
                return !item.isDelete
            })
            .map(item => {
                return item.data
            })
            .flat(2)

        const emptyText = data.some(item => !item[getTextField(chatType)])
        if (emptyText) {
            alert('Please fill in the precontext.')
            return
        }

        setSubmitLoading(true)
        const url = chatType === ChatTypeEnum.gemini 
            ? `${chatApiPrefix}/save_precontext` 
            : `${CONST_VARS.API_URL}/gpt/save_precontext`
        const result = await axios.request({
            url, 
            method: 'POST',
            data,
            headers: {
                Authorization: 'Bearer ' + AuthToken.getAccessToken(),
            },
        })
        if (result?.status !== 200) {
            alert('Submit failed.')
            setSubmitLoading(false)
            return
        }
        setSubmitLoading(false)
        await fetchData()
        alert('Submit successfully.')
    }

    const [ createVisible, setCreateVisible ] = useState(false)

    const onCreate = () => {
        setCreateVisible(true)
    }

    return (
        <Modal
            style={{ width: window.innerWidth - 80, height: window.innerHeight - 80 , zIndex: 9999991 }}
            onCancel={onClose}
        >
            <Flex vertical gap={20} className={styles.wrapper}>
                <Flex justify="end" gap={10}>
                    <Button 
                        onClick={onCreate} 
                        disabled={loading}
                    >
                        Create
                    </Button>
                    <Button 
                        type="primary" 
                        onClick={onSubmit} 
                        loading={submitLoading}
                    >
                        Submit
                    </Button>
                </Flex>
                {
                    createVisible ? (
                        <Card title="Create precontext">
                            <PreContextCurd
                                dataSource={[ { role: 'user', [getTextField(chatType)]: '' }, { role: getRoleResponse(chatType), [getTextField(chatType)]: '' } ]}
                                showDeleteButton={false}
                                showAddButton
                                onAdd={value => {
                                    const newDataSource = cloneDeep(dataSource)
                                    newDataSource.push({
                                        id: stringUtils.uuid(),
                                        data: value,
                                    })
                                    setDataSource(newDataSource)
                                    setCreateVisible(false)
                                }}
                            />
                        </Card>
                    ) : null
                }
                <Spin 
                    spinning={loading} 
                    style={{ 
                        overflow: 'auto', 
                        width: '100%', 
                        paddingBottom: '100px',
                        flex: 1,
                        gap: 10,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                        {
                            dataSource.map((item, index) => {
                                return (
                                    <Card
                                        key={item.id}
                                        style={{ width: '100%' }}
                                        title={index === 0 ? 'Precontext list' : ''}
                                    >
                                        <PreContextCurd
                                            dataSource={item.data}
                                            isDelete={item.isDelete}
                                            onChange={value => {
                                                const newDataSource = cloneDeep(dataSource)
                                                newDataSource.forEach(item2 => {
                                                    if (item.id === item2.id) {
                                                        item2.data = value
                                                    }
                                                })
                                                setDataSource(newDataSource)
                                            }}
                                            onDeleteChange={value => {
                                                const newDataSource = cloneDeep(dataSource)
                                                newDataSource.forEach(item2 => {
                                                    if (item.id === item2.id) {
                                                        item2.isDelete = value
                                                    }
                                                })
                                                setDataSource(newDataSource)
                                            }}
                                            chatType={chatType}
                                        />
                                    </Card>
                                )
                            })
                        }
                </Spin>
            </Flex>
        </Modal>
    )
}

export default PreContextModal

