import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

/**
 * ## useUrlParams
 *
 * 获取 url 问号后面的参数，转换为对象字面量
 *
 * ## 示例
 *
 * ### 示例一：https://xx.com
 *
 * ```
 * const urlParams = useUrlParams()     // {pathname:`/` ,pathObj:{...}}
 * ```
 *
 * ### 示例二：https://xx.com?name=hello
 *
 * ```
 * const urlParams = useUrlParams()     // { name: 'hello' ,pathname:`/` ,pathObj:{...}}
 * ```
 *
 * ### 示例三：https://xx.com?name=hello&age=21
 *
 * ```
 * const urlParams = useUrlParams()     // { name: 'hello', age: '21',pathname:`/` ,pathObj:{...}}
 * ```
 *
 * ### 示例三：https://xx.com?name=hello&age=21
 *
 * ```
 * const {pathObj,...queryObj} = useUrlParams()     //queryObj =>  { name: 'hello', age: '21',pathname:`/` }, pathObj=>{}
 * ```
 */
export const useUrlParams = (): UrlParamsProps => {
  const history = useHistory()
  const params: Record<string, any> = queryString.parse(history.location.search)
  const pathUrl = `${window.location.origin}${history.location.pathname}`
  return {
    ...params,
    pathObj: {
      pathUrl,                            // pathUrl eg: `http://localhost:3000/login`
      pathName: history.location.pathname,// pathName eg `/login`
      /**
       *
       * @param obj    -> query obj eg `{a:7,b:8}`  which can be converted form like `a=7&b=8`
       * @param url    -> request url , default value is `http://localhost:3000/login` when user visits by http://localhost:3000/login
       * @param url    -> http://localhost:3000/login?a=7&b=8
       */
      linkUrlParam: (obj, url = pathUrl) => {
        const paramStr = Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&')
        return `${url}?${paramStr}`
      },
    },

  }
}

interface UrlParamsProps {
  pathObj: {
    pathUrl: string;
    pathName: string;
    linkUrlParam: (obj: Record<string, any>, url?: string) => string;
  };
  [key: string]: any;
}
