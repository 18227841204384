import { CSSProperties, ReactChild } from 'react'
import { DataType } from './common'

export interface TreeItemProp {
  interactiveType?: TreeInteractiveType,
  /**
   * tree node label
   */
  label: string;
  /**
   * tree node value
   */
  val: string;
  /**
   * tree node type,
   */
  type: DataType.ARROW_CHECK | DataType.ARROW | DataType.SINGLE_CHECK_ARROW | DataType.SINGLE_CHECK | DataType.CHECK;
  /**
   * the click event when there is not children attribute in root node
   * ## `Tree Item Label`的点击事件
   * 1. 点击category的回调
   * 2. 点击price range 的回调
   * 3. 点击material/color fitler 的回调
   */
  click?: Function;
  /**
   * 1. Item Label 高亮标记
   * 2. 返回值参数标记是否被选中
   */
  active: boolean;
  /**
   *  是否展开子项`panel`
   */
  expandSubPanel?: boolean;
  /**
   * 更新展开子项`panel` 函数
   */
  updateExpandSubPanel?: (flag: boolean) => void;
  enableSelectAll?: boolean;
  enableChildrenSelectAll?: boolean;

  /**
   * select all cb
   */
  selectAllFn?: Function;
  linkField?: string;
  linkValue?: string;
  linkFn?: Function;
  linkAllowEmpty?: boolean;   // 关联字段没有值时是否要触发 linkFn, 值为 true 时触发, 值为 false 时不触发
  icon?: boolean;
  disabled?: boolean;
  children?: Array<TreeItemProp>;
  // property values that user can not see but exist
  notShowSubGroups?: Array<string>;

  // competitors
  region?: string;

  /**
   * 点击蒙版时触发事件
   */
  onClickMask?: () => void;
  /**
   * Customizing style for children item panel
   */
  customStyle?: Record<string, any>;
}

export interface TreeItemProps extends TreeItemProp {
  /**
   * item data
   */
  item: TreeItemProp;
  /**
   * only show when click the item,default action is hover, default set clickShow=false
   */
  clickShow?: boolean;

  /**
   *
   */
  index: Array<any>;
  /**
   * sub level data
   */
  children?: Array<TreeItemProp>;
  /**
   * the anchor that sub level panel,,default value `left`
   */
  anchor?: 'left' | 'bottom' | 'right';
  /**
   * enable mask panel or not,default value true
   */
  enableMaskPanel?: boolean
  isCountry?: boolean
  /**
   * mask panel custom style function
   */
  maskPanelStyleCallback?: (opt: { rect: DOMRect }) => CSSProperties;

  /**
   * the click event when there is not children attribute in root node
   */
  onChange?: Function;
  /**
   * disable the item
   */
  disabled?: boolean;
  // 和 disabled 一样有灰色蒙版, 但是可以点击
  masked?: boolean;

  /**
   * 点击蒙版时触发事件
   */
  onClickMask?: () => void;

  setTreeItem?: Function;

  // customer render function 
  render?: Function;
  /**
   * item label custom style
   */
  labelSty?: CSSProperties;

  filterTmpState?: any;
}
export enum TreeInteractiveType {
  HOVER = 'hover',
  CLICK = 'click'
}
export interface TreeProps {
  treeData: any;
  anchor?: 'left' | 'bottom' | 'right'; // left,bottom
  changeNotice: Function;             // 参数值改变
  lineAfterIndexArr?: Array<string>;  // adding line after the index of lineAfterIndexArr
  children?: ReactChild;
  interactiveType?: TreeInteractiveType,
  onClickMask?: () => void;           // 点击蒙版时触发事件
  maskPanelStyleCallback?: (opt: { rect: DOMRect }) => CSSProperties;   // mask panel custom style
}

export interface TreeItemCustomProps {
  item: any;
  setTreeItem: Function;
  disabled?: boolean;
  
  // 获取树的当前值
  getVals?: Function;
  updateTreeData?: Function;
  /**
   *  是否展开子项`panel`
   */
  expandSubPanel?: boolean;
  /**
   * 更新展开子项`panel` 函数
   */
  updateExpandSubPanel?: (flag: boolean) => void;
}

export interface PropertyTreeItemProps extends TreeItemProps {
  // property values that user can not see but exist
  notShowSubGroups?: Array<string>;
}
export interface TreeStateType {

  active?: string;
}
