import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMarket, selectCategory } from 'features/filters/filtersSlice'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useValidCategories } from './useValidCategory'
import { useLookbook } from './useLookbook'

/**
 * ## useCategoryAndCountryRequiredText
 * 
 * 右上角 Filter 中 Category 和 Country 未勾选时，提示文案
 * 
 * 优先提示 Categroy，之后提示 Country
 * 
 * ## 示例
 * 
 * ```
 * const filterRequiredText = useCategoryAndCountryRequiredText()
 * 
 * {
 *      filterRequiredText ? (
 *          <div>{filterRequiredText}</div>
 *      ) : (
 *          <PageContent />
 *      )
 * }
 * ```
 */
export const useCategoryAndCountryRequiredText = () => {
    // 右上角 Filter 选中 Category 值
    const filterCategories = useSelector(selectCategory)
    // 右上角 Filter 选中 Country 值
    const filterRegions = useSelector(selectMarket)
    // Filter 中个别属性未选择时，提示文案
    const [ filterRequiredText, setFilterRequiredText ] = useState('')

    useEffect(() => {
        if (filterCategories.length === 0) {
            setFilterRequiredText('Please Select Category')
        } else if (!filterRegions?.key) {
            setFilterRequiredText('Please Select Country')
        } else {
            setFilterRequiredText('')
        }
    }, [ filterRegions, filterCategories ])

    return filterRequiredText
}

/**
 * ## useCategoryRequiredText
 * 
 * 右上角 Filter 中 Category 未勾选时，提示文案
 * 
 * ## 示例
 * 
 * ```
 * const filterRequiredText = useCategoryRequiredText()
 *
 * {
 *      filterRequiredText ? (
 *          <div>{filterRequiredText}</div>
 *      ) : (
 *          <PageContent />
 *      )
 * }
 * ```
 */
export const useCategoryRequiredText = (text?: string) => {
    // Filter 中个别属性未选择时，提示文案
    const [ filterRequiredText, setFilterRequiredText ] = useState('')
    const categoryies = useValidCategories()
    const { isLookbook } = useLookbook()
    
    useDeepCompareEffect(() => {
        if (isLookbook) {
            setFilterRequiredText('')
        } else {
            if (!categoryies.length) {
                setFilterRequiredText(text || 'Please Select Category')
            } else {
                setFilterRequiredText('')
            }
        }
    }, [ categoryies || [], isLookbook ])

    return filterRequiredText
}

/**
 * ## useCountryRequiredText
 * 
 * 右上角 Filter 中 Country 未勾选时，提示文案
 * 
 * ## 示例
 * 
 * ```
 * const filterRequiredText = useCountryRequiredText()
 *
 * {
 *      filterRequiredText ? (
 *          <div>{filterRequiredText}</div>
 *      ) : (
 *          <PageContent />
 *      )
 * }
 * ```
 */
export const useCountryRequiredText = () => {
    // 右上角 Filter 选中 Country 值
    const filterRegions = useSelector(selectMarket)
    // Filter 中个别属性未选择时，提示文案
    const [ filterRequiredText, setFilterRequiredText ] = useState('')

    useEffect(() => {
        if (!filterRegions?.key) {
            setFilterRequiredText('Please Select Country')
        } else {
            setFilterRequiredText('')
        }
    }, [ filterRegions ])

    return filterRequiredText
}
