import React from 'react'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import {  makeStyles } from '@material-ui/core/styles'
import { useWindowZoom } from 'hooks/useWindow'
import { Tooltip as NornaTooltip } from 'componentsv2/Tooltip'

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#fff',
    fontSize: '20px',
  },
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 2px 9px 0px rgba(0, 0, 0, 0.3)',
    padding: '20px',
    top: '12px',
  },
}))

export function BootstrapTooltip(props: TooltipProps) {
  const zoom = useWindowZoom()
  const classes = useStylesBootstrap()

  if (zoom === 1) {
    return <Tooltip arrow classes={classes} {...props} placement="top" />
  }

  return (
    <NornaTooltip 
      title={props.title}
      zoom={zoom}
    >
      {props.children}
    </NornaTooltip>
  )
}
