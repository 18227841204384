/**
 * 各种权限控制
 */

import { LICENSE_CALVINKLEIN, LICENSE_JUST_BRANDS, LICENSE_TOMMYHILFIGER } from 'consts'
import { storage } from './storage'

/**
 * GlobalDatePicker
 * Last month       禁选
 * Last quarter     禁选
 * Year on Year     禁选
 */
export function isDisabledDatePreset() {
    return [
        LICENSE_TOMMYHILFIGER,
        LICENSE_CALVINKLEIN,
        LICENSE_JUST_BRANDS,
    ].includes(storage.getCustomerVendor())
}

/**
 * 是否显示 Sold out analytics 页面
 */
export function isShowSoldOutAnalyticsPage() {
    return [
        LICENSE_TOMMYHILFIGER,
        LICENSE_CALVINKLEIN,
    ].includes(storage.getCustomerVendor())
}

/**
 * 是否显示 Platform analytics 页面
 */
export function isShowPlatformAnalyticsPage() {
    return [
        LICENSE_TOMMYHILFIGER,
        LICENSE_CALVINKLEIN,
    ].includes(storage.getCustomerVendor())
}

/**
 * 这些 license 下会判断日期，可能会显示 warn data dialog
 */
export function isShowWarnDataDialog() {
    return [
        LICENSE_TOMMYHILFIGER,
        LICENSE_CALVINKLEIN,
    ].includes(storage.getCustomerVendor())
}

/**
 * 这些 license 在 Graph modal 里不包含 hm 和 zara
 */
export function isExcludeHmAndZaraForCompetitorOptions() {
    // return [
    //     // LICENSE_TOMMYHILFIGER,
    //     // LICENSE_CALVINKLEIN,
    // ].includes(storage.getCustomerVendor())
    return false
}
