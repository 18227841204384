import React, { useEffect, useState } from 'react'
import styles from './PreContextCurd.module.css'
import { Avatar, Button, Flex, Input, Typography } from 'antd'
import classNames from 'classnames';
import { ChatTypeEnum } from './types';
import { getRoleResponse, getTextField } from './PreContextModal.util';

const PreContextCurd = ({
    dataSource,
    onChange,
    onAdd,
    onDeleteChange,
    showDeleteButton = true,
    showAddButton = false,
    isDelete = false,
    chatType,
}: {
    dataSource: any[];
    onChange?: (value: any[]) => void;
    onAdd?: (value: any[]) => void;
    onDeleteChange?: (value: boolean) => void;
    showDeleteButton?: boolean;
    showAddButton?: boolean;
    isDelete?: boolean;
    chatType?: ChatTypeEnum;
}) => {
    const textField = getTextField(chatType)
    const roleResponse = getRoleResponse(chatType)

    const [ userText, setUserText ] = useState(dataSource[0]?.[textField])
    const [ modelText, setModelText ] = useState(dataSource[1]?.[textField])

    useEffect(() => {
        if (userText === dataSource?.[0]?.[textField] && modelText === dataSource[1]?.[textField]) return
        onChange?.([
            { role: 'user', [textField]: userText },
            { role: roleResponse, [textField]: modelText },
        ])
    }, [ userText, modelText ])

    return (
        <div className={classNames({
            [styles.wrapper]: true,
            [styles.deleted]: isDelete,
        })}>
            <Flex align="center" gap={40}>
                <Flex vertical gap={16} flex={1}>
                    <Flex gap={16}>
                        <Avatar size="large">{dataSource?.[0]?.role}</Avatar>
                        <Flex flex={1}>
                            <Input.TextArea
                                autoSize
                                value={userText}
                                onChange={(e) => setUserText(e.target.value)}
                                placeholder="Input ..."
                            />
                        </Flex>
                    </Flex>
                    <Flex gap={16}>
                        <Avatar size="large">{dataSource?.[1]?.role}</Avatar>
                        <Flex flex={1}>
                            <Input.TextArea
                                autoSize
                                value={modelText}
                                onChange={(e) => setModelText(e.target.value)}
                                placeholder="Input ..."
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex vertical gap={10}>
                    {
                        showDeleteButton && (
                            <Button 
                                danger
                                onClick={() => {
                                    onDeleteChange?.(!isDelete)
                                }}
                            >
                                { isDelete ? 'Undelete' : 'Delete' }
                            </Button>
                        )
                    }
                    {
                        showAddButton && (
                            <Button
                                onClick={() => onAdd?.([ { role: 'user', [textField]: userText }, { role: roleResponse, [textField]: modelText } ])}
                            >
                                Add
                            </Button>
                        )
                    }
                </Flex>
            </Flex>
        </div>
    )
}

export default PreContextCurd
