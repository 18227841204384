import { useFetch } from 'libs';
import { useCallback, useEffect, useState } from 'react';

export function usePriceUpdateAllCustomers() {
    const { getFn, data } = useFetch()

    const [ loading, setLoading ] = useState(false)

    const fetchCustomers = async () => {
        setLoading(true)
        await getFn('/price_update/all_customers/')
        setLoading(false)
    }

    useEffect(() => {
        fetchCustomers()
    }, [])

    return {
        data,
        loading,
    }
}

export const useConductCoreRun = () => {
    const { getFn } = useFetch()
    const [ loading, setLoading ] = useState(false)

    const conductCoreRun = useCallback(async (customer) => {
        setLoading(true)
        const user = localStorage.getItem('email')
        await getFn('/price_update/conduct_core_run/', { customer, user })
        setLoading(false)
    }, [])

    return {
        conductCoreRun,
        loading,
    }
}

export const useCheckTaskStatus = () => {
    const { getFn } = useFetch()
    const [ loading, setLoading ] = useState(false)
    const [ statusData, setStatusData ] = useState<any[]>([])

    const checkTaskStatus = useCallback(async () => {
        setLoading(true)
        const result = await getFn('/price_update/check_task_status/')
        if (result?.error) return setLoading(false)

        let data = result?.data || {}
        const list: any[] = []
        Object.keys(data || {}).forEach(status => {
            data[status]?.forEach(d => {
                list.push({
                    status,
                    ...d,
                })
            })
        })
        setStatusData(list)
        setLoading(false)
    }, [])

    useEffect(() => {
        checkTaskStatus()
    }, [])

    return {
        checkTaskStatus,
        loading,
        statusData,
    }
}

export const useCancelCoreRun = () => {
    const { getFn } = useFetch()
    const [ loading, setLoading ] = useState(false)

    const cancelCoreRun = useCallback(async (customer) => {
        setLoading(true)
        const user = localStorage.getItem('email')
        await getFn('/price_update/cancel_core_run/', { customer, user })
        setLoading(false)
    }, [])

    return {
        cancelCoreRun,
        loading,
    }
}
