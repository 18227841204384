import { useFavoriteList } from 'hooks/useGlobalData'
import { useFetch } from 'libs'

export const useFavorite = () => {
    const [ favoriteList, setFavoriteList ] = useFavoriteList()
    const { postFn, delFn } = useFetch()

    const createFavorite = async (name: string, path: string) => {
        // 菜单已存在，直接返回
        if (pinFavorite(name)) {
            return
        }

        // 菜单不存在，添加到收藏列表
        const payload = {
            url: '/myfavorite',
            query: {
                name,
                path,
            },
        }
        const result = await postFn(payload.url, payload)
        if (!result?.data?.success) {
            return
        }
        const data = result?.data?.result || {}
        setFavoriteList([
            ...favoriteList, 
            data,
        ])
    }

    const removeFavorite = async (name: string) => {
        // 校验参数
        const id = favoriteList.find(item => item.name === name)?.id
        if (!id) return

        const payload = {
            url: '/myfavorite',
            query: { id },
        }
        await delFn(payload.url, payload)

        setFavoriteList(favoriteList.filter(item => item.name !== name))
    }

    const pinFavorite = (name) => {
        return !!favoriteList.find(item => item.name === name)
    }

    return {
        favoriteList,
        createFavorite,
        removeFavorite,
        pinFavorite,
    }
}
