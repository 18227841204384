import { REQUEST_LOGIN, DISABLE_CANCEL_REQUEST } from 'consts'
import { RoutesObj } from 'global/Routes'
import { notification } from 'antd'
import { recordErrorLog } from 'utils/logUtils'
import { lsSet } from 'utils/ls'
import { storage } from 'utils/storage'
import { CONST_VARS } from './const'
import AuthToken from '../utils/AuthToken'

const ENABLE_POST_CACHE = 'POST_REQ_ENABLE_CACHE'

// Request Cache object
const CacheRequest = {}
// eslint-disable-next-line @typescript-eslint/no-var-requires
const qs = require('qs')
// business code

export const ConstCode = {
  OK: 200,
  ERR: -1,
  CANCEL: 0,
  NETWORK_ERROR: 9999,
}

const axios = require('axios').default

const instance = axios.create({
  baseURL: CONST_VARS.API_URL,
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
  },
})

const { Cancel } = axios
const currentRequest = {}

// ******************************** 取消上一次未完成的请求 Start ***************************************

// 是否取消重复请求开关
const cancelDuplicated = true

// 存储每个请求中的map
const pendingXHRMap = new Map()

// 取消请求类型定义 便于后期对此类型不做异常处理
const REQUEST_TYPE = {
  DUPLICATED_REQUEST: 'duplicatedRequest',
}

// 设置重复标记的函数
const duplicatedKeyFn = config => 
  // 可在此设置用户自定义其他唯一标识 默认按请求方式 + 请求地址
   `${config.method}-${(config.url || '').split('?')[0]}`

// 添加到请求记录
const addPendingXHR = config => {

  // disable `Cancel Request` or not
  if (judgeDisabledCancelRequestFn(config.data)) return
  
  if (!cancelDuplicated) {
    return
  }
  const duplicatedKey = JSON.stringify({
    duplicatedKey: duplicatedKeyFn(config),
    type: REQUEST_TYPE.DUPLICATED_REQUEST,
  })
  config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
    if (duplicatedKey && !pendingXHRMap.has(duplicatedKey)) {
      pendingXHRMap.set(duplicatedKey, cancel)
    }
  })
}

// 删除请求记录
const removePendingXHR = config => {

  // disable `Cancel Request` or not
  if (judgeDisabledCancelRequestFn(config.data)) return

  if (!cancelDuplicated) {
    return
  }
  const duplicatedKey = JSON.stringify({
    duplicatedKey: duplicatedKeyFn(config),
    type: REQUEST_TYPE.DUPLICATED_REQUEST,
  })
  if (duplicatedKey && pendingXHRMap.has(duplicatedKey)) {
    const cancel = pendingXHRMap.get(duplicatedKey)
    cancel(duplicatedKey)
    pendingXHRMap.delete(duplicatedKey)
  }
}

const handleUrl = config => {
  if (!cancelDuplicated) {
    return
  }
  const urls = [ 'account', 'metadata', 'dashboard_get_target_group_region_currency' ]
  const duplicatedKey = JSON.stringify({
    duplicatedKey: duplicatedKeyFn(config),
    type: REQUEST_TYPE.DUPLICATED_REQUEST,
  })
  const token = AuthToken.getAccessToken()
  const url = config?.url

  if (!token && urls.some(u => url.startsWith(u)) && pendingXHRMap.has(duplicatedKey)) {
    const cancel = pendingXHRMap.get(duplicatedKey)
    cancel(duplicatedKey)
    pendingXHRMap.delete(duplicatedKey)
  }
}

// 拦截请求
instance.interceptors.request.use(
  config => {
    removePendingXHR(config)
    addPendingXHR(config)
    /**
     * 目前在登录页面会发三个请求，而这三个请求都需要 token 才能拿到数据
     * 能跳转到登录页通常是因为 token 过期或不存在
     * 这样就会造成这三个接口一直报错 401，后台会疯狂记录错误日志
     * 暂时先对这三个接口特殊处理，后面考虑登录成功后再发出这三个请求
     */
    handleUrl(config)
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

// 拦截响应
instance.interceptors.response.use(response => {
  removePendingXHR(response.config)
  return response
}, error => {
  // 如果是取消请求类型则忽略异常处理
  let isDuplicatedType
  try {
    const errorType = (JSON.parse(error.message) || {}).type
    isDuplicatedType = errorType === REQUEST_TYPE.DUPLICATED_REQUEST
  } catch (error) {
    isDuplicatedType = false
  }
  if (!isDuplicatedType) {
    // 其他异常处理
    console.error(error.message)
  }
  return Promise.reject(error)
})

// ******************************** 取消上一次未完成的请求 End ***************************************

const abortPending = urlstr => {
  if (currentRequest[urlstr]) {
    // ...
  }
}

function objeMapQueryStr(data) {
  const datas = []
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in data) {
    datas.push(key + '=' + (typeof data[key] === 'object' ? JSON.stringify(data[key]) : encodeURIComponent(data[key])))
  }
  return datas.join('&')
}

// 根据 url、method、data 生成一条请求的唯一标识
function genUniqueUrlStr(config) {
  const data = config.data || {}
    const datas = objeMapQueryStr(data)
  return [
    data.requestId || '',
    config.method.toUpperCase(),
    config.url,
    datas.join(','),
  ].join('-')
}

/**
 * abort request manually
 * @param method
 * @param url
 * @param param
 */
export function abortRequest(method, url, param = {}) {
  const urlStr = genUniqueUrlStr({
    method,
    url, data: param,
  })
  abortPending(urlStr)
}

export function abortGetFn(url) {
  const urlStr = genUniqueUrlStr({
    method: 'get',
    url, data: {},
  })
  abortPending(urlStr)
}

function updateTk(url) {
  const tk = AuthToken.getAccessToken()

  if (tk && url.indexOf(REQUEST_LOGIN) === -1) {
    instance.defaults.headers.common.Authorization = 'Bearer ' + tk
  } else {

    delete instance.defaults.headers.common.Authorization
    lsSet('Authorization', '')
  }
}

/**
 * 
 * @param {} pro 
 * @param {*} cacheKey      cache key
 * @param {*} enableCache   enable cache or not
 * @returns 
 */
function resHandler(pro, cacheKey = null, enableCache = false, url, data) {
  return new Promise((resolve, reject) => {
    pro
      .then(function (response) {
        const res = {
          data: response.data,
          code: ConstCode.OK,
        }
        if (cacheKey && enableCache) {
          CacheRequest[cacheKey] = res
        }
        resolve(res)
      })
      .catch(async err => {
        if (err instanceof Cancel) {
          console.warn('canceled->' + err.message || '')
          resolve({
            code: ConstCode.CANCEL,
          })
        } else {
          console.warn('failed->' + err.message || '')
          const resp = err.response || { data: { message: 'request error' } }

          // 测试时用 404, 实际上 Network Error 是 err?.response === undefined
          // if (err?.response?.status === 404 && (data?.retry || 1) < 4) {
          if (!err?.response && (data?.retry || 1) < 4) {
            resolve({
              code: ConstCode.NETWORK_ERROR,
              retry: data?.retry || 1,
            })
            if ((data?.retry || 1) < 3) {
              return
            }
          }

          // 网络状态码不等于200和401的全部记录日志
          if (resp.status !== 200 && resp.status !== 401 && url.indexOf('service_error') === -1) {
            try {
              const errorInfo = `
                【Recorder】: ${localStorage.getItem('email')} \n
                【License】: ${storage.getCustomerVendor()} \n
                【Request URL】: ${url} \n
                【Request Data】: ${data ? JSON.stringify(data) : ''} \n
                【Error Message】: ${err?.message || ''} \n
                【Request Info】: ${err?.request ? JSON.stringify(err?.request) : ''} \n
                【Response Info】: ${err?.response ? JSON.stringify(err?.response) : ''} \n
              `
              recordErrorLog(errorInfo)
            // eslint-disable-next-line no-empty
            } catch (e) {
            }
          }

          if (resp.status === 401 || resp.status === 403) {
            lsSet('email', '')
            const { pathname } = window.location
            const token = AuthToken.getAccessToken()

            if (![ RoutesObj.LOGIN.URL, RoutesObj.FORGOT_PASSWORD.URL ].includes(pathname) && token){
              window.location.href = RoutesObj.LOGIN.URL
            }
          }

          if (resp.status !== 401) {
            notification.open({
              message: 'The request cannot be completed at the moment, please try again later. If the issue persists, please contact our support. ',
              duration: null,
              placement: 'bottomRight',
            })
          }

          reject({
            code: ConstCode.ERR,
            status: resp.status || ConstCode.ERR,
            statusText: resp.statusText || 'no statusText',
            msg: err.message || 'error message',
            ...resp.data,
          })
        }

      })
  })
}

const formatRequestUrlKeyFn = (type, url, query = {}, form = {}) => `${type}${url}${qs.stringify(query)}${qs.stringify(form)}`

export function deleteFn(url, data = {}, config = {}) {
  updateTk(url)
  let { query, data: formData } = data
  if (!query) query = {}
  if (!formData) formData = data
  const key = formatRequestUrlKeyFn('del', url, query, formData)
  /**
   * 目前参数相同时会直接返回缓存数据
   * loading 值不会改变，导致页面没有加载动画出现，看起来就像无响应一样
   * 暂时注释缓存拿数据机制，等修改 loading 问题后再解除注释
   */
  return resHandler(instance.delete(`${url}?${qs.stringify(query)}`, formData || {}, config), key, undefined, url, data)
}

export function getFn(url, data, config = {}) {
  updateTk(url)
  const queryStr = objeMapQueryStr(data)
  const key = formatRequestUrlKeyFn('get', url, data, {})
  /**
   * 目前参数相同时会直接返回缓存数据
   * loading 值不会改变，导致页面没有加载动画出现，看起来就像无响应一样
   * 暂时注释缓存拿数据机制，等修改 loading 问题后再解除注释
   */
  if (!data.force && CacheRequest[key]) {
    return CacheRequest[key]
  }
  return resHandler(instance.get(`${url}?${queryStr}`, config), key, undefined, url, data)
}

/**
 *
 * @param url
 * @param data : two form data, eg : {} or {query:{},data:{}}, query will append to url
 * @param config
 * @returns {Promise<unknown>}
 */
export function postFn(url, data, config = {}) {
  updateTk(url)
  let { query, data: formData } = data
  if (!query) query = {}
  if (!formData) formData = data
  const key = formatRequestUrlKeyFn('post', url, query, formData)

  /**
   * 目前参数相同时会直接返回缓存数据
   * loading 值不会改变，导致页面没有加载动画出现，看起来就像无响应一样
   * 暂时注释缓存拿数据机制，等修改 loading 问题后再解除注释
   */
  const enableCache = data[ENABLE_POST_CACHE]
  if (enableCache && CacheRequest[key]) {
    return CacheRequest[key]
  }
  const queryStr = qs.stringify(query)
  return resHandler(instance.post(`${url}${queryStr ? '?' : ''}${queryStr}`, REQUEST_LOGIN === url ? qs.stringify(formData || {}) : formData, config), key, enableCache, url, data)
}

export async function postAsync(url, data) {
  updateTk(url)
  // eslint-disable-next-line no-return-await
  return await resHandler(instance.post(url, data || {}), undefined, undefined, url, data)
}

export function putFn(url, data) {
  updateTk(url)
  return resHandler(instance.put(url, data || {}), undefined, undefined, url, data)
}

export function patchFn(url, data, config = {}) {
  updateTk(url)
  let { query, data: formData } = data
  if (!query) query = {}
  if (!formData) formData = data
  const key = formatRequestUrlKeyFn('patch', url, query, formData)
  /**
   * 目前参数相同时会直接返回缓存数据
   * loading 值不会改变，导致页面没有加载动画出现，看起来就像无响应一样
   * 暂时注释缓存拿数据机制，等修改 loading 问题后再解除注释
   */
  return resHandler(instance.patch(`${url}?${qs.stringify(query)}`, formData || {}, config), key, undefined, url, data)
}

/**
 * disable cancel request.
 * @param {*} args 
 * @returns 
 */
export const disableCancelRequestFn = args => {
  args.data[DISABLE_CANCEL_REQUEST] = true
  return args
}

/**
 * judge `post data` include `DISABLE_CANCEL_REQUEST` or not
 * @param {*} data  post data
 * @returns 
 */
export const judgeDisabledCancelRequestFn = (data = {}) => data?.[DISABLE_CANCEL_REQUEST]
