import React from 'react'
import { useLoadingBar, useScrollToTop } from 'hooks'
import { PriceDriversPeriodToPeriodTable } from './components/PriceDriversPeriodToPeriodTable'

export const PriceDriversPeriodToPeriod = () => {
  useScrollToTop()
  useLoadingBar()

  return (
      <div style={{ background: '#fff', position: 'relative' }}>
        <PriceDriversPeriodToPeriodTable />
      </div>
  )
}
