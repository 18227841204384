import { useEffect, useCallback } from 'react'
import { Chart } from 'libs/react-chartjs-lib'
import { lsGet } from 'utils/ls'
import { handleOverlay } from '../utils'
import { OverlayType } from '../types'

export const useHoverVerticalLine = () => {
    useEffect(() => {
        Chart.pluginService.register({
            afterDraw: chart => {
                if (chart.tooltip._active && chart.tooltip._active.length) { // eslint-disable-line
                    const activePoint = chart.controller.tooltip._active[0] // eslint-disable-
                    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/750#note_1266188826
                    if (activePoint?._options?.radius === 0) return
                    const { ctx } = chart
                    const { x } = activePoint.tooltipPosition()
                    const topY = chart.scales['left-y-axis'] ? chart.scales['left-y-axis'].top : 0
                    const bottomY = chart.scales['left-y-axis'] ? chart.scales['left-y-axis'].bottom : 0
                    ctx.save()
                    ctx.beginPath()
                    ctx.moveTo(x, topY)
                    ctx.lineTo(x, bottomY)
                    ctx.lineWidth = 1
                    ctx.strokeStyle = '#dfdfdf'
                    ctx.stroke()
                    ctx.restore()
                }
            },
        })
    }, [])
}

const afterDrawer = (chart: any) => {
    try {
        if (!chart?.canvas) return
        const $priceRange = lsGet('price_range', false)

        // price range value 
        const overlay = JSON.parse($priceRange) as OverlayType

        if (!overlay.limitMax || !overlay.max) return
        const { chartArea } = chart as any

        const a = 145 // bar width
        const b = 162 // slider width 
        // const extraRatio = (b - a) / b;
        const minRatio = overlay.min / overlay.limitMax
        const rect = chart.canvas.getBoundingClientRect()

        // min area overlay
        // if (minRatio > extraRatio) {
        let w = minRatio * b - 9
        if (w < 0) w = 0
        handleOverlay(chart, chartArea.left, chartArea.top, w, rect.height, 'overlay-left')
        // }

        const maxRatio = (overlay.limitMax - overlay.max) / overlay.limitMax

        // max area overlay
        if (maxRatio) {
            let w2 = maxRatio * b - 9 
            if (a < w2) w2 = a
            handleOverlay(chart, rect.width - w2, chartArea.top, w2, rect.height, 'overlay-right')
        }

    } catch (error) {

        console.log(error)

    }
}

/**
 *  ## 构建Price range 的overlay
 *  ### 关键点
 *  1.  register chart plugin
 *  2.  以dom 元素构建overlay
 * 
 * @returns 
 * 
 */
export const useBarSliderChartOverlay = () => {
    // const drawerFn = useCallback(() => {

    //     // Chart.pluginService.register({
    //     //     afterDraw: afterDrawer,
    //     // })

    // }, [])

    /**
     *  ## clear previous overlay
     */
    const clearOverlay = useCallback(() => {

        document.querySelector('#overlay-left')?.remove()
        document.querySelector('#overlay-right')?.remove()

    }, [])

    useEffect(() => { // eslint-disable-line

        // drawerFn()
        return () => {
            clearOverlay() //
        }
    }, []) // eslint-disable-line
    return {
        clearOverlay,
        afterDrawer,
    }
}
