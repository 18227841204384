/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Navigation } from 'components/layout';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { RoutesObj } from 'global/Routes';
import { Text } from 'components/typography';
import { ArrowDirectionIcon } from 'assets/icons/ArrowDirection';
import { LookbookProductsRow } from 'components/Lookbooks/LookbookProductsRow';
import { useGetLookbookproductInfo } from 'graphql/lookbook/getLookbookproductInfo';
import { NoCategory } from 'assets/icons/Loading';
  import styles from './styles.module.scss';
import { ExportDropdown } from 'componentsv2/ExportDropdown';
import { argFnLookbookExportProduct } from 'graphql/nornaapi/lookbook';
import { LOOKBOOK_PERMISSION } from 'consts';
import { useSelector } from 'react-redux';
import { getCacheData } from 'features/filters/cacheDataSlice';
import { Spin } from 'componentsv2/Spin';
import { downloadFileByAxios } from 'export';
import { useMenuAndFilterPermission } from 'hooks/useQa';
import { mySavedLookbooksExportFilename } from 'utils/exportUtils';
import { useLookbook } from 'hooks/useLookbook';
import { SizedBox } from 'componentsv2/SizedBox';

const SortTypeConst = {
  DATE: '',
  CATEGORY: 'category',
  VENDOR: 'vendor',
  REGION: 'region',
}

const dateProductFn = list => {
  let num = 0;
  let avg = 0;
  avg = list.map(item => {
    num += item.num;
    return item.avg * item.num;
  }).reduce((t, n) => t + n, 0);
  return {
    num,
    avg: avg / num,
  }
}

export const LookbookPage = () => {
  const history = useHistory();

  // eslint-disable-next-line
  const { lookbookId } = useParams() as any
  const { data, loading, refetch } = useGetLookbookproductInfo(lookbookId);

  const cache = useSelector(getCacheData)

  const { myLookbookList } = useLookbook()
  const [currentLookbook, setCurrentLookbook] = useState({ name: '', desc: '', lookbookproductions: [] })
  const currentLookbookData = (myLookbookList || []).filter(item => item.id === lookbookId)[0]

  useEffect(() => {
    if (myLookbookList && myLookbookList.length > 0) {
      setCurrentLookbook(currentLookbookData); // get the current lookbook
    }
  }, [myLookbookList, lookbookId, currentLookbookData, setCurrentLookbook])

  const goToLookbooks = () => {
    history.push(`${RoutesObj.LOOKBOOL_LIST.URL}`);
  }

  const { unavailableMenu } = useMenuAndFilterPermission()

  const dateInfo = dateProductFn((data?.result ?? []).filter(item => item.type === SortTypeConst.VENDOR).map(item => ({
    num: item.count,
    avg: item.convertedAveragePrice,
  })))

  const dataTmp = [{
    category: 'All Options',
    convertedAveragePrice: dateInfo.avg,
    count: dateInfo.num,
    currency: data?.result?.length ? data?.result[0]?.currency : '-',
  }]

  let disabledExport = (data?.result ?? []).length === 0;

  if (unavailableMenu.indexOf(LOOKBOOK_PERMISSION) > -1) {
    disabledExport = true;
  }

  return (
    <>
      <Navigation>
        <div className={styles.lookbookHeaderContainer}>
          <div className={styles.lookbookHeader}>
            <div className="flex flex-row flex-start">
              <div className={classnames(styles.infoTop, 'flex flex-row flex-start')}>
                <Text xxl className={styles.AllProductsText}>
                  {currentLookbook?.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>
              </div>
            </div>
            <div className={styles.backLookbookBtn} onClick={goToLookbooks}>
              <Text> <ArrowDirectionIcon /> Back to lookbooks</Text>
            </div>
          </div>
          <div className={styles.analysisBtn}>
            <div>
              <ExportDropdown
                showJPG={false}
                showCLIPBOARD={false}
                disabled={disabledExport}
                onExportExcel={async () => {
                  let query = argFnLookbookExportProduct(lookbookId)
                  await downloadFileByAxios({
                    filename: mySavedLookbooksExportFilename,
                    method: 'GET',
                    url: query.url,
                    params: {
                      id: lookbookId,
                      customer: cache?.account?.company?.code,
                    },
                  })
                }}
              />
            </div>
          </div>
        </div>
      </Navigation>
      <Spin spinning={loading}>
        <SizedBox height={30} />
        {
          data?.result?.length ? dataTmp.map((item, index: number) => <LookbookProductsRow
            key={item.category}
            rowKey={index}
            title=""
            onDel={async () => {
              await refetch()
            }}
            type=""
            count={item.count}
            averagePrice={item.convertedAveragePrice}
            currency={item.currency}
          />) : (<NoCategory>No Product Found</NoCategory>)
        }
      </Spin>
    </>
  );
};
