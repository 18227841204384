import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { useFilterGender } from 'hooks/useFilter'
import { TagWithTips } from 'componentsv2/MenuBar/FilterTags'
import { formatVendorName } from 'utils/sellers'
import { Flex, Flex1 } from 'componentsv2/Flex'
import { CATEGORY_TOTAL, IS_LIVE_ENV, MARKET_VENDOR, SELECTED_CATEGORY, SUMMARY } from 'consts'
import { useLookbook } from 'hooks/useLookbook'
import styles from './styles.module.scss'
import { MenuButton } from '../MenuButton'
import { ClothesIcon } from 'druikit'

export const TableCategoryCell = ({
    text,
    record,
    width,
    isCompetitorView,
    showMenuButton = false,
    isDeepBorderRight = false,
}: {
    text: string;
    width: number;
    record?: any;
    isCompetitorView?: boolean;
    showMenuButton?: boolean;
    isDeepBorderRight?: boolean;
}) => {
    const [ filterGender ] = useFilterGender()
    const { isLookbook } = useLookbook() 

    const sty: CSSProperties = {}
    if (isDeepBorderRight) {
      sty.borderRight = '1px solid #01A699'
    }

    /**
     * 生产环境 All categories 和 All vendors 不显示 MenuButton
     */
    let showMenu = true
    if (IS_LIVE_ENV && record?.categoryName === CATEGORY_TOTAL) {
      showMenu = false
    }
    if (IS_LIVE_ENV && record?.categoryName === SELECTED_CATEGORY) {
      showMenu = false
    }
    if (IS_LIVE_ENV && record?.vendorCode === MARKET_VENDOR.vendor) {
      showMenu = false
    }
    if (record?.vendorCode === SUMMARY) {
      showMenu = false
    }
    if (isLookbook) {
      showMenu = false
    }

    if (showMenuButton && showMenu) {
      return (
        <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{ width: `${width}px`, ...sty }}
            className={classnames(styles.tablecell1, 'text-ellipsis')}
        >
              { 
                isCompetitorView ? (
                  <ClothesIcon clothes={text} targetGroup={filterGender.length === 1 ? filterGender[0] : 'All Options'} />
                ) : null 
              }
              { isCompetitorView ? text : 
                (
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <TagWithTips width={`${width - 40}px`} label={formatVendorName(text).name} />
                    <div style={{
                      fontSize: '11px', transform: 'scale(0.9)',
                      transformOrigin: 'left',
                      whiteSpace: 'nowrap',
                    }}
                    >
                      {formatVendorName(text).platform}
                    </div>
                  </div>
                )
              }
              <Flex1 />
              <MenuButton vendor={record.vendorCode} category={record.categoryName} />
        </Flex>
      )
    }

    return (
        <div
          style={{ width: `${width}px`, ...sty }}
          className={classnames(styles.tablecell1, 'text-ellipsis')}
        >
            { 
              isCompetitorView ? (
                <ClothesIcon clothes={text} targetGroup={filterGender.length === 1 ? filterGender[0] : 'All Options'} />
              ) : null 
            }
            { isCompetitorView ? text : 
              (
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                  <TagWithTips width={`${width}px`} label={formatVendorName(text).name} />
                  <div style={{
                    fontSize: '11px', transform: 'scale(0.9)',
                    transformOrigin: 'left',
                    whiteSpace: 'nowrap',
                  }}
                  >
                    {formatVendorName(text).platform}
                  </div>
                </div>
              )
            }
        </div>
    )
}
