import React from 'react'
import * as cssConfig from 'configs/css'
import { DisplayText } from 'componentsv2/DisplayText'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'

export const NornaBarChart = ({
    direction = 'horizontal',
    currentValue,
    comparisonValue,
    maxValue,
    changePercent,      // 0-1
}: {
    direction?: 'horizontal' | 'vertical',
    currentValue: number;
    comparisonValue: number;
    maxValue: number;
    changePercent: number;
}) => {
    const currentValuePercent = currentValue / maxValue
    const comparisonValuePercent = comparisonValue / maxValue
    const percentBarColor = changePercent >= 0 ? cssConfig.primaryColor : cssConfig.redColor1

    if (direction === 'horizontal') {
        return (
            <div className={styles.horizontalBar}>
                <div className={styles.valueBar}>
                    <div className={styles.currentBar} style={{ width: currentValuePercent * 100 + '%' }} />
                    <div className={styles.currentValue}>{numberUtils.formatNumberByComma(currentValue)}</div>
                    <div className={styles.comparisonLine} style={{ width: comparisonValuePercent * 100 + '%' }} />
                    <div className={styles.comparisonValue} style={{ left: comparisonValuePercent * 105 + '%' }}>({numberUtils.formatNumberByComma(comparisonValue)})</div>
                </div>
                <div className={styles.percentBar}>
                    <div className={styles.changeBar} style={{ width: Math.abs(Math.min(changePercent, 1)) * 100 + '%', backgroundColor: percentBarColor }} />
                    <div className={styles.changePercent} style={{ color: percentBarColor, paddingTop: '4px' }}>
                        <DisplayText value={(changePercent * 100).toFixed(1) + '%'} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.verticalBar}>
            <div className={styles.valueBar}>
                <div className={styles.currentBar} style={{ height: currentValuePercent * 100 + '%' }} />
                <div className={styles.currentValue}>{numberUtils.formatNumberByComma(currentValue)}</div>
                <div className={styles.comparisonLine} style={{ height: comparisonValuePercent * 100 + '%' }} />
                <div className={styles.comparisonValue} style={{ bottom: comparisonValuePercent * 105 + '%' }}>({numberUtils.formatNumberByComma(comparisonValue)})</div>
            </div>
            <div className={styles.divideLine} />
            <div className={styles.percentBar}>
                <div className={styles.changeBar} style={{ height: Math.abs(Math.min(changePercent, 1)) * 100 + '%', backgroundColor: percentBarColor }} />
                <div className={styles.changePercent} style={{ color: percentBarColor }}>
                    <DisplayText value={(changePercent * 100).toFixed(1) + '%'} />
                </div>
            </div>
        </div>
    )
}
