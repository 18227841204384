import { useApiData, useApiLoading, useDynamicDashboardRequests, usePovValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import React, { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Flex } from 'druikit'
import { handleCardDataSource, handleLineChartData } from 'pages/DynamicDashboard/utils'
import { useDiscountEffectProducts, useFetchGraphData } from 'pages/DynamicDashboard/hooks/useApi'
import { MetricBarChartCard, MetricCard, MetricLineChartCard, MetricProductCard } from '../MetricCard'
import { MetricNav } from '../MetricNav'
import { cloneDeep } from 'lodash'
import { useFilterCurrency } from 'hooks/useFilter'
import { numberUtils } from 'norna-uikit'

export const ModalDiscountEffect = () => {
    const [ apiData ] = useApiData()
    const [ apiLoading ] = useApiLoading()
    const [ povValue ] = usePovValue()
    const [ currency ] = useFilterCurrency()
    const [ cardDataSource, setCardDataSource ] = useState<any[]>([])
    const [ discountEffectBarChartData, setDiscountEffectBarChartData ] = useState<any[]>([])
    const [ discountEffectLineChartData, setDiscountEffectLineChartData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const metricList = [ 'Discount effect', 'Discount depth', 'Discount width' ]
        const cardDataSource = handleCardDataSource({ apiData, metricList, currency, customerVendor: povValue })
        setCardDataSource([ ...cardDataSource ])

        const discountEffectBarChartData = handleDiscountEffectBarChartData(cardDataSource)
        setDiscountEffectBarChartData([ ...discountEffectBarChartData ])
    }, [ apiData, currency, povValue, {} ])

    const [ requests ] = useDynamicDashboardRequests()
    const {
        discountEffectLineGraphApiData: lineChartData,
        discountEffectLineGraphApiLoading: lineChartLoading,

        discountEffectProductsApiData: products,
        discountEffectProductsApiLoading: productsLoading,
    } = requests
    
    /* ************************** Discount effect 线图 ******************************* */
    useFetchGraphData('Discount effect')

    useDeepCompareEffect(() =>  {
        if (!lineChartData) return
        const discountEffectLineChartData = handleLineChartData(lineChartData, 'Discount effect', povValue)
        setDiscountEffectLineChartData([ ...discountEffectLineChartData ])
    }, [ lineChartData, povValue, {} ])

    /* ************************** 产品卡片 ******************************* */
    useDiscountEffectProducts()

    return (
        <>
            <MetricNav
                name="Discount effect"
                metric="Discount effect"
            />
            <Flex justifyContent="space-between">
                {
                    cardDataSource.map(item => {
                        return (
                            <MetricCard
                                style={{ width: '32%' }}
                                loading={apiLoading}
                                key={item?.title || ''}
                                title={item?.title || ''}
                                dataSource={item?.dataSource || []}
                                showUnits={item.showUnits}
                            />
                        )
                    })
                }
            </Flex>
            <Flex justifyContent="space-between" id="dynamic-dashboard-middle-graph">
                <MetricBarChartCard
                    style={{ width: '38%' }}
                    loading={apiLoading}
                    title="Discount effect"
                    dataSource={discountEffectBarChartData}
                    yAxisFormatter={value => numberUtils.formatNumber(value, { isCommaSymbol: true, isPercentSymbol: true, decimal: 1 })}
                />
                <MetricLineChartCard
                    style={{ width: '60%' }}
                    loading={lineChartLoading}
                    title="Discount effect over time"
                    dataSource={discountEffectLineChartData}
                    yAxisFormatter={value => numberUtils.formatNumber(value, { isCommaSymbol: true, isPercentSymbol: true, decimal: 1 })}
                    tooltipFormatter={value => numberUtils.formatNumber(value, { isCommaSymbol: true, isPercentSymbol: true, decimal: 1 })}
                />
            </Flex>
            <Flex justifyContent="space-between" flexWrap="wrap">
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} discounted options with more than 50% discount`}
                    dataSource={products.customerVendorDiscountEffectMoreThan50Products || []}
                    count={products.customerVendorDiscountEffectMoreThan50Count}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} discounted options with more than 50% discount`}
                    dataSource={products.comparisonVendorDiscountEffectMoreThan50Products || []}
                    count={products.comparisonVendorDiscountEffectMoreThan50Count}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} discounted options with 25% to 49% discount`}
                    dataSource={products.customerVendorDiscountEffectLessThan50Products || []}
                    count={products.customerVendorDiscountEffectLessThan50Count}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} discounted options with 25% to 49% discount`}
                    dataSource={products.comparisonVendorDiscountEffectLessThan50Products || []}
                    count={products.comparisonVendorDiscountEffectLessThan50Count}
                />
            </Flex>
        </>
    )
}

function handleDiscountEffectBarChartData(data) {
    let newData = cloneDeep(data)
    newData = newData.map(item => {
        return {
            label: item.title,
            value: Number(item?.dataSource?.[0]?.value?.replace(',', '').replace('%', '')),
        }
    })
    return newData
}
