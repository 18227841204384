import { isOnlyPlatformNameOnSearchOptions } from './feConfig'
import { formatVendorName } from './sellers'

/**
 * 2022/05/11
 * 格式化所有产品名称
 * 1. 去掉名称中的所有引号 ' ", 保留 [s' ] 和 ['s ]
 * 2. 字符串首字母大写
 * 
 * 测试: zeeman + France + https://www.kiabi.com/veste-en-denim-disney-fille-3-12-ans-bleu_P721110C721112
 */
 export function formatProductName(productName: string) {
    if (!productName) return productName
    const productNameCharArr = productName.toString().split('')
        .filter((char, index, arr) => {
            if (char === '\'' || char === '"') {
                // [s' ] 保留
                if (arr[index - 1] === 's' && arr[index + 1] === ' ') {
                    return true
                }
                // ['s ] 保留
                if (arr[index + 1] === 's' && arr[index + 2] === ' ') {
                    return true
                }
                return false
            } 
            return true
            
        })
    let newProductName = productNameCharArr.join('')
    newProductName = newProductName[0].toUpperCase() + newProductName.slice(1)
    // filter underscores
    newProductName = newProductName.replace(/_/g, '')
    return newProductName
}

/**
 * 产品小卡片中 vendor 格式化
 */
export function formatProductVendorInSmallProductCard(vendor: string) {
    if (!isOnlyPlatformNameOnSearchOptions()) {
        return vendor
    }
    const platform = formatVendorName(vendor).platform
    return platform ? platform?.replace(/\s?@\s?/, '') : vendor
}
