import { ColorItem } from './types'

export const colorAlgorithm = (colorList: Array<ColorItem>) => {
  const colors = colorList.filter(item => item.v > 0.15)
  if (colors.length === 4) {
    return colors
  }
  if (colors.length === 1) {
    return Array.from({ length: 4 }).fill(colors[0])
  }
  if (colors.length === 2) {
    return [ ...colors, ...colors ]
  }
  const sortData = colors.sort(function(a, b) {
    return b.v - a.v
  })
  if (sortData.length === 3) {
    return [ sortData[0], ...sortData ]
  }
  return sortData.slice(0, 4)
}
