import { TdPriceRangeTypeEnum } from 'graphql/nornaapi/types'
import { CSSProperties } from 'react'

export type PriceFilterSelectionModalProps = {
  closeModal: Function;
  isOpen: boolean;
  currentFilter: any;
  type?: TdPriceRangeTypeEnum;
  onChange?: Function;
  modalMaskStyle?: CSSProperties;
};

export interface PriceRangeTriggerPropsType {
  onChange: Function
  type?: TdPriceRangeTypeEnum,
  // min,max value string, eg: `min,max`
  value: string;
}

export enum PriceRangeMemoryStateKey {

  // td query key
  TD = 'td',

  // price range key for td
  TD_PRICE_RANGE = 'price-range-td',

  // price range
  FILTER_PRICE_RANGE = 'price-range-filter',

  // page date
  PAGE_DATE = 'page-date',

  // top filters for displaying
  TOP_FILTER = 'top-filter',
  // new Pricing Dashboard
  NEW_TD_PRICING = 'new-td-prcing',

  // tmp filters changed
  TMP_FILTED ='tmp-filter'
}

export interface TdPriceRangePreviousQueryType {
   previousQuery:any;
   previousRes:any;
}

export interface NornaBarPropsType {
  bars:Array<number>;
}
