import React from 'react'
import classnames from 'classnames'
import { Text } from 'components/typography/Text'
import * as cssConfig from 'configs/css'
import { Collapse } from '../Collapse'
import styles from './styles.module.scss'

export const SellerPerSize = ({
    bottom = 0,
    sizePriceList = [],
}: {
    bottom?: number;
    sizePriceList: any[];
}) => {
    return (
        <Collapse title="Seller per size" bottom={bottom}>
            {
                sizePriceList?.length ? (
                    <>
                        <div className={styles.thead}>
                            <div>SIZES</div>
                            <div>SHOP</div>
                        </div>
                        {
                            sizePriceList.map(item => {
                                const isHighlight = Number((item?.discountPercentage || '').replace('%', '')) > 0
                                const style = { color: isHighlight ? cssConfig.primaryColor : '#666' }
                                return (
                                    <div className={styles.tr} key={item.size}>
                                        <div style={{ width: 125 }}>{item?.size}</div>
                                        <div className={styles.shop} style={{ ...style }}>{item?.shop}</div>
                                    </div>
                                )
                            })
                        }
                    </>
                ) : (
                    <Text small>No size info</Text>
                )
            }
        </Collapse>
    )
}
