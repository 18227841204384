import { CATEGORY_TOTAL, IS_LIVE_ENV } from 'consts'
import { isColorSplitPage, isDashboardPages } from 'global/Routes'
import { getFilterCategoryMaxCountInLive, isNornaMember } from 'utils'
import { intersection } from 'utils/array'
import { storage } from 'utils/storage'
import { DataType } from 'componentsv2/Tree/common'
import { cloneDeep, uniq } from 'lodash'
import { enableFilterSize, getDisabledCategoryListForColorSplit, getDisabledCategoryListForSizeFilter, getMaxCategoryCount } from 'utils/feConfig'

/**
 * Regardless of whether primary or secondary categories are selected, 
 * the results only return primary categories
 */
export function getFirstLevelCategories({
    selectedCategories = [],
}: {
    selectedCategories: string[];
}) {
    const categories: any[] = storage.getCategoryTreeList()
    let firstLevelCategories: string[] = []
    if (selectedCategories.length === 1 && selectedCategories[0] === CATEGORY_TOTAL) {
        firstLevelCategories = categories.map(item => item.name)
    } else {
        categories.forEach(item => {
            // whether the current category is the parent node
            const isParentNode = Array.isArray(item.list) && item.list.length
            // the current category is parent node, 
            // and the child node and the selected category have intersection
            if (isParentNode && intersection(selectedCategories, item.list.map(item2 => item2.name)).length) {
                firstLevelCategories.push(item.name)
            }
            // the current category is leaf node, 
            // and the selected category include leaf node
            if (!isParentNode && selectedCategories.includes(item.name)) {
                firstLevelCategories.push(item.name)
            }
        })
    }
    return firstLevelCategories
}

/**
 * 获取 categories 叶子节点
 */
export function getLeafCategories({
    selectedCategories = [],
}: {
    selectedCategories: string[];
}): string[] {
    const categoryTreeList = storage.getCategoryTreeList()
    const leafCategories = selectedCategories.map(c => {
        const firstLevelNode = categoryTreeList.find(item => item.name === c)
        if (firstLevelNode && firstLevelNode?.list?.length) {
            return categoryTreeList.find(item => item.name === c)?.list?.map(item => item.name) || []
        }
        return c
    }).filter(item => item).flat(10)
    return leafCategories
}

export function getFilterCategoryMaxCount() {
    if (IS_LIVE_ENV) {
        return getFilterCategoryMaxCountInLive()
    }
    return getMaxCategoryCount()
}

/**
 * 非生产环境, 硬编码添加 All categories 选项
 */
export function getFilterCategoryOptions() {
    const categoryTreeList = storage.getCategoryTreeList()
    if (!IS_LIVE_ENV) {
        categoryTreeList.push({
            isChecked: false,
            list: [],
            name: CATEGORY_TOTAL,
        })
    }
    return categoryTreeList
}

/**
 * 激活 children size filter 有一部分 category 被禁用
 */
export function getDisabledCategoriesForSizeFilter(sizeFilterChecked = false): string[] {
    if (sizeFilterChecked && enableFilterSize()) {
        return getDisabledCategoryListForSizeFilter()
    }
    return []
}

/**
 * 生产环境 Color split 页面有一部分 category 被禁用
 */
export function getDisabledCategoriesForColorSplitPage(): string[] {
    if (IS_LIVE_ENV && (isColorSplitPage())) {
        // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/833#note_1305503208
        const categories = getDisabledCategoryListForColorSplit()
        const leafCategoreis = getLeafCategories({ selectedCategories: categories })
        return uniq([ ...categories, ...leafCategoreis ])
    }

    return []
}

/**
 * 生产环境, 分析页选择两个 maxCount 时, 需要将其它 category 都 disabled 掉不要选
 */
export function handleFilterCategoryMaxCount({
    categoryChildren,
    sizeFilterChecked = false,
}: {
    categoryChildren: any;
    sizeFilterChecked: boolean;
}) {
    const categoryData = cloneDeep(categoryChildren)
    if (IS_LIVE_ENV) {
        const selectedCategories = categoryData.filter(item => !item.disabled && item.active)
        if (selectedCategories.length >= getFilterCategoryMaxCountInLive()) {
            categoryData.forEach(item => {
                if (item.active === false) {
                    item.disabled = true
                    if (Array.isArray(item.chidlren) && item.children.length) {
                        item.children.forEach(item2 => {
                            item2.disabled = true
                            item2.active = false
                        })
                    }
                }
            })
        } else {
            const disabledCategories: string[] = getDisabledCategoriesForSizeFilter(sizeFilterChecked)
            categoryData.forEach(item => {
                if (disabledCategories.includes(item.label)) {
                    item.disabled = true
                    if (Array.isArray(item.chidlren) && item.children.length) {
                        item.children.forEach(item2 => {
                            item2.disabled = true
                            item2.active = false
                        })
                    }
                } else {
                    item.disabled = false
                    if (Array.isArray(item.chidlren) && item.children.length) {
                        item.children.forEach(item2 => {
                            item2.disabled = false
                        })
                    }
                }
            })
        }
    }
    return categoryData
}

export function getFilterCategoryChildren({
    sizeFilterChecked = false,
    categories = [],
}: {
    sizeFilterChecked: boolean;
    categories: string[];
}) {
    // 获取 category list, 非生产环境添加 all categories
    const categoryOptions = getFilterCategoryOptions()
    const disabledCategoriesForSizeFilter = getDisabledCategoriesForSizeFilter(sizeFilterChecked)
    const disabledCategoriesForColorSplitPage = getDisabledCategoriesForColorSplitPage()
    const firstLevelCategories = getFirstLevelCategories({ selectedCategories: categories })

    // 生成 tree node
    let categoryChildren = categoryOptions.map(categoryItem => {
        const firstLevelName = categoryItem.name
        const isFirstLevelDisabled = disabledCategoriesForSizeFilter.includes(firstLevelName)
        const isFirstLevelActive = isFirstLevelDisabled ? false : categories.includes(firstLevelName)
        const isFirstLevelMasked = disabledCategoriesForColorSplitPage.includes(firstLevelName)
        const newItem: any = {
            ...categoryItem,
            type: DataType.CHECK,
            active: isFirstLevelActive,
            disabled: isFirstLevelDisabled,
            masked: isFirstLevelMasked,
            label: firstLevelName,
            val: firstLevelName,
            enableSelectAll: true,
            children: null,
        }
        if (categoryItem.list?.length) {
            const firstLevelChildren = categoryItem.list.map(childrenCategoryItem => {
                const secondLevelName = childrenCategoryItem.name
                const isSecondLevelDisabled = disabledCategoriesForSizeFilter.includes(secondLevelName)
                // 子 category 如果激活, 父 category 也需要设置激活状态
                let isSecondLevelActive = isFirstLevelDisabled ? false : categories.includes(secondLevelName)
                if (isSecondLevelActive) {
                    newItem.active = true
                }
                // 父 category 激活, 子 category 也需要设置激活
                if (isFirstLevelActive) {
                    isSecondLevelActive = true
                }

                return {
                    ...childrenCategoryItem,
                    active: isSecondLevelActive,
                    disabled: isSecondLevelDisabled,
                    type: DataType.CHECK,
                    label: secondLevelName,
                    val: secondLevelName,
                    enableSelectAll: true,
                }
            })
            // newItem.keepLast = firstLevelCategories?.length === 1
            newItem.keepLast = false
            newItem.children = firstLevelChildren
        }
        return newItem
    })

    categoryChildren = handleFilterCategoryMaxCount({ categoryChildren, sizeFilterChecked })

    return categoryChildren
}

export function handleFilterCategorySelectAll(categoryData: any) {
    const newItem = cloneDeep(categoryData)
    const isActive = newItem.children
        .filter(item => !item.disabled)
        .filter(item => item.label !== CATEGORY_TOTAL)
        .every(item => item.active)
    // 全选 -> 取消全选
    if (isActive) {
        newItem.children.forEach((item, index) => {
            // if (index === 0) {
            //     item.active = true
            //     if (Array.isArray(item.children)) {
            //         item.children.forEach(item2 => item2.active = true)
            //     }
            // } else {
            //     item.active = false
            //     if (Array.isArray(item.children)) {
            //         item.children.forEach(item2 => item2.active = false)
            //     }
            // }
            item.active = false
            if (Array.isArray(item.children)) {
                item.children.forEach(item2 => item2.active = false)
            }
        })
    }
    // 取消全选 -> 全选
    else {
        newItem.children.forEach(item => {
            if (!item.disabled && item.label !== CATEGORY_TOTAL) {
                item.active = true
                if (Array.isArray(item.children)) {
                    item.children.forEach(item2 => {
                        item2.active = !item2.disabled
                    })
                }
            } else {
                item.active = false
                if (Array.isArray(item.children)) {
                    item.children.forEach(item2 => item2.active = false)
                }
            }
        })
    }
    return newItem
}

/**
 * Filter > Category 是否可以全选
 */
export function isFilterCategorySelectAll() {
    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/799#note_1284418388
    if (isDashboardPages()) {
        return true
    }
    if (IS_LIVE_ENV && isNornaMember()) {
        return true
    }
    /**
     * 生产环境 max_category_count = 9999 的都可以全选
     */
    if (IS_LIVE_ENV) {
        return getMaxCategoryCount() === 9999
    }
    return true
}

/**
 * 子节点全部选中就返回父节点, 子节点没有全部选中, 就返回子节点
 */
export function getDisplayCategoryValue(selectedCategories: string[] = []) {
    const categories: Array<string> = []
    const categoryTreeList = storage.getCategoryTreeList()
    categoryTreeList.forEach(item => {
        if (item?.list?.length) {
            const subSelected: Array<string> = []
            item.list.forEach(categoryItem => {
                if (selectedCategories.includes(categoryItem.name)) {
                    subSelected.push(categoryItem.name)
                }
            })
            // 子类被全选, 则只显示父级
            if (subSelected.length === item.list.length) {
                categories.push(item.name)
                // 否则显示选中的子类
            } else {
                categories.push(...subSelected)
            }
        } else if (selectedCategories.includes(item.name)) {
            categories.push(item.name)
        }
    })
    return uniq(categories)
}
