import React, { ReactNode, CSSProperties, useState } from 'react'
import classnames from 'classnames'
import { getDashboardValueByOptions } from 'utils/hardCoded/dashboard'
import { cloneDeep } from 'lodash'
import { NA } from 'consts'
import { InlineLoading } from 'assets/icons/'
import styles from './styles.module.scss'
import { isAttributeRankingPage, isCrossRegionSoldOutPage } from 'global/Routes'

export const TableCell = ({
    text,
    record = {},
    children,
    width,
    style = {},
    isComparisonField = false,
    isFirstMetric = false,
    isDeepBorderRight = false,
    isShadowBg = false,
    isWhiteBg = false,
    isEmptyBorderBottom=false,
    isEmptyBorderRight=false,
    isClickable = false,
    onClick,
    className,
}: {
    text?: string;
    record?: any;
    children?: ReactNode;
    width?: number;
    style?: CSSProperties;
    isComparisonField?: boolean;
    isFirstMetric?: boolean;
    isDeepBorderRight?: boolean;
    isEmptyBorderBottom?: boolean;
    isEmptyBorderRight?: boolean;
    isShadowBg?: boolean;
    isWhiteBg?: boolean;
    isClickable?: boolean;
    onClick?: () => void;
    className?: string;
}) => {
    record = cloneDeep(record)
    if (isComparisonField && !record.queryComparisonDateValid) {
        record.optionsNum = 0
    }
    if (!isComparisonField && !record.queryDateValid) {
        record.optionsNum = 0
    }
    let displayText = text
    if (!isCrossRegionSoldOutPage() && !isAttributeRankingPage()) {
        displayText = getDashboardValueByOptions(text, record?.optionsNum)
        if (displayText === NA && !isFirstMetric) {
            displayText = ''
        }
    }

    const sty: CSSProperties = {}
    if (isDeepBorderRight) {
        sty.borderRight = '1px solid #01A699'
    }
    if (isEmptyBorderBottom) {
        sty.borderBottom = 'none'
    }
    if (isEmptyBorderRight) {
        sty.borderRight = 'none'
    }

    const [ loading, setLoading ] = useState(false)
    const clickable = typeof onClick === 'function' && isClickable

    if (clickable) {
        sty.cursor = 'pointer'
    }

    if (!text && !children) {
        return (
            <div
                style={{ width: typeof width !== 'undefined' ? width + 'px' : 'auto', padding: '2px 8px', ...style, ...sty }}
                className={classnames(styles.tablecell1, 'text-ellipsis', 'new-table-tablecell')}
            />
        )
    }

    return (
        <div
            style={{ width: typeof width !== 'undefined' ? width + 'px' : 'auto', padding: '2px 8px', position: 'relative', ...style, ...sty }}
            className={classnames(styles.tablecell1, 'text-ellipsis', 'new-table-tablecell', className, isShadowBg && styles.shadowBgCell, isWhiteBg && styles.whiteBgCell)}
            onClick={async () => {
                if (!isClickable) return
                setLoading(true)
                try {
                    await onClick?.()
                    setLoading(false)
                } catch (e) {
                    setLoading(false)
                }
            }}
        >
            {children || displayText}
            {
                loading ? (
                    <InlineLoading className={styles.loadingIcon} />
                ) : null
            }
        </div>
    )
}
