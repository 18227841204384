import React, { FC, ReactNode, useState, useEffect, useRef, useLayoutEffect } from 'react'
import classnames from 'classnames'
import { SizedBox } from 'componentsv2/SizedBox'
import { Button } from 'componentsv2/Button'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ProductCard } from 'pages/SearchForOptions/components/ProductCard'
import { getVendorNameByCode, isCustomerVendor } from 'utils'
import { CompareCategoryProps, ProductProps } from './types'
import styles from './styles.module.scss'

export const CompareCategory: FC<CompareCategoryProps> = ({
    categoryName,                               // 分类名称
    vendorProductList = [],                     // 页面上展示的产品列表
}) => {
    /**
     * 点击 load more options 按钮时每页增加多少条新数据
     */
    const PAGE_SIZE = 10

    const [ vendorProducts, setVendorProducts ] = useState([ ...vendorProductList ])

    useEffect(() => {
        setVendorProducts([ ...vendorProductList ])
    }, [ vendorProductList ])

    // 是否显示 Load more options 按钮
    const [ showLoadMoreBtn, setShowLoadMoreBtn ] = useState(false)

    useEffect(() => {
        if (!Array.isArray(vendorProductList) || vendorProductList.length === 0) return
        /**
         * vendorProducts 中只要有一个 vendor 的产品数量大于 10，都显示 Load more options 按钮，否则不显示 Load more options 按钮
         */
        const result = vendorProductList.some(item => item.allProductList.length > PAGE_SIZE)
        setShowLoadMoreBtn(result)
    }, [ vendorProductList ])

    const onLoadMore = () => {
        const newVendorProducts = vendorProducts.map(item => {
            item.productList = item.allProductList.slice(0, item.productList.length + PAGE_SIZE)
            return item
        })
        setVendorProducts([ ...newVendorProducts ])

        /**
         * 只要有一个 vendor 的 productList 和 allProductList 长度不相同，都说明不是最后一页，此时应显示 Load more options 按钮
         * 否则，所有 vendor 的 productList 和 allProductList 长度相同，说明是最后一页，此时不应显示 Load more options 按钮
         */
        const result = newVendorProducts.some(item => item.productList.length !== item.allProductList.length)
        setShowLoadMoreBtn(result)
    }

    /* **************************** lazy load **************************** */
    const wrapperRef = useRef<HTMLDivElement>(null)
    const visibleRef = useRef(false)
    // eslint-disable-next-line
    const [_, setRefresh] = useState({})

    const observe = new IntersectionObserver((entries = []) => {
        entries.forEach(item => {
            if (item.isIntersecting) {
                const wrap = item.target
                visibleRef.current = true
                setRefresh({})
                observe.unobserve(wrap)
            }
        })
    })

    useLayoutEffect(() => {
        if (!wrapperRef.current) return
        observe.observe(wrapperRef.current)

        return () => {
            observe.disconnect()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div ref={wrapperRef} className={styles.category} style={{ minHeight: '300px', width: (vendorProductList.length + 1) * 260 + 'px' }}>
            {
                visibleRef.current ? (
                    <>
                        <div className={styles.categoryHeader}>
                            <div className={styles.categoryName}>{categoryName}</div>
                            <ShouldRender shouldRender={showLoadMoreBtn}>
                                <div className={styles.categoryLoadBtn}>
                                    <Button
                                        className="no-focus"
                                        type="primary"
                                        onClick={onLoadMore}
                                    >
                                        Load more options
                                    </Button>
                                </div>
                            </ShouldRender>
                        </div>
                        {
                            vendorProducts.map(({ vendor, productList, allProductList }, index) => {
                                let contentElement: ReactNode = null
                                if (allProductList.length === 0) {
                                    contentElement = (
                                        <div className={styles.noData}>
                                            No data
                                        </div>
                                    )
                                } else {
                                    contentElement = (
                                        <>
                                            {
                                                productList.map((product: ProductProps) => {
                                                    return (
                                                        <ProductCard
                                                            key={[ product.nornaid, product.seller?.vendor ].join(',')}
                                                            vendor={product.vendor}
                                                            vendorName={getVendorNameByCode(product?.seller?.vendor)}
                                                            vendorCode={product?.seller?.vendor}
                                                            image={product.image}
                                                            brand={product.brand}
                                                            lookbooks={product.lookbooks}
                                                            name={product.name}
                                                            leftPrice={product.leftPrice}
                                                            rightPrice={product.rightPrice}
                                                            nornaid={product.nornaid}
                                                            currency={product?.currency}
                                                            className={styles.vendorProduct}
                                                            sizePriceRange={product?.sizePriceRange}
                                                            latestSizePrice={product?.latestSizePrice}
                                                        />
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }

                                return (
                                    <div key={vendor} className={classnames([ styles.vendor, isCustomerVendor(vendor) ? styles.vendorFirst : styles.vendorOthers ])}>
                                        <div className={styles.vendorHeader} />
                                        <SizedBox height={20} />
                                        {contentElement}
                                    </div>
                                )
                            })
                        }
                    </>
                ) : null
            }
        </div>
    )
}
