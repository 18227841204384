/**
 * 处理线图数据结构
 * 
 * 正常情况，这两个点在线图是一条斜线
 * var data = [{x: '2022-03-09', y: 1}, {x: '2022-03-10', y: 2}]
 * 
 * 但是目前线图要求只能存在横线和竖线，不能有斜线，因此要补中间点
 * handleLineChartData(data)	// => [{x: '2022-03-09', y: 1}, {x: '2022-03-10', y: 1}, {x: '2022-03-10', y: 2}]
 */
 export function handleLineChartData(dataSource: {x: string; y: number}[]) {
	const newDataSource: {x: string; y: number}[] = []
	if (!dataSource.length) return dataSource

	let zig = 0
	dataSource.forEach((item, index, array) => {
		if (index > 0 && array[index - 1].y !== item.y) {
			const currentValue = item.y
			const prevValue = array[index - 1].y
			const currentDate = item.x
			const prevDate = array[index - 1].x

			// 当前值大于前一个值
			if (currentValue > prevValue) {
				// 上升趋势, 补点: 日期用自己的, 值用前一个
				if (zig % 2 === 0) {
					newDataSource.push({
						x: currentDate,
						y: prevValue,
					})
					zig += 1
				} else {
					newDataSource.push({
						x: prevDate,
						y: currentValue,
					})
					zig -= 1
				}
			}
			if (currentValue < prevValue) {
				if (zig % 2 === 1) {
					newDataSource.push({
						x: prevDate,
						y: currentValue,
					})
					zig -= 1
				} else {
					// 
					newDataSource.push({
						x: currentDate,
						y: prevValue,
					})
					zig += 1
				}
			}
		}
		newDataSource.push(item)
	})
	return newDataSource
}

export function getMaxTick(data, yAxesMaxFixed = false) {
    // 1000 以内 50
	// 1000 以上 500
	if (!Array.isArray(data) || !data?.length || yAxesMaxFixed) return 100
	const maxValue = Math.max(...data.map(d => d.y)) * 1.5
	const gap = (maxValue < 1000 ? maxValue < 100 ? 5 : 50 : 500) * 4
	const maxTick = Math.ceil(maxValue / gap) * gap
	return maxTick
}

export function getXTicks(data) {
	if (!Array.isArray(data) || !data?.length) return []
	const len = data.length
	if (len <= 5) {
		return data.map(d => d.x)
	}
	const interval = Math.ceil(len / 4)
	return [
		data[0 + interval * 0]?.x,
		data[0 + interval * 1]?.x,
		data[0 + interval * 2]?.x,
		data[0 + interval * 3]?.x,
		data[len - 1]?.x,
	]
}
