
import { getMemoData, updateTmpQueryData } from 'features/filters/memoDataSlice'
import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function useCustomState<T>(initValue: T | (() => T)) {
    const [ state, setState ] = useState<T>(initValue)
    return {
        state,
        updateState: useCallback((changed: any) => {
            setState({
                ...state,
                ...changed,
            })
        }, [ state ]),
    }
}

/**
 *  ## 通用、跨组件状态共享`hooks`
 * 
 *  ### usage
 * 
 * ```ts
 *     const { state, updateState } = useCustomMemoryState<TdPriceRangePreviousQueryType>(PriceRangeMemoryStateKey.TD_PRICE_RANGE)
 * ```
 * @param key 数据存储`key`值，每个功能`key`需不同
 * 
 * @return
 */
export function useCustomMemoryState<T>(key: string) {

    const { tmpQuery } = useSelector(getMemoData)
    const dispatch = useDispatch()
    return {
        state: tmpQuery[key] ?? {} as T,

        // clear state
        clearState: useCallback(() => {
            const changeData = {
                ...tmpQuery,
                [key]: {
                },
            }
            dispatch(updateTmpQueryData(changeData))
        }, [ dispatch, key, tmpQuery ]),

        // update state
        updateState: useCallback((changed: Partial<T>) => {
            const changeData = {
                ...tmpQuery,
                [key]: {
                    ...tmpQuery[key] ?? {},
                    ...changed,
                },
            }
            dispatch(updateTmpQueryData(changeData))
        }, [ dispatch, key, tmpQuery ]),
    }
}
