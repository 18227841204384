import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { Button } from 'components/form-elements/Button'
import { Product } from 'components/Product'
import { lastItem } from 'utils/array'
import { ShouldRender } from 'componentsv2/ShouldRender'
import styles from './styles.module.scss'
import { ListProps } from './types'

export const ProductsList = ({
  extendRow,
  smallItems,
  items,
  onDel = () => {
  },
  date,
  fetchMore,
  codenames,
  productsCount,
  category,
}: ListProps) => {
  const [ loadMore, setLoadMore ] = useState(true)
  const [ isLoadingMore, setIsLoadingMore ] = useState(false)
  const [ dataList, setDataList ] = useState(items)
  const expandableRow: any = useRef(null)

  const take = 100

  const handleShowMoreItems = () => {
    setIsLoadingMore(true)
    fetchMore({
      variables: {
        skip: items.length,
        take,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        setIsLoadingMore(false)
        if (!fetchMoreResult) return prev
        const prevList = prev as any
        const newList = fetchMoreResult as any
        if (newList.products.length < take) {
          setLoadMore(false)
        }
        return {
          products: [ ...prevList.products, ...newList.products ],
        }
      },
    })
  }

  useEffect(() => {
    setDataList(dataList)
  }, [ dataList, setDataList ])

  useEffect(() => {
    if (!extendRow && expandableRow !== null) {
      expandableRow.current.scrollTo(0, 0)
    }
  }, [ extendRow ])

  const handleExcludedProducts = async (urlId: any) => {
    // if (excludedProducts && excludedProducts.includes(urlId)) {
    //   const index = excludedProducts.indexOf(urlId);
    //   const toExclude = [...excludedProducts.slice(0, index), ...excludedProducts.slice(index + 1)];
    //   // setExcludedProducts(toExclude);
    // } else {
    //   const toExclude = !excludedProducts ? [urlId] : [...excludedProducts, urlId];
    //   // setExcludedProducts(toExclude);
    // }
  }

  // Container height (when expanded) in pixels depending on the product count
  let containerHeight = 650
  if (productsCount > 50) {
    containerHeight = 1640
  } else if (productsCount > 40) {
    containerHeight = 1375
  } else if (productsCount > 30) {
    containerHeight = 1115
  } else if (productsCount > 20) {
    containerHeight = 850
  }

  return (
      <div
          className={classnames(
          styles.wrap,
          smallItems && styles.smallItems,
          !smallItems && styles.bigItems,
        )}
      >
          <div>
              <div
                  className={classnames(styles.rowContent, extendRow ? styles.visible : styles.hidden,'scroll-container-id')}
                  style={extendRow ? { height: `${containerHeight}px` } : {}}
                  ref={expandableRow}
              >
                  {(dataList ?? []).map((item, index) => {
                    const date = Object.keys(item.price_info).pop() || ''
                    return (
                      <div key={item.nornaid} className={styles.rowItem}>
                        <Product
                          date={date}
                          onDel={() => {
                            setDataList([ ...dataList.slice(0, index), ...dataList.slice(index + 1) ])
                            onDel && onDel()//
                          }}
                          id={item.id}
                          nornaid={item.nornaid}
                          brand={item.brand}
                          vendor={(codenames && codenames[item.seller.vendor]) || item.seller.vendor}
                          vendorCode={item.seller.vendor}
                          imageUrl={item.images[0]}
                          category={item.categories}
                          price={lastItem(item.price_info)}
                          convertedPrice={item.convertedPrice}
                          url={item.url}
                          interpolatedPrice={item.price_info}
                          handleExcludedProducts={handleExcludedProducts}
                          title={item.title}
                          currency={item.currency}
                          toCurrency={item?.currency || 'EUR'}
                          categoryName={category}
                          enableHoverAction={false}
                          lookbooks={item.lookbooks}
                          isShowInDialog
                          latestSizePrice={item?.latest_size_price}
                        />
                      </div>
                    )
                  },
          )}
              </div>
              <ShouldRender shouldRender={productsCount > items.length && loadMore && !!extendRow}>
                  <div className={classnames(styles.rowContent, styles.message)}>
                      <Button primary disabled={isLoadingMore} onClick={() => handleShowMoreItems()}>
                          Load more products
                      </Button>
                  </div>
              </ShouldRender>
          </div>
      </div>
  )
}
