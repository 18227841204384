import * as consts from 'features/filters/moduleDataSlice.const'
import { RoutesObj } from 'global/Routes'

/**
 * 获取当前页面的 csv 数据
 */
export function getCurrentPageCsvData(csvDatas) {
    const pathname = window.location.pathname
    let csvData = ''

    switch (pathname) {
        case RoutesObj.DYNAMIC_DASHBOARD.URL:
            csvData = csvDatas[consts.CSV_DATA_DYNAMIC_DASHBOARD]
            break
        case RoutesObj.PRICE_INSIGHT.URL:
            csvData = csvDatas[consts.CSV_DATA_PRICE_INSIGHTS]
            break
        case RoutesObj.ASSORTMENT_INSIGHT.URL:
            csvData = csvDatas[consts.CSV_DATA_ASSORTMENT_INSIGHTS]
            break
        case RoutesObj.PRICE_SPREADS.URL:
            csvData = csvDatas[consts.CSV_DATA_PRICE_SPREADS]
            break
        case RoutesObj.IN_OUT_MONITORING.URL:
            csvData = csvDatas[consts.CSV_DATA_IN_OUT_MONITORING]
            break
        case RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL:
            csvData = csvDatas[consts.CSV_DATA_MOST_POPULAR_LAUNCH_DAYS]
            break
        case RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL:
            csvData = csvDatas[consts.CSV_DATA_OPTIONS_IN_OUT_BY_MONTH]
            break
        case RoutesObj.PRICE_HISTORY.URL:
            csvData = csvDatas[consts.CSV_DATA_PRICE_DRIVERS_INTRA_PERIOD]
            break
        case RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL:
            csvData = csvDatas[consts.CSV_DATA_PRICE_DRIVERS_PERIOD_TO_PERIOD]
            break
        case RoutesObj.PRICE_ARCHITECTURE.URL:
            csvData = csvDatas[consts.CSV_DATA_PRICE_ARCHITECTURE]
            break
        case RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL:
            csvData = csvDatas[consts.CSV_DATA_DISCOUNTS]
            break
        case RoutesObj.PRICING_JUMP_TABLE.URL:
            csvData = csvDatas[consts.CSV_DATA_CROSS_REGION_PRICING]
            break
        case RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.URL:
            csvData = csvDatas[consts.CSV_DATA_SOLD_OUT_ANALYTICS]
            break
        case RoutesObj.CROSS_REGION_SOLD_OUT.URL:
            csvData = csvDatas[consts.CSV_DATA_SOLD_OUT_CROSS_REGION]
            break
        case RoutesObj.CATEGORY_ANALYTICS.URL:
            csvData = csvDatas[consts.CSV_DATA_CATEGORY_SPLIT]
            break
        case RoutesObj.COLOR_ANALYTICS.URL:
            csvData = csvDatas[consts.CSV_DATA_COLOR_SPLIT]
            break
        case RoutesObj.MATERIAL_ANALYTICS.URL:
            csvData = csvDatas[consts.CSV_DATA_MATERIA_SPLIT]
            break
        case RoutesObj.POPULAR_PRODUCTS.URL:
            csvData = csvDatas[consts.CSV_DATA_PRODUCT_RANKING_LAST_WEEK]
            break
        case RoutesObj.POPULAR_ATTRIBUTES.URL:
            csvData = csvDatas[consts.CSV_DATA_ATTRIBUTE_RANKING]
            break
        case RoutesObj.ASSORTMENT_SIZE.URL:
            csvData = csvDatas[consts.CSV_DATA_SIZE_OF_LINE]
            break
        case RoutesObj.PLATFORM_ANALYTICS.URL:
            csvData = csvDatas[consts.CSV_DATA_MARKETPLACE_SELLER_ANALYTICS]
            break
        default:
    }

    return csvData
}
