import { getCanadagooseFilterCompetitorValue, getJustBrandsFilterCompetitorValue } from 'components/layout/Header/components/HeaderFilter/competitor.util'
import { getFilterGenderValue } from 'components/layout/Header/components/HeaderFilter/gender.util'
import { ALL_COUNTRIES, BRAND_VENDOR_SPLITOR, LICENSE_CANADAGOOSE, LICENSE_JUST_BRANDS, MARKET_VENDOR } from 'consts'
import { ObjectType, SellerType } from 'types'
import { Property } from 'types/property'
import { storage } from 'utils/storage'
import { formatSortFieldFn } from '../../components/layout/Header/components/HeaderFilter/components/SortBy'
import { TreeItemProp, TreeItemProps } from './types'

export { DataType } from './common'

/**
 *
 * @param rect              panel rect
 * @param rectEle           item element rect
 * @param windowVisualHeight window client height
 * @param gutter            the gutter distance between the top/bottom of broswer and panel.
 * @param style             panel style
 * @param anchor            panel anchor
 */
export function calcPosition({ rect, rectEle, windowVisualHeight, gutter, style, anchor }) {
  if (anchor === 'left' || anchor === 'right') {
    // 处理 panel 自身高度就超过浏览器可视高度的情形
    if ((rect.height + gutter * 2) > windowVisualHeight) {
      style.maxHeight = `${windowVisualHeight - gutter * 2}px`
      style.overflow = 'auto'
      style.position = 'fixed'
      style.top = gutter + 'px'
      style.left = rect.left + 'px'
      return style
    }

    if (rect.bottom > windowVisualHeight) {
      // distance：panel 底部超出窗口的距离
      /**
       * *******************************   ---
       * *    ---                      *    |
       * *     |                       *    |
       * *     |                       *   windowVisualHeight: 浏览器可视窗口高度
       * *     |                       *    |
       * *     |           ******      *    |
       * *     |           *    *      *    |
       * * *****************    ********   ---
       *       |           *    *           |
       *     rect.bottom   *    *        distance
       *       |           *    *           |
       *      ---          ******          ---
       */
      const distance = rect.bottom - windowVisualHeight
      style.top = rect.top - distance - gutter + 'px'
    } else {
      if (rectEle.bottom + rect.height < windowVisualHeight) {
        style.top = rectEle.top + 'px'
      } else {
        style.top = rect.top + 'px'
      }
    }

    style.left = rect.left
    style.position = 'fixed'

  } else if (anchor === 'bottom') {
    style.right = 'auto'
  } else if (anchor === 'right') {
    style.right = 'auto'
    style.left = '100%'
  }
  return style

}

/**
 * Judge the currect status of node that finishing interact with user
 * @param indexArr      Node index path
 * @param list          Corresponding node data
 * @returns {boolean}
 */
export const getLeafShouldActive = (indexArr, list) => {
  let leafActive = false
  let tmpItem: any = null
  let parentItem: any = null
  indexArr.forEach((indexObj, index: number) => {
    tmpItem = tmpItem ? tmpItem?.children[indexObj] : list[indexObj]
    const isLastSelectLevel = index === indexArr.length - 1
    if (isLastSelectLevel) { // the last leve node that user selected from root level
      const { keepLast } = parentItem || {}
      const enableSelectAll = typeof parentItem?.enableChildrenSelectAll !== 'undefined' ? parentItem?.enableChildrenSelectAll : parentItem?.enableSelectAll
      const lastLevelNodeActive = tmpItem.active
      if (lastLevelNodeActive && keepLast) { // keep at least one to selected
        if (!enableSelectAll) {
          leafActive = true
        } else {
          // eslint-disable-next-line
          if (parentItem.children.filter(item => item.active === true).length > 1) {
            leafActive = !tmpItem.active
          } else {
            leafActive = true
          }
        }
      } else {
        leafActive = !tmpItem.active
        tmpItem.active = leafActive
        if (tmpItem.children) tmpItem.children.forEach(leaf => {
          leaf.active = leafActive
        })
      }

    }
    parentItem = tmpItem
  })

  return { leafActive }
}

/**
 * judge owning active children node in the current node and sub child
 * @param obj   the obj that need to judge
 */
export const getLevelActive = obj => {
  let shouldActive = false
  const activeList = (obj.children || []).filter(item => item.active)
  if (activeList.length === 0) {
    shouldActive = false
  } else if (activeList.length === 1 && activeList[0].children) {
    shouldActive = getLevelActive(activeList[0])
  } else {
    shouldActive = true
  }

  return shouldActive
}
/**
 * 获取 选中的Category List 字符串数组
 * @param categoryOptions 
 * @returns 
 */
export const getActiveCategoryList = (categoryOptions: TreeItemProp[]): Array<string> => categoryOptions
  .filter(item => item.active)
  .map(item => {
    if (!item.children) return item.val
    if (item.children) return item.children.filter(el => el.active).map(el => el.val)
    return ''
  }).flat(1)

/**
 * 设置下层级所有子项全选
 * @param obj
 * @param active
 */
export const setAllActive = (obj: TreeItemProp, active: boolean): void => {

  (obj.children ?? []).forEach(item => {
    if (!item.disabled) {
      item.active = active
    } else {
      item.active = false
    }
    if (item.children) { // 递归
      setAllActive(item, active)
    }
  })

}
/**
 * get val recursively from single TreeItemProp
 * @param val 
 * @returns 
 */
export const recursiveGetVal = (val: TreeItemProp) => {
  if (val.children?.length) return val.children.map(item => recursiveGetVal(item))
  return val.val
}

export const recursiveGetPropertyVal = (val: Property) => {
  if (val.children?.length) return val.children.map(item => recursiveGetPropertyVal(item))
  return val.name
}

/**
 * get avaliable & active val
 * @param val 
 * @returns 
 */
const getVal = (val: TreeItemProp | undefined): Array<string> => (val?.children ?? [])
  .filter(item => item.active && !item.disabled)
  .map(item => item.val)

/**
 * 
 * @param item      tree 节点数据，不等同于 
 * @param selectAll 全选
 * @param onlyDisplayable 只返回界面上可显示的数据, usage: property存在值为字段`notShowSubGroups`的值，但功能界面上不显示`notShowSubGroups`,而接口传参需要传递的值
 * @returns 
 */
export const getPropertyVal = (item: TreeItemProp, selectAll = false, onlyDisplayable = false) => (item.children ?? []).filter(item => selectAll || item.active).map((el: TreeItemProp) => {
  if (Array.isArray(el?.notShowSubGroups) && el.notShowSubGroups.length > 0) {
    if (onlyDisplayable) return el.label
    return el.notShowSubGroups
  }
  if (!el.children) {
    return el.val
  }
  return getPropertyVal(el)
})
// 两个数组求交集，共同的部分
const cross = (arr1: any[], arr2: any[]) => arr1.filter(x => arr2.includes(x))

/**
 * ## set property value
 * 
 * @param item TreeItemProp
 * @param vals Array<string>
 */
export const setPropertyVal = (item: TreeItemProp, vals: Array<string>) => item.children?.forEach(el => {
  if (el?.children) {
    if (!el.disabled) el.active = el.children.filter(item => {
      // Support level 4 property, show level 4
      if (Array.isArray(item?.children) && item?.children?.length) {
        return item?.children?.filter(item2 => vals.includes(item2.val))?.length
      }
      // Support level 4 property, hide level 4
      if (Array.isArray(item?.notShowSubGroups) && item?.notShowSubGroups?.length) {
        return item?.notShowSubGroups.filter(item2 => vals.includes(item2))?.length
      }
      return vals.includes(item.val)
    }).length > 0
    setPropertyVal(el, vals)
  } else if (Array.isArray(el?.notShowSubGroups)) {
    /**
     * Tree 的叶子节点有两种类型：
     * 第一种类型：选中时实际选中的就是该叶子节点，区分叶子节点第二种类型
     * 第二种类型：叶子节点仍有子项，页面上显示该叶子节点，但选中时实际选中的是该叶子节点的子项
     *    第二种类型的数据结构里会多一个字段 notShowSubGroups，包含该叶子节点的子项
     * 这里对第二种类型的激活状态做特殊处理。
     */
    // 判断 vals 数组是否全部包含 el?.notShowSubGroups 数组，包含则设置为 true
    // cross(a, b) 之后的数组长度如果等于 b 的数组长度，则认为前者包含后者
    if (cross(vals, el.notShowSubGroups).length === el.notShowSubGroups.length) {
      el.active = true
    }
  } else if (!el.disabled) {
    el.active = vals.indexOf(el.val) > -1
  }
})

/**
 *  ## 根据 `labels` 设置当前节点是选中或取消选中
 * @param item TreeItemProp 当前节点
 * @param labels Array<string> 选中的`label`数组, 此时的`label`非 `notShowSubGroups`值，是 节点的`val`值
 */
export const setPropertyValByLabels = (item: TreeItemProp, labels: Array<string>) => item.children?.forEach(el => {
  if (el?.children) {
    if (!el.disabled) el.active = el.children.filter(item => labels.indexOf(item.label) > -1).length > 0
    setPropertyVal(el, labels)
  } else if (!el.disabled) {
    el.active = labels.includes(el.val)
  } else {
    el.active = false
  }

})
/**
 * Filter 中某些项的 label 映射对象
 * 之前使用索引（数组下标）获取树中某一项的值，如果新增一项或者删除一项，所有涉及索引的地方都要相应修改，并且只看索引不清楚具体是哪一项
 * 改成通过每一项的 label 字段值获取该项的值，考虑到 label 可能会变化，因此定义 label 常量映射
 * 之后如果 label 名称有变化，只要修改映射对象值即可，对象键（常量）无需修改
 */
export const FILTER_LABELS = {
  CATEGORY: 'Category',
  PROPERTY: 'Property',
  GENDER: 'Gender',
  COUNTRY: 'Country',
  CURRENCY: 'Currency',
  COMPETITOR: 'Competitor',
  SORT_BY: 'Sort by',
  PRICE_FILTER: 'Price perspective',
  PACK_FILTER: 'Pack filter',
  LAUNCHED_AFTER: 'Launched after',
  LAUNCHED_BEFORE: 'Launched before',
  TAX: 'Add sales tax',
  SIZE_FILTER: 'Children size filter',
  MATERIAL_FILTER: 'Material filter',
  COLOR_FILTER: 'Color filter',
  NO_SIZE: 'No size',
  PRICE_RANGE_FILTER: 'Price filter',
  LOOKBOOK_FILTER: 'Load lookbooks',
  CLEAR_FILTER: 'Clear all',
}

/**
 * ## get tree data
 * 
 * @param treeData tree component data 
 * @returns 
 */
export const getSelectedCategories = (treeData: Array<TreeItemProps>) => {
  const categoryOptions = treeData.find(item => item.label === FILTER_LABELS.CATEGORY)
  const category = getActiveCategoryList(categoryOptions?.children ?? [])
  return category
}

/**
 * ## set one level category status 
 * 
 * @param categoryLabel 
 * @param oneLevelCategories 
 * @param flag 
 */
export const setOneLevelCategorySelectedBy = (categoryLabel: string, oneLevelCategories: Array<TreeItemProps>, flag = true) => {
  const selectedCategory = oneLevelCategories.find((item: TreeItemProps) => item.label === categoryLabel)
  if (selectedCategory) {

    // if(selectedCategory.children){
    selectedCategory.active = flag
    if (selectedCategory.children) {
      selectedCategory.children.forEach(item => {
        item.active = flag
      })
    }
  }
  // }

}

/**
 * 获取树有效结果
 * @param treeData 当前`Fitler Tree` 数据
 * @param isLookbook 是否启用了`lookbok`
 * @param onlyDisplayable 是否仅返回功能界面可以看到的数据
 */
export const getTreeVals = (treeData: Array<TreeItemProps>, isLookbook = false, onlyDisplayable = false): ObjectType<any> => {

  /** ****************************** 处理 Category ************************************* */
  const category = getSelectedCategories(treeData)

  /** ****************************** 处理 Property ************************************* */
  const property: any = {}
  const propertyOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.PROPERTY)
  if (propertyOptions) {
    propertyOptions.children?.filter(item => item.active)
      .forEach(item => {
        property[item.val] = getPropertyVal(item, false, onlyDisplayable).flat(10)
      })
  }

  /** ****************************** 处理 Gender ************************************* */
  const genderOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.GENDER)
  const gender = getFilterGenderValue(genderOptions)

  /** ****************************** 处理 Country ************************************* */
  const countryOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.COUNTRY)
  const region = countryOptions?.children?.filter(item => item.active)
    .map(le => ({ key: le.label, currency: le.val }))

  /** ****************************** 处理 Currency ************************************* */
  const currencyOptions = treeData.find(item => item.label === FILTER_LABELS.CURRENCY)
  const currency = currencyOptions?.children?.filter(item => item.active)
    .map(item => item.val)

  /**
   * Country 变化，联动修改 Currency
   * 当 Country 变化时，会维护一个 region = { key: 'Sweden', currency: 'SEK' }
   * 此时 Currency 也会联动变成 'SEK'，这部分逻辑在 components/layout/Header/components/HeaderFilter/utils.tsx > useTreeData
   * 
   * Currency 变化，不要联动 Country
   * 这部分逻辑就在下面这个 if 语句里，当检测到二者不一致时，优先使用 Currency 中的选中值
   */
  if (region?.length === 1 && currency?.length === 1 && region[0].currency !== currency[0]) {
    // eslint-disable-next-line
    region[0].currency = currency[0]
  }

  /** ****************************** 处理 Competitor ************************************* */
  const competitorOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.COMPETITOR)

  const codenames = storage.getCodenames()
  let vendor: any[] = []
  if (LICENSE_CANADAGOOSE === storage.getCustomerVendor()) {
    const sellers = getCanadagooseFilterCompetitorValue(competitorOptions)
    vendor = [ ...sellers ]
  } 
  else if (LICENSE_JUST_BRANDS === storage.getCustomerVendor()) {
    const sellers = getJustBrandsFilterCompetitorValue(competitorOptions)
    vendor = [ ...sellers ]
  }
  else {
    vendor = competitorOptions?.children?.filter(item => item.active)
      .map(item => {
        if (item.children) {
          return item.children?.filter(item => item.active).map((ele: any) => {
            return {
              region: item.region,
              vendor: ele?.vendorCode || ele?.val,
            }
          })
        }
        return { region: item.region, vendor: item.val }
      }).flat(2) || []
  }

  /**
   * Competitor 勾选了 Market 时，market 字段传递所有 competitor 数组（不包含 Market 本身），格式要做转换 [{region:'', vendor:''}]
   * Competitor 未勾选 Market 时，market 字段传递 []
   */
  let market: Array<SellerType> = []
  if (vendor?.findIndex(item => item.vendor === MARKET_VENDOR.vendor) !== -1) {
    market = competitorOptions?.children?.filter(item => item.val !== MARKET_VENDOR.vendor)
      .map(item => {
        const regionData = region && region.length && region[0].key === ALL_COUNTRIES ? ALL_COUNTRIES : item.region
        if (item.children) {
          return item.children.map(ele => ({
            region: regionData,
            vendor: [ ele.val, BRAND_VENDOR_SPLITOR, item.val ].join(''),
          }))
        }
        return { region: regionData, vendor: item.val }
      }).flat(2) as Array<SellerType>

    // .map(item => ({ region: region&&region.length&&region[0].key===ALL_COUNTRIES?ALL_COUNTRIES:item.region, vendor: item.val }))
  }

  /** ****************************** 处理 Sort by ************************************* */
  const sortByOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.SORT_BY)
  let sort = {}
  if (sortByOptions)
    sort = formatSortFieldFn(sortByOptions?.children?.filter(item => item.active))

  /** ****************************** 处理 Price filter ************************************* */
  const priceFilterOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.PRICE_FILTER)
  let priceFilter: any = []
  if (priceFilterOptions) {
    priceFilter = priceFilterOptions?.children?.filter(item => item.active)
    priceFilter = priceFilter.length ? priceFilter[0] : {}
  }

  /** ****************************** 处理 Pack filter ************************************* */
  const packFilterOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.PACK_FILTER)
  const packFilter = getVal(packFilterOptions)

  /** ****************************** 处理 Price range ************************************* */
  const priceRangeOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.PRICE_RANGE_FILTER)
  let priceRange: any = { min: 0, max: 999999999 }
  if (priceRangeOptions && priceRangeOptions?.val) {
    priceRange = {
      min: parseInt(priceRangeOptions?.val.split(',')[0], 10),
      max: parseInt(priceRangeOptions?.val.split(',')[1], 10),
      /**
       *  ## price range 设置值后自动应用
       */
      activate_price_range: Boolean(parseInt(priceRangeOptions?.val.split(',')[2], 10)), // priceRangeOptions?.val.split(',')[2]==='true'
    }
  }

  /** ****************************** 处理 Launched after ************************************* */
  const launchDateOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.LAUNCHED_AFTER)
  let launchDate = ''
  if (launchDateOptions) launchDate = launchDateOptions.val

  /** ****************************** 处理 Launched before ************************************* */
  const launchBeforeDateOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.LAUNCHED_BEFORE)
  let launchBeforeDate = ''
  if (launchBeforeDateOptions) launchBeforeDate = launchBeforeDateOptions.val

  /** ****************************** 处理 Material filter ************************************* */
  const materialFilterOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.MATERIAL_FILTER)
  let materialFilter = ''
  if (materialFilterOptions) materialFilter = materialFilterOptions.val

  /** ****************************** 处理 Color filter ************************************* */
  const colorFilterOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.COLOR_FILTER)
  let colorFilter = ''
  if (colorFilterOptions) colorFilter = colorFilterOptions?.val

  /** ****************************** 处理 Size filter ************************************* */
  const sizeFilterOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.SIZE_FILTER)
  let sizeFilter = ''
  if (sizeFilterOptions) sizeFilter = sizeFilterOptions?.val

  /** ****************************** 处理 Tax filter ************************************* */
  const taxFilterOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.TAX)
  let taxFilter = ''
  if (taxFilterOptions) taxFilter = taxFilterOptions?.val

  /** ****************************** 处理 No size ************************************* */
  const noSizeFilterOptions: TreeItemProps | undefined = treeData.find(item => item.label === FILTER_LABELS.NO_SIZE)
  let noSize = false
  let noHistoricalSize = false
  let noUnavailableSize = false
  let noHarmonizedSize = false
  let zalandodeepShopEmptyString = false
  let ignoreNoAvailableSize = false
  if (noSizeFilterOptions) {
    const value = noSizeFilterOptions?.val?.split(',')
    noSize = Boolean(value[0] === 'true')
    noHistoricalSize = Boolean(value[1] === 'true')
    noUnavailableSize = Boolean(value[2] === 'true')
    noHarmonizedSize = Boolean(value[3] === 'true')
    zalandodeepShopEmptyString = Boolean(value[4] === 'true')
    ignoreNoAvailableSize = Boolean(value[5] === 'true')
  }

  return {
    category,
    region, // region
    gender, // gender
    property,// property
    vendor, // vendor
    sort, // sort
    priceFilter, //
    packFilter,
    priceRange, //
    launchDate,
    launchBeforeDate,
    materialFilter,
    colorFilter,
    sizeFilter,
    taxFilter,
    noSize,
    noHistoricalSize,
    noUnavailableSize,
    noHarmonizedSize,
    zalandodeepShopEmptyString,
    ignoreNoAvailableSize,

    /**
     * 选中 Competitor 中的 Market 时
     * market 参数需要传递 Competitor 中所有 vendor (不包含 Market)
     * 数据结构为 [{region: '', vendor: ''}, {region: '', vendor: ''}]
     */
    market,
  }
}

export const setTreeVals = (treeData: Array<TreeItemProps>) => {
  
}
/**
 *  ## 判断由`lookbook` 切换到`analysis`
 * @param prevTreePageData  previous tree page data
 * @param newPageData       新的 tree page data
 * @returns 
 */
export const isLookbooktoAnalysisChange = (prevTreePageData: any, newPageData: any) => {
  
  const prevLookbookStatus = prevTreePageData.find(item => item.label === FILTER_LABELS.LOOKBOOK_FILTER)?.active
  const newLookbookStatus = newPageData.find(item => item.label === FILTER_LABELS.LOOKBOOK_FILTER)?.active

  // 判断由`lookbook` 切换到`analysis`：由 previous的lookbook选中状态变化为 lookbook 的非选中状态
  return prevLookbookStatus && !newLookbookStatus
}

