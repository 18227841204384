import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { CloseIcon, NoCategory, PlusIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Spin } from 'componentsv2/Spin'
import { useAddExcludedProducts } from 'graphql/comparisons/addExcludedProducts'
import { selectMarket } from 'features/filters/filtersSlice'
import { useSelector } from 'react-redux'
import { useFetchProducts } from 'hooks/useFetchProducts'
import { Dragable } from 'components/common/Dragable'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { UseDialogType } from 'hooks/useDialog'
import { ClockIcon } from 'assets/icons'
import { NavigationBarContainer } from 'pages/Home/NavigationBarContext'
import { ProgressBarContext } from 'components/ProgressBar/ProgressBarContext'
import { ObjectProps } from 'types'
import { useReadCache } from 'hooks/useReadCache'
import { useAuthenticationDataContext } from 'components/guards/AuthenticationContext'
import { SnackInfo } from 'componentsv2'
import { ProgressBar } from 'components/ProgressBar'
import { setMarginTopByPageContainerMarginTop } from 'componentsv2/MenuBar/utils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { ProductProps, ProductsModalProps } from './types'
import styles from './styles.module.scss'
import productStyles from '../Product/styles.module.scss'
import { ProductDetailsModal } from '../Product/components/ProductDetailsModal'
import { ProductThumbnail } from './components/ProductThumbnail'
import { cloneDeep, uniqBy } from 'lodash'

export const ProductsModal = ({
  productUrls,
  productInOutUrls,
  productType,
  onClose,
  isOpen = true,
  headerLeading,
  headerTitle,
  headerDate,
  headerTrailing,
  currency,
  enableActions,
  comparisonId,
  sortField,
}: ProductsModalProps) => {
  // 值为 true 显示大产品列表模态框，值为 false 显示小产品列表模态框（最多3条数据）
  const [ expandedModal, setExpandedModal ] = useState(false)

  // 是否显示全屏, 这里的全屏是指除了 Header 之外的全屏
  const [ showFullScreen, setShowFullScreen ] = useState(false)

  // 一页多少条数据
  const productsPerPage = 50
  // 页面上显示的产品列表
  const [ data, setData ] = useState<ProductProps[]>([])
  // 是否显示 show more 按钮
  const [ showMoreButton, setShowMoreButton ] = useState(true)

  // 所有产品 nornaid 组成的数组
  const [ urls, setUrls ] = useState<string[]>([])
  const [ inOutLoading, setInOutLoading ] = useState(false)
  const [ rowCount, setRowCount ] = useState(3)

  // Filter -> Country {key: 'Germany', currency: 'EUR'}
  const filterCountry = useSelector(selectMarket)

  useDeepCompareEffect(() => {
    if (productInOutUrls && productType) {
      setInOutLoading(true)
      setUrls(productType === 'in' ? productInOutUrls.productInUrls : productInOutUrls.productOutUrls)
    } else if (Array.isArray(productUrls)) {
      setUrls(productUrls)
    }
  }, [ productUrls, productInOutUrls, productType, [] ])

  const { data: productData, loading, error, refetch } = useFetchProducts({
    ids: productType ? urls : urls.slice(0, productsPerPage),
    currency: currency || filterCountry.currency,
    enableCache: false,
  })

  useDeepCompareEffect(() => {
    const productList = cloneDeep(productData)
    if (!Array.isArray(productList)) return

    if (productList.length > 0) {
      let shouldAdd = true
      if (data.length) {
        if (
          data[data.length - 1].nornaid ===
          productList[productList.length - 1].nornaid
        ) {
          shouldAdd = false
        }
      }
      let newData = [ ...data ]
      if (productType) {
        newData = [ ...productList ]
      } else if (shouldAdd) {
        newData = uniqBy([ ...data, ...productList ], 'nornaid')
      }

      if (newData.length < urls.length) {
        newData = newData.slice(0, newData.length - newData.length % rowCount)
      }
      setData([ ...newData ])

      if (expandedModal === true || showFullScreen) {   // 大产品列表页，总数相同时不显示 show more
        if (newData.length === urls.length) {
          setShowMoreButton(false)
        }
      } else {    // 小产品列表页，小于等于3条数据，不显示 show more
        setShowMoreButton(newData.length > 3)
      }
    } else {
      setShowMoreButton(false)
      setData([])
    }
    setInOutLoading(false)
    setShowMoreLoading(false)
  }, [productData, expandedModal, rowCount, {}]); // eslint-disable-line

  // show more 按钮点击后如果发网络请求，设置该值为 true，此时不能点击按钮，请求结束数据处理完成后设置该值为 false
  const [ showMoreLoading, setShowMoreLoading ] = useState(false)

  // 点击 show more 按钮
  const onClickShowMoreBtn = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation()
    if (showFullScreen) {
      setShowMoreLoading(true)
      const start = data.length
      refetch(urls.slice(start, start + rowCount * 5), currency || filterCountry.currency)
    } 
    // 小弹窗点击 show more 会变成大弹窗
    else if (expandedModal === false) {
      setExpandedModal(true)
    } else {
      setShowMoreLoading(true)
      const start = data.length
      refetch(urls.slice(start, start + rowCount * 5), currency || filterCountry.currency)
    }
  }

  const dialogRef = useRef<DialogRefType>({} as DialogRefType)
  /* ******************************** product detail modal *************************************** */

  // 显示产品详情模态框
  const onShowProductDetailModal = useCallback((e, product) => {
    e.stopPropagation()
    const closeFn = dialogRef.current?.closeDialog
    dialogRef.current?.openDialog?.('Product detail', (
      <div className={productStyles.quickView}>
        <ProductDetailsModal
          nornaid={product.nornaid}
          closeProductDetails={closeFn}
          toCurrency={currency}
          vendorCode={product?.seller?.vendor}
        />
      </div>
    ), UseDialogType.PROUDUCT_DETAIL)
  }, [ dialogRef, currency ])

  /* ******************************** exclude products *************************************** */
  // 临时存储排除的产品列表
  const [ excludedProducts, setExcludedProducts ] = useState<string[]>([])

  // 排除产品 api
  const { addExcludedProducts, loading: addExcludedProductsLoading } = useAddExcludedProducts()

  // 发送请求排除选定的产品列表，然后重新加载当前页面
  const onExcludeProducts = async () => {
    await addExcludedProducts(comparisonId, excludedProducts)
    window.location.reload()
  }

  // 切换选择【排除/添加】产品列表
  const onToggleExcludeProduct = async (urlId: string) => {
    if (excludedProducts.includes(urlId)) {
      setExcludedProducts(excludedProducts.filter(item => item !== urlId))
    } else {
      setExcludedProducts([ ...excludedProducts, urlId ])
    }
  }

  /* ******************************** 鼠标悬浮放大产品卡片 *************************************** */
  const modalRef = useRef<HTMLDivElement>(null)

  /**
   * 判断一行显示几个产品
   */
  const handleRowCount = () => {
    const modalEl = modalRef.current
    if (!modalEl) return
    const rect = modalEl.getBoundingClientRect()
    const modalWidth = rect.width
    // 第一个10是外层容器内边距, 第二个10是内层容器内边距, x2 是要去除左右两边的内边距
    const paddingWidth = 10 * 2 + 10 * 2
    const productWidth = 131
    const rowCount = Math.floor((modalWidth - paddingWidth) / productWidth)
    setRowCount(rowCount)
  }

  const handleTransformOrigin = ({ index, rowCount, total }) => {
    // 左上角产品往右下角放大
    if (index === 0) {
      return '0% 0%'
    }
    // 右上角往下角方法
    if (index + 1 === rowCount) {
      return '100% 0%'
    }
    // 最上面一行往下方放大
    if (index < rowCount) {
      return '50% 0%'
    }
    // 左下角往右上角放大
    // if ((total - total % rowCount) === index) {
    if ((total - rowCount) === index) {
      return '0% 100%'
    }
    // 右下角往左上角放大
    if ((total - 1) === index) {
      return '100% 100%'
    }
    // 最下面一行往上放大
    // if ((total - total % rowCount) < index) {
    if (total - rowCount <= index) {
      return '50% 100%'
    }
    // 最左边一列往右边放大
    if (index % rowCount === 0) {
      return '0% 50%'
    }
    // 最右边一列往左边放大
    if ((index + 1) % rowCount === 0) {
      return '100% 50%'
    }
    return '50% 50%'
  }

  useLayoutEffect(() => {
    handleRowCount()
  }, [ expandedModal, showFullScreen ])

  /* ******************************** modal header *************************************** */
  const headerLeadingEle =
    typeof headerLeading !== 'undefined' ? (
      <div className={styles.headerLeading}>
        {headerLeading}
      </div>
    ) : null

  // 排除产品
  const headerSaveBtn = (
    <ShouldRender shouldRender={excludedProducts.length !== 0}>
      <Button
        disabled={addExcludedProductsLoading}
        primary
        className={styles.headerSaveBtn}
        onClick={onExcludeProducts}
      >
        Save
      </Button>
    </ShouldRender>
  )

  const headerTitleEle = (
    <div className={styles.headerTitle} style={{ width: typeof headerTitle === 'string' ? '80%' : '230px' }}>
      {
        typeof headerTitle === 'string' ? (
          <div className={styles.headerTitleInner}>{headerTitle}</div>
        ) : (
          <>{headerTitle}</>
        )
      }
      <ShouldRender shouldRender={Boolean(headerDate)}>
        <div className={styles.headerDate}><ClockIcon right={4} />{headerDate}</div>
      </ShouldRender>
      {headerSaveBtn}
    </div>
  )

  const modalHeaderEle = (
    <div className={styles.modalHeader}>
      {headerLeadingEle}
      {headerTitleEle}
      {headerTrailing}
      <ShouldRender shouldRender={true}>
        <div
          className={styles.expandIcon}
          onClick={() => {
            setShowFullScreen(!showFullScreen)
            setMarginTopByPageContainerMarginTop(styles.fullScreenModal,0)
            if (!showFullScreen && data.length === urls?.length) {
              setShowMoreButton(false)
            }
            if (showFullScreen && !expandedModal && data.length > 3) {
              setShowMoreButton(true)
            }
          }}
        >
          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2935" width="14" height="14">
            <path d="M670.165333 414.165333l135.338667-135.338666L896 369.322667V128h-241.322667l90.496 90.496-135.338666 135.338667z m75.008 391.338667L654.677333 896H896v-241.322667l-90.496 90.496-135.338667-135.338666-60.330666 60.330666z m-391.338666-195.669333l-135.338667 135.338666L128 654.677333V896h241.322667l-90.496-90.496 135.338666-135.338667zM278.826667 218.496L369.322667 128H128v241.322667l90.496-90.496 135.338667 135.338666 60.330666-60.330666z" fill="#999999" p-id="2936" />
          </svg>
        </div>
      </ShouldRender>
      <CloseIcon onClick={onClose} />
    </div>
  )

  const [ loadingBar, setLoadingBar ] = useState(null)
  const { user } = useAuthenticationDataContext()
  const { sharedFilterInfo } = useReadCache(user)
  const snackRef = React.useRef<ObjectProps>({})

  React.useEffect(() => {
    if (sharedFilterInfo) snackRef.current?.open?.(sharedFilterInfo)
  }, [ sharedFilterInfo, snackRef ])

  const modalElement = (
    <>
      <div
        ref={modalRef}
        className={classnames({
          [styles.modalWrapper]: true,
          [styles.bigModal]: !showFullScreen && expandedModal,
          [styles.fullScreenModal]: showFullScreen,
          productsModal: true,
        })}
        style={{ cursor: showFullScreen ? 'auto' : 'move' }}
        onClick={e => e.stopPropagation()}
      >
        {modalHeaderEle}
        <Spin spinning={(loading && !data.length) || inOutLoading} minHeight={220} style={{ cursor: 'auto' }} className={styles.bodyWrapper}>
          <div className={styles.modalBody} onMouseDown={e => e.stopPropagation()}>
            {
              data.length ? (expandedModal || (!expandedModal && showFullScreen) ? data : data.slice(0, 3)).map((product, index, arr) =>
                <ProductThumbnail
                  key={product.nornaid + index.toString()}
                  product={product}
                  onClick={onShowProductDetailModal}
                  enableActions={enableActions}
                  toggleExcludedProduct={onToggleExcludeProduct}
                  style={{ 
                    transformOrigin: handleTransformOrigin({ index, rowCount, total: arr.length }),
                    height: rowCount === 3 ? '207px' : '257px',
                  }}
                />,
              ) : null
            }
            <ShouldRender shouldRender={!!error}>
              <NoCategory>Something went wrong, please try to refresh the page.</NoCategory>
            </ShouldRender>
          </div>
          {
            urls.length > 3 && showMoreButton && (
              <div
                className={classnames(styles.modalFooter, expandedModal && styles.modelFooterExpanded)}
              >
                <Button
                  loading={loading && !!data.length}
                  icon={<PlusIcon />}
                  disabled={showMoreLoading}
                  primary
                  onClick={e => onClickShowMoreBtn(e)}
                >Show more
                </Button>
              </div>
            )
          }
        </Spin>
      </div>
    </>
  )

  return (
    <ProgressBarContext.Provider
      value={{
        loadingBar,
        setLoadingBar,
        snackOpen: msg => snackRef.current?.open?.(msg),
      }}
    >
      <NavigationBarContainer>
        <SnackInfo ref={snackRef} />
        <ProgressBar />
        <ShouldRender shouldRender={true}>
          <ShouldRender shouldRender={showFullScreen}>
            {modalElement}
          </ShouldRender>
          <ShouldRender shouldRender={!showFullScreen}>
            <ShouldRender shouldRender={expandedModal === false}>
              <Dragable 
                width={436} 
                height={380} 
                x={-218} 
                y={-190}
              >
                {modalElement}
              </Dragable>
            </ShouldRender>
            <ShouldRender shouldRender={expandedModal === true}>
              <Dragable 
                width={870} 
                height={547} 
                x={-435} 
                y={-200}
              >
                {modalElement}
              </Dragable>
            </ShouldRender>
          </ShouldRender>
          <Dialog ref={dialogRef} />
        </ShouldRender>
      </NavigationBarContainer>
    </ProgressBarContext.Provider>
  )
}

ProductsModal.displayName = 'ProductsModal'
