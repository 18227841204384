import React, { useEffect, useRef, useState } from 'react'
import { cloneDeep } from 'lodash'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Spin } from 'componentsv2/Spin'
import { SizedBox } from 'componentsv2/SizedBox'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { Switch } from 'componentsv2/form-elements/Switch'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { OpenInNewButton, ShowWhenOpenInNew, HideWhenOpenInNew, OPEN_IN_NEW_DATE } from 'componentsv2/business/OpenInNewButton'
import { LookbookWithoutData } from 'componentsv2/LookbookPlaceholder'
import { usePageDate } from 'hooks/usePageDate'
import { vendorShortMappingFn } from 'utils'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { useLookbook } from 'hooks/useLookbook'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { useUrlParams } from 'hooks'
import { downloadFileByAxios } from 'export'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ProductsModal } from 'components/ProductsModalV2'
import { formatDateStr2 } from 'utils/dateUtils'
import { PRICE_PERSPECTIVE_ALL_ACTUAL } from 'consts'
import { useScrollBar2 } from 'hooks/useScrollBar'
import { DISCOUNTS_TABLE_NAME } from 'configs'
import { useModuleData } from 'hooks/useModuleData'
import { useDiscountsTableData } from 'hooks/api/useDiscountsTableData'
import { useFindProductIds } from 'hooks/api/useFindProductIds'
import { discountsExportFilename } from 'utils/exportUtils'
import { useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { useCompetitorOptions } from 'hooks/useOptions'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { getDragVendorOrder } from 'utils/vendorOrderUtils'
import { Vendors } from './components/Vendor'
import { HeaderVendor } from './components/HeaderVendor'
import { firstCellWidth, geneCsvData, getActualWidth, handleApiData, othersCellWidth } from './utils'
import styles from './styles.module.scss'
import GraphButton from 'componentsv2/GraphButton'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_DISCOUNTS } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'

export const DiscountsTable = () => {
  const { pageDate: dateRangeValue } = usePageDate()
  const [ filterCountry ] = useFilterCountry()
  const [ filterCompetitor ] = useFilterCompetitor()
  const { competitorOptions } = useCompetitorOptions()

  const [ moduleData, setModuleData ] = useModuleData(DISCOUNTS_TABLE_NAME)
  const { 
    fetchPayload = {},
    newCompetitorValue = [],
    switchValue = false,
  } = moduleData

  /* ************************************ Export excel ******************************************* */
  const onExportExcel = async () => {
    const payload = cloneDeep(fetchPayload)
    payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
    payload.data.groups = getGroupsPayload({ competitorSellers: filterCompetitor })
    payload.data.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
    payload.data.vendorOrder = dataSource.map(d => d.vendorCode)
    await downloadFileByAxios({
      filename: discountsExportFilename,
      payload,
      params: { unites: switchValue, metric: 'Discount' },
    })
  }

  /* ************************************ Lookbook ******************************************* */
  const { isLookbook } = useLookbook()
  const { judgeActionOccurs, clearLookbookActions } = useLookbookAction(true, true)
  const [ lookbookWithoutData, setLookbookWithoutData ] = useState(false)

  /* ***************************** Open in new *************************************** */
  const urlParams = useUrlParams()

  useEffect(() => {
      if (typeof urlParams?.tableSwitchValue !== 'undefined') {
          setSwitchValue(urlParams.tableSwitchValue === 'true')
      }
      // eslint-disable-next-line
  }, [])

  /* ************************************ Form ******************************************* */
  const setSwitchValue = (value: boolean) => {
    setModuleData({ switchValue: value })
  }

  /* ************************************ Data ******************************************* */
  const { data, loading, fetchData } = useDiscountsTableData()
  const [ dataSource, setDataSource ] = useState<any[]>([])
  const [ , setCsvData ] = useCsvDataModuleData()

  const handleData = apiData => {
    setLookbookWithoutData(isLookbook && Array.isArray(apiData) && apiData.length === 0)
    if (!Array.isArray(apiData) || apiData.length === 0) return
    const newData = handleApiData({
      apiData,
      competitorValue: newCompetitorValue,
      filterCountry,
    })
    setDataSource([ ...newData ])
    setCsvData({
      [CSV_DATA_DISCOUNTS]: geneCsvData({ dataSource: newData, switchValue }),
    })
  }

  useEffect(() => {
    if (!data) return
    handleData(data)
    // eslint-disable-next-line
  }, [data, switchValue])

  /* ***************************** StickyHeader ********************************* */
  const [ stickyHeaderData, setStickyHeaderData ] = useState<any[]>([])

  useDeepCompareEffect(() => {
    if (!Array.isArray(dataSource) || dataSource.length === 0) return
    const ele = document.querySelector('.discounts-content') as HTMLDivElement
    if (!ele) return
    let newData: any[] = cloneDeep(dataSource)
    newData = newData.map(item => ({
      vendorCode: item.vendorCode,
      vendorName: item.vendorName,
      width: othersCellWidth,
    }))
    newData.unshift({ width: firstCellWidth, name: 'Discount' })
    setStickyHeaderData([ ...newData ])
  }, [ dataSource || [] ])

  /* ************************************ Drag column ******************************************* */
  const onDrag = (dragVendor, dropVendor) => {
    const newBusinessData = getDragVendorOrder({
      dragVendor,
      dropVendor,
      dataSource,
    })
    setDataSource([ ...newBusinessData ])
  }

  /* ************************************ Product Modal ******************************************* */
  // product info dialog
  const dialogRef = useRef<DialogRefType>({} as DialogRefType)
  const { fetchData: fetchProductIds } = useFindProductIds({ moduleName: DISCOUNTS_TABLE_NAME })

  const onShowProductsModal = async (opt: any, seller) => {
    if (opt?.disabled) return

    let products: string[] = []
    if (opt?.products?.length) {
      products = [ ...opt?.products ]
    } else {
      const result = await fetchProductIds({ seller })
      if (result?.error) return

      // 更新数据
      const newDataSource = cloneDeep(dataSource)
      newDataSource.forEach(item => {
        if (item.vendorCode === opt.vendorCode) {
          item.data.forEach(item2 => {
            item2.products = result.data[item2.apiRangeName]
          })
        }
      })
      setDataSource(newDataSource)
      products = [ ...result.data[opt.apiRangeName] ]
    }

    clearLookbookActions()
    const title = `${vendorShortMappingFn(opt.vendorName)} ${opt?.rangeName} @ ${PRICE_PERSPECTIVE_ALL_ACTUAL}`
    const dialogId = title
    const closeFn = dialogRef.current?.closeDialog
    dialogRef.current?.openDialog(dialogId, (
      <ProductsModal
        productUrls={products || []}
        onClose={() => {
          closeFn(dialogId)
          if (judgeActionOccurs()) fetchData()
        }}
        headerLeading={numberUtils.formatNumberByComma(products.length)}
        headerTitle={title}
        headerDate={formatDateStr2(dateRangeValue)}
      />
    ))
  }

  /* ************************************ Synchronize two horizontal scroll bars ******************************************* */
  useScrollBar2('.discounts-header', '.discounts-content', stickyHeaderData)

  return (
    <>
      <div id="export-wrapper" style={{ padding: '0px 0px 20px', minHeight: '970px' }}>
        <ShowWhenOpenInNew>
          <FilterInfo />
        </ShowWhenOpenInNew>
        <div className="flex flex-align-center">
          <ModuleTitle category="PRICING" title={DISCOUNTS_TABLE_NAME} showComparisonDate />
        </div>
        <Spin spinning={!Array.isArray(dataSource) || loading} minHeight={880}>
          <ShouldRender shouldRender={lookbookWithoutData}>
            <LookbookWithoutData />
          </ShouldRender>
          <ShouldRender shouldRender={!lookbookWithoutData}>
            <SizedBox height={10} />
            <div className="flex flex-align-end" style={{ position: 'relative' }}>
              <HideWhenOpenInNew>
                <ExportDropdown
                  onExportExcel={onExportExcel}
                  calcJpgWidthFn={getActualWidth}
                  adjustElementFn={ele => {
                    const width = getActualWidth()
                    if (width !== 0) {
                      const wrapper = ele.querySelector('.discounts-header')?.closest('div')
                      if (wrapper) wrapper.style.width = `${width}px`
                      const headerEle: HTMLDivElement = ele.querySelector('.discounts-header') as HTMLDivElement
                      if (headerEle) headerEle.style.width = `${width}px`
                      const contentEle: HTMLDivElement = ele.querySelector('.discounts-content') as HTMLDivElement
                      if (contentEle) contentEle.style.width = `${width}px`
                    }
                  }}
                  showJPG
                  showCLIPBOARD
                  fileName={discountsExportFilename}
                  right={10}
                />
                <GraphButton />
                <OpenInNewButton
                  left={10}
                  pageParams={{
                    [OPEN_IN_NEW_DATE]: dateRangeValue,
                    tableSwitchValue: switchValue,
                  }}
                />
              </HideWhenOpenInNew>
              <div style={{ flex: 1 }} />
              <Switch
                leftLabel="Share %"
                rightLabel="#"
                right={0}
                value={switchValue}
                onChange={value => setSwitchValue(value)}
              />
            </div>
            <div className={styles.title2} style={{ marginBottom: '6px' }}>
              Discount ladder and distribution of options
              <InfoBox
                iconSty={{ margin: '0 10px' }}
                containerSty={{ left: '-150px' }}
                title="title"
                text="tips content"
              >
                <TipsContent sty={{ padding: 0 }}>
                  A discount is defined as the difference between the Sales price of a product and the Full price of the same product.
                  <br /> <br />
                  The discount width metric measures how much of the assortment that existed during the selected time-range, was on a discount.The discount depth metric measures the average discount for the products that had a discount during the selected time-range. If a product had a 0% discount during the first half of the time-range and 50% during the second half of the time-range, then the average discount depth during the full time-range was 25%.
                  <br /> <br />
                  The discount effect measures the average price reduction effect on the full assortment. If the products in the selection have an average sales price of 300 and an average full price of 400, then the discount effect is 25% using (400-300)/400.
                </TipsContent>
              </InfoBox>
            </div>
            <ShouldRender shouldRender={Array.isArray(dataSource) && dataSource.length > 0}>
              <HeaderVendor
                dataSource={stickyHeaderData}
                onDrag={onDrag}
              />
              <Vendors
                dataSource={dataSource}
                onClick={onShowProductsModal}
              />
            </ShouldRender>
          </ShouldRender>
        </Spin>
      </div>
      <Dialog ref={dialogRef} />
    </>
  )
}
