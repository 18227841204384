/**
* @file    useDialog
* @date   2022-01-25
* @author byte_su@163.com
*/

import { ActionBoxTypes ,DialogInstance } from 'components/common/InfoBox/InfoActionContext'
import React, { useCallback, useRef } from 'react'

let ID = 500

/**
 * 弹框类型
 */
export const UseDialogType = {
    PROUDUCTS: ActionBoxTypes.DIALOGV3, // product list
    
    PROUDUCT_DETAIL: ActionBoxTypes.DIALOG_PRODUCT_DETAIL, // product detail
    
    REPORT_DIALOG: ActionBoxTypes.REPORT_DIALOG, // product detail
}
export const useDialog = actionBox => {
    /**
     * useDialog 在每个页面只会被调用一次，因此 idRef 在一个页面中只会存在一个
     * 当打开第二个 dialog 时，idRef 的值就会被替换为第二个弹窗对应的 id
     */
    const idRef = useRef({ id: ID })
    return {
        closeDialog: useCallback((title?: string) => {
            let dialogId = idRef.current.id
            if (typeof title !== 'undefined') {
                dialogId = DialogInstance[title] || idRef.current.id
            }

            // 此种ID处理方式，只能打开一个dialog
            actionBox.close(dialogId)
             
            Object.keys(DialogInstance).forEach(key => {
                if(DialogInstance[key]===dialogId){
                    delete DialogInstance[key]
                }
            })
            
        }, [ idRef, actionBox ]),
        /**
         * @todo
         * 同一时刻，只支持打开同种类型的多个窗口
         * 
         * @param title 弹框ID KEY, 不能数据，ID需不一致
         * @param content  弹框内容
         * @param type  弹框类型， 同一时刻，只能打开同种类型的一个窗口
         */
        openDialog: (title: string, bodyComponent: React.ReactElement, type = UseDialogType.PROUDUCTS) => {
            if (!DialogInstance[title]) {
                DialogInstance[title] = (ID++)//eslint-disable-line
            }else{ // 已打开，不再继续打开
                return 
            }
            idRef.current.id = DialogInstance[title]
            
            actionBox.open({
                id: DialogInstance[title],
                left: 0,
                top: 0,
                title,
                close: () => {
                    actionBox?.close?.(DialogInstance[title])
                },
                type,
                bodyComponent,
            })
        },
    }
}

useDialog.displayName = 'useDialog'
