import React from 'react'

export * from './ArrowForward'
export * from './ArrowBackward'

export const CheckIcon = () =>
    <svg viewBox="0 0 448.8 448.8">
        <g id="check">
            <polygon points="142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55 		" />
        </g>
    </svg>
