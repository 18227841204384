import { arrayUtils, stringUtils } from 'norna-uikit'
import { ChatTypeEnum } from './types'

export function handleApiData(apiData) {
    if (!apiData?.length) return []
    // 两个一组
    let data = arrayUtils.chunk(apiData, 2)
    // 添加 id
    data = data.map((item) => {
        return {
            id: stringUtils.uuid(),
            data: item,
        }
    })
    return data
}

export const getRoleResponse = (chatType) => {
    return chatType === ChatTypeEnum.gemini ? 'model' : 'assistant'
}

export const getTextField = (chatType) => {
    return chatType === ChatTypeEnum.gemini ? 'text' : 'content'
}
