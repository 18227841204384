import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store.type'
import { Collection } from './collectionDataSlice.types'

/* ************************* Module default data ******************************* */
export const initData: {
  collectionList: Collection[];
} = {
  collectionList: [],
}

export const collectionDataSlice = createSlice({
  name: 'collectionData',
  initialState: initData,
  reducers: {
    updateCollectionList(state, action) {
      state.collectionList = action.payload
    },
  },
})
export const {
    updateCollectionList,
} = collectionDataSlice.actions

export const getCollectionList = (state: RootState) => state.collection.collectionList || []

export default collectionDataSlice.reducer
