import { Day, DayValue } from 'react-modern-calendar-datepicker'
import { format, getDate, getMonth, getWeek, getYear, subDays } from 'date-fns'
import { DateFormats } from 'consts'
import { DateObjectType } from 'types/date'
import { storage } from 'utils/storage'
import { lsGet } from './ls'
import { dateUtils } from 'norna-uikit'

export const getToday = () => {
  return lsGet('latestDate') || dateUtils.today()
}

export function getThisYearStr() {
  const today = getToday()
  const year = new Date(today).getFullYear()
  return `${year}-01-01_${today}`
}

/**
 * 将 DayValue 值转换为日期类型（JS 的 Date 类型）
 * 
 * DayValue 是 DatePicker 组件的数据结构，格式如：{year: number; month: number; day: number}
 * 
 * ```
 * dayValue = { year: 2022, month: 2, day: 17 }
 * datePickerToDate(dayValue)   // 相当于 new Date(2022, 1, 17)
 * ```
 */
export const datePickerToDate = (date: DayValue): Date => {
  let d = new Date()

  if (date && date.year) {
    d = new Date(date.year, date.month - 1, date.day)
  }

  return d
}

/**
 * 将 DayValue 值转换为日期字符串
 * 
 * ```
 * dayValue = {day:{ year: 2022, month: 2, day: 17 },range:null}
 * formatDatePickerToDate(dayValue)   // '2022-02-17'
 * ```
 * 
 */

export const formatDatePickerToDate = (day: DayValue): string => {
  const d = datePickerToDate(day)
  return format(d, DateFormats.DEFAULT)
}

export const formatDatePickerToDateV2 = (state: { range: any, day: DayValue }): string => {
  if (state?.range) {
    const d = datePickerToDate(state.range?.from)
    const d2 = datePickerToDate(state.range?.to)
    return [ format(d, DateFormats.DEFAULT), format(d2, DateFormats.DEFAULT) ].join('_')
  }
  const d = datePickerToDate(state.day)
  return format(d, DateFormats.DEFAULT)
}

/**
 * 获取上一周是一年中第几周 
 * 
 * ``` js
 * getLastWeekOfYear('2022-01-04')    // 2022-01-04 在2022年是第2周，上一周是第1周，返回值是1
 * getLastWeekOfYear('2022-01-01')    // 2022-01-01 在2022年是第1周，上一周是上一年的最后一周，返回值是52
 * ```
 */
export const getLastWeekOfYear = (dateStr: string): number => {
  const date = new Date(dateStr)
  let dayOfWeek = date.getDay()     // 星期几
  dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek
  // 计算上一周星期一的日期
  const startDate = subDays(date, 6 + dayOfWeek)
  return getWeek(new Date(startDate))
}

/**
 * 格式化日期字符串，格式：YYYY-MM-DD / YYYY-MM-DD
 * 
 * 只在所有展示日期的地方调用该方法
 * ```
 * formatDateStr('2022-01-28')   // '2022-01-28'
 * formatDateStr('2022-01-28_2022-01-28')   // '2022-01-28 / 2022-01-28' 
 * formatDateStr('2022-01-28_2022-01-28', true)   // '2022-01-28'
 * formatDateStr('2022-01-28_2022-01-30')   // '2022-01-28 / 2022-01-30'
 * ```
 * 
 * 第二个参数表示当【前后两个字符串】一样时，是否去重，默认值为 false，表示不去重
 * ```
 * formatDateStr('2022-01-28_2022-01-28', true)   // '2022-01-28'
 * formatDateStr('2022-01-28_2022-01-28')   // '2022-01-28 / 2022-01-28'
 * ```
 */
export const formatDateStr = (dateStr?: string, unique = false): string => {
  if (!dateStr) return ''

  const dateArr = dateStr.split('_')
  if (dateArr.length !== 2) {
    return dateStr
  } if (dateArr[0] === dateArr[1] && unique) {
    return dateArr[0]
  }
  return dateArr.join(' / ')
}

/**
 * 格式化日期字符串，格式：YYMMDD-YYMMDD
 * 
 * 只在所有展示日期的地方调用该方法
 * ```
 * formatDateStr2('2022-01-28')   // '220128-220128'
 * formatDateStr2('2022-01-28_2022-01-28')   // '220128-220128' 
 * formatDateStr2('2022-01-28_2022-01-28', true)   // '220128'
 * formatDateStr2('2022-01-28_2022-01-30')   // '220128-220130'
 * ```
 * 
 * 第二个参数表示当【前后两个字符串】一样时，是否去重，默认值为 false，表示不去重
 * ```
 * formatDateStr('2022-01-28_2022-01-28', true)   // '220128'
 * formatDateStr('2022-01-28_2022-01-28')   // '220128-220128'
 * ```
 */
export const formatDateStr2 = (dateStr?: string, unique = false): string => {
  if (!dateStr) return ''
  let dateArr = dateStr
    .split('_')
    .map(item => item.replace(/-/g, '').slice(2))
  if (dateArr.length === 1 && unique === false) {
    dateArr = [ dateArr[0], dateArr[0] ]
  }
  return dateArr.join('-')
}

/**
 * 
 * @param date 
 * @returns 
 */
export const getDateObject = (date: Date | number): DateObjectType => ({
  day: getDate(date),
  month: getMonth(date) + 1,
  year: getYear(date),
})

export function formatDate(dateStr?: string, opt?: {
  separator?: string;
}) {
  let year 
  let month 
  let day 
  let week 
  let hour 
  let minute 
  let second 
  let millisecond 
  let ymd 
  let hms 
  let ymdhms 
  let ymdhmss
  if (!dateStr || String(new Date(dateStr)) === 'Invalid Date') {
      return { year, month, day, week, hour, minute, second, millisecond, ymd, hms, ymdhms, ymdhmss }
  }
  const separator = opt?.separator || '-'
  const d = new Date(dateStr)
  year = String(d.getFullYear())
  month = String(d.getMonth() + 1).padStart(2, '0')
  day = String(d.getDate()).padStart(2, '0')
  hour = String(d.getHours()).padStart(2, '0')
  minute = String(d.getMinutes()).padStart(2, '0')
  second = String(d.getSeconds()).padStart(2, '0')
  millisecond = String(d.getMilliseconds()).padStart(3, '0')
  const weeks = [ '周日', '周一', '周二', '周三', '周四', '周五', '周六' ]
  week = weeks[d.getDay()]
  ymd = `${year}${separator}${String(month).padStart(2, '0')}${separator}${String(day).padStart(2, '0')}`
  hms = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`
  ymdhms = `${ymd} ${hms}`
  ymdhmss = `${ymdhms}.${millisecond}`
  return { year, month, day, week, hour, minute, second, millisecond, ymd, hms, ymdhms, ymdhmss }
}

/**
 * 获取最小日期值
 * 取 sellers 中 launch_date 最小值
 */
export function getMinimumDate(): string {
  const sellers = storage.getSellers()
  const sortedLaunchDateList = sellers.map(seller => seller.launch_date)
    .sort((m, n) => Date.parse(m) - Date.parse(n))
  if (!sortedLaunchDateList.length) return ''
  return sortedLaunchDateList[0]
}

/**
 * 获取最大日期范围
 */
export function getMaxDateRange() {
  const minDate = getMinimumDate()
  const maxDate = getToday()
  return `${minDate}_${maxDate}`
}

export function getMinimumDateForPlatformAnalytics(): string {
  const sellers = storage.getSellers()
  const sortedLaunchDateList = sellers
    .filter(s => s.vendor.endsWith('@zalandodeep'))
    .map(seller => seller.launch_date)
    .sort((m, n) => Date.parse(m) - Date.parse(n))
  if (!sortedLaunchDateList.length) return ''
  return sortedLaunchDateList[0]
}

/**
 * 日期对象转字符串
 * { year: 2021, month: 11, day: 5 } -> 2021-11-05
 */
export function getDateStrByObj(o: Day): string {
  const date = o?.year ? new Date(o.year, o.month - 1, o.day) : new Date()
  return format(date, DateFormats.DEFAULT)
}

/**
 * 日期字符串转对象
 * '2021-11-05' -> { year: 2021, month: 11, day: 5 }
 */
export function getDateObjByStr(s: string): Day {
  const date = new Date(s)
  return {
    year: getYear(date),
    month: getMonth(date) + 1,
    day: getDate(date),
  }
}
