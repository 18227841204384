import { TreeDropdown } from 'componentsv2/TreeDropdown';
import { useGraphButtonModuleData } from 'features/filters/moduleDataSlice.hook';
import React, { CSSProperties, useEffect } from 'react'

export const LineChartMetricDropdown = ({
    value,
    onChange,
    onItemChange,
    options,
    left,
    right,
    enableTwoYAxis = false,
    selectorStyle,
    selectorClassName,
    showOkButton = true,
}: {
    value: string[];
    onChange?: (value: string[]) => void;
    onItemChange?: (value: string[]) => void;
    options: {key: string; description: string}[];
    left?: number | boolean;
    right?: number | boolean;
    enableTwoYAxis?: boolean;
    selectorStyle?: CSSProperties;
    selectorClassName?: string;
    showOkButton?: boolean;
}) => {
    const [ , setModuleData ] = useGraphButtonModuleData()

    useEffect(() => {
        if (enableTwoYAxis === false) {
            setModuleData({
                tmpMetricValue: value?.slice(0, 1),
            })
        }
        // eslint-disable-next-line
    }, [enableTwoYAxis])

    return (
        <TreeDropdown
            multiple={enableTwoYAxis}
            options={options}
            label="Metric"
            placeholder="Metric"
            selectorStyle={selectorStyle}
            selectorClassName={selectorClassName}
            showSelectAll={false}
            minCount={1}
            maxCount={2}
            left={left}
            right={right}
            value={value}
            onItemChange={value => {
                onItemChange?.(value)
            }}
            onChange={value => {
                onChange?.(value)
            }}
            onOk={(value: string[]) => {
                if (enableTwoYAxis) {
                    onChange?.(value)
                }
            }}
            showOkButton={showOkButton}
        />
    )
}
