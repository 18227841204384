import { GROUP_BOGNER_SUFFIX, GROUP_BOGNER_NAME } from 'configs/groups'
import { SELECTED_VENDORS, MARKET_VENDOR } from 'consts'
import { uniqBy } from 'lodash'
import { difference } from 'utils/array'
import { storage } from './storage'

/**
 * 获取 groups 参数
 * 
 * 功能介绍: 对 vendor 进行合并分组
 * 比如 Competitor 下拉框选中了 ['wera', 'Aaa@bogner', 'Bbb@bogner']
 * 之前的操作是线图和表格只会显示三个 vendor, 也就是勾选的三个 vendor
 * 现在会显示四个 vendor, 勾选的三个 vendor +【bogner subbrand 合并之后的 vendor】
 * 
 * 当出现 bogner license 的 subbrand 勾选大于1个时, 需要传参 groups, 格式为:
 * ``` js
 * // 这里的 Bogner Group 是自定义名称, 就是页面上将会显示的第四个 vendor 的名称
 * "groups": {
 *    "Bogner Group": [{
 *		  "vendor": "Aaa@bogner",
 *			"region": "Germany"
 *		}, {
 *			"vendor": "Bbb@bogner",
 *			"region": "Germany"
 *		}]
 * }
 * ```
 * 
 * 大多数页面的 competitor 传参都是 [{vendor: string; region: string}]
 * 只有 Dashboard 的线图传参时 [vendor1, vendor2], 所以线图使用该方法需要自行转换下参数格式
 * 
 * 目前只针对 bogner license, 之后有其它 vendor 或有多个 vendor 具有 subbrand 时再进行扩展
 */
export const getGroupsPayload = ({
    competitorValue = [],
    customerVendor,
    region,
}: {
    competitorValue: Array<{ vendor: string; region: string; }>;
    customerVendor?: string;
    region: string;
}): Record<string, Array<{ vendor: string; region: string; }>> => {
    customerVendor = customerVendor || storage.getCustomerVendor()
    const res =  {}
    // add SELECTED_VENDORS
    if(competitorValue.map(item => item.vendor).includes(SELECTED_VENDORS)){
        // 2022/07/16  groups 参数中需要过滤掉 Market
        res[SELECTED_VENDORS] =  uniqBy([
            ...competitorValue.filter(item => item.vendor !== SELECTED_VENDORS).filter(item => item.vendor !== MARKET_VENDOR.vendor),
            { vendor:customerVendor,region },
        ], 'vendor')
    }

    if (customerVendor === 'bogner') {
        const subbrandList = competitorValue.filter(item => String(item.vendor || '').endsWith(GROUP_BOGNER_SUFFIX))
        if (subbrandList.length < 2) return res
        res[GROUP_BOGNER_NAME]= subbrandList.map(item => ({ vendor: item.vendor, region: item.region }))
        return res
    }

    return res
}

/**
 * 判断 Competitor 下拉框的 Group 是否发生变化 
 * 
 * 之前 Competitor 比如选中了 6 个 vendor, 然后再 deselect 这 6 个 vendor 中任意 vendor,
 * 是不会重新发请求的, 直接对已有数据进行过滤
 * 
 * 现在加了 group, 逻辑就变了, Competitor 选中 6 个 vendor, 其中有三个是 subbrand, 此时接口会
 * 多返回一个 Bogner Group 的 vendor, 值是根据选中的三个 subbrand 计算的
 * 
 * 此时再 deselect 其中一个 subbrand, 还有两个 subbrand 被选中, 按照之前的逻辑是不会重新发请求的, 但现在必须要重新发请求,
 * Bogner Group 的值得根据选中的两个 subbrand 重新计算
 */
export function isCompetitorGroupChanged({
    oldCompetitorValue,
    newCompetitorValue,
}: {
    oldCompetitorValue: string[];
    newCompetitorValue: string[];
}): boolean {
    // 下拉框最新 select 选项
    const selectDiffValue = difference(newCompetitorValue, oldCompetitorValue)
    // 下拉框最新 deselect 选项
    const deselectDiffValue = difference(oldCompetitorValue, newCompetitorValue)
    // 下拉框变化项, 多选下拉框可以 select 一些项, 并且同时 deselect 一些项
    const diffValue = Array.from(new Set([ ...selectDiffValue, ...deselectDiffValue ]))

    if (diffValue.some(item => item.endsWith(GROUP_BOGNER_SUFFIX))) {
        return true
    }

    if (newCompetitorValue.includes(SELECTED_VENDORS) && diffValue.length > 0) {
        return true
    }

    return false
}
