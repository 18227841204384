import React, { useCallback } from 'react'
import { Message } from 'components/form-elements/Message'
import styles from './styles.module.scss'
import { ErrorMessageProps } from './types'

export const ErrorMessage = ({ errorMessage }: ErrorMessageProps) => {
  const reloadPage = useCallback(() => {
    window.location.reload()
  }, [])
  if (window.location.pathname.indexOf('/login') > -1 || window.location.pathname === '/') return <></>
  return (
      <div className={styles.error}>
          {errorMessage ?
              <Message error message={errorMessage} />
        :
              <Message
                  error
                  message="An error occured."
                  action={<div onClick={reloadPage}>Reload the page and try again.</div>}
              />
      }
      </div>
  )
}
