import React, { Fragment, useCallback, useImperativeHandle, useRef } from 'react'
import classnames from 'classnames'
import { cloneDeep, difference, isEmpty } from 'lodash'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { CATEGORY_TOTAL, ColorMaterialDefaultValue, ColorMaterialEmptyValue, IS_LIVE_ENV } from 'consts'
import { arrayOrStrjoinToStr } from 'utils/sortCategory'
import { useLookbook } from 'hooks/useLookbook'
import useCustomState from 'hooks/useCustomState'
import { useSelector } from 'react-redux'
import { getFilterTags } from 'features/filters/globalDataSlice'
import styles from './styles.module.scss'
import { FILTER_LABELS, getActiveCategoryList, getLeafShouldActive, getLevelActive, getSelectedCategories, getTreeVals, isLookbooktoAnalysisChange, setAllActive, setOneLevelCategorySelectedBy } from './utils'
import { TreeProps, TreeItemProps, TreeInteractiveType, TreeStateType } from './types'
import { TreeItem, TreeItemCustom } from './TreeItem'
import { DataType } from './common'

/**
 * ## un/select item action
 * @param indexArr
 * @param selectAll
 * @param tmp
 */
export const treeActionFn = (indexArr, selectAll, tmp, isLookbook = false) => {

  // ------------------------ old categories before update --------------------------------
  const oldCategories = getSelectedCategories(tmp)

  indexArr = indexArr.flat(10)
  const { leafActive } = getLeafShouldActive(indexArr, tmp)                    // the corresponding node leaf status after current interaction

  let tmpItem: any = null
  let parentItem: any = null

  indexArr.forEach((indexObj, indexNum) => {

    parentItem = tmpItem
    tmpItem = tmpItem ? tmpItem?.children[indexObj] : tmp[indexObj]

    const isLastNode = indexNum === indexArr.length - 1// last level node
    //
    if (tmpItem.children && !leafActive) {                                      // unactive after interaction
      if (!tmpItem.disabled) {
        const leafActiveFrom = getLevelActive(tmpItem)
        tmpItem.active = indexNum < indexArr.length - 1 ? leafActiveFrom : leafActive// getLevelActive(tmpItem);// leafActive;
      }
    }
    //
    else if ((tmpItem.type === DataType.SINGLE_CHECK || tmpItem.type === DataType.SINGLE_CHECK_ARROW)
      && parentItem) { // leaf node
      parentItem.children.forEach((el, indexEl) => {
        if (indexEl === indexObj) {
          el.active = leafActive
          if (leafActive && el.children && !tmpItem.disabled) {
            isLastNode && setAllActive(el, leafActive)
          }
        } else {
          el.active = false
          if (!el.active && el.children) {
            setAllActive(el, false)
          }
        }
      })
    } else if (!tmpItem.disabled) {
      tmpItem.active = leafActive
    }
    if (isLastNode && tmpItem.children && `${selectAll}` !== 'undefined') { // 包含下层级的反/全选

      setAllActive(tmpItem, leafActive)//
    }

  })

  /**
   * 1. live 环境用户取消所有Category勾选时，默认勾选第一个Category
   * 2. 勾选`All categories` 和 其他的 Categories 时，两者互斥
   */
  const categoryTmp = tmp.find(item => item.label === FILTER_LABELS.CATEGORY)
  if (categoryTmp.val === FILTER_LABELS.CATEGORY) {
    const list = getActiveCategoryList(categoryTmp.children)
    if (!list.length && IS_LIVE_ENV) { // 未选择Category
      // if (categoryTmp.children.length) {
      //   setOneLevelCategorySelectedBy(categoryTmp.children[0].label, categoryTmp.children)
      // }
    }
    const newCategories = getSelectedCategories(tmp)
    const comparedCategories = difference(newCategories, oldCategories)

    if (comparedCategories.length > 0) { // selected new one category

      // @todo select all
      if (indexArr.toString() === '0' && comparedCategories.length > 0) { // select all
        categoryTmp.children.forEach((category: TreeItemProps) => {
          setOneLevelCategorySelectedBy(category.label, categoryTmp.children, category.label !== CATEGORY_TOTAL)
        })
        // clear the rest categories when last selected one is `CATEGORY_TOTAL`
      } else if (comparedCategories.includes(CATEGORY_TOTAL)) {
        categoryTmp.children.forEach((category: TreeItemProps) => {
          if (category.label !== CATEGORY_TOTAL) {
            setOneLevelCategorySelectedBy(category.label, categoryTmp.children, false)
          }
        })
      } else { // clear CATEGORY_TOTAL
        setOneLevelCategorySelectedBy(CATEGORY_TOTAL, categoryTmp.children, false)
      }
    }
  }

  // linkage begin
  tmp.forEach(item => {
    if (!item?.linkField) return true
    // 找到关联字段对象
    const linkField = tmp.find(el => el.label === item.linkField)
    if (!linkField) return true

    // 关联字段选中项
    const feildVals = linkField.children
      .filter(child => child.active)
      .map(child => {
        if (child?.children?.length) {
          const activeItem = child.children.filter(item => item.active)
          // multi level
          if (activeItem.length) return activeItem.map(item => item.label)
        }
        return child.label
      })

    if (feildVals.length || item.linkAllowEmpty) {
      // feildVals 关联字段当前选中值
      // item.linkValue 关联字段上一次选中值
      if (arrayOrStrjoinToStr(feildVals.flat(10)) !== arrayOrStrjoinToStr(item.linkValue || [])) { //
        item.children = item.linkFn(arrayOrStrjoinToStr(feildVals.flat(10)), item)

        item.val = ''
        // item.active = false;
        if (Array.isArray(item?.children) && item.children.length > 0) {
          // 只要有一个子项的 active 为 true，那么父项的 active 也为 true
          item.active = item.children.some(c => c?.active === true)
        }

        item.linkValue = arrayOrStrjoinToStr(feildVals.flat(10))                  // save current linkField value
      }
    }
    if (!isLookbook) item.disabled = !feildVals.length
  })

  const valueUpdated = getTreeVals(tmp, isLookbook)

  try {
    //= ===============================  rm `Color filter` in live env   ================================
    // if (!IS_LIVE_ENV) {
    const colorFilterList = tmp.filter(item => item.label === FILTER_LABELS.COLOR_FILTER)
    if (colorFilterList.length) {
      // update `Color Filter` activate and set 95% value when property `color` is the first time selected
      if (valueUpdated?.property?.Colors) {

        colorFilterList[0].val = colorFilterList[0].val.checked ? colorFilterList[0].val : ColorMaterialDefaultValue
        colorFilterList[0].active = true

      } else if (!valueUpdated?.property?.Colors || isEmpty(!valueUpdated?.property?.Colors)) {
        colorFilterList[0].val = ColorMaterialEmptyValue
        colorFilterList[0].active = false
      }
    }
    // }
    const materialFilterList = tmp.filter(item => item.label === FILTER_LABELS.MATERIAL_FILTER)
    if (materialFilterList.length) {

      // update `Material Filter` activate and set 51% value when property.`materiall` is the first time selected
      if (valueUpdated?.property?.Material) {

        materialFilterList[0].val = materialFilterList[0].val.checked ? materialFilterList[0].val : ColorMaterialDefaultValue
        materialFilterList[0].active = true

      } else if (!valueUpdated?.property?.Material || isEmpty(!valueUpdated?.property?.Material)) {
        materialFilterList[0].val = ColorMaterialEmptyValue
        materialFilterList[0].active = false

      }
    }

  } catch (e) {
    console.log(e)
  }

  // linkage end
  return tmp
}

/**
 * tree component including functionality below:
 * 1. [
 *  {
 *    label: '',            //item label
 *    val: '',              //item value
 *    type: '',             //item type which including
 *    children: '',         //item label
 *    click: Function,      //
 *  }
 * ]
 * @param     treeData      //init data
 * @param     changeNotice  //callback function when the treeData changed
 */
export const Tree = React.forwardRef(({ treeData, interactiveType = TreeInteractiveType.HOVER, changeNotice, anchor, lineAfterIndexArr = [], onClickMask, maskPanelStyleCallback }: TreeProps, ref) => {
  const [ pageData, setPageData ] = React.useState<Array<TreeItemProps>>(treeData)

  const { state, updateState } = useCustomState<TreeStateType>({ active: '' })

  const prevDeps = useRef(treeData)
  const { isLookbook } = useLookbook()

  /**
   * ## 如果是由Lookbook 切换到Analysis, 保留最新的Category选中状态, 避免被旧的Category 覆盖到
   */
  const mergeCategoryFromCurrentFilter = useCallback((treeData: any) => {
    const treeDataCopy = cloneDeep(treeData)
    const treeDataCopy2 = cloneDeep(treeData)
    const categoryIndex = pageData.findIndex(item => item.label === FILTER_LABELS.CATEGORY)
    if (categoryIndex !== -1) {
      // 用户选了Category导致从Lookbook 切换到Analysis
      if (pageData[categoryIndex].active) {
        treeDataCopy[categoryIndex] = cloneDeep(pageData[categoryIndex])
      }
    }

    setPageData(treeDataCopy)
    prevDeps.current = treeDataCopy2
  }, [ pageData ])

  const propChange = useCallback(treeData => {

    if (JSON.stringify(treeData) !== JSON.stringify(prevDeps.current)) {
      /**
       * ## 此处判断由`Lookbook` 切换到 `Analysis`的变化
       *    此种变化时`Category`状态继承Tree中最新的状态
       */
      if (isLookbooktoAnalysisChange(prevDeps.current, treeData)) {
        mergeCategoryFromCurrentFilter(treeData)
      } else {   // 此处需合并 page data
        setPageData(treeData)
        prevDeps.current = treeData
      }
    }
  }, [ mergeCategoryFromCurrentFilter ])

  useDeepCompareEffect(() => {
    propChange(treeData)
  }, [ treeData ])

  const updateTreeData = useCallback((newPageData: Array<TreeItemProps>) => setPageData(newPageData), [])
  const getVals = useCallback(() => pageData, [ pageData ])

  useImperativeHandle(ref, () => ({
    getVals,
    updateTreeData,
  }), [ getVals, updateTreeData ])

  const filterTmpState = useSelector(getFilterTags)

  return (
    <div className={classnames([ styles['norna-tree'], 'norna-tree' ])}>
      <div className={styles.arrow} />
      <div className={styles.panelMask} />
      <div className={styles.treeContent}>
        <div className={styles['tree-title']}>Filter</div>
        <div>
          {pageData.map((item, index) => {
            if (item.render) {
              return (
                <TreeItemCustom
                  {...item}
                  key={item.label}
                  item={item}
                  setTreeItem={itemObj => { // the function of updating current item content by item structure
                    if (item.disabled) return
                    const tmp = cloneDeep([
                      ...pageData.slice(0, index),
                      { ...item, ...itemObj },
                      ...pageData.slice(index + 1),
                    ])
                    setPageData(tmp)
                    changeNotice(tmp)
                  }}
                  getVals={getVals}
                  updateTreeData={updateTreeData}
                  expandSubPanel={state.active === item.label}
                  updateExpandSubPanel={(flag: boolean) => updateState({ active: flag ? item.label : '' })}
                />
              )
            }
            return (
              <Fragment key={item.label}>
                <TreeItem
                  {...item}
                  index={[ index ]}
                  anchor={anchor}
                  maskPanelStyleCallback={maskPanelStyleCallback}
                  filterTmpState={filterTmpState}
                  setTreeItem={itemObj => { // the function of updating current item content by item structure
                    const tmp = cloneDeep([
                      ...pageData.slice(0, index),
                      { ...item, ...itemObj },
                      ...pageData.slice(index + 1),
                    ])
                    setPageData(cloneDeep(tmp))
                    return cloneDeep(tmp)
                    // changeNotice(cloneDeep(tmp));
                  }}
                  item={item}
                  expandSubPanel={state.active === item.label}
                  updateExpandSubPanel={(flag: boolean) => updateState({ active: flag ? item.label : '' })}
                  onChange={(indexArr, selectAll: boolean) => { // 存在索引表示选中
                    const tmp = treeActionFn(indexArr, selectAll, cloneDeep(pageData), isLookbook)
                    setPageData(tmp)
                    changeNotice(tmp)
                    /**
                     * 调用一级元素传递的 onChange事件
                     */
                    item.onChange?.(tmp)
                  }}
                  onClickMask={() => onClickMask?.()}
                />
                {lineAfterIndexArr.indexOf(item.label) > -1 ? <div className={styles.lineSplit} /> : null}
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
})

Tree.defaultProps = {
  anchor: 'left',
}
