import React, { FC, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterExcludedProductsByVendor, selectExcludedProducts } from 'features/filters/filtersSlice'
import { Product } from 'components/Product'
import { ProductCardProps } from './types'

/**
 * ## ProductCard
 * 
 * 产品卡片
 */
export const ProductCard: FC<ProductCardProps> = memo(({
    image,          // 产品图片
    vendor,         // 产品供应商
    vendorName,
    vendorCode,
    brand,          // 产品品牌
    name,           // 产品名称
    leftPrice,
    rightPrice,
    enabledQa=false,
    taggedQa={},
    fullyConfirmed=false,
    currency,
    lookbooks,
    nornaid,
    style = {},
    sizePriceRange,
    latestSizePrice,
}) => {
    const excludedProductsObj = useSelector(selectExcludedProducts)
    const excludedProducts = excludedProductsObj[vendor] || []
    const dispatch = useDispatch()

    const handleExcludedProducts = useCallback((nornaid: string) => {
        if (Array.isArray(excludedProducts) && excludedProducts.includes(nornaid)) {
            const toExclude = excludedProducts.filter(item => item !== nornaid)
            dispatch(filterExcludedProductsByVendor({
                vendor,
                products: toExclude,
            }))
        } else {
            const toExclude = [ ...excludedProducts ]
            toExclude.push(nornaid)
            dispatch(filterExcludedProductsByVendor({
                vendor,
                products: toExclude,
            }))
        }
    }, [ excludedProducts, vendor, dispatch ])

    return (
        <Product 
            id={nornaid}
            date="2021-10-18"
            category=""
            categoryName=""
            url=""
            enabledQa={enabledQa}
            taggedQa={taggedQa}
            lookbooks={lookbooks}
            handleExcludedProducts={handleExcludedProducts}
            vendor={vendor}
            vendorName={vendorName}
            vendorCode={vendorCode}
            brand={brand}
            title={name}
            fullyConfirmed={fullyConfirmed}
            interpolatedPrice={{ '2021-10-18': { discounted_price: leftPrice === rightPrice ? 0 : rightPrice, original_price: leftPrice } }}
            imageUrl={image}
            nornaid={nornaid}
            currency={currency}
            style={style}
            isShowInDialog
            sizePriceRange={sizePriceRange}
            latestSizePrice={latestSizePrice}
            isBgTransparent={'Lacoste' === vendorName}
        />
    )
})

ProductCard.displayName = 'ProductCard'
