import React, { useState } from 'react'
import classnames from 'classnames'
import { ArrowDirectionIcon } from 'componentsv2/business/MaterialFilter/icons'
import { ClickAwayListener } from '@material-ui/core'
import { MaterialFilterProps } from './types'
import styles from './styles.module.scss'

export const MaterialSplitMaterialFilter = ({
    percent,
    checked,
    style = {},
    className,
    min = 0,
    disabled = false,
    onActivate,
    onDeactivate,
}: MaterialFilterProps) => {
    const [ valInner, setValInner ] = useState(percent)
    const [ checkedInner, setChecked ] = useState(checked)

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (disabled) return
                setChecked(true)
                onActivate?.(valInner)
            }}
        >
            <div className={classnames([ styles.content, className, disabled ? styles.disabled : '' ])} style={style}>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                    <div style={{ color: '#666' }}>Count only products with more than:</div>
                </div>
                <div className={classnames(styles['input-content'], checkedInner ? '' : styles.red)}>
                    <div>
                        <div>Material</div>
                        <div>
                            <input
                                disabled={disabled}
                                value={valInner}
                                onChange={(e:any) =>  {
                                    setChecked(false)
                                    onDeactivate?.()
                                    setValInner(e.target.value)
                                }}
                                onBlur={e => {
                                    setChecked(true)
                                    // parseInt('')     is   NaN
                                    // parseInt('a9')   is   NaN
                                    // parseInt('9a')   is   9
                                    let num = 0
                                    if (Number(e.target.value[0]).toString() !== 'NaN') {
                                        num = parseInt(e.target.value, 10)
                                    }
                                    if (num > 100) {
                                        num = 100
                                    } else if (num < min) {
                                        num = min
                                    }
                                    setValInner(num > 100 ? 100 : num)
                                    onActivate?.(valInner)
                                }}
                            />
                            <span>|</span>
                            <span>%</span>
                        </div>
                    </div>
                    <button
                        disabled={disabled}
                        onClick={() => {
                            onDeactivate?.()
                            setChecked(false)
                            const t = valInner + 1
                            setValInner(t > 100 ? 100 : t)
                        }}
                    >
                        <ArrowDirectionIcon />
                    </button>
                    <button
                        disabled={disabled}
                        onClick={() => {
                            onDeactivate?.()
                            setChecked(false)
                            const t = valInner - 1
                            setValInner(t < min ? min : t)
                        }}
                    >
                        <ArrowDirectionIcon />
                    </button>
                </div>
            </div >
        </ClickAwayListener>
    )
}
