import { getDateRangeValueByPeriod } from 'componentsv2/form-elements/DatePicker'
import { ALL_VENDORS, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { format } from 'date-fns'
import { MonthPanelValue, dateUtils } from 'druikit'
import { cloneDeep } from 'lodash'
import { getVendorNameByCode } from 'utils'
import { DYNAMIC_DASHBOARD_TOOLTIP_PREFIX, INTRO_MIX, MARKET_AVERAGE, SELECTED_COMPETITORS_AVERAGE } from './const'
import { COMPARE_TO_PREVIOUS_PERIOD, COMPARE_TO_YEAR_ON_YEAR, PERIOD_LAST_MONTH, PERIOD_LAST_QUARTER, PERIOD_LAST_WEEK } from 'features/filters/dynamicDashboardSlice'
import { getTooltipText } from 'configs/tooltip'
import { numberUtils } from 'norna-uikit'

export const exportSelector = 'dynamic-dashboard'
export const exportModalSelector = 'dynamic-dashboard-detail'

const formatMapper = [
    {
        metric: 'Options #',
        name: 'New options #',
        field: 'product_newness',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1, isCommaSymbol: true }),
                // currency: 'OPT',
            }
        },
    },
    {
        metric: 'Product Newness Average price',
        name: 'Average price',
        field: 'newness_average_price',
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1, isCommaSymbol: true }),
                currency,
                description: 'The average price of the new options.',
            }
        },
    },
    {
        metric: 'Intro mix',
        name: 'Intro mix',
        field: 'intro_mix',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { isCentuple: true, isPercentSymbol: true, decimal: 1, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1, isCommaSymbol: true }),
                showUnits: true,
                description: getTooltipText(DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + INTRO_MIX),
            } 
        },
    },
    {
        metric: 'Assortment split',
        name: 'Assortment split',
        field: 'assortment_mix',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { decimal: 1,isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                description: 'The ”self assortment split”, meaning the selected categories share of the full assortment of each group of companies/competitors that are shown. The split for yourself compares to your full assortment, and the split for the Market compares to the full assortment of the market including yourself and all your competitors.',
            }
        },
    },
    {
        metric: 'Average price',
        name: 'Average price',
        field: 'average_price',
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            }
        },
    },
    {
        metric: 'Market index',
        name: 'Market index',
        field: 'market_index',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                showUnits: true,
                description: 'The assortment split of yourself divided by the assortment split of the Market. If the assortment split of a selected category is 5% for yourself and 10% for the Market, the Market index will be 50%, meaning that the selected category is only half as dominant within your own full assortment as it is within the assortment of the Market, including yourself.',
            }
        },
    },
    {
        metric: 'Discount effect',
        name: 'Discount effect',
        field: 'discount_effect',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                showUnits: true,
            }
        },
    },
    {
        metric: 'Discount depth',
        name: 'Discount depth',
        field: 'discount_depth',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                showUnits: true,
            }
        },
    },
    {
        metric: 'Discount width',
        name: 'Discount width',
        field: 'discount_width',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                showUnits: true,
            } 
        },
    },
    {
        metric: 'Sold out',
        name: 'Sold out',
        field: 'sold_out',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                showUnits: true,
                description: 'The share of the selected categories that was sold out during the selected time period. Sold out is measured as the unavailable number of sizes for any given group of products compared to the full number of sizes for the same group of products.',
            }
        },
    },
    {
        metric: 'Sold out viability',
        name: 'Sold out viability',
        field: 'size_viability',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                showUnits: true,
            }
        },
    },
    {
        metric: 'Options ratio',
        name: 'Options ratio',
        field: 'option_ratio',
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                description: 'The average number of product variations for each product, most often divided by color.',
            } 
        },
    },
    {
        metric: 'Sales Lowest price',
        name: 'Lowest price',
        field: 'sales_min_price',
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            }
        },
    },
    {
        metric: 'Sales Average price',
        name: 'Average price',
        field: 'sales_price_performance',
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            }
        },
    },
    {
        metric: 'Sales Highest price',
        name: 'Highest price',
        field: 'sales_max_price',
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            } 
        },
    },
    {
        metric: 'Full Lowest price',
        name: 'Lowest price',
        field: 'full_min_price',
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            }
        },
    },
    {
        metric: 'Full Average price',
        name: 'Average price',
        field: 'full_price_performance',
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            }
        },
    },
    {
        metric: 'Full Highest price',
        name: 'Highest price',
        field: 'full_max_price',
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            } 
        },
    },
]

export function handleCardDataSource({
    apiData,
    metricList,
    currency,
    customerVendor = '',
}) {
    if (!apiData || !customerVendor) return []
    const newApiData = cloneDeep(apiData)
    const dataSource: any[] = []

    const customerData = newApiData[customerVendor]?.['All categories']
    if (!customerData) return []

    dataSource.push({
        title: getVendorNameByCode(customerVendor),
        dataSource: metricList.map((metric, index) => {
            const metricObj = formatMapper.find(m => m.metric === metric)
            const metricData = customerData[metricObj?.field || '']
            return {
                name: metricObj?.name,
                ...metricObj?.format(metricData, currency),
                showFromPreviousYear: index === 0,
            }
        }),
    })

    const vendorList = Object.keys(newApiData || {})
    const comparisonVendors = vendorList.filter(v => ![ customerVendor, MARKET_VENDOR.vendor, SELECTED_VENDORS ].includes(v))
    const comparisonVendor = comparisonVendors.length === 1 ? comparisonVendors[0] : SELECTED_VENDORS
    const comparisonVendorData = newApiData[comparisonVendor]['All categories']
    dataSource.push({
        title: getVendorNameByCode(comparisonVendor),
        dataSource: metricList.map((metric, index) => {
            const metricObj = formatMapper.find(m => m.metric === metric)
            const metricData = comparisonVendorData[metricObj?.field || '']
            return {
                name: metricObj?.name,
                ...metricObj?.format(metricData, currency),
                showFromPreviousYear: index === 0,
            }
        }),
    })

    const marketData = newApiData[MARKET_VENDOR.vendor]['All categories']
    dataSource.push({
        title: MARKET_VENDOR.vendor,
        dataSource: metricList.map((metric, index) => {
            const metricObj = formatMapper.find(m => m.metric === metric)
            const metricData = marketData[metricObj?.field || '']
            return {
                name: metricObj?.name,
                ...metricObj?.format(metricData, currency),
                showFromPreviousYear: index === 0,
            }
        }),
    })

    return dataSource
}

export function getDate(dateValue: MonthPanelValue) {
    const startDate = dateUtils.format(new Date(dateValue.year, dateValue.month, 1)).ymd
    const endDate = dateUtils.format(new Date(dateValue.year, dateValue.month, dateUtils.getDaysInMonth(new Date(dateValue.year, dateValue.month, 1)))).ymd
    const date = `${startDate}_${endDate}`

    const comparisonStartDate = dateUtils.format(new Date(dateValue.year, dateValue.month - 1, 1)).ymd
    const comparisonEndDate = dateUtils.format(new Date(dateValue.year, dateValue.month - 1, dateUtils.getDaysInMonth(new Date(dateValue.year, dateValue.month - 1, 1)))).ymd
    const comparisonDate = `${comparisonStartDate}_${comparisonEndDate}`

    return {
        date,
        comparisonDate,
    }
}

export const getLastYearPeriod = (period) => {
    return period
        .split('_')
        .map(item => {
            const d = new Date(item)
            d.setFullYear(d.getFullYear() - 1)
            return format(d, 'yyyy-MM-dd')
        })
        .join('_')
}

export const getLastWeekDate = () => {
    return String(getDateRangeValueByPeriod('oneWholeWeekAgo'))
}

export const getLastWeekOnYearDate = () => {
    return getLastYearPeriod(String(getDateRangeValueByPeriod('oneWholeWeekAgo')))
}

export function handleLineChartData(data, metric, customerVendor = '') {
    if (!data || !customerVendor) return []
    let newData = cloneDeep(data)

    const vendorList = Object.keys(newData)
    
    const comparisonVendorList = vendorList.filter(v => ![ customerVendor, MARKET_VENDOR.vendor, SELECTED_VENDORS ].includes(v))
    let comparisonVendor = SELECTED_VENDORS
    if (comparisonVendorList.length === 1) {
        comparisonVendor = comparisonVendorList[0]
    }

    const customerVendorData = newData[customerVendor]
    const formatValue = (value, metric) => {
        if (metric === 'Sales average price') {
            return Number(value.toFixed(0))
        }
        return Number((value * 100).toFixed(1))
    }
    const dataSource = Object.keys(customerVendorData || {}).map(date => {
        return {
            label: date,
            [customerVendor]: formatValue(newData[customerVendor][date][metric], metric),
            [comparisonVendor]: formatValue(newData[comparisonVendor][date][metric], metric),
            [MARKET_VENDOR.vendor]: formatValue(newData[MARKET_VENDOR.vendor]?.[date]?.[metric], metric),
        }
    })
    return dataSource
}

/**
 * Selected vendors 都更名为 SELECTED_COMPETITORS_AVERAGE
 * Market / All vendors 都更名为 MARKET_AVERAGE
 */
export const handleName = (value: string = '') => {
    if (value.includes(`${SELECTED_VENDORS} Average`)) {
        return value.replace(`${SELECTED_VENDORS} Average`, SELECTED_COMPETITORS_AVERAGE)
    }

    if (value.includes(`${SELECTED_VENDORS} average`)) {
        return value.replace(`${SELECTED_VENDORS} average`, SELECTED_COMPETITORS_AVERAGE)
    }

    if (value.includes(SELECTED_VENDORS)) {
        return value.replace(SELECTED_VENDORS, SELECTED_COMPETITORS_AVERAGE)
    }

    if ([ MARKET_VENDOR.vendor, ALL_VENDORS ].includes(value)) {
        return MARKET_AVERAGE
    }

    if (value === 'Assortment mix') {
        return 'Assortment split'
    }

    return value
}

export const handlePeriodValue = ({
    date,
    comparisonDate,
}) => {
    // 根据 date 获取 periodValue
    const periodObj = {
        [String(getDateRangeValueByPeriod('oneWholeWeekAgo'))]: 'Last week',
        [getDateRangeValueByPeriod('oneWholeMonthAgo')]: 'Last month',
        [getDateRangeValueByPeriod('oneWholeQuarterAgo')]: 'Last quarter',

        last_week_on_year: getLastYearPeriod(String(getDateRangeValueByPeriod('oneWholeWeekAgo'))),
        last_month_on_year: getLastYearPeriod(getDateRangeValueByPeriod('oneWholeMonthAgo')),
        last_quarter_on_year: getLastYearPeriod(getDateRangeValueByPeriod('oneWholeQuarterAgo')),
    }
    const periodValue = periodObj[date]
    if (!periodValue) {
        return {
            periodValue: 'NA',
            compareToValue: 'NA',
        }
    }

    const lastWeekOnPreviousPeriod = getDateRangeValueByPeriod('twoWholeWeeksAgo')
    const lastMonthOnPreviousPeriod = getDateRangeValueByPeriod('twoWholeMonthsAgo')
    const lastQuarterOnPreviousPeriod = getDateRangeValueByPeriod('twoWholeQuartersAgo')
    const lastWeekOnYear = getLastYearPeriod(String(getDateRangeValueByPeriod('oneWholeWeekAgo')))
    const lastMonthOnYear = getLastYearPeriod(getDateRangeValueByPeriod('oneWholeMonthAgo'))
    const lastQuarterOnYear = getLastYearPeriod(getDateRangeValueByPeriod('oneWholeQuarterAgo'))

    let compareToValue = 'NA'

    if (periodValue === PERIOD_LAST_WEEK) {
        if (comparisonDate === lastWeekOnPreviousPeriod) {
            compareToValue = COMPARE_TO_PREVIOUS_PERIOD
        } else if (comparisonDate === lastWeekOnYear) {
            compareToValue = COMPARE_TO_YEAR_ON_YEAR
        }
    } else if (periodValue === PERIOD_LAST_MONTH) {
        if (comparisonDate === lastMonthOnPreviousPeriod) {
            compareToValue = COMPARE_TO_PREVIOUS_PERIOD
        } else if (comparisonDate === lastMonthOnYear) {
            compareToValue = COMPARE_TO_YEAR_ON_YEAR
        }
    } else if (periodValue === PERIOD_LAST_QUARTER) {
        if (comparisonDate === lastQuarterOnPreviousPeriod) {
            compareToValue = COMPARE_TO_PREVIOUS_PERIOD
        } else if (comparisonDate === lastQuarterOnYear) {
            compareToValue = COMPARE_TO_YEAR_ON_YEAR
        }
    }

    return {
        periodValue,
        compareToValue,
    }
}
