import React, { FC, CSSProperties, memo } from 'react'
import { ProgressProps } from './types'

/**
 * ## Progress
 * 
 * 进度条组件。
 * 
 * ## 使用示例
 * 
 * ### percent 进度
 * 
 * ``` 
 * <Progress percent={50} />
 * ```
 * 
 * ### 颜色
 * 
 * ```
 * <Progress 
 *      percent={50} 
 *      strokeColor="#74B6B6"
 *      trailColor="#EEEEEE"
 * />
 * ```
 * 
 * ### 尺寸
 * 
 * ```
 * <Progress 
 *      percent={50} 
 *      thick={4}           // 进度条的厚度
 *      length={70}         // 进度条长度，默认值 100%
 * />
 * ```
 * 
 * ### 方向
 * 
 * 垂直进度条
 * 
 * ```
 * <Progress percent={50} direction="vertical" /> 
 * ```
 * 
 * 水平进度条
 * 
 * ```
 * <Progress percent={50} direction="horizontal" />
 * ```
 */
export const Progress: FC<ProgressProps> = memo(({
    percent = 0,                    // 进度，单位百分比，范围 0-100
    strokeColor = '#74B6B6',        // 进度条的色彩
    trailColor = '#EEEEEE',         // 未完成的分段颜色
    thick = 4,                      // 进度条厚度，单位px，number类型
    length = '100%',                // 进度条长度，默认 100%，可设定指定长度，如 length='70px'
    direction = 'horizontal',       // 进度条方向，'horizontal' | 'vertical'
    style = {},
}) => {
    const progressStyle: CSSProperties = {
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',
        backgroundColor: trailColor,
        borderRadius: '100px',
        width: direction === 'horizontal' ? calcUnit(length) : calcUnit(thick),
        height: direction === 'horizontal' ? calcUnit(thick) : calcUnit(length),
        ...style,
    }

    const progressBgStyle: CSSProperties = {
        position: 'absolute',
        borderRadius: '100px',
        backgroundColor: strokeColor,
        width: direction === 'horizontal' ? percent + '%' : calcUnit(thick),
        height: direction === 'horizontal' ? calcUnit(thick) : percent + '%',
        ...direction === 'horizontal' ? { left: 0 } : { bottom: 0 },
    }

    return (
        <div style={progressStyle}>
            <div style={progressBgStyle} />
        </div>
    )
})

const calcUnit = (value: number | string | undefined) => typeof value === 'number' ? value + 'px' : value

Progress.displayName = 'Progress'
