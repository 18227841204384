import React, { CSSProperties, ReactNode } from 'react'
import classnames from 'classnames'
import { Text } from 'components/typography/Text'
import styles from './styles.module.scss'

export const ProductSizes = ({ 
    sizeList = [], 
    style,
    isHarmonizingSize = false,
}: { 
    sizeList?: string[], 
    style?: CSSProperties;
    isHarmonizingSize?: boolean;
}) => {
    let contentElement: ReactNode = (
        <Text small>No {isHarmonizingSize ? 'harmonized ' : ''}sizes available</Text>
    )

    if (sizeList.length > 0) {
        contentElement = sizeList.map((size: string) => (
            <span
                key={size}
                style={style}
                className={classnames(styles.sizeAvailable, 'text-ellipsis ')}
            >
                <Text title={size.toUpperCase()} xxs>{size.toUpperCase()}</Text>
            </span>
        ))
    }

    return (
        <div className={styles.productSizes}>
            <div className={styles.sizes}>
                {contentElement}
            </div>
        </div>
    )
}

ProductSizes.displayName = 'ProductSizes'
