import { CSSProperties, ReactPortal } from 'react'

export enum DatePickerType {
    DATE = 'datePicker',
    RANGE = 'dateRangePicker'
}
export interface DatePickerProps {
    wrapperClassName?: string;
    customRenderFn?: ((props: any) => ReactPortal);
    renderPostion?: ((props: any) => void);
    renderCustomInput?: (props: any) => React.ReactElement;
    style?: CSSProperties;
    left?: number | string;
    right?: number | string;
    minimumDate?: {                       // 最小日期
        day: number;
        month: number;
        year: number;
    };
    maximumDate?: {                       // 最大日期
        day: number;
        month: number;
        year: number;
    };
    value?: string;                       // 设置日期段下拉框选中值，格式：2021-09-13_2021-09-20
    onChange?: (value: string) => void;   // 日期段下拉框值变化时触发事件，回调函数参数 value 格式：2021-09-13_2021-09-20
    type?: DatePickerType;
    defaultValue?: string;                // 默认值，格式为：2021-09-13_2021-09-20，点击 Restart to default 按钮时设置该值
    inputValue?: string;
    inputClassName?: string;

    // disabled date picker or not
    disabled?: boolean;
    inputStyle?: CSSProperties;
};
