import React from 'react'
import Table from './components/Table'

const SoldOutAnalyticsVendors = () => {

    return (
        <>
            <Table />
        </>
    )
}

export default SoldOutAnalyticsVendors
