import React from 'react'
import { cloneDeep } from 'lodash'
import { Vendor } from '../Vendor'
import styles from './styles.module.scss'
import { TopScrollbar } from 'norna-uikit'
import classNames from 'classnames'

export const HeaderVendor = ({
    dataSource = [],
    horizontalExpandKeys = [],
    onDrag,
    onHorizontalExpanded,
    loading = false,
}: {
    dataSource: any[];
    horizontalExpandKeys: string[];
    onDrag: any;
    onHorizontalExpanded?: (expaneded: boolean, vendor: string) => void;
    loading?: boolean;
}) => {
    return (
        <>
            <TopScrollbar 
                className={classNames('price-architecture-header', styles.vendorsWrapper)}
                style={{ top: '-30px' }}
            >
                <div className={styles.vendors}>
                    <div className={styles.firstColumnWrapper}>
                        {loading === false ? <div className={styles.priceRange}>Price range</div> : null}
                    </div>
                    {
                        dataSource.map((d, index) => {
                            const d2 = cloneDeep(d)
                            if (!horizontalExpandKeys.includes(d2.vendor)) {
                                d2.regions = d2.regions.slice(0, 1)
                            }
                            return (
                                <Vendor
                                    key={d.vendor + String(index)} 
                                    dataSource={d} 
                                    onDrag={onDrag}
                                    onlyShowHeader
                                    onlyShowRegion
                                    onHorizontalExpanded={onHorizontalExpanded}
                                />
                            )
                        })
                    }
                </div>
            </TopScrollbar>
            {loading === false ? <div className={styles.shadow} /> : null}
        </>
    )
}
