import * as moduleConfig from 'configs/moduleName'

export function getTab(moduleName: string) {
    let tab = ''
    if (moduleName === moduleConfig.CATEGORY_SPLIT_PIE_GRAPH_NAME) {
        tab = 'category'
    } else if (moduleName === moduleConfig.COLOR_SPLIT_PIE_GRAPH_NAME) {
        tab = 'color'
    } else if (moduleName === moduleConfig.MATERIAL_SPLIT_PIE_GRAPH_NAME) {
        tab = 'material'
    }
    return tab
}
