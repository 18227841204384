import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { getCalendarDateValue } from '../../GlobalDatePicker.util';
import DropdownGroup from '../DropdownGroup/DropdownGroup';
import { Calendar, Day, utils } from 'react-modern-calendar-datepicker';
import styles from '../../GlobalDatePicker.module.scss'
import { formatDatePickerToDate } from 'utils/dateUtils';
import classNames from 'classnames';

const DateCalendar = ({
    value,
    onChange,
    onReset,
    isRange,
    title,
    minimumDate,
    maximumDate,
    hideDropdown = false,
    disabled = false,
}: {
    value?: string;
    onChange?: (value: string) => void;
    onReset?: () => void;
    isRange?: boolean;
    title?: string;
    minimumDate?: Day;
    maximumDate?: Day;
    hideDropdown?: boolean;
    disabled?: boolean;
}, ref) => {
    const calendarValue = getCalendarDateValue(value, isRange)

    const onCalendarChange = (value: any) => {
        let date: any = ''

        // 日期范围
        if (isRange) {
            const { isBeforeDate } = utils('en')
            // 两个值都存在
            if (value.from && value.to) {
                if (isBeforeDate(value.from, value.to)) {
                    date = [ formatDatePickerToDate(value.from), formatDatePickerToDate(value.to) ].join('_')
                } else {
                    date = [ formatDatePickerToDate(value.from), formatDatePickerToDate(value.to) ].join('_')
                }
            }
            // 只选了一个值
            else {
                date = [
                    value.from ? formatDatePickerToDate(value.from) : '',
                    value.to ? formatDatePickerToDate(value.to) : '',
                ].join('_')
            }
        } 
        // 单个日期
        else {
            date = formatDatePickerToDate(value)
        }
        onChange?.(date)
    }

    const dropdownGroupRef = useRef<any>()

    useImperativeHandle(ref, () => {
        return {
          close: () => {
            dropdownGroupRef.current?.close()
          },
        }
    })

    return (
        <div 
            className={classNames({ [styles.disabled]: disabled })}
        >
            <div className={styles.title}>
                {title}
            </div>
            {
                hideDropdown ? (
                    <div style={{ height: 68 }} />
                ) : (
                    <DropdownGroup ref={dropdownGroupRef} />
                )
            }
            <div onClick={e => e.stopPropagation()}>
                <Calendar
                    colorPrimary="#01a699"
                    minimumDate={minimumDate}
                    maximumDate={maximumDate}
                    value={calendarValue as any}
                    onChange={onCalendarChange}
                />
            </div>
            <div className={styles.btnWrapper}>
                <div 
                    className={styles.btn} 
                    onClick={e => {
                        e.stopPropagation()
                        onReset?.()
                    }}
                >
                    Reset to default
                </div>
            </div>
        </div>
    )
}

export default forwardRef(DateCalendar)
