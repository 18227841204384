import { CloseIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { Input } from 'components/form-elements/Input'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import React, { useState } from 'react'
import { Textarea } from 'components/form-elements/Textarea'
import { Message } from 'components/form-elements/Message'
import classnames from 'classnames'
import { Dragable } from 'components/common/Dragable'
import { useCreateLookbook, useRefreshLookbookList } from 'hooks/api/useLookbookData'
import { useLookbook } from 'hooks/useLookbook'
import { CreateLookbookModalProps } from './types'
import styles from './styles.module.scss'

export const CreateLookbookModal = ({
  closeModal,
  onSave, title,
  isOpen,
  hideBackBtn = false,
  noDes = false,
  modalMaskStyle = {},
}: CreateLookbookModalProps) => {
  const [ selectedLookbook, setSelectedLookbook ] = useState({
    id: Math.floor((Math.random() * 1000) + 1),
    name: '',
    desc: '',
    createdAt: new Date(),
    imageUrl: '',
  })
  const { myLookbookList = [] } = useLookbook()
  const [ validateCreateLookbook, setValidateCreateLookbook ] = useState(false)
  const [ invalidText, setInvalidText ] = useState('')
  // eslint-disable-next-line
  const { createLookbook } = useCreateLookbook()
  const { refreshLookbookList } = useRefreshLookbookList({ isUpdateLatestLookbook: true })

  const changeLookbookName = (event: any) => {
    setSelectedLookbook({ ...selectedLookbook, name: event })
    // 校验名称不能重复
    const lookbookNameList = myLookbookList.map(lookbook => lookbook.name)
    if (lookbookNameList.includes(event)) {
      setInvalidText('Lookbook name already exist.')
    } else {
      setInvalidText('')
    }
  }

  const changeLookbookDesc = (event: any) => {
    setSelectedLookbook({ ...selectedLookbook, desc: event })
  }

  const onEnterKey = event => {
    if (event.key === 'Enter') {
      saveLookbook()
    }
  }

  const saveLookbook = async () => {
    if (invalidText) return    

    if (selectedLookbook.name && selectedLookbook.name.trim() !== '') {
      if (onSave) {
        onSave(selectedLookbook)
        closeModal()
      } else {
        // loadingBar.continuousStart();
        try {
          await createLookbook(selectedLookbook.name, selectedLookbook.desc)
          await refreshLookbookList()
          closeModal()
          // loadingBar.complete();
        } catch (e) {
          // loadingBar.complete();
        }
      }
    } else {
      setValidateCreateLookbook(true)
    }
  }

  const getModalContent = () =>
    <>
      <div className={styles.modalHeader} style={{ cursor: 'move' }}>
        <div className={styles.modalTitle}>
          <Text medium>{title || 'Create New lookbook'}</Text>
        </div>
        <CloseIcon onClick={() => closeModal()} />
      </div>
      <div className={styles.modalBody} onMouseDown={e => e.stopPropagation()}>
        <div className={styles.modalContent}>
          <div className={styles.lookbookName}>
            <Input
              value={selectedLookbook?.name}
              onChange={changeLookbookName}
              maxLength={50}
              onEnterKey={onEnterKey}
              placeholder="Name"
            />
          </div>
          {noDes ? null : (
            <div className={styles.lookbookDesc}>
              <Textarea
                value={selectedLookbook?.desc}
                onChange={changeLookbookDesc}
                maxLength={500}
                placeholder="Description"
              />
            </div>
          )}
        </div>
      </div>
      {
        invalidText ? (
          <Message message={invalidText} error />
        ) : null
      }
      <ShouldRender shouldRender={validateCreateLookbook}>
        <Message message={noDes ? 'Please enter valid name' : 'Please enter valid name and description'} error />
      </ShouldRender>
      <div className={styles.modalFooter} onMouseDown={e => e.stopPropagation()}>
        {hideBackBtn ? null : (
          <Button secondary miniLarge onClick={() => closeModal()}>
            &nbsp;&nbsp;Back
          </Button>
        )}
        <Button primary mini onClick={() => saveLookbook()} disabled={!!invalidText}>
          &nbsp;&nbsp;Save
        </Button>
      </div>
    </>

  return (
    <ShouldRender shouldRender={isOpen}>
      <div
        className={styles.modal} 
        onClick={e => {
          e.stopPropagation()
          closeModal()
        }} 
        style={modalMaskStyle}
      />
      <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
        <Dragable 
          width={420} 
          height={320} 
          x={-210} 
          y={-160}
        >
          <div
            style={{ 
              height: noDes ? '200px' : invalidText ? '359px' : '319px',
            }}
            className={classnames(
              styles.modalWrapper,
              validateCreateLookbook && styles.increaseHeight)}
          >{getModalContent()}
          </div>
        </Dragable>
      </div>
    </ShouldRender>
  )
}

CreateLookbookModal.displayName = 'CreateLookbookModal'
