/**
 *  down load file 
 * @param url  file url
 */
export const downloadByUrl = url => {
    const e = document.createElement('a')
    e.href = url
    e.download = url.substr(url.lastIndexOf('/') + 1)
    document.body.appendChild(e)
    e.click()
    document.body.removeChild(e)
}
