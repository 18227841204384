export const dynamicColumnWidth = 160

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    vendorList,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        '# Options',	
        'Category',	
        ...vendorList,
    ]
    data.push(titleRow)

    const handleRowData = (item) => {
        return [
            item?.options?.replace(',', ''),
            item?.category,
            ...vendorList.map(vendor => {
               return item?.[vendor]?.replace(',', '') 
            }),
        ]
    }

    dataSource.forEach(item => {
        const dataRow = handleRowData(item)
        data.push(dataRow)

        if (item?.children?.length) {
            item.children.forEach(item2 => {
                const dataRow2 = handleRowData(item2)
                data.push(dataRow2)

                if (item2?.children?.length) {
                    item2.children.forEach(item3 => {
                        const dataRow3 = handleRowData(item3)
                        data.push(dataRow3)
                    })
                }
            })
        }
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
