import React from 'react'
import classNames from 'classnames'
import { Text } from 'components/typography/Text'
import { Button } from 'components/form-elements/Button'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { MessageProps } from './types'
import styles from './styles.module.scss'

export const Message = ({
                          message,
                          error,
                          warning,
                          success,
                          icon,
                          children,
                          info,
                          setDisplayMessage,
                          displayMessage,
                          onClick,
                          sty = {},
                          action,
                        }: MessageProps) => {
  if (displayMessage) {
    const timeOut = 3000
    setTimeout(() => {
      setDisplayMessage && setDisplayMessage(false)
    }, timeOut)
  }
  return (
    <div
      className={classNames(
        styles.message,
        error && styles.errorWrap,
        warning && styles.warningWrap,
        success && styles.successWrap,
        info && styles.infoWrap,
      )}
      style={sty}
    >
      <Text
        medium
        className={classNames(
          error && styles.error,
          warning && styles.warning,
          success && styles.success,
          info && styles.info,
        )}
      >
        {message}
        {children}
      </Text>
      <ShouldRender shouldRender={info !== undefined}>
        <Button text onClick={onClick}>
          CLOSE
        </Button>
      </ShouldRender>
      <ShouldRender shouldRender={action}>
        <div className={styles.action}>{action}</div>
      </ShouldRender>
      {icon}
    </div>
  )
}
