import { getTooltipText } from 'configs/tooltip'
import { MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { ASSORTMENT_MIX, DISCOUNT_EFFECT, DYNAMIC_DASHBOARD_KPIS, FULL_PRICE_PERFORMANCE, PRODUCT_NEWNESS, SALES_PRICE_PERFORMANCE, SOLD_OUT_STATUS } from 'features/filters/dynamicDashboardSlice'
import { cloneDeep } from 'lodash'
import { numberUtils } from 'norna-uikit'
import { MARKET_AVERAGE, DYNAMIC_DASHBOARD_TOOLTIP_PREFIX } from 'pages/DynamicDashboard/const'
import { getVendorNameByCode } from 'utils'

export const mapper = [
    {
        kpi: PRODUCT_NEWNESS,
        field: 'product_newness',
        showUnits: false,
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                // currency: 'OPT',
            }
        },
    },
    {
        kpi: ASSORTMENT_MIX,
        field: 'assortment_mix',
        showUnits: true,
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
            }
        },
    },
    {
        kpi: DISCOUNT_EFFECT,
        field: 'discount_effect',
        showUnits: true,
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
            }
        },
    },
    {
        kpi: SOLD_OUT_STATUS,
        field: 'sold_out',
        showUnits: true,
        format(data) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
            }
        },
    },
    {
        kpi: SALES_PRICE_PERFORMANCE,
        field: 'sales_price_performance',
        showUnits: false,
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            }
        },
    },
    {
        kpi: FULL_PRICE_PERFORMANCE,
        field: 'full_price_performance',
        showUnits: false,
        format(data, currency) {
            return {
                value: numberUtils.formatNumber(data?.value, { decimal: 0, isCommaSymbol: true }),
                percent: numberUtils.formatNumber(data?.change_percent, { decimal: 1, isCentuple: true, isPercentSymbol: true, isCommaSymbol: true }),
                currency,
            }
        },
    },
]

export function handleData(apiData, currency, customerVendor) {
    const newApiData = cloneDeep(apiData)
    const vendorList = Object.keys(newApiData || {})
    if (!vendorList?.length) return []
    // const customerVendor = storage.getCustomerVendor()

    const newData = DYNAMIC_DASHBOARD_KPIS.map(kpi => {
        const dataSource: any[] = []
        const kpiObj = mapper.find(item => item.kpi === kpi)
        // 处理 customerVendor
        const customerData = newApiData[customerVendor]['All categories']
        dataSource.push({
            name: getVendorNameByCode(customerVendor),
            ...kpiObj?.format(customerData[kpiObj?.field || ''], currency),
            showUnits: kpiObj?.showUnits,
            showFromPreviousYear: true,
        })
        // 处理 other vendor 或者 selected venodrs  
        const othersVendor = vendorList.filter(v => ![ customerVendor, MARKET_VENDOR.vendor, SELECTED_VENDORS ].includes(v))
        let selectedVendor = SELECTED_VENDORS
        let selectedVendorData = newApiData[SELECTED_VENDORS]['All categories']
        if (othersVendor.length === 1) {
            selectedVendor = othersVendor[0]
            selectedVendorData = newApiData[selectedVendor]['All categories']
        }
        dataSource.push({
            name: getVendorNameByCode(selectedVendor),
            ...kpiObj?.format(selectedVendorData[kpiObj?.field || ''], currency),
        })

        // 处理 Market
        const marketData = newApiData[MARKET_VENDOR.vendor]['All categories']
        dataSource.push({
            name: MARKET_AVERAGE,
            ...kpiObj?.format(marketData[kpiObj?.field || ''], currency),
        })

        return {
            title: kpi,
            kpi,
            dataSource,
            description: getTooltipText(`${DYNAMIC_DASHBOARD_TOOLTIP_PREFIX}${kpi}`),
        }
    })
    return newData
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        ...dataSource.map(d => d.title)
            .map(t => ([ t, '', '' ])),
    ]
    data.push(titleRow)

    const subTitleRow = [
        ...dataSource.map(item => {
            return item.dataSource.map(item2 => {
                return [
                    item2.name,
                ]
            })
        }).flat(2),
    ]
    data.push(subTitleRow)

    const dataRow = [
        ...dataSource.map(item => {
            return item.value
        }).flat(2),
    ]
    data.push(dataRow)

    const dataRow2 = [
        ...dataSource.map(item => {
            return item.percent
        }).flat(2),
    ]
    data.push(dataRow2)

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
