import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { CardContainerProps } from './types'

export const CardContainer = ({ children, large }: CardContainerProps) => 
    <div className={classnames(styles['card-container'], large && styles['card-container-large'])}>
        {children}
    </div>

