import { useFlatQueryObj } from 'hooks'
import { useSelector } from 'react-redux'
import { getFilterTags } from 'features/filters/globalDataSlice'
import { getPropertyValueByLabel, handleFilterPropertyTreeData } from 'components/layout/Header/components/HeaderFilter/property.util'
import { useMenuAndFilterPermission } from './useQa'

/**
 * ## `Properties` hook handler
 * @returns 
 */
export default function useProperties() {
    const flatQuery = useFlatQueryObj()
    const filterTags = useSelector(getFilterTags)
    const { filterAvailable } = useMenuAndFilterPermission()
    
    const isFilterTagsValid = Object.keys(filterTags || {}).length
    const lastSelectedCategories = isFilterTagsValid ? filterTags.category : flatQuery.categories
    let selectedProperties = isFilterTagsValid ? filterTags.property : flatQuery.properties
    selectedProperties = getPropertyValueByLabel({
        selectedCategories: lastSelectedCategories,
        selectedProperties,
    })
    const properties = handleFilterPropertyTreeData({
        selectedCategories: lastSelectedCategories,
        selectedProperties,
    }).filter((item: any) => filterAvailable(item.label))

    return {
        /**
         * ## property children of tree
         */
        children: properties,
    }
}

