import React, { useCallback, useState } from 'react'
import { CloseIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { Text } from 'components/typography'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { PRICE_RANGE_MODAL_CLASS } from 'consts'
import classNames from 'classnames'
import { Spin } from 'componentsv2'
import { Dragable } from 'components/common/Dragable'
import { SizedBox } from 'componentsv2/SizedBox'
import { InputNumber } from 'druikit'
import { useFilterCurrency } from 'hooks/useFilter'
import { Checkbox } from 'componentsv2/form-elements/Checkbox'
import { RadioItem } from 'componentsv2/business/MaterialFilter'
import { PriceFilterSelectionModalProps } from './types'
import styles from './stylesv2.module.scss'

export const PriceFilterSelectionModalV2 = ({
  closeModal,
  currentFilter,
  onChange,
  modalMaskStyle = {},
}: PriceFilterSelectionModalProps) => {
  const [ currency ] = useFilterCurrency()
  // 滑动条值
  const [ sliderValue, setSliderValue ] = useState<any>([ 0, 0 ])
  const [ priceRangeActive, setPriceRangeActive ] = useState(false)
  const [ priceRangeOptionValue, setPriceRangeOptionValue ] = useState<'all' | 'discount' | 'original'>(currentFilter.optionValue || 'all')

  // 外部传入的赋值回显
  useDeepCompareEffect(() => {
    if (!currentFilter?.range) return
    let valueArr = currentFilter.range.split(',')
    if (valueArr.length !== 2) return
    valueArr = valueArr.map(item => Number(item))
    setSliderValue(valueArr)
    setPriceRangeOptionValue(currentFilter.optionValue || 'all')
    setPriceRangeActive(currentFilter.checked || false)
  }, [ currentFilter || {} ])

  // Apply 按钮
  const savePriceFilter = useCallback(async () => {
    const max = Math.max(...sliderValue)
    const min = Math.min(...sliderValue)
    if (onChange) {
      onChange({
        max,
        min,
        checked: priceRangeActive,
        optionValue: priceRangeOptionValue,
      })
      closeModal()
    }
  }, [ closeModal, onChange, sliderValue, priceRangeOptionValue, priceRangeActive ])

  return (
    <Spin spinning={false}>
      <div className={classNames(styles.modal, PRICE_RANGE_MODAL_CLASS.slice(1))} style={modalMaskStyle} />
      <div className={styles.modalContainer}>
        <Dragable width={260} height={410} x={390} y={200}>
          <div className={styles.modalWrapper}>
            <>
              <div className={styles.modalHeader}>
                <div className={styles.modalTitle}>
                  <Text medium>Price filter ({currency})</Text>
                </div>
                <CloseIcon onClick={() => closeModal()} />
              </div>
              <div className={styles.modalBody} onMouseDown={e => e.stopPropagation()}>
                <SizedBox height={20} />
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <RadioItem
                    setChecked={setPriceRangeActive}
                    checked={priceRangeActive}
                  />
                  <div style={{ fontSize: '14px', color: '#666' }}>Activate price range</div>
                </div>
                <SizedBox height={20} />
                <div className={styles.sliderValuesContainer}>
                  <InputNumber
                    value={sliderValue[0]}
                    onBlur={value => setSliderValue([ Number(value), sliderValue[1] ])}
                    onChange={() => setPriceRangeActive(true)}
                    min={0}
                    max={100000000}
                    width={82}
                    height={28}
                    padding={2}
                    textAlign="center"
                    empty={false}
                    style={{ fontSize: 14 }}
                  />
                  -
                  <InputNumber
                    value={sliderValue[1]}
                    onBlur={value => setSliderValue([ sliderValue[0], Number(value) ])}
                    onChange={() => setPriceRangeActive(true)}
                    min={0}
                    max={100000000}
                    width={82}
                    height={28}
                    padding={2}
                    textAlign="center"
                    empty={false}
                    style={{ fontSize: 14 }}
                  />
                </div>
                <SizedBox height={20} />
                <div>
                  <Checkbox
                    disabled={priceRangeOptionValue === 'all'}
                    value={priceRangeOptionValue === 'all'} 
                    onChange={value => setPriceRangeOptionValue('all')}
                    boxColor="#40484D"
                    style={{ alignItems: 'flex-start' }}
                    labelStyle={{ marginLeft: '6px' }}
                    boxStyle={{ marginTop: '4px' }}
                  >
                    <div className={styles.optionTitle}>All options</div>
                    <div className={styles.optionSubtitle}>No options excluded</div>
                  </Checkbox>
                  <Checkbox
                    disabled={priceRangeOptionValue === 'discount'} 
                    value={priceRangeOptionValue === 'discount'} 
                    onChange={value => setPriceRangeOptionValue('discount')}
                    boxColor="#40484D"
                    style={{ alignItems: 'flex-start' }}
                    labelStyle={{ marginLeft: '6px' }}
                    boxStyle={{ marginTop: '4px' }}
                  >
                    <div className={styles.optionTitle}>Only discounted options</div>
                    <div className={styles.optionSubtitle}>Full price options excluded</div>
                  </Checkbox>
                  <Checkbox
                    disabled={priceRangeOptionValue === 'original'} 
                    value={priceRangeOptionValue === 'original'} 
                    onChange={value => setPriceRangeOptionValue('original')}
                    boxColor="#40484D"
                    style={{ alignItems: 'flex-start' }}
                    labelStyle={{ marginLeft: '6px' }}
                    boxStyle={{ marginTop: '4px' }}
                  >
                    <div className={styles.optionTitle}>Only full price options</div>
                    <div className={styles.optionSubtitle}>Discounted options excluded</div>
                  </Checkbox>
                </div>
                <SizedBox height={20} />
              </div>
              <div className={styles.modalFooter}>
                <Button 
                  primary 
                  onClick={savePriceFilter} 
                  style={{ width: '125px' }}
                >
                  Apply
                </Button>
              </div>
            </>
          </div>
        </Dragable>
      </div>
    </Spin>
  )
}

