import React, { FC, CSSProperties, ReactNode } from 'react'
import classnames from 'classnames'
import { Text } from 'components/typography/Text'
import { DescriptionsProps, DescriptionsItemProps } from './types'
import styles from './styles.module.scss'

/**
 * 描述列表组件
 */
export const Descriptions = ({
    children,
    gutter = 10,         // 单元格与单元格之间水平间距
    column = 4,          // 一行有几个单元格
    style = {},
    className = '',
}: DescriptionsProps) => {
    const defaultStyle: CSSProperties = {
        gridColumnGap: gutter + 'px',
        gridTemplateColumns: `repeat(${column}, 1fr)`,
    }

    return (
        <div className={classnames([ styles.descriptions, className ])} style={{ ...defaultStyle, ...style }}>
            {children}
        </div>
    )
}

/**
 * 描述项
 */
export const DescriptionsItem: FC<DescriptionsItemProps> = ({
    label,
    value,
    description,
    children,
    top,
    bottom,
    style = {},
    className,
    labelStyle = {},
    valueStyle = {},
    labelClassName,
    labelFontSize,
    valueClassName,
    valueFontSize,              // value 字体大小
    valueRightStyle = {},
}) => {
    const defaultStyle: CSSProperties = { ...style }
    if (typeof top === 'number') {
        defaultStyle.marginTop = top + 'px'
    }
    if (typeof bottom === 'number') {
        defaultStyle.marginBottom = bottom + 'px'
    }

    const defaultLabelStyle: CSSProperties = {}
    if (typeof labelFontSize === 'number') {
        defaultLabelStyle.fontSize = labelFontSize + 'px'
    }

    const defaultValueStyle: CSSProperties = { ...valueStyle }
    if (typeof valueFontSize === 'number') {
        defaultValueStyle.fontSize = valueFontSize + 'px'
    }

    let contentElement: ReactNode = null

    // value 是对象字面量
    if (Object.prototype.toString.call(value) === '[object Object]') {
        contentElement = (
            <div className={styles.descriptionsItemContent}>
                {
                    Object.keys(value || {}).map(key => (
                        <div key={key} style={{ marginBottom: '-5px' }}>
                            <Text small className={classnames([ styles.descriptionsItemContentItem, valueClassName ])} style={defaultValueStyle}>
                                <span>{key}</span>
                                <span style={valueRightStyle}>{(value || {})[key] && (value || {})[key].toString()}</span> 
                            </Text>
                        </div>
                    ),
                )}
            </div>
        )
    } else if (Array.isArray(value)) {  // value 是数组
        contentElement = (
            <div className={styles.descriptionsItemContentItem}>
                <Text small className={valueClassName} style={defaultValueStyle}>
                    { (value || []).join('; ') }
                </Text>
            </div>
        )
    } else if (value) {     // value 存在是其它类型
        contentElement = (
            <div className={styles.descriptionsItemContentItem}>
                <Text small className={valueClassName} style={defaultValueStyle}>
                    {value || 'No properties available'}
                </Text>
            </div>
        )
    } else {    // value 不存在
        contentElement = children
    }

    return (
        <div className={classnames([ styles.descriptionsItem, className ])} style={defaultStyle}>
            <div className={styles.descriptionsItemLabel}>
                <Text xs uppercase className={labelClassName} style={{ fontSize: '11px', ...defaultLabelStyle, ...labelStyle }}>
                    {label}
                </Text>
                {
                    description ? (
                        <span 
                            style={{
                                display: 'inline-block',
                                width: 16,
                                height: 16,
                                textAlign: 'center',
                                lineHeight: '16px',
                                color: '#fff',
                                fontSize: 11,
                                backgroundColor: '#ccc',
                                borderRadius: '50%',
                                cursor: 'default',
                                marginLeft: 4,
                                textTransform: 'initial',
                            }}
                            data-tooltip={description}
                            className={styles.tooltip}
                        >
                            i
                        </span>
                    ) : null
                }
            </div>
            {contentElement}
        </div>
    )
}

DescriptionsItem.displayName = 'DescriptionsItem'
Descriptions.displayName = 'Descriptions'
Descriptions.Item = DescriptionsItem
