import { numberUtils } from 'norna-uikit'

export function getYAxisMaxValue(yPointMaxValue, yPointMinValue = 0) {
    yPointMaxValue = zoomInNumber(yPointMaxValue)
    yPointMinValue = zoomInNumber(yPointMinValue)
    /**
     * 线图是一条水平直线，线图上最大值和最小值一样，
     * 这时候要让直线在线图偏中间偏上位置显示，需要将 y 坐标值乘以 1.5
     */
    let diffValue = yPointMaxValue
    if (Math.abs(yPointMaxValue - yPointMinValue) < (yPointMaxValue / 100)) {
        diffValue = yPointMinValue * 1.5
    }

    /**
     * 有些点的值刚好处于临界点, 比如 y 坐标轴是 [0,500,1000,1500]
     * 然后有个点的坐标是 1499，此时希望 y 坐标轴增加一个 [0,500,1000,1500,2000]
     */
    function getYAxisMaxValueByDiffValue(diffValue, step) {
        let yAxisMaxValue = 0
        const ratio = 0.7
        // diffValue % step = 0  =>  1500   值刚好和临界点值一样
        // diffValue % step / step > ratio  =>  1499 不是临界点但相当接近临界点了
        if (diffValue % step === 0 || diffValue % step / step >= ratio) {
            yAxisMaxValue = (Math.ceil(diffValue / step) + 1) * step
        } else {
            yAxisMaxValue = Math.ceil(diffValue / step) * step
        }
        return yAxisMaxValue
    }

    let yAxisMaxValue = 0
    // 1000-
    if (yPointMaxValue >= 1000) {
        yAxisMaxValue = getYAxisMaxValueByDiffValue(diffValue, 500)
    }
    // 100-1000    200
    else if (yPointMaxValue >= 100) {
        yAxisMaxValue = getYAxisMaxValueByDiffValue(diffValue, 200)
    }
    // 10-100
    else if (yPointMaxValue >= 10) {
        yAxisMaxValue = getYAxisMaxValueByDiffValue(diffValue, 10)
    }
    // 1-10
    else if (yPointMaxValue >= 1) {
        yAxisMaxValue = getYAxisMaxValueByDiffValue(diffValue, 2)
    }
    // 0.1-1
    else if (yPointMaxValue >= 0.1) {
        yAxisMaxValue = Math.ceil(diffValue * 10) / 10
    } else if (yPointMaxValue >= 0.01) {
        yAxisMaxValue = Math.ceil(diffValue * 100) / 100
    } else if (yPointMaxValue === 0) {
        yAxisMaxValue = 1
    } else {
        yAxisMaxValue = Math.ceil(diffValue)
    }
    return zoomOutNumber(yAxisMaxValue)
}

export function getYPointMaxValue(value) {
    let yPointMaxValue = 0
    if (zoomInNumber(yPointMaxValue) > 50) {
        yPointMaxValue = zoomOutNumber(Math.ceil(zoomInNumber(yPointMaxValue)))
    } else if (zoomInNumber(yPointMaxValue) > 1) {
        yPointMaxValue = zoomOutNumber(zoomInNumber(yPointMaxValue).toFixed(1))
    } else {
        yPointMaxValue = value
    }
    return yPointMaxValue
}

export function getYAxisStepValue(yAxisMaxValue) {
    if (yAxisMaxValue === -Infinity) return
    let stepValue = 1
    if (yAxisMaxValue >= 10) {
        stepValue = 1
    } else if (yAxisMaxValue >= 1) {
        stepValue = 0.1
    } else if (yAxisMaxValue >= 0.1) {
        stepValue = 0.01
    } else {
        stepValue = 0.001
    }
    return stepValue
}

/**
 * 缩小数字
 */
export function zoomInNumber(value, opts?: { factor?: number; type?: 'axis' | 'tooltip', maxValue?: number; minValue?: number }) {
    if (value === undefined) return value
    const factor = opts?.factor || 100
    const type = opts?.type || 'axis'
    let diffValue = 999999999
    if (typeof opts?.maxValue === 'number' && typeof opts?.minValue === 'number') {
        diffValue = opts.maxValue - opts.minValue
    }
    const newValue = numberUtils.divide(Number(value), factor)
    if (type === 'tooltip') return newValue
    if (value < factor / 10 || diffValue < factor / 10) {
        return Number(newValue.toFixed(3))
    }
    if (value < factor || diffValue < factor) {
        return Number(newValue.toFixed(2))
    }
    if (value < factor * 10 || diffValue < factor * 10) {
        return Number(newValue.toFixed(1))
    }
    return Number(newValue.toFixed(0))
}

/**
 * 放大数字
 */
export function zoomOutNumber(value, factor = 100) {
    if (value === undefined) return value
    return numberUtils.multiply(Number(value), factor)
}

/**
 *  ## drawer rect overlay for bar graph 
 * @param ctx  
 * @param left 
 * @param top 
 * @param w 
 * @param h 
 */
export const handleOverlay = (chartInstance: any, left: number, top: number, w: number, h: number, id: string) => {
    if (!chartInstance.canvas) return
    let ele: HTMLDivElement = document.querySelector('#' + id) as HTMLDivElement
    if (!ele) {
        ele = document.createElement('div')
        ele.id = id
        const container = chartInstance.canvas?.closest('[class*="pricesContaine"')
        if(!container)return
        container?.append?.(ele)
        container.style.position = 'relative'
        ele.style.position = 'absolute'

    }
    ele.style.left = `${left}px`
    ele.style.background = 'rgba(255,255,255,0.6)'
    ele.style.top = `${top}px`
    ele.style.height = `${h}px`
    ele.style.width = `${w}px`
}
