import classnames from 'classnames'
import { ProductActions } from 'components/Product/components/Actions'
import { ProductPrice } from 'components/Product/components/Price'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import { Product } from 'components/Product'
import React, { useState } from 'react'
import { formatProductName, getVendorNameByCode } from 'utils'
import styles from '../../styles.module.scss'
import { ProductThumbnailProps } from './types'

export const ProductThumbnail = ({
  product,
  onClick,
  toggleExcludedProduct,
  enableActions,
  style,
  className,
}: ProductThumbnailProps) => {
  const [ plusMinusToggle, setPlusMinusToggle ] = useState(true)

  const togglePlusMinus = () => {
    toggleExcludedProduct && toggleExcludedProduct(product.url)
    setPlusMinusToggle(!plusMinusToggle)
  }

  return (
    <div
      style={style}
      className={classnames(
        styles.productImage,
        !plusMinusToggle && styles.excludedProduct,
        !enableActions && styles.noHover,
        className,
      )}
      onClick={e => onClick(e, product)}
    >
      <div className={styles.actionsContainer}>
        <ShouldRender shouldRender={!!enableActions}>
          <ProductActions plusMinusToggle={plusMinusToggle} toggle={togglePlusMinus} />
        </ShouldRender>
      </div>
      <Product
        id={product?.nornaid}
        date="2021-10-18"
        category=""
        categoryName=""
        url=""
        lookbooks={product?.lookbooks}
        vendor={getVendorNameByCode(product?.seller?.vendor) || ''}
        vendorCode={product?.seller?.vendor}
        brand={product.brand}
        interpolatedPrice={{ '2021-10-18': { discounted_price: 0, original_price: 0 } }}
        imageUrl={product?.images[0]}
        nornaid={product?.nornaid}
        disabledScale
        style={{ width: '107.89px', height: '164px', padding: 0, margin: 0 }}
        imageStyle={{ width: '107.89px', height: '164px' }}
        skeletonStyle={{ minWidth: '107.89px' }}
        onlyShowImage
        isShowInDialog
        sizePriceRange={product?.size_price_range}
        latestSizePrice={product?.latest_size_price}
      />
      <div className={styles.productInfo} title={formatProductName(product.title)}>
        <Text small className={styles.productTitle} title={formatProductName(product.title)}>
          {formatProductName(product.title)}
        </Text>
        <ProductPrice
          price={product?.last_price_info?.original_price}
          discountedPrice={product?.last_price_info?.discounted_price}
          currency={product.currency}
          convertedPrice={product.convertedPrice}
          sizePriceRange={product?.size_price_range}
          latestSizePrice={product?.latest_size_price}
          vendorCode={product?.seller?.vendor}
        />
      </div>
    </div>
  )
}
