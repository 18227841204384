import React, { useState } from 'react'
import { dateUtils } from 'druikit';
import { Text } from 'components/typography'
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import { QA_BUTTON_CORE_RUN_LABEL } from 'consts/strings';
import styles from './CoreRunDialog.module.scss'
import { useCancelCoreRun, useCheckTaskStatus, useConductCoreRun, usePriceUpdateAllCustomers } from 'hooks/api/useCoreRun';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Button, Popconfirm, Space, Table } from 'antd';

export default function CoreRunDialog({
    closeModal,
}: {
    closeModal?: (value: boolean) => void;
}) {
    const { data: customers, loading } = usePriceUpdateAllCustomers()
    const { statusData, loading: statusLoading, checkTaskStatus } = useCheckTaskStatus()
    const { conductCoreRun } = useConductCoreRun()
    const { cancelCoreRun } = useCancelCoreRun()

    const [ dataSource, setDataSource ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        if (!customers?.length) return
        const dataSource = customers.map((customer, index) => {
            const statusItem = statusData?.find(item => item.customer === customer)
            return {
                index: index + 1,
                customer,
                status: statusItem?.status || '',
                user: statusItem?.user || '',
                addTimestamp: statusItem?.add_timestamp || '',
            }
        })
        setDataSource(dataSource)
    }, [ customers, statusData, [] ])

    const columns = [
        {
            title: 'index',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'customer',
            dataIndex: 'customer',
            key: 'customer',
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'user',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'addTimestamp',
            dataIndex: 'addTimestamp',
            key: 'addTimestamp',
            render(text) {
                if (!text) return ''
                return <span>{dateUtils.format(new Date(text)).ymdhms}</span>
            },
        },
        {
            title: 'action',
            dataIndex: 'action',
            key: 'action',
            render(text, record) {
                return (
                    <Space>
                        <Popconfirm
                            zIndex={9999992}
                            title="Conduct"
                            description="Are you sure to conduct this customer?"
                            onConfirm={async () => {
                                await conductCoreRun(record.customer)
                                await checkTaskStatus()
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link">Run</Button>
                        </Popconfirm>
                        {
                            record?.status === 'pending' && (
                                <Popconfirm
                                    zIndex={9999992}
                                    title="Cancel"
                                    description="Are you sure to cancel this customer?"
                                    onConfirm={async () => {
                                        await cancelCoreRun(record.customer)
                                        await checkTaskStatus()
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="link">Cancel</Button>
                                </Popconfirm>
                            )
                        }
                    </Space>
                )
            },
        },
    ]

    const handleCloseModal = () => closeModal?.(false)

    return (
        <>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <Text small>
                        {QA_BUTTON_CORE_RUN_LABEL}
                    </Text>
                    <CloseIcon
                        className={styles.closeIcon}
                        onClick={handleCloseModal}
                    />
                </div>
                <div className={classNames(styles.body)}>
                    <Table 
                        dataSource={dataSource}
                        columns={columns}
                        loading={loading || statusLoading}
                        rowKey="customer"
                        size="small"
                        scroll={{ y: 400 }}
                        pagination={false}
                    />
                </div>
            </div>
        </>
    )
}
