import MaterialSlider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'

export const Slider = withStyles({
    thumb: {
        backgroundColor: '#01A699',
    },
    track: {
        borderRadius: 4,
        backgroundColor: '#01A699',
    },
    rail: {
        borderRadius: 4,
        backgroundColor: '#01A699',
        opacity: 0.24,
    },
})(MaterialSlider)
