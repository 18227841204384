import { ObjectType, TaxFilterType } from '../../types/index'

export interface TdPriceRangeType {
    // `min,max` format 
    priceRange: string;
    currency: string;
    taxFilter?: TaxFilterType;
    region?: string;
}

export interface TdPriceRangeArgType {

    categories:Array<string>;
    competitors:Array<string>;
    customer:string;
    region:string;
    currency:string;
    includeDiscount:string;
    targetGroup:string;
    date:string;
    priceFilter:ObjectType<number|boolean>;
    market:Array<string>;
}

export enum TdPriceRangeTypeEnum {
    TD = 'td',
    NEW_PRICE_TD = 'new-pricing-dashboard'

}
