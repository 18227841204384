import { ALL_COUNTRIES, ALL_VENDORS, MARKET_VENDOR } from 'consts'
import { groupBy } from 'lodash'
import { useSelector } from 'react-redux'
import { PureSellerType, SellerType } from 'types'
import { storage } from 'utils/storage'
import { getCacheData } from '../../features/filters/cacheDataSlice'

interface Option {
  key: string;
  description: string;
  isSelected: boolean;
  isDisabled?: boolean;
  smallText?: string;
  indeterminate?: boolean;
}

type SellerMap = {
  [key: string]: string[];
};

const remapEverything = (data: SellerType[], selected: SellerType[]) => {
  const mapSelected: SellerMap = {}
  selected.forEach(s => {
    if (!mapSelected[s.region]) {
      mapSelected[s.region] = []
    }
    mapSelected[s.region].push(s.vendor)
  })

  const mapByRegions = {}
  data.forEach((s: SellerType) => {
    const key = s.region
    if (typeof mapByRegions[key] === 'undefined') {
      mapByRegions[key] = []
    }

    mapByRegions[key].push(s)
  })

  const regions: Option[] = Object.keys(mapByRegions).map((region: string) => ({
    key: region,
    description: region,
    isSelected: true,
  }))

  const sellers: Option[] = data.map((seller: any) => ({
    key: seller.vendor,
    description: regions.length > 1 ? `${seller.name}` : seller.name,
    smallText: seller.region,
    isSelected: mapSelected[seller.region] && mapSelected[seller.region].includes(seller.vendor),
    parent: seller.region,
    brands: seller.brands || [],
  }))

  return {
    regions,
    sellers,
  }
}

const codeNames = (sellers: SellerType[]) => {
  const map = {}
  sellers.forEach(s => {
    map[s.vendor] = s.name
  })

  map[MARKET_VENDOR.vendor] = ALL_VENDORS
  return map
}

const nameCodes = (sellers: SellerType[]) => {
  const map = {}
  sellers.forEach(s => {
    map[s.name??''] = s.vendor
  })

  map[ALL_VENDORS] = MARKET_VENDOR.vendor

  return map
}

/**
 *
 * @param list    sellers list
 * @param region  country region
 */
export const filterVendorEuropeOrCountry = (sellers, code, region) => {
  const sellerObj: any = groupBy(sellers, item => item.key + '-' + item.smallText)

  const keyStr = `${code}-${region}`
  if (sellerObj[keyStr]) {
    return { vendor: code, region }
  }
  // rm region Mapping
  // if (sellerObj[`${code}-Europe`]) {
  //   return { vendor: code, region: 'Europe' };
  // }
  return { vendor: code, region }
}

export const getSellerByCodeAndRegion = (code, region) => {
  const sellers = storage.getSellers()
  const remap = remapEverything(sellers, [])
  const newSellers = remap.sellers
  const sellerObj = groupBy(newSellers, item => item.key + '-' + item.smallText)
  const keyStr = `${code}-${region}`
  if (sellerObj[keyStr]) {
    return { vendor: code, region }
  }
  return { vendor: code, region }
}

export const useSellers = (selectedSellers: SellerType[], selectParentRegion = true) => {
  const cacheData = useSelector(getCacheData)
  const data = { sellers: cacheData.sellers }

  const loading = false
  const error = null
  let regions: Option[] = []
  let sellers: Option[] = []
  let codenames: any
  let namecodes: any
  let sellerObj: any = {}
  if (data && data.sellers) {
    if (!(selectedSellers instanceof Array)) {
      selectedSellers = []
    }

    const remap = remapEverything(data.sellers, selectedSellers)
    regions = remap.regions
    sellers = remap.sellers

    codenames = codeNames(data.sellers)
    namecodes = nameCodes(data.sellers)

    sellerObj = groupBy(sellers, item => item.key + '-' + item.smallText)
  }

  return {
    data,
    loading,
    error,
    regions,
    sellers,
    // convert the region of selected vendor to the valid region that vendor does exist
    getSellerByCodeAndRegion: (code, region, brands): SellerType => {
      const keyStr = `${code}-${region}`

      if (sellerObj[keyStr]) {
        return { vendor: code, region, brands }
      }
      // if (sellerObj[`${code}-Europe`]) {
      //   return { vendor: code, region: 'Europe',brands };
      // }
      return { vendor: code, region, brands }
    },
    /**
     *  get the cometitors of one region
     * @param region  vender code
     */
    filterVendorEuropeOrCountry: (region: string): Array<PureSellerType> => {

      let sellersTmp: Array<PureSellerType> = []

      // ------- return all posible seller when `ALL_COUNTRIES` is selected -------
      if (region === ALL_COUNTRIES) {
        sellersTmp =  Object.values(sellers.reduce((res, item) => {
          if (!res[item.key]) {
            res[item.key] = item
          }
          return res
        }, {}))
      } else {
        const sellerObj: any = groupBy(sellers, item => item.key + '-' + item.smallText)
        const tmps: Array<string> = []
        Object.keys(sellerObj).forEach(key => {
          const keyStr = sellerObj[key][0].key
          if (tmps.indexOf(keyStr) > -1) return
          if (`${keyStr}-${region}` === key) {
            tmps.push(keyStr)
            sellersTmp.push(sellerObj[key][0])
          } else if (sellerObj[keyStr + '-' + region]) {
            tmps.push(keyStr)
            sellersTmp.push(sellerObj[`${keyStr}-${region}`][0])
          }
        })
      }

      /**
       * 新功能：Competitor 中需要添加 Market 选项
       */
      const newSellersTmp = sellersTmp.filter(item => item.description !== 'Market')
      newSellersTmp.push({
        description: ALL_VENDORS,
        isSelected: false,
        key: 'Market',
        brands: [],
        parent: 'World',
        smallText: 'World',
      })

      return newSellersTmp
    },
    namecodes,
    codenames, // code mapping name
  }
}

