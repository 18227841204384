import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import logo from 'assets/img/logoBgTransparent.png'
import { LogoProps } from './types'
import styles from './styles.module.scss'

export const Logo: FC<LogoProps> = ({
    style = {},
    className,
    noText= false,
}) => {
    const history = useHistory()

    return (
        <div className={classnames([ styles.topLogo, className ])} style={style}>
            <img src={logo} alt="logo" className={styles.logoImg} />
            {noText?null:<div className={styles.logoText}>Norna Analytics</div>}
        </div>
    )
}
