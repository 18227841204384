import { SORTBY_HIGHEST_PRICE, SORTBY_INTRO_DATE, SORTBY_LOWEST_PRICE, SORTBY_MOST_POPULAR, SORTBY_NAME } from 'consts'

export type SortByProps = {
  onChange?: Function;
  sort?: any
}

const SortTypeConst = {
  DATE_DESC: SORTBY_INTRO_DATE,
  NAME_ASC: SORTBY_NAME,
  SELLER: SORTBY_MOST_POPULAR,
  PRICE_DESC: SORTBY_HIGHEST_PRICE,
  PRICE_ASC: SORTBY_LOWEST_PRICE,
}

export const sortByOptionsFn = sortField => Object.keys(SortTypeConst).map((key, index) => {
  const keyVal = SortTypeConst[key]
  let isSelected = false// keyVal.indexOf(sortField.sortField) > -1 && keyVal.indexOf(sortField.sortOrder)>-1;
  if (keyVal === SORTBY_INTRO_DATE && sortField.sortField === 'date') {
    isSelected = true
  }
  if (keyVal === SORTBY_NAME && sortField.sortField === 'title') {
    isSelected = true
  }
  if (keyVal === SORTBY_MOST_POPULAR && sortField.sortField === 'seller') {
    isSelected = true
  }
  if (keyVal === SORTBY_HIGHEST_PRICE && sortField.sortField === 'price' && sortField.sortOrder === 'desc') {
    isSelected = true
  }
  if (keyVal === SORTBY_LOWEST_PRICE && sortField.sortField === 'price' && sortField.sortOrder === 'asc') {
    isSelected = true
  }
  return {
    val: key,
    active: isSelected, // showType === key,
    label: keyVal==='Best seller'?'Most popular':keyVal,
    getDate() {
    },
    isDefault: index === 0,
  }
})

export const formatSortFieldFn = lookbook => {
  lookbook = lookbook.length?lookbook[0]:{ label:'' }
  const sortData = {
    sortField: '',
    sortOrder: '',
  }
  if (lookbook.label.indexOf(SortTypeConst.DATE_DESC) > -1) {
    sortData.sortField = 'date'
    sortData.sortOrder = 'desc'
  }
  if (lookbook.label.indexOf(SortTypeConst.NAME_ASC) > -1) {
    sortData.sortField = 'title'
    sortData.sortOrder = 'asc'
  }
  if (lookbook.label.indexOf(SortTypeConst.PRICE_DESC) > -1 || lookbook.label.indexOf(SortTypeConst.PRICE_ASC) > -1) {
    sortData.sortField = 'price'
    sortData.sortOrder = lookbook.label.indexOf(SortTypeConst.PRICE_DESC) > -1 ? 'desc' : 'asc'
  }
  if (lookbook.label.indexOf(SortTypeConst.SELLER) > -1) {
    sortData.sortField = 'seller'
    sortData.sortOrder = 'asc'
  }
  return sortData
}
