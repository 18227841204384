import React from 'react'
import { Descriptions } from '../Descriptions'
import { Title } from '../Title'

export const Performance = ({
    averagePrice,
    discountDepth,
    nonzeroDiscountDepth,
    daysObserved,
}) => {
    return (
        <>
            <Title>Performance</Title>
            <Descriptions.Item label="LIFECYCLE AVERAGE PRICE" labelFontSize={11} value={averagePrice} valueFontSize={22} />
            <Descriptions.Item label="DISCOUNT DEPTH LIFE CYCLE (ALL PERIODS INCLUDED)" labelFontSize={11} value={discountDepth} valueFontSize={22} />
            <Descriptions.Item label="DISCOUNT DEPTH LIFE CYCLE (ONLY DISCOUNTED PERIODS INCLUDED)" labelFontSize={11} value={nonzeroDiscountDepth} valueFontSize={22} />
            <Descriptions.Item label="DAYS OBSERVED" labelFontSize={11} value={daysObserved} valueFontSize={22} />
        </>
    )
}
