/**
* @file   UpdateComponent
* @date   2022-01-12
* @author byte_su@163.com
*/
import React, { useCallback, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ActionBoxContext } from 'components/common/InfoBox/context'
import { useMenuAndFilterPermission } from 'hooks/useQa'
import { judgeGrantedPermission } from 'utils/properties'
import { RoutesObj } from 'global/Routes'
import { useClearFilter } from 'hooks/useClearFilter'
import { useLookbook } from 'hooks/useLookbook'
import { updatePageMarginTop } from 'componentsv2/MenuBar/utils'

export const UpdateComponent = () => {

  const { pathname, search } = useLocation()
  const history = useHistory()

  const actionBox = useContext(ActionBoxContext)

  const { menuAvailable } = useMenuAndFilterPermission()
  const { clearFilter } = useClearFilter()
  const { isLookbook } = useLookbook()

  const locationChange = useCallback(() => {
    // clear filter when navigation to search option from lookbook analysis view
    if (isLookbook && 
      ![
        RoutesObj.PRICE_HISTORY.URL,
        RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL,
        RoutesObj.PRICE_ARCHITECTURE.URL,
        RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL,
        RoutesObj.PRICING_JUMP_TABLE.URL,
        RoutesObj.CATEGORY_ANALYTICS.URL,
        RoutesObj.COLOR_ANALYTICS.URL,
        RoutesObj.MATERIAL_ANALYTICS.URL,
        RoutesObj.POPULAR_PRODUCTS.URL,
        RoutesObj.PRICE_INSIGHT.URL,
        RoutesObj.ASSORTMENT_INSIGHT.URL,
        RoutesObj.PRICE_SPREADS.URL,
        RoutesObj.IN_OUT_MONITORING.URL,
        RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL,
        RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL,
      ].some(item => item.startsWith(pathname))
    ) {
      clearFilter()
    }

    // 切换页面时, 更新页面`margin-top`的值
    updatePageMarginTop()

    /**
     * 切换视图时, 滚动到最左侧, 最上方
     */
    const pageContainer: HTMLDivElement = document.querySelector('.page-container') as HTMLDivElement
    if (pageContainer) pageContainer.scrollTo({ left: 0,top:0 })

    // const bodyContainer: HTMLBodyElement = document.querySelector('body') as HTMLBodyElement;
    // if (bodyContainer) bodyContainer.scrollTo({ top: 0 })

  }, [ pathname, isLookbook, clearFilter ])

  /**
   * 切换功能视图，关闭弹框
   */
  React.useEffect(() => {

    actionBox.closeAll()
    const granted = judgeGrantedPermission(pathname + search, menuAvailable)
    if (!granted) {
      history.push(RoutesObj.DASHBOARD.URL)
    }
    locationChange()

  }, [pathname, search, history]); //eslint-disable-line

  return <div />
}

UpdateComponent.displayName = 'UpdateComponent'
