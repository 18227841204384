import * as config from 'configs/moduleName'
import { stringUtils } from 'norna-uikit'

export function getExportFilename(name: string) {
    // 规则1: 使用菜单名称
    // 规则2: 空格用下划线替代
    let newName: string = name
    newName = newName
        .replace(/\s+&\s+/g, ' ')   // ' & ' -> ' '
        .replace(/\(/g, ' ')        // '(' -> ' '
        .replace(/\)/g, ' ')       // ')' -> ' '
        .replace(/\s+/g, ' ')       // '   ' -> ' '
        .split(' ')
        .filter(item => item)
        .map(item => stringUtils.capitalize(item))
        .join('_')
    return `Norna_Analytics_${newName}_Export`
}

export const priceInsightExportFilename = getExportFilename(config.PRICE_INSIGHT_TABLE_NAME)
export const assortmentInsightExportFilename = getExportFilename(config.ASSORTMENT_INSIGHT_TABLE_NAME)
export const priceSpreadsExportFilename = getExportFilename(config.PRICE_SPREADS_TABLE_NAME)
export const inOutMonitoringExportFilename = getExportFilename(config.IN_OUT_MONITORING_TABLE_NAME)
export const dynamicDashboardExportFilename = getExportFilename(config.DYNAMIC_DASHBOARD_NAME)
export const dynamicDashboardReportExportFilename = getExportFilename(config.DYNAMIC_DASHBOARD_REPORT_NAME)
export const soldOutDashboardExportFilename = getExportFilename(config.SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME)

export const priceDriversExportFilename = getExportFilename(config.PRICE_DRIVERS_TABLE_NAME)
export const priceDriversPeriodToPeriodExportFilename = getExportFilename(config.PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME)
export const PriceArchitectureExportFilename = getExportFilename(config.PRICE_ARCHITECTURE_TABLE_NAME)
export const discountsExportFilename = getExportFilename(config.DISCOUNTS_TABLE_NAME)
export const crossRegionPricingExportFilename = getExportFilename(config.CROSS_REGION_PRICING_NAME)
export const crossRegionSoldOutExportFilename = getExportFilename(config.CROSS_REGION_SOLD_OUT_NAME)

export const categorySplitExportFilename = getExportFilename(config.CATEGORY_SPLIT_PIE_GRAPH_NAME)
export const colorSplitExportFilename = getExportFilename(config.COLOR_SPLIT_PIE_GRAPH_NAME)
export const materialSplitExportFilename = getExportFilename(config.MATERIAL_SPLIT_PIE_GRAPH_NAME)
export const productRankingExportFilename = getExportFilename(config.PRODUCT_RANKING_TABLE_NAME)
export const productRankingForJustBrandsExportFilename = getExportFilename(config.PRODUCT_RANKING_FOR_JUST_BRANDS_TABLE_NAME)
export const attributeRankingExportFilename = getExportFilename(config.ATTRIBUTE_RANKING_TABLE_NAME)
export const sizeOfLineExportFilename = getExportFilename(config.SIZE_OF_LINE_TABLE_NAME)
export const optionsInOutByCalendarExportFilename = getExportFilename(config.OPTIONS_IN_OUT_CALENDAR_NAME)
export const mostPopularLaunchDaysExportFilename = getExportFilename(config.WEEKLY_TABLE_NAME)
export const platformAnalyticsExportFilename = getExportFilename(config.PLATFORM_ANALYTICS_NAME)

export const searchForOptionsExportFilename = getExportFilename(config.SEARCH_FOR_OPTIONS_NAME)
export const mySavedLookbooksExportFilename = getExportFilename(config.MY_SAVED_LOOKBOOKS_NAME)

export function getExportFilenameByModule(moduleName: string) {
    const mapper = {
        [config.PRICE_INSIGHT_TABLE_NAME]: priceInsightExportFilename,
        [config.ASSORTMENT_INSIGHT_TABLE_NAME]: assortmentInsightExportFilename,
        [config.PRICE_SPREADS_TABLE_NAME]: priceSpreadsExportFilename,
        [config.IN_OUT_MONITORING_TABLE_NAME]: inOutMonitoringExportFilename,
        [config.SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME]: soldOutDashboardExportFilename,
    }
    return mapper[moduleName] || getExportFilename('unknown')
}
