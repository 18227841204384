import React, { useState, useRef, useCallback, useEffect } from 'react'
import { ShowMoreIcon } from 'assets/icons/ShowMore'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ClosableCard } from 'components/cards'
import classnames from 'classnames'
import { ShowMoreProps } from './types'
import styles from './styles.module.scss'

type ItemProps = {
  onClick: Function;
  children?: any;
};

const Item = ({ onClick = () => {}, children }: ItemProps) =>
    <div className={styles.item} onClick={() => onClick()}>
        {children}
    </div>

export const ShowMore = ({ isFromLookbook, children, showTitle = true }: ShowMoreProps) => {
  const [ showMore, setShowMore ] = useState(false)
  const node: any = useRef<HTMLElement>(null)

  const handleClick = useCallback((event: any) => {
    if (node && node.current && node.current.contains(event.target)) {
      return
    }
    setShowMore(false) // outside click
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [ handleClick ])

  return (
      <div
          className={classnames(
          styles['show-more'],
          isFromLookbook && styles.showMoreLookbook,
        )} ref={node}
      >
          <ShowMoreIcon onClick={() => setShowMore(!showMore)} />

          <ShouldRender shouldRender={showMore}>
              <div className={styles['show-more-dialog']}>
                <ClosableCard title="More" showTitle={showTitle} onClose={() => setShowMore(false)}>{children}</ClosableCard>
              </div>
          </ShouldRender>
      </div>
  )
}

ShowMore.Item = Item
