import React, { CSSProperties, useEffect, useState } from 'react'
import classnames from 'classnames'
import { CloseIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { FILTER_DIALOG_CLASS } from 'consts'
import styles from './styles.module.scss'
import { ArrowDirectionIcon } from './icons'

interface MaterialFilterProps {
  perent: number;
  min?: number;
  label: string;
  checked: boolean;
  onClose: Function;
  onOk: Function;
  modalMaskStyle?: CSSProperties;
}
export const RadioItem = ({ checked = false, setChecked }: any) => (
    <button
      className={classnames(styles.radio, checked ? styles.active : '')}
      onClick={() => {
        setChecked(!checked)
      }}
    >
      <div />
    </button>
  )
export const MaterialFilter = React.forwardRef(({ perent, checked, min = 0, label, onClose, onOk, modalMaskStyle = {} }: MaterialFilterProps, ref) => {
  const [ valInner, setValInner ] = useState(perent)
  const [ checkedInner, setChecked ] = useState(checked)

  /**
   * 2022/06/28 新需求
   * 没有选中 activate 选项时, 页面上 input 框显示 80%, 但其实接口传参 material percent 还是 0
   */
  useEffect(() => {
    if (label === 'material' && checkedInner === false) {
      setValInner(80)
    }
  }, [ checkedInner, label ])

  return (
    <div className={`material-filter ${FILTER_DIALOG_CLASS}`}>
      <div
        className={styles.modal}
        style={modalMaskStyle}
        onClick={() => onClose?.()}
      />
      <div className={classnames(styles['material-filter'])}>
        <div className={styles.close}>
          <CloseIcon
            onClick={() => {
              onClose({ percent: valInner, checked: checkedInner })
            }}
          />
        </div>
        <div className={styles.content}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <button
              className={classnames(styles.radio, checkedInner ? styles.active : '')}
              onClick={() => {
                setChecked(!checkedInner);
              }}
            >
              <div />
            </button> */}
            <RadioItem
              setChecked={setChecked}
              checked={checkedInner}
            />
            <div>Activate {label} filter</div>
          </div>
          <div className={classnames(styles['input-content'], checkedInner ? '' : styles.red)}>
            <div>
              <div>More than:</div>
              <div>
                <input
                  value={valInner}
                  onChange={(e: any) => {
                    setValInner(e.target.value)
                  }}
                  onBlur={e => {
                    // parseInt('')     is   NaN
                    // parseInt('a9')   is   NaN
                    // parseInt('9a')   is   9
                    let num = 0
                    if (Number(e.target.value[0]).toString() !== 'NaN') {
                      num = parseInt(e.target.value, 10)
                    }
                    if (num > 100) num = 100
                    if (num < min) num = min
                    setValInner(num)
                  }}
                />
                <span>|</span>
                <span>%</span>
              </div>
            </div>
            <button
              onClick={() => {
                const t = valInner + 1
                setValInner(t > 100 ? 100 : t)
              }}
            ><ArrowDirectionIcon />
            </button>
            <button onClick={() => {
              const t = valInner - 1
              setValInner(t < min ? min : t)
            }}
            ><ArrowDirectionIcon />
            </button>
          </div>

          <div>
            <Button
              primary
              mini
              className={styles.btn}
              onClick={() => {
                onOk({ percent: valInner, checked: checkedInner })
              }}
            > Ok
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})
MaterialFilter.displayName = 'MaterialFilter'
/**
 * init value
 */
