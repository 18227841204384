import React from 'react'
import { useDashboardFetchProducts } from 'hooks/api/useDashboard'
import { useFilterCompetitor } from 'hooks/useFilter'
import { formatDateStr2 } from 'utils/dateUtils'
import { usePageDate } from 'hooks/usePageDate'
import { ProductsModal } from 'components/ProductsModalV2'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { TableCell, TableCellWithSymbol } from 'componentsv2/TradingTableV2/TableCell'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import classNames from 'classnames'
import { isInclueInvalidVendors } from 'utils/isInclueInvalidVendors'
import { ALL_VENDORS, MARKET_VENDOR, NA, SELECTED_VENDORS } from 'consts'
import { getCompetitorOptions } from 'utils/optionsUtils'
import { isExcludeHmAndZaraForCompetitorOptions } from 'utils/privUtils'
import { isIncludeSeller, isIncludeSellerForSelectedVendors } from './utils'
import { numberUtils } from 'norna-uikit'

export const TableSoldOutCell = ({
    record,
    config,
    fieldName,
    metricName,
    metricDisplayName,
}: {
    record?: any;
    config?: any;
    fieldName: string;
    metricDisplayName?: string;
    metricName?: string;
}) => {
    const [ filterCompetitor ] = useFilterCompetitor()
    const { pageDate, comparisonPageDate } = usePageDate()

    const { fetchData: fetchProducts } = useDashboardFetchProducts()
    const dialogRef = React.useRef<DialogRefType>({} as DialogRefType)

    const showProductsModal = async ({ categoryName, vendorCode, vendorName, metricName }) => {
        const ids = await fetchProducts({ metric: metricName, category: categoryName, competitor: vendorCode })
        if (!ids.length) return

        const dialogId = `Options-${vendorCode}-${categoryName}`
        const closeFn = dialogRef.current?.closeDialog
        const title = [ vendorName, categoryName, metricDisplayName, 'Sold out' ].filter(item => item).join(', ')
        dialogRef.current?.openDialog(dialogId, (
            <ProductsModal
                productUrls={ids}
                onClose={() => closeFn(dialogId)}
                headerLeading={numberUtils.formatNumberByComma(ids.length)}
                headerTitle={title}
                headerDate={formatDateStr2(pageDate, true)}
            />
        ))
    }

    const onClickCell = async () => {
        if (!metricName) return
        await showProductsModal({
            categoryName: record.categoryName,
            vendorCode: record.vendorCode,
            vendorName: record.vendorName,
            metricName,
        })
    }

    let value = numberUtils.formatNumber(record?.[fieldName]?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
    let value2 = numberUtils.formatNumber(record?.[fieldName]?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
    let vendorList: string[] = []
    if (record.vendorCode === SELECTED_VENDORS) {
        vendorList = filterCompetitor.map(s => s.vendor)
    } else if (record.vendorCode === ALL_VENDORS || record.vendorCode === MARKET_VENDOR.vendor) {
        vendorList = getCompetitorOptions({ region: fieldName, excludeMarket: true, excludeSelectedVendor: true })
            .map(s => s.vendor)
    } else {
        vendorList = [ record.vendorCode ]
    }
    const valueInvalid = isInclueInvalidVendors({
        dateRangeValue: pageDate,
        compareDateRangeValue: comparisonPageDate,
        vendorList,
        region: fieldName,
    })
    if (valueInvalid) {
        value = NA
        value2 = ''
    }
    const isClickable = Boolean(metricName) && !valueInvalid

    if (!isExcludeHmAndZaraForCompetitorOptions()) {
        if (record.vendorCode === SELECTED_VENDORS) {
            if (!isIncludeSellerForSelectedVendors(filterCompetitor, fieldName)) {
                return (
                    <TableLockCell 
                        isDeepBorderRight
                    />
                )
            }
        } else {
            if (!isIncludeSeller(record.vendorCode, fieldName)) {
                return (
                    <TableLockCell 
                        isDeepBorderRight
                    />
                )
            }
        }
    }
    
    if (isExcludeHmAndZaraForCompetitorOptions() && [ 'hm', 'zara' ].includes(record.vendorCode) && fieldName !== 'Germany') {
        return (
            <TableLockCell 
                isDeepBorderRight
            />
        )
    }

    return (
        <TableCell 
            width={config.soldOutWidth} 
            isDeepBorderRight 
            style={{ paddingLeft: 0 }}
        >
            <div style={{ width: config.soldOutWidth, display: 'flex' }}>
                <TableCell 
                    isEmptyBorderBottom
                    text={value} 
                    record={record} 
                    width={config.soldOutValueWidth}
                    style={{ cursor: isClickable ? 'pointer' : 'auto' }}
                    isClickable={isClickable}
                    className={classNames({ 'cell-mouse-effect-div': isClickable })}
                    onClick={isClickable ? onClickCell : undefined}
                />
                <TableCellWithSymbol 
                    isEmptyBorderBottom
                    isComparisonField 
                    text={value2} 
                    record={record} 
                    width={config.soldOutChangeWidth} 
                />
            </div>
            <Dialog ref={dialogRef} />
        </TableCell>
    )
}
