import React from 'react'
import Table from './components/Table'

const CrossRegionSoldOut = () => {

    return (
        <>
            <Table />
        </>
    )
}

export default CrossRegionSoldOut
