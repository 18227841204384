import { useState } from 'react'
import { useFetch } from 'libs'
import { argFnWeeklyProductsIn } from 'graphql/nornaapi'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterGender, useFilterIgnoreNoAvailableSize, useFilterNoHarmonizedSize, useFilterNoHistoricalSize, useFilterNoSize, useFilterNoUnavailableSize, useFilterZalandodeepShopEmptyString } from 'hooks/useFilter'
import { getCategoriesPayload, getCompetitorPayload, getDashboardPropertiesPayload, getGroupsPayload, getMarketPayload, getPriceFilterPayload, getTargetGroupPayload } from 'utils/queryPayload'
import { usePageDate } from 'hooks/usePageDate'
import { useCompetitorOptions } from 'hooks/useOptions'
import { useExcludeProductsComparisonQuery, useManualLoading } from 'hooks'
import { cloneDeep, uniq } from 'lodash'
import { CATEGORY_TOTAL, SELECTED_CATEGORY, SELECTED_VENDORS } from 'consts'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { storage } from 'utils/storage'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { useLookbook } from 'hooks/useLookbook'
import { useMostPopularLaunchDaysModuleData } from 'features/filters/moduleDataSlice.hook'
import { getOldCompetitorValue, isSubsetPayload } from 'features/filters/moduleDataSlice'
import { isSuperArray } from 'utils/array'
import { isCompetitorGroupChanged } from 'utils/group'

export const useWeeklyData = () => {
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ filterNoSize ] = useFilterNoSize()
    const [ filterNoHistoricalSize ] = useFilterNoHistoricalSize()
    const [ filterNoUnavailableSize ] = useFilterNoUnavailableSize()
    const [ filterNoHarmonizedSize ] = useFilterNoHarmonizedSize()
    const [ filterZalandodeepShopEmptyString ] = useFilterZalandodeepShopEmptyString()
    const [ filterIgnoreNoAvailableSize ] = useFilterIgnoreNoAvailableSize()
    const { pageDate: date } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { checked: lookbooks, isLookbook } = useLookbook()
    const { manualLoading, showManualLoading } = useManualLoading()

    const { postFn: fetch, data, loading } = useFetch()
    const [ filteredApiData, setFilteredApiData ] = useState<any>({})
    const [ fetchPayload, setFetchPayload ] = useState<any>({})
    const [ moduleData, setModuleData ] = useMostPopularLaunchDaysModuleData()

    const {
        newCompetitorValue = [],
        oldCompetitorValue = [],
        apiPayload,
        apiData,
    } = moduleData

    const handleCacheData = () => {
        let newApiData = cloneDeep(apiData)
        if (!isLookbook) {
            Object.keys(newApiData || {}).forEach(vendorCode => {
                if (!newCompetitorValue.includes(vendorCode)) {
                    delete newApiData[vendorCode]
                }
            })
        }
        setFilteredApiData(newApiData)
    }

    const fetchData = () => {
        if (!filterCompetitor.length || !competitorOptions?.length) return

        const priceFilter = getPriceFilterPayload(comparisonQuery.collection.query)
        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const competitorVendors = [ ...filterCompetitor.map(item => item.vendor), SELECTED_VENDORS ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: filterCountry }))

        const market = getMarketPayload({
            competitorOptions,
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
        })

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers ],
        })

        const competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        const targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })

        const query = {
            region: filterCountry,
            targetGroup,
            date,
            competitors,
            allCategory: getCategoriesPayload([ ...filterCategory ]),
            market,
            groups,
            priceFilter,
            properties,
            lookbooks,
            noSize: filterNoSize,
            noHistoricalSize: filterNoHistoricalSize,
            noUnavailableSize: filterNoUnavailableSize,
            noHarmonizedSize: filterNoHarmonizedSize,
            zalandodeepShopEmptyString: filterZalandodeepShopEmptyString,
            ignoreNoAvailableSize: filterIgnoreNoAvailableSize,
        }

        const payload = argFnWeeklyProductsIn(query)

        if (isSubsetPayload({ newPayload: payload, oldPayload: apiPayload })) {
            showManualLoading()
            return
        }

        setModuleData({ 
            apiPayload: cloneDeep(payload),
            oldCompetitorValue: getOldCompetitorValue({
                competitorValue: payload?.data?.competitors || [],
                market: payload?.data?.market,
                groups: payload?.data?.groups,
            }),
        })

        setFetchPayload({ ...payload })
        fetch(payload.url, payload)
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, date, competitorOptions, lookbooks ])

    useDeepCompareEffect(() => {
        if (!newCompetitorValue?.length) return

        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleCacheData()
            return
        }

        fetchData()
    }, [ newCompetitorValue, [] ])

    /**
     * 更新 newCompetitorValue
     */
    useDeepCompareEffect(() => {
        setModuleData({
            newCompetitorValue: filterCompetitor.map(item => item.vendor),
        })
    }, [ filterCompetitor, [] ])

    useDeepCompareEffect(() => {
        if (!data) return

        const newData = cloneDeep(data)
        // 是否选中全部一级 category, 全部选中显示 All categories, 否则显示 Selected categories
        if (!storage.getIsLookbook()) {
            const categoryTreeList = storage.getCategoryTreeList()
            if (getFirstLevelCategories({ selectedCategories: filterCategory }).length !== categoryTreeList.length) {
                Object.keys(newData).forEach(vendor => {
                    newData[vendor][SELECTED_CATEGORY] = cloneDeep(newData[vendor][CATEGORY_TOTAL])
                    delete newData[vendor][CATEGORY_TOTAL]
                })
            }
        }

        setFilteredApiData({ ...newData })
        setModuleData({ apiData: { ...newData } })
    }, [ data, {} ])

    return {
        fetchData,
        fetchPayload,
        data: filteredApiData,
        loading: loading || manualLoading,
    }
}
