import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { CATEGORY_TOTAL, SELECTED_CATEGORY, SELECTED_VENDORS, SUMMARY } from 'consts'
import { uniq } from 'lodash'
import { getVendorNameByCode } from 'utils'
import { difference, sortVendorList } from 'utils/array'
import { getTotalCategoryLabel, getTotalVendorLabel, handleTableExpanded } from 'utils/dashboardPageUtils'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { whetherLock } from 'utils/lockSellerUtils'
import { numberUtils } from 'norna-uikit'
import { storage } from 'utils/storage'

interface ApiData {
    [vendor: string]: {
        [category: string]: {
            [field: string]: any;
        }
    }
}

export const handleTableData = ({
    apiData,
    isCompetitorView,
    competitorValue,
    categoryValue,
    expandedRowKeys = [],
    subExpandedRowKeysRef = [],
    region,
}: {
    apiData: ApiData,
    isCompetitorView: boolean;
    competitorValue: string[];
    categoryValue: string[];
    expandedRowKeys: string[];
    subExpandedRowKeysRef: string[];
    region: string;
}): any => {
    if (!apiData || !Object.keys(apiData || {})?.length) {
        return {
            tabledata: [],
            metricsTab: [],
        }
    }
    apiData = { ...apiData }

    let totalVendorLabel = getTotalVendorLabel(apiData)
    const totalCategoryLabel = getTotalCategoryLabel(apiData[competitorValue[0]])
    const sellers = storage.getSellers()

    if (!storage.getIsLookbook()) {
        if (isCompetitorView) {
            // ...
        } else {
            difference(Object.keys(apiData), uniq([ ...competitorValue, SELECTED_VENDORS ])).forEach(vendor => {
                delete apiData[vendor]
            })
            if (!competitorValue.includes(SELECTED_VENDORS) && apiData[SELECTED_VENDORS]) {
                apiData[SUMMARY] = apiData[SELECTED_VENDORS]
              delete apiData[SELECTED_VENDORS]
              totalVendorLabel = SUMMARY
            }
        }
    }

    const tabledata: any = []
    const metricsTab: any[] = []

    const categoryTreeObj = storage.getCategoryTreeObj()
    const firstLevelCategoryList = getFirstLevelCategories({ selectedCategories: categoryValue })
    const categoryKeys = [ ...categoryValue ]

    // vendor 进行排序
    let vendorList = Object.keys(apiData)
    vendorList = sortVendorList({ vendorList })
    
    // competitor 视图
    if (isCompetitorView) {
        const metricsChildren: any[] = []

        vendorList.forEach(vendor => {
            const queryDateValid = isQueryDateValid({ vendor, region, competitorValue })
            const queryComparisonDateValid = isQueryDateValid({ vendor, region, competitorValue, isComparisonDate: true })
            const isLock = whetherLock({ vendorCode: vendor, region, sellers, selectedVendorCodeList: competitorValue })

            const vendorName = getVendorNameByCode(vendor)
            const vendorData = apiData[vendor]

            const firstLevelExpandId = `${vendor}_${SELECTED_CATEGORY}`

            // 第 0 级
            const level0Children: any[] = []
            firstLevelCategoryList.forEach(category => {
                const level1Children: any[] = []
                if (categoryTreeObj[category]?.list?.length) {
                    const secondLevelExpandId = `${vendor}_${category}`
                    metricsChildren.push({
                        expandId: `${firstLevelExpandId}__${secondLevelExpandId}`,
                        active: false,
                        code: vendor,
                        level: 1,
                        categoryKey: category,
                        title: vendorName,
                        categorycount: categoryTreeObj[category].list?.filter(item => categoryKeys.includes(item.name)).length,
                    })

                    // 第 2 级
                    categoryTreeObj[category].list?.filter(item => categoryKeys.includes(item.name))?.forEach(subcategoryObj => {
                        const subcategory = subcategoryObj.name
                        const level2TreeItemData = {
                            ...vendorData[subcategory],
                            level: 2,
                            vendorName,
                            vendorCode: vendor,
                            isLock,
                            categoryName: subcategory,
                            queryDateValid,
                            queryComparisonDateValid,
                            optionsNum: vendorData[subcategory]?.Numbers?.value || 0,
                        }
                        level1Children.push(level2TreeItemData)
                    })
                }

                // 第 1 级
                const level1TreeItemData = {
                    ...vendorData[category],
                    children: level1Children,
                    level: 1,
                    vendorName,
                    vendorCode: vendor,
                    isLock,
                    categoryName: category,
                    queryDateValid,
                    queryComparisonDateValid,
                    optionsNum: vendorData[category]?.Numbers?.value || 0,
                }
                level0Children.push(level1TreeItemData)
            })

            // 第 0 级, All categories
            const lavel0TreeItemData = {
                ...vendorData[totalCategoryLabel],
                children: level0Children,
                level: 0,
                vendorName,
                vendorCode: vendor,
                isLock,
                categoryName: totalCategoryLabel,
                queryDateValid,
                queryComparisonDateValid,
                optionsNum: vendorData[totalCategoryLabel]?.Numbers?.value || 0,
            }
            tabledata.push(lavel0TreeItemData)

            metricsTab.push({
                expandId: firstLevelExpandId,
                active: false,
                code: vendor,           // 小写名称
                title: vendorName,      // 大写名称
                categoryKey: totalCategoryLabel,        // category 名称
                level: 0,
                children: metricsChildren.filter(c => c.code === vendor),
                categorycount: firstLevelCategoryList.length + 1,
            })
        })
    }

    // category 视图
    else {
        const vendorDateValidMapper = {}
        vendorList.forEach(vendor => {
            vendorDateValidMapper[vendor] = {
                queryDateValid: isQueryDateValid({ vendor, region, competitorValue }),
                queryComparisonDateValid: isQueryDateValid({ vendor, region, competitorValue, isComparisonDate: true }),
                isLock: whetherLock({ vendorCode: vendor, region, sellers, selectedVendorCodeList: competitorValue }),
            }
        })

        // totalCategoryLabel 放在数组最后面, 对应页面上表格最后一列
        const categoryList = [ ...firstLevelCategoryList, totalCategoryLabel ]
        categoryList.forEach(category => {
            const level0ChildrenData: any[] = []
            const level0Children: any[] = []
            const metricsChildren: any[] = []

            vendorList.forEach(vendor => {
                const queryDateValid = vendorDateValidMapper[vendor]?.queryDateValid
                const queryComparisonDateValid = vendorDateValidMapper[vendor]?.queryComparisonDateValid
                const isLock = vendorDateValidMapper[vendor]?.isLock
                
                const vendorName = getVendorNameByCode(vendor)
                const vendorData = apiData[vendor]
                const rowData = { ...vendorData[category] }

                // 第 1 级
                if (vendor !== totalVendorLabel) {
                    const level1TreeItemData = {
                        ...rowData,
                        level: 1,
                        vendorName,
                        vendorCode: vendor,
                        isLock,
                        categoryName: category,
                        queryDateValid,
                        queryComparisonDateValid,
                        optionsNum: rowData?.Numbers?.value || 0,
                    }
                    level0Children.push(level1TreeItemData)
                }
                // 第 0 级
                else {
                    tabledata.push({
                        ...rowData,
                        level: 0,
                        children: level0Children,
                        childrenData: level0ChildrenData,
                        isLock,
                        vendorCode: vendor,
                        vendorName,
                        categoryName: category,
                        queryDateValid,
                        queryComparisonDateValid,
                        optionsNum: rowData?.Numbers?.value || 0,
                    })
                }
            })

            // 构造 childrenData 数据
            const categoryValue = categoryTreeObj[category]
            const firstLevelExpandId = [ CATEGORY_TOTAL, SELECTED_CATEGORY ].includes(category) ? SELECTED_CATEGORY : category
            if (categoryValue?.list?.filter(item => categoryKeys.includes(item.name))?.length) {
                categoryValue.list?.filter(item => categoryKeys.includes(item.name)).forEach(subcategory => {
                    const children: any[] = []

                    vendorList.forEach(vendor => {
                        const queryDateValid = vendorDateValidMapper[vendor]?.queryDateValid
                        const queryComparisonDateValid = vendorDateValidMapper[vendor]?.queryComparisonDateValid
                        const isLock = vendorDateValidMapper[vendor]?.isLock

                        const vendorName = getVendorNameByCode(vendor)
                        const vendorData = apiData[vendor]
                        const rowData = { ...vendorData[subcategory.name] }

                        // 第 1 级
                        if (vendor !== totalVendorLabel) {
                            children.push({
                                ...rowData,
                                isLock,
                                vendorCode: vendor,
                                vendorName,
                                categoryName: subcategory.name,
                                parentCategoryName: category,
                                queryDateValid,
                                queryComparisonDateValid,
                                optionsNum: rowData?.Numbers?.value || 0,
                            })
                        }
                        // 第 0 级
                        else {
                            level0ChildrenData.push({
                                ...rowData,
                                level: 1,
                                children,
                                isLock,
                                vendorCode: vendor,
                                vendorName,
                                categoryName: subcategory.name,
                                parentCategoryName: category,
                                queryDateValid,
                                queryComparisonDateValid,
                                optionsNum: rowData?.Numbers?.value || 0,
                            })
                        }
                    })

                    metricsChildren.push({
                        expandId: `${firstLevelExpandId}__${subcategory.name}`,
                        active: false,
                        code: subcategory.name,
                        level: 1,
                        pTitle: category,
                        title: subcategory.name,
                        categorycount: vendorList.length,
                    })
                })
            }

            metricsTab.push({
                expandId: firstLevelExpandId,
                active: false,
                code: category,       
                title: category,      
                level: 0,
                children: metricsChildren,
                categorycount: vendorList.length,
            })
        })
    }
    
    handleTableExpanded(metricsTab, expandedRowKeys, subExpandedRowKeysRef)

    return {
        tabledata,
        metricsTab,
    }
}

export const tabWidth = 148
export const optionsWidth = 100
export const categoryWidth = 190
export const arrowWidth = 40

export const avgPriceValueWidth = 73
export const avgPriceChangePercentWidth = 84
export const avgPriceWidth = avgPriceValueWidth + avgPriceChangePercentWidth

export const discountValueWidth = 73
export const discountChangeWidth = 84
export const discountWidth = discountValueWidth + discountChangeWidth

export const noOfResellersValueWidth = 73
export const noOfResellersChangePercentWidth = 84
export const noOfResellersWidth = noOfResellersValueWidth + noOfResellersChangePercentWidth

export const regionWidth = avgPriceWidth + discountWidth + noOfResellersWidth

export const getTableScrollWidth = (regionList: string[] = []) => {
    return tabWidth + optionsWidth + categoryWidth + regionList.length * regionWidth + arrowWidth
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    isCompetitorView = true,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        isCompetitorView ? 'Vendors' : 'Categories',	
        'Options',	
        '# +/- %',	
        isCompetitorView ? 'Category' : 'Vendor',	
        'Germany Average Price', '',
        'Germany Discount width', '',
        'Germany No of sellers', '',
    ]
    data.push(titleRow)

    const handleRowData = (item) => {
        return [
            isCompetitorView ? item?.vendorName : item?.categoryName,
            numberUtils.formatNumber(item?.Numbers?.value, { decimal: 0 }),
            numberUtils.formatNumber(item?.Numbers?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            isCompetitorView ? item?.categoryName : item?.vendorName,
            numberUtils.formatNumber(item?.Germany_average_price?.value, { decimal: 0 }),
            numberUtils.formatNumber(item?.Germany_average_price?.change_percent, { isCentuple: true,isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.Germany_discount_width?.value, { isCentuple: true,isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.Germany_discount_width?.change, { isCentuple: true,isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.Germany_shops_count?.value, { decimal: 0 }),
            numberUtils.formatNumber(item?.Germany_shops_count?.change_percent, { isCentuple: true,isPercentSymbol: true, decimal: 1 }),
        ]
    }

    dataSource.forEach(item => {
        const dataRow = handleRowData(item)
        data.push(dataRow)

        if (item?.children?.length) {
            item.children.forEach(item2 => {
                const dataRow2 = handleRowData(item2)
                data.push(dataRow2)

                if (item2?.children?.length) {
                    item2.children.forEach(item3 => {
                        const dataRow3 = handleRowData(item3)
                        data.push(dataRow3)
                    })
                }
            })
        }
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
