import React, { useState } from 'react'
import { Input } from 'components/form-elements/Input'
import { Checkbox } from 'components/form-elements/Checkbox'
import { Button } from 'components/form-elements/Button'
import { Form } from 'components/form-elements/Form'
import { Label } from 'components/form-elements/Label'
import { Text } from 'components/typography/Text'
import AuthToken from 'utils/AuthToken'
import { Message } from 'components/form-elements/Message'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { useAuthenticationDataContext } from 'components/guards/AuthenticationContext'
import { SignInFormProps } from './types'

import styles from './styles.module.scss'

export const SignInForm = ({ onSignIn, error }: SignInFormProps) => {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ shouldKeepUserSigned, setShouldKeepUserSigned ]: any = useState(true)
    const { onLogin, onForgotPassword }: any = useAuthenticationDataContext()
    const [ validateError, setValidateError ] = useState(false)

    return (
        <Form
            onSubmit={() => {
                if (!email || !password) {
                    setValidateError(true)
                    return
                } 
                setValidateError(false)
                AuthToken.setShouldKeepUserSigned(shouldKeepUserSigned)
                onLogin(onSignIn, email, password)
                localStorage.setItem('hotjar_id', email)
            }}
        >
            <ShouldRender shouldRender={error || validateError}>
                <Message message="Please enter valid email address and password." error />
            </ShouldRender>
            <div className={styles['sign-in-inputs']}>
                <Label required label="Email">
                    <Input
                        value={email}
                        notAllowPaste
                        onChange={setEmail}
                    />
                </Label>
                <div>
                    <Label required label="Password">
                        <Input
                            type="password" value={password}
                            onChange={setPassword}
                            notAllowPaste
                        />
                    </Label>

                    <div className={styles['secondary-cta']}>
                        <Checkbox
                            label="Keep me signed in"
                            isChecked={shouldKeepUserSigned}
                            onChange={() => setShouldKeepUserSigned(!shouldKeepUserSigned)}
                        >
                            Keep me signed in
                        </Checkbox>

                        <div className={styles['forgot-password']} onClick={onForgotPassword} >
                            <Text small accent>
                                Forgot your password?
                            </Text>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles['sign-in-button']}>
                <Button primary type="submit">
                    SIGN IN
                </Button>
            </div>
        </Form>
    )
}
