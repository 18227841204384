import React, { useState } from 'react'
import styles from './UpdatePwdDialog.module.scss'
import { useUpdatePwd } from 'graphql/nornaapi'
import { Form, Input } from 'antd'
import { Button } from 'componentsv2/Button'

const UpdatePwdDialog = () => {
    const [ form ] = Form.useForm()
    const { updatePwd, loading } = useUpdatePwd()
    const [ errorText, setErrorText ] = useState('')

    const onSubmit = async () => {
        const { password, passwordConfirm } = form.getFieldsValue()
        if (!password || !passwordConfirm || password !== passwordConfirm) {
            return
        }
        const result = await updatePwd(password)
        if (!result?.data?.success) {
            setErrorText(result?.data?.result?.error || '')
            return
        }
        setErrorText('')
        hideUpdatePwdDialog()
    }
    
    return (
        <dialog id="updatePwdDialog" className={styles.dialog}>
            <Form
                className={styles.form}
                form={form}
                colon={false}
                layout="vertical"
            >
                <Form.Item
                    label="New password"
                    name="password"
                    rules={ [ { required: true, message: 'please input 8-30 length password' } ] }
                >
                    <Input.Password 
                        className={styles.input}
                        placeholder="New password"
                    />
                </Form.Item>
                <Form.Item
                    label="Confirm new password"
                    name="passwordConfirm"
                    rules={ [ { required: true, message: 'please input 8-30 length password' } ] }
                >
                    <Input.Password
                        className={styles.input}
                        placeholder="Confirm new password"
                    />
                </Form.Item>
                <Form.Item
                    validateStatus={errorText ? 'error' : 'success'}
                    hasFeedback
                    help={errorText}
                >
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={onSubmit}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </dialog>
    )
}

export default UpdatePwdDialog

export const showUpdatePwdDialog = () => {
    const dialog = document.querySelector('#updatePwdDialog') as HTMLDialogElement
    dialog?.showModal()
}

export const hideUpdatePwdDialog = () => {
    const dialog = document.querySelector('#updatePwdDialog') as HTMLDialogElement
    dialog?.close()
}
