import React, { FC, memo, useLayoutEffect, useState } from 'react'
import classnames from 'classnames'
import { throttle } from 'lodash'
import { whetherOpenInNew } from 'componentsv2/business/OpenInNewButton'
import { useFilterDisplayHeight } from 'hooks'
import { useWindowWidth } from 'hooks/useWindow'
import { StickyHeaderProps } from './types'
import styles from './styles.module.scss'
import { TopScrollbar } from 'norna-uikit'

/**
 * ## StickyHeader
 * 
 * 实现逻辑：
 * 
 * 01. 监听 document 的滚动事件(scroll)，当滚动条顶部位置(scrollTop)大于 610 时，渲染 StickyHeader 组件
 * 当 scrollTop 小于 610 时，移除 StickyHeader 组件 <==== 这一步放在调用 StickyHeader 组件的地方写
 * 
 * 02. CompareStickyHeader 组件使用 fixed 布局实现吸附功能
 * 
 * 03. js 事件处理外层容器 (wrapper) 和 CompareStickyHeader 组件两个容器的水平滚动条位置保持同步
 */
export const StickyHeader: FC<StickyHeaderProps> = memo(({
    children,
    scrollTop = 610,
    scrollBottom,
    selector,
    extraTop=0,
    zIndex = 101,
    innerWidth,
}) => {
    /**
     * 是否渲染 StickyHeader 组件
     */
    const [ showStickyHeader, setShowStickyHeader ] = useState(false)
    const [ outerWidth, setOuterWidth ] = useState('100%')
    const windowWidth = useWindowWidth()

    useLayoutEffect(() => {
        setTimeout(() => {
            const ele = document.querySelector(selector)
            if (!ele) return
            setOuterWidth(ele.clientWidth + 'px')
        }, 100)
    }, [ selector, windowWidth ])

    /**
     * 监听 document 的 scroll 事件
     * - 当滚动条顶部位置(scrollTop)大于 scrollTop 时，渲染 StickyHeader 组件
     * - 当滚动条顶部位置(scrollTop)小于 scrollTop 时，移除 StickyHeader 组件
     */
    useLayoutEffect(() => {
        const pageContainer = document.querySelector('.page-container')
        if (!pageContainer) return

        const onDocumentScroll = throttle(() => {
            const ele = document.querySelector(selector)
            if (!ele) return
            const top = pageContainer.scrollTop
            if (typeof scrollBottom === 'number' && top > scrollBottom) {
                setShowStickyHeader(false)
                return
            }
            setShowStickyHeader(top > scrollTop)
        }, 100)

        pageContainer.addEventListener('scroll', onDocumentScroll)

        return () => {
            pageContainer.removeEventListener('scroll', onDocumentScroll)
        }
        // eslint-disable-next-line
    }, [scrollTop, windowWidth])

    return (
        <StickHeaderContent
            className={showStickyHeader === true ? styles.show : styles.hide}
            outerWidth={outerWidth}
            selector={selector}
            zIndex={zIndex}
            extraTop={extraTop}
            innerWidth={innerWidth}
        >
            {children}
        </StickHeaderContent>
    )
})

const StickHeaderContent = ({
    children,
    outerWidth = '100%',
    zIndex = 101,
    style = {},
    extraTop = 0,
    className,
}: StickyHeaderProps) => {
    const stickTop = useFilterDisplayHeight()

    return (
        <TopScrollbar 
            className={classnames([ styles.stickyHeaderWrapper, className, 'sticky-header' ])} 
            style={{ width: outerWidth, zIndex, top: whetherOpenInNew() ? 0 : (stickTop + extraTop) + 'px', ...style }}
        >
            <div className={classnames([ styles.stickyHeader ])}>
                {children}
            </div>
        </TopScrollbar>
    )
}
