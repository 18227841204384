/**
* @file   LookbookTreeItem
* @date   2022-08-30
* @author byte_su@163.com
*/

import { TreeItem, TreeItemProps } from 'componentsv2/Tree'
import { DataType } from 'componentsv2/Tree/common'
import { updateFilterTags } from 'features/filters/globalDataSlice'
// import { getTreeVals } from 'componentsv2/Tree/utils';
import { useFlatQueryObj } from 'hooks'
import { useLookbook } from 'hooks/useLookbook'
import { LookbookType } from 'libs'
import { delay } from 'lodash'
import React, { forwardRef, useImperativeHandle, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setFilterCategory, setLastComparisonQueryForLookbook } from '../../../../../features/filters/filtersSlice'

interface LookbookTreeItemPropsType extends TreeItemProps {
  getVals?: Function;
  updateTreeData?: Function;
}
export const genLookbookFilterTreeItem = (list: Array<LookbookType>, checkedIds: Array<string>, initFilterFn: Function) => list.map((item, index) => ({
  label: item.name,
  val: item.id,
  // lookbook 如果没有产品, 设置为不可选
  disabled: !item.count,
  type: DataType.CHECK,
  active: checkedIds.includes(item.id),
  click() {
    initFilterFn?.(item)
  },
}))

export const LookbookTreeItem = forwardRef((props: LookbookTreeItemPropsType, ref) => {

  const dispatch = useDispatch()
  const { list: lookbookList, checked: checkedLookbook, setCheckedList } = useLookbook()
  const update = useCallback(() => { 
    dispatch(setLastComparisonQueryForLookbook(undefined))
    dispatch(updateFilterTags({}))
  }, [ dispatch ])

  const lookbookEvent = useCallback((lookbook: LookbookType) => {
    let checked: Array<any> = []
    if (checkedLookbook.includes(lookbook.id)) {
      checked = [ ...checkedLookbook ].filter(lookbookId => lookbookId !== lookbook.id)
      setCheckedList(checked)
    } else {
      checked = [ ...checkedLookbook, lookbook.id ]
      setCheckedList(checked)

    }
    props.setTreeItem?.({ active: !!checked.length })
    //  清空了lookbook, 此处延时执行Filter的恢复, 跳过Tree的Lookbook 切换到Analysis检查
    if (!checked.length) delay(update, 100)

  }, [ checkedLookbook, update, props.setTreeItem, setCheckedList ])

  const { comparisonQuery } = useFlatQueryObj()
  useImperativeHandle(ref, () => ({
    clearLookbook: () => setCheckedList([]),
  }), [ setCheckedList ])

  return (
    <TreeItem
      {...props}
      // eslint-disable-next-line react/no-children-prop
      children={genLookbookFilterTreeItem(lookbookList, checkedLookbook, (item: LookbookType) => {
        if (checkedLookbook.length === 0) {
          dispatch(setLastComparisonQueryForLookbook(comparisonQuery))
          dispatch(setFilterCategory([]))
        }
        lookbookEvent?.(item)
      })}
      enableSelectAll
      active={!!checkedLookbook.length}
      selectAllFn={() => {
        if (checkedLookbook.length) {
          props.setTreeItem?.({ active: false })
          delay(() => {
            dispatch(setLastComparisonQueryForLookbook(undefined))
            setCheckedList([])
          }, 100) // 此处延时执行Filter的恢复, 跳过Tree的Lookbook 切换到Analysis检查
        } else {
          // 选择 lookbook 前记住上一次 filter 选项
          dispatch(setLastComparisonQueryForLookbook(comparisonQuery))
          // 只勾选有产品的 lookbook
          setCheckedList(lookbookList.filter(item => item.count).map(item => item.id))
          // clear `Filter.Category`
          dispatch(setFilterCategory([]))
          props.setTreeItem?.({ active: !!lookbookList.map(item => item.id).length })
        }
      }}

    />
  )
})

LookbookTreeItem.displayName = 'LookbookTreeItem'

