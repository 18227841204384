import React, { memo } from 'react'
import { Dropdown, DropdownOptionProps } from 'componentsv2/form-elements/Dropdown'
import { useRegionOptions } from 'hooks/useOptions'
import { useFilterCompetitor, useFilterCountry, useFilterCurrency } from 'hooks/useFilter'
import { getCurrencyByRegion } from 'utils'
import { loadingBar } from 'hooks/useLoadingBar'
import { isDashboardPages } from 'global/Routes'
import { getAllFilterCompetitorValue } from 'utils/filterUtils'
import { RegionDropdownProps } from './types'

export const RegionDropdown = memo(({
    value,
    onChange,
    left,
    right,
    style,
    label='Country',
    className,
    selectorClassName,
    whetherUpdateCurrency = false,
    enableFilter = false,
    enableLoadingBar = false,
    disabled = false,
    enableFilterInDashboard = true,
}: RegionDropdownProps) => {
    // eslint-disable-next-line
    const [ filterCountry, setFilterCountry ] = useFilterCountry()
    // eslint-disable-next-line
    const [ _, setFilterCurrency ] = useFilterCurrency()
    // eslint-disable-next-line
    const [ __, setFilterCompetitor ] = useFilterCompetitor()
    const { regionOptions = [], defaultRegionValue } = useRegionOptions()

    return (
        <Dropdown
            style={style}
            label={label}
            className={className}
            selectorClassName={selectorClassName}
            left={left}
            right={right}
            options={regionOptions}
            title="Country"
            placeholder="Country"
            disabled={disabled}
            value={value || filterCountry || defaultRegionValue}
            onChange={value => {
                if (enableFilter) setFilterCountry(value as string)
                const selectedOption = regionOptions.find(item => item.key === value) as DropdownOptionProps
                onChange?.(value as string, selectedOption, regionOptions)
                if (whetherUpdateCurrency) {
                    // Also to change the currency
                    const currency = getCurrencyByRegion(value as string)
                    setFilterCurrency(currency)
                }
                if (isDashboardPages() && enableFilterInDashboard) {
                    setFilterCompetitor(getAllFilterCompetitorValue(value as string))
                }
                if (enableLoadingBar) loadingBar.restart()
            }}
        />
    )
})

RegionDropdown.displayName = 'RegionDropdown'
