import React from 'react'
import styles from 'components/form-elements/Input/styles.module.scss'
import { InputProps } from 'components/form-elements/Input/types'

export const Input = ({
                        name = '',
                        type = 'text',
                        value = '',
                        notAllowPaste = false,
                        onChange = e => 0,
                        onBlur = e => 0,
                        placeholder = '',
                        disabled = false,
                        maxLength = 9999,
                        autoComplete = 'on',
                        onEnterKey = e => {
                        },
                        max,
                        min,
                        sty = {},
                      }: InputProps) =>
  <input
    autoComplete={autoComplete}
    className={styles.input}
    style={sty}
    name={name}
    type={type}
    value={value}
    onChange={e => onChange(e.target.value)}
    onBlur={e => onBlur(e.target.value)}
    onKeyDown={event => onEnterKey && onEnterKey(event)}
    placeholder={placeholder}
    onPaste={e => window.location.host.indexOf('host') === -1 && notAllowPaste && e.preventDefault()}
    disabled={disabled}
    maxLength={maxLength}
    max={max}
    min={min}
  />

export const InputUi = (props: InputProps) => <Input
  {...props} sty={{
  backgroundColor: '#FAFAF9',
  padding: '0 15px',
  height: '45px',
  border: '1px dashed #EC7765',
  boxSizing: ' border-box',
  borderRadius: ' 4px',
  marginBottom: '40px',
  ...props.sty || {},
}}
                                              />
