import { numberUtils } from 'norna-uikit'
import { getVendorNameByCode } from 'utils'

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    switchValue = false,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        'Vendor',
        'Material',
        'Split %',
        '+/- % Units',
        'Average price',
        '+/- %',
    ]
    data.push(titleRow)

    dataSource.forEach(item => {
        const list = switchValue ? item?.unitList : item?.percentList
        const vendorName = getVendorNameByCode(item?.seller?.vendor)
        
        list.forEach(item2 => {
            const d = [
                vendorName,
                item2?.name.replace(',', ' '),
                switchValue ? item2?.countCurrentValue?.replace(',', '') : item2?.percentCurrentValue,
                switchValue ? item2?.countChangePercentage : item2?.percentChange,
                numberUtils.formatNumber(item2?.averagePriceCurrentValue, { decimal: 0 }),
                item2?.averagePriceChangePercentage,
            ]
            data.push(d)
        })
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
