/**
 * 常见下拉框数据源
 * 
 * - useCompetitorOptions
 * - useRegionOptions
 * - useCurrencyOptions
 * - useCategoryOptions
 */

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useSellers } from 'graphql/sellers/getSellers'
import { selectMarket, selectFilterCountry, selectFilterCurrency } from 'features/filters/filtersSlice'
import { genRegionsFn, getCacheData } from 'features/filters/cacheDataSlice'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { ALL_COUNTRIES, CATEGORY_TOTAL, IS_LIVE_ENV, SELECTED_VENDORS } from 'consts'
import { CompetitorOption, PureSellerType } from 'types'
import { sortVendorList } from 'utils/array'
import { useCustomerVendor } from 'hooks'
import { RoutesObj } from 'global/Routes'
import { uniqBy } from 'lodash'

/**
 * ## useCompetitorOptions
 * 
 * Competitor 下拉框数据源
 * 
 * ## 使用
 * 
 * ### 基础使用
 * 
 * ```
 * const { competitorOptions } = useCompetitorOptions()
 * ```
 * 
 * ### 根据 region 过滤数据
 * 
 * ```
 * const { regionOptions, defaultRegionValue } = useRegionOptions()
 * const [ regionValue, setRegionValue ] = useState(defaultRegionValue)
 * 
 * // 只要 region 值改变，competitorOptions 值会自动根据 region 重新生成
 * const { competitorOptions } = useCompetitorOptions({ region: regionValue })
 * 
 * <Dropdown 
 *      options={regionOptions}
 *      value={regionValue}
 *      onChange={value => setRegionValue(value)}
 * />
 * ```
 * 
 * ### 不包含 Market 选项
 * 
 * ```
 * const { competitorOptions } = useCompetitorOptions({ excludeMarket: true })
 * ```
 * 
 * ### 不包含自己选项
 * 
 * ```
 * const { competitorOptions } = useCompetitorOptions({ excludeSelf: true })
 * ```
 */
export const useCompetitorOptions = (opt?: { region?: string; excludeMarket?: boolean; excludeSelf?: boolean; excludeSelectedVendor?: boolean; excludeVendorCodeList?: string[] }): { competitorOptions: CompetitorOption[]; defaultCompetitorValue: string; } => {
    const { ourSeller } = useSelector(getCacheData)
    // vendor 名称映射对象
    const { sellers, filterVendorEuropeOrCountry } = useSellers([])
    
    // 右上角 Filter 选中 Country 值，{ key: 'Sweden', currency: 'SEK }
    const filterCountry: { key?: string; currency?: string; } = useSelector(selectMarket)

    // Competitor 下拉框数据源
    const [ competitorOptions, setCompetitorOptions ] = useState<CompetitorOption[]>([])

    useDeepCompareEffect(() => {
        if (!opt?.region && !filterCountry?.key) {
            setCompetitorOptions([])
            return
        }

        const competitors:Array<PureSellerType> = filterVendorEuropeOrCountry(opt?.region || filterCountry.key || ourSeller.region)
        let competitorsData:Array<CompetitorOption> = []
        competitorsData = competitors.map(item => ({
            key: item.key,
            description: item.description,
            region: item.parent,
            vendor: item.key,
        } as CompetitorOption))

        // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/777
        if (opt?.excludeMarket === true || IS_LIVE_ENV) {
            competitorsData = competitorsData.filter(item => item.key !== 'Market')
        }

        if (opt?.excludeSelf === true) {
            competitorsData = competitorsData.filter(item => item.key !== ourSeller?.vendor)
        }

        if (!opt?.excludeSelectedVendor) {
            competitorsData.push({
                key: SELECTED_VENDORS,
                description: SELECTED_VENDORS,
                region: ALL_COUNTRIES,
                vendor: SELECTED_VENDORS,
            } as CompetitorOption)
        }

        if (opt?.excludeVendorCodeList?.length) {
            competitorsData = competitorsData.filter(item => !opt.excludeVendorCodeList?.includes(item.key))
        }

        competitorsData = uniqBy(competitorsData, 'key')

        competitorsData = sortVendorList({
            vendorList: competitorsData,
            vendorField: 'key',
        })

        setCompetitorOptions(competitorsData)
    }, [ sellers, opt, filterCountry ])

    return {
        competitorOptions,
        defaultCompetitorValue: ourSeller?.vendor || '',
    }
}

interface OptionItemProps {
    key: string;
    description: string;
    isDisabled?: boolean;
}

/**
 * ## useRegionOptions
 * 
 * Region 下拉框数据源
 */
export const useRegionOptions = (): { regionOptions: OptionItemProps[]; defaultRegionValue: string } => {
    const customerVendor = useCustomerVendor()
    const filterCountry = useSelector(selectFilterCountry)
    const { regions, unregions, ourSeller } = useSelector(getCacheData)
    const regionOptions = genRegionsFn([ ...regions, ...unregions ], regions)
    /**
     * 2022/07/26 
     * bimbalola license 的 cross region pricing 页面的 region 硬编码添加一个 El Palacio de Hierro 选项
     */
    if (customerVendor === 'bimbaylola' && window.location.pathname === RoutesObj.PRICING_JUMP_TABLE.URL) {
        regionOptions.push({
            key: 'El Palacio de Hierro',
            description: 'El Palacio de Hierro',
        })
    }
    return {
        regionOptions,
        defaultRegionValue: filterCountry || ourSeller?.region || '',
    }
}

/**
 * ## useCurrencyOptions
 * 
 * Currency 下拉框数据源
 */
export const useCurrencyOptions = (): { currencyOptions: OptionItemProps[]; defaultCurrencyValue: string; } => {
    const filterCurrency = useSelector(selectFilterCurrency)
    const { currencys, uncurrencys, ourSeller } = useSelector(getCacheData)

    const currencyOptions = [
        ...currencys.map(item => ({ key: item, description: item })),
        ...uncurrencys.map(item => ({ key: item, isDisabled: true, description: item, name: item })),
    ]

    return {
        currencyOptions,
        defaultCurrencyValue: filterCurrency || ourSeller?.currency || '',
    }
}

/**
 * ## useCategoryOptions
 * 
 * Category 下拉框数据源，一级分类
 * 
 * ## 使用
 * 
 * ### 不包含 Total 选项
 * 
 * 默认 categoryOptions 是包含 Total 选项的，可以设置不包含。
 * 
 * ``` 
 * const { categoryOptions } = useCategoryOptions({ excludeTotal: true })
 * ```
 */
export const useCategoryOptions = (opt?: { excludeTotal?: boolean }): { categoryOptions: OptionItemProps[]; defaultCategoryValue: string; } => {
    const { metaCategories } = useSelector(getCacheData)

    const categoryList = metaCategories.list.map(item => item.name)
    if (opt?.excludeTotal !== true) {
        categoryList.push(CATEGORY_TOTAL)
    }

    let defaultCategoryValue = CATEGORY_TOTAL
    if (opt?.excludeTotal === true && categoryList?.length) {
        defaultCategoryValue = categoryList[0] || ''
    }
    
    return {
        categoryOptions: categoryList.map(item => ({ key: item, description: item })),
        defaultCategoryValue,
    }
}
