/**
 * vendor + region 组合不存在时显示锁图标, 测试: bimbalola + UAE
 * 
 * 1. 普通 vendor。vendor + region 不存在时显示锁图标
 * 2. Selected vendors。比较当前选择的 vendors, 全部不存在时才显示锁图标, 有一个存在就不显示锁图标
 * 3. All vendors。比较全部 vendors, 全部不存在时才显示锁图标, 有一个存在就不显示锁图标
 * 
 * Sold out analytics 需要比较 vendor_region 和 category 两个维度, 逻辑稍有不同
 * 1. Selected categories, 有一个存在一个不存在时显示 not allowed 图标
 * 2. Selected categories, 所有都不存在时显示 lock 图标
 */

import { mergePropertyBySelectedCategories } from 'components/layout/Header/components/HeaderFilter/property.util'
import { storage } from './storage'
import { ALL_VENDORS, MARKET_VENDOR, SELECTED_VENDORS, SUMMARY } from 'consts'

/**
 * 获取 Filter > Property > Color 列表数据
 * 
 * Color split 页面使用
 */
export function getFilterColorList() {
    const originalProperties = storage.getProperties() as any
    const firstLevelCategoryList = getFilterCategoryFirstLevelList()
    const properties = mergePropertyBySelectedCategories({ selectedCategories: firstLevelCategoryList, originalProperties })
    const colorList = properties?.Colors?.single_values || []
    return colorList
}

/**
 * 获取 Filter > Property > Material 列表数据
 * 
 * Material split 页面使用
 */
export function getFilterMaterialList() {
    const originalProperties = storage.getProperties() as any
    const firstLevelCategoryList = getFilterCategoryFirstLevelList()
    const properties = mergePropertyBySelectedCategories({ selectedCategories: firstLevelCategoryList, originalProperties })
    const materialList = Object.keys(properties?.Material?.first_level_grouped_values || {})
    return materialList
}

/**
 * 获取 Filter > Category 一级列表数据
 */
export function getFilterCategoryFirstLevelList() {
    const categoryTreeList = storage.getCategoryTreeList()
    const categoryFirstLevelList = categoryTreeList.map(c => c.name)
    return categoryFirstLevelList
}

/**
 * 判断 seller 是否存在
 */
function whetherSellerExist({
    vendorCode,
    region,
    sellers,
}: {
    vendorCode: string;
    region: string;
    sellers?: any[];
}) {
    const newSellers = sellers || storage.getSellers()
    const isExist = !!newSellers.find(s => s.launch_date && s.vendor === vendorCode && s.region === region)
    return isExist
}

/**
 * 判断是否显示 Lock 图标
 */
export function whetherLock({
    vendorCode,
    region,
    sellers,
    selectedVendorCodeList = [],
}: {
    vendorCode: string;
    region: string;
    sellers?: any[];
    selectedVendorCodeList?: string[];
}): boolean {
    if (storage.getIsLookbook()) return false

    const newSellers = sellers || storage.getSellers()

    // Selected vendors 根据选中的 vendors 来判断
    if ([ SELECTED_VENDORS, SUMMARY ].includes(vendorCode)) {
        const vendorCodeList = selectedVendorCodeList
            .filter(v => ![ SELECTED_VENDORS, ALL_VENDORS, MARKET_VENDOR.vendor, SUMMARY ].includes(v))
            
        const inexistList = vendorCodeList
            .filter(v => {
                const isExist = whetherSellerExist({ vendorCode: v, region, sellers: newSellers })
                return !isExist
            })
        return inexistList.length === vendorCodeList.length
    }

    // All vendors 要根据所有 vendors 来判断
    if ([ ALL_VENDORS, MARKET_VENDOR.vendor ].includes(vendorCode)) {
        const vendorCodeList = newSellers
            .filter(s => s.region === region)
            .map(s => s.vendor)
        const inexistList = vendorCodeList
            .filter(v => {
                const isExist = whetherSellerExist({ vendorCode: v, region, sellers: newSellers })
                return !isExist
            })
        return inexistList.length === vendorCodeList.length
    }

    // vendorCode 不存在时才显示锁图标
    return !whetherSellerExist({ vendorCode, region, sellers })
}
