export const width01 = 76
export const width02 = 80

export const tabWidth = 155
export const optionsWidth = 115
export const categoryWidth = 180

export const inValueWidth = width01
export const inChangePercentWidth = width02
export const avgPriceValueWidth = width01
export const avgPriceChangePercentWidth = width02
export const outValueWidth = width01
export const outChangePercentWidth = width02
export const rotationValueWidth = width01
export const rotationChangeWidth = width02
export const priceSpreadWidth = 300

export const inWidth = inValueWidth + inChangePercentWidth
export const avgPriceWidth = avgPriceValueWidth + avgPriceChangePercentWidth
export const outWidth = outValueWidth + outChangePercentWidth
export const rotationWidth = rotationValueWidth + rotationChangeWidth
