import React, { useEffect, useRef, useState } from 'react'
import { NoCategory } from 'components'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ProductsModal } from 'components/ProductsModalV2'
import { Spin } from 'componentsv2'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { ShowWhenOpenInNew, HideWhenOpenInNew, OpenInNewButton } from 'componentsv2/business/OpenInNewButton'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { Dropdown } from 'componentsv2/form-elements/Dropdown'
import { Switch } from 'componentsv2/form-elements/Switch'
import { LookbookWithoutData } from 'componentsv2/LookbookPlaceholder'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { SizedBox } from 'componentsv2/SizedBox'
import { NA, OPEN_IN_NEW_DATE, OPTIONS_NAME } from 'consts'
import { downloadFileByAxios } from 'export'
import { RoutesObj } from 'global/Routes'
import { useExcludeProductsComparisonQuery, useUrlParams } from 'hooks'
import useDialogTitle from 'hooks/useDialogTitle'
import { useCategoryRequiredText } from 'hooks/useFilterRequiredText'
import { useLookbook } from 'hooks/useLookbook'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { usePageDate } from 'hooks/usePageDate'
import { cloneDeep } from 'lodash'
import { SplitPieChart } from 'componentsv2/business/SplitPieChart'
import { SplitPieList } from 'componentsv2/business/SplitPieList'
import { vendorShortMappingFn, formatBrandVendor, regionShortMappingFn, getVendorNameByCode } from 'utils'
import { formatDateStr2 } from 'utils/dateUtils'
import { formatVendorName } from 'utils/sellers'
import { COLOR_SPLIT_PIE_GRAPH_NAME } from 'configs'
import { useSplitPieGraphData } from 'hooks/api/useSplitPieGraphData'
import { useModuleData } from 'hooks/useModuleData'
import { useFindProductIds } from 'hooks/api/useFindProductIds'
import { colorSplitExportFilename } from 'utils/exportUtils'
import { handleIsLock, handleQueryDateValid, handleVendorSort } from 'utils/pageUtils'
import { useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { useCompetitorOptions } from 'hooks/useOptions'
import { COLOR_SPLIT_PAGE_CATEGORY_EMPTY_TEXT } from 'configs/filterCategory'
import { geneCsvData, handleApiData, handleMultiColor } from './utils'
import styles from './styles.module.scss'
import GraphButton from 'componentsv2/GraphButton'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_COLOR_SPLIT } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'

export const ColorSplitPieGraph = () => {
    const { fnUseAppendDialogTitlePriceFilter } = useDialogTitle()
    const { pageDate: dateRangeValue } = usePageDate()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCompetitor ] = useFilterCompetitor()
    const { competitorOptions } = useCompetitorOptions()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const filterRequiredCategories = useCategoryRequiredText(COLOR_SPLIT_PAGE_CATEGORY_EMPTY_TEXT)

    const [ moduleData, setModuleData ] = useModuleData(COLOR_SPLIT_PIE_GRAPH_NAME)
    const {
        fetchPayload = {}, 
        apiData = [],
        newCompetitorValue = [],
        colorRangeValue = 'All colors',
        switchValue = false,
    } = moduleData

    /* ************************************ Lookbook ******************************************* */
    const { isLookbook } = useLookbook()
    const { judgeActionOccurs, clearLookbookActions } = useLookbookAction(true, true)
    const [ lookbookWithoutData, setLookbookWithoutData ] = useState(false)

    /* ***************************** Open in new *************************************** */
    const urlParams = useUrlParams()

    useEffect(() => {
        if (typeof urlParams?.pieColorRangeValue !== 'undefined') {
            setColorRangeValue(urlParams?.pieColorRangeValue)
        }
        if (typeof urlParams?.pieSwitchValue !== 'undefined') {
            setSwitchValue(urlParams.pieSwitchValue === 'true')
        }
        // eslint-disable-next-line
    }, [])

    /* ********************************** Export excel **************************************** */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        payload.data.groups = getGroupsPayload({ competitorSellers: filterCompetitor })
        payload.data.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
        payload.query.units = switchValue
        payload.query.only_solid = colorRangeValue !== 'All colors'
        await downloadFileByAxios({
            filename: colorSplitExportFilename,
            payload,
        })
    }

    /* ********************************** Form **************************************** */
    const colorRangeOptions = [ 'All colors', 'Solids' ].map(item => ({ key: item, description: item }))
    
    const setColorRangeValue = (value: string) => {
        setModuleData({ colorRangeValue: value })
    }

    // true show product count，false show product percent
    const setSwitchValue = (value: boolean) => {
        setModuleData({ switchValue: value })
    }

    /* ********************************** data **************************************** */
    const { data, loading, fetchData, setData } = useSplitPieGraphData({ moduleName: COLOR_SPLIT_PIE_GRAPH_NAME })
    const [ dataSource, setDataSource ] = useState<any[]>([])
    const [ , setCsvData ] = useCsvDataModuleData()

    const handleData = apiData => {
        setLookbookWithoutData(isLookbook && Array.isArray(apiData) && apiData.length === 0)
        if (!Array.isArray(apiData) || apiData.length === 0) return
        apiData = cloneDeep(apiData)
        
        apiData = handleQueryDateValid({ data: apiData, competitorValue: newCompetitorValue, filterCountry })
        apiData = handleIsLock({ data: apiData, competitorValue: newCompetitorValue, region: filterCountry })
        apiData = handleVendorSort({ data: apiData })

        const data = handleMultiColor({
            apiData: cloneDeep(apiData),
            multiColorVisible: colorRangeValue === 'All colors',
        })

        const pieData = handleApiData({
            apiData: cloneDeep(data),
        })

        setDataSource(cloneDeep(pieData))
        setCsvData({
            [CSV_DATA_COLOR_SPLIT]: geneCsvData({ dataSource: pieData, switchValue }),
        })
    }

    useEffect(() => {
        if (!data) return
        handleData(cloneDeep(data))
        // eslint-disable-next-line
    }, [data, colorRangeValue])

    /* ********************************** product detail modal **************************************** */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    const { fetchData: fetchProductIds } = useFindProductIds({ moduleName: COLOR_SPLIT_PIE_GRAPH_NAME })

    const onShowProductModal = async (item, seller) => {
        item.region = regionShortMappingFn(seller?.region === 'World' ? comparisonQuery?.customer_seller?.region : seller.region)
        item.vendor = vendorShortMappingFn(getVendorNameByCode(seller.vendor))
        
        let products: string[] = []
        if (item?.prod?.length) {
            products = [ ...item.prod ]
        } else {
            const result = await fetchProductIds({ seller })
            if (result?.error) return

            const newApiData = cloneDeep(apiData)
            newApiData.forEach(item => {
                if (item.seller.vendor === seller.vendor && item.seller.region === seller.region) {
                    item.bucket_assortments.forEach((categoryItem, index) => {
                        item.bucket_assortments[index].products = result.data[categoryItem.name] || []
                    })
                }
            })
            setData?.(newApiData)
            products = [ ...result.data[item.label] ]
        }

        if (!products?.length) return
        
        clearLookbookActions()
        const dialogId = `${RoutesObj.COLOR_ANALYTICS.LABEL}-${item.vendor}-${item.region}-${item.label}`
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog(dialogId, (
            <ProductsModal
                productUrls={products}
                onClose={() => {
                    closeFn(dialogId)
                    if (judgeActionOccurs()) fetchData()
                }}
                sortField={comparisonQuery?.collection?.query?.sortField}
                headerLeading={numberUtils.formatNumberByComma(products?.length)}
                headerTitle={fnUseAppendDialogTitlePriceFilter(`${item?.vendor}, ${item?.label} products, ${item?.region}`)}
                headerDate={formatDateStr2(dateRangeValue)}
            />
        ))
    }

    return (
        <div id="export-pie-wrapper">
            <ShowWhenOpenInNew>
                <FilterInfo />
            </ShowWhenOpenInNew>
            <ModuleTitle category="ASSORTMENT" title={COLOR_SPLIT_PIE_GRAPH_NAME} top={0} showComparisonDate />
            <SizedBox height={10} />
            <Spin spinning={loading} minHeight={700}>
                <ShouldRender shouldRender={lookbookWithoutData}>
                    <LookbookWithoutData />
                </ShouldRender>
                <ShouldRender shouldRender={!lookbookWithoutData}>
                    <div className={styles.filterGraphPie}>
                        <HideWhenOpenInNew>
                            <ExportDropdown
                                onExportExcel={onExportExcel}
                                selector="#export-pie-wrapper"
                                fileName={colorSplitExportFilename}
                            />
                            <div style={{ width: 10 }} />
                            <GraphButton />
                            <OpenInNewButton
                                left={10}
                                onPageParams={() => ({
                                    pieColorRangeValue: colorRangeValue,
                                    pieSwitchValue: switchValue,
                                    [OPEN_IN_NEW_DATE]: dateRangeValue,
                                })}
                            />
                        </HideWhenOpenInNew>
                        <div style={{ flex: 1 }} />
                        <HideWhenOpenInNew>
                            <Dropdown
                                options={colorRangeOptions}
                                value={colorRangeValue}
                                onChange={value => setColorRangeValue(value as 'Solids' | 'All colors')}
                                right={30}
                            />
                        </HideWhenOpenInNew>
                        <Switch
                            leftLabel="%"
                            rightLabel="#"
                            value={switchValue}
                            onChange={checked => setSwitchValue(checked)}
                        />
                    </div>
                    {
                        filterRequiredCategories ? (
                            <NoCategory>{filterRequiredCategories}</NoCategory>
                        ) : (
                            <div className={styles.perVendorContent}>
                                {dataSource.map((item: any, index: number) => {
                                    const name = vendorShortMappingFn(formatBrandVendor(item.seller?.brand, getVendorNameByCode(item.seller?.vendor)))
                                    const region = regionShortMappingFn(item.seller?.region === 'World' ? comparisonQuery?.customer_seller?.region : item.seller.region)
                                    const options = item.seller?.queryDateValid && !item.seller.isLock ? numberUtils.formatNumberByComma(item.num) : NA
                                    return (
                                        <div key={formatBrandVendor(item.seller?.brand, item?.seller?.vendor) || index.toString()}>
                                            <div className={styles.pieTitle}>
                                                <div>{formatVendorName(name).name}</div>
                                                <div style={{ fontSize: '12px', color: '#666', transform: 'scale(calc(11 / 12))' }}>{formatVendorName(name).platform} ({region})</div>
                                                <div>{OPTIONS_NAME}: {options}</div>
                                            </div>
                                            <div 
                                                onClick={e => e.stopPropagation()}
                                                onMouseLeave={e => {
                                                    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/769#note_1269972397
                                                    e.stopPropagation()
                                                }}
                                            >
                                                <SplitPieChart
                                                    dataSource={item.charts}
                                                    onElementsClick={(_, sectorIndex) => onShowProductModal(item.unitList[sectorIndex], item.seller)}
                                                    queryDateValid={item.seller.queryDateValid}
                                                    isLock={item.seller.isLock}
                                                    pieList={item.unitList}
                                                    vendorCode={item.seller.vendor}
                                                />
                                            </div>
                                            <SplitPieList
                                                dataSource={switchValue ? item.unitList : item.percentList}
                                                onItemClick={value => onShowProductModal(value, item.seller)}
                                                moduleName={COLOR_SPLIT_PIE_GRAPH_NAME}
                                                queryDateValid={item.seller.queryDateValid}
                                                isLock={item.seller.isLock}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                </ShouldRender>
            </Spin>
            <Dialog ref={dialogRef} />
        </div>
    )
}
