import { getDate, getMonth, getQuarter, getYear, subDays, subYears, startOfQuarter, lastDayOfQuarter } from 'date-fns'
import { formatDatePickerToDate } from 'utils/dateUtils'
import { lsGet } from 'utils/ls'

const today = () => {
    const datestr: string = lsGet('latestDate') as string
    return new Date(datestr as string)
}
const dayAgo = () => subDays(today(), 1)            // 一天前
const weekAgo = () => subDays(today(), 6)           // 一周前
const monthAgo = () => subDays(today(), 29)         // 一个月前
const quarterAgo = () => subDays(today(), 89)       // 一个季度前
const yearAgo = () => subYears(today(), 1)          // 一年前

export const todayRange = () => ({
    from: {
        day: getDate(today()),
        month: getMonth(today()) + 1,
        year: getYear(today()),
    },
    to: {
        day: getDate(today()),
        month: getMonth(today()) + 1,
        year: getYear(today()),
    },
})

export const yesterday = () => ({
    from: {
        day: getDate(dayAgo()),
        month: getMonth(dayAgo()) + 1,
        year: getYear(dayAgo()),
    },
    to: {
        day: getDate(today()),
        month: getMonth(today()) + 1,
        year: getYear(today()),
    },
})

export const getYesterdayDateRange = () => {
    const date = dayAgo()
    return {
        from: {
            day: getDate(date),
            month: getMonth(date) + 1,
            year: getYear(date),
        },
        to: {
            day: getDate(date),
            month: getMonth(date) + 1,
            year: getYear(date),
        },
    }
}

export const getTwoYesterdayDateRange = () => {
    const date = subDays(today(), 2)
    return {
        from: {
            day: getDate(date),
            month: getMonth(date) + 1,
            year: getYear(date),
        },
        to: {
            day: getDate(date),
            month: getMonth(date) + 1,
            year: getYear(date),
        },
    }
}

/**
 * 获取最近一周的日期值, 从今天起往前数 7 天
 */
export const getOneLatestWeekAgoDateRange = () => ({
    from: {
        day: getDate(weekAgo()),
        month: getMonth(weekAgo()) + 1,
        year: getYear(weekAgo()),
    },
    to: {
        day: getDate(today()),
        month: getMonth(today()) + 1,
        year: getYear(today()),
    },
})

/**
 * 获取上一个最近一周的日期值，从今天起往前数 7-14 天那一周
 */
export const getTwoLatestWeeksAgoDateRange = () => {
    const startDate = subDays(today(), 13)
    const endDate = subDays(today(), 7)
    return {
        from: {
            day: getDate(startDate),
            month: getMonth(startDate) + 1,
            year: getYear(startDate),
        },
        to: {
            day: getDate(endDate),
            month: getMonth(endDate) + 1,
            year: getYear(endDate),
        },
    }
}

export const getOneLatestMonthAgoDateRange = () => ({
    from: {
        day: getDate(monthAgo()),
        month: getMonth(monthAgo()) + 1,
        year: getYear(monthAgo()),
    },
    to: {
        day: getDate(today()),
        month: getMonth(today()) + 1,
        year: getYear(today()),
    },
})

export const getOneLatestQuarterAgoDateRange = () => ({
    from: {
        day: getDate(quarterAgo()),
        month: getMonth(quarterAgo()) + 1,
        year: getYear(quarterAgo()),
    },
    to: {
        day: getDate(today()),
        month: getMonth(today()) + 1,
        year: getYear(today()),
    },
})

export const getOneLatestYearAgoDateRange = () => ({
    from: {
        day: getDate(yearAgo()),
        month: getMonth(yearAgo()) + 1,
        year: getYear(yearAgo()),
    },
    to: {
        day: getDate(today()),
        month: getMonth(today()) + 1,
        year: getYear(today()),
    },
})

/**
 * 获取上一周日期值, 周一到周日
 * 
 * [latestDate] 日期对象，通过 new Date 创建的日期对象
 */
export const getOneWholeWeekAgoDateRange = (date = today()) => {
    let dayOfWeek = date.getDay()     // 星期几
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek
    const startDate = subDays(date, 6 + dayOfWeek)
    const endDate = subDays(date, dayOfWeek)
    return {
        from: {
            day: getDate(startDate),
            month: getMonth(startDate) + 1,
            year: getYear(startDate),
        },
        to: {
            day: getDate(endDate),
            month: getMonth(endDate) + 1,
            year: getYear(endDate),
        },
    }
}

/**
 * 获取上上周日期值, 周一到周日
 */
export const getTwoWholeWeeksAgoDateRange = (date = today()) => {
    const lastWeek = getOneWholeWeekAgoDateRange(date)
    const firstDayOfLastWeek = new Date(lastWeek.from.year, lastWeek.from.month - 1, lastWeek.from.day)
    const twoWeeksAgoDateRange = getOneWholeWeekAgoDateRange(firstDayOfLastWeek)
    return twoWeeksAgoDateRange
}

/**
 * 获取上一个完整月的日期值 
 */
export const getOneWholeMonthAgoDateRange = (date = today()) => {
    const dayOfMonth = date.getDate()    // 一个月第几号
    // 计算上个月有多少天
    const daysOfLastMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate()
    const startDate = subDays(date, daysOfLastMonth - 1 + dayOfMonth)
    const endDate = subDays(date, dayOfMonth)
    return {
        from: {
            day: getDate(startDate),
            month: getMonth(startDate) + 1,
            year: getYear(startDate),
        },
        to: {
            day: getDate(endDate),
            month: getMonth(endDate) + 1,
            year: getYear(endDate),
        },
    }
}

/**
 * 获取上上一个完整月的日期值
 */
export const getTwoWholeMonthsAgoDateRange = (date = today()) => {
    const lastMonth = getOneWholeMonthAgoDateRange(date)
    const firstDayOfLastMonth = new Date(lastMonth.from.year, lastMonth.from.month - 1, lastMonth.from.day)
    const twoMonthsAgoDateRange = getOneWholeMonthAgoDateRange(firstDayOfLastMonth)
    return twoMonthsAgoDateRange
}

/**
 * 获取上一季度的日期值
 */
export const getOneWholeQuarterAgoDateRange = (date = today()) => {
    const quarter = getQuarter(date)
    let dayOfLastQuarter
    switch (quarter) {
        case 1:
            dayOfLastQuarter = new Date(date.getFullYear() - 1, 10, 1)
            break
        case 2:
            // 0 表示 1 月，第一季度第一个月
            dayOfLastQuarter = new Date(date.getFullYear(), 0, 1)
            break
        case 3:
            // 3 表示 4 月，第二季度第一个月
            dayOfLastQuarter = new Date(date.getFullYear(), 3, 1)
            break
        case 4:
            // 6 表示 7 月，第三季度第一个月
            dayOfLastQuarter = new Date(date.getFullYear(), 6, 1)
            break
        default:
            dayOfLastQuarter = new Date()
            break
    }    
    const startDate = startOfQuarter(dayOfLastQuarter)
    const endDate = lastDayOfQuarter(dayOfLastQuarter)
    return {
        from: {
            day: getDate(startDate),
            month: getMonth(startDate) + 1,
            year: getYear(startDate),
        },
        to: {
            day: getDate(endDate),
            month: getMonth(endDate) + 1,
            year: getYear(endDate),
        },
    }
}

/**
 * 获取上上一个完整季度的日期值
 */
export const getTwoWholeQuartersAgoDateRange = (date = today()) => {
    const lastQuarter = getOneWholeQuarterAgoDateRange(date)
    const firstDayOfLastQuarter = new Date(lastQuarter.from.year, lastQuarter.from.month - 1, lastQuarter.from.day)
    const twoQuartersAgoDateRange = getOneWholeQuarterAgoDateRange(firstDayOfLastQuarter)
    return twoQuartersAgoDateRange
}

/**
 * 日期返回字符串转换为 DateRange 组件需要的对象
 * 
 * 参数：
 * ```
 * range = '2021-09-20_2021-09-21'
 * ```
 * 
 * 返回值：
 * ```
 * {
 *      from: {
 *          day: 20,
 *          month: 9,
 *          year: 2021,
 *      },
 *      to: {
 *          day: 21,
 *          month: 9,
 *          year: 2021,
 *      }
 * }
 * ```
 */
export const urlRange = (range: string) => {
    const rangeStr = range.split('_')
    const dateStart = new Date(rangeStr[0])
    const dateEnd = new Date(rangeStr[1])

    return {
        from: {
            day: getDate(dateStart),
            month: getMonth(dateStart) + 1,
            year: getYear(dateStart),
        },
        to: {
            day: getDate(dateEnd),
            month: getMonth(dateEnd) + 1,
            year: getYear(dateEnd),
        },
    }
}

/**
 * 根据 period（中文：一段时间。week、month、quarter）快速返回 DateRange 组件的 value 值
 * 
 * week 是最近一周，从今天开始算，往前推七天
 * 2022-01-04 是星期二，最近一周是 2021-12-29_2022-01-04
 * 
 * lastWeek 是上一周，上周一到上周日
 * 2022-01-04 是星期二，上一周是 2021-12-27_2022-01-02
 * 
 * getLatestWeek    从今天起往前数7天
 * getOneWholeWeekAgoDateRange     整周, 周一到周日
 * 
 * getTwoLatestWeeksAgo
 * getTwoWholeWeeksAgo
 */
export const getDateRangeValueByPeriod = (period: string): string => {
    const dateMapper = {
        day: () => yesterday(),
        today: () => todayRange(),
        yesterday: () => getYesterdayDateRange(),
        twoYesterday: () => getTwoYesterdayDateRange(),
        oneLatestWeekAgo: () => getOneLatestWeekAgoDateRange(),
        twoLatestWeeksAgo: () => getTwoLatestWeeksAgoDateRange(),
        oneLatestMonthAgo: () => getOneLatestMonthAgoDateRange(),
        oneLatestQuarterAgo: () => getOneLatestQuarterAgoDateRange(),
        oneLatestYearAgo: () => getOneLatestYearAgoDateRange(),
        oneWholeWeekAgo: () => getOneWholeWeekAgoDateRange(),
        twoWholeWeeksAgo: () => getTwoWholeWeeksAgoDateRange(),
        oneWholeMonthAgo: () => getOneWholeMonthAgoDateRange(),
        twoWholeMonthsAgo: () => getTwoWholeMonthsAgoDateRange(),
        oneWholeQuarterAgo: () => getOneWholeQuarterAgoDateRange(),
        twoWholeQuartersAgo: () => getTwoWholeQuartersAgoDateRange(),
    }
    const dateRange = dateMapper[period]?.()
    if (!dateRange?.from || !dateRange?.to) return ''
    return formatDatePickerToDate(dateRange.from) + '_' + formatDatePickerToDate(dateRange.to)
}

/**
 * 根据日期获取 period 下拉框值 
 */
export const getPeriodValueByDateRange = (date: string) => {
    const mapper = {
        [getDateRangeValueByPeriod('today')]: 'today',
        [getDateRangeValueByPeriod('oneLatestWeekAgo')]: 'oneLatestWeekAgo',
        [getDateRangeValueByPeriod('oneLatestMonthAgo')]: 'oneLatestMonthAgo',
        [getDateRangeValueByPeriod('oneLatestQuarterAgo')]: 'oneLatestQuarterAgo',
        [getDateRangeValueByPeriod('oneWholeWeekAgo')]: 'oneWholeWeekAgo',
        [getDateRangeValueByPeriod('oneWholeMonthAgo')]: 'oneWholeMonthAgo',
        [getDateRangeValueByPeriod('oneWholeQuarterAgo')]: 'oneWholeQuarterAgo',
    }
    return mapper[date] || 'none'
}

/**
 * 字符串转对象
 * 
 * value 格式如下：
 * '2021-11-05_2021-11-05'
 * 
 * parseDateRangePickerValue(value) => { from: { year: 2021, month: 11, day: 5 }, to: { year: 2021, month: 11, day: 5 } }
 */
export const parseDateRangePickerValue = value => {
    const rangeStr = value.split('_')
    const dateStart = new Date(rangeStr[0])
    const dateEnd = new Date(rangeStr[1])

    return {
        from: {
            day: getDate(dateStart),
            month: getMonth(dateStart) + 1,
            year: getYear(dateStart),
        },
        to: {
            day: getDate(dateEnd),
            month: getMonth(dateEnd) + 1,
            year: getYear(dateEnd),
        },
    }
}

/**
 * 对象转字符串
 * 
 * value 的格式如下：
 * value = { from: { year: 2021, month: 11, day: 5 }, to: { year: 2021, month: 11, day: 5 } }
 * 
 * stringifyDatePickerValue(value) =>  '2021-11-05_2021-11-05'
 */
export const stringifyDateRangePickerValue = value => formatDatePickerToDate(value.from) + '_' + formatDatePickerToDate(value.to)
