/**
 * request url constant
 */
export const REQUEST_LOGIN = '/token'
export const REQUEST_ACCOUNT = '/account'
export const REQUEST_TARGET_REGION_CURRECY = '/dashboard_get_target_group_region_currency/'
export const REQUEST_MY_LOOKBOOK = '/myLookbooks'
export const REQUEST_QA_PERMISSION = '/qa/fe_authentication/'
export const REQUEST_FIND_ACCOUNT_LICENSE = '/findAccountAllCompany'
export const REQUEST_TOGGLE_ACCOUNT = '/toggleAccountCompany'
export const REQUEST_UPDATE_ACCOUNT = '/updateAccount'
export const REQUEST_COLLECTIONS = '/collections'
export const REQUEST_LOOKBOOK = '/lookbook'
export const REQUEST_GET_LOOKBOOK_PRODUCT_INFO = '/getLookbookProductInfo'
export const REQUEST_UPDATE_PWD = '/updatePassword'
export const REQUEST_GET_LOOKBOOK_DETAIL_PRODUCTS = '/getLookbookDetailProducts'
export const REQUEST_LOOKBOOKS = '/lookbooks'
export const REQUEST_MY_LOOKBOOKS = '/myLookbooks'
export const REQUEST_LATEST_COLLECTION = '/latestCollection'
export const REQUEST_COLLECTION = '/collection'
export const REQUEST_COMPARISON = 'comparison'
export const REQUEST_RESET_PASSWORD = '/resetPassword'
export const REQUEST_COMPANIES = '/companies'
export const REQUEST_LOOKBOOK_ADD_PRODUCT = '/addLookbookProduct'
export const REQUEST_LOOKBOOK_REMOVE_PRODUCT = '/removeLookbookProduct'

