import React from 'react'
import { Button } from 'components/form-elements/Button'
import styles from './styles.module.scss'

export const DropdownFooterButton = ({
    onOk,
}: {
    onOk?: () => void;
}) => {
    return (
        <Button
            success
            disabled={false}
            className={styles.okButton}
            onClick={() => {
                onOk?.()
            }}
        >
            OK
        </Button>
    )
}
