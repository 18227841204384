import React from 'react'
import { FormProps } from './types'

export const Form = ({ children, onSubmit }: FormProps) =>
    <form
        onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
    >
        {children}
    </form>
