import { CloseIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { Input } from 'components/form-elements/Input'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import React, { useState } from 'react'
import { Dragable } from 'components/common/Dragable'
import { Message } from 'components/form-elements/Message'
import { useCollectionList } from 'hooks/api/useCollectionData'
import styles from './styles.module.scss'

export const ModifyCollectionModal = ({
  name = '',
  closeModal,
  onSave,
  isOpen,
  modalMaskStyle = {},
}) => {
  const { collectionList = [] } = useCollectionList()
  const [ filterName, setFilterName ] = useState(name)
  const [ errMsg, setErrMsg ] = useState('')

  const onChange = (value: string) => {
    const othersName = collectionList.map(c => c?.name).filter(c => c).filter(c => c !== name) || []
    const isIncludeName = othersName?.length && othersName.includes(value)
    setErrMsg(isIncludeName ? 'Filter name already exist.' : '')
    setFilterName(value)
  }

  const onOk = async () => {
    closeModal()
    if (filterName === name) return
    await onSave?.(filterName)
  }

  return (
    <ShouldRender shouldRender={isOpen}>
      <div
        className={styles.modal} 
        onClick={e => {
          e.stopPropagation()
          closeModal()
        }} 
        style={modalMaskStyle}
      />
      <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
        <Dragable 
          width={420} 
          height={220} 
          x={-210} 
          y={-110}
        >
          <div
            style={{ height: errMsg ? '230px' : '200px' }}
            className={styles.modalWrapper}
          >
            <div className={styles.modalHeader} style={{ cursor: 'move' }}>
              <div className={styles.modalTitle}>
                <Text medium>{ name ? 'Update' : 'Save' } filter { name ? 'name' : '' }</Text>
              </div>
              <CloseIcon onClick={() => closeModal()} />
            </div>
            <div className={styles.modalBody} onMouseDown={e => e.stopPropagation()}>
              <div className={styles.modalContent}>
                <div className={styles.lookbookName}>
                  <Input
                    value={filterName}
                    onChange={onChange}
                    maxLength={50}
                    placeholder="Name"
                  />
                </div>
              </div>
            </div>
            <ShouldRender shouldRender={Boolean(errMsg)}>
              <Message message={errMsg} error />
            </ShouldRender>
            <div className={styles.modalFooter} onMouseDown={e => e.stopPropagation()}>
              <Button secondary miniLarge onClick={() => closeModal()}>
                &nbsp;&nbsp;Back
              </Button>
              <Button primary mini onClick={() => onOk()}>
                &nbsp;&nbsp;Save
              </Button>
            </div>
          </div>
        </Dragable>
      </div>
    </ShouldRender>
  )
}
