import { useState } from 'react'
import { useFetch } from 'libs'
import { useDispatch, useSelector } from 'react-redux'
import { getCollectionList, updateCollectionList } from 'features/filters/collectionDataSlice'

export function useCreateCollection() {
    const { postFn } = useFetch()

    const createCollection = async ({
        name,
        query = {},
        sellers = [],
    }) => {
        await postFn('/collection', {
            query: { name },
            data: {
                query,
                sellers,
            },
        })
    }

    return {
        createCollection,
    }
}

export function useRemoveCollection() {
    const { delFn } = useFetch()

    const removeCollection = async (id: string) => {
        await delFn('/collection', { query: { filter_id: id } })
    }

    return {
        removeCollection,
    }
}

export function useModifyCollection() {
    const { patchFn } = useFetch()

    const modifyCollection = async ({
        id,
        name,
    }: {
        id: string;
        name?: string;
    }) => {
        await patchFn('/collection', { query: { filter_id: id, name }, data: {} })
    }

    return {
        modifyCollection,
    }
}

export function useCollectionList() {
    const { getFn } = useFetch()
    const [ loading, setLoading ] = useState(false)

    const dispatch = useDispatch()
    const collectionList = useSelector(getCollectionList)

    const setCollectionList = (value: any[]) => {
        dispatch(updateCollectionList(value))
    }

    const refreshCollectionList = async () => {
        setLoading(true)
        const result = await getFn('/collections')
        const data = result?.data?.result
        if (!Array.isArray(data)) return
        setCollectionList(data)
        setLoading(false)
    }

    return {
        collectionList,
        setCollectionList,
        refreshCollectionList,
        loading,
    }
}
