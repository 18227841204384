import { CloseIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { LazyBgImg } from 'components/Image'
import { Input } from 'components/form-elements/Input'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import React, { useState } from 'react'
import { Textarea } from 'components/form-elements/Textarea'
import { Message } from 'components/form-elements/Message'
import classnames from 'classnames'
import { EditLookbookModalProps } from './types'
import styles from './styles.module.scss'

export const EditLookbookModal = ({
  closeModal,
  onSave,
  isOpen,
  data,
}: EditLookbookModalProps) => {

  const [ selectedLookbook, setSelectedLookbook ] = useState(data)
  const [ validateEditLookbook, setValidateEditLookbook ] = useState(false)

  const changeLookbookName = (event: any) => {
    setSelectedLookbook({ ...selectedLookbook, name: event })
  }

  const changeLookbookDesc = (event: any) => {
    setSelectedLookbook({ ...selectedLookbook, desc: event })
  }

  const onEnterKey = event => {
    if (event.key === 'Enter') {
      updateLookbook()
    }
  }

  const updateLookbook = () => {
    if (selectedLookbook.name && selectedLookbook.name.trim() !== '') {
      onSave(selectedLookbook)
    } else {
      setValidateEditLookbook(true)
    }
  }

  const getModalContent = () => 
      <>
          <div className={styles.modalHeader}>
              <div className={styles.modalTitle}>
                  <Text medium>Edit lookbook</Text>
              </div>
              <CloseIcon onClick={() => closeModal()} />
          </div>
          <div className={styles.modalBody}>
              <div className={styles.imageWrap}>
                <LazyBgImg 
                  src={data?.images?.[0] || ''}
                  width={104}
                  height={130}
                />
              </div>
              <div className={styles.modalContent}>
                  <div className={styles.lookbookName}>
                      <Input
                          value={selectedLookbook?.name}
                          onChange={changeLookbookName}
                          maxLength={50}
                          onEnterKey={onEnterKey}
                      />
                  </div>
                  <div className={styles.lookbookDesc}>
                      <Textarea 
                          value={selectedLookbook?.desc}
                          onChange={changeLookbookDesc}
                          maxLength={500}
                      />
                  </div>
              </div>
          </div>
          <ShouldRender shouldRender={validateEditLookbook}>
              <Message message="Please enter valid name" error />
          </ShouldRender>
          <div className={styles.modalFooter}>
              <Button secondary miniLarge onClick={() => closeModal()}>
                  &nbsp;&nbsp;Back
              </Button>
              <Button primary mini onClick={() => updateLookbook()}>
                  &nbsp;&nbsp;Save
              </Button>
          </div>
      </>

  return (
      <ShouldRender shouldRender={isOpen}>
          <div className={styles.modal} onClick={() => closeModal()} />
          <div className={styles.modalContainer}>
              <div className={classnames(
          styles.modalWrapper,
          validateEditLookbook && styles.increaseHeight)}
              >{getModalContent()}
              </div>
          </div>
      </ShouldRender>
  )
}
