import { ALL_VENDORS, BRAND_VENDOR_SPLITOR, CATEGORY_TOTAL, MARKET_VENDOR, SELECTED_CATEGORY, SELECTED_VENDORS, SUMMARY } from 'consts/'
import { SellerType } from 'types'

/**
 *  sort  vendors by alphalbet and set Market the last one
 * @param  sellers  Array<{vendor:'',region:''}>
 * @returns 
 */
export const sortSeller = sellers => {

  const marketIndex = sellers.findIndex(item => item.vendor === MARKET_VENDOR.vendor)
  if (marketIndex > -1) {
    return [
      ...sortVendorWithBrandObject([ ...sellers.slice(0, marketIndex), ...sellers.slice(marketIndex + 1) ], 'vendor'),
      MARKET_VENDOR,
    ]
  }
  return sortVendorWithBrandObject(sellers, 'vendor')

}

/** *
 * 
 * @param Array<`Brand@Vendor`|'Vendor'>
 * 
 */
export function sortVendorWithBrandSellerType(list: Array<SellerType>) {
  const selectedVendors = list.find(item => item.vendor === SELECTED_VENDORS)
  const marketVendor = list.find(item => item.vendor === MARKET_VENDOR.vendor)
  const sortedSellers = sortVendorWithBrandObject(list.filter(item => item.vendor !== SELECTED_VENDORS && item.vendor !== MARKET_VENDOR.vendor), 'vendor')
  if (selectedVendors) sortedSellers.push(selectedVendors)
  if (marketVendor) sortedSellers.push(marketVendor)

  return sortedSellers
}
export function sortVendorWithBrandObject(list: Array<any>, field = '') {
  // if(!field) throw new Error('Please pass valid `field` argument');

  return list.sort((a: any, b: any) => {
    let sortA = field ? a[field] : a; let sortB = field ? b[field] : ''
    if (sortA.indexOf(BRAND_VENDOR_SPLITOR) > -1) sortA = [ sortA.split(BRAND_VENDOR_SPLITOR)[1].trim(), sortA.split(BRAND_VENDOR_SPLITOR)[0].trim() ].join(BRAND_VENDOR_SPLITOR)
    if (sortB.indexOf(BRAND_VENDOR_SPLITOR) > -1) sortB = [ sortB.split(BRAND_VENDOR_SPLITOR)[1].trim(), sortB.split(BRAND_VENDOR_SPLITOR)[0].trim() ].join(BRAND_VENDOR_SPLITOR)
    if (sortA > sortB) return 1
    if (sortA < sortB) return -1
    return 0
  })
}
export function sortVendorWithBrandSellerString(list: Array<string>) {
  return list.sort((a: string, b: string) => {
    let sortA = a; let sortB = b
    if (sortA.indexOf(BRAND_VENDOR_SPLITOR) > -1) sortA = [ sortA.split(BRAND_VENDOR_SPLITOR)[1], sortA.split(BRAND_VENDOR_SPLITOR)[0] ].join(BRAND_VENDOR_SPLITOR)
    if (sortB.indexOf(BRAND_VENDOR_SPLITOR) > -1) sortB = [ sortB.split(BRAND_VENDOR_SPLITOR)[1], sortB.split(BRAND_VENDOR_SPLITOR)[0] ].join(BRAND_VENDOR_SPLITOR)
    if (sortA > sortB) return 1
    if (sortA < sortB) return -1
    return 0
  })
}
/**
 *  ## 包含`SELECTED_VENDORS`,`ALL_VENDORS`的 vendor 字符数组排序
 * 
 * @param vendors   
 * @returns 
 */
export function sortVendorWithBrandSellerStringV2(vendors: Array<string>) {
  let existSelected = false
  let existAll = false
  
  const sortedList = sortVendorWithBrandSellerString(vendors).filter(vendor => {
    if (vendor === ALL_VENDORS || vendor === MARKET_VENDOR.vendor) {
      existAll = true
      return false
    }
    if (vendor === SELECTED_VENDORS) {
      existSelected = true
      return false
    }
    return true
  })
  if (existSelected) sortedList.push(SELECTED_VENDORS)
  if (existAll) sortedList.push(ALL_VENDORS)
  return sortedList
}

/**
 * 2022/08/08
 * vendor 显示方式更新了, 每个vendor都需要两行显示, 第一行是vendor名字, 第二行是平台名字(全大写)
 * 
 * ```
 * // 包含 @ 符号的给拆开, @ 前面的是 vendor 名称, @ 后面的是平台名称
 * formatVendorName('Bogner Sport@Bogner')   => {'name': 'Bogner Sport', 'platform': '@ BOGNER'}
 * // 不包含 @ 符号的, vendor 名称和平台名称都是它自身
 * formatVnedorName('Arcteryx')  => {'name': 'Arcteryx', 'platform': '@ ARCTERYX'}
 * ```
 */
export function formatVendorName(name = ''): { name: string; platform: string; fullName: string } {
  const nameArr = name.split('@')
  let platform = ''
  if (nameArr.length === 1) {
    // eslint-disable-next-line
    platform = nameArr[0]
  } else {
    // eslint-disable-next-line
    platform = nameArr[1]
  }
  /**
   * 2022/08/16 
   * All vendors 和 Selected vendors 不显示 platform
   */
  if ([ ALL_VENDORS, SELECTED_VENDORS, SUMMARY, SELECTED_CATEGORY, CATEGORY_TOTAL ].includes(name)) {
    platform = ''
  }
  if (platform) {
    platform = `@ ${platform.toUpperCase()}`
  }
  return { name: nameArr[0], platform, fullName: `${nameArr[0]} ${platform}` }
}
