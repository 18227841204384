import React, { useEffect, useRef, useState } from 'react'
import { Flex } from 'componentsv2/Flex'
import { TabItemWithChildProps } from 'componentsv2/TradingTable/types'
import { CATEGORY_TOTAL, OPTIONS_IN_NAME, OPTIONS_NAME, OPTIONS_OUT_NAME, SELECTED_CATEGORY } from 'consts'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { cloneDeep } from 'lodash'
import classnames from 'classnames'
import { Spin } from 'componentsv2'
import { Matrics, NornaTab, NornaTable, SubTh, TableCategoryCell, TableCell, TableCellWithSymbol, TableOptionsCell, Th } from 'componentsv2/TradingTableV2'
import { SizedBox } from 'componentsv2/SizedBox'
import { useLoadingBar } from 'hooks/useLoadingBar'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { IN_OUT_MONITORING_TABLE_NAME } from 'configs'
import { useDashboardComparison } from 'hooks/api/useDashboardTableData'
import { appendDialogTitlePriceFilter, getExportId, getFractionDigits } from 'utils'
import { DashboardTableForm } from 'componentsv2/business/DashboardTableForm'
import { useModuleData } from 'hooks/useModuleData'
import { ProductsModal } from 'components/ProductsModalV2'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterPricePerspective, useFilterSortBy } from 'hooks/useFilter'
import { formatDateStr2 } from 'utils/dateUtils'
import { usePageDate } from 'hooks/usePageDate'
import { useDashboardFetchInOutProducts } from 'hooks/api/useDashboard'
import { useLookbook } from 'hooks/useLookbook'
import { getAllFilterCategoryValue } from 'utils/filterUtils'
import { getExpandedKeysByMetricTab, getSubExpandedKeysByMetricTab } from 'utils/dashboardPageUtils'
import styles from './styles.module.scss'
import * as config from './config'
import { geneCsvData, handleTableData } from './utils'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_IN_OUT_MONITORING } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'

const exportId = getExportId(IN_OUT_MONITORING_TABLE_NAME)

export const InOutMonitoringTable = () => {
  useLoadingBar()
  const [ filterPricePerspective ] = useFilterPricePerspective()
  const { pageDate } = usePageDate()
  const [ filterCountry ] = useFilterCountry()
  const [ filterCategory ] = useFilterCategory()
  const [ filterCompetitor ] = useFilterCompetitor()
  const [ filterSortBy ] = useFilterSortBy()
  const { isLookbook } = useLookbook()
  const expandedRowKeysRef = useRef<string[]>([])
  const subExpandedRowKeysRef = useRef<string[]>([])
  const [ , setRefresh ] = useState({})

  // eslint-disable-next-line
  const [ moduleData, setModuleData ] = useModuleData(IN_OUT_MONITORING_TABLE_NAME)
  const { isCompetitorView, switchLoading } = moduleData

  /* ******************************** 表格 *********************************** */
  const dialogRef = React.useRef<DialogRefType>({} as DialogRefType)

  /* ************************************************************ */
  const { fetchData: fetchInOutProductData } = useDashboardFetchInOutProducts()

  const onShowInOutProductModal = async (record: any, type = OPTIONS_IN_NAME) => {
    const categoryName = record.categoryName
    const vendorCode = record.vendorCode
    const vendorName = record.vendorName
    const metric = type === OPTIONS_IN_NAME ? 'Options in' : 'Options out'
    const ids = await fetchInOutProductData({ category: categoryName, competitor: vendorCode, metric })
    if (!ids.length) return
    const dialogId = `${type}-${vendorCode}-${categoryName}`
    const closeFn = dialogRef.current?.closeDialog
    const title = [ vendorName, categoryName, type ].filter(item => item).join(', ')
    dialogRef.current?.openDialog(dialogId, (
        <ProductsModal
            productUrls={ids}
            onClose={() => closeFn(dialogId)}
            headerLeading={numberUtils.formatNumberByComma(ids.length)}
            headerTitle={`${appendDialogTitlePriceFilter(title, filterPricePerspective)}`}
            headerDate={formatDateStr2(pageDate, true)}
        />
    ))
  }

  const columns: any[] = [
    {
      dataIndex: 'options',
      width: config.optionsWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const options = numberUtils.formatNumber(record?.Numbers?.value, { isCommaSymbol: true, decimal: 0 })
        const changePercent = numberUtils.formatNumber(record?.Numbers?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })
        return (
          <TableOptionsCell
            text={options}
            changePercent={changePercent}
            record={record}
            width={config.optionsWidth}
            metricName="All"
            isDeepBorderRight
          />
        )
      },
    },
    {
      dataIndex: 'categoryKey',
      width: config.categoryWidth,
      render: (text, record) => (
        <TableCategoryCell
          text={isCompetitorView ? record.categoryName : record.vendorName}
          width={config.categoryWidth}
          isCompetitorView={isCompetitorView}
          isDeepBorderRight
        />
      ),
    },
    {
      dataIndex: 'inValue',
      width: config.inValueWidth + config.inChangePercentWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const isDisableClick = filterSortBy?.sortField === 'seller' && [ CATEGORY_TOTAL, SELECTED_CATEGORY ].includes(record?.categoryName)
        const value = numberUtils.formatNumber(record?.products_in?.value, { decimal: 0, isCommaSymbol: true })
        const inChangePercentValue = numberUtils.formatNumber(record?.products_in?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })

        return (
          <TableCell
            width={config.inValueWidth + config.inChangePercentWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell
              isFirstMetric
              text={value} 
              record={record}
              width={config.inValueWidth}
              isClickable={record?.products_in?.value && !isDisableClick}
              onClick={async () => {
                await onShowInOutProductModal(record, OPTIONS_IN_NAME)
              }}
            />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={inChangePercentValue} record={record} width={config.inChangePercentWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'avgPriceValue',
      width: config.avgPriceValueWidth + config.avgPriceChangePercentWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.avg_price?.value, { decimal: getFractionDigits(), isCommaSymbol: true })
        const avgPriceChangePercentValue = numberUtils.formatNumber(record?.avg_price?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })

        return (
          <TableCell
            width={config.avgPriceValueWidth + config.avgPriceChangePercentWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.avgPriceValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={avgPriceChangePercentValue} record={record} width={config.avgPriceChangePercentWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'outValue',
      width: config.outValueWidth + config.outChangePercentWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const isDisableClick = filterSortBy?.sortField === 'seller' && [ CATEGORY_TOTAL, SELECTED_CATEGORY ].includes(record?.categoryName)
        const value = numberUtils.formatNumber(record?.products_out?.value, { decimal: 0, isCommaSymbol: true })
        const outChangePercentValue = numberUtils.formatNumber(record?.products_out?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })

        return (
          <TableCell
            width={config.outValueWidth + config.outChangePercentWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell
              text={value} 
              record={record} 
              width={config.outValueWidth}
              isClickable={record?.products_out?.value && !isDisableClick}
              onClick={async () => {
                await onShowInOutProductModal(record, OPTIONS_OUT_NAME)
              }}
            />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={outChangePercentValue} record={record} width={config.outChangePercentWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'rotationValue',
      width: config.rotationValueWidth + config.rotationChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.rotation?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const rotationChangeValue = numberUtils.formatNumber(record?.rotation?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.rotationValueWidth + config.rotationChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.rotationValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={rotationChangeValue} record={record} width={config.rotationChangeWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'priceSpread',
      width: config.priceSpreadWidth,
      render: (_, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell />
          )
        }

        if (!record.queryDateValid) {
          return (
            <TableCell>
              {/* {NA} */}
            </TableCell>
          )
        }
        return (
          <Matrics
            max={record?.max_price || 0}
            min={record?.min_price || 0}
            avg={record?.avg_price?.value || 0}
            len={record?.maxOfMaxField || 0}
          />
        )
      },
    },
  ]

  /* ******************************************************************* */
  const { data, loading } = useDashboardComparison({ moduleName: IN_OUT_MONITORING_TABLE_NAME })

  const [ metricsTab1, setMetricsTab1 ] = useState<TabItemWithChildProps[]>([])
  const [ tabledata1, setTabledata1 ] = useState<Array<any>>([])

  const [ metricsTab2, setMetricsTab2 ] = useState<TabItemWithChildProps[]>([])
  const [ tabledata2, setTabledata2 ] = useState<Array<any>>([])
  const [ , setCsvData ] = useCsvDataModuleData()

  const handleData = (data: any) => {
    // 数据不存在直接返回
    if (!data) {
      return {}
    }

    if (isCompetitorView) {
      const {
        tabledata: tabledata1,
        metricsTab: metricsTab1,
      } = handleTableData({
        apiData: data,
        isCompetitorView: true,
        competitorValue: filterCompetitor.map(seller => seller.vendor),
        categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
        expandedRowKeys: expandedRowKeysRef.current,
        subExpandedRowKeysRef: subExpandedRowKeysRef.current,
        region: filterCountry,
      })
      setTabledata1(tabledata1)
      setMetricsTab1(metricsTab1)
      setCsvData({
        [CSV_DATA_IN_OUT_MONITORING]: geneCsvData({ dataSource: tabledata1 }),
      })
      return
    }
    
    const {
      tabledata: tabledata2,
      metricsTab: metricsTab2,
    } = handleTableData({
      apiData: data,
      isCompetitorView: false,
      competitorValue: filterCompetitor.map(seller => seller.vendor),
      categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
      expandedRowKeys: expandedRowKeysRef.current,
      subExpandedRowKeysRef: subExpandedRowKeysRef.current,
      region: filterCountry,
    })
    
    setTabledata2(tabledata2)
    setMetricsTab2(metricsTab2)
    setCsvData({
      [CSV_DATA_IN_OUT_MONITORING]: geneCsvData({ dataSource: tabledata2, isCompetitorView: false }),
    })
  }

  useDeepCompareEffect(() => {
    if (!data) return

    handleData(cloneDeep(data))
  }, [ data, filterCompetitor, isCompetitorView, expandedRowKeysRef.current, subExpandedRowKeysRef.current, {} ])

  /* ************************ 表格展开项 ************************** */
  useDeepCompareEffect(() => {
    if (expandedRowKeysRef.current?.length) {
      const firstLevelCategory = getFirstLevelCategories({ selectedCategories: filterCategory })
      firstLevelCategory.push(SELECTED_CATEGORY)
      const vendorList = filterCompetitor.map(f => f.vendor)
      let expandedRowKeys = expandedRowKeysRef.current
      expandedRowKeys = expandedRowKeys.filter(item => {
        const code = item.split('_')[0]
        if (isCompetitorView) {
          return vendorList.includes(code)
        }
        return firstLevelCategory.includes(code)
      })

      expandedRowKeysRef.current = [ ...expandedRowKeys ]
      setRefresh({})
    }
    if (subExpandedRowKeysRef.current?.length) {
      let subExpandedRowKeys = subExpandedRowKeysRef.current
      subExpandedRowKeys = subExpandedRowKeys.filter(item => {
        const arr = item.split('__')
        if (arr.length === 1) {
          return true
        }
        return filterCategory.includes(arr[1])
      })

      subExpandedRowKeysRef.current = [ ...subExpandedRowKeys ]
      setRefresh({})
    }
    // eslint-disable-next-line
  }, [filterCategory, filterCompetitor, isCompetitorView])

  return (
    <div id={exportId}>
      <ModuleTitle
        type="Dashboard"
        title={IN_OUT_MONITORING_TABLE_NAME}
        showComparisonDate
      />
      <SizedBox height={10} />
      <Dialog ref={dialogRef} />
      <Spin spinning={loading}>
        <DashboardTableForm moduleName={IN_OUT_MONITORING_TABLE_NAME} />
        <SizedBox height={20} />
        <Spin spinning={switchLoading} className={classnames([ 'norna-container-fixed-width' ])} style={{ width: '1440px' }}>
          <Flex className={styles.title}>
            <div style={{ width: config.tabWidth + 'px', textAlign: 'center' }}>
              {isCompetitorView ? 'Vendors' : 'Categories'}
            </div>
            <Th title={OPTIONS_NAME} width={config.optionsWidth} style={{ paddingLeft: '5px' }} />
            <Th title={ isCompetitorView ? 'Category' : 'Vendor' } width={config.categoryWidth} style={{ paddingLeft: '14px' }} />
            <Th title={OPTIONS_IN_NAME} width={config.inWidth} />
            <Th title="Average price In" width={config.avgPriceWidth} />
            <Th title={OPTIONS_OUT_NAME} width={config.outWidth} />
            <Th title="Rotation" width={config.rotationWidth} />
          </Flex>
          <Flex className={styles.subtitle}>
            <SubTh width={config.tabWidth} />
            <SubTh width={config.optionsWidth} innerWidth={43} align="right" showComparisonTooltip showCount showSymbol showPercent style={{ paddingRight: '5px', display: 'flex', justifyContent: 'flex-end' }} />
            <SubTh width={config.categoryWidth} />
            <SubTh width={config.inValueWidth} showCount />
            <SubTh width={config.inChangePercentWidth} innerWidth={33} showComparisonTooltip showSymbol showPercent />
            <SubTh width={config.avgPriceValueWidth} showCurrency />
            <SubTh width={config.avgPriceChangePercentWidth} innerWidth={60} showComparisonTooltip showCurrency showSymbol showPercent />
            <SubTh width={config.outValueWidth} showCount />
            <SubTh width={config.outChangePercentWidth} innerWidth={33} showComparisonTooltip showSymbol showPercent />
            <SubTh width={config.rotationValueWidth} showPercent />
            <SubTh width={config.rotationChangeWidth} innerWidth={64} showComparisonTooltip showUnit showSymbol showPercent />
            <SubTh width={config.priceSpreadWidth}>
              <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '10px', marginRight: '6px', background: '#ec7765' }} /> Average price products in
            </SubTh>
          </Flex>
          {
            isCompetitorView ? (
              <CompetitorView
                columns={columns}
                data={tabledata1}
                tab={metricsTab1}
                setMetricsTab={setMetricsTab1}
                onTabChange={(keys, subKeys) => {
                  expandedRowKeysRef.current = keys
                  subExpandedRowKeysRef.current = subKeys
                }}
              />
            ) : (
              <CategoryView
                columns={columns}
                data={tabledata2}
                tab={metricsTab2}
                setMetricsTab={setMetricsTab2}
                onTabChange={(keys, subKeys) => {
                  expandedRowKeysRef.current = keys
                  subExpandedRowKeysRef.current = subKeys
                }}
              />
            )
          }
        </Spin>
      </Spin>
      <div style={{ width: 1, height: 70, background: 'transparent' }} />
    </div>
  )
}

export const CompetitorView = ({
  columns,
  data,
  tab,
  setMetricsTab,
  onTabChange,
}) => {
  const [ cateHeight, setCateHeight ] = useState(0)
  const [ expendHeight, setExpendHeight ] = useState(0)
  const [ closeHeight, setCloseHeight ] = useState(0)

  const metricTabFn = a => {
    const metricsTab1: Array<TabItemWithChildProps> = a || tab
    const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
    let closeHeightA = closeHeight
    if (exHeight.length >= 1) {
      if (exHeight[0].getBoundingClientRect().height < 10) {
        closeHeightA = exHeight[0].getBoundingClientRect().height
      }
    }
    let index = metricsTab1.findIndex(n => n.active === true)
    setCateHeight(43)
    let subIndex = 0
    if (index > -1) {
      metricsTab1[index].children.filter(n => n.active).forEach(item => {
        subIndex += item.categorycount
      })
    }
    index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
    subIndex += index
    setExpendHeight((subIndex + 1) * 43)
    setCloseHeight(closeHeightA)
    setMetricsTab(metricsTab1)

    const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
    onTabChange?.(expandedKeys, [])
  }

  useEffect(() => {
    metricTabFn(null)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.metricsContent}>
      <NornaTab
        showHeader={false}

        categoryToVendor={true}
        metricsTab={tab}
        cateHeight={cateHeight}
        expendHeight={expendHeight}
        metricTabFn={metricTabFn}
        isDashboard={true}
      />
      <NornaTable
        showHeader={false}
        dataSource={data}
        columns={columns}

        isTd={true}
        metricTabFn={metricTabFn}
        metricsTab={tab}
        categoryToVendor={true}
        key="competitorview"
      />
    </div>
  )
}

export const CategoryView = ({
  columns,
  data,
  tab,
  setMetricsTab,
  onTabChange,
}) => {
  const [ cateHeight, setCateHeight ] = useState(0)
  const [ expendHeight, setExpendHeight ] = useState(0)
  const [ closeHeight, setCloseHeight ] = useState(0)

  const metricTabFn = a => {
    const metricsTab1: Array<TabItemWithChildProps> = a || tab
    const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
    let closeHeightA = closeHeight
    if (exHeight.length >= 1) {
      if (exHeight[0].getBoundingClientRect().height < 10) {
        closeHeightA = exHeight[0].getBoundingClientRect().height
      }
    }
    let index = metricsTab1.findIndex(n => n.active === true)
    setCateHeight(43)
    let subIndex = 0
    if (index > -1) {
      metricsTab1[index].children.filter(n => n.active).forEach(item => {
        subIndex += item.categorycount
      })
    }
    index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
    subIndex += index
    setExpendHeight((subIndex + 1) * 43)
    setCloseHeight(closeHeightA)
    setMetricsTab(metricsTab1)

    const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
    const subExpandedKeys = getSubExpandedKeysByMetricTab(metricsTab1)
    onTabChange?.(expandedKeys, subExpandedKeys)
  }

  useEffect(() => {
    metricTabFn(null)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.metricsContent}>
      <NornaTab
        showHeader={false}

        categoryToVendor={false}
        metricsTab={tab}
        cateHeight={cateHeight}
        expendHeight={expendHeight}
        metricTabFn={metricTabFn}
        isDashboard={true}
      />
      <NornaTable
        showHeader={false}
        dataSource={data}
        columns={columns}

        isTd={true}
        metricTabFn={metricTabFn}
        metricsTab={tab}
        categoryToVendor={false}
        key="categoryview"
      />
    </div>
  )
}
