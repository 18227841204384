import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { usePlatformAnalyticsModuleData } from 'features/filters/moduleDataSlice.hook'
import { ClickAwayListener, MoreOutlined, MultiSelect, SelectOption, Spin } from 'druikit'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getVendorNameByCode } from 'utils'
import FinancialChart, { FinancialChartRefProps } from 'componentsv2/FinancialChart'
import { usePageDate } from 'hooks/usePageDate'
import { usePlatformAnalyticsGraphApiData } from '../hooks/usePlatformAnalytics'
import styles from './LineGraph.module.scss'
import { cloneDeep } from 'lodash'
import { SELECTED_VENDORS } from 'consts'
import { useFilterCountry } from 'hooks/useFilter'
import { numberUtils } from 'norna-uikit'

const LineGraph = () => {
    usePlatformAnalyticsGraphApiData()
    const { pageDate } = usePageDate()
    const [ filterCountry ] = useFilterCountry()

    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const {
        graphMetricValue,
        graphApiData,
        graphApiLoading,
        rankingApiLoading,
        shopList,
    } = moduleData

    const chartRef = useRef<FinancialChartRefProps>(null)
    const [ chartData, setChartData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        if (!Object.keys(graphApiData || {}).length) return
        const chartData = graphMetricValue.map(metric => {
            const vendorData = graphApiData[SELECTED_VENDORS]
            const dateList = Object.keys(vendorData || {})
            const data = dateList.map(date => {
                return {
                    time: date,
                    value: vendorData?.[date]?.[`${metric} Sales Percent`] || 0,
                }
            })
            return {
                yAxisName: metric,
                label: metric,
                data,
            }
        })
        setChartData(chartData)
        setModuleData({ graphModalChartData: chartData })
    }, [ graphApiData, graphMetricValue, {} ])

    const onShowBigGraphModal = (e) => {
        if (graphApiLoading || rankingApiLoading || filterCountry !== 'Germany') return

        const tagName = (e.target as any)?.tagName

        if (tagName === 'CANVAS' || tagName === 'TABLE') {
            return
        }
        const priceScaleVisibleRange = chartRef.current?.getPriceScaleVisibleRange()
        const leftPriceScaleMaxRange = chartRef.current?.getLeftPriceScaleMaxRange()
        setModuleData({ 
            graphModalVisible: true,
            graphModalPriceScaleVisibleRange: priceScaleVisibleRange,
            graphModalPriceScaleMaxRange: leftPriceScaleMaxRange,
        })
    }

    const [ visible, setVisible ] = useState(false)

    return (
        <div onClick={onShowBigGraphModal}>
            <Spin className={styles.wrapper} spinning={graphApiLoading || rankingApiLoading} minHeight={200}>
                <div className={styles.form}>
                    <div className={styles.title}>
                        Seller marketplace share
                    </div>
                </div>
                <FinancialChart
                    ref={chartRef}
                    chartData={useMemo(() => filterCountry !== 'Germany' ? [] : cloneDeep(chartData), [ filterCountry, chartData ])}
                    defaultDateRange={pageDate}
                    formatYAxis={useCallback(value => {
                        return `${numberUtils.formatNumber(Math.abs(value), { isCentuple: true, decimal: 1, isPercentSymbol: true })}`
                    }, [])}
                    formatTooltipValue={useCallback(value => {
                        return `${numberUtils.formatNumber(value, { isCentuple: true, decimal: 1, isPercentSymbol: true })}`
                    }, [])}
                    formatStep={() => 0.001}
                    leftPriceScaleOptions={useMemo(() => ({
                        maxValue: 1.05,
                        scaleMargins: {
                            top: 0,
                            bottom: 0.05,
                        },
                    }), [])}
                    height={280}
                    showShortcutDate={false}
                    legendAfter={(
                        <div className={styles.moreWrapper}>
                            <div 
                                style={{ display: 'inline-block' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setVisible(!visible)
                                }}
                            >
                                <MoreOutlined size={16} color="#A0A0A0" />
                            </div>
                            {
                                visible ? (
                                    <ClickAwayListener
                                        onClickAway={() => setVisible(false)}
                                    >
                                        <div className={styles.dropdown} onClick={e => e.stopPropagation()}>
                                            <div className={styles.groupTitle}>Sellers</div>
                                            {
                                                shopList.map(v => {
                                                    return (
                                                        <SelectOption 
                                                            key={v}
                                                            boxVisible={true}
                                                            style={{ whiteSpace: 'nowrap' }}
                                                            value={graphMetricValue.includes(v)}
                                                            onChange={checked => {
                                                                chartRef.current?.updateVisibleTimeRange()
                                                                let newSelectedVendors = cloneDeep(graphMetricValue)
                                                                if (checked) {
                                                                    newSelectedVendors.push(v)
                                                                } else {
                                                                    newSelectedVendors = newSelectedVendors.filter(i => i !== v)
                                                                }
                                                                setModuleData({ graphMetricValue: [ ...newSelectedVendors ] })
                                                            }}
                                                        >
                                                            {v}
                                                        </SelectOption>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ClickAwayListener>
                                ) : null
                            }
                        </div> 
                    )}
                />
            </Spin>
        </div>
    )
}

export default memo(LineGraph)
