/**
* @file    Dragable
* @date   2022-01-25
* @author byte_su@163.com
*/

import React, { useEffect, useState } from 'react'
import { Rnd } from 'react-rnd'

/**
 * Dragable interface
 */
interface DragableType {
  children: React.ReactElement;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
}

/**
 *  Dragable component 
 * @param param0 
 * @returns 
 */
export const Dragable = ({ children, width = 200, height = 200,x=0,y=0 }: DragableType) => {
  const [ opt, setOpt ] = useState({
    width,
    height,
    x,
    y,
  })

  useEffect(() => {
    setOpt({
      width,
      height,
      x,
      y,
    })
  }, [ width, height, x, y ])

  return (
    <Rnd
      bounds=".root-child"
      size={{
        width: opt.width,
        height: opt.height,
      }}
      position={{
        x: opt.x,
        y: opt.y,
      }}
      style={{ transform: '1' }}
      onDragStop={(e, d) => {
        setOpt({ ...opt, x: d.x, y: d.y })
      }}
      onResize={(e, direction, ref, delta, position) => {
        setOpt({
          ...opt,
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          ...position,
        })
      }}
    >{children}
    </Rnd>
  )
}

Dragable.displayName = 'Dragable'
