import React, { useState } from 'react'
import { MetricNav } from '../MetricNav'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useApiData, useApiLoading, useDynamicDashboardRequests, usePovValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import { Flex } from 'druikit'
import { MetricCard, MetricLineChartCard, MetricProductCard } from '../MetricCard'
import { handleCardDataSource, handleLineChartData } from 'pages/DynamicDashboard/utils'
import { useFetchGraphData, usePricePerformanceProducts } from 'pages/DynamicDashboard/hooks/useApi'
import { MetricPriceSpreadChartCard } from '../MetricCard/MetricPriceSpreadCard'
import { cloneDeep } from 'lodash'
import { useFilterCurrency } from 'hooks/useFilter'
import { numberUtils } from 'norna-uikit'

export const ModalFullPricePerformance = () => {
    const [ apiData ] = useApiData()
    const [ apiLoading ] = useApiLoading()
    const [ povValue ] = usePovValue()
    const [ currency ] = useFilterCurrency()
    const [ cardDataSource, setCardDataSource ] = useState<any[]>([])
    const [ priceSpreadChartData, setPriceSpreadChartData ] = useState<any[]>([])
    const [ fullPriceLineChartData, setFullPriceLineChartData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const metricList = [ 'Full Lowest price', 'Full Average price', 'Full Highest price' ]
        const cardDataSource = handleCardDataSource({ apiData, metricList, currency, customerVendor: povValue })
        setCardDataSource([ ...cardDataSource ])

        const priceSpreadChartData = handlePriceSpreadChartData(cardDataSource)
        setPriceSpreadChartData([ ...priceSpreadChartData ])
    }, [ apiData, currency, povValue, {} ])

    const [ requests ] =useDynamicDashboardRequests()
    const {
        fullPriceLineGraphApiData: lineChartData,
        fullPriceLineGraphApiLoading: lineChartLoading,

        fullPriceProductsApiData: products,
        fullPriceProductsApiLoading: productsLoading,
    } = requests

    /* ************************** 线图 ******************************* */
    const metric = 'Full price performance'
    useFetchGraphData(metric)

    useDeepCompareEffect(() =>  {
        if (!lineChartData) return
        const discountEffectLineChartData = handleLineChartData(lineChartData, metric, povValue)
        setFullPriceLineChartData([ ...discountEffectLineChartData ])
    }, [ lineChartData, povValue, {} ])

    /* ************************** 产品卡片 ******************************* */
    usePricePerformanceProducts('full')

    return (
        <>
            <MetricNav
                name="Full price performance"
                metric="Full price performance"
            />
            <Flex justifyContent="space-between">
                {
                    cardDataSource.map(item => {
                        return (
                            <MetricCard
                                style={{ width: '32%' }}
                                loading={apiLoading}
                                key={item?.title || ''}
                                title={item?.title || ''}
                                dataSource={item?.dataSource || []}
                                showUnits={item.showUnits}
                            />
                        )
                    })
                }
            </Flex>
            <Flex justifyContent="space-between" id="dynamic-dashboard-middle-graph">
                <MetricPriceSpreadChartCard
                    style={{ width: '38%' }}
                    loading={apiLoading}
                    title="Full price spread"
                    dataSource={priceSpreadChartData}
                />
                <MetricLineChartCard
                    loading={lineChartLoading}
                    style={{ width: '60%' }}
                    yAxesMaxFixed
                    title="Full price ratio"
                    description="The percentage of options that are selling with full price."
                    dataSource={fullPriceLineChartData}
                    yAxisFormatter={value => numberUtils.formatNumber(value, { isCommaSymbol: true, isPercentSymbol: true, decimal: 1 })}
                    tooltipFormatter={value => numberUtils.formatNumber(value, { isCommaSymbol: true, isPercentSymbol: true, decimal: 1 })}
                />
            </Flex>
            <Flex justifyContent="space-between" flexWrap="wrap">
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} highest full price`}
                    dataSource={products.customerVendorMaximumPriceProducts || []}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} highest full price`}
                    dataSource={products.comparisonVendorMaximumPriceProducts || []}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} lowest full price`}
                    dataSource={products.customerVendorMinimumPriceProducts || []}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} lowest full price`}
                    dataSource={products.comparisonVendorMinimumPriceProducts || []}
                />
            </Flex>
        </>
    )
}

function handlePriceSpreadChartData(data) {
    let newData = cloneDeep(data)
    newData = newData.map(item => {
        return {
            label: item.title,
            lowestPrice: Number(item?.dataSource?.[0]?.value?.replace(',', '')),
            averagePrice: Number(item?.dataSource?.[1]?.value?.replace(',', '')),
            highestPrice: Number(item?.dataSource?.[2]?.value?.replace(',', '')),
        }
    })
    return newData
}
