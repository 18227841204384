import React, { useState } from 'react'
import classnames from 'classnames'
import { InlineLoading } from 'assets/icons/'
import styles from './styles.module.scss'
import { ButtonProps } from './types'

export const Button = ({
  children,
  onClick,
  primary,
  success,
  secondary,
  selected,
  onlyIcon,
  text,
  icon,
  mini,
  loadingSty = {},
  miniLarge,
  noShadow,
  disabled = false,
  hoverShadow,
  title,
  className,
  contentSty,
  style = {},
  rotateIcon45,
  loading = false,
  enableClickLoading = false,
}: ButtonProps) => {
  const [ loadingData, setLoading ] = useState(loading)
  return (
<button
    className={classnames(
      styles.button,
      primary && styles.primary,
      success && styles.success,
      secondary && styles.secondary,
      selected && styles.selected,
      icon && styles.withIcon,
      onlyIcon && styles.onlyIcon,
      text && styles.text,
      mini && styles.mini,
      noShadow && styles.noShadow,
      disabled && styles.disabled,
      loading && styles.disabled,
      miniLarge && styles.miniLarge,
      hoverShadow && styles.hoverShadow,
      rotateIcon45 && styles.rotateIcon45,
      className,
      // 'cell-mouse-effect-no-display-no-within-red-btn'
    )}
    onClick={async e => {
      if (!disabled && !loading) {
        if (enableClickLoading) {
          setLoading(true)
        }

        await onClick?.(e)

        if (enableClickLoading) {
          setLoading(false)
        }
      }
    }}
    disabled={disabled || loading}
    title={title || ''}
    style={style}
>
    {loading || (enableClickLoading && loadingData) ? <InlineLoading sty={{ padding: 0, display: 'flex', alignItems: 'center', marginRight: 10, ...loadingSty }} /> : <div className={styles.icon}>{icon}</div>}
    <div
      style={contentSty || {}}
      className={classnames(styles.content, disabled && styles.disabled, loading && styles.disabled, className)}
    >{children}
    </div>
</button>
)
}
  
