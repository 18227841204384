const width01 = 78
const width02 = 78

export const tabWidth = 148
export const categoryWidth = 190
export const arrowWidth = 40

export const soldOutValueWidth = width01
export const soldOutChangeWidth = width02
export const soldOutWidth = soldOutValueWidth + soldOutChangeWidth

export const getTableScrollWidth = (regionList: string[] = []) => {
    return tabWidth + categoryWidth + regionList.length * soldOutWidth + arrowWidth
}
