import React from 'react'
import { cloneDeep } from 'lodash'
import { TableCell } from './TableCell'
import { DisplayText } from 'druikit'
import { NA } from 'consts'

export const TableCellWithSymbol = ({
    width,
    fontSize = 12,
    text,
    record,
    isComparisonField = false,
    isFirstMetric = false,
    isDeepBorderRight = false,
    isGreenBoldBorderRight = false,
    isEmptyBorderRight = false,
    isShadowBg = false,
    isGreenBg = false,
    isClickable = false,
    onClick,
  }: {
    width: number;
    fontSize?: number;
    text: string | number | boolean;
    record: any;
    isComparisonField?: boolean;
    isFirstMetric?: boolean;
    isDeepBorderRight?: boolean;
    isGreenBoldBorderRight?: boolean;
    isEmptyBorderRight?: boolean;
    isShadowBg?: boolean;
    isGreenBg?: boolean;
    isClickable?: boolean;
    onClick?: () => void;
  }) => {
    record = cloneDeep(record)
    if (isComparisonField && !record.queryComparisonDateValid) {
      record.optionsNum = 0
    }
    if (!isComparisonField && !record.queryDateValid) {
      record.optionsNum = 0
    }

    const displayText = text
    if (displayText === NA) {
      if (isFirstMetric) {
        return (
          <TableCell isClickable={isClickable} width={width} text={displayText} isDeepBorderRight={isDeepBorderRight} isGreenBoldBorderRight={isGreenBoldBorderRight} isEmptyBorderRight={isEmptyBorderRight} isShadowBg={isShadowBg} isGreenBg={isGreenBg} />
        )
      }
      return (
        <TableCell isClickable={isClickable} width={width} text="" isDeepBorderRight={isDeepBorderRight} isGreenBoldBorderRight={isGreenBoldBorderRight} isShadowBg={isShadowBg} isEmptyBorderRight={isEmptyBorderRight} isGreenBg={isGreenBg} />
      )
    }

    return (
      <TableCell isClickable={isClickable} width={width} isDeepBorderRight={isDeepBorderRight} isGreenBoldBorderRight={isGreenBoldBorderRight} isShadowBg={isShadowBg} isEmptyBorderRight={isEmptyBorderRight} isGreenBg={isGreenBg} onClick={onClick}>
        <DisplayText value={text} fontSize={fontSize} showArrow />
      </TableCell>
    )
}
