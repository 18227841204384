import React, { useState } from 'react'
import styles from './Leaderboard.module.scss'
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useShopsSalesCountRanking } from '../hooks/usePlatformAnalytics';
import { usePlatformAnalyticsModuleData } from 'features/filters/moduleDataSlice.hook';
import { Spin } from 'druikit';
import { useFilterCountry } from 'hooks/useFilter';
import { numberUtils } from 'norna-uikit';

const Leaderboard = () => {
    useShopsSalesCountRanking()
    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const { 
        rankingApiData,
        rankingApiLoading,
    } = moduleData
    const [ data, setData ] = useState<{ label: string; value: number; percent?: number; }[]>([])
    const [ filterCountry ] = useFilterCountry()

    useDeepCompareEffect(() => {
        if (!Object.keys(rankingApiData || {})?.length) return
        let newData: any[] = Object.keys(rankingApiData || {}).map(shop => {
            return {
                label: shop,
                count: rankingApiData[shop].count,
                value: numberUtils.formatNumber(rankingApiData[shop].percent, { isCentuple: true, isPercentSymbol: true, decimal: 2 }),
            }
        })
        newData = newData.sort((a, b) => b.count - a.count)
        newData = newData.slice(0, 7)
        const shopList = newData.map(item => item.label)
        const shopOptions = shopList.map(shop => ({ value: shop, label: shop }))
        setModuleData({
            shopList,
            shopOptions,
            graphMetricValue: shopList.slice(0, 1),
        })
        const sum = newData.map(item => Number(item.value.replace('%', '')))
            .reduce((prev, next) => prev + next, 0)
        newData.push({
            label: 'Others',
            value: numberUtils.formatNumber(100 - sum, { decimal: 2, isPercentSymbol: true }),
        })
        setData(newData)
    }, [ rankingApiData ])

    const onShowModal = () => {
        if (rankingApiLoading || filterCountry !== 'Germany') return
        setModuleData({ rankingModalVisible: true })
    }

    return (
        <div onClick={() => onShowModal()}>
            <div className={styles.title}>Largest sellers at Zalando</div>
            <Spin spinning={rankingApiLoading} minHeight={100} className={styles.wrapper}>
                {
                    (filterCountry !== 'Germany' ? [] : data).map((item, index) => {
                        return (
                            <div key={item.label} className={styles.item}>
                                <div className={styles.index}>{index === 7 ? '' : `${index + 1}.`}</div>
                                <div className={styles.content}>
                                    <div 
                                        className={styles.bg} 
                                        style={{ 
                                            width: index === 7 ? '100%' : (100 - index * 5) + '%',
                                            opacity: index === 7 ? 1 : 0.8 - 0.05 * index, 
                                        }}
                                    >
                                        <div className={styles.label} title={item.label}>
                                            {item.label}
                                        </div>
                                        <div className={styles.value}>
                                            {item.value}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Spin>
        </div>
    )
}

export default Leaderboard
