import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store.type'
import { QaDataState } from './qaDataSlice.types'

export const initData = {
  checkedProduct: {},                        // currently checked product in `zoom in` view
  currentNornaIds:[],                        // nornaIds that product in current `zoom in` 
  checkedReturn:{},                          // tag checked in db
  search:'',

  //
  qaPage:false,
}
const initialState: QaDataState = initData as QaDataState
export const qaDataSlice = createSlice({
  name: 'qaData',
  initialState,
  reducers: {
    /**
    * clear checked product
    */
    clearCheckedProduct(state, action) {
      state.checkedProduct = {}
    },

    /**
     *  update check status of product
     */
    updateCheckedProduct(state, action) {                               // sellers false data
      state.checkedProduct = {
        ...state.checkedProduct,
        ...action.payload,
      }
    },

    /**
     * update currentNornaIds 
     * @param state 
     * @param action 
     */
    updateCurrentNornaIds(state, action) {                              
      state.currentNornaIds = action.payload
    },
    /**
     * checked nornaIds returned by api
     * @param state 
     * @param action 
     */
    updateCheckedReturn(state, action) {                              
      state.checkedReturn = action.payload
    },

    setSearch(state, action) {     
      state.search = action.payload
    },
    
    setQaPage(state, action) {     
      state.qaPage = action.payload
    },

  },

})
export const {
  updateCheckedReturn,
  clearCheckedProduct,
  updateCheckedProduct,
  updateCurrentNornaIds,
  setSearch,
  setQaPage,
} = qaDataSlice.actions

// cache object
export const getQaData = (state: RootState) => state.qa

export default qaDataSlice.reducer
