import React ,{ useState } from 'react'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { isLookbookFn } from 'utils'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { MenuDropdownProps } from './types'
import { ClickAwayListener } from 'druikit'

/**
 * @author weiming.su@norna.ai
 * @version 1.0.0
 * MenuDropdownItems Panel apears when user click the label
 */
export function MenuDropdown({ panelSty,children,list,customClass='' }:MenuDropdownProps) {
  const [ isOpenAccountInfo, setIsOpenAccountInfo ] = useState(false)
  const isLookbook = isLookbookFn() || window.location.pathname.indexOf('dashboard') > -1

  return (
    <ClickAwayListener
      onClickAway={() => setIsOpenAccountInfo(false)}
    >
      <div
        className={classnames(styles['account-info'],customClass)}
        onClick={() => setIsOpenAccountInfo(!isOpenAccountInfo)}
        tabIndex={0}
        style={isLookbook ? { borderRight: 'none' } : {}}
      >
          {children}
          <ShouldRender shouldRender={isOpenAccountInfo}>
            <div className={styles['account-details']} style={panelSty}>
              <div className={classnames(styles.arrow, 'account-menus')}>
                {list}
              </div>
            </div>
          </ShouldRender>
      </div>
    </ClickAwayListener>
  )
}
MenuDropdown.defaultProps = {
  panelSty :{},
}
export default MenuDropdown
