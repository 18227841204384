import { useFetch } from 'libs'
import { storage } from 'utils/storage'

export const useAnnotationUnknowSizes = () => {
    const { getFn, loading } = useFetch()

    const fetchAnnotationUnknowSizes = async () => {
        const payload = {
            url: '/annotation/unknown_sizes',
            query: {
                customer: storage.getCustomerVendor(),
            },
        }
        const result = await getFn(payload.url, payload.query)
        return result?.data || {}
    }

    return {
        fetchAnnotationUnknowSizes,
        loading,
    }
}

export const useAnnotationAnnotatedSizes = () => {
    const { getFn, loading } = useFetch()

    const fetchAnnotationAnnotatedSizes = async () => {
        const payload = {
            url: '/annotation/annotated_sizes',
            query: {
                customer: storage.getCustomerVendor(),
            },
        }
        const result = await getFn(payload.url, payload.query)
        return result?.data || {}
    }

    return {
        fetchAnnotationAnnotatedSizes,
        loading,
    }
}

export const useAnnotationSetSizeMap = () => {
    const { postFn, loading } = useFetch()

    const setSizeMap = async (data) => {
        const payload = {
            url: '/annotation/set_size_map',
            data,
        }
        await postFn(payload.url, payload)
    }

    return {
        setSizeMap,
        loading,
    }
}

export const useAnnotationMaskChildren = () => {
    const { postFn, loading } = useFetch()

    const maskChildren = async (data) => {
        const payload = {
            url: '/annotation/mask_children',
            data,
        }
        await postFn(payload.url, payload)
    }

    return {
        maskChildren,
        loading,
    }
} 
