/**
* @file    FilterTags
* @date   2022-07-14
* @author byte_su@163.com
*/

import React, { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle } from 'react'
import classNames from 'classnames'
import { FILTER_LABELS } from 'componentsv2/Tree/utils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { delay } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useLookbook } from 'hooks/useLookbook'
import { setFilterDisplayHeight } from 'features/filters/filtersSlice'
import { useSellers } from 'graphql/sellers/getSellers'
import { ALL_VENDORS, MARKET_VENDOR } from 'consts'
import { vendorShortMappingFn } from 'utils'
import { useHistory, useLocation } from 'react-router-dom'
import { getFilterUnselectTags, toggleFilterUnselectTags, updateFilterUnselectTags } from 'features/filters/globalDataSlice'
import { useFlatQueryObj } from 'hooks'
import { difference } from 'utils/array'
import { isCategorySplitPage, isColorSplitPage, isDisabledCategoryPages, isDiscountsPage, isPlatformAnalyticsPage } from 'global/Routes'
import { getPropertyLabelByValue } from 'components/layout/Header/components/HeaderFilter/property.util'
import { useWindowWidth } from 'hooks/useWindow'
import { ONE_PACK_KEY, ONE_PACK_VALUE } from 'consts/filter'
import { getDisabledCategoriesForColorSplitPage, getDisplayCategoryValue } from 'components/layout/Header/components/HeaderFilter/category.util'
import { getDisplayGenderValue } from 'components/layout/Header/components/HeaderFilter/gender.util'
import { getDisabledFilterCompetitor, getDisabledFilterGender } from 'utils/specialCase'
import { getGenderChildrenOptions, getRegionAlias } from 'utils/feConfig'
import { FilterTagsPropsType, TagButtonPropsType, TagWithTipsPropsType } from './types'
import styles from './style.module.scss'
import { getFilterDisplayHeight, getLaunchedAfterFilterTagValue, getLaunchedBeforeFilterTagValue, getPricePerspectiveValue, getPropertiesFilterTagValue, getRestNumber, getSortByFilterTagValue, updatePageMarginTop } from './utils'

/**
 * ## TagButton
 * 
 * green                clickable
 * orange               unclickable
 * grey                 unselect
 * line-through         there used to be and now there isn't
 */
const TagButton = ({
    clickable = false,
    unselect = false,
    lineThrough = false,
    ellipsis = false,
    masked = false,
    label = '',     // What is displayed on the page
    value,          // The value used in the code
    onClick,
    className = '',
    labelStyle = {},
}: TagButtonPropsType) => {
    if (!label.trim() || label === 'undefined') return null

    const defaultClassName = {
        [styles.tag]: true,
        [styles.green]: clickable,
        [styles.grey]: unselect,
        [styles.lineThrough]: lineThrough,
        [styles.masked]: masked,
        [className]: true,
    }

    return (
        <button
            onClick={() => {
                if (!clickable) return
                onClick?.(value, label)
            }}
            className={classNames(defaultClassName)}
        >
            {ellipsis ? <TagWithTips label={(label)} style={labelStyle} /> : label}
        </button>
    )
}

/**
 * ## 限制限制长度，hover时带tips的组件
 * @param param0 
 * @returns 
 */
// eslint-disable-next-line
export const TagWithTips = ({ label, width = '50px', title = label, align, fontSize, style = {}, className }: TagWithTipsPropsType) => {
    const defaultStyle: CSSProperties = {
        width,
        textAlign: align,
    }
    if (typeof align !== 'undefined') {
        defaultStyle.textAlign = align
    }
    if (typeof fontSize !== 'undefined') {
        defaultStyle.fontSize = fontSize + 'px'
    }
    return (
        <span
            style={{ ...defaultStyle, ...style }}
            className={classNames([ styles.tagWithTips, className ])}
            title={vendorShortMappingFn(title)}
        >
            {vendorShortMappingFn(label)}
        </span>
    )
}

/**
 * ## Filtered items
 * 
 *  ### order rule
 *  category\country\gender\currncy\competitor\property  
 * @param param0 
 * 
 * @returns 
 */
export const FilterTags = forwardRef(({
    categories = [],
    region,
    gender = [],
    currency,
    competitor = [],
    property = {},
    priceFilter,
    priceRange,
    productFilter,
    sortBy,
    launchedAfter,
    launchedBefore,
    noSize,
    noHistoricalSize,
    noUnavailableSize,
    noHarmonizedSize,
    zalandodeepShopEmptyString,
    ignoreNoAvailableSize,
    sizeFilter,
    taxFilter,
    materialFilter,
    packFilter,
    open,
    setIsGt2lines,
}: FilterTagsPropsType, ref) => {
    const disabledCategoriesForColorSplitPage = getDisabledCategoriesForColorSplitPage()
    const windowWidth = useWindowWidth()
    const flatQueryObj = useFlatQueryObj()

    const lastSelectedCategories = difference(getDisplayCategoryValue(flatQueryObj?.categories), categories)
    const lastSelectedGenders = difference(getDisplayGenderValue(flatQueryObj?.targetGroups), getDisplayGenderValue(gender))
    const lastSelectedCountry = getRegionAlias(flatQueryObj?.region?.key)
    const lastSelectedCurrency = flatQueryObj?.region?.currency
    let lastSelectedCompetitors = flatQueryObj?.sellers?.map(item => item.vendor)
        .map(item => item === MARKET_VENDOR.vendor ? ALL_VENDORS : item)
    lastSelectedCompetitors = difference(lastSelectedCompetitors, competitor)
    const lastSelectedSortBy = getSortByFilterTagValue(flatQueryObj.sortField)
    const lastSelectedPriceFilter = getPricePerspectiveValue(flatQueryObj.priceFilter.label)
    const lastSelectedLaunchedAfter = getLaunchedAfterFilterTagValue(flatQueryObj.launchAfter)
    const lastSelectedLaunchedBeforeDate = getLaunchedBeforeFilterTagValue(flatQueryObj.launchBeforeDate)
    let properties: any = getPropertyLabelByValue({
        selectedCategories: categories,
        selectedProperties: property,
    })
    properties = getPropertiesFilterTagValue(properties)
    let lastSelectedProperties: any = getPropertyLabelByValue({
        selectedCategories: flatQueryObj?.categories,
        selectedProperties: flatQueryObj.properties,
    })
    lastSelectedProperties = getPropertiesFilterTagValue(lastSelectedProperties)
    lastSelectedProperties = difference(lastSelectedProperties, properties)

    const disabledFilterCompetitor = getDisabledFilterCompetitor()
    const disabledFilterGender = getDisabledFilterGender()

    const dispatch = useDispatch()
    const { checkedLookbookLabels = [] } = useLookbook()

    useDeepCompareEffect(() => {
        updatePageMarginTop()
        const filterDisplayHeight = getFilterDisplayHeight()
        dispatch(setFilterDisplayHeight({ filterDisplayHeight }))
        // `Filter Display`内容的变化作为计算页面容器`margin-top`触发源, 当更新props类型时，此处需相应更新
    }, [ categories, region, property, region, competitor, gender, currency, priceFilter, priceRange, productFilter, launchedAfter, launchedBefore, noSize, noHistoricalSize, noUnavailableSize, noHarmonizedSize, zalandodeepShopEmptyString, ignoreNoAvailableSize ])

    const history = useHistory()
    const { pathname, search } = useLocation()

    const { codenames } = useSellers([])

    const filterUnselectTags = useSelector(getFilterUnselectTags)

    useImperativeHandle(ref,
        () => ({
            getVals() {
                return [ ...filterUnselectTags ]
            },
            clear() {
                // clearState()
                dispatch(updateFilterUnselectTags([]))
            },
        }),
    )

    const click = (value: string) => {
        dispatch(toggleFilterUnselectTags(value))
    }

    const delayFn = useCallback(() => {
        const numEle = document.querySelector(`.${styles.num}`)

        const restNum = getRestNumber(styles.filtersItem, styles.tag)
        setIsGt2lines?.(Boolean(restNum))
        const collapseEle: HTMLDivElement = document.querySelector(`.${styles.collapse}`) as HTMLDivElement
        if (numEle) {
            numEle.innerHTML = restNum === 0 ? '' : '+' + restNum
            if (collapseEle && restNum === 0) {
                collapseEle.style.opacity = '0'
            } else {
                collapseEle.style.opacity = '1'
            }
        }

        setTimeout(updatePageMarginTop, 0)
        setTimeout(() => {
            // 设置 displayHeight
            const filterDisplayHeight = getFilterDisplayHeight()
            dispatch(setFilterDisplayHeight({ filterDisplayHeight }))
        }, 0)
        // eslint-disable-next-line
    }, [ open ])

    useDeepCompareEffect(() => {
        delay(delayFn, 0)
    }, [ flatQueryObj, open, windowWidth ])

    /**
     * 切换功能视图
     */
    useEffect(() => {
        delayFn()
    }, [pathname, search, history]); //eslint-disable-line

    // category
    const categoriesHtml: any[] = []
    if (lastSelectedCategories.length && !isDisabledCategoryPages()) {
        categoriesHtml.push(...lastSelectedCategories.map(category => {
            const value = `${FILTER_LABELS.CATEGORY}:${category}`
            return (
                <TagButton
                    key={category}
                    value={value}
                    label={category}
                    clickable={false}
                    lineThrough
                />
            )
        }))
    }

    const categoriesClickable = categories.filter(item => !filterUnselectTags.includes(`${FILTER_LABELS.CATEGORY}:${item}`)).length > 1
    categoriesHtml.push(...categories.map((category: string) => {
        const value = `${FILTER_LABELS.CATEGORY}:${category}`
        const unselect = filterUnselectTags.includes(value)
        let clickable = unselect || categoriesClickable
        if (isCategorySplitPage()) {
            clickable = false
        }
        return (
            <TagButton
                key={category}
                value={value}
                label={category}
                clickable={clickable}
                unselect={unselect}
                masked={isColorSplitPage() && disabledCategoriesForColorSplitPage.includes(category)}
                onClick={click}
            />
        )
    }))

    // property
    const propertyHtml: any = []
    if (lastSelectedProperties.length) {
        propertyHtml.push(...lastSelectedProperties.map(item => (
            <TagButton
                key={item}
                value={item}
                label={`${item.split(':')[1]}`}
                clickable={false}
                lineThrough
            />
        )))
    }
    propertyHtml.push(...properties.map(item => (
        <TagButton
            key={item}
            value={item}
            label={`${item.split(':')[1]}`}
            clickable
            unselect={filterUnselectTags.includes(item)}
            onClick={click}
        />
    )))

    // gender
    const getGenderLabel = label => {
        if (label.includes('-')) {
            return getGenderChildrenOptions().find(item => item.key === label)?.description
        }
        return label
    } 
    const genderHtml: any[] = []
    if (lastSelectedGenders.length) {
        genderHtml.push(...lastSelectedGenders.map(genderItem => {
            const value = `${FILTER_LABELS.GENDER}:${genderItem}`
            return (
                <TagButton
                    key={genderItem}
                    value={value}
                    label={getGenderLabel(genderItem)}
                    clickable={false}
                    lineThrough
                />
            )
        }))
    }
    const genderClickable = gender.filter(item => !filterUnselectTags.includes(`${FILTER_LABELS.GENDER}:${item}`)).length > 1
    genderHtml.push(...gender.map((genderItem: string) => {
        const value = `${FILTER_LABELS.GENDER}:${genderItem}`
        const unselect = filterUnselectTags.includes(value)
        return (
            <TagButton
                key={genderItem}
                value={value}
                label={getGenderLabel(genderItem)}
                clickable={unselect || genderClickable}
                unselect={unselect}
                masked={disabledFilterGender.includes(genderItem)}
                onClick={click}
            />
        )
    }))

    // competitors
    let competitorsHtml: any[] = []
    const compeStyle = { width: 'auto', maxWidth: '200px' }
    if (lastSelectedCompetitors.length) {
        competitorsHtml.push(...lastSelectedCompetitors.map(item => {
            const value = `${FILTER_LABELS.COMPETITOR}:${item}`
            return (
                <TagButton
                    key={value}
                    value={value}
                    label={codenames[item] || item}
                    clickable={false}
                    ellipsis
                    lineThrough
                    labelStyle={compeStyle}
                />
            )
        }))
    }
    const competitorClickable = competitor.filter(item => !filterUnselectTags.includes(`${FILTER_LABELS.COMPETITOR}:${item}`)).length > 1
    competitorsHtml.push(...competitor.map((item: string) => {
        const value = `${FILTER_LABELS.COMPETITOR}:${item}`
        const unselect = filterUnselectTags.includes(value)
        return (
            <TagButton
                key={value}
                value={value}
                label={codenames[item] || item}
                ellipsis
                clickable={unselect || competitorClickable}
                unselect={unselect}
                masked={disabledFilterCompetitor.includes(item)}
                onClick={click}
                labelStyle={compeStyle}
            />
        )
    }))
    if (isPlatformAnalyticsPage()) {
        competitorsHtml = []
    }

    // lookbook
    const lookbookHtml = checkedLookbookLabels.map(lookbookName => {
        const value = lookbookName
        return (
            <TagButton
                key={`Lookbook:${lookbookName}`}
                value={value}
                label={`Lookbook: ${lookbookName}`}
                clickable
                unselect={filterUnselectTags.includes(value)}
                onClick={click}
            />
        )
    })

    // 如果 lookbook 存在, 只显示 lookbook
    if (Array.isArray(checkedLookbookLabels) && checkedLookbookLabels.length > 0) {
        return (
            <>
                {lookbookHtml}
            </>
        )
    }

    return (
        <>
            {/* categories */}
            {categoriesHtml}
            {/* country */}
            {
                region !== lastSelectedCountry ? (
                    <TagButton
                        key="lastSelectedCountry"
                        value={lastSelectedCountry}
                        label={lastSelectedCountry}
                        clickable={false}
                        lineThrough
                        className={styles.regionTag}
                    />
                ) : null
            }
            <TagButton
                key="region"
                value={region}
                label={region}
                clickable={false}
            />

            {/* currency */}
            {
                currency !== lastSelectedCurrency ? (
                    <TagButton
                        key="lastSelectedCurrency"
                        value={lastSelectedCurrency}
                        label={lastSelectedCurrency}
                        clickable={false}
                        lineThrough
                        className={styles.currencyTag}
                    />
                ) : null
            }
            <TagButton
                key="currency"
                value={`${FILTER_LABELS.CURRENCY}:${currency}`}
                label={currency}
                clickable={false}
            />

            {/* priceFilter */}
            {
                priceFilter !== lastSelectedPriceFilter && !isDiscountsPage() ? (
                    <TagButton
                        key="lastSelectedPriceFilter"
                        value={lastSelectedPriceFilter}
                        label={lastSelectedPriceFilter}
                        clickable={false}
                        lineThrough
                        className={styles.priceFilterTag}
                    />
                ) : null
            }
            {priceFilter ? (
                <TagButton
                    key="priceFilter"
                    value={priceFilter}
                    label={priceFilter}
                    clickable={false}
                />
            ) : null}

            {/* gender */}
            {genderHtml}

            {priceRange ? (
                <TagButton
                    key="priceRange"
                    value={priceRange}
                    label={priceRange}
                    clickable
                    unselect={filterUnselectTags.includes(priceRange)}
                    onClick={click}
                />
            ) : null}

            {
                productFilter ? (
                    <TagButton
                        key="productFilter"
                        value={productFilter}
                        label={productFilter}
                        clickable={productFilter !== 'All options'}
                        unselect={filterUnselectTags.includes(productFilter)}
                        onClick={click}
                    />
                ) : null
            }

            {/* Pack filter */}
            {packFilter ? (
                <TagButton
                    key="packFilter"
                    value={packFilter}
                    label={packFilter.replace(ONE_PACK_KEY, ONE_PACK_VALUE)}
                    clickable
                    unselect={filterUnselectTags.includes(packFilter)}
                    onClick={click}
                />
            ) : null}

            {/* Size filter */}
            {sizeFilter ? (
                <TagButton
                    key="sizeFilter"
                    value={sizeFilter}
                    label={sizeFilter}
                    clickable
                    unselect={filterUnselectTags.includes(sizeFilter)}
                    onClick={click}
                />
            ) : null}

            {/* Tax filter */}
            {taxFilter ? (
                <TagButton
                    key="taxFilter"
                    value={taxFilter}
                    label={taxFilter}
                    clickable
                    unselect={filterUnselectTags.includes(taxFilter)}
                    onClick={click}
                />
            ) : null}

            {/* Launched after */}
            {
                launchedAfter && lastSelectedLaunchedAfter && launchedAfter !== lastSelectedLaunchedAfter ? (
                    <TagButton
                        key="lastSelectedLaunchedAfter"
                        value={lastSelectedLaunchedAfter}
                        label={lastSelectedLaunchedAfter}
                        clickable
                        lineThrough
                    />
                ) : null
            }
            {launchedAfter ? (
                <TagButton
                    key="launchedAfter"
                    value={launchedAfter}
                    label={launchedAfter}
                    clickable
                    unselect={filterUnselectTags.includes(launchedAfter)}
                    onClick={click}
                />
            ) : null}

            {/* Launched before */}
            {
                launchedBefore && lastSelectedLaunchedBeforeDate && launchedBefore !== lastSelectedLaunchedBeforeDate ? (
                    <TagButton
                        key="lastSelectedLaunchedBefore"
                        value={lastSelectedLaunchedBeforeDate}
                        label={lastSelectedLaunchedBeforeDate}
                        clickable
                        lineThrough
                    />
                ) : null
            }
            {launchedBefore ? (
                <TagButton
                    key="launchedBefore"
                    value={launchedBefore}
                    label={launchedBefore}
                    clickable
                    unselect={filterUnselectTags.includes(launchedBefore)}
                    onClick={click}
                />
            ) : null}

            {/* Sort order */}
            {
                sortBy !== lastSelectedSortBy ? (
                    <TagButton
                        key="lastSelectedSortBy"
                        value={lastSelectedSortBy}
                        label={lastSelectedSortBy}
                        clickable={false}
                        lineThrough
                        className={styles.sortOrderTag}
                    />
                ) : null
            }
            {sortBy ? (
                <TagButton
                    key="sortOrder"
                    value={sortBy}
                    label={sortBy}
                    clickable={false}
                />
            ) : null}

            {
                materialFilter ? (
                    <TagButton
                        key="materialFilter"
                        value={materialFilter}
                        label={materialFilter} 
                        clickable
                        unselect={filterUnselectTags.includes(materialFilter)}
                        onClick={click}
                    />
                ) : null
            }

            {
                noSize ? (
                    <TagButton
                        key="noSize"
                        value={noSize}
                        label={noSize} 
                        clickable
                        unselect={filterUnselectTags.includes(noSize)}
                        onClick={click}
                    />
                ) : null
            }

            {
                noUnavailableSize ? (
                    <TagButton
                        key="noUnavailableSize"
                        value={noUnavailableSize}
                        label={noUnavailableSize} 
                        clickable
                        unselect={filterUnselectTags.includes(noUnavailableSize)}
                        onClick={click}
                    />
                ) : null
            }

            {
                noHistoricalSize ? (
                    <TagButton
                        key="noHistoricalSize"
                        value={noHistoricalSize}
                        label={noHistoricalSize} 
                        clickable
                        unselect={filterUnselectTags.includes(noHistoricalSize)}
                        onClick={click}
                    />
                ) : null
            }

            {
                noHarmonizedSize ? (
                    <TagButton
                        key="noHarmonizedSize"
                        value={noHarmonizedSize}
                        label={noHarmonizedSize} 
                        clickable
                        unselect={filterUnselectTags.includes(noHarmonizedSize)}
                        onClick={click}
                    />
                ) : null
            }

            {
                zalandodeepShopEmptyString ? (
                    <TagButton
                        key="zalandodeepShopEmptyString"
                        value={zalandodeepShopEmptyString}
                        label={zalandodeepShopEmptyString} 
                        clickable
                        unselect={filterUnselectTags.includes(zalandodeepShopEmptyString)}
                        onClick={click}
                    />
                ) : null
            }
            
            {
                ignoreNoAvailableSize ? (
                    <TagButton
                        key="ignoreNoAvailableSize"
                        value={ignoreNoAvailableSize}
                        label={ignoreNoAvailableSize} 
                        clickable
                        unselect={filterUnselectTags.includes(ignoreNoAvailableSize)}
                        onClick={click}
                    />
                ) : null
            }

            {/* competitors */}
            {competitorsHtml}
            {/* property */}
            {propertyHtml}
        </>
    )
})

FilterTags.displayName = 'FilterTags'
