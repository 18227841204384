/* eslint-disable no-console */
/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { RootState } from 'app/store.type'
import { cloneDeep, sortBy, uniq, isEqual, orderBy } from 'lodash'
import md5 from 'blueimp-md5'
import { sortSeller } from 'utils/sellers'
import { SellerType, TaxFilterType } from 'types'
import { CATEGORY_TOTAL, defaultSort, IS_LIVE_ENV, PRICE_FILTER } from 'consts'
import { lsSet as lsSetFn, lsGet as lsGetFn } from 'utils/ls'
import { setSelectAllCategory } from 'caches'
import { loadingBar } from 'hooks'
import { isColorSplitPage, isDashboardPages, isDiscountsPage } from 'global/Routes'
import { storage } from 'utils/storage'
import { getAllFilterCategoryValue, getAllFilterCompetitorValue } from 'utils/filterUtils'
import { getFilterGenderAllValue } from 'components/layout/Header/components/HeaderFilter/gender.util'
import { getDisabledCategoriesForColorSplitPage } from 'components/layout/Header/components/HeaderFilter/category.util'
import { intersection } from 'utils/array'
import { getCurrencyByRegion } from 'utils'
import { ColorMaterialDefaultValue } from '../../consts/index'
import { FilterState } from './filtersSlice.type'
/**
 *BEGIN ---- default value
 */

export const defaultEarliestLaunchDate = null
export const defaultLaunchBeforeDate = null
export const defaultPriceFilter = { type: 'SINGLE_CHECK', active: true, val: 'All actual', label: PRICE_FILTER['All actual'] }
export const defaultPriceRange = { max: 10000, min: 0, activate_price_range: false, optionValue: 'all' }
export const defaultSizeFilter = { checked: false, min: IS_LIVE_ENV ? 40 : 0, max: 170, isNoSizes: false }
export const defaultTaxFilter = { checked: false, value: 0 }
export const emptyMaterialFilter = { materials: [], percent: 0 }
export const emptyColorFilter = { colors: [], percent: 0 }
export const defaultSortField = { sortField: defaultSort.field, sortOrder: defaultSort.order }
export const defaultPackFilter = []
export const defaultNoSize = false
export const defaultNoHistoricalSize = false
export const defaultNoUnavailableSize = false
export const defaultNoHarmonizedSize = false
export const defaultZalandodeepShopEmptyString = false
export const defaultIgnoreNoAvailableSize = false

/**
 *END   ---- default value
 */

export const CURRENT = 'C_'                                // current user
export const CURRENT_EX = 'C_EX'
export const CURRENT_EX_OBJ = 'C_EX_OBJ'                   // comparsion_exclude_object
export const CURRENT_USER = 'C_U'                          // the value of CURRENT_USER ought to be the accountId/companyId
const LOOK_BOOK = 'L_B'                                    // lookbook key value
/**
 * set current value
 * @param accountIdAndCompanyId       the value ought to be `${accountId}/${companyId}`
 */
export const setCurrentUser = accountIdAndCompanyId => {
  lsSetFn(CURRENT_USER, md5(accountIdAndCompanyId))
}

export const getCurrentUser = () => lsGetFn(CURRENT_USER)

const lsSet = (value: object) => {
  if (getCurrentUser()) {
    lsSetFn(getCurrentUser() + CURRENT, JSON.stringify(value))
  }
}

const lsSetLookbook = (value: object) => {
  lsSetFn(getCurrentUser() + LOOK_BOOK + '_S', JSON.stringify(value))
}

const lsGetLookbook = () => {
  const str: any = lsGetFn(getCurrentUser() + LOOK_BOOK + '_S')
  return str ?? null

}

export const lsGet = (key?: string) => {
  const str: any = lsGetFn(getCurrentUser() + (key || CURRENT))
  return str || null
}

const lsSetExclude = (value: object) => {
  lsSetFn(getCurrentUser() + CURRENT_EX, JSON.stringify(value))
}

export const lsGetExclude = (key?: string) => {
  const str: any = lsGetFn(getCurrentUser() + (key || CURRENT_EX))
  return str ? JSON.parse(str as string) : null
}

export const isCachedWithoutCompetitor = (newQuery, oldQuery) => {
  newQuery = cloneDeep(newQuery)
  newQuery.sellers = []
  oldQuery = cloneDeep(oldQuery)
  oldQuery.sellers = []
  return isEqual(newQuery, oldQuery)
}

export const queryInit = {
  sortField: defaultSortField,
  categories: [], // [
  targetGroups: [],
  earliestLaunchDate: defaultEarliestLaunchDate,
  priceFilter: defaultPriceFilter,
  priceRange: defaultPriceRange,
  categoryTree: 'generic',
  regions: {},
  sizeFilter: defaultSizeFilter,
  packFilter: defaultPackFilter,
  taxFilter: defaultTaxFilter,
  colorFilter: emptyColorFilter,
  materialFilter: emptyMaterialFilter,
  properties: {},
  noSize: defaultNoSize,
  noHistoricalSize: defaultNoHistoricalSize,
  noUnavailableSize: defaultNoUnavailableSize,
  noHarmonizedSize: defaultNoHarmonizedSize,
  zalandodeepShopEmptyString: defaultZalandodeepShopEmptyString,
  ignoreNoAvailableSize: defaultIgnoreNoAvailableSize,
  launchBeforeDate: defaultLaunchBeforeDate,
}

const COMPARISON_QUERY = {
  sellers: [],
  updatedAt: '',
  collection: {
    name: 'Current Filter',
    query: queryInit,
    includedProducts: [],
    excludedProducts: [],
    isShared: false,
    updatedAt: '',
    hasTriggers: false,
    favorite: false,
  },
  lookbookId: null,
  analysisTarget: 'collection',
  isPinned: false,
  pinnedAnalysis: null,
  selectedAnalysis: null,
  excludedProducts: [],
  favorite: false,
  priceIndexDate: null,
}

export const email = () => getCurrentUser()// current user email

export const EMAIL = email// current user email

/* ********************************** Filter 初始值 *************************************** */
export function initStateFn() {
  const initState = {
    locationReload: false,
    assortmentCategorys: [],
    currentBlack: {},
    reloadData: '',
    categories: [],
    /**
     * 2022/08/10
     * 有些页面如 Browse options, Price architecture, 页面往下滚动时
     * 需要设置头部吸附在顶部, 此时需要知道距离顶部多少距离,
     * 而顶部的距离又是动态变化的
     */
    filterDisplayHeight: 120,
    /**
     * 2022/08/17
     * 场景: 选中 lookbook 的时候希望能记住最后一次 filter 选项, 
     * 之后再次取消选择 lookbook, 期待 filter 能恢复到上一次的选项而不是直接清空 filter
     */
    lastComparisonQueryForLookbook: undefined,
    /**
     * 记录几个 Dashboard 页面 category 选中项, 用于在 Header 里显示 category
     */
    dashboardCategories: [],
    /**
     * 2022/08/29
     * 
     * 问题:
     * 一个页面会发多个请求, 比如 Color Split 页面会发一个饼图请求和一个线图请求
     * loadingBar 应当在第一个请求发出时出现, 在两个请求都结束后才隐藏
     * 
     * 解决:
     * 每发一个请求, 就在 loadingObj 中记录一条数据 (在 libs/request.js 中做这一步操作)
     * 示例如下
     * ```
     * // 这里的 /pie-request 和 /line-request 是实际请求接口的 url
     * loadingObj = {
     *    '/pie-request': false,    
     *    '/line-request': true,
     * }
     * ```
     * 在 hooks/useLoadingBar 中写一个钩子函数, 监听 loadingObj 的值
     * 当且仅当所有值都为 false (Object.values(loadingObj)) 时才隐藏进度条, 反之显示进度条
     */
    loadingObj: {},
    comparisonQuery: lsGet() || {
      seller: {},
      comparisonQuery: null,
      date: '',
      isOurs: false,
      isPriceIndex: false,
    }, // init from local storage
    groupData: {},
    excludedProducts: lsGetExclude() || {},

    comparePreData: {
      categories: [],
      seller: {},
    },
    lookBookQuery: lsGetLookbook() || {
      comparisonQuery: {
        sellers: [],
        collection: null,
        isPinned: false,
        analysisTarget: 'lookbook',
        selectedAnalysis: null,
        pinnedAnalysis: {
          priceIndex: true,
          priceHistory: true,
          priceLadder: true,
          markdown: true,
          assortment: true,
        },
        excludedProducts: [],
        priceIndexDate: null,
        lookbook: {
          ...lsGet(lsGet(CURRENT_USER) + LOOK_BOOK) || {},
          query: queryInit,
        },
      },
      seller: {},
      date: '',
    },
    filters: lsGet()?.comparisonQuery?.collection?.query ?? queryInit,
    vendor: [],
  }

  return initState
}

// Define the initial state using that type
const initialState: FilterState = initStateFn()

export const getProperty = (types, isInclude = false) => {
  const blackProperties = {}
  Object.keys(types).map(key => {
    if ((!isInclude && types[key].length) || (isInclude && `${types[key]}` !== 'false')) {
      blackProperties[key] = types[key]
    }
  })
  return blackProperties
}
export const updateFilerFn = (payload, comparisonQuery) => {
  const query = cloneDeep(comparisonQuery)

  if (!query.customer_seller) {
    query.customer_seller = {}
  }
  if (payload.region) {
    const { region } = payload
    query.sellers = sortSeller(region.perSeller || [])

    /**
     * customer_seller 的 region 随着 Filter > Country 变化而变化
     */
    query.customer_seller.region = region.key
    query.collection.query.regions = region
  } else {
    /**
     * customer_seller 的 region 随着 Filter > Country 变化而变化
     */
    query.customer_seller.region = ''
    query.collection.query.regions = {}
  }
  query.collection.query.targetGroups = uniq(payload.gender).sort()

  query.collection.query.packFilter = uniq(payload.packFilter).sort()

  query.collection.query.categories = uniq(payload.category).sort()

  setSelectAllCategory(payload.category.indexOf(CATEGORY_TOTAL) > -1)

  if (payload.sort) {
    query.collection.query.sortField = payload.sort
  } else {
    query.collection.query.sortField = defaultSortField
  }
  if (payload.property) {
    query.collection.query.properties = payload.property
    query.collection.query.blackProperties = payload.property.black
    query.collection.query.includeUnsetCategories = payload.property.include
  }
  ;
  if (payload.vendor) {
    query.sellers = sortSeller(payload.vendor)
  }
  if (payload.priceFilter) query.collection.query.priceFilter = payload.priceFilter
  if (payload.priceRange) query.collection.query.priceRange = {
    ...query.collection.query.priceRange,
    ...payload.priceRange,
  } // price range
  
  query.collection.query.earliestLaunchDate = payload.launchDate
  query.collection.query.launchBeforeDate = payload.launchBeforeDate
  query.collection.query.noSize = payload.noSize || defaultNoSize
  query.collection.query.noHistoricalSize = payload.noHistoricalSize || defaultNoHistoricalSize
  query.collection.query.noUnavailableSize = payload.noUnavailableSize || defaultNoUnavailableSize
  query.collection.query.noHarmonizedSize = payload.noHarmonizedSize || defaultNoHarmonizedSize
  query.collection.query.zalandodeepShopEmptyString = payload.zalandodeepShopEmptyString || defaultZalandodeepShopEmptyString
  query.collection.query.ignoreNoAvailableSize = payload.ignoreNoAvailableSize || defaultIgnoreNoAvailableSize
  query.collection.query.materialFilter = !payload.materialFilter.checked ? emptyMaterialFilter : {
    ...payload.materialFilter,
    materials: payload.property.Material || [],
  }
  
  // size filter
  query.collection.query.sizeFilter = !payload.sizeFilter.checked ? defaultSizeFilter : {
    ...payload.sizeFilter,
  }

  // tax filter
  query.collection.query.taxFilter = !payload.taxFilter.checked ? defaultTaxFilter : {
    ...payload.taxFilter,
  }

  if (!IS_LIVE_ENV) {
    query.collection.query.colorFilter = !payload.colorFilter.checked ? emptyColorFilter : {
      ...payload.colorFilter,
      colors: payload.property.Colors || [],
    }
  } else if (payload.property?.Colors?.length) {
    query.collection.query.colorFilter = {
      ...emptyColorFilter,
      percent: ColorMaterialDefaultValue.percent,
      colors: payload.property.Colors || [],

    }
  } else {
    query.collection.query.colorFilter = emptyColorFilter
  }

  /**
   * Competitor 勾选了 Market 时，market 字段传递所有 competitor 数组（不包含 Market 本身），格式要做转换 [{region:'', vendor:''}]
   * Competitor 未勾选 Market 时，market 字段传递 []
   */
  if (payload.market) {
    query.market = sortBy(payload.market, 'vendor')
  } else if (!query.market) { // form
    query.market = []
  }

  return query
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    filterCategory: (state, action) => {
      state.comparisonQuery = action.payload
      state.locationReload = true
      return state
    },
    filterCollection: (state, action) => {
      if (action.payload) {
        const { payload } = action
        state.comparisonQuery.comparisonQuery.id = payload.id
        state.comparisonQuery.comparisonQuery.name = payload.name || ''
        state.comparisonQuery.comparisonQuery.sellers = sortSeller(payload.comparisons.sellers || [])
        state.comparisonQuery.comparisonQuery.createdAt = payload.comparisons.createdAt
        state.comparisonQuery.comparisonQuery.updatedAt = ''
        state.comparisonQuery.comparisonQuery.collection.id = payload.comparisons.id
        state.comparisonQuery.comparisonQuery.collection.name = payload.name
        state.comparisonQuery.comparisonQuery.collection.query = {
          ...payload.query && payload.query.categories?.length ? payload.query : queryInit,
        }
        state.comparisonQuery.comparisonQuery.collection.ownerAccountId = payload.ownerAccountId
        state.comparisonQuery.comparisonQuery.collection.isShared = false
        state.comparisonQuery.comparisonQuery.collection.createdAt = payload.createdAt
        state.comparisonQuery.comparisonQuery.collection.updatedAt = ''
        state.comparisonQuery.comparisonQuery.collection.hasTriggers = false
        state.comparisonQuery.comparisonQuery.collection.favorite = false
        state.comparisonQuery.comparisonQuery.collectionId = payload.id
      }
      lsSet(state.comparisonQuery)
      return state
    },

    setFilterDisplayHeight: (state, action) => {
      state.filterDisplayHeight = action.payload.filterDisplayHeight
    },

    /**
     * 2022/08/17
     * 场景: 选中 lookbook 的时候希望能记住最后一次 filter 选项, 
     * 之后再次取消选择 lookbook, 期待 filter 能恢复到上一次的选项而不是直接清空 filter
     */
    setLastComparisonQueryForLookbook: (state, action) => {
      if (action.payload === undefined && state.lastComparisonQueryForLookbook !== undefined) {
        state.comparisonQuery.comparisonQuery = state.lastComparisonQueryForLookbook
        state.lastComparisonQueryForLookbook = action.payload
      } else {
        state.lastComparisonQueryForLookbook = action.payload
      }
    },

    /**
     * 设置 loadingObj
     * 例子:
     * state.loadingObj = {'/assortments': false}
     * action.payload.loadingObj = {'/assortments': true}
     * setLoadingObj()  =>  后者会覆盖前者
     */
    setLoadingObj: (state, action) => {
      state.loadingObj = { ...state.loadingObj, ...action.payload.loadingObj }
    },

    /**
     * 清空 loadingObj
     * 即 loadingObj = {}
     */
    clearLoadingObj: state => {
      state.loadingObj = {}
    },

    setDashboardCategories: (state, action) => {
      state.dashboardCategories = action.payload
    },

    updateFilters: (state, action) => {
      let categoryList = storage.getCategoryTreeList() || []
      if (!action.payload.category.length && state?.comparisonQuery?.comparisonQuery?.collection?.query?.categories?.length) {
        action.payload.category = cloneDeep(state?.comparisonQuery?.comparisonQuery?.collection?.query?.categories)
      }
      if (!action.payload.category.length && categoryList.length) {
        if (IS_LIVE_ENV && isColorSplitPage()) {
          const hideCategoryList = getDisabledCategoriesForColorSplitPage()
          categoryList = categoryList.filter(item => !hideCategoryList.includes(item.name))
        }
        if (categoryList[0].list) {
          action.payload.category = categoryList[0].list.map(item => item.name)
        } else {
          action.payload.category = [ categoryList[0].name ]
        }
      }

      /**
       * 当 Filter > Competitor 一个不选时, 默认选中 customerVendor
       */
      if (!action?.payload?.vendor?.length && action?.payload?.region?.key) {
        action.payload.vendor = [ { vendor: storage.getCustomerVendor(), region: action?.payload?.region?.key } ]
      }

      /**
       * 之前将 oldRegions 变量放在 state.comparisonQuery.comparisonQuery.collection.query.oldRegions 层级
       * 会导致 Filter 打开即便不改内容，也会重新刷新的问题
       * 现在放在 state.comparisonQuery.oldRegions 层级，没有这个问题了
       */
      if (action.payload.region) {
        state.comparisonQuery.oldRegions = { ...state.comparisonQuery.comparisonQuery.collection.query.regions }
      } else {
        state.comparisonQuery.oldRegions = {}
      }

      const query = updateFilerFn(action.payload, state.comparisonQuery.comparisonQuery)

      /**
       * error case: object key 顺序不一致也会导致刷新
       * ```
       * "priceFilter":{"type":"SINGLE_CHECK","active":true,"val":"All actual","label":"All options @ actual retail price"}
       * "priceFilter":{"val":"All actual","type":"SINGLE_CHECK","active":true,"label":"All options @ actual retail price"}
       * ```
       * 
       * 注意：这里需要对两个对象都用 JSON.parse(JSON.stringify()) 包裹
       */
      const oldQuery = JSON.parse(JSON.stringify(state.comparisonQuery.comparisonQuery))
      if (Array.isArray(oldQuery?.collection?.query?.categories)) {
        oldQuery.collection.query.categories = oldQuery?.collection?.query?.categories.sort()
      }
      const newQuery = JSON.parse(JSON.stringify(query))
      if (Array.isArray(newQuery?.collection?.query?.categories)) {
        newQuery.collection.query.categories = newQuery?.collection?.query?.categories.sort()
      }

      // vendor 需要排序
      if (Array.isArray(oldQuery?.sellers)) {
        oldQuery.sellers = orderBy(oldQuery?.sellers, [ 'vendor', 'region' ], [ 'asc', 'asc' ])
      }
      if (Array.isArray(newQuery?.sellers)) {
        newQuery.sellers = orderBy(newQuery?.sellers, [ 'vendor', 'region' ], [ 'asc', 'asc' ])
      }

      // targetGroups 需要排序
      if (Array.isArray(oldQuery?.collection?.query?.targetGroups)) {
        oldQuery.collection.query.targetGroups = oldQuery?.collection?.query?.targetGroups.sort()
      }
      if (Array.isArray(newQuery?.collection?.query?.targetGroups)) {
        newQuery.collection.query.targetGroups = newQuery?.collection?.query?.targetGroups.sort()
      }

      if (isDiscountsPage()) {
        delete oldQuery.collection.query.priceFilter
        delete newQuery.collection.query.priceFilter
      }

      /**
       * Lookbook 页面会自动将 category 设置为 []
       * 因此比较时要排除 category 字段
       */
      if (storage.getIsLookbook()) {
        delete oldQuery.collection.query.categories
        delete newQuery.collection.query.categories
      }

      if (!isEqual(newQuery, oldQuery)) {
        delete query.collection.query.property
        state.comparisonQuery.comparisonQuery = query
        if (action.payload.clean) {
          state.excludedProducts = {}
          lsSetExclude(state.excludedProducts)
        }

        lsSet(state.comparisonQuery)
        if (!isCachedWithoutCompetitor(newQuery, oldQuery)) {
          loadingBar.restart()
        }
      }
    },
    setFilterCategory: (state, action) => {
      if (action.payload) {
        state.comparisonQuery.comparisonQuery.collection.query.categories = action.payload
      }

      lsSet(state.comparisonQuery)

    },

    filterProperties: (state, action) => {
      const properties = {}
      Object.keys(action.payload).map(key => {
        if (action.payload[key].length) {
          properties[key] = action.payload[key]
        }
      })
      state.comparisonQuery.comparisonQuery.collection.query.properties = properties
      lsSet(state.comparisonQuery)
    },
    filterBlackProperties: (state, action) => {
      const blackProperties = {}
      Object.keys(action.payload).map(key => {
        if (action.payload[key].length) {
          blackProperties[key] = action.payload[key]
        }
      })

      state.comparisonQuery.comparisonQuery.collection.query.blackProperties = blackProperties
      lsSet(state.comparisonQuery)

    },
    filterIncludeUnsetCategories(state, action) {
      lsSet(state.comparisonQuery)
    },
    filterVendor: (state, action) => {
      if (state?.comparisonQuery?.comparisonQuery?.sellers) {
        state.comparisonQuery.comparisonQuery.sellers = sortSeller(action.payload)
        lsSet(state.comparisonQuery)
      }
    },
    filterRemoveVendor: (state, action) => {
      state.comparisonQuery.comparisonQuery.sellers = sortSeller(state.comparisonQuery.comparisonQuery.sellers.filter(item => action.payload.vendor !== item.vendor))
      lsSet(state.comparisonQuery)
    },

    filterDate: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.earliestLaunchDate = action.payload
      lsSet(state.comparisonQuery)
    },

    filterLaunchBeforeDate: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.launchBeforeDate = action.payload
      lsSet(state.comparisonQuery)
    },

    filterNoSize: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.noSize = action.payload
      lsSet(state.comparisonQuery)
    },

    filterNoHistoricalSize: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.noHistoricalSize = action.payload
      lsSet(state.comparisonQuery)
    },
    
    filterNoUnavailableSize: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.noUnavailableSize = action.payload
      lsSet(state.comparisonQuery)
    },

    filterNoHarmonizedSize: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.noHarmonizedSize = action.payload
      lsSet(state.comparisonQuery)
    },

    filterZalandodeepShopEmptyString: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.zalandodeepShopEmptyString = action.payload
      lsSet(state.comparisonQuery)
    },
    
    filterIgnoreNoAvailableSize: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.ignoreNoAvailableSize = action.payload
      lsSet(state.comparisonQuery)
    },

    setFilterPriceRange: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.priceRange = {
        ...state.comparisonQuery.comparisonQuery.collection.query.priceRange,
        ...action.payload,
      }
      lsSet(state.comparisonQuery)
    },
    
    filterMaterial: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.materialFilter = {
        ...state.comparisonQuery.comparisonQuery.collection.query.materialFilter,
        ...action.payload,
      }
      lsSet(state.comparisonQuery)
    },

    filterExcludedProductsByVendor(state, action) {
      state.excludedProducts[action.payload.vendor] = action.payload.products || []
      lsSetExclude(state.excludedProducts)

    },
    filterExcludedProducts(state, action) {

      lsSet(state.comparisonQuery)
    },
    filterIncludeUnset: (state, action) => {
      lsSet(state.comparisonQuery)
    },
    filterMarket: (state, action) => {
      state.comparisonQuery.comparisonQuery.customer_seller.region = action.payload.key
      state.comparisonQuery.comparisonQuery.collection.query.regions = action.payload
      lsSet(state.comparisonQuery)
    },
    filterGender: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.targetGroups = uniq(action.payload).sort()
      lsSet(state.comparisonQuery)
    },
    /**
     * @deprecated  06/10/2021
     *  can been deleted, it's deleted in useReadCache file
     *  filter out the old query.targetGroups items which has not beend included by action.payload
     * @param state
     * @param action
     */
    refreshOldQueryGender: (state, action) => {
      if (!state.comparisonQuery.comparisonQuery) {
        state.comparisonQuery.comparisonQuery = COMPARISON_QUERY// mparisonQuery
      } else if (state.comparisonQuery.comparisonQuery.collection?.query) {
        state.comparisonQuery.comparisonQuery.collection.query.targetGroups = uniq(state.comparisonQuery.comparisonQuery.collection.query.targetGroups || []).filter(item => action.payload.indexOf(item) > -1).sort()
      }
      lsSet(state.comparisonQuery)

    },
    /**
     * 初始化 Filter 值
     */
    initEmptyFilter: (state, action) => {
      if (!state.comparisonQuery.comparisonQuery) {
        state.comparisonQuery.comparisonQuery = COMPARISON_QUERY// mparisonQuery
      }
      if (Object.keys(action.payload || {}).length) {
        const { categories } = state.comparisonQuery.comparisonQuery.collection.query
        const query = {
          properties:{},
          materialFilter:emptyMaterialFilter,
          colorFilter:emptyColorFilter,
          sizeFilter: defaultSizeFilter,
          packFilter: defaultPackFilter,
          taxFilter: defaultTaxFilter,
          market:[],
          ...state.comparisonQuery.comparisonQuery.collection.query,
          priceFilter: {
            ...action.payload.priceFilter || defaultPriceFilter,
            ...state.comparisonQuery.comparisonQuery.collection.query.priceFilter,
          },
          priceRange:{
            ...action.payload.priceRange || defaultPriceRange,
            ...state.comparisonQuery.comparisonQuery.collection.query.priceRange,
          },
          // categories 已存在，使用已存在的值，不存在使用初始值
          // 此处初始值为解决在Filter.categories 没有选择Category初始化第一个Category问题
          categories: (categories?.length ? categories : action.payload.categories ?? []).sort(),
        }
        
        /**
         * 初始化 Filter > Gender
         */
        if (!query.targetGroups.length && action.payload.targets?.length) {
          query.targetGroups = uniq(action.payload.targets)
        }

        if (Object.keys(action.payload.region || {}).length) {
          query.regions = {
            ...action.payload.region,
            /**
              * the priority of default currency 
              * 1. localstorage (last applied)
              * 2. last saved
              * 3. region-currency map
              */
            ...state.comparisonQuery.comparisonQuery.collection.query.regions,
          } //
        }
        state.comparisonQuery.comparisonQuery.collection.query = query
        if (!state.comparisonQuery?.comparisonQuery?.market) state.comparisonQuery.comparisonQuery.market = []
        
        // 初始化 Filter > Competitor 值
        if (action.payload?.sellers?.length && Array.isArray(state.comparisonQuery?.comparisonQuery?.sellers) && !state.comparisonQuery.comparisonQuery?.sellers?.length) {
          state.comparisonQuery.comparisonQuery.sellers = action.payload?.sellers
        }
      }
      lsSet(state.comparisonQuery)

    },
    filterSortBy: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.sortField = action.payload
      state.lookBookQuery.comparisonQuery.lookbook.query.sortField = action.payload
      lsSet(state.comparisonQuery)
    },

    cleanFilterProperties: state => {
      state.filters.properties = {}
    },
    setCategoriesAndSellers: (state, action) => {
      if (action.payload) {
        if (action.payload.categories) {
          state.comparisonQuery.comparisonQuery.collection.query.categories = uniq(action.payload.categories).sort()
        }
        if (action.payload.sellers) {
          state.comparisonQuery.comparisonQuery.collection.query.sellers = sortSeller(action.payload.sellers)
          if (action.payload.sellers.find(item => item.region === 'World' && item.vendor === 'Market')) {
            state.comparisonQuery.comparisonQuery.market = sortSeller(action.payload.sellers.filter(item => item.vendor !== 'Market'))
          } else {
            state.comparisonQuery.comparisonQuery.market = []
          }
          state.comparisonQuery.comparisonQuery.sellers = sortSeller(action.payload.sellers)
        }
      }
      lsSet(state.comparisonQuery)
    },
    cleanAllFilter: state => {
      state.filters = {
        categories: [],
        categoryTree: '',
        earliestLaunchDate: defaultEarliestLaunchDate,
        launchBeforeDate: defaultLaunchBeforeDate,
        priceFilter: defaultPriceFilter,
        priceRange: defaultPriceRange,
        regions: {},
        sortField: defaultSortField,
        targetGroups: [],
      }
      state.excludedProducts = {}// @todo init from storage
      state.categories = []
      state.vendor = []
    },

    setFilter: (state, action) => {
      state.filters = action.payload.query

      state.categories = action.payload.query.categories
      action.payload.comparisons.map(n => {
        state.vendor = n.sellers
      })
    },

    setLastQuery: (state, action) => {
      if (
        action.payload &&
        action.payload.latestCollection &&
        action.payload.latestCollection.query
      ) {

        state.filters = action.payload.latestCollection.query

        state.categories = action.payload.latestCollection.query.categories
      }
    },

    setSelectionFilter: (state, action) => {
      if (action.payload) {

        // state.comparisonQuery = action.payload;
        const { payload } = action
        state.comparisonQuery.comparisonQuery.id = payload.comparisons.id
        state.comparisonQuery.comparisonQuery.name = payload.comparisons.name
        state.comparisonQuery.comparisonQuery.sellers = sortSeller(payload.comparisons.sellers)
        state.comparisonQuery.comparisonQuery.createdAt = payload.comparisons.createdAt
        state.comparisonQuery.comparisonQuery.updatedAt = ''
        state.comparisonQuery.comparisonQuery.collection.id = payload.id
        state.comparisonQuery.comparisonQuery.collection.name = payload.name

        state.comparisonQuery.comparisonQuery.collection.query = payload.query && payload.query.categories?.length ? payload.query : queryInit
        state.comparisonQuery.comparisonQuery.collection.ownerAccountId = payload.ownerAccountId
        state.comparisonQuery.comparisonQuery.collection.isShared = false
        state.comparisonQuery.comparisonQuery.collection.createdAt = payload.createdAt
        state.comparisonQuery.comparisonQuery.collection.updatedAt = ''
        state.comparisonQuery.comparisonQuery.collection.hasTriggers = false
        state.comparisonQuery.comparisonQuery.collection.favorite = false
        state.comparisonQuery.comparisonQuery.collectionId = payload.id
        state.locationReload = true
        state.assortmentCategorys = []
        state.currentBlack = {}

        state.excludedProducts = {}// @todo init from storage

      }
      lsSet(state.comparisonQuery)

    },
    setCustomerSeller: (state, action) => {
      if (action.payload && action.payload.region) {
        state.comparisonQuery.comparisonQuery.customer_seller = action.payload
        lsSet(state.comparisonQuery)
      }
    },
    setGroupData: (state, action) => {
      if (action.payload) {
        state.groupData = action.payload
      }
    },
    setComparePreData: (state, action) => {
      if (action.payload) {
        state.comparePreData = action.payload
      }
    },
    setLookbook: (state, action) => {
      state.lookBookQuery.comparisonQuery.id = action.payload.comparisonId
      state.lookBookQuery.comparisonQuery.target = 'analysisTarget'
      state.lookBookQuery.comparisonQuery.lookbook.id = action.payload.id
      state.lookBookQuery.comparisonQuery.lookbook.name = action.payload.name
      lsSetFn(getCurrentUser() + LOOK_BOOK, JSON.stringify(action.payload))
      lsSetLookbook(state.lookBookQuery)
    },
    setLookbookSellers: (state, action) => {
      state.lookBookQuery.comparisonQuery.sellers = sortSeller(action.payload)
      lsSetLookbook(state.lookBookQuery)
    },
    setIsReload: (state, action) => {
      state.locationReload = action.payload
    },
    setAssortmentCategorys: (state, action) => {
      state.assortmentCategorys = action.payload
    },
    setCurrentBlack: (state, action) => {
      const data = action.payload // {k:'[vendor]-[collection-name]',v:{[nornaid]:[nornaid]}}
      const currentTmp = data.v
      const current = state.currentBlack[data.k] || {}
      Object.keys(current).map(key => {
        if (current[key]) {
          delete current[key]
        } else {
          currentTmp[key] = key//
        }
      })
      state.currentBlack = {
        ...state.currentBlack,
        [data.k]: currentTmp,
      }
    },
    setReloadDate: (state, action) => {
      state.reloadData = action.payload
    },
    setFilterCountryAndCompetitors: (state, action) => {
      const country: string = action.payload.region
      const sellers = action.payload.competitors
      if (!country) return
      state.comparisonQuery.comparisonQuery.collection.query.regions.key = country    // Filter.Country
      state.comparisonQuery.comparisonQuery.sellers = sellers   // Filter.Competitor
      lsSet(state.comparisonQuery)
    },
    cleanFilter: (state, action) => {
      const region = state.comparisonQuery.comparisonQuery?.collection?.query?.regions?.key
      const currency = getCurrencyByRegion(region)
      const payload = action.payload ?? {}
      const comparisonQuery = {
        comparisonQuery: {
          id: payload.comparisonId || '',
          name: '',
          customer_seller: state.comparisonQuery.comparisonQuery?.customer_seller,
          sellers: payload?.sellers || [],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          collection: {
            id: payload.collectionId || '',
            name: '',
            query: {
              ...queryInit,
              categories: payload.categories || [],
              targetGroups: payload.targets || [],
              regions: { key: region, currency },
            },
            includedProducts: null,
            excludedProducts: [],
            ownerAccountId: '',
            isShared: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            hasTriggers: false,
            favorite: false,
          },
          collectionId: payload.collectionId || '',
          lookbookId: null,
          isPinned: false,
          pinnedAnalysis: null,
          selectedAnalysis: null,
          excludedProducts: [],
          favorite: false,
          priceIndexDate: null,
          market: [],
        },
        seller: {},
        date: '',
        isOurs: false,
        isPriceIndex: false,
      }

      state.comparisonQuery = comparisonQuery
      state.excludedProducts = {}// @todo init from storage

      lsSet(state.comparisonQuery)
    },
    initFilterFromLogin: (state, action) => {
      state = initStateFn()
      lsSet(state.comparisonQuery)
    },
    addLookbook: (state, action) => {

      let combine = [ ...state.lookBookQuery.comparisonQuery.sellers.filter(item => item.lookbookId !== action.payload[0].lookbookId), ...action.payload ].filter(item => item.isSelected).map(item => {
        item.lookbookId = item.lookbookId || item.id
        return item
      })
      combine = Object.values(combine.reduce((a, b) => {
        a[b.lookbookId] = b
        return a
      }, {}))
      state.lookBookQuery.comparisonQuery.sellers = sortSeller([
        ...combine,
      ])
      lsSetLookbook(state.lookBookQuery)
    },
    updateLookbooks: (state, action) => {
      state.lookBookQuery.comparisonQuery.sellers = sortSeller([ ...action.payload ])
      lsSetLookbook(state.lookBookQuery)
    },
    initFilter(state, action) {
      const { payload } = action
      
      if (!state.comparisonQuery.comparisonQuery) {
        state.comparisonQuery.comparisonQuery = {
          ...state.comparisonQuery.comparisonQuery || {},
          ...payload,
        }
          
      } else {
        if (!payload.customer_seller && state.comparisonQuery.comparisonQuery.customer_seller) { // init customer_seller
          payload.customer_seller = state.comparisonQuery.comparisonQuery.customer_seller
        }
        if (payload.collection.query?.categories) {
          payload.collection.query.categories = uniq([ ...payload.collection.query.categories.map(category =>
            category?.category ?? category) ]).sort()
          
          setSelectAllCategory(payload?.collection?.query?.categories?.indexOf?.(CATEGORY_TOTAL) > -1)

          delete payload.collection.query.blackProperties
          delete payload.collection.query.includeUnsetCategories
        }
        
        state.comparisonQuery.comparisonQuery = {
          ...state.comparisonQuery.comparisonQuery,
          ...payload,
        }
      }
      lsSet(state.comparisonQuery)
    },
    initFromSharedFilter(state, action) {
      const payload = cloneDeep(action.payload)
      state.comparisonQuery = payload
      lsSet(state.comparisonQuery)
    },

    updateComparisonQuery(state, action) {
      state.comparisonQuery.comparisonQuery = action.payload
      lsSet(state.comparisonQuery)
    },

    /**
     * 更新 taxFilter
     */
    updateTaxFilter(state, action) {
      state.comparisonQuery.comparisonQuery.collection.query.taxFilter = action.payload
      lsSet(state.comparisonQuery)
    },

    /**
     * 更新 Size filter 值
     */
    updateSizeFilter(state, action) {
      state.comparisonQuery.comparisonQuery.collection.query.sizeFilter = action.payload
      lsSet(state.comparisonQuery)
    },

    updateCategories(state, action) {
      state.comparisonQuery.comparisonQuery.collection.query.categories = action.payload
      lsSet(state.comparisonQuery)
    },

    updateFilterGender(state, action) {
      const genderList = uniq(action.payload).sort()
      if (intersection(genderList, [ 'Men', 'Women' ]).length) {
        state.comparisonQuery.comparisonQuery.collection.query.sizeFilter = defaultSizeFilter
      }
      state.comparisonQuery.comparisonQuery.collection.query.targetGroups = genderList
      lsSet(state.comparisonQuery)
    },  

    updateFilterCountry: (state, action) => {
      const country: string = action.payload
      if (!country) return
      state.comparisonQuery.comparisonQuery.collection.query.regions.key = country    // Filter.Country
      if (!isDashboardPages()) {
        state.comparisonQuery.comparisonQuery.sellers = []
      }
      lsSet(state.comparisonQuery)
    },

    updateFilterCurrency: (state, action) => {
      const currency: string = action.payload
      if (!currency) return
      state.comparisonQuery.comparisonQuery.collection.query.regions.currency = currency
      lsSet(state.comparisonQuery)
    },

    updateFilterPricePerspective: (state, action) => {
      state.comparisonQuery.comparisonQuery.collection.query.priceFilter = {
        ...state.comparisonQuery.comparisonQuery.collection.query.priceFilter,
        ...action.payload,
      }
      lsSet(state.comparisonQuery)
    },

    updateFilterForGate(state, action) {
      const { category, country, currency, competitor, market } = action.payload
      state.comparisonQuery.comparisonQuery.collection.query.categories = category
      state.comparisonQuery.comparisonQuery.collection.query.regions.key = country
      state.comparisonQuery.comparisonQuery.collection.query.regions.currency = currency
      state.comparisonQuery.comparisonQuery.sellers = sortSeller(competitor || [])
      state.comparisonQuery.comparisonQuery.market = sortBy(market, 'vendor')
      lsSet(state.comparisonQuery)
    },

    /**
     * 更新 Dashboard filter
     */
    updateFilterForDashboard(state, action) {
      if (!state?.comparisonQuery?.comparisonQuery?.collection) {
        return
      }
      // Country 和 Currency 不要改
      const regions = state.comparisonQuery.comparisonQuery.collection.query.regions
      const filterCountry = regions.key
      // Gender 选全部, 不包含 Disabled
      const targetGroups = getFilterGenderAllValue()
      // Category 选全部
      const categories = getAllFilterCategoryValue()
      // Competitor 选全部
      const sellers = getAllFilterCompetitorValue(filterCountry)

      const comparisonQuery = JSON.parse(JSON.stringify(state.comparisonQuery.comparisonQuery))
      comparisonQuery.sellers = sortSeller(sellers || [])

      // 从分析页跳转到 Dashboard 页面
      if (!action?.payload) {
        comparisonQuery.collection.query = {
          ...queryInit,
          regions,
          targetGroups,
          categories,
        }
      }
      // Dashboard 当前页面刷新
      else {
        comparisonQuery.collection.query.regions = regions
        comparisonQuery.collection.query.targetGroups = targetGroups
        comparisonQuery.collection.query.categories = categories
      }
      
      state.comparisonQuery.comparisonQuery = comparisonQuery
      lsSet(state.comparisonQuery)
    },

    /**
     * 更新 Price range 值
     * @param state 
     * @param action 
     */
    updatePriceRangeFilter(state, action) {
      if (!state?.comparisonQuery?.comparisonQuery) return
      state.comparisonQuery.comparisonQuery.collection.query.priceRange = action.payload
      lsSet(state.comparisonQuery)
    },
  },
})
export const {
  initFilter,
  initFromSharedFilter,
  updateComparisonQuery,
  initFilterFromLogin,
  cleanFilter,
  setReloadDate,
  addLookbook,
  updateLookbooks,
  filterRemoveVendor,
  setLookbookSellers,
  setFilterCategory,
  filterCollection,
  updateFilters,
  setCategoriesAndSellers,
  filterSortBy,
  initEmptyFilter,
  refreshOldQueryGender,
  filterGender,
  filterMarket,
  setComparePreData,
  setFilterPriceRange,
  filterMaterial,
  filterExcludedProductsByVendor,
  filterExcludedProducts,
  setGroupData,
  setSelectionFilter,
  filterCategory,
  filterIncludeUnsetCategories,
  filterBlackProperties,
  filterProperties,
  filterVendor,
  cleanFilterProperties,
  cleanAllFilter,
  setFilter,
  filterDate,
  filterLaunchBeforeDate,
  filterNoSize,
  filterNoHistoricalSize,
  filterNoUnavailableSize,
  filterNoHarmonizedSize,
  filterZalandodeepShopEmptyString,
  filterIgnoreNoAvailableSize,
  setLastQuery,
  setCustomerSeller,
  setLookbook,
  filterIncludeUnset,
  setIsReload,
  setAssortmentCategorys,
  setCurrentBlack,
  setFilterCountryAndCompetitors,
  setFilterDisplayHeight,
  setLastComparisonQueryForLookbook,
  setDashboardCategories,
  setLoadingObj,
  clearLoadingObj,
  updateTaxFilter,
  updateSizeFilter,
  updateCategories,
  updateFilterGender,
  updateFilterCountry,
  updateFilterCurrency,
  updateFilterPricePerspective,
  updatePriceRangeFilter,
  updateFilterForGate,
  updateFilterForDashboard,
} = filterSlice.actions
// Other code such as selectors can use the imported `RootState` type
export const selectAssortmentCategorys = (state: RootState) => state.filter.assortmentCategorys
export const selectCurrentBlack = (state: RootState) => state.filter.currentBlack
export const selectFilter = (state: RootState) => state.filter.comparisonQuery.comparisonQuery.collection?.query
export const selectCategory = (state: RootState) => {
  const categories = state.filter.comparisonQuery?.comparisonQuery &&
    state.filter.comparisonQuery?.comparisonQuery.collection?.query?.categories
  return categories || []
}
export const selectProperty = (state: RootState) => 
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.properties || {}
export const selectVendor = (state: RootState): SellerType[] =>
  state.filter.comparisonQuery?.comparisonQuery?.sellers || []
export const selectPrice = (state: RootState) =>
  state.filter.comparisonQuery?.comparisonQuery &&
  state.filter.comparisonQuery?.comparisonQuery.collection?.query?.priceFilter
export const selectDate = (state: RootState) =>
  state.filter.comparisonQuery?.comparisonQuery &&
  state.filter.comparisonQuery?.comparisonQuery.collection?.query?.earliestLaunchDate

export const selectLaunchBeforeDate = (state: RootState) =>
  state.filter.comparisonQuery?.comparisonQuery &&
  state.filter.comparisonQuery?.comparisonQuery.collection?.query?.launchBeforeDate

// regions
export const selectMarket = (state: RootState) =>
  state.filter.comparisonQuery?.comparisonQuery &&
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.regions
export const selecGender = (state: RootState) =>
  state.filter.comparisonQuery?.comparisonQuery &&
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.targetGroups
export const selecSortBy = (state: RootState) =>
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.sortField || defaultSortField

/**
 * 当前登录用户选择的 License
 * e.g { vendor: 'salsajeans', region: 'Europe' }
 */
export const selectCustomerSeller = (state: RootState) =>
  // eslint-disable-next-line
  state.filter?.comparisonQuery?.comparisonQuery?.customer_seller || { vendor: '', region: '' };

export const selectComparisonQuery = (state: RootState) => state.filter?.comparisonQuery
export const selectCollection = (state: RootState) => state.filter?.comparisonQuery?.comparisonQuery

export const selectComparePerCollection = (state: RootState) => state.filter?.comparisonQuery?.comparisonQuery?.collection
export const selectGroupData = (state: RootState) => state.filter?.groupData
export const selectComparePreData = (state: RootState) => state.filter?.comparePreData
export const selectLookBookQuery = (state: RootState) => state.filter.lookBookQuery
export const selectLocationReload = (state: RootState) => state.filter.locationReload
export const selectreloadDate = (state: RootState) => state.filter.reloadData
export const selectExcludedProducts = (state: RootState) => state.filter.excludedProducts

/**
 * 获取 Filter > Currency 选中值
 * 格式：'EUR'
 */
export const selectFilterCurrency = (state: RootState): string =>
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.regions?.currency || ''

/**
 * 获取 Filter > Country 选中值
 * 格式：'Finland'
 */
export const selectFilterCountry = (state: RootState): string =>
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.regions?.key || ''

export const selectFilterOldCountry = (state: RootState): string =>
  state.filter.comparisonQuery?.oldRegions?.key || ''

/**
 * 获取 Filter > Price filter 选中值
 * 格式: 'All full'
 */
export const selectFilterPriceFilter = (state: RootState): string =>
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.priceFilter?.val || ''

export const selectFilterTax = (state: RootState): TaxFilterType =>
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.taxFilter || defaultTaxFilter

export const selectFilterMaterial = (state: RootState) =>
  state.filter.comparisonQuery?.comparisonQuery?.collection?.query?.materialFilter || emptyMaterialFilter

export const selectFilterSize = (state: RootState) =>
  state?.filter?.comparisonQuery?.comparisonQuery?.collection?.query?.sizeFilter || defaultSizeFilter

export const selectFilterDisplayHeight = (state: RootState): number =>
  state.filter?.filterDisplayHeight || 120

export const selectLastComparisonQueryForLookbook = (state: RootState) => 
  state.filter.lastComparisonQueryForLookbook || undefined

export const selectDashboardCategories = (state: RootState) => 
  state.filter.dashboardCategories || []

export const selectLoadingObj = (state: RootState) =>
  state.filter.loadingObj || {}

export const selectFilterNoSize = (state: RootState) =>
state?.filter?.comparisonQuery?.comparisonQuery?.collection?.query?.noSize || defaultNoSize

export const selectFilterNoHistoricalSize = (state: RootState) =>
state?.filter?.comparisonQuery?.comparisonQuery?.collection?.query?.noHistoricalSize || defaultNoHistoricalSize

export const selectFilterNoUnavailableSize = (state: RootState) =>
state?.filter?.comparisonQuery?.comparisonQuery?.collection?.query?.noUnavailableSize || defaultNoUnavailableSize

export const selectFilterNoHarmonizedSize = (state: RootState) =>
state?.filter?.comparisonQuery?.comparisonQuery?.collection?.query?.noHarmonizedSize || defaultNoHarmonizedSize

export const selectFilterZalandodeepShopEmptyString = (state: RootState) =>
state?.filter?.comparisonQuery?.comparisonQuery?.collection?.query?.zalandodeepShopEmptyString || defaultZalandodeepShopEmptyString

export const selectFilterIgnoreNoAvailableSize = (state: RootState) =>
state?.filter?.comparisonQuery?.comparisonQuery?.collection?.query?.ignoreNoAvailableSize || defaultIgnoreNoAvailableSize

export default filterSlice.reducer
