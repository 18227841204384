import { TdPriceRangeArgType } from 'graphql/nornaapi/types'

export const formatArgForTdPriceRange = (query:any):TdPriceRangeArgType => ({
        // Array<string>
        competitors: query.query.competitors.split(','),
        // Array<string>
        categories: query.data.all_category,
        // Record
        priceFilter: query.data.price_filter,
    
        // Array<string>
        market: query.data.market,
    
        // String
        region: query.query.region,
    
        // String
        currency: query.query.currency,
    
        // String
        customer: query.query.customer,
    
        // String
        includeDiscount: query.query.include_discount,
    
        // String
        date: query.query.date,
        // Array<string>
        targetGroup: query.query.target_group.split(','),
      } as TdPriceRangeArgType )

export const formatArgForNewPricePriceRange = (query:any):TdPriceRangeArgType => ({
        // Array<string>
        competitors: query.query.competitors.split(','),
        // Array<string>
        categories: query.categories??[],
        // Record
        priceFilter: query.data.price_filter,
    
        // Array<string>
        market: query.data.market,
    
        // String
        region: query.query.region,
    
        // String
        currency: query.query.currency,
    
        // String
        customer: query.query.customer,
    
        // String
        includeDiscount: query.query.include_discount,
    
        // String
        date: query.query.date,
        // Array<string>
        targetGroup: query.query.target_group.split(','),
      } as TdPriceRangeArgType )
