export type TargetGroupProps = {
  key: string;
  description: string;
  isSelected: boolean;
}

export const TARGET_GROUPS: TargetGroupProps[] = [
  { key: 'Men', description: 'Men', isSelected: false },
  { key: 'Women', description: 'Women', isSelected: false },
  { key: 'Children', description: 'Children', isSelected: false },
]
