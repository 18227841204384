import React, { useImperativeHandle ,forwardRef, useContext } from 'react'
import { useDialog } from 'hooks/useDialog'
import { ActionBoxContext } from './context'

export interface DialogRefType  {
  closeDialog:Function;
  openDialog:Function;
}
export const Dialog = forwardRef((props,ref) => {
  const actionBox = useContext(ActionBoxContext)
    const { openDialog,closeDialog } = useDialog(actionBox)
    useImperativeHandle(ref, ():DialogRefType => ({
      closeDialog,
      openDialog,
    }), [ closeDialog,openDialog ])
  
    return <div />
})

Dialog.displayName = 'Dialog' 
