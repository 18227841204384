import React, { useRef, useState } from 'react'
import { useLoadingBar } from 'hooks'
import { useModifyCollection, useCollectionList, useRemoveCollection } from 'hooks/api/useCollectionData'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { Collection } from 'features/filters/collectionDataSlice.types'
import { Spin } from 'componentsv2'
import { useHistory } from 'react-router-dom'
import { NoCategory } from 'components'
import { Flex } from 'componentsv2/Flex'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import { SizedBox } from 'componentsv2/SizedBox'
import { Box } from 'componentsv2/Box'
import { ModifyCollectionModal } from './components/ModifyCollectionModal'
import { CollectionItem } from './components/CollectionItem/CollectionItem'
import styles from './styles.module.scss'

export const MySavedFilters = () => {
    const history = useHistory()
    useLoadingBar()
    const { collectionList, refreshCollectionList } = useCollectionList()
    const { modifyCollection } = useModifyCollection()
    const { removeCollection } = useRemoveCollection()

    /* **************************** 修改 collection ********************************** */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    const [ modifyLoading, setModifyLoading ] = useState(false)

    const onShowEditModal = (collection: Collection) => {
        const dialogId = 'modify_collection_name'
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog(dialogId,
            <ModifyCollectionModal
                name={collection.name}
                closeModal={() => {
                    closeFn(dialogId)
                }}
                onSave={async (name: string) => {
                    setModifyLoading(true)
                    await modifyCollection({ id: collection.id, name })
                    await refreshCollectionList()
                    setModifyLoading(false)
                }}
                isOpen={true}
            />,
        )
    }

    /* **************************** 删除 collection ********************************** */
    const [ removeLoading, setRemoveLoadng ] = useState(false)

    const onRemove = async (collection: any) => {
        setRemoveLoadng(true)
        await removeCollection(collection.id)
        await refreshCollectionList()
        setRemoveLoadng(false)
    }

    const onGoBack = () => {
        history.goBack()
    }

    let content = <NoCategory>You have no saved filters</NoCategory>

    if (collectionList.length) {
        content = (
            <Flex flexWrap="wrap">
                {
                    collectionList.map(collection => {
                        return (
                            <CollectionItem
                                key={collection.id}
                                collection={collection}
                                onRemove={onRemove}
                                onClickName={onShowEditModal}
                            />
                        )
                    })
                }
            </Flex>
        )
    }

    return (
        <Spin spinning={removeLoading || modifyLoading}>
            <Box display="flex" fullWidth>
                <InfoBox
                    iconSty={{ margin: '0 10px' }}
                    containerSty={{ left: '0px' }}
                    title="title"
                    text="tips content"
                >
                    <TipsContent sty={{ padding: 0 }} className={styles.tips}>
                        The "My saved filter" area shows the filters that you have saved and allows you to either rename or delete them.
                    </TipsContent>
                </InfoBox>
                <Box flexGrow={1} />
                <div className={styles.backBtn} onClick={onGoBack}>&lt;&nbsp;&nbsp;Back</div>
            </Box>
            <SizedBox type="block" height={20} />
            <Dialog ref={dialogRef} />
            {content}
        </Spin>
    )
}
