import { cloneDeep } from 'lodash'
import { arrayUtils } from 'norna-uikit'
import { getVendorNameByCode } from 'utils'
import { storage } from 'utils/storage'

export function formatMetricLabel(label: string) {
    if (!label.toLowerCase().includes('sold out')) {
        return label
    }
    const mapper = {
        '<S sold out': 'XS and smaller',
        'S sold out': 'S',
        'M sold out': 'M',
        'L sold out': 'L',
        'XL sold out': 'XL',
        '>XL sold out': 'XXL and larger',
    }
    return mapper[label] || label
}

export function sortMetricLabel(metricValue: string[]) {
    const sizeList = [
        'XS and smaller', 
        'S', 
        'M', 
        'L', 
        'XL',
        'XXL and larger',
    ]
    if (arrayUtils.isSuperset(sizeList, metricValue)) {
        const newMetricValue = cloneDeep(metricValue).sort((m,n) => {
            const firstValue = sizeList.indexOf(m)
            const secondValue = sizeList.indexOf(n)
            return firstValue - secondValue
        })
        return newMetricValue
    }
    const customerVendor = storage.getCustomerVendor()
    const vendorList = [
        customerVendor,
        customerVendor === 'tommyhilfiger' ? 'calvinklein' : 'tommyhilfiger', 
        'lacoste', 
        'boss', 
        'ralphlauren', 
        'hm',
        'zara',
        'Calvin Klein@zalandodeep', 
        'Tommy Hilfiger@zalandodeep',
    ].map(item => getVendorNameByCode(item))
    const newMetricValue = cloneDeep(metricValue)
        .sort((a, b) => {
            const aIndex = vendorList.findIndex(item => item === a)
            const bIndex = vendorList.findIndex(item => item === b)
            return aIndex - bIndex
        })
    return newMetricValue
}
