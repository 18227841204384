import ChatBox from 'componentsv2/ChatBox'
import React from 'react'

const TestPage = () => {
    return (
        <>
            <ChatBox />
        </>
    )
}

export default TestPage
