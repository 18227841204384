import { configureStore } from '@reduxjs/toolkit'
import filtersReducer from 'features/filters/filtersSlice'
import cacheDataReducer from 'features/filters/cacheDataSlice'
import qaDataReducer from 'features/filters/qaDataSlice'
import lookbookDataReducer from 'features/filters/lookbookDataSlice'
import memoDataReducer from 'features/filters/memoDataSlice'
import moduleDataSlice from 'features/filters/moduleDataSlice'
import globalDataSlice from 'features/filters/globalDataSlice'
import collectionDataSlice from 'features/filters/collectionDataSlice'
import dynamicDashboardSlice from 'features/filters/dynamicDashboardSlice'

export const store = configureStore({
  reducer: {
    cache:cacheDataReducer,
    filter: filtersReducer,
    qa: qaDataReducer,
    lookbook: lookbookDataReducer,
    collection: collectionDataSlice,
    memo: memoDataReducer,
    module: moduleDataSlice,
    global: globalDataSlice,
    dynamicDashboard: dynamicDashboardSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
})
