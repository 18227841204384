import React, { useState } from 'react'
import classnames from 'classnames'
import { CloseIcon } from 'assets/icons'
import { usePageContainerHeight } from 'hooks/useWindow'
import { SizedBox } from 'componentsv2/SizedBox'
import { Slider } from 'componentsv2/Slider'
import { InputNumber } from 'druikit'
import { Button } from 'components/form-elements/Button'
import { InfoBox } from 'components/common/InfoBox'
import { FILTER_DIALOG_CLASS, IS_LIVE_ENV } from 'consts'
import { defaultSizeFilter } from 'features/filters/filtersSlice'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Checkbox } from 'componentsv2/form-elements/Checkbox'
import { SizeFilterProps, SizeFilterValue } from './types'
import styles from './styles.module.scss'
import { TipsContent } from '../../../Tips'

export const SizeGuideIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="#AFAFAF">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M21 6H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10H3V8h2v4h2V8h2v4h2V8h2v4h2V8h2v4h2V8h2v8z" />
        </svg>
    )

export const SizeFilter = ({
    onClose,
    onOk,
    modalMaskStyle = {},
    value = defaultSizeFilter,
}: SizeFilterProps) => {
    const padNum = IS_LIVE_ENV ? 0 : 1
    const [ data, setData ] = useState<SizeFilterValue>({ checked: false, min: defaultSizeFilter.min + padNum, max: defaultSizeFilter.max + padNum, isNoSizes: false })

    useDeepCompareEffect(() => {
        setData({
            checked: value?.checked,
            isNoSizes: IS_LIVE_ENV ? false : value?.isNoSizes || false,
            min: value.min + padNum,
            max: value.max + padNum,
        })
    }, [ value ])

    /* ********************************** Size Guide 模态框 ******************************************* */
    const [ sizeGuideOpen, setSizeGuideOpen ] = useState(false)

    return (
        <>
            <div className={`material-filter ${FILTER_DIALOG_CLASS}`} onMouseDown={e => e.stopPropagation()}>
                <div
                    className={styles.modal}
                    style={modalMaskStyle}
                />
                <div className={classnames(styles.sizeFilter)}>
                    <div className={styles.close}>
                        <CloseIcon onClick={() => { onClose?.(data) }} />
                    </div>
                    <div className={styles.content}>
                        <SizedBox height={28} />
                        <div className={styles.title}>Children's size range (cm)</div>
                        <div className={styles.subtitle}>Only affects the relevant categories for children</div>
                        <SizedBox height={22} />
                        <Slider
                            className={styles.slider}
                            min={IS_LIVE_ENV ? 40 : -padNum}
                            max={defaultSizeFilter.max + padNum}
                            value={[ data?.min || 0, data?.max || 170 ]}
                            onChange={(_, value) => {
                                const min = Math.max(padNum, value[0])
                                const max = Math.max(padNum, value[1])
                                setData({ ...data, min, max })
                            }}
                            step={1}
                            valueLabelDisplay="off"
                            disabled={data.isNoSizes}
                        />
                        <SizedBox height={29} />
                        <div className={styles.sliderValuesContainer}>
                            <InputNumber
                                value={data.min - padNum}
                                onBlur={value => setData({ ...data, min: Number(value) + padNum })}
                                min={IS_LIVE_ENV ? 40 : -padNum}
                                max={data.max - padNum}
                                disabled={data.isNoSizes}
                                empty={false}
                                width={70}
                                height={28}
                                textAlign="center"
                                style={{ fontSize: 14 }}
                            />
                            -
                            <InputNumber
                                value={data.max - padNum}
                                onBlur={value => setData({ ...data, max: Number(value) + padNum })}
                                min={data.min - padNum}
                                max={defaultSizeFilter.max + padNum}
                                disabled={data.isNoSizes}
                                empty={false}
                                width={70}
                                height={28}
                                textAlign="center"
                                style={{ fontSize: 14 }}
                            />
                        </div>
                        {
                            IS_LIVE_ENV ? (
                                <SizedBox height={41} />
                            ) : (
                                <>
                                    <SizedBox height={5} />
                                    <Checkbox
                                        value={data.isNoSizes}
                                        onChange={() => setData({ ...data, isNoSizes: !data?.isNoSizes })}
                                    >
                                        No sizes
                                    </Checkbox>
                                    <SizedBox height={15} />
                                </>
                            )
                        }
                        <Button
                            primary
                            mini
                            className={styles.applyBtn}
                            onClick={() => {
                                onOk?.({ ...data, checked: true, min: data.min - padNum, max: data.max - padNum })
                            }}
                        > Apply
                        </Button>
                        <SizedBox height={17} />
                        <div className={styles.guideWrapper} onClick={() => setSizeGuideOpen(!sizeGuideOpen)}>
                            Size guide <SizeGuideIcon />
                        </div>
                        <SizedBox height={16} />
                    </div>

                    <div style={{ position: 'fixed', left: '650px', top: '150px', zIndex: 999999999, opacity: sizeGuideOpen === true ? 1 : 0 }} onClick={e => e.stopPropagation()}>
                        <div
                            style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 999999999 }}
                            onClick={(e: any) => {
                                e.stopPropagation()
                                e.preventDefault()
                                setSizeGuideOpen(false)
                            }}
                        >
                            <CloseIcon />
                        </div>
                        <SizeGuide />
                    </div>
                </div>
            </div>
        </>
    )
}

export const SizeGuide = () => {
    const dataSource = [
        { size: '', age: '0 month', length: '56 cm' },
        { size: '', age: '1 month', length: '58 cm' },
        { size: '', age: '2 months', length: '60 cm' },
        { size: '', age: '3 months', length: '63 cm' },
        { size: '', age: '4 months', length: '66 cm' },
        { size: '', age: '5 months', length: '69 cm' },
        { size: '', age: '6 months', length: '71 cm' },
        { size: '', age: '7 months', length: '72 cm' },
        { size: '', age: '8 months', length: '73 cm' },
        { size: '', age: '9 months', length: '74 cm' },
        { size: '', age: '10 months', length: '75 cm' },
        { size: '', age: '11 months', length: '77 cm' },
        { size: '', age: '12 months', length: '79 cm' },
        { size: '', age: '13-14 months', length: '80 cm' },
        { size: '', age: '15 months', length: '81 cm' },
        { size: '', age: '16 months', length: '82 cm' },
        { size: '', age: '17 months', length: '83 cm' },
        { size: '', age: '18 months', length: '84 cm' },
        { size: '', age: '24 months', length: '89 cm' },
        { size: '', age: '36 months', length: '100 cm' },
        { size: '', age: '4 years', length: '106 cm' },
        { size: 'xs', age: '5 years', length: '112 cm' },
        { size: 'xs', age: '6 years', length: '118 cm' },
        { size: 's', age: '7 years', length: '119 cm' },
        { size: 's', age: '8 years', length: '128 cm' },
        { size: 'm', age: '9 years', length: '130 cm' },
        { size: 'm', age: '10 years', length: '138 cm' },
        { size: 'l', age: '11 years', length: '140 cm' },
        { size: 'l', age: '12 years', length: '149 cm' },
        { size: 'xl', age: '13 years', length: '150 cm' },
        { size: 'xl', age: '14 years', length: '156 cm' },
        { size: 'xxl', age: '15 years', length: '158 cm' },
        { size: 'xxl', age: '16 years', length: '164 cm' },
    ]

    const pageContainerHeight = usePageContainerHeight()

    return (
        <div className={styles.sizeGuideWrapper} style={pageContainerHeight > 810 ? { maxHeight: '800px', overflowY: 'scroll' } : { maxHeight: (pageContainerHeight - 50) + 'px', overflowY: 'scroll' }}>
            <div className={styles.sizeGuideTitle}>
                Size guide Children
                <InfoBox
                    iconSty={{ margin: '0 10px', fontSize: '14px' }}
                    containerSty={{ left: '-150px', top: '50px' }}
                    title="title"
                    text="tips content"
                >
                    <TipsContent sty={{ padding: 0 }}>
                        As different vendors display childrens sizes in different ways - some use centimeters, some use age, some use a "S", "L", et cetera system (but adapted for children) - we have harmonized the different size formats into centimeters to make them searchable in Norna Analytics for many vendors at once. Harmonization has been done according to the translation table below.
                    </TipsContent>
                </InfoBox>
            </div>
            <div className={styles.sizeGuideSubtitle}>Children's clothing converter for relevant categories</div>
            <SizedBox height={28} />
            <div className={styles.table}>
                <div className={styles.thead}>
                    <div className={styles.th}>Children's size</div>
                    <div className={styles.th}>Children's age</div>
                    <div className={styles.th}>Children's length</div>
                </div>
                <div className={styles.tbody}>
                    {
                        dataSource.map(item => (
                            <div key={item.length} className={styles.tr}>
                                <div className={styles.td}>{item.size}</div>
                                <div className={styles.td}>{item.age}</div>
                                <div className={styles.td}>{item.length}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
