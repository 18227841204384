import { Slider } from '@material-ui/core'
import { CloseIcon, LoadingSpinner, NoCategory } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { Text } from 'components/typography'
import useDeepCompareEffect from 'use-deep-compare-effect'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Bar } from 'libs/react-chartjs-lib'
import { styled } from '@material-ui/core/styles'
import { OverlayType, useBarSliderChartOverlay } from 'componentsv2/NornaLineChart/components/LineChart'
import { lsSet } from 'utils'
import { selectFilterCurrency } from 'features/filters/filtersSlice'
import { useSelector } from 'react-redux'
import { PRICE_RANGE_MODAL_CLASS } from 'consts'
import classNames from 'classnames'
import usePriceRange from 'hooks/usePriceRange'
import { Spin } from 'componentsv2'
import { Dragable } from 'components/common/Dragable'
// import { RadioItem } from 'componentsv2/business';
import { PriceFilterSelectionModalProps } from './types'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'

export const CustomizeSlider = styled(Slider)({
  // 2022/07/15 修改 price range 柱状图 x 轴线颜色
  // color: '#01A699',
  color: '#999',
  padding: 0,
  '& .MuiSlider-track': {
    border: 'none',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    height: 13,
    width: 13,
    backgroundColor: '#fff',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:after': {
      height: 13,
      width: 13,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      'box-shadow': '0px 0px 4px 0px rgb(0,0,0,0.7)',
    },
  },
})

export const PriceFilterSelectionModal = ({
  closeModal,
  isOpen,
  currentFilter,
  onChange,
  modalMaskStyle = {},
}: PriceFilterSelectionModalProps) => {
  const currency = useSelector(selectFilterCurrency)

  const { clearOverlay, afterDrawer } = useBarSliderChartOverlay()
  const { updateActivate, loading, max, min, bars } = usePriceRange()

  const limitRef = useRef<any>({
    min,
    max,
  })

  const [ sliderValue, setSliderValue ] = useState<any>(() => {

    const current = currentFilter.range.split(',').map((item: string) => parseInt(item, 10))
    let minData = min
    let maxData = max
    if (current[0] > minData) {
      minData = current[0] // eslint-disable-line
    }
    if (currency[1] <= maxData && current[1] >= minData) {
      maxData = current[1] // eslint-disable-line
    }

    limitRef.current = {
      min,
      max,
    }
    return [
      minData,
      maxData,
    ]
  })

  useDeepCompareEffect(() => {
    if (!currentFilter?.range) return
    let valueArr = currentFilter.range.split(',')
    if (valueArr.length !== 2) return
    valueArr = valueArr.map(item => Number(item))
    setSliderValue(valueArr)
  }, [ currentFilter || {} ])

  const sliderRef = useRef<any>()
  const barRef = useRef<any>()

  const handleRerender = useCallback((limitMax: number, limitMin: number, max: number, min: number) => {
    const jsonStr = JSON.stringify({
      limitMax: limitMax - limitMin,
      // limitMin:0,
      // limitValue:limitMax-limitMin,
      min: min - limitMin,
      max: max - limitMin,
    } as OverlayType)
    lsSet('price_range', jsonStr)

    // barRef.current?.chartInstance?.render?.()
    afterDrawer(barRef.current?.chartInstance)

  }, [ afterDrawer ])

  /**
   * ## max change effect
   */
  const maxChangeEffect = useCallback(() => {
    if (max !== limitRef.current.max || min !== limitRef.current.min) {
      limitRef.current = {
        max,
        min,
      }
      setSliderValue([ min, max ])

    }
  }, [ max, min ])

  useEffect(() => {
    maxChangeEffect()
  }, [max, min]) //eslint-disable-line

  useEffect(() => {
    handleRerender(max, min, sliderValue[1], sliderValue[0])
  }, [sliderValue]) // eslint-disable-line

  useEffect(() => {
    clearOverlay?.()
    updateActivate()
    // 
  }, []) // eslint-disable-line

  // 初始化遮罩
  useEffect(() => {
    if (barRef.current) afterDrawer(barRef.current?.chartInstance)
  }, []) //eslint-disable-line

  const savePriceFilter = useCallback(async () => {
    if (onChange) {
      onChange({
        max: sliderValue[1],
        min: sliderValue[0],
        // checked,
      })

      closeModal()
    }
  }, [ closeModal, onChange, sliderValue ])

  const labels = Array.from({ length: 10 }).map((item, index) => index)

  let contentComponent: React.ReactElement = <></> as React.ReactElement
  if (loading) {
    contentComponent = <LoadingSpinner sty={{ height: '240px' }} />
  } else if (min === 0 && max === 0) {
    contentComponent = <NoCategory >No price range data</NoCategory>
  } else {
    contentComponent = (
      <>
        <div className={styles.modalBody} onMouseDown={e => e.stopPropagation()}>
          <div className={styles.pricesContainer}>
            <Bar
              ref={barRef}
              height={100}
              type="bar"
              width={200}
              plugins={[]}
              options={{
                responsive: true,
                tooltips: {
                  enabled: false,
                },
                scales: {
                  yAxes: [
                    {
                      reverse: true,
                      display: false,
                      min: 4,
                      max: 30,
                      ticks: {
                        display: false,
                      },
                      grid: {
                        display: false,
                      },
                    },
                  ]
                  ,
                  xAxes: [
                    {
                      ticks: {
                        display: false,
                      },
                      grid: {
                        display: false,
                      },
                      barPercentage: 1.1,
                      display: false,
                    },
                  ],
                },
                elements: {
                  bar: {
                    borderWidth: 0,
                    width: 13,
                  },
                },
                position: 'center',
                legend: { display: false },
                plugins: {
                  title: {
                    display: false,
                  },
                  chartAreaBorder: {
                    borderColor: 'red',
                    borderWidth: 0,
                    borderDash: [ 5, 5 ],
                    borderDashOffset: 2,
                  },
                },
              }}
              data={{
                labels,
                datasets: [
                  {
                    label: 'Dataset',
                    data: labels.map((item, index: number) => bars[index] ?? 0),
                    backgroundColor: '#01A699',
                    width: 13,
                  },
                ],
              }}
            />
          </div>
          <div className={styles.sliderContainer}>
            <CustomizeSlider
              min={min}
              ref={sliderRef}
              max={max}
              step={1}
              value={sliderValue}
              onChange={(_event, newValue) => {

                setSliderValue(newValue)
              }}
              valueLabelDisplay="off"
            />
          </div>
          <div className={styles.sliderValuesContainer}>
            <Text medium>{numberUtils.formatNumberByComma(sliderValue[0] ?? 0)}</Text> -
            <Text medium>{numberUtils.formatNumberByComma(sliderValue[1] ?? 0)}</Text>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <Button primary onClick={savePriceFilter} disabled={false}>
            Apply
          </Button>
        </div>
      </>
    )
  }

  return (
    <Spin spinning={loading}>
      <div className={classNames(styles.modal, PRICE_RANGE_MODAL_CLASS.slice(1))} style={modalMaskStyle} />
      <div className={styles.modalContainer}>
        <Dragable width={218} height={309} x={390} y={200}>
          <div className={styles.modalWrapper}>
            <>
              <div className={styles.modalHeader}>
                <div className={styles.modalTitle}>
                  {/* <RadioItem
                  setChecked={setChecked}
                  checked={checked}
                /> */}
                  <Text medium>Price range ({currency})</Text>
                </div>
                <CloseIcon onClick={() => closeModal()} />
              </div>
              {
                contentComponent
              }
            </>
          </div>
        </Dragable>
      </div>
    </Spin>
  )
}

