import React from 'react'
import { Product } from 'components/Product'

export const ProductImage = ({
    nornaid,
    image,
    name,
    lookbooks = [],
    vendorCode,
    vendorName,
}: {
    nornaid: string;
    image: string;
    name: string;
    lookbooks?: string[];
    vendorCode?: string;
    vendorName?: string;
}) => (
        <Product 
            id={nornaid}
            date="2021-10-18"
            category=""
            categoryName=""
            url=""
            lookbooks={lookbooks}
            vendor={name}
            brand={name}
            interpolatedPrice={{ '2021-10-18': { discounted_price: 0, original_price: 0 } }}
            imageUrl={image}
            nornaid={nornaid}
            disabledScale={false}
            style={{ width: '100%', height: '276px' }}
            imageStyle={{ width: '185px', height: '276px' }}
            onlyShowImage
            isShowInDialog
            inCrp
            vendorCode={vendorCode}
            vendorName={vendorName}
        />
    )
