import React, { useEffect, useRef, useState } from 'react'
import styles from './PlatformAnalyticsTable.module.scss'
import { useCsvDataModuleData, usePlatformAnalyticsModuleData } from 'features/filters/moduleDataSlice.hook'
import * as utils from './PlatformAnalyticsTable.util'
import { useFilterCategory, useFilterCountry } from 'hooks/useFilter'
import { useLookbook } from 'hooks/useLookbook'
import { TabItemWithChildProps } from 'componentsv2/TradingTableV3/types'
import { getAllFilterCategoryValue } from 'utils/filterUtils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { NA, SELECTED_CATEGORY } from 'consts'
import { Spin } from 'druikit'
import { NornaTab, NornaTable, TableCategoryCell, TableCell, TableCellWithSymbol } from 'componentsv2/TradingTableV3'
import { getExpandedKeysByMetricTab, getSubExpandedKeysByMetricTab } from 'utils/dashboardPageUtils'
import classNames from 'classnames'
import { storage } from 'utils/storage'
import { whetherLock } from 'utils/lockSellerUtils'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import { getFractionDigits } from 'utils'
import { TableOptionsCell } from 'componentsv2/TradingTableV2'
import Thead from './Thead'
import { useScrollBar2 } from 'hooks/useScrollBar'
import { useShopsSalesOptionsAndPercentae } from '../hooks/usePlatformAnalytics'
import { Modal } from './Modal'
import { regionListForTable } from '../hardcode'
import { CSV_DATA_MARKETPLACE_SELLER_ANALYTICS } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'

const PlatformAnalyticsTable = () => {
    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const {
        tableApiData,
        tableApiLoading,
        rankingApiLoading,
        modalVisible,
        isCompetitorView,
        competitorValue,
        modalAttachData,
        modalApiData,
        modalApiPayload,
    } = moduleData

    const [ categoryValue ] = useFilterCategory()
    const [ regionValue ] = useFilterCountry()
    const { isLookbook } = useLookbook()
    const expandedRowKeysRef = useRef<string[]>([])
    const subExpandedRowKeysRef = useRef<string[]>([])
    const [ , setRefresh ] = useState({})
    const { fetchShopsSalesOptionsAndPercentae } = useShopsSalesOptionsAndPercentae()

    const [ metricsTab1, setMetricsTab1 ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata1, setTabledata1 ] = useState<Array<any>>([])

    const [ metricsTab2, setMetricsTab2 ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata2, setTabledata2 ] = useState<Array<any>>([])

    const [ columns, setColumns ] = useState<any[]>([])

    const [ , setCsvData ] = useCsvDataModuleData()

    const handleData = (data: any) => {
        if (regionValue !== 'Germany') {
            setMetricsTab1([])
            setTabledata1([])
            setMetricsTab2([])
            setTabledata2([])
            return
        }

        // 数据不存在直接返回
        if (!data) {
            return {}
        }
        const sellers = storage.getSellers()

        let columns: any[] = [
            {
                dataIndex: 'options',
                width: utils.optionsWidth,
                left: utils.tabWidth,
                render: (text, record) => {
                  if (record?.isLock) {
                    return (
                      <TableLockCell isDeepBorderRight />
                    )
                  }
          
                  const options = numberUtils.formatNumber(record?.Numbers?.value, { isCommaSymbol: true, decimal: 0 })
                  const changePercent = numberUtils.formatNumber(record?.Numbers?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
                  return (
                    <TableOptionsCell
                      text={options}
                      changePercent={changePercent}
                      record={record}
                      width={utils.optionsWidth}
                      metricName="All"
                      isDeepBorderRight
                    />
                  )
                },
            },
            {
                dataIndex: 'categoryKey',
                width: utils.categoryWidth,
                left: utils.tabWidth + utils.optionsWidth,
                render: (text, record) => {
                    return (
                        <TableCategoryCell
                            text={isCompetitorView ? record.categoryName : record.vendorName}
                            record={record}
                            width={utils.categoryWidth}
                            isCompetitorView={isCompetitorView}
                            isDeepBorderRight
                        />
                    )
                },
            },
        ]
        regionListForTable.forEach(region => {
            columns.push({
                dataIndex: `${region}`,
                width: utils.regionWidth,
                render: (text, record) => {
                    const isLock = whetherLock({
                        vendorCode: record.vendorCode,
                        region,
                        sellers,
                        selectedVendorCodeList: competitorValue,
                    })

                    if (isLock) {
                        return (
                            <TableCell
                                width={utils.regionWidth}
                                style={{ padding: 0 }}
                                isDeepBorderRight
                            >
                                <TableLockCell width={utils.avgPriceWidth} style={{ padding: 0 }} />
                                <TableLockCell width={utils.discountWidth} style={{ padding: 0 }} />
                                <TableLockCell width={utils.noOfResellersWidth} style={{ padding: 0 }} isEmptyBorderRight />
                            </TableCell>
                        )
                    }

                    let avgPriceValue = record?.[`${region}_average_price`]?.value
                    avgPriceValue = avgPriceValue !== undefined ? numberUtils.formatNumber(avgPriceValue, { 
                        decimal: getFractionDigits(), 
                        isCommaSymbol: true,
                    }) : NA
                    const avgPriceChangePercent = numberUtils.formatNumber(record?.[`${region}_average_price`]?.change_percent, { 
                        isCentuple: true,  
                        isCommaSymbol: true,
                        isPercentSymbol: true,
                        decimal: 1,
                    })
                    const discountValue = numberUtils.formatNumber(record?.[`${region}_discount_width`]?.value, {
                        isCentuple: true,
                        isCommaSymbol: true,
                        isPercentSymbol: true,
                        decimal: 1,
                    })
                    const discountChange = numberUtils.formatNumber(record?.[`${region}_discount_width`]?.change, {
                        isCentuple: true,
                        isCommaSymbol: true,
                        isPercentSymbol: true,
                        decimal: 1,
                    })
                    const shopsCountValue = numberUtils.formatNumber(record?.[`${region}_shops_count`]?.value, {
                        isCommaSymbol: true,
                        decimal: 0,
                    })
                    const shopsCountChangePercent = numberUtils.formatNumber(record?.[`${region}_shops_count`]?.change_percent, {
                        isCentuple: true,
                        isCommaSymbol: true,
                        isPercentSymbol: true,
                        decimal: 1,
                    })

                    return (
                        <TableCell
                            width={utils.regionWidth}
                            style={{ padding: 0 }}
                            isDeepBorderRight
                        >
                            <TableCell 
                                text={avgPriceValue} 
                                record={record} 
                                width={utils.avgPriceValueWidth}
                            />
                            <TableCellWithSymbol 
                                isComparisonField 
                                text={avgPriceChangePercent} 
                                record={record} 
                                width={utils.avgPriceChangePercentWidth} 
                            />
                            <TableCell 
                                text={discountValue} 
                                record={record} 
                                width={utils.discountValueWidth}
                            />
                            <TableCellWithSymbol 
                                isComparisonField 
                                text={discountChange} 
                                record={record} 
                                width={utils.discountChangeWidth} 
                            />
                            <TableCell 
                                text={shopsCountValue} 
                                record={record} 
                                width={utils.noOfResellersValueWidth}
                                isClickable={shopsCountValue > 0}
                                onClick={async () => {
                                    if (!(shopsCountValue > 0)) return
                                    const { vendorCode, categoryName } = record
                                    await fetchShopsSalesOptionsAndPercentae({ vendorCode, categoryName })
                                    setModuleData({ 
                                        modalVisible: true, 
                                        modalAttachData: {
                                            region,
                                            categoryName,
                                            count: shopsCountValue,
                                        },
                                    })
                                }}
                            />
                            <TableCellWithSymbol 
                                isComparisonField 
                                text={shopsCountChangePercent} 
                                record={record} 
                                width={utils.noOfResellersChangePercentWidth} 
                                isEmptyBorderRight
                            />
                        </TableCell>
                    )
                },
            })
        })
        setColumns(columns)

        if (isCompetitorView) {
            const {
                tabledata: tabledata1,
                metricsTab: metricsTab1,
            } = utils.handleTableData({
                apiData: data,
                isCompetitorView: true,
                competitorValue: competitorValue,
                categoryValue: isLookbook ? getAllFilterCategoryValue() : categoryValue,
                expandedRowKeys: expandedRowKeysRef.current,
                subExpandedRowKeysRef: subExpandedRowKeysRef.current,
                region: regionValue,
            })
            setTabledata1(tabledata1)
            setMetricsTab1(metricsTab1)
            setCsvData({
                [CSV_DATA_MARKETPLACE_SELLER_ANALYTICS]: utils.geneCsvData({ dataSource: tabledata1, isCompetitorView }),
            })
            return
        }

        const {
            tabledata: tabledata2,
            metricsTab: metricsTab2,
        } = utils.handleTableData({
            apiData: data,
            isCompetitorView: false,
            competitorValue: competitorValue,
            categoryValue: isLookbook ? getAllFilterCategoryValue() : categoryValue,
            expandedRowKeys: expandedRowKeysRef.current,
            subExpandedRowKeysRef: subExpandedRowKeysRef.current,
            region: regionValue,
        })

        setTabledata2(tabledata2)
        setMetricsTab2(metricsTab2)
        setCsvData({
            [CSV_DATA_MARKETPLACE_SELLER_ANALYTICS]: utils.geneCsvData({ dataSource: tabledata2, isCompetitorView }),
        })
    }

    useDeepCompareEffect(() => {
        if (!tableApiData) return

        handleData(tableApiData)
    }, [ tableApiData, competitorValue, isCompetitorView, expandedRowKeysRef.current, subExpandedRowKeysRef.current, {} ])

    /* ************************ 表格展开项 ************************** */
    useDeepCompareEffect(() => {
        if (expandedRowKeysRef.current?.length) {
            const firstLevelCategory = getFirstLevelCategories({ selectedCategories: categoryValue })
            firstLevelCategory.push(SELECTED_CATEGORY)
            const vendorList = [ ...competitorValue ]
            let expandedRowKeys = expandedRowKeysRef.current
            expandedRowKeys = expandedRowKeys.filter(item => {
                const code = item.split('_')[0]
                if (isCompetitorView) {
                    return vendorList.includes(code)
                }
                return firstLevelCategory.includes(code)
            })

            expandedRowKeysRef.current = [ ...expandedRowKeys ]
            setRefresh({})
        }
        if (subExpandedRowKeysRef.current?.length) {
            let subExpandedRowKeys = subExpandedRowKeysRef.current
            subExpandedRowKeys = subExpandedRowKeys.filter(item => {
                const arr = item.split('__')
                if (arr.length === 1) {
                    return true
                }
                return categoryValue.includes(arr[1])
            })

            subExpandedRowKeysRef.current = [ ...subExpandedRowKeys ]
            setRefresh({})
        }
        // eslint-disable-next-line
    }, [categoryValue, competitorValue, isCompetitorView])

    useScrollBar2('.platform-analytics-table-header', '.platform-analytics-table-content', {})

    const tableWidth = utils.getTableScrollWidth(regionListForTable)

    return (
        <Spin spinning={tableApiLoading || rankingApiLoading} minHeight={100} style={{ width: `min(100%, ${tableWidth}px)` }}>
            <Thead width={tableWidth} regionList={regionListForTable} />
            {
                regionValue === 'Germany' ? (
                    <>
                        {
                            isCompetitorView ? (
                                <CompetitorView
                                    tableWidth={tableWidth - utils.tabWidth}
                                    columns={columns}
                                    data={tabledata1}
                                    tab={metricsTab1}
                                    setMetricsTab={setMetricsTab1}
                                    onTabChange={(keys, subKeys) => {
                                        expandedRowKeysRef.current = keys
                                        subExpandedRowKeysRef.current = subKeys
                                    }}
                                />
                            ) : (
                                <CategoryView
                                    tableWidth={tableWidth - utils.tabWidth}
                                    columns={columns}
                                    data={tabledata2}
                                    tab={metricsTab2}
                                    setMetricsTab={setMetricsTab2}
                                    onTabChange={(keys, subKeys) => {
                                        expandedRowKeysRef.current = keys
                                        subExpandedRowKeysRef.current = subKeys
                                    }}
                                />
                            )
                        }
                    </>
                ) : null
            }
            {
                modalVisible && (
                    <Modal 
                        onClose={() => {
                            setModuleData({
                                modalVisible: false,
                            })
                        }}
                        apiData={modalApiData}
                        apiPayload={modalApiPayload}
                        categoryName={modalAttachData.categoryName}
                    />
                )
            }
        </Spin>
    )
}

export const CompetitorView = ({
    columns,
    data,
    tab,
    setMetricsTab,
    onTabChange,
    tableWidth,
}) => {
    const [ cateHeight, setCateHeight ] = useState(0)
    const [ expendHeight, setExpendHeight ] = useState(0)
    const [ closeHeight, setCloseHeight ] = useState(0)

    const metricTabFn = a => {
        const metricsTab1: Array<TabItemWithChildProps> = a || tab
        const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
        let closeHeightA = closeHeight
        if (exHeight.length >= 1) {
            if (exHeight[0].getBoundingClientRect().height < 10) {
                closeHeightA = exHeight[0].getBoundingClientRect().height
            }
        }
        let index = metricsTab1.findIndex(n => n.active === true)
        setCateHeight(43)
        let subIndex = 0
        if (index > -1) {
            metricsTab1[index].children.filter(n => n.active).forEach(item => {
                subIndex += item.categorycount
            })
        }
        index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
        subIndex += index
        setExpendHeight((subIndex + 1) * 43)
        setCloseHeight(closeHeightA)
        setMetricsTab(metricsTab1)

        const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
        onTabChange?.(expandedKeys, [])
    }

    useEffect(() => {
        metricTabFn(null)
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classNames([ styles.metricsContent, 'platform-analytics-table-content' ])}>
            <NornaTab
                showHeader={false}

                categoryToVendor={true}
                metricsTab={tab}
                cateHeight={cateHeight}
                expendHeight={expendHeight}
                metricTabFn={metricTabFn}
                isDashboard={true}
            />
            <NornaTable
                style={{ width: tableWidth }}
                showHeader={false}
                dataSource={data}
                columns={columns}

                isTd={true}
                metricTabFn={metricTabFn}
                metricsTab={tab}
                categoryToVendor={true}
                key="competitorview"
            />
        </div>
    )
}

export const CategoryView = ({
    columns,
    data,
    tab,
    setMetricsTab,
    onTabChange,
    tableWidth,
}) => {
    const [ cateHeight, setCateHeight ] = useState(0)
    const [ expendHeight, setExpendHeight ] = useState(0)
    const [ closeHeight, setCloseHeight ] = useState(0)

    const metricTabFn = a => {
        const metricsTab1: Array<TabItemWithChildProps> = a || tab
        const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
        let closeHeightA = closeHeight
        if (exHeight.length >= 1) {
            if (exHeight[0].getBoundingClientRect().height < 10) {
                closeHeightA = exHeight[0].getBoundingClientRect().height
            }
        }
        let index = metricsTab1.findIndex(n => n.active === true)
        setCateHeight(43)
        let subIndex = 0
        if (index > -1) {
            metricsTab1[index].children.filter(n => n.active).forEach(item => {
                subIndex += item.categorycount
            })
        }
        index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
        subIndex += index
        setExpendHeight((subIndex + 1) * 43)
        setCloseHeight(closeHeightA)
        setMetricsTab(metricsTab1)

        const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
        const subExpandedKeys = getSubExpandedKeysByMetricTab(metricsTab1)
        onTabChange?.(expandedKeys, subExpandedKeys)
    }

    useEffect(() => {
        metricTabFn(null)
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classNames([ styles.metricsContent, 'platform-analytics-table-content' ])}>
            <NornaTab
                showHeader={false}

                categoryToVendor={false}
                metricsTab={tab}
                cateHeight={cateHeight}
                expendHeight={expendHeight}
                metricTabFn={metricTabFn}
                isDashboard={true}
            />
            <NornaTable
                style={{ width: tableWidth }}
                showHeader={false}
                dataSource={data}
                columns={columns}

                isTd={true}
                metricTabFn={metricTabFn}
                metricsTab={tab}
                categoryToVendor={false}
                key="categoryview"
            />
        </div>
    )
}

export default PlatformAnalyticsTable
