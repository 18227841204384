import React, { FC, memo } from 'react'
import { DailyCalendarProps } from './types'
import { geneDayArr, getWeeksInYear } from './utils'
import styles from './styles.module.scss'

/**
 * Daily Options In & Out 页面使用的日历组件
 * 
 * 日历顶部有星期几
 * 日历左边有第几周
 */
export const DailyCalendar: FC<DailyCalendarProps> = memo(({
    year = new Date().getFullYear(),
    month = new Date().getMonth(),      // 十月份 month=9，九月份 month=8
    customCell,                         // 自定义日历单元格
}) => {
    const weeks = [ '', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun' ]

    /**
     * 生成指定 year 和 month 对应日历的所有天数数组
     * 
     * 如果该月第一天不是星期一，前面要补上个月最后几天的数据
     * 如果该月最后一天不是星期天，后面要补下个月前面几天的数据
     * 
     * 返回数据结构是个二维数组，每个子数组七条数据，对应周一到周日
     * [
     *      [
     *          { day: '27', month: '9', year: '2021', date: '2021-09-27' },        // 周一
     *          { day: '28', month: '9', year: '2021', date: '2021-09-28' },        // 周二
     *          { day: '29', month: '9', year: '2021', date: '2021-09-29' },        // 周三
     *          { day: '30', month: '9', year: '2021', date: '2021-09-30' },        // 周四
     *          { day: '1', month: '10', year: '2021', date: '2021-10-01' },        // 周五
     *          { day: '2', month: '10', year: '2021', date: '2021-10-02' },        // 周六
     *          { day: '3', month: '10', year: '2021', date: '2021-10-03' },        // 周日
     *      ],
     *      // ......
     * ]
     */
    const daysInMonth = geneDayArr(year, month)

    return (
        <>
            <div className={styles.week}>
                {
                    weeks.map(week => (
                        <div key={week} className={styles.weekTitle}>{week}</div>
                    ))
                }
            </div>
            {
                daysInMonth.map((row, index) => {
                    // 计算第几周
                    const firstColInRow = row[0]
                    const week = getWeeksInYear(firstColInRow.date)

                    // 处理日历中一行数据
                    const rowElements = row.map((cell, index2) => {
                        if (typeof customCell === 'function') {
                            return customCell(cell, index2)
                        } 
                        return (
                            <div key={cell.date} className={styles.calendarRowCell}>
                                {cell.day}
                            </div>
                        )
                    })

                    return (
                        <div key={index.toString()} className={styles.calendarRow}>
                            <div className={styles.calendarRowWeek}>{week}</div>
                            {rowElements}
                        </div>
                    )
                })
            }
        </>
    )
})

DailyCalendar.displayName = 'DailyCalendar'
