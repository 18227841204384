import React, { FC } from 'react'
import classnames from 'classnames'
import { Text } from 'components/typography'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Checkbox } from 'componentsv2/form-elements/Checkbox'
import { OptionItemProps } from '../types'
import styles from '../styles.module.scss'

export const DropdownOptionItem: FC<OptionItemProps> = ({
    item,
    active = false,
    onSelect,
    children = null,
    customClass = '',
    showCheckbox = false,
    disabled = false,
}) => (
    <Checkbox
        key={item.key}
        className={classnames(styles.optionItem, customClass)}
        value={active}
        disabled={disabled || !!item.isDisabled}
        onChange={() => onSelect(item)}
        boxColor="#999999"
        boxVisible={showCheckbox}
        boxOnRight={true}
        boxStyle={{ marginRight: 0 }}
    >
        <Text small className={classnames([ styles.optionName, active && styles.active, item.isDisabled && styles.disabled ])} title={item.description}>
            {item.description}
            <ShouldRender shouldRender={!!item.smallText}>
                <span className={styles.smallDescription}>
                    [{item.smallText}]
                </span>
            </ShouldRender>
        </Text>
    </Checkbox>
)

DropdownOptionItem.displayName = 'DropdownOptionItem'
