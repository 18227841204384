import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { HideWhenOpenInNew, OpenInNewButton, OPEN_IN_NEW_DATE, ShowWhenOpenInNew } from 'componentsv2/business/OpenInNewButton'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { Dropdown } from 'componentsv2/form-elements/Dropdown'
import { Switch } from 'componentsv2/form-elements/Switch'
import { SizedBox } from 'componentsv2/SizedBox'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { LookbookWithoutData } from 'componentsv2/LookbookPlaceholder'
import { formatDateStr2 } from 'utils/dateUtils'
import { usePageDate } from 'hooks/usePageDate'
import { useLoadingBar, useScrollToTop, useUrlParams } from 'hooks'
import { useLookbook } from 'hooks/useLookbook'
import { UPDATE_NEWCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { useFetch } from 'libs'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Spin } from 'componentsv2'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ProductsModal } from 'components/ProductsModalV2'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { cloneDeep, sortBy } from 'lodash'
import { sortVendorList } from 'utils/array'
import { downloadFileByAxios } from 'export'
import { getVendorNameByCode, vendorShortMappingFn } from 'utils'
import { useScrollBar2 } from 'hooks/useScrollBar'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import useDialogTitle from 'hooks/useDialogTitle'
import { PricePerspectiveDropdown } from 'componentsv2/business/PricePerspectiveDropdown'
import { MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { useFilterCompetitor, useFilterCountry, useFilterCurrency } from 'hooks/useFilter'
import { useModuleData } from 'hooks/useModuleData'
import { PRICE_ARCHITECTURE_TABLE_NAME } from 'configs'
import { usePriceArchitectureTableTable } from 'hooks/api/usePriceArchitectureTableData'
import { handleIsLock, handleQueryDateValid } from 'utils/pageUtils'
import { PriceArchitectureExportFilename } from 'utils/exportUtils'
import { useCompetitorOptions } from 'hooks/useOptions'
import { getCompetitorPayload, getMarketPayload, getGroupsPayload } from 'utils/queryPayload'
import { getDragVendorOrder } from 'utils/vendorOrderUtils'
import { VendorProps } from './components/Vendor/types'
import { Vendor, VendorFirstColumn } from './components/Vendor'
import { HeaderVendor } from './components/HeaderVendor'
import { geneCsvData, getActualWidth, getIntervalList, handleVendorRegion } from './utils'
import styles from './styles.module.scss'
import GraphButton from 'componentsv2/GraphButton'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_PRICE_ARCHITECTURE } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'

export const PriceArchitecture = () => {
    useScrollToTop()
    useLoadingBar()
    const { pageDate: dateRangeValue, comparisonPageDate } = usePageDate()
    const { fnUseAppendDialogTitlePriceFilter } = useDialogTitle()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCompetitor ] = useFilterCompetitor()
    const { competitorOptions } = useCompetitorOptions()

    const [ moduleData, setModuleData ] = useModuleData(PRICE_ARCHITECTURE_TABLE_NAME)
    const { 
        fetchPayload = {}, 
        apiData = {},
        newCompetitorValue = [],
        intervalValue = '',
        switchValue = false,
        verticalExpanedKeys = [],
        horizontalExpandKeys = [],
    } = moduleData

    /* ************************* Open in new ****************************** */
    const urlParams = useUrlParams()

    useEffect(() => {
        if (typeof urlParams?.switchValue !== 'undefined') {
            setSwitchValue(urlParams.switchValue === 'true')
        }
        if (typeof urlParams?.intervalValue !== 'undefined') {
            setIntervalValue(urlParams.intervalValue)
            fetchData(urlParams.intervalValue)
        }
        // eslint-disable-next-line
    }, [])

    /* ************************* Export excel ****************************** */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        payload.data.groups = getGroupsPayload({ competitorSellers: filterCompetitor })
        payload.data.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
        payload.data.expand_columns = verticalExpanedKeys
        payload.data.include_all_regions = horizontalExpandKeys
        payload.data.vendorOrder = vendorOrder
        await downloadFileByAxios({
            filename: PriceArchitectureExportFilename,
            payload,
            params: { unites: switchValue },
        })
    }

    /* ************************* Form ****************************** */
    // INTERVAL dropdown
    const [ intervalOptions, setIntervalOptions ] = useState<{key: string, description: string}[]>([])
    
    useEffect(() => {
        if (!filterCurrency) return
        const newIntervalList = getIntervalList(filterCurrency)
        setIntervalOptions(newIntervalList.map(item => ({ key: item.toString(), description: numberUtils.formatNumberByComma(item.toString()) })))
    }, [ filterCurrency ])

    const setIntervalValue = (value: string) => {
        setModuleData({ intervalValue: value })
    }

    // true show product count, false show product percent
    const setSwitchValue = (value: boolean) => {
        setModuleData({ switchValue: value })
    }

    const setVerticalExpanedKeys = (value: string[]) => {
        setModuleData({ verticalExpanedKeys: cloneDeep(value) })
    }

    const setHorizontalExpandKeys = (value: string[]) => {
        setModuleData({ horizontalExpandKeys: cloneDeep(value) })
    }

    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: {
                competitorValue: cloneDeep(filterCompetitor.map(item => item.vendor)),
            },
        })
    }, [ filterCompetitor ])

    const setApiData = (value: any) => {
        setModuleData({ apiData: cloneDeep(value) })
        setData(cloneDeep(value))
    }

    /* ************************************ Lookbook ******************************************* */
    const { isLookbook } = useLookbook()
    const { judgeActionOccurs, clearLookbookActions } = useLookbookAction(true, true)
    const [ lookbookWithoutData, setLookbookWithoutData ] = useState(false)

    /* ************************************ 查询数据 ******************************************* */
    const { postFn: fetchAllRegions } = useFetch()
    const [ dataSource, setDataSource ] = useState<VendorProps[]>([])
    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/752
    const [ vendorOrder, setVendorOrder ] = useState<string[]>([])
    const { fetchData, loading, data, setData } = usePriceArchitectureTableTable()
    const [ , setCsvData ] = useCsvDataModuleData()

    const fetchAllRegionsByVendor = async vendor => {
        const payload = cloneDeep(fetchPayload)
        payload.data.include_all_regions = [ vendor ]
        payload.data.price_gap = Number(intervalValue)
        const result = await fetchAllRegions(payload.url, payload)
        return result
    }

    const handleData = apiData => {
        setLookbookWithoutData(isLookbook && Array.isArray(apiData?.price_ladders) && apiData?.price_ladders?.length === 0)
        if (!Array.isArray(apiData?.price_ladders)) return
        apiData = cloneDeep(apiData)
        if (!isLookbook) {
            apiData.price_ladders = apiData.price_ladders.filter(item => newCompetitorValue.includes(item.seller.vendor))
        }

        let data = apiData.price_ladders
        
        // 处理 QueryDateValid
        data = handleQueryDateValid({ data, competitorValue: newCompetitorValue })
        data = handleIsLock({ data, competitorValue: newCompetitorValue, region: '' })
        const vendorList = sortVendorList({
            vendorList: Array.from(new Set(data.map(item => item.seller.vendor))),
            vendorOrder,
        })

        // group
        const groupedVendor = vendorList.map(vendor => {
            let vendorRegionList = data.filter(item => item.seller.vendor === vendor)
                .map(item => {
                    return handleVendorRegion(item)
                })
            // sory by initials
            vendorRegionList = sortBy(vendorRegionList, 'region')
            const currentVendorRegion = vendorRegionList.find(item => item.region === filterCountry)
            if (currentVendorRegion) {
                vendorRegionList = vendorRegionList.filter(item => item.region !== filterCountry)
                vendorRegionList.unshift(currentVendorRegion)
            }
            return {
                vendor,
                name: getVendorNameByCode(vendor),
                expanded: horizontalExpandKeys.includes(vendor),
                regions: vendorRegionList,
            }
        })

        setDataSource(groupedVendor)
        setCsvData({
            [CSV_DATA_PRICE_ARCHITECTURE]: geneCsvData({ 
                dataSource: groupedVendor, 
                switchValue,
                pageDate: dateRangeValue,
                comparisonDate: comparisonPageDate,
                verticalExpanedKeys,
            }),
        })
        
        if (urlParams?.intervalValue) {
            return
        }

        const intervalList = getIntervalList(filterCurrency)
        if (!intervalValue) {
            setIntervalValue(apiData?.price_ladders[0]?.price_ladder?.price_gap?.toString())
        } else if (!intervalList.includes(Number(String(intervalValue).replace(',', '')))) {
            const newIntervalList = [ ...intervalList, intervalValue ].sort((a, b) => {
                if (a > b) return 1
                if (a < b) return -1
                return 1
            }).filter(item => item !== 1)
            setIntervalOptions(newIntervalList.map(item => ({ key: item.toString(), description: numberUtils.formatNumberByComma(item.toString()) })))
        }
    }

    useEffect(() => {
        if (!data) return

        handleData(cloneDeep(data))
        // eslint-disable-next-line
    }, [data, horizontalExpandKeys, verticalExpanedKeys, switchValue])

    /* ************************************ Product Modal ******************************************* */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    // const { fetchData: fetchProductIds } = useFindProductIds({ moduleName: PRICE_ARCHITECTURE_TABLE_NAME })
    const { postFn: findProductIds } = useFetch()

    const onShowProductsModal = async (opt: any) => {
        if (opt?.virtualCountNumber === 0) return
        let products: string[] = []
        if (opt?.filteredProducts?.length) {
            products = [ ...opt?.filteredProducts || [] ]
        } else {
            const payload = cloneDeep(fetchPayload)
            const { region } = opt
            payload.url = 'find_product_ids'
            payload.query.metric = 'Pricing'
            payload.query.price_gap = intervalValue
            payload.data.selected_seller = {
                vendor: opt.vendor,
                region,
            }
            payload.data.competitor_sellers = payload.data.competitor_sellers.map(item => {
                if (item.vendor === MARKET_VENDOR.vendor) {
                    return item
                }
                return { ...item, region }
            })
            payload.data.customer_seller = { ...payload.data.customer_seller, region }
            payload.data.market = payload.data.market.map(item => {
                if (item.vendor === SELECTED_VENDORS) {
                    return item
                }
                return { ...item, region }
            })
            Object.keys(payload.data.groups).forEach(group => {
                payload.data.groups[group] = payload.data.groups[group].map(item => {
                    return { ...item, region }
                })
            })
            const result = await findProductIds(payload.url, payload)
            if (result?.error) return
            const newApiData = cloneDeep(apiData)
            newApiData.price_ladders.forEach(item => {
                if (item.seller.vendor === opt.vendor && item.seller.region === opt.region) {
                    Object.keys(item.price_ladder.price_distribution).forEach(key => {
                        item.price_ladder.price_distribution[key].filtered_products = result.data[key]
                    })
                }
            })
            setApiData(newApiData)
            products = [ ...result?.data?.[opt.apiName] ]
        }
        
        products = products.map(item => item)
        if (!products.length) return

        clearLookbookActions()
        const title = `${vendorShortMappingFn(getVendorNameByCode(opt?.vendor))} ${opt?.name}, ${filterCurrency}`
        const dialogId = title
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog(dialogId, (
            <ProductsModal
                productUrls={[ ...products ]}
                onClose={() => {
                    closeFn(dialogId)
                    if (judgeActionOccurs()) fetchData()
                }}
                headerLeading={numberUtils.formatNumberByComma(products?.length)}
                headerTitle={fnUseAppendDialogTitlePriceFilter(title)}
                headerDate={formatDateStr2(dateRangeValue)}
            />
        ))
    }

    /* ************************************ Drog column ******************************************* */
    const onDrag = (dragVendor, dropVendor) => {
        const newBusinessData = getDragVendorOrder({
            dragVendor,
            dropVendor,
            dataSource,
        })

        // 记住 vendor 之间的排序
        const vendorOrder = newBusinessData.map(item => item.vendor)
        setVendorOrder([ ...vendorOrder ])
        setDataSource([ ...newBusinessData ])
    }

    /* ************************************ Scrollbar ******************************************* */
    useScrollBar2('.price-architecture-header', '.price-architecture-content', dataSource)

    /* ************************************ StickyHeader ******************************************* */
    const [ stickyDataSource, setStickyDataSource ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        if (dataSource.length === 0) return
        let data = cloneDeep(dataSource)
        data = data.map(item => {
            if (!horizontalExpandKeys.includes(item.vendor)) {
                item.regions = item.regions.slice(0, 1)
            }
            return item
        })
        setStickyDataSource(cloneDeep(data))
    }, [ horizontalExpandKeys, dataSource ])

    return (
        <div style={{ background: '#fff', position: 'relative', minHeight: '900px' }}>
            <div id="export-wrapper">
                <ModuleTitle category="PRICING" title="Price architecture" showComparisonDate className={styles.firstColumnPl} />
                <ShowWhenOpenInNew>
                    <FilterInfo />
                </ShowWhenOpenInNew>
                <SizedBox height={10} />
                <Spin spinning={loading} zIndex={120} minHeight={880}>
                    <ShouldRender shouldRender={lookbookWithoutData}>
                        <LookbookWithoutData />
                    </ShouldRender>
                    <ShouldRender shouldRender={!lookbookWithoutData}>
                        <div className={classnames([ 'flex', 'flex-align-end', styles.firstColumnPl ])} style={{ position: 'relative' }}>
                            <HideWhenOpenInNew>
                                <PricePerspectiveDropdown 
                                    right
                                    enableFilter
                                />
                                <Dropdown
                                    label="Interval"
                                    options={intervalOptions}
                                    title="Interval"
                                    placeholder="Interval"
                                    value={intervalValue}
                                    onChange={value => {
                                        setIntervalValue(value as string)
                                        setVerticalExpanedKeys([])
                                        fetchData(value as string)
                                    }}
                                    right={10}
                                />
                                <ExportDropdown
                                    right
                                    onExportExcel={onExportExcel}
                                    calcJpgWidthFn={getActualWidth}
                                    adjustElementFn={ele => {
                                        const width = getActualWidth()
                                        if (width !== 0) {
                                            const wrapper = ele.querySelector('.price-architecture-header')?.closest('div')
                                            if (wrapper) wrapper.style.width = `${width}px`
                                            const headerEle: HTMLDivElement = ele.querySelector('.price-architecture-header') as HTMLDivElement
                                            if (headerEle) headerEle.style.width = `${width}px`
                                            const contentEle: HTMLDivElement = ele.querySelector('.price-architecture-content') as HTMLDivElement
                                            if (contentEle) contentEle.style.width = `${width}px`
                                        }
                                    }}
                                    showCLIPBOARD
                                    fileName={PriceArchitectureExportFilename}
                                />
                                <GraphButton />
                                <OpenInNewButton
                                    left={10}
                                    pageParams={{
                                        intervalValue,
                                        switchValue,
                                        [OPEN_IN_NEW_DATE]: dateRangeValue,
                                    }}
                                />
                            </HideWhenOpenInNew>
                            <div style={{ flex: 1 }} />
                            <Switch
                                leftLabel="%"
                                rightLabel="#"
                                right
                                value={switchValue}
                                onChange={value => setSwitchValue(value)}
                            />
                        </div>
                        <div className={classnames([ styles.title, styles.firstColumnPl ])}>
                            <div style={{ marginBottom: '6px' }}>
                                <span>Price ladder and price distribution of options</span>
                                <span style={{ color: '#01a699' }}> ({filterCurrency})</span>
                            </div>
                        </div>
                        <HeaderVendor
                            loading={loading}
                            dataSource={stickyDataSource}
                            horizontalExpandKeys={horizontalExpandKeys}
                            onDrag={onDrag}
                            onHorizontalExpanded={async (expanded, vendor) => {
                                if (expanded === false) {
                                    // fold
                                    setHorizontalExpandKeys([ ...horizontalExpandKeys ].filter(item => item !== vendor))
                                    return
                                } 
                                
                                // unfold
                                // have other regions, directly change the fold state
                                // no other regions, send request first                                    
                                const vendorData = dataSource.find(item => item.vendor === vendor)
                                if (vendorData && vendorData?.regions?.length === 1) {
                                    const result = await fetchAllRegionsByVendor(vendor)
                                    const newApiData = cloneDeep(apiData)
                                    newApiData.price_ladders = [ ...newApiData.price_ladders, ...result.data.price_ladders ]                                           
                                    setApiData(newApiData)
                                }

                                setHorizontalExpandKeys([ ...horizontalExpandKeys, vendor ])
                            }}
                        />
                        <div className={classnames([ styles.content, 'price-architecture-content' ])}>
                            <VendorFirstColumn
                                dataSource={dataSource}
                                verticalExpanedKeys={verticalExpanedKeys}
                                onVerticalExpaned={value => {
                                    if (verticalExpanedKeys.includes(value)) {
                                        setVerticalExpanedKeys([ ...verticalExpanedKeys.filter(item => item !== value) ])
                                    } else {
                                        setVerticalExpanedKeys([ ...verticalExpanedKeys, value ])
                                    }
                                }}
                            />
                            {
                                dataSource.map((d, index) => (
                                    <Vendor
                                        key={d.vendor + String(index)}
                                        dataSource={d}
                                        onClick={onShowProductsModal}
                                        hideHeader={true}
                                        hideRegion={true}
                                    />
                                ))
                            }
                        </div>
                    </ShouldRender>
                </Spin>
            </div>
            <Dialog ref={dialogRef} />
        </div>
    )
}
