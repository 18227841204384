import React, { useEffect, useState } from 'react'
import { arrayUtils, stringUtils, typeUtils } from 'norna-uikit'
import Markdown from 'react-markdown'
import styles from './ChatResponder.module.scss'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts'
import { cloneDeep } from 'lodash'

function parseStrToObj(content: string) {
    try {
        return JSON.parse(content.replace(/\n/g, ''))
    } catch (e) {
        return []
    }
}

function handleJson(dataSource) {
    dataSource.forEach(item => {
        if (item.type === 'json') {
            const last = item.content[item.content.length - 1]
            let prefix = ''

            if (last === ']' && item.content.split('').filter(i => i === '[').length !== item.content.split('').filter(i => i === ']').length) {
                prefix = '['
            }
            if (last === '}' && item.content.split('').filter(i => i === '{').length !== item.content.split('').filter(i => i === '}').length) {
                prefix = '{'
            }
            item.content = parseStrToObj(prefix + item.content)
        }
        if (item.type === 'image') {
            item.content = 'data:image/png;base64,' + item.content
        }
    })
}

function handleGraph(dataSource) {
    dataSource.forEach(item => {
        if (item.type === 'json' && item.content?.length) {
            const content = item.content     
            const values = Object.values(content?.[0])
            // 最后一个值去掉
            const last = arrayUtils.last(values)
            const lastStr = String(last)?.replace('%', '')?.replace(',', '')
            // 最后一个不是数值, 直接返回
            if (!typeUtils.isNumber(Number(lastStr))) {
                return false
            }
            item.content = content.map(item2 => {
                const values = Object.values(item2)
                let last = arrayUtils.last(values)
                last = Number(String(last)?.replace('%', '')?.replace(',', ''))
                return {
                    name: values.slice(0, values.length - 1).join(' '),
                    value: last,
                }
            })
            item.graph = 'bar'
        }
    })
}

const Responder = ({
    dataSource,
}) => {
    const [ data, setData ] = useState<any[]>([])

    useEffect(() => {
        const newDataSource = cloneDeep(dataSource)
        // 转换为 json 格式
        handleJson(newDataSource)
        // 处理图形数据结构
        handleGraph(newDataSource)
        setData(newDataSource)
    }, [ dataSource ])

    return (
        <div className="responder-wrapper">
            {
                data.map(item => {
                    if (item.type === 'string') {
                        return (
                            <Markdown key={stringUtils.uuid()}>
                                {item.content}
                            </Markdown>
                        )
                    } 
                    // else if (item.type === 'image') {
                    //     return (
                    //         <img 
                    //             key={stringUtils.uuid()}
                    //             src={item.content} 
                    //             alt=""
                    //             className={styles.image}
                    //         />
                    //     )
                    // }
                    // else if (item.graph === 'bar') {
                    //     return (
                    //         <BarChart
                    //             key={stringUtils.uuid()}
                    //             width={800} 
                    //             height={250} 
                    //             data={item.content}
                    //         >
                    //             <CartesianGrid strokeDasharray="3 3" />
                    //             <XAxis dataKey="name" />
                    //             <YAxis />
                    //             <Tooltip />
                    //             <Bar dataKey="value" fill="#8884d8" />
                    //         </BarChart>
                    //     )
                    // }
                })
            }
        </div>
    )
}

export default Responder
