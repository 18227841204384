export enum NoteType {
    Release = 'release',
    About = 'about',
    Support = 'support',
}
export interface NoteProps {

    // file type
    type: NoteType;

    // close function
    close: () => void
}

