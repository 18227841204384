import React from 'react'
import classnames from 'classnames'
import { NornaBarChart } from 'componentsv2/NornaBarChart'
import { useWindowZoom } from 'hooks/useWindow'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { PRICE_INSIGHT_TABLE_NAME } from 'configs/moduleName'
import { OPTIONS_NAME } from 'consts/strings'
import { useFilterCountry, useFilterCurrency } from 'hooks/useFilter'
import { getFractionDigits, getVendorNameByCode, regionShortMappingFn } from 'utils'
import { storage } from 'utils/storage'
import { Flex, Flex1 } from 'componentsv2/Flex'
import { DisplayText } from 'componentsv2/DisplayText'
import { DashboardDoughnutChart } from 'componentsv2/DashboardDoughnutChart'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'

export const PriceInsightTopKpi = ({
    marketData = {},
    customerData = {},
}: {
    marketData: any;
    customerData: any;
}) => {
    useWindowZoom()
    const customerVendor = storage.getCustomerVendor()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCurrency ] = useFilterCurrency()

    return (
        <div style={{ width: '1440px' }} className="norna-container-fixed-width">
            <div className={styles.row1}>
                <div className={classnames([ styles.column, styles.column1 ])}>
                    <div className={styles.left}>
                        <ModuleTitle showDate={false} category="DASHBOARD" title={PRICE_INSIGHT_TABLE_NAME} />
                        <div className={styles.customerVendor}>{getVendorNameByCode(customerVendor)}</div>
                        <div>
                            <span className={styles.kpiName}>{OPTIONS_NAME}</span>&nbsp;
                            <span className={styles.region}>{regionShortMappingFn(filterCountry)}</span>
                        </div>
                        <div className={styles.currency}>({filterCurrency})</div>
                        <Flex1 />
                        <div className={styles.remark}>
                            <span>%&nbsp;</span>
                            <span>= vs your comparison period</span>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.kpiName}>Options</div>
                        <NornaBarChart
                            direction="vertical"
                            currentValue={customerData?.optionsCurrentValue}
                            comparisonValue={customerData?.optionsComparisonValue}
                            maxValue={customerData?.optionsMaxValue}
                            changePercent={customerData?.optionsChangePercent}
                        />
                    </div>
                </div>
                <div className={classnames([ styles.column, styles.column2 ])}>
                    <div className={styles.kpiTitle}>Average price</div>
                    <div className={styles.chartWrapper} style={{ paddingTop: '20px' }}>
                        <NornaBarChart 
                            currentValue={Number(customerData?.avgPriceCurrentValue?.toFixed(getFractionDigits()) || 0)}
                            comparisonValue={Number(customerData?.avgPriceComparisonValue?.toFixed(getFractionDigits()) || 0)}
                            maxValue={customerData?.avgPriceMaxValue || 0}
                            changePercent={customerData?.avgPriceChangePercent}
                        />
                    </div>
                    <div className={styles.kpiDesc} />
                </div>
                <div className={classnames([ styles.column, styles.column3 ])}>
                    <div className={styles.kpiTitle}>Frequent price</div>
                    <div className={styles.chartWrapper} style={{ paddingTop: '20px' }}>
                        <NornaBarChart 
                            currentValue={Number(customerData?.frequentPriceCurrentValue?.toFixed(getFractionDigits()) || 0)}
                            comparisonValue={Number(customerData?.frequentPriceComparisonValue?.toFixed(getFractionDigits()) || 0)}
                            maxValue={customerData?.frequentPriceMaxValue || 0}
                            changePercent={customerData?.frequentPriceChangePercent}
                        />
                    </div>
                    <div className={styles.kpiDesc} />
                </div>
                <div className={classnames([ styles.column, styles.column4 ])}>
                    <div className={styles.kpiTitle}>Discount effect</div>
                    <div className={styles.chartWrapper}>
                        <DashboardDoughnutChart 
                            value={(customerData?.disEffectCurrentValue || 0) * 100}
                            content={(
                                <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                                    <div style={{ fontSize: '18px', color: '#666666', fontWeight: 'bold' }}>{((customerData?.disEffectCurrentValue || 0) * 100).toFixed(1) + '%'}</div>
                                    <div style={{ height: '1px', width: '59px', backgroundColor: '#D8D8D8' }} />
                                    <DisplayText
                                        style={{ fontWeight: 'bold', marginTop: '2px' }}
                                        value={(customerData?.disEffectChangePercent * 100).toFixed(1) + '%'} 
                                        fontSize={14}
                                    />
                                </Flex>
                            )}
                        />
                    </div>
                    <div className={styles.kpiDesc}>change is in <span className={styles.colorPrimary}>% units</span></div>
                </div>
                <div className={classnames([ styles.column, styles.column5 ])}>
                    <div className={styles.kpiTitle}>Discount depth</div>
                    <div className={styles.chartWrapper}>
                        <DashboardDoughnutChart 
                            value={(customerData?.disDepthCurrentValue || 0) * 100}
                            content={(
                                <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                                    <div style={{ fontSize: '18px', color: '#666666', fontWeight: 'bold' }}>{((customerData?.disDepthCurrentValue || 0) * 100).toFixed(1) + '%'}</div>
                                    <div style={{ height: '1px', width: '59px', backgroundColor: '#D8D8D8' }} />
                                    <DisplayText
                                        style={{ fontWeight: 'bold', marginTop: '2px' }}
                                        value={(customerData?.disDepthChangePercent * 100).toFixed(1) + '%'} 
                                        fontSize={14}
                                    />
                                </Flex>
                            )}
                        />
                    </div>
                    <div className={styles.kpiDesc}>change is in <span className={styles.colorPrimary}>% units</span></div>
                </div>
                <div className={classnames([ styles.column, styles.column6 ])}>
                    <div className={styles.kpiTitle}>Discount width</div>
                    <div className={styles.chartWrapper}>
                        <DashboardDoughnutChart 
                            value={(customerData?.disWidthCurrentValue || 0) * 100}
                            content={(
                                <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                                    <div style={{ fontSize: '18px', color: '#666666', fontWeight: 'bold' }}>{((customerData?.disWidthCurrentValue || 0) * 100).toFixed(1) + '%'}</div>
                                    <div style={{ height: '1px', width: '59px', backgroundColor: '#D8D8D8' }} />
                                    <DisplayText 
                                        style={{ fontWeight: 'bold', marginTop: '2px' }}
                                        value={(customerData?.disWidthChangePercent * 100).toFixed(1) + '%'} 
                                        fontSize={14}
                                    />
                                </Flex>
                            )}
                        />
                    </div>
                    <div className={styles.kpiDesc}>change is in <span className={styles.colorPrimary}>% units</span></div>
                </div>
            </div>
            <div className={styles.row2}>
                <div className={styles.marketName}>Market</div>
                <div className={styles.column1}>
                    <div className={styles.currentValue}>
                        {numberUtils.formatNumberByComma(marketData?.optionsCurrentValue || 0)}
                    </div>
                    <DisplayText 
                        value={((marketData?.optionsChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column2}>
                    <div className={styles.currentValue}>
                        {numberUtils.formatNumberByComma(marketData?.avgPriceCurrentValue?.toFixed(getFractionDigits()))}
                    </div>
                    <DisplayText 
                        value={((marketData?.avgPriceChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column3}>
                    <div className={styles.currentValue}>
                        {numberUtils.formatNumberByComma(marketData?.frequentPriceCurrentValue?.toFixed(getFractionDigits()))}
                    </div>
                    <DisplayText 
                        value={((marketData?.frequentPriceChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column4}>
                    <div className={styles.currentValue}>
                        {((marketData?.disEffectCurrentValue || 0) * 100)?.toFixed(1) + '%'}
                    </div>
                    <DisplayText 
                        value={((marketData?.disEffectChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column5}>
                    <div className={styles.currentValue}>
                        {((marketData?.disDepthCurrentValue || 0) * 100)?.toFixed(1) + '%'}
                    </div>
                    <DisplayText 
                        value={((marketData?.disDepthChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
                <div className={styles.column6}>
                    <div className={styles.currentValue}>
                        {((marketData?.disWidthCurrentValue || 0) * 100)?.toFixed(1) + '%'}
                    </div>
                    <DisplayText 
                        value={((marketData?.disWidthChangePercent || 0) * 100).toFixed(1) + '%'}
                        className={styles.changePercent}
                    />
                </div>
            </div>
        </div>
    )
}
