import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { MinusProps } from './types'

export const MinusIcon = ({ className }: MinusProps) => 
    <svg
        className={classnames(styles.minus, className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path d="M19 13H5v-2h14v2z" />
    </svg>

