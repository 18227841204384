import { cloneDeep } from 'lodash'
import { getVendorNameByCode } from 'utils'
import { sortVendorList } from 'utils/array'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { getValueByQueryDateValid, getValueByOptions, handleIsLock } from 'utils/pageUtils'
import { numberUtils } from 'norna-uikit'

export const firstCellWidth = 160
export const othersCellWidth = 310

/**
 * 获取实际宽度
 * @returns 
 */
export const getActualWidth = () => {
    const headerEles = document.querySelectorAll('.discounts-header > div')

    if (headerEles) {
        let width = 0
        headerEles.forEach(ele => {
            width += ele.getBoundingClientRect().width
        })

        const pageEle = document.querySelector('.page-container')
        // 小于页面宽度
        if(pageEle && pageEle.getBoundingClientRect().width>width){
            return 0
        }
        return width
    }

    return 0
}

export function handleApiData({
    apiData = [],
    competitorValue = [],
    filterCountry,
}: {
    apiData: any[];
    competitorValue: string[];
    filterCountry: string;
}) {
    let data: any = cloneDeep(apiData)
    // 排序
    data = handleSort({ data })
    // 处理 queryDateValid
    data = handleQueryDateValid({ data, competitorValue, filterCountry })
    // 处理 isLock
    data = handleIsLock({ data, competitorValue, region: filterCountry })
    // 处理业务数据
    data = data.map(item => handleVendorData(item))
    return data
}

export function handleVendorData(data) {
    const { queryDateValid, queryComparisonDateValid } = data.seller

    const vendorCode = data.seller.vendor
    const vendorName = getVendorNameByCode(vendorCode)
    const discountLadder = data.discount_ladder
    const options = discountLadder.total.current_value

    // discountWidth
    let disWidthCurrentValue = discountLadder.average_discount_width.current_value
    disWidthCurrentValue = handleNumber(disWidthCurrentValue, { x: 100, p: true, d: 1 })
    disWidthCurrentValue = getValueByQueryDateValid(disWidthCurrentValue, queryDateValid)
    disWidthCurrentValue = getValueByOptions(disWidthCurrentValue, options)
    
    let disWidthComparisonValue = discountLadder.average_discount_width.comparison_value
    disWidthComparisonValue = handleNumber(disWidthComparisonValue, { x: 100, p: true, d: 1 })
    disWidthComparisonValue = getValueByQueryDateValid(disWidthComparisonValue, queryComparisonDateValid)
    disWidthComparisonValue = getValueByOptions(disWidthComparisonValue, options)
    
    let disWidthChange = discountLadder.average_discount_width.change
    disWidthChange = handleNumber(disWidthChange, { x: 100, p: true, d: 1 })
    disWidthChange = getValueByQueryDateValid(disWidthChange, queryComparisonDateValid)
    disWidthChange = getValueByOptions(disWidthChange, options)
    
    let disWidthChangePercentage = discountLadder.average_discount_width.change_percentage
    disWidthChangePercentage = handleNumber(disWidthChangePercentage, { x: 100, p: true, d: 1 })
    disWidthChangePercentage = getValueByQueryDateValid(disWidthChangePercentage, queryComparisonDateValid)
    disWidthChangePercentage = getValueByOptions(disWidthChangePercentage, options)

    // discountDepth
    let disDepthCurrentValue = discountLadder.average_discount_depth.current_value
    disDepthCurrentValue = handleNumber(disDepthCurrentValue, { x: 100, p: true, d: 1 })
    disDepthCurrentValue = getValueByQueryDateValid(disDepthCurrentValue, queryDateValid)
    disDepthCurrentValue = getValueByOptions(disDepthCurrentValue, options)

    let disDepthComparisonValue = discountLadder.average_discount_depth.comparison_value
    disDepthComparisonValue = handleNumber(disDepthComparisonValue, { x: 100, p: true, d: 1 })
    disDepthComparisonValue = getValueByQueryDateValid(disDepthComparisonValue, queryComparisonDateValid)
    disDepthComparisonValue = getValueByOptions(disDepthComparisonValue, options)

    let disDepthChange = discountLadder.average_discount_depth.change
    disDepthChange = handleNumber(disDepthChange, { x: 100, p: true, d: 1 })
    disDepthChange = getValueByQueryDateValid(disDepthChange, queryComparisonDateValid)
    disDepthChange = getValueByOptions(disDepthChange, options)

    let disDepthChangePercentage = discountLadder.average_discount_depth.change_percentage
    disDepthChangePercentage = handleNumber(disDepthChangePercentage, { x: 100, p: true, d: 1 })
    disDepthChangePercentage = getValueByQueryDateValid(disDepthChangePercentage, queryComparisonDateValid)
    disDepthChangePercentage = getValueByOptions(disDepthChangePercentage, options)

    // effect
    let disEffectCurrentValue = discountLadder.average_discount_effect.current_value
    disEffectCurrentValue = handleNumber(disEffectCurrentValue, { x: 100, p: true, d: 1 })
    disEffectCurrentValue = getValueByQueryDateValid(disEffectCurrentValue, queryDateValid)
    disEffectCurrentValue = getValueByOptions(disEffectCurrentValue, options)

    let disEffectComparisonValue = discountLadder.average_discount_effect.comparison_value
    disEffectComparisonValue = handleNumber(disEffectComparisonValue, { x: 100, p: true, d: 1 })
    disEffectComparisonValue = getValueByQueryDateValid(disEffectComparisonValue, queryComparisonDateValid)
    disEffectComparisonValue = getValueByOptions(disEffectComparisonValue, options)

    let disEffectChange = discountLadder.average_discount_effect.change
    disEffectChange = handleNumber(disEffectChange, { x: 100, p: true, d: 1 })
    disEffectChange = getValueByQueryDateValid(disEffectChange, queryComparisonDateValid)
    disEffectChange = getValueByOptions(disEffectChange, options)

    let disEffectChangePercentage = discountLadder.average_discount_effect.change_percentage
    disEffectChangePercentage = handleNumber(disEffectChangePercentage, { x: 100, p: true, d: 1 })
    disEffectChangePercentage = getValueByQueryDateValid(disEffectChangePercentage, queryComparisonDateValid)
    disEffectChangePercentage = getValueByOptions(disEffectChangePercentage, options)

    // minDis
    let minCurrentValue = discountLadder.min_discount.current_value
    minCurrentValue = handleNumber(minCurrentValue, { x: 100, d: 1 })

    // maxDis
    let maxCurrentValue = discountLadder.max_discount.current_value
    maxCurrentValue = handleNumber(maxCurrentValue, { x: 100, d: 1 })

    // range
    let priceRange = `${maxCurrentValue}/${minCurrentValue} %`
    priceRange = getValueByQueryDateValid(priceRange, queryDateValid)
    priceRange = getValueByOptions(priceRange, options)

    // total
    let totalCurrentValue = discountLadder.total.current_value
    totalCurrentValue = handleNumber(totalCurrentValue, { s: true })
    totalCurrentValue = getValueByQueryDateValid(totalCurrentValue, queryDateValid)
    totalCurrentValue = getValueByOptions(totalCurrentValue, options)

    let totalComparisonValue = discountLadder.total.comparison_value
    totalComparisonValue = handleNumber(totalComparisonValue, { s: true })
    totalComparisonValue = getValueByQueryDateValid(totalComparisonValue, queryComparisonDateValid)
    totalComparisonValue = getValueByOptions(totalComparisonValue, options)

    let totalChange = discountLadder.total.change
    totalChange = handleNumber(totalChange, { s: true })
    totalChange = getValueByQueryDateValid(totalChange, queryComparisonDateValid)
    totalChange = getValueByOptions(totalChange, options)

    let totalChangePercentage = discountLadder.total.change_percentage
    totalChangePercentage = handleNumber(totalChangePercentage, { x: 100, p: true, d: 1 })
    totalChangePercentage = getValueByQueryDateValid(totalChangePercentage, queryComparisonDateValid)
    totalChangePercentage = getValueByOptions(totalChangePercentage, options)

    // discount_distribution
    const distribution = discountLadder.discount_distribution
    const keys = [ '-10-0', '0-10', '10-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80-90', '90-100' ]
    const rangeData = keys.map(key => {
        let name = ''
        if (key === '-10-0') {
          name = '0%'
        } else if (key === '0-10') {
          name = '1-10%'
        } else {
          name = `${key}%`
        }

        const rangeData = distribution[key]
        
        let percentageCurrentValue = rangeData.percentage.current_value
        percentageCurrentValue = handleNumber(percentageCurrentValue, { x: 100, d: 1, p: true })
        percentageCurrentValue = getValueByQueryDateValid(percentageCurrentValue, queryDateValid)
        percentageCurrentValue = getValueByOptions(percentageCurrentValue, options)
        
        let percentageComparisonValue = rangeData.percentage.comparison_value
        percentageComparisonValue = handleNumber(percentageComparisonValue, { x: 100, d: 1, p: true })
        percentageComparisonValue = getValueByQueryDateValid(percentageComparisonValue, queryComparisonDateValid)
        percentageComparisonValue = getValueByOptions(percentageComparisonValue, options)

        let percentageChange = rangeData.percentage.change
        percentageChange = handleNumber(percentageChange, { x: 100, d: 1, p: true })
        percentageChange = getValueByQueryDateValid(percentageChange, queryComparisonDateValid)
        percentageChange = getValueByOptions(percentageChange, options)

        let percentageChangePercentage = rangeData.percentage.change_percentage
        percentageChangePercentage = handleNumber(percentageChangePercentage, { x: 100, d: 1, p: true })
        percentageChangePercentage = getValueByQueryDateValid(percentageChangePercentage, queryComparisonDateValid)
        percentageChangePercentage = getValueByOptions(percentageChangePercentage, options)

        let countCurrentValue = rangeData.virtual_count.current_value
        countCurrentValue = handleNumber(countCurrentValue, { s: true })
        countCurrentValue = getValueByQueryDateValid(countCurrentValue, queryDateValid)
        countCurrentValue = getValueByOptions(countCurrentValue, options)
        
        let countComparisonValue = rangeData.virtual_count.comparison_value
        countComparisonValue = handleNumber(countComparisonValue, { s: true })
        countComparisonValue = getValueByQueryDateValid(countComparisonValue, queryComparisonDateValid)
        countComparisonValue = getValueByOptions(countComparisonValue, options)

        let countChange = rangeData.virtual_count.change
        countChange = handleNumber(countChange, { s: true })
        countChange = getValueByQueryDateValid(countChange, queryComparisonDateValid)
        countChange = getValueByOptions(countChange, options)
        
        let countChangePercentage = rangeData.virtual_count.change_percentage
        countChangePercentage = handleNumber(countChangePercentage, { x: 100, d: 1, p: true })
        countChangePercentage = getValueByQueryDateValid(countChangePercentage, queryComparisonDateValid)
        countChangePercentage = getValueByOptions(countChangePercentage, options)

        return {
            ...distribution[key],

            apiRangeName: key,
            rangeName: name,
            vendorCode,
            vendorName,

            percentageCurrentValue,
            percentageComparisonValue,
            percentageChange,
            percentageChangePercentage,

            countCurrentValue,
            countComparisonValue,
            countChange,
            countChangePercentage,

            products: [],
            disabled: rangeData.virtual_count.current_value === 0 && rangeData?.percentage?.current_value === 0,
        }
    })

    return {
        ...data,

        vendorCode,
        vendor: vendorCode,
        vendorName,

        // discount width
        disWidthCurrentValue,
        disWidthComparisonValue,
        disWidthChange,
        disWidthChangePercentage,

        // discount depth
        disDepthCurrentValue,
        disDepthComparisonValue,
        disDepthChange,
        disDepthChangePercentage,

        // discount effect
        disEffectCurrentValue,
        disEffectComparisonValue,
        disEffectChange,
        disEffectChangePercentage,

        // max / min
        priceRange,

        // total
        totalCurrentValue,
        totalComparisonValue,
        totalChange,
        totalChangePercentage,

        // data
        data: rangeData,
    }
}

export function handleNumber(value: number, {
    d = 0,
    x = 1,
    s = false,
    p = false,
}: {
    d?: number;         // 保留小数位数
    x?: number;         // 倍数
    s?: boolean;        // 是否显示分隔符 separator
    p?: boolean;        // 是否显示百分号 percent
}): any {
    let newValue: any = value

    // 倍数
    newValue *= x
    
    // 保留小数位数
    newValue = newValue.toFixed(d)

    // 千分位分隔符
    if (s) {
        newValue = numberUtils.formatNumberByComma(newValue)
    }

    if (p) {
        newValue += '%'
    }

    return newValue
}

export function handleSort({ data }) {
    const newData = sortVendorList({
        vendorList: data,
        vendorField: 'seller.vendor',
    })
    return newData
}

export function handleQueryDateValid({
    data,
    competitorValue,
    filterCountry,
}) {
    const newData = data.map(item => {
        const { vendor } = item.seller
        const queryDateValid = isQueryDateValid({ vendor, region: filterCountry, competitorValue })
        const queryComparisonDateValid = isQueryDateValid({ vendor, region: filterCountry, competitorValue, isComparisonDate: true })
        item.seller.queryDateValid = queryDateValid
        item.seller.queryComparisonDateValid = queryDateValid && queryComparisonDateValid
        return item
    })
    return newData
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    switchValue = false,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []
  
    const titleRow = [
        'Discount',
        ...dataSource.map(item => {
            return [
                item?.vendorName,
                'VS comparison period',
                '+/- % Units',
            ]
        }).flat(2),
    ]
    data.push(titleRow)

    data.push([
        'Depth',
        ...dataSource.map(item => {
            return [
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_depth?.current_value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_depth?.comparison_value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_depth?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            ]
        }).flat(2),
    ])

    data.push([
        'Width',
        ...dataSource.map(item => {
            return [
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_width?.current_value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_width?.comparison_value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_width?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            ]
        }).flat(2),
    ])

    data.push([
        'Effect',
        ...dataSource.map(item => {
            return [
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_effect?.current_value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_effect?.comparison_value, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
                numberUtils.formatNumber(item?.discount_ladder?.average_discount_effect?.change, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            ]
        }).flat(2),
    ])

    data.push([
        'Max/min range',
        ...dataSource.map(item => {
            return [
                item?.priceRange,
                '',
                '',
            ]
        }).flat(2),
    ])

    const rangeNameList = dataSource?.[0]?.data?.map(item => item.rangeName)
  
    rangeNameList.forEach(rangeName => {
        const dataRow = [
            rangeName,
            ...dataSource.map(item => {
                const rangeNameData = item.data.find(item2 => item2.rangeName === rangeName)
                return [
                    switchValue ? rangeNameData?.countCurrentValue?.replace(',', '') : rangeNameData?.percentageCurrentValue,
                    switchValue ? rangeNameData?.countComparisonValue?.replace(',', '') : rangeNameData?.percentageComparisonValue,
                    switchValue ? rangeNameData?.countChangePercentage : rangeNameData?.percentageChange,
                ]
            }).flat(2),
        ]
        data.push(dataRow)
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
