import React from 'react'
import styles from './RowSizeItem.module.scss'
import classNames from 'classnames'

const sizeList = [
    'children',
    'none',
    'all',
    '<S',
    'S',
    'M',
    'L',
    'XL',
    '>XL',
]

const RowSizeItem = ({
    className,
    value,
    onChange,
}: {
    className?: string;
    value?: string;
    onChange?: (value: string) => void;
}) => {
    return (
        <div className={classNames([ styles.container, className ])}>
            {
                sizeList.map(size => {
                    return (
                        <div
                            key={size}
                            className={
                                classNames({
                                    [styles.sizeTagItem]: true,
                                    [styles.sizeTagItemActive]: size === value,
                                })
                            }
                            onClick={() => {
                                onChange?.(size === value ? '' : size)
                            }}
                        >
                            {size}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RowSizeItem
