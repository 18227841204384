import React from 'react'
import classnames from 'classnames'
import { LinkProps } from './types'
import styles from './styles.module.scss'

export const Link = ({ href = '#', target = '_self', children, className = '' }: LinkProps) => 
    <a target={target} rel="noopener noreferrer" className={classnames([ styles.link, className ])} href={href}>
        {children}
    </a>

