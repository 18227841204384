/**
 * 1. Filter 中 Competitor, Region, Currency 不让选    disabled 属性不起作用
 * 2. FilterTags 里面 Competitor, Region, Currency 硬编码
 */

/**
 * 表格 Region 硬编码只显示 Germany
 */
export const regionListForTable = [
    'Germany',
    'United Kingdom',
]

/**
 * 页面内 Competitor 下拉框硬编码只有两个
 */
export const competitorOptions = [
    { label: 'Calvin Klein@Zalando', value: 'Calvin Klein@zalandodeep' },
    { label: 'Tommy Hilfiger@Zalando', value: 'Tommy Hilfiger@zalandodeep' },
]
