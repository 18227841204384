import React, { useRef, useState } from 'react'
import styles from './InputMessage.module.scss'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { getCurrentPageCsvData } from './utils'
import classNames from 'classnames'
import { isPriceArchitecturePage, isSearchForOptionsPage } from 'global/Routes'

export const loadDataStr = '[Load data]'

const InputMessage = ({
    onSendMessage,
}: {
    onSendMessage: (message: string, html: any) => void
}) => {
    const editableDivRef = React.useRef<HTMLDivElement>(null)
    const lastEditRangeRef = useRef<Range>()
    const loadDataListRef = useRef<any>({})
    const [ loading, setLoading ] = useState(false)

    const [ csvDatas ] = useCsvDataModuleData()
    const csvData = getCurrentPageCsvData(csvDatas)

    const onRecordLastEditRange = () => {
        const selection = getSelection()
        if (!selection) return
        lastEditRangeRef.current = selection.getRangeAt(0)
    }

    const onLoad = async (e) => {
        if (loading || !csvData) return
        e.preventDefault()

        // 获取焦点
        if (!lastEditRangeRef.current) {
            editableDivRef.current?.focus()
            onRecordLastEditRange()
        }

        // 如果当前页面已经 load data 了, 就不再 load data 了
        // if (loadDataListRef.current[window.location.href]) return 
        // 没有 load data
        loadDataListRef.current[window.location.href] = csvData
        
        if (isPriceArchitecturePage()) {
            const wrapper = document.createElement('div')
            
            const rangeData = document.createElement('div')
            rangeData.innerText = 'Here is the price architecture range data:'
            wrapper?.appendChild(rangeData)

            const span = document.createElement('div')
            span.classList.add(styles.loadDataSpan)
            span.innerText = loadDataStr
            wrapper?.appendChild(span)

            const summaryData = document.createElement('div')
            summaryData.innerText = 'Here is the summary of the price architecture data:'
            wrapper.appendChild(summaryData)

            const span2 = document.createElement('div')
            span2.classList.add(styles.loadDataSpan)
            span2.innerText = loadDataStr
            wrapper.appendChild(span2)

            lastEditRangeRef.current?.insertNode(wrapper)
            return
        }

        const leftSpace = document.createTextNode(' ')
        lastEditRangeRef.current?.insertNode(leftSpace)

        const span = document.createElement('span')
        span.classList.add(styles.loadDataSpan)
        span.innerText = loadDataStr
        lastEditRangeRef.current?.insertNode(span)

        const div = document.createElement('div')
        div.classList.add(styles.loadDataDiv)
        div.innerText = csvData
        span.appendChild(div)

        const rightSpace = document.createTextNode(' ')
        lastEditRangeRef.current?.insertNode(rightSpace)
    }

    /**
     * 获取输入框内容, 调用 onSend 方法
     */
    const onSend = async () => {
        if (loading) return
        const editableDivEl = editableDivRef.current
        if (!editableDivEl) return
        const inputValue = editableDivEl.innerText
        const html = editableDivEl.innerHTML
        // 发送消息后需要清空输入框内容
        editableDivRef.current.innerHTML = ''
        setLoading(true)
        await onSendMessage(inputValue, html)
        setLoading(false)
    }

    return (
        <div className={styles.wrapper}>
            <div
                ref={editableDivRef}
                contentEditable={loading ? false : 'plaintext-only'}
                className={styles.inputDiv}
                data-placeholder={loading ? 'Loading...' : 'Type and press [enter]...'}
                onClick={onRecordLastEditRange}
                onKeyUp={onRecordLastEditRange}
            />
            <div className={styles.actions}>
                <div 
                    className={classNames({
                        [styles.loadData]: true,
                        [styles.loadDataDisabled]: !csvData,
                    })}
                    onClick={onLoad}
                >
                    { !isSearchForOptionsPage() ? 'Load data' : '' }
                </div>
                <div
                    className={styles.sendMessage}
                    onClick={onSend}
                >
                    Send message
                </div>
            </div>
        </div>
    )
}

export default InputMessage
