
/** *
 * 
 *  # memo cache keys enum
 * 
 */
export enum CacheKeys {
    CACHE_SLICE = 'CACHE_SLICE',

    /**
     * Filter.Category.
     */
    IS_ALL_CATEGORY_SELECTED = 'IS_ALL_CATEGORY_SELECTED'
}

/**
 *  # set memo cache
 * 
 * @param key 
 * @param value 
 */
export const setMemoCache = (key: string, value: any) => {
    window[key] = value
}

export const getMemoCacheByKey = (key: string) => window[key]

export const setSelectAllCategory = (category:boolean) => {
    setMemoCache(CacheKeys.IS_ALL_CATEGORY_SELECTED,category)
}
