import React, { CSSProperties, FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { throttle } from 'lodash'
import { regionShortMappingFn } from 'utils'
import { FILTER_DIALOG_CLASS } from 'consts'
import useCustomState from 'hooks/useCustomState'
import { calcPosition, FILTER_LABELS } from './utils'
import { DataType } from './common'
import styles from './styles.module.scss'
import imgIcon from '../../assets/menus/noun_add.png'
import { Checkbox } from './Checkbox'
import { TreeItemCustomProps, TreeItemProps, TreeStateType } from './types'
import { ArrowForwardIcon } from './icons'

const InfoIcon = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={styles.infoIcon}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
    </svg>
  )
}

/**
 * ## TreeItem 
 * @param props
 * @constructor
 */
export const TreeItem = (props: TreeItemProps) => {
  const hasArrow = DataType.ARROW_CHECK === props.type || DataType.ARROW === props.type || DataType.SINGLE_CHECK_ARROW === props.type//
  const hasP1Check = DataType.CHECK === props.type || DataType.SINGLE_CHECK === props.type//
  const { state, updateState } = useCustomState<TreeStateType>({ active: '' })

  const children = props.children || []
  // const [open, setOpen] = React.useState(false);
  const open = props.expandSubPanel
  const setOpen = props.updateExpandSubPanel
  const [ clickShow, setClickShow ] = React.useState(props.clickShow ?? false)

  const clickEffect = useCallback(propsClickShow => {
    if (propsClickShow !== clickShow) {
      setClickShow(propsClickShow)
    }
  }, [ clickShow ])
  useEffect(() => {
    clickEffect(props.clickShow)
  }, [props.clickShow]); //eslint-disable-line
  const { anchor, enableMaskPanel, maskPanelStyleCallback } = props
  /**
   * 判断子 panel 的位置
   */
  const panelRef = useRef<HTMLDivElement>(null)
  /**
   * item element
   */
  const eleRef = useRef<HTMLDivElement>(null)
  // 子菜单面板初始偏移高度
  let top = anchor === 'left' ? '-10px' : '32px'
  top = anchor === 'right' ? '0px' : top

  const [ contentPanelStyle, setContentPanelStyle ] = useState<CSSProperties>({ top })
  // 蒙层（Tree 之外的部分）面板样式
  const [ maskPanelStyle, setMaskPanelStyle ] = useState<CSSProperties>({})
  // 窗口可视高度，如果打开控制台，窗口可视高度会变小
  const [ windowVisualHeight, setWindowVisualHeight ] = useState(window.innerHeight)

  // 监听浏览器 resize 事件，实时改变 windowVisualHeight 值
  useEffect(() => {
    // 节流，控制在 500ms 内只会执行一次 onResizeWindow 事件
    const onResizeWindow = throttle(() => {
      setWindowVisualHeight(window.innerHeight)
    }, 500)

    window.addEventListener('resize', onResizeWindow)

    return () => {
      window.removeEventListener('resize', onResizeWindow)
    }
  }, [])

  const initLayoutFn = useCallback(() => {
    if (!open || !panelRef.current) return
    if (!open || !eleRef.current) return

    // panel 面板相关尺寸对象
    const rect = panelRef.current.getBoundingClientRect()
    const rectEle = eleRef.current.getBoundingClientRect()
    // panel 面板样式对象
    const style: CSSProperties = {}
    // panel 顶部或底部距离浏览器边缘的间隙距离
    const gutter = 20

    // 自定义遮罩层面板样式
    let customMaskPanelStyle: CSSProperties = {}

    if (typeof maskPanelStyleCallback === 'function') {
      // rect：子菜单相关尺寸 {x,y,width,height,left,top,right,bottom}
      customMaskPanelStyle = maskPanelStyleCallback({ rect })
    }

    let maskSty = {}

    if (anchor === 'right') {
      maskSty = {
        right: window.innerWidth - rectEle.right - 340,
        width: '340px', // window.innerWidth - rectEle.right
      }
    } else {
      maskSty = {
        right: rect.left - 340,
        width: '340px', // rect.left
      }
    }
    // 蒙层面板样式
    enableMaskPanel && setMaskPanelStyle({
      position: 'fixed',
      top: rect.top - 40,
      bottom: window.innerHeight - rect.bottom - 30,
      // left: 0,
      // height: '100vh',
      cursor: 'default',
      // width: rect.right,
      ...maskSty,
      backgroundColor: 'transparent',
      // backgroundColor: '#ff00001a',
      ...customMaskPanelStyle,
    })

    setContentPanelStyle({
      ...contentPanelStyle,
      ...calcPosition({
        rect,
        rectEle,
        windowVisualHeight,
        gutter,
        style,
        anchor,
      }),
    })
    // eslint-disable-next-line
  }, [open, anchor, enableMaskPanel, windowVisualHeight])

  useEffect(() => {
    if (open) initLayoutFn()
  }, [open]) //eslint-disable-line

  const onClickMaskPanel = (e: SyntheticEvent) => {
    e.stopPropagation()
    const clickPanels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.norna-tree-item-click-panel')
    if (clickPanels.length === 0) return
    const toppestClickPanel = clickPanels[0]
    toppestClickPanel.click()
    props?.onClickMask?.()
  }

  return (
    <div
      className={classnames(
        styles['norna-tree-item-container'],
        props.active ? styles.active : '',
        open && props.children ? styles.hover : '',
        props.disabled ? styles.disabled : '',
        props?.masked ? styles.masked : '',
        'cell-mouse-effect-no-display-no-within',
      )}
      onMouseEnter={() => {
        // if (props.interactiveType === TreeInteractiveType.CLICK) return;
        if (!clickShow) {
          // 如果有从`Filter`打开的弹框处于打开状态，则不展开二级菜单
          if (!document.querySelector(`.${FILTER_DIALOG_CLASS}`))
            setOpen?.(true)
        }
      }}
    >
      <div ref={eleRef} className={styles['norna-tree-item-label']}>
        <div
          className={classnames(styles.flex, styles['tree-label'], props.disabled ? styles.disabled : '', props?.masked ? styles.masked : '')}
          onClick={() => {
            if (props.disabled) return

            if (props.click && !children.length) {
              props.click({ setTreeItem: props.setTreeItem, item: props.item, filterTmpState: props?.filterTmpState })
            } else if (props.enableSelectAll) {
              props.onChange?.(props.index, !props.active)
              props.selectAllFn?.({ setTreeItem: props.setTreeItem, item: props.item })
            }
          }}
          style={props.labelSty}
        >
          <button
            className={classnames(styles.flex1, styles.label, props.disabled ? styles.disabled : '', props?.masked ? styles.masked : '')}
            style={{ paddingLeft: hasArrow || hasP1Check ? 0 : '0px' }}
            onClick={e => {

              e.stopPropagation()
              e.preventDefault()
              if (props.disabled) return
              if (clickShow) { // click show
                setOpen?.(true)
              }

              if (!children.length) {
                /**
                 * `Tree Item Label`的点击事件
                 * 1. 点击category的回调
                 * 2. 点击price range 的回调
                 * 3. 点击material/color fitler 的回调
                 */
                if (props.click) {
                  props.click({ setTreeItem: props.setTreeItem, item: props.item, filterTmpState: props?.filterTmpState })
                } else {
                  props.onChange?.(props.index, !props.active)
                }
              } else if (props.enableSelectAll) {
                props.onChange?.(props.index, !props.active)
                props.selectAllFn?.({ setTreeItem: props.setTreeItem, item: props.item })
              }
            }}
          >
            {props.isCountry ? regionShortMappingFn(props.label) : props.label}
          </button>
          {hasArrow ? (
            <div className={classnames(styles['tree-cheroven-left'],
              props.disabled ? styles.disabled : '', props?.masked ? styles.masked : '')}
            >
              <ArrowForwardIcon />
            </div>
          ) : null}
          {hasP1Check ? (
            <button className={classnames(styles['tree-cheroven-left'], props.disabled ? styles.disabled : '', props?.masked ? styles.masked : '')}>
              <Checkbox
                active={props.active}
                onChange={() => {
                  if (props.disabled) return
                  props.onChange?.(props.index, !props.active)
                  props.click?.({ setTreeItem: props.setTreeItem, item: props.item })

                }}
              />
            </button>
          ) : null}

          {props.icon ? <img src={imgIcon} alt="icon" style={{ width: 27, marginRight: 15 }} /> : null}
        </div>
      </div>

      {
        !props.disabled && props.children && open ? (
          <>
            <div
              className="norna-tree-item-click-panel"
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation()
                setOpen?.(false)
              }}
            />
            {props.enableMaskPanel ? (
              <div
                className="norna-tree-item-mask-panel"
                style={maskPanelStyle}
                onClick={onClickMaskPanel}
              />
            ) : null}
            <div
              className={styles['norna-tree-item-panel']} style={{
                ...contentPanelStyle,
                ...props.customStyle || {},
              }} ref={panelRef}
            >
              <>
                {
                  children.map((item, index) => (
                    <TreeItem
                      // 这里会报错有 key 相同的，但是很不好找到底是哪个 key
                      key={item.label + index.toString()}
                      {...item}
                      filterTmpState={props?.filterTmpState}
                      interactiveType={props.interactiveType}
                      expandSubPanel={state.active === item.label}
                      updateExpandSubPanel={(flag: boolean) => updateState({ active: flag ? item.label : '' })}
                      anchor={props.anchor === 'bottom' ? 'right' : props.anchor}
                      item={item}
                      isCountry={props.label === FILTER_LABELS.COUNTRY}
                      onChange={props.onChange}
                      enableMaskPanel={props.enableMaskPanel}
                      onClickMask={props.onClickMask}
                      index={[ props.index, index ]}
                    />
                  ))
                }
                {
                  props.label === FILTER_LABELS.CATEGORY ? (
                    <div
                      style={{
                        paddingLeft: 25,
                        paddingRight: 10,
                        marginBlock: 15,
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'left',
                        width: 250,
                      }}
                    >
                      <InfoIcon />
                      <div 
                        style={{
                          fontSize: 10,
                          color: '#666',
                          lineHeight: '11px',
                          whiteSpace: 'pre-wrap',
                          fontFamily: 'Nunito Sans',
                        }}
                      >
                        Increasing the number of categories will result in a system slowdown.
                      </div>
                    </div>
                  ) : null
                }
              </>
            </div>
          </>
        ) : null
      }
    </div>
  )
}
TreeItem.defaultProps = {
  disabled: false,
  anchor: 'left',
  clickShow: false,
  enableMaskPanel: true,
  labelSty: {},
}
export const TreeItemCustom: FC<TreeItemCustomProps> = ({ item, setTreeItem,getVals,updateTreeData, disabled = false, updateExpandSubPanel, expandSubPanel }: TreeItemCustomProps) => (
  <div
    className={classnames(styles['norna-tree-item-container'], styles.containerCustom)}
    onMouseEnter={() => updateExpandSubPanel?.(true)}
  >
    <div style={{ flex: 1 }}>
      <div className={classnames(styles['launch-after'], item.val ? styles.active : '', disabled ? styles.disabled : '')}>{item.label}</div>
      {!disabled && item.render({ item, setTreeItem, expandSubPanel,getVals,updateTreeData })}
    </div>
    {item.icon ? <img src={imgIcon} alt="icon" /> : null}
  </div>
)
