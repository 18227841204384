import React, { useRef, useState } from 'react'
import { LazyBgImg } from 'components/Image'
import { Text } from 'components/typography'
import { ShowMore } from 'components/ShowMore'
import { EditIcon } from 'assets/icons/Edit'
import { DeleteIcon } from 'assets/icons/Delete'
import { BellIcon } from 'assets/icons/Bell'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Button } from 'components/form-elements/Button'
import { PlusIcon } from 'assets/icons/Plus'
import { useGetMyLookbooks } from 'graphql/lookbook/getMyLookbooks'
import { LoadingSpinner, NoCategory } from 'assets/icons'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setLookbook } from 'features/filters/filtersSlice'
import { OPTIONS_NAME } from 'consts'
import { useCreateLookbook, useModifyLookbook, useRefreshLookbookList, useRemoveLookbook } from 'hooks/api/useLookbookData'
import { useLookbook } from 'hooks/useLookbook'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { LookbookList } from './types'
import { CreateLookbookModal } from '../CreateLookbookModal'
import { EditLookbookModal } from '../EditLookbookModal'
import { DeleteLookbookModal } from '../DeleteLookbookModal'
import styles from './styles.module.scss'

export const LookbooksList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false)
  const lookbookRef = useRef<any>({})
  const [ isEditModalOpen, setIsEditModalOpen ] = useState(false)
  const [ isCreateModalOpen, setIsCreateModalOpen ] = useState(false)
  const { createLookbook } = useCreateLookbook()
  const { removeLookbook } = useRemoveLookbook()
  const { modifyLookbook } = useModifyLookbook()
  const { refreshLookbookList, loading: refreshLookbookListLoading } = useRefreshLookbookList({ isUpdateLatestLookbook: true })
  const { data, hasMoreLookbooks, isFetchingMore, fetchMoreLookbooks } = useGetMyLookbooks()
  const [ selectedLookbook, setSelectedLookbook ] = useState(data ? data[0] : {})
  const { myLookbookList } = useLookbook()

  const editLookbookClicked = (lookbook: LookbookList) => {
    setSelectedLookbook(lookbook)
    setIsEditModalOpen(true)
  }

  const editLookbook = async (updatedLookbook: LookbookList) => {
    // loadingBar.continuousStart()
    try {
      await modifyLookbook({ name: updatedLookbook.name, desc: updatedLookbook.desc, id: updatedLookbook.id })
      await refreshLookbookList()
      setIsEditModalOpen(false)
      // loadingBar.complete()
    } catch (e) {
      // loadingBar.complete()
    }
  }

  const deleteLookbookClicked = (lookbook: LookbookList) => {
    setSelectedLookbook(lookbook)
    setIsDeleteModalOpen(true)
  }

  const deleteLookbookItem = async () => {
    try {
      await removeLookbook(selectedLookbook ? selectedLookbook.id : '')
      await refreshLookbookList()
      setIsDeleteModalOpen(false)
    } catch (e) {
      // loadingBar.complete()
    }
  }

  const dialogRef = useRef<DialogRefType>({} as DialogRefType)

  const createLookbookClicked = () => {
    const dialogId = 'create-new-lookbook'
    const closeFn = dialogRef.current?.closeDialog
    dialogRef.current?.openDialog(dialogId,
        <CreateLookbookModal
            closeModal={() => {
                closeFn(dialogId)
            }}
            isOpen={true}
            hideBackBtn
        />,
    )
  }

  const createLookbookItem = async lookbook => {
    // loadingBar.continuousStart()
    try {
      setIsCreateModalOpen(false)
      await createLookbook(lookbook.name, lookbook.desc)
      await refreshLookbookList()
      // loadingBar.complete()
    } catch (e) {
      // loadingBar.complete()
    }
  }

  const generateComparisionId = async lookbook => {
    // loadingBar.continuousStart();
    try {
      lookbookRef.current = lookbook
      // await createComparison(lookbook.id, [], lookbook.name, 'lookbook');
      dispatch(setLookbook(lookbook))
      history.push(
        `/lookbook/${lookbook.id}/1`,
      )
      // loadingBar.complete();
    } catch (e) {
      // loadingBar.complete();
    }
  }

  if (refreshLookbookListLoading) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <>
      <Dialog ref={dialogRef} />
      <div className={styles.lookbookHeader}>
        <div className={styles.createLookbookBtn}>
          <Button
            primary
            icon={<PlusIcon />}
            onClick={createLookbookClicked}
          >
            Create lookbook
          </Button>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {myLookbookList?.length === 0 ? <NoCategory>Please create your first lookbook</NoCategory> : null}
        {Array.isArray(myLookbookList) && myLookbookList?.length ? myLookbookList.map(lookbook =>
          <div className={styles.cardWrapper} key={lookbook.id} >
            <div className={styles.card}>
              <div onClick={() => generateComparisionId(lookbook)}>
                <div className={styles.innerCardWrapper}>
                  <div className={styles.imageWrap}>
                    <LazyBgImg 
                      src={lookbook?.images?.[0]}
                      width={240}
                      height={139}
                      objectFit="contain"
                    />
                  </div>
                  <div className={styles.textWrap}>
                    <div className={styles.heading}>
                      <BellIcon />
                      <Text onHoverChangeColor large bold title={lookbook.name} className={styles.lookbookName}>
                        {lookbook.name}
                      </Text>
                    </div>
                    <div style={{ flex: 1 }} />
                    <div className={styles.description}>
                      <Text secondary small semibold title={lookbook.desc} className={styles.lookbookDesc}>
                        {lookbook.desc}
                      </Text>
                      <br />
                      <Text secondary small semibold title={lookbook.desc}>
                        {OPTIONS_NAME}: {lookbook?.lookbookproductions?.length}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.showMore}>
                <ShowMore isFromLookbook={true} showTitle={false}>
                  <ShowMore.Item onClick={() => editLookbookClicked(lookbook)}>
                    <EditIcon /><Text small>Edit</Text>
                  </ShowMore.Item>
                  <ShowMore.Item onClick={() => deleteLookbookClicked(lookbook)}>
                    <DeleteIcon /><Text small>Delete</Text>
                  </ShowMore.Item>
                </ShowMore>
              </div>
            </div>
          </div>,
        ) : null}

        <ShouldRender shouldRender={isDeleteModalOpen}>
          <DeleteLookbookModal
            closeModal={() => {
              setIsDeleteModalOpen(false)
            }}
            onDelete={deleteLookbookItem}
            isOpen={isDeleteModalOpen}
          />
        </ShouldRender>
        <ShouldRender shouldRender={isEditModalOpen}>
          <EditLookbookModal
            closeModal={() => {
              setIsEditModalOpen(false)
            }}
            onSave={editLookbook}
            isOpen={isEditModalOpen}
            data={selectedLookbook}
          />
        </ShouldRender>
        <ShouldRender shouldRender={isCreateModalOpen}>
          <CreateLookbookModal
            hideBackBtn
            closeModal={() => {
              setIsCreateModalOpen(false)
            }}
            onSave={createLookbookItem}
            isOpen={isCreateModalOpen}
          />
        </ShouldRender>
      </div>
      <InfiniteScroll
        hasMore={hasMoreLookbooks}
        isLoading={isFetchingMore}
        onLoadMore={fetchMoreLookbooks}
      />
    </>
  )
}
