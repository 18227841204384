import { ALL_VENDORS, IS_LIVE_ENV, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { OPTIONS_NAME } from 'consts/strings'
import { cloneDeep } from 'lodash'
import { numberUtils } from 'norna-uikit'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { whetherLock } from 'utils/lockSellerUtils'
import { getColorOptions, getMaterialOptions } from 'utils/optionsUtils'
import { storage } from 'utils/storage'

type FlattenDataRetrun = {
    vendor: string;
    vendorName?: string;
    metric: string;
    yAxis?: string;
    data: {
        time: string;
        value?: number;
    }[];
}[]

/**
 * 数据结构扁平化
 */
export const flattenData = (apiData, regionValue, sellers): FlattenDataRetrun => {
    const newApiData = cloneDeep(apiData)

    const vendorList = Object.keys(newApiData || {})
    if (!vendorList?.length) return []

    const dateList = Object.keys(newApiData[vendorList[0]] || {})
    const metricList = Object.keys(newApiData[vendorList[0]][dateList[0]] || {})
    const codenames = storage.getCodenames()

    const dataSource: any[] = []
    vendorList.forEach(vendor => {
        // seller 不存在时, 线图上面不显示对应线段
        const isLock = whetherLock({
            vendorCode: vendor,
            region: regionValue,
            sellers,
            selectedVendorCodeList: vendorList.filter(vendor => ![ SELECTED_VENDORS, ALL_VENDORS, MARKET_VENDOR.vendor ].includes(vendor)),
        })
        if (isLock) {
            return false
        }

        metricList.forEach(metric => {
            const data = dateList.map(date => {
                let data = newApiData[vendor][date][metric]
                if (metric.startsWith('Color_') || metric.startsWith('Material_')) {
                    data = data * 100
                }
                if (
                    [ 'Discount width', 'Discount depth', 'Discount effect', 'Sold out', 'Rotation', 'Price index' ].includes(metric)
                ) {
                    data = data * 100
                }
                const queryDateValid = isQueryDateValid({
                    vendor,
                    region: regionValue,
                    competitorValue: vendorList,
                    date,
                    isValidWhenLaunchDateNotExist: true,
                })
                if (!queryDateValid && typeof data === 'number') {
                    data = undefined
                }
                return {
                    time: date,
                    value: data,
                }
            })
            dataSource.push({
                vendor,
                vendorName: codenames[vendor] || vendor, 
                metric,
                data,
            })
        })
    })

    return dataSource
}

/**
 * 生成线图需要的数据结构
 */
export const handleChartData = ({
    apiData = [],
    switchValue,
    competitorValue = [],
    metricValue = [],
    regionValue,
}: {
    apiData?: any[];
    switchValue?: boolean;
    competitorValue?: { vendor: string; }[],
    metricValue?: string[];
    regionValue?: string;
}) => {
    const sellers = storage.getSellers()
    const data = flattenData(apiData, regionValue, sellers)
    const dataSource: any[] = []

    let leftYAxisChartData: any[] = []
    let rightYAxisChartData: any[] = []
    // 两根 y 轴线, vendor 最多只能有一个
    if (switchValue === true) {
        // 两根 y 轴线, metric 至少有个一值, 左边 y 轴线必定有值
        const { vendor } = competitorValue[0]
        const yAxisData = data
            .filter(item => {
                return item.vendor === vendor && item.metric === metricValue[0]
            })
            .map(item => {
                return {
                    yAxisName: getMetricDisplayByMetricParam(item.metric),
                    yAxisPosition: 'left',
                    label: getMetricDisplayByMetricParam(item.metric),
                    data: item.data,
                }
            })
        dataSource.push(...yAxisData)

        // 两根 y 轴线, metric 有两个值, 右边 y 轴线也有值
        if (metricValue.length > 1) {
            rightYAxisChartData = data
                .filter(item => {
                    return item.vendor === vendor && item.metric === metricValue[1]
                })
                .map(item => {
                    item.yAxis = 'right'
                    return {
                        yAxisName: getMetricDisplayByMetricParam(item.metric),
                        yAxisPosition: 'right',
                        label: getMetricDisplayByMetricParam(item.metric),
                        data: item.data,
                    }
                })
            dataSource.push(...rightYAxisChartData)
        }
    }
    // 一根 y 轴线, 默认是左边 y 轴线, metric 最多只能有一个
    else {
        const vendors = competitorValue.map(c => c.vendor)
        leftYAxisChartData = data
            .filter(item => vendors.includes(item.vendor) && item.metric === metricValue[0])
            .map(item => {
                return {
                    yAxisName: getMetricDisplayByMetricParam(item.metric),
                    yAxisPosition: 'left',
                    label: item.vendorName,
                    data: item.data,
                }
            })
        dataSource.push(...leftYAxisChartData)
    }

    return dataSource
}

export const METRIC_AVERAGE_PRICE = 'Average price'
export const METRIC_OPTIONS = OPTIONS_NAME
export const METRIC_DISCOUNT_EFFECT = 'Discount effect'
export const METRIC_OPTIONS_IN = 'Options in'
export const METRIC_KEY_COLOR = 'Color'
export const METRIC_KEY_MATERIAL = 'Material'

export function getMetricOptions() {
    const metricList = [
        METRIC_AVERAGE_PRICE, METRIC_OPTIONS, 'Price index', 'Discount width', 'Discount depth', METRIC_DISCOUNT_EFFECT,
        'Size ratio',
        IS_LIVE_ENV ? '' : 'Option ratio',
        'Sold out',
        'Eco label',
        'Minimum price', 'Median price', 'Maximum price',
        'Rotation', METRIC_OPTIONS_IN, 'Options out',
    ].filter(item => item)

    const metricOptions: any[] = metricList.map(m => ({ key: getMetricParamByMetricDisplay(m), description: m }))

    // const categoryOptions = getCategoryOptions({ excludeTotal: true }).map(m => ({ key: `Category_${m.key}`, description: m.key }))
    const colorOptions = getColorOptions().map(m => ({ key: `Color_${m.key}`, description: m.key }))
    const materialOptions = getMaterialOptions().map(m => ({ key: `Material_${m.key}`, description: m.key }))
    metricOptions.unshift({ key: METRIC_KEY_MATERIAL, description: 'Material', notAllowed: true, children: materialOptions })
    metricOptions.unshift({ key: METRIC_KEY_COLOR, description: 'Color', notAllowed: true, children: colorOptions })
    // metricOptions.unshift({ key: 'Category', description: 'Category',  children: categoryOptions })

    return metricOptions
}

/**
 * 前端 metric 名称经常会修改, 这个方法维护前端 metric 显示名称与接口 metric 传参映射关系
 * 1. 页面显示是 # Options, 参数传递是 Options
 */
export function getMetricParamByMetricDisplay(metric) {
    if (metric.startsWith('Color ')) {
        return metric.replace('Color ', 'Color_')
    }
    if (metric.startsWith('Material ')) {
        return metric.replace('Material ', 'Material_')
    }
    const mapper = {
        '# Options': 'Options',
    }
    return mapper[metric] || metric
}

export function getMetricDisplayByMetricParam(metricParam) {
    if (metricParam.startsWith('Color_')) {
        return metricParam.replace('Color_', 'Color ')
    }
    if (metricParam.startsWith('Material_')) {
        return metricParam.replace('Material_', 'Material ')
    }
    const mapper = {
        Options: '# Options',
    }
    return mapper[metricParam] || metricParam
}

export function formatMetricValue(value, metric = '') {
    let result = value ? value.toString() : ''
    if (
        [ 'Discount width', 'Discount depth', 'Discount effect', 'Sold out', 'Rotation', 'Price index' ].includes(metric) 
    ) {
        // 乘以100,保留1位小数,加百分号,加千分符
        result = numberUtils.formatNumber(value, { decimal: 1, isPercentSymbol: true, isCommaSymbol: true })
    } 
    else if (
        metric.startsWith('Color ') || metric.startsWith('Material ')
        || metric.startsWith('Color_') || metric.startsWith('Material_')
    ) {
        result = numberUtils.formatNumber(value, { decimal: 1, isPercentSymbol: true, isCommaSymbol: true })
    }
    else if ([ 'Size ratio', 'Option ratio' ].includes(metric)) {
        // 保留1位小数,加千分符
        result = numberUtils.formatNumber(value, { decimal: 2, isCommaSymbol: true })
    }
    else if ([ 'Eco label' ].includes(metric)) {
        // 保留1位小数,加百分号,加千分符
        result = numberUtils.formatNumber(value, { decimal: 1, isCommaSymbol: true, isPercentSymbol: true })
    }
    else if ([ 'Average price', 'Minimum price', 'Median price', 'Maximum price' ].includes(metric)) {
        // 保留指定小数位数,加千分符
        result = numberUtils.formatNumber(value, { decimal: 1, isCommaSymbol: true })
    } else {
        // 保留0位小数,加千分符
        result = numberUtils.formatNumber(value, { decimal: 0, isCommaSymbol: true })
    }
    return result
}

export function formatMetricStep(metric) {
    let result = 1
    if (
        metric.startsWith('Color_') || metric.startsWith('Material_')
    ) {
        result = 0.01
    }
    else if (
        [ 'Discount width', 'Discount depth', 'Discount effect', 'Sold out', 'Rotation' ].includes(metric) 
    ) {
        result = 0.1
    } 
    else if ([ 'Size ratio', 'Option ratio' ].includes(metric)) {
        result = 0.01
    } 
    else if ([ 'Price index' ].includes(metric)) {
        result = 0.01
    }
    else if ([ 'Eco label' ].includes(metric)) {
        result = 0.01
    }
    else if ([ 'Average price', 'Minimum price', 'Median price', 'Maximum price' ].includes(metric)) {
        result = 0.01
    } else {
        result = 1
    }
    return result
}
