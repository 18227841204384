import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { ButtonProps } from './types'
import styles from './styles.module.scss'
import { InlineLoading } from '../../assets/icons/Loading'

/**
 *
 * Button Component
 */
export const Button = ({ style,iconSty={}, left, right, className, children, onClick, loading, disabled, title, type, htmlType,
                              onlyIcon, icon, mini, miniLarge, noShadow, hoverShadow, rotateIcon45, loadingSty }: ButtonProps) => {

  const defaultStyle: CSSProperties = {}
  if (typeof left !== 'undefined') {
    defaultStyle.marginLeft = typeof left === 'number' ? left + 'px' : left
  }
  if (typeof right !== 'undefined') {
    defaultStyle.marginRight = typeof right === 'number' ? right + 'px' : right
  }

  const buttonClassName = classnames(
    styles.button,
    disabled && styles.disabled,
    loading && styles.disabled,
    type && styles[type],
    // 图标相关
    icon && styles.withIcon,
    onlyIcon && styles.onlyIcon,
    // 未可知
    mini && styles.mini,
    miniLarge && styles.miniLarge,
    noShadow && styles.noShadow,
    hoverShadow && styles.hoverShadow,
    rotateIcon45 && styles.rotateIcon45,
    className,
  )

  return (
    <button
      style={{ ...defaultStyle, ...style }}
      className={buttonClassName}
      onClick={e => !loading && !disabled && onClick?.(e)}
      disabled={disabled || loading}
      title={title}
      type={htmlType}
    >
      {loading ? <InlineLoading sty={loadingSty} /> : <div className={classnames(styles.icon)} style={iconSty}>{icon}</div>}
      <div className={classnames(styles.content, disabled && styles.disabled, className)}>
        {children}
      </div>
    </button>
  )
}
Button.defaultProps = {
  style: {},
  disabled: false,
  htmlType: 'button',
}
