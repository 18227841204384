import React, { useEffect, useState } from 'react'
import { Button, Select } from 'druikit';
import { Text } from 'components/typography'
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import { QA_BUTTON_IN_OUT_FIX_LABEL, QA_BUTTON_TARGET_PRODUCT_DATA, QA_BUTTON_TARGET_TO_DB3, QA_BUTTON_TARGET_UNDO_DB3 } from 'consts/strings';
import styles from './InOutFixDialog.module.scss'
import { getCompetitorOptions, getQaButtonTargetOptions, getRegionOptions } from 'utils/optionsUtils';
import { cloneDeep } from 'lodash';
import { storage } from 'utils/storage';
import { useFetch } from 'libs';
import { dateUtils } from 'norna-uikit'

const inOutTypeOptions = [
    { value: 'product_in', label: 'Product in' },
    { value: 'product_out', label: 'Product out' },
]

const distributionOptions = [
    { value: 'even', label: 'Even' },
    { value: 'random', label: 'Random' },
]

export default function InOutFixDialog({
    closeModal,
}: {
    closeModal?: (value: boolean) => void;
}) {
    const [ targetValue, setTargetValue ] = useState<string>(QA_BUTTON_TARGET_TO_DB3)
    const [ vendorValue, setVendorValue ] = useState<string>('')
    const [ regionValue, setRegionValue ] = useState<string>('')
    const [ startDateValue, setStartDateValue ] = useState<string>(dateUtils.today())
    const [ endDateValue, setEndDateValue ] = useState<string>(dateUtils.today())
    const [ peakStartDateValue, setPeakStartDateValue ] = useState<string>(dateUtils.today())
    const [ peakEndDateValue, setPeakEndDateValue ] = useState<string>(dateUtils.today())
    const [ inOutTypeValue, setInOutTypeValue ] = useState('product_in')
    const [ distributionValue, setDistributionValue ] = useState<string>('even')
    const [ leftOutValue, setLeftOutValue ] = useState<string>('0')

    const [ regionOptions, setRegionOptions ] = useState<any[]>([])
    const [ vendorOptions, setVendorOptions ] = useState<any[]>([])

    useEffect(() => {
        let regionOptions: any[] = getRegionOptions()
        if (!regionOptions.length) return
        regionOptions = regionOptions.map(item => ({ value: item.key, label: item.key }))
        const regionValue = regionOptions[0].value
        const vendorOptions = getCompetitorOptions({ region: regionValue, excludeMarket: true, excludeSelectedVendor: true })
            .map(item => ({ value: item.key, label: item.key }))
        setRegionValue(regionValue)
        setRegionOptions(regionOptions)
        setVendorOptions(vendorOptions)
        setVendorValue(vendorOptions[0]?.value || '')
    }, [])

    const { postFn: fetch } = useFetch()
    const [ loading, setLoading ] = useState(false)

    const onOk = async () => {
        const customer = storage.getCustomerVendor()
        const user = localStorage.getItem('email')
        const sellers = storage.getSellers()
        const seller = sellers.find(item => item.vendor === vendorValue && item.region === regionValue)

        if (!startDateValue || !endDateValue || !peakStartDateValue || !peakEndDateValue) {
            alert('Form items cannot be empty.')
            return
        }

        let payload: any = {}
        if (targetValue === QA_BUTTON_TARGET_TO_DB3) {
            payload = {
                url: '/price_update/in_out_spread_to_db3/',
                query: {
                    customer,
                    start_date: startDateValue,
                    end_date: endDateValue,
                    in_out_type: inOutTypeValue,
                    user,
                    distribution: distributionValue,
                    left_out: Number(leftOutValue),
                },
                data: {
                    seller: {
                        vendor: seller.vendor,
                        region: seller.region,
                        brand: seller.brand || '',
                    },
                    peak_dates: {
                        dates: [ peakStartDateValue, peakEndDateValue ],
                    },
                },
            }
        } else if (targetValue === QA_BUTTON_TARGET_UNDO_DB3) {
            payload = {
                url: '/price_update/revert_in_out_spread/',
                query: {
                    start_date: startDateValue,
                    end_date: endDateValue,
                    in_out_type: inOutTypeValue,
                    user,
                },
                data: {
                    seller: {
                        vendor: seller.vendor,
                        region: seller.region,
                        brand: seller.brand || '',
                    },
                    peak_dates: {
                        dates: [ peakStartDateValue, peakEndDateValue ],
                    },
                },
            }
        } else {
            payload = {
                url: '/price_update/in_out_spread_to_product_data/',
                query: {
                    start_date: startDateValue,
                    end_date: endDateValue,
                    in_out_type: inOutTypeValue,
                    user,
                },
                data: {
                    seller: {
                        vendor: seller.vendor,
                        region: seller.region,
                        brand: seller.brand || '',
                    },
                    peak_dates: {
                        dates: [ peakStartDateValue, peakEndDateValue ],
                    },
                },
            }
        }

        setLoading(true)
        let result: any
        if (targetValue === QA_BUTTON_TARGET_PRODUCT_DATA) {
            if (window.confirm('Are you sure to do it?')) {
                result = await fetch(payload.url, payload)
            }
        } else {
            result = await fetch(payload.url, payload)
        }
        setLoading(false)

        if (result?.data?.success === true) {
            alert(result?.data?.message || 'success.')
        } else {
            alert(result?.data?.message || 'fail.')
        }
    }

    const handleCloseModal = () => closeModal?.(false)

    return (
        <>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <Text small>
                        {QA_BUTTON_IN_OUT_FIX_LABEL}
                    </Text>
                    <CloseIcon
                        className={styles.closeIcon}
                        onClick={handleCloseModal}
                    />
                </div>
                <div className={classNames(styles.body)}>
                    <div className={styles.form}>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Target
                            </div>
                            <Select 
                                options={getQaButtonTargetOptions()}
                                value={targetValue}
                                onChange={setTargetValue}
                                size="small"
                                placeholder="Target"
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Seller
                            </div>
                            <Select 
                                options={cloneDeep(regionOptions)}
                                value={regionValue}
                                onChange={value => {
                                    setRegionValue(value)
                                    const vendorOptions = getCompetitorOptions({ 
                                            region: value, 
                                            excludeMarket: true,        
                                            excludeSelectedVendor: true,
                                        })
                                        .map(item => ({ value: item.key, label: item.key }))
                                    setVendorOptions(vendorOptions)
                                    setVendorValue(vendorOptions[0]?.value || '')
                                }}
                                size="small"
                                placeholder="Region"
                            />
                            <Select 
                                options={cloneDeep(vendorOptions)}
                                value={vendorValue}
                                onChange={setVendorValue}
                                size="small"
                                placeholder="Vendor"
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                In out type
                            </div>
                            <Select 
                                options={inOutTypeOptions}
                                value={inOutTypeValue}
                                onChange={setInOutTypeValue}
                                size="small"
                                placeholder="In out type"
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Distribution
                            </div>
                            <Select 
                                options={distributionOptions}
                                value={distributionValue}
                                onChange={setDistributionValue}
                                size="small"
                                placeholder="Distribution"
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Start date
                            </div>
                            <input 
                                type="date"
                                className={classNames(styles.date, styles.dateActive)}
                                value={startDateValue}
                                onChange={e => setStartDateValue(e.target.value)}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                End date
                            </div>
                            <input 
                                type="date"
                                className={classNames(styles.date, styles.dateActive)}
                                value={endDateValue}
                                onChange={e => setEndDateValue(e.target.value)}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Peak date range
                            </div>
                            <input 
                                type="date"
                                className={classNames(styles.date, styles.dateActive)}
                                value={peakStartDateValue}
                                onChange={e => setPeakStartDateValue(e.target.value)}
                            />
                            <input 
                                type="date"
                                className={classNames(styles.date, styles.dateActive)}
                                value={peakEndDateValue}
                                onChange={e => setPeakEndDateValue(e.target.value)}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Left out
                            </div>
                            <input 
                                type="number" 
                                className={classNames(styles.numberInput, styles.numberInputActive)}
                                value={leftOutValue}
                                onChange={e => setLeftOutValue(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        className={styles.submitBtn}
                        type="danger"
                        loading={loading}
                        onClick={onOk}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    )
}
