/**
 * Utils about Filter > Property
 */
import { cloneDeep, sortBy, uniq } from 'lodash'
import { CATEGORY_TOTAL, FABRIC_TECH_SPEC, isZeemanLicense, PROPERTY_MULTI_PACK_LABEL, PROPERTY_MULTI_PACK_VALUE } from 'consts'
import { DataType } from 'componentsv2/Tree/common'
import { setPropertyVal } from 'componentsv2/Tree/utils'
import { isSuperArray } from 'utils/array'
import { isObject } from 'utils/objUtils'
import { getPropertyAlias, getPropertyBlacklist } from 'utils/feConfig'
import { storage } from 'utils/storage'

interface PropertyObj {
    priority: string;
    values: Values;
    first_level_grouped_values: GroupedValues;
    second_level_grouped_values: GroupedValues;
    single_values: string[];
    not_show_sub_groups: string[];
}

interface PropertyListItem extends PropertyObj {
    zeroLevelProperty: string;
}

interface PropertiesOfCategory {
    [property: string]: PropertyObj;
}

interface PropertiesOfCategories {
    [category: string]: PropertiesOfCategory;
}

interface GroupedValues {
    [property: string]: string[];
}

interface Values {
    [property: string]: number;
}

/**
 * propertiesOfCategories = {
 *      category01: {
 *          property01: {},
 *          property02: {},
 *      },     
 *      category02: {
 *          property01: {},
 *          property02: {},
 *      },
 * }
 */
export function initNotShowSubGroupsMap(propertiesOfCategories: PropertiesOfCategories): { [property: string]: string[] } {
    propertiesOfCategories = cloneDeep(propertiesOfCategories)
    const notShowSubGroupsMap: { [property: string]: string[] } = {}

    const categoryList = Object.keys(propertiesOfCategories)
    categoryList.forEach(category => {
        const propertiesOfCategory: PropertiesOfCategory = propertiesOfCategories[category]
        const firstLevelPropertyList = Object.keys(propertiesOfCategory)
        const filteredFirstLevelPropertyList = firstLevelPropertyList
        filteredFirstLevelPropertyList.forEach(firstLevelProperty => {
            const propertyObj: PropertyObj = propertiesOfCategory[firstLevelProperty] || {}
            const firstLevelGroupedValues = propertyObj.first_level_grouped_values || []
            const secondLevelGroupedValues = propertyObj.second_level_grouped_values || []
            const notShowSubGroups = propertyObj.not_show_sub_groups || []
            notShowSubGroups.forEach(notShowItem => {
                notShowSubGroupsMap[notShowItem] = firstLevelGroupedValues[notShowItem] || secondLevelGroupedValues[notShowItem] || []
            })
        })
    })

    return cloneDeep(notShowSubGroupsMap)
}

/**
 * Some properties need to be hidden in LIVE
 */
function filterProperties(propertyData: string[]): string[]
function filterProperties(propertyData: object): object
function filterProperties(propertyData: any): any {
    const blacklist = getPropertyBlacklist()

    if (Array.isArray(propertyData)) {
        return propertyData.filter(property => !blacklist.includes(property))
    }

    if (isObject(propertyData)) {
        Object.keys(propertyData || {}).forEach(property => {
            if (blacklist.includes(property)) {
                delete propertyData[property]
            }
        })
        return propertyData
    }
    
    return propertyData
}

/**
 * 接口查到的数据是 Multi Packs，页面上要显示 Sixteen+ Packs，传参时又要修改回 Multi Packs
 * 
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/1009
 */
function handleMultiPack(propertyList: any[]) {
    if (!isZeemanLicense()) {
        return
    }

    propertyList.forEach(item => {
        if (item.zeroLevelProperty === 'Packs' && item?.first_level_grouped_values?.['Multi-packs']) {
            item.first_level_grouped_values['Multi-packs'] = item.first_level_grouped_values['Multi-packs'].map(item2 => {
                if (item2 === PROPERTY_MULTI_PACK_VALUE) {
                    return PROPERTY_MULTI_PACK_LABEL
                }
                return item2
            })
            item.values[PROPERTY_MULTI_PACK_LABEL] = item.values[PROPERTY_MULTI_PACK_VALUE]
            item.not_show_sub_groups = item.not_show_sub_groups.filter(item2 => item2 !== 'Multi-packs')
        }
    })
}

/**
 * Generate property tree data
 */
export function handleFilterPropertyTreeData({
    selectedCategories,
    selectedProperties,
}: {
    selectedCategories: string[];
    selectedProperties: { [property: string]: string[]; };
}) {
    selectedCategories = cloneDeep(selectedCategories)
    selectedProperties = cloneDeep(selectedProperties)
    const originalProperties: any = storage.getProperties()
    
    // Merge the property of multiple categories
    let propertiesOfCategory = mergePropertyBySelectedCategories({ selectedCategories, originalProperties })
    // Handles the priority field of non-leaf nodes
    propertiesOfCategory = handleNonLeafNodesPriority(propertiesOfCategory)
    // property object -> property array
    const propertyList = handlePropertyObjToPropertyList(propertiesOfCategory)
    // sort by priority
    const sortedPropertyList = sortPropertyListByPriority(propertyList)
    handleMultiPack(sortedPropertyList)
    // switch property data to tree data
    let propertyTreeData = handlePropertyDataToTreeData(sortedPropertyList)
    propertyTreeData = propertyTreeData.map(item => {
        const vals = selectedProperties?.[item.val] ?? []
        setPropertyVal?.(item, vals)
        item.active = !!selectedProperties?.[item.val]
        return item
    })
    return propertyTreeData
}

export function getPropertyValueByLabel({
    selectedCategories,
    selectedProperties,
}: {
    selectedCategories: string[];
    selectedProperties: { [property: string]: string[]; };
}) {
    if (!selectedCategories?.length) return {}

    selectedCategories = cloneDeep(selectedCategories)
    selectedProperties = cloneDeep(selectedProperties)
    const originalProperties: any = storage.getProperties()

    // Merge the property of multiple categories
    const propertiesOfCategory = mergePropertyBySelectedCategories({ selectedCategories, originalProperties })
    Object.keys(selectedProperties).forEach(zeroProperty => {
        selectedProperties[zeroProperty] = uniq(selectedProperties[zeroProperty].map(item => {
            if (propertiesOfCategory[zeroProperty]?.first_level_grouped_values?.[item]) {
                return propertiesOfCategory[zeroProperty]?.first_level_grouped_values?.[item]
            }
            return item
        }).flat(10))
    })
    return cloneDeep(selectedProperties)
}

export function getPropertyLabelByValue({
    selectedCategories,
    selectedProperties,
}: {
    selectedCategories: string[];
    selectedProperties: { [property: string]: string[]; };
}) {
    if (!selectedCategories?.length) return {}

    selectedCategories = cloneDeep(selectedCategories)
    selectedProperties = cloneDeep(selectedProperties)
    const originalProperties: any = storage.getProperties()

    // Merge the property of multiple categories
    const propertiesOfCategory = mergePropertyBySelectedCategories({ selectedCategories, originalProperties })
    Object.keys(selectedProperties).forEach(zeroProperty => {
        const currentList = selectedProperties[zeroProperty]
        const propertyObj = propertiesOfCategory[zeroProperty]
        if (!propertyObj) return
        const notShowSubGroups = propertyObj.not_show_sub_groups
        const firstLevelGroupedValues = propertyObj.first_level_grouped_values
        const secondLevelGroupedValues = propertyObj.second_level_grouped_values

        notShowSubGroups.forEach(notShowItem => {
            const firstLevelPropertyList = firstLevelGroupedValues[notShowItem] || []
            const secondLevelPropertyList = secondLevelGroupedValues[notShowItem] || []
            if (firstLevelPropertyList.length && isSuperArray(currentList, firstLevelPropertyList)) {
                selectedProperties[zeroProperty] = selectedProperties[zeroProperty].filter(item => !firstLevelPropertyList.includes(item))
                selectedProperties[zeroProperty].push(notShowItem)
            }
            if (secondLevelPropertyList.length && isSuperArray(currentList, secondLevelPropertyList)) {
                selectedProperties[zeroProperty] = selectedProperties[zeroProperty].filter(item => !secondLevelPropertyList.includes(item))
                selectedProperties[zeroProperty].push(notShowItem)
            }
        })
        selectedProperties[zeroProperty] = uniq(selectedProperties[zeroProperty])
    })
    return cloneDeep(selectedProperties)
}

/* ************************** Merge Start ******************************** */
/**
 * Merge property according to the selected category
 */
export function mergePropertyBySelectedCategories({
    selectedCategories = [],
    originalProperties,
}: {
    selectedCategories: string[];
    originalProperties: PropertiesOfCategories;
}) {
    selectedCategories = cloneDeep(selectedCategories)
    originalProperties = cloneDeep(originalProperties)

    if (selectedCategories.includes(CATEGORY_TOTAL)) {
        selectedCategories = Object.keys(originalProperties)
    }

    const propertiesOfCategoriesList: PropertiesOfCategory[] = selectedCategories.map(category => originalProperties[category] || {})
    if (!propertiesOfCategoriesList.length) return {}
    const firstPropertiesOfCategory: PropertiesOfCategory = propertiesOfCategoriesList[0]
    const restPropertiesOfCategoriesList: PropertiesOfCategory[] = propertiesOfCategoriesList.slice(1)
    restPropertiesOfCategoriesList.forEach(propertiesOfCategory => {
        const propertyList = Object.keys(propertiesOfCategory)
        propertyList.forEach(property => {
            // Add attributes if they don't exist
            if (!firstPropertiesOfCategory[property]) {
                firstPropertiesOfCategory[property] = propertiesOfCategory[property]
            }
            // Merge if exist
            else {
                const firstPropertyObj = firstPropertiesOfCategory[property]
                const propertyObj: PropertyObj = propertiesOfCategory[property]

                // merge priority
                const mergedPriority = String((Number(firstPropertyObj.priority) + Number(propertyObj.priority)) / 2)

                // merge values
                const mergedValues = mergeValues(
                    firstPropertyObj.values,
                    propertyObj.values,
                )

                // merge first_level_grouped_values
                const mergedFirstLevelGroupedValues = mergeGroupedValues(
                    firstPropertyObj.first_level_grouped_values,
                    propertyObj.first_level_grouped_values,
                )

                // merge second_level_grouped_values
                const mergedSecondLevelGroupedValues = mergeGroupedValues(
                    firstPropertyObj.second_level_grouped_values,
                    propertyObj.second_level_grouped_values,
                )

                // merge single_values
                const mergedSingleValues = uniq([
                    ...firstPropertyObj.single_values,
                    ...propertyObj.single_values,
                ])

                // merge not_show_sub_groups
                const mergedNotShowSubGroups = uniq([
                    ...firstPropertyObj.not_show_sub_groups,
                    ...propertyObj.not_show_sub_groups,
                ])

                firstPropertiesOfCategory[property].priority = mergedPriority
                firstPropertiesOfCategory[property].values = mergedValues
                firstPropertiesOfCategory[property].first_level_grouped_values = mergedFirstLevelGroupedValues
                firstPropertiesOfCategory[property].second_level_grouped_values = mergedSecondLevelGroupedValues
                firstPropertiesOfCategory[property].single_values = mergedSingleValues
                firstPropertiesOfCategory[property].not_show_sub_groups = mergedNotShowSubGroups
            }
        })
    })
    return cloneDeep(firstPropertiesOfCategory)
}

/**
 * ```
 * const groupedValues1 = {
 *      Color: ['Yellow'],
 * }
 * 
 * const groupedValues2 = {
 *      Color: ['Black'],
 *      Material: ['Wool'],
 * }
 * 
 * const mergedGroupedValues = mergeGroupedValues(groupedValues1, groupedValues2)
 * // {
 * //      Color: ['Yellow', 'Black'],
 * //      Material: ['Wool'],
 * // }
 * ```
 */
function mergeGroupedValues(groupedValues1: GroupedValues, groupedValues2: GroupedValues) {
    Object.keys(groupedValues2).forEach(property => {
        // Add attributes if they don't exist
        if (!groupedValues1[property]) {
            groupedValues1[property] = groupedValues2[property]
        }
        // Merge if exist
        else {
            groupedValues1[property] = uniq([
                ...groupedValues1[property],
                ...groupedValues2[property],
            ])
        }
    })
    return cloneDeep(groupedValues1)
}

/**
 * ```
 * const values1 = {
 *      "Pig leather": 1.0,
 *      "Calf suede": 1.0,
 * }
 * 
 * const values2 = {
 *      "Pig leather": 3.0,
 *      "Lamb suede": 1.0,
 *      "Sheep leather": 1.0,
 * }
 * ```
 */
function mergeValues(values1, values2) {
    values1 = cloneDeep(values1)
    values2 = cloneDeep(values2)
    Object.keys(values2).forEach(property => {
        // Add attributes if they don't exist
        if (!values1[property]) {
            values1[property] = values2[property]
        }
        // Merge if exist
        else {
            values1[property] = (values1[property] + values2[property]) /2
        }
    })
    return cloneDeep(values1)
}
/* ************************** Merge End ******************************** */

/**
 * The values field contains the sorting priority of the leaf nodes
 * The priority of a non-leaf node is calculated as the average of the priority of all leaf nodes
 */
function handleNonLeafNodesPriority(propertiesOfCategory: PropertiesOfCategory) {
    const firstLevelPropertyList = Object.keys(propertiesOfCategory)
    firstLevelPropertyList.forEach(firstLevelProperty => {
        const propertyObj: PropertyObj = propertiesOfCategory[firstLevelProperty]
        // eslint-disable-next-line prefer-destructuring
        const values = propertyObj.values
        const firstLevelGroupedValues = propertyObj.first_level_grouped_values
        const secondLevelGroupedValues = propertyObj.second_level_grouped_values

        const firstLevelGroupedValuesKeys = Object.keys(firstLevelGroupedValues)
        const secondLevelGroupedValuesKeys = Object.keys(secondLevelGroupedValues)
        // No child node, skip this loop, enter this next loop
        if (!firstLevelGroupedValuesKeys.length) return

        firstLevelGroupedValuesKeys.forEach(property => {
            let totalPriority = 0
            firstLevelGroupedValues[property].forEach(subProperty => {
                totalPriority += values[subProperty] || 0
            })
            const priority = totalPriority / firstLevelGroupedValues[property].length
            propertiesOfCategory[firstLevelProperty].values[property] = priority
        })

        secondLevelGroupedValuesKeys.forEach(property => {
            let totalPriority = 0
            secondLevelGroupedValues[property].forEach(subProperty => {
                totalPriority += values[subProperty] || 0
            })
            const priority = totalPriority / secondLevelGroupedValues[property].length
            propertiesOfCategory[firstLevelProperty].values[property] = priority
        })
    })
    return cloneDeep(propertiesOfCategory)
}

/**
 * object -> array
 * 
 * input data
 * ```
 * {
 *    key1: { priority: 12, othersField: 0 },
 *    key2: { priority: 11, othersField: 0 },
 *    key3: { priority: 13, othersField: 0 },
 * }
 * ```
 *
 * output data
 * ```
 * [
 *    { objKey: 'key2', priority: 11, othersField: 0 },
 *    { objKey: 'key1', priority: 12, othersField: 0 },
 *    { objKey: 'key3', priority: 13, othersField: 0 },
 * ]
 * ```
 */
function handlePropertyObjToPropertyList(propertiesOfCategory: PropertiesOfCategory) {
    let zeroLevelPropertyList = Object.keys(propertiesOfCategory)
    // 过滤 0 级 Property: Packs
    zeroLevelPropertyList = filterProperties(zeroLevelPropertyList)
    const propertyList = zeroLevelPropertyList.map(zeroLevelProperty => {
        return {
            ...propertiesOfCategory[zeroLevelProperty],
            zeroLevelProperty,
        }
    })
    propertyList.sort((m, n) => Number(m.priority) - Number(n.priority))
    return cloneDeep(propertyList)
}

function sortPropertyListByPriority(propertyList) {
    const newPropertyList = cloneDeep(propertyList)
    newPropertyList.sort((m, n) => Number(m.priority) - Number(n.priority))
    return newPropertyList
}

/* ************************** Tree Data Start ******************************** */
/**
 * 
 */
function handlePropertyDataToTreeData(propertyList: PropertyListItem[]) {
    const propertyTreeData = propertyList.map(propertyObj => {
        // eslint-disable-next-line prefer-destructuring
        const { values, zeroLevelProperty } = propertyObj
        const firstLevelGroupedValues = filterProperties(propertyObj.first_level_grouped_values)
        const secondLevelGroupedValues = filterProperties(propertyObj.second_level_grouped_values)
        const firstLevelSingleValues = filterProperties(propertyObj.single_values)
        const notShowSubGroups = propertyObj.not_show_sub_groups

        const sortFieldList = [ 'priority', 'val' ]
        const formatLabel = property => getPropertyAlias(property)

        // handle not_show_sub_groups
        const notShowSubGroupsObj = {}
        notShowSubGroups.forEach(notShowItem => {
            if (firstLevelGroupedValues[notShowItem]) {
                notShowSubGroupsObj[notShowItem] = firstLevelGroupedValues[notShowItem]
                delete firstLevelGroupedValues[notShowItem]
                firstLevelSingleValues.push(notShowItem)
            }
            if (secondLevelGroupedValues[notShowItem]) {
                notShowSubGroupsObj[notShowItem] = secondLevelGroupedValues[notShowItem]
                delete secondLevelGroupedValues[notShowItem]
            }
        })

        let children: any[] = []
        Object.keys(firstLevelGroupedValues).forEach(firstLevelProperty => {
            const secondChildren = sortBy(
                firstLevelGroupedValues[firstLevelProperty].map(secondLevelProperty => {
                    let thirdChildren: any[] = []
                    if (Object.keys(secondLevelGroupedValues).includes(secondLevelProperty)) {
                        thirdChildren = sortBy(
                            secondLevelGroupedValues[secondLevelProperty].map(thirdLevelProperty => {
                                return {
                                    label: thirdLevelProperty,
                                    val: thirdLevelProperty,
                                    priority: values[thirdLevelProperty],
                                    active: false,
                                    type: DataType.CHECK,
                                }
                            }), sortFieldList,
                        )
                    }

                    const obj: any = {
                        label: formatLabel(secondLevelProperty),
                        val: secondLevelProperty,
                        priority: values[secondLevelProperty],
                        active: false,
                        type: DataType.CHECK,
                        notShowSubGroups: notShowSubGroupsObj[secondLevelProperty] || {},
                    }

                    if (thirdChildren.length) {
                        obj.enableSelectAll = true // 允许全选
                        obj.type = DataType.ARROW_CHECK
                        obj.children = thirdChildren
                    }

                    return obj
                }), sortFieldList,
            )

            children.push({
                label: firstLevelProperty,
                val: firstLevelProperty,
                enableSelectAll: true, // 允许全选
                active: false,
                type: DataType.ARROW_CHECK,
                priority: values[firstLevelProperty],
                children: secondChildren,
            })
        })
        firstLevelSingleValues.forEach(firstLevelProperty => {
            children.push({
                label: firstLevelProperty,
                val: firstLevelProperty,
                priority: values[firstLevelProperty],
                active: false,
                type: DataType.CHECK,
                notShowSubGroups: notShowSubGroupsObj[firstLevelProperty],
            })
        })

        /**
         * For the fabric tech spec property, the option values need to be sorted twice
         * - First time sorting according to the previous words
         * - When the preceding words are the same, they are sorted according to the following values.
         * 
         * input: item.label = 'Water pillars 2,000 mm'
         * output: result = { prefix: 'Water pillars', num: 2000 }
         * 
         * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/173
         */
        if (zeroLevelProperty === FABRIC_TECH_SPEC) {
            const newChildren = children.map(item => {
                const result = handleFabricTechSpec(item.label)
                item.num = Number(result.num)
                item.prefix = result.prefix
                return item
            })
            children = sortBy(newChildren, [ 'prefix', 'num' ])
            children = children.map(item => {
                delete item.prefix
                delete item.num
                return item
            })
        }

        return {
            label: zeroLevelProperty,
            val: zeroLevelProperty,
            active: false,
            enableSelectAll: true, // 允许全选
            type: DataType.ARROW_CHECK,
            children: sortBy(children, sortFieldList),
        }
    })
    return cloneDeep(propertyTreeData)
}

/**
 * Special handling fabric tech spec properties
 * 
 * input data
 * ```
 * 'Water pillars 3,000 mm'
 * ```
 * 
 * output data
 * ```
 * { prefix: 'Water pillars', num: 3000 }
 * ```
 * 
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/173
 */
function handleFabricTechSpec(str) {
    const result = /(.*)(\s+\d+[,|' ']\d+\s+)/.exec(str)
    if (!result) return {
        prefix: str,
        num: 0,
    }
    return {
        prefix: result[1],
        num: result[2].trim().replace(' ', '').replace(',', ''),
    }
}
/* ************************** Tree Data End ******************************** */
