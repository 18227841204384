import { useFetch } from 'libs/hookRequest'

/**
 * update data directly by python api
 */
export const useUpdateDataFromNornaApi = () => {

  const { postFn, data, loading, error } = useFetch()

  return {
    updateData: async arg => postFn(arg.url, arg),
    data,
    loading,
    error,
  }
}

/**
 * save current filter
 * @param opt
 */
export const argFnSaveFilter = (filter: string) => ({
  url: 'save_filter/',
  query: {},
  data: { filter },
})

/**
 * update pass word
 * @param filter
 */
export const argFnUpdatePwd = (filter: string) => ({
  url: 'updatePassword',
  query: {},
  data: { filter },
})
