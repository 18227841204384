import * as string from '../../GlobalDatePicker.const'
import { disabledSamePeriodLastYearLicenseList } from 'configs'
import { storage } from 'utils/storage'
import { isDisabledDatePreset } from 'utils/privUtils'

/**
 * 获取 Time period 下拉框选项
 */
export function getTimePeriodOptions() {
    return [
        string.PERIOD_TODAY,
        string.PERIOD_YESTERDAY,
        string.PERIOD_LAST_7_DAYS,
        string.PERIOD_LAST_WEEK,
        string.PERIOD_LAST_MONTH,
        string.PERIOD_LAST_QUARTER,
    ].map((item: string) => {
        let disabled = false
        /**
         * tommy license, Month 和 Quarter 要禁用
         */
        if (
            [
                string.PERIOD_LAST_QUARTER,
            ].includes(item) 
            && isDisabledDatePreset()
        ) {
            disabled = true
        }
        return {
            key: item,
            description: item,
            isDisabled: disabled,
        }
    })
}

export function getComparedToOptions() {
    return [
        string.COMPARE_TO_YEAR_ON_YEAR,
        string.COMPARE_TO_PREVIOUS_PERIOD,
    ].map(item => {
        let disabled = false
        if (
            item === string.COMPARE_TO_YEAR_ON_YEAR
            && (disabledSamePeriodLastYearLicenseList.includes(storage.getCustomerVendor()) || isDisabledDatePreset())
        ) {
            disabled = true
        }

        return { 
            key: item, 
            description: item, 
            isDisabled: disabled,
        }
    })
}
