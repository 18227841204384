
/**
 * Pattern Name
 */
export const PATTERN = 'Pattern'
export const PATTERN_SOLID = 'Solid'

export const MULTI_COLOR = 'Multi-color'
/**
 * ## 不需要自动关联Pattern的Colors值
 * 
 */
export const PATTERN_EXCLUDE_COLORS = []

/**
 * Pack filter
 */
export const ONE_PACK_KEY = 'One pack'
export const ONE_PACK_VALUE = 'Exclude packs'

