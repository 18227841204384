import React from 'react'
import { SizedBox } from 'componentsv2/SizedBox'
import { PriceSpreadsTable } from './components/PriceSpreadsTable'

export const PriceSpreads = () => {

    return (
        <>
            <PriceSpreadsTable />
            <SizedBox height={30} />
        </>
    )
}
