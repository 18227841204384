import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { Flex, Checkbox } from 'druikit'
import { Text } from 'components/typography'
import { ArrowForwardIcon } from '../ArrowForward'
import { ShouldRender } from 'componentsv2/ShouldRender'
import styles from './styles.module.scss'

export const TreeOption = ({
    label,
    value = false,
    onChange,
    style = {},
    className = '',
    disabled = false,           // 不可点击, 字体颜色灰色
    disabledClick = false,      // 不可点击, 字体颜色不变
    checkboxVisible = true,     // 是否显示复选框
    arrowVisible = false,       // 是否显示箭头
    active = false,
}: {
    label: string;
    value: boolean;
    onChange?: (value: boolean) => void;
    style?: CSSProperties;
    className?: string;
    disabled?: boolean;
    disabledClick?: boolean;
    checkboxVisible?: boolean;
    arrowVisible?: boolean;
    active?: boolean;
}) => {
    const textCls = classnames({
        [styles.optionName]: true,
        [styles.active]: active || value,
        [styles.disabled]: disabled || disabledClick,
        [styles.disabledClick]: disabledClick,
    })

    const arrowCls = classnames({
        [styles.arrowRight]: true,
        [styles.arrowActive]: value,
        [styles.disabled]: disabled || disabledClick,
        [styles.disabledClick]: disabledClick,
    })

    return (
        <Flex alignItems="center" justifyContent="space-between" className={classnames([ styles.optionItem, className ])}>
            <Checkbox
                style={{ flex: 1, height: 31, paddingBlock: 0, ...style }}
                value={value}
                onChange={value => onChange?.(value)}
                disabled={disabled || disabledClick}
                boxColor="#999999"
                boxVisible={checkboxVisible}
                boxPosition="right"
                boxStyle={{ marginRight: 0 }}
                labelStyle={{ paddingRight: 10 }}
            >
                <Text
                    small
                    title={label}
                    className={textCls}
                >
                    {label}
                </Text>
            </Checkbox>
            <ShouldRender shouldRender={arrowVisible}>
                <div className={arrowCls} style={{ paddingRight: 15 }}>
                    <ArrowForwardIcon />
                </div>
            </ShouldRender>
        </Flex>
    )
}
