import React, { useCallback, useRef } from 'react'
import { cloneDeep, sortBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { DataType } from 'componentsv2/Tree/common'
import { PRICE_FILTER_ARRAY, CATEGORY_TOTAL, IS_LIVE_ENV, BRAND_VENDOR_SPLITOR, ALL_COUNTRIES, SELECTED_VENDORS, SELECTED_VENDORS_OBJ, FILTER_DIALOG_CLASS, LICENSE_KAPPAHL, ALERT_TYPE_LAUNCHEDAFTER } from 'consts'
import { isDashboardPages, isDisabledCategoryPages, isDisabledLookbookPages, isDiscountsPage, isPlatformAnalyticsPage, isSoldOutVendorsPage, RoutesObj } from 'global/Routes'
import { storage } from 'utils/storage'
import { DISABLE_PROPERTIES_IN_LIVE } from 'utils/sortCategory'
import { useHistory } from 'react-router-dom'
import { ObjectType, PureSellerType } from 'types'
import { FILTER_LABELS, getSelectedCategories } from 'componentsv2/Tree/utils'
import { useMenuAndFilterPermission } from 'hooks/useQa'
import { TreeItemProp, TreeItemProps } from 'componentsv2/Tree'
import { useLookbook } from 'hooks/useLookbook'
import { useCustomerVendor, useIsGate } from 'hooks'
import { updateCheckedLookbook } from 'features/filters/lookbookDataSlice'
import { TdPriceRangeTypeEnum } from 'graphql/nornaapi/types'
import { getFilterCategoryMaxCountInLive } from 'utils'
import { getFilterTags, updateFilterTags } from 'features/filters/globalDataSlice'
import { useFilterCategory, useFilterTax } from 'hooks/useFilter'
import { TaxFilterModal } from 'componentsv2/business/TaxFilterModal'
import { ONE_PACK_KEY, ONE_PACK_VALUE } from 'consts/filter'
import { getAllFilterCategoryValue, getAllFilterCompetitorValue } from 'utils/filterUtils'
import { useCalendarTmpDate, useTmpProperty } from 'hooks/useGlobalData'
import { ModifyCollectionModal } from 'pages/MySavedFilters/components/ModifyCollectionModal'
import { enableFilterPack, enableFilterSize, enableFilterTax } from 'utils/feConfig'
import { OpenFilterType } from './HeaderFilter.type'
import { ActionBoxTypes } from '../../../../common/InfoBox/InfoActionContext'
import { MaterialFilter } from '../../../../../componentsv2/business/MaterialFilter'
import { cleanFilter, queryInit, updateCategories, updateFilterGender, updatePriceRangeFilter, updateSizeFilter } from '../../../../../features/filters/filtersSlice'
import { sortByOptionsFn } from './components/SortBy'
import { Launched } from './components/Launched'
import { Other } from './other'
import { PriceFilter } from './components/PriceFilter'
import { LookbookTreeItem } from './LookbookTreeItem'
import { SizeFilter } from './components/SizeFilter'
import { getFilterGenderAllValue, getFilterGenderChildren, getFilterGenderChildrenValue, handleFilterGenderSelecteAll } from './gender.util'
import { getCompetitorSellersByRegion, getFilterCompetitorChildren, handleFilterCompetitorByRegion, handleFilterCompetitorSelecteAll } from './competitor.util'
import { LaunchedAfterWarningDialog } from './components/LaunchedAfterWarningDialog'
import { getDisabledCategoriesForSizeFilter, getFilterCategoryChildren, handleFilterCategorySelectAll, isFilterCategorySelectAll } from './category.util'
import { getPropertyValueByLabel, handleFilterPropertyTreeData } from './property.util'
import { NoSizeDialog } from 'componentsv2/business/NoSizeDialog'

// 模态框蒙版样式
const modalMaskStyle = {}   // 深色背景

export function objToArr(obj) {
  const trueArr: string[] = []
  const falseArr: string[] = []
  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      trueArr.push(key)
    } else {
      falseArr.push(key)
    }
  })
  return {
    trueArr,
    falseArr,
  }
}

export const formatMetaCategory: any = (composition: any) => {
  const subCategoryies = Object.keys(composition).map(category => {
    const categoryVal = composition[category]
    const properties = {}
    const blackProperties = {}
    Object.keys(categoryVal).forEach(group => {
      const objArr = objToArr(categoryVal[group])
      if (objArr.trueArr.length) properties[group] = objArr.trueArr
      if (objArr.falseArr.length) blackProperties[group] = objArr.falseArr
    })
    return {
      category,
      properties,
      blackProperties,
    }
  })
  return subCategoryies
}

/**
 * Filter DISABLE_PROPERTIES_IN_LIVE Properties
 * @param list 
 * @returns 
 */
export const filterProperties = (list: Array<string>) => {

  // Step 1: Pre Check
  if (!(list instanceof Array)) return list

  // Step 2: Handle Logic
  try {
    if (!IS_LIVE_ENV) return list
    return list.filter(item => DISABLE_PROPERTIES_IN_LIVE.indexOf(item) === -1)
    // Step 3: Try Catch
  } catch (e) {
    console.log(e)
    return list
  }
}

const ID_PRICE_RANGE = 9
const ID_MATERIAL_FILTER = 3
const ID_NO_SIZE = 7
const ID_SAVE = 4
const ID_SIZE_FILTER = 5
const ID_TAX_FILTER = 6
const zIndex = 9999991
/**
 * open price filter dialog
 * @param actionBox
 */
export const openPriceRangeFilter = ({ actionBox, type = TdPriceRangeTypeEnum.TD, ok, initValue, isTd = false }) => {
  actionBox.close(ID_PRICE_RANGE)
  actionBox.open && actionBox.open({
    id: ID_PRICE_RANGE,
    left: 100,
    top: 100,
    close() {
    },
    type: ActionBoxTypes.CUSTOM,
    bodyComponent: (
      <div style={{ position: 'fixed', zIndex: 999999999 }}>
        <PriceFilter
          isTd={isTd}
          type={type}
          initValue={initValue}
          closeFn={() => {
            actionBox.close(ID_PRICE_RANGE)
          }}
          onChange={val => {
            ok(val)
            actionBox.close(ID_PRICE_RANGE)
          }}
          modalMaskStyle={modalMaskStyle}
        />
      </div>
    ),
  })
}

/**
 * 打开 Size Filter 弹窗
 */
export const openSizeFilter = ({ actionBox, ok, value }) => {
  actionBox.close(ID_SIZE_FILTER)
  actionBox.open && actionBox.open({
    id: ID_SIZE_FILTER,
    left: 100,
    top: 100,
    close() {
    },
    type: ActionBoxTypes.CUSTOM,
    bodyComponent: (
      <div style={{ position: 'fixed', zIndex: 999999999 }}>
        <SizeFilter
          value={value}
          onClose={() => {
            actionBox.close(ID_SIZE_FILTER)
          }}
          onOk={val => {
            ok(val)
            actionBox.close(ID_SIZE_FILTER)
          }}
          modalMaskStyle={modalMaskStyle}
        />
      </div>
    ),
  })
}

/**
 * 打开 Launched after 警告对话框
 */
export const openLaunchedAfterWarningDialog = ({ actionBox }) => {
  actionBox.close(ID_SIZE_FILTER)
  actionBox.open && actionBox.open({
    id: ID_SIZE_FILTER,
    left: 100,
    top: 100,
    close() {
    },
    type: ActionBoxTypes.CUSTOM,
    bodyComponent: (
      <div style={{ position: 'fixed', zIndex: 999999999 }}>
        <LaunchedAfterWarningDialog
          onClose={() => {
            actionBox.close(ID_SIZE_FILTER)
          }}
          modalMaskStyle={modalMaskStyle}
        />
      </div>
    ),
  })
}

/**
 * 打开 Tax 弹窗
 */
export const openTaxFilter = ({ actionBox, ok, value }) => {
  actionBox.close(ID_TAX_FILTER)
  actionBox.open && actionBox.open({
    id: ID_TAX_FILTER,
    left: 100,
    top: 100,
    close() {
    },
    type: ActionBoxTypes.CUSTOM,
    bodyComponent: (
      <div style={{ position: 'fixed', zIndex: 999999999 }}>
        <TaxFilterModal
          onClose={() => {
            actionBox.close(ID_TAX_FILTER)
          }}
          onOk={val => {
            ok(val)
            actionBox.close(ID_TAX_FILTER)
          }}
        />
      </div>
    ),
  })
}

/**
 *
 * @param actionBox       //action box container
 * @param percent         //the init percent value
 * @param checked         // active or not
 * @param label           // color or material
 * @param filterRef       // the ref of MaterialFilter
 * @param ok              // ok button function
 */
export const openFilterFn = ({ actionBox, percent, checked, label, filterRef, ok }: OpenFilterType) => {
  actionBox.close(ID_MATERIAL_FILTER)
  actionBox.open && actionBox.open({
    id: ID_MATERIAL_FILTER,
    left: 100, top: 100,
    close() {
    },
    type: ActionBoxTypes.CUSTOM,
    bodyComponent: (
      <div style={{ position: 'fixed', zIndex }}>
        <MaterialFilter
          perent={percent}
          checked={checked}
          ref={filterRef}
          label={label}
          min={0}
          onOk={val => {
            actionBox.close(ID_MATERIAL_FILTER)
            ok?.(val)
          }}
          onClose={() => {
            actionBox.close(ID_MATERIAL_FILTER)
          }}
          modalMaskStyle={modalMaskStyle}
        />
      </div>
    ),
  })
}

export const openNoSizeFn = ({ actionBox, ok, noSelectionDatesSize, noHistoricalSize, noUnavailableSize, noHarmonizedSize, zalandodeepShopEmptyString, ignoreNoAvailableSize }: any) => {
  actionBox.close(ID_NO_SIZE)
  actionBox.open && actionBox.open({
    id: ID_NO_SIZE,
    left: 100, 
    top: 100,
    close() {},
    type: ActionBoxTypes.CUSTOM,
    bodyComponent: (
      <div style={{ position: 'fixed', zIndex }}>
        <NoSizeDialog
          noSelectionDatesSize={noSelectionDatesSize}
          noHistoricalSize={noHistoricalSize}
          noUnavailableSize={noUnavailableSize}
          noHarmonizedSize={noHarmonizedSize}
          zalandodeepShopEmptyString={zalandodeepShopEmptyString}
          ignoreNoAvailableSize={ignoreNoAvailableSize}
          onOk={val => {
            actionBox.close(ID_NO_SIZE)
            ok?.(val)
          }}
          onClose={() => {
            actionBox.close(ID_NO_SIZE)
          }}
          modalMaskStyle={modalMaskStyle}
        />
      </div>
    ),
  })
}

/**
 * save filter dialog
 * @param param 
 */
export const saveDialogFn = ({ actionBox, setTreeItem, saveFilterFn, ok }) => {
  actionBox.open && actionBox.open({
    id: ID_SAVE,
    left: 0,
    top: 0,
    close: () => {
    },
    type: ActionBoxTypes.CUSTOM,
    bodyComponent: (
      <div className={`material-filter ${FILTER_DIALOG_CLASS}`} style={{ position: 'fixed', zIndex: 99999999, top: '400px', left: '800px' }}>
        <ModifyCollectionModal 
          closeModal={() => {
            actionBox.close(ID_SAVE)
          }}
          onSave={async (name: string) => {
            saveFilterFn({ name, setTreeItem })
            actionBox.close(ID_SAVE)
          }}
          isOpen={true}
        />
      </div>
    ),
  })
}

/**
 * generate tree items for filter list
 * @param list
 * @param hideFilter
 * @param initFilterFn
 */
export const genFilterTreeItem = (list, initFilterFn, hideFilter) => list.map(item => ({
  label: item.name,
  val: item.id,
  click() {
    initFilterFn?.(item)
    hideFilter()
  },
}))

/** *
 *  ## split brands of seller from 'brand@vendor' of `PureSellerType`
 * 
 *  eg:
 *  `[
 *    {key:'Kate Spade @ El Palacio de Hierro'},{key:'Coach @ El Palacio de Hierro'}
 *  ]`
 *  to 
 *  `[
 *    {key:' @ El Palacio de Hierro',brands:['Kate Spade','Coach']}
 *  ]`
 * 
 */
export const spliteBrandsFromBrandVenoder = (sellers: Array<PureSellerType>): Array<PureSellerType> => {
  const competitors: Array<PureSellerType> = Object.values(sellers.reduce((res: ObjectType<PureSellerType>, item: PureSellerType) => {
    if (item.key.indexOf(BRAND_VENDOR_SPLITOR) > -1) {
      const [ brand, vendor ] = item.key.split(BRAND_VENDOR_SPLITOR)
      const [ , vendorDesc ] = item.description.split(BRAND_VENDOR_SPLITOR)
      if (res[vendor]) {
        res[vendor].brands.push(brand)
      } else {
        res[vendor] = {
          ...item,
          key: vendor,
          description: vendorDesc,
          brands: [ brand ],
        }
      }
    } else {
      /**
       * 2022/05/12
       * 
       * competitors = [
       *    { key: 'aaa@bogner' },
       *    { key: 'bogner' },
       * ]
       * 
       * 遍历第一次时, 因为有 @ 符合, res 已经有值了 res = {'bogner': {brand: ['aaa']}}
       * 
       * 然后遍历第二次, 之前的逻辑是直接 res[item.key] = item 就会造成第二次循环时替换了 res 中的 bogner 键
       * 变成了 res = {'bogner': {brand: []}}
       * 
       * 加一步判断，如果 res 中不存在 key 时, 才会添加数据
       */
      // eslint-disable-next-line
      if (!res[item.key]) {
        res[item.key] = item
      }
    }
    return res
  }, {}))

  const all: PureSellerType = competitors.slice(-1)[0]

  const selectedVendor: PureSellerType = {
    ...all, 
    key: SELECTED_VENDORS,
    description: SELECTED_VENDORS, 
    parent: SELECTED_VENDORS_OBJ.region, 
    smallText: SELECTED_VENDORS_OBJ.region,
  }

  return [
    // sort competitors
    ...sortBy(competitors.slice(0, -1), 'key'),
    // set market the last one
    selectedVendor,
    ...competitors.slice(-1),
  ]
}

/**
 * tree data hook
 * @param flatQuery
 * @param actionBox
 * @param initFilterFn
 * @param hideFilter
 * @param optionSelectionFilter
 * @param saveFilterFn
 * @param ourSeller
 * @param searchObj
 * @param cacheData
 * @param regionsFn
 * @param sellers
 * @param properties
 */
export const useTreeData = ({ onUpdateFilterTags, onColorFilterChange, onMaterialFilterChange, onClickMask, flatQuery, actionBox, initFilterFn, hideFilter, optionSelectionFilter, saveFilterFn, ourSeller, cacheData, regionsFn, onNoSizeFilterChange }: any) => {
  const history = useHistory()
  const filterTags = useSelector(getFilterTags)
  const [ calendarTmpDate ] = useCalendarTmpDate()
  const [ tmpProperty, setTmpProperty ] = useTmpProperty()

  let categories: string[] = []
  let properties: Record<string, string[]> = {}
  let targetGroups: string[] = []
  let region: any = {}
  let competitors: any[] = []
  let sortField: any = {}
  let priceFilter: any = {}
  let packFilter: any = []
  let priceRange: any = {}
  let launchAfter: any = null
  let launchBeforeDate: any = null
  let sizeFilter: any = {}
  let taxFilter: any = {}
  let materialFilter: any = {}
  let colorFilter: any = {}
  let noSize: any = false
  let noHistoricalSize: any = false
  let noUnavailableSize: any = false
  let noHarmonizedSize: any = false
  let zalandodeepShopEmptyString: any = false
  let ignoreNoAvailableSize: any = false

  if (Object.keys(filterTags || {})?.length) {
    categories = filterTags.category
    properties = filterTags.property
    targetGroups = filterTags.gender
    region = filterTags.region
    competitors = filterTags.vendor
    sortField = filterTags.sort
    priceFilter = filterTags.priceFilter
    packFilter = filterTags.packFilter
    priceRange = filterTags.priceRange
    launchAfter = filterTags.launchDate
    launchBeforeDate = filterTags.launchBeforeDate
    sizeFilter = filterTags.sizeFilter
    taxFilter = filterTags.taxFilter
    materialFilter = filterTags.materialFilter
    colorFilter = filterTags.colorFilter
    noSize = filterTags.noSize
    noHistoricalSize = filterTags.noHistoricalSize
    noUnavailableSize = filterTags.noUnavailableSize
    noHarmonizedSize = filterTags.noHarmonizedSize
    zalandodeepShopEmptyString = filterTags.zalandodeepShopEmptyString
    ignoreNoAvailableSize = filterTags.ignoreNoAvailableSize
  } else {
    categories = flatQuery.categories
    properties = flatQuery.properties
    targetGroups = flatQuery.targetGroups
    region = flatQuery.region
    competitors = flatQuery.sellers
    sortField = flatQuery.sortField
    priceFilter = flatQuery.priceFilter
    packFilter = flatQuery.packFilter
    priceRange = flatQuery.priceRange
    launchAfter = flatQuery.launchAfter
    launchBeforeDate = flatQuery.launchBeforeDate
    sizeFilter = flatQuery.sizeFilter
    taxFilter = flatQuery.taxFilter
    materialFilter = flatQuery.materialFilter
    colorFilter = flatQuery.colorFilter
    noSize = flatQuery.noSize
    noHistoricalSize = flatQuery.noHistoricalSize
    noUnavailableSize = flatQuery.noUnavailableSize
    noHarmonizedSize = flatQuery.noHarmonizedSize
    zalandodeepShopEmptyString = flatQuery.zalandodeepShopEmptyString
    ignoreNoAvailableSize = flatQuery.ignoreNoAvailableSize
  }

  const customerVendor = useCustomerVendor()

  const materialFilterRef = React.useRef({
    getVals() {},
  })

  const dispatch = useDispatch()
  const { filterAvailable } = useMenuAndFilterPermission()

  // ------------------------  countries ----------------------------
  const avaliableRegions = [ ...cacheData.regions ]

  /** *
   * ALL_COUNTRIES only available with `test` env and `RoutesObj.SEARCH_OPTIONS.URL` functionality
   */
  if (!IS_LIVE_ENV && [ RoutesObj.SEARCH_OPTIONS.URL ].some(item => window.location.pathname.startsWith(item))) {
    avaliableRegions.push(ALL_COUNTRIES)
  }

  /* *************************************** Lookbook **************************************** */
  const { isSearchOptions, isAttributeRanking, isLookbook, checked: checkedLookbook, enabledLookbook } = useLookbook()

  const lookbookRef = useRef<any>()

  /* *************************************** Category **************************************** */
  const [ , setFilterCategory ] = useFilterCategory()
  // 点击 category 时清空 lookbook
  const categoryClick = useCallback((treeData?: any) => {
    if (isLookbook) {
      lookbookRef.current?.clearLookbook?.()
      if (treeData?.find(item => item.label === FILTER_LABELS.CATEGORY)) {
        const categories = getSelectedCategories(treeData)
        setFilterCategory(categories)
      }
    }
  }, [ isLookbook, setFilterCategory ])

  const categoryChildren = getFilterCategoryChildren({
    categories,
    sizeFilterChecked: sizeFilter?.checked,
  })

  if (IS_LIVE_ENV && categoryChildren.filter(item => item.active).length > getFilterCategoryMaxCountInLive()) {
    categoryChildren.forEach((item, index) => {
      item.active = false
      item.children?.forEach(item2 => {
        item2.active = false
      })
    })
    dispatch(updateCategories([]))
  }

  /* *************************************** Property **************************************** */
  const selectedProperties = getPropertyValueByLabel({
    selectedCategories: categories,
    selectedProperties: properties,
  })

  const propertyChildren = handleFilterPropertyTreeData({
    selectedCategories: categories,
    selectedProperties,
  }).filter((item: any) => filterAvailable(item.label))

  /* *************************************** Gender **************************************** */
  const isGate = useIsGate()
  const genderChildren = getFilterGenderChildren({
    filterGender: targetGroups,
    isGate,
  })

  /* *************************************** Competitor **************************************** */
  const competitorChildren = getFilterCompetitorChildren({
    region: region?.key,
    competitors,
  })

  /* *************************************** Price perspective **************************************** */
  const pricePerspectiveChildren = PRICE_FILTER_ARRAY.map(item => ({
    type: DataType.SINGLE_CHECK,
    active: priceFilter?.val === item.val,
    ...item,
  }))

  // discounts 页面硬编码只选中 Sales prices
  if (isDiscountsPage()) {
    pricePerspectiveChildren.forEach((item: any) => {
      item.active = item.label === 'Sales prices'
      item.disabled = item.label !== 'Sales prices'
    })
  }

  /* *************************************** Pack filter **************************************** */
  const packFilterChildren = [
    {
      type: DataType.SINGLE_CHECK,
      active: packFilter.length,
      val: ONE_PACK_KEY,
      label: ONE_PACK_VALUE,
    },
  ]

  /* *************************************** Tax filter **************************************** */
  // eslint-disable-next-line
  const [ _, setFilterTax ] = useFilterTax()

  const treeData: Array<TreeItemProp> = [
    {
      label: FILTER_LABELS.CATEGORY,
      val: FILTER_LABELS.CATEGORY,
      active: categories.length > 0,
      type: DataType.ARROW,
      // keepLast: true,
      enableSelectAll: isFilterCategorySelectAll(),
      enableChildrenSelectAll: true,
      selectAllFn({ setTreeItem, item }) {
        if (!setTreeItem) return
        const newItem = handleFilterCategorySelectAll(item)
        const result = setTreeItem?.(cloneDeep(newItem))
        onUpdateFilterTags?.(result)
      },
      onChange: categoryClick,
      children: categoryChildren,
    },
    {
      label: FILTER_LABELS.LOOKBOOK_FILTER,
      val: '',
      active: !!checkedLookbook.length,
      type: DataType.ARROW,
      /**
       *  此种处理方式目的在于将lookbook引起变化的effect从tree中剥离出去, 降低lookbook对tree的有效影响
       * @param param0 
       * @returns 
       */
      render: ({ setTreeItem,getVals,updateTreeData, item, expandSubPanel }) => <LookbookTreeItem {...item} expandSubPanel={expandSubPanel} ref={lookbookRef} setTreeItem={setTreeItem} getVals={getVals} updateTreeData={updateTreeData} />,
    },
    {
      label: FILTER_LABELS.PROPERTY,
      enableSelectAll: true,            // 允许全选
      active: properties && Object.keys(properties).length,
      type: DataType.ARROW,
      children: propertyChildren,
      linkField: FILTER_LABELS.CATEGORY,
      linkValue: categories,
      linkAllowEmpty: true,
      linkFn: (
        category: any,
      ) => {
        // category 变化时才进入
        const categorieValue = category.split(',_,').filter(c => c)

        if (!categorieValue?.length) {
          setTmpProperty(cloneDeep(properties))
        } else {
          setTmpProperty({})
        }
        let propertyValue = {}
        if (Object.keys(tmpProperty || {})?.length) {
          propertyValue = cloneDeep(tmpProperty)
          setTmpProperty({})
        } else {
          propertyValue = cloneDeep(properties)
        }
        const selectedProperties = getPropertyValueByLabel({
          selectedCategories: categorieValue,
          selectedProperties: propertyValue,
        })
        const children = handleFilterPropertyTreeData({
          selectedCategories: categorieValue,
          selectedProperties,
        }).filter((item: any) => filterAvailable(item.label))
        return children
      },
    } as any,
    {
      label: 'Gender',
      active: true,
      type: DataType.ARROW,
      keepLast: true,
      enableSelectAll: true, // 允许全选
      selectAllFn({ setTreeItem, item }) {
        const newItem = handleFilterGenderSelecteAll(item)
        const result = setTreeItem({ ...newItem })
        onUpdateFilterTags?.(result)
      },
      children: genderChildren,
    },
    {
      label: FILTER_LABELS.COUNTRY,
      active: region && region.key,
      type: DataType.ARROW,
      keepLast: true,// keep the last one active
      children: [
        ...avaliableRegions.map((item, index) => ({
          active: region.key === item,
          type: DataType.SINGLE_CHECK,
          label: item,
          disabled: false,
          val: cacheData.regionToCurrency[item],
        })),

        ...cacheData.unregions.map((item, index) => ({
          active: false,
          type: DataType.SINGLE_CHECK,
          label: item,
          disabled: true,
          val: cacheData.regionToCurrency[item],
        })),
      ],
    },
    {
      label: FILTER_LABELS.CURRENCY,
      active: true,
      type: DataType.ARROW,
      keepLast: true,
      children: [
        ...cacheData.currencys.map(item => ({
          active: region?.currency === item,
          type: DataType.SINGLE_CHECK,
          label: item,
          disabled: false,
          val: item,
        })),
        ...cacheData.uncurrencys.map(item => ({
          active: false,
          type: DataType.SINGLE_CHECK,
          label: item,
          disabled: true,     // 不可操作
          val: item,
        })),
      ],
      linkField: FILTER_LABELS.COUNTRY,
      linkValue: region.key,
      linkFn: (region: string) => {
        let currency = cacheData.regionToCurrency[region]
        /**
         * selected the first avaialble currency when user switch region to `ALL_COUNTRIES` in test live
         */
        if (!currency && cacheData.currencys.length && region === ALL_COUNTRIES && !IS_LIVE_ENV) {
          currency = cacheData.currencys[0] //eslint-disable-line
        }
        return [
          ...cacheData.currencys.map((item: string) => ({
            active: currency === item,
            type: DataType.SINGLE_CHECK,
            label: item,
            disabled: false,
            val: item,
          })),
          ...cacheData.uncurrencys.map((item: string) => ({
            active: false,
            type: DataType.SINGLE_CHECK,
            label: item,
            disabled: true,     // 不可操作
            val: item,
          })),
        ]
      },
    },
    {
      label: FILTER_LABELS.COMPETITOR,
      linkField: FILTER_LABELS.COUNTRY, // linkage country,
      linkValue: region.key, // linkage country,
      enableSelectAll: true, // 允许全选
      disabled: (!(region && region.key)) || isPlatformAnalyticsPage(),
      linkFn: (
        region: string,
        currentCompetitorTreeItem: TreeItemProps,
      ) => {
        if (isDashboardPages()) {
          competitors = getCompetitorSellersByRegion({ region, excludeSelf: false })
            .map(s => ({ vendor: s.vendor, region: s.region }))
          if (customerVendor !== LICENSE_KAPPAHL) {
            competitors.push({ vendor: SELECTED_VENDORS, region })
          }
        }
        const data = handleFilterCompetitorByRegion({
          region,
          currentCompetitorTreeItem,
          competitors,
        })
        return data
      },
      active: competitors.length > 0,
      type: DataType.ARROW,
      selectAllFn: ({ setTreeItem, item }) => {
        const newItem = handleFilterCompetitorSelecteAll(item)
        const result = setTreeItem?.(cloneDeep(newItem))
        onUpdateFilterTags?.(result)
      },
      children: competitorChildren,
    },

    {
      label: FILTER_LABELS.SORT_BY,
      active: Object.values(sortField).filter(item => item).length > 0,
      type: DataType.ARROW,
      keepLast: true,// keep the last one active
      children: sortByOptionsFn(sortField).map(item => ({
        label: item.label,
        val: item.val,
        active: item.active,
        type: DataType.SINGLE_CHECK,
      })),
    },
    {
      label: FILTER_LABELS.PRICE_FILTER,
      keepLast: true,// keep the last one active
      active: !!(priceFilter && Object.keys(priceFilter).length),
      val: priceFilter,
      type: DataType.ARROW,
      customStyle: { width: 220 },
      children: pricePerspectiveChildren,
    },
    {
      label: FILTER_LABELS.PACK_FILTER,
      type: DataType.ARROW,
      active: !!packFilter?.length,
      enableSelectAll: true,
      children: packFilterChildren,
    },
    {
      label: FILTER_LABELS.PRICE_RANGE_FILTER,
      active: true,
      val: [ priceRange.min, priceRange.max, Number(priceRange.activate_price_range) ].join(','),
      icon: true,
      click: useCallback(({ setTreeItem, item }) => {
        openPriceRangeFilter({
          actionBox,
          initValue: {
            range: priceRange.activate_price_range ? [ priceRange.min, priceRange.max ].join(',') : '0,0',
            optionValue: priceRange?.optionValue || 'all',
            checked: priceRange.activate_price_range,
          },
          ok: (val: any) => {
            setTreeItem?.({ active: true, val: [ val.min, val.max, Number(val.checked) ].join(',') })
            onClickMask?.()
            dispatch(updatePriceRangeFilter({ activate_price_range: val.checked, min: val.min, max: val.max, optionValue: val.optionValue }))
            hideFilter()
          },
        })
      }, [ actionBox, priceRange, dispatch, hideFilter, onClickMask ]),
      // type: DataType.ARROW,
    },
    {
      label: FILTER_LABELS.TAX,
      active: !!taxFilter?.checked,
      val: taxFilter,
      icon: true,
      click: useCallback(({ setTreeItem }) => {
        openTaxFilter({
          actionBox,
          value: taxFilter,
          ok: value => {
            // 更新 taxFilter.....
            setTreeItem?.({ active: value.checked, val: value })
            onClickMask?.(undefined, true)
            setFilterTax({ ...value })
          },
        })
      }, [ actionBox, onClickMask, setFilterTax, taxFilter ]),
      // type: DataType.ARROW,
    },
    {
      label: FILTER_LABELS.LAUNCHED_AFTER,
      icon: true,
      active: !!launchAfter,
      val: launchAfter,
      render({ setTreeItem, item }) {
        return (
          <Launched
            containerStyle={{ flex: 1, opacity: 0 }}
            disabled={isLookbook}
            customRenderFn={Other}
            renderPostion={({ calendarContainerElement, inputElement }) => {
              calendarContainerElement.current.style.left = '390px'
              calendarContainerElement.current.style.top = '200px'
              calendarContainerElement.current.style.zIndex = 99999
              calendarContainerElement.current.style.transform = 'none'
              calendarContainerElement.current.style.position = 'fixed'
            }}
            selected={item?.val}
            wrapperClassName="blockClass"
            onChange={newData => {
              if (!newData) return
              setTreeItem?.({ val: newData, active: !!newData })
            }}
            onClickDate={newDate => {
              let selectedDate = storage.getGlobalDateValue()
              if (calendarTmpDate && !calendarTmpDate?.endsWith('_')) {
                selectedDate = calendarTmpDate
              }
              const selectedEndDate = selectedDate.split('_')[1]
              const alertMessages = storage.getAlertMessages()
              if ((Date.parse(newDate) > Date.parse(selectedEndDate)) && !alertMessages.includes(ALERT_TYPE_LAUNCHEDAFTER)) {
                // 弹出弹窗
                openLaunchedAfterWarningDialog({
                  actionBox,
                })
              }
            }}
          />
        )
      },
    },
    {
      label: FILTER_LABELS.LAUNCHED_BEFORE,
      icon: true,
      active: !!launchBeforeDate,
      val: launchBeforeDate,
      render({ setTreeItem, item }) {
        return (
          <Launched
            containerStyle={{ flex: 1, opacity: 0 }}
            disabled={isLookbook}
            customRenderFn={Other}
            renderPostion={({ calendarContainerElement, inputElement }) => {
              calendarContainerElement.current.style.left = '390px'
              calendarContainerElement.current.style.top = '200px'
              calendarContainerElement.current.style.zIndex = 99999
              calendarContainerElement.current.style.transform = 'none'
              calendarContainerElement.current.style.position = 'fixed'
            }}
            selected={item?.val}
            wrapperClassName="blockClass"
            onChange={newDate => {
              if (!newDate) return
              setTreeItem?.({ val: newDate, active: !!newDate })
            }}
          />
        )
      },
    },
    {
      label: FILTER_LABELS.SIZE_FILTER,
      active: !!sizeFilter.checked,
      val: sizeFilter,
      icon: true,
      click({ setTreeItem, item, filterTmpState }) {
        openSizeFilter({
          actionBox,
          value: sizeFilter,
          ok: value => {
            setTreeItem?.({ active: value.checked, val: value })
            onClickMask?.(undefined, true)
            // 修改 category
            let newCategories = cloneDeep(categories)
            if (Array.isArray(filterTmpState?.category)) {
              newCategories = cloneDeep(filterTmpState?.category)
            }
            const disabledCategories: string[] = getDisabledCategoriesForSizeFilter(true)
            newCategories = newCategories.filter(item => !disabledCategories.includes(item))
            if (!newCategories.length) {
              if (categories.includes(CATEGORY_TOTAL)) {
                const enabledCategories = categoryChildren.filter(item => !disabledCategories.includes(item.label))
                let cate: string[] = []
                enabledCategories.forEach(item => {
                  if (Array.isArray(item?.children) && item?.children.length) {
                    cate = [ ...cate, ...item?.children.map(item2 => item2.label) ]
                  } else {
                    cate = [ ...cate, item.label ]
                  }
                })
                cate = cate.filter(item => !disabledCategories.includes(item))
                newCategories = [ ...cate ]
              } else {
                const firstCategory = categoryChildren.find(item => !disabledCategories.includes(item.label))
                if (Array.isArray(firstCategory?.children) && firstCategory?.children.length) {
                  newCategories = firstCategory.children.map(item => item.label)
                } else {
                  newCategories = [ firstCategory.label ]
                }
              }
            }
            dispatch(updateCategories([ ...newCategories ]))
            dispatch(updateFilterGender(getFilterGenderChildrenValue()))
            dispatch(updateSizeFilter({ ...value }))
            hideFilter()
          },
        })
      },
    },
    {
      label: FILTER_LABELS.MATERIAL_FILTER,
      active: !!materialFilter.checked,
      val: materialFilter,
      icon: true,
      click({ setTreeItem, item }) {
        openFilterFn({
          checked: !!item?.val?.checked,
          filterRef: materialFilterRef,
          actionBox,
          label: 'material',
          percent: item?.val?.percent,
          ok: val => {
            setTreeItem?.({ val, active: val.checked })
            onMaterialFilterChange?.({ ...val })
          },
        })
      },
    },
    {
      label: FILTER_LABELS.COLOR_FILTER,
      active: !!colorFilter.checked,
      val: colorFilter,
      icon: true,
      click({ setTreeItem, item }) {
        openFilterFn({
          checked: !!item?.val?.checked,
          filterRef: materialFilterRef,
          actionBox,
          label: 'color',
          percent: item?.val?.percent,
          ok: val => {
            setTreeItem?.({ val, active: val.checked })
            onColorFilterChange?.({ ...val })
          },
        })
      },
    },
    {
      label: FILTER_LABELS.NO_SIZE,
      active: noSize || noHistoricalSize || noUnavailableSize || noHarmonizedSize || zalandodeepShopEmptyString || ignoreNoAvailableSize,
      val: [ noSize, noHistoricalSize, noUnavailableSize, noHarmonizedSize, zalandodeepShopEmptyString, ignoreNoAvailableSize ].join(','),
      icon: true,
      click({ setTreeItem, item }) {
        openNoSizeFn({
          noHistoricalSize,
          noUnavailableSize,
          noHarmonizedSize,
          zalandodeepShopEmptyString,
          ignoreNoAvailableSize,
          noSelectionDatesSize: noSize,
          actionBox,
          ok: val => {
            setTreeItem?.({ 
              val, 
              active: val.noSelectionDatesSize || val.noHistoricalSize || val.noUnavailableSize || val.noHarmonizedSize || val.zalandodeepShopEmptyString || val.ignoreNoAvailableSize,
            })
            onNoSizeFilterChange?.(val)
          },
        })
      },
    },
    {
      label: 'Load filters',
      val: '',
      type: 'ARROW',
      children: genFilterTreeItem(optionSelectionFilter, initFilterFn, hideFilter),
    },
    {
      label: 'Save filter',
      val: 'Save filter',
      icon: true,
      click({ setTreeItem }) {
        saveDialogFn({
          setTreeItem,
          actionBox,
          saveFilterFn,
          ok: () => {
          },
        })
      },
    },
    {
      label: 'My saved filters',
      val: 'Manage your saved filters',
      icon: true,
      click() {
        hideFilter()
        history.push(`${RoutesObj.MY_SAVED_FILTERS.URL}`)
      },
    },
    {
      label: 'Reset filters to default',
      val: 'Clear all',
      click() {
        const filterCountry = region?.key || 'Sweden'
        const sellers = getAllFilterCompetitorValue(filterCountry)
        
        // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/805
        // if (isDashboardPages()) {
        //   const categories = getAllFilterCategoryValue()
        //   dispatch(cleanFilter({
        //     // Dashboard 页面选中全部 category 和全部 competitor
        //     categories,
        //     sellers,
        //     targets: getFilterGenderAllValue(),
        //   }))
        // } 
        // else {
        //   // const defaultCategory = getInitCategories(categoryList)
        //   const defaultCategory = getInitCategories(storage.getCategoryTreeList())
        //   dispatch(cleanFilter({
        //     // 清空时，默认选择第一个category
        //     categories: defaultCategory,
        //     sellers,
        //     targets: getFilterGenderAllValue(),
        //   }))
        // }
        const categories = getAllFilterCategoryValue()
        dispatch(cleanFilter({
          categories,
          sellers,
          targets: getFilterGenderAllValue(),
        }))

        // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/804
        dispatch(updateFilterTags({}))
        // clear lookbook
        dispatch(updateCheckedLookbook([]))
      },
    },
  ].filter(item => filterAvailable(item.label)).map(item => {
    if (isSearchOptions && !item.disabled) {
      item.disabled = item.label === FILTER_LABELS.LOOKBOOK_FILTER
      return item
    }

    if (isAttributeRanking && !item.disabled) {
      item.disabled = item.label === FILTER_LABELS.LOOKBOOK_FILTER
      return item
    }

    if (isSoldOutVendorsPage() && !item.disabled) {
      item.disabled = item.label === FILTER_LABELS.LOOKBOOK_FILTER
      return item
    }

    if (isPlatformAnalyticsPage() && !item.disabled) {
      item.disabled = item.label === FILTER_LABELS.LOOKBOOK_FILTER
      return item
    }

    // disabled other filter items when user have select lookbook
    if (isLookbook) {
      item.disabled = item.label !== FILTER_LABELS.LOOKBOOK_FILTER
    }

    if (item.label === FILTER_LABELS.LOOKBOOK_FILTER && !enabledLookbook) {
      item.disabled = true
    }

    // enable currency and clear filter for lookbook
    if (enabledLookbook && [ FILTER_LABELS.CURRENCY, FILTER_LABELS.CATEGORY, FILTER_LABELS.CLEAR_FILTER ].includes(item.label)) {
      item.disabled = false
    }

    // 禁用 Filter > Category
    if (FILTER_LABELS.CATEGORY === item.label && isDisabledCategoryPages()) {
      item.disabled = true
    }

    // 禁用 Filter > Lookbook
    if (FILTER_LABELS.LOOKBOOK_FILTER === item.label && isDisabledLookbookPages()) {
      item.disabled = true
    }

    return item
  })

  let newTreeData = cloneDeep(treeData)
  if (IS_LIVE_ENV) {
    // hide COLOR_FILTER for all vendors
    newTreeData = newTreeData.filter(item => ![ FILTER_LABELS.COLOR_FILTER, FILTER_LABELS.NO_SIZE ].includes(item.label))
    if (!enableFilterSize()) {
      newTreeData = newTreeData.filter(item => ![ FILTER_LABELS.SIZE_FILTER ].includes(item.label))
    }
  } else {
    if (!enableFilterSize()) {
      newTreeData = newTreeData.filter(item => ![ FILTER_LABELS.SIZE_FILTER ].includes(item.label))
    }
  }

  // live 环境并且是 Kappahl 时才显示 Pack filter, 其它情况不显示
  if (!enableFilterPack()) {
    newTreeData = newTreeData.filter(item => ![ FILTER_LABELS.PACK_FILTER ].includes(item.label))
  }

  if (!enableFilterTax()) {
    newTreeData = newTreeData.filter(item => item.label !== FILTER_LABELS.TAX)
  }

  return { treeData: newTreeData }
}

export const findDistinct = (array, key) => {
  const distinct =
    (array || array.sellers).map(item => item[key])
      .filter((value, index, self) => self.indexOf(value) === index)
  return distinct
}

export const formatCollection = (filter, targets?: Array<any> | null) => {
  const { comparisons, ...collection } = JSON.parse(JSON.stringify(filter))
  const comparison = comparisons[0]
  // eslint-disable-next-line
  delete collection['__typename'];
  comparison.collection = collection
  // eslint-disable-next-line
  delete comparison['__typename'];
  // eslint-disable-next-line
  delete collection['__comparisons__'];
  if (!comparison.collection.query) comparison.collection.query = { ...queryInit }

  const locked = storage.getLocked()
  if (locked) {
    comparison.name = ''
  }

  if (targets) { // init targetGroups from python api
    comparison.collection.query.targetGroups = (comparison.collection.query.targetGroups || []).filter(item => targets.indexOf(item) > -1)
  }
  if (comparison.collection.query?.categories) {
    comparison.collection.query.categories = comparison.collection.query?.categories.map(category => category?.category ?? category)
    delete comparison.collection.query.blackProperties
    delete comparison.collection.query.includeUnsetCategories
  }
  return comparison
}
