import React from 'react'
import { Dropdown, DropdownOptionProps } from 'componentsv2/form-elements/Dropdown'
import { PRICE_FILTER_ARRAY } from 'consts'
import { FILTER_LABELS } from 'componentsv2/Tree/utils'
import { useFilterPricePerspective } from 'hooks/useFilter'
import { loadingBar } from 'hooks/useLoadingBar'
import { PricePerspectiveDropdownProps } from './types'

export const PricePerspectiveDropdown = ({
    value,
    onChange,
    left,
    right,
    style,
    label = FILTER_LABELS.PRICE_FILTER,
    title = FILTER_LABELS.PRICE_FILTER,
    className,
    enableFilter = false,
    enableLoadingBar = false,
    disabled = false,
}: PricePerspectiveDropdownProps) => {
    const [ filterPricePerspective, setFilterPricePerspective ] = useFilterPricePerspective()
    const options = PRICE_FILTER_ARRAY.map(item => ({
        key: item.val,
        description: item.label,
    }))
    const defaultValue = options[0].key

    return (
        <Dropdown
            style={style}
            className={className}
            left={left}
            right={right}
            options={options}
            title={title}
            label={label}
            placeholder={label}
            dropdownStyle={{ width: '140px' }}
            disabled={disabled}
            value={value || filterPricePerspective || defaultValue}
            onChange={value => {
                if (enableFilter) setFilterPricePerspective(value as string)
                const selectedOption = options.find(item => item.key === value) as DropdownOptionProps
                onChange?.(value as string, selectedOption, options)
                if (enableLoadingBar) loadingBar.restart()
            }}
        />
    )
}
