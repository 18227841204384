import React, { CSSProperties, useLayoutEffect, useState } from 'react'
import classnames from 'classnames'
import { CloseIcon } from 'assets/icons/Close'
import hoverIcon from 'assets/menus/noun_add.png'
import { RoutesObj } from 'global/Routes'
import { ClickAwayListener } from '@material-ui/core'
import { storage } from 'utils/storage'
import { SellerType } from 'types'
import { getSellerByCodeAndRegion } from 'graphql/sellers/getSellers'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterSize } from 'hooks/useFilter'
import { ALL_VENDORS, MarketArray, MARKET_VENDOR, SELECTED_CATEGORY, SELECTED_VENDORS, SELECTED_VENDORS_OBJ } from 'consts'
import { setCategoriesAndSellers } from 'features/filters/filtersSlice'
import { useDispatch } from 'react-redux'
import { getCategoryValueByLabel } from 'utils'
import { useHistory } from 'react-router-dom'
import { getDisabledCategoriesForSizeFilter, getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { difference } from 'utils/array'
import styles from './styles.module.scss'

export const MenuButton = ({
    vendor,
    category,
}: {
    vendor: string;
    category: string;
}) => {
    const [ open, setOpen ] = useState(false)
    const [ hover, setHover ] = useState(false)
    const [ menuStyle, setMenuStyle ] = useState<CSSProperties>({})

    // hide Menu when scroll page
    useLayoutEffect(() => {
        const pageContainer = document.querySelector('.page-container')
        const onScroll = () => setOpen(false)
        pageContainer?.addEventListener('scroll', onScroll)
        return () => {
            pageContainer?.removeEventListener('scroll', onScroll)
        }
    }, [])

    const onShowMenu = () => {
        if (open) return
        const e = window.event
        const box = e?.target as SVGElement
        if (!box) return
        const rect = box.getBoundingClientRect()
        setOpen(true)
        setMenuStyle({
            top: rect.top - 50,
            left: rect.right + 16,
            position: 'fixed',
            zIndex: 9999,
        })
    }

    return (
        <div 
            className={classnames({
                [styles.menuButton]: true,
                [styles.menuButtonHover]: hover,
            })} 
            onClick={onShowMenu}
        >
            <Icon 
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            />
            {
                open ? (
                    <Menu
                        vendor={vendor}
                        category={category}
                        style={menuStyle}
                        onClose={() => setOpen(false)}
                    />
                ) : null
            }
        </div>
    )
}

export const Icon = ({
    onMouseEnter,
    onMouseLeave,
}: {
    onMouseEnter?: Function;
    onMouseLeave?: Function;
}) => {
    return (
        <svg onMouseEnter={e => onMouseEnter?.(e)} onMouseLeave={e => onMouseLeave?.()} xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 24 24" fill="#01A699">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M22 5v2h-3v3h-2V7h-3V5h3V2h2v3h3zm-3 14H5V5h6V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6h-2v6zm-4-6v4h2v-4h-2zm-4 4h2V9h-2v8zm-2 0v-6H7v6h2z" />
        </svg>
    )
}

interface MenuItem {
    name: string;
    url: string;
}

export const Menu = ({
    vendor,
    category,
    style = {},
    className,
    onClose,
}: {
    vendor: string;
    category: string;
    style?: CSSProperties;
    className?: string;
    onClose?: () => void;
}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ filterSize ] = useFilterSize()

    const items: MenuItem[] = [
        {
            name: RoutesObj.CATEGORY_ANALYTICS.LABEL,
            url: RoutesObj.CATEGORY_ANALYTICS.URL,
        },
        {
            name: RoutesObj.COLOR_ANALYTICS.LABEL,
            url: RoutesObj.COLOR_ANALYTICS.URL,
        },
        {
            name: RoutesObj.MATERIAL_ANALYTICS.LABEL,
            url: RoutesObj.MATERIAL_ANALYTICS.URL,
        },
        {
            name: RoutesObj.POPULAR_PRODUCTS.LABEL,
            url: RoutesObj.POPULAR_PRODUCTS.URL,
        },
        {
            name: RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.LABEL,
            url: RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL,
        },
        {
            name: RoutesObj.PRICE_ARCHITECTURE.LABEL,
            url: RoutesObj.PRICE_ARCHITECTURE.URL,
        },
        {
            name: RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.LABEL,
            url: RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL,
        },
        {
            name: RoutesObj.SEARCH_OPTIONS.LABEL,
            url: RoutesObj.SEARCH_OPTIONS.URL,
        },
    ]

    const onClick = (menuItem: MenuItem) => {
        /* ********************* update sellers ************************* */
        const customerVendor = storage.getCustomerVendor()
        let sellers: SellerType[] = []
        if (customerVendor !== vendor) {
            // 当前点击 All vendors 和 Selected vendors 跳转页面
            if (MarketArray.includes(vendor)) {
                sellers = [ ...filterCompetitor ]
                    .filter(seller => !MarketArray.includes(seller.vendor))
                if (vendor === SELECTED_VENDORS) {
                    sellers.push(SELECTED_VENDORS_OBJ)
                } else if ([ ALL_VENDORS, MARKET_VENDOR.vendor ].includes(vendor)) {
                    sellers.push(SELECTED_VENDORS_OBJ)
                    sellers.push(MARKET_VENDOR)
                }
            } else {
                sellers.push(getSellerByCodeAndRegion(vendor, filterCountry))
                sellers.push(getSellerByCodeAndRegion(customerVendor, filterCountry))
            }
        }

        /* ********************* update category ************************* */
        let categoryList: string[] = []
        if (category === SELECTED_CATEGORY) {
            categoryList = [ ...getFirstLevelCategories({ selectedCategories: filterCategory }) ]
        } else {
            categoryList = [ category ]
        }
        let categoryValue = getCategoryValueByLabel(categoryList)
        if (filterSize?.checked) {
            const disabledCategories = getDisabledCategoriesForSizeFilter(true)
            categoryValue = difference(categoryValue, disabledCategories)
        }
        
        dispatch(setCategoriesAndSellers({
            sellers,
            categories: categoryValue,
        }))

        // from=dashboard 表示从 Dashboard 的表格跳转到分析页, 和点击左边菜单跳转区分开
        history.push(menuItem.url + '?from=dashboard')
    }

    return (
        <ClickAwayListener onClickAway={() => onClose?.()}>
            <div className={classnames([ styles.rightMenu, className ])} style={{ ...style }}>
                <div className={styles.rightMenuTitle}>
                    <div className="flex1" style={{ color: '#666666' }}>Analysis</div>
                    <CloseIcon onClick={onClose} />
                </div>
                {
                    items.map(item => (
                        <div
                            key={item.name}
                            className={styles.menuItem}
                            onClick={() => onClick(item)}
                        >
                            <div className={styles.menuItemName}>{item.name}</div>
                            <img src={hoverIcon} alt="icon" />
                        </div>
                    ))
                }
            </div>
        </ClickAwayListener>
    )
}
