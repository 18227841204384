import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store.type'
import { dateUtils } from 'norna-uikit';
import { storage } from 'utils/storage'

export interface GlobalDataType {
    // Time period in the left menu
    calendarTmpDate: string;
    calendarDate: string;
    calendarComparisonTmpDate: string;
    calendarComparisonDate: string;
    calendarPeriodValue: string;
    calendarCompareToValue: string;
    calendarSelectionDate: string;
    // Filter tags
    filterTags: any;
    filterUnselectTags: string[];
    // Price Architecture
    convertCurrency: {rate: number; source: string; target: string; value: number}[];
    currentRate: {[currency: string]: number};
    // 临时 property
    tmpProperty: Record<string, string[]>;
    // 版本号是否已读
    versionRead: boolean;
    // favorite 列表
    favoriteList: { id: string; name: string; path: string; }[];
}

const initialState: GlobalDataType = {
    // Time period in the left menu d
    calendarTmpDate: '',
    calendarDate: '',
    calendarComparisonTmpDate: '',
    calendarComparisonDate: '',
    calendarPeriodValue: '',
    calendarCompareToValue: '',
    calendarSelectionDate: '',
    // Filter tags
    filterTags: {},
    filterUnselectTags: [],
    // Price Architecture -> convert_currency
    convertCurrency: [],
    currentRate: {},
    // 临时 property
    tmpProperty: {},
    // 版本号是否已读
    versionRead: false,
    // favorite 列表
    favoriteList: [],
}

export const GlobalDataSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        /* ************************ Time period in the left menu ************************** */
        updateCalendarTmpDate(state, action) {
            state.calendarTmpDate = action.payload
        },
        updateCalendarDate(state, action) {
            state.calendarDate = action.payload
            storage.setGlobalDateValue(action.payload)
            storage.setRecordDate(dateUtils.today())
        },
        updateCalendarComparisonTmpDate(state, action) {
            state.calendarComparisonTmpDate = action.payload
        },
        updateCalendarComparisonDate(state, action) {
            state.calendarComparisonDate = action.payload
            storage.setGlobalComparisonDateValue(action.payload)
            storage.setRecordComparisonDate(dateUtils.today())
        },
        updateCalendarPeriodValue(state, action) {
            state.calendarPeriodValue = action.payload
        },
        updateCalendarCompareToValue(state, action) {
            state.calendarCompareToValue = action.payload
        },
        updateCalendarSelectionDate(state, action) {
            state.calendarSelectionDate = action.payload
        },

        /* ************************ FilterTags ************************** */
        updateFilterTags(state, action) {
            state.filterTags = action.payload
        },
        toggleFilterUnselectTags(state, action) {
            let newFilterUnselectTags = JSON.parse(JSON.stringify(state.filterUnselectTags)) || []
            const tag = action.payload
            if (newFilterUnselectTags.includes(tag)) {
                newFilterUnselectTags = newFilterUnselectTags.filter(item => item !== tag)
            } else {
                newFilterUnselectTags.push(tag)
            }
            state.filterUnselectTags = [ ...newFilterUnselectTags ]
        },
        updateFilterUnselectTags(state, action) {
            state.filterUnselectTags = action.payload
        },

        /* ************************ Price architecture ************************** */
        updateConvertCurrency(state, action) {
            const ratioData = action.payload || []
            const currentRate = ratioData
                .filter((item: any) => item.source === 'EUR')
                .reduce((res: any, item: any) => {
                    res[item.target] = item.rate
                    return res
                }, { EUR: 1 })
            state.convertCurrency = ratioData
            state.currentRate = currentRate
            storage.setCurrentRate(currentRate)
        },

        /* ************************ tmpProperty ************************** */
        updateTmpProperty(state, action) {
            state.tmpProperty = action.payload
        },
        /* ************************ versionRead ************************** */
        updateVersionRead(state, action) {
            state.versionRead = action.payload
        },
        /* ************************ favoriteList ************************** */
        updateFavoriteList(state, action) {
            state.favoriteList = action.payload
        },
    },
})

export const {
    /* ************************ Time period in the left menu ************************** */
    updateCalendarTmpDate,
    updateCalendarDate,
    updateCalendarComparisonTmpDate,
    updateCalendarComparisonDate,
    updateCalendarPeriodValue,
    updateCalendarCompareToValue,
    updateCalendarSelectionDate,
    /* ************************ FilterTags ************************** */
    updateFilterTags,
    toggleFilterUnselectTags,
    updateFilterUnselectTags,
    /* ************************ Price architecture ************************** */
    updateConvertCurrency,
    /* ************************ tmpProperty ************************** */
    updateTmpProperty,
    /* ************************ versionRead ************************** */
    updateVersionRead,
    /* ************************ favoriteList ************************** */
    updateFavoriteList,
    /* ************************ 通用更改方法 ************************** */
} = GlobalDataSlice.actions

export const getCalendarTmpDate = (state: RootState) => state.global.calendarTmpDate
export const getCalendarDate = (state: RootState) => state.global.calendarDate
export const getCalendarComparisonTmpDate = (state: RootState) => state.global.calendarComparisonTmpDate
export const getCalendarComparisonDate = (state: RootState) => state.global.calendarComparisonDate
export const getCalendarPeriodValue = (state: RootState) => state.global.calendarPeriodValue 
export const getCalendarCompareToValue = (state: RootState) => state.global.calendarCompareToValue
export const getCalendarSelectionDate = (state: RootState) => state.global.calendarSelectionDate

export const getFilterTags = (state: RootState) => state.global.filterTags || {}
export const getFilterUnselectTags = (state: RootState) => state.global.filterUnselectTags || []

export const getConvertCurrencyValue = (state: RootState) => state.global.convertCurrency || []
export const getCurrentRateValue = (state: RootState) => state.global.currentRate || {}

export const getTmpProperty = (state: RootState) => state.global.tmpProperty || {}

export const getVersionRead = (state: RootState) => state.global.versionRead || false

export const getFavoriteList = (state: RootState) => state.global.favoriteList || []

export default GlobalDataSlice.reducer
