import { MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { cloneDeep } from 'lodash'
import { getVendorNameByCode } from 'utils'
import { colors } from '../MyCharts/utils'
import { numberUtils } from 'norna-uikit'

export function handleNewnessSplitPieChartData(data) {
    let newData = cloneDeep(data)
    const total = newData.map(item => Number(item?.dataSource?.[0]?.value?.replace(',', ''))).reduce((curr, next) => curr + next, 0)
    newData = newData.map(item => {
        const option = Number(item?.dataSource?.[0]?.value?.replace(',', ''))
        return {
            label: item.title,
            value: option,
        }
    })
    newData[0].value = Number((newData[0].value / total * 100).toFixed(1))
    newData[1].value = Number((newData[1].value / total * 100).toFixed(1))
    newData[2].value = 100 - newData[0].value - newData[1].value
    return newData
}

export function handleNewnessSplitBarChartData(data) {
    if (!data?.length) return []
    let newData = cloneDeep(data)

    newData = newData.map(item => {
        return {
            label: item.title,
            value: Number(item.dataSource[0].value.replace('%', '').replace(',', '')),
        }
    })

    const lastItemValue = newData[newData.length - 1].value
    
    newData = newData.map(item => {
        item.value = !lastItemValue ? 0 : Number(numberUtils.formatNumber(item.value / lastItemValue, { isCentuple: true, decimal: 1 }))
        return item
    })

    return newData
}

export function handleWeeklyNewnessBarChartData(data, customerVendor) {
    if (!data) return { newData: [], productData: [] }
    let newData = cloneDeep(data)

    const vendorList = Object.keys(newData)
    const comparisonVendorList = vendorList.filter(v => ![ customerVendor, MARKET_VENDOR.vendor, SELECTED_VENDORS ].includes(v))
    let comparisonVendor = SELECTED_VENDORS
    if (comparisonVendorList.length === 1) {
        comparisonVendor = comparisonVendorList[0]
    }

    newData = Object.keys(newData[customerVendor] || {}).map(week => {
        return {
            label: `W${week}`,
            [customerVendor]: newData[customerVendor][week].virtual_count,
            [comparisonVendor]: newData[comparisonVendor][week].virtual_count,
            [MARKET_VENDOR.vendor]: newData[MARKET_VENDOR.vendor][week].virtual_count,
        }
    })

    const productData = newData.map(item => {
        return {
            label: item.label,
            [colors[0]]: {
                vendorName: getVendorNameByCode(customerVendor),
                products: data[customerVendor][item.label.replace('W', '')].products,
            },
            [colors[1]]: {
                vendorName: getVendorNameByCode(comparisonVendor),
                products: data[comparisonVendor][item.label.replace('W', '')].products,
            },
            [colors[2]]: {
                vendorName: getVendorNameByCode(MARKET_VENDOR.vendor),
                products: data[MARKET_VENDOR.vendor][item.label.replace('W', '')].products,
            },
        }
    })

    return {
        newData,
        productData,
    }
}
