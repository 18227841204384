import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { Title } from 'components/typography/Title'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ArrowIcon } from 'assets/icons/Arrow'
import { ProductPriceProps } from './types'
import styles from './styles.module.scss'
import { Descriptions } from '../Descriptions'
import { numberUtils } from 'norna-uikit'

export const ProductPrice = ({
    price,
    currency,
    style,
    className,
    top,
    bottom,
}: ProductPriceProps) => {
    const discountedPrice = price?.discountedPrice || ''
    const originalPrice = price?.originalPrice || ''

    const defaultStyle: CSSProperties = {}
    if (typeof top === 'number') {
        defaultStyle.marginTop = top + 'px'
    }
    if (typeof bottom === 'number') {
        defaultStyle.marginBottom = bottom + 'px'
    }

    return (
        <div className={classnames([ styles.productPrice, className ])} style={{ ...defaultStyle, ...style }}>
            <Title xxl uppercase>
                {originalPrice}{' '}
                {!Number(discountedPrice) && (currency?.toLowerCase() || '')}
            </Title>

            <ShouldRender shouldRender={!!Number(discountedPrice.replace(/,/g, ''))}>
                <div className={styles.arrow}>
                    <ArrowIcon right />
                </div>
                <div className={styles.discountedPrice}>
                    <Title xxl uppercase>
                        {discountedPrice}{' '}
                        {currency?.toLowerCase() ?? ''}
                    </Title>
                </div>
            </ShouldRender>
        </div>
    )
}

export const ProductPriceV2 = ({
    price,
    currency,
    style,
    className,
    top,
    bottom,
}: ProductPriceProps) => {
    const discountedPrice = price?.discountedPrice || ''
    const originalPrice = price?.originalPrice || ''

    const defaultStyle: CSSProperties = {}
    if (typeof top === 'number') {
        defaultStyle.marginTop = top + 'px'
    }
    if (typeof bottom === 'number') {
        defaultStyle.marginBottom = bottom + 'px'
    }

    const isSingle = !Number(discountedPrice.replace(/,/g, ''))

    return (
        <div className={classnames([ styles.productPrice, className ])} style={{ ...defaultStyle, ...style, alignItems: 'center', display: 'flex' }}>
            {
                isSingle ? (
                    <Title xxl uppercase style={{ fontSize: 22 }}>
                        { `${numberUtils.formatNumberByComma(originalPrice)} ${currency} - ${numberUtils.formatNumberByComma(originalPrice)} ${currency}` }
                    </Title>
                ) : (
                    <>
                        <Title xxl uppercase style={{ fontSize: 22 }}>
                            {originalPrice}{' '}
                            {!Number(discountedPrice) && (currency?.toLowerCase() || '')}
                        </Title>
                        <div className={styles.arrow} style={{ paddingTop: 2 }}>
                            <ArrowIcon right />
                        </div>
                        <div className={styles.discountedPrice}>
                            <Title xxl uppercase style={{ fontSize: 22 }}>
                                {discountedPrice}{' '}
                                {currency?.toLowerCase() ?? ''}
                            </Title>
                        </div>
                    </>
                )
            }
        </div>
    )
}

ProductPrice.displayName = 'ProductPrice'

export const ProductPriceRange = ({
    minPrice,
    maxPrice,
    currency = '',
    label,
}) => {
    let value = ''
    if (typeof minPrice === 'undefined') {
        value = '-'
    } else {
        if (minPrice === maxPrice) {
            value = `${numberUtils.formatNumberByComma(minPrice)} ${currency}`
        } else {
            value = `${numberUtils.formatNumberByComma(minPrice)} ${currency} - ${numberUtils.formatNumberByComma(maxPrice)} ${currency}`
        }
    }

    return (
        <Descriptions.Item 
            label={`${label} ${minPrice === maxPrice ? '' : 'RANGE ALL SIZES'}`} 
            value={value} 
            valueFontSize={22} 
        />
    )
}

export const ProductPriceRangeV2 = ({
    minPrice,
    maxPrice,
    currency = '',
    label,
}) => {
    if (String(minPrice) === 'NaN') {
        return (
            <Descriptions.Item 
                label={label} 
                value="-" 
                valueFontSize={22} 
            />
        )
    }
    let value = ''
    if (typeof minPrice === 'undefined') {
        value = '-'
    } else {
        value = `${numberUtils.formatNumberByComma(minPrice)} ${currency} - ${numberUtils.formatNumberByComma(maxPrice)} ${currency}`
    }

    return (
        <Descriptions.Item 
            label={label} 
            value={value} 
            valueFontSize={22} 
        />
    )
}
