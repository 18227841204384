import React from 'react'
import styles from './styles.module.scss'
import { ShowMoreProps } from './types'

export const ShowMoreIcon = ({ onClick }: ShowMoreProps) =>
    <svg
        className={styles['show-more']}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        onClick={() => onClick()}
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path className={styles.dots} d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
