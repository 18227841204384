import React from 'react'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useLoadingBar } from 'hooks'
import { CategorySplitPieGraph } from './components/CategorySplitPieGraph'
import styles from './styles.module.scss'

export const CategorySplit = () => {
  useScrollToTop()
  useLoadingBar()

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.container}>
        <CategorySplitPieGraph />
      </div>
    </div>
  )
}
