import React from 'react'
import { Text } from 'components/typography'
import styles from './styles.module.scss'
import { LabelProps } from './types'

export const Label = ({ children, label, required }: LabelProps) =>
    <label>
        <div className={styles.label}>
            <Text large>
                {label}
            </Text>

            <span className={styles.required}>
                {required && '*'}
            </span>
        </div>

        {children}
    </label>
