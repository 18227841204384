import React, { useEffect, useState } from 'react'
import { Flex } from 'druikit'
import { useApiData, useModalVisible, usePovValue, useSettingKpiValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { MetricCard } from './components/MetricCard'
import styles from './styles.module.scss'
import { MetricModal } from './components/MetricModal'
import { geneCsvData, handleData } from './utils'
import { useFilterCurrency } from 'hooks/useFilter'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_DYNAMIC_DASHBOARD } from 'features/filters/moduleDataSlice.const'
import { ASSORTMENT_MIX, DISCOUNT_EFFECT, FULL_PRICE_PERFORMANCE, PRODUCT_NEWNESS, SALES_PRICE_PERFORMANCE, SOLD_OUT_STATUS } from 'features/filters/dynamicDashboardSlice'

export const MetricInfo = () => {
    const [ settingKapiValue ] = useSettingKpiValue()
    const [ currency ] = useFilterCurrency()
    const [ apiData ] = useApiData()
    const [ povValue ] = usePovValue()

    const [ dataSource, setDataSource ] = useState<any[]>([])
    const [ , setCsvData ] = useCsvDataModuleData()

    useDeepCompareEffect(() => {
        const newData: any[] = handleData(apiData, currency, povValue)
        setDataSource(newData)
        setCsvData({
            [CSV_DATA_DYNAMIC_DASHBOARD]: geneCsvData({ dataSource: newData }),
        })
    }, [ apiData, currency, {} ])

    const [ productNewnessVisible, setProductNewnessVisible ] = useState(false)
    const [ assortmentMixVisible, setAssortmentMixVisible ] = useState(false)
    const [ discountEffectVisible, setDiscountEffectVisible ] = useState(false)
    const [ soldOutStatusVisible, setSoldOutStatusVisible ] = useState(false)
    const [ salesPricePerformanceVisible, setSalesPricePerformanceVisible ] = useState(false)
    const [ fullPricePerformanceVisible, setFullPricePerformanceVisible ] = useState(false)
    const [ , setModalVisible ] = useModalVisible()

    useEffect(() => {
        if (
            productNewnessVisible ||
            assortmentMixVisible ||
            discountEffectVisible ||
            soldOutStatusVisible ||
            salesPricePerformanceVisible ||
            fullPricePerformanceVisible
        ) {
            // 有模态框
            setModalVisible(true)
        } else {
            // 没有模态框
            setModalVisible(false)
        }
    }, [
        productNewnessVisible,
        assortmentMixVisible,
        discountEffectVisible,
        soldOutStatusVisible,
        salesPricePerformanceVisible,
        fullPricePerformanceVisible,
    ])

    return (
        <>
            <Flex flexWrap="wrap">
                {
                    dataSource.map(item => {
                        if (!settingKapiValue.includes(item.title)) {
                            return null
                        }
                        return (
                            <MetricCard
                                key={item?.title || ''}
                                className={styles.cardItem}
                                title={item?.title || ''}
                                dataSource={item?.dataSource || []}
                                showUnits={item.showUnits}
                                onClick={() => {
                                    switch (item.kpi) {
                                        case PRODUCT_NEWNESS:
                                            setProductNewnessVisible(true)
                                            break
                                        case ASSORTMENT_MIX:
                                            setAssortmentMixVisible(true)
                                            break
                                        case DISCOUNT_EFFECT:
                                            setDiscountEffectVisible(true)
                                            break
                                        case SOLD_OUT_STATUS:
                                            setSoldOutStatusVisible(true)
                                            break
                                        case SALES_PRICE_PERFORMANCE:
                                            setSalesPricePerformanceVisible(true)
                                            break
                                        case FULL_PRICE_PERFORMANCE:
                                            setFullPricePerformanceVisible(true)
                                            break
                                        default:
                                    }
                                }}
                                description={item.description}
                            />
                        )
                    })
                }

                {
                    productNewnessVisible && (
                        <MetricModal
                            kpi={PRODUCT_NEWNESS}
                            onClose={() => setProductNewnessVisible(false)}
                        />
                    )
                }

                {
                    assortmentMixVisible && (
                        <MetricModal
                            kpi={ASSORTMENT_MIX}
                            onClose={() => setAssortmentMixVisible(false)}
                        />
                    )
                }

                {
                    discountEffectVisible && (
                        <MetricModal
                            kpi={DISCOUNT_EFFECT}
                            onClose={() => setDiscountEffectVisible(false)}
                        />
                    )
                }

                {
                    soldOutStatusVisible && (
                        <MetricModal
                            kpi={SOLD_OUT_STATUS}
                            onClose={() => setSoldOutStatusVisible(false)}
                        />
                    )
                }

                {
                    salesPricePerformanceVisible && (
                        <MetricModal
                            kpi={SALES_PRICE_PERFORMANCE}
                            onClose={() => setSalesPricePerformanceVisible(false)}
                        />
                    )
                }

                {
                    fullPricePerformanceVisible && (
                        <MetricModal
                            kpi={FULL_PRICE_PERFORMANCE}
                            onClose={() => setFullPricePerformanceVisible(false)}
                        />
                    )
                }
            </Flex>
        </>
    )
}
