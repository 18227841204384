
export const calcUnit = (value: number | string | undefined) => typeof value === 'number' ? value + 'px' : value

/**
 * 获取 name 属性对应的值
 * 
 * name 可能是嵌套字段，如：name = 'a.b.c'
 * record = { a: { b: { c: 'abc' } } }
 * getValueByName({ record, name })        // 'abc'
 */
export const getValueByName = ({ record, name }: { record: Record<string, any>; name?: string }): string | undefined => {
    if (!name) return
    const nameArr = name.split('.')

    let value: any = { ...record }
    // eslint-disable-next-line
    for (let i = 0; i < nameArr.length; i++) {
        if (Object.prototype.toString.call(value) !== '[object Object]') {
            break
        }
        value = value[nameArr[i]]
    }

    return value || ''
}

/**
 * 递归改变数组内数据结构
 */
export function recursion(data: any[], fieldsOrcb: ((item: any) => void) | Record<string, any>, mode: 'append' | 'new' = 'append'): any[] {
    const iterator = function (arr: any[], cb: Function) {
        // eslint-disable-next-line
        for (let i = 0; i < arr.length; i++) {
            cb.call(arr[i], arr[i])
        }
    }

    const changeItem = function (item: any) {
        if (typeof fieldsOrcb === 'function') {
            fieldsOrcb(item)
        } else if (Object.prototype.toString.call(fieldsOrcb) === '[object Object]') {
            Object.keys(fieldsOrcb).forEach(key => {
                item[key] = item[fieldsOrcb[key]]
            })

            if (mode === 'new') { // 删除原有字段
                const arr1 = Object.keys(item)
                const arr2 = Object.keys(fieldsOrcb)
                const arr3 = arr1.filter(a1 => !arr2.includes(a1) && a1 !== 'children')
                arr3.forEach(a3 => delete item[a3])
            }
        }

        if (item.children) {
            iterator(item.children, changeItem)
        }
    }

    iterator(data, changeItem)
    return [ ...data ]
}
