import React, { FC, memo } from 'react'
import { Dropdown, DropdownProps, DropdownType } from 'componentsv2/form-elements/Dropdown'

export const MultipleDropdown: FC<DropdownProps> = memo(props => (
    <Dropdown
        type={DropdownType.MULTI}
        {...props}
    />
),
)

MultipleDropdown.displayName = 'MultipleDropdown'
