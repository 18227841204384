import { GlobalPropsType } from 'libs'
import { CSSProperties } from 'react'
import { ObjectType } from 'types'

export interface MenuBarType {
    label: string;
}
export interface MenuBarItemListItem {
    // list item label
    label: string,

    // list item click event cb
    click?: (e?: React.SyntheticEvent) => void,

    // list item url
    url?: string,
    // disabled or not
    disabled?: boolean;
    // list item  url fn 
    urlFn?: (id: string) => string
    hidden?: boolean;
}

/**
 * ## 触发关闭的事件类型 
 */
export enum MenuPanelDisappearType {
    // 鼠标移开消失
    LEAVE = 'leave',

    // 点击其他处消失
    CLICK_AWAY = 'click away'
}
export interface MenuBarItemProps {
    // MenuBar Item title
    title: string;
    // disabled or not
    disabled?: boolean;
    icon?: React.ReactNode;

    // 出于其他限制(eg: `Filter`打开状态)，禁用其他menu的hover
    disabledHover?: boolean;
    // 菜单面板消失的类型
    disappearType?: MenuPanelDisappearType,
    panelSty?: CSSProperties;

    // 展开后
    active?: boolean;

    /**
     * 自定义展开列表元素
     * 原则上 `list` 和 `customListElement` 必然有一个有效
     */
    CustomListElement?: React.ComponentType<{ ref: any }>;
    // list item
    list?: Array<MenuBarItemListItem>;
    hide?: boolean;
    /**
     *  @param flag: indicate if active the panel or not
     */
    updateActive?: (flag: boolean) => void;
    /**
     * 当前展开的是哪个菜单
     */
    currentActive?: string;
}

export interface MenuBarHookType {

    // create lookbook action
    createLookbook: () => void,

    // open release note
    openRelaseNote: (e?: React.SyntheticEvent) => void,

    // open support 
    openSupport: (e?: React.SyntheticEvent) => void,

    // about norna
    openAbout: (e?: React.SyntheticEvent) => void,
}

/**
 *  ## order 
 *  category\country\gender\currncy\competitor\property  
 */
export interface FilterTagsPropsType {
    // category
    categories?: Array<string>;

    // region
    region: string;

    // gender
    gender?: Array<string>;

    // currency
    currency: string;

    // competitors
    competitor?: Array<string>

    // property
    property?: ObjectType<Array<string>>

    priceFilter?: string;
    priceRange?: string;
    productFilter?: string;
    launchedAfter?: string;
    launchedBefore?: string;
    materialFilter?: string;
    colorFilter?: string;
    sortBy?: string;
    sizeFilter?: string;
    taxFilter?: string;
    packFilter?: string;
    noSize?: string;
    noHistoricalSize?: string;
    noUnavailableSize?: string;
    noHarmonizedSize?: string;
    zalandodeepShopEmptyString?: string;
    ignoreNoAvailableSize?: string;

    open?: boolean;
    setIsGt2lines?: Function;
}

export interface TagButtonPropsType extends GlobalPropsType {
    value: string;
    label: string;
    clickable?: boolean;
    unselect?: boolean;
    lineThrough?: boolean;
    ellipsis?: boolean;
    masked?: boolean;
    onClick?: (value: string, label: string) => void;
    className?: string;
    labelStyle?: CSSProperties;
}

export interface MenuBarStateType {
    release: boolean;
    support: boolean;
    about: boolean;

    // filter 是否发生改变，以判断其他`Menu`在hover时是否起作用
    filterChanged: boolean;
    // 当前选中的 item
    active: string;
}
export interface TagWithTipsPropsType {
    width?: string;
    title?: string;
    label: string;
    align?: 'left' | 'center' | 'right';
    fontSize?: number;
    style?: CSSProperties;
    className?: string;
}

export interface GlobalDatePickerPropsType {
    active?: boolean;
}
