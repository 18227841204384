import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames';
import { FloatButton, Input, Skeleton } from 'antd';
import { stringUtils, asyncUtils } from 'norna-uikit'
import axios from 'axios';
import ChatResponder from './ChatResponder'
import InputMessage, { loadDataStr } from './InputMessage'
import { getCurrentPageCsvData } from './utils';
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook';
import PreContextModal from './PreContextModal';
import { isPriceArchitecturePage } from 'global/Routes';
import { chatApiPrefix } from 'configs';
import AuthToken from 'utils/AuthToken';
import { CONST_VARS } from 'libs/const';
import { ChatTypeEnum, SpeakerEnum } from './types';

const fetchResponse = async ({
    input,
    sessionId,
    chatType,
}: {
    input: string;
    sessionId?: string;
    chatType?: ChatTypeEnum;
}) => {
    const user = localStorage.getItem('email')
    try {
        const result = await axios.request({
            url: chatType === ChatTypeEnum.gemini ? `${chatApiPrefix}/chat` : `${CONST_VARS.API_URL}/gpt/chat`, 
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + AuthToken.getAccessToken(),
            },
            params: {
                user,
                session_id: sessionId,
            },
            data: {
                input,
            },
        })
        return result
    } catch (e) {
        return null
    }
}

const ChatBox = () => {
    const messageListDivRef = React.useRef<HTMLDivElement>(null)
    const messageListRef = useRef<any[]>([])
    const loadingRef = useRef<boolean>(false)
    const [ refresh, setRefresh ] = useState({})
    const [ sessionId, setSessionId ] = useState('')

    /**
     * 滚动到底部
     */
    const scrollToBottom = () => {
        const messageListDivEl = messageListDivRef.current
        if (!messageListDivEl) return
        setTimeout(() => {
            messageListDivEl.scrollTop = messageListDivEl.scrollHeight
        }, 100)
    }

    /**
     * 插入消息
     */
    const insertMessage = (speaker, message: any) => {
        const newMessage = {
            id: stringUtils.uuid(),
            speaker,
            message,
        }
        const newMessageList = messageListRef.current.map(item => item)
        newMessageList.push(newMessage)
        messageListRef.current = newMessageList
        loadingRef.current = speaker === SpeakerEnum.questioner
        setRefresh({})
    }

    const [ csvDatas ] = useCsvDataModuleData()
    const csvData = getCurrentPageCsvData(csvDatas)

    const onSend = async (inputValue, html) => {
        if (!inputValue.trim()) return
        
        if (isPriceArchitecturePage()) {
            const csvDataArr = csvData.split('divide divide divide')
            inputValue = inputValue.replace(loadDataStr, csvDataArr[0].trim())
            inputValue = inputValue.replace(loadDataStr, csvDataArr[1].trim())
            html = html.replace(loadDataStr, `<br/>${csvDataArr[0].trim()}<br/><br/>`).replaceAll('\n', '<br/>')
            html = html.replace(loadDataStr, `<br/>${csvDataArr[1].trim()}<br/>`).replaceAll('\n', '<br/>')
        } else {
            if (inputValue.includes(loadDataStr)) {
                inputValue = inputValue.replaceAll(loadDataStr, '')
                inputValue += '\n'
                inputValue += csvData
            }
        }

        insertMessage(SpeakerEnum.questioner, html)
        scrollToBottom()

        let result: any = await fetchResponse({
            input: inputValue,
            sessionId,
            chatType: chatBoxType,
        })
        if (!result?.data?.response?.length) {
            insertMessage(SpeakerEnum.error, 'Sorry, I can\'t understand your question.')
            scrollToBottom()
            return
        }

        const { response, session_id } = result?.data || {}
        insertMessage(SpeakerEnum.responder, response)
        setSessionId(session_id)
        scrollToBottom()
    }

    const [ chatBoxType, setChatBoxType ] = useState<ChatTypeEnum>()
    const [ chatBoxVisible, setChatBoxVisible ] = useState(false)

    const onToggleChatBox = () => {
        setChatBoxVisible(!chatBoxVisible)
        if (!chatBoxVisible) {
            setTimeout(() => scrollToBottom(), 100)
        }
    }

    useEffect(() => {
        messageListRef.current = []
        setSessionId('')
    }, [ window.location.pathname ])

    const [ preContextVisible, setPreContextVisible ] = useState(false)
    const [ preContextType, setPreContextType ] = useState<ChatTypeEnum>()

    return (
        <>
            <FloatButton.Group
                shape="square"
                style={{ zIndex: 9999991 }}
            >
                <FloatButton
                    description="Gemini Context"
                    onClick={() => {
                        setPreContextType(ChatTypeEnum.gemini)
                        setPreContextVisible(true)
                    }}
                />
                <FloatButton
                    description="Gpt Context"
                    onClick={() => {
                        setPreContextType(ChatTypeEnum.gpt)
                        setPreContextVisible(true)
                    }}
                />
                <FloatButton
                    description="Gemini Chat"
                    onClick={() => {
                        setChatBoxType(ChatTypeEnum.gemini)
                        onToggleChatBox()
                    }}
                />
                <FloatButton
                    description="Gpt Chat"
                    onClick={() => {
                        setChatBoxType(ChatTypeEnum.gpt)
                        onToggleChatBox()
                    }}
                />
            </FloatButton.Group>

            {
                preContextVisible && (
                    <PreContextModal
                        chatType={preContextType} 
                        onClose={() => setPreContextVisible(false)}
                    />
                )
            }
            
            {
                chatBoxVisible && (
                    <div className={styles.chatBoxWrapper}>
                        <div className={styles.messageList} ref={messageListDivRef}>
                            {
                                messageListRef.current.map(item => {
                                    let bodyEl: ReactNode = null
                                    if (item.speaker === SpeakerEnum.error) {
                                        bodyEl = <>{item.message}</>
                                    }
                                    else if (item.speaker === SpeakerEnum.responder) {
                                        bodyEl = <ChatResponder dataSource={item.message} />
                                    }
                                    else {
                                        bodyEl = (
                                            <div style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: item.message }} />
                                        )
                                    }

                                    const cls = classNames({
                                        [styles.messageItem]: true,
                                        [styles.questionerMessageItem]: item.speaker === SpeakerEnum.questioner,
                                        [styles.responderMessageItem]: [ SpeakerEnum.responder, SpeakerEnum.error ].includes(item.speaker),
                                    })

                                    return (
                                        <div 
                                            key={item.id}
                                            className={cls}
                                        >
                                            {bodyEl}
                                        </div>
                                    )
                                })
                            }
                            {
                                loadingRef.current && (
                                    <Skeleton avatar paragraph={{ rows: 0 }} active />
                                )
                            }
                        </div>
                        <div className={styles.inputWrapper}>
                            <InputMessage
                                onSendMessage={onSend}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ChatBox
