/**
 * 获取实际宽度
 * @returns 
 */
 export const getActualWidth = () => {
    const headerEles = document.querySelectorAll('.search-header > div')

    if (headerEles) {
        let width = 0
        headerEles.forEach(ele => {
            width += ele.getBoundingClientRect().width
        })

        const pageEle = document.querySelector('.page-container')
        width += 140
        // 小于页面宽度
        if(pageEle && pageEle.getBoundingClientRect().width>width){
            return 0
        }
        return width 
    }

    return 0
}
