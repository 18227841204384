import React from 'react'
import classnames from 'classnames'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { CheckIcon } from 'assets/icons/Check'
import styles from './styles.module.scss'
import { CheckboxProps } from './types'

export const Checkbox = ({ name = '', isChecked, onChange, children, className, hidden, isDisabled }: CheckboxProps) => <div
    className={styles['checkbox-container']} onClick={e => {
      if (!isDisabled) {
      onChange?.()
      }
    }}
                                                                                                                        >
    <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={onChange}
        className={styles['checkbox-label']}
        disabled={isDisabled}
    />

    <div className={classnames(
      styles['checkbox-design'],
      className && styles[`${className}`],
      hidden && styles.hidden,
      isDisabled && styles.isDisabled)}
    >
        <ShouldRender shouldRender={isChecked}>
            <CheckIcon color="#999" />
        </ShouldRender>
    </div>

    {children}
                                                                                                                        </div>
