import React, { FC, memo } from 'react'
import { ShouldRenderProps } from './types'

/**
 * ## ShouldRender
 *
 * 渲染组件。
 *
 * ## 何时使用
 *
 * 不使用 ShouldRender 组件代码写法如下：
 *
 * ```
 * { visible ? <Element> : null }
 * ```
 *
 * 使用 ShouldRender 组件代码写法如下：
 *
 * ```
 * <ShouldRender shouldRender={visible}>
 *    <Element>
 * </ShouldRender>
 * ```
 */
export const ShouldRender: FC<ShouldRenderProps> = memo(({
                                                           children,
                                                           shouldRender,
                                                         }) => (
  shouldRender ? <>{children}</> : null
))

ShouldRender.displayName = 'ShouldRender'
