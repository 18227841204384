import React, { FC, CSSProperties, useState } from 'react'
import classNames from 'classnames'
import { CollapsibleArrowIcon } from 'assets/icons/CollapsibleArrow'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import { CollapseProps } from './types'
import styles from './styles.module.scss'

export const Collapse: FC<CollapseProps> = ({
    title,
    children,
    showArrow = true,           // 是否显示箭头图标
    style = {},
    className = '',
    top,
    bottom,
}) => {
    // 是否折叠，false 表示不折叠
    const [ collapsed, setCollapsed ] = useState(false)

    const defaultStyle: CSSProperties = {
        width: '100%',
    }
    if (typeof top === 'number') {
        defaultStyle.marginTop = top + 'px'
    }
    if (typeof bottom === 'number') {
        defaultStyle.marginBottom = bottom + 'px'
    }

    return (
        <div className={classNames([ className, styles.collapse ])} style={{ ...defaultStyle, ...style }}>
            <div
                className={styles.header} 
                style={{ cursor: showArrow === false ? 'auto' : 'pointer' }}
                onClick={() => showArrow === true && setCollapsed(!collapsed)}
            >
                <Text>{title}</Text>
                <ShouldRender shouldRender={showArrow}>
                    <CollapsibleArrowIcon collapsed={!collapsed} />
                </ShouldRender>
            </div>
            <ShouldRender shouldRender={!collapsed}>
                {children}
            </ShouldRender>
        </div>
    )
}

Collapse.displayName = 'Collapse'
