import { getFractionDigits } from 'utils'
import * as Request from 'libs/request'
import { storage } from 'utils/storage'
import { getValueByOptions, getValueByQueryDateValid, handleNumber } from 'utils/pageUtils'
import { dateRangeUtils, numberUtils } from 'norna-uikit'

export function handleVendorRegion(data: any) {
    const { queryDateValid, queryComparisonDateValid, isLock } = data.seller
    const priceLadder = data.price_ladder

    const options = priceLadder.total?.current_value

    // total
    let totalCurrentValue = options
    totalCurrentValue = handleNumber(totalCurrentValue, { s: true })
    totalCurrentValue = getValueByQueryDateValid(totalCurrentValue, queryDateValid)

    let totalComparisonValue = priceLadder.total?.comparison_value
    totalComparisonValue = handleNumber(totalComparisonValue, { s: true })
    totalComparisonValue = getValueByQueryDateValid(totalComparisonValue, queryComparisonDateValid)
    totalComparisonValue = getValueByOptions(totalComparisonValue, options)

    let totalChange = priceLadder.total?.change
    totalChange = handleNumber(totalChange, { s: true })
    totalChange = getValueByQueryDateValid(totalChange, queryComparisonDateValid)
    totalChange = getValueByOptions(totalChange, options)

    let totalChangePercentage = priceLadder.total?.change_percentage
    totalChangePercentage = handleNumber(totalChangePercentage, { x: 100, p: true, d: 1 })
    totalChangePercentage = getValueByQueryDateValid(totalChangePercentage, queryComparisonDateValid)
    totalChangePercentage = getValueByOptions(totalChangePercentage, options)

    // avgPrice
    let avgPriceCurrentValue = priceLadder.average_price?.current_value
    avgPriceCurrentValue = handleNumber(avgPriceCurrentValue, { s: true, d: getFractionDigits() })
    avgPriceCurrentValue = getValueByQueryDateValid(avgPriceCurrentValue, queryDateValid)
    avgPriceCurrentValue = getValueByOptions(avgPriceCurrentValue, options)

    let avgPriceComparisonValue = priceLadder.average_price?.comparison_value
    avgPriceComparisonValue = handleNumber(avgPriceComparisonValue, { s: true, d: getFractionDigits() })
    avgPriceComparisonValue = getValueByQueryDateValid(avgPriceComparisonValue, queryComparisonDateValid)
    avgPriceComparisonValue = getValueByOptions(avgPriceComparisonValue, options)

    let avgPriceChange = priceLadder.average_price?.change
    avgPriceChange = handleNumber(avgPriceChange, { s: true, d: getFractionDigits() })
    avgPriceChange = getValueByQueryDateValid(avgPriceChange, queryComparisonDateValid)
    avgPriceChange = getValueByOptions(avgPriceChange, options)

    let avgPriceChangePercentage = priceLadder.average_price?.change_percentage
    avgPriceChangePercentage = handleNumber(avgPriceChangePercentage, { x: 100, p: true, d: 1 })
    avgPriceChangePercentage = getValueByQueryDateValid(avgPriceChangePercentage, queryComparisonDateValid)
    avgPriceChangePercentage = getValueByOptions(avgPriceChangePercentage, options)

    // median price
    let medianPriceCurrentValue = priceLadder.median_price?.current_value
    medianPriceCurrentValue = handleNumber(medianPriceCurrentValue, { s: true, d: getFractionDigits() })
    medianPriceCurrentValue = getValueByQueryDateValid(medianPriceCurrentValue, queryDateValid)
    medianPriceCurrentValue = getValueByOptions(medianPriceCurrentValue, options)

    let medianPriceComparisonValue = priceLadder.median_price?.comparison_value
    medianPriceComparisonValue = handleNumber(medianPriceComparisonValue, { s: true, d: getFractionDigits() })
    medianPriceComparisonValue = getValueByQueryDateValid(medianPriceComparisonValue, queryComparisonDateValid)
    medianPriceComparisonValue = getValueByOptions(medianPriceComparisonValue, options)

    let medianPriceChange = priceLadder.median_price?.change
    medianPriceChange = handleNumber(medianPriceChange, { s: true, d: getFractionDigits() })
    medianPriceChange = getValueByQueryDateValid(medianPriceChange, queryComparisonDateValid)
    medianPriceChange = getValueByOptions(medianPriceChange, options)

    let medianPriceChangePercentage = priceLadder.median_price?.change_percentage
    medianPriceChangePercentage = handleNumber(medianPriceChangePercentage, { x: 100, p: true, d: 1 })
    medianPriceChangePercentage = getValueByQueryDateValid(medianPriceChangePercentage, queryComparisonDateValid)
    medianPriceChangePercentage = getValueByOptions(medianPriceChangePercentage, options)

    // frequent price
    let frequentPriceCurrentValue = priceLadder.most_frequent_price?.current_value
    frequentPriceCurrentValue = handleNumber(frequentPriceCurrentValue, { s: true, d: getFractionDigits() })
    frequentPriceCurrentValue = getValueByQueryDateValid(frequentPriceCurrentValue, queryDateValid)
    frequentPriceCurrentValue = getValueByOptions(frequentPriceCurrentValue, options)

    let frequentPriceComparisonValue = priceLadder.most_frequent_price?.comparison_value
    frequentPriceComparisonValue = handleNumber(frequentPriceComparisonValue, { s: true, d: getFractionDigits() })
    frequentPriceComparisonValue = getValueByQueryDateValid(frequentPriceComparisonValue, queryComparisonDateValid)
    frequentPriceComparisonValue = getValueByOptions(frequentPriceComparisonValue, options)

    let frequentPriceChange = priceLadder.most_frequent_price?.change
    frequentPriceChange = handleNumber(frequentPriceChange, { s: true, d: getFractionDigits() })
    frequentPriceChange = getValueByQueryDateValid(frequentPriceChange, queryComparisonDateValid)
    frequentPriceChange = getValueByOptions(frequentPriceChange, options)

    let frequentPriceChangePercentage = priceLadder.most_frequent_price?.change_percentage
    frequentPriceChangePercentage = handleNumber(frequentPriceChangePercentage, { x: 100, p: true, d: 1 })
    frequentPriceChangePercentage = getValueByQueryDateValid(frequentPriceChangePercentage, queryComparisonDateValid)
    frequentPriceChangePercentage = getValueByOptions(frequentPriceChangePercentage, options)

    // min price
    let minPriceCurrentValue = priceLadder.min_price?.current_value
    minPriceCurrentValue = handleNumber(minPriceCurrentValue, { s: true, d: getFractionDigits() })
    minPriceCurrentValue = getValueByQueryDateValid(minPriceCurrentValue, queryDateValid)
    minPriceCurrentValue = getValueByOptions(minPriceCurrentValue, options)

    let minPriceComparisonValue = priceLadder.min_price?.comparison_value
    minPriceComparisonValue = handleNumber(minPriceComparisonValue, { s: true, d: getFractionDigits() })
    minPriceComparisonValue = getValueByQueryDateValid(minPriceComparisonValue, queryComparisonDateValid)
    minPriceComparisonValue = getValueByOptions(minPriceComparisonValue, options)

    let minPriceChange = priceLadder.min_price?.change
    minPriceChange = handleNumber(minPriceChange, { s: true, d: getFractionDigits() })
    minPriceChange = getValueByQueryDateValid(minPriceChange, queryComparisonDateValid)
    minPriceChange = getValueByOptions(minPriceChange, options)

    let minPriceChangePercentage = priceLadder.min_price?.change_percentage
    minPriceChangePercentage = handleNumber(minPriceChangePercentage, { x: 100, p: true, d: 1 })
    minPriceChangePercentage = getValueByQueryDateValid(minPriceChangePercentage, queryComparisonDateValid)
    minPriceChangePercentage = getValueByOptions(minPriceChangePercentage, options)

    // max price
    let maxPriceCurrentValue = priceLadder.max_price?.current_value
    maxPriceCurrentValue = handleNumber(maxPriceCurrentValue, { s: true, d: getFractionDigits() })
    maxPriceCurrentValue = getValueByQueryDateValid(maxPriceCurrentValue, queryDateValid)
    maxPriceCurrentValue = getValueByOptions(maxPriceCurrentValue, options)

    let maxPriceComparisonValue = priceLadder.max_price?.comparison_value
    maxPriceComparisonValue = handleNumber(maxPriceComparisonValue, { s: true, d: getFractionDigits() })
    maxPriceComparisonValue = getValueByQueryDateValid(maxPriceComparisonValue, queryComparisonDateValid)
    maxPriceComparisonValue = getValueByOptions(maxPriceComparisonValue, options)

    let maxPriceChange = priceLadder.max_price?.change
    maxPriceChange = handleNumber(maxPriceChange, { s: true, d: getFractionDigits() })
    maxPriceChange = getValueByQueryDateValid(maxPriceChange, queryComparisonDateValid)
    maxPriceChange = getValueByOptions(maxPriceChange, options)

    let maxPriceChangePercentage = priceLadder.max_price?.change_percentage
    maxPriceChangePercentage = handleNumber(maxPriceChangePercentage, { x: 100, p: true, d: 1 })
    maxPriceChangePercentage = getValueByQueryDateValid(maxPriceChangePercentage, queryComparisonDateValid)
    maxPriceChangePercentage = getValueByOptions(maxPriceChangePercentage, options)

    const result: any[] = handlePriceDistribution(priceLadder.price_distribution, options, data.seller)

    // index
    let index = priceLadder.index
    index = handleNumber(index, { x: 100, p: true, d: 0, s: true })
    index = getValueByQueryDateValid(index, queryDateValid)
    index = getValueByOptions(index, options)

    return {
        vendor: data.seller.vendor,
        region: data.seller.region,
        isLock,
        active: data.seller.vendor === storage.getCustomerVendor(),
        data: result,
        totalCurrentValue,
        totalComparisonValue,
        totalChange,
        totalChangePercentage,
        avgPriceCurrentValue,
        avgPriceComparisonValue,
        avgPriceChange,
        avgPriceChangePercentage,
        medianPriceCurrentValue,
        medianPriceComparisonValue,
        medianPriceChange,
        medianPriceChangePercentage,
        frequentPriceCurrentValue,
        frequentPriceComparisonValue,
        frequentPriceChange,
        frequentPriceChangePercentage,
        maxPriceCurrentValue,
        maxPriceComparisonValue,
        maxPriceChange,
        maxPriceChangePercentage,
        minPriceCurrentValue,
        minPriceComparisonValue,
        minPriceChange,
        minPriceChangePercentage,
        index,
    }
}

/**
 * 将 36 个划分为8个
 */
export function handlePriceDistribution(data, options, seller) {
    const childrenLength = 5
    // 01 分组, 前面每5个分为1组, 最后一个单独为1组
    const keyArr = Object.keys(data)
    const groupKeyArr: string[][] = []
    // eslint-disable-next-line
    for (let i = 0; i < 8; i++) {
        /**
         * 第一次遍历 startIndex = 0   endIndex=5
         * 第二次遍历 startIndex = 5   endIndex=10
         * ...
         * 倒数第二次遍历  startIndex = 30    endIndex=35
         * 最后一次遍历    startIndex = 35    endIndex=40
         */
        const startIndex = i * childrenLength
        const endIndex = (i + 1) * childrenLength
        groupKeyArr.push(keyArr.slice(startIndex, endIndex))
    }

    const formatCount = (value, queryDateValid, options) => {
        let newValue = handleNumber(value, { s: true })
        newValue = getValueByQueryDateValid(newValue, queryDateValid)
        newValue = getValueByOptions(newValue, options)
        return newValue
    }

    const formatPercent = (value, queryDateValid, options) => {
        let newValue = handleNumber(value, { x: 100, p: true, d: 1 })
        newValue = getValueByQueryDateValid(newValue, queryDateValid)
        newValue = getValueByOptions(newValue, options)
        return newValue
    }

    const formatItem = (item: any) => {
        const { queryDateValid, queryComparisonDateValid } = seller

        const percentageCurrentValue = formatPercent(item.percentage?.current_value, queryDateValid, options)
        const percentageComparisonValue = formatPercent(item.percentage?.comparison_value, queryComparisonDateValid, options)
        const percentageChange = formatPercent(item.percentage?.change, queryComparisonDateValid, options)
        const percentageChangePercentage = formatPercent(item.percentage?.change_percentage, queryComparisonDateValid, options)

        const countCurrentValue = formatCount(item.virtual_count?.current_value, queryDateValid, options)
        const countComparisonValue = formatCount(item.virtual_count?.comparison_value, queryComparisonDateValid, options)
        const countChange = formatCount(item.virtual_count?.change, queryComparisonDateValid, options)
        const countChangePercentage = formatPercent(item.virtual_count?.change_percentage, queryComparisonDateValid, options)

        return {
            percentageCurrentValue,
            percentageComparisonValue,
            percentageChange,
            percentageChangePercentage,
            countCurrentValue,
            countComparisonValue,
            countChange,
            countChangePercentage,
            filteredProducts: item.filtered_products,
        }
    }

    // 02 计算总和
    const result = groupKeyArr.map(groupKey => {
        let key = groupKey[0]
        if (groupKey.length === childrenLength) {
            key = [ groupKey[0].split('-')[0], groupKey[groupKey.length - 1].split('-')[1] ].join('-')
        }
        const children = groupKey.map(k => {
            const item = data[k]
            return {
                ...item,
                apiName: k,
                name: handlePriceRange(k),
                vendor: seller.vendor,
                region: seller.region,
                ...formatItem(item),
            }
        })

        const initValue = {
            filtered_products: [],
            percentage: {
                current_value: 0,
                comparison_value: 0,
                change: 0,
                change_percentage: 0,
            },
            virtual_count: {
                current_value: 0,
                comparison_value: 0,
                change: 0,
                change_percentage: 0,
            },
        }
        const totalItem = children.reduce((curr, next) => {
            curr.percentage.current_value += next.percentage?.current_value
            curr.percentage.comparison_value += next.percentage?.comparison_value
            curr.percentage.change += next.percentage?.change
            curr.virtual_count.current_value += next.virtual_count?.current_value
            curr.virtual_count.comparison_value += next.virtual_count?.comparison_value
            curr.virtual_count.change += next.virtual_count?.change
            curr.filtered_products = [ ...curr.filtered_products, ...next.filtered_products ]
            return curr
        }, initValue)
        totalItem.percentage.change_percentage = totalItem.percentage.comparison_value === 0 ? '' : totalItem.percentage.change / totalItem.percentage.comparison_value
        totalItem.virtual_count.change_percentage = totalItem.virtual_count.comparison_value === 0 ? '' : totalItem.virtual_count.change / totalItem.virtual_count.comparison_value

        return {
            apiName: key,
            name: handlePriceRange(key),
            vendor: seller.vendor,
            region: seller.region,
            children,
            ...formatItem(totalItem),
        }
    })
    return result
}

/**
 * 接口返回的数据 150000-175000
 * 处理后返回的数据  150,000-175,000
 */
function handlePriceRange(value) {
    let arr = value.replace('inf', '').split('-')
    arr = arr.map(item => numberUtils.formatNumberByComma(item))
    if (arr.length === 1) return arr[0] + '-'
    return arr.join('-')
}
/**
 * 获取实际宽度
 * @returns 
 */
export const getActualWidth = () => {
    const headerEles = document.querySelectorAll('.price-architecture-header > div')

    if (headerEles) {
        let width = 0
        headerEles.forEach(ele => {
            width += ele.getBoundingClientRect().width
        })

        const pageEle = document.querySelector('.page-container')
        // 小于页面宽度
        if (pageEle && pageEle.getBoundingClientRect().width > width) {
            return 0
        }
        return width
    }

    return 0
}

export async function fetchAllVendorsData(payload: any) {
    const result: any = await Request.postFn(payload.url, payload)
    return result?.data || { price_ladders: [] }
}

export function getIntervalList(currency) {
    if (!currency) return []
    const currentRate = storage.getCurrentRate()
    const initIntervalList = [ '1', '5', '10', '30', '50', '100', '500' ]
    let newIntervalList = initIntervalList.map(item => {
        let rate = currentRate[currency] || 1
        if (rate < 1) { rate = 1 }
        else if (rate < 10) { rate = 10 }
        else if (rate < 100) { rate = 100 }
        else if (rate < 1000) { rate = 1000 }
        else if (rate < 10000) { rate = 10000 }
        else if (rate < 100000) { rate = 100000 }
        return parseInt(item, 10) * rate
    })
    newIntervalList = Array.from(new Set(newIntervalList))
    newIntervalList = newIntervalList.sort((a, b) => a - b)  // 升序 
    newIntervalList = newIntervalList.filter(item => item !== 1)
    return newIntervalList
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    switchValue = false,
    pageDate,
    comparisonDate,
    verticalExpanedKeys = [],
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const formatCurrentTitle = (vendor, region, date) => {
        return `data of ${vendor} ${region} at selected time period from ${dateRangeUtils.first(date)} to ${dateRangeUtils.last(date)}`
    }

    const formatComparisonTitle = (vendor, region, date) => {
        return `changes ${switchValue ? '' : 'in unit percentage'} of ${vendor} ${region} at comparison time period from ${dateRangeUtils.first(date)} to ${dateRangeUtils.last(date)}`
    }

    const getTitleArr = () => {
        return dataSource.map(item => {
            const vendorName = item.name
            return item?.regions?.map(item2 => {
                const region = item2.region
                return [
                    formatCurrentTitle(vendorName, region, pageDate),
                    formatComparisonTitle(vendorName, region, comparisonDate),
                ]
            })
        }).flat(10)
    }

    const titleRow = [
        'Price Range',
        ...getTitleArr(),
    ]
    data.push(titleRow)

    dataSource?.[0]?.regions?.[0]?.data?.forEach((item: any) => {
        const dataRow = [ item?.name ]
        dataSource?.forEach(vendorItem => {
            vendorItem?.regions?.forEach(regionItem => {
                const regionData = regionItem?.data?.find(d => d.name === item.name)
                dataRow.push(...[
                    switchValue ? regionData?.countCurrentValue?.replace(',', '') : regionData?.percentageCurrentValue,
                    switchValue ? regionData?.countChangePercentage?.replace(',', '') : regionData?.percentageChange,
                ])
            })
        })
        data.push(dataRow)

        if ((verticalExpanedKeys as any[])?.includes(item.name) && item?.children?.length) {
            item.children.forEach(item2 => {
                const dataRow = [ item2?.name ]
                dataSource?.forEach(vendorItem => {
                    vendorItem?.regions?.forEach(regionItem => {
                        const regionData = regionItem?.data?.find(d => d.name === item.name)?.children?.find(d => d.name === item2.name)
                        dataRow.push(...[
                            switchValue ? regionData?.countCurrentValue?.replace(',', '') : regionData?.percentageCurrentValue,
                            switchValue ? regionData?.countChangePercentage?.replace(',', '') : regionData?.percentageChange,
                        ])
                    })
                })
                data.push(dataRow)
            })
        }
    })

    data.push([ 'divide divide divide' ])

    const titleRow2 = [
        'Metric',
        ...getTitleArr(),
    ]
    data.push(titleRow2)

    data.push([
        'Product count',
        ...dataSource.map(item => {
            return item.regions.map(regionItem => {
                return [
                    regionItem?.totalCurrentValue?.replace(',', ''),
                    regionItem?.totalChangePercentage?.replace(',', ''),
                ]
            })
        }).flat(2),
    ])

    data.push([
        'Average price',
        ...dataSource.map(item => {
            return item.regions.map(regionItem => {
                return [
                    regionItem?.avgPriceCurrentValue?.replace(',', ''),
                    regionItem?.avgPriceChangePercentage?.replace(',', ''),
                ]
            })
        }).flat(2),
    ])

    data.push([
        'Median price',
        ...dataSource.map(item => {
            return item.regions.map(regionItem => {
                return [
                    regionItem?.medianPriceCurrentValue?.replace(',', ''),
                    regionItem?.medianPriceChangePercentage?.replace(',', ''),
                ]
            })
        }).flat(2),
    ])

    data.push([
        'Most frequent price',
        ...dataSource.map(item => {
            return item.regions.map(regionItem => {
                return [
                    regionItem?.frequentPriceCurrentValue?.replace(',', ''),
                    regionItem?.frequentPriceChangePercentage?.replace(',', ''),
                ]
            })
        }).flat(2),
    ])

    data.push([
        'Min price',
        ...dataSource.map(item => {
            return item.regions.map(regionItem => {
                return [
                    regionItem?.minPriceCurrentValue?.replace(',', ''),
                    regionItem?.minPriceChangePercentage?.replace(',', ''),
                ]
            })
        }).flat(2),
    ])

    data.push([
        'Max price',
        ...dataSource.map(item => {
            return item.regions.map(regionItem => {
                return [
                    regionItem?.maxPriceCurrentValue?.replace(',', ''),
                    regionItem?.maxPriceChangePercentage?.replace(',', ''),
                ]
            })
        }).flat(2),
    ])

    data.push([
        'Price index',
        ...dataSource.map(item => {
            return item.regions.map(regionItem => {
                return [
                    regionItem?.index?.replace(',', ''),
                    '',
                ]
            })
        }).flat(2),
    ])

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
