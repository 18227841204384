import React, { FC, useState, useEffect, useRef, useLayoutEffect } from 'react'
import classnames from 'classnames'
import { Spin } from 'componentsv2/Spin'
import { SizedBox } from 'componentsv2/SizedBox'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Button } from 'components/form-elements/Button'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { UseDialogType } from 'hooks/useDialog'
import { AnalysisIcon } from 'assets/icons'
import { IS_LIVE_ENV, PRODUCT_CARD_COLORS, PRODUCT_CARD_TYPE } from 'consts'
import { useReport } from 'hooks/useReport'
import { ReportProductModal, ReportProductModalV2 } from 'componentsv2/ReportProductModal'
import { lsGet } from 'utils/ls'
import { useSelector } from 'react-redux'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { useWindowZoom } from 'hooks/useWindow'
import productStyles from 'components/Product/styles.module.scss'
import { setMarginTopByPageContainerMarginTop } from 'componentsv2/MenuBar/utils'
import { useProductSizesDetailsData } from 'hooks/api/useProductSizesDetailsData'
import * as cssConfig from 'configs/css'
import { Descriptions } from './components/Descriptions'
import { ProductImageWrapper } from './components/ProductImage'
import { ProductTitle } from './components/ProductTitle'
import { ProductPriceRangeV2 } from './components/ProductPrice'
import { ProductDateForSizePrice } from './components/ProductDate'
import { ProductCardProps, ProductObj } from './types'
import { formatColorObj, getTitle, handleSizePriceData } from './utils'
import styles from './styles.module.scss'
import { ProductHeader } from './components/ProductHeader'
import { Performance } from './components/Performance'
import { DiscountLineChart } from './components/DiscountLineChart'
import { PriceLineChart } from './components/PriceLineChart'
import { FullPriceLineChart } from './components/FullPriceLineChart'
import { CategoryDetails } from './components/CategoryDetails'
import { ProductDetails } from './components/ProductDetails'
import { AvailableOptions } from './components/AvailableOptions'
import { NornaCategory } from './components/NornaCategory'
import { Title } from './components/Title'
import { AvailableSizes } from './components/AvailableSizes'
import { NornaSize } from './components/NornaSize'
import { SellerPerSize } from './components/SellerPerSize'
import { numberUtils } from 'norna-uikit'

export const SizePriceProductCard: FC<ProductCardProps> = ({
    nornaid,
    toCurrency,
    closeProductDetails,
    enabledQa = !IS_LIVE_ENV,
}) => {
    const zoom = useWindowZoom()
    const { notShowSubGroupsMap = {}, account = {} } = useSelector(getCacheData)
    // 当前登录的 license
    const customerSellerVendor = account?.company?.code
    // 最近一天产品有数据的日期
    const latestDate = lsGet('latestDate') || ''

    // 所有产品，ProductDto 接口返回的产品数据结构，格式：{'nornaid1': ProductDto, 'nornaid2': ProductDto}
    const [ productObj, setProductObj ] = useState<ProductObj>({})
    // 当前显示的产品 ProductVo 页面上展示的产品数据结构
    const [ product, setProduct ] = useState<any>()

    // 根据 nornaid 查询产品接口
    // const { data: productList, loading } = useFetchProductDetail(nornaid, false, '', toCurrency, enabledQa)
    const { data: productList, loading } = useProductSizesDetailsData({ nornaid })

    // 数据结构做处理
    useEffect(() => {
        if (!Array.isArray(productList) || productList.length === 0) {
            return
        }

        /**
         * 处理 Others
         * 接口返回的数据
         * [
         *      {'nornaid': '1', peer_products: { '1': {display_color: 'Yellow', color_percent: {'Yellow': 1}}, '2': {display_color: 'Others', color_percent: {'Others': 1}}} }},
         *      {'nornaid': '2', peer_products: { '1': {display_color: 'Yellow', color_percent: {'Yellow': 1}}, '2': {display_color: 'Blue', color_percent: {'Blue': 1}} }},
         * ]
         * 
         * 
         * 当前页面显示的 nornaid = '1'
         * 期待接口数据
         * [
         *      {'nornaid': '1', peer_products: { '1': {display_color: 'Yellow', color_percent: {'Yellow': 1}}, '2': {display_color: 'Blue', color_percent: {'Blue': 1}} },
         *      {'nornaid': '2', peer_products: { '1': {display_color: 'Yellow', color_percent: {'Yellow': 1}}, '2': {display_color: 'Blue', color_percent: {'Blue': 1}} },
         * ]
         */
        const productListHandledOthers = productList.map(item => {
            // 只处理当前页面显示的 nornaid
            if (item.nornaid === nornaid) {
                // nornaid = '1'
                // item.peer_products = { '1': {display_color: 'Yellow', color_percent: {'Yellow': 1}}, '2': {display_color: 'Others', color_percent: {'Others': 1}}} }}
                // Object.keys(item.peer_products) = ['1', '2']
                Object.keys(item.peer_products).forEach(peerNornaid => {
                    const selfColor = item.peer_products[peerNornaid].display_color
                    if (selfColor === 'Others') {
                        // 过滤操作, 获取其它 nornaid 列表
                        productList.filter(i => i.nornaid !== nornaid)
                            .some(i2 => {
                                // othersColorList = ['Blue']
                                const othersColor = i2.peer_products[peerNornaid]
                                if (othersColor && othersColor.display_color && othersColor.display_color !== 'Others') {
                                    item.peer_products[peerNornaid] = othersColor
                                    return true
                                } 
                                return false
                            })
                    }
                })
            }
            return item
        })

        /**
         * 2022/05/03
         * 接口返回的数据
         * [
         *      {'nornaid': 1, peer_products: {'1': {}, '2': {}, '3': {}}},
         *      {'nornaid': 2, peer_products: {'2': {}, '3': {}}},
         *      {'nornaid': 3, peer_products: {'2': {}, '3': {}}},
         * ]
         * 导致的问题就是第一次进入 Product Card 看到 Available options 有三个选项 (nornaid = 1, 对应的 peer_products 有三个)
         * 当切换到 nornaid = 2 时，会发现 Available options 只有两个选项了
         * 
         * 期望:
         * 所有 nornaid 的 peer_products 要一样，即求合集 (补齐)
         * [
         *      {'nornaid': 1, peer_products: {'1': {}, '2': {}, '3': {}}},
         *      {'nornaid': 2, peer_products: {'1': {}, '2': {}, '3': {}}},
         *      {'nornaid': 3, peer_products: {'1': {}, '2': {}, '3': {}}},
         * ]
         * 
         * 测试链接: bogner license + Germany + https://arcteryx.com/de/en/shop/mens/trino-sl-hoody?nornaVariation=3
         */
        const newProductList = productListHandledOthers.map(item => {
            Object.keys(item.peer_products).forEach(nornaid => {
                // 2022/07/05 新改动, 颜色值逻辑都在后端处理, 数据结构变为
                // {peer_products: {nornaid1: { display_color: 'Black', color_percent: {Black: 1} }}}
                const color = item.peer_products[nornaid].display_color
                item.peer_products[nornaid] = {
                    rgb_color: PRODUCT_CARD_COLORS[color] || color,
                    quantity: 1,
                    name: formatColorObj(item.peer_products[nornaid].color_percent),
                }
            })
            return item
        })
        const nornaidList = newProductList.map(item => item.nornaid)
        let peerProducts = {}
        newProductList.forEach(item => {
            peerProducts = { ...peerProducts, ...item.peer_products }
        })
        newProductList.forEach(item => {
            item.peer_products = peerProducts
        })

        // 接口返回的产品列表转对象数据结构
        const productObj = newProductList.reduce((res, current) => {
            if (current?.peer_products) {
                // delete peerProducts which nornaid could not fetch product data
                Object.keys(current?.peer_products).forEach(nornaid => {
                    if (!nornaidList.includes(nornaid)) {
                        delete current.peer_products[nornaid]
                    }
                })
            }
            res[current.nornaid] = current
            return res
        }, {})
        setProductObj(productObj)
        // 页面展示的产品数据结构
        const productVo = handleSizePriceData({
            productDto: newProductList[0], latestDate, productObj, notShowSubGroupsMap,
        })
        setProduct(productVo)
    }, [ productList, latestDate, notShowSubGroupsMap, customerSellerVendor, nornaid ])

    /* ********************************* Report Modal *********************************** */
    const [ reportModalVisible, setReportModalVisible ] = useState(false)
    const { enabledReport } = useReport()
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    /* ********************************* Flex *********************************** */

    useLayoutEffect(() => {
        setMarginTopByPageContainerMarginTop(productStyles.quickView)
    }, [])

    return (
        <Spin spinning={loading} className={styles.wrapper} style={{ justifyContent: 'center', overflowX: 'hidden' }}>
            <ProductHeader onClose={closeProductDetails} />
            <div className={classnames([ styles.body, 'norna-container-fixed-width' ])} onClick={e => e.stopPropagation()} data-type={PRODUCT_CARD_TYPE}>
                <ProductImageWrapper 
                    images={product?.images || []}
                    originalUrl={product?.originalUrl}
                    url={product?.url}
                    vendor={product?.vendor}
                    nornaid={nornaid}
                    enabledQa={enabledQa}
                />

                <SizedBox width={40 * zoom} />

                <div style={{ width: '500px' }}>
                    <Title>{getTitle(product?.urlState)}</Title>

                    <ProductTitle title={product?.title} url={product?.url} urlState={product?.urlState} vendor={product?.vendor || ''} />

                    <SizedBox height={30} />

                    <Descriptions column={2} style={{ gridTemplateColumns: '3fr 1.8fr', gridColumnGap: 0 }}>
                        <ProductPriceRangeV2
                            minPrice={numberUtils.formatNumber(product?.sizePriceRange?.actual_price?.[0], { isCommaSymbol: true, decimal: 2 }) || ''}
                            maxPrice={numberUtils.formatNumber(product?.sizePriceRange?.actual_price?.[1], { isCommaSymbol: true, decimal: 2 }) || ''}
                            currency={product?.currency}
                            label="sales price range for sizes"
                        />
                        <Descriptions.Item 
                            label="average sales price of option" 
                            value={product?.averagePriceOption ? `${product?.averagePriceOption} ${product?.currency}` : '-'} 
                            valueFontSize={22}
                        />
                        <ProductPriceRangeV2
                            minPrice={numberUtils.formatNumber(product?.sizePriceRange?.original_price?.[0], { isCommaSymbol: true, decimal: 2 }) || ''}
                            maxPrice={numberUtils.formatNumber(product?.sizePriceRange?.original_price?.[1], { isCommaSymbol: true, decimal: 2 }) || ''}
                            currency={product?.currency}
                            label="full price range for sizes"
                        />
                        <Descriptions.Item 
                            label="average full price of option" 
                            value={product?.averageFullPrice ? `${product?.averageFullPrice} ${product?.currency}` : '-'} 
                            valueFontSize={22}
                        />
                        <ProductPriceRangeV2
                            minPrice={numberUtils.formatNumber(product?.sizePriceRange?.first_observed_original_price?.[0], { isCommaSymbol: true, decimal: 2 }) || ''}
                            maxPrice={numberUtils.formatNumber(product?.sizePriceRange?.first_observed_original_price?.[1], { isCommaSymbol: true, decimal: 2 }) || ''}
                            currency={product?.currency}
                            label="First observed full price range for sizes"
                        />
                        <Descriptions.Item 
                            label="Discount" 
                            value={product?.discount}
                            valueFontSize={22}
                            valueStyle={{ color: cssConfig.primaryColor }}
                        />
                    </Descriptions>

                    <ProductDateForSizePrice 
                        lastRelaunchDate={product?.lastRelaunchDate}
                        firstObservedDate={product?.firstObservedDate}
                        firstDiscountDate={product?.firstDiscountDate}
                    />

                    <Descriptions column={2}>
                        <AvailableOptions
                            colorList={product?.colorList || []}
                            onClick={nornaid => {
                                if (!nornaid || Object.keys(productObj || {}).length === 0) return
                                // 页面展示的产品数据结构
                                const productVo = handleSizePriceData({
                                    productDto: productObj[nornaid], 
                                    latestDate, 
                                    productObj, 
                                    notShowSubGroupsMap,
                                })
                                setProduct(productVo)
                            }}
                            bottom={20}
                        />
                    </Descriptions>

                    <ProductDetails 
                        targetGroup={product?.targetGroup}
                        propertyList={product?.propertyList}
                        materialObj={product?.materialObj || {}}
                        colorList={product?.colorList || []}
                        sizeList={product?.sizePriceList || []}
                    />

                    <CategoryDetails 
                        mainCategory={product?.mainCategory}
                        subCategory={product?.subCategory}
                        bottom={20}
                    />

                    <NornaCategory 
                        nornaCategory={product?.nornaCategory}
                        nornaCategoryTypeObj={product?.nornaCategoryTypeObj}
                        bottom={20}
                    />

                    <NornaSize 
                        nornaSize={product?.nornaSize}
                        bottom={20}
                    />

                    <AvailableSizes 
                        sizePriceList={product?.sizePriceList || []}
                        totalSizePrice={product?.totalSizePrice || {}}
                        currency={product?.currency || ''}
                        bottom={20}
                    />

                    <SellerPerSize 
                        sizePriceList={product?.sellerPerSize || []}
                        bottom={40}
                    />

                    <div className={classnames([ styles.reportProduct, 'flex flex-row' ])}>
                        <Button
                            icon={<AnalysisIcon />} primary
                            onClick={() => setReportModalVisible(true)}
                            className="margin-right-10"
                        >
                            Report Product
                        </Button>
                        <ReportProductModal isOpen={reportModalVisible} closeModal={setReportModalVisible} productUrl={product?.originalUrl || ''} />
                        <ShouldRender shouldRender={enabledReport}>
                            <Button
                                icon={<AnalysisIcon />}
                                primary
                                onClick={e => {
                                    const closeFn = dialogRef.current?.closeDialog
                                    dialogRef.current.openDialog('report tool',
                                        <ReportProductModalV2 
                                            offset={{ x: - 240, y: 0 }} 
                                            isOpen={true} 
                                            closeModal={closeFn} 
                                            productUrl={product?.originalUrl || ''} 
                                            products={[ 
                                                { nornaid: product?.nornaid || '', vendor: product?.vendor || '' }, 
                                            ]}
                                        />,
                                        UseDialogType.REPORT_DIALOG,
                                    )
                                }}
                            >
                                Report Tool
                            </Button>
                        </ShouldRender>
                        <Dialog ref={dialogRef} />
                    </div>
                </div>

                <SizedBox width={60 * zoom} />

                <div style={{ width: '480px' }}>
                    <PriceLineChart 
                        dataSource={product?.priceHistoryList || []} 
                    />
                    <SizedBox height={40} />
                    <FullPriceLineChart
                        dataSource={product?.fullPriceHistoryList || []} 
                    />
                    <SizedBox height={40} />
                    <DiscountLineChart 
                        dataSource={product?.discountHistoryList || []} 
                    />                    
                    <SizedBox height={40} />
                    <Performance
                        averagePrice={product?.averagePrice}
                        discountDepth={product?.discountDepth}
                        nonzeroDiscountDepth={product?.nonzeroDiscountDepth}
                        daysObserved={product?.daysObserved}
                    />
                </div>
            </div>
        </Spin>
    )
}
