import { FILTER_DIALOG_CLASS } from 'consts'
import { TdPriceRangeTypeEnum } from 'graphql/nornaapi/types'
import React, { CSSProperties, useState } from 'react'
import { PriceFilterSelectionModalV2 } from '../../../PriceFilter/PriceFilterSelectionModalV2'
import { TdPriceFilterSelectionModal } from '../../../PriceFilter/TdPriceFilterSelectionModal'

export type PriceFilterProps = {
    onChange?: Function;
    closeFn?: Function;
    initValue: string;
    isTd?: boolean;
    type:TdPriceRangeTypeEnum,
    modalMaskStyle?: CSSProperties;
};
export const PriceFilter = React.forwardRef(({ 
    type=TdPriceRangeTypeEnum.TD,
    onChange, initValue, closeFn, modalMaskStyle = {},isTd=false }: PriceFilterProps, ref) => {
    const [ currentFilter, setCurrentFiler ] = useState(initValue)
    if(isTd){
        return (
            <div className="material-filter">
                <TdPriceFilterSelectionModal
                    type={type}
                    closeModal={() => closeFn?.()}
                    currentFilter={currentFilter}
                    onChange={(filter:any) => {
                        onChange?.(filter)
                        setCurrentFiler(filter)
                    }}
                    isOpen={true}
                    modalMaskStyle={modalMaskStyle}
                />
            </div>
        )
    }
    return (
        <div className={`material-filter ${FILTER_DIALOG_CLASS}`}>
            <PriceFilterSelectionModalV2
                closeModal={() => closeFn?.()}
                currentFilter={currentFilter}
                onChange={(filter:any) => {
                    onChange?.(filter)
                    setCurrentFiler(filter)
                }}
                isOpen={true}
                modalMaskStyle={modalMaskStyle}
            />
        </div>
    )
})
