import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './Row.module.scss'
import RowSizeItem from './RowSizeItem';
import { cloneDeep } from 'lodash';
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog';
import { ProductDetailsModal } from 'components/Product/components/ProductDetailsModal';
import { UseDialogType } from 'hooks/useDialog';
import { useFilterCurrency } from 'hooks/useFilter';
import productStyles from 'components/Product/styles.module.scss'
import { useInView } from 'react-intersection-observer'

const Row = ({
    rowData,
    onChange,
}: {
    rowData: any;
    onChange?: (rowData: any) => void,
}) => {
    const { ref, inView } = useInView({
        threshold: 0,
    })

    const rowDataRef = useRef<any>(rowData)
    const [ , setRefresh ] = useState({})

    useEffect(() => {
        rowDataRef.current = rowData
        setRefresh({})
    }, [ rowData ])

    const onChangeSize= (fromSize, toSize) => {
        const data = cloneDeep(rowDataRef.current)
        data.sizes.forEach(item => {
            if (item.size === fromSize) {
                item.value = toSize
            }
        })
        rowDataRef.current = cloneDeep(data)
        setRefresh({})
        onChange?.(cloneDeep(data))
    }

    /* ******************************** product detail modal *************************************** */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    const [ currency ] = useFilterCurrency()

    // 显示产品详情模态框
    const onShowProductDetailModal = useCallback((e, nornaid, qaPage) => {
        e.stopPropagation()
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog?.('Product detail', (
        <div className={productStyles.quickView}>
            <ProductDetailsModal
                nornaid={nornaid}
                closeProductDetails={closeFn}
                toCurrency={currency}
                qaPage={qaPage}
            />
        </div>
        ), UseDialogType.PROUDUCT_DETAIL)
    }, [ dialogRef, currency ])

    return (
        <div 
            className={styles.container} 
            style={{ height: 32 + (rowDataRef.current?.sizes?.length || 0) * 40 }}
            ref={ref}
        >
            {
                inView ? (
                    <>
                        <div className={styles.title}>
                            <div>Region: {rowDataRef.current.region}</div>
                            <div>Gender: {rowDataRef.current.gender}</div>
                            <div>Category: {rowDataRef.current.parent} - {rowDataRef.current.category}</div>
                        </div>
                        {
                            rowDataRef.current?.sizes?.map(sizeItem => {
                                return (
                                    <div
                                        key={sizeItem.size}
                                        className={styles.sizeContainer}
                                    >
                                        <div className={styles.sizeAi}>
                                            Size: {sizeItem.size}
                                        </div>
                                        <RowSizeItem
                                            className={styles.sizeTagContainer}
                                            value={sizeItem.value || sizeItem.to_value}
                                            onChange={(toVlaue) => onChangeSize?.(sizeItem.size, toVlaue)}
                                        />
                                        {
                                            sizeItem.sample_product ? (
                                                <div 
                                                    className={styles.productLink}
                                                    onClick={(e) => onShowProductDetailModal(e, sizeItem.sample_product, sizeItem.qa)}
                                                >
                                                    Product { sizeItem?.qa ? '(In gate)' : '' }
                                                </div>
                                            ) : null
                                        }
                                        <div className={styles.time}>
                                            {sizeItem.modified_at?.slice(0, 19)?.replace('T', ' ')}
                                        </div>
                                        <div className={styles.time}>
                                            {sizeItem.modified_by?.replace('@norna.ai', '')}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <Dialog ref={dialogRef} />
                    </>
                ) : null
            }
        </div>
    )
}

export default Row
