import useDeepCompareEffect from 'use-deep-compare-effect'
import { fetchProducts } from 'graphql/nornaapi'
import { selectCollection } from 'features/filters/filtersSlice'
import { useSelector } from 'react-redux'
import { useFetch } from 'libs/hookRequest'

/**
 * 
 * @param param
 * param.enableCache enable cache or not 
 * @returns 
 */
export const useFetchProducts = ({ date = '', ids, currency = '', enableCache = false }) => {
  const { postFn: fetch, data, error, loading } = useFetch()
  const comparisonQuery = useSelector(selectCollection)

  useDeepCompareEffect(() => {
    if (!ids?.length) return
    const payload = fetchProducts(comparisonQuery, date, ids, currency)
    fetch(payload.url, payload)
  }, [ date, comparisonQuery, ids ])

  return {
    data,
    fetch,
    refetch(ids, currency) {
      if (!ids?.length) return

      const payload = fetchProducts(comparisonQuery, date, ids, currency)
      fetch(payload.url, payload)
    },
    error,
    loading,
  }
}
