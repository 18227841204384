import React, { FC, CSSProperties, memo, useState, useEffect } from 'react'
import classnames from 'classnames'
import { CheckIcon } from 'assets/icons/Check'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Box } from 'componentsv2/Box'
import { CheckboxProps } from './types'
import styles from './styles.module.scss'

/**
 * ## Checkbox
 * 
 * 复选框。
 * 
 * ## 代码演示
 * 
 * ### 基础使用
 * 
 * ```
 * <Checkbox />
 * ```
 * 
 * ### 设置复选框初始值以及监听值变化
 * 
 * ```
 * const [checked, setChecked] = useState(true)
 * 
 * <Checkbox value={checked} onChange={value => setChecked(value)}/>
 * ```
 * 
 * ### 定义 checkbox 最左边方框样式
 * 
 * ```
 * // 此时不显示方框，可以当做列表项使用
 * <Checkbox boxVisible={false} />
 * 
 * // boxBg 表示方框背景颜色
 * // boxColor 表示方框内对勾颜色
 * <Checkbox boxBg="" boxColor="" />
 * ```
 */
export const Checkbox: FC<CheckboxProps> = memo(({
    children,               // 子元素
    style = {},             // 样式对象
    labelStyle = {},
    boxStyle = {},
    className = '',         // 类名
    left,                   // margin-left 属性值
    right,                  // margin-right 属性值
    disabled = false,       // 是否禁用
    value = false,          // 是否为选中状态
    onChange,               // 点击Checkbox回调函数
    boxVisible = true,      // checkbox 最左边方框是否可见，当值为 false 时不显示方框，此时可以作为一个列表项使用
    boxBg = '#eeeeee',      // checkbox 最左边方框背景颜色
    boxColor = '#999999',   // checkbox 最左边方框内对勾颜色
    boxOnRight = false,
}) => {
    // 下拉框 (select) 样式
    const checkboxStyle: CSSProperties = {}
    if (typeof left !== 'undefined') {
        checkboxStyle.marginLeft = left + 'px'
    }
    if (typeof right !== 'undefined') {
        checkboxStyle.marginRight = right + 'px'
    }

    const [ checkboxValue, setCheckboxValue ] = useState(value)

    useEffect(() => {
        setCheckboxValue(value)
    }, [ value ])

    return (
        <div
            style={{ ...checkboxStyle, ...style }}
            className={classnames([ className, styles.checkbox, disabled && styles.checkboxDisabled ])}
            onClick={() => {
                if (disabled) return
                setCheckboxValue(!checkboxValue)
                onChange?.(!checkboxValue)
            }}
        >
            {
                boxOnRight === true ? (
                    <>
                        <div className={styles.label} style={labelStyle}>
                            {children}
                        </div>
                        <Box width={6} />
                        <ShouldRender shouldRender={boxVisible}>
                            <div className={styles.box} style={{ backgroundColor: boxBg, ...boxStyle }}>
                                <ShouldRender shouldRender={checkboxValue}>
                                    <CheckIcon color={boxColor} />
                                </ShouldRender>
                            </div>
                        </ShouldRender>
                    </>
                ) : (
                    <>
                        <ShouldRender shouldRender={boxVisible}>
                            <div className={styles.box} style={{ backgroundColor: boxBg, ...boxStyle }}>
                                <ShouldRender shouldRender={checkboxValue}>
                                    <CheckIcon color={boxColor} />
                                </ShouldRender>
                            </div>
                        </ShouldRender>
                        <div className={styles.label} style={labelStyle}>
                            {children}
                        </div>
                    </>
                )
            }
        </div>
    )
})

Checkbox.displayName = 'Checkbox'
