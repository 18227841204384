import React, { ReactNode, useRef, useState } from 'react'
import classnames from 'classnames'
import { uniq } from 'lodash'
import { TreeOption } from '../TreeOption'
import styles from './styles.module.scss'
import { intersection } from 'utils/array'

export interface OptionItem {
    label: string;
    labelClassName?: string;
    value?: string;
    disabled?: boolean;
    checked?: boolean;
    children?: OptionItem[];
}

interface TreeItemProps {
    itemData: OptionItem;
    value: string[];
    onClick: (itemData: OptionItem) => void;
    multiple?: boolean;
    bordered?: boolean;
    minCount?: number;
    maxCount?: number;
    options: OptionItem[];
    level1MaxCount?: number;
}

export const TreeItem = ({
    itemData,
    value=[],
    onClick,
    multiple = false,
    bordered = false,
    minCount = 0,
    maxCount = 9999,
    level1MaxCount,
    options = [],
}: TreeItemProps) => {
    const isParent = Boolean(Array.isArray(itemData?.children) && itemData?.children?.length)
    const childrenVisibleRef = useRef<boolean>(false)
    const positionRef = useRef<{ left: number; top: number}>({ left: 0, top: 0 })
    const [ , setRefresh ] = useState({})

    const treeItemChildrenCls = classnames({
        [styles.treeItemChildren]: true,
        [styles.border]: bordered,
    })

    let disableClick = false
    if (level1MaxCount !== 1 && value.length === minCount && value.includes(itemData.value || '')) {
        disableClick = true
    }
    if (level1MaxCount !== 1 && value.length === maxCount && !value.includes(itemData.value || '')) {
        disableClick = true
    }
    if (level1MaxCount !== 1 && level1MaxCount && getFirstLevelValues(options, value)?.length === level1MaxCount && !getAllValues(options, value).includes(itemData.value || '')) {
        disableClick = true
    }

    let childrenEl: ReactNode = null
    if (isParent) {
        childrenEl = itemData?.children?.map(item2 => {
            const isDisabled = value.length >= 12 && !value.includes(item2?.value || '')
            const isParent = Array.isArray(item2?.children) && !!item2?.children?.length

            return (
                <div className={styles.treeItem} key={item2.value}>
                    <TreeOption
                        label={item2.label}
                        value={getValue(item2, value || [])}
                        onChange={checked => {
                            onClick(item2)
                        }}
                        disabled={isDisabled}
                        disabledClick={(value.length === minCount && value.includes(item2.value || '')) || (value.length === maxCount && !value.includes(item2.value || ''))}
                        checkboxVisible={multiple && !isParent}
                        arrowVisible={isParent}
                    />
                </div>
            )
        })
    }

    return (
        <>
            <div
                className={styles.treeItem}
                onMouseEnter={e => {
                    if (!isParent) return
                    const rect = e.currentTarget.getBoundingClientRect()
                    childrenVisibleRef.current = true
                    positionRef.current = { left: rect.right + 1, top: rect.top - 9 }
                    setRefresh({})
                }}
                onMouseLeave={e => {
                    if (!isParent) return
                    childrenVisibleRef.current = false
                    setRefresh({})
                }}
            >
                <TreeOption
                    label={itemData.label}
                    value={getValue(itemData, value)}
                    onChange={() => {
                        onClick(itemData)
                    }}
                    disabled={value.length >= 12 && !value.includes(itemData?.value || '')}
                    disabledClick={disableClick}
                    checkboxVisible={multiple}
                    arrowVisible={false}
                    active={getActive(itemData, value)}
                />
                {
                    isParent && childrenVisibleRef.current ? (
                        <div 
                            className={treeItemChildrenCls} 
                            style={{ 
                                position: 'fixed',
                                left: positionRef.current.left + 'px',  
                                top: positionRef.current.top + 'px',
                                zIndex: 99999,
                            }}
                        >
                            {childrenEl}
                        </div>
                    ) : null
                }
            </div>
        </>
    )
}

function getValue(optionItem: OptionItem, selectedKeys: string[] = []) {
    return selectedKeys.includes(optionItem.value || '')
}

function getActive(optionItem, selectedKeys) {
    return !!intersection((optionItem?.children || [])?.map(item => item.value), selectedKeys)?.length
}

function getFirstLevelValues(options, value) {
    let data: string[] = []
    options.forEach(l1 => {
        if (l1?.children?.length) {
            const childrenValue = l1.children?.filter(l2 => value.includes(l2.value))
            if (childrenValue.length === l1.children.length) {
                data.push(l1.value)
            } else {
                data.push(...childrenValue)
            }
        } else {
            if (value.includes(l1.value)) {
                data.push(l1.value)
            }
        }
    })
    data = data.filter(d => d)
    return uniq(data)
}

function getAllValues(options, value) {
    let data: string[] = []
    options.forEach(l1 => {
        if (l1?.children?.length) {
            const childrenValue = l1.children?.filter(l2 => value.includes(l2.value))
            if (childrenValue.length === l1.children.length) {
                data.push(l1.value)
            }
            data.push(...childrenValue)
        } else {
            if (value.includes(l1.value)) {
                data.push(l1.value)
            }
        }
    })
    data = data.filter(d => d)
    return uniq(data)
}
