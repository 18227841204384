import React, { memo } from 'react'
import { DropdownOptionProps } from 'componentsv2/form-elements/Dropdown'
import { useFilterGender } from 'hooks/useFilter'
import { loadingBar } from 'hooks/useLoadingBar'
import { getGenderOptions } from 'utils/optionsUtils'
import { GenderDropdownProps } from './types'
import { TreeDropdown } from 'componentsv2/TreeDropdown'

export const GenderDropdown = memo(({
    value,
    onChange,
    left,
    right,
    style,
    className,
    label='Gender',
    enableFilter = false,
    enableLoadingBar = false,
    disabled = false,
}: GenderDropdownProps) => {
    const [ filterGender, setFilterGender ] = useFilterGender()
    const genderOptions = getGenderOptions()

    return (
        <TreeDropdown
            multiple
            options={genderOptions}
            style={style}
            className={className}
            label={label}
            placeholder="Gender"
            left={left}
            right={right}
            minCount={1}
            disabled={disabled}
            value={value || filterGender || genderOptions.filter(g => !g.isDisabled).map(k => k.key)}
            onOk={value => {
                if (enableFilter) setFilterGender(value)
                const selectedOption = genderOptions.filter(item => value.includes(item.key)) as DropdownOptionProps[]
                onChange?.(value, selectedOption, genderOptions)
                if (enableLoadingBar) loadingBar.restart()
            }}
        />
    )
})

GenderDropdown.displayName = 'GenderDropdown'
