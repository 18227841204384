import React, { FC, CSSProperties } from 'react'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import { handleLeftAndRight } from 'utils'
import { SwitchProps } from './types'
import styles from './styles.module.scss'

/**
 * ## Switch
 * 
 * 开关组件。
 * 
 * ## 示例
 * 
 * ### 基础使用
 * 
 * ```
 * <Switch />
 * ```
 * 
 * ### 设置 Switch 值：value、onChange
 * 
 * onChange 事件最好使用 useCallback 包裹，这样当前页面只要传递给 Switch 组件的值未变化，Switch 组件就不会重新渲染
 * 
 * ```
 * const [checked, setChecked] = useState(false)
 * 
 * <Switch 
 *      value={checked}
 *      onChange={useCallback(c => setChecked(c), [])}
 * />
 * ```
 * 
 * ### 设置左右标签：leftLabel、rightLabel
 * 
 * ```
 * <Switch 
 *      leftLabel="%"
 *      rightLabel="Units"
 * />
 * ```
 * 
 * ### 禁用开关：disabled
 * 
 * ```
 * <Switch disabled />
 * ```
 * 
 * ### 设置 Switch 与左右组件间距：left、right
 * 
 * left 是 marginLeft 的别名
 * 
 * right 是 marginRight 的别名
 * 
 * ```
 * <Box />
 * <Siwtch left={10} right={10} />
 * <Box />
 * ```
 */
export const Switch: FC<SwitchProps> = ({
    className,              // Switch className 类名
    style = {},             // Switch style 
    switchWrapperStyle={},
    left,                   // Switch marginLeft 值
    right,                  // Switch marginRight 值
    bottom,                 // Switch marginBottom 值
    leftLabel,              // Switch 左边标签文案
    label='',                  // Switch top label文案
    rightLabel,             // Switch 右边标签文案
    disabled,               // 是否禁用
    value,                  // 是否选中，true 选中，false 取消选中
    onChange,               // 改变 Switch 值触发事件
}) => {
    // Switch style
    const switchStyle: CSSProperties = {
        position: 'relative',
    }

    if (disabled) {
        switchStyle.opacity = .4
    }

    left = handleLeftAndRight(left)
    right = handleLeftAndRight(right)
    
    if (typeof left !== 'undefined') {
        switchStyle.marginLeft = typeof left === 'number' ? left + 'px' : left
    }

    if (typeof right !== 'undefined') {
        switchStyle.marginRight = typeof right === 'number' ? right + 'px' : right
    }

    if (typeof bottom !== 'undefined') {
        switchStyle.marginBottom = typeof bottom === 'number' ? bottom + 'px' : bottom
    }

    // Label style
    const labelStyle: CSSProperties = {
        fontSize: '14px',
        fontFamily: 'Nunito Sans',
    }

    const onSwitch = checked => {
        if (disabled) return
        onChange?.(checked)
    }

    return (
        <Typography component="div" className={className} style={{ ...switchStyle, ...style }}>
             {label?<div className={styles.label}>{label}</div>:null}
            <div className={styles.switchWrapper} style={switchWrapperStyle}>
                <Grid component="label" container alignItems="center">
                    <Grid item style={{ padding: 0, marginRight: '4px' }} onClick={() => 0}>
                        <span className={!value ? styles.activeItem : styles.item} style={{ ...labelStyle }}>{leftLabel}</span>
                    </Grid>
                    <Grid item>
                        <div className={styles.switch} onClick={() => onSwitch(!value)}>
                            <div
                                className={classnames([ 'switch-handle', styles.handle ])} 
                                style={{ transform: `translate(${value ? 17 : 0}px)`, transition: 'transform .3s' }}
                            />
                        </div>
                    </Grid>
                    <Grid item style={{ padding: 0, marginLeft: '4px'  }} onClick={() => 0}>
                        <span className={value ? styles.activeItem : styles.item} style={{ ...labelStyle }}>{rightLabel}</span>
                    </Grid>
                </Grid>
            </div>
        </Typography>
    )
}

Switch.displayName = 'Switch'
