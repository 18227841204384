/**
* @file    useLogin
* @date   2022-03-30
* @author byte_su@163.com
*/

import { setCurrentUser } from 'features/filters/filtersSlice'
import AuthToken from 'utils/AuthToken'
import { lsPureSet, setLicenseId } from 'utils/ls'

/** *
 * # Account Login Hooks
 */
export const useLogin = () => ({
        /**
         * ## Applicable scenario
         * 
         * 1. sigin
         * 2. switch license
         * 3. refresh page
         * 
         * @param accessToken 
         * @param email 
         * @param companyId 
         * @param accountId 
         */
        initNewUser: (accessToken: string | null, email: string, companyId: string, accountId: string) => {
            //= ============================= set licenseId  ===========================
            setLicenseId(companyId)

            accessToken && AuthToken.setAccessToken(accessToken)
            AuthToken.setEmail(email)

            setCurrentUser(`${companyId}/${accountId}`)

            // for hotjar
            lsPureSet('email', email)
        },
    })

useLogin.displayName = 'useLogin'
