import React, { memo, ReactNode, useState } from 'react'
import {
  updateFilterForGate,
  updateFilterGender,
} from 'features/filters/filtersSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { usePageDate } from 'hooks/usePageDate'
import { useLoadingBar, useTestEnvAndQaPriv } from 'hooks'
import { SizedBox } from 'componentsv2/SizedBox'
import { Switch } from 'componentsv2/form-elements/Switch'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { OpenInNewButton, HideWhenOpenInNew, ShowWhenOpenInNew, OPEN_IN_NEW_DATE } from 'componentsv2/business/OpenInNewButton'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { getQaData, setQaPage } from 'features/filters/qaDataSlice'
import { useClearFilter } from 'hooks/useClearFilter'
import { cloneDeep } from 'lodash'
import { downloadFileByAxios } from 'export'
import { sortVendorList } from 'utils/array'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { ALL_COUNTRIES, CATEGORY_TOTAL, IS_LIVE_ENV, MARKET_VENDOR } from 'consts'
import { useCompetitorOptions } from 'hooks/useOptions'
import { searchForOptionsExportFilename } from 'utils/exportUtils'
import { SEARCH_FOR_OPTIONS_NAME } from 'configs'
import { useModuleData } from 'hooks/useModuleData'
import { getFilterGenderValueForGate } from 'components/layout/Header/components/HeaderFilter/gender.util'
import { useFilterProperty } from 'hooks/useFilter'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { PageCompetitor } from './components/PageCompetitor'
import styles from './styles.module.scss'
import { getActualWidth } from './utils'
import GraphButton from 'componentsv2/GraphButton'

export const SearchForOptions = memo(() => {
  // 从其它页面跳转到该页面时，滚动条默认恢复原位
  useScrollToTop()
  useLoadingBar()
  const cacheData = useSelector(getCacheData)

  const dispatch = useDispatch()
  // Used only for passing param when selecting a gate
  const { competitorOptions } = useCompetitorOptions()
  const [ filterProperty ] = useFilterProperty()

  const [ moduleData, setModuleData ] = useModuleData(SEARCH_FOR_OPTIONS_NAME)
  const { showOldProducts = false, multiGender = false, multiMetaCategory = false, inputSize = '', historicalSizeFilter = [], isGroupByProperty = false } = moduleData

  /**
   * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/247
   * showOldProducts 默认值为 false，查询当前存在的产品
   * showOldProducts 值为 true 时，查询当前不存在的产品
   */
  const setShowOldProducts = (value: boolean) => {
    setModuleData({ showOldProducts: value })
  }

  const setIsGroupByProperty = (value: boolean) => {
    setModuleData({ isGroupByProperty: value })
  }

  const setInputSize = (value: string) => {
    setModuleData({ inputSize: value })
  }

  useDeepCompareEffect(() => {
    if (Object.keys(filterProperty || {}).length !== 1 && isGroupByProperty) {
      setIsGroupByProperty(false)
    }
  }, [ filterProperty ])

  // Switch 只在 Test 环境并且有 Qa 权限时才显示
  const testEnvAndQaPriv = useTestEnvAndQaPriv()
  
  const qaData = useSelector(getQaData)

  // 处理 pageDate
  const { pageDate } = usePageDate()

  const { clearFilter } = useClearFilter()
  const contentElement: ReactNode = (
    <PageCompetitor 
      date={pageDate} 
      showOldProducts={showOldProducts} 
      historicalSizeFilter={historicalSizeFilter} 
      multiGender={multiGender} 
      multiMetaCategory={multiMetaCategory} 
      onFetchPayloadChange={payload => setFetchPayload(payload)} 
    />
  )

  /* ************************************ 导出文件 ******************************************* */
  const [ exportLoading, setExportLoading ] = useState(false)
  const [ fetchPayload, setFetchPayload ] = useState<any>({})

  const onExportExcel = async () => {
    setExportLoading(true)
    const payload = cloneDeep(fetchPayload)
    if (Array.isArray(payload?.data?.competitor_sellers)) {
      payload.data.competitor_sellers = sortVendorList({
        vendorList: payload.data.competitor_sellers,
        vendorField: 'vendor',
      })
    }
    await downloadFileByAxios({
      filename: searchForOptionsExportFilename,
      payload,
    })
    setExportLoading(false)
  }

  return (
    <div className={styles.container} id="export-wrapper" >
      <ShowWhenOpenInNew>
        <FilterInfo />
      </ShowWhenOpenInNew>
      <ModuleTitle category="SEARCH" title={SEARCH_FOR_OPTIONS_NAME} showDate top={0} left={40} style={{ paddingTop: 20 }} />
      <SizedBox height={10} />
      <HideWhenOpenInNew>
        <div className={styles.form}>
          <ShouldRender shouldRender={testEnvAndQaPriv}>
            <Switch
              left={10}
              value={showOldProducts}
              onChange={checked => setShowOldProducts(checked)}
              rightLabel="Old products"
            />
            {
              IS_LIVE_ENV ? null : (
                <Switch
                  left={10}
                  value={multiGender}
                  onChange={checked => setModuleData({ multiGender: checked })}
                  rightLabel="Multi gender"
                />
              )
            }
            <Switch
              left={10}
              value={multiMetaCategory}
              onChange={checked => setModuleData({ multiMetaCategory: checked })}
              rightLabel="Multi meta"
            />
            <Switch
              left={10}
              value={qaData?.qaPage}
              onChange={checked => {
                dispatch(setQaPage(checked))
                clearFilter()
                dispatch(updateFilterGender(getFilterGenderValueForGate(checked)))
                /**
                 * When switch to Gate
                 * all categories + all countries + all competitor
                 */
                if (checked) {
                  const competitor = competitorOptions
                    .map(item => ({ vendor: item.key, region: item.region }))
                  const market = competitorOptions
                    .filter(item => ![ MARKET_VENDOR.vendor ].includes(item.vendor))
                    .map(item => ({ vendor: item.vendor, region: ALL_COUNTRIES }))
                  dispatch(updateFilterForGate({
                    category: [ CATEGORY_TOTAL ],
                    country: ALL_COUNTRIES,
                    currency: cacheData.currencys[0],
                    competitor,
                    market,
                  }))
                }
              }}
              rightLabel="Gate"
            />
            <Switch 
              left={10}
              rightLabel="Group by property"
              disabled={Object.keys(filterProperty || {})?.length !== 1}
              value={isGroupByProperty}
              onChange={checked => {
                setIsGroupByProperty(checked)
              }}
              right={10}
            />
            <input
              placeholder="input sizes"
              value={inputSize}
              onChange={e => {
                setInputSize(e.target.value || '')
              }}
              style={{
                width: 140,
                height: 36,
                border: '1px solid #666',
                borderRadius: '18px 0 0 18px',
                paddingLeft: 10,
              }}
            />
            <button 
              onClick={() => {
                setModuleData({ historicalSizeFilter: inputSize.split(',').map(item => item?.trim())?.filter(item => item) })
              }}
              style={{
                height: 36,
                border: '1px solid #666',
                borderLeft: 'none',
                borderRadius: '0 18px 18px 0',
                paddingLeft: 4,
                paddingRight: 4,
              }}
            >
              Search
            </button>
          </ShouldRender>
          <ExportDropdown
            scale={1}
            imgsSeletor=".scale_121 img"
            enableForceLoadImg
            // beforeFn={useCallback(() => loadingBar.continuousStart(), [loadingBar])}
            // afterFn={useCallback(() => loadingBar.complete(), [loadingBar])}
            calcJpgWidthFn={getActualWidth}
            adjustElementFn={ele => {
              const width = getActualWidth()
              if (width !== 0) {
                const headerEle: HTMLDivElement = ele.querySelector('.searchForOptionsContent') as HTMLDivElement
                const wrapper: HTMLDivElement = ele.querySelector('#export-wrapper') as HTMLDivElement
                if (headerEle) headerEle.style.width = `${width}px`
                if (wrapper) wrapper.style.width = `${width}px`
              }
            }}
            onExportExcel={onExportExcel}
            fileName={searchForOptionsExportFilename}
            left={10}
            right
            loading={exportLoading}
          />
          <GraphButton />
          <OpenInNewButton
            left={10}
            pageParams={{
              pageDate,
              [OPEN_IN_NEW_DATE]: pageDate,
            }}
          />
        </div>
        <SizedBox height={20} />
      </HideWhenOpenInNew>
      {contentElement}
    </div>
  )
})

SearchForOptions.displayName = 'SearchForOptions'
