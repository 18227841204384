/**
 * region
 */

export const USA_KEY = 'United States'
export const USA_VALUE = 'USA'

export const UK_KEY = 'United Kingdom'
export const UK_VALUE = 'UK'

export const UAE_KEY = 'United Arab Emirates'
export const UAE_VALUE = 'UAE'

export const EL_KEY = 'El Palacio de Hierro'
export const EL_VALUE = 'El Palacio'
