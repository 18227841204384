/**
* @file    MenuBarDisplay
* @date   2022-07-06
* @author byte_su@163.com
*/

import { ClickAwayListener } from '@material-ui/core'
import { ClockIcon } from 'assets/icons'
import classNames from 'classnames'
import { ActionBoxContext } from 'components/common/InfoBox/context'
import { Account } from 'components/layout/Header/components/Account'
import { QaButton } from 'components/layout/Header/components/QaButton'
import { SearchBar } from 'components/layout/Header/components/Searchbar'
import { SelectionSearchbar } from 'components/layout/Header/components/SelectionSearchbar'
import { VerticalLine } from 'components/layout/Header/components/VerticalLine'
import { ProgressBar } from 'components/ProgressBar'
import { ShareFilterButton } from 'componentsv2/business'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ArrowBackwardIcon } from 'componentsv2/Tree/icons'
import { getPropertyVal } from 'componentsv2/Tree/utils'
import { CATEGORY_TOTAL, IS_LIVE_ENV, isJustBrandsLicense } from 'consts'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { defaultPackFilter, defaultSizeFilter, defaultTaxFilter, isCachedWithoutCompetitor, selectDashboardCategories, setFilterDisplayHeight, setLastComparisonQueryForLookbook, updateComparisonQuery } from 'features/filters/filtersSlice'
import { useFlatQueryObj } from 'hooks'
import { useFilter } from 'hooks/useFilter'
import { useFunctionHandler } from 'hooks/useFunctionHandler'
import { usePageDate } from 'hooks/usePageDate'
import { usePriceRangeQueryCompare } from 'hooks/usePriceRange'
import useProperties from 'hooks/useProperties'
import { RegionsType } from 'libs'
import { cloneDeep, isEmpty, uniq } from 'lodash'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ObjectType } from 'types'
import { regionShortMappingFn } from 'utils'
import { formatDateStr2 } from 'utils/dateUtils'
import { sortVendorWithBrandSellerStringV2 } from 'utils/sellers'
import { isCategorySplitPage, isComparisonDatePages, isDynamicDashboardPage, isLineGraphReportPage, isMostPopularLaunchDaysPage, isMySavedFiltersPage, isMySavedLookbooksPage, isOptionsInOutCalendarPage, isPlatformAnalyticsPage, isProductRankingPage, isSizeOfLinePage } from 'global/Routes'
import { useLookbook } from 'hooks/useLookbook'
import { loadingBar } from 'hooks/useLoadingBar'
import { getFilterTags } from 'features/filters/globalDataSlice'
import { headerHeight } from 'configs/css'
import { getDisabledCategoriesForSizeFilter, getDisplayCategoryValue } from 'components/layout/Header/components/HeaderFilter/category.util'
import { FilterTags } from './FilterTags'
import styles from './style.module.scss'
import { getFilterDisplayHeight, getGendersFilterTagValue, getIgnoreNoAvailableSize, getLaunchedAfterFilterTagValue, getLaunchedBeforeFilterTagValue, getMaterialFilterTagValue, getNoHarmonizedSize, getNoHistoricalSizeValue, getNoSizeValue, getNoUnavailableSize, getPackFilterValue, getPricePerspectiveValue, getPriceRangeFilterTagValue, getProductFilterTagValue, getSizeFilterTagValue, getSortByFilterTagValue, getTaxFilterTagValue, getZalandodeepShopEmptyString, handleQuery, updatePageMarginTop } from './utils'
import { useDateObj } from 'pages/DynamicDashboard/hooks/useApi'
import DateRangeText from 'componentsv2/business/DateRangeText/DateRangeText'

export const MenuBarDisplay = () => {
    const { comparisonQuery } = useFlatQueryObj()
    const { query } = comparisonQuery.collection
    const { pageDate, comparisonPageDate } = usePageDate()
    const actionBox = useContext(ActionBoxContext)
    const cacheData = useSelector(getCacheData)
    const dispatch = useDispatch()
    const displayRef = useRef<ObjectType<any>>()
    const state = useSelector(getFilterTags)
    const [ open, setOpen ] = useState(true)
    const [ isGt2lines, setIsGt2lines ] = useState(false)
    const { children } = useProperties()
    const { compare } = usePriceRangeQueryCompare()
    const { disabled: disabledFilter } = useFilter()
    const { isLookbook, checkedLookbookLabels, setCheckedList, list: lookbookList } = useLookbook()

    /* *************************** Filter tag value ***************************** */
    let categories: string[] = []
    let properties = {}
    let genders: string[] = []
    let region: RegionsType = {} as RegionsType
    let currency = ''
    let competitors: string[] = []
    let sortBy = ''
    let priceFilter = ''
    let priceRange = ''
    let productFilter = ''
    let launchedAfter = ''
    let launchBeforeDate = ''
    let sizeFilter = ''
    let taxFilter = ''
    let materialFilter = ''
    let packFilter = ''
    let noSize = ''
    let noHistoricalSize = ''
    let noUnavailableSize = ''
    let noHarmonizedSize = ''
    let zalandodeepShopEmptyString = ''
    let ignoreNoAvailableSize = ''

    // 获取仅可以展示的`properties` 进行展示
    cloneDeep(children)
        ?.filter(item => item.active)
        .forEach((item: any) => {
            properties[item.val] = getPropertyVal(item, false, true).flat(10)
        })
    
    if (!isEmpty(state ?? {})) {  // tmp filter
        categories = state?.category ?? []
        properties = state.property ?? {}
        region = state?.region ?? {}
        currency = state.region.currency
        competitors = (state?.vendor ?? []).map((item: any) => item.vendor)
        sortBy = getSortByFilterTagValue(state.sort)
        priceFilter = getPricePerspectiveValue(state.priceFilter.label)
        priceRange = getPriceRangeFilterTagValue(state.priceRange)
        productFilter = getProductFilterTagValue(state.priceRange)
        launchedAfter = getLaunchedAfterFilterTagValue(state.launchDate)
        launchBeforeDate = getLaunchedBeforeFilterTagValue(state.launchBeforeDate)
        sizeFilter = getSizeFilterTagValue(state.sizeFilter)
        taxFilter = getTaxFilterTagValue(state.taxFilter)
        materialFilter = getMaterialFilterTagValue(state.materialFilter)
        genders = getGendersFilterTagValue(state.gender, sizeFilter)
        packFilter = getPackFilterValue(state.packFilter)
        noSize = getNoSizeValue(state.noSize)
        noUnavailableSize = getNoUnavailableSize(state.noUnavailableSize)
        noHarmonizedSize = getNoHarmonizedSize(state.noHarmonizedSize)
        zalandodeepShopEmptyString = getZalandodeepShopEmptyString(state.zalandodeepShopEmptyString)
        ignoreNoAvailableSize = getIgnoreNoAvailableSize(state.ignoreNoAvailableSize)
        noHistoricalSize = getNoHistoricalSizeValue(state.noHistoricalSize)
    } else {
        categories = query.categories ?? []
        region = query.regions
        currency = query.regions.currency
        competitors = (comparisonQuery.sellers ?? []).map((item: any) => item.vendor)
        sortBy = getSortByFilterTagValue(query.sortField)
        priceFilter = getPricePerspectiveValue(query.priceFilter.label)
        priceRange = getPriceRangeFilterTagValue(query.priceRange)
        productFilter = getProductFilterTagValue(query.priceRange)
        launchedAfter = getLaunchedAfterFilterTagValue(query.earliestLaunchDate)
        launchBeforeDate = getLaunchedBeforeFilterTagValue(query.launchBeforeDate)
        sizeFilter = getSizeFilterTagValue(query.sizeFilter)
        taxFilter = getTaxFilterTagValue(query.taxFilter)
        materialFilter = getMaterialFilterTagValue(query.materialFilter)
        genders = getGendersFilterTagValue(query.targetGroups, sizeFilter)
        packFilter = getPackFilterValue(query.packFilter)
        noSize = getNoSizeValue(query.noSize)
        noUnavailableSize = getNoUnavailableSize(query.noUnavailableSize)
        noHarmonizedSize = getNoHarmonizedSize(query.noHarmonizedSize)
        zalandodeepShopEmptyString = getZalandodeepShopEmptyString(query.zalandodeepShopEmptyString)
        ignoreNoAvailableSize = getIgnoreNoAvailableSize(query.ignoreNoAvailableSize)
        noHistoricalSize = getNoHistoricalSizeValue(query.noHistoricalSize)
    }

    categories = getDisplayCategoryValue(categories)
    
    /**
     * Size filter 与 category 联动
     */
    if (sizeFilter) {
        const disabledCategories: string[] = getDisabledCategoriesForSizeFilter(true)
        categories = categories.filter(item => !disabledCategories.includes(item))
    }

    const dashboardCategories = useSelector(selectDashboardCategories)
    if (isCategorySplitPage()) {
        categories = [ ...dashboardCategories ]
    }

    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/866
    if (isSizeOfLinePage()) {
        categories = [ CATEGORY_TOTAL ]
    }

    competitors = sortVendorWithBrandSellerStringV2(competitors)

    let categoryStr: Array<string> = categories.filter((item: string) => item?.trim?.()).sort()

    const updateFilter = useCallback(() => {
        if (document.querySelector('.fixedFilter')?.innerHTML) return   // filter is opened
        const filtered = displayRef.current?.getVals()

        // lookbook
        if (isLookbook) {
            const removedLookbooks = [ ...filtered ]
            const lookbooks = checkedLookbookLabels
                .filter(item => !removedLookbooks.includes(item))
                .map(item => lookbookList.find(item2 => item2.name === item))
                .map(item => item.id)
                .filter(item => item)
            setCheckedList([ ...lookbooks ])
            displayRef.current?.clear()
            // 取消选中 lookbook 时需要重置 filter 信息
            if (lookbooks.length === 0) {
                dispatch(setLastComparisonQueryForLookbook(undefined))
            }
            return
        }

        if (!filtered.length) return                                  // changed by user's action

        const comparisonQueryData = cloneDeep(comparisonQuery)

        handleQuery(comparisonQueryData, filtered, properties, children, launchedAfter, cacheData, materialFilter, launchBeforeDate, noSize, noHistoricalSize, noUnavailableSize, noHarmonizedSize, zalandodeepShopEmptyString, ignoreNoAvailableSize)
        const { changed } = compare(comparisonQueryData)

        // handle price range
        if (filtered.includes(priceRange) || changed) {
            comparisonQueryData.collection.query.priceRange.activate_price_range = false
        }
        // handle product filter
        if (filtered.includes(productFilter)) {
            comparisonQueryData.collection.query.priceRange.optionValue = 'all'
        }
        // handle sizeFilter
        if (filtered.includes(sizeFilter)) {
            comparisonQueryData.collection.query.sizeFilter = defaultSizeFilter
        }
        // handle tax
        if (filtered.includes(taxFilter)) {
            comparisonQueryData.collection.query.taxFilter = defaultTaxFilter
        }
        // handle pack filter
        if (filtered.includes(packFilter)) {
            comparisonQueryData.collection.query.packFilter = defaultPackFilter
        }

        if (!comparisonQueryData?.collection?.query?.properties?.Material && filtered.some(item => item.startsWith('Material:'))) {
            comparisonQueryData.collection.query.materialFilter.checked = false
        }

        if (!comparisonQueryData?.collection?.query?.properties?.Colors && filtered.some(item => item.startsWith('Colors:'))) {
            comparisonQueryData.collection.query.colorFilter.checked = false
        }

        if (JSON.stringify(comparisonQuery) !== JSON.stringify(comparisonQueryData)) {
            dispatch(updateComparisonQuery(comparisonQueryData))        // update filter
            displayRef.current?.clear()                                 // clear filterd
            actionBox.closeAll()                                        // close all dialog when query filter changed
            if (!isCachedWithoutCompetitor(comparisonQuery, comparisonQueryData)) {
                loadingBar.restart()
            }
        }
    }, [ isLookbook, comparisonQuery, properties, children, launchedAfter, launchBeforeDate, cacheData, compare, priceRange, sizeFilter, taxFilter, checkedLookbookLabels, setCheckedList, lookbookList, dispatch, actionBox, materialFilter, packFilter, productFilter, noSize, noHistoricalSize, noUnavailableSize, noHarmonizedSize, zalandodeepShopEmptyString, ignoreNoAvailableSize ])

    /**
    * Search 框里输入的值
    * 
    * 当 Search 框中没有值时，Logo 是以 absolute 定位水平居中的
    * 当 Search 框中有值时，Search 框的宽度会变宽，此时 Logo 需要修改为 relative 布局
    */
    const [ searchWidthChanged, setSearchWidthChanged ] = useState(false)// 

    let navStr = formatDateStr2(pageDate)
    const comparisonDatePages = isComparisonDatePages()

    const disabledDatePages = isProductRankingPage() && !isJustBrandsLicense()

    let comparisonPageDateStr = formatDateStr2(comparisonPageDate)

    const showDate = !isOptionsInOutCalendarPage()

    const dateObj = useDateObj()
    if (isDynamicDashboardPage()) {
        navStr = formatDateStr2(dateObj.date)
        comparisonPageDateStr = formatDateStr2(dateObj.comparisonDate)
    }

    /**
     * 2022/08/21 
     * Size of line 两个页面在 Header 里不显示 categories
     */
    let hideCategories = false
    if (isSizeOfLinePage() || isMostPopularLaunchDaysPage()) {
        hideCategories = true
    }

    /**
     * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/868
     * 隐藏头部的 region + category + selected time period + comparison period
     */
    let hideHeaderInfo = false
    if (isMySavedFiltersPage() || isMySavedLookbooksPage() || isLineGraphReportPage()) {
        hideHeaderInfo = true
    }

    let pathComponent = (
        <>
            {regionShortMappingFn(region?.key)}
            {categoryStr.length && !hideCategories ? <>&nbsp;/&nbsp;</> : ''}
            {categoryStr.length && !hideCategories ? <span className={styles.categories} title={categoryStr.join(', ')}>{categoryStr.join(', ')}</span> : null}
        </>
    )

    if (isPlatformAnalyticsPage()) {
        pathComponent = (
            <>
                Germany
                {categoryStr.length && !hideCategories ? <>&nbsp;/&nbsp;</> : ''}
                {categoryStr.length && !hideCategories ? <span className={styles.categories} title={categoryStr.join(', ')}>{categoryStr.join(', ')}</span> : null}
            </>
        )
    }

    const funciontHandler = useFunctionHandler()

    if (isLookbook) {
        pathComponent = <>Lookbook</>
    }

    return (
        <ClickAwayListener
            onClickAway={updateFilter}
        >
            <div className={classNames(styles.filterDisplay, 'filterDisplay')}>
                <ProgressBar />
                <div className="flex">
                    <ul className={styles.navs} style={{ height: headerHeight }}>
                        {
                            isDynamicDashboardPage() ? null : (
                                <li>
                                    {funciontHandler}{ hideHeaderInfo ? null : <>&nbsp;/&nbsp;{pathComponent}</> }
                                </li>
                            )
                        }
                        {
                            showDate && !hideHeaderInfo ? (
                                <li 
                                    className={classNames({ 
                                        disabled: disabledDatePages,
                                    })}
                                >
                                    <div className={styles.selectedTimePeriodWrapper}>
                                        <div className={styles.verticalLine} />
                                        <ClockIcon />
                                        <div>
                                            Selected time period
                                        </div>
                                        <DateRangeText type="date">
                                            <div className={styles.dateRangeValue}>
                                                {navStr}
                                            </div>
                                        </DateRangeText>
                                    </div>
                                </li>
                            ) : null
                        }
                        {
                            showDate && !hideHeaderInfo ? (
                                <li className={classNames({ disabled: !comparisonDatePages })}>
                                    <div className={styles.subTitle}>
                                        <div className={styles.verticalLine} />
                                        <ClockIcon />
                                        <div>
                                            Comparison period
                                        </div>
                                        <DateRangeText type="comparisonDate">
                                            <div className={styles.dateRangeValue}>
                                                {comparisonPageDateStr}
                                            </div>
                                        </DateRangeText>
                                    </div>
                                </li>
                            ) : null
                        }
                    </ul>
                    <div style={{ flex: 1 }} />
                    <div className={styles.account} style={{ top: (headerHeight - 50) / 2 }}>
                        {!IS_LIVE_ENV ? (
                            <div className={styles.searchContainer}>
                                <SearchBar onWidthChange={changed => setSearchWidthChanged(changed)} />
                                <SelectionSearchbar />
                            </div>
                        ) : null}
                        {!searchWidthChanged ? (
                            <>
                                <QaButton key="qa" />
                                <ShouldRender shouldRender={!disabledFilter}>
                                    <ShareFilterButton />
                                    <VerticalLine />
                                    <VerticalLine />
                                </ShouldRender>
                            </>
                        ) : null}
                        <Account />
                    </div>
                </div>
                <div style={{ display: disabledFilter ? 'none' : 'block' }} >
                    <ul className={styles.filters} >
                        <li className={styles.title}>
                            <span>FILTERS</span>
                        </li>
                        <li className={styles.filtersItem}>
                            <div style={open ? {} : { maxHeight: '52px' }} className={styles.filtersItemContainer}>
                                <FilterTags
                                    open={open}
                                    setIsGt2lines={setIsGt2lines}
                                    ref={displayRef}
                                    categories={categoryStr}
                                    property={properties}
                                    gender={genders}
                                    region={regionShortMappingFn(region.key)}
                                    currency={currency}
                                    competitor={uniq(competitors)}
                                    sortBy={sortBy}
                                    priceFilter={priceFilter}
                                    priceRange={priceRange}
                                    productFilter={productFilter}
                                    launchedAfter={launchedAfter}
                                    launchedBefore={launchBeforeDate}
                                    noSize={noSize}
                                    noHistoricalSize={noHistoricalSize}
                                    noUnavailableSize={noUnavailableSize}
                                    noHarmonizedSize={noHarmonizedSize}
                                    zalandodeepShopEmptyString={zalandodeepShopEmptyString}
                                    ignoreNoAvailableSize={ignoreNoAvailableSize}
                                    sizeFilter={sizeFilter}
                                    taxFilter={taxFilter}
                                    materialFilter={materialFilter}
                                    packFilter={packFilter}
                                />
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                setOpen(!open)
                                setTimeout(updatePageMarginTop, 0)
                                setTimeout(() => {
                                    // 设置 displayHeight
                                    const filterDisplayHeight = getFilterDisplayHeight()
                                    dispatch(setFilterDisplayHeight({ filterDisplayHeight }))
                                }, 0)
                            }}
                        >
                            <span className={styles.num} />
                            <button
                                className={classNames({
                                    [styles.collapse]: true,
                                    [styles.open]: open,
                                })}
                                style={{ opacity: isGt2lines ? 1 : 0 }}
                            >
                                <ArrowBackwardIcon />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </ClickAwayListener>
    )
}

MenuBarDisplay.displayName = 'MenuBarDisplay'

