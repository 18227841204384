import React, { CSSProperties, forwardRef, useState, useImperativeHandle, useEffect } from 'react'
import classnames from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { CaretIcon } from 'assets/icons'
import { BaseSelectProps, BaseSelectRefProps } from './types'
import styles from './styles.module.scss'

/**
 * ## 何时使用
 * 
 * BaseSelect 组件提供下拉功能，可以基于该组件实现具有下拉功能的具体组件，如下拉框、多选下拉框、日期选择框等
 * 
 * ## 组成结构
 *
 * ```
 * <div className="select">   下拉框 (select) 包含下拉选择器 (selector) 和下拉弹窗 (dropdown)
 *      <div className="selector"></div>  点击下拉选择器 (selector) 弹出下拉弹窗 (dropdown)
 *      <div className="dropdown"></div>  下拉弹窗 (dropdown)
 * </div>
 * ```
 * 
 * ## 使用示例
 * 
 * ### 基本使用
 * 
 * ```
 * <BaseSelect
 *      placeholder="Week"
 *      renderDropdown={(
 *          <>
 *              <h1>Mon</h1>
 *              <h1>Tue</h1>
 *              <h1>Wed</h1>
 *          </>
 *      )}
 * />
 * ```
 * 
 * ### 外部组件调用 BaseSelect 内部的方法
 * 
 * （使用了 React useImperativeHandle 钩子特性）
 * 
 * ```
 * import { BaseSelect, BaseSelectRefProps } from 'componentsv2/BaseSelect'
 * 
 * // 通过 selectRef.current 可以调用 BaseSelectRefProps 对外暴露的方法
 * const selectRef = useRef<BaseSelectRefProps>()
 * 
 * const onClick = () => {
 *      // 调用 close() 方法关闭下拉弹窗
 *      selectRef.current?.close()
 * }
 * 
 * <BaseSelect
 *     ref={selectRef}
 *     placeholder="Month"
 *     renderDropdown={(
 *         <>
 *             <h1 onClick={onClick}>hello world</h1>
 *         </>
 *     )}
 * />
 * ```
 */
export const BaseSelect = forwardRef<BaseSelectRefProps, BaseSelectProps>(({
  className,
  renderSelector,           // 自定义选择器 selector 内容
  renderDropdown,           // 自定义下拉弹窗 dropdown 内容
  inputValue = '',          // 选择器中显示的文案
  placeholder,              // inputValue 为空时，显示 placeholder
  disabled = false,         // 是否禁用
  left,                     // margin-left 值
  right,                    // margin-right 值
  style = {},               // 下拉框样式
  selectorStyle = {},       // 选择器样式
  selectorClassName,
  dropdownStyle = {},       // 下拉弹窗样式
  onIsOpenChanged,
}, ref) => {
  // 控制下拉弹窗是否显示
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    onIsOpenChanged?.(isOpen)
    // eslint-disable-next-line
  }, [isOpen])

  // 下拉框 (select) 类名
  const selectClassName = classnames([
    styles.select,
    disabled && styles.disabled,
    className,
  ])

  // 下拉框 (select) 样式
  const selectStyle: CSSProperties = {}
  if (typeof left !== 'undefined') {
    selectStyle.marginLeft = left + 'px'
  }
  if (typeof right !== 'undefined') {
    selectStyle.marginRight = right + 'px'
  }

  // 下拉选择器 (selector) 类名
  const selectorCls = classnames([
    inputValue && styles.isSelected,
    styles.selector,
    selectorClassName,
  ])

  /**
   * 组件对外暴露的方法
   */
  useImperativeHandle(ref, () => ({
    close: () => setIsOpen(false),
    open: () => setIsOpen(true),
  }))

  return (
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div data-testid="select" className={selectClassName} style={{ ...selectStyle, ...style }}>
              {
                    renderSelector ? (
                      <div data-testid="selector" onClick={() => { if (!disabled) setIsOpen(!isOpen) }}>
                        {renderSelector}
                      </div>
                    ) : (
                      <div data-testid="selector" className={selectorCls} style={selectorStyle} onClick={() => { if (!disabled) setIsOpen(!isOpen) }}>
                          <div className={styles.selectorSelection}>{inputValue || placeholder}</div>
                          <div className={classnames(styles.selectorArrow, isOpen && styles.selectorArrowFlipped)}>
                              <CaretIcon />
                          </div>
                      </div>
                    )
                }
              <div data-testid="dropdown" className={classnames(styles.dropdown, isOpen && styles.dropdownExpanded)} style={dropdownStyle}>
                  {renderDropdown}
              </div>
          </div>
      </ClickAwayListener>
  )
})

BaseSelect.displayName = 'BaseSelect'
