import { createSlice } from '@reduxjs/toolkit'
import { LookbookType } from 'libs'
import { RootState } from '../../app/store.type'
import { LookbookDataState } from './lookbookDataSlice.types'

export const initData = {
  checked: [],                        // currently checked product in `zoom in` view
  list:[] as Array<LookbookType>,
  myLookbookList: [],       // 接口查询到的完整的 lookbook 列表信息
  latestLookbook: {},       // 当前选择的 lookbook
  lookbookActions:[] as Array<string>,
}
const initialState: LookbookDataState = initData as LookbookDataState
export const lookbookDataSlice = createSlice({
  name: 'lookbookData',
  initialState,
  reducers: {
    /**
    * checked product
    */
    updateCheckedLookbook(state, action) {
      state.checked = action.payload
      state.lookbookActions = [] // clear lookbook actions
    },

    /**
     * 
     * @param state 
     * @param action 
     */
    updateLookbookList(state, action) {
      state.list = action.payload
    },

    updateMyLookbookList(state, action) {
      state.myLookbookList = action.payload
    },

    updateLatestLookbook(state, action) {
      state.latestLookbook = action.payload
    },

    updateLookbookActions(state, action) {
      state.lookbookActions = action.payload
    },

    clearLookbookActions(state) {
      state.lookbookActions = []
    },

  },

})
export const {
  updateCheckedLookbook,
  updateLookbookList,
  updateMyLookbookList,
  updateLatestLookbook,
  updateLookbookActions,
  clearLookbookActions,
} = lookbookDataSlice.actions

// lookbook object
export const getLookbookData = (state: RootState) => state.lookbook

export default lookbookDataSlice.reducer
