import React from 'react'
import styles from './styles.module.scss'
import { CheckIcon } from './icons'

export const Checkbox = ({
  onChange = () => {
  },
  active,

}) => (
  <div
    className={styles.checkbox}
    onClick={e => {
      e.stopPropagation()
      onChange?.()
    }}
  >
    {active ? <CheckIcon /> : null}
  </div>
)
