import React, { CSSProperties, memo, useRef } from 'react'
import { SkeletonLoading } from 'components/loaders'
import { IMG_PLACEHOLDER } from 'consts'
import classNames from 'classnames'
import { disableLazyLoad } from 'features/filters/memoDataSlice'
import { useSelector } from 'react-redux'
import NornaImage from './NornaImage'
import { ImageProps } from './types'
import styles from './styles.module.scss'
import { getImgFullPath } from 'utils/imgUtils'

export const LazyBgImg = memo(({
  id,
  style,
  className = '',
  src = IMG_PLACEHOLDER, 
  retryCount = 3,
  cropedImg = true, 
  skeletonStyle = {},
  onClick,
  width,
  height,
  objectFit,
}: ImageProps) => {
  // 导出图片时禁用懒加载
  const stateOfDisableLazyLoad = useSelector(disableLazyLoad)

  // ibm 更快加载图片还是 aws 更快加载图片
  const fasterRef = useRef<'ibm' | 'aws' | ''>('')

  const sty: CSSProperties = {}
  if (width) {
    sty.width = width
  }
  if (height) {
    sty.height = height
  }

  return (
    <div
      id={id}
      style={sty}
      className={classNames({
        [styles.imgContainer]: true,
        [styles.noLimitSizeBg]: !cropedImg,
        lazyBgImg: true,
        [className]: true,
      })}
      onClick={() => onClick?.()}
    >
      <NornaImage
          style={{ 
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              background: '#f8f7f5',
              objectFit,
              ...style,
          }}
          src={getImgFullPath({ src, isKeepOriginalSize: !cropedImg, width, height })}
          lazy={!stateOfDisableLazyLoad}
          retry={retryCount}
      />
      {
        !fasterRef.current ? (
          <SkeletonLoading isLoading={!fasterRef.current} style={{ ...skeletonStyle }} />
        ) : null
      }
    </div>
  )
})

LazyBgImg.displayName = 'LazyBgImg'
