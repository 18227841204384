import React, { CSSProperties, useState } from 'react'
import DateRangePanel from 'componentsv2/form-elements/DateRangePanel/DateRangePanel'
import styles from './styles.module.scss'
import { ClickAwayListener } from 'druikit';
import { usePageDate } from 'hooks/usePageDate';
import { isDynamicDashboardPage, isSearchForOptionsPage } from 'global/Routes';
import { useDateObj } from 'pages/DynamicDashboard/hooks/useApi';
import { useCalendarComparisonDate, useCalendarDate, useCalendarSelectionDate } from 'hooks/useGlobalData';
import { loadingBar } from 'hooks';
import { IS_LIVE_ENV } from 'consts';
import { dateRangeUtils } from 'norna-uikit';

const DateRangeText = ({
    type = 'date',
    calendarStyle,
    children,
}: {
    type?: 'date' | 'comparisonDate';
    calendarStyle?: CSSProperties;
    children: React.ReactNode; // 子组件
}) => {
    const [ open, setOpen ] = useState(false)
    const { pageDate, comparisonPageDate } = usePageDate()
    const dateObj = useDateObj()

    // 其它页面日期
    const [ , setCalendarDate ] = useCalendarDate()
    const [ , setCalendarComparisonDate ] = useCalendarComparisonDate()
    const [ , setCalendarSelectionDate ] = useCalendarSelectionDate()

    let dateValue = pageDate
    let comparisonDateValue = comparisonPageDate

    if (isDynamicDashboardPage()) {
        dateValue = dateObj.date
        comparisonDateValue = dateObj.comparisonDate
    }

    const onChange = value => {
        // DynamicDashboard 页面
        if (type === 'date') {
            if (isSearchForOptionsPage()) {
                if (!IS_LIVE_ENV) {
                    setCalendarSelectionDate(dateRangeUtils.last(value))
                } else {
                    setCalendarDate(dateRangeUtils.last(value))
                }
            } else {
                setCalendarDate(value)
            }
        } else {
            setCalendarComparisonDate(value)
        }
        setOpen(false)
        loadingBar.restart()
    }

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (!open) return
                setOpen(false)
            }}
        >
            <div className={styles.wrapper}>
                <span onClick={() => setOpen(!open)}>
                    { children }
                </span>
                {
                    open && (
                        <div
                            style={calendarStyle}
                            className={styles.dateRangePanel} 
                        >
                            <DateRangePanel
                                value={type === 'date' ? dateRangeUtils.from(dateValue) : comparisonDateValue}
                                onChange={onChange}
                            />
                        </div>
                    )
                }
            </div>
        </ClickAwayListener>
    )
}

export default DateRangeText
