import React from 'react'
import { LoadingSpinner } from 'assets/icons/Loading'
import { LandingWrap } from 'components/layout/LandingWrap'
import { Message } from 'components/form-elements/Message'
import { useAuthenticationDataContext } from 'components/guards/AuthenticationContext'
import { ForgotPasswordForm } from './components/ForgotPasswordForm'

export const ForgotPassword = () => {
  const { resetPassword, res }: any = useAuthenticationDataContext()

  return (
      <LandingWrap title="FORGOT PASSWORD">
          {res?.loading && <LoadingSpinner />}
          {res?.error &&
        res?.error?.graphQLErrors.map(
          ({ message }: any) =>
            message &&
            <Message
                message="Something went wrong! Please, try again."
                key={message}
                error={true}
            />
          ,
        )}
          <ForgotPasswordForm onResetPassword={resetPassword} />
      </LandingWrap>
  )
}
