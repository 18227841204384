/**
* @file    MenuBar
* @date   2022-07-05
* @author byte_su@163.com
*/
import { ActionBoxContext } from 'components/common/InfoBox/context'
import { HeaderFilter } from 'components/layout/Header/components/HeaderFilter'
import { Logo } from 'components/layout/Header/components/Logo'
import { CreateLookbookModal } from 'components/Lookbooks/CreateLookbookModal'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { Note } from 'components/layout/Header/components/Account/components'
import useCustomState from 'hooks/useCustomState'
import { NoteType } from 'components/layout/Header/components/Account/components/type'
import { useFilter } from 'hooks/useFilter'
import classnames from 'classnames'
import { Calendar } from 'assets/icons/NewMenu/Calendar'
import { TreeInteractiveType } from 'componentsv2/Tree'
import { usePageDate } from 'hooks/usePageDate'
import { TIME_PERIOD } from 'consts/strings'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { useCreateLookbook, useRefreshLookbookList } from 'hooks/api/useLookbookData'
import { MenuBarItem } from './MenuBarItem'
import { MenuBarItemProps, MenuBarStateType, MenuPanelDisappearType } from './types'
import styles from './style.module.scss'
import useMenuBar from './utils'
import { GlobalDatePicker } from './GlobalDatePicker/GlobalDatePicker'
import { useHistory } from 'react-router-dom'
import { RoutesObj } from 'global/Routes'
import { useModalVisible } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'

export const MenuBar = () => {
    const history = useHistory()
    const actionBox = useContext(ActionBoxContext)
    const { createLookbook } = useCreateLookbook()
    const { refreshLookbookList } = useRefreshLookbookList()

    const createLookbookItem = React.useCallback(async lookbook => {
        try {
            await createLookbook(lookbook.name, lookbook.desc)
            await refreshLookbookList()
            actionBox.close(3)
        } catch (e) {
            // loadingBar && loadingBar.complete();
        }
    }, [ actionBox, createLookbook, refreshLookbookList ])

    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    
    const lookbookAction = React.useCallback(() => {
        const dialogId = 'create-new-lookbook'
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog(dialogId,
            <CreateLookbookModal
                closeModal={() => {
                    closeFn(dialogId)
                }}
                onSave={createLookbookItem}
                isOpen={true}
                hideBackBtn
            />,
        )
        // eslint-disable-next-line
    }, [ actionBox, createLookbookItem ])

    const { state, updateState } = useCustomState<MenuBarStateType>({ support: false, release: false, about: false, filterChanged: false, active: '' })

    // 更新菜单项选中状态
    const updateActive = useCallback((active, title) => updateState({ active: active ? title : '' }), [ updateState ])

    // 更新Note panel
    const updateNote = useCallback((note = {}, e?: React.SyntheticEvent) => {
        e?.stopPropagation()
        updateState({ support: false, active: '', release: false, about: false, ...note })
    }, [ updateState ])

    const { list } = useMenuBar({
        createLookbook: lookbookAction,
        openRelaseNote: (e?: React.SyntheticEvent) => updateNote({ release: true }, e),
        openSupport: (e?: React.SyntheticEvent) => updateNote({ support: true }, e),
        openAbout: (e?: React.SyntheticEvent) => updateNote({ about: true }, e),
    })

    const { disabled: disabledTimePicker } = usePageDate()
    const { disabled: disabledFilter } = useFilter()

    /**
     * Filter 打开后是否发生了变化
     * 1. 发生变化时，hover移开Filter不需要隐藏Filter, 同时其他Menu不能Hover显示.
     * 2. 未发生变化, hover移开Filter时 隐藏Filter
     */
    const [ filterChanged, setFilterChanged ] = useState(false)

    const [ dynamicDashboardModalVisible ] = useModalVisible()

    return (
        <>
            {state.release && <Note close={() => updateState({ release: false })} type={NoteType.Release} />}
            {state.support && <Note close={() => updateState({ support: false })} type={NoteType.Support} />}
            {state.about && <Note close={() => updateState({ about: false })} type={NoteType.About} />}
            <Dialog ref={dialogRef} />
            <div className={styles.container}>
                <div className={styles.items} style={{ pointerEvents: dynamicDashboardModalVisible ? 'none' : 'auto' }}>
                    <div 
                        style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box', paddingBlock: 10 }}
                        onClick={() => {
                            history.push(RoutesObj.DYNAMIC_DASHBOARD.URL)
                        }}
                    >
                        <Logo noText style={{ width: '100%', height: '100%' }} />
                        <div style={{ fontSize: 10, transform: 'scale(0.8)', lineHeight: 1, color: '#666', marginTop: 8 }}>HOME</div>
                    </div>
                    <div
                        className={classnames({ [styles.disabled]: disabledFilter })}
                    >
                        <HeaderFilter
                            updateFilterChangeStatus={setFilterChanged}
                            active={state.active === 'Filter'}
                            currentActive={state.active}
                            interactiveType={TreeInteractiveType.CLICK}
                            updateActive={active => updateActive(active, 'Filter')}
                        />
                    </div>
                    {list.slice(0, -1).map((item: MenuBarItemProps) => (
                        <MenuBarItem
                            {...item}
                            key={item.title}
                            disabledHover={filterChanged}
                            disappearType={MenuPanelDisappearType.CLICK_AWAY}
                            active={state.active === item.title}
                            currentActive={state.active}
                            updateActive={active => updateActive(active, item.title)}
                        />
                    ))}
                    <MenuBarItem
                        disabled={disabledTimePicker}
                        title={TIME_PERIOD}
                        disabledHover={filterChanged}
                        disappearType={MenuPanelDisappearType.CLICK_AWAY}
                        panelSty={{ width: '674px', height: '576px' }}
                        icon={<Calendar />}
                        active={state.active === TIME_PERIOD}
                        currentActive={state.active}
                        updateActive={active => updateActive(active, TIME_PERIOD)}
                        CustomListElement={GlobalDatePicker}
                    />
                    {list.slice(-1).map((item: MenuBarItemProps) => (
                        <MenuBarItem
                            {...item} 
                            key={item.title}
                            disabledHover={filterChanged}
                            disappearType={MenuPanelDisappearType.CLICK_AWAY}
                            active={state.active === item.title}
                            currentActive={state.active}
                            updateActive={active => updateActive(active, item.title)}
                        />
                    ))}
                </div>
                <div
                    className={styles.blankSpace}
                    onMouseEnter={() => {
                        if (!state.active) return
                        updateActive?.(false, '')
                    }}
                />
            </div>
        </>
    )
}

MenuBar.displayName = 'MenuBar'
