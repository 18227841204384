import React from 'react'
import { SizedBox } from 'componentsv2/SizedBox'
import { useLoadingBar, useScrollToTop } from 'hooks'
import { PriceHistoryDrivers } from './components/PriceHistoryDrivers'

export const PriceHistoryPage = () => {
  useScrollToTop()
  useLoadingBar()

  return (
      <div style={{ background: '#fff', position: 'relative' }}>
        <PriceHistoryDrivers />
      </div>
  )
}

PriceHistoryPage.displayName = 'PriceHistoryPage'
