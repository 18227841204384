import { EMAIL } from '../features/filters/filtersSlice'
import { isLookbookFn } from './index'
import { lsGet, lsSet } from './ls'

export function addExclude(query, excludedPro) {

  query = JSON.parse(JSON.stringify(query))
  query.comparisonQuery.collection.query.excludedProducts = excludedPro
  query.comparisonQuery.excludedProducts = excludedPro
  query.comparisonQuery.collection.excludedProducts = excludedPro
  return query
}

/**
 * add  excludedProducts property to query
 * @param queryComparisoQuery
 * @param excludedPro
 */
export function formatQueryData(queryComparisoQuery, excludedPro, seller, opt = { init: false }) {
  const key =  `${EMAIL()}_${seller.vendor}_${JSON.stringify(queryComparisoQuery.comparisonQuery.collection.query.categories)}`// exclude products key
  if (opt.init) {
    const excludedProFromStorage = lsGet(key)
    if (excludedProFromStorage) {
      excludedPro = JSON.parse(excludedProFromStorage)
    }
  }

  queryComparisoQuery = JSON.parse(JSON.stringify(queryComparisoQuery))
  queryComparisoQuery.comparisonQuery.collection.query.excludedProducts = excludedPro
  queryComparisoQuery.comparisonQuery.excludedProducts = excludedPro
  queryComparisoQuery.comparisonQuery.collection.excludedProducts = excludedPro
  lsSet(key, JSON.stringify(excludedPro))

  return queryComparisoQuery
}

/**
 * pure query by excluding excludedProducts property
 * @param queryComparisoQuery
 */
export function rmExclude(queryComparisoQuery) {
  queryComparisoQuery = JSON.parse(JSON.stringify(queryComparisoQuery))
  delete queryComparisoQuery.comparisonQuery.collection.query.excludedProducts
  delete queryComparisoQuery.comparisonQuery.excludedProducts
  delete queryComparisoQuery.comparisonQuery.collection.excludedProducts
  return JSON.stringify(queryComparisoQuery)
}

export function needCategory(comparisonQuery, selectLookBook) {
  const isLookbook = isLookbookFn()
  const categorires = comparisonQuery?.collection?.query?.categories ?? []
  let needCategory = false
  if (isLookbook && selectLookBook) {
    needCategory = (selectLookBook.comparisonQuery.sellers ?? []).length === 0
  } else {
    needCategory = categorires.length === 0 || categorires[0].category === ''
  }
  return needCategory
}

/**
 * property map val to label
 */
const PropertyValToLabel = {
  Wool: 'Standard wool',
  // Cotton: 'Standard cotton',
}

/**
 * convert val to label
 * @param val
 */
export const propertyValToLabelFn = val => PropertyValToLabel[val] || val

