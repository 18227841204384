import React from 'react'
import classNames from 'classnames'
import { TextProps } from './types'
import styles from './styles.module.scss'

export const Text = ({
  children,
  xs,
  xxs,
  small,
  medium,
  large,
  xl,
  xxl,
  primary,
  secondary,
  accent,
  regular,
  semibold,
  bold,
  extrabold,
  black,
  oneLine,
  uppercase,
  className,
  isLoading,
  title,
  underline,
  strikeThrough,
  disabled,
  withoutText,
  onHoverChangeColor,
  style = {},
}: TextProps) => 
    <span
        className={classNames(
        styles.text,
        xs && styles.xs,
        xxs && styles.xxs,
        small && styles.small,
        medium && styles.medium,
        large && styles.large,
        xl && styles.xl,
        xxl && styles.xxl,
        primary && styles.primary,
        secondary && styles.secondary,
        accent && styles.accent,
        regular && styles.regular,
        semibold && styles.semibold,
        bold && styles.bold,
        extrabold && styles.extrabold,
        black && styles.black,
        oneLine && styles.oneLine,
        uppercase && styles.uppercase,
        isLoading && styles.isLoading,
        underline && styles.underline,
        strikeThrough && styles.strikeThrough,
        disabled && styles.disabled,
        withoutText && styles.withoutText,
        onHoverChangeColor && styles.onHoverChangeColor,
        className,
      )}
        title={title || ''}
        style={style}
    >
        {children}
    </span>

