import React from 'react'
import { Collapse } from '../Collapse'
import { ProductColors } from '../ProductColors'

export const AvailableOptions = ({
    colorList = [],
    onClick,
    bottom = 0,
}: {
    colorList: any[];
    onClick: (nornaid: string) => void;
    bottom?: number;
}) => {

    return (
        <Collapse title="Available options" showArrow={false} bottom={bottom}>
            <ProductColors
                colorList={colorList}
                onClick={color => {
                    onClick(color?.nornaid)
                }}
            />
        </Collapse>
    )
}
