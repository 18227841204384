import React, { CSSProperties } from 'react'
import { TableCell } from './TableCell'
import { CATEGORY_TOTAL, SELECTED_CATEGORY, SUMMARY } from 'consts';

export const LockIcon = ({
    size = 16,
}: {
    size?: number;
}) => {
    return (
        <svg
            style={{ marginRight: 0 }}
            width={size}
            height={size}
            viewBox="0 0 1024 1024"
            fill="#888"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M512 85.333333a170.666667 170.666667 0 0 1 170.666667 170.666667v85.333333h74.666666A96 96 0 0 1 853.333333 437.333333v405.333334A96 96 0 0 1 757.333333 938.666667H266.666667A96 96 0 0 1 170.666667 842.666667v-405.333334A96 96 0 0 1 266.666667 341.333333H341.333333V256a170.666667 170.666667 0 0 1 170.666667-170.666667z m245.333333 320H266.666667a32 32 0 0 0-32 32v405.333334c0 17.664 14.336 32 32 32h490.666666a32 32 0 0 0 32-32v-405.333334a32 32 0 0 0-32-32z m-245.333333 170.666667a64 64 0 1 1 0 128 64 64 0 0 1 0-128z m0-426.666667A106.666667 106.666667 0 0 0 405.333333 256v85.333333h213.333334V256A106.666667 106.666667 0 0 0 512 149.333333z" p-id="1476"></path>
        </svg>
    )
}

export const NotAllowedIcon = ({
    size = 12,
}: {
    size?: number;
}) => {
    return (
        <svg
            width={size}
            height={size}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="#888"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z" />
        </svg>
    )
}

export const TableLockCell = ({
    style,
    isDeepBorderRight,
    isEmptyBorderRight = false,
    isShadowBg,
    isWhiteBg,
    width,
    categoryName = '',
    showNotAllowedIcon = false,
}: {
    style?: CSSProperties;
    isDeepBorderRight?: boolean;
    isEmptyBorderRight?: boolean;
    isShadowBg?: boolean;
    isWhiteBg?: boolean;
    width?: number;
    categoryName?: string;
    showNotAllowedIcon?: boolean;
}) => {
    return (
        <TableCell
            isDeepBorderRight={isDeepBorderRight} 
            isEmptyBorderRight={isEmptyBorderRight}
            isShadowBg={isShadowBg}
            isWhiteBg={isWhiteBg}
            width={width}
            style={{ display: 'flex', justifyContent: 'center', ...style }}
        >
            {
                ([ CATEGORY_TOTAL, SELECTED_CATEGORY, SUMMARY ].includes(categoryName) && showNotAllowedIcon) ? (
                    <NotAllowedIcon />
                ) : (
                    <LockIcon />
                )
            }
        </TableCell>
    )
}
