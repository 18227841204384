import html2pdf from 'html-to-pdf-js'
import html2canvas from 'html2canvas'
import { format } from 'date-fns'
import { DateFormats } from 'consts'
import { downloadFile } from 'utils'
import AuthToken from 'utils/AuthToken'
import axios from 'axios'
import { CONST_VARS } from 'libs/const'
import { ExportPdfProps, ExportImgProps, ExportCsvProps, CopyImgToClipboardProps, Html2CanvasOptionsType } from './types'
import { jsPDF } from 'jspdf'
import { asyncUtils } from 'norna-uikit'
import { loadingBar } from 'hooks'

export const exportPdf = ({
    selector = '#export-wrapper',
    excludeSelectors = [],
    fileName,
    onSuccess,
    onFail,
    onComplete,
}: ExportPdfProps) => {
    const element: any = document.querySelector(selector)
    if (!element) return
    const eleWidth = element ? element.clientWidth : 900
    const eleHeight = element ? element.clientHeight : 1200

    html2pdf().set({
        margin: 0,
        filename: fileName + '.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
            ...getHtml2CanvasOptions(element, selector, excludeSelectors, { isKeepOriginScale: true }),
            scrollY: 0,
        },
        pagebreak: { mode: 'avoid-all' },        // 控制页面上断页行为
        allowTaint: true,
        useCORS: true,
        jsPDF: {
            unit: 'px',
            format: [ eleWidth, eleHeight + 30 ],
            // 宽度大于高度，横向(landscape)排版，宽度小于高度，竖向(portrait)排版
            orientation: eleWidth > eleHeight ? 'landscape' : 'portrait',
        },
    })
        .from(element)
        .save()
        .then(() => {
            onSuccess?.()
            onComplete?.()
        })
        .catch((e) => {
            console.log('e', e);
            onFail?.()
            onComplete?.()
        })
}

export const exportImg = ({
    selector = '#export-wrapper',
    excludeSelectors = [],
    fileName,
    onSuccess,
    onFail,
    scale,
    imgsSeletor,
    scrollHeight,
    onComplete,
    adjustElementFn,
    calcJpgWidthFn,
    calcJpgHeightFn,
}: ExportImgProps) => {
    const element: any = document.querySelector(selector)
    if (!element) return
    html2canvas(element, getHtml2CanvasOptions(element, selector, excludeSelectors, {
        adjustElementFn,
        calcJpgWidthFn,
        calcJpgHeightFn,
        scrollHeight,
        scale,
    }))
        .then(canvas => {
            const imageData = canvas.toDataURL('image/jpeg')
            const alink = document.createElement('a')
            alink.href = imageData
            alink.download = fileName + '.jpg'
            alink.click()
            onSuccess?.()
            onComplete?.()
        }).catch(() => {
            onFail?.()
            onComplete?.()
        })
}

/**
 * @decrepated at 11/08/2022
 * @param param0 
 */
export const exportCsv = ({
    fileName,
    fileContent,
    autoGeneDate = false,
}: ExportCsvProps) => {
    fileName = autoGeneDate ? fileName.replace(' ', '_') + '_' + format(new Date(), DateFormats.DEFAULT.replace(/-/ig, '_')) : fileName.replace(' ', '_')
    fileName += '.csv'
    downloadFile(fileName, fileContent, true)
}

export const copyImgToClipboard = ({
    selector = '#export-wrapper',
    excludeSelectors = [],
    adjustElementFn,
    calcJpgWidthFn,
    scale,
    scrollHeight,
    onSuccess,
    onFail,
    onComplete,
}: CopyImgToClipboardProps) => {
    const element: any = document.querySelector(selector)
    if (!element) return
    html2canvas(element, getHtml2CanvasOptions(element, selector, excludeSelectors, {
        adjustElementFn,
        scale,
        calcJpgWidthFn,
        scrollHeight,
    }))
        .then(canvas => {
            canvas.toBlob(blob => {
                if (!blob) return
                // eslint-disable-next-line
                // @ts-ignore 
                const data = [ new ClipboardItem({ 'image/png': blob }) ]
                // eslint-disable-next-line
                // @ts-ignore
                navigator.clipboard.write(data).then(() => {
                    console.log('Copied to clipboard successfully!')
                    onSuccess?.()
                    onComplete?.()
                }).catch(e => {
                    console.error('Unable to write to clipboard', e)
                    onFail?.()
                    onComplete?.()
                })
            })
        }).catch(() => {
            onFail?.()
            onComplete?.()
        })
}

// adjustElementFn = (element:HTMLDivElement) => { }
export const getHtml2CanvasOptions = (element, selector = '#export-wrapper', excludeSelectors: string[] = [], {
    adjustElementFn,
    calcJpgWidthFn,
    calcJpgHeightFn,
    scrollHeight,
    scale = 3,
    formHeight = 0,
    isKeepOriginScale = false,
}: Html2CanvasOptionsType = {}) => {
    let eleWidth = element ? element.clientWidth : 900
    let eleHeight = element ? (scrollHeight ? element.scrollHeight : element.clientHeight) : 1200
    if (calcJpgWidthFn) eleWidth = Math.max(calcJpgWidthFn(), eleWidth)
    if (calcJpgHeightFn) eleHeight= Math.max(calcJpgHeightFn(), eleHeight)

    // 对于有缩放的模块需要还原尺寸
    let zoom = 1
    const zoomContainer = element.querySelector('.norna-container-fixed-width')
    if (zoomContainer) {
        zoom = zoomContainer.style.zoom
    }
    if (zoom !== 1) {
        eleHeight = eleHeight / zoom + 60
        eleWidth = eleWidth / zoom + 80     // 80 是外层容器左右内边距
    } else {
        if (!isKeepOriginScale) {
            eleWidth += 40
            eleHeight += 60
        }
    }
    // 导出线图 excel 时, 图标不包含 form 表单, 需要将 form 表单的高度剔除
    if (formHeight) {
        eleHeight -= formHeight
    }
    return {
        // removeContainer:false,
        imageTimeout: 15000,            // 加载图片超时时间(毫秒)，设置为0表示不处理图片超时
        backgroundColor: '#fff',
        scale,                       // 渲染比例，值越大导出的文件越大
        useCORS: true,                  // 网页内有其它网址，比如图片地址，是否跨站处理这些地址
        width: eleWidth,                // canvas 画布宽度
        height: eleHeight,              // canvas 画布高度
        // 有垂直滚动条时截图不完整, 加上 windowHeight 解决问题
        windowHeight: eleHeight * 1.5,
        scrollY: -Math.floor(window.scrollY),       // 不设置 scrollY 时，滚动条有滑动时导出文件，会发现文件的顶部有留白，留白的高度就是滚动条的高度
        onclone: async element => {
            // 导出 dom 加内边距
            const wrapper = element.querySelector(selector)
            if (wrapper) {
                wrapper.style['padding-left'] = '40px'
                wrapper.style['padding-top'] = '30px'
            }
            await adjustElementFn?.(element)

            /**
             * 问题：canvas 不渲染 svg
             * 原因：canvas 不会处理 svg style 属性
             * ref: https://stackoverflow.com/questions/32481054/svg-not-displayed-when-using-html2canvas
             */
            const svgElements = element.querySelectorAll('svg')
            svgElements.forEach(function (item: any) {
                item.setAttribute('width', item.getBoundingClientRect().width)
                item.setAttribute('height', item.getBoundingClientRect().height)
                item.style.width = null
                item.style.height = null
            })

            /**
             * canvas 不能很好兼容 transition 属性，会导致元素错位问题
             * 对需要去除 transition 属性的 dom 元素添加 class="canvas-transition"，避免遍历全部后代元素造成资源浪费
             */
            const transitionElements = element.querySelectorAll('.canvas-transition')
            transitionElements.forEach(function (item: any) {
                item.style.transition = 'none'
            })

            /**
             * Switch：canvas 不能很好兼容 translate 属性，单独处理
             */
            const switchElements = element.querySelectorAll('.switch-handle')
            switchElements.forEach(function (item: any) {
                if (item.style.transform) {
                    if (item.style.transform === 'translate(17px)') {
                        item.style.left = '17px'
                    } else {
                        item.style.left = '0px'
                    }
                    item.style.transform = ''
                    item.style.transition = ''
                }
            })

            /**
             * DatePicker 中有 input，会错位
             */
            const datePickerInputElements = element.querySelectorAll('.DatePicker > input')
            datePickerInputElements.forEach((item: any) => {
                item.style.paddingTop = '4px'
            })

            /**
             * 导出内容排除 excludeSelectors 中的 dom 对象
             */
            excludeSelectors.forEach(s => {
                // element 是整个文档，一个页面可能有多个相同名称的 className
                // 因此，先用 querySelector(selector) 过滤一下
                const child = element.querySelector(selector)?.querySelector(s)
                child.style.display = 'none'
            })

            /**
             * norna-container-fixed-width
             */
            const zoomedElements = element.querySelectorAll('.norna-container-fixed-width')
            zoomedElements.forEach(item => {
                item.style['pointer-events'] = 'none'
                item.style.zoom = 1
                const form = element.querySelector('.form')
                if (form) {
                    form.style.width = '1440px'
                }
            })

            return element
        },
    }
}

/**
 * 获取 html 转 canvas 得到的图片对象
 */
export async function getHtml2CanvasImgData({
    selector,
    excludeSelectors,
    formHeight = 0,
}) {
    const element: any = document.querySelector(selector)
    if (!element) return
    const canvas: any = await html2canvas(element, getHtml2CanvasOptions(element, selector, excludeSelectors, { formHeight }))

    // imageData 是 base64 字符串
    const imageData = canvas.toDataURL('image/jpeg')

    // 本地另存图片
    // const alink = document.createElement('a')
    // alink.href = imageData
    // alink.download =  'a.jpg'
    // alink.click()

    const blob = dataURLtoFile(imageData, 'image/jpeg')
    const fileOfBlob = new File([ blob ], new Date() + '.jpg')
    const formData = new FormData()
    formData.append('graph_image', fileOfBlob)
    return formData
}

function dataURLtoFile(dataURI: any, type: any) {
    const binary = atob(dataURI.split(',')[1])
    const array: number[] = []
    // eslint-disable-next-line
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }
    return new Blob([ new Uint8Array(array) ], { type })
}

/**
 * 将 selector 对应 html 转为图片并上传到服务端, 返回服务端保存的图片路径
 */
export async function getHtml2CanvasImgUploadPath({
    selector,
    excludeSelectors = [],
    formHeight = 0,
}: {
    selector: string;
    excludeSelectors?: string[];
    formHeight?: number;
}) {
    const formData = await getHtml2CanvasImgData({ selector, excludeSelectors, formHeight })
    const tk = AuthToken.getAccessToken()
    const result = await axios.request({
        url: CONST_VARS.API_URL + '/dashboard/upload_graph_image/',
        method: 'POST',
        data: formData,       // body 传参
        headers: { authorization: 'Bearer ' + tk },
    })
    if (result.status !== 200) {
        return
    }
    return result?.data?.upload_path
}

/** 
 * root是要监听的根元素，我们要对root节点内的img元素进行监听* 这里用到Promise.all
 * 
*/
export const onAllImgLoaded = async (ele: HTMLElement, imgsSeletor = 'img') => {
    // 为了使用Array的map方法
    const imgNodes = ele instanceof HTMLImageElement ? [ ele ] : ele.querySelectorAll(imgsSeletor)

    const imgArr = Array.prototype.slice.call(imgNodes)
    // eslint-disable-next-line no-return-await
    return Promise.all(
        imgArr.map(img =>
            new Promise(resolve => {
                img.style.display = 'block'
                if (img.complete) {
                    resolve(img)
                } else {
                    img.addEventListener('load', () => resolve(img))
                }

            }),
        ),
    )
}

export const exportImgUtil = ({
    selector,
    excludeSelectors,
    adjustElementFn,
    scale,
    scrollHeight,
    calcJpgWidthFn,
    calcJpgHeightFn,
    snackRef,
    disableLazyLoad,
    afterFn,
}: any) => {
    copyImgToClipboard({
        selector,
        excludeSelectors,
        adjustElementFn,
        scale,
        scrollHeight,
        calcJpgWidthFn,
        calcJpgHeightFn,
        onSuccess: () => {
            snackRef.current?.open?.('Copying image to clipboard succeeded')
            disableLazyLoad(false)
            afterFn?.()
        },
        onFail: () => {
            snackRef.current?.open?.('Failed to copy image to clipboard')
            disableLazyLoad(false)
            afterFn?.()
        },
    })
}

/* ************************ dynamic dashboard report ************************ */
export async function getCanvasBySelector({
    selector,
}: {
    selector: string;
}) {
    const element: any = document.querySelector(selector)
    if (!element) return
    const canvas = await html2canvas(element, getHtml2CanvasOptions(element, selector))
    return canvas
}

export async function getImageDataBySelector({
    selector,
}: {
    selector: string;
}): Promise<string> {
    const canvas = await getCanvasBySelector({ selector }) as HTMLCanvasElement
    const imageData = canvas.toDataURL('image/jpeg') as string
    return imageData
}

export const exportPdfForDynamicDashboardReport = async ({
    selectors = [],
    fileName = 'unknown.pdf',
    onFinish,
}: {
    selectors: string[];
    fileName?: string;
    onFinish?: () => void;
}) => {
    if (!selectors?.length) return

    try {
        loadingBar.restart()
        const firstSelector = selectors[0]
        const firstEl = document.querySelector(firstSelector) as HTMLDivElement
        if (!firstEl) return

        const doc = new jsPDF({
            format: [ firstEl.offsetWidth, firstEl.offsetHeight ],
            unit: 'px',
            orientation: firstEl.offsetWidth > firstEl.offsetHeight ? 'l' : 'p',
        })

        for (let i = 0; i < selectors.length; i++) {
            const selector = selectors[i]
            const element = document.querySelector(selector) as HTMLDivElement
            if (!element) continue
            const width = element.offsetWidth
            const height = element.offsetHeight
            if (i > 0) {
                doc.addPage(
                    [ width, height ], 
                    width > height ? 'l' : 'p',
                )
            }
            const imageData = await getImageDataBySelector({ selector })
            doc.addImage(imageData, 'JPEG', 0, 0, width, height)
            await asyncUtils.sleep(500)
        }

        doc.save(fileName.endsWith('.pdf') ? fileName : fileName + '.pdf')
        loadingBar.done()
        onFinish?.()
    } catch (e) {
        loadingBar.done()
        onFinish?.()
    }
}
