import React, { useEffect, useState } from 'react'
import { getDate, getMonth,  getYear } from 'date-fns'
import { dateUtils } from 'druikit'
import { Calendar, DayValue } from 'norna-react-modern-calendar-datepicker'
import 'norna-react-modern-calendar-datepicker/lib/DatePicker.css'

const getDefaultDate = (maxDateRange): any => {
    if (!maxDateRange) return
    const [ minDateStr, maxDateStr ] = maxDateRange.split('_')
    const minDate = dateUtils.format(new Date(minDateStr))
    const maxDate = dateUtils.format(new Date(maxDateStr))
    const minimumDate = {
        year: minDate.year,
        month: minDate.month,
        day: minDate.day,
    }
    const maximumDate = {
        year: maxDate.year,
        month: maxDate.month,
        day: maxDate.day,
    }
    return {
        minimumDate,
        maximumDate,
    }    
}

const DateRangePanel = ({
    value = '',
    onChange,
    maxDateRange,
}: {
    value?: string;
    onChange?: (value: string) => void;
    maxDateRange?: string;
}) => {
    const [ selectedValue, setSelectedValue ] = useState<any>(parseDateRangePickerValue(value))

    useEffect(() => {
        const newValue = parseDateRangePickerValue(value)
        if (newValue === selectedValue) return
        setSelectedValue(newValue)
    }, [ value ])

    const [ minimumDate, setMinimumDate ] = useState<any>(getDefaultDate(maxDateRange)?.minimumDate)
    const [ maximumDate, setMaximumDate ] = useState<any>(getDefaultDate(maxDateRange)?.maximumDate)
    
    useEffect(() => {
        if (!maxDateRange) return
        const { minimumDate, maximumDate } = getDefaultDate(maxDateRange)
        setMinimumDate(minimumDate)
        setMaximumDate(maximumDate)
    }, [ maxDateRange ])

    return (
        <Calendar
            colorPrimary="#01a699"
            colorPrimaryLight="rgba(1, 166, 153, 0.5)"
            maximumDate={maximumDate}
            minimumDate={minimumDate}
            value={selectedValue}
            onChange={(v: any) => {
                setSelectedValue(v)
                if (v?.from && v?.to) {
                    onChange?.(stringifyDateRangePickerValue(v))
                }
            }}
        />
    )
}

export default DateRangePanel

export enum DateFormats {
    DEFAULT = 'yyyy-MM-dd',
    YYMMDD = 'yyMMdd',
}

/**
 * 字符串转对象
 * 
 * value 格式如下：
 * '2021-11-05_2021-11-05'
 * 
 * parseDateRangePickerValue(value) => { from: { year: 2021, month: 11, day: 5 }, to: { year: 2021, month: 11, day: 5 } }
 */
export const parseDateRangePickerValue = value => {
    if (!value) return { from: null, to: null }
    const rangeStr = value.split('_')
    const dateStart = new Date(rangeStr[0])
    const dateEnd = new Date(rangeStr[1])

    return {
        from: {
            day: getDate(dateStart),
            month: getMonth(dateStart) + 1,
            year: getYear(dateStart),
        },
        to: {
            day: getDate(dateEnd),
            month: getMonth(dateEnd) + 1,
            year: getYear(dateEnd),
        },
    }
}

/**
 * 对象转字符串
 * 
 * value 的格式如下：
 * value = { from: { year: 2021, month: 11, day: 5 }, to: { year: 2021, month: 11, day: 5 } }
 * 
 * stringifyDatePickerValue(value) =>  '2021-11-05_2021-11-05'
 */
export const stringifyDateRangePickerValue = value => formatDatePickerToDate(value.from) + '_' + formatDatePickerToDate(value.to)

export const formatDatePickerToDate = (day: DayValue): string => {
    const d = datePickerToDate(day)
    return dateUtils.format(d).ymd
}

/**
 * 将 DayValue 值转换为日期类型（JS 的 Date 类型）
 * 
 * DayValue 是 DatePicker 组件的数据结构，格式如：{year: number; month: number; day: number}
 * 
 * ```
 * dayValue = { year: 2022, month: 2, day: 17 }
 * datePickerToDate(dayValue)   // 相当于 new Date(2022, 1, 17)
 * ```
 */
export const datePickerToDate = (date: DayValue): Date => {
    let d = new Date()
  
    if (date && date.year) {
      d = new Date(date.year, date.month - 1, date.day)
    }
  
    return d
}
