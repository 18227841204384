import React, { ReactNode, useState } from 'react'
import classnames from 'classnames'
import { ProductPrice } from 'componentsv2/business/ProductPrice'
import { LazyBgImg } from 'components/Image'
import { isCustomerVendor, vendorShortMappingFn } from 'utils'
import { CaretIcon } from 'assets/icons/Caret'
import { Text } from 'components/typography/Text'
import { TagWithTips } from 'componentsv2/MenuBar/FilterTags'
import { formatVendorName } from 'utils/sellers'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ProductProps } from './types'
import styles from './styles.module.scss'

export const Table = ({
    dataSource = [],
    onClick,
}: {
    dataSource: any[];
    onClick?: (product: any) => void;
}) => {
    if (!dataSource.length) return null

    return (
        <>
            {
                dataSource.map((item: any, rowIndex) => (
                    <Row
                        key={item.vendor + item.category}
                        vendor={item.vendor}
                        name={item.name}
                        categoryName={item.category}
                        products={item.products}
                        onClick={onClick}
                    />
                ))
            }
        </>
    )
}

export const Row = ({
    name,
    vendor,
    categoryName,
    products = [],
    onClick,
}: {
    name: string;
    vendor: string;
    categoryName: string;
    products?: any[];
    onClick?: (product: any) => void;
}) => {
    let content: ReactNode = null

    if (!products.length) {
        content = (
            <div className={styles.noProducts}>
                <Text secondary>No products found</Text>
            </div>
        )
    } else {
        content = products.map((product, index) => (
            <ProductCell 
                key={product.nornaid + product.image}
                product={product}
                onClick={onClick}
                index={index + 1}
            />
        ))
    }

    const [ arrowDirection, setArrowDirection ] = useState<'right' | 'left'>('right') 

    return (
        <div className={styles.tr}>
            <VendorCell name={name} categoryName={categoryName} highlight={isCustomerVendor(vendor)} />
            <div className={styles.productWrapper}>
                <div
                    className={styles.productAnimation}
                    style={{ left: arrowDirection === 'right' ? '0px' : '-1260px' }}
                >
                    {content}
                </div>
            </div>
            <ArrowCell
                direction={arrowDirection}
                showArrowIcon={products.length > 7}
                onClick={() => setArrowDirection(arrowDirection === 'right' ? 'left' : 'right')}
            />
        </div>
    )
}

export const VendorCell = ({
    name,
    highlight = false,
    categoryName,
}: {
    name: string;
    highlight?: boolean;
    categoryName: string;
}) => {
    return (
        <div className={classnames(highlight ? styles.tableHeaderSelf : styles.tableHeaderOthers)}>
            <TagWithTips align="center" label={formatVendorName(name).name} width="130px" />
            <TagWithTips align="center" style={{ fontSize: '12px' }} label={formatVendorName(name).platform} width="130px" />
            <TagWithTips align="center" style={{ fontSize: '12px' }} label={categoryName} width="130px" />
        </div>
    )
}

export const ProductCell = ({
    product,
    index,
    onClick,
}: {
    product: ProductProps;
    index: number;
    onClick?: (product: any) => void;
}) => {
    return (
        <div
            className={styles.productItem}
            onClick={() => onClick?.(product)}
        >
            <div className={styles.badge}>
                {index}
            </div>
            <div className={styles.colImg}>
                <LazyBgImg 
                    src={product.image}
                    width={50}
                    height={65}
                />
            </div>
            <div className={styles.colInfo}>
                <div className={styles.colBrand} title={product.brand}>
                    {vendorShortMappingFn(product.brand)}
                </div>
                <div className={styles.colName} title={product.name}>
                    {product.name}
                </div>
                <ProductPrice
                    leftPrice={product.originalPrice}
                    rightPrice={product.actualPrice}
                    currency={product?.currency}
                />
            </div>
        </div>
    )
}

export const ArrowCell = ({
    showArrowIcon = false,
    direction = 'right',
    onClick,
}: {
    direction?: 'right' | 'left',
    showArrowIcon?: boolean;
    onClick?: () => void;
}) => {
    const isRightArrow = direction === 'right'

    return (
        <div className={styles.tableArrow}>
            <ShouldRender shouldRender={showArrowIcon}>
                <div className={styles.arrowWrap} onClick={() => onClick?.()}>
                    <CaretIcon 
                        className={isRightArrow ? styles.arrowRight : styles.arrowLeft} 
                        color="#74b6b6" 
                    />
                    <div className={styles.arrowTip}>
                        { isRightArrow ? 'See more products' : 'See less products' }
                    </div>
                </div>
            </ShouldRender>
        </div>
    )
}
