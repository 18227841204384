export const width01 = 0.0372
export const width02 = 0.046
export const getPercent = value => value * 100 + '%'

// level 3 title
export const vendorsWidth = 0.074
export const avgPriceStartWidth = width01
export const avgPriceEndWidth = width01
export const avgPriceChangeWidth = width01
export const avgPricePercentWidth = width02
export const identicalOptionsWidth = width02
export const discountsAddedCountWidth = width01
export const discountsAddedChangeWidth = width01
export const discountsAddedPercentWidth = width02
export const discountsRemovedCountWidth = width01
export const discountsRemovedChangeWidth = width01
export const discountsRemovedPercentWidth = width02
export const discountsOptionsCountWidth = width01
export const discountsOptionsChangeWidth = width01
export const discountsOptionsPercentWidth = width02
export const fullPriceCountWidth = width01
export const fullPriceChangeWidth = width01
export const fullPricePercentWidth = width02
export const optionsInCountWidth = width01
export const optionsInChangeWidth = width01
export const optionsInPercentWidth = width02
export const optionsOutCountWidth = width01
export const optionsOutChangeWidth = width01
export const optionsOutPercentWidth = width02 

// level 2 title
export const discountsAddedWidth = discountsAddedCountWidth + discountsAddedChangeWidth + discountsAddedPercentWidth
export const discountsRemovedWidth = discountsRemovedCountWidth + discountsRemovedChangeWidth + discountsRemovedPercentWidth
export const discountChangesWidth = discountsOptionsCountWidth + discountsOptionsChangeWidth + discountsOptionsPercentWidth
export const fullPriceChangesWidth = fullPriceCountWidth + fullPriceChangeWidth + fullPricePercentWidth
export const optionsInWidth = optionsInCountWidth + optionsInChangeWidth + optionsInPercentWidth
export const optionsOutWidth = optionsOutCountWidth + optionsOutChangeWidth + optionsOutPercentWidth 

// level 1 title
export const avgPriceWidth = avgPriceStartWidth + avgPriceEndWidth + avgPriceChangeWidth + avgPricePercentWidth
export const priceChangesWidth = identicalOptionsWidth + discountsAddedWidth + discountsRemovedWidth + discountChangesWidth + fullPriceChangesWidth
export const productMixChangesWidth = optionsInWidth + optionsOutWidth

export const vendorsWidthStr = getPercent(vendorsWidth)
export const avgPriceStartWidthStr = getPercent(avgPriceStartWidth)
export const avgPriceEndWidthStr = getPercent(avgPriceEndWidth)
export const avgPriceChangeWidthStr = getPercent(avgPriceChangeWidth)
export const avgPricePercentWidthStr = getPercent(avgPricePercentWidth)
export const identicalOptionsWidthStr = getPercent(identicalOptionsWidth)
export const discountsAddedCountWidthStr = getPercent(discountsAddedCountWidth)
export const discountsAddedChangeWidthStr = getPercent(discountsAddedChangeWidth)
export const discountsAddedPercentWidthStr = getPercent(discountsAddedPercentWidth)
export const discountsRemovedCountWidthStr = getPercent(discountsRemovedCountWidth)
export const discountsRemovedChangeWidthStr = getPercent(discountsRemovedChangeWidth)
export const discountsRemovedPercentWidthStr = getPercent(discountsRemovedPercentWidth)
export const discountsOptionsCountWidthStr = getPercent(discountsOptionsCountWidth)
export const discountsOptionsChangeWidthStr = getPercent(discountsOptionsChangeWidth)
export const discountsOptionsPercentWidthStr = getPercent(discountsOptionsPercentWidth)
export const fullPriceCountWidthStr = getPercent(fullPriceCountWidth)
export const fullPriceChangeWidthStr = getPercent(fullPriceChangeWidth)
export const fullPricePercentWidthStr = getPercent(fullPricePercentWidth)
export const optionsInCountWidthStr = getPercent(optionsInCountWidth)
export const optionsInChangeWidthStr = getPercent(optionsInChangeWidth)
export const optionsInPercentWidthStr = getPercent(optionsInPercentWidth)
export const optionsOutCountWidthStr = getPercent(optionsOutCountWidth)
export const optionsOutChangeWidthStr = getPercent(optionsOutChangeWidth)
export const optionsOutPercentWidthStr = getPercent(optionsOutPercentWidth) 

export const discountsAddedWidthStr = getPercent(discountsAddedWidth)
export const discountsRemovedWidthStr = getPercent(discountsRemovedWidth)
export const discountChangesWidthStr = getPercent(discountChangesWidth)
export const fullPriceChangesWidthStr = getPercent(fullPriceChangesWidth)
export const optionsInWidthStr = getPercent(optionsInWidth)
export const optionsOutWidthStr = getPercent(optionsOutWidth)

export const avgPriceWidthStr = getPercent(avgPriceWidth)
export const priceChangesWidthStr = getPercent(priceChangesWidth)
export const productMixChangesWidthStr = getPercent(productMixChangesWidth)
