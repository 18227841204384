import React, { FC, useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import { Spin } from 'componentsv2/Spin'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { HideWhenOpenInNew, OpenInNewButton, OPEN_IN_NEW_DATE, ShowWhenOpenInNew } from 'componentsv2/business/OpenInNewButton'
import { BootstrapTooltip } from 'componentsv2/TradingTable/BootstrapTooltip'
import { LookbookWithoutData } from 'componentsv2/LookbookPlaceholder'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ProductsModal } from 'components/ProductsModalV2'
import { argFnAveragePriceComparison } from 'graphql/nornaapi'
import { formatDateStr2 } from 'utils/dateUtils'
import { useFetch } from 'libs/hookRequest'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { vendorShortMappingFn, getFractionDigits, getVendorNameByCode } from 'utils'
import { formatVendorName } from 'utils/sellers'
import { isSuperArray, sortVendorList } from 'utils/array'
import { useLookbook } from 'hooks/useLookbook'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { useExcludeProductsComparisonQuery, useManualLoading } from 'hooks'
import { useWindowWidth, useWindowZoom } from 'hooks/useWindow'
import { downloadFileByAxios } from 'export'
import { cloneDeep } from 'lodash'
import { isSubsetPayload, UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, UPDATE_NEWCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { TagWithTips } from 'componentsv2/MenuBar/FilterTags'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import useDialogTitle from 'hooks/useDialogTitle'
import { usePageDate } from 'hooks/usePageDate'
import { isCompetitorGroupChanged } from 'utils/group'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { IS_LIVE_ENV, NA } from 'consts'
import { useFilterCompetitor, useFilterCountry, useFilterCurrency, useFilterPricePerspective } from 'hooks/useFilter'
import { SizedBox } from 'componentsv2/SizedBox'
import { priceDriversPeriodToPeriodExportFilename } from 'utils/exportUtils'
import { PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME } from 'configs'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { useCompetitorOptions } from 'hooks/useOptions'
import { useModuleData } from 'hooks/useModuleData'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { Table, ColumnProps } from './components/Table'
import { PriceHistoryDriversProps } from './types'
import { geneCsvData, getZoom } from './utils'
import * as config from './config'
import styles from './styles.module.scss'
import { storage } from 'utils/storage'
import GraphButton from 'componentsv2/GraphButton'
import { whetherLock } from 'utils/lockSellerUtils'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_PRICE_DRIVERS_PERIOD_TO_PERIOD } from 'features/filters/moduleDataSlice.const'
import { dateRangeUtils, numberUtils } from 'norna-uikit'

export const PriceDriversPeriodToPeriodTable: FC<PriceHistoryDriversProps> = () => {
    const { fnUseAppendDialogTitlePriceFilter } = useDialogTitle()
    const { pageDate: dateRangeValue, comparisonPageDate } = usePageDate()
    const { manualLoading, showManualLoading } = useManualLoading()
    const windowWidth = useWindowWidth()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const [ filterCurrency ] = useFilterCurrency()
    const { competitorOptions } = useCompetitorOptions()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterCountry ] = useFilterCountry()
    const isLiveAndFullPrice = IS_LIVE_ENV && filterPricePerspective === 'All full'
    const zoom = useWindowZoom()

    const [ moduleData, setModuleData ] = useModuleData(PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME)
    const { 
        fetchPayload = {}, 
        apiData = {},
        oldCompetitorValue = [], 
        newCompetitorValue = [],
    } = moduleData

    /* ************************************ Export excel ******************************************* */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        payload.data.groups = getGroupsPayload({ competitorSellers: filterCompetitor })
        payload.data.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
        await downloadFileByAxios({
            filename: priceDriversPeriodToPeriodExportFilename,
            payload,
        })
    }

    /* ************************************ Lookbook ******************************************* */
    const { checked: checkedLookbooks, isLookbook } = useLookbook()
    const { judgeActionOccurs, clearLookbookActions } = useLookbookAction(true, true)
    const [ lookbookWithoutData, setLookbookWithoutData ] = useState(false)

    /** ****************************** Form ************************************ */
    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: {
                competitorValue: cloneDeep(filterCompetitor.map(item => item.vendor)),
            },
        })
    }, [ filterCompetitor ])

    /** ****************************** Data ************************************ */
    const { postFn: fetch, data, loading, setData } = useFetch()
    const [ dataSource, setDataSource ] = useState([])
    const [ tableZoom, setTableZoom ] = useState(1)
    const [ , setCsvData ] = useCsvDataModuleData()

    const fetchData = async () => {
        if (!dateRangeValue || !comparisonPageDate || !competitorOptions?.length) return
        
        const query = cloneDeep(comparisonQuery)
        query.sellers = getCompetitorPayload({
            competitorSellers: [ ...filterCompetitor ],
        })
        query.market = getMarketPayload({
            competitorSellers: filterCompetitor,
            competitorOptions,
        })
        query.groups = getGroupsPayload({
            competitorSellers: [ ...filterCompetitor ],
        })
        const payload = argFnAveragePriceComparison(query, dateRangeUtils.from(dateRangeValue), checkedLookbooks, dateRangeUtils.from(comparisonPageDate))
        
        // if the query conditions are the same, no request is sent
        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload }) && Object.keys(apiData || {}).length) {
            showManualLoading()
            setData?.(apiData)
            return
        }
        
        setModuleData({
            type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE,
            payload: cloneDeep(payload),
        })
        await fetch(payload.url, payload)
    }

    const handleData = apiData => {
        setLookbookWithoutData(isLookbook && Array.isArray(apiData?.price_effected) && apiData?.price_effected?.length === 0)
        if (!Array.isArray(apiData?.price_effected)) return

        apiData = cloneDeep(apiData)
        const sellers = storage.getSellers()

        if (!isLookbook) {
            apiData.price_effected = apiData.price_effected.filter(item => newCompetitorValue.includes(item.seller.vendor))
        }

        const data = sortVendorList({
            vendorList: apiData.price_effected,
            vendorField: 'seller.vendor',
        })

        const newDataSource = data.map(item => {
            const queryDateValid = isQueryDateValid({
                vendor: item.seller.vendor,
                region: filterCountry,
                competitorValue: newCompetitorValue,
            })
            return {
                queryDateValid,
                originalVendor: item?.seller?.vendor,
                isLock: whetherLock({
                    vendorCode: item?.seller?.vendor,
                    region: item?.seller?.region,
                    sellers,
                    selectedVendorCodeList: filterCompetitor.map(c => c.vendor),
                }),
                // Vendors
                vendor: getVendorNameByCode(item?.seller?.vendor),
                // Average price
                startAveragePrice: numberUtils.formatNumberByComma(Number(item?.start_average_price).toFixed(getFractionDigits())),
                endAveragePrice: numberUtils.formatNumberByComma(Number(item?.end_average_price).toFixed(getFractionDigits())),
                // Effect on avg. price (SEK)
                averagePriceChanged: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.average_price_changed).toFixed(getFractionDigits())), item?.average_price_changed),
                // Effect on avg. price (%)
                averagePriceChangedPercentage: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.average_price_changed_percentage * 100).toFixed(1)) + '%', item?.average_price_changed_percentage),
                // Identical option ratio
                seasonality: numberUtils.formatNumberByComma(Number(item?.seasonality * 100).toFixed(1)) + '%',
                // Discounts added
                discountOptionsAddedProductCount: numberUtils.formatNumberByComma(item?.discount_options_added?.product_count),
                discountOptionsAddedProducts: item?.discount_options_added?.products,
                discountOptionsAddedPriceChanged: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(isLiveAndFullPrice ? 0 : item?.discount_options_added?.total_price_changed).toFixed(getFractionDigits())), item?.discount_options_added?.total_price_changed),
                discountOptionsAddedPercentage: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(isLiveAndFullPrice ? 0 : item?.discount_options_added?.average_price_changed_percentage * 100).toFixed(1)) + '%', item?.discount_options_added?.average_price_changed_percentage),
                // Discounts removed
                discountOptionsRemovedProductCount: numberUtils.formatNumberByComma(item?.discount_options_removed?.product_count),
                discountOptionsRemovedProducts: item?.discount_options_removed?.products,
                discountOptionsRemovedPriceChanged: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(isLiveAndFullPrice ? 0 : item?.discount_options_removed?.total_price_changed).toFixed(getFractionDigits())), item?.discount_options_removed?.total_price_changed),
                discountOptionsRemovedPercentage: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(isLiveAndFullPrice ? 0 : item?.discount_options_removed?.average_price_changed_percentage * 100).toFixed(1)) + '%', item?.discount_options_removed?.average_price_changed_percentage),
                // Discount changes
                furtherChangesProductCount: numberUtils.formatNumberByComma(item?.discount_price_changed?.product_count),
                furtherChangesProducts: item?.discount_price_changed?.products,
                furtherChangesPriceChanged: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(isLiveAndFullPrice ? 0 : item?.discount_price_changed?.total_price_changed).toFixed(getFractionDigits())), item?.discount_price_changed?.total_price_changed),
                furtherChangesPercentage: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(isLiveAndFullPrice ? 0 : item?.discount_price_changed?.average_price_changed_percentage * 100).toFixed(1)) + '%', item?.discount_price_changed?.average_price_changed_percentage),
                // Options in
                optionsAddedProductCount: numberUtils.formatNumberByComma(item?.options_added?.product_count),
                optionsAddedProducts: item?.options_added?.products,
                optionsAddedPriceChanged: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.options_added?.total_price_changed).toFixed(getFractionDigits())), item?.options_added?.total_price_changed),
                optionsAddedPercentage: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.options_added?.average_price_changed_percentage * 100).toFixed(1)) + '%', item?.options_added?.average_price_changed_percentage),
                // Options out
                optionsRemovedProductCount: numberUtils.formatNumberByComma(item?.options_removed?.product_count),
                optionsRemovedProducts: item?.options_removed?.products,
                optionsRemovedPriceChanged: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.options_removed?.total_price_changed).toFixed(getFractionDigits())), item?.options_removed?.total_price_changed),
                optionsRemovedPercentage: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.options_removed?.average_price_changed_percentage * 100).toFixed(1)) + '%', item?.options_removed?.average_price_changed_percentage),
                // Full price changes
                unannoucedPriceChangesProductCount: numberUtils.formatNumberByComma(item?.original_price_changed?.product_count),
                unannoucedPriceChangesProducts: item?.original_price_changed?.products,
                unannoucedPriceChangesPriceChanged: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.original_price_changed?.total_price_changed).toFixed(getFractionDigits())), item?.original_price_changed?.total_price_changed),
                unannoucedPriceChangesPercentage: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.original_price_changed?.average_price_changed_percentage * 100).toFixed(1)) + '%', item?.original_price_changed?.average_price_changed_percentage),
                // Currency changed
                currencyRateChanged: handlePrefixSymbol(numberUtils.formatNumberByComma(Number(item?.currency_rate_changed).toFixed(getFractionDigits())), item?.currency_rate_changed),
            }
        })
        const zoom = getZoom(newDataSource)
        setTableZoom(zoom)
        setDataSource(newDataSource)
        setCsvData({
            [CSV_DATA_PRICE_DRIVERS_PERIOD_TO_PERIOD]: geneCsvData({ dataSource: data }),
        })
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, dateRangeValue, comparisonPageDate, checkedLookbooks, competitorOptions ])

    // monitor Filter > Competitor
    useDeepCompareEffect(() => {
        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (newCompetitorValue.length > 0 && isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleData(cloneDeep(apiData))
            return
        }

        fetchData()
    }, [ newCompetitorValue, [] ])

    useEffect(() => {
        if (!data) return
        
        handleData(cloneDeep(data))
        setModuleData({ apiData: cloneDeep(data) })
        // eslint-disable-next-line
    }, [data, windowWidth])

    const columns: ColumnProps[] = [
        {
            title: (
                <div style={{ textAlign: 'center' }}>Vendors</div>
            ),
            width: config.vendorsWidthStr,
            name: 'vendor',
            className: styles.deepBorderRight,
            render: text => (
                <div style={{ width: '100%' }}>
                    <div className={styles.ellipsis} title={text}>
                        <TagWithTips align="center" width="auto" style={{ fontSize: '13px' }} label={formatVendorName(text).name} />
                    </div>
                    <div className={styles.ellipsis} title={text}>
                        <TagWithTips align="center" width="auto" style={{ fontSize: '12px', transform: 'scale(calc(11 / 12))', color: '#666' }} label={formatVendorName(text).platform} />
                    </div>
                </div>
            ),
        },
        {
            title: (
                <BootstrapTooltip title={`The average price for the comparison time period (${formatDateStr2(comparisonPageDate)}).`}>
                    <div style={{ lineHeight: '14px' }}>Start period</div>
                </BootstrapTooltip>
            ),
            width: config.avgPriceStartWidthStr,
            children: [
                {   
                    name: 'startAveragePrice',
                    width: config.avgPriceStartWidthStr,
                    className: classnames([ styles.greyBg, styles.normalBorderRight ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
        {
            title: (
                <BootstrapTooltip title={`The average price for the selected time period (${formatDateStr2(dateRangeValue)}).`}>
                    <div style={{ lineHeight: '14px' }}>End period</div>
                </BootstrapTooltip>
            ),
            width: config.avgPriceEndWidthStr,
            children: [
                {
                    name: 'endAveragePrice',
                    width: config.avgPriceEndWidthStr,
                    className: classnames([ styles.greyBg, styles.normalBorderRight ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
        {
            title: (
                <BootstrapTooltip title="The relative (%) average price change between the selected time period and the comparison time period.">
                    <div>Change</div>
                </BootstrapTooltip>
            ),
            width: config.getPercent(config.avgPriceChangeWidth + config.avgPricePercentWidth),
            children: [
                {
                    name: 'averagePriceChanged',
                    width: config.avgPriceChangeWidthStr,
                    className: classnames([ styles.greyBg, styles.normalBorderRight ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
                {
                    name: 'averagePriceChangedPercentage',
                    width: config.avgPricePercentWidthStr,
                    className: styles.deepBorderRight,
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
        {
            title: (
                <BootstrapTooltip title="Options that were previously at full price that received a discount during the selected time period.">
                    <div>Discounts added</div>
                </BootstrapTooltip>
            ),
            width: config.discountsAddedWidthStr,
            children: [
                {
                    name: 'discountOptionsAddedProductCount',
                    width: config.discountsAddedCountWidthStr,
                    className: classnames([ styles.shadowBg, styles.normalBorderRight ]),
                    render: (text, record) => {
                        const value = record?.queryDateValid ? text : NA
                        if (value === NA) return <div>{value}</div>

                        const products = record?.discountOptionsAddedProducts || []
                        return (
                            <div
                                className={classnames({ [styles.clickable]: products.length > 0, 'cell-absolute cell-mouse-effect': products.length > 0 })}
                                onClick={() => {
                                    onShowProductModal({
                                        vendor: record.vendor,
                                        products,
                                        title: 'Discounts added',
                                    })
                                }}
                            >
                                {value}
                            </div>
                        )
                    },
                },
                {
                    name: 'discountOptionsAddedPriceChanged',
                    width: config.discountsAddedChangeWidthStr,
                    className: classnames([ styles.shadowBg, styles.normalBorderRight ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
                {
                    name: 'discountOptionsAddedPercentage',
                    width: config.discountsAddedPercentWidthStr,
                    className: classnames([ styles.shadowBg, styles.normalBorderRight ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
        {
            title: (
                <BootstrapTooltip title="Options that were previously discounted that returned to full price during the selected time period.">
                    <div>Discounts removed</div>
                </BootstrapTooltip>
            ),
            width: config.discountsRemovedWidthStr,
            children: [
                {
                    name: 'discountOptionsRemovedProductCount',
                    width: config.discountsRemovedCountWidthStr,
                    className: styles.normalBorderRight,
                    render: (text, record) => {
                        const value = record?.queryDateValid ? text : NA
                        if (value === NA) return <div>{value}</div>

                        const products = record?.discountOptionsRemovedProducts || []
                        return (
                            <div
                                className={classnames({ [styles.clickable]: products.length > 0, 'cell-absolute cell-mouse-effect': products.length > 0 })}
                                onClick={() => onShowProductModal({
                                    vendor: record.vendor,
                                    products,
                                    title: 'Discounts removed',
                                })}
                            >
                                {value}
                            </div>
                        )
                    },
                },
                {
                    name: 'discountOptionsRemovedPriceChanged',
                    width: config.discountsRemovedChangeWidthStr,
                    className: styles.normalBorderRight,
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
                {
                    name: 'discountOptionsRemovedPercentage',
                    width: config.discountsRemovedPercentWidthStr,
                    className: styles.normalBorderRight,
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
        {
            title: (
                <BootstrapTooltip title="Discounted options that received additional discounts or discount reductions during the selected time period.">
                    <div>Discount changes</div>
                </BootstrapTooltip>
            ),
            width: config.discountChangesWidthStr,
            children: [
                {
                    name: 'furtherChangesProductCount',
                    width: config.discountsOptionsCountWidthStr,
                    className: classnames([ styles.shadowBg, styles.normalBorderRight ]),
                    render: (text, record) => {
                        const value = record?.queryDateValid ? text : NA
                        if (value === NA) return <div>{value}</div>

                        const products = record?.furtherChangesProducts || []
                        return (
                            <div
                                className={classnames({ [styles.clickable]: products.length > 0, 'cell-absolute cell-mouse-effect': products.length > 0 })}
                                onClick={() => onShowProductModal({
                                    vendor: record.vendor,
                                    products,
                                    title: 'Further changes',
                                })}
                            >
                                {value}
                            </div>
                        )
                    },
                },
                {
                    name: 'furtherChangesPriceChanged',
                    width: config.discountsOptionsChangeWidthStr,
                    className: classnames([ styles.shadowBg, styles.normalBorderRight ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
                {
                    name: 'furtherChangesPercentage',
                    width: config.discountsOptionsPercentWidthStr,
                    className: classnames([ styles.shadowBg, styles.normalBorderRight ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
        {
            title: (
                <BootstrapTooltip title="Options that had a price change that affected the average price of the selected category, but where the full price of the options changed in parallel to the sales price. As such this price effect was not the result of a discount, but of a permanent price change.">
                    <div>Full price changes</div>
                </BootstrapTooltip>
            ),
            width: config.fullPriceChangesWidthStr,
            children: [
                {
                    name: 'unannoucedPriceChangesProductCount',
                    width: config.fullPriceCountWidthStr,
                    className: styles.normalBorderRight,
                    render: (text, record) => {
                        const value = record?.queryDateValid ? text : NA
                        if (value === NA) return <div>{value}</div>
                        
                        const products = record?.unannoucedPriceChangesProducts || []
                        return (
                            <div
                                className={classnames({ [styles.clickable]: products.length > 0, 'cell-absolute cell-mouse-effect': products.length > 0 })}
                                onClick={() => onShowProductModal({
                                    vendor: record.vendor,
                                    products,
                                    title: 'Unannouced price changes',
                                })}
                            >
                                {value}
                            </div>
                        )
                    },
                },
                {
                    name: 'unannoucedPriceChangesPriceChanged',
                    width: config.fullPriceChangeWidthStr,
                    className: styles.normalBorderRight,
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
                {
                    name: 'unannoucedPriceChangesPercentage',
                    width: config.fullPricePercentWidthStr,
                    className: styles.deepBorderRight,
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
        {
            title: (
                <BootstrapTooltip title="Options introduced during the selected time period.">
                    <div>Options in</div>
                </BootstrapTooltip>
            ),
            width: config.optionsInWidthStr,
            children: [
                {
                    name: 'optionsAddedProductCount',
                    width: config.optionsInCountWidthStr,
                    className: styles.normalBorderRight,
                    render: (text, record) => {
                        const value = record?.queryDateValid ? text : NA
                        if (value === NA) return <div>{value}</div>

                        const products = record?.optionsAddedProducts || []
                        return (
                            <div
                                className={classnames({ [styles.clickable]: products.length > 0, 'cell-absolute cell-mouse-effect': products.length > 0 })}
                                onClick={() => onShowProductModal({
                                    vendor: record.vendor,
                                    products,
                                    title: 'Options in',
                                })}
                            >
                                {value}
                            </div>
                        )
                    },
                },
                {
                    name: 'optionsAddedPriceChanged',
                    width: config.optionsInChangeWidthStr,
                    className: styles.normalBorderRight,
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
                {
                    name: 'optionsAddedPercentage',
                    width: config.optionsInPercentWidthStr,
                    className: styles.normalBorderRight,
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
        {
            title: (
                <BootstrapTooltip title="Options discontinued during the selected time period.">
                    <div>Options out</div>
                </BootstrapTooltip>
            ),
            width: config.optionsOutWidthStr,
            children: [
                {
                    name: 'optionsRemovedProductCount',
                    width: config.optionsOutCountWidthStr,
                    className: classnames([ styles.shadowBg, styles.normalBorderRight ]),
                    render: (text, record) => {
                        const value = record?.queryDateValid ? text : NA
                        if (value === NA) return <div>{value}</div>

                        const products = record?.optionsRemovedProducts || []
                        return (
                            <div
                                className={classnames({ [styles.clickable]: products.length > 0, 'cell-absolute cell-mouse-effect': products.length > 0 })}
                                onClick={() => onShowProductModal({
                                    vendor: record.vendor,
                                    products,
                                    title: 'Options out',
                                })}
                            >
                                {value}
                            </div>
                        )
                    },
                },
                {
                    name: 'optionsRemovedPriceChanged',
                    width: config.optionsOutChangeWidthStr,
                    className: classnames([ styles.shadowBg, styles.normalBorderRight ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
                {
                    name: 'optionsRemovedPercentage',
                    width: config.optionsOutPercentWidthStr,
                    className: classnames([ styles.shadowBg ]),
                    render: (text, record) => <div>{record?.queryDateValid ? text : NA}</div>,
                },
            ],
        },
    ]

    /** ****************************** 产品模态框 ************************************ */

    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    const onShowProductModal = ({ vendor, products = [], title = '' }) => {
        if (!Array.isArray(products) || products.length === 0) return
        const modalTilte = `${vendorShortMappingFn(vendor)}, ${title}`
        clearLookbookActions()
        const dialogId = `Price history drivers-${vendor}-${title}`
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog(dialogId,
            <ProductsModal
                productUrls={products}
                onClose={() => {
                    closeFn(dialogId)
                    if (judgeActionOccurs()) fetchData()
                }}
                headerLeading={numberUtils.formatNumberByComma(products.length)}
                headerTitle={fnUseAppendDialogTitlePriceFilter(modalTilte)}
                headerDate={formatDateStr2(dateRangeValue, true)}
            />)
    }

    return (
        <div id="export-table-wrapper">
            <ShowWhenOpenInNew>
                <FilterInfo />
            </ShowWhenOpenInNew>
            <ModuleTitle 
                category="PRICING" 
                title={PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME} 
                showComparisonDate 
                bottom={10} 
                subTitle={(
                    <InfoBox
                        iconSty={{ margin: '0 10px' }}
                        containerSty={{ left: '-150px' }}
                        title="title"
                        text="tips content"
                    >
                        <TipsContent sty={{ padding: 0 }}>
                            The Price driver (period to period) analytics shows the root causes of the price deltas between two time periods for the group of products that you selected. In short, it shows why the average price was higher or lower during the selected time period - breaking down the price movements for products that are identical in the two time periods, but also analyzing price movements for non-identical products.
                        </TipsContent>
                    </InfoBox>
                )}
            />
            <ShouldRender shouldRender={!lookbookWithoutData}>
                <Spin spinning={loading || manualLoading} minHeight={10}>
                    <HideWhenOpenInNew>
                        <div className={styles.form}>
                            <ExportDropdown
                                right
                                onExportExcel={onExportExcel}
                                adjustElementFn={ele => {
                                    const headerEle: HTMLDivElement = ele.querySelector('.sticky-header') as HTMLDivElement
                                    if (headerEle) {
                                        headerEle.style.top = '0px'
                                        headerEle.style.position = 'relative'
                                    }
                                }}
                                selector="#export-table-wrapper"
                                fileName={priceDriversPeriodToPeriodExportFilename}
                            />
                            <GraphButton />
                            <OpenInNewButton
                                left={10}
                                pageParams={{
                                    [OPEN_IN_NEW_DATE]: dateRangeValue,
                                }}
                            />
                        </div>
                    </HideWhenOpenInNew>
                    <SizedBox height={10} />
                    <div className={styles.ttile} style={{ top: (-30 / zoom) + 'px' }}>
                        <div style={{ width: config.vendorsWidthStr }} />
                        <div style={{ width: config.avgPriceWidthStr }}>Average price</div>
                        <div style={{ width: config.priceChangesWidthStr }}>Price changes for identical options</div>
                        <div style={{ width: config.productMixChangesWidthStr }}>Product mix changes</div>
                    </div>
                    <div className={classnames(styles.theadWrap, 'sticky-header')}>
                        <div className={styles.thead}>
                            {
                                columns.map((item, index) => (
                                    <div
                                        key={index.toString()}
                                        className={styles.th}
                                        style={{ width: typeof item.width === 'number' ? item.width + 'px' : item.width }}
                                    >
                                        {item.title}
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.smallTheadWrap}>
                            <div className={styles.smallThead} style={{ width: config.vendorsWidthStr }} />
                            <div className={styles.smallThead} style={{ width: config.avgPriceStartWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.avgPriceEndWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.avgPriceChangeWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.avgPricePercentWidthStr }}>%</div>
                            <div className={styles.smallThead} style={{ width: config.discountsAddedCountWidthStr }}>#</div>
                            <div className={styles.smallThead} style={{ width: config.discountsAddedChangeWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.discountsAddedPercentWidthStr }}>%</div>
                            <div className={styles.smallThead} style={{ width: config.discountsRemovedCountWidthStr }}>#</div>
                            <div className={styles.smallThead} style={{ width: config.discountsRemovedChangeWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.discountsRemovedPercentWidthStr }}>%</div>
                            <div className={styles.smallThead} style={{ width: config.discountsOptionsCountWidthStr }}>#</div>
                            <div className={styles.smallThead} style={{ width: config.discountsOptionsChangeWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.discountsOptionsPercentWidthStr }}>%</div>
                            <div className={styles.smallThead} style={{ width: config.fullPriceCountWidthStr }}>#</div>
                            <div className={styles.smallThead} style={{ width: config.fullPriceChangeWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.fullPricePercentWidthStr }}>%</div>
                            <div className={styles.smallThead} style={{ width: config.optionsInCountWidthStr }}>#</div>
                            <div className={styles.smallThead} style={{ width: config.optionsInChangeWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.optionsInPercentWidthStr }}>%</div>
                            <div className={styles.smallThead} style={{ width: config.optionsOutCountWidthStr }}>#</div>
                            <div className={styles.smallThead} style={{ width: config.optionsOutChangeWidthStr }}>{filterCurrency}</div>
                            <div className={styles.smallThead} style={{ width: config.optionsOutPercentWidthStr }}>%</div>
                        </div>
                    </div>
                    <Table
                        key="table"
                        dataSource={dataSource}
                        columns={columns}
                        rowClassName={styles.tr}
                        style={{ zoom: tableZoom }}
                    />
                </Spin>
            </ShouldRender>
            <ShouldRender shouldRender={lookbookWithoutData}>
                <LookbookWithoutData />
            </ShouldRender>
            <Dialog ref={dialogRef} />
            <div style={{ width: 1, height: 70, background: 'transparent' }} />
        </div>
    )
}

export const handlePrefixSymbol = (value: any, valueNum: number) => {
    const symbol = valueNum < 0 ? '-' : '+'
    if (String(value).startsWith('+') || String(value).startsWith('-')) {
        return value
    }
    return `${symbol}${value}`
}
