import React, { useEffect } from 'react'
import { CloseIcon } from 'assets/icons'
import styles from '../styles.module.scss'
import { Button } from '../../../../../form-elements/Button'
import { NoteProps, NoteType } from './type'
import { downLoadPDF, updateSize } from './utils'
import { releaseNote } from 'configs/releaseNote'
import { useFetch } from 'libs'
import { useDispatch, useSelector } from 'react-redux'
import { getVersionRead, updateVersionRead } from 'features/filters/globalDataSlice'
import { argFnPostReleaseNote } from 'graphql/nornaapi'

export function ReleaseNote({ onClose }) {
  const { postFn: fetch } = useFetch()
  const dispatch = useDispatch()
  const versionRead = useSelector(getVersionRead)

  const modifyVersionRead = async () => {
    const payload = argFnPostReleaseNote()
    const result = await fetch(payload.url, payload)
    if (!result?.data?.success) return
    dispatch(updateVersionRead(true))
  }

  useEffect(() => {
    if (versionRead) return
    modifyVersionRead()
  }, [ versionRead ])

  return <Note type={NoteType.Release} close={onClose} />
}

const ReleaseContent = (
  <>
    <div className={styles.header}>
      <div
        className={styles.title}
        style={{
          height: '18px',
          color: '#ec7765',
          fontFamily: 'Nunito Sans',
          fontSize: '22px',
          fontWeight: 400,
          lineHeight: '18px',
        }}
      >Release notes - Norna analytics
      </div>
      <div
        className={styles.time}
        style={{
          color: '#4a4a4a',
          fontFamily: 'Nunito Sans',
          fontSize: '14px',
          fontWeight: 400,
          marginTop: '3px',
        }}
      >Norna Analytics application – {releaseNote.version} release, {releaseNote.date}
      </div>
    </div>
    <div style={{ height: 20, width: 1 }} />
    <div style={{ margin: 0, height: 1, width: '100%', background: 'rgba(151, 151, 151, 0.33)' }} />
    <div style={{ height: 20, width: 1 }} />
    <div className={styles.body}>
      <div
        className={styles.description}
        style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: '10px', fontWeight: 400 }}
      >The information contained in this document is confidential, privileged and only for the information of the intended recipient and may not be used, published or redistributed without the prior written consent of Norna AB.
      </div>
      <div className={styles.releaseList}>
        <div
          className={styles.title} style={{
            color: '#4a4a4a',
            fontFamily: 'Nunito Sans',
            fontSize: '14px',
            fontWeight: 400,
            marginTop: '20px',
          }}
        >
          New in this release
        </div>
        <ul>
          {
            releaseNote.content.map(item => (
              <li
                key={item}
                style={{
                  marginTop: '15px',
                  color: '#666666',
                  fontFamily: 'Nunito Sans',
                  fontSize: '11px',
                  fontWeight: 400,
                }}
              >
                {item}
              </li>
            ))
          }

        </ul>

        <div
          className={styles.title} style={{
            color: '#4a4a4a',
            fontFamily: 'Nunito Sans',
            fontSize: '14px',
            fontWeight: 400,
            marginTop: '20px',
          }}
        >
          Bug fixes
        </div>
        <ul>
          <li style={{
            marginTop: '15px',
            color: '#666666',
            fontFamily: 'Nunito Sans',
            fontSize: '11px',
            fontWeight: 400,
          }}
          > • Various bug fixes
          </li>
        </ul>
      </div>
    </div>
    <div className={styles.divideBottomLine} />
  </>
)
const AboutContent = (
  <>
    <div className={styles.header}>
      <div
        className={styles.title}
        style={{
          height: '18px',
          color: '#ec7765',
          fontFamily: 'Nunito Sans',
          fontSize: '22px',
          fontWeight: 400,
          lineHeight: '18px',
        }}
      >About Norna
      </div>
    </div>
    <div style={{ height: 20, width: 1 }} />
    <div style={{ margin: 0, height: 1, width: '100%', background: 'rgba(151, 151, 151, 0.33)' }} />
    <div style={{ height: 20, width: 1 }} />
    <div className={styles.body}>
      <div
        className={styles.description}
        style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: '12px', fontWeight: 400, lineHeight: '14px' }}
      >
        Norna is a Swedish AI company founded by Michael Collaros and Jonas Saric with a talented team of 30 people from 8 nationalities, drawing on experience from different international tech companies.
        <br />
        <br />
        We are a dedicated team of AI scientists, designers, product managers and AI engineers with a mission to disrupt the retail industry.
      </div>
    </div>
    <div className={styles.divideBottomLine} />
  </>
)
const SupportContent = (
  <>
    <div className={styles.header}>
      <div
        className={styles.title}
        style={{
          height: '18px',
          color: '#ec7765',
          fontFamily: 'Nunito Sans',
          fontSize: '22px',
          fontWeight: 400,
          lineHeight: '18px',
        }}
      >Support & Contact
      </div>
    </div>
    <div className={styles.divideTopLine} />
    <div className={styles.body}>
      <div
        className={styles.description}
        style={{ color: '#666666', fontFamily: 'Nunito Sans', fontSize: '12px', fontWeight: 400, lineHeight: '14px' }}
      >
        {/* <div style={{ color: '#4A4A4A', fontSize: '14px', marginBottom: '10px' }}>Support between 9-17</div> */}
        <div>
          We are here to support you! If you need assistance, please reach out to your dedicated Support person, or contact Norna&apos;s support team!
        </div>
        <br />
        <br />
        <div>support@norna.ai</div>
        <br />
        <br />
        {/* <div style={{ color: '#4A4A4A', fontSize: '14px', marginBottom: '10px' }}>Video link for support</div> */}
        <div>Unless differently agreed we are on call during normal Swedish office hours.</div>

        <br />
        <br />
        <br />
        {/* <div style={{ color: '#4A4A4A', fontSize: '14px', marginBottom: '10px' }}>Chief Commercial Officer</div> */}
        {/* <div>
          martin.hallander@norna.ai
          <br />
          +46 70 444 20 68
        </div> */}

      </div>
    </div>
    {/* <div className={styles.divideBottomLine} /> */}
  </>
)

/**
 * Note content
 * @param param0 
 * @returns 
 */
export function Note({ close, type }: NoteProps) {

  useEffect(() => {
    updateSize(styles.release, styles.content, styles.contenta,type)
  }, [ type ])

  return (
    <div
      className={styles.release} 
      onClick={() => {
        close?.()
        const $release:HTMLDivElement = document.querySelector(`.${styles.release}`) as HTMLDivElement
        if( $release){
          $release.style.opacity = '0'
        }
      }}
    >
      <div className={styles.contenta}>
        <div className={styles.closeIconWrapper}>
          <CloseIcon onClick={() => close?.()} />
        </div>
        <div onClick={(e: any) => e.stopPropagation()} className={styles.content} id="release">
          {type === NoteType.Release && ReleaseContent}
          {type === NoteType.Support && SupportContent}
          {type === NoteType.About && AboutContent}
        </div>
        <div className={styles.download}>
          {type === NoteType.Support ? null : (
            <Button
              primary
              miniLarge
              className="marginLeft5"
              onClick={() => downLoadPDF(type)}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

