import { BaseSelect, BaseSelectRefProps, SelectOkButton, SelectOption } from 'druikit';
import { DYNAMIC_DASHBOARD_KPIS } from 'features/filters/dynamicDashboardSlice';
import { cloneDeep } from 'lodash';
import { useSettingCategoryOptions, useSettingCategoryValue, useSettingKpiValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData';
import React, { CSSProperties, ReactNode, useRef, useState } from 'react'
import { Tree } from './components/Tree';
import classNames from 'classnames';

const GroupTitle = ({
    children,
    showInfo = false,
    count,
}: {
    children: ReactNode;
    showInfo?: boolean;
    count?: number;
}) => {
    return (
        <div style={{ padding: '6px 15px', fontSize: 12, color: '#666', fontWeight: 'bold' }}>
            {children}
            {
                showInfo ? (
                    <div style={{ fontSize: 12, color: 'red' }}>
                        The selection cannot exceed {count} categories.
                    </div>
                ) : null
            }
        </div>
    )
}

const SettingTreeSelect = ({
    style,
    className,
    value = [],
    onChange,
    disabled,
    options = [],
    size = 'middle',
    label,
    labelStyle,
    labelClassName,
    placeholder,
    selectorTextWhenSelectAll = 'All',
    dropdownTitle,
    dropdownMaxHeight = 300,
    dropdownStyle,
    dropdownClassName,
    dropdownLabelStyle,
    dropdownLabelClassName,
    selectAll = true,
    clearAll = true,
    minCount = 0,
    maxCount = 9999,
    ...props
}: any) => {
    const selectRef = useRef<BaseSelectRefProps>(null)
    const [ categoryOptions ] = useSettingCategoryOptions()

    /* ************************* dropdown ******************************* */
    const dropdownSty: CSSProperties = {
        padding: 0,
        minWidth: 164,
        maxHeight: 330,
        ...dropdownStyle,
    };

    const dropdownLabelSty: CSSProperties = {
        width: 'fit-content',
        whiteSpace: 'nowrap',
        ...dropdownLabelStyle,
    };

    const [ settingCategoryValue ] = useSettingCategoryValue()
    const [ settingKpiValue ] = useSettingKpiValue()
    const [ categoryData, setCategoryData ] = useState<string[]>(settingCategoryValue || [])
    const [ kpiData, setKpiData ] = useState<string[]>(settingKpiValue || [])

    const onOk = () => {
        if (categoryData?.length > 12) return
        const categories = cloneDeep(categoryData)
        const data: string[] = []
        if (categories?.length) {
            categories.slice(0, 12).forEach(item => data.push(`CATEGORY_${item}`))
        }
        if (kpiData.length) {
            kpiData.forEach(item => data.push(`KPI_${item}`))
        }
        onChange?.(data)
        selectRef.current?.close()
    }

    const renderDropdown = (
        <>
            <div style={{ padding: '4px 0 10px', maxHeight: 290, overflow: 'auto' }}>
                <GroupTitle 
                    count={12}
                    showInfo={categoryData?.length === 12}
                >
                    Settings for categories
                </GroupTitle>
                <Tree 
                    options={categoryOptions}
                    value={cloneDeep(categoryData)}
                    multiple
                    onChange={value => {
                        setCategoryData(value)
                    }}
                />
                <GroupTitle>
                    Settings for KPIs
                </GroupTitle>
                {
                    DYNAMIC_DASHBOARD_KPIS.map(item => {
                        return (
                            <SelectOption
                                key={item}
                                labelStyle={dropdownLabelSty}
                                boxVisible
                                boxPosition="right"
                                value={kpiData.includes(item)}
                                onChange={checked => {
                                    let newData = cloneDeep(kpiData)
                                    if (checked) {
                                        newData.push(item)
                                    } else {
                                        newData = newData.filter(item2 => item2 !== item)
                                    }
                                    setKpiData([ ...newData ])
                                }}
                            >
                                {item}
                            </SelectOption>
                        )
                    })
                }
            </div>
            <SelectOkButton onClick={onOk} disabled={categoryData.length > 12} />
        </>
    )

    return (
        <>
            <BaseSelect
                ref={selectRef}
                style={style}
                className={classNames(className, 'dynamic-dashboard_setting-select')}
                disabled={disabled}
                label=""
                labelStyle={labelStyle}
                labelClassName={labelClassName}
                selectorValue="Settings"
                placeholder={placeholder}
                renderDropdown={renderDropdown}
                dropdownStyle={dropdownSty}
                dropdownClassName={dropdownClassName}
                onClickAway={() => {
                    // 当前是关闭状态, 直接返回
                    if (!selectRef.current?.getIsOpen()) return;
                    onOk();
                }}
                {...props}
            />
        </>
    )
}

export default SettingTreeSelect
