import classnames from 'classnames'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import React, { useContext, useEffect, useState, CSSProperties, useLayoutEffect, useRef } from 'react'
import { cloneDeep, debounce, throttle } from 'lodash'
import { LazyBgImg } from 'components/Image'
import { PlusIcon } from 'assets/icons/Plus'
import { CloseIcon } from 'assets/icons/Close'
import { CreateLookbookModal } from 'components/Lookbooks/CreateLookbookModal'
import { SelectProductLookbook } from 'components/Lookbooks/DelProductLookbook'
import { useRemoveLookbookProduct } from 'graphql/lookbook/removeLookbookProduct'
import { DeleteIcon } from 'assets/icons/Delete'
import { ActionBoxContext } from 'components/common/InfoBox/context'
import { ActionBoxTypes, offSetEleFn, parentshasClass } from 'components/common/InfoBox/InfoActionContext'
import { useParams } from 'react-router-dom'
import { PropertyQaAnnotation } from 'consts/'
import { useQa } from 'hooks/useQa'
import { Legend } from 'componentsv2/NornaLineChart/components/Legend'
import { vendorToColorList } from 'utils/colorJson'
import { formatProductName, getVendorCodeByName } from 'utils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { isKeepOriginalAspectRatio } from 'utils/imgUtils'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { formatProductVendorInSmallProductCard } from 'utils/product'
import { useLookbook } from 'hooks/useLookbook'
import { useRefreshLookbookList } from 'hooks/api/useLookbookData'
import { useCreateProductLookbooks, useRemoveProductLookbooks } from 'hooks/api/useLookbookProductData'
import { addClassName, delClassName } from 'utils/domUtils'
import { QaCheck } from './components/QaCheck'
import { LookbookActions } from './components/LookbookActions'
import { ProductProps } from './types'
import styles from './styles.module.scss'
import { ProductPrice } from './components/Price'
import { ProductActions } from './components/Actions'
import { useProductDialog } from './useHooks'
import { useSelector } from 'react-redux'
import { disableLazyLoad } from 'features/filters/memoDataSlice'
import { loadingBar } from 'hooks/useLoadingBar'
import { Popover } from 'antd'

const observe = new IntersectionObserver((entries = []) => {
  entries.forEach(item => {
    if (item.isIntersecting) {
      const productItem = item.target
      const productEle = productItem.firstChild
      delClassName(productEle, 'noneClass')
      addClassName(productEle, 'blockClass')
      observe.unobserve(productItem)
    }
  })
})

let myTimer

export const Product = ({
  onDel,
  vendor,
  vendorName,
  vendorCode,
  brand,
  imageUrl,
  price,
  availability,
  id,
  nornaid,
  title,
  interpolatedPrice,
  currency,
  toCurrency,
  convertedPrice,
  date,
  fullyConfirmed = false,
  enabledQa = false,
  taggedQa = {},
  lookbooks,
  style = {},
  // 以下属性为了兼容 Cross Region Pricing 页面产品卡片
  disabledScale = false,    // 鼠标移动到图片上, 不会出现放大效果
  imageStyle = {},          // 图片样式, 可设置图片宽高
  onlyShowImage = false,    // 只显示图片, 不显示其它诸如 vendor, brand, price 这些信息
  // 以下属性为了兼容 ProductModal 
  isShowInDialog = false,   // 在产品列表模态框中显示的产品卡片, 布局和层级会有不同
  skeletonStyle = {},       // 图片骨架样式, 在产品列表弹窗里, 图片骨架宽度100%失效, 只显示了一半的宽度
  sizePriceRange,
  latestSizePrice,
  inCrp = false,
  isBgTransparent = false,
}: ProductProps) => {
  const { lookbookId } = useParams<any>()
  const { openDetailDialog } = useProductDialog()
  const [ savedLookbooks, setSavedLookbooks ] = useState(lookbooks || [])
  const [ actionOpen, setActionOpen ] = useState(false)

  // update savedLookbooks by props.lookbooks
  useDeepCompareEffect(() => {
    setSavedLookbooks(lookbooks ?? [])
  }, [ lookbooks || [] ])

  /**
   * savedLookbooks 当前产品已添加到哪些 lookbook 中 
   * e.g ['53adcc5d0bb3459f9d32fc7a56cf90ea']
   * 
   * myLookbookList 当前用户总共拥有哪些 lookbook
   */
  const [ hover, setHover ] = useState({ hover: false, enter: false })
  const { createProductLookbooks } = useCreateProductLookbooks()
  const { removeProductLookbooks } = useRemoveProductLookbooks()
  const { removeLookbookProduct } = useRemoveLookbookProduct()
  const { myLookbookList, latestLookbook, setLatestLookbook } = useLookbook()
  const { refreshLookbookList } = useRefreshLookbookList()
  // 当前选择的 lookbook
  const [ currentLookbook, setCurrentLookbook ] = useState(lookbookId ? myLookbookList?.filter(item => item.id === lookbookId)[0] : latestLookbook)// current lookbook
  const [ isProductAddedToLookbook, setIsProductAddedToLookbook ] = useState(lookbookId ? true : (lookbooks ?? []).length > 0)

  const enterQuickView = () => {
    const vendorCode = getVendorCodeByName(vendorName || vendor)
    openDetailDialog({ toCurrency, nornaid, enabledQa, vendorCode })
  }

  useEffect(() => {
    setIsProductAddedToLookbook((savedLookbooks ?? []).length > 0)
    setCurrentLookbook(lookbookId ? (myLookbookList || [])?.filter(item => item.id === lookbookId)[0] : latestLookbook)
  }, [ latestLookbook, savedLookbooks, lookbookId, myLookbookList ])

  const { updateLookbookActions } = useLookbookAction()

  const actionBox = useContext(ActionBoxContext)

  /**
   * 往 lookbook 中添加产品
   */
  const addItemToLookbook = debounce(async () => {
    if (myLookbookList.length === 0) {
      onShowCreateNewLookbookModal()
      return
    }
    if (!currentLookbook) {
      return
    }
    setIsProductAddedToLookbook(true)
    const lookbookId = currentLookbook.id
    await createProductLookbooks(nornaid, [ lookbookId ])
    setSavedLookbooks(savedLookbooks ? [ ...savedLookbooks, lookbookId ] : [ lookbookId ])
    actionBox.close(1)

    updateLookbookActions([ [ lookbookId, nornaid ].join('+') ])
    refreshLookbookList?.()
  }, 300)

  const productRef: any = React.createRef<HTMLElement>()

  const createLookbookClicked = e => {
    closeLookbookPopover()
    onShowCreateNewLookbookModal()
    actionBox.close(1)
    zoomNormal()
  }

  /* ************************* Create new lookbook ****************************** */
  const dialogRef = useRef<DialogRefType>({} as DialogRefType)

  const onShowCreateNewLookbookModal = () => {
    if (!document.querySelector('.productsModal')) {
      actionBox.closeAll()
    }
    zoomNormal()
    
    setTimeout(() => {
      const dialogId = 'create-new-lookbook'
      const closeFn = dialogRef.current?.closeDialog
      dialogRef.current?.openDialog(dialogId,
          <CreateLookbookModal
              closeModal={() => {
                  closeFn(dialogId)
              }}
              isOpen={true}
              hideBackBtn
          />,
      )
    }, 200)
  }

  /**
   *
   *
   * @arg remove action or not
   *
   * */
  const saveToOtherLookbook = async (lookbooks, remove) => {
    // loadingBar?.continuousStart();
    const lookbookActions: Array<string> = []
    /**
     * @todo  save batch
     */
    if (!remove) {
      let checked = lookbooks[0].filter(item => item.isChecked)
      checked = checked.filter(item => !savedLookbooks?.includes(item?.id))
      let unchecked = lookbooks[0].filter(item => !item.isChecked)
      unchecked = unchecked.filter(item => savedLookbooks?.includes(item?.id))

      if (checked?.length) {
        await createProductLookbooks(nornaid, checked.map(item => item.id))
        checked.forEach(item => {
          lookbookActions.push([ item?.id, nornaid ].join('+'))
        })
      }
      
      if (unchecked?.length) {
        await removeProductLookbooks(nornaid, unchecked.map(item => item.id))
        unchecked.forEach(item => {
          lookbookActions.push([ item?.id, nornaid ].join('-'))
        })
      }

      if (!lookbookId && checked?.length) { // not lookbook page to switch the lastest lookbook
        const lookbookIds = myLookbookList.map(lookbook => lookbook.id)
        const newChecked = cloneDeep(lookbooks[0].filter(item => item.isChecked))
        newChecked.sort((m, n) => lookbookIds.indexOf(m.id) - lookbookIds.indexOf(n.id))
        // tslint:disable-next-line
        setLatestLookbook(newChecked[0])// update the isProductAddedToLookbook by effect
      }

      setSavedLookbooks(lookbooks[0].filter(item => item.isChecked).map(item => item.id))
    } else { // remove action
      let checked = lookbooks[0].filter(item => item.isChecked)
      checked = checked.filter(item => savedLookbooks?.includes(item?.id))
      let unchecked = lookbooks[0].filter(item => !item.isChecked)
      unchecked = unchecked.filter(item => savedLookbooks?.includes(item?.id))

      if (checked?.length) {
        await removeProductLookbooks(nornaid, checked.map(item => item.id))
        checked.forEach(item => {
          lookbookActions.push([ item?.id, nornaid ].join('-'))
        })
      }
      setSavedLookbooks(unchecked.map(item => item.id))
      await onDel?.()
    }

    updateLookbookActions(lookbookActions)
    refreshLookbookList?.()
    // loadingBar.complete();
    actionBox?.close?.(2)
  }

  const removeLookbookClicked = async () => {
    closeLookbookPopover()
    /**
     * 2022/09/02 my saved lookbook 详情页面删除产品不要打开 remove 弹窗，而是直接删除
     */
    if (window.location.pathname.match(/\/lookbook\/.*?\//)) {
      loadingBar.restart()
      let lookbooks = cloneDeep(myLookbookList).filter(item => item.id === lookbookId)
      lookbooks = lookbooks.map(item => {
        item.isChecked = true
        return item
      })
      lookbooks = [ lookbooks, null ]
      await saveToOtherLookbook(lookbooks, true)
      actionBox.closeAll()
      zoomNormal()
      loadingBar.done()
      return
    }

    if (savedLookbooks?.length === 1) {
      let lookbooks = cloneDeep(myLookbookList).filter(item => item.id === savedLookbooks[0])
      lookbooks = lookbooks.map(item => {
        item.isChecked = true
        return item
      })
      lookbooks = [ lookbooks, null ]
      saveToOtherLookbook(lookbooks, true)
      // actionBox.closeAll()
      zoomNormal()
      return
    }

    actionBox.open && actionBox.open({
      id: 2,
      left: 0,
      top: 0,
      title: 'Delete from lookbook',
      close: () => {
        actionBox?.close?.(1)
      },
      type: ActionBoxTypes.DIALOG,
      bodyComponent: (
        <SelectProductLookbook
          myLookbookList={[
            ...(myLookbookList || []).filter(lookbook => (savedLookbooks ?? []).indexOf(lookbook.id) > -1),
          ]}
          type={SelectProductLookbook.TYPE.REMOVE}
          onSave={lookbooks => {
            saveToOtherLookbook(lookbooks, true)
          }}
          lookbooks={savedLookbooks || []}
          selectedProductId={id}
        />
      ),
    })
    zoomNormal()

  }

  const selectOtherLookbookClicked = () => {
    closeLookbookPopover()
    actionBox.open && actionBox.open({
      id: 2,
      left: 0,
      top: 0,
      title: 'Select Other lookbook',
      close: () => {
        actionBox?.close?.(1)
      },
      type: ActionBoxTypes.DIALOG,
      bodyComponent: (
        <SelectProductLookbook
          myLookbookList={myLookbookList}
          onSave={saveToOtherLookbook}
          lookbooks={savedLookbooks || []}
          selectedProductId={id}
        />
      ),
    })
    zoomNormal()
  }

  const zoomNormal = () => {
    setHover({
      hover: false,
      enter: false,
    })
  }

  let interpolatedPriceData = price
  if (interpolatedPrice && interpolatedPrice[date]) {
    interpolatedPriceData = {
      discountedPrice: interpolatedPrice[date].discounted_price || interpolatedPrice[date].actual_price,
      price: interpolatedPrice[date].original_price,
    }
  }

  const { updateCheckedProduct, checkedProduct } = useQa()

  /* *************************** lazy load ********************************** */
  useLayoutEffect(() => {
    if (!productRef.current) return
    observe.observe(productRef.current)
    // eslint-disable-next-line
  }, [])

  /* *************************** lookbook action ********************************** */
  const closeLookbookPopover = () => {
    setActionOpen(false)
  }

  const actionEl = (
    <ul className="addToLookbookInProduct" onClick={e => e.stopPropagation()}>
      {
        !isProductAddedToLookbook ? (
          <li
            onClick={selectOtherLookbookClicked}
            className="flex flex-start pointer"
            style={{ paddingLeft: 0 }}
          >
            <PlusIcon />
            <span className={styles.createNewText}>Add product to another lookbook</span>
          </li>
        ) : null
      }
      {
        isProductAddedToLookbook ? (
          <li
            onClick={selectOtherLookbookClicked} 
            className="flex flex-start pointer"
            style={{ paddingLeft: 0 }}
          >
            <PlusIcon />
            <span className={styles.createNewText}>Save product to other lookbook</span>
          </li>
        ) : null
      }
      <li
        onClick={createLookbookClicked} 
        className="flex flex-start flex-row"
        style={{ paddingLeft: 0 }}
      >
        <PlusIcon />
        <span className={styles.createNewText}>Create new lookbook</span>
      </li>
      <ShouldRender shouldRender={isProductAddedToLookbook}>
        <li
          className={classnames(styles.removeLookbook, 'flex flex-row flex-start')}
          onClick={removeLookbookClicked}
        >
          <DeleteIcon />
          <span>Delete from lookbook</span>
        </li>
      </ShouldRender>
      <li className="flex flex-column flex-align-start">
        <div
          className={classnames(styles.saveToText, 'flex flex-between flex-row ', !isProductAddedToLookbook ? 'pointer' : '')}
          onClick={() => {
            if (!isProductAddedToLookbook) {
              selectOtherLookbookClicked()
            }
          }}
        >
          {
            isProductAddedToLookbook && savedLookbooks?.length ? (
              <span style={{ color: '#01a699' }}>
                AVAILABLE IN
              </span>
            ) : null
          }
        </div>
        {
          (savedLookbooks ?? []).length ? (
            <div style={{ maxHeight: '45px', overflowY: 'auto', width: '100%' }}> 
              {
                (myLookbookList ?? []).filter(item => (savedLookbooks ?? []).indexOf(item.id) > -1).map(item =>
                  <div
                    key={item.id}
                    className="flex1 ellipsis"
                    onClick={() => {
                      zoomNormal()
                    }}
                    style={{ fontWeight: 600, cursor: 'pointer', width: '100%', lineHeight: 1.5 }}
                  >{item.name}
                  </div>,
                )
              }
            </div>
          ) : (
            <div
              className="flex1 ellipsis"
              onClick={async () => {
                if (!isProductAddedToLookbook) { //
                  await addItemToLookbook()
                  closeLookbookPopover()
                }
                zoomNormal()
              }}
              style={{ fontWeight: 600, cursor: 'pointer', width: '100%' }}
            >
              {currentLookbook?.name}
            </div>
          )
        }
      </li>
    </ul>
  )

  return (
    <>
      <div
        ref={productRef}
        className={classnames(
          styles.product,
          disabledScale ? 'product-disable-scale' : inCrp ? 'scale_121_crp' : 'scale_121',
          // eslint-disable-next-line
          hover.hover || hover.enter ? (disabledScale ? 'product-disable-scale-12' : inCrp ? 'scale_12_crp' : 'scale_12') : '',
        )}
        style={style}
      >
        <div
          className={classnames(styles.content, 'product-ele content blockClass')} style={{
            borderBottom: enabledQa && fullyConfirmed ? '1px solid blue' : 'none',
            borderRight: enabledQa && taggedQa.meta_tag ? '1px solid red' : 'none',
          }}
        >
          {enabledQa ? (
            <>
              <Legend enableLabel={false} dataSource={PropertyQaAnnotation.map((item, index) => ({ label: item, dotColor: !taggedQa[`${item}_tag`] ? 'white' : vendorToColorList[index] }))} />
              <div className="lengend-next-sibling" />
            </>
          ) : null}
          
          <div className={classnames(styles.addToLookbook, 'add-to-lookbook addToLookbook')}>
            <Popover
              placement="topLeft"
              content={actionEl}
              arrow={false}
              zIndex={9999999}
              open={actionOpen}
              onOpenChange={setActionOpen}
            >
              <div>
                <LookbookActions
                  addCheckIconToggle={isProductAddedToLookbook}
                  addEvent={(flag, e) => {
                    if (lookbookId) { // lookbook page to remove
                      removeLookbookClicked()
                      return
                    }
                    if (flag) {// add
                      addItemToLookbook()
                    } else { // remove
                      removeLookbookClicked()
                    }
                  }}
                />
              </div>
            </Popover>
          </div>

          <div
            className={classnames(
              styles.imageWrap,
              (isBgTransparent && styles.bgTransparent),
            )}
            style={imageStyle}
            onClick={() => {
              enterQuickView()
            }}
          >
            <LazyBgImg 
              src={imageUrl} 
              skeletonStyle={skeletonStyle} 
              cropedImg={!isKeepOriginalAspectRatio(vendorName || vendor, brand)} 
            />
          </div>

          <ShouldRender shouldRender={!onlyShowImage}>
            <div className={classnames(styles.description, 'description')}>
              <ShouldRender shouldRender={!lookbookId}>
                <div className={enabledQa ? '' : classnames(styles.actions, 'actions')} >
                  {
                    enabledQa ? (
                      <QaCheck
                        checked={checkedProduct[nornaid]}
                        onChange={() => updateCheckedProduct(nornaid)}
                      />
                    ) : null
                  }
                </div>
              </ShouldRender>
              <div className={classnames(styles.productDetails)}>
                <Text
                  oneLine uppercase
                  primary={!!taggedQa.qa_tag}
                  accent={taggedQa.qa_tag ? false : !!taggedQa.preliminary_qa_tag}
                  className="text-ellipsis"
                  style={{ maxWidth: '110px' }}
                >
                  {formatProductVendorInSmallProductCard(vendor)}
                </Text>
                <div title={brand}>
                  <Text
                    oneLine
                    small
                    primary={!!taggedQa.qa_tag}
                    accent={taggedQa.qa_tag ? false : !!taggedQa.preliminary_qa_tag}
                    className="text-ellipsis"
                    style={{ maxWidth: '110px' }}
                  >
                    {brand}&nbsp;
                  </Text>
                </div>
                <div className={styles.categoryName} title={title}>
                  <Text
                    small
                    oneLine
                    primary={!!taggedQa.qa_tag}
                    accent={taggedQa.qa_tag ? false : !!taggedQa.preliminary_qa_tag}
                    title={title}
                  >
                    {formatProductName(title || '')}
                  </Text>
                </div>
                {interpolatedPriceData && !availability &&
                  <div className={styles.price}>
                    <ProductPrice
                      primary={!!taggedQa.qa_tag}
                      accent={taggedQa.qa_tag ? false : !!taggedQa.preliminary_qa_tag}
                      date={date}
                      {...interpolatedPriceData} 
                      currency={currency}
                      convertedPrice={convertedPrice}
                      sizePriceRange={sizePriceRange}
                      latestSizePrice={latestSizePrice}
                      vendorCode={vendorCode}
                    />
                  </div>
                }
                {availability &&
                  <Text small uppercase className={styles.availability}>
                    {availability}
                  </Text>
                }
              </div>
            </div>
          </ShouldRender>
        </div>
      </div>
      <Dialog ref={dialogRef} />
    </>
  )
}

Product.Price = ProductPrice
Product.Actions = ProductActions
