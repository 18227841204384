import React from 'react'
import classnames from 'classnames'
import { logoMapper } from 'componentsv2/VendorLogo'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { formatVendorName } from 'utils/sellers'
import { Flex } from 'componentsv2/Flex'
import { ComparisonTooltipV2 } from 'componentsv2/business/ComparisonTooltip'
import { isCustomerVendor } from 'utils'
import styles from './styles.module.scss'
import { TopScrollbar } from 'norna-uikit'
import classNames from 'classnames'

let dragVendor

export const HeaderVendor = ({
    dataSource = [],
    onDrag,
}: {
    dataSource: any[];
    onDrag?: (dragVendor, dropVendor) => void;
}) => {
    const onDragStart = e => {
        dragVendor = e.currentTarget.getAttribute('data-vendor')
    }

    const onDragOver = e => {
        e.preventDefault()
    }

    const onDrop = e => {
        const dropVendor = e.currentTarget.getAttribute('data-vendor')
        if (dragVendor === dropVendor) return
        onDrag?.(dragVendor, dropVendor)
    }

    return (
        <>
            <TopScrollbar 
                className={classNames('discounts-header', styles.vendorsWrapper)}
                style={{ top: '-30px' }}
            >
                <div className={styles.vendors}>
                    {
                        dataSource.map((item, index) => {
                            const key = (item.vendorName || '') + index.toString()
                            if (index === 0) {
                                return (
                                    <div 
                                        key={key} 
                                        className={classnames([ styles.discount, styles.deepBorderRight ])} 
                                    >
                                        Discount
                                    </div>
                                )
                            } 
                            return (
                                <div
                                    key={key}
                                    className={classnames({ [styles.vendor]: true, [styles.firstVendor]: index === 1 && isCustomerVendor(item.vendorCode) })} 
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-vendor={item.vendorCode}
                                >
                                    <Flex flexDirection="column" className={styles.firstColumn}>
                                        <ShouldRender shouldRender={logoMapper[item?.vendorCode]}>
                                            <div className={styles.vendorLogoWrapper}>
                                                {logoMapper[item?.vendorCode] || item.vendorName}
                                            </div>
                                            <div className={styles.vendorPlatform}>
                                                {formatVendorName(item.vendorName).platform}
                                            </div>
                                        </ShouldRender>
                                        <ShouldRender shouldRender={!logoMapper[item?.vendorCode]}>
                                            <div className={styles.vendorLogoWrapper}>
                                                {formatVendorName(item.vendorName).name}
                                            </div>
                                            <div className={styles.vendorPlatform}>
                                                {formatVendorName(item.vendorName).platform}
                                            </div>
                                        </ShouldRender>
                                    </Flex>
                                    <Flex flexDirection="column" className={classnames([ styles.secondColumn, styles.deepBorderRight ])}>
                                        <div className={styles.firstRow}>
                                            <ComparisonTooltipV2 width={140} height={50}>
                                                <div style={{ width: '140px', height: '50px', lineHeight: '50px' }}>vs comparison period</div>
                                            </ComparisonTooltipV2>
                                        </div>
                                        <div className={styles.secondRow}>+/- <span className={styles.colorPrimary}>%</span> units</div>
                                    </Flex>
                                </div>
                            )
                        })
                    }
                </div>
            </TopScrollbar>
            <div className={styles.shadow} />
        </>
    )
}
