import { IS_LIVE_ENV } from 'consts'

export const primaryColor = '#01A699'

export const blueColor1 = '#1D756E'

export const redColor1 = '#FB8383'
export const redColor2 = '#FB7676'

export const greyColor1 = '#EDEDED'   // 背景颜色

export const headerHeight = IS_LIVE_ENV ? 60 : 85
