import { storage } from 'utils/storage'
import { ALL_COUNTRIES, ALL_VENDORS, MARKET_VENDOR, SELECTED_VENDORS, SUMMARY } from 'consts'
import { GROUP_BOGNER_NAME, GROUP_BOGNER_SUFFIX } from 'configs/groups'
import { dateRangeUtils } from 'norna-uikit';

export function isQueryDateValid({
    vendor,
    region,
    date,
    isComparisonDate = false,
    isValidWhenLaunchDateNotExist = false,
    competitorValue = [],
}: {
    vendor: string;
    region: string;
    date?: string;      // 线图模块会自己传 date
    isComparisonDate?: boolean;
    competitorValue?: string[];
    isValidWhenLaunchDateNotExist?: boolean;    // launch_date 不存在时是否认为有效
}) {
    // 获取日期
    let startDate = ''
    if (date) {
        startDate = date
    } else {
        const pageDate = storage.getGlobalDateValue()
        const pageComparisonDate = storage.getGlobalComparisonDateValue()
        const queryDate = isComparisonDate ? pageComparisonDate : pageDate
        startDate = dateRangeUtils.first(queryDate)
    }
    
    const sellers = storage.getSellers()
    const newSellers = [ ...sellers ]
    // 处理 All countries 场景
    let vendorList = sellers.map(item => item.vendor)
    vendorList = Array.from(new Set(vendorList))    // 去重
    vendorList.forEach(vendor => {
        const filterSellers = sellers.filter(seller => seller.vendor === vendor)
            .map(seller => ({ ...seller, dateParse: Date.parse(seller.launch_date) }))
            .sort((a, b) => b.dateParse - a.dateParse)
        newSellers.push({ vendor, region: ALL_COUNTRIES, launch_date: filterSellers[0]?.launch_date })
    })

    let competitors: string[] = []
    // handle `Selected vendors`
    if (vendor === SELECTED_VENDORS || vendor === SUMMARY) {
      competitors = [ ...competitorValue ]
    }
    // handle `All vendors`
    else if (vendor === MARKET_VENDOR.vendor || vendor === ALL_VENDORS) {
      // filter all competitor from sellers by region
      const allCompetitorValue = newSellers.filter(item => item.region === region).map(item => item.vendor)
      competitors = [ ...allCompetitorValue ]
    }
    // handle `Bogner Selected`
    else if (vendor === GROUP_BOGNER_NAME) {
      competitors = [ ...competitorValue ].filter(item => item.endsWith(GROUP_BOGNER_SUFFIX))
    }
    // handle normal vendor
    else {
      competitors = [ vendor ]
    }
    competitors = competitors.filter(item => ![ SELECTED_VENDORS, MARKET_VENDOR.vendor, GROUP_BOGNER_NAME ].includes(item))
    competitors = Array.from(new Set(competitors))

    const filterSellers = newSellers.filter(item => competitors.includes(item.vendor) && item.region === region)
    const launchDateList = filterSellers.map(item => item.launch_date)?.filter(item => item)

    // 找不到 launch_date 时是否认为是有效日期
    if (!launchDateList?.length && isValidWhenLaunchDateNotExist) {
      return true
    }

    if (isComparisonDate) {
      return launchDateList.every(launchDate => Date.parse(launchDate) <= Date.parse(startDate))
    }

    return launchDateList.some(launchDate => Date.parse(launchDate) <= Date.parse(startDate))
}
