/**
 * ## loookbook
 * 
 * export product of lookbook by region
 * 
 */
export const argFnLookbookExportProduct = (lookbookId: string) => ({
  url: 'navigation/similar_products_in_lookbooks',
  query: {
    id: lookbookId,
  },

})

