import { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useExcludeProductsComparisonQuery, useManualLoading } from 'hooks'
import { useLookbook } from 'hooks/useLookbook'
import { usePageDate } from 'hooks/usePageDate'
import { useModuleData } from 'hooks/useModuleData'
import { PRICE_ARCHITECTURE_TABLE_NAME } from 'configs'
import { cloneDeep } from 'lodash'
import * as Request from 'libs/request'
import { argFnPriceLadderAndDistributionComparison } from 'graphql/nornaapi'
import { isSubsetPayload, UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { useDispatch } from 'react-redux'
import { setLoadingObj } from 'features/filters/filtersSlice'
import { getIntervalList } from 'pages/PriceArchitecture/utils'
import { useFilterCompetitor, useFilterCurrency } from 'hooks/useFilter'
import { isCompetitorGroupChanged } from 'utils/group'
import { isSuperArray } from 'utils/array'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { useCompetitorOptions } from 'hooks/useOptions'
import { dateRangeUtils } from 'norna-uikit'

export const usePriceArchitectureTableTable = () => {
    const dispatch = useDispatch()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { pageDate, comparisonPageDate } = usePageDate()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterCompetitor ] = useFilterCompetitor()
    const { checked: checkedLookbooks, isLookbook } = useLookbook()
    const { manualLoading, showManualLoading } = useManualLoading()
    const { competitorOptions } = useCompetitorOptions()

    const [ moduleData, setModuleData ] = useModuleData(PRICE_ARCHITECTURE_TABLE_NAME)
    const { 
        apiData,
        fetchPayload,
        intervalValue,
        horizontalExpandKeys = [],
        newCompetitorValue = [],
        oldCompetitorValue = [],
    } = moduleData

    const [ data, setData ] = useState({ price_ladders: [] })
    const [ loading, setLoading ] = useState(false)
    const [ filteredApiData, setFilteredApiData ] = useState<any>([])

    const handleCacheData = () => {
        const newApiData = cloneDeep(apiData)
        if (!isLookbook) {
            newApiData.price_ladders = newApiData.price_ladders?.filter(item => newCompetitorValue.includes(item.seller.vendor))
        }
        setFilteredApiData(newApiData)
    }

    const fetchData = async (gap?: string) => {
        if (!competitorOptions?.length) return

        const path = 'price_ladder_and_distribution'
        const result: any = { price_ladders: [] }

        try {
            // fetch all vendors data
            const query = cloneDeep(comparisonQuery)
            const intervalList = getIntervalList(filterCurrency)
            const priceGap = gap || intervalValue || intervalList
            query.sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
            query.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
            query.groups = getGroupsPayload({ competitorSellers: [ ...filterCompetitor ] })
            const payload: any = argFnPriceLadderAndDistributionComparison(query, dateRangeUtils.from(pageDate), { gap: priceGap }, checkedLookbooks, comparisonPageDate)
            payload.query.columns = 36
            payload.data.include_all_regions = []

            if (!gap && isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload, pageType: 'priceArchitecture' }) && Object.keys(apiData || {}).length) {
                showManualLoading()
                handleCacheData()
                return
            }

            setModuleData({
                type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE,
                payload: cloneDeep(payload),
            })

            setLoading(true)
            dispatch(setLoadingObj({ loadingObj: { [path]: true } }))
            
            const allVendorData = await fetchAllVendorsData(payload)
            if (!allVendorData?.price_ladders.length) return
            result.price_ladders = [ ...result.price_ladders, ...allVendorData.price_ladders ]

            // fetch all regions data
            // The lookbook page interface returns all regions data directly, so don't send Regions alone
            if (!isLookbook && horizontalExpandKeys.length) {
                const regionPayload = cloneDeep(payload)
                regionPayload.data.include_all_regions = [ ...horizontalExpandKeys ]
                const allRegionData = await fetchAllVendorsData(regionPayload)
                result.price_ladders = [ ...result.price_ladders, ...allRegionData.price_ladders ]
            }

            setLoading(false)
            dispatch(setLoadingObj({ loadingObj: { [path]: false } }))
        } catch (e) {
            console.warn('api error', e)
            setLoading(false)
            dispatch(setLoadingObj({ loadingObj: { [path]: false } }))
        }

        setData(result)
        return result
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, pageDate, comparisonPageDate, checkedLookbooks, competitorOptions ])

    useDeepCompareEffect(() => {
        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            if (Array.isArray(apiData?.price_ladders)) {
                handleCacheData()
            }
            return
        }

        fetchData()
    }, [ newCompetitorValue ])

    /**
     * 接口返回的数据保存一份到内存中
     */
    useDeepCompareEffect(() => {
        if (!data?.price_ladders?.length) return
        setModuleData({ apiData: cloneDeep(data) })
        setFilteredApiData(cloneDeep(data))
    }, [ data, [] ])

    return {
        loading: loading || manualLoading,
        setData: setFilteredApiData,
        data: filteredApiData,
        fetchData,
    }
}

export async function fetchAllVendorsData(payload: any) {
    const result: any = await Request.postFn(payload.url, payload)
    return result?.data || { price_ladders: [] }
}
