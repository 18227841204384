/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { reportProductArg } from 'graphql/nornaapi'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { useSelector } from 'react-redux'
import { Text } from 'components/typography'
import { CloseIcon, EditIcon, PlusIcon, VisibilityEyeIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import classnames from 'classnames'
// import { useAuthenticationDataContext } from 'components/guards/AuthenticationContext';
import { useFetch } from 'libs/hookRequest'
import { debounce } from 'lodash'
import { ReportProductModalTypes } from './types'
import styles from './styles.module.scss'
import { getCacheData } from '../../features/filters/cacheDataSlice'

export const ReportProductModal = ({ isOpen, closeModal, productUrl }: ReportProductModalTypes) => {
  const node: any = useRef<HTMLElement>(null)
  const [ buttonName, setButtonName ] = useState('Report product')
  const [ issueInputFocused, setIssueInputFocused ] = useState(false)
  const [ issue, setIssue ] = useState('')
  const [ reportSent, isReportSent ] = useState(false)
  const { account:currentUser } = useSelector(getCacheData)
  // 获取当前登录用户信息，为了获取邮箱地址
  // const currentUser = useAuthenticationDataContext();
  const { postFn: fetch } = useFetch()

  const handleClick = useCallback((event: any) => {
    if (node && node.current && node.current.contains(event.target)) {
      // inside click
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [ handleClick, issue ])

  const [ options, setOptions ] = useState([
    {
      type: 'select',
      label: 'Wrong option',
      selected: false,
    },
    {
      type: 'select',
      label: 'Wrong price',
      selected: false,
    },
    {
      type: 'select',
      label: 'Wrong image',
      selected: false,
    },
    {
      type: 'input',
      label: 'Other issue',
      selected: false,
    },
  ])

  const handleCloseModal = () => {
    closeModal(false)
  }

  const handleReportProblem = (index, option, value?) => {
    const clonedOptions = [ ...options ]
    if (option.type === 'select') {
      clonedOptions[index].selected = !clonedOptions[index].selected
    } else {
      clonedOptions[index].selected = !!value
      setIssue(value)
    }
    setOptions(clonedOptions)
  }

  let reportedProblems: string[] = []
  let hasSelected = false
  options.forEach((item, index) => {
    if (item.selected) {
      hasSelected = true
      let problem
      item.type === 'select' ? problem = item.label : problem = issue.trim()
      problem && reportedProblems.push(problem)
    } else {
      reportedProblems.splice(index, 1)
    }
  })

  const sendReport = async () => {
    if (hasSelected && buttonName === 'Report product') {
      setButtonName('Handling')
      const payload = { user: currentUser?.email, url: productUrl, reports: reportedProblems }
      const query = reportProductArg(payload)
      const res = await fetch(query.url, query)
      if(res?.data?.success){
        isReportSent(true)
        setButtonName('Thank you !')
      }else{
        setButtonName('Report failed, try it later!')
      }
    }
    if (buttonName === 'Thank you !') {
      handleCloseModal()
    }
    reportedProblems = []
  }

  return (
    <ShouldRender shouldRender={isOpen}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <Text small>Something wrong with the product, please report</Text>
          <CloseIcon onClick={handleCloseModal} />
        </div>
        <div className={styles.body}>
          <ShouldRender shouldRender={!reportSent}>
            {options.map((option, index) =>
                option.type === 'select' ?
                  <Button
                    secondary
                    icon={<PlusIcon />}
                    onClick={() => handleReportProblem(index, option)}
                    key={option.label}
                    className={option.selected ? styles.selectedProblem : ''}
                  >
                    {option.label}
                  </Button>
                  :
                  <Button
                    key={option.label}
                    secondary
                    icon={<EditIcon />}
                    className={classnames(
                      issue.trim() && styles.selectedProblem,
                      issueInputFocused && styles.selectedInput,
                    )}
                  >
                    <input
                      type="text"
                      maxLength={280}
                      onFocus={() => setIssueInputFocused(true)}
                      onBlur={() => setIssueInputFocused(false)}
                      onChange={e => handleReportProblem(index, option, e.target.value)}
                      placeholder={issue || option.label}
                      ref={node}
                      className={classnames(!issueInputFocused && styles.unfocusedInput)}
                    />
                  </Button>
              ,
            )}
          </ShouldRender>
        </div>
        <div className={styles.footer}>
          <Button
          icon={<VisibilityEyeIcon />}
            primary
            onClick={debounce(sendReport,300)}
            disabled={reportedProblems.length === 0}
          >
            {buttonName}
          </Button>
        </div>
      </div>
      <div onClick={handleCloseModal} className={styles.closeModal} />
    </ShouldRender>
  )
}
