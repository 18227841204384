import { width01, width02 } from './config'
import { getVendorNameByCode } from 'utils'
import { numberUtils } from 'norna-uikit'

export function getZoom(data) {
    // 获取表格宽度
    const pageContainer = document.querySelector('.page-container')
    if (!pageContainer) return 1
    const tableWidth = pageContainer.clientWidth - 80

    // 判断每一行最大长度字符串
    let percent4MaxWidth = 0
    let percent4dot6Width = 0
    const percent4Fields = [
        'startAveragePrice', 'endAveragePrice', 'averagePriceChanged', 
        'discountOptionsAddedProductCount', 'discountOptionsAddedPriceChanged',
        'discountOptionsRemovedProductCount', 'discountOptionsRemovedPriceChanged',
        'furtherChangesProductCount', 'furtherChangesPriceChanged',
        'unannoucedPriceChangesProductCount', 'unannoucedPriceChangesPriceChanged',
        'optionsAddedProductCount', 'optionsAddedPriceChanged',
        'optionsRemovedProductCount', 'optionsRemovedPriceChanged',
    ]
    const percent4dot6Fields = [
        'averagePriceChangedPercentage', 'seasonality',
        'discountOptionsAddedPercentage', 'discountOptionsRemovedPercentage', 'furtherChangesPercentage',
        'optionsAddedPercentage', 'optionsRemovedPercentage',
    ]
    data.forEach(item => {
        percent4Fields.forEach(item2 => {
            percent4MaxWidth = Math.max(getStrWidth(item[item2]), percent4MaxWidth)
        })
        percent4dot6Fields.forEach(item2 => {
            percent4dot6Width = Math.max(getStrWidth(item[item2]), percent4dot6Width)
        })
    })

    // 10 = padding left + padding right
    const percent4ContentWidth = tableWidth * width01 - 10
    const percent4dot6ContentWidth = tableWidth * width02 - 10

    if (percent4MaxWidth <= percent4ContentWidth && percent4dot6Width <= percent4dot6ContentWidth) {
        return 1
    }

    return Math.min(percent4ContentWidth / percent4MaxWidth, percent4dot6ContentWidth / percent4dot6Width)
}

/**
 * 
 */
export function getStrWidth(str = '') {
    const symbolWidthMapper = {
        0: 8.41,
        1: 8.41,
        2: 8.41,
        3: 8.41,
        4: 8.41,
        5: 8.41,
        6: 8.41,
        7: 8.41,
        8: 8.41,
        9: 8.41,
        '+': 8.41,
        '-': 5.94,
        ',': 3.2,
        '.': 3.2,
        '%': 13.02,
    }
    const strWidth = str
        .split('')
        .map(item => symbolWidthMapper[item] || 0)
        .reduce((curr, next) => curr + next, 0)
    return strWidth
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []
  
    const titleRow = [
        '',
        'Average price', '', '', '',
        'Price changes for identical options', '', '', '', '', '', '', '', '', '', '', '', '',
        'Product mix changes', '', '', '', '', '',
    ]
    const subTitleRow = [
        'Vendors',
        'Start',
        'End',
        'Change',
        '',
        'Identical options',
        'Discounts added', '', '',
        'Discounts removed', '', '',
        'Discount changes', '', '',
        'Full price changes', '', '',
        'Options in', '', '',
        'Options out', '', '',
    ]
    data.push(titleRow)
    data.push(subTitleRow)
  
    const handleRowData = (item) => {
        return [
            getVendorNameByCode(item?.seller?.vendor),
            numberUtils.formatNumber(item?.start_average_price, { decimal: 0 }),
            numberUtils.formatNumber(item?.end_average_price, { decimal: 0 }),
            numberUtils.formatNumber(item?.average_price_changed, { decimal: 0 }),
            numberUtils.formatNumber(item?.average_price_changed_percentage, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.seasonality, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.discount_options_added?.product_count, { decimal: 0 }),
            numberUtils.formatNumber(item?.discount_options_added?.total_price_changed, { decimal: 0 }),
            numberUtils.formatNumber(item?.discount_options_added?.average_price_changed_percentage, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.discount_options_removed?.product_count, { decimal: 0 }),
            numberUtils.formatNumber(item?.discount_options_removed?.total_price_changed, { decimal: 0 }),
            numberUtils.formatNumber(item?.discount_options_removed?.average_price_changed_percentage, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.discount_price_changed?.product_count, { decimal: 0 }),
            numberUtils.formatNumber(item?.discount_price_changed?.total_price_changed, { decimal: 0 }),
            numberUtils.formatNumber(item?.discount_price_changed?.average_price_changed_percentage, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.original_price_changed?.product_count, { decimal: 0 }),
            numberUtils.formatNumber(item?.original_price_changed?.total_price_changed, { decimal: 0 }),
            numberUtils.formatNumber(item?.original_price_changed?.average_price_changed_percentage, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.options_added?.product_count, { decimal: 0 }),
            numberUtils.formatNumber(item?.options_added?.total_price_changed, { decimal: 0 }),
            numberUtils.formatNumber(item?.options_added?.average_price_changed_percentage, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
            numberUtils.formatNumber(item?.options_removed?.product_count, { decimal: 0 }),
            numberUtils.formatNumber(item?.options_removed?.total_price_changed, { decimal: 0 }),
            numberUtils.formatNumber(item?.options_removed?.average_price_changed_percentage, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
        ]
    }
  
    dataSource.forEach(item => {
        const dataRow = handleRowData(item)
        data.push(dataRow)
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
