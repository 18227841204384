import { useEffect } from 'react'

/**
 * ## useScrollToTop
 * 
 * 页面加载完成后，设置滚动条回到顶部
 * 
 * ## 示例
 * 
 * ```
 * useScrollToTop()
 * ```
 */
export const useScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
}
