import React from 'react'
import ReactDOM from 'react-dom'

export function Other({ children, calendarContainerElement, inputElement }) {
 
  const id = 'other'

  let el = document.querySelector(`#${id}`)
  if (!el) {

    el = document.createElement('div')
    document.body.appendChild(el)
    el.id = id

  }
  
  if (!children) {
    return ReactDOM.createPortal(
      <></>,
      el)
  }
  return ReactDOM.createPortal(
    React.cloneElement(children),
    el)

}

