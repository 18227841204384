/**
* @file    QaButton
* @date   2022-03-15
* @author byte_su@163.com
*/

import React, { useRef, useState } from 'react'

import { Button } from 'components/form-elements/Button'
import { useQa } from 'hooks/useQa'
import { useReport } from 'hooks/useReport'
import { AnalysisIcon, CloseIcon } from 'assets/icons'
import emitter from 'utils/event'
import { CUSTOM_EVENTS, ONE_DAY, QA_BUTTON_CORE_RUN_LABEL, QA_BUTTON_CORE_RUN_VALUE, QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_LABEL, QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_VALUE, QA_BUTTON_IN_OUT_FIX_LABEL, QA_BUTTON_IN_OUT_FIX_VALUE, QA_BUTTON_PRICE_BACKPOLATION_LABEL, QA_BUTTON_PRICE_BACKPOLATION_VALUE, QA_BUTTON_QA_SIZE_LABEL, QA_BUTTON_QA_SIZE_VALUE, QA_BUTTON_SPEED_BENCHMARK_LABEL, QA_BUTTON_SPEED_BENCHMARK_VALUE } from 'consts'
import { BaseSelect, BaseSelectRefProps } from 'componentsv2'
import { DropdownOptionItem, OptionProps } from 'componentsv2/form-elements/Dropdown'
import classnames from 'classnames'
import DatePicker from 'react-modern-calendar-datepicker'
import { formatDatePickerToDate, getDateObject, getToday } from 'utils/dateUtils'
import { ClickAwayListener } from '@material-ui/core'
import styles from './QaButton.module.scss'
import { Other } from './HeaderFilter/other'
import QaSizeDialog from './QaSizeDialog/QaSizeDialog'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import PriceBackpolationDialog from './PriceBackpolationDialog/PriceBackpolationDialog'
import InOutFixDialog from './InOutFixDialog/InOutFixDialog'
import CoreRunDialog from './CoreRunDialog/CoreRunDialog'
import { isSearchForOptionsPage } from 'global/Routes'
import { cloneDeep } from 'lodash'
import { downloadFileByAxios } from 'export'
import { sortVendorList } from 'utils/array'
import { useModuleData } from 'hooks/useModuleData'
import { SEARCH_FOR_OPTIONS_COMPETITOR } from 'features/filters/moduleDataSlice'
import { loadingBar } from 'hooks'
import SpeedBenchmarkDialog from './SpeedBenchmarkDialog/SpeedBenchmarkDialog'

/**
 * `Batch Report` button
 * @returns 
 */
export const QaButton = () => {
  const { checkedProductList } = useQa()
  const { enabledReport, snackOpen, enableQaRun, qaPage, qaRun, qaCheck, qaCount } = useReport()
  const today = new Date(getToday())
  const selectRef = useRef<BaseSelectRefProps>()
  const confirmSelectRef = useRef<BaseSelectRefProps>()
  const [ qaCountOpen, setQaCountOpen ] = useState(false)
  const [ qaCountContent, setQaCountContent ] = useState('')

  const [ date, setDate ] = useState<any>({
    check_test: getDateObject(today),
    check_pro: getDateObject(today),
  })

  const last30 = new Date(Date.now() - ONE_DAY * 30)

  const dialogRef = useRef<DialogRefType>({} as DialogRefType)

  /* ************************* export_zalandodeep_shops ***************************** */
  const [ moduleData ] = useModuleData(SEARCH_FOR_OPTIONS_COMPETITOR)
  const { fetchPayload = {} } = moduleData

  const onExportZalandodeep = async () => {
    selectRef.current?.close()

    try {
      loadingBar.start()
      const payload = cloneDeep(fetchPayload)
      if (Array.isArray(payload?.data?.competitor_sellers)) {
        payload.data.competitor_sellers = sortVendorList({
          vendorList: payload.data.competitor_sellers,
          vendorField: 'vendor',
        })
      }
      payload.url = 'navigation/export_zalandodeep_shops'
      await downloadFileByAxios({
        filename: 'Norna_Analytics_' + QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_LABEL.replaceAll(' ', '_'),
        payload,
      })
      loadingBar.done()
    } catch (e) {
      loadingBar.done()
    }
  }

  const renderDropdown = (
    <div style={{ flex: 1, overflow: 'auto', padding: '0 0px', margin: '10px 0' }}>
      {
        [
          { key: QA_BUTTON_QA_SIZE_VALUE, description: QA_BUTTON_QA_SIZE_LABEL },
          { key: 'qa_count', description: 'Qa Count' },
          { key: 'run_test', description: 'Run Qa Test' },
          { key: 'run_pro', description: ' Run Qa Pro' },
          { key: QA_BUTTON_PRICE_BACKPOLATION_VALUE, description: QA_BUTTON_PRICE_BACKPOLATION_LABEL },
          { key: QA_BUTTON_IN_OUT_FIX_VALUE, description: QA_BUTTON_IN_OUT_FIX_LABEL },
          { key: QA_BUTTON_CORE_RUN_VALUE, description: QA_BUTTON_CORE_RUN_LABEL },
          { key: QA_BUTTON_SPEED_BENCHMARK_VALUE, description: QA_BUTTON_SPEED_BENCHMARK_LABEL },
          { key: QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_VALUE, description: QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_LABEL },
          { key: 'check_test', description: 'Check Qa Test' },
          { key: 'check_pro', description: 'Check Qa Pro' },
        ].filter(item => {
          // QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_VALUE 只在 Search for options 页面显示
          if (!isSearchForOptionsPage() && item.key === QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_VALUE) {
            return false
          }
          if (qaPage) {
            return ![ 'run_pro', 'check_pro' ].includes(item.key)
          }
          if (!qaPage) {
            return ![ 'qa_count' ].includes(item.key)
          }
          return true
        }).map((item: OptionProps) => {
          if (item.key.indexOf('check') > -1) {
            return (
              <div key={item.key}>
                <>
                  {qaPage ? null : (
                    <DatePicker
                      calendarPopperPosition="bottom"
                      calendarClassName={styles.date}
                      value={date[item.key]}
                      minimumDate={getDateObject(last30)}
                      maximumDate={getDateObject(today)}
                      colorPrimary="#01a699"
                      customRenderFn={Other}
                      renderPostion={({ calendarContainerElement, inputElement }) => {
                        const { clientHeight } = document.documentElement
                        const { width, height } = calendarContainerElement.current.getBoundingClientRect()
                        const inputRect = inputElement.current.getBoundingClientRect()
                        calendarContainerElement.current.style.left = (inputRect.x - width) + 'px'
                        const overflowBottom = (inputRect.y + height + inputRect.height) > clientHeight
                        let overflowTop = (inputRect.y - height + inputRect.height) < 0
                        overflowTop = overflowTop ? 70 : inputRect.y - height + inputRect.height
                        calendarContainerElement.current.style.top = (overflowBottom ? overflowTop : inputRect.y + inputRect.height) + 'px'
                        calendarContainerElement.current.style.zIndex = 99999
                        calendarContainerElement.current.style.position = 'fixed'
                      }}
                      onChange={value => {
                        setDate({
                          ...date,
                          [item.key]: value,
                        })
                      }}
                    />
                  )}
                  <Button
                    primary
                    style={{ width: '100%' }}
                    onClick={async e => {
                      e.stopPropagation()
                      if (window.confirm(`Do you want to ${item.description}?`)) {
                        try {
                          let res: any = null
                          res = await qaCheck(item.key === 'check_pro', formatDatePickerToDate(date[item.key]))
                          if (res?.data?.success) {
                            snackOpen(`${item.description} successfully, congrats~`)
                          } else {
                            snackOpen(`${item.description} failed, please try it later `)
                          }
                        } catch (error) {
                          snackOpen(`${item.description} failed, please try it later `)
                        }
                      }
                    }}
                  >{item.description}
                  </Button>
                </>
              </div>
            )
          }
          if (item.key === 'qa_count') {
            return (
              <DropdownOptionItem
                customClass={classnames('cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
                key={item.key}
                item={item}
                active={false}
                showCheckbox={false}
                onSelect={async item => {
                  try {
                    const result: any = await qaCount()
                    if (result?.error) {
                      return snackOpen(`${item.description} failed, please try it later `)
                    }
                    const { data } = result
                    setQaCountContent(JSON.stringify(data, null, 4))
                    setQaCountOpen(true)
                    selectRef.current?.close()
                  } catch (e) {
                    snackOpen(`${item.description} failed, please try it later `)
                  }
                }}
              />
            )
          }
          if (item.key === QA_BUTTON_QA_SIZE_VALUE) {
            return (
              <DropdownOptionItem
                customClass={classnames('cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
                key={item.key}
                item={item}
                active={false}
                showCheckbox={false}
                onSelect={async item => {
                  const dialogId = `${QA_BUTTON_QA_SIZE_VALUE}_dialog`
                  const closeFn = dialogRef.current?.closeDialog
                  dialogRef.current?.openDialog(dialogId, (
                    <QaSizeDialog 
                      closeModal={() => {
                        closeFn(dialogId)
                      }}
                    />
                  ))
                  selectRef.current?.close()
                }}
              />
            )
          }
          if (item.key === QA_BUTTON_PRICE_BACKPOLATION_VALUE) {
            return (
              <DropdownOptionItem
                customClass={classnames('cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
                key={item.key}
                item={item}
                active={false}
                showCheckbox={false}
                onSelect={async item => {
                  const dialogId = `${QA_BUTTON_PRICE_BACKPOLATION_VALUE}_dialog`
                  const closeFn = dialogRef.current?.closeDialog
                  dialogRef.current?.openDialog(dialogId, (
                    <PriceBackpolationDialog 
                      closeModal={() => {
                        closeFn(dialogId)
                      }}
                    />
                  ))
                  selectRef.current?.close()
                }}
              />
            )
          }
          if (item.key === QA_BUTTON_IN_OUT_FIX_VALUE) {
            return (
              <DropdownOptionItem
                customClass={classnames('cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
                key={item.key}
                item={item}
                active={false}
                showCheckbox={false}
                onSelect={async item => {
                  const dialogId = `${QA_BUTTON_IN_OUT_FIX_VALUE}_dialog`
                  const closeFn = dialogRef.current?.closeDialog
                  dialogRef.current?.openDialog(dialogId, (
                    <InOutFixDialog 
                      closeModal={() => {
                        closeFn(dialogId)
                      }}
                    />
                  ))
                  selectRef.current?.close()
                }}
              />
            )
          }
          if (item.key === QA_BUTTON_CORE_RUN_VALUE) {
            return (
              <DropdownOptionItem
                customClass={classnames('cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
                key={item.key}
                item={item}
                active={false}
                showCheckbox={false}
                onSelect={async item => {
                  const dialogId = `${QA_BUTTON_CORE_RUN_VALUE}_dialog`
                  const closeFn = dialogRef.current?.closeDialog
                  dialogRef.current?.openDialog(dialogId, (
                    <CoreRunDialog 
                      closeModal={() => {
                        closeFn(dialogId)
                      }}
                    />
                  ))
                  selectRef.current?.close()
                }}
              />
            )
          }
          if (item.key === QA_BUTTON_SPEED_BENCHMARK_VALUE) {
            return (
              <DropdownOptionItem
                customClass={classnames('cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
                key={item.key}
                item={item}
                active={false}
                showCheckbox={false}
                onSelect={async item => {
                  const dialogId = `${QA_BUTTON_SPEED_BENCHMARK_VALUE}_dialog`
                  const closeFn = dialogRef.current?.closeDialog
                  dialogRef.current?.openDialog(dialogId, (
                    <SpeedBenchmarkDialog 
                      closeModal={() => {
                        closeFn(dialogId)
                      }}
                    />
                  ))
                  selectRef.current?.close()
                }}
              />
            )
          }
          if (item.key === QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_VALUE) {
            return (
              <DropdownOptionItem
                customClass={classnames('cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
                key={item.key}
                item={item}
                active={false}
                showCheckbox={false}
                onSelect={onExportZalandodeep}
              />
            )
          }
          const disabled = qaPage && item.key !== 'run_test'
          return (
            <DropdownOptionItem
              customClass={classnames(disabled ? '' : 'cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
              key={item.key}
              item={item}
              active={false}
              disabled={disabled}
              showCheckbox={false}
              onSelect={async item => {
                if (window.confirm(`Do you want to ${item.description}?`)) {
                  try {
                    let res: any = null
                    res = await qaRun(item.key === 'run_pro')
                    if (res?.data?.success) {
                      snackOpen(`${item.description} successfully, congrats~`)
                    } else {
                      snackOpen(`${item.description} failed, please try it later `)
                    }
                  } catch (error) {
                    snackOpen(`${item.description} failed, please try it later `)
                  }
                }
              }}
            />
          )
        })
      }
    </div>
  )

  const renderConfirmDropdown = (
    <div style={{ flex: 1, overflow: 'auto', padding: '0 0px', margin: '10px 0' }}>
      {
        [
          { key: 'confirm', description: 'Confirm' },
          { key: 'preliminary-confirm', description: 'Preliminary Confirm' },
        ].map((item: OptionProps) => {
          return (
            <DropdownOptionItem
              customClass={classnames('cell-mouse-effect-no-display-no-within-padding-lr-15', styles.lineItem)}
              key={item.key}
              item={item}
              active={false}
              showCheckbox={false}
              onSelect={async item => {
                if (!window.confirm(`Do you want to ${item.key} these selected product?`)) return
                if (item.key === 'confirm') {
                  emitter.emit(CUSTOM_EVENTS.QA_CONFIRM_REPORT)
                } else {
                  emitter.emit(CUSTOM_EVENTS.QA_PRELIMINARY_CONFIRM_REPORT)
                }
                confirmSelectRef.current?.close()
              }}
            />
          )
        })
      }
    </div>
  )

  return (
    <div className={styles.qaButtons}>
      <Dialog key="qa_size_dialog" ref={dialogRef} />
      {
        enabledReport ? (
          <Button
            key="Batch Report"
            disabled={!checkedProductList.length}
            icon={<AnalysisIcon />}
            primary
            style={{ margin: '0px 10px' }}
            onClick={() => emitter.emit(CUSTOM_EVENTS.QA_REPORT)}
          >Batch Report
          </Button>
        ) : null
      }
      {
        enabledReport ? (
          <BaseSelect
              ref={confirmSelectRef as any}
              key="Confirm"
              style={{
                width: '100px',
                minWidth: '100px',
                border: 'solid 1px #ec7665',
                color: '#ffffff',
                background: '#ec7665',
              }}
              className={styles.dropdown}
              selectorStyle={{ color: !qaPage || !checkedProductList.length ? '#666' : '#fff' }}
              dropdownStyle={{ width: 180, overflow: 'visible' }}
              disabled={!qaPage || !checkedProductList.length}
              placeholder="Confirm"
              renderDropdown={renderConfirmDropdown}
          />
        ) : null
      }
      {
        enableQaRun ? (
          <BaseSelect
            ref={selectRef as any}
            key="Qa"
            style={{
              width: '85px',
              minWidth: '85px',
              border: 'solid 1px #ec7665',
              color: '#666666',
              borderRadius: 0,
              borderTopRightRadius: '85px',
              borderBottomRightRadius: '85px',
              background: '#ec7665',
            }}
            className={styles.dropdown}
            dropdownStyle={{ width: 180, overflow: 'visible' }}
            disabled={!enableQaRun}
            placeholder="Qa"
            renderDropdown={renderDropdown}
          />
        ) : null
      }
      {
        qaCountOpen ? (
          <div 
            style={{ width: '100vw', height: '100vh', background: 'transparent', position: 'fixed', left: 0, top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            key="QaCount"
          >
            <ClickAwayListener onClickAway={() => setQaCountOpen(false)}>
              <div style={{ position: 'relative', background: '#fff', borderRadius: '4px', padding: '20px', width: '600px', boxShadow: '0px 2px 28px 4px rgb(0 0 0 / 15%)' }}>
                <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
                  <CloseIcon onClick={() => setQaCountOpen(false)} />
                </div>
                <textarea style={{ width: '100%', minHeight: '300px', maxHeight: '600px', border: 'none', outline: 'none', userSelect: 'none' }} readOnly>
                  {qaCountContent}
                </textarea>
              </div>
            </ClickAwayListener>
          </div>
        ) : null
      }
    </div>
  )
}

QaButton.displayName = 'QaButton'
