import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { NA } from 'consts/strings'
import styles from './styles.module.scss'

export const RectArrow = ({
    value = '',
    percent = 0,        // 0-100
    disabled = false,
    highlight = false,
    style = {},
    className = '',
    arrowStyle = {},
    arrowClassName = '',
    labelStyle = {},
    labelClassName = '',
    onClick,
}: {
    value?: string;
    percent?: number;
    disabled?: boolean;
    highlight?: boolean;
    style?: CSSProperties;
    className?: string;
    arrowStyle?: CSSProperties;
    arrowClassName?: string;
    labelStyle?: CSSProperties;
    labelClassName?: string;
    onClick?: () => void;
}) => {
    const arrowSty: CSSProperties = {
        width: Number(percent) * 0.85 + '%',
    }
    if (value === NA) {
        arrowSty.width = '0%'
    }

    const arrowCls = {
        [styles.arrow]: true,
        [styles.highlight]: highlight,
        [styles.disabled]: disabled,
        [arrowClassName]: arrowClassName,
        pointer: !disabled,
    }

    const labelCls = {
        [styles.label]: true,
        [labelClassName]: labelClassName,
        pointer: !disabled,
    }

    return (
        <div 
            style={{ ...style }}
            className={classnames([ styles.rectArrow, className ])}
            onClick={async () => {
                if (disabled) return
                await onClick?.()
            }}
        >
            <div
                style={{ ...arrowStyle, ...arrowSty }}
                className={classnames(arrowCls)}
            />
            <div
                style={{ ...labelStyle }}
                className={classnames(labelCls)}
            >
                {value}
            </div>
        </div>
    )
}
