import React, { CSSProperties } from 'react'
import { Flex } from 'componentsv2/Flex'
import { NA, NAN } from 'consts'
import { getColorByValue } from 'utils'
import { ColorArrow } from 'componentsv2/ColorArrow'

export const DisplayText = ({
    value,
    showArrow = false,
    fontSize,
    style,
    className,
}: {
    value: any;
    showArrow?: boolean;
    fontSize?: number;
    style?: CSSProperties;
    className?: string;
}) => {
    // 非数值类型直接返回
    const newValue = String(value)
        .replace(/,/g, '')      // '12,345' -> '12345'
        .replace(/%/g, '')      // '12%' -> '12'
        .trim()                 // 去除前后空格 '12 %' -> '12'

    const sty: CSSProperties = {}
    if (typeof fontSize !== 'undefined') {
        sty.fontSize = fontSize + 'px'
    }

    if (String(Number(newValue)) === NAN) {
        return (<div className={className} style={{ color: '#484848', ...sty, ...style }}>{NA}</div>)
    }

    // 根据数值正负获取对应的颜色
    const color = getColorByValue(Number(newValue))
    sty.color = color

    // 前面加正负号
    const symbol = Number(newValue) >= 0 ? '+' : '-'

    return (
        <Flex alignItems="center" style={{ ...sty, ...style }} className={className}>
            { showArrow ? (<ColorArrow value={Number(newValue)} size={12} />) : null }
            { symbol + String(value).replace(/-/g, '').replace(/\+/g, '') }
        </Flex>
    )
}
