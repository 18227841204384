import { ALL_COUNTRIES, ALL_VENDORS, BRAND_VENDOR_SPLITOR, IS_LIVE_ENV, LICENSE_BOGNER, LICENSE_CANADAGOOSE, LICENSE_JUST_BRANDS, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { ApiSellerType, CompetitorSellerType, FilterCompetitorSellerType } from 'types'
import { getVendorNameByCode, vendorShortMappingFn } from 'utils'
import { intersection, sortVendorList } from 'utils/array'
import { storage } from 'utils/storage'
import { DataType } from 'componentsv2/Tree/common'
import { canadagooseFilterCompetitor, getJustBrandsCompetitorChildren, LEVEL1_CANCADAGOOSE, LEVEL1_MONCLER, LEVEL2_DIRECT_PARTNERS, LEVEL2_INDIRECT_PARTNERS, LEVEL3_PURE_PLAYERS, LEVEL3_WHS } from 'configs/filterCompetitor'
import { cloneDeep, sortBy } from 'lodash'
import { TreeItemProp } from 'componentsv2/Tree'
import { isDashboardPages } from 'global/Routes'
import { getDisabledFilterCompetitor } from 'utils/specialCase'

/** *
* ## judege the initial competitor's status of new country when user switch `Filter.country` 
*  
* @param competitorsSelected   all selected competitors from previous country
* @param competitors           all competitors from previous country
* @param competitor            current competitor item
*/
export const judgeCompetitorStatusFromPreviousCountry = (competitorsSelected: Array<string>, competitors: Array<string>, competitor: string): boolean => {

    // case 1: no selected competitor from previous country. ,set initial status `false`
    if (competitorsSelected.length === 0) return false

    // case 2: selected the competitor when current competitor name is in the selected competitors of previous country.
    if (competitorsSelected.indexOf(competitor) > -1) return true

    // case 3: set initial status `false` when  current competitor name is not selected in `competitorsSelected` but exist in `competitors`
    if (competitorsSelected.indexOf(competitor) === -1 && competitors.indexOf(competitor) > -1) return false

    // case 4: set initial status `true` when  current competitor name does not exist in `competitors`
    if (competitorsSelected.indexOf(competitor) === -1 && competitors.indexOf(competitor) === -1) return false

    // other case false
    return false
}

/**
 * 根据 region 从 sellers 中过滤数据
 */
export function getCompetitorSellersByRegion({
    region,
    excludeSelf = true,
}): ApiSellerType[] {
    const sellers = storage.getSellers()
    let competitorSellers: ApiSellerType[] = []

    /**
     * region 为 All countires 时
     */
    if (region === ALL_COUNTRIES) {
        // 利用对象字面量键值会覆盖特性获取所有 vendorCode 信息
        const sellerObj = sellers.reduce((res, seller) => {
            if (res[seller.vendor]) return res
            res[seller.vendor] = seller
            return res
        }, {})
        competitorSellers = Object.values(sellerObj)
    }
    // region 为其它时, vendorCode + region 做分组
    else {
        competitorSellers = sellers.filter(item => item.region === region)
    }

    if (excludeSelf) {
        competitorSellers = competitorSellers.filter(item => item.vendor !== storage.getCustomerVendor())
    }

    return competitorSellers
}

/**
 * 将 sellers 数据结构转换为下拉框需要的数据结构
 */
export function handleSellersToOptions(sellers: ApiSellerType[]) {
    const options = sellers.map(seller => {
        return {
            key: seller.vendor,
            description: seller.name,
            brands: [],
            // 代码里印象中没用到的字段
            smallText: seller.region,
            parent: seller.region,
            isSelected: false,
        }
    })
    return options
}

/**
 * options 拆分 brands
 * 
 * e.g
 * ```
 * [
 *      { key:'Kate Spade @ El Palacio de Hierro' },
 *      { key:'Coach @ El Palacio de Hierro' },
 * ]
 * 
 * ->
 * 
 * [
 *      { 
 *          key:' @ El Palacio de Hierro', 
 *          brands: ['Kate Spade','Coach'], 
 *      }
 * ]
 * ```
 */
export function handleCompetitorOptionsWithBrands(options: any[], originalKey = false) {
    // 按照 platform 进行分组
    const optionsObj = options.reduce((res, option) => {
        // vendor 包含 @ 符号
        if (option.key.indexOf(BRAND_VENDOR_SPLITOR) > -1) {
            // 'Canada Goose@24s' -> brand = 'Canada Goose', vendor = '24s'
            const [ brand, vendor ] = option.key.split(BRAND_VENDOR_SPLITOR)
            const [ , vendorName ] = option.description.split(BRAND_VENDOR_SPLITOR)

            // 不存在
            if (!res[vendorName]) {
                res[vendorName] = {
                    ...option,
                    key: vendorName,
                    description: handleGroupName(vendorName),
                    brands: [ brand ],
                    value: vendor,
                }
            }
            // 存在直接更新 brands
            else {
                res[vendorName].brands.push(brand)
            }

            if (res[vendorName]?.brands?.length) {
                const newBrands = sortVendorList({
                    vendorList: res[vendorName].brands.map(item => `${item}@${vendor}`),
                }).map(item => item.split(BRAND_VENDOR_SPLITOR)[0])
                res[vendorName].brands = newBrands
            }
        }
        // vendor 不包含 @ 符号
        else {
            /**
             * note:
             * competitors = [ 
             *      { key: 'aaa@bogner' },
             *      { key: 'bogner' }
             * ]
             * 遍历第一次时, 因为有 @ 符合, res 已经有值了 res = {'bogner': {brand: ['aaa']}}
             * 
             * 然后遍历第二次, 之前的逻辑是直接 res[option.key] = item 就会造成第二次循环时替换了 res 中的 bogner 键
             * 变成了 res = {'bogner': {brand: []}}
             */
            if (!res[option.key]) {
                res[option.key] = { ...option }
            }
        }
        return res
    }, {})

    let newOptions: any[] = Object.values(optionsObj)
    const region = newOptions[0]?.parent

    // 没有 Selected vendors 要添加
    if (!newOptions.find(option => option.key === SELECTED_VENDORS)) {
        newOptions.push({
            key: SELECTED_VENDORS,
            description: SELECTED_VENDORS,
            brands: [],
            // 代码里印象中没用到的字段
            smallText: region,
            parent: region,
            isSelected: false,
        })
    }

    // 非 LIVE 环境没有 Market 要添加 Market
    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/778#note_1273578269
    if (!IS_LIVE_ENV && !newOptions.find(option => option.key === MARKET_VENDOR.vendor)) {
        newOptions.push({
            key: MARKET_VENDOR.vendor,
            description: ALL_VENDORS,
            brands: [],
            // 代码里印象中没用到的字段
            smallText: MARKET_VENDOR.region,
            parent: MARKET_VENDOR.region,
            isSelected: false,
        })
    }

    // 排序
    newOptions = sortVendorList({ vendorList: newOptions, vendorField: 'key' })
    return newOptions
}

/**
 * 选中的 competitor 转换为带 brands 格式 
 */
export function handleCompetitorSellersWithBrands(competitorSellers: CompetitorSellerType[]): FilterCompetitorSellerType[] {
    // vendor -> brands 排序
    const sellerObj = competitorSellers.reduce((res, seller) => {
        if (seller.vendor.indexOf('@') > -1) {
            const [ brand ] = seller.vendor.split('@')
            const [ , vendorName ] = getVendorNameByCode(seller.vendor).split('@')
            if (!res[vendorName]) {
                res[vendorName] = {
                    ...seller,
                    vendor: vendorName,
                    brands: [ brand ],
                }
            } else {
                res[vendorName].brands?.push(brand)
                res[vendorName].brands?.sort()
            }
        }
        else {
            if (!res[seller.vendor]) {
                res[seller.vendor] = {
                    ...seller,
                    brands: [],
                }
            }
        }
        return res
    }, {})
    return Object.values(sellerObj)
}

/**
 * 获取选中的 competitor 值
 */
export function getSelectedCompetitorValue(competitorSellers: FilterCompetitorSellerType[]) {
    const selectedVendorsBrands = {}
    const sellers = handleCompetitorSellersWithBrands(competitorSellers)
    const selectedVendors = sellers.map(seller => {
        selectedVendorsBrands[seller.vendor] = seller.brands || []
        return seller.vendor
    })
    return {
        selectedVendors,
        selectedVendorsBrands,
    }
}

/**
 * 生成 competitor brands 子节点
 */
export function handleCompetitorBrandsChildren(brands: string[], selectedBrands: string[] = []) {
    const disabledFilterCompetitor = getDisabledFilterCompetitor()
    let children
    if (brands?.length) {
        const sellers: ApiSellerType[] = storage.getSellers()
        children = brands.map(brand => {
            const seller = sellers.find(seller => seller.vendor?.startsWith(brand))
            return {
                val: brand,
                active: selectedBrands?.includes(brand),
                type: DataType.CHECK,
                label: seller?.name?.split('@')?.[0] || brand,
                masked: disabledFilterCompetitor.includes(brand),
                vendorCode: seller?.vendor,
            }
        })
    }
    return children
}

/**
 * region 变化时, 获取 Competitor 选项数据
 * 多个方法公用, 提出为公共方法
 */
export function handleFilterCompetitorOptions(region, excludeSelf = false, originalKey = false) {
    const competitorSellers = getCompetitorSellersByRegion({ region, excludeSelf: excludeSelf })
    let options: any = handleSellersToOptions(competitorSellers)
    options = handleCompetitorOptionsWithBrands(options, originalKey)
    return options
}

/**
 * 生成 Filter > Competitor 节点元素
 */
export function getFilterCompetitorChildren({
    region,
    competitors,        // 当前选中的 competitor
    excludeSelf = false,
}) {
    const disabledFilterCompetitor = getDisabledFilterCompetitor()

    if (storage.getCustomerVendor() === LICENSE_CANADAGOOSE) {
        return getFilterCompetitorChildrenForCanadagoose({ region, competitors, disabledFilterCompetitor, excludeSelf })
    }

    if (storage.getCustomerVendor() === LICENSE_JUST_BRANDS) {
        return getFilterCompetitorChildrenForJustBrands({ region, competitors, disabledFilterCompetitor, excludeSelf })
    }

    const { selectedVendors, selectedVendorsBrands } = getSelectedCompetitorValue(competitors)
    const options = handleFilterCompetitorOptions(region, excludeSelf)
    const children = options.map(option => {
        const children = handleCompetitorBrandsChildren(option.brands, selectedVendorsBrands[option.key])
        return {
            label: vendorShortMappingFn(option.description),
            val: option.key,
            region: option.smallText,
            active: selectedVendors.includes(option.key),
            enableSelectAll: true,
            children,
            masked: disabledFilterCompetitor.includes(option.key),
            type: option.brands?.length ? DataType.ARROW_CHECK : DataType.CHECK,
        }
    })
    return children
}

export function handleGroupName(groupName: string) {
    const customerVendor = storage.getCustomerVendor()
    if (customerVendor === LICENSE_BOGNER) {
        const names = [ 'Bogner' ]
        const mapper = names.reduce((obj, name: string) => {
            obj[name] = `${name} brands`
            return obj
        }, {})
        return mapper[groupName] || groupName
    }
    return groupName
}

/**
 * 处理 Select all 
 */
export function handleFilterCompetitorSelecteAll(item: any) {
    const newItem = cloneDeep(item)

    /**
     * in live env,
     * when you click Filter > Competitor to select all competitors
     * it doesn't contain `SELECTED_VENDORS`
     */
    const excludeCompetitorsForSelectAllFilterCompetitor = IS_LIVE_ENV ? [
        SELECTED_VENDORS,
    ] : []

    // Whether all is selected
    const isSelectAll = newItem.children
        .filter(item => !excludeCompetitorsForSelectAllFilterCompetitor.includes(item.val))
        .every(item => item.active)

    const iterator = function (arr = [], cb) {
        // eslint-disable-next-line
        for (let i = 0; i < arr.length; i++) {
            cb.call(arr[i], arr[i])
        }
    }

    const setAllActive = function (item) {
        item.active = !excludeCompetitorsForSelectAllFilterCompetitor.includes(item.val)
        if (item?.children?.length) {
            iterator(item.children, setAllActive)
        }
    }

    const setAllDeactive = function (item) {
        item.active = false
        if (item?.children?.length) {
            iterator(item.children, setAllDeactive)
        }
    }

    if (isSelectAll) {
        iterator(newItem.children, setAllDeactive)
    } else {
        iterator(newItem.children, setAllActive)
    }
    return newItem
}

export function handleFilterCompetitorByRegion({
    region,
    competitors,
    currentCompetitorTreeItem,
}: {
    region: string;
    competitors,
    currentCompetitorTreeItem: any;
}) {
    if (LICENSE_CANADAGOOSE === storage.getCustomerVendor()) {
        const disabledFilterCompetitor = getDisabledFilterCompetitor()
        return getFilterCompetitorChildrenForCanadagoose({ region, competitors, disabledFilterCompetitor })
    }

    if (LICENSE_JUST_BRANDS === storage.getCustomerVendor()) {
        const disabledFilterCompetitor = getDisabledFilterCompetitor()
        return getFilterCompetitorChildrenForJustBrands({ region, competitors, disabledFilterCompetitor })
    }

    const options = handleFilterCompetitorOptions(region)
    let data = options
        .map(item => {
            /**
             * ## vendor with brands
             */
            //----------------------------------------------------------------------------------------------------

            let selectedBrands: Array<string> = []
            const previousCurrentItem = (currentCompetitorTreeItem.children ?? [])
                .find((competitor: TreeItemProp) => competitor.active && competitor.val === item.key)

            // ------ case 1: item of previous region exists and it's brands exist
            if (previousCurrentItem && previousCurrentItem.children) {
                selectedBrands = item.brands.filter(brand => judgeCompetitorStatusFromPreviousCountry(
                    (previousCurrentItem.children ?? []).filter(item => item.active).map(item => item.val),
                    (previousCurrentItem.children ?? []).map(item => item.val),
                    brand,
                ))
                // ------ case 2: item of previous region do not exist the vendor with brands. selected all as the default value.
            } else if (item.brands) {
                selectedBrands = item.brands
            }

            return {
                label: vendorShortMappingFn(item.description),
                val: item.key,
                region: item.smallText,
                active: isDashboardPages() ? ![ MARKET_VENDOR.vendor, SELECTED_VENDORS ].includes(item.key) : judgeCompetitorStatusFromPreviousCountry(
                    (currentCompetitorTreeItem.children ?? []).filter((item: TreeItemProp) => item.active).map((item: TreeItemProp) => item.val),
                    (currentCompetitorTreeItem.children ?? []).map((item: TreeItemProp) => item.val),
                    item.key),
                enableSelectAll: true,
                children: handleCompetitorBrandsChildren(item.brands, selectedBrands),
                type: item.brands?.length ? DataType.ARROW_CHECK : DataType.CHECK,
            }
        })
    data = sortVendorList({
        vendorList: data,
        vendorField: 'val',
    })
    return data
}

/* ****************************** Just brands START ************************************** */
function getFilterCompetitorChildrenForJustBrands({
    region,
    competitors,        // 当前选中的 competitor
    disabledFilterCompetitor = [],
    excludeSelf = false,
}: {
    region: string;
    competitors: any[];
    disabledFilterCompetitor: string[];
    excludeSelf?: boolean;
}) {
    // 选中值
    const selectedVendors = competitors.map(c => c.vendor)

    const children: any[] = [
        ...getJustBrandsCompetitorChildren({
            region,     // 地区
            selectedVendors,        // 选中值
            disabledFilterCompetitor,
        }),
        {
            active: selectedVendors.includes(SELECTED_VENDORS),
            label: SELECTED_VENDORS,
            val: SELECTED_VENDORS,
            region,
            type: DataType.CHECK,
            masked: disabledFilterCompetitor.includes(SELECTED_VENDORS),
        },
    ]

    if (!IS_LIVE_ENV) {
        children.push({
            active: selectedVendors.includes(MARKET_VENDOR.vendor),
            label: ALL_VENDORS,
            val: MARKET_VENDOR.vendor,
            region,
            type: DataType.CHECK,
            masked: disabledFilterCompetitor.includes(MARKET_VENDOR.vendor),
        })
    }

    return children
}

export function getJustBrandsFilterCompetitorValue(competitorOptions) {
    const sellers: { vendor: string; region: string }[] = []

    const iterator = function (arr = [], cb) {
        // eslint-disable-next-line
        for (let i = 0; i < arr.length; i++) {
            cb.call(arr[i], arr[i])
        }
    }

    const handleItem = function (item) {
        if (item?.children?.length) {
            iterator(item.children, handleItem)
            return
        }
        if (item?.active) {
            sellers.push({
                vendor: item.val,
                region: item.region,
            })
        }
    }

    iterator(competitorOptions?.children, handleItem)

    return sellers
}
/* ****************************** Just brands End ************************************** */

/* ****************************** Canada goose START ************************************** */
function getFilterCompetitorChildrenForCanadagoose({
    region,
    competitors,        // 当前选中的 competitor
    disabledFilterCompetitor = [],
    excludeSelf = false,
}: {
    region: string;
    competitors: any[];
    disabledFilterCompetitor: string[];
    excludeSelf?: boolean;
}) {
    // 过滤出 sellers 中有的数据
    const competitorSellers = getCompetitorSellersByRegion({ region, excludeSelf })
    const vendors = competitorSellers.map(s => s.vendor)
    const sellers = canadagooseFilterCompetitor.filter(item => vendors.includes(item.vendor))

    // 生成 competitor tree 的几组叶子节点
    const leafSellers = getCanadagooseCompetitorLeaf(sellers)
    const selectedVendors = competitors.map(c => c.vendor)

    const isCanadagooseDirectWhsActive = !!intersection(selectedVendors, leafSellers.canadagooseDirectWhsSellers.map(s => s.vendor))?.length
    const isCanadagooseDirectPureActive = !!intersection(selectedVendors, leafSellers.canadagooseDirectPureSellers.map(s => s.vendor))?.length
    const isCanadagooseIndirectActive = !!intersection(selectedVendors, leafSellers.canadagooseIndirectSellers.map(s => s.vendor))?.length
    const isMonclerDirectWhsActive = !!intersection(selectedVendors, leafSellers.monclerDirectWhsSellers.map(s => s.vendor))?.length
    const isMonclerDirectPureActive = !!intersection(selectedVendors, leafSellers.monclerDirectPureSellers.map(s => s.vendor))?.length
    const isMonclerIndirectActive = !!intersection(selectedVendors, leafSellers.monclerIndirectSellers.map(s => s.vendor))?.length

    const children: any[] = [
        {
            active: isCanadagooseDirectWhsActive || isCanadagooseDirectPureActive || isCanadagooseIndirectActive,
            label: LEVEL1_CANCADAGOOSE,
            type: DataType.ARROW_CHECK,
            enableSelectAll: true,
            masked: disabledFilterCompetitor.includes(LEVEL1_CANCADAGOOSE),
            children: [
                {
                    active: isCanadagooseDirectWhsActive || isCanadagooseDirectPureActive,
                    label: LEVEL2_DIRECT_PARTNERS,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    masked: disabledFilterCompetitor.includes(LEVEL2_DIRECT_PARTNERS),
                    children: [
                        {
                            active: isCanadagooseDirectWhsActive,
                            label: LEVEL3_WHS,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(LEVEL3_WHS),
                            children: getCanadagooseLeafTreeNode({ sellers: leafSellers.canadagooseDirectWhsSellers, region, competitors }),
                        },
                        {
                            active: isCanadagooseDirectPureActive,
                            label: LEVEL3_PURE_PLAYERS,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(LEVEL3_PURE_PLAYERS),
                            children: getCanadagooseLeafTreeNode({ sellers: leafSellers.canadagooseDirectPureSellers, region, competitors }),
                        },
                    ],
                },
                {
                    active: isCanadagooseIndirectActive,
                    label: LEVEL2_INDIRECT_PARTNERS,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    masked: disabledFilterCompetitor.includes(LEVEL2_INDIRECT_PARTNERS),
                    children: getCanadagooseLeafTreeNode({ sellers: leafSellers.canadagooseIndirectSellers, region, competitors }),
                },
            ],
        },
        {
            active: isMonclerDirectWhsActive || isMonclerDirectPureActive || isMonclerIndirectActive,
            label: LEVEL1_MONCLER,
            type: DataType.ARROW_CHECK,
            enableSelectAll: true,
            masked: disabledFilterCompetitor.includes(LEVEL1_MONCLER),
            children: [
                {
                    active: isMonclerDirectWhsActive || isMonclerDirectPureActive,
                    label: LEVEL2_DIRECT_PARTNERS,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    masked: disabledFilterCompetitor.includes(LEVEL2_DIRECT_PARTNERS),
                    children: [
                        {
                            active: isMonclerDirectWhsActive,
                            label: LEVEL3_WHS,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(LEVEL3_WHS),
                            children: getCanadagooseLeafTreeNode({ sellers: leafSellers.monclerDirectWhsSellers, region, competitors }),
                        },
                        {
                            active: isMonclerDirectPureActive,
                            label: LEVEL3_PURE_PLAYERS,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(LEVEL3_PURE_PLAYERS),
                            children: getCanadagooseLeafTreeNode({ sellers: leafSellers.monclerDirectPureSellers, region, competitors }),
                        },
                    ],
                },
                {
                    active: isMonclerIndirectActive,
                    label: LEVEL2_INDIRECT_PARTNERS,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    masked: disabledFilterCompetitor.includes(LEVEL2_INDIRECT_PARTNERS),
                    children: getCanadagooseLeafTreeNode({ sellers: leafSellers.monclerIndirectSellers, region, competitors }),
                },
            ],
        },
        ...getCanadagooseLeafTreeNode({ sellers: sortBy(sellers.filter(s => !s.level1), 'vendor'), region, competitors }),
        {
            active: selectedVendors.includes(SELECTED_VENDORS),
            label: SELECTED_VENDORS,
            val: SELECTED_VENDORS,
            region,
            type: DataType.CHECK,
            masked: disabledFilterCompetitor.includes(SELECTED_VENDORS),
        },
    ]

    if (!IS_LIVE_ENV) {
        children.push({
            active: selectedVendors.includes(MARKET_VENDOR.vendor),
            label: ALL_VENDORS,
            val: MARKET_VENDOR.vendor,
            region,
            type: DataType.CHECK,
            masked: disabledFilterCompetitor.includes(MARKET_VENDOR.vendor),
        })
    }

    return children
}

/**
 * 获取 Canadagoose Competitor 叶子节点
 */
function getCanadagooseLeafTreeNode({
    sellers,
    region,
    competitors,
}: {
    sellers: any[];
    region: string;
    competitors: CompetitorSellerType[];
}) {
    return sellers.map(seller => {
        return {
            active: competitors.map(c => c.vendor).includes(seller.vendor),
            label: seller.name,
            val: seller.vendor,
            region,
            type: DataType.CHECK,
        }
    })
}

function getCanadagooseCompetitorLeaf(sellers) {
    const canadagooseDirectWhsSellers = sortBy(sellers.filter(item => item.level1 === LEVEL1_CANCADAGOOSE && item.level2 === LEVEL2_DIRECT_PARTNERS && item.level3 === LEVEL3_WHS), [ 'name' ])
    const canadagooseDirectPureSellers = sortBy(sellers.filter(item => item.level1 === LEVEL1_CANCADAGOOSE && item.level2 === LEVEL2_DIRECT_PARTNERS && item.level3 === LEVEL3_PURE_PLAYERS), [ 'name' ])
    const canadagooseIndirectSellers = sortBy(sellers.filter(item => item.level1 === LEVEL1_CANCADAGOOSE && item.level2 === LEVEL2_INDIRECT_PARTNERS), [ 'name' ])
    const monclerDirectWhsSellers = sortBy(sellers.filter(item => item.level1 === LEVEL1_MONCLER && item.level2 === LEVEL2_DIRECT_PARTNERS && item.level3 === LEVEL3_WHS), [ 'name' ])
    const monclerDirectPureSellers = sortBy(sellers.filter(item => item.level1 === LEVEL1_MONCLER && item.level2 === LEVEL2_DIRECT_PARTNERS && item.level3 === LEVEL3_PURE_PLAYERS), [ 'name' ])
    const monclerIndirectSellers = sortBy(sellers.filter(item => item.level1 === LEVEL1_MONCLER && item.level2 === LEVEL2_INDIRECT_PARTNERS), [ 'name' ])
    return {
        canadagooseDirectWhsSellers,
        canadagooseDirectPureSellers,
        canadagooseIndirectSellers,
        monclerDirectWhsSellers,
        monclerDirectPureSellers,
        monclerIndirectSellers,
    }
}

export function getCanadagooseFilterCompetitorValue(competitorOptions) {
    const sellers: { vendor: string; region: string }[] = []

    const iterator = function (arr = [], cb) {
        // eslint-disable-next-line
        for (let i = 0; i < arr.length; i++) {
            cb.call(arr[i], arr[i])
        }
    }

    const handleItem = function (item) {
        if (item?.children?.length) {
            iterator(item.children, handleItem)
            return
        }
        if (item?.active) {
            sellers.push({
                vendor: item.val,
                region: item.region,
            })
        }
    }

    iterator(competitorOptions?.children, handleItem)

    return sellers
}

/* ****************************** Canada goose END ************************************** */
