import { cloneDeep } from 'lodash'

export const encodeObjectValue = (param: any = {}) => {
    const paramData = cloneDeep(param)
    Object.keys(paramData).forEach(key => {
        if (typeof paramData[key] === 'string') {
            paramData[key] = encodeURIComponent(paramData[key])
        }
    })
    return paramData
}
