/**
 * 生成日历天数
 * 
 * 如果当前月第一天不是星期一，前面需要补上个月的数据
 * 如果当前月最后一天不是星期日，后面需要补下个月的数据
 */
export const geneDayArr = (year, month) => {
    // 上个月总共多少天，30
    const lastMonthTotalDays = new Date(year, month, 0).getDate()
    // 当前月总共有多少天
    const currentMonthTotalDays = new Date(year, month + 1, 0).getDate()

    // 当前月的第一天是星期几，5
    const firstDayWeek = new Date(year, month, 1).getDay() || 7
    // 当前月最后一天是星期几
    const lastDayWeek = new Date(year, month, currentMonthTotalDays).getDay() || 7

    const days = Array.from({ length: currentMonthTotalDays }, (_, index) => ({
        day: index + 1,
        year,
        month: month + 1,
        date: `${year}-${String(Number(month) + 1).padStart(2, '0')}-${String(index + 1).padStart(2, '0')}`,
    }))

    // eslint-disable-next-line
    for (let i = 0; i < firstDayWeek - 1; i++) {
        let prevDaysYear = year
        let prevDaysMonth = month
        if (prevDaysMonth === 0) {
            prevDaysYear -= 1
            prevDaysMonth = 12
        }
        days.unshift({
            day: lastMonthTotalDays - i,
            year: prevDaysYear,
            month: prevDaysMonth,
            date: `${prevDaysYear}-${String(prevDaysMonth).padStart(2, '0')}-${String(lastMonthTotalDays - i).padStart(2, '0')}`,
        })
    }

    const appendDays = Array.from({ length: 7 - lastDayWeek }, (_, index) => {
        let nextYear = year
        let nextMonth = month
        if (nextMonth === 11) {
            nextYear += 1
            nextMonth = (nextMonth + 2) % 12
        } else {
            nextMonth += 2
        }
        return {
            day: index + 1,
            year: nextYear,
            month: nextMonth,
            date: `${nextYear}-${String(nextMonth).padStart(2, '0')}-${String(index + 1).padStart(2, '0')}`,
        }
    })

    const daysInMonth = [ ...days, ...appendDays ]

    const newDaysInMonth: any[] = []
    // eslint-disable-next-line
    for (let i = 0; i < daysInMonth.length / 7; i++) {
        const rowData = daysInMonth.slice(i * 7, (i + 1) * 7)
        newDaysInMonth.push(rowData)
    }

    return newDaysInMonth
}

/**
 * 参数中的日期是一年中第几天
 */
export function getDaysInYear(dateStr) {
    const date = new Date(dateStr)
    const year = date.getFullYear()         // 指定日期的年份
    const month = date.getMonth()           // 指定日期的月份
    const dayInMonth = date.getDate()       // 指定日期是当前月第几天

    const daysInMonths = [ 31, new Date(year, 2, 0).getDate(), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]
    const newDaysInMonths = daysInMonths.slice(0, month)

    return newDaysInMonths.reduce((prev, next) => prev + next, 0) + dayInMonth
}

/**
 * 参数中的日期是一年中第几周
 * 
 * 2021 年
 * 1号是星期五
 * 2号是星期六
 * 3号是星期日
 *
 * 有的计算方法将这三天归为上一年的最后一周 includeSpecialDays = false
 * 有的计算方法将这三天归为这一年的第一周 includeSpecialDays = true
 */
export function getWeeksInYear(dateStr: string, includeSpecialDays = false) {
    const daysInYear = getDaysInYear(dateStr)

    const date = new Date(dateStr)
    const year = date.getFullYear()
    // 当前年的第一天是星期几
    const firstDayInYear = new Date(year, 0, 1).getDay() || 7

    /**
     * 特殊天数
     */
    const specialDays = 7 - firstDayInYear + 1

    if (daysInYear > specialDays) {
        return Math.ceil((daysInYear - specialDays) / 7) + (includeSpecialDays ? 1 : 0)
    }

    return includeSpecialDays ? 1 : 0
}
