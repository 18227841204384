import { Button } from 'components/form-elements/Button'
import { Text } from 'components/typography'
import React, { useEffect, useState } from 'react'
import { Checkbox } from 'components/form-elements/Checkbox'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'
import { ErrorInfo, LoadingSpinner } from '../../index'
import { OtherLookbookModalProps } from './types'
import styles from './styles.module.scss'

const TYPE = {
  REMOVE: 'remove',
  SELECT: 'select',
}

export function SelectProductLookbook({
  type = TYPE.SELECT,
  onSave,
  lookbooks = [],
  myLookbookList,
  selectedProductId,
}: OtherLookbookModalProps) {
  const [ allLookbook, setAllLookbook ] = useState([ ...myLookbookList ?? [] ])
  const [ validateOtherLookbook, setValidateOtherLookbook ] = useState(false)
  const [ last, setLast ] = useState({ id: '' })

  useEffect(() => {
    const newLookbookList = cloneDeep(myLookbookList || [])
    newLookbookList.forEach((lookbook: any) => {
      lookbook.isChecked = type === TYPE.SELECT ? lookbooks.includes(lookbook.id) : false
    })
    if (newLookbookList.length ===1 && type === TYPE.REMOVE) {
      newLookbookList.forEach(item => {
        item.isChecked = true
      })
    }
    setAllLookbook([ ...newLookbookList ])
  }, [ myLookbookList, lookbooks, setAllLookbook, type ])

  const onOtherLookbookChange = lookbook => {
    lookbook.isChecked = !lookbook.isChecked
    if (lookbook.isChecked) {
      setLast(lookbook)
    }
    setAllLookbook([ ...allLookbook ])
  }

  const saveToLookbook = () => {
    let selectedLookbooks = allLookbook// .filter(item => item.isChecked);
    if (selectedLookbooks && selectedLookbooks.filter(item => item.isChecked).length > 0) {
      // add the last selected lookbook  to the first position
      selectedLookbooks = selectedLookbooks.filter(item => item.id !== last.id)
      if (last.id) {
        selectedLookbooks.unshift(last) //
      }
      onSave([
        [ ...selectedLookbooks ],
        last.id ? last : null,// last checked
      ])
    } else {
      setValidateOtherLookbook(true)
    }
  }
  
  return (
      <div className="flex flex-column" style={{ width: '200px' }}>
          <div className={styles.modalBody}>
              <div className={styles.modalContent}>
                  {allLookbook ? allLookbook.map(lookbook =>
                      <div key={lookbook.id} className={lookbook.isChecked ? styles.lookbookSelected : ''}>
                          <Checkbox
                              name={lookbook.name}
                              isChecked={lookbook.isChecked}
                              isDisabled={lookbook.disabled}
                              onChange={() => onOtherLookbookChange(lookbook)}
                          >
                              <Text small className={classNames([ styles.lookbookName, 'ellipsis' ])} style={{ width: '150px' }}>
                                  <span>{lookbook.name}</span>
                              </Text>
                          </Checkbox>
                      </div>,
          ) : <LoadingSpinner />}
              </div>
          </div>
          {validateOtherLookbook ? <ErrorInfo msg="At least one check box should be checked" /> : null}
          <div className={styles.modalFooter}>
              <Button primary onClick={() => saveToLookbook()}>
                  {TYPE.SELECT === type ? 'Save' : 'Remove'}
              </Button>
          </div>
      </div>
  )
};
SelectProductLookbook.TYPE = TYPE
