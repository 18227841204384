let legendEle: HTMLSpanElement

export const calcEle = (label: string) => {
    if (!legendEle) {
        legendEle = document.createElement('span') as HTMLSpanElement
        legendEle.style.fontSize = '16px'
        legendEle.style.display = 'none'
        document.body.append(legendEle)
    }

    legendEle.innerHTML = label
    let rect = legendEle.getBoundingClientRect()
    let labelStr = label
    let num = 4
    if (rect.width > 89) {

        // legendEle.innerHTML = labelStr.slice(0, num)
        // rect = legendEle.getBoundingClientRect()
        do {
            // eslint-disable-next-line no-plusplus
            num++

            // labelStr
            legendEle.innerHTML = labelStr.slice(0, num)
            rect = legendEle.getBoundingClientRect()

        } while (rect.width < 89) 
        labelStr = labelStr.slice(0, num)
        if (num < label.length) labelStr = labelStr.slice(0, -1) + '...'
    }

    return labelStr
}
