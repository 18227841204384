import React from 'react'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Collapse } from '../Collapse'
import { Descriptions } from '../Descriptions'

export const ProductDetails = ({
    targetGroup,
    propertyList = [],
    materialObj = {},
    colorList,
    sizeList,
    bottom = 20,
}: {
    targetGroup: any;
    propertyList: any;
    materialObj: any;
    colorList: any[];
    sizeList: any[];
    bottom?: number;
}) => {
    return (
        <Collapse title="Product details" bottom={bottom}>
            <Descriptions column={3}>
                <Descriptions.Item label="Gender" value={targetGroup} />
                <ShouldRender shouldRender={(propertyList?.length || 0) > 0}>
                    <Descriptions.Item label={propertyList[0]?.key || ''} value={propertyList[0]?.value || ''} key={propertyList[0]?.key || 'index-1'} />
                </ShouldRender>
                <ShouldRender shouldRender={Object.keys(materialObj || {}).length > 0}>
                    <Descriptions.Item label="Material" value={materialObj} valueRightStyle={{ width: '35px' }} />
                </ShouldRender>
                {
                    propertyList?.slice(1).map(property => (
                        <Descriptions.Item label={property.key} value={property?.value} key={property.key} />
                    ))
                }
                <Descriptions.Item label="Colors" value={String(colorList?.length || 0)} />
                <Descriptions.Item label="Sizes" value={String(sizeList?.length || 0)} />
            </Descriptions>
        </Collapse>
    )
}
