import { cloneDeep } from 'lodash'
import { numberUtils } from 'norna-uikit'

export function handleSoldOutBarChartData(data) {
    const newData = cloneDeep(data)
    const soldOutBarChartData = newData.map(item => {
        return {
            label: item.title,
            value: item?.dataSource?.[0]?.value?.replace('%', '').replace(',', ''),
        }
    })
    return soldOutBarChartData
}

export function handleSoldOutSplitBarChartData(data) {
    if (!data?.length) return []
    let newData = cloneDeep(data)

    const lastItemValue = newData[newData.length - 1].value?.replace(',', '').replace('%', '')

    newData = newData.map(item => {
        item.value = !lastItemValue ? 0 : numberUtils.formatNumber(Number(item.value.replace(',', '').replace('%', '')) / Number(lastItemValue), { isCentuple: true, decimal: 1 })
        return item
    })
    return newData
}
