import { SizedBox } from 'componentsv2/SizedBox'
import React from 'react'
import { AssortmentInsightTable } from './components/AssortmentInsightTable'

export const AssortmentInsight = () => {

    return (
        <>
            <AssortmentInsightTable />
            <SizedBox height={30} />
        </>
    )
}
