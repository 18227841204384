import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Dropdown, DropdownRefProps } from 'componentsv2/form-elements/Dropdown'
import { getComparedToOptions, getTimePeriodOptions } from './utils'
import { isSearchForOptionsPage } from 'global/Routes'
import { useCalendarCompareToValue, useCalendarComparisonTmpDate, useCalendarPeriodValue, useCalendarTmpDate } from 'hooks/useGlobalData'
import { getDateKeyValueMapper, getDateRangeOfLastYear } from '../../GlobalDatePicker.util'
import * as str from '../../GlobalDatePicker.const'
import styles from './styles.module.scss'
import { formatDateStr2 } from 'utils/dateUtils'
import { NA } from 'consts'
import { dateRangeUtils } from 'norna-uikit'

const DropdownGroup = (props, ref) => {
    const [ periodValue, setPeriodValue ] = useCalendarPeriodValue()
    const [ compareToValue, setCompareToValue ] = useCalendarCompareToValue()
    const [ calendarTmpDate, setTmpDate ] = useCalendarTmpDate()
    const [ calendarComparisonTmpDate, setComparisonTmpDate ] = useCalendarComparisonTmpDate()

    useImperativeHandle(ref, () => {
        return {
          close: () => {
            periodRef.current?.close()
            compareToRef.current?.close()
          },
        }
    })

    /**
     * 改变 Period 下拉框，修改 date 和 comparisonDate
     */
    const onPeriodChange = (value) => {
        setPeriodValue(value)

        const mapper = getDateKeyValueMapper()
        if (value === str.PERIOD_TODAY) {
            setTmpDate(mapper.today)
            if (compareToValue === str.COMPARE_TO_YEAR_ON_YEAR) {
                setComparisonTmpDate(mapper.today_on_year)
            } else if (compareToValue === str.COMPARE_TO_PREVIOUS_PERIOD) {
                setComparisonTmpDate(mapper.today_on_previous_period)
            }
        }
        else if (value === str.PERIOD_YESTERDAY) {
            setTmpDate(mapper.yesterday)
            if (compareToValue === str.COMPARE_TO_YEAR_ON_YEAR) {
                setComparisonTmpDate(mapper.yesterday_on_year)
            } else if (compareToValue === str.COMPARE_TO_PREVIOUS_PERIOD) {
                setComparisonTmpDate(mapper.yesterday_on_previous_period)
            }
        }
        else if (value === str.PERIOD_LAST_7_DAYS) {
            setTmpDate(mapper.last_7_days)
            if (compareToValue === str.COMPARE_TO_YEAR_ON_YEAR) {
                setComparisonTmpDate(mapper.last_7_days_on_year)
            } else if (compareToValue === str.COMPARE_TO_PREVIOUS_PERIOD) {
                setComparisonTmpDate(mapper.last_7_days_on_previous_period)
            }
        }
        else if (value === str.PERIOD_LAST_WEEK) {
            setTmpDate(mapper.last_week)
            if (compareToValue === str.COMPARE_TO_YEAR_ON_YEAR) {
                setComparisonTmpDate(mapper.last_week_on_year)
            } else if (compareToValue === str.COMPARE_TO_PREVIOUS_PERIOD) {
                setComparisonTmpDate(mapper.last_week_on_previous_period)
            }
        } else if (value === str.PERIOD_LAST_MONTH) {
            setTmpDate(mapper.last_month)
            if (compareToValue === str.COMPARE_TO_YEAR_ON_YEAR) {
                setComparisonTmpDate(mapper.last_month_on_year)
            } else if (compareToValue === str.COMPARE_TO_PREVIOUS_PERIOD) {
                setComparisonTmpDate(mapper.last_month_on_previous_period)
            }
        } else {
            setTmpDate(mapper.last_quarter)
            if (compareToValue === str.COMPARE_TO_YEAR_ON_YEAR) {
                setComparisonTmpDate(mapper.last_quarter_on_year)
            } else if (compareToValue === str.COMPARE_TO_PREVIOUS_PERIOD) {
                setComparisonTmpDate(mapper.last_quarter_on_previous_period)
            }
        }
    }

    /**
     * 改变 CompareTo 下拉框, 只可能修改 ComparisonDate
     */
    const onCompareToChange = (value) => {
        setCompareToValue(value)

        // 如果 date 不是完整的日期范围值, 直接返回
        if (calendarTmpDate.endsWith('_')) {
            return
        }

        // 根据 date 生成 comparisonDate
        if (value === str.COMPARE_TO_YEAR_ON_YEAR) {
            setComparisonTmpDate(getDateRangeOfLastYear(calendarTmpDate))
        } else if (value === str.COMPARE_TO_PREVIOUS_PERIOD) {
            const mapper = getDateKeyValueMapper()
            if (periodValue === str.PERIOD_TODAY) {
                setComparisonTmpDate(mapper.today_on_previous_period)
            }
            else if (periodValue === str.PERIOD_YESTERDAY) {
                setComparisonTmpDate(mapper.yesterday_on_previous_period)
            }
            else if (periodValue === str.PERIOD_LAST_7_DAYS) {
                setComparisonTmpDate(mapper.last_7_days_on_previous_period)
            }
            else if (periodValue === str.PERIOD_LAST_WEEK) {
                setComparisonTmpDate(mapper.last_week_on_previous_period)
            } 
            else if (periodValue === str.PERIOD_LAST_MONTH) {
                setComparisonTmpDate(mapper.last_month_on_previous_period)
            } 
            else if (periodValue === str.PERIOD_LAST_QUARTER) {
                setComparisonTmpDate(mapper.last_quarter_on_previous_period)
            }
        }
    }

    const [ periodText, setPeriodText ] = useState('')
    const [ compareToText, setCompareToText ] = useState('')

    useEffect(() => {
        if (periodValue !== NA) {
            setPeriodText('')
            return
        }
        if (calendarTmpDate.endsWith('_')) {
            return
        }
        if (isSearchForOptionsPage()) {
            setPeriodText(dateRangeUtils.last(calendarTmpDate))
        } else {
            setPeriodText(formatDateStr2(calendarTmpDate))
        }
    }, [ periodValue, calendarTmpDate ])

    useEffect(() => {
        if (compareToValue !== NA) {
            setCompareToText('')
            return
        }
        if (calendarComparisonTmpDate.endsWith('_')) {
            return
        }
        if (isSearchForOptionsPage()) {
            setCompareToText(dateRangeUtils.last(calendarComparisonTmpDate))
        } else {
            setCompareToText(formatDateStr2(calendarComparisonTmpDate))
        }
    }, [ compareToValue, calendarComparisonTmpDate ])

    const periodRef = useRef<DropdownRefProps>(null)
    const compareToRef = useRef<DropdownRefProps>(null)

    return (
        <div className={styles.wrapper} onClick={e => e.stopPropagation()}>
            <div className={styles.titleWrapper}>
                <div className={styles.periodTitle}>
                    TIME PERIOD
                </div>
                <div className={styles.compareToTitle}>
                    COMPARED TO
                </div>
            </div>
            <div className={styles.date}>
                <Dropdown
                    className={styles.timePeriodSelect}
                    options={getTimePeriodOptions()}
                    value={periodValue}
                    onChange={onPeriodChange}
                    constantPlaceholder={periodText}
                    title="Time period presets"
                    placeholder="Custom"
                    dropdownStyle={{ maxHeight: '235px', width: '170px' }}
                    disabled={isSearchForOptionsPage()}
                    ref={periodRef}
                    onVisibleChanage={open => {
                        if (open) {
                            compareToRef.current?.close()
                        }
                    }}
                />
                <Dropdown
                    className={styles.compareToSelect}
                    options={getComparedToOptions()}
                    value={compareToValue}
                    onChange={onCompareToChange}
                    key={compareToText || 'compareTo'}
                    constantPlaceholder={compareToText}
                    placeholder="Custom"
                    title="Compared to"
                    dropdownStyle={{ maxHeight: '235px', width: '170px' }}
                    disabled={isSearchForOptionsPage()}
                    ref={compareToRef}
                    onVisibleChanage={open => {
                        if (open) {
                            periodRef.current?.close()
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default forwardRef(DropdownGroup)
