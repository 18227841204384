import { useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useSelector, useDispatch } from 'react-redux'
import { selectLoadingObj, clearLoadingObj } from 'features/filters/filtersSlice'
import { isInOutMonitoringPage, isPriceSpreadsPage } from 'global/Routes'

export const useLoadingBar = () => {
    const dispatch = useDispatch()
    const loadingObj = useSelector(selectLoadingObj)

    useDeepCompareEffect(() => {
        const loadingList: boolean[] = Object.values(loadingObj || {}) || []
        if (loadingList.length === 0) {
            loadingBar.done()
            return
        }
        // 全部都为 false 时才隐藏进度条
        if (Array.isArray(loadingList) && loadingList.every(item => item === false)) {
            loadingBar.done()
        } else {
            loadingBar.start()
        }
    }, [ loadingObj ])

    useEffect(() => 
         () => {
            dispatch(clearLoadingObj())
            loadingBar.done()
        }
        // eslint-disable-next-line
    , [])
}

export const loadingBar = {
    _createProgressBar() {
        const bar = document.createElement('div')
        if (isPriceSpreadsPage() || isInOutMonitoringPage()) {
            bar.className = 'norna-progress-bar norna-progress-bar-animation-2'
        } else {
            bar.className = 'norna-progress-bar norna-progress-bar-animation'
        }
        document.body.append(bar)
    },
    start() {
        const bar = document.querySelector('.norna-progress-bar')
        if (bar) return
        this._createProgressBar()
    },
    restart() {
        const bar = document.querySelector('.norna-progress-bar')
        if (bar) {
            bar.remove()
            this._createProgressBar()
        } else {
            this._createProgressBar()
        }
    },
    done() {
        const bar = document.querySelector('.norna-progress-bar') as any
        if (!bar) return
        bar.remove()
    },
}
