
export interface CategoryProps {
    name: string;
    isChecked: boolean;
    list: CategoryProps[];
}

export enum DataType {
    index = 'index',
    options = 'options',
}

export interface RowProps {
    options: string;
    category: string;
    [key: string]: any;
}
