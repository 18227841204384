/**
* @file    QaCheck
* @date   2022-03-08
* @author byte_su@163.com
*/

import classnames from 'classnames'
import React from 'react'
import { Checkbox } from 'components/form-elements/Checkbox'
import styles from './styles.module.scss'
import { useIsShowDeleteImageButton } from 'hooks/useIsShowDeleteImageButton'

/**
 * Qa check
 * @param param0 
 * @returns 
 */
export const QaCheck = ({ checked, onChange, style = {} }) => {
  const isShowDeleteImageBtn = useIsShowDeleteImageButton()

  return (
    <div 
      style={{ ...style }} 
      className={classnames({
        [styles.showDeleteImageBtn]: isShowDeleteImageBtn,
        [styles.checkboxContainer]: true,
      })}>
      <Checkbox
        isChecked={checked}
        onChange={() => onChange()}
      />
    </div>
  )
}

QaCheck.displayName = 'QaCheck'
