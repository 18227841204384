import React from 'react'

export const DeleteIcon = () => 
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px">
        <path d="M0 0h24v24H0z" fill="none" /><path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
    </svg>

// <?xml version="1.0" encoding="utf-8"?>
// <!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
// <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"></>
// <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
// 	 width="18px" height="18px" viewBox="0 0 18 18" enable-background="new 0 0 18 18" xml:space="preserve">
// <path fill="none" d="M0,0h18v18H0V0z"/>
// <path fill="none" d="M0,0h18v18H0V0z"/>
// <path d="M4.208,14.631c0,0.938,0.766,1.703,1.704,1.703h6.815c0.937,0,1.703-0.766,1.703-1.703V4.408H4.208V14.631z M6.303,8.564
// 	l1.201-1.2l1.814,1.806l1.807-1.806l1.201,1.2l-1.807,1.807l1.807,1.807l-1.201,1.201l-1.807-1.807l-1.806,1.807l-1.201-1.201
// 	l1.806-1.807L6.303,8.564z M12.301,1.852L11.448,1H7.189L6.337,1.852H3.355v1.704h11.927V1.852H12.301z"/>
// </svg>

