import React, { FC, memo, useState, useEffect } from 'react'
import classnames from 'classnames'
import { ClickAwayListener } from '@material-ui/core'
import { MonthPanelProps } from './types'
import styles from './styles.module.scss'

/**
 * ## 何时使用
 * 
 * 如果希望直接在页面上显示面板用于选择月份和年份，而不是在下拉框中选择月份和年份，此时可以使用 MonthPanel 组件而非 MonthPicker 组件。
 */
export const MonthPanel: FC<MonthPanelProps> = memo(({
    onChange,
    value,
    months,
}) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const defaultMonthArr = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
    const defaultYearArr = [ 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030 ]

    const yearArr = defaultYearArr
    const monthArr = months || defaultMonthArr

    const [ year, setYear ] = useState(new Date().getFullYear())
    const [ month, setMonth ] = useState(new Date().getMonth())
    const [ showMonth, setShowMonth ] = useState(true)

    // 初始化 year、month 值
    useEffect(() => {
        if (value) {
            setYear(value.year)
            setMonth(value.month)
        }
    }, [ value ])

    // 点击 month item，记录 month 值，并触发 onChange() 事件
    const onClickMonth = monthIndex => {
        setMonth(monthIndex)
        onChange?.({ year, month: monthIndex })
    }

    const monthPanelBody = monthArr.map((item, index) => {
        const disabled = year > currentYear || (year === currentYear && index > currentMonth)
        const className = {
            [styles.panelItem]: true,
            [styles.active]: index === month,
            [styles.disabled]: disabled,
        }
        return (
            <div
                key={item}
                className={classnames(className)}
                onClick={() => {
                    if (disabled) return
                    onClickMonth(index)
                }}
            >
                {item}
            </div>
        )
    })

    // 点击 year item，记录选中的 year 值，并切换到 month panel
    const onClickYear = y => {
        setYear(y)
        if (y === currentYear && month > currentMonth) {
            setMonth(currentMonth)
            // onChange?.({ year, month: currentMonth })
        }
        setShowMonth(true)
    }

    const yearPanelBody = yearArr.map(item => {
        const disabled = item > currentYear
        const className = {
            [styles.panelItem]: true,
            [styles.active]: item === year,
            [styles.disabled]: disabled,
        }
        return (
            <div
                key={item}
                className={classnames(className)}
                onClick={() => {
                    if (disabled) return
                    onClickYear(item)
                }}
            >
                {item}
            </div>
        )
    })

    return (
        <ClickAwayListener 
            onClickAway={() => {
                if (value?.year === year && value?.month === month) return
                onChange?.({ year, month })
            }}
        >
            <div className={styles.panel}>
                <div className={styles.panelHeader}>
                    <div className={styles.panelHeaderYear} onClick={() => setShowMonth(!showMonth)}>
                        {showMonth ? year : monthArr[month]}
                    </div>
                </div>
                <div className={styles.panelBody}>
                    {showMonth ? monthPanelBody : yearPanelBody}
                </div>
            </div>
        </ClickAwayListener>
    )
})

MonthPanel.displayName = 'MonthPanel'
