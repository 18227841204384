import { ALL_VENDORS, IMG_PLACEHOLDER, SELECTED_VENDORS } from 'consts'
import { getOriginalPicScaleVendorNameList } from './feConfig'
import { awsImagePrefix } from 'configs/image'

/**
 * 是否保留图片原图宽高比
 */
export function isKeepOriginalAspectRatio(vendorName: string, brand?: string) {
    if ([ SELECTED_VENDORS, ALL_VENDORS ].includes(vendorName) && brand) {
        return getOriginalPicScaleVendorNameList()
            .map(item => item.toUpperCase())
            .includes(brand?.split('@')?.pop()?.trim()?.toUpperCase() || '')
    }   
    return getOriginalPicScaleVendorNameList().includes(vendorName?.split('@')?.pop()?.trim() || '')
}

/**
 * 生成图片完整路径
 */
export function getImgFullPath({
    src,
    isKeepOriginalSize,       // 是否保持原图尺寸
    width = 160,
    height = 250,
}: {
    src: string;
    isKeepOriginalSize?: boolean;
    width?: number;
    height?: number;
}) {
    if (src.startsWith('http')) {
        return src
    }

    const domain = awsImagePrefix
    let fullPath = `${domain}image=${src}`
    if (src !== IMG_PLACEHOLDER && !src.includes('&width')) {
        fullPath += `&width=${width}&height=${height}`
    }
    if (isKeepOriginalSize) {
        fullPath = fullPath.split('&width')[0]
    }
    return fullPath
}
