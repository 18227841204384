const width01 = 78
const width02 = 78

export const tabWidth = 148
export const optionsWidth = 115
export const categoryWidth = 190
export const arrowWidth = 40

export const sizeValueWidth = width01
export const sizeChangeWidth = width02
export const sizeWidth = sizeValueWidth + sizeChangeWidth

export const getTableScrollWidth = () => {
    return tabWidth + optionsWidth + categoryWidth + sizeWidth * 7 + arrowWidth
}

export const whitelist_vendors = [
    'tommyhilfiger', 'lacoste', 'calvinklein', 'boss', 'ralphlauren', 'Calvin Klein@zalandodeep', 'Tommy Hilfiger@zalandodeep',
]
