import React, { CSSProperties, ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { Box, Card, Divider, Flex, Spin, Text } from 'druikit'
import styles from './styles.module.scss'
import { colors, getMaxTick } from '../MyCharts/utils'
import { SizedBox } from 'componentsv2/SizedBox'
import { Bar, BarChart, CartesianGrid, LabelList, ReferenceArea, ReferenceLine, XAxis, YAxis } from 'recharts'
import { ALL_VENDORS, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { getVendorNameByCode } from 'utils'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ProductsModal } from 'components/ProductsModalV2'
import { handleName } from 'pages/DynamicDashboard/utils'
import { Tooltip } from 'antd'
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined'
import { usePovValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import { numberUtils } from 'norna-uikit'

const yAxisWidth = 50

export const MetricWeeklyNewnessBarChartCard = ({
    style,
    className,
    title,
    description,
    loading = false,
    dataSource = [],
    productData = [],
    yAxisFormatter,
}: {
    style?: CSSProperties;
    className?: string;
    title?: string | ReactNode; 
    description?: string | ReactNode;
    loading?: boolean;
    dataSource: any[];
    productData: any[];
    yAxisFormatter?: (value: number) => string;
}) => {
    const [ customerVendor ] = usePovValue()
    const comparisonVendor = Object.keys(dataSource?.[0] || {}).filter(item => ![ customerVendor, MARKET_VENDOR.vendor, 'label' ].includes(item))?.[0]

    /* ************************* 图表宽度 ******************************** */
    const divRef = useRef<HTMLDivElement>(null)
    const [ chartWidth, setChartWidth ] = useState(400)
    const [ chartActualWidth, setChartActualWidth ] = useState(400)
    const [ barGap, setBarGap ] = useState(40)

    useLayoutEffect(() => {
        const wrapperEl = divRef.current
        if (!wrapperEl) return
        const rect = wrapperEl.getBoundingClientRect()
        const chartWidth = rect.width
        setChartWidth(chartWidth)
        const barW = 8          // 柱子宽度
        const vendorL = 3       // vendor 个数, 固定 3 个
        const weekNum = 5       // 一屏显示 5 个 week 数据

        if (dataSource.length >= 5) {
            const singleWeekWidth = chartWidth / weekNum
            // 分母 4 表示平均分成 4 份
            const gap = (singleWeekWidth - vendorL * barW) / 4
            setBarGap(gap)
            const chartActualWidth = singleWeekWidth * dataSource.length
            setChartActualWidth(chartActualWidth)
        } else {
            const singleWeekWidth = chartWidth / dataSource.length
            // 分母 4 表示平均分成 4 份
            const gap = (singleWeekWidth - vendorL * barW) / 4
            setBarGap(gap)
            setChartActualWidth(chartWidth)
        }
    }, [ dataSource ])

    /* ************************* 自定义标签 ******************************** */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, index, value, fill: color } = props;
        const clickable = value > 0
    
        const labelH = 18
        const labelW = 40
        const labelT = 10   // label 距离柱子顶部的高度 10px
        const labelT2 = 3
        const offset = 1

        const onClick = () => {
            if (!clickable) return
            const week = productData[index].label
            const products = productData[index][color]?.products || []
            let vendorName = productData[index][color]?.vendorName
            vendorName = vendorName === ALL_VENDORS ? MARKET_VENDOR.vendor : vendorName
            if (!products?.length) return

            const title = `${[ MARKET_VENDOR.vendor, SELECTED_VENDORS ].includes(vendorName) ? vendorName + ' average' : vendorName}, ${week}`
            const dialogId = title
            const closeFn = dialogRef.current?.closeDialog
            dialogRef.current?.openDialog(dialogId, (
                <ProductsModal
                  productUrls={products || []}
                  onClose={() => {
                    closeFn(dialogId)
                  }}
                  headerLeading={numberUtils.formatNumberByComma(products.length)}
                  headerTitle={title}
                />
            ))
        }
    
        if (height > (labelH + labelT * 2 + labelT2 * 2)) {
            const p1 = [ x - (labelW - labelH - width) / 2, y + labelT ]
            const p2 = [ p1[0], p1[1] + labelT2 ]
            const c1 = [ p2[0], p2[1] + labelH / 2 ]
            const c2 = [ p2[0] + labelW - labelH, p2[1] + labelH / 2 ]
            const r = labelH / 2
            const t = [ x + width / 2, p2[1] + labelH / 2 + offset ]  // 文本坐标

            return (
                <g style={{ cursor: clickable ? 'pointer' : 'auto' }} onClick={onClick}>
                    <rect x={p1[0]} y={p1[1]} width={labelW - labelH} height={labelH + labelT2 * 2} fill={index % 2 === 0 ? 'rgb(227,240,240)' : '#fff'} />
                    <circle cx={c1[0]} cy={c1[1]} r={r} fill={color} />
                    <circle cx={c2[0]} cy={c2[1]} r={r} fill={color} />
                    <rect x={p2[0]} y={p2[1]} width={labelW - labelH} height={labelH} fill={color} />
                    <text 
                        x={t[0]} 
                        y={t[1]} 
                        textAnchor="middle" 
                        dominantBaseline="middle" 
                        fill="white" 
                        fontSize={10} 
                    >
                        {typeof yAxisFormatter === 'function' ? yAxisFormatter(value) : value}
                    </text>
                </g>
            );
        }

        const p1 = [ x - (labelW - labelH - width) / 2, y - labelT2 - labelH ]
        const c1 = [ p1[0], p1[1] + labelH / 2 ]
        const c2 = [ p1[0] + labelW - labelH, p1[1] + labelH / 2 ]
        const r = labelH / 2
        const t = [ x + width / 2, p1[1] + labelH / 2 + offset ]  // 文本坐标

        return (
            <g style={{ cursor: clickable ? 'pointer' : 'auto' }} onClick={onClick}>
                <circle cx={c1[0]} cy={c1[1]} r={r} fill={color} />
                <circle cx={c2[0]} cy={c2[1]} r={r} fill={color} />
                <rect x={p1[0]} y={p1[1]} width={labelW - labelH} height={labelH} fill={color} />
                <text x={t[0]} y={t[1]} textAnchor="middle" dominantBaseline="middle" fill="white" fontSize={10}>
                    {typeof yAxisFormatter === 'function' ? yAxisFormatter(value) : value}
                </text>
            </g>
        )
    }

    return (
        <Spin spinning={loading} style={{ marginBottom: 34, ...style }} className={className}>
            <Flex style={{ marginBottom: 10 }}>
                <div className={styles.cardTitle}>{title}</div>
                {
                    description ? (
                        <Tooltip 
                            title={description} 
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0}
                            zIndex={9999992}
                            placement="top"
                        >
                            <div className={styles.descTooltip}>
                                <InfoOutlined
                                    size={16}
                                    color="#bbb"
                                />
                            </div>
                        </Tooltip>
                    ) : null
                }
            </Flex>
            <Card fringed={false} height={330} style={{ paddingLeft: 33, paddingRight: 28 }}>
                <div ref={divRef} style={{ width: '100%' }}>
                    <Flex justifyContent="flex-end" alignItems="flex-end" height={38}>
                        {
                            [ customerVendor, comparisonVendor, MARKET_VENDOR.vendor ].map((item, index) => {
                                const color = colors[index]
                                return (
                                    <Flex key={item + index.toString()} alignItems="center" style={{ marginRight: 10 }}>
                                        <Box width={12} height={12} backgroundColor={color} style={{ borderRadius: 12 }} />
                                        <Box width={6} />
                                        <Text fontSize12 color666>
                                            {handleName(getVendorNameByCode(item))}
                                        </Text>
                                    </Flex>
                                )
                            })
                        }
                    </Flex>
                    <Divider length="100%" />
                    <SizedBox height={10} />
                    <div style={{ position: 'relative' }}>
                        <div 
                            className={styles.weeklyBarChartWrapper}
                            style={{ 
                                width: chartWidth,
                                paddingBottom: 10,
                            }}
                        >
                            <BarChart
                                width={chartActualWidth}
                                height={230}
                                data={dataSource}
                                barSize={8}
                                barGap={barGap}
                                margin={{
                                    top: 10,
                                    right: 20,
                                    left: -10,
                                }}
                            >
                                <CartesianGrid 
                                    strokeDasharray="3 3" 
                                    vertical={false}
                                />
                                {
                                    dataSource.map((item, index) => {
                                        const label = item.label
                                        const fill = index % 2 === 0 ? '#74B6B6' : '#fff'
                                        return (
                                            <ReferenceArea 
                                                key={label} 
                                                x1={label} 
                                                x2={label} 
                                                y1={0} 
                                                y2={getMaxTick(dataSource.map(item => ({ label: item.label, value: Math.max(item[customerVendor], item[comparisonVendor], item[MARKET_VENDOR.vendor]) })))} 
                                                fill={fill} 
                                                fillOpacity={0.2}
                                                stroke="#74B6B6"
                                            />
                                        )
                                    })
                                }
                                <ReferenceLine y={0} stroke="white" strokeWidth={2} />
                                <ReferenceLine y={0} strokeWidth={1} strokeDasharray="3 3" />
                                <ReferenceLine y={getMaxTick(dataSource.map(item => ({ label: item.label, value: Math.max(item[customerVendor], item[comparisonVendor], item[MARKET_VENDOR.vendor]) })))} stroke="white" strokeWidth={2} />
                                <ReferenceLine y={getMaxTick(dataSource.map(item => ({ label: item.label, value: Math.max(item[customerVendor], item[comparisonVendor], item[MARKET_VENDOR.vendor]) })))} strokeWidth={1} strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="label" 
                                    tick={{ fontSize: 12, color: '#666' }} 
                                    axisLine={false} 
                                    tickLine={false} 
                                    tickMargin={10}
                                />
                                <YAxis
                                    width={60}
                                    tick={{ fontSize: 12, color: '#666' }}
                                    style={{ opacity: 0 }}
                                    axisLine={false} 
                                    tickLine={false} 
                                    tickMargin={10}
                                    domain={[ 0, getMaxTick(dataSource.map(item => ({ label: item.label, value: Math.max(item[customerVendor], item[comparisonVendor], item[MARKET_VENDOR.vendor]) }))) ]}
                                />
                                <Bar 
                                    dataKey={customerVendor} 
                                    fill={colors[0]} 
                                    minPointSize={4} 
                                >
                                    <LabelList 
                                        dataKey={customerVendor}
                                        content={renderCustomizedLabel}
                                        fill={colors[0]}
                                    />
                                </Bar>
                                <Bar 
                                    dataKey={comparisonVendor} 
                                    fill={colors[1]} 
                                    minPointSize={4} 
                                >
                                    <LabelList 
                                        dataKey={comparisonVendor}
                                        content={renderCustomizedLabel}
                                        fill={colors[1]}
                                    />
                                </Bar>
                                <Bar 
                                    dataKey={MARKET_VENDOR.vendor} 
                                    fill={colors[2]} 
                                    minPointSize={4} 
                                >
                                    <LabelList 
                                        dataKey={MARKET_VENDOR.vendor}
                                        content={renderCustomizedLabel}
                                        fill={colors[2]}
                                    />
                                </Bar>
                            </BarChart>
                        </div>
                        <BarChart
                            width={yAxisWidth}
                            height={230}
                            data={dataSource}
                            barSize={8}
                            margin={{
                                top: 10,
                                left: -10,
                            }}
                            style={{ 
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                background: '#fff',
                                paddingBottom: 10,
                            }}
                        >
                            <XAxis 
                                dataKey="label" 
                                tick={{ fontSize: 12, color: '#666' }} 
                                axisLine={false} 
                                tickLine={false} 
                                tickMargin={10}
                            />
                            <YAxis
                                style={{ background: '#fff' }}
                                tick={{ fontSize: 12, color: '#666' }}
                                axisLine={false} 
                                tickLine={false} 
                                tickMargin={10}
                                tickFormatter={yAxisFormatter}
                                domain={[ 0, getMaxTick(dataSource.map(item => ({ label: item.label, value: Math.max(item[customerVendor], item[comparisonVendor], item[MARKET_VENDOR.vendor]) }))) ]}
                            />
                        </BarChart>
                    </div>
                </div>
            </Card>
            <Dialog ref={dialogRef} />
        </Spin>
    )
}
