export function formatPrice(price: number, opt?: {separator?: string, decimal?: number, decimalSymbol?: string;}) {
    const separator = typeof opt?.separator === 'undefined' ? ',' : opt.separator
    const decimal = opt?.decimal || 0
    const decimalSymbol = opt?.decimalSymbol || '.'
    let result = Number(price).toFixed(decimal)
    result = result.replace('.', decimalSymbol)
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
    return result
}

export function formatPublishPrice(price: number, region, vendor) {
    const symbols = {
        '£': '£',
        $: '$',
        '€': '€',
        'د.إ': 'د.إ',
        Mex: 'Mex',
        AED: 'AED',
        USD: 'USD',
    }

    const regions = {
        Spain: 'Spain',
        France: 'France',
        Mexico: 'Mexico',
        UK: 'United Kingdom',
        US: 'United States',
        UAE: 'United Arab Emirates',
        El_Palacio_de_Hierro: 'El Palacio de Hierro',
    }

    const vendors = {
        acnestudios: 'acnestudios',
        adolfodominguez: 'adolfodominguez',
        aliceandolivia: 'aliceandolivia',
        'ba-and-sh': 'ba-and-sh',
        bimbaylola: 'bimbaylola',
        coach: 'coach',
        cos: 'cos',
        furla: 'furla',
        ganni: 'ganni',
        karllagerfeld: 'karllagerfeld',
        katespade: 'katespade',
        kenzo: 'kenzo', 
        maje: 'maje',
        marcjacobs: 'marcjacobs',
        massimodutti: 'massimodutti',
        michaelkors: 'michaelkors',
        purificaciongarcia: 'purificaciongarcia',
        reformation: 'reformation',
        sandro: 'sandro',
        toryburch: 'toryburch',
        'zadig-et-voltaire': 'zadig-et-voltaire',
        zara: 'zara',
    }

    const priceList: any = [
        {
            format: (price: number) => ` ¥${formatPrice(price, { decimal: 0, separator: ',' })}`,
            price: ' ¥2,900',
            vendor: vendors.acnestudios,
            region: 'China',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: ',' })} € `,
            price: ' 2,574 € ',
            vendor: vendors.acnestudios,
            region: 'Finland',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: ' ' })} `,
            price: ' €1 200 ',
            vendor: vendors.acnestudios,
            region: 'France',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: ' ' })} `,
            price: ' €1 200 ',
            vendor: vendors.acnestudios,
            region: 'Germany',
        },
        {
            format: (price: number) => ` ¥${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ' ¥45,650 ',
            vendor: vendors.acnestudios,
            region: 'Japan',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: ' ' })} `,
            price: ' €1 200 ',
            vendor: vendors.acnestudios,
            region: 'Spain',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: ' ' })} SEK `,
            price: ' 2 700 SEK ',
            vendor: vendors.acnestudios,
            region: 'Sweden',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 0, separator: ' ' })} `,
            price: ' £1 050 ',
            vendor: vendors.acnestudios,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` $${formatPrice(price, { decimal: 0, separator: ' ' })} `,
            price: ' $1 300 ',
            vendor: vendors.acnestudios,
            region: 'United States',
        },
        // adolfodominguez
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 2, separator: '.', decimalSymbol: ',' })} `,
            price: ` ${symbols['€']} 1.990,00 `,
            vendor: vendors.adolfodominguez,
            region: regions.Spain,
        },
        {
            format: (price: number) => ` Mex$ ${formatPrice(price, { decimal: 2, separator: ',' })} `,
            price: ' Mex$ 4,790.00 ',
            vendor: vendors.adolfodominguez,
            region: regions.Mexico,
        },
        // aliceandolivia
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 0, separator: '.' })} `,
            price: ` ${symbols['€']}1.145 `,
            vendor: vendors.aliceandolivia,
            region: regions.Spain,
        },
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 0, separator: '.' })} `,
            price: ` ${symbols['€']}1.145 `,
            vendor: vendors.aliceandolivia,
            region: regions.France,
        },
        {
            format: (price: number) => ` ${symbols['£']}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols['£']}1,325 `,
            vendor: vendors.aliceandolivia,
            region: regions.UK,
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.$}1,190 `,
            vendor: vendors.aliceandolivia,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: '.', decimalSymbol: ',' })} ${symbols['د.إ']} `,
            price: ` 2.470,00 ${symbols['د.إ']} `,
            vendor: vendors.aliceandolivia,
            region: regions.UAE,
        },
        // ba-and-sh
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, decimalSymbol: ',', separator: '' })} € `,
            price: ' 200,00 € ',
            vendor: vendors['ba-and-sh'],
            region: 'France',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 2, separator: '' })} `,
            price: ' €395.00 ',
            vendor: vendors['ba-and-sh'],
            region: 'Spain',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 2, separator: '' })} `,
            price: ' £365.00 ',
            vendor: vendors['ba-and-sh'],
            region: 'United Kingdom',
        },
        // bimbaylola
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })} € `,
            price: ' 145 € ',
            vendor: vendors.bimbaylola,
            region: 'France',
        },
        {
            format: (price: number) => ` $ ${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ' $ 7,300 ',
            vendor: vendors.bimbaylola,
            region: 'Mexico',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })} € `,
            price: ' 125 € ',
            vendor: vendors.bimbaylola,
            region: 'Spain',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' £205 ',
            vendor: vendors.bimbaylola,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} `,
            price: ` ${symbols.$}1,190.00 `,
            vendor: vendors.bimbaylola,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })} ${symbols.AED} `,
            price: ' 2745 AED ',
            vendor: vendors.bimbaylola,
            region: regions.UAE,
        },
        // coach
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })}€ `,
            price: ' 1500€ ',
            vendor: vendors.coach,
            region: 'France',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal:0, separator: '' })}€ `,
            price: ' 1500€ ',
            vendor: vendors.coach,
            region: 'Spain',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' £1400 ',
            vendor: vendors.coach,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.$}1,190 `,
            vendor: vendors.coach,
            region: regions.US,
        },
        // cos
        {
            format: (price: number) => ` ¥${formatPrice(price, { separator: '', decimal: 2 })} `,
            price: ' ¥1150.00 ',
            vendor: vendors.cos,
            region: 'China',
        },
        {
            format: (price: number) => ` € ${formatPrice(price, { separator: '', decimal: 2, decimalSymbol: ',' })} `,
            price: ' € 175,00 ',
            vendor: vendors.cos,
            region: 'Finland',
        },
        {
            format: (price: number) => ` € ${formatPrice(price, { separator: '', decimal: 2, decimalSymbol: ',' })} `,
            price: ' € 175,00  ',
            vendor: vendors.cos,
            region: 'France',
        },
        {
            format: (price: number) => ` € ${formatPrice(price, { separator: '', decimal: 2, decimalSymbol: ',' })} `,
            price: ' € 175,00  ',
            vendor: vendors.cos,
            region: 'Germany',
        },
        {
            format: (price: number) => ` ¥ ${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ' ¥ 17,500 ',
            vendor: vendors.cos,
            region: 'Japan',
        },
        {
            format: (price: number) => ` € ${formatPrice(price, { decimal: 2, decimalSymbol: ',', separator: '' })} `,
            price: ' € 175,00  ',
            vendor: vendors.cos,
            region: 'Spain',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: '' })} SEK `,
            price: ' 1750.00 SEK',
            vendor: vendors.cos,
            region: 'Sweden',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' £150',
            vendor: vendors.cos,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` $${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' $250',
            vendor: vendors.cos,
            region: 'United States',
        },
        // furla
        {
            format: (price: number) => ` € ${formatPrice(price, { decimal: 2, decimalSymbol: ',', separator: '' })} `,
            price: ' € 425,00 ',
            vendor: vendors.furla,
            region: regions.Spain,
        },
        {
            format: (price: number) => ` € ${formatPrice(price, { decimal: 2, decimalSymbol: ',', separator: '' })} `,
            price: ' € 425,00 ',
            vendor: vendors.furla,
            region: regions.France,
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 2, separator: '' })} `,
            price: ' £195.00 ',
            vendor: vendors.furla,
            region: regions.UK,
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} `,
            price: ` ${symbols.$}1,190.00 `,
            vendor: vendors.furla,
            region: regions.US,
        },
        // ganni
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' €875 ',
            vendor: vendors.ganni,
            region: 'Finland',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' €875 ',
            vendor: vendors.ganni,
            region: 'France',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' €875',
            vendor: vendors.ganni,
            region: 'Germany',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' €875 ',
            vendor: vendors.ganni,
            region: 'Spain',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: ',' })} SEK `,
            price: ' 2,322 SEK ',
            vendor: vendors.ganni,
            region: 'Sweden',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' £825 ',
            vendor: vendors.ganni,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.$}1,190 `,
            vendor: vendors.ganni,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: '.', decimalSymbol: ',' })} ${symbols['د.إ']} `,
            price: ` 2.470,00 ${symbols['د.إ']} `,
            vendor: vendors.ganni,
            region: regions.UAE,
        },
        // karllagerfeld
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ` ${symbols['€']}199`,
            vendor: vendors.karllagerfeld,
            region: regions.France,
        },
        {
            format: (price: number) => ` ${symbols.Mex}${symbols.$}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.Mex}${symbols.$}6,300`,
            vendor: vendors.karllagerfeld,
            region: regions.Mexico,
        },
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ` ${symbols['€']}199`,
            vendor: vendors.karllagerfeld,
            region: regions.Spain,
        },
        {
            format: (price: number) => ` ${symbols['€']} ${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ` ${symbols['€']} 419`,
            vendor: vendors.karllagerfeld,
            region: regions.UAE,
        },
        {
            format: (price: number) => ` ${symbols['£']}${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ` ${symbols['£']}319`,
            vendor: vendors.karllagerfeld,
            region: regions.UK,
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.$}1,190 `,
            vendor: vendors.karllagerfeld,
            region: regions.US,
        },
        // katespade
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 2, separator: '' })} `,
            price: ' £450.00',
            vendor: vendors.katespade,
            region: regions.UK,
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.$}1,190 `,
            vendor: vendors.katespade,
            region: regions.US,
        },
        // kenzo   
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' €595 ',
            vendor: vendors.kenzo,
            region: 'France',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' €595 ',
            vendor: vendors.kenzo,
            region: 'Spain',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' £485 ',
            vendor: vendors.kenzo,
            region: 'United Kingdom',
        },
        // maje
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })} € `,
            price: ' 495 € ',
            vendor: vendors.maje,
            region: 'France',
        },
        {
            format: (price: number) => ` $ ${formatPrice(price, { decimal: 2, separator: ',' })} `,
            price: ' $ 6,590.00 ',
            vendor: vendors.maje,
            region: 'Mexico',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })} € `,
            price: ' 335 € ',
            vendor: vendors.maje,
            region: 'Spain',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' £359 ',
            vendor: vendors.maje,
            region: 'United Kingdom',
        },
        // marcjacobs
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 2, decimalSymbol: '.', separator: ',' })} `,
            price: ` ${symbols['€']}1,145.00 `,
            vendor: vendors.marcjacobs,
            region: regions.Spain,
        },
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 2, decimalSymbol: '.', separator: ',' })} `,
            price: ` ${symbols['€']}1,145.00 `,
            vendor: vendors.marcjacobs,
            region: regions.France,
        },
        {
            format: (price: number) => ` ${symbols['£']}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols['£']}1,325 `,
            vendor: vendors.marcjacobs,
            region: regions.UK,
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} `,
            price: ` ${symbols.$}1,190.00 `,
            vendor: vendors.marcjacobs,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${symbols.AED} ${formatPrice(price, { decimal: 2, decimalSymbol: '.', separator: ',' })} `,
            price: ` ${symbols.AED} 2,175.00 `,
            vendor: vendors.marcjacobs,
            region: regions.UAE,
        },
        // massimodutti
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, decimalSymbol: ',', separator: '' })} € `,
            price: ' 349,00 € ',
            vendor: vendors.massimodutti,
            region: 'France',
        },
        {
            format: (price: number) => ` MXN ${formatPrice(price, { decimal: 2, separator: ',' })} `,
            price: ' MXN 2,795.00 ',
            vendor: vendors.massimodutti,
            region: 'Mexico',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, decimalSymbol: ',', separator: '' })} € `,
            price: ' 349,00 € ',
            vendor: vendors.massimodutti,
            region: 'Spain',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: ',' })} SEK `,
            price: ' 2,499.00 SEK ',
            vendor: vendors.massimodutti,
            region: 'Sweden',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 2, separator: '' })} `,
            price: ' £299.00 ',
            vendor: vendors.massimodutti,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} `,
            price: ` ${symbols.$}1,190.00 `,
            vendor: vendors.massimodutti,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} ${symbols.AED} `,
            price: ` 1,599.00 ${symbols.AED} `,
            vendor: vendors.massimodutti,
            region: regions.UAE,
        },
        // michaelkors
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: ',' })} € `,
            price: ' 1,850.00 € ',
            vendor: vendors.michaelkors,
            region: 'France',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: ',' })} € `,
            price: ' 1,850.00 € ',
            vendor: vendors.michaelkors,
            region: 'Spain',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 2, separator: ',' })} `,
            price: ' £1,190.00',
            vendor: vendors.michaelkors,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} `,
            price: ` ${symbols.$}1,190.00 `,
            vendor: vendors.michaelkors,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${symbols.AED} ${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} `,
            price: ` ${symbols.AED} 2,175.00 `,
            vendor: vendors.michaelkors,
            region: regions.UAE,
        },
        // purificaciongarcia
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, decimalSymbol: ',', separator: '.' })} MXN `,
            price: ' 2.380,00 MXN ',
            vendor: vendors.purificaciongarcia,
            region: 'Mexico',
        },
        // reformation
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ` ${symbols['€']}358 `,
            vendor: vendors.reformation,
            region: regions.Spain,
        },
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ` ${symbols['€']}358 `,
            vendor: vendors.reformation,
            region: regions.France,
        },
        {
            format: (price: number) => ` ${symbols['£']}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: `${symbols['£']}1,325`,
            vendor: vendors.reformation,
            region: regions.UK,
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.$}1,190 `,
            vendor: vendors.reformation,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${symbols.AED}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.AED}1,200 `,
            vendor: vendors.reformation,
            region: regions.UAE,
        },
        // sandro
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })} € `,
            price: ' 365 € ',
            vendor: vendors.sandro,
            region: 'France',
        },
        {
            format: (price: number) => ` $${formatPrice(price, { decimal: 2, separator: ',' })} `,
            price: ' $5,590.00 ',
            vendor: vendors.sandro,
            region: 'Mexico',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })} € `,
            price: ' 275 € ',
            vendor: vendors.sandro,
            region: 'Spain',
        },
        {
            format: (price: number) => ` £${formatPrice(price, { decimal: 0, separator: '' })} `,
            price: ' £559 ',
            vendor: vendors.sandro,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} `,
            price: ` ${symbols.$}1,190.00 `,
            vendor: vendors.sandro,
            region: regions.US,
        },
        // toryburch
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 0, separator: '.' })} `,
            price: ` ${symbols['€']}1.145 `,
            vendor: vendors.toryburch,
            region: regions.Spain,
        },
        {
            format: (price: number) => ` ${symbols['€']}${formatPrice(price, { decimal: 0, separator: '.' })} `,
            price: ` ${symbols['€']}1.145 `,
            vendor: vendors.toryburch,
            region: regions.France,
        },
        {
            format: (price: number) => ` ${symbols['£']}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols['£']}1,325 `,
            vendor: vendors.toryburch,
            region: regions.UK,
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ` ${symbols.$}1,190 `,
            vendor: vendors.toryburch,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 0, separator: '' })} ${symbols.AED} `,
            price: ` 2745 ${symbols.AED} `,
            vendor: vendors.toryburch,
            region: regions.UAE,
        },
        // zadig-et-voltaire
        {
            format: (price: number) => ` EUR ${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ' EUR 1,495  ',
            vendor: vendors['zadig-et-voltaire'],
            region: 'France',
        },
        {
            format: (price: number) => ` €${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ' €1,495 ',
            vendor: vendors['zadig-et-voltaire'],
            region: 'Spain',
        },
        {
            format: (price: number) => ` GBP ${formatPrice(price, { decimal: 0, separator: ',' })} `,
            price: ' GBP 1,445 ',
            vendor: vendors['zadig-et-voltaire'],
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` ${symbols.$}${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} `,
            price: ` ${symbols.$}1,190.00 `,
            vendor: vendors['zadig-et-voltaire'],
            region: regions.US,
        },
        // zara
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: '' })} EUR `,
            price: ' 379.00 EUR ',
            vendor: vendors.zara,
            region: 'France',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: '' })} EUR `,
            price: ' 379.00 EUR ',
            vendor: vendors.zara,
            region: 'Germany',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: ',' })} MXN `,
            price: ' 1,999.00 MXN ',
            vendor: vendors.zara,
            region: 'Mexico',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: '' })} EUR `,
            price: ' 379.00 EUR ',
            vendor: vendors.zara,
            region: 'Spain',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: ',' })} SEK `,
            price: ' 1,195.00 SEK ',
            vendor: vendors.zara,
            region: 'Sweden',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: '' })} GBP `,
            price: ' 159.00 GBP ',
            vendor: vendors.zara,
            region: 'United Kingdom',
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} ${symbols.USD} `,
            price: ` 1,190.00 ${symbols.USD} `,
            vendor: vendors.zara,
            region: regions.US,
        },
        {
            format: (price: number) => ` ${formatPrice(price, { decimal: 2, separator: ',', decimalSymbol: '.' })} ${symbols.AED} `,
            price: ` 1,599.00 ${symbols.AED} `,
            vendor: vendors.zara,
            region: regions.UAE,
        },
        ...Object.values(vendors).map(vendor => ({
            format: (price: number) => ` $${formatPrice(price, { decimal: 2, separator: ',' })} `,
            price: ' $4,450.00 ',
            vendor,
            region: regions.El_Palacio_de_Hierro,
        })),
    ]
    const p = priceList.find(item => item.region === region && item.vendor === vendor)
    if (!p || typeof p?.format !== 'function') return price
    return p.format(price)
}
