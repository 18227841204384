import { getAssortmentInsightTableData, getAttributeRankingTableData, getCategorySplitPieGraphData, getColorSplitPieGraphData, getCrossRegionPricingData, getCrossRegionSoldOutData, getDiscountsTableData, getDynamicDashboardData, getInOutDashboardCalendarData, getInOutDashboardWeeklyTableData, getInOutMonitoringTableData, getMaterialSplitPieGraphData, getPriceArchitectureTableData, getPriceDriversPeriodToPeriodTableData, getPriceHistoryTableData, getPriceInsightTableData, getPriceSpreadsTableData, getProductRankingTableData, getSearchForOptionsCategoryData, getSearchForOptionsCompetitorData, getSearchForOptionsData, getSizeOfLineTableData, getSoldOutVendorsTableData, SEARCH_FOR_OPTIONS_CATEGORY, SEARCH_FOR_OPTIONS_COMPETITOR, updateModuleData } from 'features/filters/moduleDataSlice'
import * as config from 'configs/moduleName'
import { useDispatch, useSelector } from 'react-redux'
import { SOLD_OUT_VENDORS_TABLE } from 'features/filters/moduleDataSlice.const'

export const useModuleData = (moduleName: string): any => {
    const moduleData = useSelector(getModuleDataSelectorFunction(moduleName))

    const dispatch = useDispatch()
    const setModuleData = (payload: object) => {
        dispatch(updateModuleData({ moduleName, payload }))
    }

    return [
        moduleData,
        setModuleData,
    ]
}

export const getModuleDataSelectorFunction = (moduleName: string) => {
    switch (moduleName) {
        // Dashboard
        case config.PRICE_INSIGHT_TABLE_NAME:
            return getPriceInsightTableData
        case config.ASSORTMENT_INSIGHT_TABLE_NAME:
            return getAssortmentInsightTableData
        case config.PRICE_SPREADS_TABLE_NAME:
            return getPriceSpreadsTableData
        case config.IN_OUT_MONITORING_TABLE_NAME:
            return getInOutMonitoringTableData
        case config.DYNAMIC_DASHBOARD_NAME:
            return getDynamicDashboardData
        case SOLD_OUT_VENDORS_TABLE:
            return getSoldOutVendorsTableData
        
        // Pricing
        case config.PRICE_DRIVERS_TABLE_NAME:
            return getPriceHistoryTableData
        case config.PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME:
            return getPriceDriversPeriodToPeriodTableData
        case config.PRICE_ARCHITECTURE_TABLE_NAME:
            return getPriceArchitectureTableData
        case config.DISCOUNTS_TABLE_NAME:
            return getDiscountsTableData
        case config.CROSS_REGION_PRICING_NAME:
            return getCrossRegionPricingData
        case config.CROSS_REGION_SOLD_OUT_NAME:
            return getCrossRegionSoldOutData
        
        // Assortment
        case config.CATEGORY_SPLIT_PIE_GRAPH_NAME:
            return getCategorySplitPieGraphData
        case config.COLOR_SPLIT_PIE_GRAPH_NAME:
            return getColorSplitPieGraphData
        case config.MATERIAL_SPLIT_PIE_GRAPH_NAME:
            return getMaterialSplitPieGraphData
        case config.PRODUCT_RANKING_TABLE_NAME:
            return getProductRankingTableData
        case config.ATTRIBUTE_RANKING_TABLE_NAME:
            return getAttributeRankingTableData
        case config.SIZE_OF_LINE_TABLE_NAME:
            return getSizeOfLineTableData
        case config.OPTIONS_IN_OUT_CALENDAR_NAME:
            return getInOutDashboardCalendarData
        case config.WEEKLY_TABLE_NAME:
            return getInOutDashboardWeeklyTableData

        // Lookbooks
        case config.SEARCH_FOR_OPTIONS_NAME:
            return getSearchForOptionsData
        case SEARCH_FOR_OPTIONS_CATEGORY:
            return getSearchForOptionsCategoryData
        case SEARCH_FOR_OPTIONS_COMPETITOR:
            return getSearchForOptionsCompetitorData

        // default
        default:
            return getPriceInsightTableData
    }
}
