/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import { ArrowBackwardIcon, CloseIcon, MinusIcon, PlusIcon, VisibilityEyeIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { Checkbox } from 'components/form-elements/Checkbox'

import { cloneDeep, debounce } from 'lodash'
import { InputUi } from 'components/form-elements/Input'
import classNames from 'classnames'
import { Dropdown, DropdownRefProps } from 'componentsv2/form-elements/Dropdown'
import { useSelector } from 'react-redux'
import { OptionType } from 'types'
import { useFetch } from 'libs/hookRequest'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { lsGet, lsSet } from 'utils/ls'
import { Dragable } from 'components/common/Dragable'
import { BatchReportArgType, reportToolArg } from 'graphql/nornaapi/report'
import { useReport } from 'hooks/useReport'
import { useAdminMetaData } from 'hooks/useAdminMetaData'
import { useQa } from 'hooks/useQa'
import { UNISEX, UNSET } from 'consts'
import { Box } from 'componentsv2/Box'
import { ReportProductModalTypesV2, ReportType } from './types'
import styles from './styles-v2.module.scss'
import { getCacheData } from '../../features/filters/cacheDataSlice'
import { hidePropertyFirstLevelList, hidePropertySecondLevelMapper } from './ReportProductModalV2.util'
import { Flex } from 'antd'

const BaseField = {
  propertyGroup: 'propertyGroup',
  properties: 'properties',
  material: 'Material',
  number: 'number',
  RGB: 'rgb',
}

const splitor = '#'

const DropdownCustom = ({ val, optionsData, onChange }) => {
  const dropdownRef = useRef<DropdownRefProps>(null)
  const [ maxHeight, setMaxHeight ] = useState<number>(480)

  useLayoutEffect(() => {
    const selectorEl = dropdownRef.current?.getSelectorEl()
    if (!selectorEl) {
      return
    }
    const selectorBottom = selectorEl.getBoundingClientRect().bottom
    const dropdownBottom = selectorBottom + 480 + 20
    const windowHight = window.innerHeight
    if (dropdownBottom > windowHight) {
      setMaxHeight(480 - dropdownBottom + windowHight)
    }
  }, [])

  return (
    <Dropdown
      ref={dropdownRef}
      showSearch={true}
      value={val}
      customMaxHeight={maxHeight}
      options={optionsData.map(item => ({ key: item, description: item }))}
      onChange={onChange}
      placeholder="select here"
    />
  )
}

const ButtonCustom = ({ onClick, icon, style = {} }) => {
  return (
    <Button
      onlyIcon={true}
      style={style}
      secondary={true}
      icon={icon}
      onClick={onClick}
    />
  )
}

const calcValue = (val: string, max: number) => {
  if (Number.isNaN(parseInt(val, 10))) {
    val = '0'
  }
  let valInt = parseInt(val, 10) > 100 ? 100 : parseInt(val, 10)
  valInt = parseInt(val, 10) < 0 ? 1 : valInt
  return parseInt(`${valInt}`, 10)
}

const InputComponentCustom = ({ val, onChange }) => {
  return (
    <InputUi
      min={0} max={255} placeholder="0-255" value={val}
      onChange={val => onChange(calcValue(val, 255))} sty={{ marginBottom: 0, width: 75, height: 36 }}
    />
  )
}

const TextInputComponentCustom = ({ val, onChange }) => {
  return (
    <InputUi
      placeholder="please input" value={val}
      onChange={val => onChange(val)} sty={{ marginBottom: 0, width: '100%', height: 36 }}
    />
  )
}

const InputRangeComponentCustom = ({ val, onChange }) => {
  return (
    <InputUi
      min={0} max={100} placeholder="0-100" value={val}
      onChange={val => onChange(calcValue(val, 100))} sty={{ marginBottom: 0, width: 75, height: 36 }}
    />
  )
}

const TAB = {
  PROPERTY: 0,
  MATERIAL: 1,
  NAME: 2,
  BRAND: 3,
  COLOR: 4,
  SIZE: 5,
  DELETE_PRODUCT: 6,
}

const Handling = 'Handling'
const ReportText = 'Submit'
const REPORT_CURRENT_TAB = 'REPORT_'
const Empty = { key: '', description: '' }

export const ReportProductModalV2 = ({ isOpen, nornaids, type = ReportType.SINGLE_PRODUCT, closeModal, productUrl, products = [] }: ReportProductModalTypesV2) => {
  const [ error, setError ] = useState('')
  const [ buttonName, setButtonName ] = useState(ReportText)
  const [ reportSent, isReportSent ] = useState(false)

  const { postFn: fetch } = useFetch()
  const { adminMetaData: baseData } = useAdminMetaData()

  const { account: currentUser } = useSelector(getCacheData)

  // report functionality hook
  const { reportFn, batchReportFn, getReportedFn, qaPage, deleteProduct } = useReport()
  // qa product hook
  const { clearCheckedProduct } = useQa()

  const [ property, setProperty ] = useState<Array<OptionType>>([ cloneDeep(Empty) ])
  const [ material, setMaterial ] = useState<Array<OptionType>>([ cloneDeep(Empty) ])
  const [ color, setColor ] = useState<Array<OptionType & { r: number, g: number, b: number }>>([ { ...cloneDeep(Empty), r: 0, g: 0, b: 0 } ])

  /**
   * report title
   */
  const [ title, setTitle ] = useState<string>()
  const [ unset, setUnset ] = useState<boolean>(false)
  const [ crossVariation, setCrossVariation ] = useState(false)

  /**
   * set brand
   */
  const [ brand, setBrand ] = useState<string>()

  /**
   * size
   */
  const [ size, setSize ] = useState<string>()

  // open again
  useEffect(() => {
    if (isOpen) isReportSent(false)
  }, [ isOpen ])
  const handleCloseModal = () => closeModal(false)

  const tabStore = lsGet(REPORT_CURRENT_TAB)
  const [ tab, setTab ] = useState(type === ReportType.MULTI_PRODUCT && `${Number(tabStore)}` === `${TAB.COLOR}` ? TAB.PROPERTY : Number(lsGet(REPORT_CURRENT_TAB)) ?? TAB.PROPERTY)

  const checkReport = useCallback(() => {
    let total = 0; let colorTotal = 0
    const submitObj: any = {}

    // BEGIN: property
    if (tab === TAB.PROPERTY) {
      submitObj.property = property.map(item => {
        if (!item.key || !item.description) submitObj.failedProperty = true
        return [ item.key, item.description ]
      }) as any
      if (submitObj.failedProperty) {
        setButtonName(ReportText)
        return setError('please input every value for property')
      }
      if (submitObj.property.length) {
        const tmp = Array.from(new Set(submitObj.property.map(item => item.join())))
        if (tmp.length !== submitObj.property.length) {
          return setError('do not submit the same item for property')
        }
      }
    }
    // END: property

    // BEGIN: material
    if (tab === TAB.MATERIAL) {
      submitObj.material = material.map(item => {
        total += parseFloat(item.description)
        if (!item.key || !item.description) submitObj.failedMaterial = true
        return [ item.key, item.description ]
      }) as any
      if (submitObj.failedMaterial) {
        setButtonName(ReportText)
        return setError('please input every value')
      }
      if (total !== 100 && total !== 0) {
        setButtonName(ReportText)
        return setError('sum of material value is not equal 100')
      }
      if (submitObj.material.length) {
        const tmp = Array.from(new Set(submitObj.material.map(item => item[0])))
        if (tmp.length !== submitObj.material.length) {
          setButtonName(ReportText)
          return setError('do not submit the same item name form material')
        }
      }
    }
    // END: material

    // BEGIN: color
    if (tab === TAB.COLOR) {
      submitObj.color = color.map(item => {
        colorTotal += parseFloat(item.description)
        if (!item.description) submitObj.failedColor = true
        return [ [ item.r, item.g, item.b ].join(splitor), item.description ]
      }) as any

      if (submitObj.failedColor) {
        setButtonName(ReportText)
        return setError('please input every value')
      }
      if (colorTotal !== 100 && colorTotal !== 0) {
        setButtonName(ReportText)
        return setError('sum of color value is not equal 100')
      }
      if (color.length) {
        const tmp = Array.from(new Set(submitObj.color.map(item => item[0])))
        if (tmp.length !== submitObj.color.length) {
          setButtonName(ReportText)
          return setError('do not submit the same color item')
        }
      }
      if (!color.length && !material.length && !property.length) {
        setButtonName(ReportText)
        return
      }
    }

    if (tab === TAB.NAME) {
      submitObj.title = [ [ 'name', title ] ] as any
      if (!title) {
        setButtonName(ReportText)
        return setError('please input every value')
      }
    }

    if (tab === TAB.BRAND) {
      submitObj.brand = [ [ 'brand', brand ] ] as any
      if (!brand) {
        setButtonName(ReportText)
        return setError('please input every value')
      }
    }

    if (tab === TAB.SIZE) {
      submitObj.size = [ [ 'size', size ] ] as any
      if (!size) {
        setButtonName(ReportText)
        return setError('please input every value')
      }
    }

    setError('') // set error empty
    return submitObj
  }, [ color, property, material, tab, title, brand, size ])

  const sendReport = async () => {
    setButtonName(Handling)

    if (tab === TAB.DELETE_PRODUCT) {
      const payload = {
        url: '/delete_product/',
        data: {
          user: currentUser?.email,
          url: productUrl,
          vendor: products[0]?.vendor,
          qa_page: qaPage,
        },
      }
      const res = await deleteProduct(payload)
      if (!res?.data?.success) {
        setButtonName(ReportText)
        setError('submit material failed, try it later')
      }
      return
    }

    try {
      const payload = { user: currentUser?.email, url: productUrl, mask: [], field: 'material', crossVariation: false }
      let query: any = null; let res: any = null

      // let total = 0; let colorTotal = 0;
      const submitObj: any = checkReport()
      if (!submitObj) return

      // BEGIN: property
      if (tab === TAB.PROPERTY) {
        if (submitObj?.property?.length) {
          const properties = submitObj.property

          if (type === ReportType.MULTI_PRODUCT) {
            res = await batchReportFn(properties, BatchReportArgType.PROPERTY, unset || properties.map(item => item[1]).indexOf(UNSET) > -1, products, crossVariation)
          } else {
            res = await reportFn(productUrl, properties, BatchReportArgType.PROPERTY, unset || properties.map(item => item[1]).indexOf(UNSET) > -1, products[0]?.vendor, crossVariation)
          }
          if (!res?.data?.success) {
            setButtonName(ReportText)
            setError('submit property failed, try it later')

          } else if (type === ReportType.MULTI_PRODUCT) {
            clearCheckedProduct()
          }
        }
      }
      // END: property

      // BEGIN: material
      if (tab === TAB.MATERIAL) {
        if (submitObj.material.length) {
          if (type === ReportType.MULTI_PRODUCT) {
            res = await batchReportFn(submitObj.material, BatchReportArgType.MATERIAL, false, products, crossVariation)
          } else {
            res = await reportFn(productUrl, submitObj.material, BatchReportArgType.MATERIAL, false, products[0]?.vendor, crossVariation)

          }
          if (!res?.data?.success) {
            setButtonName(ReportText)
            setError('submit material failed, try it later')
          } else if (type === ReportType.MULTI_PRODUCT) {
            clearCheckedProduct()
          }
        }
      }
      // END: material

      // BEGIN: color
      if (tab === TAB.COLOR) {
        if (color.length) {
          payload.field = 'color'
          payload.mask = submitObj.color
          payload.crossVariation = crossVariation || false
          query = reportToolArg(payload)
          res = await fetch(query.url, query)
          if (!res?.data?.success) {
            setError('submit color failed, try it later')
          }
        }
        if (!color.length && !material.length && !property.length) {
          setButtonName(ReportText)
          return
        }
      }

      if (tab === TAB.NAME || tab === TAB.BRAND || tab === TAB.SIZE) {
        let field = ''
        if (tab === TAB.NAME) {
          field = 'title'
        } else if (tab === TAB.BRAND) {
          field = 'brand'
        } else if (tab === TAB.SIZE) {
          field = 'size'
        }
        if (type === ReportType.MULTI_PRODUCT) {
          res = await batchReportFn(submitObj[field], tab === TAB.SIZE ? BatchReportArgType.SIZE : BatchReportArgType.PROPERTY, false, products, crossVariation)
        } else {
          res = await reportFn(productUrl, submitObj[field], tab === TAB.SIZE ? BatchReportArgType.SIZE : BatchReportArgType.PROPERTY, false, products[0]?.vendor, crossVariation)
        }
        if (!res?.data?.success) {
          setButtonName(ReportText)
          setError(`submit ${field} failed, try it later`)
        } else if (type === ReportType.MULTI_PRODUCT) {
          clearCheckedProduct()
        }
      }

      setError('')
      getReportedFn()
      // END: color
      if (res && res?.data?.success) {
        isReportSent(true)
        setButtonName('Thank you !')
      } else if (color.length || property.length || material.length) {
        setButtonName('Report failed, try it later!')
      }
    } catch (e) {
      setButtonName(ReportText)
      console.log(e)
    }
  }

  /**
   * update content
   */
  const updateFn = useCallback(
    (setProperty, property, index, val) => {
      setProperty([
        ...property.slice(0, index),
        Object.assign(property[index], val),
        ...property.slice(index + 1),
      ])
      checkReport()
    },
    [])//eslint-disable-line

  /**
   * add new row for material , property or color
   */
  const addNewRow = useCallback((setFn, options) => {
    let needFill = false
    options.slice(0, options.length - 1).forEach(item => {
      if (Object.prototype.hasOwnProperty.call(item, 'r')) {
        if (!item.description) needFill = true
      } else if (!item.key || !item.description) needFill = true
    })
    if (needFill) return setError('please fill out before add new row')
    setFn(options)
    checkReport()
  }, [setError]) //eslint-disable-line

  return (
    <Dragable width={480} height={300} y={-150} x={-240} >
      <ShouldRender shouldRender={isOpen}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <Text small>Report Tool</Text>
            <CloseIcon onClick={handleCloseModal} />
          </div>
          <div className={classNames(styles.body)}>
            {
              reportSent ? (
                <div style={{ lineHeight: 10, textAlign: 'center' }}>
                  success
                </div>
              ) : (
                <>
                  <Tabs
                    value={tab}
                    className={styles.tabs}
                    indicatorColor="primary"
                    onChange={(e, newValue) => {
                      lsSet(REPORT_CURRENT_TAB, newValue) //
                      setTab(newValue)
                    }} aria-label="report tool tabs "
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab value={0} label="Property" />
                    <Tab value={1} label="Material" />
                    <Tab value={2} label="Name" />
                    <Tab value={3} label="Brand" />
                    {type === ReportType.SINGLE_PRODUCT ? <Tab value={4} label="Color" /> : null}
                    <Tab value={5} label="Size" />
                    {type === ReportType.SINGLE_PRODUCT ? <Tab value={6} label="Delete product" /> : null}
                  </Tabs>
                  {
                    tab === TAB.PROPERTY ? (
                      <div>
                        {
                          property.map((item, index) => {
                            const firstLevelOptions = (baseData[BaseField.propertyGroup] || [])
                              .filter(item => {
                                return !hidePropertyFirstLevelList.includes(item)
                              })
                            // 第一级选中值
                            const selectedFirstLevel = item.key
                            // subproperty 选项
                            let secondLevelOptions = baseData[selectedFirstLevel] ? [
                              ...baseData[selectedFirstLevel],
                              // UNSET
                            ] : []
                            // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/875
                            if (selectedFirstLevel === 'TargetGroup' && baseData[selectedFirstLevel]) {
                              secondLevelOptions.push(UNISEX)
                            }
                            /**
                             * @date 2022/07/06
                             * mask Colors 时禁用`UNSET`
                             */
                            if (selectedFirstLevel !== 'Colors' && baseData[selectedFirstLevel]) {
                              secondLevelOptions.push(UNSET)
                            }
                            
                            if (Object.keys(hidePropertySecondLevelMapper).includes(selectedFirstLevel)) {
                              secondLevelOptions = secondLevelOptions
                                .filter(item => !hidePropertySecondLevelMapper?.[selectedFirstLevel]?.includes(item))
                            }

                            return (
                              <div 
                                className={classNames('flex flex-row', styles['row-item'])} style={{ marginBottom: 10 }} 
                                key={selectedFirstLevel}
                              >
                                <DropdownCustom
                                  val={selectedFirstLevel}
                                  optionsData={firstLevelOptions}
                                  onChange={(val: string) => updateFn(setProperty, property, index, { key: val, description: '' })}
                                />
                                <DropdownCustom
                                  val={item.description}
                                  optionsData={secondLevelOptions}
                                  onChange={(val: string) => updateFn(setProperty, property, index, { description: val })}
                                />
                                <ButtonCustom
                                  onClick={() => setProperty([ ...property.slice(0, index), ...property.slice(index + 1) ])}
                                  icon={<MinusIcon />}
                                />
                              </div>
                            )
                          })
                        }
                        <div className="flex flex-row">
                          <ButtonCustom
                            style={{ marginRight: 15 }}
                            onClick={() => addNewRow(setProperty, [ ...property, cloneDeep(Empty) ])}
                            icon={<PlusIcon />}
                          />
                          <Checkbox
                            isChecked={unset}
                            onChange={() => {
                              setUnset(!unset)
                            }}
                          >
                            unset
                          </Checkbox>
                          <Box width={10} />
                          <Checkbox
                            isChecked={crossVariation}
                            onChange={() => {
                              setCrossVariation(!crossVariation)
                            }}
                          >
                            cross variation
                          </Checkbox>
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    tab === TAB.MATERIAL ? (
                      <div>
                        {
                          material.map((item, index) => (
                            <div 
                              className={classNames('flex flex-row', styles['row-item'])} 
                              style={{ marginBottom: 10 }} 
                              key={item.key}
                            >
                              <DropdownCustom
                                val={item.key}
                                optionsData={(baseData.Material || [])}
                                onChange={(val: string) => updateFn(setMaterial, material, index, { key: val })}
                              />
                              <InputRangeComponentCustom
                                val={item.description}
                                onChange={val => setMaterial([
                                  ...material.slice(0, index),
                                  Object.assign(material[index], { description: val }),
                                  ...material.slice(index + 1),
                                ])}
                              />
                              <ButtonCustom
                                icon={<MinusIcon />}
                                onClick={() => setMaterial([ ...material.slice(0, index), ...material.slice(index + 1) ])}
                              />
                            </div>
                          ))
                        }
                        <ButtonCustom
                          icon={<PlusIcon />}
                          onClick={() => addNewRow(setMaterial, [ ...material, cloneDeep(Empty) ])}
                        />
                      </div>
                    ) : null
                  }
                  {
                    tab === TAB.COLOR ? (
                      <div>
                        {
                          color.map((item, index) => (
                            <div 
                              className={classNames('flex flex-row', styles['row-item'])} 
                              style={{ marginBottom: 10 }} 
                              key={item.key}
                            >
                              <InputComponentCustom
                                key="r"

                                val={item.r} onChange={valInt => setColor([
                                  ...color.slice(0, index),
                                  Object.assign(color[index], { r: valInt }),
                                  ...color.slice(index + 1),
                                ])}
                              />
                              <InputComponentCustom
                                key="g"
                                val={item.g} onChange={valInt => setColor([
                                  ...color.slice(0, index),
                                  Object.assign(color[index], { g: valInt }),
                                  ...color.slice(index + 1),
                                ])}
                              />
                              <InputComponentCustom
                                key="b"
                                val={item.b} onChange={valInt => setColor([
                                  ...color.slice(0, index),
                                  Object.assign(color[index], { b: valInt }),
                                  ...color.slice(index + 1),
                                ])}
                              />
                              <InputRangeComponentCustom
                                val={item.description}
                                onChange={val => {
                                  setColor([
                                    ...color.slice(0, index),
                                    Object.assign(color[index], { description: val }),
                                    ...color.slice(index + 1),
                                  ])
                                }}
                              />
                              <ButtonCustom
                                icon={<MinusIcon />}
                                onClick={() => setColor([ ...color.slice(0, index), ...color.slice(index + 1) ])}
                              />
                            </div>
                          ))
                        }
                        <ButtonCustom
                          icon={<PlusIcon />}
                          onClick={() => addNewRow(setColor, [ ...color, { ...cloneDeep(Empty), r: 0, g: 0, b: 0 } ])}
                        />
                      </div>
                    ) : null}
                  {
                    tab === TAB.NAME ? (
                      <div>
                        <TextInputComponentCustom
                          key="title"
                          val={title}
                          onChange={(val: string) => setTitle(val)}
                        />
                      </div>
                    ) : null
                  }
                  {
                    tab === TAB.BRAND ? (
                      <div>
                        <TextInputComponentCustom
                          key="brand"
                          val={brand}
                          onChange={(val: string) => setBrand(val)}
                        />
                      </div>
                    ) : null
                  }
                  {
                    tab === TAB.SIZE ? (
                      <div>
                        <TextInputComponentCustom
                          key="size"
                          val={size}
                          onChange={(val: string) => setSize(val)}
                        />
                      </div>
                    ) : null
                  }
                  {
                    tab === TAB.DELETE_PRODUCT ? (
                      <Flex vertical gap={8} style={{ fontSize: 16 }}>
                        <div style={{ fontWeight: 'bold' }}>url</div>
                        <div style={{ fontSize: 14, color: '#666' }}>{productUrl}</div>
                        <div style={{ fontWeight: 'bold' }}>vendor</div>
                        <div style={{ fontSize: 14, color: '#666' }}>{products?.[0]?.vendor}</div>
                      </Flex>
                    ) : null
                  }
                </>
              )
            }
          </div>
          <div className={styles.footer}>
            {!reportSent ? <>
              <div className={styles.errorInfo} >{error}</div>
              <Button
                icon={<VisibilityEyeIcon />}
                primary
                onClick={debounce(sendReport, 300)}
                disabled={buttonName === Handling}
                loading={buttonName === Handling}
              >
                {buttonName}
              </Button>
                           </> : null}
            {
              reportSent ? (
                <Button
                  icon={<ArrowBackwardIcon />}
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    material.length && setMaterial([ cloneDeep(Empty) ])
                    property.length && setProperty([ cloneDeep(Empty) ])
                    color.length && setColor([ { ...cloneDeep(Empty), r: 0, g: 0, b: 0 } ])
                    isReportSent(false)
                    setError('')
                    setUnset(false)
                    setCrossVariation(false)
                    setButtonName(ReportText)
                  }} primary
                >
                  Back
                </Button>
              ) : null}
          </div>
        </div>
      </ShouldRender>
    </Dragable>
  )
}

ReportProductModalV2.displayName = 'ReportProductModalV2'
