import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RoutesObj } from '../global/Routes'
import { isJustBrandsLicense } from 'consts'
import { PRODUCT_RANKING_FOR_JUST_BRANDS_TABLE_NAME } from 'configs/moduleName'

/**
 * ## usePageDate
 * 
 * Analytics 页面的右上角会有一个倒立的感叹号图标，鼠标悬浮在图标上会显示 Filter 中框选的内容和当前页面的日期值
 * 当前页面的日期值就是 pageDate 变量
 * 
 * 注：当一个页面有多个日期框时，pageDate 等于页面最上面的日期框中的值
 */
export const useFunctionHandler = (): React.ReactNode => {

    const { pathname, search } = useLocation()
    const pathStr = [ pathname, search ].join('')

    if (pathStr.startsWith(RoutesObj.SEARCH_OPTIONS.URL)) {
        return <><span>Search</span>&nbsp;/&nbsp;<Link to={RoutesObj.SEARCH_OPTIONS.URL} >{RoutesObj.SEARCH_OPTIONS.LABEL}</Link> </>
    } 
    if (
        pathStr.startsWith(RoutesObj.LOOKBOOL_LIST.URL) ||
        pathStr.startsWith('/lookbook')
    ) {
        return <><span>Search</span>&nbsp;/&nbsp;<Link to={RoutesObj.LOOKBOOL_LIST.URL} >{RoutesObj.LOOKBOOL_LIST.LABEL}</Link></>
    } if (pathStr.startsWith(RoutesObj.MY_SAVED_FILTERS.URL)) {
        return <><span>Search</span>&nbsp;/&nbsp;<Link to={RoutesObj.MY_SAVED_FILTERS.URL} >{RoutesObj.MY_SAVED_FILTERS.LABEL}</Link></>
    } if (pathStr.startsWith('/profile')) {
        return <><span>Profile</span></>
    } 
    if (pathStr.startsWith(RoutesObj.PRICE_HISTORY.URL)) {
        return <><span>Pricing</span>&nbsp;/&nbsp;<Link to={RoutesObj.PRICE_HISTORY.URL} >{RoutesObj.PRICE_HISTORY.LABEL}</Link></>
    } 
    if (pathStr.startsWith(RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL)) {
        return <><span>Pricing</span>&nbsp;/&nbsp;<Link to={RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL} >{RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.PRICE_ARCHITECTURE.URL)) {
        return <><span>Pricing</span>&nbsp;/&nbsp;<Link to={RoutesObj.PRICE_ARCHITECTURE.URL} >{RoutesObj.PRICE_ARCHITECTURE.LABEL}</Link></>
    }if (pathStr.startsWith(RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL)) {
        return <><span>Pricing</span>&nbsp;/&nbsp;<Link to={RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL} >{RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.PRICING_JUMP_TABLE.URL)) {
        return <><span>Pricing</span>&nbsp;/&nbsp;<Link to={RoutesObj.PRICING_JUMP_TABLE.URL}>{RoutesObj.PRICING_JUMP_TABLE.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.CROSS_REGION_SOLD_OUT.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.CROSS_REGION_SOLD_OUT.URL}>{RoutesObj.CROSS_REGION_SOLD_OUT.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.PRICE_ARCHITECTURE.URL)) {
        return <><span>Pricing</span>&nbsp;/&nbsp;<Link to={RoutesObj.PRICE_ARCHITECTURE.URL}>{RoutesObj.PRICE_ARCHITECTURE.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.ASSORTMENT_SIZE.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.ASSORTMENT_SIZE.URL}>{RoutesObj.ASSORTMENT_SIZE.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL}>{RoutesObj.MOST_POPULAR_LAUNCH_DAYS.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL}>{RoutesObj.OPTIONS_IN_OUT_CALENDAR.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.PRICE_INSIGHT.URL)) {
        return <><span>Dashboard</span>&nbsp;/&nbsp;<Link to={RoutesObj.PRICE_INSIGHT.URL}>{RoutesObj.PRICE_INSIGHT.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.URL}>{RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.ASSORTMENT_INSIGHT.URL)) {
        return <><span>Dashboard</span>&nbsp;/&nbsp;<Link to={RoutesObj.ASSORTMENT_INSIGHT.URL}>{RoutesObj.ASSORTMENT_INSIGHT.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.PRICE_SPREADS.URL)) {
        return <><span>Dashboard</span>&nbsp;/&nbsp;<Link to={RoutesObj.PRICE_SPREADS.URL}>{RoutesObj.PRICE_SPREADS.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.IN_OUT_MONITORING.URL)) {
        return <><span>Dashboard</span>&nbsp;/&nbsp;<Link to={RoutesObj.IN_OUT_MONITORING.URL}>{RoutesObj.IN_OUT_MONITORING.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.DYNAMIC_DASHBOARD.URL)) {
        return <><span>Dashboard</span>&nbsp;/&nbsp;<Link to={RoutesObj.DYNAMIC_DASHBOARD.URL}>{RoutesObj.DYNAMIC_DASHBOARD.LABEL}</Link></>
    }

    // 需要放到后面
    if (pathStr.startsWith(RoutesObj.DASHBOARD.URL)||pathStr.startsWith(RoutesObj.DASHBOARD_ALIAS.URL)) {
        return <><span>Dashboard</span>&nbsp;/&nbsp;<Link to={RoutesObj.DASHBOARD.URL}>{RoutesObj.DASHBOARD.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.CATEGORY_ANALYTICS.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.CATEGORY_ANALYTICS.URL}>{RoutesObj.CATEGORY_ANALYTICS.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.COLOR_ANALYTICS.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.COLOR_ANALYTICS.URL}>{RoutesObj.COLOR_ANALYTICS.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.MATERIAL_ANALYTICS.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.MATERIAL_ANALYTICS.URL}>{RoutesObj.MATERIAL_ANALYTICS.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.POPULAR_PRODUCTS.URL)) {
        return (
            <>
                <span>Assortment</span>&nbsp;/&nbsp;
                <Link to={RoutesObj.POPULAR_PRODUCTS.URL}>
                    {isJustBrandsLicense() ? PRODUCT_RANKING_FOR_JUST_BRANDS_TABLE_NAME : RoutesObj.POPULAR_PRODUCTS.LABEL}
                </Link>
            </>
        )
    }
    if (pathStr.startsWith(RoutesObj.POPULAR_ATTRIBUTES.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.POPULAR_ATTRIBUTES.URL}>{RoutesObj.POPULAR_ATTRIBUTES.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.PLATFORM_ANALYTICS.URL)) {
        return <><span>Assortment</span>&nbsp;/&nbsp;<Link to={RoutesObj.PLATFORM_ANALYTICS.URL}>{RoutesObj.PLATFORM_ANALYTICS.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.DYNAMIC_DASHBOARD_REPORT.URL)) {
        return <><span>Dashboard</span>&nbsp;/&nbsp;<Link to={RoutesObj.DYNAMIC_DASHBOARD_REPORT.URL}>{RoutesObj.DYNAMIC_DASHBOARD_REPORT.LABEL}</Link></>
    }
    if (pathStr.startsWith(RoutesObj.LINE_GRAPH_REPORT.URL)) {
        return (
            <>
                <span>Dashboard</span>&nbsp;/&nbsp;
                <Link 
                    to={RoutesObj.LINE_GRAPH_REPORT.URL}
                >
                    {RoutesObj.LINE_GRAPH_REPORT.LABEL}
                </Link>
            </>
        )
    }
    
    return ''
}
