import React, { FC, ReactNode, CSSProperties, useRef, useState, useLayoutEffect } from 'react'
import classnames from 'classnames'
import { useFilterDisplayHeight } from 'hooks'
import loadingImg from './demo.gif'
import { SpinProps } from './types'
import styles from './styles.module.scss'

/**
 * ## 何时使用
 * 
 * 用于页面的加载中状态。
 *
 * ## 代码演示
 * 
 * ### 基本用法
 * 
 * this.state.loading 变量为 true 时，页面会一直处于加载状态。
 * 
 * ```
 * <Spin spinning={this.state.loading}>
 *    <div>xxx</div>
 * </Spin>
 * ```
 * 
 * ### 高度说明 minHeight
 * 
 * Spin 默认最小高度为 300px，如果不需要最小高度，可设置 minHeight={0}
 * 
 * Spin 的最大高度为其 children 的实际高度。
 * 
 * ```
 * <Spin spinning={this.state.loading} minHeight={0}>
 *    <div>xxx</div>
 * </Spin>
 * ```
 * 
 * ### 层级设置说明 zIndex
 * 
 * 页面中有些第三方组件如日历组件的 zIndex 为 100，有时第三方组件会悬浮在 Spin 上方。
 * 
 * 如果希望 Spin 位于其它组件上方，可设置 zIndex 属性，默认值为 101。
 * 
 * ```
 * <Spin spinning={this.state.loading} zIndex={101}>
 *    <div>xxx</div>
 * </Spin>
 * ```
 * 
 * ### 加载图标各种大小 size
 * 
 * Spin 的加载图标大小有三种设置，small、default（默认值）、large
 * 
 * ```
 * <Spin spinning={this.state.loading} size="default">
 *    <div>xxx</div>
 * </Spin>
 * ```
 */
export const Spin: FC<SpinProps> = ({
  children = null,
  spinning = true,
  size = 'default',
  style = {},
  maskStyle = {},
  zIndex = 101,
  minHeight = 300,
  className = '',
  useSpinImg = false,
}) => {
  // loading 大小
  const sizeObj: Record<'small' | 'default' | 'large', string> = {
    small: 'loading-icon-small',
    default: 'loading-icon-default',
    large: 'loading-icon-large',
  }

  // spinWrap 默认样式
  const defaultStyle: CSSProperties = {
    minHeight: minHeight + 'px',
  }

  // spinMask 默认样式
  const defaultMaskStyle: CSSProperties = {
    zIndex,
  }

  // 定义 LoadingIcon 展示模式
  // LoadingMode.flex 容器高度小于浏览器可视窗口高度时，LoadingIcon 垂直水平居中展示
  // LoadingMode.sticky 容器高度大于浏览器可视窗口高度时，
  // 采用 position: sticky 定位
  enum LoadingMode {
    flex = '垂直水平居中',
    sticky = '粘性定位',
  }

  // LoadingIcon 根据该变量值进行展示
  const [ loadingMode, setLoadingMode ] = useState<LoadingMode>(LoadingMode.flex)
  // 容器 div，主要为了获取容器高度
  const divRef = useRef<HTMLDivElement>(null)
  const filterDisplayHeight = useFilterDisplayHeight()

  // 计算 loadingMode 值
  useLayoutEffect(() => {
    if (!divRef.current) return
    // 容器高度
    const { height } = divRef.current.getBoundingClientRect()
    // 浏览器可视窗口高度
    const bodyEleHeight = window.innerHeight - filterDisplayHeight

    if (height < bodyEleHeight) {
      setLoadingMode(LoadingMode.flex)
    } else {
      setLoadingMode(LoadingMode.sticky)
    }
  }, [ children, LoadingMode.flex, LoadingMode.sticky, filterDisplayHeight ])

  // LoadingIcon
  let loadingContent: ReactNode = null
  if (loadingMode === LoadingMode.flex) {
    loadingContent = (
        <div style={{ ...defaultMaskStyle, ...maskStyle }} className={classnames(styles.spinMask, styles.spinMaskFlex)}>
            {
              useSpinImg ? <LoadingImg /> : <LoadingIcon size={sizeObj[size]} />
            }
        </div>
    )
  } else {
    loadingContent = (
        <div style={{ ...defaultMaskStyle, ...maskStyle }} className={styles.spinMask}>
            <div className={styles.spinMaskSticky}>
              {
                useSpinImg ? <LoadingImg /> : <LoadingIcon size={sizeObj[size]} />
              }
            </div>
        </div>
    )
  }

  return (
      <div 
          className={classnames(styles.spinWrap, className)} 
          style={{ ...defaultStyle, ...style }} 
          ref={divRef}
          onMouseDown={e => e.stopPropagation()}
      >
          { children }
          { spinning && loadingContent }
      </div>
  )
}

const LoadingImg = () => {
  return (
    <img
      style={{ userSelect: 'none' }}
      src={loadingImg}
      alt=""
      width={45} 
      height={45} 
    />
  )
}

const LoadingIcon = ({
  size,
}) => (
    <svg
        width="135"
        height="135"
        viewBox="0 0 135 135"
        xmlns="http://www.w3.org/2000/svg"
        fill="#DE183D"
        className={styles[size]}
    >
        <path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 67 67"
                to="-360 67 67"
                dur="2.5s"
                repeatCount="indefinite"
            />
        </path>
        <path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 67 67"
                to="360 67 67"
                dur="8s"
                repeatCount="indefinite"
            />
        </path>
    </svg>
)

Spin.displayName = 'Spin'
