import React, { CSSProperties, useState } from 'react'
import classnames from 'classnames'
import { CloseIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { FILTER_DIALOG_CLASS, NO_SIZE_IGNORE_NO_AVAILABLE_SIZE, NO_SIZE_NO_AVAILABLE_SIZE_UPDATE, NO_SIZE_NO_HARMONIZED_SIZE, NO_SIZE_NO_SIZE_HISTORY, NO_SIZE_NO_TRUE_SELLER_INFORMATION_Z, NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE } from 'consts'
import styles from './styles.module.scss'
import { Tooltip } from 'antd'
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined'

interface MaterialFilterProps {
  noSelectionDatesSize,
  noHistoricalSize,
  noUnavailableSize,
  noHarmonizedSize,
  zalandodeepShopEmptyString,
  ignoreNoAvailableSize,
  onClose: Function;
  onOk: Function;
  modalMaskStyle?: CSSProperties;
}
export const RadioItem = ({ checked = false, setChecked }: any) => (
    <button
      className={classnames(styles.radio, checked ? styles.active : '')}
      onClick={() => {
        setChecked(!checked)
      }}
    >
      <div />
    </button>
  )
export const NoSizeDialog = React.forwardRef(({ 
  noSelectionDatesSize,
  noHistoricalSize,
  noUnavailableSize,
  noHarmonizedSize,
  zalandodeepShopEmptyString,
  ignoreNoAvailableSize,
  onClose, 
  onOk, 
  modalMaskStyle = {}, 
}: MaterialFilterProps, ref) => {
  const [ noSizeUpdate, setNoSizeUpdate ] = useState(noSelectionDatesSize)
  const [ noSizeHistory, setNoSizeHistory ] = useState(noHistoricalSize)
  const [ noUnavailable, setNoUnavailable ] = useState(noUnavailableSize)
  const [ noHarmonized, setNoHarmonized ] = useState(noHarmonizedSize)
  const [ isZalandodeepShopEmptyString, setIsZalandodeepShopEmptyString ] = useState(zalandodeepShopEmptyString)
  const [ isIgnoreNoAvailableSize, setIsIgnoreNoAvailableSize ] = useState(ignoreNoAvailableSize)

  return (
    <div className={`material-filter ${FILTER_DIALOG_CLASS}`}>
      <div
        className={styles.modal}
        style={modalMaskStyle}
        onClick={() => onClose?.()}
      />
      <div className={classnames(styles['material-filter'])}>
        <div className={styles.close}>
          <CloseIcon
            onClick={() => {
              onClose([ noSizeUpdate, noSizeHistory, noUnavailable, noHarmonized, isZalandodeepShopEmptyString, isIgnoreNoAvailableSize ].join(','))
            }}
          />
        </div>
        <div className={styles.content}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoSizeUpdate(value)
                // if (value) {
                //   setNoSizeHistory(false)
                //   setNoUnavailable(false)
                //   setNoHarmonized(false)
                //   setIsZalandodeepShopEmptyString(false)
                //   setIsIgnoreNoAvailableSize(false)
                // }
              }}
              checked={noSizeUpdate}
            />
            <div>{NO_SIZE_NO_AVAILABLE_SIZE_UPDATE}</div>
            <Tooltip
              title="From the products in the current filter selection, there is no available size update in 14 days from the start of the date range that is selected."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoUnavailable(value)
                // if (value) {
                //   setNoSizeUpdate(false)
                //   setNoSizeHistory(false)
                //   setNoHarmonized(false)
                //   setIsZalandodeepShopEmptyString(false)
                //   setIsIgnoreNoAvailableSize(false)
                // }
              }}
              checked={noUnavailable}
            />
            <div>{NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE}</div>
            <Tooltip
              title="From the products in the current filter selection, there is no unavailable size update in 14 days from the start of the date range that is selected."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoSizeHistory(value)
                // if (value) {
                //   setNoSizeUpdate(false)
                //   setNoUnavailable(false)
                //   setNoHarmonized(false)
                //   setIsZalandodeepShopEmptyString(false)
                //   setIsIgnoreNoAvailableSize(false)
                // }
              }}
              checked={noSizeHistory}
            />
            <div>{NO_SIZE_NO_SIZE_HISTORY}</div>
            <Tooltip
              title="From the products in the current filter selection, there is no size history of the products with respect to the cut date - 8 days."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoHarmonized(value)
                // if (value) {
                //   setNoSizeUpdate(false)
                //   setNoUnavailable(false)
                //   setNoSizeHistory(false)
                //   setIsZalandodeepShopEmptyString(false)
                //   setIsIgnoreNoAvailableSize(false)
                // }
              }}
              checked={noHarmonized}
            />
            <div>{NO_SIZE_NO_HARMONIZED_SIZE}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setIsZalandodeepShopEmptyString(value)
                // if (value) {
                //   setNoSizeUpdate(false)
                //   setNoUnavailable(false)
                //   setNoSizeHistory(false)
                //   setNoHarmonized(false)
                //   setIsIgnoreNoAvailableSize(false)
                // }
              }}
              checked={isZalandodeepShopEmptyString}
            />
            <div>{NO_SIZE_NO_TRUE_SELLER_INFORMATION_Z}</div>
            <Tooltip
              title="No seller information on any date of any size."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setIsIgnoreNoAvailableSize(value)
                // if (value) {
                //   setNoSizeUpdate(false)
                //   setNoUnavailable(false)
                //   setNoSizeHistory(false)
                //   setNoHarmonized(false)
                //   setIsZalandodeepShopEmptyString(false)
                // }
              }}
              checked={isIgnoreNoAvailableSize}
            />
            <div>{NO_SIZE_IGNORE_NO_AVAILABLE_SIZE}</div>
          </div>
          <div>
            <Button
              primary
              mini
              style={{ paddingInline: 40 }}
              className={styles.btn}
              onClick={() => {
                onOk([ noSizeUpdate, noSizeHistory, noUnavailable, noHarmonized, isZalandodeepShopEmptyString, isIgnoreNoAvailableSize ].join(','))
              }}
            > Ok
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})

NoSizeDialog.displayName = 'NoSizeDialog'
