import React, { useEffect, useState } from 'react'
import { DEFAULT_FONT_FAMILIY } from 'consts'
import { useWindowZoom } from 'hooks/useWindow'
import { Area, AreaChart, CartesianGrid, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts'
import { LineChartProps } from './types'
import { getMaxTick, getXTicks } from './utils'
import { numberUtils } from 'norna-uikit'

export const LineChart = ({
  label,
  chartData = [],
  customTooltipLabel,
  customYAxesTicks,
  yAxesMaxFixed,
  isHarmonizedSize = false,
}: LineChartProps) => {
  const zoom = useWindowZoom()

  function formatDate(date: string) {
    const d = new Date(date)
    const year = d.getFullYear()
    const monthMapper= [
        'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ]
    const month = monthMapper[d.getMonth() + 1]
    const day = String(d.getDate()).padStart(2, '0')
    return {
        year,
        month,
        day,
    }
  }

  const [ chartWidth, setChartWidth ] = useState<number>(480)

  useEffect(() => {
    if (zoom >= 1) return
    setChartWidth(480 * zoom)
  }, [ zoom ])

  const yAxisConfig = {
    unit: yAxesMaxFixed && !isHarmonizedSize ? '%' : '',
    tickFormatter: value => {
      if (isHarmonizedSize) {
        if (value === 0) {
          return 'In stock'
        } else if (value === 100) {
          return 'Sold out'
        }
        return ''
      }
      return numberUtils.formatNumberByComma(value)
    },
  }

  return (
    <div style={{ height: '140px', width: chartWidth, zoom: zoom >= 1 ? 1 : 1 / zoom }}>
      <AreaChart
        width={chartWidth}
        height={140}
        data={chartData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid 
          stroke="#d9d9d9" 
          strokeWidth={0.7}
        />
        <XAxis
          dataKey="x"
          axisLine={false}
          tickLine={{ stroke: '#d9d9d9', strokeWidth: 0.7 }}
          tickSize={10}
          tick={{ fontSize: '12px', fontFamily: DEFAULT_FONT_FAMILIY }}
          tickFormatter={value => {
            const { month, day } = formatDate(value)
            return `${month} ${day}`
          }}
          ticks={getXTicks(chartData)}
          orientation="bottom"
          interval="preserveStart"
        />
        <YAxis
          axisLine={false}
          tickLine={{ stroke: '#d9d9d9', strokeWidth: 0 }}
          tick={{ fontSize: '12px', fontFamily: DEFAULT_FONT_FAMILIY }}
          tickCount={5}
          type="number"
          domain={yAxesMaxFixed ? [ 0, 100 ] : [ 0, getMaxTick(chartData) ]}
          allowDecimals={false}
          allowDataOverflow={true}
          unit={yAxisConfig.unit}
          tickFormatter={yAxisConfig.tickFormatter}
          width={70}
        />
        {
          !!chartData?.length && (
            <Tooltip
              position={{ y: -10 }}
              isAnimationActive
              wrapperStyle={{ borderRadius: '8px', border: '1px solid transparent' }}
              contentStyle={{ borderRadius: '8px', border: '1px solid transparent' }}
              content={({ active, payload, label: la }) => {
                if (active && payload && payload.length) {
                  const { year, month, day } = formatDate(la)
                  return (
                    <div style={{ backgroundColor: 'rgb(51, 51, 51)', padding: '4px', fontSize: '12px', fontFamily: DEFAULT_FONT_FAMILIY, color: '#fff', borderRadius: '8px', outline: 'none', border: '0px solid transparent' }}>
                      <div style={{ fontWeight: 'bold' }}>
                        {`${month} ${day} ${year}`}
                      </div>
                      <div>
                        <span style={{ display: 'inline-block', width: '8px', height: '8px', backgroundColor: '#fff', marginRight: '4px' }} />
                        {label}: {
                          isHarmonizedSize ? (
                            <span>{ payload[0].value === 0 ? 'In stock' : payload[0].value === 100 ? 'Sold out' : '' }</span>
                          ) : (
                            <span>{numberUtils.formatNumberByComma(payload[0].value)}{yAxesMaxFixed ? '%' : ''}</span>
                          )
                        }
                      </div>
                    </div>
                  )
                }
                return null
              }}
            />
          )
        }
        <Area
          type="linear"
          dataKey="y"
          stroke="#ec7765"
          strokeWidth={3}
          fill="rgb(236 119 101 / 0.5)"
        />
        <ReferenceLine 
          y={getMaxTick(chartData, yAxesMaxFixed) * 0.75}
          stroke="#d9d9d9"
          strokeWidth={0.7}
        />
        <ReferenceLine 
          y={getMaxTick(chartData, yAxesMaxFixed) * 0.25}
          stroke="#d9d9d9"
          strokeWidth={0.7}
        />
      </AreaChart>
    </div>
  )
}

LineChart.displayName = 'LineChart'
