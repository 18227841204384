import { useCallback, useEffect } from 'react'
import { useFetch } from 'libs/hookRequest'
import { useDispatch, useSelector } from 'react-redux'
import { getCacheData, updateAdminMetaData } from 'features/filters/cacheDataSlice'
import { argFnGetMetaData } from 'graphql/nornaapi'
import { isEmpty } from 'lodash'
import { ArrayStringValueMap } from 'types/cache'
import { getAdminProperties } from 'utils/properties'

/**
 * hook to get authentication
 */
export const useAdminMetaData = () => {

  const dispatch = useDispatch()
  const { adminMetaData } = useSelector(getCacheData)
  const { getFn: fetchMetaData } = useFetch()

  const request = useCallback(async () => {
      const query = argFnGetMetaData()
      const metaData = await fetchMetaData(query.url)
      const baseData: ArrayStringValueMap<string> = getAdminProperties(metaData?.data?.categories, metaData?.data?.properties)
      dispatch(updateAdminMetaData(baseData))
    }, [ dispatch, fetchMetaData ])

  useEffect(() => {
    if (isEmpty(adminMetaData)) {
      request()
    }
  }, []);//eslint-disable-line
  return {
    adminMetaData,
  }
}
