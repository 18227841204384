import { LoadingSpinner } from 'assets/icons/Loading'
import { SkeletonLoading } from 'components/loaders/SkeletonLoading'
import { NoData } from 'components/NoData'
import { ShouldRender } from 'componentsv2/ShouldRender'
import React, { useState } from 'react'
import { Description } from 'components/collection/Products/components/ProductsRow/components'
import { useGetLookbookDetailProducts } from 'graphql/lookbook/getLookbookDetailProducts'
import { useParams } from 'react-router-dom'
import { ProductsList } from '../ProductsList'
import styles from './styles.module.scss'
import { LookbookProductsRowProps } from './types'
import { useSellers } from '../../../graphql/sellers/getSellers'

export const LookbookProductsRow = ({
                                      title,
                                      rowKey,
                                      count,
                                      type,
                                      averagePrice,
                                      currency,

                                      onDel = () => {
                                      },
                                    }: LookbookProductsRowProps) => {

  const { lookbookId } = useParams<{ lookbookId: string }>()
  const { data, loading, fetchMore } = useGetLookbookDetailProducts(
    // type, ['category', 'vendor', 'region'].indexOf(type) > -1 ? title : '',
    lookbookId)

  const [ extendRow, setExtendRow ] = useState(true)
  const toggleItemsRow = () => {
    setExtendRow(!extendRow)
  }
  const { codenames } = useSellers([])

  return (
    <div>
      {/* <div className={styles.collectionHeader}>
        <Header
          title={title ?? 'All Options'}
        />
      </div> */}
      {loading &&
      <div className={styles['loading-wrapper']}>
        <LoadingSpinner />
      </div>
      }
      <ShouldRender shouldRender={!loading && (data?.result || []).length > 0}>
        <div className={styles.wrap}>
          <div className={styles.descriptionColumn}>
            <Description
              toggleItemsRow={toggleItemsRow}
              productsCount={count}
              count={count}
              maxCount={1000000}
              currency={currency}
            />
          </div>
          <div className={styles.list}>
            <SkeletonLoading isLoading={loading}>
              <ProductsList
                onDel={async () => {
                  await onDel && onDel()
                }}
                date=""
                extendRow={extendRow}
                smallItems={true}
                items={(data?.result || []).map(item => ({
                    ...item,
                    convertedPrice: {
                      convertedDiscountedPrice: item.originalDiscountPrice,
                      convertedPrice: item.originalPrice,
                      convertedCurrency: item.originalCurrency,
                    },
                    brand:item.brand??item.seller?.brand??'',
                  }))}
                fetchMore={fetchMore}
                codenames={codenames}
                productsCount={count}

                category={title}
              />
            </SkeletonLoading>
          </div>
        </div>
      </ShouldRender>
      <ShouldRender shouldRender={!loading && (data?.result || []).length === 0}>
        <NoData small={true} />
      </ShouldRender>
    </div>
  )
}
