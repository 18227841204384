/**
 * groups
 * 
 * SELECTED_VENDORS
 * BOGNER_SELECTED
 * KAPPAHL_SELECTED
 */

import { LICENSE_BOGNER } from 'consts'

export const GROUP_BOGNER_NAME = 'Bogner Selected'
export const GROUP_BOGNER_SUFFIX = '@bogner'

export type GroupsConfigType = {
    license: string;
    suffix: string;
    name: string;
}[]

export const groupsConfig: GroupsConfigType = [
    {
        license: LICENSE_BOGNER,
        suffix: GROUP_BOGNER_SUFFIX,
        name: GROUP_BOGNER_NAME,
    },
]
