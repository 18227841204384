import { RoutesObj } from 'global/Routes'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { useFetch } from 'libs/hookRequest'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { PERMISSIONS,  IS_LIVE_ENV, ColorMaterialEmptyValue } from 'consts'
import { getQaData } from 'features/filters/qaDataSlice'
import { useLocation } from 'react-router-dom'
import { defaultIgnoreNoAvailableSize, defaultLaunchBeforeDate, defaultNoHarmonizedSize, defaultNoHistoricalSize, defaultNoSize, defaultNoUnavailableSize, defaultPackFilter, defaultPriceRange, defaultSizeFilter, defaultTaxFilter, defaultZalandodeepShopEmptyString, selectCollection, selectComparisonQuery, selectExcludedProducts, selectFilterDisplayHeight, selectLastComparisonQueryForLookbook } from 'features/filters/filtersSlice'
import { cloneDeep } from 'lodash'
import { addExclude } from 'utils/query'
import { fetchProductDetail } from 'graphql/nornaapi'
import { getTaxPayload } from 'utils/queryPayload'
import { useFilterCountry, useFilterTax } from './useFilter'
import { useQaPermission } from './useQaPermission'

export { useFilterQuery } from './useFilter'
export * from './useUrlParams'
export * from './useLogin'
export * from './useLoadingBar'
export * from './useScrollToTop'
export * from './useManualLoading'

/**
 * flatQuery hooks
 */
export const useFlatQueryObj = () => {
  const lastComparisonQueryForLookbook = useSelector(selectLastComparisonQueryForLookbook)
  const { comparisonQuery } = useSelector(selectComparisonQuery)
  const query = comparisonQuery.collection.query || {}
  return {
    categories: query.categories || [],
    sellers: comparisonQuery.sellers,
    properties: query.properties || {},
    customerSeller: comparisonQuery.customer_seller || {},
    region: comparisonQuery.collection.query.regions,
    launchAfter: query.earliestLaunchDate,
    launchBeforeDate: query.launchBeforeDate || defaultLaunchBeforeDate,
    targetGroups: query.targetGroups,
    sortField: query.sortField,
    name: comparisonQuery.name,
    materialFilter: query.materialFilter || ColorMaterialEmptyValue,
    colorFilter: query.colorFilter || ColorMaterialEmptyValue,
    sizeFilter: query.sizeFilter || defaultSizeFilter,
    taxFilter: query.taxFilter || defaultTaxFilter,
    priceFilter: query.priceFilter || {},
    packFilter: query.packFilter || defaultPackFilter,
    priceRange: query.priceRange || defaultPriceRange,
    noSize: query.noSize || defaultNoSize,
    noHistoricalSize: query.noHistoricalSize || defaultNoHistoricalSize,
    noUnavailableSize: query.noUnavailableSize || defaultNoUnavailableSize,
    noHarmonizedSize: query.noHarmonizedSize || defaultNoHarmonizedSize,
    zalandodeepShopEmptyString: query.zalandodeepShopEmptyString || defaultZalandodeepShopEmptyString,
    ignoreNoAvailableSize: query.ignoreNoAvailableSize || defaultIgnoreNoAvailableSize,
    collection: comparisonQuery.collection,
    query,
    lastComparisonQueryForLookbook,
    comparisonQuery,
  }
}

/**
 * url path search obj
 */
export const usePathSearchObj = () => {
  const parsed: any = queryString.parse(window.location.search)
  let singleDate = false
  if (parsed.date) {
    const dates = parsed.date.split('_')
    if (dates.length > 1) { // single date from range
      singleDate = dates[0] === dates[1]
    } else {
      singleDate = true
    }
  }
  return {
    ...parsed,
    singleDate,
  }
}
/**
 * get product details by nornaid
 * @param nornaid
 * @param enabledQa   启用QA模式
 */
export const useFetchProductDetail = (nornaid, qaAnnotation = false, metaName = '', toCurrency = '',enabledQa=false, qaPage: any = undefined) => {
  const { postFn:fetch, data, error, loading } = useFetch()
  const comparisonQuery = useSelector(selectCollection)
  const qaData = useSelector(getQaData)
  const { pathname } = useLocation()
  const [ filterCountry ] = useFilterCountry()
  const [ filterTax ] = useFilterTax()

  useDeepCompareEffect(() => {
    if (!nornaid) return
    const taxRate = getTaxPayload({ filterTax, region: filterCountry })
    const payload = fetchProductDetail(nornaid, comparisonQuery, qaAnnotation, metaName, toCurrency, taxRate)
    if(qaData.qaPage&&RoutesObj.SEARCH_OPTIONS.URL.startsWith(pathname)) {
      payload.query.qa_page = qaData.qaPage
    }
    if (typeof qaPage === 'boolean') {
      payload.query.qa_page = qaPage
    }
    fetch(payload.url,payload)
  }, [ comparisonQuery, nornaid, qaData.qaPage, qaPage ])

  return {
    data,
    fetch,
    error,
    loading,
  }
}

/**
 * ## useTestEnvAndQaPriv
 * 
 * 有很多内容需要在 test 环境并且有 qa 权限才显示在页面上
 * 
 * ```
 * const testEnvAndQaPriv = useTestEnvAndQaPriv()
 * ```
 */
export const useTestEnvAndQaPriv = () => {
  /**
   * Test 环境并且有 QA 权限时显示 Norna Category
   */
   const qaPermission = useQaPermission()
   return !IS_LIVE_ENV && qaPermission.includes(PERMISSIONS.QA)
}

/**
 * 获取当前登录的 vendor
 */
export const useCustomerVendor = () => {
  const { ourSeller } = useSelector(getCacheData)
  return ourSeller?.vendor
}

export const useFilterDisplayHeight = () => {
  const filterDisplayHeight = useSelector(selectFilterDisplayHeight)
  return filterDisplayHeight || 121
}

/**
 * 
 */
export const useExcludeProductsComparisonQuery = (opts?: {
  excludeCompetitorValue?: boolean;
  excludeCategoryValue?: boolean;
}) => {
  const excludeCompetitorValue = opts?.excludeCompetitorValue || false
  const excludeCategoryValue = opts?.excludeCategoryValue || false
  const excludedProductsObj = useSelector(selectExcludedProducts)
  const excludedPro = Object.values(excludedProductsObj).flat()
  const comparison = useSelector(selectComparisonQuery)
  const { comparisonQuery } = comparison
  const excludeRes = addExclude({ comparisonQuery }, excludedPro)
  const query = cloneDeep(excludeRes.comparisonQuery)
  if (excludeCompetitorValue === true) {
    query.sellers = []
  }
  if (excludeCategoryValue && query?.collection?.query?.categories) {
    query.collection.query.categories = []
  }
  return query
}

/**
 * Gate switch is true in Browse options page.
 */
export const useIsGate = () => {
  const qaData = useSelector(getQaData)
  return qaData?.qaPage
}
