import { useState } from 'react'
import { DISCOUNTS_TABLE_NAME } from 'configs'
import { UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, UPDATE_NEWCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { argFnDiscountLadderComparison } from 'graphql/nornaapi'
import { useExcludeProductsComparisonQuery, useManualLoading } from 'hooks'
import { useLookbook } from 'hooks/useLookbook'
import { useModuleData } from 'hooks/useModuleData'
import { usePageDate } from 'hooks/usePageDate'
import { useFetch } from 'libs'
import { cloneDeep } from 'lodash'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { priceFilterInDiscountsPage } from 'utils/hardCoded/discounts'
import { isSubsetPayload } from 'features/filters/moduleDataSlice'
import { isSuperArray } from 'utils/array'
import { isCompetitorGroupChanged } from 'utils/group'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { useCompetitorOptions } from 'hooks/useOptions'
import { useFilterCompetitor } from 'hooks/useFilter'
import { dateRangeUtils } from 'norna-uikit'

export const useDiscountsTableData = () => {
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { pageDate, comparisonPageDate } = usePageDate()
    const [ filterCompetitor ] = useFilterCompetitor()
    const { manualLoading, showManualLoading } = useManualLoading()
    const { checked: checkedLookbooks, isLookbook } = useLookbook()
    const { competitorOptions } = useCompetitorOptions()

    const [ moduleData, setModuleData ] = useModuleData(DISCOUNTS_TABLE_NAME)
    const {
        apiData,
        fetchPayload,
        oldCompetitorValue,
        newCompetitorValue,
    } = moduleData

    const { postFn: fetch, data, loading } = useFetch()
    const [ filteredApiData, setFilteredApiData ] = useState<any>([])

    const handleCacheData = () => {
        let newApiData = cloneDeep(apiData)
        if (!isLookbook) {
            newApiData = newApiData.filter(item => newCompetitorValue.includes(item.seller.vendor))
        }
        setFilteredApiData(newApiData)
    }

    const fetchData = () => {
        if (!competitorOptions?.length) {
            return
        }

        const query = cloneDeep(comparisonQuery)
        query.sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        query.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
        query.groups = getGroupsPayload({ competitorSellers: [ ...filterCompetitor ] })
        query.collection.query.priceFilter = priceFilterInDiscountsPage
        const payload = argFnDiscountLadderComparison(query, dateRangeUtils.from(pageDate), checkedLookbooks, dateRangeUtils.from(comparisonPageDate))

        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload }) && apiData.length) {
            showManualLoading()
            handleCacheData()
            return
        }

        setModuleData({
            type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE,
            payload: cloneDeep(payload),
        })
        fetch(payload.url, payload)
    }

    /**
     * 其它变量变化触发发送请求
     */
    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, pageDate, comparisonPageDate, checkedLookbooks, competitorOptions ])

    /**
     * Filter > Competitor 变化触发发送请求
     */
    useDeepCompareEffect(() => {
        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleCacheData()
            return
        }

        fetchData()
    }, [ newCompetitorValue ])

    /**
     * 更新 newCompetitorValue
     */
    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: { competitorValue: filterCompetitor.map(item => item.vendor) },
        })
    }, [ filterCompetitor, [] ])

    /**
     * 接口返回的数据保存一份到内存中
     */
    useDeepCompareEffect(() => {
        if (!data) return
        setModuleData({ apiData: [ ...data ] })
        setFilteredApiData([ ...data ])
    }, [ data, [] ])

    return {
        fetchData,
        setData: setFilteredApiData,
        data: filteredApiData,
        loading: loading || manualLoading,
    }
}
