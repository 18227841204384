import { cloneDeep } from 'lodash'
import { arrayUtils } from 'norna-uikit'
import { getCompetitorOptions, getRegionOptions } from 'utils/optionsUtils'

export function formatMetricLabel(label: string) {
    if (label.toLowerCase().includes(' sold out')) {
        return label.replace(' sold out', '')
    }
    return label
}

export function sortMetricLabel(metricValue: string[], region: string) {
    const regionList = getRegionOptions().map(item => item.key)
    if (arrayUtils.isSuperset(regionList, metricValue)) {
        const newMetricValue = cloneDeep(metricValue).sort((m,n) => {
            const firstValue = regionList.indexOf(m)
            const secondValue = regionList.indexOf(n)
            return firstValue - secondValue
        })
        return newMetricValue
    }

    const vendorList = getCompetitorOptions({ region, excludeMarket: true, excludeSelectedVendor: true }).map(item => item.key)
    const newMetricValue = cloneDeep(metricValue)
        .sort((a, b) => {
            const aIndex = vendorList.findIndex(item => item === a)
            const bIndex = vendorList.findIndex(item => item === b)
            return aIndex - bIndex
        })
    return newMetricValue
}
