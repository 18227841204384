import React, { FC, CSSProperties, memo, useState, useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import classnames from 'classnames'
import CalendarDatePicker, { DayValue, DayRange } from 'react-modern-calendar-datepicker'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Button } from 'components/form-elements/Button'
import { getDate, getMonth, getYear } from 'date-fns'
import { useSelector } from 'react-redux'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { formatDatePickerToDate, getDateObjByStr, getMinimumDate } from 'utils/dateUtils'
import { getOneLatestWeekAgoDateRange, urlRange, todayRange, parseDateRangePickerValue } from './utils'
import { DatePickerProps, DatePickerType } from './types'
import styles from './styles.module.scss'

/**
 * ## DatePicker
 * 
 * 日期选择器组件。
 * 
 * ## 示例
 * 
 * ### 基础使用
 * 
 * ```
 * <DatePicker />
 * ```
 * 
 * ### 日期范围选择器
 * 
 * ```
 * <DatePicker type="dateRangePicker" />
 * ```
 * 
 * ### 设置选中值以及监听值变化：value、onChange
 * 
 * - value 值格式为：2021-09-13
 * - onChange 回调函数的参数 value 格式为：2021-09-13
 * 
 * ```
 * const [dateValue, setDateValue] = useState('')
 *
 * <DateRangePicker
 *    value={dateValue}
 *    onChange={
 *      useCallback(value => {
 *        setDateValue(value)
 *      }, [])
 *    }
 * />
 * ```
 */
export const DatePicker: FC<DatePickerProps> = memo(({
    wrapperClassName = '',
    customRenderFn,
    renderPostion,
    style = {},
    left,
    right,
    minimumDate,          // 最小日期，格式 {day: 1, month: 8, year: 2020 }
    maximumDate,          // 最大日期，格式 格式 {day: 1, month: 8, year: 2020 }，默认值当天
    value,                // 设置日期段下拉框选中值，格式：2021-09-13_2021-09-20
    onChange,             // 日期下拉框值变化时触发事件，回调函数参数 value 格式：2021-09-13_2021-09-20
    type = DatePickerType.DATE,
    renderCustomInput,
    defaultValue,         // 默认值，格式为：2021-09-13_2021-09-20，点击 Restart to default 按钮时设置该值
    inputValue,
    inputStyle,
    inputClassName,
    disabled=false,
}) => {
    // 默认最小日期，2020-08-01 这是个什么日子？公司创建日？？
    const defaultMinimumDate = getDateObjByStr(getMinimumDate()) || {
        day: 1,
        month: 8,
        year: 2020,
    }

    // 默认最大日期，latestDate 当天，latestDate 是服务端传回的日期，含义是有数据的，离今天最近一天的日期
    const defaultMaximumDate = todayRange().from

    // 默认样式
    const dateStyle: CSSProperties = {}

    if (typeof left !== 'undefined') {
        dateStyle.marginLeft = typeof left === 'number' ? left + 'px' : left
    }

    if (typeof right !== 'undefined') {
        dateStyle.marginRight = typeof right === 'number' ? right + 'px' : right
    }

    useEffect(() => {
        if (type === 'datePicker') {
            onDatePickerInitValue(value)
        } else {
            onDateRangePickerInitValue(value)
        }
        // eslint-disable-next-line
    }, [value])

    /* ************************** DatePicker ********************************** */
    // CalendarDatePicker 接收的值 { year: 2021, month: 10, day: 5 }
    const [ datePickerValue, setDatePickerValue ] = useState<DayValue>()
    // 格式化的日期值 2021-10-05
    const [ datePickerText, setDatePickerText ] = useState<string>(defaultValue || '')

    // 监听 datePickerValue 值变化，自动生成 datePickerText 值
    useEffect(() => {
        if (datePickerValue) {
            setDatePickerText(formatDatePickerToDate(datePickerValue))
        }
    }, [ datePickerValue ])

    // DatePicker 文本框
    const renderDatePickerInput = ({ ref }) =>
        <input
            readOnly
            disabled={disabled}
            ref={ref}
            className={classnames(styles.datePickerSelector, disabled?'':'cell-mouse-effect-no-display-no-within', disabled?styles.disabled:'')}
            style={{ ...dateStyle, ...style }}
            value={datePickerText}
        />

    // 初始化 DatePicker 值
    const onDatePickerInitValue = (value?: string) => {
        if (typeof value === undefined) {
            setDatePickerValue(todayRange().from)
        } else {
            const date = new Date(value as string)
            setDatePickerValue({
                day: getDate(date),
                month: getMonth(date) + 1,
                year: getYear(date),
            })
        }
    }

    const onDatePickerChange = (values: DayValue) => {
        setDatePickerValue(values)
        onChange?.(formatDatePickerToDate(values))
    }

    /* ************************** DateRangePicker ********************************** */
    // 日期范围下拉框接收的值 { from: { year: 2021, month: 10, day: 5 }, to: { year: 2021, month: 10, day: 5 } }
    const [ dateRangePickerValue, setDateRangePickerValue ] = useState<DayRange>(getOneLatestWeekAgoDateRange())
    // 日期范围下拉框格式化的日期值 2021-10-05_2021-10-05
    const [ dateRangePickerText, setDateRangePickerText ] = useState<string>(' / ')

    // 监听 dateRangePickerValue 值变化，自动生成 dateRangePickerText 值
    useEffect(() => {
        if (dateRangePickerValue) {
            const placeholderFrom = dateRangePickerValue.from && formatDatePickerToDate(dateRangePickerValue.from)
            const placeholderTo = dateRangePickerValue.to ? formatDatePickerToDate(dateRangePickerValue.to) : ''
            setDateRangePickerText(`${placeholderFrom} / ${placeholderTo}`)
        }
    }, [ dateRangePickerValue ])

    // DateRangePicker 文本框
    const renderDateRangePickerInput = ({ ref }) =>
        <input
            readOnly
            ref={ref}
            className={classnames([ styles.dateRangePickerSelector, inputClassName ])}
            style={{ ...dateStyle, ...style, ...inputStyle }}
            value={inputValue || dateRangePickerText}
        />

    const renderDateRangePickerFooter = () => (
        <div className={styles.resetButtonContainer}>
            <Button onClick={() => {
                const value = defaultValue ? parseDateRangePickerValue(defaultValue) : getOneLatestWeekAgoDateRange()
                setDateRangePickerValue(value)
                const dateRangeValue = formatDatePickerToDate(value.from) + '_' + formatDatePickerToDate(value.to)
                onChange?.(dateRangeValue)
            }}
            >
                Reset to default
            </Button>
        </div>
    )

    // 初始化日期范围下拉框值
    const onDateRangePickerInitValue = (value?: string) => {
        if (typeof value === 'string' && value) {
            setDateRangePickerValue(urlRange(value))
        }
    }

    const onDateRangePickerChange = (values: DayRange) => {
        if (values?.from && values?.to) {
            const dateRangeValue = formatDatePickerToDate(values.from) + '_' + formatDatePickerToDate(values.to)
            onChange?.(dateRangeValue)
        }
        setDateRangePickerValue(values)
    }
   
    // customize input 
    let inputHtml = type === 'datePicker' ? renderDatePickerInput : renderDateRangePickerInput
    if(renderCustomInput) inputHtml = renderCustomInput

    return (
        <CalendarDatePicker
            wrapperClassName={wrapperClassName}
            customRenderFn={customRenderFn}
            calendarPopperPosition="bottom"
            value={type === 'datePicker' ? datePickerValue as any : dateRangePickerValue}
            onChange={(values: DayValue | DayRange) => {
                if (type === 'datePicker') {
                    onDatePickerChange(values as DayValue)
                } else {
                    onDateRangePickerChange(values as DayRange)
                }
            }}
            colorPrimary="#01a699"
            colorPrimaryLight="rgba(1, 166, 153, 0.5)"
            renderInput={inputHtml}
            renderFooter={type === 'datePicker' ? () => null : renderDateRangePickerFooter}
            renderPostion={renderPostion}
            minimumDate={minimumDate || defaultMinimumDate}
            maximumDate={maximumDate || defaultMaximumDate}
        />
    )
})

DatePicker.displayName = 'DatePicker'

export const useMinimumDate = () => {
    /**
     * 2022/03/29 需求:
     * license 为 pop 时，设置日期选择框最小为 2021-11-01
     */
    const cacheData = useSelector(getCacheData)
    const [ minimumDate, setMinimumDate ] = useState<any>(null)
    useDeepCompareEffect(() => {
        // polarnopyret
        if (cacheData?.account?.company?.code === 'polarnopyret') {
            setMinimumDate({
                day: 1,
                month: 11,
                year: 2021,
            })
        }
        // eslint-disable-next-line
    }, [cacheData])

    return minimumDate
}
