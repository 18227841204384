import React from 'react'
import Modal from 'componentsv2/Modal';
import Graph from '../Graph';
import { useDashboardLineGraphData2 } from 'hooks/api/useDashboardLineGraphData';

const GraphModal = ({
    onClose,
}: {
    onClose?: () => void;
}) => {
    useDashboardLineGraphData2()

    return (
        <Modal
            style={{ width: window.innerWidth - 80, height: window.innerHeight - 30, zIndex: 9999999 }}
            left={40}
            className="sold-out-analytics-graph-modal"
            onCancel={onClose}
        >
            <Graph />
        </Modal>
    )
}

export default GraphModal
