import { v4 as getNS, v5 as uuidNameSpace } from 'uuid'
import { ALL_VENDORS, IS_LIVE_ENV, MARKET_VENDOR, OPTIONS_IN_NAME, OPTIONS_NAME, OPTIONS_OUT_NAME, PRICE_FILTER, VendorShortMapping } from 'consts/index'
import { isDashboardPages, RoutesObj } from '../global/Routes'
import { colorJson, vendorToColorList } from './colorJson'
import { storage } from './storage'
import { getMaxCategoryCount, getRegionAlias, isPriceWithTwoDecimal } from './feConfig'

export * from './sortCategory'
export * from './ls'
export * from './competitors'
export { formatProductName } from './product'

const RequestNameSpace = getNS()
export const isLookbookFn = () => {
  const path = window.location.pathname

  const isLookbook = !!path.includes(RoutesObj.LOOKBOOK.URL)
  return isLookbook
}
export function downloadFile(fileName: string, content, o = true) {
  if (o) {
    content = '\ufeff' + content
  }
  const aTag = document.createElement('a')
  var blob = new Blob([content]); // eslint-disable-line
  aTag.download = fileName
  aTag.href = URL.createObjectURL(blob)
  aTag.click()
  URL.revokeObjectURL(blob.toString()); // eslint-disable-line
}

export function genUniqueUrlStr(config, requestId = '') {
  if (!config) return '-'
  const data: any = config || {}
  const datas: Array<any> = []
  Object.keys(data).forEach((key: any) => {
    if (typeof data[key] === 'object') {
      Object.keys(data[key]).forEach(elKey => {
        if (elKey !== '__typename') {
          datas.push(`${elKey}=${data[key][elKey]}`)
        }
      })
    } else if (key !== '__typename') {
      datas.push(`${key}=${data[key]}`)
    }
  })
  const key = [ requestId, datas.sort().join(',') ].join('-')
  return key
}

export function genRequestTrackerId(vars) {
  return { requestTrackerId: uuidNameSpace(genUniqueUrlStr(vars, 'GROUP_INFO'), RequestNameSpace) }
}

/**
 * add date dot
 * date:YYYYMMDD - YYYYMMDD
 */
export function addDateDot(date: string): Array<string> {
  if (!date) return [ date ]
  // date.split('')
  return date
    .replace(/\s/gi, '')
    .split('-')
    .map(item => [ item.slice(0, 4), item.slice(4, 6), item.slice(6) ].join('.'))
}

/**
 * add date dot
 * date:YYMMDD - YYMMDD - > ['YY.MM.DD','YY.MM.DD']
 */
export function addDateDotSpliter(date: string): Array<string> {
  if (!date) return [ date ]
  // date.split('')
  return date
    .replace(/\s/gi, '')
    .split('-')
    .map(item => [ item.slice(0, 2), item.slice(2, 4), item.slice(4) ].join('.'))
}

export function formatterDateToEng(date: Array<string>) {
  const startDate = new Date(date[0]).toString().split(' ')
  const endDate = new Date(date[1]).toString().split(' ')
  return (
    startDate[0] +
    ' ' +
    startDate[2] +
    ' ' +
    startDate[1] +
    '-' +
    endDate[0] +
    ' ' +
    endDate[2] +
    ' ' +
    endDate[1]
  )
}
/**
 * prepend zero
 * @param num
 */
export function prependZero(num: number): string {
  if (num < 10) return '0' + num
  return `${num}`
}

export function formatWeekLabel(num: string): string {
  if (!num) return num
  return num.replace('W.', 'Week')
}

export function toThou(str, spliter?: string) {
  const reg = /(\d)(?=(?:\d{3})+\b)/g
  return `${str}`.replace(reg, '$1' + (spliter || ' '))
}

export function arrayPluckObj(arr, keyField, valueField) {
  return arr.reduce((obj, current) => {
    obj[current[keyField]] = valueField ? current[valueField] || '' : current
    return obj
  }, {})
}

/**
 * format ComparisonQuery
 * @param selectComparisonQuery
 */
export function formatQuery(selectComparisonQuery: any) {
  const query = JSON.parse(
    JSON.stringify(
      selectComparisonQuery.comparisonQuery
        ? selectComparisonQuery.comparisonQuery
        : selectComparisonQuery,
    ),
  )
  return query
}

export const formatterColor = (color: string) => {
  const colorString = color.toLowerCase().replace(/\s*/g, '')
  // return colorString
  const colorArray = colorJson()
  const colorObj: any = colorArray.find(arr => arr.find(e => e === colorString))
  if (colorObj) return colorObj[2]
  return colorString
}
/**
 * colors default
 * @param idx  index number
 * @returns 
 */
export const getColorForTimeSeriew = (idx: any) => {
  const colors = vendorToColorList
  // [
  //   //Old Color
  //   '#559492',
  //   '#ff6900',
  //   '#ffb900',
  //   '#9b608c',
  //   '#6e6296',
  //   '#999999',
  //   '#f50001',
  //   '#666666',
  //   '#fd6f5e',
  //   '#fd6f5e',
  //   '#00a999',
  // ];
  if (idx > colors.length) {
    return colors[0]
  }
  return colors[idx]
}

const Metric = {}

/**
 * color of metric generator
 * every metric should has unqiue color value
 * 
 * @param metric 
 * @returns 
 */
export const getColorForMetric = (metric: string) => {
  if (Metric[metric]) { return Metric[metric] }

  Metric[metric] = vendorToColorList[Object.keys(Metric).length]
  return Metric[metric]
}

/**
 * ## `Vendor` 界面显示缩写映射函数
 */
export const vendorShortMappingFn = (vendorLabel = ''): string => {
  if (vendorLabel.indexOf('El Palacio de Hierro') > -1) {
    return vendorLabel.replace('El Palacio de Hierro', VendorShortMapping['El Palacio de Hierro'])
  }
  return VendorShortMapping[vendorLabel] ?? vendorLabel
}

/**
 * ## `Region` 界面显示缩写映射函数
 */
export const regionShortMappingFn = (regionLabel: string): string => getRegionAlias(regionLabel)

/**
 *  弹框标题后面追加 `@ Price Filter`
 * @param title 
 * @param priceFilterVal 
 * @returns 
 */
export const appendDialogTitlePriceFilter = (title: string, priceFilterVal: string) => `${title} @ ${PRICE_FILTER[priceFilterVal]}`

/* ********************************* added methods ************************************* */

export const getCurrencyByRegion = (region: string): string => {
  const regionToCurrency = storage.getRegionToCurrency()
  const configs = storage.getConfigs()
  return regionToCurrency[region] || configs?.currencys?.[0] || ''
}

export const getCodeAndNameMapperBySellers = (sellers: {currency: string; launch_date: string; name: string; region: string; vendor: string;}[]) => {
  const codenames = {}
  const namecodes = {}
  sellers.forEach(seller => {
    codenames[seller.vendor] = seller.name
    namecodes[seller.name] = seller.vendor
  })
  codenames[MARKET_VENDOR.vendor] = ALL_VENDORS
  namecodes[ALL_VENDORS] = MARKET_VENDOR.vendor
  return { codenames, namecodes }
}

export const getVendorNameByCode = (code: string) => {
  const codenames = storage.getCodenames()
  return codenames[code] || code
}

export const getVendorCodeByName = (name: string) => {
  const namecodes = storage.getNamecodes()
  return namecodes[name] || name
}

export function getFractionDigits(defaultFractionDigits = 0) {
  const customerVendor = storage.getCustomerVendor()
  if (isPriceWithTwoDecimal()) {
    return 2
  }
  if ([ 'bimbaylola' ].includes(customerVendor)) {
    return 0
  }
  return defaultFractionDigits
}

export const getCategoryValueByLabel = (categoryList: string[]): string[] => {
  const categoryTreeList = storage.getCategoryTreeList()
  const result = categoryList.map(category => {
    const c = categoryTreeList.find(item => item.name === category)
    if (c?.list?.length) {
      return c.list.map(i => i.name)
    }
    return category
  }).flat(10)
  return result
}

export const handleLeftAndRight = (value?: number | boolean) => {
  if (typeof value === 'boolean') {
    return value === true ? 10 : 0
  }
  return value || 0
}

export const getExportId = value => value.replace(/\s+/g, '_').replace(/&/g, '')
export const getExportSelector = value => `#${getExportId(value)}`
export const getExportFilename = getExportId

export const getTableTitleTooltip = (title: string) => {
  const tooltips = {
    [OPTIONS_NAME]: 'The average number of options in the marketplace during the selected time period. ',
    Category: 'The product category. ',
    'Average price': 'The average price per option during the selected time period. ',
    'Avg price In': 'The average price per option during the selected time period. ',
    'Average price In': 'The average price per option during the selected time period. ',
    
    Index: 'The price index of each competitor or competitor category, compared to yourself or your corresponding category (you = index 100%). ',
    'Median price': 'The median price for all options for the selected time period in the category. ',
    'Frequent price': 'The most frequently used price point during the selected time period. ',
    'Discount Width': 'The share of the assortment that was on a discount during the selected time period. The definition of Discount depends on the price perspective that you have selected, as the Discount measures the delta between the price perspective metric and the full price of each individual product, as well as on an aggregated level. ',
    'Discount Depth': 'The average discount (for options with a discount) during the selected time period. The definition of Discount depends on the price perspective that you have selected, as the Discount measures the delta between the price perspective metric and the full price of each individual product, as well as on an aggregated level. ',
    'Discount Effect': 'The discount effect measures the average price reduction effect on the full assortment. If the products in the selection have an average sales price of 300 and an average full price of 400, then the discount effect is 25% using (400-300)/400. ',

    'Category split': 'The distribution of the assortment in percentage.',
    'Size of line': 'The relative assortment size of each vendor or vendor category compared to the average assortment size of your competitors. If your size of line is 20%, it means that your assortment size is 20% of the average assortment size of your competitors.',
    'Size ratio': 'The average number of assortment sizes per vendor and/or category during the selected time period. ',
    'Option ratio': 'The average number of variations/articles per product during the selected time period. ',
    'Total sold out': 'The share of the assortment that was out of stock during the selected time period. ',
    'Eco label': 'The share of the assortment that had an eco label during the selected time period. ',
    Solid: 'The share of the assortment that had solid Colors during the selected time period. ',

    'Low price': 'The lowest price observed for an option during the selected time period. ',
    'Avg price': 'The average price per option during the selected time period. ',
    'High price': 'The highest price observed for an option during the selected time period. ',
    'Low %': 'A price index (you=100%), comparing the Low price of yourself to the Low price of your competitors during the selected time period. ',
    'Avg %': 'A price index (you=100%), comparing the Average price of yourself to the Average price of your competitors during the selected time period. ',
    'High %': 'A price index (you=100%), comparing the High price of yourself to the High price of your competitors during the selected time period. ',
    'Price spread': 'A visualization of of the price range and the average price during the selected time period. ',

    [OPTIONS_IN_NAME]: 'The number of options introduced to the marketplace during the selected time period. ',
    [OPTIONS_OUT_NAME]: 'The number of options removed from the marketplace during the selected time period. ',
    Rotation: 'The share of options introduced and removed, compared to the average number of options during the selected time period. ',

    doughnutDisDepth: 'The average discount (for options with a discount) during the selected time period.',
    doughnutDisWidth: 'The share of the assortment that was on a discount during the selected time period.',
    doughnutPriceChangePercent: 'The relative average price change from the start to the end of the selected time period.',
    'Harmonized sold out': 'By harmonizing sizes, Norna converts a number of base sizes (40, 42, 44 et al) into an alphanumerical system. This is done to allow deeper analytics of the sold out situation also on a harmonized size level. However, by doing so the number of bases sizes are in fact reduced, which affects the metrics compared to a raw sold out metric where harmonization has not been done. For detailed information please contact your team at Norna',
    'Sold out 1': 'The Norna Sold out 1 metric measures the sold-out situation based on the availability of the existing base sizes and sizing systems that vendors use for their various products. The metric shows the share of the assortment that was out of stock during the selected time period, without any special blending and without any harmonization of size systems.',
    
    'Sold out': 'The sold out metric measures the sold-out situation based on the availability of the existing base sizes and sizing systems that vendors use for their various products. The metric shows the share of the assortment that was out of stock during the selected time period, without any special blending and without any harmonization of size systems. <br/><br/> The sold out situation for an individual product is updated on average once per week with different products being updated on different dates. As such the aggregated sold-out situation is updated daily, with a 0-7 days time lag for individual products.',
    
    'Sold out 2': 'The Norna Sold out 2 metric measures the sold-out situation through a process where the different base sizes that vendors use (41, 42, 52, 16, 16,5 et cetera) are harmonized into an alphanumerical/international system, and where the fraction of harmonized out of stock sizes determines the sold-out ratio without any special blending. The purpose of the Sold out 2 metric is to allow for true sold-out comparisons between vendors, categories, and regions that (indeed) use different size systems.The difference to the Norna Sold out 1 metric is that the Sold out 1 metric does not involve any size harmonization, which is a process that reduces the number of sizes as several base sizes might be grouped into one alphanumerical size. As the number of sizes are the denominator of sold-out calculations, calculations will be affected by this grouping process, making the Norna Sold out 1 and 2 metrics not directly comparable.For detailed information please contact your team at Norna',

    '# Unique options': 'The number of unique options in the marketplace during the selected time period.',
    'Rotation ratio': 'A metric for the rotation of the assortment during the selected time period. The metric measures how many unique products that existed in the market during the selected time period, as compared to the average number of products that existed during the same time period. If the unique product count was high, but the daily average count was low, then the assortment rotated heavily.',
    'Lifespan, days': 'Shows how many days the selected products have been present on the market at the end of the selected time period, as compared to when they were either introduced or reintroduced.',
  }
  return tooltips[title] || title
}

export function getColorByValue(value: number) {
  let color = '#484848'
  if (value < 0) {
    color = '#E22F21'
  } else if (value === 0) {
    color = '#484848'
  } else {
    color = '#01A699'
  }
  return color
}

export const getFilterCategoryMaxCountInLive = () => {
  if (!isDashboardPages()) {
    return getMaxCategoryCount()
  }
  return 999999
}

export const isCustomerVendor = (vendor: string) => {
  return vendor === storage.getCustomerVendor()
}

export const isNornaMember = () => {
  const email = localStorage.getItem('email') || ''
  return email.endsWith('norna.ai')
}

export function getSymbolFromCurrency(currency: string) {
  const mapper = {
      EUR: '€',
      GBP: '£',
      USD: '$',
  }
  return mapper[currency] || currency
}

export const Colors = {
  Camel: '#C1A36D',
  'Light Beige': '#FDF7A9',
  Beige: '#CFBF65',
  'Dark Beige': '#A48B48',
  'Medium Beige': '#CCBD46',
  'Light Grey': '#A3B1B8',
  Grey: '#71828F',
  'Dark Grey': '#657684',
  Black: '#080722',
  White: '#FEFEED',
  'Dark Red': '#94121D',
  'Light Red': '#FE5C5F',
  'Medium Red': '#E22E31',
  Red: '#C6282C',
  Turquoise: '#39B993',
  'Dark Turquoise': '#147174',
  'Medium Turquoise': '#29B28C',
  'Light Turquoise': '#6CF4B6',
  'Medium Brown': '#A66C1D',
  'Dark Brown': '#782A0C',
  'Light Brown': '#C69455',
  Brown: '#9D5D1F',
  'Medium Green': '#51B843',
  Green: '#64B94E',
  'Light Green': '#94ED74',
  'Dark Green': '#3A6728',
  'Light Orange': '#FCC068',
  Orange: '#F28935',
  'Medium Orange': '#F48F2F',
  'Dark Orange': '#E64C1A',
  'Medium Blue': '#337EBD',
  'Dark Blue': '#1A2D92',
  'Light Blue': '#7EEAE3',
  Blue: '#4186B8',
  'Dark Purple': '#671478',
  'Medium Puple': '#9A42BC',
  Purple: '#9143AC',
  'Light Purple': '#BF90E7',
  'Dark Yellow': '#E9D427',
  'Light Yellow': '#FCFB80',
  Yellow: '#F3EA58',
  'Dark Pink': '#E02A81',
  Pink: '#E86299',
  'Light Pink': '#F3A6C2',
  'Medium Pink': '#EC5E96',
  Others: '#41BDB3',
  'Multi-color': '#41BDB3',
  'Multi-material': '#41BDB3',
}
