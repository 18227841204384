import { getDisplayCategoryValue } from 'components/layout/Header/components/HeaderFilter/category.util'
import { getGendersFilterTagValue, getLaunchedAfterFilterTagValue, getMaterialFilterTagValue, getPackFilterValue, getPricePerspectiveValue, getPriceRangeFilterTagValue, getPropertiesFilterTagValue, getSizeFilterTagValue, getSortByFilterTagValue, getTaxFilterTagValue } from 'componentsv2/MenuBar/utils'
import { FILTER_LABELS } from 'componentsv2/Tree/utils'
import { Collection } from 'features/filters/collectionDataSlice.types'
import { getVendorNameByCode } from 'utils'

export function formatCollection(collection: Collection) {
    const { query, comparisons } = collection

    const categoryValue = getDisplayCategoryValue(query?.categories).join(', ')
    const regionValue = query?.regions?.key
    const currencyValue = query?.regions?.currency
    const competitorValue = comparisons[0]?.sellers?.map(s => getVendorNameByCode(s?.vendor) || '')?.filter(c => c)?.join(', ')
    const sizeFilterValue = getSizeFilterTagValue(query?.sizeFilter).replace(`${FILTER_LABELS.SIZE_FILTER}: `, '')

    return {
        categoryValue,
        regionValue,
        currencyValue,
        competitorValue,
        sortByValue: getSortByFilterTagValue(query.sortField)?.replace(`${FILTER_LABELS.SORT_BY}: `, ''),
        priceRangeFilterValue: getPriceRangeFilterTagValue(query?.priceRange).replace('Price range: ', ''),
        launchedAfterValue: getLaunchedAfterFilterTagValue(query?.earliestLaunchDate).replace(`${FILTER_LABELS.LAUNCHED_AFTER}: `, ''),
        sizeFilterValue,
        taxFilterValue: getTaxFilterTagValue(query?.taxFilter).replace(`${FILTER_LABELS.TAX}: `, ''),
        materialValue: getMaterialFilterTagValue(query?.materialFilter).replace(`${FILTER_LABELS.MATERIAL_FILTER}: `, ''),
        genderValue: getGendersFilterTagValue(query?.targetGroups, sizeFilterValue).join(', '),
        propertyValue: getPropertiesFilterTagValue(query?.properties).join(', '),
        pricePerspectiveValue: getPricePerspectiveValue(query?.priceFilter?.label).replace('Price: ', ''),
        packFilterValue: getPackFilterValue(query?.packFilter),
    }
}
