import { useState } from 'react'
import { useCompetitorValue } from './useDynamicDashbordData'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getVendorNameByCode } from 'utils'

export const useCompetitorNavLabel = () => {
    const [ competitorValue ] = useCompetitorValue()
    const [ competitorLabel, setCompetitorLabel ] = useState('')

    useDeepCompareEffect(() => {
        if (competitorValue.length === 0) {
            setCompetitorLabel('')
            return
        }
        if (competitorValue.length === 1) {
            setCompetitorLabel(getVendorNameByCode(competitorValue[0]))
            return
        }
        const vendorNames = competitorValue.map(c => getVendorNameByCode(c))
        setCompetitorLabel(`Competitors: ${vendorNames.join(', ')}`)
    }, [ competitorValue, [] ])

    return competitorLabel
}
