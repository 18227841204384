import { useEffect } from 'react'
import { useFetch } from 'libs/hookRequest'
import { REQUEST_GET_LOOKBOOK_PRODUCT_INFO } from 'consts/request'

export const useGetLookbookproductInfo = (id: string) => {
  const { data, loading, error, getFn: refetch } = useFetch()
  useEffect(() => {
    if (id) {
      refetch(`${REQUEST_GET_LOOKBOOK_PRODUCT_INFO}`, { id })
    }
  }, [id]);//eslint-disable-line
  return {
    data: data || undefined,
    loading,
    error,
    refetch: async () => {
      await refetch(`${REQUEST_GET_LOOKBOOK_PRODUCT_INFO}`, { id })
    },
  }
}
