import React from 'react'
import { ProductCard, SizePriceProductCard } from 'componentsv2/business/ProductCard'
import { getOnlyShowSizeProductsForCompetitors, isSizePriceProductCard } from 'utils/feConfig'
import { ProductDetailsModalTypes } from './types'

export const ProductDetailsModal = ({
  nornaid = '',
  closeProductDetails,
  toCurrency,
  enabledQa,
  qaPage,
  vendorCode = '',
}: ProductDetailsModalTypes) => {
  const vendors = getOnlyShowSizeProductsForCompetitors()
  
  if (vendors.includes(vendorCode) || isSizePriceProductCard()) {
    return (
      <SizePriceProductCard 
        nornaid={nornaid} 
        toCurrency={toCurrency} 
        closeProductDetails={closeProductDetails} 
        enabledQa={enabledQa}
      />
    )
  }
  
  return (
    <ProductCard
      nornaid={nornaid} 
      toCurrency={toCurrency} 
      closeProductDetails={closeProductDetails} 
      enabledQa={enabledQa} 
      qaPage={qaPage}
    />
  )
}

