import React from 'react'
import classnames from 'classnames'
import { Doughnut } from 'libs/react-chartjs-lib'
import { NornaDoughnutChartProps } from './types'
import styles from './styles.module.scss'

export const NornaDoughnutChart = ({
    style = {},
    className,
    content = '',       // 饼图中间文字
    contentFontSize,
    value = 0,          // 饼图占比，0-100
    size = 300,
}: NornaDoughnutChartProps) => (
        <div className={classnames([ styles.nornaDoughnut, className ])} style={{ ...style, width: size + 'px', height: size + 'px' }}>
            <div className={styles.doughnutWrapper}>
                <Doughnut
                    data={{
                        datasets: [
                            {
                                backgroundColor: [ '#01A699', '#e3e5e8' ],
                                borderWidth: 0,
                                data: [ value, 100 - value ],
                            },
                        ],
                    }}
                    options={{
                        tooltips: {
                            display: false,
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        cutoutPercentage: 72,
                        title: { display: false },
                        animation: {
                            duration: 1,
                        },
                        legend: { display: false },
                        scales: {
                            ticks: 5,
                        },
                    }}
                />
                <div className={styles.content} style={{ fontSize: contentFontSize || size * 0.1 + 'px' }}>
                    {content}
                </div>
            </div>
            <div className={styles.bigCircle} />
            <div className={styles.smallCircle} />
        </div>
    )

NornaDoughnutChart.displayName = 'NornaDoughnutChart'
