import { useCallback } from 'react'
import { useFlatQueryObj } from 'hooks'
import { appendDialogTitlePriceFilter } from '../utils/index'

export default function useDialogTitle() {
    const { comparisonQuery } = useFlatQueryObj()
    const { query } = comparisonQuery.collection
    return {
        fnUseAppendDialogTitlePriceFilter: useCallback((title: string) => appendDialogTitlePriceFilter(title, query.priceFilter.val), [ query ]),
    }
}
