export const colors = [
    '#74B6B6',
    '#FF7634',
    '#FFBB21',
]

export function getMaxTick(data, yAxesMaxFixed = false) {
    // 1000 以内 50
	// 1000 以上 500
	if (!Array.isArray(data) || !data?.length || yAxesMaxFixed) return 100

	const marginTop = 0.3		// 图形上边留白
	const mValue = Math.max(...data.map(d => d.value)) || 0.1	// 数据里最大数值
	const yMValue = mValue / (1 - marginTop)	// 计算 y 轴最大值

	return getResult(yMValue)
}

function getResult(value) {
	if (value < 4) {
		let data = Number(value.toFixed(1))
		for (let i = 0; i < 1000; i++) {
			if ([ 0.2, 0.4, 0.8, 1.2, 1.6, 2.0, 2.4, 2.8, 3.2, 4 ].includes(data)) {
				break
			}
			data = Number((data + 0.1).toFixed(1))
		}
		return data
	}

	let v = 4
	if (value < 100) {
		v = 4
	}
	else if (value < 1000) {
		v = 40
	}
	else if (value < 10000) {
		v = 400
	} 
	else if (value < 100000) {
		v = 4000
	}
	else {
		v = 40000
	}
	let data = Math.ceil(value)
	for (let i = 0; i < 1000000; i++) {
		let s = (data / v).toString()

		if (
			[ 4, 6, 9, 20, 24, 28, 32, 36 ].includes(data)
			|| (!s.includes('.') && s.endsWith('0')) 
			|| (!s.includes('.') && s.endsWith('5'))
		) {
			break
		}

		data = data + 1
	}
	return data
}
