import React, { useState, useCallback } from 'react'
import { Input } from 'components/form-elements/Input'
import { Button } from 'components/form-elements/Button'
import { Form } from 'components/form-elements/Form'
import { Label } from 'components/form-elements/Label'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Message } from 'components/form-elements/Message'
import { typeUtils } from 'norna-uikit'
import { useAuthenticationDataContext } from 'components/guards/AuthenticationContext'
import styles from './styles.module.scss'
import { ForgotPasswordFormProps } from './types'

export const ForgotPasswordForm = ({ onResetPassword }: ForgotPasswordFormProps) => {
  const [ email, setEmail ] = useState('')
  const [ invalidEmail, setInvalidEmail ]: any = useState(false)
  const { logIn } = useAuthenticationDataContext()

  const emailValidation = useCallback(() => {
    if (!typeUtils.isEmail(email)) {
      setInvalidEmail(true)
    } else {
      setInvalidEmail(false)
    }
  }, [ email ])

  const resetPassword = useCallback(() => {
    if (typeUtils.isEmail(email)) {
      onResetPassword({ query: { email: email.trim() } })
    }
  }, [ email, onResetPassword ])

  return (
      <Form onSubmit={resetPassword}>
          <ShouldRender shouldRender={invalidEmail}>
              <Message message="Please enter a valid email address." error />
          </ShouldRender>
          <div>
              <Label required label="Email">
                  <Input value={email} onChange={setEmail} />
              </Label>

              <div />
          </div>

          <div className={styles['forgot-password-actions']}>
              <Button primary type="submit" onClick={emailValidation}>
                  RESET PASSWORD
              </Button>
              <Button secondary onClick={logIn}>
                  CANCEL
              </Button>
          </div>
      </Form>
  )
}
