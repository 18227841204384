import React, { createContext, useContext, useEffect, useState } from 'react'
import AuthToken from 'utils/AuthToken'
import { useHistory } from 'react-router-dom'
import { RoutesObj } from 'global/Routes'
import { typeUtils } from 'norna-uikit'
import { useResetPassword } from 'graphql/authentication/resetPassword'
import { lsRm } from 'utils/ls'
import { CURRENT_SWITCHED_LICENSE } from 'consts'
import { AuthenticationContextType } from './types'

export const AuthenticationContext = createContext<AuthenticationContextType>({
  authData: {},
  user: {},
  setUser: () => {},
  publicRoute: '',
  res: {},
  resetPasswordMsg: '',
  resetPassword: () => {
  },
  onLogout: () => {
  },
  onLogin: () => {
  },
  logIn: () => {
  },
  onForgotPassword: () => {
  },
  setPublicRoute: () => {
  },
})

const AuthenticationDataProvider = (props: any) => {

  const history = useHistory()
  const [ authData, setAuthData ] = useState({})
  // const [validateToken, { error }] = useValidateToken();
  const [ publicRoute, setPublicRoute ] = useState('')
  const [ resetPasswordMsg, setResetPasswordMsg ] = useState('')
  const [ user, setUser ] = useState(AuthToken.getUserFromToken())
  // const idToken = AuthToken.getIdToken();
  const [ resetPassword, res ] = useResetPassword()
  // useEffect(() => {
  //   if (idToken) {
  //     validateToken();
  //   }
  // }, [history, idToken, validateToken]);

  useEffect(() => {
    const currentAuthData = AuthToken.getIdToken()

    if (currentAuthData) {
      setAuthData(currentAuthData)
    }
  }, [ history, user ])

  // useEffect(() => {
  //   if (error) {
  //     AuthToken.removeAuthTokens();
  //     history.push(Routes.LOGIN);
  //   }
  // }, [error, history]);

  useEffect(() => {

    if (res) {
      setResetPasswordMsg('We\'ve just sent you an email to reset your password.')
      history.push(RoutesObj.LOGIN.URL)
      setPublicRoute(RoutesObj.LOGIN.URL)
    }
  }, [ res, setPublicRoute]); //eslint-disable-line

  const onLogout = () => {
    AuthToken.removeAuthTokens()
    
    Object.keys(localStorage).forEach(key => {
      if(key.startsWith(window[CURRENT_SWITCHED_LICENSE])){
        lsRm(key.replace(`${window[CURRENT_SWITCHED_LICENSE]}-`,''))
      }
    })
    // history.push(Routes.LOGIN);
    // Refresh the page so that old user data and GraphQL client cache is cleared for the next user to login
    window.location.href = RoutesObj.LOGIN.URL
  }

  const logIn = () => {
    history.push(RoutesObj.LOGIN.URL)
    setPublicRoute(RoutesObj.LOGIN.URL)
  }

  const onForgotPassword = () => {
    history.push(RoutesObj.FORGOT_PASSWORD.URL)
    setPublicRoute(RoutesObj.FORGOT_PASSWORD.URL)
  }
  const onLogin = React.useCallback((onSignIn, email: string, password: string) => {

    onSignIn({ variables: { username: typeUtils.isEmail(email) && email.trim(), password } })
    setResetPasswordMsg('')

  }, [])

  return (
      <AuthenticationContext.Provider
          value={{
          authData,
          onLogin,
          onLogout,
          user,
          setUser,
          onForgotPassword,
          publicRoute,
          logIn,
          resetPassword,
          res,
          resetPasswordMsg,
        }}
          {...props}
      />
  )
}

export const useAuthenticationDataContext = () => useContext(AuthenticationContext)

export default AuthenticationDataProvider
