import React, { FC, CSSProperties } from 'react'
import { OpenIconProps } from './types'

export const OpenIcon: FC<OpenIconProps> = ({
    style = {},
    className,
    size,
    color = '#fff',
}) => {
    const defaultStyle: CSSProperties = {}
    defaultStyle.fill = color

    return (
        <svg 
            className={className}
            style={{ ...defaultStyle, ...style }}
            width={size || 24} 
            height={size || 24}
            viewBox="0 0 1024 1024"
            version="1.1" 
        >
            <path d="M170.65984 42.65984l298.65984 0q17.67424 0 30.16704 12.4928t12.4928 30.16704-12.4928 30.16704-30.16704 12.4928l-298.65984 0q-17.67424 0-30.16704 12.4928t-12.4928 30.16704l0 682.65984q0 17.67424 12.4928 30.16704t30.16704 12.4928l682.65984 0q17.67424 0 30.16704-12.4928t12.4928-30.16704l0-298.65984q0-17.67424 12.4928-30.16704t30.16704-12.4928 30.16704 12.4928 12.4928 30.16704l0 298.65984q0 53.00224-37.49888 90.50112t-90.50112 37.49888l-682.65984 0q-53.00224 0-90.50112-37.49888t-37.49888-90.50112l0-682.65984q0-53.00224 37.49888-90.50112t90.50112-37.49888zM682.65984 42.65984l256 0q17.67424 0 30.16704 12.4928t12.4928 30.16704l0 256q0 17.67424-12.4928 30.16704t-30.16704 12.4928-30.16704-12.4928-12.4928-30.16704l0-153.00608-353.9968 353.9968q-12.32896 12.32896-30.0032 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-17.67424 12.32896-30.0032l353.9968-353.9968-153.00608 0q-17.67424 0-30.16704-12.4928t-12.4928-30.16704 12.4928-30.16704 30.16704-12.4928z" />
        </svg>
    )
}

OpenIcon.displayName = 'OpenIcon'
