import React, { useState, useRef, useLayoutEffect } from 'react'
import { usePageDate } from 'hooks/usePageDate'
import { formatDateStr2 } from 'utils/dateUtils'
import { PAGE_CONTAINER_CLASSNAME } from 'consts'
import styles from './styles.module.scss'

export const ComparisonTooltip = ({
    children,
    width,
    height,
}: {
    children: any;
    width?: number;
    height?: number;
}) => {
    const { pageDate, comparisonPageDate } = usePageDate()
    // eslint-disable-next-line
    const [ _, setRefresh ] = useState({})
    const isOpenRef = useRef(false)
    const calendarRef = useRef<HTMLSpanElement>(null)
    const tooltipRef = useRef<HTMLDivElement>(null)
    const positionRef = useRef<{ left: number; top: number }>({ left: -500, top: -500 })

    const onPageContainerScroll = () => {
        if (!tooltipRef.current || positionRef.current.left > 0) return
        positionRef.current = { left: -500, top: -500 }
        setRefresh({})
    }

    useLayoutEffect(() => {
        const pageContainer = document.querySelector(`.${PAGE_CONTAINER_CLASSNAME}`)
        pageContainer?.addEventListener('scroll', onPageContainerScroll)
    
        return () => {
          pageContainer?.removeEventListener('scroll', onPageContainerScroll)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <span 
            ref={calendarRef}
            className={styles.tooltipWrapper} 
            style={{ width: width + 'px', height: height + 'px' }}
            onMouseEnter={e => {
                if (isOpenRef.current) return
                const triggerRect = calendarRef.current?.getBoundingClientRect()
                const tooltipRect = { width: 250, height: 80 }
                if (!triggerRect || !tooltipRect) return
                const left = triggerRect.left + Math.abs(triggerRect.width - tooltipRect.width) / 2 * (triggerRect.width > tooltipRect.width ? 1 : -1)
                const top = triggerRect.top - tooltipRect.height - 20
                if (triggerRect.top > tooltipRect.height + 85) {
                    positionRef.current = { left, top }
                    isOpenRef.current = true
                    setRefresh({})
                }
            }}
            onMouseLeave={e => {
                if (!isOpenRef.current) return
                positionRef.current = { left: -500, top: -500 }
                isOpenRef.current = false
                setRefresh({})
            }}
        >
            {
                isOpenRef.current ? (
                    <div
                        className={styles.svg} 
                        style={{ width: width + 'px', height: height + 'px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="rgb(1, 166, 153)">
                                <g>
                                    <rect fill="none" height="24" width="24" />
                                </g>
                                <g>
                                    <path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                ) : children
            }
            <div 
                ref={tooltipRef} 
                className={styles.comparisonTooltip}
                style={{ left: positionRef.current.left + 'px', top: positionRef.current.top + 'px', display: isOpenRef.current ? 'block': 'none' }}    
            >
                <div style={{ fontSize: '14px', fontWeight: 'normal', color: '#585858', marginBottom: '6px' }}>Comparison period</div>
                <div style={{ fontSize: '12px', fontWeight: 'normal', color: '#999999' }}>{`${formatDateStr2(pageDate)} vs ${formatDateStr2(comparisonPageDate)}`}</div>
            </div>
        </span>
    )
}
