import { REQUEST_LOOKBOOK, REQUEST_MY_LOOKBOOK } from 'consts'
import { useLookbook } from 'hooks/useLookbook'
import { useFetch } from 'libs'
import { useState } from 'react'

export function useCreateLookbook() {
    const { postFn } = useFetch()
    
    const createLookbook = async (name: string, desc?: string) => {
        await postFn(REQUEST_LOOKBOOK, { query: { name, desc }, data: {} })
    }

    return {
        createLookbook,
    }
}

export function useRemoveLookbook() {
    const { delFn } = useFetch()

    const removeLookbook = async (id: string) => {
        await delFn(REQUEST_LOOKBOOK, { query: { id }, data: {} })
    }

    return {
        removeLookbook,
    }
}

export function useModifyLookbook() {
    const { patchFn } = useFetch()

    const modifyLookbook = async ({
        id,
        name,
        desc,
    }: {
        id: string;
        name?: string;
        desc?: string;
    }) => {
        await patchFn(REQUEST_LOOKBOOK, { query: { name, desc, id }, data: {} })
    }

    return {
        modifyLookbook,
    }
}

export function useRefreshLookbookList(opt?: { isUpdateLatestLookbook?: boolean }) {
    const { getFn } = useFetch()
    const { setLookbookList, setMyLookbookList, setLatestLookbook, latestLookbook } = useLookbook()
    const [ loading, setLoading ] = useState(false)

    const refreshLookbookList = async () => {
        setLoading(true)
        const result = await getFn(REQUEST_MY_LOOKBOOK)
        const data = result?.data?.result
        if (!Array.isArray(data)) return

        setLookbookList(data.map(item => ({
          id: item.id,
          count: item.lookbookproductions?.length ?? 0,
          name: item.name,
        })))

        setMyLookbookList(data.map(item => item))
        if (!latestLookbook?.id || opt?.isUpdateLatestLookbook) {
            setLatestLookbook(data[data.length - 1])
        }
        setLoading(false)
    }

    return {
        refreshLookbookList,
        loading,
    }
}
