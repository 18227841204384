import { numberUtils } from 'norna-uikit';

/**
 * 线图通用配置
 */
export const commonOptions = {
    layout: {
        // 整个线图背景是白色的
        background: { 
            type: 'solid', 
            color: 'white', 
        },
        // 文本颜色是浅灰色
        textColor: '#adadad',
    },
    timeScale: {
        // 不显示 x 轴线
        borderVisible: false,
        // 固定 x 轴右边范围  
        fixRightEdge: true,
        // 固定 x 轴左边范围
        fixLeftEdge: true,
        centerPointLength: 3,
        paddingInline: 30, // x 轴左边和右边留白
    },
    grid: {
        // 垂直网格线不显示
        vertLines: {
            visible: false,
        },
        // 水平网格线显示虚线
        horzLines: {
            style: 3,
        },
    },
    autoSize: true,
}

/**
 * 线图 y 轴通用配置
 * 左边 y 轴线和右边 y 轴线可以单独配置参数, 可以有不同的表现
 */
export const commonPriceScaleOptions = {
    autoScale: false,
    // 不显示 y 轴
    borderVisible: false,
    textColor: '#adadad',
    mode: 0,
    // 文本完全可见时才显示文本
    entireTextOnly: false,
    // 顶部留白和底部留白
    scaleMargins: {
        top: 0.25,
        bottom: 0.05,
    },
    // 底部固定
    fixBottomEdge: false,
    // 最大值
    // maxValue: 1,
}

/**
 * 线图颜色集合
 */
export const legendColors = [
    '#FA7230', '#6C6493', '#FA6BE4', '#FABB30', '#6EB970',
    '#232524', '#1E00FF', '#34B5FF', '#E22F21', '#F5ABAB',
    '#B4B4B4',  // rgba(180, 180, 180, 1)
    '#680052',  // rgba(104, 0, 82, 1)
    '#D85A5A',  // rgba(216, 90, 90, 1)
    '#BDC472',  // rgba(189, 196, 114, 1)
    '#FB5252',
    '#93648A',
    '#649392',
    '#01A699',
    '#7FB7B6',
    '#4A90E2',
    '#7ED321',
    '#8B572A',
    '#F8E71C',
    '#B8E986',
    '#9013FE',
    '#BD10E0',
    '#382A8B',
    '#1CC2F8',
    '#E986D0',
    '#2E13FE',
    '#2CE010',
    '#8B832A',
    '#F81CC9',
    '#E98686',
    '#13FEF1',
    '#D2E010',
    '#6F88AA',
    '#4F3A4A',
    '#682A2A',
    '#133E3C',
    '#000000 ',
    '#FFD99A ',
    '#B87171 ',
    '#636363 ',
    '#346200 ',
    '#E877FF ',
    'rgba(116, 182, 182, 1)', 
    'rgba(255, 98, 98, 1)', 
    'rgba(108, 100, 147, 1)', 
    'rgba(250, 187, 48, 1)', 
    'rgba(110, 185, 112, 1)', 
    'rgba(52, 181, 255, 1)',
    'rgba(180, 180, 180, 1)', 
    'rgba(104, 0, 82, 1)', 
    'rgba(216, 90, 90, 1)',
    'rgba(189, 196, 114, 1)',
]

/**
 * 获取 tooltip label list
 */
export function getTooltipLabelList({
    chartData = [],
    sortTooltipLabel,
}: {
    chartData: any[];
    sortTooltipLabel?: (labelList: string[]) => string[];
}) {
    let labelList = chartData.map(item => item.label)
    if (typeof sortTooltipLabel === 'function') {
        labelList = sortTooltipLabel(labelList)
    }
    return labelList
}

/**
 * 线图里面获取到的日期可能会有两种形式
 * 1. 字符串 '2024-02-04'
 * 2. 对象字面量 { year: 2024, month: 2, day: 4 }
 * 
 * 最终需要处理成和 x 轴日期格式一样 04 Feb '24
 */
function formatTooltipDate(value: string | { year: number; month: number; day: number }) {
    let date = new Date()
    if (typeof value === 'string') {
        date = new Date(value)
    } else {
        date = new Date(value.year, value.month - 1, value.day)
    }
    const MONTHS_ABBR = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    return `${day} ${MONTHS_ABBR[month]} '${String(year).slice(2)}`
}

/**
 * 线图 tooltip 自定义生成
 */
export function handleChartTooltip({
    chartContainerEl,   // 线图最外层 div
    chart,              // 线图实例对象, 上面有很多关于线图的方法和属性
    seriesArr,
    tooltipLabelList = [],
    formatTooltipValue,
}: {
    chartContainerEl: any;
    chart: any;
    seriesArr: any[];
    tooltipLabelList: string[];
    formatTooltipValue?: (value: number, index: number) => string;
}) {
    const tooltipClassName = 'line-graph-tooltip'
    const tooltipSelector = `.${tooltipClassName}`
    const toolTipWidth = 180;

    // tooltip 已经存在, 删掉重新生成
    const tooltipEl = chartContainerEl.querySelectorAll(tooltipSelector)
    tooltipEl.forEach(t => t.remove?.())

    // 创建 tooltip 样式
    const toolTip = document.createElement('div') as HTMLDivElement
    toolTip.style.height = 'fit-content'
    toolTip.style.position = 'absolute'
    toolTip.style.display = 'none'
    toolTip.style.padding = '8px'
    toolTip.style.boxSizing = 'border-box'
    toolTip.style.fontSize = '12px'
    toolTip.style.textAlign = 'left'
    toolTip.style.zIndex = '1000'
    toolTip.style.top = '12px'
    toolTip.style.left = '12px'
    toolTip.style.pointerEvents = 'none'
    toolTip.style.border = '1px solid'
    toolTip.style.borderRadius = '2px'
    toolTip.style.left = '12px'
    toolTip.style.background = 'rgb(255, 255, 255)';
    toolTip.style.color = 'black';
    toolTip.style.borderColor = 'rgba(0, 150, 136, 1)';
    toolTip.classList.add(tooltipClassName)
    chartContainerEl.appendChild(toolTip);

    // 监听鼠标移动, 更新 tooltip
    chart.subscribeCrosshairMove(param => {
        if (
            param.point === undefined       // x 轴对应竖线没有点
            || !param.time                  // x 轴没有时间, 整个线图就没有数据
            || param.point.x < 0 
            || param.point.x > chartContainerEl.clientWidth 
            || param.point.y < 0 
            || param.point.y > chartContainerEl.clientHeight 
            || !seriesArr?.length
        ) {
            toolTip.style.display = 'none'
            return
        }

        // x 轴对应的时间
        const date = formatTooltipDate(param.time)

        const valueArr: any[] = []

        seriesArr.forEach(series => {
            const data = param.seriesData.get(series)
            const price = data?.value !== undefined ? data?.value : data?.close
            valueArr.push(price)
        })

        let htmlStr = `
            <div style="font-size: 12px; margin-bottom: 8px;">
                ${date}
            </div>
        `
        valueArr.forEach((value, index) => {
            const color = legendColors[index]
            const labelStr = tooltipLabelList[index]?.replace('<', '&lt;')?.replace('>', '&gt;')
            let valueStr = numberUtils.formatNumber(value, { isCommaSymbol: true, decimal: 0 })
            if (typeof formatTooltipValue === 'function') {
                valueStr = formatTooltipValue(value, index)
            }
            htmlStr += `
                <div style="display: flex; align-items: center;">
                    <div style="margin-right: 4px; width: 8px; height: 8px; border-radius: 50%; background-color: ${color}"></div>
                    <div style="font-size: 12px; width: fit-content; flex: 1;">
                        ${labelStr}
                    </div>
                    <div style="font-size: 12px; width: 60px; text-align: right;">
                        ${valueStr}
                    </div>
                </div>
            `
        })

        toolTip.innerHTML = htmlStr

        const coordinate = seriesArr[0].priceToCoordinate(valueArr[0]);
        if (coordinate === null) {
            return toolTip.style.display = 'none';
        }

        let shiftedCoordinate = param.point.x
        if (shiftedCoordinate > chartContainerEl.clientWidth * 0.6) {
            shiftedCoordinate = shiftedCoordinate - toolTipWidth
        } else {
            shiftedCoordinate = shiftedCoordinate + 100
        }

        toolTip.style.left = shiftedCoordinate + 'px';
        toolTip.style.top = 0 + 'px';
        toolTip.style.display = 'block';
    });
}

function formatChartDate(value: string | { year: number, month: number, day: number }) {
    if (typeof value === 'string') return value
    return `${value.year}-${String(value.month).padStart(2, '0')}-${String(value.day).padStart(2, '0')}`
}

/**
 * {
 *      from: { year, month, day },
 *      to: { year, month, day },
 * } 
 */
export function formatTimeRangeToStr(timeRange) {
    return `${formatChartDate(timeRange.from)}_${formatChartDate(timeRange.to)}`
}

export function getMaxDateRange(chartData) {
    if (!chartData.length) return ''
    const { data } = chartData[0]
    const first = data[0].time
    const last = data[data.length - 1].time
    return `${formatChartDate(first)}_${formatChartDate(last)}`
}

export function isAdjustYAxisVisibleRange({
    chartData,
    priceScaleValue,
}: {
    chartData: any[];
    priceScaleValue: any;
}) {
    // let newChartData = chartData
    //     .filter(item => {
    //         return !!item?.data?.filter(item2 => item2?.value !== undefined)?.length
    //     })
    //     .map(item => {
    //         const valueArr = item?.data?.map(item2 => item2?.value || 0)
    //         const maxValue = Math.max(...valueArr)
    //         const minValue = Math.min(...valueArr)
    //         return {
    //             minValue,
    //             maxValue,
    //         }
    //     })

    // const acutalRange = getYAxisActualRange(priceScaleValue)

    // const isAdjust = newChartData.some(item => {
    //     return (item.maxValue < acutalRange?.minValue) || (item?.minValue > acutalRange?.maxValue)
    // })

    // return isAdjust
    return true
}

function getYAxisActualRange(priceScaleValue) {
    const { minValue, maxValue } = priceScaleValue
    const y1 = maxValue - (0.75 * (maxValue - minValue)) / 0.7
    const y2 = (maxValue - 0.25 * y1) / 0.75
    return {
        minValue: y1,
        maxValue: y2,
    }
}
