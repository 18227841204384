import { useEffect } from 'react'
import { useFetch } from 'libs/hookRequest'
import { REQUEST_GET_LOOKBOOK_DETAIL_PRODUCTS } from 'consts/request'

export const useGetLookbookDetailProducts = (
  id: string) => {
  const { data, loading, error, getFn: fetchMore } = useFetch(
    //   , {
    //   variables: {
    //     keyword,
    //     type,
    //     id,
    //   },
    //   fetchPolicy: 'network-only',
    //
    // }
  )
  useEffect(() => {
    fetchMore(REQUEST_GET_LOOKBOOK_DETAIL_PRODUCTS, { id })

  }, [id]); //eslint-disable-line
  return {
    data,
    loading,
    fetchMore: id => {
      fetchMore(REQUEST_GET_LOOKBOOK_DETAIL_PRODUCTS, { id })
    },
    error,
  }
}
