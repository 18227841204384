import React, { useState } from 'react'
import classnames from 'classnames'
import { CloseIcon } from 'assets/icons'
import { SizedBox } from 'componentsv2/SizedBox'
import { Button } from 'componentsv2/Button'
import { Checkbox } from 'componentsv2/form-elements/Checkbox'
import { ALERT_TYPE_LAUNCHEDAFTER, FILTER_DIALOG_CLASS } from 'consts'
import { useCreateAlertMessage } from 'hooks/api/useAlertMessageData'
import { storage } from 'utils/storage'
import { uniq } from 'lodash'
import styles from './styles.module.scss'

export const LaunchedAfterWarningDialog = ({
    onClose,
    modalMaskStyle = {},
}: any) => {
    const [ checked, setChecked ] = useState(true)
    const [ loading, setLoading ] = useState(false)

    const { createAlertMessage } = useCreateAlertMessage()

    const onOk = async () => {
        setLoading(true)
        try {
            await createAlertMessage(ALERT_TYPE_LAUNCHEDAFTER, checked)
            const alertMessages = storage.getAlertMessages()
            if (checked) {
                alertMessages.push(ALERT_TYPE_LAUNCHEDAFTER)
                storage.setAlertMessages(uniq([ ...alertMessages ]))
            } else {
                storage.setAlertMessages(uniq(alertMessages.filter(item => item !== ALERT_TYPE_LAUNCHEDAFTER)))
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    return (
        <>
            <div className={`material-filter ${FILTER_DIALOG_CLASS}`} onMouseDown={e => e.stopPropagation()}>
                <div
                    className={styles.modal}
                    style={modalMaskStyle}
                />
                <div className={classnames(styles.sizeFilter)}>
                    <div className={styles.close}>
                        <CloseIcon onClick={() => { onClose?.() }} />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>Warning</div>
                        <SizedBox type="block" height={20} />
                        <div className={styles.text}>
                            The selected <span style={{ fontWeight: 'bold' }}>"launched after"</span> date is later than the end of the
                        </div>
                        <div className={styles.text}>
                            <span style={{ fontWeight: 'bold' }}>"selected time period"</span>, meaning that you will not receive any data
                        </div>
                        <div className={styles.text}>
                            in your analytics.
                        </div>
                        <SizedBox type="block" height={20} />
                        <Checkbox value={checked} onChange={value => setChecked(value)}>
                            Don't show this again
                        </Checkbox>
                        <SizedBox type="block" height={20} />
                        <Button
                            type="primary"
                            mini
                            className={styles.applyBtn}
                            onClick={async () => {
                                await onOk?.()
                                onClose?.()
                            }}
                            loading={loading}
                        >
                            Got it
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
