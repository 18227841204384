import { CaretOutlineIcon } from 'assets/icons/CaretOutline'
import { Text } from 'components/typography'
import { OPTIONS_NAME } from 'consts'
import React, { useState } from 'react'
import styles from './styles.module.scss'
import { DescriptionProps } from './types'
import { numberUtils } from 'norna-uikit'

export const Description = ({ toggleItemsRow, count, maxCount }: DescriptionProps) => {
    const [ expanded, setExpanded ] = useState(false)

    const toggleItems = () => {
        setExpanded(!expanded)
        toggleItemsRow()
    }

    return (
        <div className={styles.descriptionWrap}>
            <div>
                <div>
                    <Text small>{OPTIONS_NAME}</Text>
                    <Text small accent className={styles.totalProducts}>
                        {count ? numberUtils.formatNumberByComma(count) : null}
                    </Text>
                </div>
            </div>
            {count && maxCount && count > maxCount ?
                <div className={styles.showAllButtonWrap}>
                    <button onClick={() => toggleItems()} >
                        <CaretOutlineIcon isExpanded={expanded} />
                        <Text small accent>
                            {expanded ? 'Show less' : 'Show more'}
                        </Text>
                    </button>
                </div> : null
            }
        </div>
    )
}
