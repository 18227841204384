import React, { FC } from 'react'
import classnames from 'classnames'
import { TagWithTips } from 'componentsv2/MenuBar/FilterTags'
import { LegendProps } from './types'
import styles from './styles.module.scss'
import { calcEle } from './utils'

export const Legend: FC<LegendProps> = ({
    dataSource = [],
    style = {},
    enableCalcWidth,
    className,
    enableLabel = true,
    enableLimit = false,
}) => (
    <div className={classnames([ styles.legend, className ])} style={style}>
        <ul>
            {
                dataSource.map((item, index) => {

                    let html = <>{item.label}</>

                    if (enableLimit) html = <TagWithTips className="vendor-tag" title={item.label} label={enableCalcWidth ? calcEle(item.label) : item.label} width="auto" />

                    return (
                        <li key={item.label || item.dotColor} className={styles.legendItem} title={item.label}>
                            <span aria-label={item.label} title={item.label} style={{ backgroundColor: item.dotColor }}> </span>
                            <span>{enableLabel ? html : ''}</span>
                        </li>
                    )
                })
            }
        </ul>
    </div>
)

Legend.displayName = 'Legend'
