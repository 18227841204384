import React, { CSSProperties, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Flex } from 'druikit'
import styles from './styles.module.scss'
import { DisplayText } from 'componentsv2/DisplayText'
import { COMPARE_TO_PREVIOUS_PERIOD, COMPARE_TO_YEAR_ON_YEAR, PERIOD_LAST_MONTH, PERIOD_LAST_QUARTER, PERIOD_LAST_WEEK } from 'features/filters/dynamicDashboardSlice'
import { handleName } from 'pages/DynamicDashboard/utils'
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined'
import { Tooltip } from 'antd'
import { useCalendarCompareToValue, useCalendarPeriodValue } from 'hooks/useGlobalData'
import VendorNameTooltip from './VendorNameTooltip'

function getSymbolFromCurrency(currency: string) {
    const mapper = {
        EUR: '€',
        GBP: '£',
        USD: '$',
    }
    return mapper[currency] || currency
}

function getFromText(selectedPeriod: string, selectedCompareWith: string) {
    let fromText = ''
    if (selectedCompareWith === COMPARE_TO_YEAR_ON_YEAR) {
        fromText = 'From previous year'
    } else if (selectedCompareWith === COMPARE_TO_PREVIOUS_PERIOD) {
        if (selectedPeriod === PERIOD_LAST_WEEK) {
            fromText = 'From previous week'
        } else if (selectedPeriod === PERIOD_LAST_MONTH) {
            fromText = 'From previous month'
        } else if (selectedPeriod === PERIOD_LAST_QUARTER) {
            fromText = 'From previous quarter'
        } else {
            fromText = 'From calendar selection'
        }
    } else {
        fromText = 'From calendar selection'
    }
    return fromText
}

export const MetricVendor = ({
    style,
    className,
    vendorName,
    value,
    changePercent,
    showUnits = false,
    showFromPreviousYear = false,
    currency = '',
    description = '',
}: {
    style?: CSSProperties;
    className?: string;
    vendorName?: string;
    value?: ReactNode;
    changePercent?: number | string;
    // 值是百分数, 看加减法, 有 % units, 值是数值，看乘除法
    showUnits?: boolean;
    showFromPreviousYear?: boolean;
    currency?: string;
    description?: string;
}) => {
    const [ periodValue ] = useCalendarPeriodValue()
    const [ compareToValue ] = useCalendarCompareToValue()
    const [ fromText, setFromText ] = useState('')

    useEffect(() => {
        setFromText(getFromText(periodValue, compareToValue))
    }, [ periodValue, compareToValue ])

    const symbol = getSymbolFromCurrency(currency)
    let currencyEl: ReactNode = null
    if (currency) {
        currencyEl = (
            <div style={{ fontSize: '0.5em', color: '#666', marginLeft: 4 }}>{currency}</div>
        )
    }
    if (symbol && symbol !== currency) {
        currencyEl = (
            <div style={{ fontSize: '1em', color: '#666', marginLeft: 4 }}>{symbol}</div>
        )
    }

    /* ************************* 动态处理字体大小 ****************************** */
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [ fontSize, setFontSize ] = useState(32)

    useLayoutEffect(() => {
        const wrapperEl = wrapperRef.current
        if (!wrapperEl) return

        const wrapperWidth = wrapperEl.getBoundingClientRect().width
        const wrapperPadding = 18
        const wrapperContentWidth = wrapperWidth - wrapperPadding * 2

        let toolDivEl = document.querySelector('#tool-div') as HTMLDivElement
        if (!toolDivEl) {
            toolDivEl = document.createElement('div') as HTMLDivElement
            toolDivEl.setAttribute('id', 'tool-div')
            document.documentElement.appendChild(toolDivEl)
        }

        if (!toolDivEl) return

        try {
            Array.from({ length: 20 }, (_, index) => index + 1).forEach(item => {
                const fontSize = 32
                toolDivEl.style.width = 'fit-content'        
                toolDivEl.innerHTML = `
                    <div style="font-size: ${fontSize - item}px; display: flex; flex-wrap: nowrap; align-items: baseline; width: fit-content;">
                        <div>${value}</div>
                        <div style="font-size: 0.5em; margin-left: 4px;">${currency}</div>
                    </div>
                `
                const actualWidth = toolDivEl.getBoundingClientRect().width
                if (actualWidth <= wrapperContentWidth) {
                    setFontSize(fontSize - item)
                    throw new Error('End Loop')
                }
            })
        } catch (e) {
            console.warn('End loop ....')
        }

    }, [])

    return (
        <Flex ref={wrapperRef} style={style} className={className} flexDirection="column">
            <VendorNameTooltip 
                vendorName={vendorName}
                description={description}
            />
            <div className={styles.value} style={{ fontSize, display: 'flex', alignItems: 'baseline', height: 41, lineHeight: '41px' }}>
                {value}{currencyEl}
            </div>
            <div className={styles.changePercent}>
                <DisplayText value={changePercent} />
            </div>
            {
                showFromPreviousYear ? (
                    <div>
                        <div className={styles.changeType}>
                           {fromText}
                        </div>
                    </div>
                ) : null
            }
            {
                showUnits ? (
                    <div>
                        <div className={styles.changeType}>
                            <span style={{ color: '#01A699', marginRight: 3 }}>%</span>
                            <span>units</span>
                        </div>
                    </div>
                ) : null
            }
        </Flex>
    )
}
