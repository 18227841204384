import { IS_LIVE_ENV } from 'consts'
import { cloneDeep } from 'lodash'
import { arrayUtils, numberUtils, stringUtils } from 'norna-uikit'
import { getSubmetaColorList, paletteList } from 'utils/colorJson'
import { getValueByQueryDateValid } from 'utils/pageUtils'
import { storage } from 'utils/storage'
import { PieVendorApiData, PieVendorCategory } from './types'
import { getFilterCategoryFirstLevelList } from 'utils/lockSellerUtils'
import { getVendorNameByCode } from 'utils'

/**
 * get category and color mapper object
 */
 export function handlePieColorObj(vendorDataList: PieVendorApiData[]) {
    const newVendorDataList = cloneDeep(vendorDataList)
    let colorList = newVendorDataList.map(item => item.bucket_assortments.map(item2 => item2.name)).flat(2)
    const categoryList = getFilterCategoryFirstLevelList()
    colorList = Array.from(new Set([ ...colorList, ...categoryList ]))
    colorList.push('Other categories')
    const categoryTreeList = storage.getCategoryTreeList()

    const colorObj = {}
    colorList.forEach((color, index) => {
        const c = paletteList[index]
        const subColorList = getSubmetaColorList(c)
        colorObj[color] = c
        if (categoryTreeList.find(x => x.name === color)?.list?.length) {
            categoryTreeList
                ?.find(x => x.name === color)
                ?.list
                ?.forEach((x, i) => {
                    colorObj[x.name] = subColorList[i]
                })
        }
    })
    return colorObj
}

/**
 * return up to 15 categories
 * 
 * - If the number of categories is less than 15, return directly
 * - If the number of categories is greater than or equal to 15, 
 *   merge the 15th and subsequent categories into one category [Other categories]
 */
 export function handlePieCategory(categoryList: any[], num = 15) {
    let newCategoryList = cloneDeep(categoryList)

    // exclude [Others] category in live env
    if (IS_LIVE_ENV) {
        newCategoryList = newCategoryList
            .filter(category => category.name.toLowerCase() !== 'others')
    }

    // filter out the categories whose number of products is not 0
    newCategoryList = newCategoryList.filter(item => item.count.current_value !== 0)

    if (newCategoryList.length < num) {
        return newCategoryList
    }

    const beforeList = newCategoryList.slice(0, num - 1)
    const afterList = newCategoryList.slice(num - 1)
    // products
    const totalProducts = afterList.map(item => item.products || []).flat(1)
    // count
    const countCurrentValue = afterList.reduce((curr, next) => curr + next.count.current_value, 0)
    const countComparisonValue = afterList.reduce((curr, next) => curr + next.count.comparison_value, 0)
    const countChange = countCurrentValue - countComparisonValue
    const countChangePercentage = countComparisonValue === 0 ? 0 : countChange / countComparisonValue
    // percent
    const percentCurrentValue = afterList.reduce((curr, next) => curr + next.percent.current_value, 0)
    const percentComparisonValue = afterList.reduce((curr, next) => curr + next.percent.comparison_value, 0)
    const percentChange = percentCurrentValue - percentComparisonValue
    const percentChangePercentage = percentComparisonValue === 0 ? 0 : percentChange / percentComparisonValue
    // average_price
    const avgPriceCurrentValue = afterList.reduce((curr, next) => curr + next.average_price.current_value, 0)
    const avgPriceComparisonValue = afterList.reduce((curr, next) => curr + next.average_price.comparison_value, 0)
    const avgPriceChange = avgPriceCurrentValue - avgPriceComparisonValue
    const avgPriceChangePercentage = avgPriceComparisonValue === 0 ? 0 : avgPriceChange / avgPriceComparisonValue

    beforeList.push({
        name: 'Other categories',
        count: {
            current_value: countCurrentValue,
            comparison_value: countComparisonValue,
            change: countChange,
            change_percentage: countChangePercentage,
        },
        percent: {
            current_value: percentCurrentValue,
            comparison_value: percentComparisonValue,
            change: percentChange,
            change_percentage: percentChangePercentage,
        },
        average_price: {
            current_value: avgPriceCurrentValue,
            comparison_value: avgPriceComparisonValue,
            change: avgPriceChange,
            change_percentage: avgPriceChangePercentage,
        },
        products: totalProducts,
    })
    return beforeList
}

/**
 * Calculate the percentage
 */
 export function handlePiePercent(categoryList: any[], decimal = 4) {
    let newCategoryList = cloneDeep(categoryList)
    
    const totalCount = newCategoryList.reduce((curr, next) => curr + next?.count?.current_value || 0, 0)
    const totalComparisonCount = newCategoryList.reduce((curr, next) => curr + next?.count?.comparison_value || 0, 0)
    if (totalCount === 0) return []
    
    newCategoryList = newCategoryList.map(category => {
        const currentValue = Number((category.count.current_value / totalCount).toFixed(decimal))
        const comparisonValue = totalComparisonCount === 0 ? 0 : Number((category.count.comparison_value / totalComparisonCount).toFixed(decimal))
        const change = currentValue - comparisonValue
        const changePercentage = change / currentValue
        category.percent = {
            current_value: currentValue,
            comparison_value: comparisonValue,
            change,
            change_percentage: changePercentage,
        }
        return category
    })
    if (newCategoryList.length > 1) {
        const totalPercentExcludeLast = arrayUtils.fsum(
            newCategoryList
                .slice(0, newCategoryList.length - 1)
                .map(i => i?.percent?.current_value || 0),
        )
        newCategoryList[newCategoryList.length - 1].percent.current_value = numberUtils.subtract(1, totalPercentExcludeLast)

        const totalComparisonPercentExcludeLast = arrayUtils.fsum(
            newCategoryList
                .slice(0, newCategoryList.length - 1)
                .map(i => i?.percent?.comparison_value || 0),
        )
        newCategoryList[newCategoryList.length - 1].percent.comparison_value = numberUtils.subtract(1, totalComparisonPercentExcludeLast)
    } else {
        newCategoryList[0].percent = {
            current_value: 1,
            comparison_value: 1,
            change: 0,
            change_percentage: 0,
        }
    }
    return newCategoryList
}

/**
 * { 
 *      label: '',              
 *      prod: [],               
 *      backgroundColor: '',    
 *      disabled: false,        
 *      count: 0, 
 *      percent: 1 
 * }
 */
 export function handlePieDataStructure(categoryList: PieVendorCategory[], colorObj: Record<string, string>, seller: any) {
    let newCategoryList: any[] = cloneDeep(categoryList)

    const handleCategoryItem = (category: any) => {
        let countCurrentValue = numberUtils.formatNumberByComma(category.count.current_value)
        countCurrentValue = getValueByQueryDateValid(countCurrentValue, seller.queryDateValid)

        let countChangePercentage = (category.count.change_percentage * 100).toFixed(1) + '%'
        countChangePercentage = getValueByQueryDateValid(countChangePercentage, seller.queryComparisonDateValid)

        let percentCurrentValue = (category.percent.current_value * 100).toFixed(1) + '%'
        percentCurrentValue = getValueByQueryDateValid(percentCurrentValue, seller.queryDateValid)

        let percentChange = (category.percent.change * 100).toFixed(1) + '%'
        percentChange = getValueByQueryDateValid(percentChange, seller.queryComparisonDateValid)

        let averagePriceCurrentValue = category.average_price.current_value
        averagePriceCurrentValue = getValueByQueryDateValid(averagePriceCurrentValue, seller.queryDateValid)

        let averagePriceChangePercentage = (category.average_price.change_percentage * 100).toFixed(1) + '%'
        averagePriceChangePercentage = getValueByQueryDateValid(averagePriceChangePercentage, seller.queryComparisonDateValid)
    
        return {
            countCurrentValue,
            countChangePercentage,
            percentCurrentValue,
            percentChange,
            averagePriceCurrentValue,
            averagePriceChangePercentage,
        }
    }

    newCategoryList = newCategoryList.map(category => {
        const newCategory = {
            ...category,
            backgroundColor: colorObj[category.name],
            name: category.name,
            label: stringUtils.capitalize(category.name),
            prod: category.products,
            disabled: category?.count.current_value === 0,
            ...handleCategoryItem(category),
        }

        if (Array.isArray(category?.children) && category?.children?.length) {
            newCategory.children = category.children.map(c2 => {
                return {
                    ...c2,
                    backgroundColor: '',
                    name: c2.name,
                    label: stringUtils.capitalize(c2.name),
                    prod: c2.products,
                    disabled: c2?.count.current_value === 0,
                    ...handleCategoryItem(c2),
                }
            })
        }

        return newCategory
    })

    return newCategoryList
}

/**
 * 将 list 数据结构转换为树状数据结构
 */
export function handleListDataToTreeData({
    data,
}: {
    data: any[];
}) {
    let newData = cloneDeep(data)
    const categoryTreeList = storage.getCategoryTreeList()
    const firstLevelCategoryList = categoryTreeList.map(c => c.name)

    newData = newData.map(d => {
        const bucketAssortments = d.bucket_assortments
        const newBucketAssortments: any[] = []

        firstLevelCategoryList.forEach(c1 => {
            const c1Data = bucketAssortments.find(b => b.name === c1)
            if (!c1Data) return true
            // 找它的子元素
            const childrenCategoryList = categoryTreeList.find(c => c.name === c1)?.list?.map(c => c.name) || []
            const childrenCategoryData = bucketAssortments.filter(c => childrenCategoryList?.includes(c.name))
            c1Data.children = childrenCategoryData
            newBucketAssortments.push(c1Data)
        })

        d.bucket_assortments = newBucketAssortments
        return d
    })

    return newData
}

/**
 * 过滤数据
 */
export function handleFilterData({
    data = [],
    categoryValue,
}: {
    data: any[];
    categoryValue: string[];
}) {
    let newData = cloneDeep(data)
    newData = newData.map(item => {
        item.bucket_assortments = item.bucket_assortments.filter(item2 => categoryValue.includes(item2.name))
        return item
    })
    return newData
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    switchValue = false,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        'Vendor',
        'Category',
        'Split %',
        '+/- % Units',
        'Average price',
        '+/- %',
    ]
    data.push(titleRow)

    dataSource.forEach(item => {
        const list = switchValue ? item?.unitList : item?.percentList
        const vendorName = getVendorNameByCode(item?.seller?.vendor)
        
        list.forEach(item2 => {
            const d = [
                vendorName,
                item2?.name.replace(',', ' '),
                switchValue ? item2?.countCurrentValue?.replace(',', '') : item2?.percentCurrentValue,
                switchValue ? item2?.countChangePercentage : item2?.percentChange,
                numberUtils.formatNumber(item2?.averagePriceCurrentValue, { decimal: 0 }),
                item2?.averagePriceChangePercentage,
            ]
            data.push(d)

            if (item2?.children?.length) {
                item2?.children?.forEach(item3 => {
                    const d2 = [
                        vendorName,
                        item3?.name?.replace(',', ' '),
                        switchValue ? item3?.countCurrentValue?.replace(',', '') : item3?.percentCurrentValue,
                        switchValue ? item3?.countChangePercentage : item3?.percentChange,
                        numberUtils.formatNumber(item3?.averagePriceCurrentValue, { decimal: 0 }),
                        item3?.averagePriceChangePercentage,
                    ]
                    data.push(d2)
                })
            }
        })
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
