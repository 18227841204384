import React, { Fragment, ReactNode } from 'react'
import classnames from 'classnames'
import { useCustomerVendor } from 'hooks'
import { useSelector } from 'react-redux'
import { getCacheData } from 'features/filters/cacheDataSlice'
import styles from './styles.module.scss'

export const Table = ({
    columns,
    dataSource,
}) => {
    /**
     * 获取缓存数据，目的是获取 Filter > Country 总共有几个值，然后确定表格表体最大高度
     */
    const cacheData = useSelector(getCacheData)
    /**
     * bimba + cross region pricing 页面硬编码加了一个【El Palacio】region, 因此 tbody 高度也要改下 
     */
    const customerVendor = useCustomerVendor()
    const tbodyHeight = 24 * ((cacheData?.regions || []).length + (customerVendor === 'bimbaylola' ? 1 : 0))

    const thElements: ReactNode = columns.map(item => {
        if (typeof item.title === 'string') {
            return (
                <div style={{ width: item.width, textAlign: item?.align || 'left' }} key={String(item.title)}>
                    {item.title}
                </div>
            )
        }
        return (
            <Fragment key={item.dataIndex}>
                { item.title }
            </Fragment>
        )
    })

    const trElements = dataSource.map((item, index) => (
        <div className={classnames({ [styles.tr]: true, [styles.trActive]: item?.active === true })} key={index.toString()}>
            {
                columns.map(column => {
                    if (typeof column?.render === 'function') {
                        return (
                            <Fragment key={column.dataIndex}>
                                { column.render(item[column.dataIndex] || '', item) }
                            </Fragment>
                        ) 
                    } 
                    return (
                        <div className={styles.td} style={{ width: column.width }} key={column.dataIndex}>
                            { item[column.dataIndex] || '' }
                        </div>
                    )
                })
            }
        </div>
    ))

    return (
        <>
            <div className={styles.thead} style={{ borderBottom: '' }}>
                {thElements}
            </div>
            <div className={styles.tbody} style={{ height: tbodyHeight + 'px' }}>
                {trElements}
            </div>
        </>
    )
}
