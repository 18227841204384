import React, { useState, useImperativeHandle, forwardRef, CSSProperties, useEffect } from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { OptionItem, TreeItem, getLeafs } from '../TreeItem'
import { intersection, isSuperArray } from 'utils/array';
import { uniq } from 'lodash';

export interface TreeProps {
    options: OptionItem[];
    value?: string[];
    onChange?: (value: string[]) => void;
    bordered?: boolean;
    ancestorBordered?: boolean;
    minCount?: number;
    maxCount?: number;
    level1MaxCount?: number;
    multiple?: boolean;
    style?: CSSProperties;
}

export interface TreeRefProps {
    clearAll: () => void;
    selectAll: () => void;
    getValue: () => string[];
}

export const Tree = forwardRef<TreeRefProps, TreeProps>(({
    options = [],
    value = [],
    onChange,
    bordered = true,
    ancestorBordered = true,
    minCount = 0,
    maxCount = 9999,
    level1MaxCount,
    multiple = false,
    style = {},
}, ref) => {
    // 对外部暴露的方法
    useImperativeHandle(ref, () => {
        return {
          clearAll: () => {
            if (!minCount) {
                setData([])
                return
            }
            const allValues = getAllValues(options)
            const newData = allValues.filter(item => data.includes(item)).slice(0, minCount)
            setData([ ...newData ])
          },
          selectAll: () => {
            const allValues = getAllValues(options)
            setData([ ...allValues ])
          },
          getValue: () => {
            return [ ...data ] || []
          },
        }
    })

    const treeCls = classnames({
        [styles.tree]: true,
        [styles.border]: ancestorBordered === false ? false : bordered,
    })

    const [ data, setData ] = useState<string[]>(value)

    useEffect(() => {
        setData([ ...value ])
    }, [ value ])

    const onItemClick = (item: OptionItem) => {
        // 单选
        if (!multiple) {
            setData([ item.value || '' ])
            onChange?.([ item.value || '' ])
            return
        }

        let newData = [ ...data ]
        const isParent = Array.isArray(item?.children) && !!item?.children?.length

        // 父节点
        if (isParent) {
            const childrenKeys = getLeafs(item)
            const isSelectAll = intersection(newData, childrenKeys)?.length === childrenKeys?.length
            // 当前有选中的 -> 取消选中
            if (isSelectAll) {
                // newData 中只有 children
                if (isSuperArray(childrenKeys, newData) && minCount) {
                    newData = childrenKeys.filter(c => newData.includes(c)).slice(0, minCount)
                } else {
                    newData = newData.filter(d => !childrenKeys.includes(d))
                }
            }
            // 当前没有选中的 -> 全选操作
            else {
                newData = uniq([ ...newData, ...childrenKeys ])
            }
        } 
        // 叶子节点
        else {
            // 之前包含 -> 取消选中
            if (data.includes(item.value || '')) {
                newData = newData.filter(d => d !== item.value)
            }
            // 之前不包含 -> 选中
            else {
                newData.push(item.value || '')
            }
        }
        
        setData(newData)
        onChange?.(newData)
    }

    return (
        <div className={treeCls} style={{ ...style }}>
            {
                options.map(item => {
                    return (
                        <TreeItem 
                            key={item.value}
                            itemData={item}
                            value={data}
                            onClick={onItemClick}
                            minCount={minCount}
                            maxCount={maxCount}
                            level1MaxCount={level1MaxCount}
                            multiple={multiple}
                            bordered={bordered}
                            options={options}
                        />
                    )
                })
            }
        </div>
    )
})

function getAllValues(options: OptionItem[]) {
    const allValues: string[] = []

    const iterator = function (arr: OptionItem[] = [], cb) {
        // eslint-disable-next-line
        for (let i = 0; i < arr.length; i++) {
            cb.call(arr[i], arr[i])
        }
    }

    const handleValue = function (item: OptionItem) {
        if (!item?.children?.length) {
            allValues.push(item.value || '')
        }

        if (item?.children?.length) {
            iterator(item.children, handleValue)
        }
    }

    iterator(options, handleValue)
    return allValues
}
