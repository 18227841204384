import React, { useLayoutEffect, useRef } from 'react'
import { CloseOutlined } from 'druikit';
import styles from './Modal.module.scss'
import { useModalExportDisabled, useModalRequests } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { headerHeight } from 'configs/css';
import ResellerRanking from './ResellerRanking';
import { ExportDropdown } from 'componentsv2/ExportDropdown';
import { ClockIcon } from 'assets/icons';
import { usePageDate } from 'hooks/usePageDate';
import { formatDateStr2 } from 'utils/dateUtils';
import { cloneDeep } from 'lodash';
import { downloadFileByAxios } from 'export';
import { platformAnalyticsExportFilename } from 'utils/exportUtils';
import classNames from 'classnames';

const exportModalSelector = 'platform-analytics-modal-export'
const exportFilename = platformAnalyticsExportFilename

export const getActualHeight = () => {
    const tableContentEl = document.querySelector('.sellers-modal-table > div')

    if (tableContentEl) {
        const height = tableContentEl?.getBoundingClientRect()?.height

        const pageEle = document.querySelector('.page-container')
        // 小于页面高度
        if(pageEle && pageEle.getBoundingClientRect().height > height){
            return 0
        }

        return height
    }

    return 0
}

export const Modal = ({
    onClose,
    apiData,
    apiPayload,
    categoryName,
}: {
    onClose?: () => void;
    apiData?: any;
    apiPayload?: any;
    categoryName?: string;
}) => {
    const [ , setModalExportDisabled ] = useModalExportDisabled()
    const [ modalRequests ] = useModalRequests()
    const { pageDate } = usePageDate()

    useDeepCompareEffect(() => {
        if (Object.values(modalRequests || {}).every(item => item === false)) {
            setModalExportDisabled(false)
        } else {
            setModalExportDisabled(true)
        }
    }, [ modalRequests, {} ])

    const contentRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)
    const leftRef = useRef<HTMLDivElement>(null)
    const bottomRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const drag = contentRef.current
        const headerEl = headerRef.current
        const leftEl = leftRef.current
        const bottomEl = bottomRef.current
        if (!drag || !headerEl || !leftEl || !bottomEl) return

        const handleMousedown = (event) => {
			const x = event.clientX - drag.offsetLeft
			const y = event.clientY - drag.offsetTop
			document.onmousemove = function(event) {
				event.preventDefault()
				let moveX = event.clientX - x;
				let moveY = event.clientY - y;
				drag.style.left = moveX + 'px'
                drag.style.top = moveY + 'px'
			}
			document.onmouseup = function(event) {
				// 多次点击会注册多次事件造成内存泄漏
				document.onmousemove = null;
				document.onmouseup = null;
			}
        }

        headerEl.addEventListener('mousedown', handleMousedown)
        leftEl.addEventListener('mousedown', handleMousedown)
        bottomEl.addEventListener('mousedown', handleMousedown)

        return () => {
            if (!drag || !headerEl || !leftEl || !bottomEl) return
            headerEl.removeEventListener('mousedown', handleMousedown)
            leftEl.removeEventListener('mousedown', handleMousedown)
            bottomEl.removeEventListener('mousedown', handleMousedown)
        }
    }, [])

    const onExportExcel = async () => {
        const payload = cloneDeep(apiPayload)
        payload.url = payload.url.replace('/dashboard', 'dashboard')
        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    return (
        <div 
            className={styles.wrapper} 
            style={{ top: headerHeight + 5 }} 
            ref={contentRef}
        >
            <div className={styles.mask}></div>
            <div className={styles.header} ref={headerRef}>
                <div className={styles.iconWrapper} onClick={onClose}>
                    <CloseOutlined />
                </div>
            </div>
            <div className={styles.left} ref={leftRef} />
            <div className={styles.bottom} ref={bottomRef} />
            <div 
                className={styles.body}
                id={exportModalSelector}
                onMouseDown={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <div className={styles.form}>
                    <div className={styles.count}>
                        {Object.keys(apiData || {})?.length || 0}
                    </div>
                    <div className={styles.formContent}>
                        <div className={styles.mainInfo}>
                            {`${categoryName} - Germany`}
                        </div>
                        <div className={styles.dateWrapper}>
                            <ClockIcon right={4} />{formatDateStr2(pageDate, true)}
                        </div>
                    </div>
                    <div className={styles.flex1} />
                    <div>
                        <ExportDropdown 
                            fileName={exportFilename}
                            selector={`#${exportModalSelector}`}
                            onExportExcel={onExportExcel}
                            calcJpgHeightFn={getActualHeight}
                            adjustElementFn={ele => {
                                const height = getActualHeight()
                                if (height !== 0) {
                                    const wrapper = ele.querySelector('.sellers-modal-table') as HTMLDivElement
                                    if (wrapper) wrapper.style.height = `${height}px`
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={classNames(styles.table, 'sellers-modal-table')}>
                    <ResellerRanking apiData={apiData} />
                </div>
            </div>
        </div>
    )
}
