import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { TableCategoryCell, TableOptionsCell } from 'componentsv2/TradingTableV2'
import { NornaTab, NornaTable } from 'componentsv2/TradingTableV3'
import { CategoryCompetitorSwitch } from 'componentsv2/business/CategoryCompetitorSwitch'
import { SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME } from 'configs'
import { Box, Button, Flex, Flex1, Spin } from 'druikit'
import { getExportId, getExportSelector } from 'utils'
import { getExportFilenameByModule } from 'utils/exportUtils'
import { MARKET_VENDOR, SELECTED_CATEGORY, SELECTED_VENDORS } from 'consts'
import { useCsvDataModuleData, useSoldOutVendorsModuleData } from 'features/filters/moduleDataSlice.hook'
import { useLoadingBar } from 'hooks'
import { useDashboardComparison } from 'hooks/api/useDashboardTableData'
import { CSV_DATA_SOLD_OUT_ANALYTICS, SOLD_OUT_VENDORS_TABLE } from 'features/filters/moduleDataSlice.const'
import { useFilterCategory, useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { useLookbook } from 'hooks/useLookbook'
import { TabItemWithChildProps } from 'componentsv2/TradingTable/types'
import { getAllFilterCategoryValue } from 'utils/filterUtils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { getExpandedKeysByMetricTab, getSubExpandedKeysByMetricTab } from 'utils/dashboardPageUtils'
import { geneCsvData, handleTableData, isLockCell, isShowNotAllowedIcon } from './utils'
import GraphModal from '../GraphModal'
import { cloneDeep } from 'lodash'
import { downloadFileByAxios } from 'export'
import Thead from './Thead'
import classNames from 'classnames'
import { useScrollBar2 } from 'hooks/useScrollBar'
import * as config from './config'
import styles from './styles.module.scss'
import { TableSizeCell } from './TableSizeCell'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined'
import { numberUtils } from 'norna-uikit'

const exportId = getExportId(SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME)
const exportSelector = getExportSelector(SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME)
const exportFilename = getExportFilenameByModule(SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME)

const Table = () => {
    useLoadingBar()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCategory ] = useFilterCategory()
    const [ filterCompetitor ] = useFilterCompetitor()
    const { isLookbook } = useLookbook()
    const expandedRowKeysRef = useRef<string[]>([])
    const subExpandedRowKeysRef = useRef<string[]>([])
    const [ , setRefresh ] = useState({})

    const [ moduleData, setModuleData ] = useSoldOutVendorsModuleData()

    const {
        isCompetitorView,
        fetchPayload,
    } = moduleData

    const isLockTableCell = useCallback(({
        vendorCode,
        categoryName,
    }: {
        vendorCode: string;
        categoryName: string;
    }) => {
        return isLockCell({
            vendorCode,
            categoryName,
            region: filterCountry,
            selectedVendorCodeList: filterCompetitor.map(c => c.vendor),
            selectedCategoryList: filterCategory,
        })
    }, [ filterCountry, filterCompetitor, filterCategory ])

    const columns: any[] = [
        {
            dataIndex: 'options',
            width: config.optionsWidth,
            left: config.tabWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell isDeepBorderRight />
                    )
                }

                const options = numberUtils.formatNumber(record?.Numbers?.value, { isCommaSymbol: true, decimal: 0 })
                const changePercent = numberUtils.formatNumber(record?.Numbers?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
                return (
                    <TableOptionsCell
                        text={options}
                        changePercent={changePercent}
                        record={record}
                        width={config.optionsWidth}
                        metricName="All"
                        isDeepBorderRight
                    />
                )
            },
        },
        {
            dataIndex: 'categoryKey',
            width: config.categoryWidth,
            left: config.tabWidth + config.optionsWidth,
            render: (text, record) => {
                return (
                    <TableCategoryCell
                        text={isCompetitorView ? record.categoryName : record.vendorName}
                        record={record}
                        width={config.categoryWidth}
                        isCompetitorView={isCompetitorView}
                        isDeepBorderRight
                    />
                )
            },
        },

        {
            dataIndex: 'soldOutValue',
            width: config.sizeWidth,
            render: (text, record) => {
                if (record?.isLock) {
                    return (
                        <TableLockCell isShadowBg />
                    )
                }

                return (
                    <TableSizeCell 
                        config={config}
                        record={record}
                        fieldName="original_sold_out"
                        isFirstMetric
                        isShadowBg
                    />
                )
            },
        },

        {
            dataIndex: '<sValue',
            width: config.sizeWidth,
            render: (text, record) => {
                if (isLockTableCell(record) || record?.isLock) {
                    const showNotAllowedIcon = isShowNotAllowedIcon({ categoryName: record.categoryName, selectedCategoryList: filterCategory })
                    return <TableLockCell isDeepBorderRight showNotAllowedIcon={showNotAllowedIcon} categoryName={record.categoryName} />
                }

                return (
                    <TableSizeCell 
                        config={config}
                        record={record}
                        fieldName="<S"
                        metricName="<S"
                        metricDisplayName="XS and smaller"
                    />
                )
            },
        },

        {
            dataIndex: 'sValue',
            width: config.sizeWidth,
            render: (text, record) => {
                if (isLockTableCell(record) || record?.isLock) {
                    const showNotAllowedIcon = isShowNotAllowedIcon({ categoryName: record.categoryName, selectedCategoryList: filterCategory })
                    return <TableLockCell isDeepBorderRight showNotAllowedIcon={showNotAllowedIcon} categoryName={record.categoryName} />
                }

                return (
                    <TableSizeCell 
                        config={config}
                        record={record}
                        fieldName="S"
                        metricName="S"
                        metricDisplayName="S"
                    />
                )
            },
        },

        {
            dataIndex: 'mValue',
            width: config.sizeWidth,
            render: (text, record) => {
                if (isLockTableCell(record) || record?.isLock) {
                    const showNotAllowedIcon = isShowNotAllowedIcon({ categoryName: record.categoryName, selectedCategoryList: filterCategory })
                    return <TableLockCell isDeepBorderRight showNotAllowedIcon={showNotAllowedIcon} categoryName={record.categoryName} />
                }

                return (
                    <TableSizeCell 
                        config={config}
                        record={record}
                        fieldName="M"
                        metricName="M"
                        metricDisplayName="M"
                    />
                )
            },
        },

        {
            dataIndex: 'lValue',
            width: config.sizeWidth,
            render: (text, record) => {
                if (isLockTableCell(record) || record?.isLock) {
                    const showNotAllowedIcon = isShowNotAllowedIcon({ categoryName: record.categoryName, selectedCategoryList: filterCategory })
                    return <TableLockCell isDeepBorderRight showNotAllowedIcon={showNotAllowedIcon} categoryName={record.categoryName} />
                }

                return (
                    <TableSizeCell 
                        config={config}
                        record={record}
                        fieldName="L"
                        metricName="L"
                        metricDisplayName="L"
                    />
                )
            },
        },

        {
            dataIndex: 'xlValue',
            width: config.sizeWidth,
            render: (text, record) => {
                if (isLockTableCell(record) || record?.isLock) {
                    const showNotAllowedIcon = isShowNotAllowedIcon({ categoryName: record.categoryName, selectedCategoryList: filterCategory })
                    return <TableLockCell isDeepBorderRight showNotAllowedIcon={showNotAllowedIcon} categoryName={record.categoryName} />
                }

                return (
                    <TableSizeCell 
                        config={config}
                        record={record}
                        fieldName="XL"
                        metricName="XL"
                        metricDisplayName="XL"
                    />
                )
            },
        },

        {
            dataIndex: '>xlValue',
            width: config.sizeWidth,
            render: (text, record) => {
                if (isLockTableCell(record) || record?.isLock) {
                    const showNotAllowedIcon = isShowNotAllowedIcon({ categoryName: record.categoryName, selectedCategoryList: filterCategory })
                    return <TableLockCell isDeepBorderRight showNotAllowedIcon={showNotAllowedIcon} categoryName={record.categoryName} />
                }
                
                return (
                    <TableSizeCell 
                        config={config}
                        record={record}
                        fieldName=">XL"
                        metricName=">XL"
                        metricDisplayName="XXL and larger"
                    />
                )
            },
        },
    ]

    /* ******************************************************************* */
    const { data, loading } = useDashboardComparison({ moduleName: SOLD_OUT_VENDORS_TABLE })

    const [ metricsTab1, setMetricsTab1 ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata1, setTabledata1 ] = useState<Array<any>>([])

    const [ metricsTab2, setMetricsTab2 ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata2, setTabledata2 ] = useState<Array<any>>([])
    const [ , setCsvData ] = useCsvDataModuleData()

    const handleData = (data: any) => {
        // 数据不存在直接返回
        if (!data) {
            return {}
        }

        if (isCompetitorView) {
            const {
                tabledata: tabledata1,
                metricsTab: metricsTab1,
            } = handleTableData({
                apiData: data,
                isCompetitorView: true,
                competitorValue: filterCompetitor.map(seller => seller.vendor),
                categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
                expandedRowKeys: expandedRowKeysRef.current,
                subExpandedRowKeysRef: subExpandedRowKeysRef.current,
                region: filterCountry,
            })
            setTabledata1(tabledata1)
            setMetricsTab1(metricsTab1)
            setCsvData({
                [CSV_DATA_SOLD_OUT_ANALYTICS]: geneCsvData({ dataSource: tabledata1, isCompetitorView }),
            })
            return
        }

        const {
            tabledata: tabledata2,
            metricsTab: metricsTab2,
        } = handleTableData({
            apiData: data,
            isCompetitorView: false,
            competitorValue: filterCompetitor.map(seller => seller.vendor),
            categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
            expandedRowKeys: expandedRowKeysRef.current,
            subExpandedRowKeysRef: subExpandedRowKeysRef.current,
            region: filterCountry,
        })
        setTabledata2(tabledata2)
        setMetricsTab2(metricsTab2)
        setCsvData({
            [CSV_DATA_SOLD_OUT_ANALYTICS]: geneCsvData({ dataSource: tabledata2, isCompetitorView }),
        })
    }

    useDeepCompareEffect(() => {
        if (!data) return

        handleData(data)
    }, [ data, filterCompetitor, isCompetitorView, expandedRowKeysRef.current, subExpandedRowKeysRef.current, {} ])

    /* ************************ 表格展开项 ************************** */
    useDeepCompareEffect(() => {
        if (expandedRowKeysRef.current?.length) {
            const firstLevelCategory = getFirstLevelCategories({ selectedCategories: filterCategory })
            firstLevelCategory.push(SELECTED_CATEGORY)
            const vendorList = filterCompetitor.map(f => f.vendor)
            let expandedRowKeys = expandedRowKeysRef.current
            expandedRowKeys = expandedRowKeys.filter(item => {
                const code = item.split('_')[0]
                if (isCompetitorView) {
                    return vendorList.includes(code)
                }
                return firstLevelCategory.includes(code)
            })

            expandedRowKeysRef.current = [ ...expandedRowKeys ]
            setRefresh({})
        }
        if (subExpandedRowKeysRef.current?.length) {
            let subExpandedRowKeys = subExpandedRowKeysRef.current
            subExpandedRowKeys = subExpandedRowKeys.filter(item => {
                const arr = item.split('__')
                if (arr.length === 1) {
                    return true
                }
                return filterCategory.includes(arr[1])
            })

            subExpandedRowKeysRef.current = [ ...subExpandedRowKeys ]
            setRefresh({})
        }
        // eslint-disable-next-line
    }, [filterCategory, filterCompetitor, isCompetitorView])

    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        payload.query.by_category = !isCompetitorView
        if (payload?.data?.groups[SELECTED_VENDORS]) {
            payload.data.competitors = payload.data.competitors.filter(item => item !== MARKET_VENDOR.vendor)
        }

        if (isCompetitorView && payload?.data?.groups?.[SELECTED_VENDORS] && !filterCompetitor.map(item => item.vendor).includes(SELECTED_VENDORS)) {
            delete payload?.data?.groups?.[SELECTED_VENDORS]
        }

        payload.query.has_selected_vendors = filterCompetitor.map(s => s.vendor).includes(SELECTED_VENDORS)

        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    useScrollBar2('.sold-out-dashboard-table-header', '.sold-out-dashboard-table-content', {})

    const [ graphModalVisible, setGraphModalVisible ] = useState(false)

    const tableWidth = config.getTableScrollWidth()

    return (
        <div id={exportId}>
            <Flex alignItems="flex-end">
                <ModuleTitle
                    type="Assortment"
                    title={SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME}
                    showComparisonDate
                />
                <Box width={30} />
                <ExportDropdown
                    right
                    selector={exportSelector}
                    fileName={exportFilename}
                    onExportExcel={onExportExcel}
                />
                <Button 
                    type="danger"
                    onClick={() => setGraphModalVisible(true)}
                >
                    Sold out graph
                </Button>
                <Flex1 />
                <CategoryCompetitorSwitch
                    value={isCompetitorView}
                    onChange={value => {
                        setModuleData({ isCompetitorView: value })
                    }}
                />
            </Flex>
            <Box height={20} />
            <div style={{ color: '#666', display: 'flex', alignItems: 'center', gap: 6, fontSize: 14 }}>
                <InfoOutlined
                    size={16}
                    color="#bbb"
                />
                <div>
                    Gender: children is not included in this analytics.
                </div>
            </div>
            <Box height={20} />
            <Spin 
                spinning={loading}
            >
                <Thead width={tableWidth} />
                {
                    isCompetitorView ? (
                        <CompetitorView
                            tableWidth={tableWidth - config.tabWidth}
                            columns={columns}
                            data={tabledata1}
                            tab={metricsTab1}
                            setMetricsTab={setMetricsTab1}
                            onTabChange={(keys, subKeys) => {
                                expandedRowKeysRef.current = keys
                                subExpandedRowKeysRef.current = subKeys
                            }}
                        />
                    ) : (
                        <CategoryView
                            tableWidth={tableWidth - config.tabWidth}
                            columns={columns}
                            data={tabledata2}
                            tab={metricsTab2}
                            setMetricsTab={setMetricsTab2}
                            onTabChange={(keys, subKeys) => {
                                expandedRowKeysRef.current = keys
                                subExpandedRowKeysRef.current = subKeys
                            }}
                        />
                    )
                }
            </Spin>
            {
                graphModalVisible && (
                    <GraphModal 
                        onClose={() => setGraphModalVisible(false)}
                    />
                )
            }
            <div style={{ width: 1, height: 70, background: 'transparent' }} />
        </div>
    )
}

export const CompetitorView = ({
    columns,
    data,
    tab,
    setMetricsTab,
    onTabChange,
    tableWidth,
}) => {
    const [ cateHeight, setCateHeight ] = useState(0)
    const [ expendHeight, setExpendHeight ] = useState(0)
    const [ closeHeight, setCloseHeight ] = useState(0)

    const metricTabFn = a => {
        const metricsTab1: Array<TabItemWithChildProps> = a || tab
        const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
        let closeHeightA = closeHeight
        if (exHeight.length >= 1) {
            if (exHeight[0].getBoundingClientRect().height < 10) {
                closeHeightA = exHeight[0].getBoundingClientRect().height
            }
        }
        let index = metricsTab1.findIndex(n => n.active === true)
        setCateHeight(43)
        let subIndex = 0
        if (index > -1) {
            metricsTab1[index].children.filter(n => n.active).forEach(item => {
                subIndex += item.categorycount
            })
        }
        index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
        subIndex += index
        setExpendHeight((subIndex + 1) * 43)
        setCloseHeight(closeHeightA)
        setMetricsTab(metricsTab1)

        const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
        onTabChange?.(expandedKeys, [])
    }

    useEffect(() => {
        metricTabFn(null)
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classNames([ styles.metricsContent, 'sold-out-dashboard-table-content' ])}>
            <NornaTab
                showHeader={false}

                categoryToVendor={true}
                metricsTab={tab}
                cateHeight={cateHeight}
                expendHeight={expendHeight}
                metricTabFn={metricTabFn}
                isDashboard={true}
            />
            <NornaTable
                style={{ width: tableWidth }}
                showHeader={false}
                dataSource={data}
                columns={columns}

                isTd={true}
                metricTabFn={metricTabFn}
                metricsTab={tab}
                categoryToVendor={true}
                key="competitorview"
            />
        </div>
    )
}

export const CategoryView = ({
    columns,
    data,
    tab,
    setMetricsTab,
    onTabChange,
    tableWidth,
}) => {
    const [ cateHeight, setCateHeight ] = useState(0)
    const [ expendHeight, setExpendHeight ] = useState(0)
    const [ closeHeight, setCloseHeight ] = useState(0)

    const metricTabFn = a => {
        const metricsTab1: Array<TabItemWithChildProps> = a || tab
        const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
        let closeHeightA = closeHeight
        if (exHeight.length >= 1) {
            if (exHeight[0].getBoundingClientRect().height < 10) {
                closeHeightA = exHeight[0].getBoundingClientRect().height
            }
        }
        let index = metricsTab1.findIndex(n => n.active === true)
        setCateHeight(43)
        let subIndex = 0
        if (index > -1) {
            metricsTab1[index].children.filter(n => n.active).forEach(item => {
                subIndex += item.categorycount
            })
        }
        index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
        subIndex += index
        setExpendHeight((subIndex + 1) * 43)
        setCloseHeight(closeHeightA)
        setMetricsTab(metricsTab1)

        const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
        const subExpandedKeys = getSubExpandedKeysByMetricTab(metricsTab1)
        onTabChange?.(expandedKeys, subExpandedKeys)
    }

    useEffect(() => {
        metricTabFn(null)
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classNames([ styles.metricsContent, 'sold-out-dashboard-table-content' ])}>
            <NornaTab
                showHeader={false}

                categoryToVendor={false}
                metricsTab={tab}
                cateHeight={cateHeight}
                expendHeight={expendHeight}
                metricTabFn={metricTabFn}
                isDashboard={true}
            />
            <NornaTable
                style={{ width: tableWidth }}
                showHeader={false}
                dataSource={data}
                columns={columns}

                isTd={true}
                metricTabFn={metricTabFn}
                metricsTab={tab}
                categoryToVendor={false}
                key="categoryview"
            />
        </div>
    )
}

export default Table
