import React from 'react'
import { Collapse } from '../Collapse'
import { Descriptions } from '../Descriptions'

export const CategoryDetails = ({
    mainCategory = '',
    subCategory = '',
    bottom = 0,
}: {
    mainCategory?: string;
    subCategory?: string;
    bottom?: number;
}) => {
    return (
        <Collapse title="Category details" bottom={bottom}>
            <Descriptions column={3}>
                <Descriptions.Item label="MAIN CATEGORY" value={mainCategory} />
                <Descriptions.Item label="SUB CATEGORY" value={subCategory} />
            </Descriptions>
        </Collapse>
    )
}
