import { MARKET_VENDOR } from 'consts'
import { clone } from 'lodash'
import { ObjectType, SellerType } from 'types'

/**
 * empty `brand` value
 */
export const BRAND_EMPTY_VAL = ''
/**
 *  expand 
 * 
 * `[ {vender,brands:['brand1','brand2']} ]` 
 * 
 *  to 
 *  `[ {vender,brand:'brand1'},{vender,brand:'brand2'} ]` 
 * 
 * @param competitors 
 * @param region 
 * @returns 
 */
export const formatCompetitorsFromStore = (competitors: Array<SellerType>, region = '') => {
    try {

        if (competitors?.length) {
            return competitors.map((competitor: SellerType) => {
                let brand: undefined | string

                // const isAllBrands = isSelectedAllBrands(competitor.vendor, competitor.brands);
                /**
                 * ## expand brands
                 * 
                 * 1. not select all brand
                 * 2. brands exists
                 * 
                 */
                if (competitor?.brands?.length) {
                    return competitor?.brands.map((brand: string) => ({
                        brand:brand||BRAND_EMPTY_VAL,
                        region,
                        vendor: competitor.vendor,
                    }))
                }
                // const index = isAllBrands;
                // if (isAllBrands) {
                //     brand = ALL_BRANDS_VAL
                // }
                return {
                    brand:brand||BRAND_EMPTY_VAL,
                    // market region default value is `MARKET_VENDOR.region`
                    region: competitor.vendor === MARKET_VENDOR.vendor ? MARKET_VENDOR.region : region,
                    vendor: competitor.vendor,
                }
            }).flat(2)

        }
    } catch (e) {
        console.log(e)
        
        return []
    }
    return []

}

/**
 *  merge 
 *  `[ {vender,brand:'brand1'},{vender,brand:'brand2'} ]`
 *  to 
 *  `[ {vender,brands:['brand1','brand2']} ]` 
 * 
 * @param competitors 
 * @param region 
 * @returns 
 */
type MergeVendorType = Omit<SellerType, 'brands'> & { brand: string };

export const mergeCompetitorsToStore = (sellers: Array<MergeVendorType>) => {

    const values = Object.values(sellers.reduce((res: ObjectType<SellerType>, seller: MergeVendorType) => {

        if (!res[seller.vendor]) res[seller.vendor] = clone(seller)
        if (!res[seller.vendor].brands) {
            res[seller.vendor].brands = []
        }
        (res[seller.vendor].brands as Array<string>).push(seller.brand) //eslint-disable-line
        return res
    }, {}))

    return values

}

/**
 *  format `brand@vendor`
 * @param brand    brand name
 * @param vendor   vendor name
 * @returns 
 */
export const formatBrandVendor = (brand: string | null | undefined, vendor: string) => (brand ? brand + ' @ ' : '') + vendor

/**
 *  format `vendor_brand`
 * @param brand    brand name
 * @param vendor   vendor name
 * @returns 
 */
export const formatBrandVendorForSearchOption = (brand: string | null | undefined, vendor: string) => vendor + (brand ? '_' + brand : '')

/**
 * 2022/04/15 需求
 * 
 * 由
 *      A [1,2,3,4,5]
 *      B [2,3,4,5,6,7]
 *      A selected [1,2,3]
 * 得出
 *      B selected [2,3,6,7]
 * 
 * 如何计算出 B 选中值？
 * 1. A 选中的 aSelected = [1,2,3]
 * 2. B 和 A 交集 c1 = [2,3,4,5]
 * 3. c1 再和 A 中选中的求交集 c2 = [2,3]
 * 4. B 有的 A 没有的 c3 = [6,7]
 * 5. c2 和 c3 的并集得出 b 选中的 bSelected = [2,3,6,7]
 * 
 * 特殊情况：
 * 当 aSelected 一个没选时，bSelected 也返回空
 */
export function algoCompetitor({
    a = [],
    b = [],
    aSelected = [],
}: {
    a: string[];
    b: string[];
    aSelected: string[];
}): string[] {
    // 特殊情况，如果 a 一个都没选中，b 也返回空数组
    if (aSelected.length === 0) return []

    // 01 b 有的 a 也有的，即 b 和 a 的交集
    const c1 = b.filter(item => a.includes(item))

    // 02 b 和 a 交集，再和 a 选中的求交集
    const c2 = c1.filter(item => aSelected.includes(item))

    // 03 b 有的 a 没有的
    const c3 = b.filter(item => !a.includes(item))

    const bSelected = [ ...c2, ...c3 ]
    return bSelected
}

