import { updateDisableLazyLoad } from 'features/filters/memoDataSlice'
import { useDispatch } from 'react-redux'

/**
 * ## 控制是否元素产品卡片图使用懒加载
 * @returns 
 */
export default function useDisableLazyLoad() {
    const dispatch = useDispatch()
    return {
        disableLazyLoad: (flag: boolean) => dispatch(updateDisableLazyLoad(flag)),
    }
}
