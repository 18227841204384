import React, { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useApiData, useApiLoading, useDynamicDashboardRequests, usePovValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import { Flex } from 'druikit'
import { handleCardDataSource } from 'pages/DynamicDashboard/utils'
import { MetricBarChartCard, MetricCard, MetricProductCard, percentFormat } from '../MetricCard'
import { MetricNav } from '../MetricNav'
import { useFetchProductNewnessIds, useFetchWeeklyNewness } from 'pages/DynamicDashboard/hooks/useApi'
import { MetricWeeklyNewnessBarChartCard } from '../MetricCard/MetricWeeklyNewnessBarChartCard'
import { handleNewnessSplitBarChartData, handleWeeklyNewnessBarChartData } from './utils'
import { useFilterCurrency } from 'hooks/useFilter'
import { getTooltipText } from 'configs/tooltip'
import { DYNAMIC_DASHBOARD_TOOLTIP_PREFIX, NEWNESS_INDEX, NEW_OPTIONS } from 'pages/DynamicDashboard/const'
import { numberUtils } from 'norna-uikit'

export const ModalProductNewness = () => {
    const [ apiData ] = useApiData()
    const [ apiLoading ] = useApiLoading()
    const [ currency ] = useFilterCurrency()
    const [ povValue ] = usePovValue()
    const [ cardDataSource, setCardDataSource ] = useState<any[]>([])
    const [ newnessSplitBarChartData, setNewnessSplitBarChartData ] = useState<any[]>([])
    const [ weeklyNewnessBarChartData, setWeeklyNewnessBarChartData ] = useState<any[]>([])
    const [ weeklyNewnessBarChartProductData, setWeeklyNewnessBarChartProductData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const metricList = [ 'Options #', 'Product Newness Average price', 'Intro mix' ]
        const cardDataSource = handleCardDataSource({ apiData, metricList, currency, customerVendor: povValue })
        setCardDataSource([ ...cardDataSource ])

        const newnessSplitBarChartData = handleNewnessSplitBarChartData(cardDataSource)
        setNewnessSplitBarChartData([ ...newnessSplitBarChartData ])
    }, [ apiData, currency, povValue, {} ])

    useFetchWeeklyNewness()
    useFetchProductNewnessIds()

    const [ requests ] = useDynamicDashboardRequests()
    const { 
        newnessWeeklyApiData: weeklyNewnessData,
        newnessWeeklyApiLoading: weeklyNewnessLoading,

        newnessProductsApiData: products,
        newnessProductsApiLoading: productsLoading,
    } = requests

    useDeepCompareEffect(() => {
        if (!weeklyNewnessData) return
        const {
            newData: weeklyNewnessBarChartData,
            productData,
        } = handleWeeklyNewnessBarChartData(weeklyNewnessData, povValue)
        setWeeklyNewnessBarChartData([ ...weeklyNewnessBarChartData ])
        setWeeklyNewnessBarChartProductData([ ...productData ])
    }, [ weeklyNewnessData, povValue, {} ])

    return (
        <>
            <MetricNav 
                name="Product newness"
                metric="Product newness"
            />
            <Flex justifyContent="space-between">
                {
                    cardDataSource.map(item => {
                        return (
                            <MetricCard
                                style={{ width: '32%' }}
                                loading={apiLoading}
                                key={item?.title || ''}
                                title={item?.title || ''}
                                dataSource={item?.dataSource || []}
                                showUnits={item.showUnits}
                            />
                        )
                    })
                }
            </Flex>
            <Flex justifyContent="space-between" id="dynamic-dashboard-middle-graph">
                <MetricBarChartCard
                    style={{ width: '38%' }}
                    loading={apiLoading}
                    title="Newness index"
                    description={getTooltipText(DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + NEWNESS_INDEX)}
                    dataSource={newnessSplitBarChartData}
                    yAxisFormatter={percentFormat}
                />
                <MetricWeeklyNewnessBarChartCard
                    style={{ width: '60%' }}
                    loading={weeklyNewnessLoading || productsLoading}
                    title="New options"
                    description={getTooltipText(DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + NEW_OPTIONS)}
                    yAxisFormatter={value => numberUtils.formatNumber(value, { isCommaSymbol: true, decimal: 0 })}
                    dataSource={weeklyNewnessBarChartData}
                    productData={weeklyNewnessBarChartProductData}
                />
            </Flex>
            <Flex justifyContent="space-between">
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} new options`}
                    description="The number of options that you introduced and reintroduced during the selected time period”."
                    dataSource={products.customerVendorProducts || []}
                    count={products.customerVendorProducts?.length}
                    showMaxText={false}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} new options`}
                    description="The number of options that your selected competitor/s introduced and reintroduced during the selected time period. The number of options for which images are displayed is limited."
                    dataSource={products.comparisonVendorProducts || []}
                    count={products.comparisonVendorProducts?.length}
                    showMaxText={false}
                />
            </Flex>
        </>
    )
}
