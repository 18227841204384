import { selectCategory } from 'features/filters/filtersSlice'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { useSelector } from 'react-redux'
import { CATEGORY_TOTAL } from 'consts'
import { useCallback } from 'react'

/**
 * useCategories
 * 
 * 1. 选中全部二级分类，返回一级分类 [firstLevel]
 * 2. 选中部分二级分类，返回选中的二级分类 [secondLevel1, secondLevel2]
 * 
 * 特殊情况：
 * 3. 一个都没选
 *      showFirstLevel=true 时返回全部一级分类 [firstLevel1, firstLevel2, ...]
 *      showFirstLevel=false 时返回空数组 []
 * 4. 选中 CATEGORY_TOTAL 常量值，目前是 'All categories'
 *      showFirstLevel=true 时返回全部一级分类 [firstLevel1, firstLevel2, ...]
 *      showFirstLevel=false 时返回空数组 ['All categories']
 */
export const useCategories = (showFirstLevel = true) => {
    // Filter -> Category
    const filterCategory = useSelector(selectCategory)

    // 获取缓存数据，其中 cacheData.metaCategories.list 是 Filter > Category 全部选项值
    const cacheData = useSelector(getCacheData)
    const categoryList = cacheData?.metaCategories?.list || []

    let categoryArr: string[] = []
    // 一个都没勾选
    if (filterCategory.length === 0) {
        if (showFirstLevel === true) {
            categoryArr = categoryList.map(item => item.name)
        } else {
            categoryArr = []
        }
    } else

    // 勾选的是 CATEGORY_TOTAL
    if (filterCategory.find(item => item === CATEGORY_TOTAL)) {
        if (showFirstLevel === true) {
            categoryArr = categoryList.map(item => item.name)
        } else {
            categoryArr = [ CATEGORY_TOTAL ]
        }
    } else
    
    // 勾选的是一级分类，一级分类一次只能选一个
    if (cross(filterCategory, categoryList.map(item => item.name)).length > 0) {
        categoryArr = [ ...filterCategory ]
    } else

    // 剩下的只能是勾选二级分类
    {
        categoryList.forEach(item => {
            if (cross(item.list.map(item2 => item2.name), filterCategory).length > 0) {
                const crossArr = cross(item.list.map(item2 => item2.name), filterCategory)
                if (item.list.length === crossArr.length) {
                    categoryArr.push(item.name)
                } else {
                    categoryArr.push(...crossArr)
                }
            }
        })
    }

    return {
        categories:categoryArr,

        /**
         * ## find first selected categories
         * case: 
         *  1. Filter.Category is selected 
         *  2. Filter.Category not selected , return the first
         */
        getFirstSelectedCategories:useCallback(() => {
                // Filter.Category not selected 
                if(filterCategory.length===0)return categoryList.map(item => {
                    if(item.list.length)return item.list.map(subCat => subCat.name)
                    return item.name
                }).flat(2)
    
                // case: Filter.Category is selected 
                const firstSelectedCategory:Array<string> = []
                categoryList.forEach(item => {
                    if(firstSelectedCategory.length!==0)return 
                    if(item.list.length){
                        item.list.forEach(subCat => {
                            if(filterCategory.includes(subCat.name)){
                                firstSelectedCategory.push(subCat.name)
                            }
                        })
                    }else if(filterCategory.includes(item.name)){
                        firstSelectedCategory.push(item.name)
                    }
                })
                return firstSelectedCategory
            },
            [ categoryList,filterCategory ],
        ),    
    }
}

/**
 * 两个数组求交集，共同的部分
 * 
 * e.g.1 
 * cross([1, 2], [1, 3])       // [1]
 */
function cross(arr1: any[], arr2: any[]) {
    return arr1.filter(x => arr2.includes(x))
}
