import React from 'react'
import { Text } from 'components/typography'
import { ArrowIcon } from 'assets/icons/Arrow'
import { ProductPriceProps } from './types'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'
import { getOnlyShowSizeProductsForCompetitors } from 'utils/feConfig'
import { sortBy } from 'lodash'

const handleValue = value => {
  return numberUtils.formatNumberByComma(Number(value).toFixed(2))
}

export const ProductPrice = ({
  price,
  discountedPrice = null,
  currency,
  primary = false,
  accent = false,
  sizePriceRange,
  latestSizePrice,
  vendorCode = '',
}: ProductPriceProps & { convertedPrice?: any, sizePriceRange?: any, latestSizePrice?: any; vendorCode?: string }) => {
  const vendors = getOnlyShowSizeProductsForCompetitors()

  const renderPrice = (price, discountedPrice) => {
    // 原价 -> 折扣价
    if (discountedPrice && price !== discountedPrice) {
      return (
        <div className={styles.productPrice}>
          <Text
            xs
            accent={accent}
            primary={primary}
          >
            {handleValue(price)}
          </Text>
          <div className={styles.arrowIcon}>
            <ArrowIcon right />
          </div>
          <Text
            xs 
            accent={(!primary && !accent) ? true : accent}
            primary={primary}
          >
            {handleValue(discountedPrice)}
          </Text>
          <Text
            xs 
            uppercase
            className={styles.currency}
            accent={(!primary && !accent) ? true : accent}
            primary={primary}
          >
            {currency?.toLowerCase() ?? 'gbp'}
          </Text>
        </div>
      )
    }

    return (
      <div className={styles.productPrice}>
        <Text xs uppercase primary={primary} accent={accent}>
          {handleValue(price)}{' '}
          {currency?.toLowerCase() ?? 'gbp'}
        </Text>
      </div>
    )
  }
  
  // 返回价格范围
  if (vendors.includes(vendorCode)) {
    return renderPrice(price, discountedPrice)
  }

  return renderPrice(price, discountedPrice)
}
