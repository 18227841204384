import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'
import { MenuDropdownItemProps } from './types'

/**
 * MenuDropdown Item
 * @ignore
 */
export function MenuDropdownItem({ sty,customClass='', children, onClick }:MenuDropdownItemProps) {
  return (
  <div className={classNames(styles.item,customClass)} onClick={() => onClick?.()} style={sty}>
      {children}
  </div>
  )
}

MenuDropdownItem.defaultProps = {
  sty: {},
}

export default MenuDropdownItem
