import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { Spin } from 'componentsv2/Spin'
import { popularProductsArg } from 'graphql/nornaapi'
import { ProductDetailsModal } from 'components/Product/components/ProductDetailsModal'
import productStyles from 'components/Product/styles.module.scss'
import { OpenInNewButton, HideWhenOpenInNew, ShowWhenOpenInNew, OPEN_IN_NEW_DATE } from 'componentsv2/business/OpenInNewButton'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { LookbookWithoutData } from 'componentsv2/LookbookPlaceholder'
import { isSubsetPayload, UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, UPDATE_NEWCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { usePageDate } from 'hooks/usePageDate'
import { useExcludeProductsComparisonQuery, useLoadingBar, useManualLoading, useScrollToTop } from 'hooks'
import { formatProductName, getVendorNameByCode } from 'utils'
import { useFetch } from 'libs/hookRequest'
import { isSuperArray, sortVendorList } from 'utils/array'
import { useLookbook } from 'hooks/useLookbook'
import { downloadFileByAxios } from 'export'
import { cloneDeep } from 'lodash'
import { SizedBox } from 'componentsv2/SizedBox'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { UseDialogType } from 'hooks/useDialog'
import { useWindowZoom } from 'hooks/useWindow'
import { isCompetitorGroupChanged } from 'utils/group'
import { formatDateStr2 } from 'utils/dateUtils'
import { productRankingExportFilename, productRankingForJustBrandsExportFilename } from 'utils/exportUtils'
import { PRODUCT_RANKING_FOR_JUST_BRANDS_TABLE_NAME, PRODUCT_RANKING_TABLE_NAME } from 'configs'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { useCompetitorOptions } from 'hooks/useOptions'
import { useModuleData } from 'hooks/useModuleData'
import { useFilterCompetitor, useFilterCountry, useFilterCurrency } from 'hooks/useFilter'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { isJustBrandsLicense, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { getDisabledFilterCompetitor } from 'utils/specialCase'
import { getDateRangeValueByPeriod } from 'componentsv2/form-elements/DatePicker'
import { Table, ProductProps, RowProps } from './components/Table'
import styles from './styles.module.scss'
import GraphButton from 'componentsv2/GraphButton'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_PRODUCT_RANKING_LAST_WEEK } from 'features/filters/moduleDataSlice.const'
import { geneCsvData } from './utils'
import { dateRangeUtils } from 'norna-uikit'

export const ProductRanking = () => {
  useScrollToTop()
  useLoadingBar()
  useWindowZoom()
  const { pageDate: dateRangeValue } = usePageDate()
  const { manualLoading, showManualLoading } = useManualLoading()
  const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
  const [ filterCurrency ] = useFilterCurrency()
  const [ filterCountry ] = useFilterCountry()
  const [ filterCompetitor ] = useFilterCompetitor()
  const { competitorOptions } = useCompetitorOptions()

  const [ moduleData, setModuleData ] = useModuleData(PRODUCT_RANKING_TABLE_NAME)
  const {
    fetchPayload = {},
    apiData = {},
    oldCompetitorValue = [],
    newCompetitorValue = [],
  } = moduleData

  /* ************************************ Lookbook ******************************************* */
  const { checked: checkedLookbooks, isLookbook } = useLookbook()
  const [ lookbookWithoutData, setLookbookWithoutData ] = useState(false)

  /* ************************************ Form ******************************************* */
  useDeepCompareEffect(() => {
    setModuleData({
      type: UPDATE_NEWCOMPETITORVALUE,
      payload: {
        competitorValue: cloneDeep(filterCompetitor.map(item => item.vendor)),
      },
    })
  }, [ filterCompetitor ])

  /* ********************************** Export excel **************************************** */
  const onExportExcel = async () => {
    const payload = cloneDeep(fetchPayload)
    payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
    payload.data.groups = getGroupsPayload({ competitorSellers: filterCompetitor })
    payload.data.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
    await downloadFileByAxios({
      filename: isJustBrandsLicense() ? productRankingForJustBrandsExportFilename : productRankingExportFilename,
      payload,
    })
  }

  /* ********************************** data **************************************** */
  const { postFn: fetch, data, loading, setData } = useFetch()
  const [ dataSource, setDataSource ] = useState<any[]>([])
  const [ , setCsvData ] = useCsvDataModuleData()

  const fetchData = () => {
    if (!competitorOptions?.length) return

    const disabledFilterCompetitor = getDisabledFilterCompetitor()
    const query = cloneDeep(comparisonQuery)
    query.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
    query.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
    query.groups = getGroupsPayload({ competitorSellers: filterCompetitor })

    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/874
    if (disabledFilterCompetitor.includes(SELECTED_VENDORS)) delete query.groups?.[SELECTED_VENDORS]
    if (disabledFilterCompetitor.includes(MARKET_VENDOR.vendor)) {
      query.market = []
      query.competitor_sellers = query.competitor_sellers.filter(s => s.vendor !== MARKET_VENDOR.vendor)
    }

    const payload = popularProductsArg(query, dateRangeUtils.from(dateRangeValue), checkedLookbooks)

    if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload }) && apiData?.popular_products) {
      showManualLoading()
      setData?.(apiData)
      return
    }

    setModuleData({
      type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE,
      payload: cloneDeep(payload),
    })
    fetch(payload.url, payload)
  }

  const handleData = apiData => {
    setLookbookWithoutData(isLookbook && Array.isArray(apiData?.popular_products) && apiData?.popular_products?.length === 0)
    if (!Array.isArray(apiData?.popular_products) || apiData?.popular_products.length === 0) return

    apiData = cloneDeep(apiData)
    if (!isLookbook) {
      apiData.popular_products = apiData.popular_products?.filter(item => newCompetitorValue.includes(item.seller.vendor))
    }

    apiData.popular_products.forEach(item => {
      const queryDateValid = isQueryDateValid({ vendor: item.seller.vendor, region: filterCountry, competitorValue: newCompetitorValue })
      if (!queryDateValid) {
        item.popular_products_with_category = []
      }
    })

    const data = sortVendorList({
      vendorList: cloneDeep(apiData.popular_products),
      vendorField: 'seller.vendor',
    })

    const dataSource = data.map(item => {
      const vendorCode = item.seller.vendor
      const vendorName = getVendorNameByCode(vendorCode)
      return item.popular_products_with_category.map(categoryData => {
        const categoryName = categoryData.category
        const productData = categoryData?.popular_products || []
        // 构建行数据
        const row: RowProps = {
          vendor: vendorCode,
          name: vendorName,
          category: categoryName,
          products: [],
        }
        if (Array.isArray(productData) && productData.length) {
          row.products = productData.map(product => ({
            brand: product?.brand,
            image: product?.image,
            name: formatProductName(product?.name),
            nornaid: product?.nornaid,
            actualPrice: product?.latest_price?.actual_price,
            originalPrice: product?.latest_price?.original_price,
            currency: filterCurrency,
            vendorCode,
          }))
        }
        return row
      })
    }).flat(3)
    setDataSource(dataSource)
    setCsvData({
      [CSV_DATA_PRODUCT_RANKING_LAST_WEEK]: geneCsvData({ dataSource }),
    })
  }

  useDeepCompareEffect(() => {
    fetchData()
  }, [ comparisonQuery, dateRangeValue, checkedLookbooks, competitorOptions ])

  useDeepCompareEffect(() => {
    if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
      fetchData()
      return
    }

    if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
      showManualLoading()
      handleData(cloneDeep(apiData))
      return
    }

    fetchData()
  }, [ newCompetitorValue ])

  useEffect(() => {
    if (!data) return

    handleData(cloneDeep(data))
    setModuleData({ apiData: cloneDeep(data) })
    // eslint-disable-next-line
  }, [data])

  /* ********************************** product detail modal **************************************** */
  const dialogRef = useRef<DialogRefType>({} as DialogRefType)

  const onClickProduct = (product: ProductProps) => {
    const closeFn = dialogRef.current?.closeDialog
    dialogRef.current?.openDialog?.('Product detail', (
      <div className={productStyles.quickView}>
        <ProductDetailsModal
          nornaid={product.nornaid}
          closeProductDetails={closeFn}
          toCurrency={filterCurrency}
          vendorCode={product.vendorCode}
        />
      </div>
    ), UseDialogType.PROUDUCT_DETAIL)
  }

  return (
    <div className={styles.container} id="export-wrapper">
      <ShowWhenOpenInNew>
        <FilterInfo />
      </ShowWhenOpenInNew>
      <ModuleTitle 
        category="ASSORTMENT" 
        title={isJustBrandsLicense() ? PRODUCT_RANKING_FOR_JUST_BRANDS_TABLE_NAME : PRODUCT_RANKING_TABLE_NAME} 
        date={isJustBrandsLicense() ? formatDateStr2(dateRangeValue) : formatDateStr2(getDateRangeValueByPeriod('oneLatestWeekAgo'))}
        subTitle={(
          <InfoBox
              iconSty={{ margin: '0 10px' }}
              containerSty={{ left: '-150px' }}
              title="title"
              text="tips content"
          >
              <TipsContent sty={{ padding: 0 }}>
                The product ranking of an individual product is achieved through a mathematical blending of three parameters, as they change on a daily basis: the individual product's website location, the product's unavailable size stock ratio and its replenish rate, with an asymmetric bias towards the website location, meaning that the website location factors in the most. 
                <br /><br />
                The better the website location is, i.e., the closer to the very beginning of the product page that a product is displayed, the higher its ranking will be. 
                <br /><br />
                On the unavailable size stock ratio, the more a product is sold out, the higher its ranking will be.
                <br /><br />
                On the replenishment rate a higher ranking score is achieved the shorter the time period is from when a product gets fully or partially out of stock, until it is replenished.
              </TipsContent>
          </InfoBox>
        )}
      />
      <SizedBox height={10} />
      <Spin spinning={loading || manualLoading}>
        <ShouldRender shouldRender={lookbookWithoutData}>
          <LookbookWithoutData />
        </ShouldRender>
        <ShouldRender shouldRender={!lookbookWithoutData}>
          <HideWhenOpenInNew>
            <div className={styles.filterTable}>
              <ExportDropdown
                right
                showExcel={!isLookbook}
                onExportExcel={onExportExcel}
                showJPG
                showCLIPBOARD
                fileName={isJustBrandsLicense() ? productRankingForJustBrandsExportFilename : productRankingExportFilename}
              />
              <GraphButton />
              <OpenInNewButton
                left={10}
                onPageParams={() => ({
                  range: dateRangeValue,
                  [OPEN_IN_NEW_DATE]: dateRangeValue,
                })}
              />
            </div>
          </HideWhenOpenInNew>
          <div className={classnames([ styles.popTable, 'norna-container-fixed-width' ])}>
            <Table 
              dataSource={dataSource}
              onClick={onClickProduct}
            />
          </div>
        </ShouldRender>
      </Spin>
      <Dialog ref={dialogRef} />
      <div style={{ width: 1, height: 70, background: 'transparent' }} />
    </div>
  )
}
