import * as config from 'configs/moduleName'
import { useModuleData } from 'hooks/useModuleData'
import { cloneDeep } from 'lodash'
import { useFetch } from 'libs'

export const useFindProductIds = ({
    moduleName,
}: {
    moduleName: string;
}) => {
    const [ moduleData ] = useModuleData(moduleName)
    const { fetchPayload = {} } = moduleData
    const { postFn: findProductIds } = useFetch()

    const fetchData = async ({
        seller,
    }: {
        seller: { vendor: string; region: string };
    }) => {
        const payload = cloneDeep(fetchPayload)
        payload.url = 'find_product_ids'
        payload.query.metric = getMetric(moduleName)
        payload.data.selected_seller = { ...seller }
        const result = await findProductIds(payload.url, payload)
        return result
    }

    return {
        fetchData,
    }
}

const getMetric = (moduleName: string) => {
    const mapper = {
        // Pricing
        [config.PRICE_ARCHITECTURE_TABLE_NAME]: 'Pricing',
        [config.DISCOUNTS_TABLE_NAME]: 'Discounts',
        // Assortment
        [config.CATEGORY_SPLIT_PIE_GRAPH_NAME]: 'Category',
        [config.COLOR_SPLIT_PIE_GRAPH_NAME]: 'Color',
        [config.MATERIAL_SPLIT_PIE_GRAPH_NAME]: 'Material',
    }
    return mapper[moduleName] || ''
}
