export function MockData(licenseList, user) {
  if (user.name === 'kappahl@norna.ai' && licenseList.length) {
    return [
      ...licenseList,
      ...[
        'XLNT',
        'Newbie',
        'Kay/day',
        'Hampton Republic 27',
      ].map(item => ({
        description: item,
        isSelected: false,
        key: 'f696dd5a-65eb-4d6f-bbc9-25d9643abe72',

      })),
    ]
  } else {
    return licenseList
  }
}
