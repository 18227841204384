import { ObjectType } from 'types'
import { formatProductName } from 'utils'

/**
 * 
 * @param index   index of product
 * @param length  total number of products
 * @param showMore  show more product or not
 * @returns       transform-origin variable  
 */
export const scaleRuleFn = (index: number, length: number, showMore: boolean, columnCount: number): string => {
    const indexNum = index + 1
    const currentFullRow = parseInt(Number(indexNum / columnCount).toFixed(0), 10)
    const totalRow = parseInt(Number(Math.floor(length / columnCount)).toFixed(0), 10) + ((length % columnCount) === 0 ? 0 : 1)

    if (!showMore) {   // show less

        if (indexNum === columnCount + 1) return 'bottom left'
        if (indexNum === columnCount * 2) return 'bottom right'
        if (indexNum > columnCount + 1 && indexNum < columnCount * 2) return 'bottom center'

    }
    // default value    
    let transformOrigin = 'center center'

    // first row
    if (indexNum < columnCount + 1) transformOrigin = 'center center'

    // first product of first row
    if (indexNum === 1) transformOrigin = 'center left'

    // last product of first row
    if (indexNum === columnCount) transformOrigin = 'center right'

    // right row, not include the last product of the first row, not include the last product of the last row
    if ((indexNum % columnCount) === 0 && indexNum !== columnCount && totalRow * columnCount !== indexNum) transformOrigin = 'center right'

    // left row, not including the first product of first row, not include the last product of last row
    if ((indexNum % columnCount) === 1 && indexNum !== 1 && (totalRow * columnCount - columnCount - 1) !== indexNum) transformOrigin = 'center left'

    let lineRowNum = length % columnCount
    if (lineRowNum === 0) lineRowNum = columnCount

    // last row  and not first row
    if (indexNum > (length - lineRowNum) && totalRow !== 1) transformOrigin = 'center center'

    // not first row, last row, first left product and not the first row
    if (totalRow > 1 && (currentFullRow + 1) === totalRow && (indexNum % columnCount === 1)) transformOrigin = 'center left'

    // last row, the last column product and not the first row
    if (totalRow > 1 && currentFullRow === totalRow && currentFullRow * columnCount === indexNum && indexNum === length) transformOrigin = 'center right'

    return transformOrigin
}

const PREFIX = 'AAAA'

// used to replace '-'
const REPLACOR = '__'

const revertOriginStr = (newStr:string,dataMap:ObjectType<string>):string => {
    const key = Object.keys(dataMap).find(key => key.indexOf(newStr)>-1)
    if(!key)return newStr

    // revert back to the original string
    const search = key.replace(new RegExp(PREFIX,'g'),'').replace(new RegExp(REPLACOR,'g'),'-')    
    return search

}
/**
 * 
 * # convert `t-s` to AAAt__sAAA
 *  
 * @param str 
 * @returns 
 */
const convertStr = (str:string):string => PREFIX+str.replace(/"/g,'').replace(new RegExp('-'),REPLACOR)+PREFIX 
/**
 *  ## search rule function 
 *  
 *   1. [ ] a+b: a and b exist
 *   2. [ ] -a: a not exists
 *   3. [ ] a-b: a exists and b not exists
 *   4. [ ] a,b,c one of a,b,c is exist 
 * 
 * @param search  search words
 * @param title   target string
 * @returns 
 */
export const searchFn = (search: string, title: string): boolean => {

    title  = formatProductName(title).toLowerCase()
    search  = search.toLowerCase()

    const quotationStr = search.match(/"(.*?)"/g)
    const searchMap = {}
    if(quotationStr&&quotationStr.length){
        quotationStr.forEach(item => {

            // '"t-s"'  replace with 't__s'
            const key = convertStr(item) 
            search = search.replace(item,key)
            searchMap[key] = item.replace(/"/g,'')
        })
    }

    let returnOrNot = false

    if (!search) returnOrNot = true

    else if (search.indexOf('+') > -1) {// a+b
        const searchItems = search.split('+').map(item => item.trim()).filter(item => item)
        returnOrNot = searchItems.filter(searchItem => title.indexOf(revertOriginStr(searchItem,searchMap)) > -1).length === searchItems.length

    } else if (search.indexOf('-') > -1) { // -a,a-b
        const searchItems = search.split('-').map(item => item.trim()).filter(item => item)

        if (search.trim().startsWith('-')) {

            returnOrNot = searchItems.filter(searchItem => title.indexOf(revertOriginStr(searchItem,searchMap)) === -1).length === searchItems.length

        } else {
            const firstOneExist = title.indexOf(searchItems[0]) > -1
            if (firstOneExist) {
                returnOrNot = searchItems.slice(1).filter(searchItem => title.indexOf(revertOriginStr(searchItem,searchMap)) === -1).length === searchItems.length - 1
            } else {
                returnOrNot = false
            }
        }

    } else if (search.indexOf(',') > -1) { // a,b,c

        const searchItems = search.split(',').filter(item => item)
        returnOrNot = !!searchItems.find(searchItem => title.indexOf(revertOriginStr(searchItem,searchMap)) > -1)

    } else { // a
        returnOrNot = title.indexOf(revertOriginStr(search,searchMap).trim()) > -1
    }

    return returnOrNot

}

export const sortByVendors = (products:any[], sortRules:Array<string>) => {
    const productObj = {}
    products.forEach((item:any) => {
        if(!productObj[item.seller.vendor])productObj[item.seller.vendor] = []
        productObj[item.seller.vendor].push(item)

    })
    
    return sortRules.map((vendor:string) => productObj[vendor]||[]).flat(2)
}
