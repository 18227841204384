import React from 'react'

import { Calendar } from './Calendar'
import { CalendarV2 } from './Calendar/CalendarV2'

/**
 * 右上角 Filter > Launched after 使用该组件
 */
export const Launched = props => <Calendar {...props} />
export const LaunchedV2 = CalendarV2
