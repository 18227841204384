import React from 'react'
import classNames from 'classnames'
import { CaretProps } from './types'
import styles from './styles.module.scss'

export const CaretOutlineIcon = ({ isExpanded }: CaretProps) => 
    <svg className={classNames(styles.caret, isExpanded && styles.flipped)} viewBox="0 0 24 24">
        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
    </svg>

