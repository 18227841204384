import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { ArrowProps } from './types'

export const CollapsibleArrowIcon = ({ collapsed }: ArrowProps) => 
    <svg
        className={classnames(styles.arrow, collapsed ? styles.down : styles.up)}
        height="24"
        viewBox="0 0 24 24"
        width="24"
    >
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>

