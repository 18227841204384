import React from 'react'
import classnames from 'classnames'
import { RectArrow } from 'componentsv2/RectArrow'
import { OPTIONS_NAME } from 'consts'
import { storage } from 'utils/storage'
import { Flex } from 'componentsv2/Flex'
import { DisplayText } from 'componentsv2/DisplayText'
import { useModuleData } from 'hooks/useModuleData'
import { DISCOUNTS_TABLE_NAME } from 'configs'
import { isCustomerVendor } from 'utils'
import styles from './styles.module.scss'
import { LockIcon } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'

export const Vendors = ({
    dataSource = [],
    onClick,
}: {
    dataSource: any[];
    onClick?: (opt: any, seller: { vendor: string; region: string }) => void;
}) => {
    const customerVendor = storage.getCustomerVendor()

    return (
        <div className={classnames([ styles.vendors, 'discounts-content' ])}>
            <FirstColumn vendorRecord={dataSource[0]} />
            {
                dataSource.map((item, index) => (
                        <Vendor
                            vendorRecord={item}
                            highlight={item.vendorCode === customerVendor}
                            index={index}
                            onClick={onClick}
                            key={item.vendorCode}
                        />
                    ))
            }
        </div>
    )
}

export const Vendor = ({
    vendorRecord,
    highlight = false,
    index,
    onClick,
}: {
    vendorRecord: any;
    highlight?: boolean;
    index: number;
    onClick?: (opt: any, seller: { vendor: string; region: string }) => void;
}) => {
    const [ moduleData ] = useModuleData(DISCOUNTS_TABLE_NAME)
    const { switchValue } = moduleData

    const Space = () => (
        <Flex className={classnames([ styles.valueItem, styles.space ])}>
            <div className={classnames([ styles.currentValueColumn, styles.normalBorderRight ])} />
            <div className={styles.comparisonValueColumn} />
            <div className={classnames([ styles.changeColumn, styles.deepBorderRight ])} />
        </Flex>
    )

    if (vendorRecord.seller.isLock) {
        return (
            <div
                className={classnames([ styles.vendor, index === 0 && isCustomerVendor(vendorRecord?.vendorCode) ? styles.firstVendor : '' ])} 
            >   
                <div style={{ width: 310, height: '100%', position: 'relative' }}>
                    <div style={{ height: 48 * 3 + 60 + 12, position: 'relative' }} className={styles.deepBorderRight}>
                        <div className={styles.lockWrapper}>
                            <LockIcon size={20} />
                        </div>
                    </div>
                    <div className={styles.border} />
                    <div style={{ height: 48 * 11 + 12, position: 'relative' }} className={styles.deepBorderRight}>
                        <div className={styles.lockWrapper}>
                            <LockIcon size={20} />
                        </div>
                    </div>
                    <div className={styles.border} />
                    <div className={styles.verticalDashedLine} />
                    <div className={styles.valueItem} style={{ position: 'relative' }}>
                        <div className={styles.lockWrapper}>
                            <LockIcon size={20} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={classnames([ styles.vendor, index === 0 && isCustomerVendor(vendorRecord?.vendorCode) ? styles.firstVendor : '' ])} 
        >
            <Space />
            <Flex className={styles.valueItem}>
                <div className={classnames([ styles.currentValueColumn, styles.normalBorderRight ])}>
                    <RectArrow
                        value={vendorRecord.disDepthCurrentValue}
                        percent={Number(vendorRecord.disDepthCurrentValue?.replace('%', ''))}
                        disabled={true}
                        highlight={highlight}
                    />
                </div>
                <div className={styles.comparisonValueColumn}>{vendorRecord.disDepthComparisonValue}</div>
                <div className={classnames([ styles.changeColumn, styles.deepBorderRight ])}>
                    <DisplayText value={vendorRecord.disDepthChange} showArrow fontSize={12} />
                </div>
            </Flex>
            <Flex className={styles.valueItem}>
                <div className={classnames([ styles.currentValueColumn, styles.normalBorderRight ])}>
                    <RectArrow 
                        value={vendorRecord.disWidthCurrentValue}
                        percent={Number(vendorRecord.disWidthCurrentValue?.replace('%', ''))}
                        disabled={true}
                        highlight={highlight}
                    />
                </div>
                <div className={styles.comparisonValueColumn}>{vendorRecord.disWidthComparisonValue}</div>
                <div className={classnames([ styles.changeColumn, styles.deepBorderRight ])}>
                    <DisplayText value={vendorRecord.disWidthChange} showArrow fontSize={12} />
                </div>
            </Flex>
            <Flex className={styles.valueItem}>
                <div className={classnames([ styles.currentValueColumn, styles.normalBorderRight ])}>
                    <RectArrow 
                        value={vendorRecord.disEffectCurrentValue}
                        percent={Number(vendorRecord.disEffectCurrentValue?.replace('%', ''))}
                        disabled={true}
                        highlight={highlight}
                    />
                </div>
                <div className={styles.comparisonValueColumn}>{vendorRecord.disEffectComparisonValue}</div>
                <div className={classnames([ styles.changeColumn, styles.deepBorderRight ])}>
                    <DisplayText value={vendorRecord.disEffectChange} showArrow fontSize={12} />
                </div>
            </Flex>
            <Flex className={classnames([ styles.valueItem, styles.rangeValueItem ])}>
                <div className={classnames([ styles.currentValueColumn, styles.normalBorderRight ])}>{vendorRecord.priceRange}</div>
                <div className={classnames([ styles.comparisonValueColumn ])}>
                    <div style={{ fontSize: '11px' }} className={classnames([ styles.colorPrimary ])}>#</div>
                </div>
                <div className={classnames([ styles.changeColumn, styles.deepBorderRight ])}>
                    <div style={{ fontSize: '11px' }}>+/- <span className={styles.colorPrimary}>%</span> {switchValue ? '' : 'units'}</div>
                </div>
            </Flex>
            <div className={styles.border} />
            <Space />
            {
                vendorRecord.data.map(d => {
                    const currentValue = switchValue ? d.countCurrentValue : d.percentageCurrentValue
                    const comparisonValue = switchValue ? d.countComparisonValue : d.percentageComparisonValue
                    const changeValue = switchValue ? d.countChangePercentage : d.percentageChange
                    return (
                        <div
                            className={styles.valueItem}
                            style={{ display: 'flex' }}
                            key={d.rangeName}
                        >
                            <div 
                                className={classnames([ styles.currentValueColumn, styles.normalBorderRight ])}
                                style={{ cursor: d?.disabled ? 'auto' : 'pointer' }}
                                onClick={() => {
                                    if (d?.disabled) return
                                    onClick?.(d, vendorRecord.seller)
                                }}
                            >
                                <RectArrow 
                                    value={currentValue}
                                    percent={Number(d?.percentageCurrentValue?.replace('%', ''))}
                                    disabled={d?.disabled}
                                    highlight={highlight}
                                />
                            </div>
                            <div className={styles.comparisonValueColumn}>{comparisonValue}</div>
                            <div className={classnames([ styles.changeColumn, styles.deepBorderRight ])}>
                                <DisplayText value={changeValue} showArrow fontSize={12} />
                            </div>
                        </div>
                    )
                })
            }
            <div className={styles.border} />
            <Flex className={styles.valueItem}>
                <div className={styles.currentValueColumn}>
                    {vendorRecord.totalCurrentValue}
                </div>
                <div className={styles.comparisonValueColumn}>{vendorRecord.totalComparisonValue}</div>
                <div className={classnames([ styles.changeColumn, styles.backgroundNone ])}>
                    <DisplayText value={vendorRecord.totalChangePercentage} showArrow fontSize={12} />
                </div>
            </Flex>
        </div>
    )
}

export const FirstColumn = ({
    vendorRecord,
}: {
    vendorRecord: any;
}) => {
    return (
        <div className={styles.firstColumn}>
            <div className={classnames([ styles.deepBorderRight, styles.space ])} />
            <div className={classnames([ styles.valueItem, styles.valueFirstItem, styles.deepBorderRight ])}>Depth</div>
            <div className={classnames([ styles.valueItem, styles.valueFirstItem, styles.deepBorderRight ])}>Width</div>
            <div className={classnames([ styles.valueItem, styles.valueFirstItem, styles.deepBorderRight ])}>Effect</div>
            <div className={classnames([ styles.valueItem, styles.valueFirstItem, styles.deepBorderRight, styles.rangeValueItem ])}>Max/min range</div>
            <div className={classnames([ styles.border, styles.valueFirstItem, styles.deepBorderRight ])} />
            <div className={classnames([ styles.deepBorderRight, styles.space ])} />
            {
                vendorRecord.data.map(d => {
                    return (
                        <div className={classnames([ styles.valueItem, styles.valueFirstItem, styles.deepBorderRight ])} key={d.rangeName}>
                            {d.rangeName}
                        </div>
                    )
                })
            }
            <div className={classnames([ styles.border ])} />
            <div className={classnames([ styles.valueItem, styles.valueFirstItem ])}>{OPTIONS_NAME}</div>
        </div>
    )
}
