import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import styles from './styles.module.scss'
import { InfiniteScrollProps } from './types'

export const InfiniteScroll = ({ hasMore, isLoading, onLoadMore }: InfiniteScrollProps) =>
    <VisibilitySensor
        active={hasMore && !isLoading}
        onChange={isVisible => {
        if (isVisible && !isLoading) {
          onLoadMore()
        }
      }}
    >
        <div className={styles['infinite-scroll']} />
    </VisibilitySensor>
