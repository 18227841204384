import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isLineGraphReportPage, isMySavedFiltersPage, isMySavedLookbooksPage, isOptionsInOutCalendarPage, isProductRankingPage, isSearchForOptionsPage } from 'global/Routes'
import { getQaData } from 'features/filters/qaDataSlice'
import { formatDateStr2 } from 'utils/dateUtils'
import { useLookbook } from 'hooks/useLookbook'
import { useLocation } from 'react-router-dom'
import { useCalendarComparisonDate, useCalendarDate, useCalendarSelectionDate } from 'hooks/useGlobalData'
import { IS_LIVE_ENV, isJustBrandsLicense } from 'consts'
import { dateRangeUtils } from 'norna-uikit'

export const usePageDate = () => {
    const { pathname } = useLocation()
    const [ calendarComparisonDate ] = useCalendarComparisonDate()
    const [ calendarSelectionDate ] = useCalendarSelectionDate()

    /* *********************************** pageDate **************************************** */
    const [ calendarDate ] = useCalendarDate()
    const [ pageDate, setPageDate ] = useState(calendarDate)

    useEffect(() => {
        if (isSearchForOptionsPage()) {
            if (!IS_LIVE_ENV) {
                setPageDate(calendarSelectionDate)
                return
            }
            const current = dateRangeUtils.last(calendarDate)
            setPageDate(current)
        } else {
            setPageDate(calendarDate)
        }
    }, [ pathname, calendarDate, calendarSelectionDate ])

    /* *********************************** 是否禁用 TimePicker **************************************** */
    const [ disabled, setDisabled ] = useState(false)
    const qaData = useSelector(getQaData)
    const { isLookbook } = useLookbook()

    useEffect(() => {
        if (
            (isSearchForOptionsPage() && qaData.qaPage) 
            || isMySavedFiltersPage() 
            || isMySavedLookbooksPage() 
            || isOptionsInOutCalendarPage() 
            || (isProductRankingPage() && !isJustBrandsLicense())
            || isLineGraphReportPage()
        ) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
        // eslint-disable-next-line
    }, [pathname, isLookbook, qaData.qaPage])

    /* *********************************** 是否显示日期范围 **************************************** */
    const [ isRange, setIsRange ] = useState(true)

    useEffect(() => {
        if (
            isSearchForOptionsPage()
        ) {
            setIsRange(false)
        } else {
            setIsRange(true)
        }
    }, [ pathname ])

    return {
        pageDate,
        comparisonPageDate: calendarComparisonDate,
        dateRangeVsStr: `${formatDateStr2(pageDate)} vs ${formatDateStr2(calendarComparisonDate)}`,
        disabled,
        isRange,
        getDisplayDate: (date?: string) => {
            return formatDateStr2(date || pageDate)
        },
        getDisplayComparisonDate: (date?: string, comparisonDate?: string) => {
            return `${formatDateStr2(date || pageDate)} vs ${formatDateStr2(comparisonDate || calendarComparisonDate)}`
        },
    }
}
