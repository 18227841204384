import { useFetch } from 'libs/hookRequest'
import { REQUEST_UPDATE_PWD } from 'consts/request'

/**
 * update data directly by python api
 */
export const useUpdatePwd = () => {
  const { patchFn:updateData,  data, loading, error } = useFetch()

  return {
    updatePwd: async password => updateData(REQUEST_UPDATE_PWD,{
      query: { password },
    }),
    data,
    loading,
    error,
  }
}
