
export const releaseNote = {
    version: '2.2.13',
    date: 'September 9th, 2024',
    content: [
        '• Customer based customization features',
        '• Report generation from dynamic dashboard',
        '• Fine tunings on the UI',
    ],
}
