import * as config from 'configs/moduleName'

export const RoutesObj = {
  HOME: { URL: '/', LABEL: 'Market overview' },
  LOGIN: { URL: '/login', LABEL: 'Sign In' },
  FORGOT_PASSWORD: { URL: '/forgot-password', LABEL: 'Forgot password' },
  DASHBOARD: { URL: '/dashboard/home', LABEL: 'Market overview' },
  DASHBOARD_ALIAS: { URL: '/dashboard/dashboard', LABEL: 'Market overview' },
  QA: { URL: '/qa', LABEL: 'QA' },
  OPTIONS_IN_OUT: { URL: '/options-in-and-out', LABEL: 'Options in out' },
  CREATE_COLLECTION: { URL: '/create-selection', LABEL: 'Create Collection' },
  PROFILE: { URL: '/profile', LABEL: 'Profile' },
  COLLECTION: { URL: '/selections', LABEL: 'Selections' },
  COMPARISONS: { URL: '/comparisons', LABEL: 'Comparisons' },
  NOTIFICATIONS: { URL: '/comparisons', LABEL: 'Notifications' },
  LOOKBOOK: { URL: '/lookbook', LABEL: 'Lookbook' },
  REPORT: { URL: '/report', LABEL: 'Report' },
  DASHBOARDREPORT: { URL: '/boardreport', LABEL: 'Board report' },
  PRODUCT_DETAILS: { URL: '/options/:id', LABEL: 'Product details' },
  MY_SAVED_FILTERS: { URL: '/dashboard/selections', LABEL: config.MY_SAVED_FILTERS_NAME },

  // Dashboard
  PRICE_INSIGHT: { URL: '/price-insight', LABEL: config.PRICE_INSIGHT_TABLE_NAME },
  ASSORTMENT_INSIGHT: { URL: '/assortment-insight', LABEL: config.ASSORTMENT_INSIGHT_TABLE_NAME },
  PRICE_SPREADS: { URL: '/price-spreads', LABEL: config.PRICE_SPREADS_TABLE_NAME },
  IN_OUT_MONITORING: { URL: '/in-out-monitoring', LABEL: config.IN_OUT_MONITORING_TABLE_NAME },
  SOLD_OUT_ANALYTICS_VENDORS: { URL: '/sold-out-analytics-vendors', LABEL: config.SOLD_OUT_ANALYTICS_VENDORS_TABLE_NAME },
  OPTIONS_IN_OUT_CALENDAR: { URL: '/options-in-and-out-by-calendar', LABEL: config.OPTIONS_IN_OUT_CALENDAR_NAME },
  DYNAMIC_DASHBOARD: { URL: '/dynamic-dashboard', LABEL: config.DYNAMIC_DASHBOARD_NAME },
  DYNAMIC_DASHBOARD_REPORT: { URL: '/dynamicdashboardreport', LABEL: config.DYNAMIC_DASHBOARD_NAME + ' report' },
  LINE_GRAPH_REPORT: { URL: '/linegraphreport', LABEL: config.LINE_GRAPH_REPORT_NAME },

  // Pricing
  PRICE_HISTORY: { URL: '/price-history', LABEL: config.PRICE_DRIVERS_TABLE_NAME },
  PRICE_DRIVERS_PERIOD_TO_PERIOD: { URL: '/price-drivers-period-to-period', LABEL: config.PRICE_DRIVERS_PERIOD_TO_PERIOD_TABLE_NAME },
  PRICE_ARCHITECTURE: { URL: '/price-architecture', LABEL: config.PRICE_ARCHITECTURE_TABLE_NAME },
  DISCOUNT_DEPTH_AND_WIDTH: { URL: '/discounts', LABEL: config.DISCOUNTS_TABLE_NAME },
  PRICING_JUMP_TABLE: { URL: '/pricing-jump-table', LABEL: config.CROSS_REGION_PRICING_NAME },
  CROSS_REGION_SOLD_OUT: { URL: '/cross-region-sold-out', LABEL: config.CROSS_REGION_SOLD_OUT_NAME },

  // Assortment
  CATEGORY_ANALYTICS: { URL: '/category-split', LABEL: config.CATEGORY_SPLIT_PIE_GRAPH_NAME },
  COLOR_ANALYTICS: { URL: '/color-split', LABEL: config.COLOR_SPLIT_PIE_GRAPH_NAME },
  MATERIAL_ANALYTICS: { URL: '/material-split', LABEL: config.MATERIAL_SPLIT_PIE_GRAPH_NAME },
  POPULAR_PRODUCTS: { URL: '/popular-options', LABEL: config.PRODUCT_RANKING_TABLE_NAME },
  POPULAR_ATTRIBUTES: { URL: '/popular-attributes', LABEL: config.ATTRIBUTE_RANKING_TABLE_NAME },
  ASSORTMENT_SIZE: { URL: '/assortment-size', LABEL: config.SIZE_OF_LINE_TABLE_NAME },
  MOST_POPULAR_LAUNCH_DAYS: { URL: '/most-popular-launch-days', LABEL: config.WEEKLY_TABLE_NAME },
  PLATFORM_ANALYTICS: { URL: '/platform-analytics', LABEL: config.PLATFORM_ANALYTICS_NAME },

  // Lookbooks
  SEARCH_OPTIONS: { URL: '/selections/nornai-analysis/list', LABEL: config.SEARCH_FOR_OPTIONS_NAME },
  LOOKBOOL_LIST: { URL: '/dashboard/lookbooks', LABEL: config.MY_SAVED_LOOKBOOKS_NAME },
}

export const isLineGraphReportPage = () => window.location.pathname === RoutesObj.LINE_GRAPH_REPORT.URL

export const isSearchForOptionsPage = () => window.location.pathname === RoutesObj.SEARCH_OPTIONS.URL
export const isMySavedFiltersPage = () => window.location.pathname === RoutesObj.MY_SAVED_FILTERS.URL
export const isMySavedLookbooksPage = () => window.location.pathname === RoutesObj.LOOKBOOL_LIST.URL

// Dashboard
export const isPriceInsightPage = () => window.location.pathname === RoutesObj.PRICE_INSIGHT.URL
export const isAssortmentInsightPage = () => window.location.pathname === RoutesObj.ASSORTMENT_INSIGHT.URL
export const isPriceSpreadsPage = () => window.location.pathname === RoutesObj.PRICE_SPREADS.URL
export const isInOutMonitoringPage = () => window.location.pathname === RoutesObj.IN_OUT_MONITORING.URL
export const isOptionsInOutCalendarPage = () => window.location.pathname === RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL
export const isDynamicDashboardPage = () => window.location.pathname === RoutesObj.DYNAMIC_DASHBOARD.URL
export const isSoldOutVendorsPage = () => window.location.pathname === RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.URL

// Pricing
export const isPriceArchitecturePage = () => window.location.pathname === RoutesObj.PRICE_ARCHITECTURE.URL
export const isDiscountsPage = () => window.location.pathname === RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL
export const isCrossRegionPricingPage = () => window.location.pathname === RoutesObj.PRICING_JUMP_TABLE.URL
export const isCrossRegionSoldOutPage = () => window.location.pathname === RoutesObj.CROSS_REGION_SOLD_OUT.URL

// Assortment
export const isCategorySplitPage = () => window.location.pathname === RoutesObj.CATEGORY_ANALYTICS.URL
export const isColorSplitPage = () => window.location.pathname === RoutesObj.COLOR_ANALYTICS.URL
export const isMaterialSplitPage = () => window.location.pathname === RoutesObj.MATERIAL_ANALYTICS.URL
export const isProductRankingPage = () => window.location.pathname === RoutesObj.POPULAR_PRODUCTS.URL
export const isAttributeRankingPage = () => window.location.pathname === RoutesObj.POPULAR_ATTRIBUTES.URL
export const isSizeOfLinePage = () => window.location.pathname === RoutesObj.ASSORTMENT_SIZE.URL
export const isMostPopularLaunchDaysPage = () => window.location.pathname === RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL
export const isPlatformAnalyticsPage = () => window.location.pathname === RoutesObj.PLATFORM_ANALYTICS.URL

const comparisonDatePages = [
  RoutesObj.PRICE_INSIGHT.URL,
  RoutesObj.ASSORTMENT_INSIGHT.URL,
  RoutesObj.PRICE_SPREADS.URL,
  RoutesObj.IN_OUT_MONITORING.URL,
  RoutesObj.DYNAMIC_DASHBOARD.URL,
  RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL,
  RoutesObj.PRICE_ARCHITECTURE.URL,
  RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL,
  RoutesObj.CATEGORY_ANALYTICS.URL,
  RoutesObj.COLOR_ANALYTICS.URL,
  RoutesObj.MATERIAL_ANALYTICS.URL,
  RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.URL,
  RoutesObj.CROSS_REGION_SOLD_OUT.URL,
]

export function isComparisonDatePages() {
  return comparisonDatePages.includes(window.location.pathname)
}

export function isNotComparisonDatePages() {
  return !isComparisonDatePages()
}

export const dashboardPages = [
  RoutesObj.PRICE_INSIGHT.URL,
  RoutesObj.ASSORTMENT_INSIGHT.URL,
  RoutesObj.PRICE_SPREADS.URL,
  RoutesObj.IN_OUT_MONITORING.URL,
  RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL,
  RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL,
]

export function isDashboardPages(pathname?: string) {
  return dashboardPages.includes(pathname || window.location.pathname)
}

export function isNotDashboardPages() {
  return !isDashboardPages()
}

/**
 * 这些页面禁用 Lookbook 功能
 */
export function isDisabledLookbookPages() {
  const pages = [
    RoutesObj.ASSORTMENT_SIZE.URL,
  ]
  return pages.includes(window.location.pathname)
}

/**
 * 这些页面禁用 Filter > Category
 */
export const disabledCategoryPages = [
  RoutesObj.CATEGORY_ANALYTICS.URL,
  RoutesObj.ASSORTMENT_SIZE.URL,
]

export function isDisabledCategoryPages() {
  return disabledCategoryPages.includes(window.location.pathname)
}
