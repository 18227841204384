import { CloseIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import React from 'react'
import styles from './styles.module.scss'
import { DeleteLookbookModalProps } from './types'

export const DeleteLookbookModal = ({
  closeModal,
  onDelete,
  isOpen,
}: DeleteLookbookModalProps) => {

  const getModalContent = () => 
      <>
          <div className={styles.modalHeader}>
              <div className={styles.modalTitle}>
                  <Text medium>Delete lookbook</Text>
              </div>
              <CloseIcon onClick={() => closeModal()} />
          </div>
          <div className={styles.modalBody}>
              <div className={styles.modalContent}>
                  <span>Are you sure?</span>
              </div>
          </div>
          <div className={styles.modalFooter}>
              <Button secondary miniLarge onClick={() => closeModal()}>
                  &nbsp;&nbsp;Back
              </Button>
              <Button primary mini onClick={() => onDelete()}>
                  &nbsp;&nbsp;Delete
              </Button>
          </div>
      </>

  return (
      <ShouldRender shouldRender={isOpen}>
          <div className={styles.modal} onClick={() => closeModal()} />
          <div className={styles.modalContainer}>
              <div className={styles.modalWrapper}>{getModalContent()}</div>
          </div>
      </ShouldRender>
  )
}
