import React, { ReactNode } from 'react'
import { ErrorMessage } from 'components/error-message/ErrorMessage'
import { recordErrorLog } from 'utils/logUtils'

interface IState { //eslint-disable-line
  hasError: boolean;
}
interface IProps {//eslint-disable-line
  children: ReactNode;
}

export class ErrorBoundary extends React.Component<IProps,IState> {//eslint-disable-line
  constructor(props) {
    super(props)
    this.state = { 
      hasError: false,
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // 记录错误日志
    recordErrorLog(error.message)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorMessage />
      )
    }

    return this.props.children
  }
}
