import React from 'react'
import { formatDateStr } from 'utils/dateUtils'
import { ShouldRender } from 'componentsv2/ShouldRender'
import classNames from 'classnames'
import { useConvertDisplayNotShowPropery } from 'features/filters/cacheDataSlice'
import { usePageDate } from 'hooks/usePageDate'
import styles from './tips.module.scss'
import { ClockIcon } from '../../../../assets/icons/Clock'
import { sortByOptionsFn } from './HeaderFilter/components/SortBy'
import { getDisplayCategoryValue } from './HeaderFilter/category.util'
import { dateRangeUtils } from 'norna-uikit'

export const Tips = React.forwardRef(function ({ filter: comparisonQuery }: any, ref) {
    const { query } = comparisonQuery.collection
    // let competitors = (comparisonQuery.sellers ?? []).map((item: any) => item.vendor);
    let categories = query.categories ?? []
    const region = query.regions
    const properties: any = query.properties ?? {}
    // 处理展示不显示二级Properties的Property
    const { pureShow } = useConvertDisplayNotShowPropery()
    const propertyValue = properties && Object.keys(properties).length > 0 ? pureShow(Object.values(properties).flat(1) as string[]).join(', ') : ''

    // eslint-disable-next-line prefer-destructuring
    const genders = query.targetGroups

    // const colorFilter = query.colorFilter?.checked ? query.colorFilter.percent : ''
    const priceFilter = query.priceFilter.label
    // const priceRange = query.priceRange?.activate_price_range ? [query.priceRange.min, query.priceRange.max].join(',') : ''
    // const materialFilter = query.materialFilter?.checked ? query.materialFilter.percent : ''
    const sortField = sortByOptionsFn(query.sortField)[0].label
    const launchedAfter = query.earliestLaunchDate

    categories = getDisplayCategoryValue(categories)
    // competitors = sortVendorWithBrandSellerStringV2(competitors);

    const { pageDate } = usePageDate()

    let dateValue = ''
    // Browse options 页面日期显示
    if (window.location.pathname === '/selections/nornai-analysis/list') {
        dateValue = typeof pageDate === 'string' ? dateRangeUtils.last(pageDate) : dateRangeUtils.last(pageDate?.key)
    } else {
        dateValue = typeof pageDate === 'string' ? formatDateStr(pageDate, true) : formatDateStr(pageDate?.key, true)
    }

    return (
        <div className={styles.tips}>
            <div className={styles.title}>Filter</div>
            <div className={styles.content}>
                <div>
                    <ShouldRender shouldRender={Boolean(region?.key)}>
                        <>
                            <span key={1}>{region.key}</span>
                            ,&nbsp;&nbsp;
                        </>
                    </ShouldRender>
                    <ClockIcon />
                    <span key={2}>{dateValue}</span>
                </div>
                <ShouldRender shouldRender={Boolean(categories)}>
                    <div>
                        <label>Categories: </label>{categories.join(', ')}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(genders)}>
                    <div>
                        <label>Gender: </label>{genders.join(', ')}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(propertyValue)}>
                    <div>
                        <label>Property: </label> {propertyValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(sortField)}>
                    <div>
                        <label>Sort by: </label>{sortField}
                    </div>
                </ShouldRender>
                {/* <ShouldRender shouldRender={Boolean(materialFilter)}>
                    <div>
                        <label>Material filter: </label>{materialFilter}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(colorFilter)}>
                    <div>
                        <label>Color filter: </label>{colorFilter}
                    </div>
                </ShouldRender> */}
                <ShouldRender shouldRender={Boolean(priceFilter)}>
                    <div>
                        <label>Price filter: </label>{priceFilter}
                    </div>
                </ShouldRender>
            </div>
            <div className={styles.footer}>
                <ShouldRender shouldRender={Boolean(launchedAfter)}>
                    <>
                        Regular price, launched after:&nbsp;
                        <span>{launchedAfter}</span>
                    </>
                </ShouldRender>
            </div>
        </div>
    )
})
/**
 *  ## Tips content Component 
 * 
 *  ## eg:
 * 
 * <InfoBox
 *  iconSty={{ margin: '0 10px' }}
 *  containerSty={{ left: '-140px' }}
 *  title="title"
 *  text="tips content"
 *  >
 *    <TipsContent>
 *     The percentage that you use in the material filter decides how &quot;solid&ldquo; a product needs to be to be displayed in the material analytics. If you set it at 70%, then only products with one material more than 70% will be displayed in each material &quot;bucket&ldquo;
 *    </TipsContent>
 * </InfoBox>
 *  
 * @param props 
 * @returns 
 */
export const TipsContent = ({ children, sty = {}, className }: any) => (
    <div className={classNames(styles.tips, className)} style={sty ?? {}}>
        <div className={classNames(styles.content, styles.contentInner)} style={{ border: 'none' }}>
            {children}
        </div>
    </div>
)
