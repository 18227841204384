import React, { CSSProperties, memo, useEffect, useState, useCallback, useImperativeHandle, forwardRef, useRef } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import classnames from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { CaretIcon } from 'assets/icons'
import { sortBy } from 'lodash'
import { Text } from 'components/typography'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { HorizontalLine } from 'components/HorizontalLine'
import { Button } from 'components/form-elements/Button'
import { parentshasClass } from 'components/common/InfoBox/InfoActionContext'
import { handleLeftAndRight } from 'utils'
import { DropdownHeader } from './components/DropdownHeader'
import { DropdownOptionItem } from './components/DropdownOptionItem'
import { DropdownProps, DropdownRefProps, DropdownType, OptionProps } from './types'
import styles from './styles.module.scss'

const getSelectedOptions = ({ options, value, type }) => {
  if (!value) return []
  let keyArr: string[] = []
  if (typeof value === 'string') {
    if (type === DropdownType.SINGLE) {
      keyArr.push(value)
    } else {
      keyArr = value.split(',')
    }
  }
  if (Array.isArray(value)) keyArr = [ ...value ]
  const selectedOptions = options.filter(item => keyArr.includes(item.key))
  return selectedOptions
}

/**
 * ## 何时使用
 * 
 * 单选下拉框。
 * 
 * ## 组成结构
 * 
 * ```
 * <div className="select">   下拉框 (select) 包含下拉选择器 (selector) 和下拉弹窗 (dropdown)
 *      <div className="selector"></div>  点击下拉选择器 (selector) 弹出下拉弹窗 (dropdown)
 *      <div className="dropdown"></div>  下拉弹窗 (dropdown)
 * </div>
 * ```
 * 
 * ## 代码演示
 * 
 * ### 基本用法
 * 
 * ```
 * <Dropdown
 *    value="Jan"
 *    options={
 *      [
 *          { key: 'Jan', description: 'January' },
 *          { key: 'Feb', description: 'February' },
 *      ]
 *    }
 *    placeholder="Month"
 *    onChange={useCallback(month => setMonth(month.key), [])}
 * />
 * ```
 * 
 * 注意事项：
 *      onChange 事件如果不使用 useCallback 包裹，那么当父组件中变量更新，
 *      即使 <Dropdown> 组件接收的参数未改变，<Dropdown> 也会重新渲染一次，
 *      考虑性能因素，应当为 onChange 事件添加 useCallback 包裹。
 * 
 * ### constantPlaceholder
 * 
 * 下拉菜单也可以使用 Dropdown 组件，参考 ExportDropdown。
 * 
 * 只要 constantPlaceholder 属性不为空，就认为是下拉菜单。
 * 
 * 下拉菜单和下拉框的区别？
 * - 下拉框选择选项后，下拉框的内容会被选项替换；下拉菜单选择选项后，下拉框内容不变。所以这里叫做 constantPlaceholder，constant 有常量的意思
 * - 下拉框默认边框、字体颜色都是灰色，只有至少选中一项后，边框和字体颜色才会变成绿色；下拉菜单的边框和字体颜色一直是绿色。
 */
export const Dropdown = memo(forwardRef<DropdownRefProps, DropdownProps>(({
  type = DropdownType.SINGLE,                    // 下拉框 (select) 类型，`DropdownType.SINGLE` 单选下拉框，`DropdownType.MULTI` 多选下拉框

  disabled,                           // 下拉框 (select) 是否禁用
  style = {},                         // 下拉框 (select) 样式
  className = '',                     // 下拉框 (select) 类名
  right = 0,                          // 下拉框 (select) marginRight 值
  left = 0,                           // 下拉框 (select) marginLeft 值
  placeholder = '',                   // 下拉选择器 (selector) 中 placeholder 文案，选择下拉框选项，placeholder 会被选中项替换
  constantPlaceholder,                // 下拉选择器 (selector) 中 placeholder 文案，选择下拉框选项，下拉框中仍然显示 constantPlaceholder 值
  showSelected = false,               // 默认情况当 constantPlaceholder 不为空时，下拉选项不显示选中项（即便选中，文字也没有颜色）
  options = [],                       // 下拉弹窗 (dropdown) 中下拉选项数组
  value = '',                         // 下拉弹窗 (dropdown) 中选中项的 key 值
  title,                              // 下拉弹窗 (dropdown) 标题
  label='',                              // 下拉弹窗 (dropdown) label
  dropdownStyle = {},                 // 下拉弹窗 (dropdown) 样式
  selectorClassName,
  showSearch = false,                 // 下拉弹窗 (dropdown) ，是否显示搜索框
  onChange,                           // 下拉弹窗 (dropdown) 中下拉选项变化时触发事件
  labelStyle = {},
  footer,
  // --------------------------- 多选下拉框选项 -----------------------------
  showClose = type === DropdownType.MULTI,
  showSelectAll = true,
  showClearAll = true,
  showOkButton = true,
  clearAllType = 'remain',
  clearAllMinCount = 0,
  onOk,
  customMaxHeight = null,                 // customize the max height of panel

  minCount = 0,
  maxCount = 99999,                   // 多选下拉框最多选择想，99999 等于不设限制
  // --------------------------- 下面几个属性待确定 -----------------------------
  customRender,                       // customRender 返回的元素会被放在 dropdown 的最前面
  onVisibleChanage,
  isSelectorCenter = false,
}, ref) => {
  left = handleLeftAndRight(left)
  right = handleLeftAndRight(right)

  // 下拉弹窗 (dropdown) 是否显示
  const [ isOpen, setIsOpen ] = useState(false)
  // 下拉弹窗 (dropdown) 中已选中下拉选项数组
  const [ selectedOptions, setSelectedOptions ] = useState<OptionProps[]>(getSelectedOptions({ options, value, type }))
  // 下拉弹窗 (dropdown) 是否显示超出最大数目的提示文字
  const [ showMaxCountMessage, setShowMaxCountMessage ] = useState<boolean>(false)
  const [ showMinCountMessage, setShowMinCountMessage ] = useState<boolean>(false)
  // 下拉选择器 (selector) 中显示的文字
  const [ inputValue, setInputValue ] = useState<string>('')

  const selectorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    onVisibleChanage?.(isOpen)
  }, [ isOpen ])

  /**
   * Dropdown 对外暴露方法
   */
  useImperativeHandle(ref, () => ({
    close: () => {
      setIsOpen(false)
    },
    getSelectorEl: () => {
      return selectorRef.current
    },
  }))

  // 下拉框 (select) 类名
  const selectClassName = classnames([
    styles.select,
    constantPlaceholder && styles.green,
    disabled && styles.disabled,
    className,
    disabled ? '' : 'cell-mouse-effect-no-display-no-within',
  ])

  // 下拉框 (select) 样式
  const selectWrapperStyle: CSSProperties = {}
  if (typeof left !== 'undefined') {
    selectWrapperStyle.marginLeft = left + 'px'
  }
  if (typeof right !== 'undefined') {
    selectWrapperStyle.marginRight = right + 'px'
  }

  // 根据 value 参数初始化 selectedOptions 值
  useDeepCompareEffect(() => {
    const selectedOptions = getSelectedOptions({ options, type, value })
    setSelectedOptions(selectedOptions)
  }, [ options, type, value ])

  // 监听 options 如果为空数组，清空下拉选择器中的值，清空下拉框弹窗中选中项
  useEffect(() => {
    if (options.length === 0) {
      setInputValue('')
      setSelectedOptions([])
    }
  }, [ options ])

  /**
   * 选择下拉选项触发事件
   */
  const onSelect = (item: OptionProps) => {
    // 【单选下拉框】或【未设置 onOk 事件的多选下拉框】，勾选下拉选项后就关闭下拉弹窗
    if (type === DropdownType.SINGLE || typeof onOk === 'undefined') {
      setIsOpen(false)
    }

    // 多选下拉框，如果 selectedOptions 已包含 item，表示取消勾选，则删除 item；不包含 item，表示勾选，添加 item
    if (type === DropdownType.MULTI) {
      const option = selectedOptions.find(selectedOption => selectedOption.key === item.key)
      if (option) {   // 有，表示取消勾选
        setSelectedOptions(selectedOptions.filter(i => i.key !== item.key))
      } else {    // 没有，表示勾选
        setSelectedOptions([ ...selectedOptions, item ])
      }
    } else {
      // constantPlaceholder 有值时，说明是下拉菜单，每次点击下拉选项都要触发事件
      // eslint-disable-next-line
      if (typeof constantPlaceholder !== 'undefined') {
        setTimeout(() => {
          onChange?.(item.key)
        }, 500)
      } else {
        // 单选下拉框，selectedOptions 中有且只有一个值，直接替换即可
        setSelectedOptions([ item ])
      }
    }
  }

  const DropdownPanelClass = 'drop-panel-class'

  /* ******************************** 搜索框功能 *************************************** */
  // 下拉弹窗 (dropdown) 中搜索框填写值
  const [ searchValue, setSearchValue ] = useState('')
  // 下拉框选项列表
  const [ dropdownOptions, setDropdownOptions ] = useState<OptionProps[]>([])

  useDeepCompareEffect(() => {
    setDropdownOptions(options || [])
  }, [ options ])

  useEffect(() => {
    if (!searchValue) {
      setDropdownOptions([ ...options ])
    } else {
      const filterOptions = options.filter(item => item.description.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1)
      setDropdownOptions([ ...filterOptions ])
    }
    // eslint-disable-next-line
  }, [searchValue])

  // 监听 selectedOptions 变化，处理 onChange 事件
  useDeepCompareEffect(() => {
    if (type === DropdownType.MULTI) {
      onChange?.(selectedOptions.map(item => item.key))
    }
    /**
     * fix bug:
     * 之前 Dropdown 只要 value 有值，就会触发一次 onChange，这样其实是不对的
     * onChange 只有在下拉框选项值发生变化时才会触发
     */
    if (type === DropdownType.SINGLE && selectedOptions.length > 0 && value !== selectedOptions[0].key) {
      onChange?.(selectedOptions[0]?.key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedOptions ])

  // 监听 selectedOptions 变化，处理 selector 中显示值
  useEffect(() => {
    if (constantPlaceholder) {
      return
    }
    if (selectedOptions.length > 1) {
      const desc = selectedOptions[0].description
      setInputValue(`${desc.length > 7 ? desc.slice(0, 6) + '...' : desc}, +${selectedOptions.length - 1}`)
    } else if (selectedOptions.length === 1) {
      setInputValue(selectedOptions[0].description)
    } else {
      setInputValue('')
    }
  }, [ selectedOptions, constantPlaceholder ])

  // 监听 selectedOptions 变化，处理是否显示超出最大数目的提示文字
  useEffect(() => {
    if (type === DropdownType.SINGLE) return

    if (selectedOptions.length > maxCount) {
      setShowMaxCountMessage(true)
    } else if (minCount && selectedOptions.length < minCount) {
      setShowMinCountMessage(true)
    } else {
      setShowMaxCountMessage(false)
      setShowMinCountMessage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedOptions ])

  const onClearAll = () => {
    if (clearAllType === 'remain') {
      setSelectedOptions(selectedOptions.slice(0, minCount))
    } else {
      setSelectedOptions([])
    }
  }

  const onClickOk = keys => {
    if (!keys.length && showClearAll && clearAllMinCount > 0 && clearAllType === 'empty') {
      setSelectedOptions(options.slice(0, clearAllMinCount))
      keys = options.slice(0, clearAllMinCount).map(item => item.key)
    }
    if (JSON.stringify(sortBy(keys)) === JSON.stringify(sortBy(value))) {
      return
    }
    onOk?.(keys)
  }

  return (
    <ClickAwayListener onClickAway={e => {
      const parEle = parentshasClass(e.target, DropdownPanelClass)
      if (!parEle) {
        if (showMaxCountMessage || showMinCountMessage) return
        setIsOpen(false)
      }
      // eslint-disable-next-line
      if (isOpen && type === DropdownType.MULTI && sortBy((value || []) as string[]).toString() !== selectedOptions.map(item => item.key).sort().toString()) {
        // selectedOptions 按照 options 顺序进行排序
        let keys = selectedOptions.map(item => item.key)
        keys = options.map(item => item.key).filter(item => keys.includes(item))
        keys = keys.slice(0, maxCount)
        onClickOk?.(keys)
      }
    }}
    >
      <div style={{ ...selectWrapperStyle }}>
        {label?<div className={styles.label} style={{ ...labelStyle }}>{label}</div>:null}
        <div
          className={selectClassName}
          style={{ ...style }}
        >
          <div
            ref={selectorRef}
            className={classnames(styles.selector, selectorClassName, inputValue && styles.isSelected)}
            onClick={() => { 
              if (!disabled) {
                setIsOpen(!isOpen)
              } 
            }}
          >
            <div 
              className={styles.selectorSelection}
              style={{ textAlign: isSelectorCenter ? 'center' : 'left' }}
            >
              {inputValue || constantPlaceholder || placeholder}
            </div>
            <div className={classnames(styles.selectorArrow, isOpen && styles.selectorArrowFlipped)}>
              <CaretIcon color={constantPlaceholder && '#01a699'} />
            </div>
          </div>

          <div
            className={classnames(styles.dropdown, isOpen && styles.dropdownExpanded, DropdownPanelClass)}
            style={{
              maxHeight: customMaxHeight ?? (type === DropdownType.MULTI ? '290px' : '215px'),
              minWidth: '164px',
              ...dropdownStyle,
              width: 'auto',
            }}
          >
            {customRender && customRender({ setInputValue })}

            <DropdownHeader
              title={title}
              showClose={showClose}
              onClick={useCallback(() => setIsOpen(false), [])}
            />

            <ShouldRender shouldRender={showSearch}>
              <div className={styles.search}>
                <input
                  className={styles.searchInput}
                  placeholder="Search"
                  onChange={e => setSearchValue(e.target.value)}
                />
              </div>
            </ShouldRender>

            <ShouldRender shouldRender={showMaxCountMessage}>
              <span className={styles.maxCountTip}>
                The selection can not be more than {maxCount}
              </span>
            </ShouldRender>
            <ShouldRender shouldRender={showMinCountMessage && !!minCount}>
              <span className={styles.maxCountTip}>
                The selection can not be less than {minCount}
              </span>
            </ShouldRender>

            <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden', padding: '0 0px', margin: '10px 0' }}>
              <ShouldRender shouldRender={type === DropdownType.MULTI && (showClearAll || showSelectAll)}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {
                    type === DropdownType.MULTI && showClearAll && options.length > 0 ? (
                      <div
                        onClick={onClearAll}
                        className={classnames(styles.clearAll, 'cell-mouse-effect-no-display-no-within-padding-lr-15')}
                      >
                        <Text small secondary>
                          Clear all
                        </Text>
                      </div>
                    ) : null
                  }
                  {
                    type === DropdownType.MULTI && showSelectAll && options.length > 0 ? (
                      <div 
                        onClick={() => setSelectedOptions([ ...dropdownOptions ].filter(item => !item.isDisabled))} 
                        className={classnames(styles.clearAll, 'cell-mouse-effect-no-display-no-within-padding-lr-15')}
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <Text small secondary>
                          Select all
                        </Text>
                      </div>
                    ) : null
                  }
                </div>
                <HorizontalLine />
              </ShouldRender>

              <ShouldRender shouldRender={!options.length}>
                <Text style={{ padding: '0 17px' }} small>No options</Text>
              </ShouldRender>

              {
                dropdownOptions.map((item: OptionProps) =>
                  <DropdownOptionItem
                    key={item.key}
                    customClass="cell-mouse-effect-no-display-no-within-padding-lr-15"
                    item={item}
                    /**
                     * active：下拉选项处于激活状态文字颜色会变成蓝色，表示选中该选项
                     * 
                     * constantPlaceholder 值存在，表示当前是菜单下拉框，参考 ExportDropdown 组件
                     * 选择下拉框选项，选中项的颜色不应当改变
                     * 
                     * constantPlaceholder 值不存在，表示当前是普通下拉框
                     * 选择下拉框选项，选中项的颜色会变成蓝色
                     */
                    active={(showSelected || !constantPlaceholder) && !!selectedOptions.find(option => option.key === item.key)}
                    showCheckbox={type === DropdownType.MULTI}
                    onSelect={onSelect}
                    /**
                     * 单选下拉框 minCount 设置了之后, 当选中数目等于 minCount 时, 不让再取消选中
                     */
                    disabled={type === DropdownType.MULTI && selectedOptions.length === minCount && selectedOptions.map(item => item.key).includes(item.key)}
                  />,
                )
              }

              {footer}
            </div>

            <ShouldRender shouldRender={Boolean(type === DropdownType.MULTI && onOk && options.length > 0) && showOkButton}>
              <div>
                <Button
                  success
                  disabled={showMaxCountMessage || showMinCountMessage}
                  className={styles.okButton}
                  onClick={() => {
                    setIsOpen(false)
                    // 对 selectedOptions 按照 options 进行排序
                    let keys = selectedOptions.map(item => item.key)
                    keys = options.map(item => item.key).filter(item => keys.includes(item))
                    onClickOk?.(keys)
                  }}
                >
                  OK
                </Button>
              </div>
            </ShouldRender>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
}))

Dropdown.displayName = 'Dropdown'
