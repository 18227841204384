import { Assortment } from 'assets/icons/NewMenu/Assortment'
import { Dashboards } from 'assets/icons/NewMenu/Dashboards'
import { HelpResorces } from 'assets/icons/NewMenu/HelpResorces'
import { Pricing } from 'assets/icons/NewMenu/Pricing'
import { Search } from 'assets/icons/NewMenu/Search'
import { sortByOptionsFn } from 'components/layout/Header/components/HeaderFilter/components/SortBy'
import { FILTER_LABELS, getPropertyVal, setAllActive, setPropertyValByLabels } from 'componentsv2/Tree/utils'
import { ALL_VENDORS, GENDER_CHILDREN, IS_LIVE_ENV, isJustBrandsLicense, MARKET_VENDOR, NO_SIZE_IGNORE_NO_AVAILABLE_SIZE, NO_SIZE_NO_AVAILABLE_SIZE_UPDATE, NO_SIZE_NO_HARMONIZED_SIZE, NO_SIZE_NO_SIZE_HISTORY, NO_SIZE_NO_TRUE_SELLER_INFORMATION_Z, NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE, SORTBY_HIGHEST_PRICE, SORTBY_INTRO_DATE, SORTBY_LOWEST_PRICE, SORTBY_MOST_POPULAR, SORTBY_NAME } from 'consts'
import { isDiscountsPage, RoutesObj } from 'global/Routes'
import { cloneDeep, delay, sortBy } from 'lodash'
import React from 'react'
import { ObjectType, SellerType, TaxFilterType } from 'types'
import { getCategoriesByViewFiltedCategories } from 'utils'
import { downloadByUrl } from 'utils/file'
import { getGenderOptions } from 'utils/optionsUtils'
import { getDisplayGenderValue, getFilterGenderChildrenValue } from 'components/layout/Header/components/HeaderFilter/gender.util'
import { MenuBarItemProps, MenuBarHookType } from './types'
import { isShowPlatformAnalyticsPage, isShowSoldOutAnalyticsPage } from 'utils/privUtils'
import { PRODUCT_RANKING_FOR_JUST_BRANDS_TABLE_NAME } from 'configs'
import { storage } from 'utils/storage'
import { useFavorite } from 'hooks/api/useFavorite'
import PinOutlined from './PinFilled'

export default function useMenuBar({ createLookbook, openRelaseNote, openAbout, openSupport }: MenuBarHookType): ObjectType<Array<MenuBarItemProps>> {
    const { favoriteList = [] } = useFavorite()

    const list = [
        {
            title: 'Dashboard',
            icon: <Dashboards />,
            list: [
                { label: RoutesObj.PRICE_INSIGHT.LABEL, url: RoutesObj.PRICE_INSIGHT.URL },
                { label: RoutesObj.ASSORTMENT_INSIGHT.LABEL, url: RoutesObj.ASSORTMENT_INSIGHT.URL },
                { label: RoutesObj.PRICE_SPREADS.LABEL, url: RoutesObj.PRICE_SPREADS.URL },
                { label: RoutesObj.IN_OUT_MONITORING.LABEL, url: RoutesObj.IN_OUT_MONITORING.URL },
                { label: RoutesObj.MOST_POPULAR_LAUNCH_DAYS.LABEL, url: RoutesObj.MOST_POPULAR_LAUNCH_DAYS.URL },
                { label: RoutesObj.OPTIONS_IN_OUT_CALENDAR.LABEL, url: RoutesObj.OPTIONS_IN_OUT_CALENDAR.URL },
                { 
                    label: RoutesObj.DYNAMIC_DASHBOARD_REPORT.LABEL, 
                    url: RoutesObj.DYNAMIC_DASHBOARD_REPORT.URL, 
                    // hidden: ![ 'localhost', 'staging.norna.ai' ].includes(window.location.hostname),  
                    hidden: true,  
                },
                { 
                    label: RoutesObj.LINE_GRAPH_REPORT.LABEL, 
                    url: RoutesObj.LINE_GRAPH_REPORT.URL, 
                    hidden: !storage.getEmail()?.endsWith('@norna.ai'),  
                },
            ].filter((item: any) => !item.hidden),
        },
        {
            title: 'Pricing',
            icon: <Pricing />,
            list: [
                { label: RoutesObj.PRICE_HISTORY.LABEL, url: RoutesObj.PRICE_HISTORY.URL },
                { label: RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.LABEL, url: RoutesObj.PRICE_DRIVERS_PERIOD_TO_PERIOD.URL },
                { label: RoutesObj.PRICE_ARCHITECTURE.LABEL, url: RoutesObj.PRICE_ARCHITECTURE.URL },
                { label: RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.LABEL, url: RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL },
                { label: RoutesObj.PRICING_JUMP_TABLE.LABEL, url: RoutesObj.PRICING_JUMP_TABLE.URL },
            ].filter((item: any) => !item.hidden),
        },
        {
            title: 'Assortment',
            icon: <Assortment />,
            list: [
                { 
                    label: RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.LABEL, 
                    url: RoutesObj.SOLD_OUT_ANALYTICS_VENDORS.URL, 
                    hidden: !isShowSoldOutAnalyticsPage(), 
                },
                { 
                    label: RoutesObj.CROSS_REGION_SOLD_OUT.LABEL, 
                    url: RoutesObj.CROSS_REGION_SOLD_OUT.URL, 
                },
                { label: RoutesObj.CATEGORY_ANALYTICS.LABEL, url: RoutesObj.CATEGORY_ANALYTICS.URL },
                { label: RoutesObj.COLOR_ANALYTICS.LABEL, url: RoutesObj.COLOR_ANALYTICS.URL },
                { label: RoutesObj.MATERIAL_ANALYTICS.LABEL, url: RoutesObj.MATERIAL_ANALYTICS.URL },
                { 
                    label: isJustBrandsLicense() ? PRODUCT_RANKING_FOR_JUST_BRANDS_TABLE_NAME : RoutesObj.POPULAR_PRODUCTS.LABEL, 
                    url: RoutesObj.POPULAR_PRODUCTS.URL, 
                },
                { label: RoutesObj.POPULAR_ATTRIBUTES.LABEL, url: RoutesObj.POPULAR_ATTRIBUTES.URL },
                { label: RoutesObj.ASSORTMENT_SIZE.LABEL, url: RoutesObj.ASSORTMENT_SIZE.URL, hidden: false },
                { 
                    label: RoutesObj.PLATFORM_ANALYTICS.LABEL, 
                    url: RoutesObj.PLATFORM_ANALYTICS.URL,
                    hidden: !isShowPlatformAnalyticsPage(), 
                },
            ].filter((item: any) => !item.hidden),
        },
        {
            title: 'Lookbooks',
            icon: <Search />,
            list: [
                { label: RoutesObj.SEARCH_OPTIONS.LABEL, url: RoutesObj.SEARCH_OPTIONS.URL },
                { label: 'Create a new lookbook', click: createLookbook },
                { label: RoutesObj.LOOKBOOL_LIST.LABEL, url: RoutesObj.LOOKBOOL_LIST.URL },
            ],
        },
        {
            title: 'Help & resources',
            icon: <HelpResorces />,
            list: [
                {
                    label: 'Manual', click: () => {
                        downloadByUrl('/docs/Manual_Norna.pdf')
                    },
                },
                { label: 'Support & Contact ', click: openSupport },
                { label: 'Release notes', click: openRelaseNote },
                { label: 'About Norna', click: openAbout },
            ],
        },
    ]

    if (favoriteList.length) {
        list.unshift({
            title: 'My Favorite',
            icon: <PinOutlined size={30} />,
            list: sortBy(favoriteList, 'name').map((item: any) => {
                return {
                    label: item.name,
                    url: item.path,
                }
            }),
        })
    }

    return {
        list,
    }
}

/**
 *  ## 
 * 
 * @param filtersItem 容器class 字符
 * @param tag         tag class 字符
 * @returns 
 */
export const getRestNumber = (filtersItem: string, tag: string) => {
    const filtersItemEle = document.querySelector(`.${filtersItem}`)
    const tags = document.querySelectorAll(`.${tag}`)
    const total = tags.length
    let firstLineWidth = 0
    let secondLineWidth = 0
    if (filtersItemEle) {
        let firstLineItemNum = 0  // 下标
        let secondLineItemNum = 0;
        [ ...tags ].find((ele, index) => {
            // firstLineWidth += ele.clientWidth + 2 + 4
            firstLineWidth += Number(ele.getBoundingClientRect().width.toFixed(2)) + 4
            if (firstLineWidth > filtersItemEle?.clientWidth) {
                // 第一行的数量初次满足条件的赋值
                if (firstLineItemNum === 0) firstLineItemNum = index

                // secondLineWidth += ele.clientWidth + 2 + 4
                secondLineWidth += Number(ele.getBoundingClientRect().width.toFixed(2)) + 4

                if (secondLineWidth > filtersItemEle?.clientWidth) {
                    // 第二行的计算数量初次满足赋值
                    if (secondLineItemNum === 0) secondLineItemNum = index - firstLineItemNum
                    return true
                }
            }
            return false
        })
        if (secondLineItemNum === 0) {
            return 0
        }
        return total - firstLineItemNum - secondLineItemNum
    }
    return 0
}

/**
 * ## 设置Nav Date 日期位置
 * 
 * @param param0 props.calendarContainerElement 日期dom元素
 * @param param1 props.inputElement 日期输入框dom元素
 */
export const renderDatePosition = ({ calendarContainerElement, inputElement }) => {

    try {

        const { clientHeight } = document.documentElement
        const { height } = calendarContainerElement.current.getBoundingClientRect()
        const inputRect = inputElement.current.getBoundingClientRect()
        calendarContainerElement.current.style.left = '75px'
        calendarContainerElement.current.style.transform = 'none'
        const overflowBottom = (inputRect.y + height + inputRect.height) > clientHeight
        let overflowTop = (inputRect.y - height + inputRect.height) < 0
        overflowTop = overflowTop ? 70 : inputRect.y - height + inputRect.height
        const topHeight = overflowBottom ? overflowTop : inputRect.y + inputRect.height
        if (topHeight + height > window.innerHeight) {
            calendarContainerElement.current.style.top = topHeight + 'px'
        } else {
            calendarContainerElement.current.style.bottom = (window.innerHeight - topHeight - height) + 'px'
            calendarContainerElement.current.style.top = 'auto'
        }
        calendarContainerElement.current.style.zIndex = 99999
        calendarContainerElement.current.style.position = 'fixed'
    } catch (error) {
        console.log(error)

    }
}

/**
 *  ## 基于`Filter Display`内容变化, 动态更新功能页容器的`margin-top`值
 */
export const updatePageMarginTop = () => {
    const eleFilterDisplay = document.querySelector('.filterDisplay')
    // .filterDisplay

    const elePageContainer: HTMLDivElement = document.querySelector('.page-container') as HTMLDivElement
    if (eleFilterDisplay && elePageContainer?.style) {
        elePageContainer.style.marginTop =  `${eleFilterDisplay?.clientHeight}px`
    }
    if (elePageContainer) {
        if (window.location.pathname.startsWith(RoutesObj.SEARCH_OPTIONS.URL)) {
            elePageContainer.style.paddingTop = '0px'
            elePageContainer.style.paddingLeft = '0px'
            elePageContainer.style.paddingRight = '0px'
        } 
        // Dynamic dashboard 不要内边距
        else if (window.location.pathname.startsWith(RoutesObj.DYNAMIC_DASHBOARD.URL)) {
            elePageContainer.style.padding = '0px'
        }
        else {
            elePageContainer.style.paddingTop = '30px'
            elePageContainer.style.paddingLeft = '40px'
            elePageContainer.style.paddingRight = '40px'
        }

        elePageContainer.style.height = `calc(100vh - 5px - ${elePageContainer.style.marginTop})`
    }

}

/**
 * 获取 .filterDisplay 高度
 * 
 * 有些页面如 Browse options, Price architecture, 页面往下滚动时, 
 * 需要设置头部吸附在顶部, 此时需要知道距离顶部多少距离,
 * 而顶部的距离又是动态变化的
 */
export const getFilterDisplayHeight = () => {
    const filterDisplay = document.querySelector('.filterDisplay')
    return filterDisplay?.clientHeight
}

/**
 *  ## 依据当前页容器的`MarginTop`, 设置当前元素的`top`值
 * @returns 
 */
export const setMarginTopByPageContainerMarginTop = (classStr: string, delayTime = 1000) => {

    delay(() => {
        const eleFilterDisplay = document.querySelector('.filterDisplay')
        const ele: HTMLDivElement = document.querySelector(`.${classStr}`) as HTMLDivElement
        if (ele?.style && eleFilterDisplay) {
            // max-height: calc(100% - 264px);
            ele.style.top = `${eleFilterDisplay.clientHeight}px`
            ele.style.maxHeight = `calc(100vh - ${eleFilterDisplay.clientHeight}px)`
        }
    }, delayTime)
}
/**
 *  ## 根据`MenuBarDisplay`的变化, 更新新的`comparisonQuery` 
 * 
 * @param comparisonQuery 
 * @param filtered 
 * @param properties 
 * @param children 
 * @param materialFilter 
 * @param colorFilter 
 * @param launchedAfter 
 */
 export const handleQuery = (comparisonQuery: any, filtered: string[], properties, children, launchedAfter: string, cacheData: any, materialFilter: string, launchBeforeDate: string, noSize: string, noHistoricalSize: string, noUnavailableSize: string, noHarmonizedSize: string, zalandodeepShopEmptyString: string, ignoreNoAvailableSize: string) => {
    comparisonQuery.sellers = comparisonQuery.sellers.filter((item: SellerType) => {
        const vendor = item.vendor === MARKET_VENDOR.vendor ? ALL_VENDORS : item.vendor
        return !filtered.includes(`${FILTER_LABELS.COMPETITOR}:${vendor}`)
    })
    const { query } = comparisonQuery.collection
    query.categories = getCategoriesByViewFiltedCategories(cacheData, cloneDeep(query.categories), filtered)

    // 对于 Children 下面还有 gender size range 的情况, 取消 children, 实际上是取消子项
    const childrenValue = getFilterGenderChildrenValue()
    if (childrenValue?.length && filtered.includes(`${FILTER_LABELS.GENDER}:${GENDER_CHILDREN}`)) {
        query.targetGroups = query.targetGroups.filter(gender => !childrenValue.includes(gender))
    }
    query.targetGroups = query.targetGroups.filter((gender: string) => !filtered.includes(`${FILTER_LABELS.GENDER}:${gender}`))
    query.colorFilter.colors = query.colorFilter.colors.filter((item: string) => !filtered.includes(`Colors:${item}`))
    query.materialFilter.materials = query.materialFilter.materials.filter((item: string) => !filtered.includes(`Materials:${item}`))

    // handle property
    const values: ObjectType<Array<string>> = {}
    Object.keys(properties).forEach((key: string) => {
        properties[key].forEach((property: string) => {
            if (!filtered.includes(`${key}:${property}`)) {
                if (!values[key]) values[key] = []
                values[key].push(property)
            }
        })
    })

    const propertiesData = {}

    const childrenData = cloneDeep(children)

    childrenData.forEach(item => {
        if (values[item.val]) {
            setPropertyValByLabels(item, values[item.val])
        } else {
            item.active = false
            setAllActive(item, false)
        }
    })

    childrenData?.filter(item => item.active)
        .forEach((item: any) => {
            propertiesData[item.val] = getPropertyVal(item, false, false).flat(10)
        })
    query.properties = propertiesData

    // handle `Material filter: percent`
    if (filtered.includes(materialFilter)) {
        query.materialFilter.checked = false
        query.materialFilter.percent = 0
    }

    // handle lanunched after
    if (filtered.includes(launchedAfter)) query.earliestLaunchDate = null

    // handle launch before date
    if (filtered.includes(launchBeforeDate)) query.launchBeforeDate = null

    if (filtered.includes(noSize)) query.noSize = false

    if (filtered.includes(noHistoricalSize)) query.noHistoricalSize = false
    
    if (filtered.includes(noUnavailableSize)) query.noUnavailableSize = false

    if (filtered.includes(noHarmonizedSize)) query.noHarmonizedSize = false
    
    if (filtered.includes(zalandodeepShopEmptyString)) query.zalandodeepShopEmptyString = false

    if (filtered.includes(ignoreNoAvailableSize)) query.ignoreNoAvailableSize = false
}

/**
 * 判断当前`currentLink` 是否和地址栏中的location 匹配
 * @param pathname 
 * @param search 
 * @param currentLink 
 * @returns 
 */
export const activeCurrentPath = (pathname: string, search: string, currentLink: string) => currentLink.startsWith(pathname + search)

export const getPriceRangeFilterTagValue = (priceRange: any) => {
    if (!priceRange?.activate_price_range) return ''
    return `Price range: ${[ priceRange.min, priceRange.max ].join(',')}`
}

export const getProductFilterTagValue = (priceRange: any) => {
    const mapper = {
        all: 'All options',
        discount: 'Only discounted options',
        original: 'Only full price options',
    }
    return `${mapper[priceRange.optionValue]}`
}

export const getLaunchedAfterFilterTagValue = (launchedAfter: any) => {
    if (!launchedAfter) return ''
    return `${FILTER_LABELS.LAUNCHED_AFTER}: ${launchedAfter}`
}

export const getLaunchedBeforeFilterTagValue = (launchedBefore: any) => {
    if (!launchedBefore) return ''
    return `${FILTER_LABELS.LAUNCHED_BEFORE}: ${launchedBefore}`
}

export const getSortByFilterTagValue = (sortBy: any) => {
    if (!sortBy) return ''
    const sortByValue = sortByOptionsFn(sortBy).find(item => item.active)?.label
    const mapper = {
        [SORTBY_INTRO_DATE]: 'Intro date',
        [SORTBY_NAME]: 'Name',
        [SORTBY_MOST_POPULAR]: 'Most popular',
        [SORTBY_HIGHEST_PRICE]: 'Highest price',
        [SORTBY_LOWEST_PRICE]: 'Lowest price',
    }
    return `${FILTER_LABELS.SORT_BY}: ${mapper[sortByValue]}`
}

export const getSizeFilterTagValue = (sizeFilter: any) => {
    if (!sizeFilter?.checked) return ''
    if (sizeFilter?.isNoSizes) {
        return `${FILTER_LABELS.SIZE_FILTER}: No sizes`
    }
    const sizeFilterValue = [ sizeFilter?.min, sizeFilter.max ].join('-')
    return `${FILTER_LABELS.SIZE_FILTER}: ${sizeFilterValue}`
}

export const getTaxFilterTagValue = (taxFilter: TaxFilterType) => {
    if (!taxFilter?.checked) return ''
    const taxFilterValue = (taxFilter.value * 100) + '%'
    return `${FILTER_LABELS.TAX}: ${taxFilterValue}`
}

export const getMaterialFilterTagValue = (materialFilter: any) => {
    if (!materialFilter?.checked) return ''
    const materialFilterValue = materialFilter.percent + '%'
    return `${FILTER_LABELS.MATERIAL_FILTER}: ${materialFilterValue}`
}

export const getGendersFilterTagValue = (genders: any = [], sizeFilter: string) => {
    if (sizeFilter) return cloneDeep(getDisplayGenderValue(genders)).sort()
    // genders 排序
    const genderOptions = getGenderOptions()
    let value = genderOptions
        .map(item => {
            if (Array.isArray(item?.children) && item?.children?.length) {
                return item.children.map(item2 => item2.key)
            }
            return item.key
        })
        .flat(2)
        .filter(item => genders.includes(item))
    value = getDisplayGenderValue(value)
    return cloneDeep(value).sort()
}

export const getPropertiesFilterTagValue = (property: any = {}) => {
    const groups = Object.keys(property)
    if (!groups.length) return []

    const properties = groups.map(group => {
        return property[group].map(item => `${group}:${item}`)
    }).flat(10)
    return properties
}

export const getPricePerspectiveValue = (value: string) => {
    let pricePerspectiveValue = value
    // hard code 'Sales prices' for Discounts page
    if (isDiscountsPage()) {
        pricePerspectiveValue = 'Sales prices'
    }
    return `Price: ${pricePerspectiveValue}`
}

export const getPackFilterValue = (value: string[]) => {
    if (!value?.length) return ''
    return value.join(',')
}

export const getNoSizeValue = (value: boolean) => {
    if (!value) return ''
    return NO_SIZE_NO_AVAILABLE_SIZE_UPDATE
}

export const getNoUnavailableSize = (value: boolean) => {
    if (!value) return ''
    return NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE
}

export const getNoHarmonizedSize = (value: boolean) => {
    if (!value) return ''
    return NO_SIZE_NO_HARMONIZED_SIZE
}

export const getZalandodeepShopEmptyString = (value: boolean) => {
    if (!value) return ''
    return NO_SIZE_NO_TRUE_SELLER_INFORMATION_Z
}

export const getIgnoreNoAvailableSize = (value: boolean) => {
    if (!value) return ''
    return NO_SIZE_IGNORE_NO_AVAILABLE_SIZE
}

export const getNoHistoricalSizeValue = (value: boolean) => {
    if (!value) return ''
    return NO_SIZE_NO_SIZE_HISTORY
}
