import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { ArrowProps } from './types'

export const ArrowIcon = ({ up, right, down, left, className }: ArrowProps) => 
    <svg
        className={classnames(
          styles.arrow,
          up && styles.up,
          right && styles.right,
          down && styles.down,
          left && styles.left,
          className,
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
    </svg>
  
