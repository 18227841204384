import { OffsetType } from 'types'

export type ReportProductModalTypes = {
  isOpen: boolean;
  closeModal: Function;
  productUrl: string;
};
export enum ReportType {
  SINGLE_PRODUCT,
  MULTI_PRODUCT,
}
export type ReportProductModalTypesV2 = ReportProductModalTypes & {
  type?: ReportType.SINGLE_PRODUCT | ReportType.MULTI_PRODUCT; // 
  offset?: OffsetType; // offset relative to the source target
  nornaids?: Array<string>; // 
  products?: {
    nornaid: string;
    vendor: string;
  }[]
}
