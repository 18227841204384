/**
 * 特殊处理, 规则记录在数据库中
 */

import { ALL_VENDORS, GENDER_CHILDREN, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { isProductRankingPage, isSoldOutVendorsPage } from 'global/Routes'
import { getVendorCodeByName, getVendorNameByCode } from 'utils'
import { storage } from './storage'

export function getDisabledFilterCompetitor(): string[] {
    if (isProductRankingPage()) {
        const blacklist = storage.getBlacklist()
        const blackCompetitor = blacklist?.find(b => b.analysis === 'popular-options')?.competitor?.all || []
        const competitor = blackCompetitor
            .map(b => {
                if (b === 'selected vendors') {
                    return SELECTED_VENDORS
                }
                if (b === 'all vendors') {
                    return [ MARKET_VENDOR.vendor, ALL_VENDORS ]
                }
                return [ b, getVendorNameByCode(b), getVendorCodeByName(b) ]
            })
            .flat(2)
            .filter(b => b)
            .filter((b, i, a) => a.indexOf(b) === i)
        return competitor
    }
    return []
}

export function getDisabledFilterGender(): string[] {
    if (isSoldOutVendorsPage()) {
        return [ GENDER_CHILDREN ]
    }
    return []
}
