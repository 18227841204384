import React, { useEffect, useRef, useState } from 'react'
import styles from './QaSizeDialog.module.scss'
import { useAnnotationAnnotatedSizes, useAnnotationMaskChildren, useAnnotationSetSizeMap, useAnnotationUnknowSizes } from 'hooks/api/useAnnotaitionUnknowSizes';
import Row from './Row';
import { cloneDeep, sortBy, uniq } from 'lodash';
import { Button, Select, Spin } from 'druikit';
import { Text } from 'components/typography'
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import { QA_BUTTON_QA_SIZE_LABEL } from 'consts/strings';

enum TabTypeEnum {
    unknow = 'unknow',
    annotated = 'annotated',
}

const QaSizeDialog = ({
    closeModal,
}: {
    closeModal?: (value: boolean) => void;
}) => {
    const { fetchAnnotationUnknowSizes, loading } = useAnnotationUnknowSizes()
    const { fetchAnnotationAnnotatedSizes, loading: fetchAnnotatedSizesLoading } = useAnnotationAnnotatedSizes()
    const { setSizeMap, loading: modifyLoading } = useAnnotationSetSizeMap()
    const { maskChildren, loading: maskChildrenLoading } = useAnnotationMaskChildren()
    const [ dataSource, setDataSource ] = useState<any[]>([])
    const [ dataSourceAnnotated, setDataSourceAnnotated ] = useState<any[]>([])
    const productsRef = useRef<any[]>([])
    const productsAnnotatedRef = useRef<any[]>([])
    const [ tabType, setTabType ] = useState<TabTypeEnum>(TabTypeEnum.unknow)

    const fetchData = () => {
        fetchAnnotationUnknowSizes()
            .then(result => {
                if (!Array.isArray(result)) return
                let dataSource = result.map(item => {
                    let sizes: any[] = []
                    Object.keys(item.size || {}).forEach(size => {
                        sizes.push({
                            size,
                            ...item.size[size] || {},
                        })
                    })
                    item.sizes = sizes
                    item.id = [ item.region, item.gender, item.vendor, item.parent, item.category ].join('_')
                    delete item.size
                    return item
                })
                dataSource = sortBy(dataSource, [ 'region', 'gender', 'vendor', 'parent', 'category' ])
                setDataSource(dataSource)
                productsRef.current = dataSource
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchAnnotatedData = () => {
        fetchAnnotationAnnotatedSizes()
            .then(result => {
                if (!Array.isArray(result)) return
                let dataSource = result.map(item => {
                    let sizes: any[] = []
                    Object.keys(item.query_size || {}).forEach(size => {
                        sizes.push({
                            size,
                            ...item.query_size[size] || {},
                        })
                    })
                    item.sizes = sizes
                    item.id = [ item.region, item.gender, item.vendor, item.parent, item.category ].join('_')
                    delete item.query_size
                    return item
                })
                dataSource = sortBy(dataSource, [ 'region', 'gender', 'vendor', 'parent', 'category' ])
                setDataSourceAnnotated(dataSource)
                productsAnnotatedRef.current = dataSource
            })
    }

    useEffect(() => {
        fetchAnnotatedData()
    }, [])

    const onOk = async () => {
        const data = tabType === TabTypeEnum.unknow ? cloneDeep(productsRef.current) : cloneDeep(productsAnnotatedRef.current)

        const maskChildrenPayload = data.filter(item => {
            return item.sizes.filter(item2 => item2.value === 'children')?.length
        }).map(item => {
            const size = item.sizes
                .filter(item2 => item2.value === 'children')
                .map(item2 => item2.size)
            return {
                category: item.category,
                customer: item.customer,
                gender: item.gender,
                region: item.region,
                vendor: item.vendor,
                size,
            }
        })

        if (maskChildrenPayload?.length) {
            await maskChildren(maskChildrenPayload)
        }

        const payload = data.filter(item => {
            return item.sizes.filter(item2 => item2.value && item2.value !== 'children')?.length
        }).map(item => {
            const size = item.sizes
                .filter(s => s.value && s.value !== 'children')
                .reduce((obj, next) => { 
                    obj[next.size] = next.value
                    return obj 
                }, {})
            return {
                category: item.category,
                customer: item.customer,
                gender: item.gender,
                region: item.region,
                vendor: item.vendor,
                size,
            }
        })

        if (payload.length) {
            await setSizeMap(payload)
        }

        if (!payload?.length && !maskChildrenPayload?.length) {
            alert('no change')
            return
        }
        
        await fetchData()
        await fetchAnnotatedData()
    }

    const handleCloseModal = () => closeModal?.(false)

    const [ regionList, setRegionList ] = useState<string[]>([])
    const [ genderList, setGenderList ] = useState<string[]>([])
    const [ categoryList, setCategoryList ] = useState<string[]>([])

    const [ vendorValue, setVendorValue ] = useState('All')
    const [ regionValue, setRegionValue ] = useState('All')
    const [ genderValue, setGenderValue ] = useState('All')
    const [ categoryValue, setCatgoryValue ] = useState('All')

    useEffect(() => {
        if (!dataSource.length || !dataSourceAnnotated.length) return
        let data = tabType === TabTypeEnum.unknow ? dataSource : dataSourceAnnotated
        const regionList = uniq(data.map(item => item.region))
        const genderList = uniq(data.map(item => item.gender))
        const categoryList = uniq(data.map(item => item.category))
        setRegionList([ 'All', ...regionList ])
        setGenderList([ 'All', ...genderList ])
        setCategoryList([ 'All', ...categoryList ])
    }, [ tabType, dataSource, dataSourceAnnotated ])

    return (
        <>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <div style={{ display: 'flex', alignItems: 'flex-end', gap: 20 }}>
                        <Text small>
                            {QA_BUTTON_QA_SIZE_LABEL}
                        </Text>

                        <div style={{ display: 'flex' }}>
                            <div 
                                className={classNames({ [styles.tabItem]: true, [styles.tabItemActive]: TabTypeEnum.unknow === tabType })}
                                onClick={() => setTabType(TabTypeEnum.unknow)}
                            >
                                Not annotated
                            </div>
                            <div 
                                className={classNames({ [styles.tabItem]: true, [styles.tabItemActive]: TabTypeEnum.annotated === tabType })}
                                onClick={() => setTabType(TabTypeEnum.annotated)}
                            >
                                Annotated
                            </div>
                        </div>

                        <Select 
                            label="region"
                            size="small"
                            placeholder="region"
                            options={regionList.map(item => ({ value: item, label: item }))}
                            value={regionValue}
                            onChange={value => setRegionValue(value)}
                        />

                        <Select 
                            label="gender"
                            size="small"
                            placeholder="gender"
                            options={genderList.map(item => ({ value: item, label: item }))}
                            value={genderValue}
                            onChange={value => setGenderValue(value)}
                        />

                        <Select 
                            label="category"
                            size="small"
                            placeholder="category"
                            options={categoryList.map(item => ({ value: item, label: item }))}
                            value={categoryValue}
                            onChange={value => setCatgoryValue(value)}
                        />
                    </div>
                    <CloseIcon className={styles.closeIcon} onClick={handleCloseModal} />
                </div>
                <div className={classNames(styles.body)}>
                    <Spin spinning={loading || fetchAnnotatedSizesLoading || modifyLoading || maskChildrenLoading}>
                        {
                            tabType === TabTypeEnum.unknow ? (
                                <div className={styles.container}>
                                    {
                                        dataSource
                                            .filter(item => {
                                                if (vendorValue === 'All') {
                                                    return true
                                                }
                                                return item.vendor === vendorValue
                                            })
                                            .filter(item => {
                                                if (regionValue === 'All') {
                                                    return true
                                                }
                                                return item.region === regionValue
                                            })
                                            .filter(item => {
                                                if (genderValue === 'All') {
                                                    return true
                                                }
                                                return item.gender === genderValue
                                            })
                                            .filter(item => {
                                                if (categoryValue === 'All') {
                                                    return true
                                                }
                                                return item.category === categoryValue
                                            })
                                            .map((item, index) => {
                                            return (
                                                <Row 
                                                    key={item.id}
                                                    rowData={item}
                                                    onChange={value => {
                                                        const products = cloneDeep(productsRef.current)
                                                        products[index] = value
                                                        productsRef.current = cloneDeep(products)
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <div className={styles.container}>
                                    {
                                        dataSourceAnnotated
                                            .filter(item => {
                                                if (vendorValue === 'All') {
                                                    return true
                                                }
                                                return item.vendor === vendorValue
                                            })
                                            .filter(item => {
                                                if (regionValue === 'All') {
                                                    return true
                                                }
                                                return item.region === regionValue
                                            })
                                            .filter(item => {
                                                if (genderValue === 'All') {
                                                    return true
                                                }
                                                return item.gender === genderValue
                                            })
                                            .filter(item => {
                                                if (categoryValue === 'All') {
                                                    return true
                                                }
                                                return item.category === categoryValue
                                            })
                                            .map((item, index) => {
                                            return (
                                                <Row
                                                    key={item.id}
                                                    rowData={item}
                                                    onChange={value => {
                                                        const products = cloneDeep(productsAnnotatedRef.current)
                                                        products[index] = value
                                                        productsAnnotatedRef.current = cloneDeep(products)
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        
                    </Spin>
                </div>
                <div className={styles.footer}>
                    <Button
                        className={styles.submitBtn}
                        type="danger"
                        loading={modifyLoading || loading}
                        onClick={onOk}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    )
}

export default QaSizeDialog
