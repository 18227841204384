import React from 'react'
import { Switch } from 'componentsv2/form-elements/Switch'
import { useLookbook } from 'hooks/useLookbook'
import type { AxisSwitchProps } from './types'

/**
 * 除了 Price history 每个线图都要加这个 axis switch 组件 
 */
export const AxisSwitch = ({
    value,
    onChange,
    left,
    right,
    className,
}: AxisSwitchProps) => {
    const { isLookbook } = useLookbook()

    return (
        <Switch
            className={className}
            leftLabel="1 Axis"
            rightLabel="2 Axis"
            value={value}
            onChange={onChange}
            right={right}
            left={left}
            disabled={isLookbook}
        />
    )
}
