import React, { CSSProperties, useState } from 'react'
import classnames from 'classnames'
import { paletteList } from 'utils/colorJson'
import { genId } from 'components/common/InfoBox/InfoActionContext'
import { MultiColor as Multi } from 'assets/icons/MultiColor'
import styles from './styles.module.scss'
import { ColorProps } from './types'
import { colorAlgorithm } from './utils'

/**
 * color dot component
 * @param props
 * @constructor
 */
export function ColorDot(props: ColorProps) {
  const defalutStyle = props.width === 36 ? {} : { width: props.width, height: props.width, border: 'none' }
  const colorContainerStyle: CSSProperties = {
    ...props.style || {},
  }
  if (props.isSelected) {
    colorContainerStyle.border = props.selectedBorder
  }
  const defalutColorPartionStyle = item => props.width === 36 ? { backgroundColor: item.color } : {
    width: props.width / 2,
    height: props.width / 2,
    backgroundColor: item.color,
  }
  const isWhite = (props.sortOrder || 0) > 0
  const [ data ] = useState(colorAlgorithm(props.colors))

  let body
  if (isWhite) {
    body = props.sortOrder
  } else if (props?.colors?.length > 0 && props?.colors[0]?.color === 'Others') {
    body = (
      <div className="flex flex-center" style={{ width: '100%', height: '100%' }}>
        ?
      </div>
    )
  } else if (props?.colors?.length > 0 && props.colors[0]?.color === 'Multi-color') {
    body = <Multi />
  } else {
    body = 
      data.map((item, index) => (
        <div
          className={classnames(styles.split, styles['split' + index])}
          key={`${genId()}`}
          style={defalutColorPartionStyle(item)}
        />
      ))
  }

  return (
    <div
      className={classnames(styles['color-container'], props.isSelected ? styles.active : '', props.className)}
      style={{ ...defalutStyle, ...colorContainerStyle }}
      data-tooltip={props.colors[0]?.name}
    >
      <div
        onClick={() => {
          props.click?.()
        }}
        className={classnames(styles.color, isWhite ? 'flex flex1 flex-center' : 'flex')}
        style={defalutStyle}
      >
        {body}
      </div>
    </div>
  )
}

ColorDot.defaultProps = {
  width: 36,
  isSelected: false,
}

export function MultiColor({ width = 10 }) {
  return (
    <ColorDot
      width={10}
      colors={paletteList.slice(0, 4).map(item => ({ color: item, v: 1 }))}
    />
  )
}
