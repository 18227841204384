import { Tooltip } from 'antd'
import { handleName } from 'pages/DynamicDashboard/utils';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styles from './VendorNameTooltip.module.scss'
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined';
import { useWindowWidth } from 'hooks/useWindow';

const VendorNameTooltip = ({
    vendorName,
    description,
}: {
    vendorName?: string;
    description?: string;
}) => {
    const [ delay, setDelay ] = useState(10000)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const titleRef = useRef<HTMLSpanElement>(null)
    const windowWidth = useWindowWidth()

    useLayoutEffect(() => {
        const wrapperEl = wrapperRef.current
        const titleEl = titleRef.current
        if (!wrapperEl || !titleEl) return
        const wrapperWidth = wrapperEl.getBoundingClientRect().width
        const titleWidth = titleEl.getBoundingClientRect().width
        if (titleWidth > wrapperWidth) {
            setDelay(0)
        } else {
            setDelay(10000)
        }
    }, [ windowWidth ])

    return (
        <div className={styles.wrapper}>
            <Tooltip
                title={handleName(vendorName)}
                mouseEnterDelay={delay}
                mouseLeaveDelay={0}
                zIndex={9999992}
                placement="top"
            >
                <div className={styles.vendorName} ref={wrapperRef}>
                    <span ref={titleRef}>{handleName(vendorName)}</span>
                </div>
            </Tooltip>
            {
                description ? (
                    <Tooltip 
                        title={description} 
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                        zIndex={9999992}
                        placement="top"
                    >
                        <div className={styles.tooltip}>
                            <InfoOutlined
                                size={16}
                                color="#bbb"
                            />
                        </div>
                    </Tooltip>
                ) : null
            }
        </div>
    )
}

export default VendorNameTooltip
