import classnames from 'classnames'
import { Button } from 'components/form-elements/Button'
import { todayRange } from 'componentsv2/form-elements/DatePicker'
import React, { useEffect, useState } from 'react'
import { useMinimumDate } from 'componentsv2/form-elements/DatePicker'
import DatePicker, { DayValue } from 'react-modern-calendar-datepicker'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { formatDatePickerToDate, getDateObject } from 'utils/dateUtils'

import { FILTER_DIALOG_CLASS } from 'consts'
import styles from './styles.module.scss'

const emptyPlaceholder = 'Launched after...'

/**
 * 右上角 Filter > Launched after 使用该组件
 */
export const Calendar = props => {
  const [ earliestLaunchDate, setEarliestLaunchDate ] = useState(props.selected)
  const maximumDate = todayRange().from

  const launchDate: DayValue = null

  useEffect(() => {
    setEarliestLaunchDate(props.selected)
  }, [ props.selected ])

  useEffect(() => {
    if (earliestLaunchDate) {
      const earliestLauncha = new Date(earliestLaunchDate)
      const launchDate = getDateObject(earliestLauncha)
      setDay(launchDate)
    } else { setDay(null) }
  }, [ earliestLaunchDate ])

  const [ day, setDay ] = useState<DayValue>(launchDate)
  const placeholder = day ? formatDatePickerToDate(day) : emptyPlaceholder
  useEffect(() => {
    if (day) {
      props.onChange(formatDatePickerToDate(day))
    }
    else {
      props.onChange(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ day ])

  useEffect(() => {
    if (placeholder !== emptyPlaceholder) {
      setEarliestLaunchDate(placeholder)
    }
  }, [ placeholder, setEarliestLaunchDate ])

  const renderCustomInput = ({ ref }) =>
    <input
      readOnly
      ref={ref}
      value={placeholder}
      className={classnames(
        placeholder === emptyPlaceholder ? styles.dateRangeInputEmpty : styles.dateRangeInput,
      )}
    />

  return (
    <div className={styles.container} style={props.containerStyle || {}} >
      <DatePicker
        wrapperClassName={`${props.wrapperClassName || ''}`}
        calendarPopperPosition="bottom"
        value={day}
        customRenderFn={props.customRenderFn}
        renderPostion={props.renderPostion}
        onChange={value => {
          setDay(value)
          props?.onClickDate?.(formatDatePickerToDate(value))
        }}
        colorPrimary="#01a699"
        renderInput={renderCustomInput}
        minimumDate={useMinimumDate()}
        maximumDate={maximumDate}
        renderFooter={() =>
          <div className={`${styles.resetButtonContainer} ${FILTER_DIALOG_CLASS}`}>
            {/* <Button
              onClick={() => {
                setDay(null)
              }}
            >
              Clear
            </Button> */}
          </div>
        }
      />
    </div>
  )
}
