import React, { FC, CSSProperties, memo } from 'react'
import { SizedBox } from 'componentsv2/SizedBox'
import { Spin } from 'componentsv2/Spin'
import { useSellers } from 'graphql/sellers/getSellers'
import { DailyCalendar, CalendarCellProps } from '../DailyCalendar'
import Grid from '../Grid'
import Count from '../Count'
import { PerVendorProps } from '../../types'
import styles from './styles.module.scss'

interface PerVendorProductsProps {
  perVendorData: PerVendorProps;
  year: number;
  month: number;
  onEnterGrid: Function;
  onLeaveGrid: Function;
  onClickGrid: Function;
  clickVendor?: string;
  clickDate?: string;
  clickLoading?: boolean;
  style: CSSProperties;
  loading: boolean;
}

const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]

const PerVendorProducts: FC<PerVendorProductsProps> = ({
  perVendorData,
  year,
  month,            // new Date().getMonth()  数值9表示实际的10月，数值10表示实际的11月
  onEnterGrid,
  onLeaveGrid,
  onClickGrid,
  clickVendor,
  clickDate,
  clickLoading = false,
  style = {},
  loading = false,
}) => {
    // vendors 映射对象
    const { codenames = {} } = useSellers([])

    // 自定义日历单元格
    const onCustomCalendarCell = (cell: CalendarCellProps) => {
        if (cell.month === month + 1) {
            let productsIn = 0
            let productsOut = 0
            let result: any = perVendorData.dailyProductsInOut.find(item => item.date === cell.date)
            if (result) {
                productsIn = result.productsIn
                productsOut = result.productsOut
            } else {
                result = {
                    date: cell.date,
                    productsIn: 0,
                    productsOut: 0,
                }
            }

            return (
                <Grid
                    key={cell.date} 
                    date={cell.date} 
                    productsIn={productsIn}
                    productsOut={productsOut} 
                    onClick={date => onClickGrid(perVendorData, date, result)}
                    onMouseEnter={e => onEnterGrid(e, result)}
                    onMouseLeave={onLeaveGrid}
                    loading={clickLoading && clickVendor === perVendorData?.vendor && clickDate === cell.date}
                />
            )
        } 
        return (
            <Grid 
                key={cell.date} 
                date={cell.date} 
                productsIn={0} 
                productsOut={0} 
                style={{ backgroundColor: 'rgba(0, 0, 0, .05)', color: '#999' }}
            />
        )
    }

    return (
        <div className={styles.perVendor} style={style}>
            <div className={styles.title}>
                <div>
                    <div>{codenames?.[perVendorData.vendor] || perVendorData.vendor}</div>
                    <div className={styles.subtitle}>{months[month]}</div>
                </div>
                <SizedBox width={30} />
                <div>
                    <Count type="in" quantity={perVendorData.totalProductsIn} />
                    <Count type="out" quantity={perVendorData.totalProductsOut} />
                </div>
            </div>
            <SizedBox height={20} />
            <Spin spinning={loading && clickVendor === perVendorData.vendor} minHeight={111}>
                <div className={styles.calendar}>
                    <DailyCalendar 
                        year={year} 
                        month={month} 
                        customCell={onCustomCalendarCell}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default memo(PerVendorProducts)
