import React from 'react'
import { Button } from 'components/form-elements/Button'
import { PlusIcon } from 'assets/icons/Plus'
import { RightCheckIcon } from 'assets/icons/RightCheck'
import classnames from 'classnames'
import { LookbookActionsProp } from './types'
import styles from './styles.module.scss'

export const LookbookActions = ({
  // 产品是否已经添加到 lookbook 中，已添加显示黄色对钩图标，未添加显示绿色加号图标
  addCheckIconToggle,
  addEvent, 
}: LookbookActionsProp) => {
  return (
    <div className={styles.actions}>
      {
        addCheckIconToggle ? (
          <div className={classnames(styles.check, 'check')}>
            <Button
              onlyIcon
              icon={<RightCheckIcon />}
              onClick={e => {
                e.stopPropagation()
                addEvent && addEvent(false, e)
              }}
            />
          </div>
        ) : (
          <div className={classnames(styles.plus, ' plus')}>
            <Button
              onlyIcon
              icon={<PlusIcon />}
              onClick={e => {
                e.stopPropagation()
                addEvent && addEvent(true, e)
              }}
            />
          </div>
        )
      }
    </div>
  )
}

