import React, { FC, useEffect, useState } from 'react'
import { useCompetitorOptions } from 'hooks/useOptions'
import { useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { selectVendor, selectCustomerSeller, selectFilterCountry, selectFilterOldCountry } from 'features/filters/filtersSlice'
import { formatBrandVendor } from 'utils'
import { COMPETITOR_MAX_COUNT, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { Dropdown, DropdownType } from 'componentsv2/form-elements/Dropdown'
import { storage } from 'utils/storage'
import { GROUP_BOGNER_SUFFIX, GROUP_BOGNER_NAME } from 'configs/groups'
import { judgeCompetitorStatusFromPreviousCountry } from 'components/layout/Header/components/HeaderFilter/competitor.util'
import { CompetitorDropdownProps } from './types'

/**
 * 目前系统中所有 Competitor 下拉框行为统一，所以抽出为一个业务组件方便全局处理一些公共属性
 * 
 * 业务逻辑：Competitor 下拉框要和 Filter.Competitor 联动
 * Filter.Competitor 值变化，要更新 Competiro 下拉框
 *      Filter.Competitor 选中项如果大于10项，Competitor 下拉框只显示前十个
 *      Filter.Competitor 一个都没选时，Competitor 下拉框默认选自己
 * Compeitor 下拉框值变化，不需要更新 Filter.Competitor
 */
export const CompetitorDropdown: FC<CompetitorDropdownProps> = ({
    left,
    right,
    style,
    label='Competitor',
    type = DropdownType.MULTI,
    maxCount = COMPETITOR_MAX_COUNT,      // 默认最多展示十个
    minCount = 0,
    clearAllType,
    clearAllMinCount,
    onChange,
    onItemChange,
    hideCustomerAndAll=false,
    hideCustomer = false,
    hideSelectedVendors=false,
    hideAll=false,
    showClearAll = true,
    showSelectAll = true,
    value = [],
    region,
    disabled = false,
    disabledFilter = false,     // Filter.Competitor 变化时，不会影响下拉框选中项
    excludeVendorCodeList = [],
    selectorClassName,
    showOkButton = true,
}) => {
    // e.g [{vendor: 'apc', region: 'Sweden'}]
    const filterCompetitor = useSelector(selectVendor)
    const filterCountry = useSelector(selectFilterCountry)
    const { competitorOptions } = useCompetitorOptions({ 
        region: region || filterCountry, 
        excludeSelectedVendor: hideSelectedVendors, 
        excludeSelf: hideCustomer || hideCustomerAndAll, 
        excludeMarket: hideCustomerAndAll || hideAll,
        excludeVendorCodeList,
    })

    const [ competitorValue ] = useCompetitorValueByRegion({ type: 'object' })
    
    const [ data, setData ] = useState<string[]>([])
    useDeepCompareEffect(() => {
        if (!Array.isArray(value)) return
        let newValue = [ ...value ]
        if (hideCustomer || hideCustomerAndAll) {
            const customerVendor = storage.getCustomerVendor()
            newValue = newValue.filter(item => item !== customerVendor)
        }
        if (hideCustomerAndAll) {
            newValue = newValue.filter(item => item !== MARKET_VENDOR.vendor)
        }
        if (hideSelectedVendors) {
            newValue = newValue.filter(item => item !== SELECTED_VENDORS)
        }
        setData([ ...newValue ])
    }, [ value ])

    /**
     * 需求:
     * Filter.Country 改变时，Filter.Competitor 以及页面中的 Competitor 下拉框会根据 Filter.Country 选中项重新获取对应的下拉框数据源
     * （Sweden 的 Competitor 下拉框数据源和 Germany 的 Competitor 下拉框数据源也许不一样）
     * 此时页面中线图应该要重置为只显示 customer 一条线
     * 
     * 问题描述：
     * 这里之前使用 useDeepCompareEffect，会导致一个问题
     * Filter.Country 选择 Germany，Filter.Competitor 什么也不选，此时 filterCompetitor = []
     * Color analytics 页面，线图上面的 Competitor 下拉框选择几个其它 vendor，此时线图中不止一条线了
     * Filter.Country 选择 Sweden，点击 Filter 外面页面重新加载，会发现线图并未重置
     * 
     * 原因分析:
     * Filter.Country 第二次选择 Sweden 时，filterCompetitor 仍然为 []
     * 而 useDeepCompareEffect 会比较 filterCompetitor 的值(可以认为都序列化字符串然后比较字符串了)而不是比较变量引用
     * 因此不会进入这个钩子函数
     * 
     * 解决方法:
     * 将 useDeepCompareEffect 替换为 useEffect 即可
     */
    useEffect(() => {
        if (competitorOptions.length === 0 || disabledFilter === true) return
        onChange?.(competitorValue.map(item => item.key))
        // eslint-disable-next-line
    }, [filterCompetitor])

    const [ maxCountValue, setMaxCountValue ] = useState(maxCount)
    /**
     * 2022/05/13
     * bogner license 选择 subbrand 时, 要动态修改 Competitor 下拉框 maxCount 值
     * 
     * 问题: 正常情况, Competitor 下拉框最多可以选择 10 个
     * 但当 bogner license 选择超过1个 subbrand 时, 接口会多返回一个合并subbrand后的 vendor, 这个时候下拉框要限制最多只能选9个
     */
    const onMaxCountChange = value => {
        if (!Array.isArray(value)) return
        const subbrandList = value.filter(item => item.endsWith(GROUP_BOGNER_SUFFIX))
        if (subbrandList.length > 1) {
            // 选择了两个 subbrand, maxCount 要减一
            // setMaxCountValue(maxCount - 1)
            setMaxCountValue(maxCount)
        } else {
            // 选择了两个 subbrand, 之后又 deselect 只选择了一个或零个 subbrand, maxCount 值要恢复
            setMaxCountValue(maxCount)
        }
    }

    return (
        <Dropdown
            type={type}
            disabled={disabled}
            left={left}
            right={right}
            label={label}
            placeholder="Competitor"
            title="Competitor"
            maxCount={maxCountValue}
            minCount={minCount}
            style={style}
            showClearAll={showClearAll}
            showSelectAll={showSelectAll}
            dropdownStyle={{ width: '200px', maxHeight: '330px' }}
            selectorClassName={selectorClassName}
            options={competitorOptions}
            value={data}
            clearAllType={clearAllType}
            clearAllMinCount={clearAllMinCount}
            onChange={value => {
                onMaxCountChange(value)
                // only work in `DropdownType.SINGLE` mode
                if (type === DropdownType.SINGLE) {
                    onChange?.(typeof value === 'string' ? [ value ] : value)
                    onItemChange?.(typeof value === 'string' ? [ value ] : value)
                } else {
                    onItemChange?.(typeof value === 'string' ? [ value ] : value)
                }
            }}
            onOk={(value: string[]) => {
                onChange?.(value)
            }}
            showOkButton={showOkButton}
        />
    )
}

CompetitorDropdown.displayName = 'CompetitorDropdown'

/**
 * 获取 Competitor 下拉框选中值
 * 
 * 旧逻辑:
 * Competitor 下拉框选中值和 Filter.Competitor 联动
 * 当 Filter.Competitor 选中项超过十个时，Competitor 下拉框只选前十个
 * 当 Filter.Competitor 一个都没选时，Competitor 下拉框默认选中自己
 * 
 * 2022/03/18 新逻辑: 始终带上 customer
 */
export const useCompetitorValue = (): { vendor: string; region: string; brand?: string; key: string; }[] => {
    // e.g [{vendor: 'apc', region: 'Sweden'}]
    const filterCompetitor = useSelector(selectVendor)
    // e.g {vendor: 'apc', region: 'Sweden'}
    const customerSeller = useSelector(selectCustomerSeller)

    const newFilterCompetitor = filterCompetitor.map(item => {
        if (Array.isArray(item.brands)) {
            return item.brands.map(brand => ({
                brand,
                vendor: item.vendor,
                region: item.region,
                key: formatBrandVendor(brand, item.vendor),
            }))
        }
        return [
            {
                region: item.region,
                vendor: item.vendor,
                key: item.vendor,
            },
        ]
    }).flat(2)

    const competitorValue = newFilterCompetitor.filter(item => item.vendor !== customerSeller.vendor)
    competitorValue.unshift({ ...customerSeller, key: customerSeller.vendor })

    return competitorValue
}

/**
 * 2022/04/15 需求
 * 
 * 由
 *      A [1,2,3,4,5]
 *      B [2,3,4,5,6,7]
 *      A selected [1,2,3]
 * 得出
 *      B selected [2,3,6,7]
 * 
 * 如何计算出 B 选中值？
 * 1. A 选中的 aSelected = [1,2,3]
 * 2. B 和 A 交集 c1 = [2,3,4,5]
 * 3. c1 再和 A 中选中的求交集 c2 = [2,3]
 * 4. B 有的 A 没有的 c3 = [6,7]
 * 5. c2 和 c3 的并集得出 b 选中的 bSelected = [2,3,6,7]
 * 
 * 特殊情况：
 * 当 aSelected 一个没选时，bSelected 也返回空
 */
export function useCompetitorValueByRegion(opt?: {
    oldRegion?: string;
    newRegion?: string;
    type?: 'string' | 'object';
}): any {
    const filterCountry = useSelector(selectFilterCountry)
    const filterOldCountry = useSelector(selectFilterOldCountry)

    const oldRegion = opt?.oldRegion || filterOldCountry
    const newRegion = opt?.newRegion || filterCountry
    const type = opt?.type || 'string'
    const { competitorOptions: oldCompetitorOptions } = useCompetitorOptions({ region: oldRegion })
    const { competitorOptions: newCompetitorOptions } = useCompetitorOptions({ region: newRegion })

    const [ competitorValue, setCompetitorValue ] = useState<string[]>(useCompetitorValue().map(item => item.key) || [])
    const [ competitorObjValue, setCompetitorObjValue ] = useState<{ vendor: string; region: string; brand?: string; key: string; }[]>(useCompetitorValue() || [])

    useDeepCompareEffect(() => {
        if (oldRegion && newRegion && oldRegion !== newRegion && oldCompetitorOptions.length > 0 && newCompetitorOptions.length > 0) {
            const oldCptt = oldCompetitorOptions.map(item => item.key)

            const objValue = newCompetitorOptions.filter(item => judgeCompetitorStatusFromPreviousCountry(
                type === 'string' ? competitorValue : competitorObjValue.map(item => item.key),
                oldCptt,
                item.key,
            ))
            const value = objValue.map(item => item.key)
            setCompetitorObjValue(objValue)
            setCompetitorValue(value)
        }
        // eslint-disable-next-line
    }, [oldRegion, newRegion, oldCompetitorOptions, newCompetitorOptions])

    if (type === 'string') {
        return [ competitorValue, setCompetitorValue ]
    }
    return [ competitorObjValue, setCompetitorObjValue ]
}

/**
 * 2022/05/13
 * 
 * bogner license 选择大于1个 subbrand 时, 接口会多返回一个 Group vendor
 * 但是 Competitor 下拉框中并不显示这个 Group vendor
 * 代码中经常会获取 competitor 选中值, 做一些过滤操作，
 * 这里需要处理如果 bogner license 选择大于1个 subbrand 时, competitor 选中值要包含这个 Group vendor
 */
export function getComeptitorValueByGroup(competitorValue: string[]): string[] {
    const newCompetitorValue = [ ...competitorValue ]
    const bognerSubbrandList = competitorValue.filter(item => item.endsWith(GROUP_BOGNER_SUFFIX))
    if (bognerSubbrandList.length > 1) {
        newCompetitorValue.push(GROUP_BOGNER_NAME)
    }
    return newCompetitorValue
}
