import React from 'react'
import { Box, Flex, Flex1, CalendarOutlined } from 'druikit'
import { useCategoryValue, useFetchPayload, useModalExportDisabled } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import { getExportSelector, regionShortMappingFn } from 'utils'
import { cloneDeep } from 'lodash'
import { ExportDropdown, getHtml2CanvasImgUploadPath } from 'componentsv2/ExportDropdown'
import { downloadFileByAxios } from 'export'
import { getExportFilename } from 'utils/exportUtils'
import { useDateObj } from 'pages/DynamicDashboard/hooks/useApi'
import { useCompetitorNavLabel } from 'pages/DynamicDashboard/hooks/useNav'
import styles from './styles.module.scss'
import DateRangeText from 'componentsv2/business/DateRangeText/DateRangeText'
import { handleName } from 'pages/DynamicDashboard/utils'
import { useFilterCountry } from 'hooks/useFilter'
import { RoutesObj } from 'global/Routes'

export const MetricNav = ({
    name,
    metric,
}: {
    name?: string;
    metric: 'Product newness' | 'Assortment mix' | 'Discount effect' | 'Sold out' | 'Sales price performance' | 'Full price performance',
}) => {
    const [ regionValue ] = useFilterCountry()
    const [ categoryValue ] = useCategoryValue()
    const [ modalExportDisabled ] = useModalExportDisabled()
    const [ fetchPayload ] = useFetchPayload()
    const competitorLabel = useCompetitorNavLabel()

    const exportFilename = getExportFilename('Dynamic dashboard ' + name || '')
    const exportSelector = getExportSelector('dynamic-dashboard-detail')

    const onExportExcel = async () => {
        const payload: any = cloneDeep(fetchPayload)
        payload.url = 'dashboard/export_dynamic_dashboard'
        payload.query.graph_image = await getHtml2CanvasImgUploadPath({ selector: '#dynamic-dashboard-middle-graph' })
        payload.query.metric = metric
        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    const dateObj = useDateObj()

    return (
        <Flex 
            className={styles.wrapper} 
            style={{
                position: window.location.pathname === RoutesObj.DYNAMIC_DASHBOARD_REPORT.URL ? 'unset' : 'sticky',
            }}
        >
            <Flex 
                flexGrow={1}
                height={38} 
                lineHeight={38}
                backgroundColor="#74B6B6" 
                color="#fff" 
                paddingLeft={34}
                paddingRight={20}
                width="calc(100% - 200px)"
            >   
                <Flex1 ellipsis width="calc(100% - 160px)">
                    <span style={{ fontSize: 21 }}>{handleName(name)},&nbsp;</span>
                    <span style={{ fontSize: 16 }}>
                        {regionShortMappingFn(regionValue)} | { cloneDeep(categoryValue).sort().join(', ') }
                    </span>
                    <span style={{ fontSize: 16 }}>&nbsp; | &nbsp; {competitorLabel}</span>
                </Flex1>
                <Box width={20} />
                <Flex fullHeight alignItems="center">
                    <Box width={8} />
                    <CalendarOutlined color="#fff" size={16} />
                    <Box width={8} />
                    <DateRangeText type="date" calendarStyle={{ top: 46, left: '-50%' }}>
                        <Box fontSize={14} paddingTop={2} cursor="pointer">
                            {dateObj?.date?.split('_')?.map(item => item.replaceAll('-', '').slice(2))?.join('-')}
                        </Box>
                    </DateRangeText>
                </Flex>
            </Flex>
            <Box width={52} />
            <ExportDropdown
                onExportExcel={onExportExcel}
                selector={exportSelector}
                fileName={exportFilename}
                scrollHeight
                enableForceLoadImg={true}
                imgsSeletor="img"
                disabled={modalExportDisabled}
            />
        </Flex>
    )
}
