import React, { CSSProperties } from 'react'
import { XAxis, YAxis, CartesianGrid, LineChart, Line, Tooltip } from 'recharts'
import { MARKET_VENDOR } from 'consts';
import { colors, getMaxTick } from './utils';
import { Flex, dateUtils } from 'druikit';
import { PERIOD_LAST_QUARTER } from 'features/filters/dynamicDashboardSlice';
import { getVendorNameByCode } from 'utils';
import { handleName } from 'pages/DynamicDashboard/utils';
import { useCalendarPeriodValue } from 'hooks/useGlobalData';
import { usePovValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData';

export const MyLineChart = ({
    width = 400,
    height = 230,
    dataSource = [],
    yAxisFormatter,
    yAxesMaxFixed,
    tooltipFormatter,
}: {
    width?: number;
    height?: number;
    dataSource?: { label: string, value: number; }[];
    yAxisFormatter?: (value: number) => string;
    yAxesMaxFixed?: boolean;
    tooltipFormatter?: (value: number) => string;
}) => {
    const [ periodValue ] = useCalendarPeriodValue()
    const [ customerVendor ] = usePovValue()
    const comparisonVendor = Object.keys(dataSource?.[0] || {}).filter(item => ![ customerVendor, MARKET_VENDOR.vendor, 'label' ].includes(item))?.[0]
    const chartWidth = width

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ padding: '10px 16px', border: '1px solid #ccc', backgroundColor: '#fff', fontSize: 11 }}>
                    {
                        payload.map(p => {
                            const vendorName = getVendorNameByCode(p.name)
                            return (
                                <Flex alignItems="center" key={p.color} style={{ width: 200 }}>
                                    <div style={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: p.color, marginRight: 10 }} />
                                    <div style={{ flex: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                        {handleName(vendorName)}
                                    </div>
                                    <div style={{ textAlign: 'right', marginLeft: 8 }}>
                                        {
                                            typeof tooltipFormatter === 'function' ? 
                                                tooltipFormatter(p.value) : 
                                                p.value
                                        }
                                    </div>
                                </Flex>
                            )
                        })
                    }
                </div>
            );
        }
        return null
    }

    const CustomTooltipV2 = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const sty: CSSProperties = {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#fff',
                background: '#000',
                height: '26px',
                lineHeight: '26px',
                borderRadius: 2,
                paddingInline: 4,
            }
            return (
                <div style={sty}>
                    {label}
                </div>
            );
        }
        return null
    }

    return (
        <div style={{ position: 'relative' }}>
            <LineChart
                width={chartWidth}
                height={height}
                data={dataSource}
                syncId="anyId"
                margin={{
                    top: 10,
                    bottom: 0,
                    right: 20,
                }}
            >
                <CartesianGrid 
                    strokeDasharray="3 3" 
                    vertical={false}
                />
                <XAxis
                    dataKey="label"
                    axisLine={false}
                    tickLine={false}
                    minTickGap={100}
                    tick={{ fontSize: 12, color: '#666' }}
                    tickMargin={10}
                    tickFormatter={(value) => {
                        const d = dateUtils.format(new Date(value))
                        const day = d.day
                        const month = d.month
                        return `${day} ${dateUtils.MONTHS_ABBR[Number(month) - 1]}`
                    }}
                />
                <YAxis
                    axisLine={false}
                    tickSize={0}
                    tick={{ fontSize: 12, color: '#666' }}
                    tickFormatter={yAxisFormatter}
                    tickMargin={6}
                    domain={yAxesMaxFixed ? [ 0, 100 ] : [ 0, getMaxTick(dataSource.map(item => ({ label: item.label, value: Math.max(item[customerVendor], item[comparisonVendor], item[MARKET_VENDOR.vendor]) }))) ]}
                />
                <Tooltip
                    wrapperStyle={{ fontSize: 10 }}
                    position={{ y: 40 }}
                    content={CustomTooltip as any}
                    // isAnimationActive
                />
                <Line type="linear" dataKey={customerVendor} stroke={colors[0]} strokeWidth={2} dot={{ r: periodValue === PERIOD_LAST_QUARTER ? 0 : 3 }} activeDot={{ color: colors[0] }} />
                <Line type="linear" dataKey={comparisonVendor} stroke={colors[1]} strokeWidth={2} dot={{ r: periodValue === PERIOD_LAST_QUARTER ? 0 : 3 }} activeDot={{ color: colors[1] }} />
                <Line type="linear" dataKey={MARKET_VENDOR.vendor} stroke={colors[2]} strokeWidth={2} dot={{ r: periodValue === PERIOD_LAST_QUARTER ? 0 : 3 }} activeDot={{ color: colors[2] }} />
            </LineChart>
            <LineChart
                style={{ position: 'absolute', top: 0, left: 0 }}
                width={chartWidth}
                height={height}
                data={dataSource}
                syncId="anyId"
                margin={{
                    top: 10,
                    bottom: 0,
                    right: 20,
                }}
            >
                <CartesianGrid
                    style={{ display: 'none' }}
                    strokeDasharray="3 3" 
                    vertical={false}
                />
                <XAxis
                    style={{ display: 'none' }}
                    dataKey="label"
                    axisLine={false}
                    tickLine={false}
                    minTickGap={100}
                    tick={{ fontSize: 12, color: '#666' }}
                    tickMargin={10}
                    tickFormatter={(value) => {
                        const d = dateUtils.format(new Date(value))
                        const day = d.day
                        const month = d.month
                        return `${day} ${dateUtils.MONTHS_ABBR[Number(month) - 1]}`
                    }}
                />
                <YAxis
                    style={{ display: 'none' }}
                    axisLine={false}
                    tickSize={0}
                    tick={{ fontSize: 12, color: '#666' }}
                    tickFormatter={yAxisFormatter}
                    tickMargin={6}
                    domain={yAxesMaxFixed ? [ 0, 100 ] : [ 0, getMaxTick(dataSource.map(item => ({ label: item.label, value: Math.max(item[customerVendor], item[comparisonVendor], item[MARKET_VENDOR.vendor]) }))) ]}
                />
                <Tooltip
                    wrapperStyle={{ fontSize: 10 }}
                    position={{ y: height - 25 }}
                    content={CustomTooltipV2 as any}
                    // isAnimationActive
                />
                <Line style={{ display: 'none' }} type="linear" dataKey={customerVendor} stroke={colors[0]} strokeWidth={2} activeDot={{ r: 8, color: colors[0] }} />
                <Line style={{ display: 'none' }} type="linear" dataKey={comparisonVendor} stroke={colors[1]} strokeWidth={2} activeDot={{ r: 8, color: colors[1] }} />
                <Line style={{ display: 'none' }} type="linear" dataKey={MARKET_VENDOR.vendor} stroke={colors[2]} strokeWidth={2} activeDot={{ r: 8, color: colors[2] }} />
            </LineChart>
        </div>
    )
}
