import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'

const defaultRect = {
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: 0,
  height: 0,
  x: 0, 
  y: 0,
}

enum ArrowAlign {
  center = 'arrowCenter',      // 居中
  left = 'arrowLeft',          // 居左
  right = 'arrowRight',        // 居右
}

/**
 * ## 何时使用
 * 
 * 在鼠标点击元素的下方显示弹出框
 * 
 * ## 代码演示
 * 
 * 鼠标在元素上悬浮时显示弹出框，鼠标离开元素时关闭弹出框
 * 
 * ```
 * const [visible, setVisible] = useState(false)
 * const [target, setTarget] = useState()
 * 
 * return (
 *     <>
 *         <div onMouseEnter={(e) => setVisible(true); setTarget(e.target);}
 *             onMouseLeave={() => setVisible(false)}>
 *             hover me
 *         </div>
 *         {
 *             visible && (
 *                 <Popover target={target}>
 *                     <div>
 *                         <div>hello</div>
 *                         <div>world</div>
 *                     </div>
 *                 </Popover>
 *             )
 *         }
 *     </>
 * )
 * ```
 */
export const Popover = ({
  target,
  children,
  zIndex = 10,
}) => {
  // 目标对象
  const [ targetRect, setTargetRect ] = useState(defaultRect)

  useEffect(() => {
    if (target) {
      setTargetRect(target.getBoundingClientRect())
    }
  }, [ target ])

  // 弹窗对象
  const popoverRef = useRef<HTMLDivElement>(null)
  const [ popoverRect, setPopoverRect ] = useState(defaultRect)

  useEffect(() => {
    if (popoverRef.current) {
      setPopoverRect(popoverRef.current.getBoundingClientRect())
    }
  }, [])

  const [ left, setLeft ] = useState(-1000)
  const [ top, setTop ] = useState(-1000)
  const [ arrowAlign, setArrowAlign ] = useState(ArrowAlign.center)

  // 计算 popover 坐标
  useLayoutEffect(() => {
    const distance = popoverRect.width - targetRect.width
    const halfDistance = distance / 2

    // 目标对象右边框距离浏览器右边缘距离
    const right = document.body.clientWidth - targetRect.right

    if (distance && targetRect.left < halfDistance && right > halfDistance) {        // 居左
      setLeft(targetRect.x)
      setArrowAlign(ArrowAlign.left)
    } else if (distance && targetRect.left > halfDistance && right < halfDistance) { // 居右
      setLeft(targetRect.x - distance)
      setArrowAlign(ArrowAlign.right)
    } else {    // 居中
      setLeft(targetRect.x - halfDistance)
      setArrowAlign(ArrowAlign.center)
    }

    setTop(targetRect.y + targetRect.height + 10)
  }, [ targetRect, popoverRect ])

  return (
      <div 
          className={styles.popoverWrap} 
          style={{
              left: left + 'px',
              top: top + 'px',
              zIndex,
            }}
          ref={popoverRef}
      >
          <div className={classnames(styles.popoverArrow, styles[arrowAlign])} />
          <div className={styles.popoverContent}>
              {children}
          </div>
      </div>
  )
}
