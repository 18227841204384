import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { MainSectionProps } from './types'

export const MainSection = ({ children, initialLayout }: MainSectionProps) =>
    <section className={classnames(styles.initialLayout, !initialLayout && styles.flex)}>
        {children}
    </section>
  
