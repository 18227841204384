import React, { useEffect, useState } from 'react'
import { Button, Select, Spin, Switch } from 'druikit';
import { Text } from 'components/typography'
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import styles from './PriceBackpolationDialog.module.scss'
import { QA_BUTTON_PRICE_BACKPOLATION_LABEL, QA_BUTTON_TARGET_PRODUCT_DATA, QA_BUTTON_TARGET_TO_DB3, QA_BUTTON_TARGET_TO_SIZE_PRICE_MONITOR, QA_BUTTON_TARGET_UNDO_DB3, QA_BUTTON_TARGET_UNDO_SIZE_PRICE_MONITOR } from 'consts/strings';
import { getCompetitorOptions, getQaButtonTargetOptions, getRegionOptions } from 'utils/optionsUtils';
import { dateUtils } from 'norna-uikit'
import { useFetch } from 'libs';
import { cloneDeep } from 'lodash';
import { storage } from 'utils/storage';

export default function PriceBackpolationDialog({
    closeModal,
}: {
    closeModal?: (value: boolean) => void;
}) {
    const [ targetValue, setTargetValue ] = useState<string>(QA_BUTTON_TARGET_TO_DB3)
    const [ vendorValue, setVendorValue ] = useState<string>('')
    const [ regionValue, setRegionValue ] = useState<string>('')
    const [ startDateValue, setStartDateValue ] = useState<string>(dateUtils.today())
    const [ endDateValue, setEndDateValue ] = useState<string>(dateUtils.today())
    const [ referStartDateValue, setReferStartDateValue ] = useState<string>(dateUtils.today())
    const [ referEndDateValue, setReferEndDateValue ] = useState<string>(dateUtils.today())
    const [ overwriteValue, setOverwriteValue ] = useState<boolean>(true)

    const [ regionOptions, setRegionOptions ] = useState<any[]>([])
    const [ vendorOptions, setVendorOptions ] = useState<any[]>([])

    useEffect(() => {
        let regionOptions: any[] = getRegionOptions()
        if (!regionOptions.length) return
        regionOptions = regionOptions.map(item => ({ value: item.key, label: item.key }))
        const regionValue = regionOptions[0].value
        const vendorOptions = getCompetitorOptions({ region: regionValue, excludeMarket: true, excludeSelectedVendor: true })
            .map(item => ({ value: item.key, label: item.key }))
        setRegionValue(regionValue)
        setRegionOptions(regionOptions)
        setVendorOptions(vendorOptions)
        setVendorValue(vendorOptions[0]?.value || '')
    }, [])

    const { postFn: fetch } = useFetch()
    const [ loading, setLoading ] = useState<boolean>(false)

    const onOk = async () => {
        const user = localStorage.getItem('email')

        if (!startDateValue || !endDateValue || !referStartDateValue || !referEndDateValue) {
            alert('Form items cannot be empty.')
            return
        }

        const sellers = storage.getSellers()
        const seller = sellers.find(item => item.vendor === vendorValue && item.region === regionValue)

        const commonPayload = {
            query: {
                start_date: startDateValue,
                end_date: endDateValue,
                user,
            },
            data: {
                seller: {
                    vendor: seller.vendor,
                    region: seller.region,
                    brand: seller.brand || '',
                },
                refer_dates: {
                    dates: [ 
                        referStartDateValue, 
                        referEndDateValue, 
                    ],
                },
            },
        }

        let payload: any = {}
        if (targetValue === QA_BUTTON_TARGET_TO_DB3) {
            payload = {
                url: '/price_update/price_backpolation_to_db3/',
                query: {
                    ...commonPayload.query,
                    overwrite: overwriteValue,
                },
                data: {
                    ...commonPayload.data,
                },
            }
        } 
        else if (targetValue === QA_BUTTON_TARGET_UNDO_DB3) {
            payload = {
                url: '/price_update/revert_price_backpolation/',
                query: {
                    ...commonPayload.query,
                },
                data: {
                    ...commonPayload.data,
                },
            }
        }
        else if (targetValue === QA_BUTTON_TARGET_TO_SIZE_PRICE_MONITOR) {
            payload = {
                url: '/price_update/price_backpolation_to_size_price_monitor/',
                query: {
                    ...commonPayload.query,
                    overwrite: overwriteValue,
                },
                data: {
                    ...commonPayload.data,
                },
            }
        }
        else if (targetValue === QA_BUTTON_TARGET_UNDO_SIZE_PRICE_MONITOR) {
            payload = {
                url: '/price_update/revert_size_price_monitor_backpolation/',
                query: {
                    ...commonPayload.query,
                },
                data: {
                    ...commonPayload.data,
                },
            }
        }
        else {
            payload = {
                url: '/price_update/price_backpolation_to_product_data/',
                query: {
                    ...commonPayload.query,
                },
                data: {
                    ...commonPayload.data,
                },
            }
        }

        setLoading(true)
        let result: any
        if (targetValue === QA_BUTTON_TARGET_PRODUCT_DATA) {
            if (window.confirm('Are you sure to do it?')) {
                result = await fetch(payload.url, payload)
            }
        } else {
            result = await fetch(payload.url, payload)
        }
        setLoading(false)

        if (result?.data?.success === true) {
            alert(result?.data?.message || 'success.')
        } else {
            alert(result?.data?.message || 'fail.')
        }
    }

    const handleCloseModal = () => closeModal?.(false)

    return (
        <>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <Text small>
                        {QA_BUTTON_PRICE_BACKPOLATION_LABEL}
                    </Text>
                    <CloseIcon
                        className={styles.closeIcon}
                        onClick={handleCloseModal}
                    />
                </div>
                <div className={classNames(styles.body)}>
                    <Spin spinning={false} className={styles.form}>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Target
                            </div>
                            <Select 
                                options={getQaButtonTargetOptions()}
                                value={targetValue}
                                onChange={setTargetValue}
                                size="small"
                                placeholder="Target"
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Seller
                            </div>
                            <Select 
                                options={cloneDeep(regionOptions)}
                                value={regionValue}
                                onChange={(value) => {
                                    setRegionValue(value)
                                    const vendorOptions = getCompetitorOptions({ region: value, excludeMarket: true, excludeSelectedVendor: true })
                                        .map(item => ({ value: item.key, label: item.key }))
                                    setVendorOptions(vendorOptions)
                                    setVendorValue(vendorOptions[0]?.value || '')
                                }}
                                size="small"
                                placeholder="Region"
                            />
                            <Select 
                                options={cloneDeep(vendorOptions)}
                                value={vendorValue}
                                onChange={setVendorValue}
                                size="small"
                                placeholder="Vendor"
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Start date
                            </div>
                            <input 
                                type="date"
                                className={classNames(styles.date, styles.dateActive)}
                                value={startDateValue}
                                onChange={e => setStartDateValue(e.target.value)}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                End date
                            </div>
                            <input 
                                type="date"
                                className={classNames(styles.date, styles.dateActive)}
                                value={endDateValue}
                                onChange={e => setEndDateValue(e.target.value)}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Refer date range
                            </div>
                            <input 
                                type="date"
                                className={classNames(styles.date, styles.dateActive)}
                                value={referStartDateValue}
                                onChange={e => setReferStartDateValue(e.target.value)}
                            />
                            <input 
                                type="date"
                                className={classNames(styles.date, styles.dateActive)}
                                value={referEndDateValue}
                                onChange={e => setReferEndDateValue(e.target.value)}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.formLabel}>
                                Overwrite existing products
                            </div>
                            <Switch
                                labelStyle={{ height: 0 }}
                                size="small"
                                value={overwriteValue}
                                onChange={setOverwriteValue}
                                leftLabel="False"
                                rightLabel="True"
                            />
                        </div>
                    </Spin>
                </div>
                <div className={styles.footer}>
                    <Button
                        className={styles.submitBtn}
                        type="danger"
                        loading={loading}
                        onClick={onOk}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    )
}

