import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { ErrorInfoProps, NoDataProps } from './types'
import { Message } from '../form-elements/Message'

export function NoData({ children, fullWidth, small }: NoDataProps) {
  return (
      <div className={classnames(styles.noData, fullWidth && styles.fullWidth, small && styles.small)}>
          {children || 'No data'}
      </div>
  )
}

export function ErrorInfo({ msg }: ErrorInfoProps) {
  return (
      <Message message={msg} error />
  )
}

