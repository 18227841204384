import { Circle } from './Circle'
import { CircleType, PointType } from './WireframeTool.type'

export class WireframeDrawer {
    // points: Array<PointType>;
    circles: Array<CircleType>

    context: CanvasRenderingContext2D

    canvas: HTMLCanvasElement

    selectedIndex: number          // 当前选中点的索引

    rectDrawing = false   // 新添加第二个节点在拖拽矩形

    paths: Array<Path2D>

    /**
     * constructor method
     * 
     * @param context 
     * @param canvas 
     * @param circles 
     * @returns 
     */
    constructor(context: CanvasRenderingContext2D, canvas: HTMLCanvasElement, circles: Array<Circle>) {

        this.context = context
        this.selectedIndex = -1
        const circle = new Circle(100, 100)
        this.circles = circles ?? [ circle ]
        this.paths = []

        this.canvas = canvas
        if (!context) return
        if (!canvas) return
        // this.renderPath()
        this.renderCircle()
    }

    readonly bg = 'transparent'

    readonly strokeColor = '#01a699'

    readonly strokeStyle = '#ec7765'

    /**
     * path
     */
    renderPath(): void {
        const { context, circles } = this

        // 如果点击新的位置，则进入下面的代码，绘制点
        context.lineWidth = 1
        context.moveTo(circles[0].x, circles[0].y)

        // 从起始点开始绘制
        circles.forEach(item => {
            context.lineTo(item.x, item.y)

        })
        context.closePath()

        context.fillStyle = this.bg
        context.fill()
        context.strokeStyle = this.strokeColor
        context.stroke()

    }

    /** *
     * render circle
     */
    renderCircle(): void {

        const { context, circles } = this

        const paths: Array<Path2D> = []
        context.clearRect(0, 0, this.canvas.width, this.canvas.height)

        context.globalAlpha = 0.85
        context.beginPath()

        // 遍历数组画圆
        circles.forEach(item => {
            const circle = item
            const path: Path2D = new Path2D()

            context.fillStyle = circle.color
            context.strokeStyle = this.strokeStyle
            path.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2)
            path.closePath()
            // path.
            context.stroke(path)
            paths.push(path)
        })

        this.paths = paths
        context.closePath()
        if (circles.length)
            this.renderPath()
    }

    /**
     * coordinate of current click on the canvas
     * @param e 
     * @returns 
     */
    getXY(e: any): PointType {
        const canvasRect: DOMRect = this.canvas.getBoundingClientRect()

        const x = e.pageX - canvasRect.left
        const y = e.pageY - canvasRect.top - window.scrollY
        return { x, y }
    }

    /**
     *  
     * @param x  current x  
     * @param y 
     * @returns  point of the shortest distance from current click point
     */
    getLatestDistancePointIndex(x: number, y: number): number {
        try {
            if (!this.circles.length) return -1
            const resMap = this.circles.reduce((res: any, circle, index) => {
                const distanceFromCenter = (circle.x - x) ** 2
                    + (circle.y - y) ** 2
                res[Math.floor(distanceFromCenter)] = index
                return res
            }, {})

            const [ min, lastSec ] = Object.keys(resMap).map(item => parseFloat(item)).sort((a, b) => {
                if (a > b) return 1
                if (a < b) return -1
                return 0
            })
            if (resMap[min] + 1 === resMap[lastSec]) {
                return resMap[min]
            } if (resMap[lastSec] + 1 === resMap[min]) { // 两元素相邻
                return resMap[lastSec]
            }
            // index point of the shortest disctance 
            const minValIndex = resMap[min]; let prePointDistance = 0; let nextPointDistance = 0

            // prePointDistance distance 
            if (minValIndex === 0) {
                prePointDistance = (this.circles[this.circles.length - 1].x - x) ** 2
                    + (this.circles[this.circles.length - 1].y - y) ** 2
            } else {
                prePointDistance = (this.circles[minValIndex].x - x) ** 2
                    + (this.circles[minValIndex].y - y) ** 2
            }

            // nextPointDistance distance 
            if (minValIndex === this.circles.length - 1) {
                nextPointDistance = (this.circles[0].x - x) ** 2
                    + (this.circles[0].y - y) ** 2
            } else {
                nextPointDistance = (this.circles[minValIndex + 1].x - x) ** 2
                    + (this.circles[minValIndex + 1].y - y) ** 2
            }

            if (nextPointDistance >= prePointDistance) {
                return minValIndex
            } if (minValIndex === 0) {
                return this.circles.length - 1
            }
            return minValIndex - 1
        } catch (e) {
            console.log(e)
            return -1
        }

    }

    /**
     * get the drawed points array
     * @returns 
     */
    getCircles(): Array<Circle> {
        return this.circles || []
    }

    clear(): void {
        this.circles = []
        this.renderCircle()
    }

    insertPoints(x: number, y: number) {
        const that = this

        // 新增第二个点时画巨形
        if (that.circles.length === 1) {
            const first = that.circles[0]
            // second
            const circleSecond = new Circle(first.x, y)
            that.circles.push(circleSecond)

            // third
            const circleThird = new Circle(x, y)
            that.circles.push(circleThird)

            // fourth
            const circleFourth = new Circle(x, first.y)
            that.circles.push(circleFourth)
            that.selectedIndex = 2
            that.rectDrawing = true
        } else {
            // 临近元素增加新节点
            const index = that.getLatestDistancePointIndex(x, y)
            const circle = new Circle(x, y)

            that.circles.splice(index + 1, 0, circle)
            that.selectedIndex = index + 1 // set the selected index of the newest one
            that.rectDrawing = false

        }
    }

    /**
     * init method
     */
    init() {
        const { canvas } = this
        const { context } = this

        const self = this; //eslint-disable-line

        canvas.onmousedown = function (e) {
            const { x, y } = self.getXY(e)

            // point in path
            self.paths.forEach((path, index) => {
                if (context.isPointInPath(path, x, y)) {
                    self.selectedIndex = index
                }
            })

            if (self.selectedIndex > -1) return

            // new pointer
            self.insertPoints(x, y)

            self.renderCircle()
        }

        canvas.onmousemove = function (e) {
            // 判断圆圈是否开始拖拽
            if (self.selectedIndex > -1 && self.circles[self.selectedIndex]) { // draging
                // 取得鼠标位置
                const { x: x1, y: y1 } = self.getXY(e)
                // 根据上文得到的index设置index点位置随鼠标改变

                self.circles[self.selectedIndex].x = x1
                self.circles[self.selectedIndex].y = y1

                if (self.rectDrawing) {
                    self.circles[1].y = y1
                    self.circles[3].x = x1
                }
                self.renderCircle()

            }
        }

        canvas.onmouseup = function () {
            self.selectedIndex = -1
        }

        canvas.onmouseout = function () {
            self.selectedIndex = -1
        }

    }
}
