import React from 'react'
import { usePageDate } from 'hooks/usePageDate'
import { formatDateStr2 } from 'utils/dateUtils'
import { Flex } from 'componentsv2/Flex'
import { useWindowZoom } from 'hooks/useWindow'
import { Tooltip as NornaTooltip } from 'componentsv2/Tooltip'
import styles from './stylesv2.module.scss'

export const ComparisonTooltipV2 = ({
    children,
    width,
    height,
}: {
    children: any;
    width?: number;
    height?: number;
}) => {
    const zoom = useWindowZoom()
    const { pageDate, comparisonPageDate } = usePageDate()

    const tooltipContent = (
        <div>
            <div style={{ fontSize: '14px', fontWeight: 'normal', color: '#585858', marginBottom: '6px' }}>Comparison period</div>
            <div style={{ fontSize: '12px', fontWeight: 'normal', color: '#999999' }}>{`${formatDateStr2(pageDate)} vs ${formatDateStr2(comparisonPageDate)}`}</div>
        </div>
    )

    return (
        <NornaTooltip title={tooltipContent} zoom={zoom}>
            <span className={styles.tooltipWrapper} style={{ display: 'inline-block', width: width + 'px', height: height + 'px' }}>
                {children}
                <Flex className={styles.svg} style={{ width: width + 'px', height: height + 'px' }} alignItems="center" justifyContent="center">
                    <CalendarIcon />  
                </Flex>
            </span>
        </NornaTooltip>
    )
}

export const CalendarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="rgb(1, 166, 153)">
            <g>
                <rect fill="none" height="24" width="24" />
            </g>
            <g>
                <path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z" />
            </g>
        </svg>
    )
}
