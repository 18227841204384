import {  withStyles ,createStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'

export const StyledTableBodyCell = withStyles(() => createStyles({
  root: {
    paddingLeft: '0',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: '0',
    border: 'none',
    height: '40px',
    borderBottom: 'none',
    color: '#484848',
    position:'relative',
    background: 'rgba(116, 182, 182, 0.15)',
    fontSize: '14px',
  },
}))(TableCell)
