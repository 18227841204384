import React, { memo, useState } from 'react'
import classNames from 'classnames'
import { CalendarOutlined, ClickAwayListener } from 'druikit'
import styles from './ShortcutDateGroup.module.scss'
import { dateRangeUtils, getValidDateRange } from './ShortcutDateGroup.util'
import DateRangePanel from './DateRangePanel'
import { getThisYearStr } from 'utils/dateUtils'

enum DATE_ALIAS_ENUMS {
    LAST_WEEK = 'Last week',
    LAST_MONTH = 'Last month',
    LAST_QUARTER = 'Last quarter',
    LAST_6_MONTHS = 'Last 6 months',
    LAST_YEAR = 'Last year',
    THIS_YEAR = 'This year',
    MAX = 'Max',
}

const dateAliasList = [
    DATE_ALIAS_ENUMS.LAST_WEEK,
    DATE_ALIAS_ENUMS.LAST_MONTH,
    DATE_ALIAS_ENUMS.LAST_QUARTER,
    DATE_ALIAS_ENUMS.LAST_6_MONTHS,
    DATE_ALIAS_ENUMS.LAST_YEAR,
    DATE_ALIAS_ENUMS.THIS_YEAR,
    DATE_ALIAS_ENUMS.MAX,
]

interface ShortcutDateGroupProps {
    value: string;
    onChange: (value: string) => void;
    maxDateRange: string;
}

const ShortcutDateGroup = ({
    value,
    onChange,
    maxDateRange,      // 日期范围
}: ShortcutDateGroupProps) => {
    const [ calendarOpen, setCalendarOpen ] = useState(false)
    const [ activeDateAlias, setActiveDateAlias ] = useState('')

    const dateMapper = {
        [DATE_ALIAS_ENUMS.LAST_WEEK]: () => dateRangeUtils.getWeek(1),
        [DATE_ALIAS_ENUMS.LAST_MONTH]: () => dateRangeUtils.getMonth(1),
        [DATE_ALIAS_ENUMS.LAST_QUARTER]: () => dateRangeUtils.getQuarter(1),
        [DATE_ALIAS_ENUMS.LAST_6_MONTHS]: () => {
            const lastSixMonths = [
                dateRangeUtils.getMonth(6).split('_')[0],
                dateRangeUtils.getMonth(1).split('_')[1],
            ].join('_')
            return lastSixMonths
        },
        [DATE_ALIAS_ENUMS.LAST_YEAR]: () => dateRangeUtils.getYear(1),
        [DATE_ALIAS_ENUMS.THIS_YEAR]: () => getThisYearStr(),
        [DATE_ALIAS_ENUMS.MAX]: () => maxDateRange,
    }

    const onClickShortcutDate = (dateAlias) => {
        setActiveDateAlias(dateAlias)
        if (!dateAlias) return
        const date = dateMapper[dateAlias]?.()
        const validDate = getValidDateRange(date, maxDateRange)
        onChange(validDate)
    }

    return (
        <div className={styles.wrapper}>
            {
                dateAliasList.map(dateAlias => {
                    const date = dateMapper[dateAlias]?.()
                    const disabled = !dateRangeUtils.isParent(maxDateRange, date)
                    return (
                        <div
                            key={dateAlias}
                            className={classNames({ 
                                [styles.shortcutDate]: true, 
                                [styles.shortcutDateActive]: activeDateAlias === dateAlias,
                                [styles.shortcutDateDisabled]: disabled,
                            })} 
                            onClick={() => {
                                if (disabled) return
                                onClickShortcutDate(dateAlias)
                            }}
                        >
                            {dateAlias}
                        </div>
                    )
                })
            }
            <div
                className={classNames({ [styles.shortcutDate]: true })}
                onClick={() => {
                    onClickShortcutDate('')
                    setCalendarOpen(true)
                }}
            >
                <CalendarOutlined size={16} color="#989898" style={{ marginTop: 16 }} />
            </div>
            {
                calendarOpen ? (
                    <ClickAwayListener
                        onClickAway={() => setCalendarOpen(false)}
                    >
                        <div className={styles.calendarWrapper}>
                            <DateRangePanel
                                value={value}
                                onChange={value => {
                                    onChange(value)
                                    // setCalendarOpen(false)
                                }}
                                maxDateRange={maxDateRange}
                            />
                        </div>
                    </ClickAwayListener>
                ) : null
            }
        </div>
    )
}

export default memo(ShortcutDateGroup)
