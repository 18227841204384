import { useState, useLayoutEffect } from 'react'
import { PRODUCT_CARD_TYPE } from 'consts'

/**
 * 获取浏览器窗口宽度
 */
export const useWindowWidth = () => {
    const [ windowWidth, setWindowWidth ] = useState(window.innerWidth)

    useLayoutEffect(() => {
        const onWindowScroll = (e: any) => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', onWindowScroll)

        return () => {
            window.removeEventListener('resize', onWindowScroll)
        }
    }, [])
    
    return windowWidth
}

export const usePageContainerHeight = () => {
    const windowWidth = useWindowWidth()
    const [ pageContainerHeight, setPageContainerHeight ] = useState(window.innerHeight - 130)

    useLayoutEffect(() => {
        const pageContainer = document.querySelector('.page-container')
        if (!pageContainer) return
        const height = pageContainer.clientHeight
        setPageContainerHeight(height)
    }, [ windowWidth ])

    return pageContainerHeight
}

export const useWindowZoom = (opts?: { isInProductCard?: boolean }) => {
    const isInProductCard = opts?.isInProductCard || false
    const windowWidth = useWindowWidth()
    const [ zoom, setZoom ] = useState(1)

    useLayoutEffect(() => {
        const pageContainer = document.querySelector('.page-container') as HTMLDivElement
        const nornaContainers = document.querySelectorAll('.norna-container-fixed-width')
        if (nornaContainers.length === 0) {
            return 
        }

        // 内容期望宽度, 小于这个宽度不会出现水平滚动条
        const contentExpectWidth = pageContainer?.offsetWidth - 80
        nornaContainers.forEach((nornaContainer: Element) => {
            const isProductCard = nornaContainer.getAttribute('data-type') === PRODUCT_CARD_TYPE
            // 内容实际宽度
            const contentActualWidth = (nornaContainer as any)?.offsetWidth
            // 缩放比例
            let zoom = contentExpectWidth / contentActualWidth
            if (isProductCard || isInProductCard) {
                // ProductCard 宽度是 window.innerWidth * 0.9
                // 内容宽度要去除滚动条宽度 10px
                // 内容宽度还要去除左右内边距各 10px, 共 20px
                const newContentExpectWidth = windowWidth * 0.9 - 10 - 20
                // 20px 是左右内边距
                const newContentActualWidth = contentActualWidth - 10 - 20
                zoom = newContentExpectWidth / newContentActualWidth
            }

            /**
             * 2022-08-28
             * ProductCard 在小屏幕上也会出现水平滚动条, 也需要缩小
             * 但是 ProductCard 在大屏幕上不要缩放, 保持原来尺寸, 即 zoom > 1 时要设置 zoom = 1
             */
            if (isProductCard && zoom > 1) {
                zoom = 1
            }

            // nornaContainer.style.transform = `scale(${zoom})`
            // nornaContainer.style.transformOrigin = '0% 0%'
            // @ts-ignore
            nornaContainer.style.zoom = String(zoom)
            // pageContainer.style.overflowX = 'hidden'
            pageContainer.style.overflowX = 'auto'
            setZoom(zoom)
        })
        // eslint-disable-next-line
    }, [windowWidth, isInProductCard])

    return zoom
}

