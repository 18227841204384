import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { CloseIcon, ExpandViewIcon, ArrowIcon } from 'assets/icons'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { whetherOpenInNew } from 'componentsv2/business/OpenInNewButton'
import { LazyBgImg } from 'components/Image'
import { TagWithTips } from 'componentsv2/MenuBar/FilterTags'
import { formatVendorName } from 'utils/sellers'
import { NA, NAN, OPTIONS_NAME } from 'consts'
import { isCustomerVendor } from 'utils'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { useFilterCountry } from 'hooks/useFilter'
import styles from './styles.module.scss'
import { usePageDate } from 'hooks/usePageDate'

export const CompareVendorHeader = ({
    vendorList = [],
    onExpand,
    onClose,
    headerStyle = {},
}: any) => {
    const defaultStyle: CSSProperties = {
        width: (vendorList.length + 1) * 260 + 'px',
    }

    return (
        <div className={styles.vendor} style={defaultStyle}>
            <div className={styles.vendorPadLeft}>
                <div className={styles.vendorPadLeftHeader} />
            </div>
            {
                vendorList.map((item: any, index) => (
                    <div className={classnames([ styles.vendorItem, isCustomerVendor(item.vendor) ? styles.vendorItemFirst : styles.vendorItemOthers ])} key={item.vendor}>
                        <div className={styles.vendorHeader} style={{ ...headerStyle }}>
                            <ShouldRender shouldRender={item.showExpandBtn}>
                                <div className={styles.vendorHeaderZoom}>
                                    <ExpandViewIcon onClick={() => onExpand?.(item)} />
                                </div>
                            </ShouldRender>
                            <div className={styles.vendorHeaderName}>
                                <TagWithTips 
                                    align="center" 
                                    label={formatVendorName(item?.vendor).name} 
                                    width="100%" 
                                />
                                <TagWithTips 
                                    align="center" 
                                    style={{ fontSize: '12px', color: '#999' }} 
                                    label={formatVendorName(item?.vendor).platform} 
                                    width="100%" 
                                />
                            </div>
                            <ShouldRender shouldRender={item.showCloseBtn && !whetherOpenInNew()}>
                                <div className={styles.vendorHeaderClose}>
                                    <CloseIcon onClick={() => onClose?.(item)} />
                                </div>
                            </ShouldRender>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export const CompareVendorContent = ({
    vendorList = [],
}: {
    vendorList: any[];
}) => {
    const { pageDate } = usePageDate()
    const [ filterCountry ] = useFilterCountry()

    const defaultStyle: CSSProperties = {
        width: (vendorList.length + 1) * 260 + 'px',
    }

    return (
        <div className={styles.vendor} style={defaultStyle}>
            <div className={styles.vendorPadLeft}>
                <div className={styles.vendorPadLeftContent} />
            </div>
            {
                vendorList.map((item: any, index) => {
                    const queryDateValid = isQueryDateValid({ 
                        vendor: item.vendorOrigin, 
                        region: filterCountry, 
                        competitorValue: vendorList.map(item => item.vendorOrigin),
                        date: pageDate,
                    })
                    return (
                        <div className={classnames([ styles.vendorItem, isCustomerVendor(item.vendor) ? styles.vendorItemFirst : styles.vendorItemOthers ])} key={item.vendor}>
                            <div className={styles.vendorContent}>
                                <div className={styles.vendorImageWrap}>
                                    <LazyBgImg 
                                        src={item.image ? item.image : 'imageplaceholder.png'}
                                        objectFit="cover"
                                        width={70}
                                        height={70}
                                    />
                                </div>
                                <div className={styles.vendorPercent}>
                                    {queryDateValid ? <div>{item.percent === NAN ? NA : `${item.percent}%`}</div> : <div>{NA}</div>}
                                    {
                                        item.percent === NAN ? null : (
                                            <ArrowIcon
                                                className={classnames(
                                                    styles.percentArrow,
                                                    item.percentOrigin === 100 || item.percentOrigin === 0 ? styles.percentNone : '',
                                                    item.percentOrigin > 100 ? styles.percentHigher : '',
                                                    item.percentOrigin < 100 ? styles.percentLower : '',
                                                )}
                                            />
                                        )
                                    }
                                </div>
                                <div className={styles.vendorDesc}>
                                    <div className={styles.vendorLabel}>{OPTIONS_NAME}</div>
                                    <div className={styles.vendorValue}>{queryDateValid ? item.options : NA}</div>
                                </div>
                                <div className={styles.vendorDesc}>
                                    <div className={styles.vendorLabel}>Average price</div>
                                    {
                                        queryDateValid ? (
                                            <div className={styles.vendorValue}>{item.averagePrice}&nbsp;{item.currency}</div>
                                        ) : <div className={styles.vendorValue}>{NA}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
