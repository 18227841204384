import React, {  } from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import styles from './styles.module.scss'
import { NornaTableProps } from './NornaTable.type'
import { Row } from '../Row'

export const NornaTable = ({
  showHeader = true,
  columns,
  dataSource = [],

  categoryToVendor,
  metricsTab,
  metricTabFn,
  isTd,
  onExpand,
  collapseLineStyle = {},
  firstLevelArrowStyle = {},
}: NornaTableProps) => {
  return (
    <div
      className={styles.metricsTable}
    >
      <TableContainer
        component={Paper}
        style={{ boxShadow: 'none', backgroundColor: '#f0f2f5', overflow: 'hidden' }}
      >
        <Table aria-label="collapsible table" className="table-custom-v2">
          <TableBody className="tableBody">
            {
              dataSource.map((rowData: any, rowIndex: number) => {
                return (
                  <Row
                    key={rowData.categoryName + rowData.vendorCode + rowIndex.toString()}
                    rowData={rowData}
                    isTd={isTd}
                    columns={columns}
                    categoryToVendor={categoryToVendor}
                    setMetricsTab={metricTabFn}
                    metricsTab={metricsTab}
                    onExpand={onExpand}
                    collapseLineStyle={{ ...collapseLineStyle }}
                    firstLevelArrowStyle={firstLevelArrowStyle}
                  />
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

