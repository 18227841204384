import React, { useEffect, useRef, useState } from 'react'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { TableCategoryCell } from 'componentsv2/TradingTableV2'
import { NornaTab, NornaTable } from 'componentsv2/TradingTableV3'
import { CategoryCompetitorSwitch } from 'componentsv2/business/CategoryCompetitorSwitch'
import { CROSS_REGION_SOLD_OUT_NAME } from 'configs'
import { Box, Button, Flex, Flex1, Spin } from 'druikit'
import { getExportId, getExportSelector } from 'utils'
import { crossRegionSoldOutExportFilename } from 'utils/exportUtils'
import { MARKET_VENDOR, SELECTED_CATEGORY, SELECTED_VENDORS } from 'consts'
import { useCrossRegionSoldOutModuleData, useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { useLoadingBar } from 'hooks'
import { useDashboardComparison } from 'hooks/api/useDashboardTableData'
import { useFilterCategory, useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { useLookbook } from 'hooks/useLookbook'
import { TabItemWithChildProps } from 'componentsv2/TradingTable/types'
import { getAllFilterCategoryValue } from 'utils/filterUtils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { getExpandedKeysByMetricTab, getSubExpandedKeysByMetricTab } from 'utils/dashboardPageUtils'
import { geneCsvData, handleTableData } from './utils'
import { cloneDeep } from 'lodash'
import { downloadFileByAxios } from 'export'
import Thead from './Thead'
import classNames from 'classnames'
import { useScrollBar2 } from 'hooks/useScrollBar'
import * as config from './config'
import styles from './styles.module.scss'
import { useRegionOptions } from 'hooks/useOptions'
import { TableSoldOutCell } from './TableSoldOutCell'
import GraphModal from '../GraphModal'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import { storage } from 'utils/storage'
import { whetherLock } from 'utils/lockSellerUtils'
import { CSV_DATA_SOLD_OUT_CROSS_REGION } from 'features/filters/moduleDataSlice.const'

const exportId = getExportId(CROSS_REGION_SOLD_OUT_NAME)
const exportSelector = getExportSelector(CROSS_REGION_SOLD_OUT_NAME)
const exportFilename = crossRegionSoldOutExportFilename

const Table = () => {
    useLoadingBar()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCategory ] = useFilterCategory()
    const [ filterCompetitor ] = useFilterCompetitor()
    const { isLookbook } = useLookbook()
    const expandedRowKeysRef = useRef<string[]>([])
    const subExpandedRowKeysRef = useRef<string[]>([])
    const [ , setRefresh ] = useState({})

    const [ moduleData, setModuleData ] = useCrossRegionSoldOutModuleData()

    const {
        isCompetitorView,
        fetchPayload,
    } = moduleData

    const [ columns, setColumns ] = useState<any[]>([])
    const { regionOptions = [] } = useRegionOptions()
    const [ regionList, setRegionList ] = useState<any[]>([])

    /* ******************************************************************* */
    const { data, loading } = useDashboardComparison({ moduleName: CROSS_REGION_SOLD_OUT_NAME })

    const [ metricsTab1, setMetricsTab1 ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata1, setTabledata1 ] = useState<Array<any>>([])

    const [ metricsTab2, setMetricsTab2 ] = useState<TabItemWithChildProps[]>([])
    const [ tabledata2, setTabledata2 ] = useState<Array<any>>([])

    const [ , setCsvData ] = useCsvDataModuleData()

    const handleData = (data: any) => {
        // 数据不存在直接返回
        if (!data) {
            return {}
        }

        const sellers = storage.getSellers()
        // filterCountry 要放在第一位
        let regionList = regionOptions.map(item => item.key)
        regionList = [ filterCountry, ...regionList.filter(item => item !== filterCountry) ]
        setRegionList([ ...regionList ])
        let columns: any[] = [
            {
                dataIndex: 'categoryKey',
                width: config.categoryWidth,
                left: config.tabWidth,
                render: (text, record) => {
                    return (
                        <TableCategoryCell
                            text={isCompetitorView ? record.categoryName : record.vendorName}
                            record={record}
                            width={config.categoryWidth}
                            isCompetitorView={isCompetitorView}
                            isDeepBorderRight
                        />
                    )
                },
            },
        ]
        regionList.forEach(region => {
            columns.push({
                dataIndex: `${region}SoldOut`,
                width: config.soldOutWidth,
                render: (text, record) => {
                    const isLock = whetherLock({
                        vendorCode: record.vendorCode,
                        region,
                        sellers,
                        selectedVendorCodeList: filterCompetitor.map(c => c.vendor),
                    })
                    if (isLock) {
                        return (
                            <TableLockCell isDeepBorderRight />
                        )
                    }

                    return (
                        <TableSoldOutCell 
                            record={record}
                            config={config}
                            fieldName={region}
                            metricName={`${region} sold out`}
                        />
                    )
                },
            })
        })
        setColumns(columns)

        if (isCompetitorView) {
            const {
                tabledata: tabledata1,
                metricsTab: metricsTab1,
            } = handleTableData({
                apiData: data,
                isCompetitorView: true,
                competitorValue: filterCompetitor.map(seller => seller.vendor),
                categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
                expandedRowKeys: expandedRowKeysRef.current,
                subExpandedRowKeysRef: subExpandedRowKeysRef.current,
                region: filterCountry,
            })
            setTabledata1(tabledata1)
            setMetricsTab1(metricsTab1)
            setCsvData({
                [CSV_DATA_SOLD_OUT_CROSS_REGION]: geneCsvData({ dataSource: tabledata1, isCompetitorView, regionList }),
            })
            return
        }

        const {
            tabledata: tabledata2,
            metricsTab: metricsTab2,
        } = handleTableData({
            apiData: data,
            isCompetitorView: false,
            competitorValue: filterCompetitor.map(seller => seller.vendor),
            categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
            expandedRowKeys: expandedRowKeysRef.current,
            subExpandedRowKeysRef: subExpandedRowKeysRef.current,
            region: filterCountry,
        })

        setTabledata2(tabledata2)
        setMetricsTab2(metricsTab2)
        setCsvData({
            [CSV_DATA_SOLD_OUT_CROSS_REGION]: geneCsvData({ dataSource: tabledata2, isCompetitorView, regionList }),
        })
    }

    useDeepCompareEffect(() => {
        if (!data || !regionOptions?.length) return

        handleData(data)
    }, [ data, filterCompetitor, isCompetitorView, expandedRowKeysRef.current, subExpandedRowKeysRef.current, {}, regionOptions ])

    /* ************************ 表格展开项 ************************** */
    useDeepCompareEffect(() => {
        if (expandedRowKeysRef.current?.length) {
            const firstLevelCategory = getFirstLevelCategories({ selectedCategories: filterCategory })
            firstLevelCategory.push(SELECTED_CATEGORY)
            const vendorList = filterCompetitor.map(f => f.vendor)
            let expandedRowKeys = expandedRowKeysRef.current
            expandedRowKeys = expandedRowKeys.filter(item => {
                const code = item.split('_')[0]
                if (isCompetitorView) {
                    return vendorList.includes(code)
                }
                return firstLevelCategory.includes(code)
            })

            expandedRowKeysRef.current = [ ...expandedRowKeys ]
            setRefresh({})
        }
        if (subExpandedRowKeysRef.current?.length) {
            let subExpandedRowKeys = subExpandedRowKeysRef.current
            subExpandedRowKeys = subExpandedRowKeys.filter(item => {
                const arr = item.split('__')
                if (arr.length === 1) {
                    return true
                }
                return filterCategory.includes(arr[1])
            })

            subExpandedRowKeysRef.current = [ ...subExpandedRowKeys ]
            setRefresh({})
        }
        // eslint-disable-next-line
    }, [filterCategory, filterCompetitor, isCompetitorView])

    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        payload.query.by_category = !isCompetitorView
        if (payload?.data?.groups[SELECTED_VENDORS]) {
            payload.data.competitors = payload.data.competitors.filter(item => item !== MARKET_VENDOR.vendor)
        }

        if (isCompetitorView && payload?.data?.groups?.[SELECTED_VENDORS] && !filterCompetitor.map(item => item.vendor).includes(SELECTED_VENDORS)) {
            delete payload?.data?.groups?.[SELECTED_VENDORS]
        }

        payload.query.has_selected_vendors = filterCompetitor.map(s => s.vendor).includes(SELECTED_VENDORS)

        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    const [ graphModalVisible, setGraphModalVisible ] = useState(false)

    useScrollBar2('.sold-out-dashboard-table-header', '.sold-out-dashboard-table-content', {})

    const tableWidth = config.getTableScrollWidth(regionList)

    return (
        <div id={exportId}>
            <Flex alignItems="flex-end">
                <ModuleTitle
                    type="Assortment"
                    title={CROSS_REGION_SOLD_OUT_NAME}
                    showComparisonDate
                />
                <Box width={30} />
                <ExportDropdown
                    right
                    selector={exportSelector}
                    fileName={exportFilename}
                    onExportExcel={onExportExcel}
                />
                <Button 
                    type="danger"
                    onClick={() => setGraphModalVisible(true)}
                >
                    Sold out graph
                </Button>
                <Flex1 />
                <CategoryCompetitorSwitch
                    value={isCompetitorView}
                    onChange={value => {
                        setModuleData({ isCompetitorView: value })
                    }}
                />
            </Flex>
            <Box height={20} />
            <Spin 
                spinning={loading}
            >
                <Thead width={tableWidth} regionList={regionList} />
                {
                    isCompetitorView ? (
                        <CompetitorView
                            tableWidth={tableWidth - config.tabWidth}
                            columns={columns}
                            data={tabledata1}
                            tab={metricsTab1}
                            setMetricsTab={setMetricsTab1}
                            onTabChange={(keys, subKeys) => {
                                expandedRowKeysRef.current = keys
                                subExpandedRowKeysRef.current = subKeys
                            }}
                        />
                    ) : (
                        <CategoryView
                            tableWidth={tableWidth - config.tabWidth}
                            columns={columns}
                            data={tabledata2}
                            tab={metricsTab2}
                            setMetricsTab={setMetricsTab2}
                            onTabChange={(keys, subKeys) => {
                                expandedRowKeysRef.current = keys
                                subExpandedRowKeysRef.current = subKeys
                            }}
                        />
                    )
                }
            </Spin>
            {
                graphModalVisible && (
                    <GraphModal
                        onClose={() => setGraphModalVisible(false)}
                    />
                )
            }
            <div style={{ width: 1, height: 70, background: 'transparent' }} />
        </div>
    )
}

export const CompetitorView = ({
    columns,
    data,
    tab,
    setMetricsTab,
    onTabChange,
    tableWidth,
}) => {
    const [ cateHeight, setCateHeight ] = useState(0)
    const [ expendHeight, setExpendHeight ] = useState(0)
    const [ closeHeight, setCloseHeight ] = useState(0)

    const metricTabFn = a => {
        const metricsTab1: Array<TabItemWithChildProps> = a || tab
        const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
        let closeHeightA = closeHeight
        if (exHeight.length >= 1) {
            if (exHeight[0].getBoundingClientRect().height < 10) {
                closeHeightA = exHeight[0].getBoundingClientRect().height
            }
        }
        let index = metricsTab1.findIndex(n => n.active === true)
        setCateHeight(43)
        let subIndex = 0
        if (index > -1) {
            metricsTab1[index].children.filter(n => n.active).forEach(item => {
                subIndex += item.categorycount
            })
        }
        index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
        subIndex += index
        setExpendHeight((subIndex + 1) * 43)
        setCloseHeight(closeHeightA)
        setMetricsTab(metricsTab1)

        const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
        onTabChange?.(expandedKeys, [])
    }

    useEffect(() => {
        metricTabFn(null)
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classNames([ styles.metricsContent, 'sold-out-dashboard-table-content' ])}>
            <NornaTab
                showHeader={false}

                categoryToVendor={true}
                metricsTab={tab}
                cateHeight={cateHeight}
                expendHeight={expendHeight}
                metricTabFn={metricTabFn}
                isDashboard={true}
            />
            <NornaTable
                style={{ width: tableWidth }}
                showHeader={false}
                dataSource={data}
                columns={columns}

                isTd={true}
                metricTabFn={metricTabFn}
                metricsTab={tab}
                categoryToVendor={true}
                key="competitorview"
            />
        </div>
    )
}

export const CategoryView = ({
    columns,
    data,
    tab,
    setMetricsTab,
    onTabChange,
    tableWidth,
}) => {
    const [ cateHeight, setCateHeight ] = useState(0)
    const [ expendHeight, setExpendHeight ] = useState(0)
    const [ closeHeight, setCloseHeight ] = useState(0)

    const metricTabFn = a => {
        const metricsTab1: Array<TabItemWithChildProps> = a || tab
        const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
        let closeHeightA = closeHeight
        if (exHeight.length >= 1) {
            if (exHeight[0].getBoundingClientRect().height < 10) {
                closeHeightA = exHeight[0].getBoundingClientRect().height
            }
        }
        let index = metricsTab1.findIndex(n => n.active === true)
        setCateHeight(43)
        let subIndex = 0
        if (index > -1) {
            metricsTab1[index].children.filter(n => n.active).forEach(item => {
                subIndex += item.categorycount
            })
        }
        index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
        subIndex += index
        setExpendHeight((subIndex + 1) * 43)
        setCloseHeight(closeHeightA)
        setMetricsTab(metricsTab1)

        const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
        const subExpandedKeys = getSubExpandedKeysByMetricTab(metricsTab1)
        onTabChange?.(expandedKeys, subExpandedKeys)
    }

    useEffect(() => {
        metricTabFn(null)
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classNames([ styles.metricsContent, 'sold-out-dashboard-table-content' ])}>
            <NornaTab
                showHeader={false}

                categoryToVendor={false}
                metricsTab={tab}
                cateHeight={cateHeight}
                expendHeight={expendHeight}
                metricTabFn={metricTabFn}
                isDashboard={true}
            />
            <NornaTable
                style={{ width: tableWidth }}
                showHeader={false}
                dataSource={data}
                columns={columns}

                isTd={true}
                metricTabFn={metricTabFn}
                metricsTab={tab}
                categoryToVendor={false}
                key="categoryview"
            />
        </div>
    )
}

export default Table
