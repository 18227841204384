import React from 'react'
import classname from 'classnames'
import { CardContainer } from 'components/cards/CardContainer'
import { CloseIcon } from 'assets/icons/Close'
import { Text } from 'components/typography'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { ClosableCardProps } from './types'

export const ClosableCard = ({ children, title, onClose, large, condensed, showTitle = true }: ClosableCardProps) =>
    <CardContainer large={large}>
        <div className={classnames(styles['closable-card'], large && styles['card-large'])}>
            {!condensed &&
            <div className="flex flex-end">
                {
                    showTitle ? <Text>{title}</Text> : null
                }
                <div className={classname(styles['close-card'],'flex flex-center')}>
                    <CloseIcon onClick={onClose} />
                </div>
            </div>
      }
            <div className={styles.body}>{children}</div>
        </div>
    </CardContainer>

