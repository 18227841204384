/**
 * 监控系统接口是否正常
 * 
 * 轮询发出接口，接口报错，往 Slack 发一条消息
 */

import { useEffect } from 'react'
import axios from 'axios'
import { CONST_VARS } from 'libs/const'
import AuthToken from 'utils/AuthToken'
import { storage } from 'utils/storage'

export const useMonitor = () => {
    const getConfig = (test = true) => {
        return {
            seconds: test ? 10 : 60 * 5,
            monitoringEmail: test ? 'zhicheng.bao@norna.ai' : 'testing@norna.ai',
        }
    }

    const polling = async () => {
        axios.request({
            url: CONST_VARS.API_URL + '/release_note',
            method: 'GET',
            params: { version: 'v1.0.0' },
            headers: {
                Authorization: 'Bearer ' + AuthToken.getAccessToken(),
            },
        }).catch(_ => {
            console.log('请求失败')

            const text = `${window.location.hostname}: 测试接口报错了，需要检查下是否服务器挂了`
            axios.request({
                url: 'https://staging.norna.ai/slack/slack-message',
                method: 'POST',
                params: { text },
            })
        })
    }

    useEffect(() => {
        const config = getConfig(false)
        if (storage.getEmail() !== config.monitoringEmail) {
            return
        }

        // 轮询，每5分钟执行一次
        const timer = setInterval(polling, config.seconds * 1000)

        return () => {
            if (timer) {
                clearInterval(timer)
            }
        }
    }, [])
}
