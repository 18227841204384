import React, { useEffect, useImperativeHandle, useRef } from 'react'
import { LoadingSpinner } from 'assets/icons'
import classNames from 'classnames'
import { getDateRangeValueByPeriod, todayRange } from 'componentsv2/form-elements/DatePicker'
import { getDateObjByStr, getMinimumDate } from 'utils/dateUtils'
import { usePageDate } from 'hooks/usePageDate'
import { useCalendarCompareToValue, useCalendarComparisonDate, useCalendarComparisonTmpDate, useCalendarDate, useCalendarPeriodValue, useCalendarSelectionDate, useCalendarTmpDate } from 'hooks/useGlobalData'
import { loadingBar } from 'hooks'
import { GlobalDatePickerPropsType } from '../types'
import styles from './GlobalDatePicker.module.scss'
import { isInclueInvalidVendors } from 'utils/isInclueInvalidVendors'
import { useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { WarningDataDialog } from 'componentsv2/WarningDataDialog'
import { handleDateDropdownGroupValue } from './GlobalDatePicker.util'
import DateCalendar from './components/DateCalendar/DateCalendar'
import { isProductRankingPage, isSearchForOptionsPage } from 'global/Routes'
import { IS_LIVE_ENV, isJustBrandsLicense, NA } from 'consts'

export const GlobalDatePicker = React.forwardRef(({ active }: GlobalDatePickerPropsType, ref) => {
    const [ calendarDate, setCalendarDate ] = useCalendarDate()
    const [ calendarTmpDate, setCalendarTmpDate ] = useCalendarTmpDate()
    const [ calendarComparisonDate, setCalendarComparisonDate ] = useCalendarComparisonDate()
    const [ calendarComparisonTmpDate, setCalendarComparisonTmpDate ] = useCalendarComparisonTmpDate()
    const [ , setCalendarPeriodValue ] = useCalendarPeriodValue()
    const [ calendarCompareToValue, setCalendarCompareToValue ] = useCalendarCompareToValue()
    const [ calendarSelectionDate, setCalendarSelectionDate ] = useCalendarSelectionDate()
    const { isRange } = usePageDate()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCountry ] = useFilterCountry()
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    useImperativeHandle(ref, () => ({
        close: () => {
            // return ture 表示关闭
            // return false 表示关闭失败
            if (
                (calendarTmpDate?.endsWith('_') || !calendarTmpDate) ||
                (calendarComparisonTmpDate?.endsWith('_') || !calendarComparisonTmpDate)
            ) {
                return false
            }

            if (calendarTmpDate === calendarDate && calendarComparisonTmpDate === calendarComparisonDate) {
                return false
            }

            // 日期改变，重置进度条
            if (calendarTmpDate !== calendarDate) {
                loadingBar.restart()
            }
            
            const invalid = isInclueInvalidVendors({
                dateRangeValue: calendarTmpDate,
                compareDateRangeValue: calendarComparisonTmpDate,
                vendorList: filterCompetitor.map(f => f.vendor),
                region: filterCountry,
            })
            if (invalid) {
                const dialogId = 'warning_data_dialog'
                const closeFn = dialogRef.current?.closeDialog
                dialogRef.current?.openDialog(dialogId,
                    <WarningDataDialog
                        onCancel={() => {
                            closeFn(dialogId)
                        }}
                    />,
                )
            }

            if (!IS_LIVE_ENV && isSearchForOptionsPage()) {
                if (calendarTmpDate !== calendarSelectionDate) {
                    setCalendarSelectionDate(calendarTmpDate)
                }
                setCalendarTmpDate('')
                setCalendarComparisonTmpDate('')
                return true
            }
            setCalendarDate(calendarTmpDate)
            setCalendarTmpDate('')
            setCalendarComparisonDate(calendarComparisonTmpDate)
            setCalendarComparisonTmpDate('')
            return true
        },
        judgeChanged: () => calendarDate !== calendarTmpDate || calendarComparisonDate !== calendarComparisonTmpDate,
    }))

    useEffect(() => {
        if (!active) {
            return
        }
        if (!IS_LIVE_ENV && isSearchForOptionsPage()) {
            setCalendarTmpDate(calendarSelectionDate)
            if ((!calendarComparisonTmpDate && calendarComparisonDate) || calendarComparisonTmpDate.endsWith('_')) {
                setCalendarComparisonTmpDate(calendarComparisonDate)
            }
            return
        }
        // 第一种情形: tmpDate 不存在，但 state 存在
        // 第二种情形: tmpDate 只选了一个值, 以 _ 结尾, 表示上一次只选了一半没选完, 这一次再进入 Date 时恢复完整日期范围
        // 这两种情况将 date 的值赋值给 tmpDate
        if ((!calendarTmpDate && calendarDate) || calendarTmpDate.endsWith('_')) {
            setCalendarTmpDate(calendarDate)
        }
        if ((!calendarComparisonTmpDate && calendarComparisonDate) || calendarComparisonTmpDate.endsWith('_')) {
            setCalendarComparisonTmpDate(calendarComparisonDate)
        }
    }, [calendarDate, calendarComparisonDate, active]) // eslint-disable-line

    useEffect(() => {
        if (calendarTmpDate) return
        const result = handleDateDropdownGroupValue({
            tmpDate: calendarDate, 
            comparisonTmpDate: calendarComparisonDate,
            compareToValue: calendarCompareToValue,
            operType: 'period',
        })
        setCalendarPeriodValue(result?.periodValue || NA)
        setCalendarCompareToValue(result?.compareToValue || NA)
        if (result?.comparisonTmpDate) {
            setCalendarComparisonDate(result?.comparisonTmpDate)
        }
    }, [ calendarDate, active ])

    useEffect(() => {
        if (calendarComparisonTmpDate) return
        const result = handleDateDropdownGroupValue({
            tmpDate: calendarDate, 
            comparisonTmpDate: calendarComparisonDate,
            compareToValue: calendarCompareToValue,
            operType: 'compareTo',
        })
        setCalendarPeriodValue(result?.periodValue || NA)
        setCalendarCompareToValue(result?.compareToValue || NA)
        if (result?.comparisonTmpDate) {
            setCalendarComparisonDate(result?.comparisonTmpDate)
        }
    }, [ calendarComparisonDate, active ])

    useEffect(() => {
        if (!calendarTmpDate) return
        const result = handleDateDropdownGroupValue({
            tmpDate: calendarTmpDate, 
            comparisonTmpDate: calendarComparisonTmpDate,
            compareToValue: calendarCompareToValue,
            operType: 'period',
        })
        setCalendarPeriodValue(result?.periodValue || NA)
        setCalendarCompareToValue(result?.compareToValue || NA)
        if (result?.comparisonTmpDate) {
            setCalendarComparisonTmpDate(result?.comparisonTmpDate)
        }
    }, [ calendarTmpDate ])

    useEffect(() => {
        if (!calendarComparisonTmpDate) return
        const result = handleDateDropdownGroupValue({
            tmpDate: calendarTmpDate, 
            comparisonTmpDate: calendarComparisonTmpDate,
            compareToValue: calendarCompareToValue,
            operType: 'compareTo',
        })
        setCalendarPeriodValue(result?.periodValue || NA)
        setCalendarCompareToValue(result?.compareToValue || NA)
        if (result?.comparisonTmpDate) {
            setCalendarComparisonTmpDate(result?.comparisonTmpDate)
        }
    }, [ calendarComparisonTmpDate ])

    const minimumDate = getDateObjByStr(getMinimumDate())
    const maximumDate = todayRange().from

    const dateRef = useRef<any>()

    if (!calendarTmpDate || !calendarComparisonTmpDate) {
        return (
            <div style={{ height: '422px' }}>
                <LoadingSpinner />
            </div>
        )
    }

    return (
        <div
            className={
                classNames({
                    [styles.navDate]: true,
                })
            } key="global-key"
        >
            <Dialog ref={dialogRef} />
            <div 
                style={{ height: '520px', display: 'flex', flexDirection: 'row' }} 
                onClick={() => {
                    dateRef.current?.close()
                }}
            >
                <DateCalendar 
                    isRange={isRange}
                    title="Selected time period"
                    minimumDate={minimumDate}
                    maximumDate={maximumDate}
                    value={calendarTmpDate}
                    ref={dateRef}
                    onChange={value => {
                        setCalendarTmpDate(value)
                    }}
                    onReset={() => {
                        setCalendarTmpDate(getDateRangeValueByPeriod('oneWholeWeekAgo'))
                    }}
                />
                <div className={styles.line} />
                <DateCalendar
                    isRange={isRange}
                    title="Selected comparison period "
                    minimumDate={minimumDate}
                    maximumDate={maximumDate}
                    value={calendarComparisonTmpDate}
                    onChange={value => {
                        setCalendarComparisonTmpDate(value)
                    }}
                    onReset={() => {
                        setCalendarComparisonTmpDate(getDateRangeValueByPeriod('twoWholeWeeksAgo'))
                    }}
                    hideDropdown
                    disabled={isSearchForOptionsPage() || (isProductRankingPage() && isJustBrandsLicense())}
                />
            </div>
        </div>
    )
})
