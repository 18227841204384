import React, { CSSProperties, ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { Box, Card, Divider, Flex, Spin, Text } from 'druikit'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import styles from './styles.module.scss'
import { colors } from '../MyCharts/utils'
import { MyPriceSpreadChart } from '../MyCharts/MyPriceSpreadChart'
import { handleName } from 'pages/DynamicDashboard/utils'
import { numberUtils } from 'norna-uikit'

export const MetricPriceSpreadChartCard = ({
    style,
    className,
    title,
    description,
    loading = false,
    dataSource = [],
}: {
    style?: CSSProperties;
    className?: string;
    title?: string | ReactNode; 
    description?: string | ReactNode;
    loading?: boolean;
    dataSource: {label: string; lowestPrice: number; averagePrice: number; highestPrice: number;}[];
}) => {
    /* ************************* 图表宽度 ******************************** */
    const divRef = useRef<HTMLDivElement>(null)
    const [ chartWidth, setChartWidth ] = useState(400)

    useLayoutEffect(() => {
        const wrapperEl = divRef.current
        if (!wrapperEl) return
        const rect = wrapperEl.getBoundingClientRect()
        setChartWidth(rect.width)
    }, [])

    return (
        <Spin spinning={loading} style={{ marginBottom: 34, ...style }} className={className}>
            <Flex style={{ marginBottom: 10 }}>
                <div className={styles.cardTitle}>{title}</div>
                {
                    description ? (
                        <InfoBox
                            iconSty={{ margin: '0 10px' }}
                            containerSty={{ left: '-150px' }}
                            title="title"
                            text="tips content"
                        >
                            <TipsContent sty={{ padding: 0 }}>
                                {description}
                            </TipsContent>
                        </InfoBox>
                    ) : null
                }
            </Flex>
            <Card fringed={false} height={330} style={{ paddingLeft: 33, paddingRight: 28 }}>
                <div ref={divRef} style={{ width: '100%' }}>
                    <Flex justifyContent="flex-end" alignItems="flex-end" height={38}>
                        {
                            dataSource.map((item, index) => {
                                const color = colors[index]
                                return (
                                    <Flex key={item.label} alignItems="center" style={{ marginRight: 10 }}>
                                        <Box width={12} height={12} backgroundColor={color} style={{ borderRadius: 12 }} />
                                        <Box width={6} />
                                        <Text fontSize12 color666>
                                            {handleName(item.label)}
                                        </Text>
                                    </Flex>
                                )
                            })
                        }
                    </Flex>
                    <Divider length="100%" />
                    <Flex
                        justifyContent="space-between" 
                        fontSize={11} color="#878787" 
                        marginBottom={10} 
                        paddingLeft={20} 
                        paddingRight={20}
                    >
                        <div>Low</div>
                        <div>Avearge</div>
                        <div>High</div>
                    </Flex>
                    {
                        dataSource.map((item, index) => {
                            return (
                                <MyPriceSpreadChart
                                    key={item.label}
                                    style={{ marginBottom: 10 }}
                                    lowestPrice={item.lowestPrice}
                                    averagePrice={item.averagePrice}
                                    highestPrice={item.highestPrice}
                                    rangeHighestPrice={Math.max(...dataSource.map(it => it.highestPrice))}
                                    rangeLowestPrice={Math.min(...dataSource.map(it => it.lowestPrice))}
                                    color={colors[index]}
                                    formatter={value => numberUtils.formatNumberByComma(value)}
                                />
                            )
                        })
                    }
                </div>
            </Card>
        </Spin>
    )
}
