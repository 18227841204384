import React, { CSSProperties } from 'react'

export const ColorArrow = ({
    value = 0,
    size = 10,
    scale = 1,
    right,
    style = {},
  }: {
    value: number;
    size?: number;
    scale?: number;
    right?: number;
    style?: CSSProperties;
  }) => {
    const sty: CSSProperties = { 
      ...style,
      transform: `scale(${scale})`,
    }
    if (typeof right !== 'undefined') {
      sty.marginRight = '0px'
    }

    if (value === 0) {
      const fill = '#484848'
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size} fill={fill}>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
        </svg>
      )
    }

    if (value > 0) {
      const fill = '#01A699'
      return (
        <svg style={{ ...sty }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size} fill={fill}>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
        </svg>
      )
    }
    
    const fill = '#E22F21'
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size} fill={fill}>
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
      </svg>
    )
}
