import React from 'react'
import classnames from 'classnames'
// import { CloseIcon } from 'assets/icons'
import { Button } from 'componentsv2/Button'
import { FILTER_DIALOG_CLASS } from 'consts'
import { Flex } from 'componentsv2/Flex'
import styles from './styles.module.scss'

export const WarningDataDialog = ({
    onCancel,
    modalMaskStyle = {},
}: any) => {
    return (
        <>
            <div className={`material-filter ${FILTER_DIALOG_CLASS}`} onMouseDown={e => e.stopPropagation()}>
                <div
                    className={styles.modal}
                    style={modalMaskStyle}
                />
                <div className={classnames(styles.sizeFilter)}>
                    {/* <div className={styles.close}>
                        <CloseIcon onClick={() => { onCancel?.() }} />
                    </div> */}
                    <div className={styles.content}>
                        <div className={styles.title}>
                            Data notice
                        </div>
                        <div className={styles.text}>
                            <div>
                                Data is not available during the selected time 
                            </div>
                            <div>
                                period for some of the vendors. 
                            </div>
                        </div>
                        <Flex justifyContent="center" style={{ width: '100%' }}>
                            <Button
                                type="primary"
                                mini
                                className={styles.applyBtn}
                                onClick={async () => {
                                    onCancel?.()
                                }}
                            >
                                Got it
                            </Button>
                        </Flex>
                    </div>
                </div>
            </div>
        </>
    )
}
