import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { ContainerProps } from './types'

export const Container = ({ children,sty={} }: ContainerProps) =>
    <div className={classNames([ styles.container, 'root-child' ])} style={sty}>
        {children}
    </div>

