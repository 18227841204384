import React, { FC, memo } from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { DatePicker, DatePickerType } from 'componentsv2/form-elements/DatePicker'
import { DateRangePickerProps } from './types'

/**
 * ## DateRangePicker
 * 
 * 日期范围选择器组件。
 * 
 * ## 示例
 * 
 * ### 基础使用
 * 
 * ```
 * <DateRangePicker />
 * ```
 * 
 * ### 设置选中值以及监听值变化：value、onChange
 * 
 * - value 值格式为：2021-09-13_2021-09-20
 * - onChange 回调函数的参数 value 格式为：2021-09-13_2021-09-20
 * 
 * 注：onChange 函数建议使用 useCallback 包裹，当传递给 DateRange 组件的属性未变化时，是不会重新渲染该组件的。
 * 
 * ```
 * const [dateRangeValue, setDateRangeValue] = useState('')
 * 
 * <DateRangePicker
 *    value={dateRangeValue}
 *    onChange={
 *      useCallback(value => {
 *        setDateRangeValue(value)
 *      }, [])
 *    }
 * />
 * ```
 * 
 * ### 和 Period 下拉框联动场景
 * 
 * 使用工具方法 getDateRangeValueByPeriod() 获取 period 对应的日期返回字符串
 * 
 * ```
 * import { DateRangePicker, getDateRangeValueByPeriod } from 'componentsv2/form-elements/DateRangePicker'
 * 
 * export const Demo = () => {
 *    // Period 下拉框选中值
 *    const [period, setPeriod] = useState('week')
 * 
 *    // DateRangePicker 选中值
 *    const [range, setRange] = useState('2021-09-20_2021-09-21')
 * 
 *    return (
 *      <>
 *        <Dropdown
 *          value={period}
 *          onChange={useCallback(value => {
 *            setPeriod(period)
 *           
 *            // period 为 'none' 时不做处理
 *            if (value === 'none') return
 *            // period 为 'week'、'month'、'quarter' 时使用工具方法拿到对应的日期段字符串
 *            const dateRangeValue = getDateRangeValueByPeriod(value)
 *            // 为 DateRangePicker 组件选中值重新赋值
 *            setRange(dateRangeValue)
 *          }, [])}
 *        />
 *        
 *        <DateRangePicker
 *          value={range}
 *          onChange={useCallback(value => {
 *            setRange(value)
 *            
 *            // DateRangePicker 组件手动选中值后，需要将 Period 下拉框重置为 'none' 选项
 *            setPeriod('none')
 *          }, [dispatch])}
 *        />
 *      </>
 *    )
 * }
 * ```
 */
export const DateRangePicker: FC<DateRangePickerProps> = memo(({
  wrapperClassName = '',
  customRenderFn,
  renderPostion,
  style = {},
  left,
  right,
  minimumDate,          // 最小日期，格式 {day: 1, month: 8, year: 2020 }
  maximumDate,          // 最大日期，格式 格式 {day: 1, month: 8, year: 2020 }，默认值当天
  defaultValue,         // 默认值，格式为：2021-09-13_2021-09-20，点击 Restart to default 按钮时设置该值
  value,                // 设置日期段下拉框选中值，格式：2021-09-13_2021-09-20
  onChange,             // 日期段下拉框值变化时触发事件，回调函数参数 value 格式：2021-09-13_2021-09-20
  inputValue,
  inputClassName,
  inputStyle,
}) => (
    <DatePicker 
      wrapperClassName={wrapperClassName}
      customRenderFn={customRenderFn}
      renderPostion={renderPostion}
      type={DatePickerType.RANGE}
      style={style}
      left={left}
      right={right}
      minimumDate={minimumDate}
      maximumDate={maximumDate}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      inputValue={inputValue}
      inputClassName={inputClassName}
      inputStyle={inputStyle}
    />
  ))

DateRangePicker.displayName = 'DateRangePicker'
