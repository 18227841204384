import React from 'react'
import { Button } from 'components/form-elements/Button'
import { PlusIcon } from 'assets/icons/Plus'
import { ProductActionsProp } from './types'
import styles from './styles.module.scss'

export const ProductActions = ({ plusMinusToggle, toggle }: ProductActionsProp) => 
    <div className={styles.actions}>
        <div className={plusMinusToggle ? styles.minus : styles.plus}>
            <Button
                primary
                miniLarge
                onlyIcon
                icon={<PlusIcon />}
                onClick={e => {
            e.stopPropagation()
            toggle && toggle()
          }}
            />
        </div>
    </div>

