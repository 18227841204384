import React, { useState } from 'react'
import classnames from 'classnames'
import { ShouldRender } from 'componentsv2/ShouldRender'
import styles from './styles.module.scss'

export const Info = ({
    children,
}) => {
    const [ visible, setVisible ] = useState(false)

    return (
        <div
            className={styles.info}
            onMouseLeave={() => {
                if (!visible) return
                setVisible(!visible) 
            }}
        >
            <div
                className={classnames(styles.infoIcon)}
                onMouseEnter={() => {
                    if (visible) return
                    setVisible(!visible) 
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" /></svg>
            </div>
            <ShouldRender shouldRender={visible}>
                <div className={styles.popover}>
                    <div>
                        {children}
                    </div>
                </div>
            </ShouldRender>
        </div>
    )
}
