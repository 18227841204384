import React, { useState, useEffect, ReactNode, memo, useMemo } from 'react'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import classnames from 'classnames'
import { CaretIcon } from 'assets/icons/Caret'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { StyledTableBodyCell } from '../StyledTableBodyCell'
import styles from './styles.module.scss'
import { RowGroupType, RowType } from './Row.type'
import { calcHandler } from './utils'
import { ColumnsType } from '../types'

// 235, 243, 243

const BodyCell = memo(({
  columns,
  rowData = {},
}: {
  columns: ColumnsType[];
  rowData: any;
}) => {
  let background = 'rgba(116, 182, 182, 0.15)'
  if (rowData?.level === 0) {
    // background = 'rgba(116, 182, 182, 0.15)'
    background = 'rgb(249, 251, 251)'
  } else if (rowData?.level === 1) {
    background = 'white'
  } else {
    background = 'rgba(74,74,74,0.1)'
  }

  return (
    <>
      {
        columns.map((column, columnIndex) => {
          const text = column.dataIndex ? rowData[column.dataIndex] : ''
          let bodyEl: ReactNode = (<>{text}</>)
          if (typeof column?.render === 'function') {
            bodyEl = (
              <>
                {column.render(text, rowData, columnIndex)}
              </>
            )
          }
      
          return (
            <StyledTableBodyCell                                    // body column cell
              style={{ background, ...column.style, height: '43px', width: column.width + 'px' || 'auto' }}
              className={column.className}
              key={column.dataIndex}
            >
              {bodyEl}
            </StyledTableBodyCell>
          )
        })
      }
    </>
  )
})

/**
 * RowGroup includes the row and its children rows
 */
export const RowGroup = ({
  rowData, 
  columns, 
  metricsTab, 
  setMetricsTab, 
  enableRotate, 
  categoryToVendor, 
  isTd,
  expandSub,
  rowActive,
  appendNum,
  toggleExpandFn,
  currentRowHeight,
  onExpand,
  collapseLineStyle = {},
  firstLevelArrowStyle = {},
}: RowGroupType) => {
  const [ expanded, setExpanded ] = useState(false)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (rowActive === false) {
      timer = setTimeout(() => {
        setExpanded(false)
      }, 500)
    } else {
      setExpanded(true)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [ rowActive ])

  // sub group height
  const height = rowActive ? currentRowHeight : 0

  return (
    <>
      <TableRow>
        <BodyCell 
          columns={useMemo(() => columns, [ columns ])}
          rowData={useMemo(() => rowData, [ rowData ])}
        />
        <StyledTableBodyCell
          className={styles.svgColor}
          width="auto"
          style={rowData.level ? { background: 'white' } : { background: 'rgb(249, 251, 251)', ...firstLevelArrowStyle }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (rowActive !== expanded) return
              toggleExpandFn?.()
            }}
          >
            <CaretIcon isReversed={rowActive} />
          </IconButton>
        </StyledTableBodyCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={columns.length + appendNum}>
          <div
            className={styles.collapse}
            style={{ height }}
          >
            <ShouldRender shouldRender={rowActive ? true : expanded}>
              <ShouldRender shouldRender={expandSub}>
                <div className={classnames(styles.collapseLine, styles.collapseLineTop)} />
              </ShouldRender>
              <Table>
                <TableBody aria-label="purchases">
                  {
                    expanded && rowData?.children?.length ? rowData?.children?.map(subrowData => {
                      if (subrowData.children && subrowData.children.length) {
                        return (
                          <Row
                            categoryToVendor={categoryToVendor}
                            rowData={subrowData}
                            key={subrowData.categoryName + subrowData.vendorCode + categoryToVendor}
                            isTd={isTd}
                            enableRotate={true}
                            columns={columns}
                            setMetricsTab={setMetricsTab}
                            metricsTab={metricsTab}
                            onExpand={onExpand}
                            collapseLineStyle={collapseLineStyle}
                          />
                        )
                      }
                      return (
                        <TableRow key={subrowData.categoryName + subrowData.vendorCode}>
                          <BodyCell 
                            columns={columns}
                            rowData={subrowData}
                          />
                          <StyledTableBodyCell width="auto" style={subrowData.level ? { background: 'white' } : {}} />
                        </TableRow>
                      )
                    }) : null
                  }
                </TableBody>
              </Table>
            </ShouldRender>
          </div>
          <ShouldRender shouldRender={expandSub}>
            <div className={styles.collapseLine} style={collapseLineStyle} />
          </ShouldRender>
        </TableCell>
      </TableRow>
    </>
  )
}

export function Row({
  rowData, 
  columns, 
  metricsTab, 
  setMetricsTab, 
  enableRotate = true,
  categoryToVendor, 
  isTd, 
  onExpand, 
  collapseLineStyle = {}, 
  firstLevelArrowStyle = {}, 
}: RowType) {
  const {
    subActive,
    expandSub,
    rowActive,
    rowSubIndex,
    appendNum,
    rowIndex,
    currentRowHeight,
  } = calcHandler({ row: rowData, metricsTab, categoryToVendor, isTd })
  
  // children caetgory wrapper height
  let childrenCategoryHeight = 0
  if (subActive && Array.isArray(rowData.childrenData) && rowData.childrenData.length) {
    childrenCategoryHeight = metricsTab[rowIndex].children
      .map(item => {
        return item?.active ? item?.categorycount : 1
      })
      .reduce((curr, next) => curr + next, 0) * 43
  }

  return (
    <>
      <RowGroup {
        ...{
          subActive,
          expandSub,
          rowActive,
          rowSubIndex,
          appendNum,
          rowIndex,
          rowData,
          columns,
          metricsTab,
          setMetricsTab,
          categoryToVendor,
          isTd,
          currentRowHeight,
          onExpand,
          collapseLineStyle,
          firstLevelArrowStyle,
          toggleExpandFn: () => {
            const a = metricsTab.concat()
            // competitor 视图
            if (categoryToVendor) {
              // 第 1 级
              if (rowData.level === 1) {
                const active = !a[rowIndex].children[rowSubIndex].active
                a[rowIndex].children[rowSubIndex].active = active
                setMetricsTab(a)
                onExpand?.(active, String(`${rowIndex}-${rowSubIndex}`))
              } else {
                const index = a.findIndex(item => item.title === rowData.vendorName)
                const active = !a[index].active
                a[index].active = active
                setMetricsTab(a)
                onExpand?.(active, String(index))
              }
            } 
            // category 视图
            else if (rowData.level === 0) {
              const index = a.findIndex(item => item.title === rowData.categoryName)
              const active = !a[index].active
              a[index].active = active
              setMetricsTab(a)
              onExpand?.(active, String(index))
            }
          },
        }}
      />

      <TableRow>
        <TableCell colSpan={columns.length + appendNum + 1}>
          <div
            className={styles.collapse}
            style={{ height: childrenCategoryHeight + 'px' }}
          >
            <Table aria-label="purchases">
              <TableBody>
                {
                  rowData?.childrenData?.length ? rowData.childrenData.map((subRecord, index) => {
                    let subExtraNum = subRecord.level === 1 ? 0 : 1
                    subExtraNum = categoryToVendor ? subExtraNum : 0
                    return (
                        <RowGroup
                          key={subRecord.categoryName + subRecord.vendorCode}
                          {...{
                            subActive,
                            expandSub,
                            rowActive: metricsTab[rowIndex]?.children[index]?.active,
                            rowSubIndex,
                            appendNum: subExtraNum,
                            rowIndex,
                            rowData: subRecord,
                            columns,
                            metricsTab,
                            setMetricsTab,
                            categoryToVendor,
                            isTd,
                            currentRowHeight,
                            collapseLineStyle,
                            toggleExpandFn() {
                              const tabs = metricsTab.concat()
                              // category 视图
                              if (subRecord.level === 0) {
                                tabs[tabs.findIndex(item => item.title === subRecord.categoryName)].active = !tabs[tabs.findIndex(item => item.title === subRecord.categoryName)].active
                                setMetricsTab(tabs)
                              } else if (subRecord.level === 1) {
                                const oneLevelIndex = tabs.findIndex(item => item.title === subRecord.parentCategoryName)
                                const twoLevelIndex = tabs[oneLevelIndex].children.findIndex(item => item.title === subRecord.categoryName)
                                tabs[oneLevelIndex].children[twoLevelIndex].active = !tabs[oneLevelIndex].children[twoLevelIndex].active
                                setMetricsTab(tabs)
                              }
                            },
                          }}
                        />
                    )
                  }) : null}
              </TableBody>
            </Table>
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}
