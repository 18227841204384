import React, { FC, Fragment, CSSProperties, useState, useEffect } from 'react'
import classnames from 'classnames'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { whetherOpenInNew } from 'componentsv2/business/OpenInNewButton'
import { CaretIcon } from 'assets/icons/Caret'
import { useWindowZoom } from 'hooks/useWindow'
import { cloneDeep } from 'lodash'
import { dynamicColumnWidth } from '../../uitls'
import { calcUnit, getValueByName } from './utils'
import { TableProps, TheadProps, TbodyProps, ColumnProps } from './types'
import styles from './styles.module.scss'

const rowHeight = 41

/**
 * 表格组件
 */
export const Table: FC<TableProps> = ({
    dataSource = [],
    columns = [],
    theadClassName,
    showThead = true,
    showTbody = true,
    className = '',
}) => {
    if (!dataSource.length) return null

    return (
        <div className={classnames([ styles.table, className ])}>
            <div style={{ width: 445 + (columns.length - 3) * dynamicColumnWidth + 'px' }}>
                {
                    showThead ? (
                        <Thead columns={columns} theadClassName={theadClassName} />
                    ) : null
                }
                {
                    showTbody ? (
                        <Tbody dataSource={dataSource} columns={columns} />
                    ) : null
                }
            </div>
        </div>
    )
}

/**
 * 表头
 * 
 * 支持合并单元格功能 TODO
 */
const Thead: FC<TheadProps> = ({
    columns,
    theadClassName,
    theadStyle = {},
}) => {
    const zoom = useWindowZoom()

    const geneThElement = ({ column, index }: { column: ColumnProps, index: number }) => {
        const thStyle: CSSProperties = { padding: '0 12px' }
        if (column.width) {
            thStyle.width = column.width
        }

        if (typeof column.title === 'string') {
            return (
                <div style={thStyle} key={column.title + index.toString()}>
                    {column.title}
                </div>
            )
        } 
        return (
            <Fragment key={column.title + index.toString()}>
                {column.title}
            </Fragment>
        )
    }

    /**
     * 通过 Open in new 打开的窗口没有头部 Header
     * 因此，sticky 布局时 top 需要设置为 0
     */
    const top = whetherOpenInNew() ? 0 : (-30 / zoom) + 'px'

    return (
        <div className={classnames([ styles.thead, theadClassName ])} style={{ top, ...theadStyle }}>
            {
                columns.map((column, index) => geneThElement({ column, index }))
            }
            <div style={{ width: '45px' }} />
        </div>
    )
}

export const Tbody = ({
    dataSource = [],
    columns = [],
}: TbodyProps) => {
    const [ data, setData ] = useState<any[]>([])
 
    /**
     * Process the data source, adding three fields
     * 
     * level: Tree structure data level, the first level level=0, the second level level=1
     * index: e.g The second element of the second level index='1-1'
     * expanded
     * borderPostion: 'Top' | 'Bottom'
     */
    useEffect(() => {
        function geneFields(arr, level, parentIndex?: string) {
            return arr.map((item, index) => {
                item.level = level
                item.index = parentIndex ? `${parentIndex}-${index}` : `${index}`
                item.expanded = false
                if (Array.isArray(item?.children) && item.children.length > 0) {
                    item.children = geneFields(item.children, level + 1, item.index)
                }
                return item
            })
        }

        const newDataSource = geneFields(dataSource, 0)

        if (newDataSource.length > 0) {
            // First level default expansion
            newDataSource[0].expanded = true
            newDataSource[0].borderPosition = 'Top'
            newDataSource[0].children[newDataSource[0].children.length - 1].borderPosition = 'Bottom'
        }

        setData([ ...newDataSource ])
    }, [ dataSource ])

    return (
        <div className={styles.tbody}>
            {
                data.map(record => (
                    <Row 
                        key={record.level + '_' + record.index} 
                        rowData={record} 
                        columns={columns}
                        onToggleExpanded={rowData => {
                            const newData: any = cloneDeep(data)
                            const indexArr = rowData.index.split('-').map(item => Number(item))
                            if (indexArr.length === 1) {
                                newData[indexArr[0]].expanded = !newData[indexArr[0]].expanded
                            } else if (indexArr.length === 2) {
                                newData[indexArr[0]].children[indexArr[1]].expanded = !newData[indexArr[0]].children[indexArr[1]].expanded
                            } else if (indexArr.length === 3) {
                                newData[indexArr[0]].children[indexArr[1]].children[indexArr[2]].expanded = !newData[indexArr[0]].children[indexArr[1]].children[indexArr[2]].expanded
                            }

                            function getExpandedKeys(arr: any[] = []) {
                                arr.forEach(item => {
                                    if (item?.expanded) {
                                        item.borderPosition = 'Top'
                                        if (Array.isArray(item?.children) && item.children?.length) {
                                            item.children[item.children.length - 1].borderPosition = 'Bottom'
                                            getExpandedKeys(item.children)
                                        }
                                    } else if (item.borderPosition === 'Top') {
                                        item.borderPosition = ''
                                    }
                                })
                            }
                            getExpandedKeys(newData)

                            setData(cloneDeep(newData))
                        }} 
                    />
                ))
            }
        </div>
    )
}

export const Row = ({
    rowData,
    columns = [],
    onToggleExpanded,
}: {
    rowData: any;
    columns: any[];
    onToggleExpanded: (rowData: any) => void,
}) => {
    const geneTdElement = ({ columnData, columnIndex, rowData }) => {
        const className = classnames([
            styles.td,
            styles[columnData.align || 'left'],
            columnData.className,
            columnIndex !== 0 && rowData.borderPosition && styles[`tdBorder${rowData.borderPosition}`],
        ])
        const value = getValueByName({ record: rowData, name: columnData.name })
        const key = rowData.index + '-column-' + columnIndex
        if (typeof columnData?.render === 'function') {

            return (
                <div key={key} className={className} style={{ width: calcUnit(columnData.width), ...columnData.style }}>
                    {columnData?.render?.(value, rowData)}
                </div>
            )
        }
        return (
            <div key={key} className={className} style={{ width: calcUnit(columnData.width), ...columnData.style }}>
                {value}
            </div>
        )
    }

    const arrowElement = (
        <div
            key={'arrow' + rowData.index}
            style={{ width: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
            className={rowData.borderPosition && styles[`tdBorder${rowData.borderPosition}`]}
        >
            <ShouldRender shouldRender={rowData.children?.length > 0}>
                <div
                    style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                    onClick={() => {
                        if (rowData.expanded !== innerExpanded) return
                        onToggleExpanded(rowData)
                    }}
                >
                    <CaretIcon isReversed={rowData.expanded} color="#74b6b6" />
                </div>
            </ShouldRender>
        </div>
    )

    let childrenHeight = 0
    if (rowData.expanded && Array.isArray(rowData?.children) && rowData.children.length) {
        let expandedRow = 0
        if (Array.isArray(rowData.children)) {
            expandedRow += rowData.children.length
            rowData.children.forEach(item => {
                if (item?.expanded && Array.isArray(item?.children)) {
                    expandedRow += item?.children.length
                }
            })
        }
        childrenHeight = expandedRow * rowHeight
    }

    const [ innerExpanded, setInnerExpanded ] = useState<boolean>(rowData.expanded)

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>
        if (rowData.expanded === false) {
            timer = setTimeout(() => {
                setInnerExpanded(false)
            }, 300)
        } else {
            setInnerExpanded(true)
        }
        return () => {
            clearTimeout(timer)
        }
    }, [ rowData.expanded ])

    return (
        <>
            <div className={classnames([ styles.tr, styles[`trLevel${rowData.level}`] ])}>
                {columns.map((columnData, columnIndex) => geneTdElement({ columnData, columnIndex, rowData }))}
                {arrowElement}
            </div>
            <main style={{ height: childrenHeight + 'px', transition: 'height .5s ease 0s' }}>
                {
                    (rowData.expanded ? true : innerExpanded) && Array.isArray(rowData?.children) && rowData.children.length ? (
                        <>
                            {
                                rowData.children.map(record => (
                                    <Fragment key={record.index}>
                                        <Row 
                                            rowData={record} 
                                            columns={columns} 
                                            onToggleExpanded={onToggleExpanded}
                                        />
                                    </Fragment>
                                ))
                            }
                        </>
                    ) : null
                }
            </main>
        </>
    )
}
