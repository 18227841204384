/**
 * 获取有效的日期值
 */
export function getValidDateRange(value, maxValue) {
    const [ vMinDate, vMaxDate ] = value.split('_')
    const [ mMinDate, mMaxDate ] = maxValue.split('_')
    const result = [ vMinDate, vMaxDate ]
    if (vMinDate < mMinDate) {
        result[0] = mMinDate
    }
    if (vMaxDate > mMaxDate) {
        result[1] = mMaxDate
    }
    return result.join('_')
}

export const dateRangeUtils = {
    /**
     * 减多少天
     */
    subDays(date: Date = new Date(), days: number) {
        date = new Date(date)
        date.setDate(date.getDate() - days)
        return date
    },
    /**
     * 获取一个月总共有多少天
     */
    getDaysInMonth(date: Date = new Date()) {
        date = new Date(date)
        // 获取下个月的日期
        date.setMonth(date.getMonth() + 1)
        // 将日期设置为0, 即上个月最后一天
        date.setDate(0)
        // 一个月有多少天
        const days = date.getDate()
        return days
    },
    /**
     * dateRangeUtils.getWeek()  获取当前周
     * dateRangeUtils.getWeek(1) 获取上一周
     * dateRangeUtils.getWeek(2) 获取上两周
     */
    getWeek(interval = 0, date: Date = new Date()) {
        date = new Date(date)
        // 0 - 6: 周日 - 周六
        const day = date.getDay()
        const intervalDays = interval * 7
        const startDate = this.subDays(date, (day - 1 + intervalDays))
        const endDate = this.subDays(date, (day - 7 + intervalDays))
        return this.formatRange(startDate, endDate)
    },
    /**
     * dateRangeUtils.getMonth()  获取当前月
     * dateRangeUtils.getMonth(1) 获取上个月
     * dateRangeUtils.getMonth(2) 获取上两个月
     */
    getMonth(interval = 0, date: Date = new Date()) {
        date = new Date(date)
        const intervalMonth = interval
        date.setMonth(date.getMonth() - intervalMonth)
        date.setDate(1)
        const startDate = new Date(date)
        date.setDate(this.getDaysInMonth(date))
        const endDate = new Date(date)
        return this.formatRange(startDate, endDate)
    },
    /**
     * dateRangeUtils.getQuarter()   获取当前季度
     * dateRangeUtils.getQuarter(1)  获取上个季度
     * dateRangeUtils.getQuarter(2)  获取上两个季度
     */
    getQuarter(interval = 0, date: Date = new Date()) {
        date = new Date(date)
        // 0 - 11
        const month = date.getMonth()
        const subMonths = month % 3 + interval * 3
        date.setMonth(date.getMonth() - subMonths)
        date.setDate(1)
        const startDate = new Date(date)
        date.setMonth(date.getMonth() + 2)
        date.setDate(this.getDaysInMonth(date))
        const endDate = new Date(date)
        return this.formatRange(startDate, endDate)
    },
    /**
     * dateRangeUtils.getYear()  获取当前年
     * dateRangeUtils.getYear(1) 获取上一年
     * dateRangeUtils.getYear(2) 上去上两年
     */
    getYear(interval = 0, date: Date = new Date()) {
        date = new Date(date)
        const year = date.getFullYear()
        const intervalYears = interval
        const startDate = new Date(year - intervalYears, 0, 1)
        const endDate = new Date(year - intervalYears, 11, 31)
        return this.formatRange(startDate, endDate)
    },
    formatRange(startDate: Date, endDate: Date) {
        return [ startDate, endDate ].map(this.format).join('_')
    },
    format(date: Date = new Date()) {
        date = new Date(date)
        const year = String(date.getFullYear())
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const ymd = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
        return ymd
    },
    isIntersection(date1, date2) {
        if (!date1 || !date2) return true
        const [ date1Start, date1End ] = date1.split('_')
        const [ date2Start, date2End ] = date2.split('_')
        if ((date1End < date2Start) || (date1Start > date2End)) {
            return false
        }
        return true
    },
    isParent(parentDate, childDate) {
        const [ parentStartDate, parentEndDate ] = parentDate.split('_')
        const [ childStartDate, childEndDate ] = childDate.split('_')
        if ((parentStartDate <= childStartDate) && (parentEndDate >= childEndDate)) {
            return true
        }
        return false
    },
}
