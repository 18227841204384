import { sortBy } from 'lodash'
import { CATEGORY_TOTAL, ELEMENT_SPLITOR, IS_LIVE_ENV, MarimekkoCategoryOrder } from 'consts/'
import { FILTER_LABELS } from 'componentsv2/Tree/utils'

/**
 * ## Gender Sort Function 
 * 
 * @param list          targets list
 * @param targetsOrder  targets order
 * @returns             sorted targets by `targetsOrder`
 */
export const sortGenderFn = (list: Array<string>, targetsOrder: Array<string>) => sortBy(list, [ item => targetsOrder?.indexOf?.(item) + 1 ])

/**
 * recursive sorting category function for arry
 * which array element including `other` array field
 * @param list list data
 * @param fieldName item fieldName
 * @returns sorted list by rules MarimekkoCategoryOrder
 */
export const sortCategoryFn = (list, fieldName, childrenName = 'other') => sortBy(list, [ item => {
  if (item.other?.length) item.other = sortCategoryFn(item[childrenName], fieldName)
  return MarimekkoCategoryOrder.indexOf(item[fieldName]) + 1
} ])

/**
 * 
 * @param arrStr Array<String>|String
 * @param splitor 
 * @returns   string
 */
export const arrayOrStrjoinToStr = (arrStr, splitor = ELEMENT_SPLITOR) => {
  if (Array.isArray(arrStr)) {
    return arrStr.sort().join(splitor)
  }

  return arrStr
}

/**
 * 根据选中的父级Category获取子级Category
 * @param currentCategories 
 * @param categoriesList 
 * @returns 
 */
export const isParentCateogry = (currentCategories, categoriesList) => {

  const cate = currentCategories.map(item => {
    const index = categoriesList.findIndex(el => el.name === item)
    if (index > -1 && categoriesList[index].list.length) {
      return categoriesList[index].list.map(leaf => leaf.name)
    }
    return item

  }).flat(10)
  return cate
}

/**
 * Filter disable categories in live
 */
export const DISABLE_CATEGORY_IN_LIVE = [
  CATEGORY_TOTAL,
]

/**
 * 生产环境需要禁用的Categories 
 */
export const TD_DISABLE_CATEGORY_IN_LIVE = [
  'Others',
]
/**
 * 生产环境需要禁用的Properties 
 */
export const DISABLE_PROPERTIES_IN_LIVE = [
  'Others',
]

/**
 * 需求: 生产环境禁用TD_DISABLE_CATEGORY_IN_LIVE 中的Categories
 * 方案: 从基础数据中过滤掉需要禁用的Cateogries
 */
/**
 * 
 * @param list  category 字符串数组
 * @returns 
 */
export const filterDisabledCategoriesInLive = list => {
  if (IS_LIVE_ENV) {
    return list.filter(item => TD_DISABLE_CATEGORY_IN_LIVE.indexOf(item) === -1)
  }
  return list

}

/**
 * 在Category基础数据第一个元素的值(包含二级的，返回二级，否则返回一级)
 * @param list 
 */
export const getInitCategories = list => {
  const first = list?.[0] ?? { name: '' }
  return first.list?.length ? first.list.map(item => item.name) : [ first.name ]
}

/**
 * ## 根据界面冻结`categories 获取剩余可用的`categories`对象
 * 
 * @param cacheData 
 * @param categories 目前选中的categories
 * @param blocked  `Filter Display`中冻结的对象
 * @returns 
 */
 export const getCategoriesByViewFiltedCategories = (cacheData: any, categories: Array<string>, blocked: string[]) => {
  // const selectedCategories: Array<string> = []
  cacheData.metaCategories.list.forEach(item => {
    if (blocked.includes(`${FILTER_LABELS.CATEGORY}:${item.name}`)) {
      if (item.list.length) {
        const list = item.list.map(item => item.name)
        categories = categories.filter(item => !list.includes(item))
      } else {
        categories = categories.filter(category => category !== item.name)
      }
    } else if (item.list.length) {
        item.list.forEach(item => {
          if (blocked.includes(`${FILTER_LABELS.CATEGORY}:${item.name}`)) {
            categories = categories.filter(category => item.name !== category)
          }
        })
      }
  })
  return categories
}
