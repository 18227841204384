/**
* @file    NornaBar
* @date   2022-07-18
* @author byte_su@163.com
*/

import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { Bar } from 'libs/react-chartjs-lib'
import { NornaBarPropsType } from './types'

export const NornaBar = forwardRef(({ bars }: NornaBarPropsType, ref) => {
    const labels = Array.from({ length: 10 }).map((item, index) => index)
    const barRef = useRef<any>()
    useImperativeHandle(ref,
        () => ({
            bar:barRef.current,
        }),
        [])
    return (
        <Bar
            ref={barRef}
            height={100}
            type="bar"
            width={200}
            plugins={[]}
            options={{
                responsive: true,
                tooltips: {
                    enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            reverse: true,
                            display: false,
                            min: 4,
                            max: 30,
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                        },
                    ]
                    ,
                    xAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                            barPercentage: 1.1,
                            display: false,
                        },
                    ],
                },
                elements: {
                    bar: {
                        borderWidth: 0,
                        width: 13,
                    },
                },
                position: 'center',
                legend: { display: false },
                plugins: {
                    title: {
                        display: false,
                    },
                    chartAreaBorder: {
                        borderColor: 'red',
                        borderWidth: 0,
                        borderDash: [ 5, 5 ],
                        borderDashOffset: 2,
                    },
                },
            }}
            data={{
                labels,
                datasets: [
                    {
                        label: 'Dataset',
                        data: labels.map((item, index: number) => bars[index] ?? 0),
                        backgroundColor: '#01A699',
                        width: 13,
                    },
                ],
            }}
        />
    )
})

NornaBar.displayName = 'NornaBar'
