import React, { Fragment, ReactNode } from 'react'
import classNames from 'classnames'
import { useSoldOutVendorsModuleData } from 'features/filters/moduleDataSlice.hook'
import { ComparisonTooltipV2 } from 'componentsv2/business/ComparisonTooltip'
import * as config from './config'
import styles from './Thead.module.scss'
import { getRegionAlias } from 'utils/feConfig'

const PrimaryText = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <span style={{ color: '#01A699' }}>{children}</span>
    )
}

const Thead = ({
    width,
    regionList,
}: {
    width?: number;
    regionList?: string[];
}) => {
    const [ moduleData ] = useSoldOutVendorsModuleData()
    const { isCompetitorView } = moduleData

    return (
        <div className={classNames([ 'sold-out-dashboard-table-header', styles.wrapper ])}>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.title}>
                        <td width={config.tabWidth} align="center">
                            {isCompetitorView ? 'Vendors' : 'Categories'}
                        </td>
                        <td 
                            width={config.categoryWidth} 
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth }}
                        >
                            {isCompetitorView ? 'Category' : 'Vendor'}
                        </td>
                        {
                            regionList?.map(region => {
                                return (
                                    <td key={region} width={config.soldOutWidth}>
                                        {getRegionAlias(region)}
                                    </td>
                                )
                            })
                        }
                        <td className="tableArrowTd bgWhite" width={config.arrowWidth}></td>
                    </tr>
                </tbody>
            </table>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.subtitle}>
                        <td width={config.tabWidth}></td>
                        <td 
                            width={config.categoryWidth}
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth }}
                        />
                        
                        {
                            regionList?.map(region => {
                                return (
                                    <Fragment key={region}>
                                        <td width={config.soldOutValueWidth}>
                                            <PrimaryText>
                                                %
                                            </PrimaryText>
                                        </td>
                                        <td width={config.soldOutChangeWidth}>
                                            <ComparisonTooltipV2 width={config.soldOutChangeWidth}>
                                                <div>
                                                    <PrimaryText>Change %</PrimaryText>
                                                </div>
                                            </ComparisonTooltipV2>
                                        </td>
                                    </Fragment>
                                )
                            })
                        }
                        <td className="tableArrowTd bgWhite" width={config.arrowWidth}></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Thead
