import React from 'react'
import classnames from 'classnames'
import { Text } from 'components/typography/Text'
import * as cssConfig from 'configs/css'
import { Collapse } from '../Collapse'
import styles from './styles.module.scss'

export const AvailableSizes = ({
    bottom = 0,
    sizePriceList = [],
    totalSizePrice = {},
    currency,
}: {
    bottom?: number;
    sizePriceList: any[];
    totalSizePrice: any;
    currency?: string;
}) => {
    return (
        <Collapse title="Price per size" bottom={bottom}>
            {
                sizePriceList?.length ? (
                    <>
                        <div className={styles.thead}>
                            <div>SIZES</div>
                            <div>FULL PRICE</div>
                            <div>SALES PRICE</div>
                            <div>DISCOUNT</div>
                        </div>
                        {
                            sizePriceList.map(item => {
                                const isHighlight = Number((item?.discountPercentage || '').replace('%', '')) > 0
                                const style = { color: isHighlight ? cssConfig.primaryColor : '#666' }
                                return (
                                    <div className={styles.tr} key={item.size}>
                                        <div>{item?.size}</div>
                                        <div>{item?.originalPrice ? `${item?.originalPrice} ${currency}` : '-'}</div>
                                        <div style={{ ...style }}>{item?.actualPrice ? `${item?.actualPrice} ${currency}` : '-'}</div>
                                        <div style={{ ...style }}>{item?.discountPercentage || '0.0%'}</div>
                                    </div>
                                )
                            })
                        }
                        <div className={classnames([ styles.tr, styles.trTotal ])}>
                            <div>AVERAGE</div>
                            <div>{totalSizePrice?.fullPrice || '-'}</div>
                            <div>{totalSizePrice?.salesPrice || '-'}</div>
                            <div>{totalSizePrice?.discount || '0.0%'}</div>
                        </div>
                    </>
                ) : (
                    <Text small>No size info</Text>
                )
            }
        </Collapse>
    )
}
