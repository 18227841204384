import React, { FC, CSSProperties, memo, useEffect } from 'react'
import classnames from 'classnames'
import { OPEN_IN_NEW_DATE } from 'consts'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { OpenIcon } from 'assets/icons/OpenIcon'
import { useUrlParams } from 'hooks/useUrlParams'
import { useFilterInfo } from 'hooks/useFilterInfo'
import { formatDateStr } from 'utils/dateUtils'
import { FILTER_LABELS } from 'componentsv2/Tree/utils'
import { Info } from './components/Info'
import { FilterInfoProps } from './types'
import styles from './styles.module.scss'

/**
 * FilterInfo
 * 
 * 展示右上角 Filter 中选中值信息
 */
export const FilterInfo: FC<FilterInfoProps> = memo(({
    top,                    // margin-top 值
    bottom = 16,            // margin-bottom 值，通常 FilterInfo 会放在 ModuleTitle 上面，因此给个下外边距的默认值
    left,                   // margin-left 值
    right,                  // margin-right 值
    style = {},
    className,
    hidden = false,         // 不显示内容, Size of line 页面没有使用 Filter, 但也可以打开 Open in new 窗口, 也需要执行 useEffect 的内容
}) => {
    const defaultStyle: CSSProperties = {}
    if (typeof left !== 'undefined') {
        defaultStyle.marginLeft = left + 'px'
    }
    if (typeof right !== 'undefined') {
        defaultStyle.marginRight = right + 'px'
    }
    if (typeof bottom !== 'undefined') {
        defaultStyle.marginBottom = bottom + 'px'
    }
    if (typeof top !== 'undefined') {
        defaultStyle.marginTop = top + 'px'
    }

    const urlParams = useUrlParams()

    // Region, Currency, Category, Gender, Competitor
    const dateValue = formatDateStr(urlParams[OPEN_IN_NEW_DATE], true)

    const {
        categoryValue,
        propertyValue,
        genderValue,
        countryValue,
        currencyValue,
        sortByValue,
        priceFilterValue,
        launchedAfterValue,
        materialFilterValue,
        colorFilterValue,
    } = useFilterInfo()

    // 用数组而不是直接拼接字符串，是担心有些属性没有值，没有值的属性就不显示
    let filterArr: string[] = []
    filterArr.push(dateValue)
    filterArr.push(countryValue)           // Filter > Country
    filterArr.push(currencyValue)          // Filter > Currency
    filterArr.push(categoryValue)          // Filter > Category
    filterArr.push(genderValue)            // Filter > Gender
    filterArr.push(propertyValue)          // Filter > Property
    filterArr.push(sortByValue)            // Filter > Sort by
    filterArr.push(priceFilterValue)       // Filter > Price filter
    filterArr.push(materialFilterValue)    // Filter > Material filter
    filterArr.push(colorFilterValue)       // Filter > Color filter
    filterArr.push(launchedAfterValue)     // Filter > Launched after
    filterArr = filterArr.filter(item => item)

    useEffect(() => {
        const pageEle:HTMLDivElement = document.querySelector('.page-container') as HTMLDivElement
        const bodyEle:HTMLBodyElement = document.querySelector('body') as HTMLBodyElement
        if(pageEle){
            pageEle.style.overflow = 'auto'
            pageEle.style.margin = '0 auto'
            const containerEle = pageEle.parentElement
            if (containerEle) containerEle.style.margin = '0 auto'
        }
        if(bodyEle)bodyEle.style.overflow = 'auto'

    }, [])

    if (hidden === true) {
        return null
    }

    return (
        <div className={classnames([ styles.filterInfo, className ])} style={{ ...defaultStyle, ...style }}>
            <div className={styles.filterInfoContent}>
                <OpenIcon size={20} />
                <div className={styles.divider} />
                <div className={styles.filter}>
                    {filterArr.join(', ')}
                </div>
                <div className={styles.divider} />
                <Info>
                    <div className={styles.filterDetail}>
                        <ShouldRender shouldRender={Boolean(dateValue)}>
                            <div>
                                <label>Date: </label>{dateValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(categoryValue)}>
                            <div>
                                <label>Categories: </label>{categoryValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(propertyValue)}>
                            <div>
                                <label>Property: </label>{propertyValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(genderValue)}>
                            <div>
                                <label>Gender: </label>{genderValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(countryValue)}>
                            <div>
                                <label>Country: </label>{countryValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(currencyValue)}>
                            <div>
                                <label>Currency: </label>{currencyValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(sortByValue)}>
                            <div>
                                <label>Sort by: </label>{sortByValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(priceFilterValue)}>
                            <div>
                                <label>{FILTER_LABELS.PRICE_FILTER}: </label>{priceFilterValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(launchedAfterValue)}>
                            <div>
                                <label>Launched after: </label>{launchedAfterValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(materialFilterValue)}>
                            <div>
                                <label>Material filter: </label>{materialFilterValue}
                            </div>
                        </ShouldRender>
                        <ShouldRender shouldRender={Boolean(colorFilterValue)}>
                            <div>
                                <label>Color filter: </label>{colorFilterValue}
                            </div>
                        </ShouldRender>
                    </div>
                </Info>
            </div>
        </div>
    )
})

FilterInfo.displayName = 'FilterInfo'
