/* ************************************** Canada goose hard code ************************************** */

import { DataType } from 'componentsv2/Tree/common'
import { ALL_COUNTRIES } from 'consts'
import { intersection } from 'utils/array'

export const LEVEL1_CANCADAGOOSE = 'Canada Goose'
export const LEVEL1_MONCLER = 'Moncler'

export const LEVEL2_DIRECT_PARTNERS = 'Direct Partners'
export const LEVEL2_INDIRECT_PARTNERS = 'Indirect Partners'

export const LEVEL3_WHS = 'Canada Goose WHS'
export const LEVEL3_PURE_PLAYERS = 'Pure players'

const canadagooseSuffixName = `@${LEVEL1_CANCADAGOOSE}`
const monclerSuffixName = `@${LEVEL1_MONCLER}`

export const canadagooseFilterCompetitor = [
    {
        name: `Canada Goose${canadagooseSuffixName}`,
        vendor: 'canadagoose',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Bergfreunde${canadagooseSuffixName}`,
        vendor: 'Canada Goose@bergfreunde',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Breuninger${canadagooseSuffixName}`,
        vendor: 'Canada Goose@breuninger',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Engelhorn${canadagooseSuffixName}`,
        vendor: 'Canada Goose@engelhorn',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `KaDeWe${canadagooseSuffixName}`,
        vendor: 'Canada Goose@kadewe',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Lodenfrey${canadagooseSuffixName}`,
        vendor: 'Canada Goose@lodenfrey',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `De Bijenkorf${canadagooseSuffixName}`,
        vendor: 'Canada Goose@debijenkorf',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Flannels${canadagooseSuffixName}`,
        vendor: 'Canada Goose@flannels',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Harvey Nichols${canadagooseSuffixName}`,
        vendor: 'Canada Goose@harveynichols',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Selfridges${canadagooseSuffixName}`,
        vendor: 'Canada Goose@selfridges',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Galeries Lafayette${canadagooseSuffixName}`,
        vendor: 'Canada Goose@galerieslafayette',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Printemps${canadagooseSuffixName}`,
        vendor: 'Canada Goose@printemps',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Rinascente${canadagooseSuffixName}`,
        vendor: 'Canada Goose@rinascente',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    /* ****************************************************************** */
    {
        name: `Luisa via Roma${canadagooseSuffixName}`,
        vendor: 'Canada Goose@luisaviaroma',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `Mr Porter${canadagooseSuffixName}`,
        vendor: 'Canada Goose@mrporter',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `Net a Porter${canadagooseSuffixName}`,
        vendor: 'Canada Goose@net-a-porter',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `Care of Carl${canadagooseSuffixName}`,
        vendor: 'Canada Goose@careofcarl',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `My Theresa${canadagooseSuffixName}`,
        vendor: 'Canada Goose@mytheresa',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `24S${canadagooseSuffixName}`,
        vendor: 'Canada Goose@24s',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `Browns Fashion${canadagooseSuffixName}`,
        vendor: 'Canada Goose@brownsfashion',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    /* ****************************************************************** */
    {
        name: `Farfetch${canadagooseSuffixName}`,
        vendor: 'Canada Goose@farfetch',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_INDIRECT_PARTNERS,
    },
    {
        name: `Miinto${canadagooseSuffixName}`,
        vendor: 'Canada Goose@miinto',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_INDIRECT_PARTNERS,
    },
    {
        name: `Yoox${canadagooseSuffixName}`,
        vendor: 'Canada Goose@yoox',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_INDIRECT_PARTNERS,
    },
    {
        name: `Cettire${canadagooseSuffixName}`,
        vendor: 'Canada Goose@cettire',
        level1: LEVEL1_CANCADAGOOSE,
        level2: LEVEL2_INDIRECT_PARTNERS,
    },
    /* ****************************************************************** */
    {
        name: `Moncler${monclerSuffixName}`,
        vendor: 'moncler',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Breuninger${monclerSuffixName}`,
        vendor: 'Moncler@breuninger',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Engelhorn${monclerSuffixName}`,
        vendor: 'Moncler@engelhorn',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Lodenfrey${monclerSuffixName}`,
        vendor: 'Moncler@lodenfrey',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `De Bijenkorf${monclerSuffixName}`,
        vendor: 'Moncler@debijenkorf',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Flannels${monclerSuffixName}`,
        vendor: 'Moncler@flannels',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Harvey Nichols${monclerSuffixName}`,
        vendor: 'Moncler@harveynichols',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Selfridges${monclerSuffixName}`,
        vendor: 'Moncler@selfridges',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    {
        name: `Printemps${monclerSuffixName}`,
        vendor: 'Moncler@printemps',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_WHS,
    },
    /* ****************************************************************** */
    {
        name: `Luisa via Roma${monclerSuffixName}`,
        vendor: 'Moncler@luisaviaroma',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `Mr Porter${monclerSuffixName}`,
        vendor: 'Moncler@mrporter',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `Net a Porter${monclerSuffixName}`,
        vendor: 'Moncler@net-a-porter',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `Care of Carl${monclerSuffixName}`,
        vendor: 'Moncler@careofcarl',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `My Theresa${monclerSuffixName}`,
        vendor: 'Moncler@mytheresa',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `24S${monclerSuffixName}`,
        vendor: 'Moncler@24s',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    {
        name: `Browns Fashion${monclerSuffixName}`,
        vendor: 'Moncler@brownsfashion',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_DIRECT_PARTNERS,
        level3: LEVEL3_PURE_PLAYERS,
    },
    /* ****************************************************************** */
    {
        name: `Yoox${monclerSuffixName}`,
        vendor: 'Moncler@yoox',
        level1: LEVEL1_MONCLER,
        level2: LEVEL2_INDIRECT_PARTNERS,
    },

    {
        name: 'Stone Island',
        vendor: 'stoneisland',
    },
    {
        name: 'Ami Paris',
        vendor: 'ami',
    },
    {
        name: 'Mackage',
        vendor: 'mackage',
    },
    {
        name: 'Moose Knuckles',
        vendor: 'mooseknuckles',
    },
    {
        name: 'Burberry',
        vendor: 'burberry',
    },
    {
        name: 'Acnestudios',
        vendor: 'acnestudios',
    },
    {
        name: 'Ganni',
        vendor: 'ganni',
    },
    {
        name: 'Herno',
        vendor: 'herno',
    },
    {
        name: 'The North Face',
        vendor: 'northface',
    },
    {
        name: 'Arcteryx',
        vendor: 'arcteryx',
    },
]

/* ************************************** Just brands hard code ************************************** */
export const JUST_BRANDS_LEVEL1_DTC = 'DTC'
export const JUST_BRANDS_LEVEL1_PLATFORMS = 'Platforms'

export const JUST_BRANDS_LEVEL2_ZALANDO = 'Zalando'
export const JUST_BRANDS_LEVEL2_ABOUT_YOU = 'About You'
export const JUST_BRANDS_LEVEL2_OTTO = 'Otto'

export const getJustBrandsCompetitorChildren = ({
    region,
    selectedVendors,        // 选中值
    disabledFilterCompetitor,
}) => {
    const netherlandsDtcVendors = [
        'pme-legend',        'vanguard',
        'castiron-clothing', 'camelactive',
        'gant',              'gstar',
        'boss',              'marcopolo',
        'noexcess',          'scotch-and-soda',
        'stateofart',        'tommyhilfiger',
        'ralphlauren',       'albertoshop',
        'meyerbroeken',      'brax',
        'wellensteyn',       'olymp',
        'blueindustry',      'profuomo',
        'cavallaronapoli',   'lyleandscott',
        'nza',
    ]
    const netherlandsPlatformsVendors = [
        'Tommy Hilfiger@zalando',
        'Hugo Boss@zalando', 'PME Legend@zalando',
        'G-Star@zalando',    'Vanguard@zalando',
        'Cast Iron@zalando',
    ]

    const belgiumDtcVendors = [
        'pme-legend',
    ]

    const belgiumPlatformsVendors = [
        'Tommy Hilfiger@zalando',
        'Hugo Boss@zalando', 'PME Legend@zalando',
        'G-Star@zalando',    'Vanguard@zalando',
        'Cast Iron@zalando',
    ]

    const germanyDtcVendors = [
        'pme-legend',
    ]

    const germanyPlatformsAboutYouVendors = [
        'PME Legend@aboutyou',
        'Cast Iron@aboutyou',
        'Vanguard@aboutyou',
    ]

    const germanyPlatformsOttoVendors = [
        'PME Legend@otto',
        'Cast Iron@otto',
        'Vanguard@otto',
    ]

    const germanyPlatformsZalandoVendors = [
        'Tommy Hilfiger@zalando',
        'Hugo Boss@zalando', 'PME Legend@zalando',
        'G-Star@zalando',    'Vanguard@zalando',
        'Cast Iron@zalando',
    ]

    const germanyPlatformsVendors = [ 
        ...germanyPlatformsAboutYouVendors,
        ...germanyPlatformsOttoVendors,
        ...germanyPlatformsZalandoVendors,
    ]

    switch (region) {
        case 'Belgium':
            return [
                {
                    active: !!intersection(selectedVendors, belgiumDtcVendors)?.length,
                    region: 'Belgium',
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    label: JUST_BRANDS_LEVEL1_DTC,
                    masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL1_DTC),
                    children: [
                        {
                            label: 'PME Legend',
                            val: 'pme-legend',
                            region: 'Belgium',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('pme-legend'),
                        },
                    ],
                },
                {
                    active: !!intersection(selectedVendors, belgiumPlatformsVendors)?.length,
                    region: 'Belgium',
                    label: JUST_BRANDS_LEVEL1_PLATFORMS,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL1_PLATFORMS),
                    children: [
                        {
                            active: !!intersection(selectedVendors, belgiumPlatformsVendors)?.length,
                            region: 'Belgium',
                            label: JUST_BRANDS_LEVEL2_ZALANDO,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL2_ZALANDO),
                            children: [
                                {
                                    label: 'PME Legend',
                                    val: 'PME Legend@zalando',
                                    region: 'Belgium',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('PME Legend@zalando'),
                                },
                                {
                                    label: 'Cast Iron',
                                    val: 'Cast Iron@zalando',
                                    region: 'Belgium',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Cast Iron@zalando'),
                                },
                                {
                                    label: 'G-Star',
                                    val: 'G-Star@zalando',
                                    region: 'Belgium',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('G-Star@zalando'),
                                },
                                {
                                    label: 'Hugo Boss',
                                    val: 'Hugo Boss@zalando',
                                    region: 'Belgium',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Hugo Boss@zalando'),
                                },
                                {
                                    label: 'Tommy Hilfiger',
                                    val: 'Tommy Hilfiger@zalando',
                                    region: 'Belgium',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Tommy Hilfiger@zalando'),
                                },
                                {
                                    label: 'Vanguard',
                                    val: 'Vanguard@zalando',
                                    region: 'Belgium',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Vanguard@zalando'),
                                },
                            ],
                        },
                    ],
                },
            ]
        case 'Netherlands':
            return [
                {
                    active: !!intersection(selectedVendors, netherlandsDtcVendors)?.length,
                    region: 'Netherlands',
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    label: JUST_BRANDS_LEVEL1_DTC,
                    masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL1_DTC),
                    children: [
                        {
                            label: 'PME Legend',
                            val: 'pme-legend',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('pme-legend'),
                        },
                        {
                            label: 'Alberto',
                            val: 'albertoshop',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('albertoshop'),
                        },
                        {
                            label: 'Blue Industry',
                            val: 'blueindustry',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('blueindustry'),
                        },
                        {
                            label: 'Brax',
                            val: 'brax',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('brax'),
                        },
                        {
                            label: 'Camel active',
                            val: 'camelactive',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('camelactive'),
                        },
                        {
                            label: 'Cast Iron',
                            val: 'castiron-clothing',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('castiron-clothing'),
                        },
                        {
                            label: 'Cavallaro',
                            val: 'cavallaronapoli',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('cavallaronapoli'),
                        },
                        {
                            label: 'G-star',
                            val: 'gstar',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('gstar'),
                        },
                        {
                            label: 'Gant',
                            val: 'gant',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('gant'),
                        },
                        {
                            label: 'Hugo Boss',
                            val: 'boss',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('boss'),
                        },
                        {
                            label: 'Lyle & Scott',
                            val: 'lyleandscott',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('lyleandscott'),
                        },
                        {
                            label: 'Marc O polo',
                            val: 'marcopolo',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('marcopolo'),
                        },
                        {
                            label: 'Meyer',
                            val: 'meyerbroeken',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('meyerbroeken'),
                        },
                        {
                            label: 'NZA',
                            val: 'nza',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('nza'),
                        },
                        {
                            label: 'No Excess',
                            val: 'noexcess',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('noexcess'),
                        },
                        {
                            label: 'Olymp',
                            val: 'olymp',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('olymp'),
                        },
                        {
                            label: 'Profumo',
                            val: 'profuomo',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('profuomo'),
                        },
                        {
                            label: 'Ralph Lauren',
                            val: 'ralphlauren',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('ralphlauren'),
                        },
                        {
                            label: 'Scotch & Soda',
                            val: 'scotch-and-soda',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('scotch-and-soda'),
                        },
                        {
                            label: 'State of Art',
                            val: 'stateofart',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('stateofart'),
                        },
                        {
                            label: 'Tommy Hilfiger',
                            val: 'tommyhilfiger',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('tommyhilfiger'),
                        },
                        {
                            label: 'Vanguard',
                            val: 'vanguard',
                            region: 'Netherlands',
                            level1: JUST_BRANDS_LEVEL1_DTC,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('vanguard'),
                        },
                        {
                            label: 'Wellensteyn',
                            val: 'wellensteyn',
                            region: 'Netherlands',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('wellensteyn'),
                        },
                    ],
                },
                {
                    active: !!intersection(selectedVendors, netherlandsPlatformsVendors)?.length,
                    region: 'Netherlands',
                    label: JUST_BRANDS_LEVEL1_PLATFORMS,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL1_PLATFORMS),
                    children: [
                        {
                            active: !!intersection(selectedVendors, netherlandsPlatformsVendors)?.length,
                            region: 'Netherlands',
                            label: JUST_BRANDS_LEVEL2_ZALANDO,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL2_ZALANDO),
                            children: [
                                {
                                    label: 'PME Legend',
                                    val: 'PME Legend@zalando',
                                    region: 'Netherlands',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('PME Legend@zalando'),
                                },
                                {
                                    label: 'Cast Iron',
                                    val: 'Cast Iron@zalando',
                                    region: 'Netherlands',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Cast Iron@zalando'),
                                },
                                {
                                    label: 'G-Star',
                                    val: 'G-Star@zalando',
                                    region: 'Netherlands',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('G-Star@zalando'),
                                },
                                {
                                    label: 'Hugo Boss',
                                    val: 'Hugo Boss@zalando',
                                    region: 'Netherlands',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Hugo Boss@zalando'),
                                },
                                {
                                    label: 'Tommy Hilfiger',
                                    val: 'Tommy Hilfiger@zalando',
                                    region: 'Netherlands',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Tommy Hilfiger@zalando'),
                                },
                                {
                                    label: 'Vanguard',
                                    val: 'Vanguard@zalando',
                                    region: 'Netherlands',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Vanguard@zalando'),
                                },
                            ],
                        },
                    ],
                },
            ]
        case 'Germany':
            return [
                {
                    active: !!intersection(selectedVendors, germanyDtcVendors)?.length,
                    region: 'Germany',
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    label: JUST_BRANDS_LEVEL1_DTC,
                    masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL1_DTC),
                    children: [
                        {
                            label: 'PME Legend',
                            val: 'pme-legend',
                            region: 'Germany',
                            type: DataType.CHECK,
                            active: selectedVendors.includes('pme-legend'),
                        },
                    ],
                },
                {
                    active: !!intersection(selectedVendors, germanyPlatformsVendors)?.length,
                    region: 'Germany',
                    label: JUST_BRANDS_LEVEL1_PLATFORMS,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL1_PLATFORMS),
                    children: [
                        {
                            active: !!intersection(selectedVendors, germanyPlatformsAboutYouVendors)?.length,
                            region: 'Germany',
                            label: JUST_BRANDS_LEVEL2_ABOUT_YOU,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL2_ABOUT_YOU),
                            children: [
                                {
                                    label: 'PME Legend',
                                    val: 'PME Legend@aboutyou',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('PME Legend@aboutyou'),
                                },
                                {
                                    label: 'Cast Iron',
                                    val: 'Cast Iron@aboutyou',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Cast Iron@aboutyou'),
                                },
                                {
                                    label: 'Vanguard',
                                    val: 'Vanguard@aboutyou',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Vanguard@aboutyou'),
                                },
                            ],
                        },
                        {
                            active: !!intersection(selectedVendors, germanyPlatformsOttoVendors)?.length,
                            region: 'Germany',
                            label: JUST_BRANDS_LEVEL2_OTTO,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL2_OTTO),
                            children: [
                                {
                                    label: 'PME Legend',
                                    val: 'PME Legend@otto',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('PME Legend@otto'),
                                },
                                {
                                    label: 'Cast Iron',
                                    val: 'Cast Iron@otto',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Cast Iron@otto'),
                                },
                                {
                                    label: 'Vanguard',
                                    val: 'Vanguard@otto',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Vanguard@otto'),
                                },
                            ],
                        },
                        {
                            active: !!intersection(selectedVendors, germanyPlatformsZalandoVendors)?.length,
                            region: 'Germany',
                            label: JUST_BRANDS_LEVEL2_ZALANDO,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL2_ZALANDO),
                            children: [
                                {
                                    label: 'PME Legend',
                                    val: 'PME Legend@zalando',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('PME Legend@zalando'),
                                },
                                {
                                    label: 'Cast Iron',
                                    val: 'Cast Iron@zalando',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Cast Iron@zalando'),
                                },
                                {
                                    label: 'G-Star',
                                    val: 'G-Star@zalando',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('G-Star@zalando'),
                                },
                                {
                                    label: 'Hugo Boss',
                                    val: 'Hugo Boss@zalando',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Hugo Boss@zalando'),
                                },
                                {
                                    label: 'Tommy Hilfiger',
                                    val: 'Tommy Hilfiger@zalando',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Tommy Hilfiger@zalando'),
                                },
                                {
                                    label: 'Vanguard',
                                    val: 'Vanguard@zalando',
                                    region: 'Germany',
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Vanguard@zalando'),
                                },
                            ],
                        },
                    ],
                },
            ]
        case ALL_COUNTRIES:
            return [
                {
                    active: !!intersection(selectedVendors, netherlandsDtcVendors)?.length,
                    region: ALL_COUNTRIES,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    label: JUST_BRANDS_LEVEL1_DTC,
                    masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL1_DTC),
                    children: [
                        {
                            label: 'PME Legend',
                            val: 'pme-legend',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('pme-legend'),
                        },
                        {
                            label: 'Alberto',
                            val: 'albertoshop',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('albertoshop'),
                        },
                        {
                            label: 'Blue Industry',
                            val: 'blueindustry',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('blueindustry'),
                        },
                        {
                            label: 'Brax',
                            val: 'brax',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('brax'),
                        },
                        {
                            label: 'Camel active',
                            val: 'camelactive',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('camelactive'),
                        },
                        {
                            label: 'Cast Iron',
                            val: 'castiron-clothing',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('castiron-clothing'),
                        },
                        {
                            label: 'Cavallaro',
                            val: 'cavallaronapoli',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('cavallaronapoli'),
                        },
                        {
                            label: 'G-star',
                            val: 'gstar',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('gstar'),
                        },
                        {
                            label: 'Gant',
                            val: 'gant',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('gant'),
                        },
                        {
                            label: 'Hugo Boss',
                            val: 'boss',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('boss'),
                        },
                        {
                            label: 'Lyle & Scott',
                            val: 'lyleandscott',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('lyleandscott'),
                        },
                        {
                            label: 'Marc O polo',
                            val: 'marcopolo',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('marcopolo'),
                        },
                        {
                            label: 'Meyer',
                            val: 'meyerbroeken',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('meyerbroeken'),
                        },
                        {
                            label: 'NZA',
                            val: 'nza',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('nza'),
                        },
                        {
                            label: 'No Excess',
                            val: 'noexcess',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('noexcess'),
                        },
                        {
                            label: 'Olymp',
                            val: 'olymp',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('olymp'),
                        },
                        {
                            label: 'Profumo',
                            val: 'profuomo',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('profuomo'),
                        },
                        {
                            label: 'Ralph Lauren',
                            val: 'ralphlauren',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('ralphlauren'),
                        },
                        {
                            label: 'Scotch & Soda',
                            val: 'scotch-and-soda',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('scotch-and-soda'),
                        },
                        {
                            label: 'State of Art',
                            val: 'stateofart',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('stateofart'),
                        },
                        {
                            label: 'Tommy Hilfiger',
                            val: 'tommyhilfiger',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('tommyhilfiger'),
                        },
                        {
                            label: 'Vanguard',
                            val: 'vanguard',
                            region: ALL_COUNTRIES,
                            level1: JUST_BRANDS_LEVEL1_DTC,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('vanguard'),
                        },
                        {
                            label: 'Wellensteyn',
                            val: 'wellensteyn',
                            region: ALL_COUNTRIES,
                            type: DataType.CHECK,
                            active: selectedVendors.includes('wellensteyn'),
                        },
                    ],
                },
                {
                    active: !!intersection(selectedVendors, germanyPlatformsVendors)?.length,
                    region: ALL_COUNTRIES,
                    label: JUST_BRANDS_LEVEL1_PLATFORMS,
                    type: DataType.ARROW_CHECK,
                    enableSelectAll: true,
                    masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL1_PLATFORMS),
                    children: [
                        {
                            active: !!intersection(selectedVendors, germanyPlatformsAboutYouVendors)?.length,
                            region: ALL_COUNTRIES,
                            label: JUST_BRANDS_LEVEL2_ABOUT_YOU,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL2_ABOUT_YOU),
                            children: [
                                {
                                    label: 'PME Legend',
                                    val: 'PME Legend@aboutyou',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('PME Legend@aboutyou'),
                                },
                                {
                                    label: 'Cast Iron',
                                    val: 'Cast Iron@aboutyou',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Cast Iron@aboutyou'),
                                },
                                {
                                    label: 'Vanguard',
                                    val: 'Vanguard@aboutyou',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Vanguard@aboutyou'),
                                },
                            ],
                        },
                        {
                            active: !!intersection(selectedVendors, germanyPlatformsOttoVendors)?.length,
                            region: ALL_COUNTRIES,
                            label: JUST_BRANDS_LEVEL2_OTTO,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL2_OTTO),
                            children: [
                                {
                                    label: 'PME Legend',
                                    val: 'PME Legend@otto',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('PME Legend@otto'),
                                },
                                {
                                    label: 'Cast Iron',
                                    val: 'Cast Iron@otto',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Cast Iron@otto'),
                                },
                                {
                                    label: 'Vanguard',
                                    val: 'Vanguard@otto',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Vanguard@otto'),
                                },
                            ],
                        },
                        {
                            active: !!intersection(selectedVendors, germanyPlatformsZalandoVendors)?.length,
                            region: ALL_COUNTRIES,
                            label: JUST_BRANDS_LEVEL2_ZALANDO,
                            type: DataType.ARROW_CHECK,
                            enableSelectAll: true,
                            masked: disabledFilterCompetitor.includes(JUST_BRANDS_LEVEL2_ZALANDO),
                            children: [
                                {
                                    label: 'PME Legend',
                                    val: 'PME Legend@zalando',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('PME Legend@zalando'),
                                },
                                {
                                    label: 'Cast Iron',
                                    val: 'Cast Iron@zalando',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Cast Iron@zalando'),
                                },
                                {
                                    label: 'G-Star',
                                    val: 'G-Star@zalando',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('G-Star@zalando'),
                                },
                                {
                                    label: 'Hugo Boss',
                                    val: 'Hugo Boss@zalando',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Hugo Boss@zalando'),
                                },
                                {
                                    label: 'Tommy Hilfiger',
                                    val: 'Tommy Hilfiger@zalando',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Tommy Hilfiger@zalando'),
                                },
                                {
                                    label: 'Vanguard',
                                    val: 'Vanguard@zalando',
                                    region: ALL_COUNTRIES,
                                    type: DataType.CHECK,
                                    active: selectedVendors.includes('Vanguard@zalando'),
                                },
                            ],
                        },
                    ],
                },
            ]
        default:
            return []
    }
}
