import React, { useEffect } from 'react'
import { ProgressBar } from 'components/ProgressBar'
import { selectMarket, setCustomerSeller } from 'features/filters/filtersSlice'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import { useSellers } from '../../../graphql/sellers/getSellers'
import { getCacheData } from '../../../features/filters/cacheDataSlice'
import { UpdateComponent } from './components/UpdateComponent'

export const Header = () => {
  const dispatch = useDispatch()

  const { ourSeller } = useSelector(getCacheData)

  const { sellers, getSellerByCodeAndRegion } = useSellers([])

  // Filter > Country 选中项，格式如：{key: 'Norway', currency: 'NOK'}
  const filterCountry = useSelector(selectMarket)

  // const {disabled} = useFilter()

  useEffect(() => {
    /**
     * 此前遇到的问题：
     * 刷新页面会进入该 useEffect 方法，customer_seller.region 会被重置为 ourSeller.region 值，
     * 而 ourSeller.region 有可能与 Filter > Country 选中值不对应，
     * 就会造成发接口查询得到的数据不正确（查出来数据的 region 和 Filter > Country 对不上）
     *
     * 解决方法：如果当前 Filter > Country 有选中值，customer_seller.region 优先被设置为 Filter > Country 选中值
     */
    if (filterCountry?.key) {
      dispatch(setCustomerSeller({ vendor: ourSeller.vendor, region: filterCountry?.key }))
    } else if (ourSeller && sellers.length) {
      const customerSeller = getSellerByCodeAndRegion(ourSeller.vendor, ourSeller.region, ourSeller.brands)// { vendor: ourSeller.vendor, region: ourSeller.region };
      dispatch(setCustomerSeller(customerSeller))
    }
  }, [ ourSeller, dispatch, sellers, getSellerByCodeAndRegion, filterCountry ])

  return (
    <header className={styles.header}>
      <div className={styles.topBar}>
        <div style={{ flex: 1 }}>
          &nbsp;
        </div>
        
        <ProgressBar />
        {/* 不要删除，触发 radio 的 focus() 可以让页面其它元素失去焦点 */}
        <div style={{ height: 0, width: 0, overflow: 'hidden' }}>
          <input type="radio" id="unfocusRadio" />
        </div>
      </div>
      <UpdateComponent />
    </header>
  )
}
