import { useSelector } from 'react-redux'
import { selectComparisonQuery } from 'features/filters/filtersSlice'
import { PRICE_FILTER } from 'consts'
import { sortByOptionsFn } from 'components/layout/Header/components/HeaderFilter/components/SortBy/SortBy'
import { useCategories } from 'pages/SearchForOptions/hooks/useCategories'
import { useConvertDisplayNotShowPropery } from 'features/filters/cacheDataSlice'

/**
 * ## useFilterInfo
 * 
 * 目前有两个地方都用到了 filter 信息展示
 * 1. 所有分析页右上角会有个感叹号图标，鼠标悬浮会显示 filter 选中信息
 * 2. 分析页点击 Open in new 按钮会打开一个新弹窗，里面也会显示 filer 选中信息
 * 
 * 目前有几个属性需要特殊处理：
 * 比如 categoryValue 选中全部二级分类时只显示一级分类，选中部分二级分类时，显示选中的二级分类；
 * 比如 propertyValue 需要特殊处理展示不显示二级Properties的Property
 * 
 * 后面如果再需要对个别属性做特殊处理，只要修改这一个地方即可。
 */
export const useFilterInfo = (): FilterInfoReturn => {
    // Filter 选中值大对象
    const { comparisonQuery } = useSelector(selectComparisonQuery)

    // e.g ['Jackets & Blazers']
    const { categories } = useCategories(false)

    // e.g ['Children', 'Men', 'Women']
    const targetGroups = comparisonQuery.collection.query.targetGroups || []

    // e.g [{val: 'DATE_DESC', active: true, label: 'Intro date', isDefault: true, getDate: ƒ}]
    const sortField = sortByOptionsFn(comparisonQuery.collection.query.sortField || {}).filter(item => item.active)

    // e.g {type: 'SINGLE_CHECK', active: true, val: 'All actual', label: 'All options @ actual retail price'}
    const priceFilter = comparisonQuery.collection.query.priceFilter || {}

    // e.g {Colors: Array(12)}
    const properties = comparisonQuery.collection.query.properties || []

    // e.g {percent: 90, checked: true, materials: Array(0)}
    const materialFilter = comparisonQuery.collection.query.materialFilter || {}

    // e.g {percent: 90, checked: true, colors: Array(12)}
    const colorFilter = comparisonQuery.collection.query.colorFilter || {}

    // e.g {key: 'Finland', currency: 'EUR'}
    const region = comparisonQuery.collection.query.regions

    // e.g 20220208
    const { earliestLaunchDate } = comparisonQuery.collection.query

    // 处理展示不显示二级Properties的Property
    const { pureShow } = useConvertDisplayNotShowPropery()

    // Region, Currency, Category, Gender, Competitor
    const categoryValue = categories && categories.length ? categories.join(', ') : ''
    const propertyValue = properties && Object.keys(properties).length > 0 ? pureShow(Object.values(properties).flat(1) as string[]).join(', ') : ''
    const genderValue = targetGroups && targetGroups.length ? targetGroups.join(', ') : ''
    const countryValue = region && region.key ? region.key : ''
    const currencyValue = region && region.currency ? region.currency : ''
    const sortByValue = sortField.length > 0 ? sortField[0].label : ''
    const priceFilterValue = Object.keys(priceFilter).length > 0 ? PRICE_FILTER[priceFilter.val] : ''
    const launchedAfterValue = earliestLaunchDate ? (earliestLaunchDate || '').replace(/-/ig, '') : ''
    const materialFilterValue = Object.keys(materialFilter).length && materialFilter.percent ? materialFilter.percent + '%' : ''
    const colorFilterValue = Object.keys(colorFilter).length && colorFilter.percent ? colorFilter.percent + '%' : ''

    return {
        categoryValue,
        propertyValue,
        genderValue,
        countryValue,
        currencyValue,
        sortByValue,
        priceFilterValue,
        launchedAfterValue,
        materialFilterValue,
        colorFilterValue,
    }
}

interface FilterInfoReturn {
    categoryValue: string;
    propertyValue: string;
    genderValue: string;
    countryValue: string;
    currencyValue: string;
    sortByValue: string;
    priceFilterValue: string;
    launchedAfterValue: string;
    materialFilterValue: string;
    colorFilterValue: string;
}
