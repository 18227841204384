export const calcHandler = ({ 
  row, 
  metricsTab, 
  categoryToVendor, 
  isTd, 
}: {
  row: any;
  metricsTab: any;
  categoryToVendor: boolean;
  isTd: boolean;
}) => {
  // sum height of current row and active children height
  let currentRowHeight = 43
  let rowIndex = -1       // 当前是第几行
  let rowSubIndex = -1
  let rowActive = false
  let expandSub = false
  let subActive = false
  let currentTab: any = null

  // competitor 视图
  if (categoryToVendor) {
    // 第 1 级
    const oneLevelIndex = metricsTab.findIndex(item => item.title === row.vendorName)
    rowIndex = oneLevelIndex
    currentTab = metricsTab[oneLevelIndex] // corresponding tab item
    if (row.level === 1 && currentTab) {
      const secondLevelIndex = currentTab.children.findIndex(el => el.categoryKey === row.categoryName)
      rowSubIndex = secondLevelIndex
      currentTab = currentTab.children[secondLevelIndex] //
      if (currentTab.active) {
        currentRowHeight = currentTab.active ? currentTab.categorycount * 43 : 0
      }
      rowActive = currentTab.active
      expandSub = currentTab.active
    } else if (row.level === 2) { // leaf node

    } else if (currentTab) { // root node
      currentRowHeight = (currentTab.categorycount - 1) * 43
      currentTab.children.forEach(el => {
        if (el.active) currentRowHeight += el.categorycount * 43
      })
      rowActive = currentTab.active
      expandSub = currentTab.active
    }
  } 
  // category 视图
  else {
    const oneLevelIndex = metricsTab.findIndex(item => item.title === row.categoryName)
    rowIndex = oneLevelIndex
    currentTab = metricsTab[oneLevelIndex] // corresponding tab item
    if (oneLevelIndex > -1 && currentTab) {
      subActive = currentTab.subActive
      if (currentTab.active && currentTab.subActive) {
        currentRowHeight = (currentTab.categorycount - 1) * 43
        rowActive = currentTab.active
        expandSub = currentTab.subActive
      } else if (currentTab.subActive && currentTab) {
        rowActive = currentTab.active
        // currentRowHeight += currentTab.children.length*43;
        currentRowHeight += (currentTab.categorycount - 2) * 43
        // currentTab.children.forEach(el => el.active && ()
        expandSub = currentTab.subActive
      } else if (currentTab.active && currentTab) { // leaf node
        // root node
        currentRowHeight = (currentTab.categorycount - 1) * 43
        rowActive = currentTab.active
        expandSub = currentTab.active
      }
    }
  }

  let appendNum = row.level === 1 ? 0 : 1
  appendNum = categoryToVendor ? appendNum : 0
  if (isTd) {
    appendNum = 0
  }
  if (categoryToVendor && (row.level === 0 || row.level === 1) && appendNum === 0) {
    if (row?.children?.filter(item => item?.children && item?.children?.length).length) {
      appendNum += 1
    }
  }

  return {
    appendNum,
    rowActive, // current row active or not
    rowIndex,  // current row index in the current row level
    expandSub, // expand next level or not
    rowSubIndex, //
    subActive,
    currentRowHeight,
  }
}
