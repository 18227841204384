import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { RoutesObj } from 'global/Routes'
import { Home } from 'pages/Home'
import AuthenticationDataProvider from 'components/guards/AuthenticationContext/AuthenticationContext'
import { PrivateRoute } from 'components/guards/PrivateRoute'
import { ActionBoxProvider } from 'components/common/InfoBox/InfoActionContext'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { ErrorBoundary } from './components/common/ErrorBoundary'

const App: React.FC = () =>
  <Provider store={store}>
    <Router>
      <AuthenticationDataProvider>
        <ActionBoxProvider>
          <ErrorBoundary>
            <PrivateRoute path={RoutesObj.HOME.URL} component={Home} />
          </ErrorBoundary>
        </ActionBoxProvider>
      </AuthenticationDataProvider>
    </Router>
  </Provider>

export default App
