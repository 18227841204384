import React from 'react'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { CloseIcon } from 'assets/icons'
import styles from './styles.module.scss'
import { useWindowZoom } from 'hooks/useWindow'

export const ProductHeader = ({
    onClose,
}) => {
    const zoom = useWindowZoom()

    return (
        <div className={styles.header} style={{ zoom }} onClick={e => e.stopPropagation()}>
            <ShouldRender shouldRender={!!onClose}>
                <div className={styles.productModal}>
                    <div
                        onClick={e => {
                            if (typeof onClose === 'function') {
                                onClose(e)
                            }
                        }}
                    >
                        <CloseIcon />
                    </div>
                </div>
            </ShouldRender>
        </div>
    )
}
