import { useFetch } from 'libs'

/**
 * 将产品添加到 lookbook 中
 */
export function useCreateProductLookbooks() {
    const { patchFn } = useFetch()

    const createProductLookbooks = async (productId: string, lookbookIds: string[]) => {
        await patchFn('/addLookbooksProduct', {
            query: { product_id: productId },
            data: lookbookIds,
        })
    }

    return {
        createProductLookbooks,
    }
}

/**
 * 删除产品的 lookbooks
 */
export function useRemoveProductLookbooks() {
    const { patchFn } = useFetch()

    const removeProductLookbooks = async (productId: string, lookbooks: string[]) => {
        await patchFn('/removeLookbooksProduct', {
            query: { product_id: productId },
            data: lookbooks,
        })
    }

    return {
        removeProductLookbooks,
    }
}
