import React from 'react'
import { LandingWrapProps } from './types'
import { Title } from '../../typography/Title'
import styles from './styles.module.scss'

export const LandingWrap = ({ children, title }: LandingWrapProps) =>
    <div className={styles['sign-in']}>
        <div className={styles.image} />

        <div className={styles['sign-in-section']}>
            <div className={styles['sign-in-form']}>
                <Title xxxl>{title}</Title>
                <div className={styles.tips}><span>Use Google Chrome</span></div>
                {children}
            </div>
        </div>
    </div>

