import { useEffect, useState } from 'react'
import { CATEGORY_SPLIT_PIE_GRAPH_NAME, COLOR_SPLIT_PIE_GRAPH_NAME, MATERIAL_SPLIT_PIE_GRAPH_NAME } from 'configs'
import { argFnColorMaterialCategoryAssortmentComparison } from 'graphql/nornaapi'
import { useExcludeProductsComparisonQuery, useManualLoading } from 'hooks'
import { useValidCategories } from 'hooks/useValidCategory'
import { useFetch } from 'libs'
import { cloneDeep } from 'lodash'
import { usePageDate } from 'hooks/usePageDate'
import { useFilterCompetitor, useFilterCurrency } from 'hooks/useFilter'
import { useLookbook } from 'hooks/useLookbook'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useModuleData } from 'hooks/useModuleData'
import { isSubsetPayload, UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, UPDATE_NEWCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { isSuperArray } from 'utils/array'
import { isCompetitorGroupChanged } from 'utils/group'
import { useCategoryOptions, useCompetitorOptions } from 'hooks/useOptions'
import { getTab } from 'utils/splitPageUtils'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { isColorSplitPage } from 'global/Routes'
import { dateRangeUtils } from 'norna-uikit'

export const useSplitPieGraphData = ({
    moduleName,
    materialPercent,
    isLimitMaterial,
}: {
    moduleName: string;
    materialPercent?: any;
    isLimitMaterial?: boolean;
}) => {
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCurrency ] = useFilterCurrency()
    const categories = useValidCategories()
    const { pageDate, comparisonPageDate } = usePageDate()
    const { manualLoading, showManualLoading } = useManualLoading()
    const { checked: checkedLookbooks, isLookbook } = useLookbook()
    const { categoryOptions } = useCategoryOptions({ excludeTotal: true })
    const { competitorOptions } = useCompetitorOptions()

    const [ moduleData, setModuleData ] = useModuleData(moduleName)
    const {
        fetchPayload = {},
        apiData = [],
        oldCompetitorValue = [],
        newCompetitorValue = [],
    } = moduleData

    const { postFn: fetch, data, loading } = useFetch()
    const [ filteredApiData, setFilteredApiData ] = useState<any>({})

    const handleCacheData = () => {
        let newApiData = cloneDeep(apiData)
        if (!isLookbook) {
            newApiData = newApiData.filter(item => newCompetitorValue.includes(item.seller.vendor))
        }
        setFilteredApiData(newApiData)
    }

    const fetchData = () => {
        if (!competitorOptions?.length) {
            return
        }

        // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/833#note_1308276089
        if (!isLookbook && isColorSplitPage() && !categories.length) {
            return
        }

        const query = cloneDeep(comparisonQuery)

        query.sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        query.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
        query.groups = getGroupsPayload({ competitorSellers: [ ...filterCompetitor ] })
        query.tab = getTab(moduleName)
        
        // color split
        if (moduleName === COLOR_SPLIT_PIE_GRAPH_NAME) {
            query.categoryParsed = cloneDeep(categories)
        }

        if (moduleName === CATEGORY_SPLIT_PIE_GRAPH_NAME) {
            query.categoryParsed = categoryOptions.map(item => item.key)
        }

        const payload: any = argFnColorMaterialCategoryAssortmentComparison(query, dateRangeUtils.from(pageDate), filterCurrency, checkedLookbooks, comparisonPageDate)
        // material split
        if (moduleName === MATERIAL_SPLIT_PIE_GRAPH_NAME) {
            payload.query.threshold = isLimitMaterial ? materialPercent : undefined
            payload.query.material_average_distribution = !isLimitMaterial
        }

        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload }) && apiData.length) {
            showManualLoading()
            return
        }

        setModuleData({ 
            type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, 
            payload: cloneDeep(payload), 
        })
        
        fetch(payload.url, payload)
    }

    /**
     * 其它变量变化触发发送请求
     */
    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, pageDate, comparisonPageDate, checkedLookbooks, competitorOptions ])

    /**
     * Material split 页面内 material 数值改变时触发
     */
    useEffect(() => {
        if (!materialPercent) return
        fetchData()
        // eslint-disable-next-line
    }, [materialPercent, isLimitMaterial])

    /**
     * Filter > Competitor 变化触发发送请求
     */
    useDeepCompareEffect(() => {
        if (!newCompetitorValue.length) return

        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleCacheData()
            return
        }

        fetchData()
    }, [ newCompetitorValue ])

    /**
     * 更新 newCompetitorValue
     */
    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: { competitorValue: filterCompetitor.map(item => item.vendor) },
        })
    }, [ filterCompetitor, [] ])

    /**
     * 接口返回的数据保存一份到内存中
     */
    useDeepCompareEffect(() => {
        if (!data) return
        setModuleData({ apiData: [ ...data ] })
        setFilteredApiData([ ...data ])
    }, [ data, [] ])

    return {
        fetchData,
        setData: setFilteredApiData,
        data: filteredApiData,
        loading: loading || manualLoading,
    }
}
