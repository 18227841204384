import React, { FC, memo } from 'react'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit';

interface CountProps {
  type: 'in' | 'out';
  quantity: number;
}

const Count: FC<CountProps> = ({
  type = 'in',
  quantity = 0,
}) => {
  const colorObj = {
    in: '#74B6B6',
    out: '#EC7765',
  }

  return (
      <div className={styles.count}>
          <div 
              className={styles.dot} 
              style={{ backgroundColor: colorObj[type] }}
          />
          <div className={styles.text}>
              Option {type}.
          </div>
          <div 
              className={styles.quantity} 
              style={{ color: colorObj[type] }}
          >
            {numberUtils.formatNumberByComma(quantity)}
          </div>
      </div>
  )
}

export default memo(Count)
