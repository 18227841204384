import React from 'react'
import { getFractionDigits } from 'utils'
import { LineChart } from '../LineChart'
import { Title } from '../Title'
import { numberUtils } from 'norna-uikit'

export const PriceLineChart = ({
    dataSource = [],
    title,
}: {
    dataSource: any[];
    title?: string;
}) => {
    return (
        <>
            <Title>{title || 'Sales price history'}</Title>
            <LineChart 
                label="Prices"
                chartData={dataSource || []}
                customTooltipLabel={item => `Prices: ${numberUtils.formatNumberByComma(Number(item.yLabel).toFixed(getFractionDigits()))}`}
            />
        </>
    )
}
