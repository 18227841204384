import React, { useLayoutEffect, useRef } from 'react'
import { CloseOutlined } from 'druikit';
import styles from './styles.module.scss'
import { useModalExportDisabled, useModalRequests } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData';
import { exportModalSelector } from 'pages/DynamicDashboard/utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { headerHeight } from 'configs/css';
import MetricModalContent from './MetricModalContent';

export const MetricModal = ({
    kpi,
    onClose,
}: {
    kpi?: string;
    onClose?: () => void;
}) => {
    const [ , setModalExportDisabled ] = useModalExportDisabled()
    const [ modalRequests ] = useModalRequests()

    useDeepCompareEffect(() => {
        if (Object.values(modalRequests || {}).every(item => item === false)) {
            setModalExportDisabled(false)
        } else {
            setModalExportDisabled(true)
        }
    }, [ modalRequests, {} ])

    const contentRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)
    const leftRef = useRef<HTMLDivElement>(null)
    const bottomRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const drag = contentRef.current
        const headerEl = headerRef.current
        const leftEl = leftRef.current
        const bottomEl = bottomRef.current
        if (!drag || !headerEl || !leftEl || !bottomEl) return

        const handleMousedown = (event) => {
			const x = event.clientX - drag.offsetLeft
			const y = event.clientY - drag.offsetTop
			document.onmousemove = function(event) {
				event.preventDefault()
				let moveX = event.clientX - x;
				let moveY = event.clientY - y;
               
				drag.style.left = moveX + 'px'
                drag.style.top = moveY + 'px'
			}
			document.onmouseup = function(event) {
				// 多次点击会注册多次事件造成内存泄漏
				document.onmousemove = null;
				document.onmouseup = null;
			}
        }

        headerEl.addEventListener('mousedown', handleMousedown)
        leftEl.addEventListener('mousedown', handleMousedown)
        bottomEl.addEventListener('mousedown', handleMousedown)

        return () => {
            if (!drag || !headerEl || !leftEl || !bottomEl) return
            headerEl.removeEventListener('mousedown', handleMousedown)
            leftEl.removeEventListener('mousedown', handleMousedown)
            bottomEl.removeEventListener('mousedown', handleMousedown)
        }
    }, [])

    if (!kpi) return null

    return (
        <div 
            className={styles.wrapper} 
            style={{ top: headerHeight + 5 }} 
            ref={contentRef}
        >
            <div className={styles.mask}></div>
            <div className={styles.header} ref={headerRef}>
                <div className={styles.iconWrapper} onClick={onClose}>
                    <CloseOutlined />
                </div>
            </div>
            <div className={styles.left} ref={leftRef} />
            <div className={styles.bottom} ref={bottomRef} />
            <div 
                className={styles.body}
                id={exportModalSelector}
                onMouseDown={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <MetricModalContent 
                    kpi={kpi}
                    style={{ minWidth: 1210 }}
                />
            </div>
        </div>
    )
}
