import React from 'react'
import styles from './styles.module.scss'
import { CloseProps } from './types'
import classNames from 'classnames'

export const CloseIcon = ({
    className,
    onClick = () => {},
}: CloseProps) =>
    <svg
        className={classNames(styles['close-icon'], className)}
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => onClick?.()}
        viewBox="0 0 24 24"
    >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </svg>

