import React, { memo } from 'react'
import { Dropdown, DropdownOptionProps } from 'componentsv2/form-elements/Dropdown'
import { useCurrencyOptions } from 'hooks/useOptions'
import { useFilterCurrency } from 'hooks/useFilter'
import { loadingBar } from 'hooks/useLoadingBar'
import { CurrencyDropdownProps } from './types'

export const CurrencyDropdown = memo(({
    value,
    onChange,
    left,
    right,
    style,
    label='Currency',
    className,
    enableFilter = false,
    enableLoadingBar = false,
    disabled = false,
}: CurrencyDropdownProps) => {
    const [ filterCurrency, setFilterCurrency ] = useFilterCurrency()
    const { currencyOptions = [], defaultCurrencyValue } = useCurrencyOptions()

    return (
        <Dropdown
            style={style}
            label={label}
            className={className}
            left={left}
            right={right}
            options={currencyOptions}
            title="Currency"
            placeholder="Currency"
            disabled={disabled}
            value={value || filterCurrency || defaultCurrencyValue}
            onChange={value => {
                if (enableFilter) setFilterCurrency(value as string)
                const selectedOption = currencyOptions.find(item => item.key === value) as DropdownOptionProps
                onChange?.(value as string, selectedOption, currencyOptions)
                if (enableLoadingBar) loadingBar.restart()
            }}
        />
    )
})

CurrencyDropdown.displayName = 'CurrencyDropdown'
