/**
 * /account 接口返回的 fe_config 字段
 */

import { storage } from './storage'

/**
 * 是否显示 Filter > Children size filter 菜单项
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336483137
 */
export function enableFilterSize() {
    return storage.getFeConfig()?.children_size_filters_enabled || false
} 

/**
 * 是否显示 Filter > Tax 菜单项
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336495887
 */
export function enableFilterTax() {
    return storage.getFeConfig()?.filter_tax_enabled || false
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1339163945
 */
export function enableFilterPack() {
    return storage.getFeConfig()?.pack_filter_enabled || false
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336478698
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1338309192
 */
export function getPropertyBlacklist() {
    const feConfig = storage.getFeConfig()
    const blackList: string[] = []
    blackList.push(...feConfig?.property_blacklist || [])

    if (!feConfig?.property_trouser_fit_enable) {
        blackList.push('Trouser fit')
    }

    return blackList.filter(b => b)
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336481675
 */
export function getPropertyAlias(property: string) {
    const propertyAlias = storage.getFeConfig()?.property_name_aliases || {}
    return propertyAlias[property] || property
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336486089
 */
export function getDisabledCategoryListForSizeFilter(): string[] {
    return storage.getFeConfig()?.hidden_categories_on_selected_children_size_filters || []
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336487650
 */
export function getDisabledCategoryListForColorSplit() {
    return storage.getFeConfig().hidden_categories_on_selected_color || []
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336494281
 */
export function getOriginalPicScaleVendorNameList() {
    return storage.getFeConfig()?.original_picture_scale || []
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336495063
 */
export function isSizePriceProductCard() {
    return storage.getFeConfig()?.size_prices_product_card || false
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336498757
 */
export function isOnlyPlatformNameOnSearchOptions() {
    return storage.getFeConfig()?.only_platform_name_on_search_options || false
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336499605
 */
export function isPriceWithTwoDecimal() {
    return storage.getFeConfig()?.price_with_two_decimal_places || false
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336502053
 */
export function getRegionAlias(region: string) {
    return storage.getFeConfig()?.region_aliases?.[region] || region
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1338307651
 */
export function isFirstDashboardTableAsCategoryViewpoint() {
    return storage.getFeConfig()?.first_dashboard_table_as_category_viewpoint || false
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336491601
 */
export function getGenderChildrenOptions() {
    return storage.getFeConfig()?.gender_size_filters_options || []
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336489953
 */
export function isDashboardExcludeSelectedVendors() {
    return storage.getFeConfig()?.dashboard_disselected_selected_vendors || false
}

/**
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/893#note_1336477315
 */
export function getMaxCategoryCount() {
    return storage.getFeConfig()?.max_category_count || 9999
}

/**
 * vendorList 控制是否显示 size product card
 */
export function getOnlyShowSizeProductsForCompetitors() {
    return storage.getFeConfig()?.only_show_size_products_for_competitors || []
}
