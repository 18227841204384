/**
* @file    DailyProductInOutProducts
* @date   2022-01-28
* @author byte_su@163.com
*/

import { ProductsModal } from 'components/ProductsModalV2/ProductsModal'
import React, { useCallback, useState } from 'react'
import { Switch } from 'componentsv2/form-elements/Switch'
import useDialogTitle from 'hooks/useDialogTitle'
import { OPTIONS_IN_NAME, OPTIONS_OUT_NAME } from 'consts'
import { getVendorNameByCode } from 'utils'
import styles from './styles.module.scss'

export enum ProductType {
    in = 'Options in',
    out = 'Options out',
}

export const DailyProductInOutProducts = ({
    productInUrls,
    currency,
    closeFn,
    productModalDate,
    clickVendor,
    productOutUrls, 
}) => {
    const { fnUseAppendDialogTitlePriceFilter } = useDialogTitle()

    const [ productType, setProductType ] = useState(productInUrls.length > 0 ? ProductType.in : ProductType.out)

    const onChangeProductType = useCallback(checked => {
        if (checked) {
            setProductType(ProductType.out)
        } else {
            setProductType(ProductType.in)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const typeLabel  = productType === ProductType.in ? 'in' : 'out'

    return (
        <ProductsModal
            productInOutUrls={{
                productInUrls,
                productOutUrls,
            }}
            currency={currency}
            productType={typeLabel}
            onClose={closeFn}
            headerLeading={productType === ProductType.in ? productInUrls.length : productOutUrls.length}
            headerTitle={(
                <div className={styles.productModalTitle}>
                    <div className={styles.productTitle}>
                        {fnUseAppendDialogTitlePriceFilter(`${getVendorNameByCode(clickVendor)}, ${productType === ProductType.in ? OPTIONS_IN_NAME : OPTIONS_OUT_NAME}`)}.
                    </div>
                </div>
            )}
            headerDate={productModalDate}
            headerTrailing={
                <Switch
                    right={10}
                    leftLabel="In"
                    rightLabel="Out"
                    value={productType === ProductType.out}
                    onChange={onChangeProductType}
                    disabled={productInUrls.length === 0 || productOutUrls.length === 0}
                />
            }
        />
    )
}

DailyProductInOutProducts.displayName = 'DailyProductInOutProducts'
