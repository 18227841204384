import React, { FC, memo } from 'react'
import classnames from 'classnames'
import { calcUnit } from './utils'
import { TableProps, ColumnProps } from './types'
import styles from './styles.module.scss'
import { LockIcon } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'

export const Table: FC<TableProps> = memo(({
    dataSource = [],
    columns = [],
    rowClassName,
    style = {},
}) => {
    /**
     * name 可能是嵌套字段，如：name = 'a.b.c'
     * row = { a: { b: { c: 'abc' } } }
     * getValueByName({ row, name })        // 'abc'
     */
    const getValueByName = ({ row, name }: { row: Record<string, any>; name?: string }): string | undefined => {
        if (!name) return
        const nameArr = name.split('.')
        
        let value: any = { ...row }
        // eslint-disable-next-line
        for (let i = 0; i < nameArr.length; i++) {
            if (Object.prototype.toString.call(value) !== '[object Object]') {
                break
            }
            value = value[nameArr[i]]
        }

        return value || ''
    }

    const geneColumnsElement = ({ columns, rowData, rowIndex }: { columns: ColumnProps[], rowData: any, rowIndex: number }) => columns.map((column, index2) => {
            if (Array.isArray(column.children) && column.children.length > 0) {
                return geneColumnsElement({ columns: column.children, rowData, rowIndex })
            }

            const className = classnames([
                styles.td,
                styles[column.align || 'left'],
                column.className,
            ])
            const value = getValueByName({ row: rowData, name: column.name })
            const key = `${column.name || ''}-${column.title || ''}-${index2}`
            if (typeof column?.render === 'function') {
                if (rowData?.isLock && column?.name !== 'vendor') {
                    return (
                        <div key={key} className={className} style={{ width: calcUnit(column.width), ...column.style }}>
                            <LockIcon size={16} />
                        </div>
                    )
                }
                return (
                    <div key={key} className={className} style={{ width: calcUnit(column.width), ...column.style }}>
                        {column?.render?.(value, rowData, rowIndex)}
                    </div>
                )
            }
            return (
                <div key={key} className={className} style={{ width: calcUnit(column.width), ...column.style }}>
                    {value}
                </div>
            )

        }).flat(10)

    return (
        <div className={styles.tbody} style={style}>
            {
                dataSource.map((row, index) => (
                    <div key={row.vendor} className={classnames([ styles.tr, rowClassName ])}>
                        { geneColumnsElement({ columns, rowData: row, rowIndex: index }) }
                    </div>
                ))
            }
        </div>
    )
})

