export interface ProductCardProps {
    nornaid: string;
    toCurrency?: string;
    closeProductDetails?: Function;
    enabledQa?: boolean; // enable qa 
    qaPage?: boolean;
}

export enum URL_STATE {
    valid = 'valid',
    invalid = 'invalid',
    interpolated = 'interpolated',
}

// 页面上展示的数据结构
export interface ProductVo {
    nornaid: string;
    images: string[];               // 产品图片
    title: string;                  // 产品标题
    url: string;                    // 产品链接，去除 nornaRegionInfo 参数
    urlState: URL_STATE;
    originalUrl: string;            // 原始产品链接，带 nornaRegionInfo 参数
    currentPrice: CurrentPrice;     // 当前价格
    currency: string;               // 币种

    // Product Details
    targetGroup: string;            // Gender
    materialObj: Record<string, string>;       // Material
    propertyList: { key: string, value: string[] }[];       // 其它 Property
    mainCategory: string;
    subCategory: string;
    nornaCategory: string;
    nornaCategoryTypeObj: Record<string, string>;
    nornaSize: string[];
    sizeList: string[];
    colorList: ColorRecord[];

    firstObservedDate: string;        // 对应 launch_date 字段，first observed e.g "2022-02-24"
    firstDiscountDate: string;        // 对应 first_discount_date 字段，first discounted e.g "2022-03-09"
    lastRelaunchDate: string;
    firstPrice: CurrentPrice;  
    highestPrice: CurrentPrice;   
    fullPrice: CurrentPrice;    
    introductionPrice: CurrentPrice;
    priceHistoryList: LineChartPoint[];
    fullPriceHistoryList: LineChartPoint[];
    discountHistoryList: LineChartPoint[];

    discountDepth: string;            // 对应 average_discount_depth 字段，discount depth life cycle e.g 0.026677782409337204
    nonzeroDiscountDepth: string;     
    averagePrice: string;             // 对应 average_price 字段，lifecycle average price e.g 23.349999999999998
    daysObserved: string;             // day observed，最近一天有产品的日期减去 first observed 的日期 

    vendor: string;

    availableHarmonizingSizeGraph?: any;
    totalHarmonizingSizes?: string[];
    allowHarmonizingSize?: boolean;
}

export interface CurrentPrice {
    discountedPrice: string;
    originalPrice: string;
}

export interface ColorRecord {
    nornaid: string;
    isSelected: boolean;
    sortOrder: number;
    colors: {
        id: number;
        color: string;
        v: number;
        nornaid: string;
    }[];
}

export interface ProductObj {
    [nornaid: string]: ProductDto;
}

// 接口返回的数据结构
export interface ProductDto {
    nornaid: string;
    title: string;                  // 产品标题，e.g "Blooming nights pyjama"
    url: string;                    // 产品链接，e.g "https://www.calida.com/en-DE/p/blooming-nights-pyjama-p-41593-652/?nornaRegionInfo=Germany"
    brand: string;
    currency: string;               // e.g 接口查询时传递的币种，如果和原始币种不一致，需要做汇率转换 "EUR"
    original_currency: string;      // e.g 该产品原始币种 "EUR"

    average_discount_depth: number;     // discount depth life cycle e.g 0.026677782409337204
    average_nonzero_discount_depth: number;
    discount_depths: {                  // advertised dicount history
        [date: string]: number;
    };
    average_price: number;              // lifecycle average price e.g 23.349999999999998
    first_discount_date: string;        // first discounted e.g "2022-03-09"
    launch_date: string;                // first observed e.g "2022-02-24"
    latest_update: string;              // e.g "2022-03-09 02:20:22.042328+00:00"
    last_launch_date: string;           // last relaunch date e.g "2022-06-03"

    target_groups: string[];        // e.g ['Women']
    
    available_size: string[];       // e.g ['', 'L = 48/50', 'M = 44/46', 'S = 40/42', 'XS = 36/38']
    categories: {
        [key: string]: any[];
    };
    images: string[];
    materials: {
        total_percentage: number;
        status: string;
        material_composition: {
            [key: string]: number;
        }
    };
    norna_category: string;     // e.g "Nightwear"
    norna_size: string[];
    peer_products: PeerProduct;
    price_info: PriceInfo;
    max_price: {
        actual_price?: number;
        original_price?: number;
    };
    properties: {
        [property: string]: string[];
    };
    propertyList?: {
        key: string;
        value: string[];
    }[];
    raw_colors: any[];
    seller: {
        region: string;     // e.g "Germany"
        vendor: string;     // e.g "calida"
    };
    sequence: number[];
    unavailable_size: string[];
    interpolate: boolean;

    allow_harmonizing_size?: boolean;
    available_harmonizing_size_graph?: any;
    total_harmonizing_sizes?: string[];
}

export interface PriceInfo {
    [date: string]: {
        discounted_price: number | null;
        original_price: number;
    };
}

export interface PeerProduct {
    [nornaid: string]: {
        quantity: number;
        rgb_color: [number, number, number];
    }[];
}

export interface DiscountHistory {
    [date: string]: number;
}

export interface LineChartPoint {
    x: string;
    y: number;
}
