import { getQaData } from 'features/filters/qaDataSlice'
import { isSearchForOptionsPage } from 'global/Routes'
import { argFnGetProductAvailableSizesDetails } from 'graphql/nornaapi'
import { useFilterCountry, useFilterCurrency, useFilterTax } from 'hooks/useFilter'
import { useFetch } from 'libs'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getTaxPayload } from 'utils/queryPayload'

export const useProductSizesDetailsData = ({
    nornaid,
    qaAnnotation = false,
    metaName,
    toCurrency,
}: {
    nornaid: string;
    qaAnnotation?: boolean;
    metaName?: string;
    toCurrency?: string;
}) => {
    const { postFn: fetch, data, error, loading } = useFetch()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterCountry ] = useFilterCountry()
    const [ filterTax ] = useFilterTax()
    const qaData = useSelector(getQaData)

    useEffect(() => {
        if (!nornaid) return

        const payload = argFnGetProductAvailableSizesDetails({
            nornaid,
            toCurrency: toCurrency || filterCurrency,
            qaAnnotation,
            metaName,
            taxRate: getTaxPayload({ filterTax, region: filterCountry }),
        })
        
        payload.query.qa_page = qaData.qaPage && isSearchForOptionsPage()

        fetch(payload.url, payload)
        // eslint-disable-next-line
    }, [ filterCurrency, filterCountry, toCurrency, nornaid, qaData.qaPage, qaAnnotation, metaName ])

    return {
        data,
        fetch,
        error,
        loading,
    }
}
