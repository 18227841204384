import axios, { AxiosRequestConfig } from 'axios'
import { DateFormats } from 'consts'
import { addDays, format } from 'date-fns'
import { CONST_VARS } from 'libs/const'
import { downloadFile } from 'utils'
import AuthToken from 'utils/AuthToken'

export const exportLookbookFn = (list: Array<any>) => {
  if (list.length === 0) return
  const regions = Object.keys(list[0]).filter(item => item !== 'title')
  return downloadFile(`Lookbook_Products_${format(addDays(new Date(), 0), DateFormats.DEFAULT.replace(/-/ig, '_'))}.csv`,
    'Title,' + regions.join(',') + '\n' +
    list.map((item: any) => item.title + ',' + regions.map(region => {
      const currency = item[region]?.currency ?? 'N/A'
      if (currency === 'N/A') return currency

      return `${item[region]?.actual_price || 0} (${currency})`
    }).join(',')).join('\n')
    , true)
}
/**
 * 导出文件
 */
 export function downloadFileFn(url:string, filename:string, cb:Function) {
  if (typeof window === 'undefined') {
      throw new Error('请在浏览器环境中使用该方法')
  }

  const tk = AuthToken.getAccessToken()
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.setRequestHeader('authorization','Bearer ' + tk)
  xhr.responseType = 'blob'
  xhr.onload = function () {
      if (xhr.status === 200) {
          const link = document.createElement('a')
          const body:any = document.querySelector('body')

          link.href = window.URL.createObjectURL(xhr.response)
          link.download = filename

          // fix Firefox
          link.style.display = 'none'
          body.appendChild(link)

          link.click()
          body.removeChild(link)

          window.URL.revokeObjectURL(link.href)
          cb?.()
      }
  }
  xhr.send()
}

/**
 * 导出文件
 */
export async function downloadFileByAxios({
  filename,
  url,
  method = 'POST',
  params = {},
  data = {},
  payload = {},    // 查询参数负载
}: AxiosRequestConfig & {filename: string; payload?: any;}) {
  const tk = AuthToken.getAccessToken()
  // 发请求
  const result = await axios.request({
    url: CONST_VARS.API_URL + '/' + (url || payload?.url),
    method,
    params: { ...payload?.query || {}, ...params, export: true },     // query 传参 xxxx?a=1
    data: { ...payload?.data || {}, ...data },       // body 传参
    headers: { 
      authorization: 'Bearer ' + tk,
    },
    responseType: 'blob',
  })

  // 下载文件
  const link = document.createElement('a')
  const body:any = document.querySelector('body')

  link.href = window.URL.createObjectURL(result.data)
  link.download = filename

  // fix Firefox
  link.style.display = 'none'
  body.appendChild(link)

  link.click()
  body.removeChild(link)

  window.URL.revokeObjectURL(link.href)
}
