import React, { createContext, CSSProperties } from 'react'

export interface ActionBoxConfigState {
  children?: any,
  bodyComponent?: React.ReactNode,
  close?: any,
  enter?: any,
  click?: any,
  w?: number,
  h?: number,
  cancelClose?: any,
  timerClose?: any,
  target?: any
  elRef?: any,
  type?: string,
  title?: string,
  id?: number | string,
  left: number,
  top: number,
  className?: string;
  style?: CSSProperties;
}
;
export const ActionBoxContext = createContext({
  open: (data: ActionBoxConfigState) => {
  },
  close: (index: number) => {
  },
  /**
   * 关闭所有弹框
   */
  closeAll: () => {
  },
  cancelClose: (index: number) => {

  },
  timerClose: (index: number, timer?: number) => {

  },
})
ActionBoxContext.displayName = 'ActionBoxContext'
