export function getChromeVersion(): number {
    const ua = navigator.userAgent
    const match = ua.match(/Chrome\/(\d+)/)
  
    if (match && match[1]) {
        return Number(match[1])
    }
  
    return -1
}
