import React, { CSSProperties, ReactNode } from 'react'
import { Tooltip as AntdTooltip } from 'antd'
import { getTooltipText } from 'configs/tooltip';

const Tooltip = ({
    title,
    children,
    placement = 'top',
    mouseLeaveDelay = 0,
    overlayInnerStyle,
}: {
    title?: ReactNode;
    children: ReactNode;
    placement?: 'top' | 'right' | 'bottom' | 'left' | 'bottomRight' | 'bottomLeft';
    mouseLeaveDelay?: number;
    overlayInnerStyle?: CSSProperties;
}) => {
    return (
        <AntdTooltip 
            title={getTooltipText(title)}
            mouseEnterDelay={0}
            mouseLeaveDelay={mouseLeaveDelay}
            placement={placement}
            overlayInnerStyle={overlayInnerStyle}
        >
            <div>{children}</div>
        </AntdTooltip>
    )
}

export default Tooltip

