import { storage } from 'utils/storage'

export const LICENSE_BOGNER = 'bogner'
export const LICENSE_KAPPAHL = 'kappahl'
export const LICENSE_POLARNOPYRET = 'polarnopyret'
export const LICENSE_ZEEMAN = 'zeeman'
export const LICENSE_CANADAGOOSE = 'canadagoose'
export const LICENSE_MARIMEKKO = 'marimekko'
export const LICENSE_OSCARJACOBSON = 'oscarjacobson'
export const LICENSE_A = 'a'
export const LICENSE_CARINWESTER = 'carinwester'
export const LICENSE_WERA = 'wera'
export const LICENSE_CW = 'cw'
export const LICENSE_HUNKYDORY = 'hunkydory'
export const LICENSE_ODDMOLLY = 'oddmolly'
export const LICENSE_BIMBAYLOLA = 'bimbaylola'
export const LICENSE_ZARA = 'zara'
export const LICENSE_TIGEROFSWEDEN = 'tigerofsweden'
export const LICENSE_MARIMEKKO_DENIM = 'levis'
export const LICENSE_TOMMYHILFIGER = 'tommyhilfiger'
export const LICENSE_CALVINKLEIN = 'calvinklein'
export const LICENSE_JUST_BRANDS = 'pme-legend'   // Just brands

export const isZeemanLicense = () => {
    return storage.getCustomerVendor() === LICENSE_ZEEMAN
}

export const isJustBrandsLicense = () => {
    return storage.getCustomerVendor() === LICENSE_JUST_BRANDS
}
