import React, { FC, useState, useEffect, useLayoutEffect } from 'react'
import classnames from 'classnames'
import { Spin } from 'componentsv2/Spin'
import { SizedBox } from 'componentsv2/SizedBox'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { useFetchProductDetail } from 'hooks'
import { CloseIcon } from 'assets/icons'
import { lsGet } from 'utils/ls'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { useSelector } from 'react-redux'
import { LICENSE_BIMBA, PRICE_FILTER, PRODUCT_CARD_COLORS, PRODUCT_CARD_TYPE } from 'consts'
import { Collapse } from 'componentsv2/business/ProductCard/components/Collapse'
import { Descriptions } from 'componentsv2/business/ProductCard/components/Descriptions'
import { LineChart } from 'componentsv2/business/ProductCard/components/LineChart'
import { ProductImage } from 'componentsv2/business/ProductCard/components/ProductImage'
import { ProductTitle } from 'componentsv2/business/ProductCard/components/ProductTitle'
import { ProductPrice } from 'componentsv2/business/ProductCard/components/ProductPrice'
import { ProductDate } from 'componentsv2/business/ProductCard/components/ProductDate'
import { handleData } from 'componentsv2/business/ProductCard/utils'
import { ProductVo } from 'componentsv2/business/ProductCard/types'
import { getFractionDigits } from 'utils'
import { regionShortMappingFn } from 'utils'
import { storage } from 'utils/storage'
import { useWindowZoom } from 'hooks/useWindow'
import { formatPublishPrice } from './utils'
import { ProductCardProps } from './types'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'

export const ProductCard: FC<ProductCardProps> = ({
    nornaid,
    toCurrency,
    closeProductDetails,
    priceDetail = [],
    indexRegion,
}) => {
    const zoom = useWindowZoom()
    const { notShowSubGroupsMap = {} } = useSelector(getCacheData)
    const customerVendor = storage.getCustomerVendor()
    // 最近一天产品有数据的日期
    const latestDate = lsGet('latestDate') || ''

    // 当前显示的产品 ProductVo 页面上展示的产品数据结构
    const [ product, setProduct ] = useState<ProductVo>()

    // 根据 nornaid 查询产品接口
    const { data: productList, loading } = useFetchProductDetail(nornaid, false, '', toCurrency)

    // 数据结构做处理
    useEffect(() => {
        if (!Array.isArray(productList) || productList.length === 0) {
            return
        }
        const newProductList = productList.map(item => {
            Object.keys(item.peer_products).forEach(nornaid => {
                // 如果没有一个颜色 >=0.95 就是 multi
                // 接口直接返回的颜色名称就叫做 'Multi-color'
                const isMulti = Object.values(item.peer_products[nornaid]).every(value => (value as number || 0) < 0.95) || Object.keys(item.peer_products[nornaid]).some(item => item === 'Multi-color')
                if (isMulti) {
                    item.peer_products[nornaid] = [ {
                        rgb_color: 'Multi',
                        quantity: 1,
                    } ]
                } else {
                    item.peer_products[nornaid] = Object.keys(item.peer_products[nornaid]).map(color => {
                        if (color === 'Others') {
                            return {
                                rgb_color: color,
                                quantity: item.peer_products[nornaid][color],
                            }
                        }
                        return {
                            rgb_color: PRODUCT_CARD_COLORS[color],
                            quantity: item.peer_products[nornaid][color],
                        }
                    })
                }
            })
            return item
        })

        const nornaidList = newProductList.map(item => item.nornaid)
        // 接口返回的产品列表转对象数据结构
        const productObj = newProductList.reduce((res, current) => {
            if (current?.peer_products) {
                // delete peerProducts which nornaid could not fetch product data
                Object.keys(current?.peer_products).forEach(nornaid => {
                    if (!nornaidList.includes(nornaid)) {
                        delete current.peer_products[nornaid]
                    }
                })
            }
            res[current.nornaid] = current
            return res
        }, {})
        // 页面展示的产品数据结构
        const productVo = handleData({
            productDto: newProductList[0], latestDate, productObj, notShowSubGroupsMap,
        })
        setProduct(productVo)
    }, [ productList, latestDate, notShowSubGroupsMap ])

    /* ********************************* Flex *********************************** */
    /**
     * 模态框宽度小于 1400px 时, 最外层 div flex 布局 justify-content: flex-start, 这样出现水平滚动条才会从最左边开始
     * 模态框宽度大于 1400px 时, 最外层 div flex 布局 justify-content: center
     */
    const [ justify, setJustify ] = useState('center')
    useLayoutEffect(() => {
        const handleJustify = () => {
            const width = document.body.clientWidth * 0.9
            if (width <= 1400) {
                setJustify('flex-start')
            } else {
                setJustify('center')
            }
        }
        handleJustify()
        window.addEventListener('resize', handleJustify)
        return () => {
            window.removeEventListener('resize', handleJustify)
        }
    }, [])

    return (
        <Spin spinning={loading} className={styles.wrapper} style={{ justifyContent: justify }}>
            <div className={styles.header} style={{ zoom }} onClick={e => e.stopPropagation()}>
                <ShouldRender shouldRender={!!closeProductDetails}>
                    <div className={styles.productModal}>
                        {/* <div title="Open product in new tab">
                            <a href={`/options/${nornaid}`} target="_blank" rel="noopener noreferrer">
                                <LaunchIcon />
                            </a>
                        </div> */}
                        <div
                            onClick={e => {
                                if (typeof closeProductDetails === 'function') {
                                    closeProductDetails(e)
                                }
                            }}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                </ShouldRender>
            </div>
            <div 
                className={classnames([ styles.body, 'norna-container-fixed-width' ])} 
                onClick={e => e.stopPropagation()} 
                data-type={PRODUCT_CARD_TYPE}
            >
                <div style={{ width: '400px' }} className={styles.imageWrapper}>
                    <ShouldRender shouldRender={(product?.images || []).length > 0}>
                        <ProductImage
                            images={product?.images || []}
                            product={product}
                            enabledQa={false}
                        />
                    </ShouldRender>
                </div>

                <SizedBox width={40 * zoom} />

                <div style={{ width: '500px' }}>
                    <div className={styles.moduleTitle}>
                        Product information {indexRegion}
                    </div>

                    <ProductTitle title={product?.title} url={product?.url} vendor={product?.vendor || ''} />

                    <Descriptions.Item label={String(PRICE_FILTER?.['All actual'] || 'SALES PRICE').toUpperCase()} top={30} bottom={26}>
                        <ProductPrice price={product?.currentPrice} currency={product?.currency} />
                    </Descriptions.Item>

                    <ProductDate product={product} />

                    <Collapse title="Price details" bottom={20}>
                        <div className={styles.thead}>
                            <div className={styles.th}>COUNTRY</div>
                            <div className={styles.th}>PRICE</div>
                            <div className={styles.th}>INDEX</div>
                        </div>
                        {
                            priceDetail.map(item => (
                                <div className={styles.tr} key={item.region}>
                                    <div className={styles.td}>{regionShortMappingFn(item.region)}</div>
                                    <div className={styles.td}>{item.price}</div>
                                    <div className={styles.td}>{item.index}</div>
                                </div>
                            ))
                        }
                    </Collapse>

                    <ShouldRender shouldRender={customerVendor === LICENSE_BIMBA}>
                        <Collapse title="Price publishing details" bottom={20}>
                            <div className={styles.thead}>
                                <div className={styles.th}>COUNTRY</div>
                                <div className={styles.th}>FORMAT</div>
                            </div>
                            {
                                priceDetail.map(item => (
                                    <div className={styles.tr} key={item.region}>
                                        <div className={styles.td}>{regionShortMappingFn(item.region)}</div>
                                        <div className={styles.td}>{formatPublishPrice(item.currencyPriceMapper[item.currency], item.region, item.vendor)}</div>
                                    </div>
                                ))
                            }
                        </Collapse>
                    </ShouldRender>
                </div>

                <SizedBox width={60 * zoom} />

                <div style={{ width: '480px' }}>
                    <div className={styles.moduleTitle}>Sales price history {indexRegion}</div>
                    <LineChart
                        label="Prices"
                        chartData={product?.priceHistoryList || []}
                        customTooltipLabel={item => `Prices: ${numberUtils.formatNumberByComma(Number(item.yLabel).toFixed(getFractionDigits()))}`}
                    />
                    <SizedBox height={40} />
                    <div className={styles.moduleTitle}>Full price history {indexRegion}</div>
                    <LineChart
                        label="Prices"
                        chartData={product?.fullPriceHistoryList || []}
                        customTooltipLabel={item => `Prices: ${numberUtils.formatNumberByComma(Number(item.yLabel).toFixed(getFractionDigits()))}`}
                    />
                </div>
            </div>
        </Spin>
    )
}

ProductCard.displayName = 'ProductCard'
