import React from 'react'
import { useDashboardHarmonizingSoldOutIds } from 'hooks/api/useDashboard'
import { formatDateStr2 } from 'utils/dateUtils'
import { usePageDate } from 'hooks/usePageDate'
import { ProductsModal } from 'components/ProductsModalV2'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { TableCell, TableCellWithSymbol } from 'componentsv2/TradingTableV2/TableCell'
import classNames from 'classnames'
import { numberUtils } from 'norna-uikit'

export const TableSizeCell = ({
    record,
    config,
    fieldName,
    metricName,
    metricDisplayName,
    isFirstMetric = false,
    isShadowBg = false,
}: {
    record?: any;
    config?: any;
    fieldName: string;
    metricDisplayName?: string;
    metricName?: '<S' | 'S' | 'M' | 'L' | 'XL' | '>XL';
    isFirstMetric?: boolean;
    isShadowBg?: boolean;
}) => {
    const { pageDate } = usePageDate()

    const { fetchData: fetchProducts } = useDashboardHarmonizingSoldOutIds()
    const dialogRef = React.useRef<DialogRefType>({} as DialogRefType)

    const showProductsModal = async ({ categoryName, vendorCode, vendorName, metricName }) => {
        const ids = await fetchProducts({ metric: metricName, category: categoryName, competitor: vendorCode })
        if (!ids.length) return

        const dialogId = `Options-${vendorCode}-${categoryName}`
        const closeFn = dialogRef.current?.closeDialog
        const title = [ vendorName, categoryName, metricDisplayName, 'Sold out' ].filter(item => item).join(', ')
        dialogRef.current?.openDialog(dialogId, (
            <ProductsModal
                productUrls={ids}
                onClose={() => closeFn(dialogId)}
                headerLeading={numberUtils.formatNumberByComma(ids.length)}
                headerTitle={title}
                headerDate={formatDateStr2(pageDate, true)}
            />
        ))
    }

    const isClickable = metricName && record?.optionsNum

    const onClickCell = async () => {
        if (!isClickable) return
        await showProductsModal({
            categoryName: record.categoryName,
            vendorCode: record.vendorCode,
            vendorName: record.vendorName,
            metricName,
        })
    }

    const value = numberUtils.formatNumber(record?.[fieldName]?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
    const value2 = numberUtils.formatNumber(record?.[fieldName]?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

    return (
        <TableCell 
            width={config.sizeWidth} 
            isDeepBorderRight 
            style={{ paddingLeft: 0 }}
        >
            <div style={{ width: config.sizeWidth, display: 'flex' }}>
                <TableCell
                    isEmptyBorderBottom
                    isShadowBg={isShadowBg}
                    text={String(value)} 
                    record={record} 
                    width={config.sizeValueWidth} 
                    style={{ cursor: isClickable ? 'pointer' : 'auto' }}
                    isClickable={isClickable}
                    className={classNames({ 'cell-mouse-effect-div': isClickable })}
                    onClick={metricName ? onClickCell : undefined}
                    isFirstMetric={isFirstMetric}
                />
                <TableCellWithSymbol 
                    isEmptyBorderBottom 
                    isShadowBg={isShadowBg}
                    isComparisonField 
                    text={value2} 
                    record={record} 
                    width={config.sizeChangeWidth}
                />
            </div>
            <Dialog ref={dialogRef} />
        </TableCell>
    )
}
