import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import { CURRENT_SWITCHED_LICENSE } from 'consts'
import { initLicenseId, lsSetLicenseId } from 'utils/ls'
import App from './App'
import * as serviceWorker from './serviceWorker'

const domNode = document.getElementById('root') as HTMLElement
const root = createRoot(domNode)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

/**
 * page unload event
 */
window.onunload = () => {

    /** **
     * 
     * ## 不同浏览器标签页, 各不同license 互不影响存储设计思路
     * 
     *  1. 内存数据`window[CURRENT_SWITCHED_LICENSE]`存储当前license值
     * 
     *  2. localStorage 存取数据增加`window[CURRENT_SWITCHED_LICENSE]`前缀，避免不同license在localStorage中的冲突
     * 
     *  3. 当用户刷新页面时, 更新当前`CURRENT_SWITCHED_LICENSE`对应的window[CURRENT_SWITCHED_LICENSE]的值
     *  
     * 
     */
    //= =====================================  ssasve licenseId of `tab memory` into localstorage  ========================================
    // save current licenseId from current `tab memory`
    lsSetLicenseId(window[CURRENT_SWITCHED_LICENSE])
}

window.onload = () => {
    //
    initLicenseId()
    
}
