import React, { useState } from 'react'
import AuthToken from 'utils/AuthToken'
import { Message } from 'components/form-elements/Message'
import { LandingWrap } from 'components/layout/LandingWrap'
import { LoadingSpinner } from 'assets/icons/Loading'
import { useAuthenticationDataContext } from 'components/guards/AuthenticationContext'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { useFetchs } from 'libs/hookRequest'
import { CURRENT_SWITCHED_LICENSE, REQUEST_ACCOUNT, REQUEST_LOGIN } from 'consts'
import { useLogin } from 'hooks/useLogin'
import { SignInForm } from './components/SignInForm'
import { useUrlParams } from '../../hooks'
import { useHistory } from 'react-router-dom'

export const SignIn = () => {
  const { postFn, getFn } = useFetchs()
  const { resetPasswordMsg, setUser } = useAuthenticationDataContext()
  const { pathObj, ...queryObj } = useUrlParams()
  const { initNewUser } = useLogin()
  const history = useHistory()

  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState('')

  const onSignIn = async (arg) => {
    setLoading(true)
    try {
      // 请求 token
      let result = await postFn(REQUEST_LOGIN, { data: arg.variables, query: {} })
      if (result[REQUEST_LOGIN]?.error) {
        setLoading(false)
        setError(result[REQUEST_LOGIN]?.error)
        return
      }
      const tokenData = result[REQUEST_LOGIN]?.data || {}
      const accessToken = tokenData.access_token
      AuthToken.setAccessToken(accessToken)
      // 请求 account
      result = await getFn(REQUEST_ACCOUNT,{ selectParentRegion:true })
      if (result[REQUEST_ACCOUNT]?.error) {
        setLoading(false)
        setError(result[REQUEST_ACCOUNT]?.error)
        return
      }
      const accountData = result[REQUEST_ACCOUNT]?.data || {}
      // 初始化新用户
      initNewUser(accessToken, accountData.email, accountData?.company?.id, accountData.id)
      setUser(AuthToken.getUserFromToken())
      // pass by search param
      queryObj[CURRENT_SWITCHED_LICENSE] = accountData.company.id
      history.push('/')
    } catch (e: any) {
      setError(e?.message)
      setLoading(false)
    }
  }

  return (
    <LandingWrap title="SIGN IN">
      {loading && <LoadingSpinner />}

      {/* <div style={{ color: '#ec7765', fontWeight: 'bold' }}>Sorry for the inconvenience! We are currently updating Norna Analytics with the brand new Dynamic Dashboard and during this update Norna Analytics will unfortunately not be available.</div> */}
      <ShouldRender shouldRender={resetPasswordMsg !== ''}>
        <Message message={resetPasswordMsg} success={true} />
      </ShouldRender>
      <SignInForm
        error={error}
        onSignIn={onSignIn}
      />
    </LandingWrap>
  )
}
