import { lsGet, lsRm,  lsSet, ssGet, ssRm, ssSet } from './ls'

class AuthToken {

  static ACCESS_TOKEN_KEY = '67663750-dfe4-4a33-8f33-8cc9e24d0c74'

  static ID_TOKEN_KEY = '52363850-84e4-3f33-2b13-8cc9f2bdbc74'

  static SHOULD_KEEP_USER_SIGNED_KEY = '67463750-dfe2-4a33-8fc3-8139e25d0c74'

  static setEmail(email: string) {
    lsSet('email', email)
  }

  static setAccessToken(token: string) {
    this.setAuthToken(token, this.ACCESS_TOKEN_KEY)
  }

  static getAccessToken() {
    return this.getAuthToken(this.ACCESS_TOKEN_KEY)
  }

  static setIdToken(token: string) {
    this.setAuthToken(token, this.ID_TOKEN_KEY)
  }

  static getIdToken() {
    return this.getAuthToken(this.ID_TOKEN_KEY)
  }

  static setAuthToken(token: string, tokenKey: string) {
    const encryptedToken = window.btoa(token)
    const shouldKeepUserSigned = this.shouldKeepUserSigned()
    if (shouldKeepUserSigned) {
      lsSet(tokenKey, encryptedToken)
    } else {
      ssSet(tokenKey, encryptedToken)
    }
  }

  static getAuthToken(tokenKey: string) {
    let encryptedToken:string
    const shouldKeepUserSigned = this.shouldKeepUserSigned()

    if (shouldKeepUserSigned) {
      encryptedToken = lsGet(tokenKey)
    } else {
      encryptedToken = ssGet(tokenKey)
    }
    
    return encryptedToken ? window.atob(encryptedToken) : null
  }

  static removeAuthTokens() {
    if (this.shouldKeepUserSigned()) {
      lsRm(this.ACCESS_TOKEN_KEY)
      lsRm(this.ID_TOKEN_KEY)
    } else {
      ssRm(this.ACCESS_TOKEN_KEY)
      ssRm(this.ID_TOKEN_KEY)
    }
  }

  static getUserFromToken() {
    const email = lsGet('email')??''
    
    return {
      nickname: email,
      username: email,
      email,
    } as any
  }

  static shouldKeepUserSigned() {
    const shouldKeepUserSigned = lsGet(this.SHOULD_KEEP_USER_SIGNED_KEY)

    if (shouldKeepUserSigned && (shouldKeepUserSigned === 'true' || shouldKeepUserSigned === 'false')) {
      return shouldKeepUserSigned
    }

    return false
  }

  static setShouldKeepUserSigned(shouldKeepUserSigned: boolean) {
    lsSet(this.SHOULD_KEEP_USER_SIGNED_KEY, JSON.stringify(shouldKeepUserSigned))

    if (!shouldKeepUserSigned) {
      lsRm(this.ACCESS_TOKEN_KEY)
    }
  }
}

export default AuthToken
