import { Descriptions, Flex, Table, Tag } from 'antd'
import { DateRangePicker, getDateRangeValueByPeriod } from 'componentsv2/form-elements/DateRangePicker'
import { getMetricOptions } from 'componentsv2/GraphButton/components/GraphModal/utils'
import { TreeDropdown } from 'componentsv2/TreeDropdown'
import { PRICE_FILTER_ARRAY } from 'consts'
import { Button, MultiSelect, Select } from 'druikit'
import { uniq, uniqBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { storage } from 'utils/storage'
import { useFetch } from 'libs'
import { getPriceFilterKeyByValue, getPriceFilterValueByKey } from 'graphql/nornaapi'
import { downloadFileByAxios } from 'export'
import { loadingBar, useLoadingBar } from 'hooks'
import { dateUtils } from 'norna-uikit'
import { sortVendorList } from 'utils/array'

const getPricePerspectiveOptions = () => {
  return PRICE_FILTER_ARRAY.map(item => {
    return {
      value: item.val,
      label: item.label,
    }
  })
}

export default function Page() {
  useLoadingBar()
  
  const licenses = storage.getLicenses()
  const licenseOptions = licenses.filter(item => item.name !== 'All').map(item => ({ value: item.code, label: item.name }))
  const [ licenseValue, setLicenseValue ] = useState([ storage.getCustomerVendor() ])

  const [ countryOptions, setCountryOptions ] = useState<any[]>([])
  const [ countryValue, setCountryValue ] = useState('')
  const [ genderOptions, setGenderOptions ] = useState<any[]>([])
  const [ genderValue, setGenderValue ] = useState<string[]>([])
  const [ availableSellers, setAvailableSellers ] = useState<any[]>([])
  const [ vendorOptions, setVendorOptions ] = useState<any[]>([])
  const [ vendorValue, setVendorValue ] = useState<string[]>([])
  const metricOptions = getMetricOptions()
  const [ metricValue, setMetricValue ] = useState<string[]>([ 'Options' ])
  const pricePerspectiveOptions = getPricePerspectiveOptions()
  const [ pricePerspectiveValue, setPricePerspectiveValue ] = useState(PRICE_FILTER_ARRAY[0]?.val)
  const [ dateRangeValue, setDateRangeValue ] = useState(getDateRangeValueByPeriod('oneWholeWeekAgo'))

  useEffect(() => {
    if (!licenses?.length) return
    const licenseValue = storage.getCustomerVendor()
    const currentLicense = licenses.find(item => item.code === licenseValue)
    if (!currentLicense) return
    const { 
      available_region = [],
      available_target_group = [],
      available_competitor_sellers = [],
    } = currentLicense

    const countryOptions = [ ...available_region, 'All Countries' ].map(item => ({ value: item, label: item }))
    let countryValue = available_region[0]
    const genderOptions = available_target_group.map(item => ({ value: item, label: item }))
    let genderValue = available_target_group.slice(0, 1)
    let vendorOptions = available_competitor_sellers
      .filter(item => item.region === countryValue)
      .map(item => ({ value: item.vendor, label: item.name }))
    let vendorValue = available_competitor_sellers.slice(0, 1).map(item => item.vendor)

    setCountryOptions(countryOptions)
    setCountryValue(countryValue)
    setGenderOptions(genderOptions)
    setGenderValue(genderValue)
    setAvailableSellers(available_competitor_sellers)
    setVendorOptions(vendorOptions)
    setVendorValue(vendorValue)
  }, [])

  const columns = [
    {
      dataIndex: 'customer',
      title: 'License info',
      width: '60%',
      render(_, record) {
        return (
          <Descriptions column={3}>
            <Descriptions.Item label="License">{Array.isArray(record?.query?.customer) ? record?.query?.customer?.join(', ') : record?.query?.customer}</Descriptions.Item>
            <Descriptions.Item label="Country">{record?.query?.region}</Descriptions.Item>
            <Descriptions.Item label="Price perspective">{getPriceFilterKeyByValue(record?.query?.price_perspective)}</Descriptions.Item>
            <Descriptions.Item label="Gender">{record?.query?.target_group?.join(', ')}</Descriptions.Item>
            <Descriptions.Item span={2} label="Date range">{record?.query?.date}</Descriptions.Item>
            <Descriptions.Item span={3} label="Vendor">{record?.query?.competitors?.join(', ')}</Descriptions.Item>
            <Descriptions.Item span={3} label="Metric">{record?.query?.metrics?.join(', ')}</Descriptions.Item>
          </Descriptions>
        )
      },
    },
    {
      dataIndex: 'datetime',
      title: 'Datetime',
      render(_, record) {
        return (
          <Descriptions column={1} layout="vertical">
            <Descriptions.Item label="Create time">
              {dateUtils.format(record?.create_time, 'yyyy-MM-dd HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="Finished time">
              {dateUtils.format(record?.finished_time, 'yyyy-MM-dd HH:mm:ss')}
            </Descriptions.Item>
          </Descriptions>
        )
      },
    },
    {
      dataIndex: 'email',
      title: 'Submitter',
    },
    {
      dataIndex: 'status',
      title: 'Status',
      render(text) {
        if (text === 'done') {
          return <Tag color="green">{text}</Tag>
        }
        if (text === 'failed') {
          return <Tag color="red">{text}</Tag>
        }
        if (text === 'running') {
          return <Tag color="blue">{text}</Tag>
        }
        return <Tag>{text}</Tag>
      },
    },
    {
      dataIndex: 'action',
      title: 'Action',
      render(_, record) {
        return (
          <Flex gap={10}>
            {
              [ 'running', 'pending' ].includes(record.status) && (
                <Button onClick={() => onCancel(record._id)}>Cancel</Button>
              )
            }
            {
              [ 'done' ].includes(record.status) && (
                <Button onClick={() => onDownload(record._id)}>Download</Button>
              )
            }
          </Flex>
        )
      },
    },
  ]

  const { patchFn: cancelTask } = useFetch()

  const onCancel = async (taskId) => {
    await cancelTask(`/schedular/tasks/${taskId}`, {})
    await fetchSchedularTasks()
  }

  const onDownload = async (taskId) => {
    loadingBar.restart()
    await downloadFileByAxios({
      filename: 'Line-graph-report',
      payload: { url: `schedular/result/${taskId}` },
      method: 'GET',
    })
    loadingBar.done()
  }

  const { postFn: submitOrder } = useFetch()

  const onOrder = async () => {
    if (!licenseValue) return

    const payload = {
      url: '/dashboard/async_download_all_graph',
      query: {
        region: countryValue,
        date: dateRangeValue,
      },
      data: {
        customer: licenseValue,
        competitors: vendorValue,
        target_group: genderValue,
        metrics: metricValue,
        price_perspective: getPriceFilterValueByKey(pricePerspectiveValue),
      },
    }

    await submitOrder(payload.url, payload)
    fetchSchedularTasks()
  }

  const { getFn: listTasks, data: dataSource = [], loading } = useFetch()

  const fetchSchedularTasks = async () => {
    listTasks('/schedular/tasks')
  }

  useEffect(() => {
    fetchSchedularTasks()
  }, [])

  const handleVendorByRegion = (countryValue, currentLicenses) => {
    let vendorValue: any[] = []
    if (currentLicenses.length === 1 && countryValue !== 'All Countries') {
      vendorValue = currentLicenses[0].available_competitor_sellers
        .filter(item => item.region === countryValue)
        .map(item => ({ vendor: item.vendor, name: item.name }))
    } else {
      currentLicenses.forEach(li => {
        vendorValue.push(...li.available_competitor_sellers.map(item => ({ vendor: item.vendor, name: item.name })))
      })
      vendorValue = uniqBy(vendorValue, 'vendor')
    }
    if (currentLicenses.length === 1) {
      vendorValue = sortVendorList({ vendorList: vendorValue, vendorField: 'vendor', customerVendor: currentLicenses[0]?.code })
    }
    setVendorOptions(vendorValue.map(item => ({ value: item.vendor, label: item.name })))
    if (currentLicenses.length > 1) {
      setVendorValue([ ...vendorValue?.map(item => item.vendor) ])
    } else {
      setVendorValue([ vendorValue?.[0]?.vendor ])
    }
  }

  return (
    <Flex vertical gap={20}>
      <Flex gap={10} align="end">
        <MultiSelect 
          label="License"
          placeholder="License"
          options={licenseOptions}
          minCount={1}
          value={licenseValue}
          onChange={(value) => {
            const currentLicenses = licenses.filter(item => value.includes(item.code))

            // 处理 region
            let availableRegion: string[] = []
            currentLicenses.forEach(li => {
              availableRegion.push(...li.available_region)
            })
            availableRegion = uniq(availableRegion)
            setCountryOptions([ ...availableRegion, 'All Countries' ].map(item => ({ value: item, label: item })))
            let countryValue = 'All Countries'
            if (value?.length === 1) {
              countryValue = currentLicenses[0]?.available_region?.[0]
            }
            setCountryValue(countryValue)

            // 处理 gender
            let availableTargetGroup: string[] = []
            currentLicenses.forEach(li => {
              availableTargetGroup.push(...li.available_target_group)
            })
            availableTargetGroup = uniq(availableTargetGroup)
            setGenderOptions(availableTargetGroup.map(item => ({ value: item, label: item })))
            setGenderValue(availableTargetGroup)

            // 处理 vendor
            handleVendorByRegion(countryValue, currentLicenses)

            setLicenseValue(value)
          }}
        />
        <Select
          disabled={licenseValue?.length > 1}
          label="Country"
          placeholder="Country"
          options={countryOptions}
          value={countryValue}
          onChange={value => {
            const currentLicenses = licenses.filter(item => licenseValue.includes(item.code))
            handleVendorByRegion(value, currentLicenses)
            setCountryValue(value)
          }}
        />
        <MultiSelect
          disabled={licenseValue?.length > 1}
          label="Vendor"
          placeholder="Vendor"
          options={vendorOptions}
          value={vendorValue}
          onChange={setVendorValue}
          minCount={1}
        />
        <MultiSelect
          disabled={licenseValue?.length > 1}
          key={genderValue.join(',')}
          label="Gender"
          placeholder="Gender"
          options={genderOptions}
          value={genderValue}
          onChange={setGenderValue}
          minCount={1}
        />
        <TreeDropdown
          multiple
          label="Metric"
          placeholder="Metric"
          options={metricOptions}
          value={metricValue}
          onOk={setMetricValue}
        />
        <Select 
          label="Price perspective"
          placeholder="Price perspective"
          options={pricePerspectiveOptions}
          value={pricePerspectiveValue}
          onChange={setPricePerspectiveValue}
        />
        <DateRangePicker
          value={dateRangeValue}
          onChange={setDateRangeValue}
        />
        <Button
          type="danger"
          onClick={onOrder}
        >
          Order
        </Button>
        <Button
          type="danger"
          onClick={() => {
            fetchSchedularTasks()
          }}
        >
          Refresh
        </Button>
      </Flex>
      <Table 
        columns={columns}
        dataSource={dataSource}
        rowKey="_id"
        loading={loading}
      />
    </Flex>
  )
}
