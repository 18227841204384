import React, { CSSProperties, ReactNode, useCallback, useRef } from 'react'
import { Card, Flex, Spin } from 'druikit'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import classNames from 'classnames'
import { LazyBgImg } from 'components'
import { isKeepOriginalAspectRatio } from 'utils/imgUtils'
import styles from './styles.module.scss'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ProductDetailsModal } from 'components/Product/components/ProductDetailsModal'
import { UseDialogType } from 'hooks/useDialog'
import productStyles from 'components/Product/styles.module.scss'
import { handleName } from 'pages/DynamicDashboard/utils'
import { useFilterCurrency } from 'hooks/useFilter'
import { useInView } from 'react-intersection-observer'
import { numberUtils } from 'norna-uikit'
import { ProductPrice } from 'components/Product/components/Price'

export const MetricProductCard = ({
    style,
    className,
    title,
    description,
    loading = false,
    dataSource = [],
    count,
    showMaxText = true,
}: {
    style?: CSSProperties;
    className?: string;
    title?: string | ReactNode;
    description?: string | ReactNode;
    loading?: boolean;
    dataSource: any[];
    count?: number;
    showMaxText?: boolean;
}) => {
    const [ currencyValue ] = useFilterCurrency()

    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    /* ******************************** product detail modal *************************************** */

    // 显示产品详情模态框
    const onShowProductDetailModal = useCallback((e, product) => {
        e.stopPropagation()
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog?.('Product detail', (
            <div className={productStyles.quickView}>
                <ProductDetailsModal
                    nornaid={product.nornaid}
                    closeProductDetails={closeFn}
                    toCurrency={currencyValue}
                    enabledQa={false}
                    vendorCode={product?.seller?.vendor}
                />
            </div>
        ), UseDialogType.PROUDUCT_DETAIL)
    }, [ dialogRef, currencyValue ])

    return (
        <Spin spinning={loading} style={{ marginBottom: 34, ...style }} className={className}>
            <Flex style={{ marginBottom: 10 }}>
                <div className={styles.cardTitle}>
                    {handleName(String(title))?.replace('average ', '')}
                </div>
                {
                    description ? (
                        <InfoBox
                            iconSty={{ margin: '0 10px' }}
                            containerSty={{ left: '-153px' }}
                            title="title"
                            text="tips content"
                            showInfoIcon
                        >
                            <TipsContent sty={{ padding: 0 }}>
                                {description}
                            </TipsContent>
                        </InfoBox>
                    ) : null
                }
            </Flex>
            <Card fringed height={350}>
                <div className={styles.productCardContainer}>
                    <div className={styles.productCardTitle}>
                        <span style={{ color: '#74B6B6' }}>
                            {numberUtils.formatNumber((count || dataSource.length), { isCommaSymbol: true, decimal: 0 })}
                        </span> option{(count || dataSource?.length) ? 's' : ''}
                        { showMaxText && <> (max 20 options shown)</> }
                    </div>
                    <div className={classNames([ styles.productCardContent ])}>
                        {
                            dataSource.map((product: any, index: number) => {
                                return (
                                    <ProductCard
                                        key={product?.nornaid}
                                        index={index}
                                        currencyValue={currencyValue}
                                        product={product}
                                        onClick={e => onShowProductDetailModal(e, product)}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </Card>
            <Dialog ref={dialogRef} />
        </Spin>
    )
}

function ProductCard({
    index,
    currencyValue,
    product,
    onClick,
}: {
    index: number;
    currencyValue?: string;
    product?: any;
    onClick?: any;
}) {
    const { ref, inView } = useInView({
        threshold: 0,
    })

    return (
        <div 
            className={styles.productItem} 
            style={{ width: 'max(calc((100% - 60px) / 6), 110px)' }}
            key={product?.nornaid}
            onClick={onClick}
            ref={ref}
        >
            {
                inView || index < 10 ? (
                    <div className={classNames({
                        [styles.productItemInner]: true,
                        [styles.productFirstItemInner]: index === 0,
                    })}>
                        <div className={styles.imageWrapper}>
                            <LazyBgImg 
                                src={product.images[0]} 
                                cropedImg={!isKeepOriginalAspectRatio(product?.seller?.vendor, product?.seller?.brand)}
                            />
                        </div>
                        <div className={styles.brand}>{product?.brand}</div>
                        <div className={styles.category}>{product?.categories?.[0]}</div>
                        <ProductPrice
                            price={product?.last_price_info?.original_price}
                            discountedPrice={product?.last_price_info?.discounted_price}
                            currency={product.currency}
                            convertedPrice={product.convertedPrice}
                            sizePriceRange={product?.size_price_range}
                            latestSizePrice={product?.latest_size_price}
                            vendorCode={product?.seller?.vendor}
                        />
                    </div>
                ) : null
            }
        </div>
    )
}
