import { useLayoutEffect } from 'react'

/**
 * 多个滚动条联动
 */
export const useScrollBar2 = (selector1, selector2, data) => {
    useLayoutEffect(() => {
        const selector1Wrapper = document.querySelector(selector1)
        const selector2Wrapper = document.querySelector(selector2)
        if (!selector1Wrapper || !selector2Wrapper) return

        /**
         */
        const onSelector1Scroll = (e: any) => {
            if (!selector1Wrapper || !selector2Wrapper) return
            const { scrollLeft } = e.target
            if (typeof scrollLeft !== 'number') return
            selector1Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0))
            selector2Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0))
        }

        /**
         */
         const onSelector2Scroll = (e: any) => {
            if (!selector1Wrapper || !selector2Wrapper) return
            const { scrollLeft } = e.target
            if (typeof scrollLeft !== 'number') return
            selector1Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0))
            selector2Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0))
        }

        function bindEventSelector1() {
            removeEventSelector2()
            selector1Wrapper.addEventListener('scroll', onSelector1Scroll)
        }

        function bindEventSelector2() {
            removeEventSelector1()
            selector2Wrapper.addEventListener('scroll', onSelector2Scroll)
        }

        function removeEventSelector2() {
            selector2Wrapper.removeEventListener('scroll', onSelector2Scroll)
        }

        function removeEventSelector1() {
            selector1Wrapper.removeEventListener('scroll', onSelector1Scroll)
        }

        selector1Wrapper.addEventListener('mouseenter', bindEventSelector1)
        selector2Wrapper.addEventListener('mouseenter', bindEventSelector2)

        function onKeydown(e) {
            // 如果在 ChatBox 里面按下左右方向键，直接返回，保留默认行为
            if (e.target?.attributes?.contenteditable?.value) {
                return
            }
            const gap = 20
            if (e.key === 'ArrowRight') {
                e.preventDefault()
                const { scrollLeft } = selector1Wrapper
                if (typeof scrollLeft !== 'number') return
                selector1Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0)) + gap
                selector2Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0)) + gap
            }
            if (e.key === 'ArrowLeft') {
                e.preventDefault()
                const { scrollLeft } = selector1Wrapper
                if (typeof scrollLeft !== 'number') return
                selector1Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0)) - gap
                selector2Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0)) - gap
            }
        }

        document.documentElement.addEventListener('keydown', onKeydown)
        
        return () => {
            /**
             * 销毁组件时需要移除事件监听操作
             */
            selector1Wrapper.removeEventListener('mouseenter', bindEventSelector1)
            selector1Wrapper.removeEventListener('scroll', onSelector1Scroll)
            selector2Wrapper.removeEventListener('mouseenter', bindEventSelector2)
            selector2Wrapper.removeEventListener('scroll', onSelector2Scroll)
            document.documentElement.removeEventListener('keydown', onKeydown)
        }
        // eslint-disable-next-line
    }, [selector1, selector2, data])
}
