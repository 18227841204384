import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { HorizontalLineProps } from './types'

export const HorizontalLine = ({ smallMargin }: HorizontalLineProps) =>
    <>
        <hr className={classnames(styles['horizontal-line'], smallMargin && styles.smallMargin)} />
        <div className={styles['line-margin']} />
    </>
