import React, { ReactNode, CSSProperties } from 'react'

export const Flex = ({
    style = {},
    className,
    children,
    flexDirection = 'row',
    flexWrap = 'nowrap',
    justifyContent = 'flex-start',
    alignItems = 'stretch',
}: {
    style?: CSSProperties;
    className?: string;
    children: ReactNode;
    flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
    alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
}) => {

    const sty: CSSProperties = {
        ...style,
        flexDirection,
        flexWrap,
        justifyContent,
        alignItems,
        display: 'flex',
    }

    return (
        <div className={className} style={{ ...sty }}>
            {children}
        </div>
    )
}

export const Flex1 = () => {
    return <div style={{ flex: 1 }} />
}
