import React, { FC, CSSProperties, memo } from 'react'
import classnames from 'classnames'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ClockIcon } from 'assets/icons'
import { usePageDate } from 'hooks/usePageDate'
import { formatDateStr2 } from 'utils/dateUtils'
import { isSearchForOptionsPage } from 'global/Routes'
import { ModuleTitleProps } from './types'
import styles from './styles.module.scss'
import { dateRangeUtils } from 'norna-uikit'

/**
 * ## ModuleTitle
 *
 * 模块标题组件。
 *
 * 大多数页面都由若干个模块组成，如：Market dashboard 页面由 Market metrics、Market visuals 两个模块组成
 *
 * 每个模块都有一个模块标题，其结构相同，为避免重复代码，可将其抽出为一个组件使用。
 *
 * ## 结构
 *
 * 其中 title 属性必填，subTitle 和 category 非必填。
 *
 * ```
 * category
 * title   subtitle
 * ```
 *
 * 示例（title、subTitle、category 均有自身的样式，这里未能体现，只能提现组件结构）
 *
 * ```
 * ASSORTMENT
 * Color analytics   2021-09-18_2021-09-18
 * ```
 *
 * ## 示例
 *
 * 示例一
 *
 * ```
 * <ModuleTitle
 *      title="Market visuals"
 * />
 * ```
 *
 * 示例二
 *
 * ```
 * <ModuleTitle
 *      category="ASSORTMENT"
 *      title="Color Visuals"
 * />
 * ```
 *
 * 示例三
 *
 * ```
 * <ModuleTitle
 *      category="ASSORTMENT"
 *      title="Color analytics"
 *      subTitle="2021-09-18_2021-09-18"
 * />
 * ```
 */
export const ModuleTitle: FC<ModuleTitleProps> = memo(({
    type,
    title,              // 标题，必填
    subTitle,
    category,           // 分类，非必填
    left,               // margin-left 值
    right,              // margin-right 值
    bottom,             // margin-bottom
    top,                // margin-top 值
    style = {},         // 样式对象
    className,          // 类名
    showDate = false,
    showComparisonDate = false,
    date = '',
}) => {
    const { pageDate, getDisplayComparisonDate } = usePageDate()

    const defaultStyle: CSSProperties = {}
    if (typeof left !== 'undefined') {
        defaultStyle.marginLeft = left + 'px'
    }
    if (typeof right !== 'undefined') {
        defaultStyle.marginRight = right + 'px'
    }
    if (typeof bottom !== 'undefined') {
        defaultStyle.marginBottom = bottom + 'px'
    }
    if (typeof top !== 'undefined') {
        defaultStyle.marginTop = top + 'px'
    }

    if (typeof type !== 'undefined') {
        if (type === 'Dashboard') {
            category = 'Dashboard'.toUpperCase()
        } else if (type === 'Assortment') {
            category = 'Assortment'.toUpperCase()
        } else if (type === 'Marketing') {
            category = 'Marketing'.toUpperCase()
        } else if (type === 'Pricing') {
            category = 'Pricing'.toUpperCase()
        } else if (type === 'Search') {
            category = 'Search'.toUpperCase()
        } else if (type === 'Visuals') {
            category = 'Visuals'.toUpperCase()
        }
    }

    return (
        <div className={classnames([ styles.moduleTitle, className ])} style={{ ...defaultStyle, ...style }}>
            {category && <div className={styles.category}>{category}</div>}
            <div className={styles.titleWrapper}>
                <div className={styles.title}>{title}</div>
                <ShouldRender shouldRender={Boolean(subTitle)}>
                    {
                        typeof subTitle === 'string' ? (
                            <>
                                <ClockIcon />
                                <div className={styles.subTitle}>{subTitle}</div>
                            </>
                        ) : subTitle
                    }
                </ShouldRender>
            </div>
            {
                showDate ? (
                    <div className={styles.subTitle}>
                        {isSearchForOptionsPage() ? dateRangeUtils.last(pageDate) : formatDateStr2(pageDate)}
                    </div>
                ) : null
            }
            {
                showComparisonDate ? (
                    <div className={styles.subTitle}>
                        {getDisplayComparisonDate()}
                    </div>
                ) : null
            }
            {
                date ? (
                    <div className={styles.subTitle}>
                        {date}
                    </div>
                ) : null
            }
        </div>
    )
})

ModuleTitle.displayName = 'ModuleTitle'
