import React, { useCallback, useEffect, useState } from 'react'
import { WireframeTool } from 'componentsv2/WireframeTool'
import { LazyBgImg } from 'components/Image'
import { IMG_PLACEHOLDER, UNSET } from 'consts'
import { fetchColorSegmentationReportArg, submitColorSegmentationReportArg } from 'graphql/nornaapi/report'
import { useFetch } from 'libs/hookRequest'
import { useReport } from 'hooks/useReport'
import { isKeepOriginalAspectRatio } from 'utils/imgUtils'
import { IMAGE_CLOUD_URL } from 'configs/image'

export interface ImageAnnotationProps {
    imageUrl: string;
    productUrl: string;
    productOriginalUrl: string;
    nornaid: string;
    enabledQa: boolean;
    vendor: string;
    vendorName: string;
    nocache?: boolean;
}

/**
 * image annotation hooks
 * @returns 
 */
export function ImageAnnotation({ imageUrl, enabledQa, productOriginalUrl, productUrl, nornaid, vendor, vendorName, nocache = false }: ImageAnnotationProps): any {
    const { postFn: submitFn } = useFetch()
    const { postFn: getColrAnnotationFn } = useFetch()
    const { snackOpen, setFirstImageFn, deleteImageFn, rotateImageFn } = useReport()

    const [ info, setInfo ] = useState<{ points: Array<[number, number]>, loaded: boolean }>({ loaded: false, points: [] })
    // ===================== report =====================

    const { reportFn, getReportedFn, qaPage } = useReport()

    //= =====================    Fetch Data    =======================
    const fetchFn = useCallback(async () => {
        const arg: any = fetchColorSegmentationReportArg(nornaid)

        if (qaPage) arg.query.qa_page = true

        const res = await getColrAnnotationFn(arg.url, arg)
        const formatRes = res.data.segmentation.reduce((res, item) => {
            res[item.image_name] = item.polygon
            return res
        }, {})

        setInfo({
            loaded: true,
            points: formatRes[imageUrl] ?? [],
        })
    }, [ nornaid, qaPage, getColrAnnotationFn, imageUrl ])

    useEffect(() => {
        fetchFn()
    }, []) //eslint-disable-line

    const setFirstImageFnCb = useCallback(async () => {
        const firstImgRes = await setFirstImageFn(imageUrl, nornaid)
        if (firstImgRes?.data?.success) {
            snackOpen('submit successfully')
            await getReportedFn()
        } else {
            snackOpen('failed to sumbit `first image` successfully')
        }
    },[ getReportedFn, imageUrl, nornaid, setFirstImageFn, snackOpen ])

    const deleteImageFnCb = useCallback(async () => {
        const deleteImgRes = await deleteImageFn(imageUrl, nornaid)
        if (deleteImgRes?.data?.success) {
            snackOpen('submit successfully')
            await getReportedFn()
        } else {
            snackOpen('failed to sumbit `delete image` successfully')
        }
    },[ getReportedFn, imageUrl, deleteImageFn, snackOpen ])

    const onRotateImage = async (rotation: number) => {
        const res = await rotateImageFn(imageUrl, nornaid, rotation)
        if (res?.data?.success) {
            snackOpen('rotate image successfully')
            await getReportedFn()
        } else {
            snackOpen('failed to sumbit `rotate image` successfully')
        }
    }

    const onSubmit = useCallback(async (paths, w, h, colors: string) => {
        const arg: any = submitColorSegmentationReportArg(productUrl, imageUrl, nornaid, paths, w, h)

        if (qaPage) arg.query.qa_page = true
        const res = await submitFn(arg.url, arg)

        if (res?.data?.success) {
            if (colors) {
                const reportRes = await reportFn(productOriginalUrl, [ [ 'Colors', colors ] ], 'property', colors === UNSET, vendor)

                if (reportRes?.data?.success) {
                    snackOpen('submit successfully')
                } else {
                    snackOpen('submit `color annotation` successfully but failed to `color` property')
                }
                await getReportedFn()
            } else {
                snackOpen('submit successfully')
                await getReportedFn()
            }
        } else { //
            snackOpen('submit failed, try it latter')
        }
    },[ getReportedFn, imageUrl, nornaid, productOriginalUrl, productUrl, qaPage, reportFn, snackOpen, submitFn, vendor ])
    
    const useCropedSize = !isKeepOriginalAspectRatio(vendorName)
    if (!enabledQa || imageUrl === IMG_PLACEHOLDER) {
        return (
            <LazyBgImg 
                cropedImg={useCropedSize}
                src={`${imageUrl}&width=400&height=600`} 
            />
        )
    }

    let src = `${IMAGE_CLOUD_URL}image=${imageUrl}&width=400&height=600`
    if(!useCropedSize){
        // eslint-disable-next-line prefer-destructuring
        src = src.split('&width')[0]
    }
    if (nocache === true) {
        src = src + '&random=' + uuid()
    }
   
    return info.loaded ? (
        <WireframeTool
            setFirstImageFn={setFirstImageFnCb}
            deleteImageFn={deleteImageFnCb}
            rotateImageFn={onRotateImage}
            onSubmit={onSubmit}
            points={info.points}
            w={400} 
            h={600}
            url={src}
            buttonGroupStyle={{ left: 0 }}
            enabledQa={enabledQa}
            nornaid={nornaid}
        />
    ) : (
        <LazyBgImg 
            cropedImg={useCropedSize}
            src={`${imageUrl}&width=400&height=600`} 
        />
    )

}

function uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}
