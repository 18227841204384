import React, { CSSProperties, Fragment, ReactNode } from 'react'
import classnames from 'classnames'
import { CaretUpOutlined, CaretDownOutlined } from 'druikit'
import { ShouldRender } from 'componentsv2/ShouldRender'
import styles from './styles.module.scss'
import { NornaTabProps } from './NornaTab.type'
import classNames from 'classnames'
import { formatVendorName } from 'utils/sellers'
import { CATEGORY_TOTAL } from 'consts'

const renderEmpty = sameLevelHasChild => {
  if (sameLevelHasChild) return null
  return null
}

export function NornaTabItem({
  metricsTab,
  categoryToVendor,
  metricTabFn,
  sameLevelHasChild,
  record,
  isSub,
}: NornaTabProps) {
  const color = record.active ? '#484848' : '#9B9B9B'
  let count = 0
  if (categoryToVendor) {
    const childActive = (record.children || []).filter(item => item.active)
    if (childActive.length) {
      childActive.forEach(item => {
        count += item.categorycount || 0
      })
    }
  }

  /**
   * 是否会显示下拉箭头
   * 显示条件：
   * 1. Switch 指向 Category
   * 2. record.length > 0 说明有子项
   */
  const showArrowIcon = !categoryToVendor && record.children && record.children.length

  const twoLinesSty: CSSProperties = { 
    whiteSpace: 'normal', 
    WebkitBoxOrient: 'vertical', 
    WebkitLineClamp: 2,
    display: '-webkit-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }

  const isSameName = !record.title?.includes('@')

  return (
    <div
      className="pointer"
      key={record.title}
      style={{
        position: 'relative',
        backgroundColor: '#fff',
        height: record.active ? ((count + record.categorycount) * 43) + 'px' : '43px',
        paddingBottom: record.active ? '3px' : '0px',
        display: 'flex',
        alignItems: 'center',
        marginRight: 4,
        ...isSub ? {} : {},
      }}
    >
      <button
        className={classnames(record.active ? styles.activeTab : '', styles.tabBtn, styles.tabItem,
          'cell-mouse-effect-black-font',
          'text-ellipsis', 'flex flex-row', showArrowIcon ? styles.buttonHasIcon : styles.buttonNoIcon)}
        style={{
          backgroundColor: isSameName ? '#edefee' : '#e0e0e0',
          color: [ 'Market', CATEGORY_TOTAL ].indexOf(record.title) > -1 ? 'black' : color,
          ...isSub ? {
            color: '#01a699',
            border: '1px solid #01a699',
            backgroundColor: '#fff',
          } : {},
          paddingLeft: '4px',
          paddingRight: '4px',
        }}
        onClick={e => {
          e.preventDefault()
          const tabs = metricsTab.concat()
          if (record.level === 0) {
            tabs[tabs.findIndex(item => item.title === record.title)].active = !tabs[tabs.findIndex(item => item.title === record.title)].active
          } else {
            const tabActiveObj = tabs[tabs.findIndex(item => item.title === record.pTitle)]
            const tabSubActiveIndex = tabActiveObj.children.findIndex(item => item.title === record.title)
            tabActiveObj.children[tabSubActiveIndex].active = !tabActiveObj.children[tabSubActiveIndex].active
          }
          metricTabFn(tabs)
          // lose focus
          setTimeout(() => {
            // components/layout/Header/Header.tsx 文件里添加了一个隐藏的 Radio 元素，用于触发 focus() 让页面中其它元素失去焦点
            const el: HTMLElement = window.document.querySelector('#unfocusRadio') as HTMLElement //eslint-disable-line
            if (el) el.focus()
          }, 200)
        }}
      >
        {/* canvas-transition 类名用于处理 Export JPG 功能时使用，千万别删掉了 */}
        <span
          className={classnames('flex1 canvas-transition', styles.tabText, isSub ? styles['sub-tab'] : '')}
          style={{
            height: record.active ? ((count + record.categorycount) * 43 - 10) + 'px' : '34px',
            width: '100%',
          }}
        >
          <span className={styles.innerText} style={{ height: '100%' }}>
            <ShouldRender shouldRender={Boolean(categoryToVendor)}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: record.active ? ((count + record.categorycount) * 43 - 10) + 'px' : '34px' }}>
                <div style={{ width: '130px', lineHeight: '14px', fontSize: 14, marginBottom: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatVendorName(record.title).name}</div>
                <div style={{ width: '144px', lineHeight: '10px', fontSize: 10 }}>{formatVendorName(record.title).platform}</div>
              </div>
            </ShouldRender>
            <ShouldRender shouldRender={!categoryToVendor}>
              {
                showArrowIcon ? (
                  <div style={{ width: 105, ...twoLinesSty }}>{record.title}</div>
                ) : (
                  <div style={{ width: 130, ...twoLinesSty }}>{record.title}</div>
                )
              }
            </ShouldRender>
          </span>
        </span>
        {showArrowIcon ? (
          <div 
            className={styles.iconWrapper}
            onClick={e => {
              e.stopPropagation()
              const a = metricsTab.concat()
              a[a.findIndex(item => item.title === record.title)].subActive = !a[a.findIndex(item => item.title === record.title)].subActive
              metricTabFn(a)
            }}
          >
            {
              record.subActive ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined />
              )
            }
          </div>
        ) : renderEmpty(sameLevelHasChild)}
      </button>
    </div>
  )
}

export function NornaTab({ 
  metricsTab, 
  cateHeight, 
  expendHeight, 
  categoryToVendor, 
  metricTabFn, 
  isDashboard = false,
  showHeader = true,
}: NornaTabProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: 'sticky', left: 0, zIndex: 2 }}>
      <div className="metricsTabSeller1">
        <div className={styles.metricsTab}>
          {
            metricsTab.map(n => {
              let childrenCategoryHeight = 0
              if (n.subActive && Array.isArray(n.children)) {
                childrenCategoryHeight = n.children
                  .map(item => {
                    return item?.active ? item?.categorycount : 1
                  })
                  .reduce((curr, next) => curr + next, 0) * 43
              }
              return (
                <Fragment key={n.title}>
                  <NornaTabItem
                    {...{
                      metricsTab,
                      cateHeight,
                      categoryToVendor,
                      expendHeight,
                      metricTabFn,
                      sameLevelHasChild: categoryToVendor ? false : !!metricsTab.filter(child => child.children).length,
                      record: n,
                    }}
                  />
                  <main style={{ transition: 'height .5s ease 0s', height: childrenCategoryHeight + 'px' }}>
                    {
                      n.subActive ? n.children.map(item => (
                        <NornaTabItem
                          key={item.title}
                          {...{
                            metricsTab,
                            categoryToVendor,
                            isSub: true,
                            cateHeight,
                            sameLevelHasChild: false,
                            expendHeight,
                            metricTabFn,
                            record: item,
                          }}
                        />
                      )) : null
                    }
                  </main>
                </Fragment>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export const TagWithTips = ({ label, width = '50px', title = label, align, fontSize, style = {}, className }: any) => {
  const defaultStyle: CSSProperties = {
      width,
      textAlign: align,
      display: 'inline-block',
  }
  if (typeof align !== 'undefined') {
      defaultStyle.textAlign = align
  }
  if (typeof fontSize !== 'undefined') {
      defaultStyle.fontSize = fontSize + 'px'
  }
  return (
      <div
          style={{ ...defaultStyle, ...style }}
          className={classNames([ styles.tagWithTips, className ])}
          title={title}
      >
          {label}
      </div>
  )
}

NornaTab.displayName = 'NornaTab'

