import React, { FC, Fragment, memo, ReactNode, useEffect, useState } from 'react'
import classnames from 'classnames'
import { MultiColor } from 'componentsv2/ColorDot'
import { getFractionDigits } from 'utils'
import { Flex } from 'componentsv2/Flex'
import { arrayUtils, numberUtils, stringUtils } from 'norna-uikit'
import { useModuleData } from 'hooks/useModuleData'
import { DisplayText } from 'componentsv2/DisplayText'
import { useFilterCurrency } from 'hooks/useFilter'
import { NA } from 'consts'
import { CaretIcon } from 'assets/icons/Caret'
import * as cssConfig from 'configs/css'
import { getTab } from 'utils/splitPageUtils'
import { uniq } from 'lodash'
import { isCategorySplitPage, isMaterialSplitPage } from 'global/Routes'
import { PieListProps } from './types'
import * as config from './config'
import styles from './styles.module.scss'
import { ComparisonTooltip } from '../ComparisonTooltip'
import { getFilterCategoryFirstLevelList, getFilterColorList, getFilterMaterialList } from 'utils/lockSellerUtils'
import { COLOR_SPLIT_PIE_GRAPH_NAME, MATERIAL_SPLIT_PIE_GRAPH_NAME } from 'configs'
import { paletteList } from 'utils/colorJson'
import { LockIcon } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import useDeepCompareEffect from 'use-deep-compare-effect'

/**
 * ## PieList
 *
 * 业务组件，Color / Category / Material Analytics 页面中使用。
 *
 * 饼图下面的列表。
 *
 * ## 参数说明
 *
 * ### dataSource
 *
 * ```
 * dataSource = [
 *      {
 *          backgroundColor: '#fff',        // 列表项左边小圆点的背景颜色
 *          label: 'White',                 // 列表项中间显示的文字
 *          value: 27.4,                    // 列表项右边的数值
 *
 *          // 以上三个字段是必填字段，还可以传其它任意键值对
 *      }
 * ]
 * ```
 *
 * ### onItemClick
 *
 * 点击列表中某一项触发事件 onItemClick(item)
 *
 * - item 列表中某一项的数据，对应 dataSource 数值中的某一个值
 *
 * 例如：点击第二个列表项，item 为 dataSource 数组中第二个值
 */
export const SplitPieList: FC<PieListProps> = memo(({
  dataSource = [],
  queryDateValid = true,
  onItemClick,
  moduleName,
  isLock = false,
  expandedNames = [],
  onExpand,
}) => {
  const [ moduleData ] = useModuleData(moduleName)
  const { switchValue } = moduleData

  const [ filterCurrency ] = useFilterCurrency()

  let body: ReactNode

  getFilterMaterialList()

  if (isLock) {
    let list: string[] = []
    if (moduleName === COLOR_SPLIT_PIE_GRAPH_NAME) {
      list = getFilterColorList()
    } else if (moduleName === MATERIAL_SPLIT_PIE_GRAPH_NAME) {
      list = getFilterMaterialList()
    } else {
      list = getFilterCategoryFirstLevelList()
    }
    body = (
      <div className={styles.lockWrapper}>
        <div className={styles.lockTable}>
          {
            list.slice(0, 11).map((item, index) => {
              const backgroundColor = moduleName === COLOR_SPLIT_PIE_GRAPH_NAME ? item : paletteList[index]
              return (
                <div key={stringUtils.uuid()} className={styles.lockTr}>
                  <div className={styles.keyFont} style={{ width: config.indexWidth + 'px' }}>
                    {index + 1}.
                  </div>
                  <div className={styles.dotWrap} style={{ width: config.colorWidth + 'px' }}>
                    {[ 'Multi-material', 'Multi-color', 'Multi-category' ].indexOf(item) > -1 ? <MultiColor /> :
                      <div className={styles.dot} style={{ backgroundColor }} />}
                  </div>
                  <div
                    title={item}
                    style={{ width: config.labelWidth + 'px', paddingRight: '4px' }} 
                    className={classnames([ styles.colorFont, styles.deepBorderRight, styles.normalBorderBottom ])}
                  >
                    {item}
                  </div>
                  <div className={classnames([ styles.restSpace, styles.normalBorderBottom ])}>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className={styles.verticalDashedLine} />
        <div className={styles.lockIconWrapper}>
          <LockIcon size={16} />
        </div>
      </div>
    )
  }
  else if (dataSource.length > 0 && queryDateValid) {
    body = (
      <>
        <div className={classnames([ styles.listBody, isMaterialSplitPage() ? styles.materialPageListBody : '' ])}>
          {
            dataSource.map((item, index: number) => {
              return (
                <Fragment key={item.label.toString() + index.toString()}>
                  <button
                    tabIndex={index}
                    className={classnames({ [styles.disabled]: item.disabled, 'cell-mouse-effect-div': true })}
                    onClick={() => {
                      if (isMaterialSplitPage()) {
                        onItemClick?.(item)
                        return
                      }
                      if (item?.percent?.current_value === 0 && item?.count?.current_value === 0) {
                        return
                      }
                      onItemClick?.(item)
                    }}
                  >
                    <div className={styles.keyFont} style={{ width: config.indexWidth + 'px' }}>
                      {index + 1}.
                    </div>
                    <div className={styles.dotWrap} style={{ width: config.colorWidth + 'px' }}>
                      {[ 'Multi-material', 'Multi-color', 'Multi-category' ].indexOf(item.label) > -1 ? <MultiColor /> :
                        <div className={styles.dot} style={{ backgroundColor: item.backgroundColor }} />}
                    </div>
                    <div
                      title={item.label}
                      style={{ width: config.labelWidth + 'px', paddingRight: '4px' }} 
                      className={classnames([ styles.colorFont, styles.deepBorderRight, styles.normalBorderBottom ])}
                    >
                      {item.label}
                    </div>
                    <div
                      style={{ width: config.currentValueWidth + 'px' }} 
                      className={classnames([ styles.valueFont, styles.normalBorderBottom ])}
                    >
                      {item.currentValue}
                    </div>
                    <div
                      style={{ width: config.changePercentageWidth + 'px', backgroundColor: 'rgba(237, 239, 238, 0.5)' }}
                      className={classnames([ styles.valueFont, styles.deepBorderRight, styles.normalBorderBottom ])}
                    >
                      <DisplayText value={item.changePercentage} showArrow />
                    </div>
                    <div
                      style={{ width: config.avgPriceCurrentValueWidth + 'px' }}
                      className={classnames([ styles.valueFont, styles.normalBorderBottom ])}
                    >
                      {typeof item.averagePriceCurrentValue === 'undefined' ? '-' : item.averagePriceCurrentValue === NA ? NA : numberUtils.formatNumberByComma(item.averagePriceCurrentValue?.toFixed(getFractionDigits()))}
                    </div>
                    <div 
                      style={{ width: config.avgPriceChangePercentageWidth + 'px', backgroundColor: 'rgba(237, 239, 238, 0.5)' }}
                      className={classnames([ styles.valueFont, styles.deepBorderRight, styles.normalBorderBottom ])}
                    >
                      <DisplayText value={item.averagePriceChangePercentage} showArrow />
                    </div>
                    <div
                      className={styles.arrowWrapper} 
                      style={{ width: isCategorySplitPage() ? config.arrowWidth : 0 + 'px', height: '100%' }}
                      onClick={e => {
                        e.stopPropagation()
                        let newExpandedNames = [ ...expandedNames ]
                        if (newExpandedNames.includes(item.label)) {
                          newExpandedNames = newExpandedNames.filter(n => n !== item.label)
                        } else {
                          newExpandedNames.push(item.label)
                        }
                        onExpand?.(uniq(newExpandedNames))
                      }}
                    >
                      {
                        item?.children?.length ? (
                          <CaretIcon isReversed={expandedNames.includes(item.label)} />
                        ) : null
                      }
                    </div>
                  </button>
                  {
                    expandedNames.includes(item.label) && item?.children?.length ? (
                      <>
                        {
                          item.children.map((child, childIndex) => {
                            return (
                              <button
                                tabIndex={childIndex}
                                style={{ background: 'rgba(74, 74, 74, 0.1)' }}
                                className={classnames({ [styles.disabled]: child.disabled, 'cell-mouse-effect-div': true })}
                                key={child.label.toString()}
                                onClick={() => {
                                  if (child?.percent?.current_value === 0 && child?.count?.current_value === 0) {
                                    return
                                  }
                                  onItemClick?.(child)
                                }}
                              >
                                <div className={styles.keyFont} style={{ width: config.indexWidth + 'px' }} />
                                <div className={styles.dotWrap} style={{ width: config.colorWidth + 'px' }} />
                                <div
                                  title={child.label}
                                  style={{ width: config.labelWidth + 'px', paddingRight: '4px' }} 
                                  className={classnames([ styles.colorFont, styles.deepBorderRight, styles.normalBorderBottom ])}
                                >
                                  {child.label}
                                </div>
                                <div
                                  style={{ width: config.currentValueWidth + 'px' }} 
                                  className={classnames([ styles.valueFont, styles.normalBorderBottom ])}
                                >
                                  {child.currentValue}
                                </div>
                                <div
                                  style={{ width: config.changePercentageWidth + 'px', backgroundColor: 'rgba(237, 239, 238, 0.5)' }}
                                  className={classnames([ styles.valueFont, styles.deepBorderRight, styles.normalBorderBottom ])}
                                >
                                  <DisplayText value={child.changePercentage} showArrow />
                                </div>
                                <div
                                  style={{ width: config.avgPriceCurrentValueWidth + 'px' }}
                                  className={classnames([ styles.valueFont, styles.normalBorderBottom ])}
                                >
                                  {typeof child.averagePriceCurrentValue === 'undefined' ? '-' : child.averagePriceCurrentValue === NA ? NA : numberUtils.formatNumberByComma(child.averagePriceCurrentValue?.toFixed(getFractionDigits()))}
                                </div>
                                <div 
                                  style={{ width: config.avgPriceChangePercentageWidth + 'px', backgroundColor: 'rgba(237, 239, 238, 0.5)' }}
                                  className={classnames([ styles.valueFont, styles.deepBorderRight, styles.normalBorderBottom ])}
                                >
                                  <DisplayText value={child.averagePriceChangePercentage} showArrow />
                                </div>
                                <div style={{ width: config.arrowWidth + 'px' }}>
                                  &nbsp;
                                </div>
                              </button>
                            )
                          })
                        }
                      </>
                    ) : null
                  }
                </Fragment>
              )
            })
          }
        </div>
        {
          isMaterialSplitPage() ? (
            <Flex flexDirection="row">
              <div className={styles.keyFont} style={{ width: config.indexWidth + 'px', height: 25 }} />
              <div className={styles.dotWrap} style={{ width: config.colorWidth + 'px', height: 25 }} /> 
              <div
                style={{ width: config.labelWidth + 'px', paddingRight: '4px', paddingTop: 4, fontWeight: 'bolder' }} 
                className={classnames([ styles.colorFont, styles.deepBorderRight, styles.normalBorderTop ])}
              >
                Total
              </div>
              <div
                style={{ width: config.currentValueWidth + 'px', height: 25, paddingTop: 4, fontWeight: 'bolder' }} 
                className={classnames([ styles.valueFont, styles.normalBorderTop ])}
              >
                {
                  numberUtils.formatNumberByComma(arrayUtils.fsum(dataSource.map(d => numberUtils.toNumber(d.currentValue))))
                }
                {
                  dataSource[0].currentValue.includes('%') ? '%' : ''
                }
              </div>  
              <div
                style={{ width: config.changePercentageWidth + 'px', height: 25, backgroundColor: 'rgba(237, 239, 238, 0.5)' }}
                className={classnames([ styles.valueFont, styles.deepBorderRight, styles.normalBorderTop ])}
              />
              <div
                style={{ width: config.avgPriceCurrentValueWidth + 'px', height: 25 }}
                className={classnames([ styles.valueFont, styles.normalBorderTop ])}
              />
              <div 
                style={{ width: config.avgPriceChangePercentageWidth + 'px', height: 25, backgroundColor: 'rgba(237, 239, 238, 0.5)' }}
                className={classnames([ styles.valueFont, styles.deepBorderRight, styles.normalBorderTop ])}
              />
              <div
                className={styles.arrowWrapper} 
                style={{ width: isCategorySplitPage() ? config.arrowWidth : 0 + 'px', height: '100%' }}
              />
            </Flex>
          ) : null
        }
      </>
    )
  } 
  else {
    body = (
      <div className={styles.noData}>Currently not applicable</div>
    )
  }

  return (
    <div className={styles.list} style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <Flex className={styles.title}>
          <div style={{ fontWeight: 'bold', paddingLeft: '6px', width: config.indexWidth + 'px' }} />
          <div style={{ fontWeight: 'bold', paddingLeft: '6px', width: config.colorWidth + 'px' }} />
          <div style={{ fontWeight: 'bold', width: config.labelWidth + 'px' }}>{stringUtils.capitalize(getTab(moduleName))}</div>
          <div style={{ fontWeight: 'bold', paddingLeft: '6px', width: config.currentValueWidth + config.changePercentageWidth + 'px' }}>Split</div>
          <div style={{ fontWeight: 'bold', paddingLeft: '6px', width: config.avgPriceCurrentValueWidth + config.avgPriceChangePercentageWidth + 'px' }}>Average price</div>
        </Flex>
        <Flex className={styles.subtitle}>
          <div className={styles.subtitleItem} style={{ paddingLeft: '6px', width: config.indexWidth + 'px' }} />
          <div className={styles.subtitleItem} style={{ paddingLeft: '6px', width: config.colorWidth + 'px' }} />
          <div className={styles.subtitleItem} style={{ paddingLeft: '6px', width: config.labelWidth + 'px' }} />
          <div className={styles.subtitleItem} style={{ paddingLeft: '6px', width: config.currentValueWidth + 'px', color: cssConfig.primaryColor }}>{switchValue ? '#' : '%'}</div>
          <ComparisonTooltip width={config.changePercentageWidth} height={16}>
            <div
              className={styles.subtitleItem}
              style={{ paddingLeft: '6px', width: config.changePercentageWidth + 'px' }}
            >
              { 
                switchValue ? (
                  <>
                    +/- <span style={{ color: cssConfig.primaryColor }}>%</span>
                  </>
                ) : (
                  <>
                    +/- <span style={{ color: cssConfig.primaryColor }}>% units</span>
                  </>
                ) 
              }
            </div>
          </ComparisonTooltip>
          <div 
            className={styles.subtitleItem} 
            style={{ paddingLeft: '6px', width: config.avgPriceCurrentValueWidth + 'px', color: cssConfig.primaryColor }}
          >
            {filterCurrency}
          </div>
          <div style={{ paddingLeft: '6px', width: config.avgPriceChangePercentageWidth + 'px' }}>
            <ComparisonTooltip width={30} height={16}>
              <div className={styles.subtitleItem} style={{ display: 'inline-block' }}>
                +/- <span style={{ color: cssConfig.primaryColor }}>%</span>
              </div>
            </ComparisonTooltip>
          </div>
        </Flex>
        <div className={styles.content}>
          {body}
        </div>
      </div>
    </div>
  )
})
