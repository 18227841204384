import React, { useState, useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { useFetch } from 'libs/hookRequest'
import { argFnOptionsIndices } from 'graphql/nornaapi'
import { cloneDeep } from 'lodash'
import { Spin } from 'componentsv2/Spin'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { Switch } from 'componentsv2/form-elements/Switch'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { OpenInNewButton, HideWhenOpenInNew, OPEN_IN_NEW_DATE, ShowWhenOpenInNew } from 'componentsv2/business/OpenInNewButton'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useCompetitorOptions } from 'hooks/useOptions'
import { usePageDate } from 'hooks/usePageDate'
import { useUrlParams } from 'hooks/useUrlParams'
import { useExcludeProductsComparisonQuery, useLoadingBar, useManualLoading } from 'hooks'
import { CATEGORY_TOTAL, MARKET_VENDOR, NA, OPTIONS_NAME } from 'consts'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { isSuperArray, sortVendorList } from 'utils/array'
import { isCompetitorGroupChanged } from 'utils/group'
import { downloadFileByAxios } from 'export'
import { isSubsetPayload, UPDATE_NEWCOMPETITORVALUE, UPDATE_OLDCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { TagWithTips } from 'componentsv2/MenuBar/FilterTags'
import { sizeOfLineExportFilename } from 'utils/exportUtils'
import { SIZE_OF_LINE_TABLE_NAME } from 'configs'
import { getCompetitorPayload, getDashboardPropertiesPayload, getGroupsPayload, getMarketPayload, getPriceFilterPayload, getTargetGroupPayload } from 'utils/queryPayload'
import { useFilterCompetitor, useFilterCountry, useFilterGender, useFilterIgnoreNoAvailableSize, useFilterNoHarmonizedSize, useFilterNoHistoricalSize, useFilterNoSize, useFilterNoUnavailableSize, useFilterZalandodeepShopEmptyString } from 'hooks/useFilter'
import { useScrollBar2 } from 'hooks/useScrollBar'
import { useModuleData } from 'hooks/useModuleData'
import { formatVendorName } from 'utils/sellers'
import { getVendorNameByCode } from 'utils'
import { storage } from 'utils/storage'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { Table, ColumnProps } from './components/Table'
import { dynamicColumnWidth, geneCsvData } from './uitls'
import { CategoryProps, DataType, RowProps } from './types'
import styles from './styles.module.scss'
import { LockIcon } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import GraphButton from 'componentsv2/GraphButton'
import { whetherLock } from 'utils/lockSellerUtils'
import { ClothesIcon } from 'druikit'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_SIZE_OF_LINE } from 'features/filters/moduleDataSlice.const'
import { TopScrollbar, numberUtils } from 'norna-uikit'

// 多个地方会使用到这个 id, 因此作为一个公共常量使用
const PAGE_ID = 'export-wrapper'

/**
 * 获取实际宽度
 * @returns 
 */
export const getActualWidth = () => {
    const headerEl = document.querySelector('.table-thead > div')

    if (headerEl) {
        const width = headerEl.getBoundingClientRect().width

        const pageEle = document.querySelector('.page-container')
        // 小于页面宽度
        if(pageEle && pageEle.getBoundingClientRect().width > width){
            return 0
        }

        return width
    }

    return 0
}

export const SizeOfLine = () => {
    useScrollToTop()
    useLoadingBar()
    const { pageDate: dateRangeValue  } = usePageDate()
    const { manualLoading, showManualLoading } = useManualLoading()
    const [ regionValue ] = useFilterCountry()
    const [ genderValue ] = useFilterGender()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterNoSize ] = useFilterNoSize()
    const [ filterNoHistoricalSize ] = useFilterNoHistoricalSize()
    const [ filterNoUnavailableSize ] = useFilterNoUnavailableSize()
    const [ filterNoHarmonizedSize ] = useFilterNoHarmonizedSize()
    const [ filterZalandodeepShopEmptyString ] = useFilterZalandodeepShopEmptyString()
    const [ filterIgnoreNoAvailableSize ] = useFilterIgnoreNoAvailableSize()
    const cacheData = useSelector(getCacheData)
    const customerVendor = storage.getCustomerVendor()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    
    const [ moduleData, setModuleData ] = useModuleData(SIZE_OF_LINE_TABLE_NAME)
    const { 
        fetchPayload = {}, 
        apiData = {}, 
        oldCompetitorValue = [],
        newCompetitorValue = [],
        switchValue = true,
    } = moduleData

    /* ***************************** 表单 *************************************** */
    const { competitorOptions } = useCompetitorOptions({ excludeMarket: true, excludeSelf: true, region: regionValue })
    
    const setSwitchValue = (value: boolean) => {
        setModuleData({ switchValue: value })
    }

    /* ***************************** Open in new *************************************** */
    const urlParams = useUrlParams()

    useEffect(() => {
        if (typeof urlParams?.switchValue !== 'undefined') {
            setSwitchValue(urlParams.switchValue === 'true')
        }
        // eslint-disable-next-line
    }, [])

    /* ***************************** Export excel *************************************** */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        const competitorSellers = [ ...filterCompetitor ]
        payload.query.competitors = getCompetitorPayload({ competitorSellers, returnType: 'vendor', isDashboard: true }).join(',') 
        payload.query.market = getMarketPayload({ competitorSellers: [ { vendor: MARKET_VENDOR.vendor, region: regionValue } ], competitorOptions, returnType: 'vendor' }).join(',')
        payload.data.groups = getGroupsPayload({ competitorSellers: [ ...competitorSellers ] })
        await downloadFileByAxios({
            filename: sizeOfLineExportFilename,
            payload,
            params: { use_index: switchValue },
        })
    }

    /* ***************************** 表格 *************************************** */
    const { postFn: fetch, data, loading, setData } = useFetch()
    const [ indexDataSource, setIndexDataSource ] = useState<RowProps[]>([])
    const [ optionsDataSource, setOptionsDataSource ] = useState<RowProps[]>([])
    const [ columns, setColumns ] = useState<ColumnProps[]>([])
    const [ , setCsvData ] = useCsvDataModuleData()

    const staticColumns = [
        {
            title: (
                <div style={{ width: '100px', paddingLeft: '12px' }}>
                    {OPTIONS_NAME}
                </div>
            ),
            name: 'options',
            width: '100px',
            className: styles.borderBottom,
            render: text => {
                return (
                    <div className={styles.tdOptionsWrapper}>
                        <div className={styles.optionsInner}>{text === '0' ? NA : text}</div>
                    </div>
                )
            },
        },
        {
            title: 'Category',
            name: 'category',
            width: '150px',
            className: classnames([ styles.borderRight, styles.borderBottom ]),
            render: text => (
                <div className={styles.tdCategory}>
                    <div className={styles.clothesIconWrapper} style={{ width: '25px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ClothesIcon 
                            clothes={text} 
                            targetGroup={genderValue.length === 1 ? genderValue[0] : 'All Options'} 
                        />
                    </div>
                    <TagWithTips label={text} width="110px" />
                </div>
            ),
        },
        {
            title: 'Market average',
            name: 'Market',
            width: '140px',
            style: { padding: '0 12px' },
            className: classnames([ styles.borderRight, styles.borderBottom ]),
            render: text => {
                const queryDateValid = isQueryDateValid({ vendor: MARKET_VENDOR.vendor, region: regionValue  })
                return (
                    <div>{queryDateValid ? text : NA}</div>
                )
            },
        },
    ]

    const fetchData = () => {
        if (!regionValue || !genderValue?.length || !dateRangeValue || !filterCompetitor.length || !competitorOptions.length) return
        
        const competitorSellers = [ ...filterCompetitor ]
        const competitors = getCompetitorPayload({ competitorSellers, returnType: 'vendor', isDashboard: true })
        const market = getMarketPayload({ competitorSellers: [ { vendor: MARKET_VENDOR.vendor, region: regionValue } ], competitorOptions, returnType: 'vendor' })
        const groups = getGroupsPayload({ competitorSellers: [ ...competitorSellers ] })
        const priceFilter = getPriceFilterPayload(comparisonQuery.collection.query)
        const properties = getDashboardPropertiesPayload(comparisonQuery)
        const targetGroup = getTargetGroupPayload({ targetGroups: genderValue, returnType: 'array' })

        const payload = argFnOptionsIndices({
            customer: customerVendor,
            region: regionValue,
            targetGroup,
            competitors,
            date: dateRangeValue,
            market,
            groups,
            priceFilter,
            properties,
            noSize: filterNoSize,
            noHistoricalSize: filterNoHistoricalSize,
            noUnavailableSize: filterNoUnavailableSize,
            noHarmonizedSize: filterNoHarmonizedSize,
            zalandodeepShopEmptyString: filterZalandodeepShopEmptyString,
            ignoreNoAvailableSize: filterIgnoreNoAvailableSize,
        })

        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload, pageType: 'sizeOfLine', competitorType: 'string' }) && Object.keys(apiData || {}).length) {
            showManualLoading()
            setData?.(apiData)
            return
        }

        setModuleData({ fetchPayload:  cloneDeep(payload) })
        setModuleData({
            type: UPDATE_OLDCOMPETITORVALUE,
            payload: {
                competitorValue: payload.data.competitors,
                customerVendor,
                market: payload.data.market,
                groups: payload.data.groups,
            },
        })
        fetch(payload.url, payload)
    }

    const handleData = (apiData: Record<string, any> = {}) => {
        if (Object.prototype.toString.call(apiData) !== '[object Object]') return

        apiData = cloneDeep(apiData)
        // filter data by selected competitor
        Object.keys(apiData?.options || {}).filter(item => ![ ...newCompetitorValue, MARKET_VENDOR.vendor ].includes(item))
            .forEach(item => {
                delete apiData.index[item]
                delete apiData.options[item]
            })

        Object.keys(apiData?.index || {}).forEach(vendor => {
            const queryDateValid = isQueryDateValid({ vendor, region: regionValue, competitorValue: newCompetitorValue })
            if (!queryDateValid) {
                Object.keys(apiData.index[vendor]).forEach(category => {
                    apiData.index[vendor][category] = 0
                    apiData.options[vendor][category] = 0
                })
            }
        })

        const indexData = apiData?.index || {}
        const optionsData = apiData?.options || {}

        const categoryList: CategoryProps[] = [
            { name: CATEGORY_TOTAL, isChecked: false, list: [ ...cacheData.metaCategories.list ] },
        ]
        let vendorList = Object.keys(optionsData)
        vendorList = sortVendorList({
            vendorList,
        })

        // 递归计算 category 数据
        function getRowData(categoryList: CategoryProps[] = [], vendorList, type: DataType) {
            if (categoryList.length === 0) return []

            return categoryList.map(categoryItem => {
                const category = categoryItem.name

                const indexVendorData: Record<string, string> = {}
                const optionsVendorData: Record<string, any> = {}
                vendorList.forEach(vendor => {
                    // 乘以100保留0位小数并加百分号
                    indexVendorData[vendor] = numberUtils.formatNumber(indexData[vendor][category], {
                        isCentuple: true,
                        isPercentDecimal: true,
                        isCommaSymbol: true,
                        isPercentSymbol: true,
                    }) 
                    // 这里还是原始数字，尚未格式化（每三位逗号隔开），因为下面还要计算总数
                    optionsVendorData[vendor] = optionsData[vendor][category]
                })

                // 计算 totalOptions，表格第一列数据
                const copyOptionsVendorData = cloneDeep(optionsVendorData)
                delete copyOptionsVendorData.Market
                const optionsValues: number[] = Object.values(copyOptionsVendorData)
                const totalOptionsNum = optionsValues.reduce((total, current) => total + current, 0)
                const totalOptionsStr = numberUtils.formatNumber(totalOptionsNum, { isCommaSymbol: true, decimal: 0 })

                indexVendorData.options = totalOptionsStr
                optionsVendorData.options = totalOptionsStr

                // 重新计算 optionsVendorData，数值需要进行数字格式化
                vendorList.forEach(vendor => {
                    optionsVendorData[vendor] = numberUtils.formatNumber(optionsData[vendor][category], { isCommaSymbol: true, decimal: 0 })
                })

                let lastVendorData
                if (type === DataType.index) {
                    lastVendorData = { ...indexVendorData }
                } else {
                    lastVendorData = { ...optionsVendorData }
                }

                return {
                    category,
                    ...lastVendorData,
                    children: getRowData(categoryItem.list, vendorList, type),
                }
            })
        }

        const optionsDataSource = getRowData(categoryList, vendorList, DataType.options)
        const indexDataSource = getRowData(categoryList, vendorList, DataType.index)

        setOptionsDataSource([ ...optionsDataSource ])
        setIndexDataSource([ ...indexDataSource ])

        setCsvData({
            [CSV_DATA_SIZE_OF_LINE]: geneCsvData({ dataSource: switchValue ? indexDataSource : optionsDataSource, vendorList }),
        })

        /* **************************** dynamic columns ************************************** */
        const columns: ColumnProps[] = [ ...staticColumns ]
        const sellers = storage.getSellers()

        const dynamicColumns: ColumnProps[] = vendorList.filter(item => item !== 'Market').map(vendor => {
            const vendorName = getVendorNameByCode(vendor)
            const np = formatVendorName(vendorName)
            const isLock = whetherLock({
                vendorCode: vendor,
                region: regionValue,
                sellers,
                selectedVendorCodeList: filterCompetitor.map(c => c.vendor),
            })
            return {
                title: (
                    <div className={styles.thVendor} style={{ maxWidth: dynamicColumnWidth + 'px' }}>
                        <div className={styles.name} title={np.name}>{np.name}</div>
                        <div className={styles.platform} title={np.platform}>{np.platform}</div>
                    </div>
                ),
                name: vendor,
                style: { width: dynamicColumnWidth + 'px', padding: '0 12px' },
                className: classnames([ styles.borderRight, styles.borderBottom ]),
                render: text => {
                    if (isLock) {
                        return (
                            <div>
                                <LockIcon size={16} />
                            </div>
                        )
                    }

                    const queryDateValid = isQueryDateValid({ vendor, region: regionValue, competitorValue: newCompetitorValue  })
                    return (
                        <div>{queryDateValid ? text : NA}</div>
                    )
                },
            }
        })

        columns.splice(2, 0, ...dynamicColumns)

        setColumns([ ...columns ])
    }

    useDeepCompareEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [comparisonQuery, regionValue, genderValue, filterCompetitor, dateRangeValue, competitorOptions])

    useDeepCompareEffect(() => {
        if (isCompetitorGroupChanged({ newCompetitorValue, oldCompetitorValue })) {
            fetchData()
            return
        }

        if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleData(cloneDeep(apiData))
            return
        }

        fetchData()
    }, [ newCompetitorValue ])

    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: { competitorValue: filterCompetitor.map(item => item.vendor) },
        })
    }, [ filterCompetitor, [] ])

    useEffect(() => {
        if (!data) return

        handleData(cloneDeep(data))
        setModuleData({ apiData: cloneDeep(data) })
        // eslint-disable-next-line
    }, [data])

    /* ************************************ Synchronize two horizontal scroll bars ******************************************* */
    useScrollBar2('.table-thead', '.table-tbody', indexDataSource)

    return (
        <div className={styles.container} id={PAGE_ID}>
            <ShowWhenOpenInNew>
                <FilterInfo hidden />
            </ShowWhenOpenInNew>
            <ModuleTitle category="ASSORTMENT" title={SIZE_OF_LINE_TABLE_NAME} showDate />
            <Spin spinning={loading || manualLoading} minHeight={50}>
                <div className={styles.form}>
                    <HideWhenOpenInNew>
                        <ExportDropdown
                            onExportExcel={onExportExcel}
                            fileName={sizeOfLineExportFilename}
                            right={10}
                            calcJpgWidthFn={getActualWidth}
                            adjustElementFn={ele => {
                                const width = getActualWidth()
                                if (width !== 0) {
                                    const wrapper = ele.querySelector('.table-thead')?.closest('div')
                                    if (wrapper) wrapper.style.width = `${width}px`
                                    const headerEle: HTMLDivElement = ele.querySelector('.table-thead') as HTMLDivElement
                                    if (headerEle) headerEle.style.width = `${width}px`
                                    const contentEle: HTMLDivElement = ele.querySelector('.table-tbody') as HTMLDivElement
                                    if (contentEle) contentEle.style.width = `${width}px`
                                }
                            }}
                        />
                        <GraphButton />
                        <OpenInNewButton
                            left={10}
                            pageParams={{
                                genderValue,
                                regionValue,
                                switchValue,
                                [OPEN_IN_NEW_DATE]: dateRangeValue,
                            }}
                        />
                    </HideWhenOpenInNew>
                    <div style={{ flex: 1 }} />
                    <Switch
                        value={switchValue}
                        onChange={value => setSwitchValue(value)}
                        leftLabel={OPTIONS_NAME.toLowerCase()}
                        rightLabel="index"
                        left={10}
                    />
                </div>
                <ShouldRender shouldRender={switchValue}>
                    <div className={styles.tableTitle}>Market avg indexed to 100</div>
                </ShouldRender>
                <ShouldRender shouldRender={!switchValue}>
                    <div className={styles.tableTitle}>Assortment size indexed to market</div>
                </ShouldRender>
                <div className={styles.theadWrapper}>
                    <TopScrollbar className="table-thead">
                        <Table
                            dataSource={switchValue ? indexDataSource : optionsDataSource}
                            columns={columns}
                            theadClassName={styles.thead}
                            showTbody={false}
                        />
                    </TopScrollbar>
                </div>
                <Table
                    dataSource={switchValue ? indexDataSource : optionsDataSource}
                    columns={columns}
                    theadClassName={styles.thead}
                    showThead={false}
                    className={classnames([ 'table-tbody', styles.tbodyTable ])}
                />
            </Spin>
        </div>
    )
}
