import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { debounce } from 'lodash'
import { ProgressBarContext } from 'components/ProgressBar/ProgressBarContext'
import { ObjectProps } from 'types'
import { SnackInfo } from 'componentsv2/SnackInfo'
import { ModalV2 } from '../../Modal/Modal'
import { ActionBoxConfigState, ActionBoxContext } from './context'
import styles from './styles.module.scss'
import { LoadingSpinner } from '../../../assets/icons/Loading'

let dialogIndex = 0

export function genId() {
  dialogIndex += 1
  return `ido-action-box-${dialogIndex}`
}

interface G {
  id: number | string | ReturnType<typeof setTimeout>
}

const g: G = { id: '' }
export const DialogInstance = {}

export function offSetEleFn(curEle) {
  let totalLeft = null
  let totalTop = null
  let scrollTop = 0
  let scrollLeft = 0
  if (curEle === null) return { offsetLeft: 0, offsetTop: 0, scrollTop, scrollLeft }
  let par = curEle.offsetParent
  //
  totalLeft += curEle.offsetLeft
  totalTop += curEle.offsetTop
  scrollTop += curEle.scrollTop
  scrollLeft += curEle.scrollLeft
  //
  while (par) {
    if (navigator.userAgent.indexOf('MSIE 8.0') === -1) {
      //
      totalTop += par.clientTop
      totalLeft += par.clientLeft
      scrollTop += curEle.scrollTop
      scrollLeft += curEle.scrollLeft
    }
    // add the parent offset
    totalTop += par.offsetTop
    totalLeft += par.offsetLeft

    scrollTop += par.scrollTop
    scrollLeft += par.scrollLeft

    par = par.offsetParent
  }
  return { offsetLeft: totalLeft, offsetTop: totalTop, scrollTop, scrollLeft }
}

export function parentshasClass(curEle, curClass) {

  if ([ ...curEle.classList ].indexOf(curClass) > -1) return curEle
  let par = curEle.parentElement
  let breakFlag = false
  let contain = false
  while (par && !contain && !breakFlag) {
    contain = [ ...par.classList ].indexOf(curClass) > -1
    if (contain) {
      breakFlag = true
    } else {
      par = par.parentElement
    }
  }
  return contain ? par : contain
}
/**
 * ActionBox Types
 */
export const ActionBoxTypes = {
  BOX: 'BOX',
  CUSTOM: 'CUSTOM',
  DIALOG: 'DIALOG',
  DIALOGV3: 'DIALOG-V3',
  DIALOG_PRODUCT_DETAIL: 'DIALOG_PRODUCT_DETAIL', // 产品详情弹框
  LOADING: 'LOADING',
  REPORT_DIALOG: 'REPORT_DIALOG',                 // report dialog
}
export function getOffsetSum(ele) {
  let top = 0
  let left = 0
  while (ele) {
    top += ele.offsetTop
    left += ele.offsetLeft
    ele = ele.offsetParent
  }
  return {
    top,
    left,
  }
}

export function useActionBox() {
  const [ actionConfig, setActionConfig ] = useState<Array<ActionBoxConfigState>>([])
  const open = useCallback(debounce((actionboxItem: ActionBoxConfigState) => {
    if (actionboxItem.id) { // exist judge
      const existItem = actionConfig.filter(item => item.id === actionboxItem.id)
      if (existItem?.length ?? false) return
    }
    /**
     * 2022/06/10
     * 新需求: 可以同时打开多个产品列表 dialog
     */
    // 同一个时间，限制打开1个同种类型弹框
    // const sampeTypeExist = actionConfig.findIndex(item => item.type === actionboxItem.type) > -1;
    // if (sampeTypeExist) {
    //   return;
    // }

    actionboxItem.id = actionboxItem.id || genId()
    setActionConfig([ ...actionConfig, actionboxItem ])

  }, 200), [ actionConfig ])
  const clear = id => {
    if (g[id]) {
      clearTimeout(g[id])
    }
  }
  const timerClose = debounce((id, time = 2000) => {
    clear(id)
  }, 400)
  const cancelClose = debounce(clear, 300)

  const close = useCallback(id => {
    setActionConfig(actionConfig => {
      const index = actionConfig.findIndex(item => item.id === id)

      document.querySelector(`#other-root-dialog-${id}`)?.remove()
      if (index > -1) {
        return [
          ...actionConfig.slice(0, index),
          ...actionConfig.slice(index + 1),
        ]
      }
      return actionConfig

    })
  }, [ setActionConfig ])

  /**
   * close all dialog
   */
  const closeAll = useCallback(() => {

    /**
     * clear limit here when close all dialog
     */
    Object.keys(DialogInstance).forEach(key => { delete DialogInstance[key] })
    setActionConfig(actionConfig => {
      actionConfig.forEach(item => { // rm all dialog
        document.querySelector(`#other-root-dialog-${item.id}`)?.remove()
      })
      return []
    })
  }, [ setActionConfig ])

  return {
    timerClose,
    cancelClose,
    actionConfig, // state data
    close,        // close fn
    closeAll,        // close fn
    setBodyComponent: open, //
    open,          // open fn
  }
}

const $rootOther: HTMLElement = document.getElementById('otherRoot') as HTMLElement

function ActionBox(props: ActionBoxConfigState) {
  const elId = `other-root-dialog-${props.id}`
  let el: HTMLElement | null = document.querySelector(`#${elId}`)
  if (!el) {
    el = document.createElement('div')
    el.id = elId
    el.style.position = 'relative'
    el.style.zIndex = '1'
    $rootOther?.appendChild(el)
  };
  const [ position, setPosition ] = useState({ left: 0, top: 0, opacity: 0, targetW: 0, targetH: 0 })
  const domRef: any = useRef()

  useEffect(() => {
    if (ActionBoxTypes.BOX === props.type) {
      setPosition({
        left: props?.left,
        top: props?.top - domRef.current.clientHeight,
        opacity: 1,
        targetW: props?.w ?? 0,
        targetH: props?.h ?? 0,
      })
    }

    function scroll() {
      props.close?.()
    }

    if (ActionBoxTypes.BOX === props.type) {

      document.addEventListener('scroll', scroll)
    }
    return () => {
      if (ActionBoxTypes.BOX === props.type) {
        document.removeEventListener('scroll', scroll)
      }
    }
  }, [
    domRef,
    props,
  ])

  const bodyComponent = function () {
    if (ActionBoxTypes.LOADING === props.type) {
      return (
        <LoadingSpinner noBackground={true} />
      )
    }
    if (ActionBoxTypes.DIALOG === props.type || ActionBoxTypes.DIALOGV3 === props.type || ActionBoxTypes.DIALOG_PRODUCT_DETAIL === props.type || ActionBoxTypes.REPORT_DIALOG === props.type) {
      return (
        <ModalV2
          key={`Modal-${props.id}`}
          isOpen={true}
          type={[ ActionBoxTypes.REPORT_DIALOG, ActionBoxTypes.DIALOG_PRODUCT_DETAIL, ActionBoxTypes.DIALOGV3 ].indexOf(props.type) > -1 ? 1 : 0}
          closeFn={() => {
            props.close?.()
          }}
          title={props.title}
        >
          {props.children}
        </ModalV2>
      )
    }

    const defaultStyle = {
      left: position.left + 'px',
      top: position.top + 'px',
      opacity: position.opacity,
      ...props.style,
    }

    return (
      <div
        ref={domRef}
        className={styles.actionBox}
        onMouseLeave={() => {
          props.close?.()
        }}
        onMouseEnter={() => {
          props.enter?.()
        }}
        style={defaultStyle}
      >
        <div
          className={styles.actionBoxPanel}
        >
          {props.children}
        </div>
        <div
          className={styles.hotArea}

          onClick={() => {
            props?.click?.()
          }}
          style={{
            zIndex: -1,
            border: '1px soild red',
            marginLeft: 10,
            width: position.targetW,
            height: position.targetH,
          }}
        />
      </div>
    )
  }

  return ReactDOM.createPortal(
    bodyComponent(),
    el)
}

export const ActionType = {
  CUSTOM: 'CUSTOM',
}

export function ActionBoxProvider(props) {
  const { children } = props
  const [ loadingBar, setLoadingBar ] = useState(null)
  const actionObj = useActionBox()
  const { actionConfig, close, cancelClose } = actionObj
  const snackRef = React.useRef<ObjectProps>({})

  return (
    <ProgressBarContext.Provider
      value={{
        loadingBar,
        setLoadingBar,
        snackOpen: msg => snackRef.current?.open?.(msg),
      }}
    >
      <SnackInfo ref={snackRef} />

      <ActionBoxContext.Provider value={actionObj}>
        {
          actionConfig.map((actionboxItem, index) => {

            if (actionboxItem.type === ActionType.CUSTOM) {
              return (
                <div key={`Dialog-${actionboxItem.id}`}>
                  {actionboxItem.bodyComponent}
                </div>
              )
            }
            return (
              <ActionBox
                key={`dialog-${actionboxItem.id}`}
                id={actionboxItem.id}
                type={actionboxItem.type || ActionBoxTypes.BOX}
                close={() => {
                  actionboxItem?.close?.()
                  return close(actionboxItem.id)
                }}
                cancelClose={() => cancelClose(actionboxItem.id)}
                left={actionboxItem.left}
                top={actionboxItem.top}
                title={actionboxItem.title}
                w={actionboxItem.w}
                click={actionboxItem.click}
                h={actionboxItem.h}
                enter={actionboxItem.enter}
                style={actionboxItem.style}
              >
                {actionboxItem.bodyComponent}
              </ActionBox>
            )
          })
        }
        {children}
      </ActionBoxContext.Provider>
    </ProgressBarContext.Provider>
  )
}
