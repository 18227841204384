import React, { FC, CSSProperties, memo } from 'react'
import { SizedBoxProps } from './types'

/**
 * # 何时使用
 * 
 * 通常，要设置两个元素之间距离，我们会使用 margin css 属性处理。
 * 
 * 在 React 中，我们希望使用组件也能很方便的设置组件之间的距离。
 * 
 * SizedBox 组件灵感来源于 Flutter 中的 SizedBox 构件。
 * 
 * # 代码演示
 * 
 * 设置组件之间水平距离。
 * 
 * ```tsx
 * import React from 'react';
 * import { SizedBox } from 'xxx';
 * 
 * export default () => {
 *     return (
 *         <>
 *             <span>hello</span>
 *             <SizedBox width={10}/>
 *             <span>world</span>
 *         </>
 *     )
 * }
 * ```
 * 
 * 设置组件之间垂直距离。
 * 
 * ```tsx
 * import React from 'react';
 * import { SizedBox } from 'xxx';
 * 
 * export default () => {
 *     return (
 *         <>
 *             <div>Lucy</div>
 *             <SizedBox height={100}/>
 *             <div>Tom</div>
 *         </>
 *     )
 * }
 * ```
 * 
 */
export const SizedBox: FC<SizedBoxProps> = memo(({
    height = 1,
    width = 1,
    type = 'inline-block',
    className,
    style = {},
}) => {
    const divStyle: CSSProperties = {
        width: width + 'px',
        height: height + 'px',
        background: 'transparent',
        display: type,
        padding: 0,
        margin: 0,
    }

    return (
        <div style={{ ...divStyle, ...style }} className={className} />
    )
})

SizedBox.displayName = 'SizedBox'
