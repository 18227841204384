import React from 'react'
import { SizedBox } from 'componentsv2/SizedBox'
import { PriceInsightTable } from './components/PriceInsightTable'

export const PriceInsight = () => {

    return (
        <>
            <PriceInsightTable />
            <SizedBox height={30} />
        </>
    )
}
