import React from 'react'
import { Container, Body, MainSection } from 'components/layout'
import { useLoadingBar } from 'hooks'
import styles from './styles.module.scss'
import { LookbooksList } from 'components/Lookbooks/LookbooksList'

export const MyLookbooks = () => {
    useLoadingBar()
    return (
        <Container>
            <Body>
                <MainSection>
                    <div className={styles.contentContainer}>
                        <div className={styles.noPadding}>
                            <LookbooksList />
                        </div>
                    </div>
                </MainSection>
            </Body>
        </Container>
    )
}
