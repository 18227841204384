import { useEffect, useState, useRef } from 'react'
import { throttle } from 'lodash'

export const useManualLoading = () => {
    const [ manualLoading, setManualLoading ] = useState(false)
    const timerRef = useRef<any>()

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
                timerRef.current = null
            }
        }
        // eslint-disable-next-line
    }, [])

    return {
        manualLoading,
        showManualLoading: throttle((seconds = 1) => {
            setManualLoading(true)
            timerRef.current = setTimeout(() => {
                if (!timerRef.current) return
                setManualLoading(false)
                timerRef.current = null
            }, seconds * 1000)
        }, 500),
    }
}
