import React from 'react'
import { useLoadingBar, useScrollToTop } from 'hooks'
import { SizedBox } from 'componentsv2/SizedBox'
import { DiscountsTable } from './components/DiscountsTable'

export const Discounts = () => {
  useScrollToTop()
  useLoadingBar()

  return (
    <div style={{ background: '#fff', position: 'relative', minHeight: '1000px' }}>
      <DiscountsTable />
      <SizedBox height={40} />
    </div>
  )
}
