/**
* @file    VerticalLine
* @date   2022-01-27
* @author byte_su@163.com
*/

import React from 'react'

export const VerticalLine = () => <div
   style={{ height:30,width:2,background:'#f0f0f0' }}
                                  />

VerticalLine.displayName = 'VerticalLine'
