import { TreeItemProp } from 'componentsv2/Tree'
import { recursiveGetPropertyVal, recursiveGetVal } from 'componentsv2/Tree/utils'
import { DISABLED_MENU_AND_PROPERTY } from 'consts'
import { TARGET_GROUPS } from 'consts/targetGroups'
import { RoutesObj } from 'global/Routes'
import { values } from 'lodash'
import { ArrayObjectType } from 'types'
import { ArrayStringValueMap } from 'types/cache'
import { Property } from 'types/property'

const MATERIAL = 'Material'
export const CATEGORIES = 'Categories'
/**
 * get all properties from one vendor metadata
 */
export const getVendorProperties = (propertiesData: ArrayStringValueMap<TreeItemProp>): ArrayStringValueMap<string> => {
  const categories: Array<string> = Object.keys(propertiesData)
  const cateogryProperies: Array<TreeItemProp> = values(propertiesData).flat(10)

  const materials = Array.from(new Set(categories.map(category => propertiesData[category].filter(item => item.val === MATERIAL).map(recursiveGetVal)).flat(10)))

  const propertiesLeafObj = {}
  const propertyGroup: Array<string> = Array.from(new Set(cateogryProperies.map(item => {
    if (!propertiesLeafObj[item.label]) {
      propertiesLeafObj[item.label] = []
    }
    propertiesLeafObj[item.label] = Array.from(new Set([ ...propertiesLeafObj[item.label], ...recursiveGetVal(item) ].flat(10)))
    return item.label
  })))

  const propertyValue: Array<string> = Array.from(new Set(cateogryProperies.map(item => recursiveGetVal(item)).flat(10)))
  // cateogryProperies
  return {
    properties: propertyValue,
    propertyGroup: [ ...propertyGroup, CATEGORIES ],
    [MATERIAL]: materials,
    ...propertiesLeafObj,
    [CATEGORIES]: categories,
  }

  // return
}

/**
 * get properies(including category) from admin meta data
 * @param propertiesData 
 * @returns 
 */
export const getAdminProperties = (categories: Array<{ category: string, properties: Array<string> }>, properties: ArrayStringValueMap<Property>): ArrayStringValueMap<string> => {

  if (!categories || !properties) return {}

  const categoriesData: Array<string> = categories.map(item => item.category)

  const propertiesLeafObj = {}
  const propertyGroup: Array<string> = Object.keys(properties)

  propertyGroup.forEach(item => {
    properties[item].forEach(leafProperty => {
      if (!propertiesLeafObj[item]) propertiesLeafObj[item] = []
      propertiesLeafObj[item].push(recursiveGetPropertyVal(leafProperty))
    })

    propertiesLeafObj[item] = Array.from(new Set(propertiesLeafObj[item].flat(10))).sort()
  })

  return {
    propertyGroup: [ ...propertyGroup, 'Categories', 'TargetGroup' ].sort(),
    // [MATERIAL]: propertiesLeafObj.material,
    ...propertiesLeafObj,
    Categories: categoriesData.sort(),

    /**
     * set `TargetGroup`
     */
    TargetGroup: TARGET_GROUPS.map(item => item.key),
  }

  // return
}

/** *
 * ## convertor using to convert `permission` to functionality
 * 
 * 
 */
export const convertPermisionToMenuAndFilterItems = (unavalidMenus: Array<string>, unavalidProperties: Array<string>): Pick<ArrayObjectType, 'unavailableMenu' | 'unavailableFilter'> => ({
  unavailableMenu: (unavalidMenus ?? []).map(item => DISABLED_MENU_AND_PROPERTY[item] ?? item),
  unavailableFilter: (unavalidProperties ?? []).map(item => DISABLED_MENU_AND_PROPERTY[item] ?? item),
})

/** *
 * ## 
 * 
 * @param url  current url
 * @param list list of unavaliable menu names 
 * 
 * @returns `true` means `grant`, `false` means `not grant`
 */
export const judgeGrantedPermission = (url: string, menuAvailable: (menuLabel:string)=>boolean): boolean => {

  if (url.startsWith(RoutesObj.COLOR_ANALYTICS.URL)) {
    return menuAvailable(RoutesObj.COLOR_ANALYTICS.LABEL) 
  } if (url.startsWith(RoutesObj.MATERIAL_ANALYTICS.URL)) {
    return menuAvailable(RoutesObj.MATERIAL_ANALYTICS.LABEL)
  } if (url.startsWith(RoutesObj.CATEGORY_ANALYTICS.URL)) {
    return menuAvailable(RoutesObj.CATEGORY_ANALYTICS.LABEL)
  } if (url.startsWith(RoutesObj.SEARCH_OPTIONS.URL)) {
    return menuAvailable(RoutesObj.SEARCH_OPTIONS.LABEL)
  } if (url.startsWith(RoutesObj.MY_SAVED_FILTERS.URL)) {
    return menuAvailable(RoutesObj.MY_SAVED_FILTERS.LABEL)
  } if (url.startsWith(RoutesObj.LOOKBOOL_LIST.URL)) {
    return menuAvailable(RoutesObj.LOOKBOOL_LIST.LABEL)
  } if (url.startsWith(RoutesObj.PRICE_ARCHITECTURE.URL)) return menuAvailable(RoutesObj.PRICE_ARCHITECTURE.LABEL)
  if (url.startsWith(RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.URL)) return menuAvailable(RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.LABEL)
  if (url.startsWith(RoutesObj.DASHBOARD_ALIAS.URL)) return menuAvailable(RoutesObj.DASHBOARD_ALIAS.LABEL)
  
    let permissionAllow = true
    Object.keys(RoutesObj).forEach((key: string) => {
      if (key !== RoutesObj.HOME.URL) {
        if (url.startsWith(RoutesObj[key].URL)) {
          permissionAllow = menuAvailable(RoutesObj[key].LABEL)
        }
      }
    })
    return permissionAllow
  
}

