
export function getImgNaturalDimensions(img: HTMLImageElement, callback?: Function) {
    let nWidth = 0; let nHeight = 0
    if (img.naturalWidth) { // 现代浏览器
      if(2/3 > img.naturalWidth/img.naturalHeight){ // 比例比2:3 大
        nHeight = 600
        nWidth = (600/img.naturalHeight)*img.naturalWidth
      }else{
        nWidth = 400
        nHeight = (400/img.naturalWidth)*img.naturalHeight    
      }
      // nWidth = img.naturalWidth
      // nHeight = img.naturalHeight
    } else { // IE6/7/8
      const image: HTMLImageElement = new Image()
      image.src = img.src
      image.onload = function () {
        callback?.(image.width, image.height)
      }
    }
    return { w: nWidth, h: nHeight }
  }
  
