import { BaseSelect, BaseSelectRefProps, Divider, SelectButtonGroup, SelectDropdownHeader, SelectOkButton } from 'druikit';
import { cloneDeep } from 'lodash';
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Tree } from './components/Tree';
import { getFilterCompetitorChildren, handleFilterCompetitorOptions } from 'components/layout/Header/components/HeaderFilter/competitor.util';
import { ALL_VENDORS, LICENSE_CANADAGOOSE, LICENSE_JUST_BRANDS, SELECTED_VENDORS } from 'consts';
import { getLeafs } from './components/TreeItem';
import { getVendorNameByCode } from 'utils';
import { storage } from 'utils/storage';
import { useFilterCountry } from 'hooks/useFilter';

function recursion(data: any[], fieldsOrcb: ((item: any) => void) | Record<string, any>, mode: 'append' | 'new' = 'append'): any[] {
    const iterator = function (arr: any[], cb: Function) {
        for (let i = 0; i < arr.length; i++) {
            cb.call(arr[i], arr[i])
        }
    }

    const changeItem = function (item: any) {
        if (typeof fieldsOrcb === 'function') {
            fieldsOrcb(item)
        } else if (Object.prototype.toString.call(fieldsOrcb) === '[object Object]') {
            Object.keys(fieldsOrcb).forEach(key => {
                item[key] = item[fieldsOrcb[key]]
            })

            if (mode === 'new') { // 删除原有字段
                const arr1 = Object.keys(item)
                const arr2 = Object.keys(fieldsOrcb)
                const arr3 = arr1.filter(a1 => {
                    return !arr2.includes(a1) && a1 !== 'children'
                })
                arr3.forEach(a3 => delete item[a3])
            }
        }

        if (item.children) {
            iterator(item.children, changeItem)
        }
    }

    iterator(data, changeItem)
    return [ ...data ]
}

const TreeSelect = ({
    style,
    className,
    value = [],
    onChange,
    disabled,
    size = 'middle',
    label = 'Competitor',
    labelStyle,
    labelClassName,
    placeholder = 'Competitor',
    selectorTextWhenSelectAll = 'All',
    dropdownTitle,
    dropdownMaxHeight = 300,
    dropdownStyle,
    dropdownClassName,
    dropdownLabelStyle,
    dropdownLabelClassName,
    selectAll = true,
    clearAll = true,
    minCount = 0,
    maxCount = 9999,
    multiple = true,
    excludeVendor,
    ...props
}: any) => {
    const selectRef = useRef<BaseSelectRefProps>(null)

    /* ************************* competitorOptions ******************************* */
    const [ regionValue ] = useFilterCountry()
    const [ competitorOptions, setCompetitorOptions ] = useState<any[]>([])

    useEffect(() => {
        const customer = storage.getCustomerVendor()
        if (customer === LICENSE_CANADAGOOSE) {
            let options = getFilterCompetitorChildren({ region: regionValue, competitors: [], excludeSelf: true })
            options = options.filter(item => ![ ALL_VENDORS, SELECTED_VENDORS ].includes(item.label))
            options = recursion(options, item => {
                item.value = item.val || item.label
                delete item.active
                delete item.enableSelectAll
                delete item.masked
                delete item.type
                delete item?.val
                delete item?.region
            })
            setCompetitorOptions(options)
        } 
        else {
            let options = handleFilterCompetitorOptions(regionValue, false, true)
            options = options.filter(item => ![ ALL_VENDORS, SELECTED_VENDORS ].includes(item.description))
            options = options.map(item => {
                if (item.key === excludeVendor) {
                    return undefined
                }
                const newItem: any = {
                    value: item.key,
                    label: item.description,
                }
                if (Array.isArray(item?.brands) && item?.brands?.length) {
                    newItem.children = item.brands.map(item2 => {
                        const vendorCode = `${item2}@${item.value}`
                        if (excludeVendor === vendorCode) {
                            return undefined
                        }
                        return {
                            value: vendorCode,
                            label: getVendorNameByCode(vendorCode)?.split('@')[0],
                        }
                    }).filter(item => item)
                }
                return newItem
            }).filter(item => item)
            setCompetitorOptions(options)
        }
    }, [ regionValue, excludeVendor ])

    /* ************************* dropdown ******************************* */
    const dropdownSty: CSSProperties = {
        padding: 0,
        minWidth: 164,
        maxHeight: 330,
        ...dropdownStyle,
    }

    const [ data, setData ] = useState(value)

    useDeepCompareEffect(() => {
        setData(value)
    }, [ value, [] ])

    const onOk = () => {
        if (!data?.length) {
            setData(value)
            selectRef.current?.close()
            return
        }
        onChange?.(data)
        selectRef.current?.close()
    }

    const [ selectorValue, setSelectorValue ] = useState('')

    useDeepCompareEffect(() => {
        const newData = data.map(item => getVendorNameByCode(item))
        if (newData.length > 1) {
            const desc = newData[0]
            setSelectorValue(`${desc.length > 7 ? desc.slice(0, 6) + '...' : desc}, +${newData.length - 1}`)
        } else if (newData.length === 1) {
            setSelectorValue(newData[0])
        } else {
            setSelectorValue('')
        }
    }, [ data, [] ])

    const renderDropdown = (
        <>
            <div style={{ padding: '4px 0 10px', maxHeight: 290, overflow: 'auto' }}>
                <SelectDropdownHeader 
                    title={label} 
                    onClick={() => selectRef.current?.close()}
                />
                {
                    multiple && (
                        <SelectButtonGroup 
                            clearAll
                            selectAll
                            onClearAll={() => setData([])}
                            onSelectAll={() => {
                                const value = competitorOptions.map(item => getLeafs(item))
                                    .flat(10)
                                setData(value)
                            }}
                        />
                    )
                }
                <Divider fullLength />
                <Tree
                    options={cloneDeep(competitorOptions)}
                    value={data}
                    multiple={multiple}
                    onChange={values => {
                        setData(values)
                        if (!multiple && values[0] !== value[0]) {
                            onChange?.(values)
                            selectRef.current?.close()
                        }
                    }}
                />
            </div>
            {
                multiple && (
                    <SelectOkButton onClick={onOk} />
                )
            }
        </>
    )

    return (
        <>
            <BaseSelect
                ref={selectRef}
                style={{ marginRight: 10, ...style }}
                className={className}
                disabled={disabled}
                label={label}
                labelStyle={{ fontSize: 10 }}
                labelClassName={labelClassName}
                selectorValue={selectorValue}
                placeholder={placeholder}
                renderDropdown={renderDropdown}
                dropdownStyle={dropdownSty}
                dropdownClassName={dropdownClassName}
                onClickAway={() => {
                    // 当前是关闭状态, 直接返回
                    if (!selectRef.current?.getIsOpen()) return;
                    onOk();
                }}
                {...props}
            />
        </>
    )
}

export default TreeSelect
