import React from 'react'
import { Descriptions } from '../Descriptions'
import { ProductPrice } from '../ProductPrice'
import styles from './styles.module.scss'

/**
 * 有两个地方用到该组件, 因此提出为公共组件使用
 * 1. /componentsv2/business/ProductCard/ProductCard.tsx
 * 2. /pages/CrossRegionPricing/components/ProductCard/ProductCard.tsx
 */
export const ProductDate = ({ product }) => (
        <Descriptions column={3}>
            <Descriptions.Item label="FIRST OBSERVED" value={product?.firstObservedDate} valueFontSize={22} />
            <Descriptions.Item label="FIRST DISCOUNTED" value={product?.firstDiscountDate || '-'} valueFontSize={22} />
            <Descriptions.Item label="FIRST OBSERVED PRICE" description="First observed sales price">
                <ProductPrice 
                    price={product?.firstPrice} 
                    currency={product?.currency} 
                    className={styles.firstPrice} 
                />
            </Descriptions.Item>
            <Descriptions.Item label="FULL PRICE">
                <ProductPrice price={product?.fullPrice} currency={product?.currency} className={styles.firstPrice} />
            </Descriptions.Item>
            <Descriptions.Item label="INTRODUCTION PRICE" description="First observed full price">
                <ProductPrice price={product?.introductionPrice} currency={product?.currency} className={styles.firstPrice} />
            </Descriptions.Item>
            <Descriptions.Item label="HIGHEST PRICE">
                <ProductPrice price={product?.highestPrice} currency={product?.currency} className={styles.firstPrice} />
            </Descriptions.Item>
            <Descriptions.Item label="LATEST RELAUNCH DATE" value={product?.lastRelaunchDate || '-'} valueFontSize={22} />
        </Descriptions>
    )

export const ProductDateForSizePrice = ({
    lastRelaunchDate,
    firstObservedDate,
    firstDiscountDate,
}: {
    lastRelaunchDate: any;
    firstObservedDate: any;
    firstDiscountDate: any;
}) => {
    return (
        <Descriptions column={3} style={{ gridTemplateColumns: '1.5fr 1.5fr 1.8fr', gridColumnGap: 0 }}>
            <Descriptions.Item label="LATEST RELAUNCH DATE" value={lastRelaunchDate || '-'} valueFontSize={22} />
            <Descriptions.Item label="FIRST OBSERVED DATE" value={firstObservedDate} valueFontSize={22} />
            <Descriptions.Item label="FIRST DISCOUNTED DATE" value={firstDiscountDate || '-'} valueFontSize={22} />
        </Descriptions>
    )
}
