import { useSelector } from 'react-redux'
import { selectCategory } from 'features/filters/filtersSlice'
import { IS_LIVE_ENV } from 'consts'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { isColorSplitPage } from 'global/Routes'
import { getDisabledCategoriesForColorSplitPage } from 'components/layout/Header/components/HeaderFilter/category.util'

/**
 * ## useValidCategories
 * 
 * filtered disabled categories and remain the avaliable categories
 * 
 * ## 示例
 * 
 */
export const useValidCategories = () => {

    const { metaCategories } = useSelector(getCacheData)
    const { listObj } = metaCategories

    // 右上角 Filter 选中 Category 值
    const filterCategories = useSelector(selectCategory)
    
    /**
     * filter avalid categories in color analysis view page
     * 
     */
    if (IS_LIVE_ENV && getDisabledCategoriesForColorSplitPage()?.length && isColorSplitPage()) {

        // expand add sub disabled categories
        const disabledListIncludingSubs = getDisabledCategoriesForColorSplitPage().map((categoryStr: string) => {
            if (listObj[categoryStr]?.list?.length) return listObj[categoryStr].list.map(item => item.name)
            return categoryStr
        }).flat(2)
        return filterCategories.filter(item => disabledListIncludingSubs.indexOf(item) === -1)
    }
    return filterCategories

}

