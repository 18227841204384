import { formatBrandVendor } from 'utils/competitors'
import { getVendorNameByCode } from 'utils'
import { isSuperArray, difference, sortVendorList } from 'utils/array'
import { formatProductName } from 'utils'
import { NA } from 'consts'
import { storage } from 'utils/storage'
import { ApiProductData } from './types'
import { numberUtils } from 'norna-uikit'

/**
 * 处理数据结构
 * 
 * regionValue
 *      第一次进入页面，regionValue 相当于 Filter.Country 全部值都选中，此时页面显示全部 Country 列表
 *      页面中只展示 regionValue 勾选的 Country 列表
 * 
 * filterCountryChanged  
 *      filterCountryChanged = true，表示 Filter.Country 改变，页面中显示的 Country 列表需要排序
 *      filterCountryChanged = false，表示 Select Country 下拉框改变，页面中显示的 Country 列表不要排序
 */
export function handleData({
    apiData,
    indexCurrency = 'SEK',
    indexRegion = 'Sweden',
    mode = 'identical',
    showCurrentPrice = true,
    regionValue = [],
    isLookbook,
}: {
    apiData: any;
    indexCurrency?: string;
    indexRegion?: string;
    mode?: 'identical' | 'all',
    showCurrentPrice?: boolean;
    regionValue?: string[];
    isLookbook?: boolean;
}): any[] {
    const customerSellerVendor = storage.getCustomerVendor()
    let newData: any[] = []
    let vendorList = Object.keys(apiData.total)
    // 对接口返回的数据做排序
    vendorList = sortVendorList({
        vendorList,
    })
    vendorList.forEach(vendor => {
        // 处理 vendor、brand、name 字段
        const vbnObj = handleVendorBrandName(vendor)

        let headerData: any[] = []
        let regionList = Object.keys(apiData.total[vendor])
        /**
         * 2022/04/30
         * regionList 只显示 regionValue 中包含的 Country
         */
        const emptyRegionList = Object.keys(apiData.total[vendor]).filter(r => !apiData.total[vendor][r]?.currency)
        regionList = regionList.filter(item => !emptyRegionList.includes(item))
        regionList = regionList.filter(item => regionValue.includes(item))
        regionList.forEach(region => {
            // 过滤产品
            let products: ApiProductData[] = []
            if (mode === 'identical') {
                const filterRegionValue = regionValue.filter(item => !emptyRegionList.includes(item))
                products = filterApiProducts({
                    products: apiData.products[vendor],
                    // 当前 vendor 拥有的 region list
                    regionValue: filterRegionValue,
                    region,
                })
            } else {
                products = filterApiProducts({
                    products: apiData.products[vendor],
                    region,
                })
            }
            // 求币种，求和时使用
            const { currency='' } = apiData.total[vendor][region]
            // 求和，将每个 Country 按照自己的币种计算一份平均价格
            const totalPrice = totalPriceApiProducts({
                products,
                region,
                currency,
                showCurrentPrice,
            })
            // 求和，将每个 Country 按照同一币种 indexCurrency 计算一份平均价格，用于计算页面上 index 列数值
            const indexTotalPrice = totalPriceApiProducts({
                products,
                region,
                currency: indexCurrency,
                showCurrentPrice,
            })
            // 求平均价格
            const averagePrice = handleAveragePrice({ totalPrice, length: products.length })
            const indexAveragePrice = handleAveragePrice({ totalPrice: indexTotalPrice, length: products.length })
            headerData.push({
                region,
                currency,
                price: `${numberUtils.formatNumberByComma(averagePrice.toFixed(2))} ${currency}`,
                indexPrice: indexAveragePrice,
                products,
                options: products.length,
                active: region === indexRegion,
            })
        })
        // 计算 headerData.index 数值
        headerData = handleIndex({
            data: headerData,
            indexRegion,
        })
        // header 里要补齐下拉框里选中的 region 但自身没有的 region 数据
        const existRegionList = headerData.map(item => item.region)
        const padRegionList = difference(regionValue, existRegionList)
        const padData = padRegionList.map(region => ({
            active: region === indexRegion,
            currency: '',
            index: NA,
            indexPrice: 0,
            options: 0,
            price: NA,
            products: [],
            region,
        }))
        headerData.push(...padData)
        // 排序
        headerData = regionValue.map(region => headerData.find(item => item.region === region))
        // 处理产品列表
        let allProductList = headerData.find(item => item.region === indexRegion)?.products || []
        // 处理产品数据结构
        allProductList = handleApiProducts({
            products: allProductList,
            vendor: vbnObj.vendor,
            indexCurrency,
            indexRegion,
            showCurrentPrice,
            customerSellerVendor,
        })
        /**
         * allProductList 过滤，产品的 region 只显示 regionValue 中包含的值
         */
        allProductList = allProductList.map(item => {
            item.data = item.data.filter(item => regionValue.includes(item.region))
            return item
        })
        /**
         * allProductList 排序，当前选中的 indexRegion 如果有要放在第一位，其它 Country 按首字母排序
         */
        allProductList = allProductList.map(item => {
            let newData = item.data
            /**
             * 每个产品要补齐下拉框转中选中的 region 但自身产品不包含的 region 的数据
             */
            const existRegionList = newData.map(item => item.region)
            const padRegionList = difference(regionValue, existRegionList).sort()
            const padData = padRegionList.map(region => ({
                active: region === indexRegion,
                currency: '',
                currencyPriceMapper: {},
                index: NA,
                indexPrice: 0,
                nornaid: '',
                price: NA,
                region,
                vendor: '',
            }))
            newData.push(...padData)
            newData = regionValue.map(r => newData.find(item => item.region === r))

            item.data = [ ...newData ]
            return item
        })

        newData.push({
            vendor: vbnObj.vendor,
            brand: vbnObj.brand,
            name: vbnObj.name,
            headerData,
            options: isLookbook ? numberUtils.formatNumberByComma(apiData?.products?.[vendor]?.length || 0) : numberUtils.formatNumberByComma(allProductList.length),
            allProductList,
            productList: allProductList.slice(0, 10),
        })
    })
    // 处理让每个 vendor 的 productList 都保持一样的长度
    newData = handleSameLengthProducts({ data: newData })
    return newData
}

/**
 * 处理接口返回的产品列表的数据结构，转换为页面可用的数据结构
 */
function handleApiProducts({
    products,
    vendor,
    indexCurrency,
    indexRegion,
    showCurrentPrice = true,
    customerSellerVendor,
}: {
    products: ApiProductData[];
    vendor: string;
    indexCurrency: string;
    indexRegion: string;
    showCurrentPrice: boolean;
    customerSellerVendor: string;
}) {
    const newProducts = products.map(product => {
        const regionList = Object.keys(product?.region || {})
        let data = regionList.map(region => {
            const regionData = product?.region[region]
            return {
                vendor,
                region,
                currency: regionData.currency,
                currencyPriceMapper: showCurrentPrice ? regionData?.latest_price_info || {} : regionData?.average_rate_price_info || {},
                nornaid: regionData.product.nornaid,
                lookbooks: regionData.lookbooks,
            }
        })
        data = handleTableData({
            data,
            indexCurrency,
            indexRegion,
            customerSellerVendor,
        })
        return {
            image: product.image,
            title: formatProductName(product.title),
            dhash: '',
            indexCurrency,
            indexRegion,
            data,
            vendorCode: vendor,
            vendorName: getVendorNameByCode(vendor),
        }
    })
    return newProducts
}

/**
 * 处理产品列表
 * 
 * ```
 * [
 *      {vendor: 'vendor1', products: [1, 2, 3]},
 *      {vendor: 'vendor1', products: [1,2,3,4,5,6]}
 * ]
 * ```
 * 
 * 产品字段 products 补齐，保持长度一致
 * 
 * ```
 * [
 *      {vendor: 'vendor1', products: [1,2,3,undefined,undefined,undefined]},
 *      {vendor: 'vendor1', products: [1,2,3,4,5,6]}
 * ]
 * ```
 */
function handleSameLengthProducts({
    data,
}: {
    data: Array<{ productList: any[] }>;
}) {
    let productListMaxLength = 0
    // eslint-disable-next-line
    data.forEach(item => productListMaxLength = Math.max(productListMaxLength, item.productList.length))
    const newData = data.map(item => {
        if (item.productList.length < productListMaxLength) {
            item.productList = [ ...item.productList, ...Array(productListMaxLength - item.productList.length).fill(undefined) ]
        }
        return item
    })
    return newData
}

/**
 * 过滤接口返回的产品列表
 * 
 * ```
 * vendor = [
 *      {region: 'Germany', currency: 'EUR'},
 *      {region: 'Austria', currency: 'EUR'},
 *      {region: 'Switzerland', currency: 'CHF'},
 * ]
 * ```
 * region: 上面代码示例中的 region 字段  
 * regionValue: 页面上展示的 Country 列表，如 ['Germany', 'Austria', 'Switzerland']
 * 
 * 
 * 规则1:(all) regionValue 没有传值，根据 region 过滤
 * 规则2:(identical) regionValue 传值了，根据 region 和 indexRegion 过滤
 */
function filterApiProducts({
    products,
    region,
    regionValue,
}: {
    products: ApiProductData[];
    region: string;
    regionValue?: string[];
}) {
    const productList = products.filter(product => {
        const rList = Object.keys(product.region)
        if (typeof regionValue !== 'undefined') {
            return isSuperArray(rList, regionValue)
        }
        return rList.includes(region)
    })
    return productList
}

/**
 * 计算接口返回的产品列表和
 * 
 * ```
 * products = [
 *      {
 *           region: {
 *               France: {      // ---> region
 *                   latest_price_info: {
 *                       CHF: 139,
 *                       CNY: 951,      // ---> currency
 *                       DKK: 1026,
 *                   },
 *               },
 *               Spain: {       // ---> region
 *                   latest_price_info: {
 *                       CHF: 123,
 *                       CNY: 841,      // ---> currency
 *                       DKK: 907,
 *                   },
 *               },
 *           },
 *      },
 * ]
 * ```
 * 
 * 根据 region 和 currency 找到具体的价格，然后计算累和
 */
function totalPriceApiProducts({
    products,
    region,
    currency,
    showCurrentPrice = true,
}: {
    products: ApiProductData[];
    region: string;
    currency: string;
    showCurrentPrice: boolean;
}): number {
    let total = 0
    // eslint-disable-next-line
    for (let i = 0; i < products.length; i++) {
        const product = products[i]
        const mapper = showCurrentPrice ? product.region[region]?.latest_price_info : product.region[region]?.average_rate_price_info
        total += mapper[currency]
    }
    return total
}

/**
 * 计算平均价格
 */
function handleAveragePrice({
    totalPrice,
    length,
}) {
    // 求平均价格
    let averagePrice = 0
    if (length > 0) {
        averagePrice = Number(numberUtils.divide(totalPrice, length).toFixed(2))
    }
    return averagePrice
}

/**
 * 处理 vendor
 * 
 * v = 'bimbaylola'
 * 这种就是普通的 vendor
 * 返回 vendor = 'bimbaylola', brand: '', name = 'Bimba y Lola' (vendor 的大写形式，在页面上展示这个)
 * 
 * v = 'elpalaciodehierro_Coach' (有下划线)
 * 这种是 vendor 和 brand 拼接的，格式为 vendor_brand
 * 返回 vendor = 'elpalaciodehierro', brand = 'Coach', name = 'Coach @ El Palacio de Hierro'
 */
function handleVendorBrandName(v) {
    let vendor = ''
    let brand = ''
    let name = ''
    const arr = v.split('_')
    if (arr.length === 1) {
        // eslint-disable-next-line
        vendor = arr[0]
        // eslint-disable-next-line
        name = getVendorNameByCode(arr[0])
    } else {
        // eslint-disable-next-line
        vendor = arr[0]
        // eslint-disable-next-line
        brand = arr[1]
        // eslint-disable-next-line
        name = formatBrandVendor(arr[1], getVendorNameByCode(arr[0]))
    }

    return {
        vendor,
        brand,
        name,
    }
}

/**
 * 处理 headerData 中 index 数值
 */
function handleIndex({
    data,
    indexRegion,
}: {
    data: { region: string; indexPrice: number; }[];
    indexRegion: string;
}) {
    let newData: { region: string; indexPrice: number;[key: string]: any; }[] = [ ...data ]
    const refPrice = newData.find(item => item.region === indexRegion)?.indexPrice

    if (refPrice) {
        newData = newData.map(item => {
            const index = (item.indexPrice / refPrice) * 100
            item.index = numberUtils.formatNumber(index, { isCommaSymbol: true, isPercentSymbol: true, decimal: 0 })
            return item
        })
    } else {
        newData = newData.map(item => {
            item.index = NA
            return item
        })
    }

    return newData
}

/**
 * 处理表格数据
 * 
 * { region, price, index }
 */
export function handleTableData({ data, indexCurrency, indexRegion, customerSellerVendor }: {
    data: { currency: string; region: string; currencyPriceMapper: Record<string, number>;[key: string]: any; }[];
    indexCurrency: string;
    indexRegion: string;
    customerSellerVendor: string;
}) {
    let newData: any = [ ...data ]
    newData = newData.map(item => {
        // 处理 headerData.price 字段
        // 01 取整 02 每三位用逗号隔开 03 拼接币种
        const price = numberUtils.formatNumberByComma(Number(item.currencyPriceMapper[item.currency]).toFixed(2))?.concat(` ${item.currency}`)

        return {
            region: item.region,
            currency: item.currency,
            price,
            // 根据 indexCurrency 转换为统一币种的价格，用于计算 index
            indexPrice: item.currencyPriceMapper[indexCurrency],
            ...item,
            active: item.region === indexRegion,
        }
    })

    const refPrice = newData.find(item => item.region === indexRegion)?.indexPrice
    if (refPrice) {
        newData = newData.map(item => {
            item.index = numberUtils.formatNumber(item.indexPrice / refPrice, { 
                isCentuple: true, 
                isCommaSymbol: true, 
                isPercentSymbol: true, 
                decimal: 0, 
            })
            return item
        })
    } else {
        newData = newData.map(item => {
            item.index = NA
            return item
        })
    }

    return newData
}

/**
 * 获取实际宽度
 * @returns 
 */
 export const getActualWidth = () => {
    const headerEles = document.querySelectorAll('.pricing-jump-table > div')

    if (headerEles) {
        let width = 0
        headerEles.forEach(ele => {
            width += ele.getBoundingClientRect().width
        })

        const pageEle = document.querySelector('.page-container')
        width += 140
        // 小于页面宽度
        if(pageEle && pageEle.getBoundingClientRect().width>width){
            return 0
        }
        return width 
    }

    return 0
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    data.push([
        ...dataSource.map(item => {
            return [
                item.name,
                '', '', '',
            ]
        }).flat(2),
    ])

    data.push([
        ...dataSource.map(item => {
            return [
                item?.options?.replace(',', ''),
                '', '', '',
            ]
        }).flat(2),
    ])

    data.push([
        ...dataSource.map(item => {
            return [
                'Country',
                'Price',
                'Currency',
                'Index',
            ]
        }).flat(2),
    ])

    const regionList = dataSource?.[0]?.headerData?.map(item => item.region)
    regionList.forEach(region => {
        let dataRow: any[] = []
        dataSource.forEach(item => {
            const regionData = item?.headerData?.find(item2 => item2.region === region)
            dataRow.push(...[
                regionData?.region,
                regionData?.price?.replace(',', '')?.split(' ')?.[0],
                regionData?.currency,
                regionData?.index,
            ])
        })
        data.push([ ...dataRow.flat(10) ])
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
