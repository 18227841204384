import React, { useState, useRef, useEffect, useDeferredValue } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import classnames from 'classnames'
import { cloneDeep } from 'lodash'
import { CaretOutlineIcon } from 'assets/icons/CaretOutline'
import { Spin } from 'componentsv2/Spin'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Switch } from 'componentsv2/form-elements/Switch'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { PricePerspectiveDropdown } from 'componentsv2/business/PricePerspectiveDropdown'
import { MultipleDropdown } from 'componentsv2/form-elements/MultipleDropdown'
import { NornaDoughnutChart } from 'componentsv2/NornaDoughnutChart'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import productStyles from 'components/Product/styles.module.scss'
import { UseDialogType } from 'hooks/useDialog'
import { loadingBar, useCustomerVendor, useExcludeProductsComparisonQuery, useLoadingBar, useManualLoading, useScrollToTop } from 'hooks'
import { useFilterCompetitor, useFilterCountry, useFilterCurrency, useFilterPricePerspective } from 'hooks/useFilter'
import { useFetch } from 'libs/hookRequest'
import { useCompetitorOptions, useRegionOptions } from 'hooks/useOptions'
import { argFnProductRegionsDistribution } from 'graphql/nornaapi/nornaData'
import { useSelector } from 'react-redux'
import { usePageDate } from 'hooks/usePageDate'
import { formatProductName, regionShortMappingFn } from 'utils'
import { isSuperArray } from 'utils/array'
import { formatVendorName } from 'utils/sellers'
import { downloadFileByAxios } from 'export'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { isSubsetPayload, UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, UPDATE_NEWCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { Dropdown } from 'componentsv2/form-elements/Dropdown'
import { StickyHeader } from 'componentsv2/StickyHeader'
import { useLookbook } from 'hooks/useLookbook'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { SizedBox } from 'componentsv2/SizedBox'
import { logoMapper } from 'componentsv2/VendorLogo'
import { TagWithTips } from 'componentsv2/MenuBar/FilterTags'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { LookbookWithoutData } from 'componentsv2/LookbookPlaceholder'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import { useScrollBar2 } from 'hooks/useScrollBar'
import { useWindowZoom } from 'hooks/useWindow'
import { isCompetitorGroupChanged } from 'utils/group'
import { MARKET_VENDOR, NA, SELECTED_VENDORS, LICENSE_BIMBA } from 'consts'
import { crossRegionPricingExportFilename } from 'utils/exportUtils'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { useModuleData } from 'hooks/useModuleData'
import { CROSS_REGION_PRICING_NAME } from 'configs'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { Table } from './components/Table'
import { ProductCard } from './components/ProductCard'
import { ProductImage } from './components/ProductImage'
import { geneCsvData, getActualWidth, handleData as handleApiData } from './utils'
import styles from './styles.module.scss'
import GraphButton from 'componentsv2/GraphButton'
import { getOnlyShowSizeProductsForCompetitors } from 'utils/feConfig'
import { SizePriceProductCard } from './components/ProductCard/SizePriceProductCard'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_CROSS_REGION_PRICING } from 'features/filters/moduleDataSlice.const'
import { TopScrollbar, dateRangeUtils } from 'norna-uikit'

export const CrossRegionPricing = () => {
    useScrollToTop()
    useLoadingBar()
    const { pageDate: dateRangeValue } = usePageDate()
    const { manualLoading, showManualLoading } = useManualLoading()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCurrency ] = useFilterCurrency()
    const cacheData = useSelector(getCacheData)
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const customerVendor = useCustomerVendor()
    const { competitorOptions } = useCompetitorOptions()
    const zoom = useWindowZoom()

    const [ moduleData, setModuleData ] = useModuleData(CROSS_REGION_PRICING_NAME)
    const { 
        fetchPayload = {}, 
        apiData = {},
        oldCompetitorValue = [],
        newCompetitorValue = [],
        regionValue = [],
        priceSampleValue = 'all',
        switchValue = false,
        indexRegion = '',
        indexCurrency = '',
    } = moduleData

    const columns: any[] = [
        {
            title: 'Country',
            dataIndex: 'region',
            width: '95px',
            render: text => (
                <div style={{ width: '95px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{regionShortMappingFn(text)}</div>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width: '85px',
            render: text => (
                <div title={text} style={{ width: '85px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>
            ),
        },
        {
            title: 'Index',
            dataIndex: 'index',
            width: '45px',
            render: text => (
                <div style={{ color: '#31B4A7', width: '45px', textAlign: 'left' }}>{text}</div>
            ),
        },
    ]

    /* ************************************ Export excel ******************************************* */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        if (!payload?.query) payload.query = {}
        payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        payload.data.groups = getGroupsPayload({ competitorSellers: filterCompetitor })
        payload.data.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
        payload.query.selected_region = indexRegion
        payload.query.average_currency_rate = !switchValue
        payload.query.only_identical = priceSampleValue !== 'all'
        payload.data.export_regions = [ ...regionValue ]
        await downloadFileByAxios({
            filename: crossRegionPricingExportFilename,
            payload,
        })
    }

    /* ************************************ Lookbook ******************************************* */
    const { checked: checkedLookbooks, isLookbook } = useLookbook()
    const { changing: lookbookActionChanging } = useLookbookAction(true)
    const [ lookbookWithoutData, setLookbookWithoutData ] = useState(false)

    /* ************************************ Form ******************************************* */
    /**
     * PRICE SAMPLE dropdown
     * 
     * all regions: France、Spain、China、Japan
     * selected regions: France、Spain、China
     * 
     * identical: filter out products that contain all selected regions
     * all: for France, filter out product that contain France
     */
     const setPriceSampleValue = (value: string) => {
        setModuleData({ priceSampleValue: value })
    }

    // COUNTRIES dropdown
    const { regionOptions } = useRegionOptions()

    const setRegionValue = (value: string[]) => {
        setModuleData({ regionValue: cloneDeep(value) })
    }

    useDeepCompareEffect(() => {
        if (regionValue.length || !regionOptions.length) return

        const keys = regionOptions.filter(item => item.isDisabled === false).map(item => item.key)
        if (customerVendor === LICENSE_BIMBA && !keys.includes('El Palacio de Hierro')) {
            keys.push('El Palacio de Hierro')
        }
        setRegionValue(keys)
    }, [ regionOptions, regionValue ])

    // true is Current，false is Fixed (The price of the average exchange rate over the last six months)
    const setSwitchValue = (value: boolean) => {
        setModuleData({ switchValue: value })
    }

    const setIndexRegion = (value: string) => {
        setModuleData({ indexRegion: value })
    }

    useEffect(() => {
        if (indexRegion || !filterCountry) return
        setIndexRegion(filterCountry)
        // eslint-disable-next-line
    }, [filterCountry])

    const setIndexCurrency = (value: string) => {
        setModuleData({ indexCurrency: value })
    }

    useEffect(() => {
        if (indexCurrency || !filterCurrency) return
        setIndexCurrency(filterCurrency)
        // eslint-disable-next-line
    }, [filterCurrency])

    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: {
                competitorValue: cloneDeep(filterCompetitor.map(item => item.vendor)),
            },
        })
    }, [ filterCompetitor ])

    /* ************************************ Data ******************************************* */
    const { postFn: fetch, data, loading, setData } = useFetch()
    const [ dataSource, setDataSoure ] = useState<any[]>([])
    const [ , setCsvData ] = useCsvDataModuleData()
    const deferredDataSource = useDeferredValue(dataSource)
    const deferredIndexCurrency = useDeferredValue(indexCurrency)
    const deferredIndexRegion = useDeferredValue(indexRegion)

    // hide show more button when all vendor's productList length lte allProductList length
    const hideShowMoreBtn = dataSource.every(item => item.productList.length >= item.allProductList.length)

    const fetchData = () => {
        if (!dateRangeValue || !competitorOptions?.length) return
        
        const query = cloneDeep(comparisonQuery)
        query.sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor.filter(item => item.vendor !== MARKET_VENDOR.vendor) ] })
        query.market = []
        query.groups = getGroupsPayload({ competitorSellers: [ ...filterCompetitor.filter(item => item.vendor !== MARKET_VENDOR.vendor) ] })
        const payload = argFnProductRegionsDistribution(query, dateRangeUtils.from(dateRangeValue), filterPricePerspective, isLookbook ? checkedLookbooks : null)

        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload }) && Object.keys(apiData || {}).length) {
            showManualLoading()
            setTimeout(() => {
                setData?.(apiData)
            }, 500)
            return
        }

        setModuleData({
            type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE,
            payload: cloneDeep(payload),
        })
        fetch(payload.url, payload)
    }

    const [ switchLoading, setSwitchLoading ] = useState(false)

    const handleData = (c: any) => {
        setLookbookWithoutData(isLookbook && Object.keys(c?.total || {})?.length === 0)
        if (!c || regionValue.length === 0 || !Object.keys(c?.total || {})?.length) return
        
        const d = { products: {}, total: {} }
        Object.keys(c?.total || {}).forEach(vendor => {
            d.products[vendor] = [ ...c.products[vendor] ]
            d.total[vendor] = { ...c.total[vendor] }
        })

        if (!isLookbook) {
            Object.keys(d?.total || {}).forEach(vendor => {
                if (!newCompetitorValue.includes(vendor)) {
                    delete d.total[vendor]
                    delete d.products[vendor]
                }
            })
        }

        Object.keys(d.products).forEach(vendor => {
            const queryDateValid = isQueryDateValid({ vendor, region: indexRegion, competitorValue: newCompetitorValue, isValidWhenLaunchDateNotExist: true })
            if (!queryDateValid) {
                d.products[vendor] = []
                Object.keys(d.total[vendor]).forEach(region => {
                    d.total[vendor][region] = {}
                }) 
            }
        })

        const dataSource = handleApiData({
            apiData: { ...d },
            indexCurrency,
            indexRegion,
            mode: priceSampleValue,
            showCurrentPrice: switchValue,
            regionValue,
            isLookbook,
        })

        dataSource.forEach(item => {
            item.queryDateValid = isQueryDateValid({ vendor: item.vendor, region: indexRegion, competitorValue: newCompetitorValue, isValidWhenLaunchDateNotExist: true })
        })

        setDataSoure(dataSource)
        setSwitchLoading(false)
        setCsvData({
            [CSV_DATA_CROSS_REGION_PRICING]: geneCsvData({ dataSource }),
        })
    }   

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, dateRangeValue, filterPricePerspective, checkedLookbooks, competitorOptions ])

    useEffect(() => {
        if (!isLookbook) return
        fetchData()
        // eslint-disable-next-line
    }, [lookbookActionChanging, isLookbook, competitorOptions])

    useDeepCompareEffect(() => {
        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }
        if ([ ...oldCompetitorValue ]?.sort()?.toString() !== [ ...newCompetitorValue ]?.sort()?.toString() && isSuperArray(oldCompetitorValue, newCompetitorValue) && Object.keys(apiData || {}).length) {
            showManualLoading()
            setTimeout(() => {
                handleData({ ...apiData })
            }, 500)
            return
        }

        fetchData()
    }, [ newCompetitorValue ])

    const deferredPriceSampleValue = useDeferredValue(priceSampleValue)
    const deferredSwitchValue = useDeferredValue(switchValue)
    const deferredRegionValue = useDeferredValue(regionValue)

    useEffect(() => {
        if (!data) return

        handleData(data)
        setModuleData({ apiData: { ...data } })
        // eslint-disable-next-line
    }, [data || {}, deferredIndexRegion, deferredIndexCurrency, deferredPriceSampleValue, deferredSwitchValue, deferredRegionValue, filterCountry, isLookbook])

    const onShowMore = () => {
        let newDataSource = cloneDeep(dataSource)
        newDataSource = newDataSource.map(item => {
            item.productList = item.allProductList.slice(0, item.productList.length + 10)
            return item
        })
        let productListMaxLength = 0
        // eslint-disable-next-line
        newDataSource.forEach(item => productListMaxLength = Math.max(productListMaxLength, item.productList.length))
        newDataSource = newDataSource.map(item => {
            if (item.productList.length < productListMaxLength) {
                item.productList = [ ...item.productList, ...Array(productListMaxLength - item.productList.length).fill(undefined) ]
            }
            return item
        })
        setDataSoure(newDataSource)
    }

    /* ************************************ Product Card Modal ******************************************* */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    const onShowProductDetailModal = async (e, product) => {
        e.stopPropagation()
        const nornaid = product.data.find(item => item.region === product.indexRegion)?.nornaid || ''
        const vendors = getOnlyShowSizeProductsForCompetitors()
        const vendorCode = product.data.find(item => item.region === product.indexRegion)?.vendor || ''
        const closeFn = dialogRef.current?.closeDialog

        if (vendors.includes(vendorCode)) {
            dialogRef.current?.openDialog?.('Product detail', (
                <div className={productStyles.quickView}>
                    <SizePriceProductCard
                        nornaid={nornaid}
                        closeProductDetails={closeFn}
                        toCurrency={product.indexCurrency}
                        priceDetail={product.data}
                        indexRegion={regionShortMappingFn(indexRegion)}
                    />
                </div>
            ), UseDialogType.PROUDUCT_DETAIL)
            return
        }

        dialogRef.current?.openDialog?.('Product detail', (
            <div className={productStyles.quickView}>
                <ProductCard
                    nornaid={nornaid}
                    closeProductDetails={closeFn}
                    toCurrency={product.indexCurrency}
                    priceDetail={product.data}
                    indexRegion={regionShortMappingFn(indexRegion)}
                />
            </div>
        ), UseDialogType.PROUDUCT_DETAIL)
    }

    /* ************************************ Synchronize two horizontal scroll bars  ******************************************* */
    useScrollBar2('.pricing-jump-table', '.sticky-header', dataSource)

    return (
        <div className={styles.container} id="export-wrapper">
            <ModuleTitle 
                style={{ position: 'relative', zIndex: 200 }}
                category="PRICING" 
                title="Cross region pricing" 
                showDate
                subTitle={(
                    <InfoBox
                        iconSty={{ margin: '0 10px' }}
                        containerSty={{ left: '-150px' }}
                        title="title"
                        text="tips content"
                    >
                        <TipsContent sty={{ padding: 0 }}>
                            The "Cross-region pricing" analytics shows prices across markets for product categories or identical products, depending on your selection in the "options sample" filter, for the markets that you have selected in the "countries" filter. A product needs to exist in all the markets that you have selected to show up in the analytics, when the "only identical options" options sample is selected. As such it is important to be aware that the more countries you select, the lesser the likelihood will be that a product exists in all these markets simultaneously.
                            <br /><br />
                            The product count in the cross region pricing analysis will deviate from the product count in the other sections of Norna Analytics. This as the cross region pricing analysis shows the number of products that existed during the selected time period, without any adjustment if products existed only during a fraction of the selected time period. This adjustment happens in all other reports of Norna Analytics.
                        </TipsContent>
                    </InfoBox>
                )} 
            />
            <SizedBox height={10} />
            <Spin 
                useSpinImg
                spinning={loading || manualLoading || switchLoading || !dataSource?.length} 
                zIndex={103} 
                minHeight={1000}
            >
                <ShouldRender shouldRender={lookbookWithoutData}>
                    <LookbookWithoutData />
                </ShouldRender>
                <ShouldRender shouldRender={!lookbookWithoutData}>
                    <div className={classnames(styles.form, 'cross-region-tool')} style={{ top: (-30 / zoom) + 'px' }}>
                        <MultipleDropdown
                            right={10}
                            label="COUNTRIES MATCHED"
                            style={{ width: '140px' }}
                            title="Region"
                            showClearAll
                            showSelectAll
                            minCount={1}
                            options={cloneDeep(regionOptions).map(item => {
                                item.description = regionShortMappingFn(item.description)
                                return item
                            })}
                            value={regionValue}
                            onOk={value => {
                                if (!value.includes(indexRegion) && value.length > 0) {
                                    setIndexRegion(value[0])
                                }
                                value = regionOptions.map(item => item.key).filter(item => value.includes(item))
                                setRegionValue(value as string[])
                                setSwitchLoading(true)
                            }}
                        />
                        <Dropdown
                            right={10}
                            label="OPTIONS SAMPLE"
                            options={[ { key: 'all', description: 'All options' }, { key: 'identical', description: 'Only identical options' } ]}
                            value={priceSampleValue}
                            onChange={value => {
                                setPriceSampleValue(value as 'identical' | 'all')
                                setSwitchLoading(true)
                            }}
                        />
                        <PricePerspectiveDropdown
                            right
                            enableFilter
                        />
                        <ExportDropdown
                            onExportExcel={onExportExcel}
                            calcJpgWidthFn={getActualWidth}
                            enableForceLoadImg
                            scale={1}
                            beforeFn={() => loadingBar.start()}
                            afterFn={() => loadingBar.done()}
                            adjustElementFn={ele => {
                                const width = getActualWidth()
                                if (width !== 0) {
                                    const headerEle: HTMLDivElement = ele.querySelector('.pricing-jump-table') as HTMLDivElement
                                    if (headerEle) headerEle.style.width = `${width}px`
                                }

                                const headerTool: HTMLDivElement = ele.querySelector('.cross-region-tool') as HTMLDivElement
                                if (headerTool) {
                                    headerTool.style.top = '0px'
                                    headerTool.style.position = 'relative'
                                }
                            }}
                            fileName={crossRegionPricingExportFilename}
                            right={10}
                        />
                        <GraphButton />
                        <div style={{ flex: 1 }} />
                        <Switch
                            label="ANCHOR POINT CURRENCY"
                            style={{ marginTop: '10px' }}
                            value={switchValue}
                            onChange={value => {
                                setSwitchValue(value)
                                setSwitchLoading(true)
                            }}
                            leftLabel="Fixed"
                            rightLabel="Current"
                        />
                    </div>
                    <StickyHeader
                        innerWidth={(dataSource.length - 1) * 275 + 285}
                        scrollTop={140}
                        extraTop={75}
                        selector=".pricing-jump-table"
                    >
                        <div className={styles.stickHeaderWrap}>
                            {
                                dataSource.map(item => (
                                    <div key={item.name} className={styles.stickHeaderTitle}>
                                        <div>{formatVendorName(item.name).name}</div>
                                        <div style={{ fontSize: '12px', color: '#666' }}>{formatVendorName(item.name).platform}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </StickyHeader>
                    <TopScrollbar className="pricing-jump-table">
                        <div className={styles.body}>
                            {
                                deferredDataSource.map((item, index) => {
                                    const c = [ ...columns ]
                                    if (index === 0) {
                                        c.push({
                                            title: (
                                                <div style={{ width: '40px', paddingLeft: '14px', paddingTop: '2px' }}>
                                                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2197" width="12" height="12">
                                                        <path d="M519.872 168.336C290.768 168.336 95.12 310.848 15.84 512c79.272 201.152 274.928 343.664 504.032 343.664 229.12 0 424.768-142.512 504.04-343.664-79.272-201.152-274.696-343.664-504.04-343.664z m0 572.776c-126.464 0-229.104-102.64-229.104-229.112s102.64-229.104 229.104-229.104c126.472 0 229.12 102.64 229.12 229.104s-102.648 229.112-229.12 229.112z m0-366.568c-75.832 0-137.464 61.624-137.464 137.456s61.632 137.464 137.464 137.464S657.344 587.84 657.344 512s-61.632-137.456-137.472-137.456z m0 0" p-id="2198" fill="#bfbfbf" />
                                                    </svg>
                                                </div>
                                            ),
                                            dataIndex: 'active',
                                            align: 'center',
                                            width: '40px',
                                            render: (text, record) => (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px' }}>
                                                    <input
                                                        type="radio"
                                                        checked={text}
                                                        onChange={() => {
                                                            setSwitchLoading(true)
                                                            if (record.currency) {
                                                                setIndexCurrency(record.currency)
                                                            } else {
                                                                setIndexCurrency(cacheData.regionToCurrency[record.region])
                                                            }
                                                            setIndexRegion(record.region)
                                                        }}
                                                    />
                                                    <div className={classnames({ [styles.inputRadio]: true, [styles.radioChecked]: text === true })} />
                                                </div>
                                            ),
                                        })
                                    }

                                    return (
                                        <div key={item.name} className={styles.vendor}>
                                            <div className={styles.headerWrap}>
                                                <div className={styles.header}>
                                                    <div className={styles.headerLogo} style={{ height: item.vendor === SELECTED_VENDORS ? '74px': '50px' }}>
                                                        {logoMapper[item.vendor] || item.vendor}
                                                    </div>
                                                    <div className={styles.platformName}>
                                                        {formatVendorName(item.vendor).platform}
                                                    </div>
                                                    <NornaDoughnutChart
                                                        value={100}
                                                        content={isLookbook ? `#${item.options}` : item?.queryDateValid ? `#${item.options}` : NA}
                                                        contentFontSize={14}
                                                        size={144}
                                                        style={{ marginBottom: '12px' }}
                                                    />
                                                    <Table
                                                        columns={c}
                                                        dataSource={item.headerData}
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.productListTitle}>
                                                <TagWithTips width="100%" label={formatVendorName(item.name).name} />
                                                <TagWithTips width="100%" style={{ fontSize: '12px', color: '#666' }} label={formatVendorName(item.name).platform} />
                                            </div>

                                            {
                                                item.productList.map((product, ind) => {
                                                    if (typeof product === 'undefined') {
                                                        /**
                                                         * bimba + cross region pricing 页面硬编码加了一个【El Palacio】region, 因此 tbody 高度也要改下 
                                                         */
                                                        const height = 441 + 24 * ((cacheData?.regions || []).length + (customerVendor === 'bimbaylola' ? 1 : 0))
                                                        return (
                                                            <div
                                                                className={styles.productWrap}
                                                                style={{ height: height + 'px' }}
                                                                key={item.name + ind.toString()}
                                                            >
                                                                {ind === 0 ? <div className={styles.noData}>No data</div> : ''}
                                                            </div>
                                                        )
                                                    }

                                                    const nornaid = product.data?.find(item => item.region === indexRegion)?.nornaid
                                                    const lookbooks = product.data?.find(item => item.region === indexRegion)?.lookbooks

                                                    return (
                                                        <div className={styles.productWrap} key={product?.title + ind.toString()}>
                                                            <div
                                                                className={styles.product}
                                                                onClick={e => onShowProductDetailModal(e, product)}
                                                            >
                                                                <ProductImage 
                                                                    nornaid={nornaid} 
                                                                    image={product.image} 
                                                                    lookbooks={lookbooks} 
                                                                    name={item.name} 
                                                                    vendorCode={product.vendorCode}
                                                                    vendorName={product.vendorName}
                                                                />
                                                                <div style={{ fontSize: '11px', color: '#484848', paddingLeft: '4px' }}>
                                                                    <div className={styles.productTitle}>{formatProductName(product.title)}</div>
                                                                    <div className={styles.productVendor}><TagWithTips width="100%" label={`${item.name} @ ${item.name}`} /></div>
                                                                </div>
                                                                <Table
                                                                    columns={columns}
                                                                    dataSource={product.data}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <ShouldRender shouldRender={index === 0 && !hideShowMoreBtn}>
                                                <div className={styles.showMoreBtn} onClick={onShowMore}>
                                                    <CaretOutlineIcon />
                                                    Show more
                                                </div>
                                            </ShouldRender>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </TopScrollbar>
                </ShouldRender>
            </Spin>
            <Dialog ref={dialogRef} />
        </div>
    )
}
