import React, { useState } from 'react'
import styles from './styles.module.scss'

export const NavigationContext = React.createContext({
  collapsed: false,
  setCollapsed: (collapsed: boolean) => {},
})

export const NavigationRow = ({ children }: any) => <div className={styles.row}>{children}</div>

export const Navigation = ({ children }: any) => {
  const [ collapsed, setCollapsed ] = useState(false)

  return (
      <NavigationContext.Provider value={{ collapsed, setCollapsed }}>
          <div className={styles.header}>{children}</div>
      </NavigationContext.Provider>
  )
}

Navigation.Row = NavigationRow
