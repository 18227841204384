import React, { useState } from 'react'
import styles from './ResellerRanking.module.scss'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { numberUtils } from 'norna-uikit'

const ResellerRanking = ({
    apiData,
}) => {
    const [ dataSource, setDataSource ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        if (!Object.keys(apiData || {})?.length) return
        const newData = Object.keys(apiData || {}).map(reseller => {
            const data = apiData[reseller]
            return {
                reseller,
                options: numberUtils.formatNumber(data?.count || 0, { isCommaSymbol: true, decimal: 0 }),
                total: numberUtils.formatNumber(data?.percent, { isCentuple: true, isPercentSymbol: true, isCommaSymbol: true, decimal: 2 }),
            }
        })
        setDataSource(newData)
    }, [ apiData, {} ])

    return (
        <div className={styles.wrapper}>
            <div className={styles.table}>
                <div className={styles.thead}>
                    <div className={styles.ranking}>
                        Ranking
                    </div>
                    <div className={styles.reseller}>
                        Sellers
                    </div>
                    <div className={styles.total}>
                        % of total
                    </div>
                </div>
                <div className={styles.tbody}>
                    {
                        dataSource?.map((item, index) => {
                            return (
                                <div 
                                    key={index}
                                    className={styles.tr}
                                >   
                                    <div className={styles.ranking}>
                                        {index + 1}.
                                    </div>
                                    <div className={styles.reseller}>
                                        {item.reseller}
                                    </div>
                                    <div className={styles.total}>
                                        {item.total}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className={styles.verticalLine1} />
                    <div className={styles.verticalLine2} />
                    <div className={styles.verticalLine3} />
                </div>
            </div>
        </div>
    )
}

export default ResellerRanking
