import { Button } from 'druikit'
import React, { memo, useState } from 'react'
import { RiseOutlined } from '@ant-design/icons'
import GraphModal from './components/GraphModal'

const GraphButton = () => {
    const [ modalVisible, setModalVisible ] = useState(false)

    const onOpenModal = () => {
        setModalVisible(true)
    }

    const onCloseModal = () => {
        setModalVisible(false)
    }

    return (
        <>
            <Button type="danger" onClick={onOpenModal}>
                <RiseOutlined style={{ marginRight: 10 }} />
                Graph
            </Button>
            {
                modalVisible && (
                    <GraphModal onCancel={onCloseModal} />
                )
            }
        </>
    )
}

export default memo(GraphButton)
