import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { cloneDeep, intersection, sortBy, uniq, values } from 'lodash'
import { CacheKeys, setMemoCache } from 'caches'
import { regionShortMappingFn } from '../../utils/index'
import { RootState } from '../../app/store.type'
import { CacheDataState } from './cacheDataSlice.types'

/**
 * ## 说明一：成对出现的变量业务说明
 * - regions 和 unregions
 * - currencys 和 uncurrencys
 * - targets 和 untargets
 * 含义：un- 前缀开头的变量在 Tree 里都是不可选的(disabled状态)，没有 un- 开头的变量都是可勾选的
 * 业务场景：之前经常需要 mock 一个数据在前端只可以看，不可以操作，之前的做法是在前端硬编码，现在只需要在库里将 un- 开头前缀的变量加上对应数据即可
 */
export const initData = {
  regions: [],                        // regions self custom by api
  unregions: [],                        //  un regions self custom by api
  targets: [],                        // un targets self custom by api
  regionToCurrency: {},
  untargets: [],                        // targets self custom by api

  targetsOrder: [],                     // targets order by api
  currencys: [],                        //
  uncurrencys: [],                        //
  sellersFalse: [],                   //
  metaCategories: [],                  // meta categories by api
  notShowSubGroupsMap: {},             // not show property group
  originalProperties: {},              // original format property data that returned by api
  latestDate: '',                     //
  sellers: [],
  ourSeller: {},//
  account: {},//
  isReady: false,                     //
  qaPermission: [],                   // qa permission list
  adminMetaData: {},
  unavailableMenu: [],                // unavailable menus
  unavailableFilter: [],              // unavailable filter
  vendorOrder: [],
}

const initialState: CacheDataState = initData as CacheDataState

export const cacheDataSlice = createSlice({
  name: 'cacheData',
  initialState,
  reducers: {
    initRegionsAndTargetsData(state, action) {                      // init regions and targets
      state.regions = action.payload.regions
      state.unregions = action.payload.unregions
      state.regionToCurrency = action.payload.regionToCurrency
      state.targets = uniq(action.payload.targets) as string[]
      state.untargets = action.payload.untargets

      // Gender Order Of Td
      state.targetsOrder = [ ...action.payload.targets, ...action.payload.untargets ]
      state.currencys = action.payload.currencys
      state.uncurrencys = action.payload.uncurrencys
      state.latestDate = action.payload.latestDate
      state.ourSeller = action.payload.ourSeller
      state.notShowSubGroupsMap = action.payload.notShowSubGroupsMap
      state.account = action.payload.account
      state.sellers = sortBy(action.payload.sellers, 'vendor')

      state.metaCategories = action.payload.metaCategories
      state.originalProperties = action.payload.originalProperties // originalProperties

      state.unavailableMenu = action.payload.unavailableMenu
      state.unavailableFilter = action.payload.unavailableFilter

      state.vendorOrder = action.payload.vendorOrder || []
      state.isReady = true
      setMemoCache(CacheKeys.CACHE_SLICE, cloneDeep(action.payload))
    },
    initSellersFalse(state, action) {                               // sellers false data
      state.sellersFalse = action.payload || []
    },

    updateQaPermission(state, action) {
      state.qaPermission = action.payload || []
    },

    updateAdminMetaData(state, action) {
      state.adminMetaData = action.payload || {}
    },
  },
})

export const {
  initSellersFalse,
  initRegionsAndTargetsData,
  updateQaPermission,
  updateAdminMetaData,
} = cacheDataSlice.actions

// cache object
export const getCacheData = (state: RootState) => state.cache

export const genRegionsFn = (regions, regionsCache) => {
  let datas: string[] = []
  if (regionsCache.length) {
    datas = regionsCache
  }
  return (regions ?? []).map(tt => ({
    key: tt,
    isDisabled: datas.length && datas.indexOf(tt) === -1,
    description: regionShortMappingFn(tt),
    name: tt,
  }))
}

/**
 * generate regions list
 * @param regions
 */
export const useRegionsFn = regions => {
  const data = useSelector(getCacheData)

  return genRegionsFn(regions, data.regions)
}
/**
 * 展示不显示二级Properties的Property
 */
export const useConvertDisplayNotShowPropery = () => {
  const { notShowSubGroupsMap } = useSelector(getCacheData)

  return {
    /**
     * 净化掉not_show_sub_properties的properties展示
     * @param selectedProperties 
     * @returns 
     */
    pureShow: (selectedProperties: Array<string>) => {
      const intersectionList = intersection(values(notShowSubGroupsMap).flat(10), selectedProperties)
      if (!intersectionList.length) return selectedProperties
      const groupProperties: Array<string> = []
      intersectionList.forEach(val => {

        /**
         * @todo 
         * 少量数据可行，大量数据存在性能问题
         */
        Object.keys(notShowSubGroupsMap).forEach(key => {
          // groupProperties
          if (notShowSubGroupsMap[key].indexOf(val) > -1) {
            groupProperties.push(key)
          }
        })
      })
      return [
        ...selectedProperties.filter(item => intersectionList.indexOf(item) === -1),
        ...Array.from(new Set(groupProperties)),
      ]
    },
  }
}

/**
 * 
 * @returns 
 */
export const useOurSeller = () => {
  const data = useSelector(getCacheData)
  return data.ourSeller
}

export default cacheDataSlice.reducer
