import React, { CSSProperties, ReactNode } from 'react'
import { Flex, Card, Spin } from 'druikit';
import classNames from 'classnames';
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined'
import { Tooltip } from 'antd'
import { MetricVendor } from './MetricVendor';
import styles from './styles.module.scss'
import { handleName } from 'pages/DynamicDashboard/utils';

export interface MetricCardData {
    name: string;
    value: string | ReactNode;
    percent: string | number;
    showUnits?: boolean;
    description?: string;
    showFromPreviousYear?: boolean;
    currency?: string;
}

export const MetricCard = ({
    style,
    className,
    loading = false,
    title,
    description,
    showUnits = false,
    dataSource = [],
    onClick,
}: {
    style?: CSSProperties;
    className?: string;
    loading?: boolean;
    title?: string | ReactNode; 
    description?: string | ReactNode;
    showUnits?: boolean;
    dataSource?: MetricCardData[];
    onClick?: () => void;
}) => {
    return (
        <Spin spinning={loading} minHeight={100} style={{ marginBottom: 34, ...style }} className={className}>
            <Flex style={{ marginBottom: 10 }}>
                <div className={styles.cardTitle}>
                    {handleName(String(title))}
                </div>
                {
                    description ? (
                        <Tooltip title={description} mouseEnterDelay={0} mouseLeaveDelay={0}>
                            <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                <InfoOutlined
                                    size={16}
                                    color="#bbb"
                                />
                            </div>
                        </Tooltip>
                    ) : null
                }
            </Flex>
            <Card fringed className={classNames({ [styles.cardClickable]: typeof onClick === 'function' })}>
                <Flex 
                    style={{ cursor: typeof onClick === 'function' ? 'pointer' : 'auto' }}
                    className={styles.cardWrapper} 
                    onClick={onClick}
                >
                    {
                        dataSource.map(item => {
                            return (
                                <MetricVendor
                                    key={item.name}
                                    className={styles.cardItem}
                                    style={{ width: '33.3%' }}
                                    vendorName={item.name}
                                    value={item.value}
                                    changePercent={item.percent}
                                    description={item.description}
                                    showUnits={item?.showUnits || showUnits}
                                    showFromPreviousYear={item?.showFromPreviousYear}
                                    currency={item.currency}
                                />
                            )
                        })
                    }
                </Flex>
            </Card>
        </Spin>
    )
}
