import { IS_LIVE_ENV } from 'consts'
import { getCacheData } from 'features/filters/cacheDataSlice'
import { clearCheckedProduct, getQaData, setSearch, updateCheckedProduct } from 'features/filters/qaDataSlice'
import { RoutesObj } from 'global/Routes'
import { pickBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ObjectType } from 'types'

/**
 * qa hooks
 * @returns 
 */
export function useQa(): any {
    const { checkedProduct,search } = useSelector(getQaData)
    const dispatch = useDispatch()
    const location = useLocation()
    const isSelectionPage = location.pathname.startsWith(RoutesObj.SEARCH_OPTIONS.URL)
    return {
        setSearch:(search:string) => {
            dispatch(setSearch(search))
        },
        search,
        isSelectionPage,
        enableQa: !IS_LIVE_ENV,
        /**
         * 
         * checked nornaId list
         */
        checkedProductList: Object.keys(pickBy(checkedProduct, val => val)),
        /**
         * checked nornaId object
         */
        checkedProduct,

        updateCheckedProduct: (nornaId: string) => dispatch(updateCheckedProduct({ [nornaId]: !checkedProduct[nornaId] })),

        /**
         *  ## selectet all `nornaids`
         * @param nornaids 
         * @param selectAll 
         */
        batchUpdateCheckedProduct: (nornaids: Array<string>,selectAll = true) => {
            
            dispatch(updateCheckedProduct(nornaids.reduce((res,nornaid) => {
                res[nornaid] = selectAll
                return res
            },{})))
        },

        clearCheckedProduct: () => dispatch(clearCheckedProduct({})),

    }

}

/** *
 * ## Filter or Menu available hooks
 * 
 * 
 */
export function useMenuAndFilterPermission(): ObjectType<any> {

    const { unavailableMenu, unavailableFilter } = useSelector(getCacheData)
    
    return {
        unavailableMenu:unavailableMenu??[],
        /**
         * 
         * checked nornaId list
         */
        menuAvailable: (menu: string) => (unavailableMenu ?? []).indexOf?.(menu) === -1,
        filterAvailable: (item: string) => (unavailableFilter ?? []).indexOf?.(item) === -1,

    }

}

