import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { SELECTED_CATEGORY } from 'consts'
import { cloneDeep } from 'lodash'
import { numberUtils } from 'norna-uikit'
import { getVendorNameByCode } from 'utils'
import { difference, sortVendorList } from 'utils/array'
import { getTotalCategoryLabel, handleTableExpanded } from 'utils/dashboardPageUtils'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { whetherLock } from 'utils/lockSellerUtils'
import { storage } from 'utils/storage'

interface ApiData {
    [vendor: string]: {
        [category: string]: {
            [field: string]: any;
        }
    }
}

export const handleTableData = ({
    apiData,
    competitorValue,
    categoryValue,
    region,
    expandedRowKeys = [],
}: {
    apiData: ApiData,
    competitorValue: string[];
    categoryValue: string[];
    region: string;
    expandedRowKeys: string[];
}): any => {
    if (!apiData || !Object.keys(apiData || {})?.length) {
        return {
            tabledata: [],
            metricsTab: [],
        }
    }
    apiData = { ...apiData }

    // 第一级标题
    const totalCategoryLabel = getTotalCategoryLabel(apiData[competitorValue[0]])
    if (!storage.getIsLookbook()) {
        difference(Object.keys(apiData), competitorValue).forEach(vendor => {
            delete apiData[vendor]
        })
    }

    const tabledata: any = []
    const metricsTab: any[] = []

    const categoryTreeObj = storage.getCategoryTreeObj()
    // 获取第一级 category
    const firstLevelCategoryList = getFirstLevelCategories({ selectedCategories: categoryValue })
    const categoryKeys = [ ...categoryValue ]
    const sellers = storage.getSellers()

    // vendor 进行排序
    let vendorList = Object.keys(apiData)
    vendorList = sortVendorList({ vendorList })
    
    // competitor 视图
    const metricsChildren: any[] = []

    vendorList.forEach(vendor => {
        // 查询日期是否有效
        const queryDateValid = isQueryDateValid({ vendor, region, competitorValue })
        // 是否显示锁图标
        const isLock = whetherLock({ vendorCode: vendor, region, sellers, selectedVendorCodeList: competitorValue })

        const vendorName = getVendorNameByCode(vendor)
        const vendorData = apiData[vendor]

        // 第一级展开 id: vendor + category
        const firstLevelExpandId = `${vendor}_${SELECTED_CATEGORY}`

        // 第 0 级
        const level0Children: any[] = []
        // 获得第二级列表
        firstLevelCategoryList.forEach(category => {
            const level1Children: any[] = []
            if (categoryTreeObj[category]?.list?.length) {
                const secondLevelExpandId = `${vendor}_${category}`
                metricsChildren.push({
                    expandId: `${firstLevelExpandId}__${secondLevelExpandId}`,
                    active: false,
                    code: vendor,
                    level: 1,
                    categoryKey: category,
                    title: vendorName,
                    categorycount: categoryTreeObj[category].list?.filter(item => categoryKeys.includes(item.name)).length,
                })

                // 第 2 级
                categoryTreeObj[category].list?.filter(item => categoryKeys.includes(item.name))?.forEach(subcategoryObj => {
                    const subcategory = subcategoryObj.name
                    const level2TreeItemData = {
                        ...vendorData[subcategory],
                        level: 2,
                        vendorName,
                        vendorCode: vendor,
                        isLock,
                        categoryName: subcategory,
                        queryDateValid,
                        max: getMax(vendorData[subcategory]),
                        Numbers: { value: vendorData[subcategory]?.['options in'] || 0 },
                        optionsNum: vendorData[subcategory]?.['options in'] || 0,
                    }
                    level1Children.push(level2TreeItemData)
                })
            }

            // 第 1 级
            const level1TreeItemData = {
                ...vendorData[category],
                children: level1Children,
                level: 1,
                vendorName,
                vendorCode: vendor,
                isLock,
                categoryName: category,
                queryDateValid,
                max: getMax(vendorData[category]),
                Numbers: { value: vendorData[category]?.['options in'] || 0 },
                optionsNum: vendorData[category]?.['options in'] || 0,
            }
            level0Children.push(level1TreeItemData)
        })

        // 第 0 级, All categories
        const lavel0TreeItemData = {
            ...vendorData[totalCategoryLabel],
            children: level0Children,
            level: 0,
            vendorName,
            vendorCode: vendor,
            isLock,
            categoryName: totalCategoryLabel,
            queryDateValid,
            max: getMax(vendorData[totalCategoryLabel]),
            Numbers: { value: vendorData[totalCategoryLabel]?.['options in'] || 0 },
            optionsNum: vendorData[totalCategoryLabel]?.['options in'] || 0,
        }
        tabledata.push(lavel0TreeItemData)

        metricsTab.push({
            expandId: firstLevelExpandId,
            active: false,
            code: vendor,           // 小写名称
            title: vendorName,      // 大写名称
            categoryKey: totalCategoryLabel,        // category 名称
            level: 0,
            children: metricsChildren.filter(c => c.code === vendor),
            categorycount: firstLevelCategoryList.length + 1,
        })
    })

    handleTableExpanded(metricsTab, expandedRowKeys, [])

    return {
        tabledata,
        metricsTab,
    }
}

function getMax(data = {}) {
    const newData = cloneDeep(data)
    delete newData['options in']
    const maxCount = Math.max(...Object.values(newData).map((item: any) => item?.products_in || 0))
    const maxPercent = Math.max(...Object.values(newData).map((item: any) => item?.products_in_share || 0))
    return { products_in: maxCount, products_in_share: maxPercent }
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
    switchValue = true,
  }) {
    if (!dataSource?.length) return ''
    const data: any[] = []
  
    const titleRow = [
        'Vendors',	
        '# In',	
        'Category',	
        'Monday',	
        'Tuesday',	
        'Wednesday',	
        'Thursday',	
        'Friday',
        'Saturday',
        'Sunday',
    ]
    data.push(titleRow)
  
    const handleRowData = (item) => {
        return [
            item?.vendorName,
            item?.Numbers?.value,
            item?.categoryName,
            !switchValue ? numberUtils.formatNumber(item?.Monday?.products_in, { decimal: 0 }) : numberUtils.formatNumber(item?.Monday?.products_in_share, { isCentuple: true, isPercentSymbol: true, isPercentDecimal: true }),
            !switchValue ? numberUtils.formatNumber(item?.Tuesday?.products_in, { decimal: 0 }) : numberUtils.formatNumber(item?.Tuesday?.products_in_share, { isCentuple: true, isPercentSymbol: true, isPercentDecimal: true }),
            !switchValue ? numberUtils.formatNumber(item?.Wednesday?.products_in, { decimal: 0 }) : numberUtils.formatNumber(item?.Wednesday?.products_in_share, { isCentuple: true, isPercentSymbol: true, isPercentDecimal: true }),
            !switchValue ? numberUtils.formatNumber(item?.Thursday?.products_in, { decimal: 0 }) : numberUtils.formatNumber(item?.Thursday?.products_in_share, { isCentuple: true, isPercentSymbol: true, isPercentDecimal: true }),
            !switchValue ? numberUtils.formatNumber(item?.Friday?.products_in, { decimal: 0 }) : numberUtils.formatNumber(item?.Friday?.products_in_share, { isCentuple: true, isPercentSymbol: true, isPercentDecimal: true }),
            !switchValue ? numberUtils.formatNumber(item?.Saturday?.products_in, { decimal: 0 }) : numberUtils.formatNumber(item?.Saturday?.products_in_share, { isCentuple: true, isPercentSymbol: true, isPercentDecimal: true }),
            !switchValue ? numberUtils.formatNumber(item?.Sunday?.products_in, { decimal: 0 }) : numberUtils.formatNumber(item?.Sunday?.products_in_share, { isCentuple: true, isPercentSymbol: true, isPercentDecimal: true }),
        ]
    }
  
    dataSource.forEach(item => {
        const dataRow = handleRowData(item)
        data.push(dataRow)
  
        if (item?.children?.length) {
            item.children.forEach(item2 => {
                const dataRow2 = handleRowData(item2)
                data.push(dataRow2)
  
                if (item2?.children?.length) {
                    item2.children.forEach(item3 => {
                        const dataRow3 = handleRowData(item3)
                        data.push(dataRow3)
                    })
                }
            })
        }
    })
  
    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
