import React from 'react'

export const EjectIcon = () =>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
    >
        <path fill="none" d="M0 24V0h24v24H0z" />
        <path d="M5 17h14v2H5zm7-12L5.33 15h13.34z" />
    </svg>

