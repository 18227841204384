import React, { CSSProperties } from 'react'
import { MultiCategoryDropdown } from '../CategoryDropdown'
import { getFilterCategoryMaxCount, getLeafCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { useLookbook } from 'hooks/useLookbook'
import { useFilterCategory } from 'hooks/useFilter'
import { storage } from 'utils/storage'
import { cloneDeep } from 'lodash'
import { CATEGORY_TOTAL } from 'consts'

const LineChartCategoryDropdown = ({
    value,
    onChange,
    onItemChange,
    enableFilter = true,
    right = true,
    selectorStyle,
    selectorClassName,
    showSelectAll = false,
    showClearAll = false,
    showOkButton = true,
}: {
    value?: string[];
    onChange?: (value: string[]) => void;
    onItemChange?: (value: string[]) => void;
    enableFilter?: boolean;
    right?: boolean;
    selectorStyle?: CSSProperties;
    selectorClassName?: string;
    showSelectAll?: boolean;
    showClearAll?: boolean;
    showOkButton?: boolean;
}) => {
    const { isLookbook } = useLookbook()
    const [ filterCategory, setFilterCategory ] = useFilterCategory()
    
    return (
        <MultiCategoryDropdown
            right={right}
            excludeTotal
            multiple
            minCount={0}
            maxCount={getFilterCategoryMaxCount()}
            disabled={isLookbook}
            showSelectAll={showSelectAll}
            showClearAll={showClearAll}
            selectorStyle={selectorStyle}
            selectorClassName={selectorClassName}
            value={value || filterCategory}
            onChange={value => {
                if (!value.length) {
                    const categoryTreeList = storage.getCategoryTreeList()
                    const firstLevelCategoryList = categoryTreeList.map(item => item.name).filter(item => item)
                    const data = getLeafCategories({ selectedCategories: firstLevelCategoryList.slice(0, 1) })
                    onChange?.(data)
                    if (enableFilter) {
                        setFilterCategory(data)
                    }
                } else {
                    let newValue = cloneDeep(value)
                    if (newValue.length > 1 && newValue.includes(CATEGORY_TOTAL)) {
                        newValue = newValue.filter(item => item !== CATEGORY_TOTAL)
                    }
                    onChange?.([ ...newValue ])
                    if (enableFilter) {
                        setFilterCategory([ ...newValue ])
                    }
                }
            }}
            onItemChange={value => {
                if (!value.length) {
                    const categoryTreeList = storage.getCategoryTreeList()
                    const firstLevelCategoryList = categoryTreeList.map(item => item.name).filter(item => item)
                    const data = getLeafCategories({ selectedCategories: firstLevelCategoryList.slice(0, 1) })
                    onItemChange?.(data)
                    if (enableFilter) {
                        setFilterCategory(data)
                    }
                } else {
                    let newValue = cloneDeep(value)
                    if (newValue.length > 1 && newValue.includes(CATEGORY_TOTAL)) {
                        newValue = newValue.filter(item => item !== CATEGORY_TOTAL)
                    }
                    onItemChange?.([ ...newValue ])
                    if (enableFilter) {
                        setFilterCategory([ ...newValue ])
                    }
                }
            }}
            showOkButton={showOkButton}
        />
    )
}

export default LineChartCategoryDropdown
