export const hidePropertyFirstLevelList = [
    'Accessories closing details',
    'Back',
    'Back patch',
    'Bag details',
    'Belt closing',
    'Braces closing',
    'Button color',
    'Button material',
    'Closing',
    'Coat length',
    'Coat- Formal Wear',
    'Collar details',
    'Color rivet',
    'Cuffs',
    'Dresses closing details',
    'Eyewear details',
    'Fashion deatils',
    'Jacket & Coat front',      // ...
    'Jacket & Coat hem details',
    'Jewelry details',
    'Neck jewelry',
    'Nonsense',
    'Pantie details',
    'Pocket details',
    'Pockets',
    'Shoes closing details',
    'Skirt & Dress front',
    'Skirt & Dress hem details',
    'Skirts & Trousers closing details',
    'Sleeve details',
    'Sock height',
    'Stitching',
    'Top front',
    'Top hem details',
    'Top length',
    'Trouser front',
    'Trouser hem details',
    'Upper body fit',
    'Upper body garments closing details',
    'Waist',
    'Wallet details',
    'Zipper details',
]

export const hidePropertySecondLevelMapper = {
    'Bags type': [
        'Drawstring bag',
        'Envelope bag',
        'Flap bag',
        'Round bag',
        'Saddle bag',
        'Bicycle bag',
        'Bucket bag',
        'Doctor',
        'Frame bag',
        'Hobo bag',
        'Mini bag',
        'Picnic case',
        'Shopping bag',
        'Side packs',
    ],
    'Dresses type': [
        'Bardot dress',
        'Bodycon dress',
        'Caftan dress',
        'Dance dress',
        'Flounce dress',
        'Ballgown',
        'Blazer dress',
        'Cocktail dress',
        'Empire',
        'Evening dress',
        'Kimono dress',
        'Pinafore dress',
        'Sheath dress',
        'Shift dress',
        'Smock',
        'Strapless dress',
        'Sun dress',
        'Tiered dress',
        'Wrap dress',
        'Pouf',
        'Princess cut dress',
        'Prom dress',
        'Qipao dress',
        'Saree',
        'Shirt dress',
        'Sweater dress',
    ],
    'Jackets Type': [       // ...
        'Stretch down jacket',
        'Dress jacket',
        'Lumber jacket',
        'Army jacket',
        'Safari jacket',
        'Tech outerwear',
        'Wax jacket',
    ],
    Neckline: [
        'Cowl neck',
        'Deep neck',
        'Fur collar',
        'Banded collar',
        'Bow collar',
        'Jabot collar',
        'Mandarin collar',
        'Mock neck',
        'Scarf collar',
        'Stand-away collar',
        'Hood',
        'Dropped lapel',
        'Kimono collar',
        'Napoleon lapel',
        'Narrow lapel',
        'Notched lapel',
        'Oversized lapel',
        'Peak lapel',
        'Reverse lapel',
        'Shawl collar',
        'Shawl lapel',
        'Wide Lapel',
        'Asymmetric collar',
        'Bertha collar',
        'Other collar',
        'Sailor collar',
        'Gathered neckline',
        'Halter neck',
        'Key hole',
        'Other necklines',
        'Collarless collar',
        'Henley neckline',
        'Rib neckline',
        'Rolled edge neck',
        'Scoop neck',
        'Square collar',
        'Strap',
        'Strapless',
        'Button down collar',
        'Chelsea collar',
        'Club collar',
        'Cut away collar',
        'Dog ear collar',
        'Flat collar',
        'Kent collar',
        'Oversized collar',
        'Pajama collar',
        'Peter pan collar',
        'Polo collar',
        'Regular collar',
        'Spread collar',
        'Wing collar',
        'Notch Split neckline',
        'V-neck',
        'Boat neck',
    ],
    Pattern: [
        'Croco',
        'Leopard',
        'Snake',
        'Tiger',
        'Zebra',
        'Batik',
        'Buffalo checks',
        'Gingham check',
        'Glen check',
        'Madras plaid',
        'Mini-check',
        'Pepita check',
        'Pin check',
        'Prince of Wales check',
        'Shepherd\'s check',
        'Tartan plaid',
        'Tattersall',
        'Windowpane check',
        'Dotted',
        'Polka dots',
        'Swiss dot',
        'Flower Jacquard',
        'Flowers and belts',
        'Melange',
        'Motley',
        'Abstract',
        'African',
        'Airbrush',
        'Argyle',
        'Asian',
        'Birdseye',
        'Bohemian',
        'Brush stroke',
        'Bull’s eye',
        'Burnout',
        'Chinoiserie',
        'Ditsy print',
        'Fairisle',
        'Fishbone',
        'Gradient',
        'Graphic',
        'Houndstooth',
        'Logo',
        'Logo jacquard',
        'Medallion',
        'Paisley',
        'Rope',
        'Trompe l\'oeuil',      // ...
        'Plaids',
        'Photo print',
        'Breton/French stripes',
        'Diagonal stripes',
        'Dress/Pencil stripes',
        'Multicolor stripes',
        'Pinstripes',
        'Serpentine stripes',
        'Shadow stripes',
        'Tweed print',
        'Wallpaper pattern',
    ],
    'Scarves type': [
        'Ascot scarf',
    ],
    'Shoes type': [
        'Army boots',
        'Chukka boot',
        'Desert boots',
        'Dress boot',
        'Motorcycle boot',
        'Other shoes',
        'Overshoe',
        'Party shoes',
        'Platform boot',
        'Plimsolls',
        'Winter boots',
        'Work boots',
    ],
    'Skirts type': [
        'Circle skirt',
        'Kilt skirt',
        'A-line',
        'Ball gown skirt',
        'Balloon',
        'Bell shaped',
        'Braces skirt',
        'Chino skirt',
        'Mermaid',
        'Pencil',
        'Tutu skirt',
        'Sports/Athleisure skirt',
        'Wrap skirt',
    ],
    'Tops/Shirts type': [
        'Asymetric top',
        'Blouse long sleeve',
        'Blouse short sleeve',
        'Blouse sleeveless',
        'Knit function',
        'Knit other',
        'Knit top',
        'Knitwear rollneck',
        'Knitwear roundneck',
        'Knitwear turtleneck',
        'Knitwear v-neck',
        'Maternity top',
        'Peplum top',
        'Polo shirt long sleeve',
        'Polo shirt short sleeve',
        'Qipao top',
        'Shirt long sleeve',
        'Shirt short sleeve',
        'Singoalla',
        'Candillon',
        'Smock',
        'Strap top',
        'T-Shirt long sleeve',
        'T-Shirt short sleeve',
        'Top long sleeve',
        'Top short sleeve',
        'Wrap top',
    ],
    'Trousers type': [
        'Baby sleeping bag',
        'Drapey trouser',
        'One piece',
        'Crepe trouser',
        'Harem',
        'Palazzo',
        'Pull-on',
        'Swimwear trouser',
    ],
}
