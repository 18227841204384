import React from 'react'
import { getDashboardValueByOptions } from 'utils/hardCoded/dashboard'
import { NA } from 'consts'
import { DisplayText } from 'componentsv2/DisplayText'
import { cloneDeep } from 'lodash'
import { TableCell } from './TableCell'
import { isCrossRegionSoldOutPage } from 'global/Routes'

export const TableCellWithSymbol = ({
    width,
    fontSize = 12,
    text,
    record,
    isComparisonField = false,
    isFirstMetric = false,
    isDeepBorderRight = false,
    isEmptyBorderBottom = false,
    isEmptyBorderRight = false,
    isShadowBg = false,
    isClickable = false,
    onClick,
  }: {
    width: number;
    fontSize?: number;
    text: string | number | boolean;
    record: any;
    isComparisonField?: boolean;
    isFirstMetric?: boolean;
    isDeepBorderRight?: boolean;
    isEmptyBorderBottom?: boolean;
    isEmptyBorderRight?: boolean;
    isShadowBg?: boolean;
    isClickable?: boolean;
    onClick?: () => void;
  }) => {
    record = cloneDeep(record)
    if (isComparisonField && !record.queryComparisonDateValid) {
      record.optionsNum = 0
    }
    if (!isComparisonField && !record.queryDateValid) {
      record.optionsNum = 0
    }

    let displayText: any = text
    if (!isCrossRegionSoldOutPage()) {
      displayText = getDashboardValueByOptions(text, record?.optionsNum)
    }
    if (displayText === NA || (isCrossRegionSoldOutPage() && text === '')) {
      if (isFirstMetric) {
        return (
          <TableCell isClickable={isClickable} width={width} text={displayText} isDeepBorderRight={isDeepBorderRight} isShadowBg={isShadowBg} isEmptyBorderBottom={isEmptyBorderBottom} isEmptyBorderRight={isEmptyBorderRight} />
        )
      }
      return (
        <TableCell isClickable={isClickable} width={width} text="" isDeepBorderRight={isDeepBorderRight} isShadowBg={isShadowBg} isEmptyBorderBottom={isEmptyBorderBottom} isEmptyBorderRight={isEmptyBorderRight} />
      )
    }

    return (
      <TableCell isClickable={isClickable} width={width} isDeepBorderRight={isDeepBorderRight} isShadowBg={isShadowBg} isEmptyBorderBottom={isEmptyBorderBottom} isEmptyBorderRight={isEmptyBorderRight} onClick={onClick}>
        <DisplayText value={getDashboardValueByOptions(text, record.options)} fontSize={fontSize} showArrow />
      </TableCell>
    )
}
