import { useFetch } from 'libs'
import { useEffect, useState } from 'react'

/**
 * 新增 alert message
 */
export function useCreateAlertMessage() {
    const { postFn } = useFetch()
    
    const createAlertMessage = async (alertType: string, hide = true) => {
        await postFn('/alert_message', { query: { alert_type: alertType, hide }, data: {} })
    }

    return {
        createAlertMessage,
    }
}

/**
 * 查询 alert message
 */
export function useListAlertMessages() {
    const { getFn } = useFetch()
    
    const listAlertMessages = async () => {
        const result = await getFn('/alert_messages')
        return result
    }

    return {
        listAlertMessages,
    }
}

export function useAlertMessages() {
    const [ alertMessages, setAlertMessages ] = useState<string[]>([])
    const { listAlertMessages } = useListAlertMessages()

    useEffect(() => {
        const query = async () => {
            const result = await listAlertMessages()
            if (!Array.isArray(result)) return
            setAlertMessages(result)
        }
        query()
        // eslint-disable-next-line
    }, [])

    return alertMessages || []
}   
