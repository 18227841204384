import { CloseIcon, LoadingSpinner, NoCategory } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { Text } from 'components/typography'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { OverlayType, useBarSliderChartOverlay } from 'componentsv2/NornaLineChart/components/LineChart'
import { lsSet } from 'utils'
import { selectFilterCurrency } from 'features/filters/filtersSlice'
import { useSelector } from 'react-redux'
import { PRICE_RANGE_MODAL_CLASS } from 'consts'
import classNames from 'classnames'
import { useTdPriceRange } from 'hooks/usePriceRange'
import { Spin } from 'componentsv2'
import { Dragable } from 'components/common/Dragable'
import { TdPriceRangeTypeEnum } from 'graphql/nornaapi/types'
import { PriceFilterSelectionModalProps } from './types'
import styles from './styles.module.scss'
import { CustomizeSlider } from './PriceFilterSelectionModal'
import { NornaBar } from './NornaBar'
import { numberUtils } from 'norna-uikit'

/**
 * ## price range for td
 * @param param0 
 * @returns 
 */
export const TdPriceFilterSelectionModal = ({
  closeModal,
  isOpen,
  currentFilter,
  onChange,
  modalMaskStyle = {},
  type=TdPriceRangeTypeEnum.TD,
}: PriceFilterSelectionModalProps) => {

  const currency = useSelector(selectFilterCurrency)
  const { clearOverlay, afterDrawer } = useBarSliderChartOverlay()
  const { updateActivate, loading, max, min, bars } = useTdPriceRange(type)

  const limitRef = useRef<any>({
    min,
    max,
  })

  const [ sliderValue, setSliderValue ] = useState<any>(() => {

    let current = [ min, max ]
    if (currentFilter.range) current = currentFilter.range.split(',').map((item: string) => parseInt(item, 10))
    let minData = min
    let maxData = max
    if (current[0] > minData) {
      minData = current[0] // eslint-disable-line
    }
    if (current[1] < maxData) {
      maxData = current[1] // eslint-disable-line
    }

    limitRef.current = {
      min,
      max,
    }
    return [
      minData,
      maxData,
    ]
  })

  const sliderRef = useRef<any>()
  const barRef = useRef<any>()

  const handleRerender = useCallback((limitMax: number, limitMin: number, max: number, min: number) => {
    const jsonStr = JSON.stringify({
      limitMax: limitMax - limitMin,
      min: min - limitMin,
      max: max - limitMin,
    } as OverlayType)
    lsSet('price_range', jsonStr)
    afterDrawer(barRef.current?.bar?.chartInstance)

  }, [ afterDrawer ])

  /**
   * ## max change effect
   */
  const maxMinChangeEffect = useCallback(() => {
    if (max !== limitRef.current.max || min !== limitRef.current.min) {
      limitRef.current = { max,min }
      setSliderValue([ min, max ])

    }
  }, [ max, min ])

  useEffect(() => {
    maxMinChangeEffect()
  }, [max, min]) //eslint-disable-line

  useEffect(() => {
    handleRerender(max, min, sliderValue[1], sliderValue[0])
  }, [sliderValue]) // eslint-disable-line

  useEffect(() => {
    clearOverlay?.()
    updateActivate()
  }, []) // eslint-disable-line

  // 初始化遮罩
  useEffect(() => {
    if (barRef?.current?.bar) afterDrawer(barRef.current.bar?.chartInstance)
  }, []) //eslint-disable-line

  const savePriceFilter = useCallback(async () => {
    if (onChange) {
      onChange({
        max: sliderValue[1],
        min: sliderValue[0],
        // checked,
      })

      closeModal()
    }
  }, [ closeModal, onChange, sliderValue ])

  let contentComponent: React.ReactElement = <></> as React.ReactElement
  if (loading) {
    contentComponent = <LoadingSpinner sty={{ height: '200px' }} />
  } else if (min === 0 && max === 0) {
    contentComponent = <NoCategory >No price range data</NoCategory>
  } else {
    contentComponent = (
      <>
        <div className={styles.modalBody} onMouseDown={e => e.stopPropagation()}>
          <div className={styles.pricesContainer}>
            <NornaBar
              ref={barRef}
              bars={bars}
            />
          </div>
          <div className={styles.sliderContainer}>
            <CustomizeSlider
              min={min}
              ref={sliderRef}
              max={max}
              step={1}
              value={sliderValue}
              onChange={(_event, newValue) => setSliderValue(newValue)}
              valueLabelDisplay="off"
            />
          </div>
          <div className={styles.sliderValuesContainer}>
            <Text medium>{numberUtils.formatNumberByComma(sliderValue[0] ?? 0)}</Text> -
            <Text medium>{numberUtils.formatNumberByComma(sliderValue[1] ?? 0)}</Text>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <Button primary onClick={savePriceFilter} disabled={false}>
            Apply
          </Button>
        </div>
      </>
    )
  }

  return (
    <Spin spinning={loading}>
      <div className={classNames(styles.modal, PRICE_RANGE_MODAL_CLASS.slice(1))} onClick={() => closeModal()} style={modalMaskStyle} />
      <div className={styles.modalContainer}>
        <Dragable width={218} height={309} x={0} y={0}>
          <div className={styles.modalWrapper}>
            <div className={styles.modalHeader}>
              <div className={styles.modalTitle}>
                <Text medium>Price range ({currency})</Text>
              </div>
              <CloseIcon onClick={() => closeModal()} />
            </div>
            {contentComponent}
          </div>
        </Dragable>
      </div>
    </Spin>
  )
}

