import React, { useState } from 'react'
import { useApiData, useApiLoading, useDynamicDashboardRequests, usePovValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { MetricNav } from '../MetricNav'
import { Flex } from 'druikit'
import { MetricBarChartCard, MetricCard, MetricProductCard, percentFormat } from '../MetricCard'
import { handleCardDataSource } from 'pages/DynamicDashboard/utils'
import { useFetchDashboardProducts } from 'pages/DynamicDashboard/hooks/useApi'
import { handleSoldOutBarChartData, handleSoldOutSplitBarChartData } from './utils'
import { useFilterCurrency } from 'hooks/useFilter'
import { getTooltipText } from 'configs/tooltip'
import { numberUtils } from 'norna-uikit'

export const ModalSoldOutStatus = () => {
    const [ apiData ] = useApiData()
    const [ apiLoading ] = useApiLoading()
    const [ povValue ] = usePovValue()
    const [ currency ] = useFilterCurrency()
    const [ cardDataSource, setCardDataSource ] = useState<any[]>([])
    const [ soldOutBarChartData, setSoldOutBarChartData ] = useState<any[]>([])
    const [ soldOutSplitBarChartData, setSoldOutSplitBarChartData ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const metricList = [ 'Sold out', 'Options ratio' ]
        let cardDataSource = handleCardDataSource({ apiData, metricList, currency, customerVendor: povValue })
        const baseValue = Number(cardDataSource?.[2]?.dataSource?.[0]?.value?.replace(',','')?.replace('%', ''))
        cardDataSource = cardDataSource.map(item => {
            item.dataSource.push({
                name: 'Sold out index',
                precent: '0.0%',
                showFromPreviousYear: false,
                value: !baseValue ? 0 : numberUtils.formatNumber(Number(item?.dataSource?.[0]?.value.replace(',', '').replace('%', '')) / Number(baseValue), { isCentuple: true, decimal: 1, isPercentSymbol: true }),
                description: 'The sold % of yourself for the selected categories during the selected time period divided by the sold out % of the Market. If the sold out % is 5% for yourself and 10% for the market, the index will be 50%, meaning that your products are only half as sold out as they are for all the competitors in the market.',
            })
            return item
        })
        setCardDataSource([ ...cardDataSource ])

        const soldOutBarChartData = handleSoldOutBarChartData(cardDataSource)
        setSoldOutBarChartData([ ...soldOutBarChartData ])

        const soldOutSplitBarChartData = handleSoldOutSplitBarChartData(soldOutBarChartData)
        setSoldOutSplitBarChartData([ ...soldOutSplitBarChartData ])
    }, [ apiData, currency, povValue, {} ])

    const [ requests ] = useDynamicDashboardRequests()
    const {
        soldOutProductsApiData: products,
        soldOutProductsApiLoading: productsLoading,
    } = requests

    useFetchDashboardProducts('Sold out')

    return (
        <>
            <MetricNav
                name="Sold out status"
                metric="Sold out"
            />
            <Flex justifyContent="space-between">
                {
                    cardDataSource.map(item => {
                        return (
                            <MetricCard
                                style={{ width: '32%' }}
                                loading={apiLoading}
                                key={item?.title || ''}
                                title={item?.title || ''}
                                dataSource={item?.dataSource || []}
                                showUnits={item.showUnits}
                            />
                        )
                    })
                }
            </Flex>
            <Flex justifyContent="space-between" id="dynamic-dashboard-middle-graph">
                <MetricBarChartCard
                    style={{ width: '49%' }}
                    loading={apiLoading}
                    title="Sold out"
                    dataSource={soldOutBarChartData}
                    yAxisFormatter={percentFormat}
                />
                <MetricBarChartCard
                    style={{ width: '49%' }}
                    loading={apiLoading}
                    title="Sold out index"
                    description={getTooltipText('sold out index')}
                    dataSource={soldOutSplitBarChartData}
                    yAxisFormatter={percentFormat}
                />
            </Flex>
            <Flex justifyContent="space-between">
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[0]?.title} sold out more than 50%`}
                    dataSource={products.customerVendorProducts || []}
                    count={products.customerVendorCount}
                />
                <MetricProductCard
                    loading={productsLoading}
                    style={{ width: '49%' }}
                    title={`${cardDataSource?.[1]?.title} sold out more than 50%`}
                    dataSource={products.comparisonVendorProducts || []}
                    count={products.comparisonVendorCount}
                />
            </Flex>
        </>
    )
}
