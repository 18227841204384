import { createSlice } from '@reduxjs/toolkit'
import { lsGet, lsSet } from '../../utils/ls'
import { RootState } from '../../app/store.type'
// import { MemoDataState } from './memoDataSlice.types';

export const PRICE_RAGNE_KEY = 'pr_key'
export const PRICE_RAGNE_RES_KEY = 'pr_res_key'
export const initData = {
  priceRangePreviousQuery: lsGet(PRICE_RAGNE_KEY, false),
  priceRangePreviousRes: lsGet(PRICE_RAGNE_RES_KEY),
  tmpQuery: {},
  // 是否禁用懒加载
  disableLazyLoad: false,
}
const initialState: any = initData
export const memoDataSlice = createSlice({
  name: 'memoData',
  initialState,
  reducers: {
    /**
    * checked product
    */
    updatePriceRangePreviousData(state, action) {

      state.priceRangePreviousQuery = action.payload.query
      state.priceRangePreviousRes = action.payload.res
      lsSet(PRICE_RAGNE_KEY, action.payload.query)
      lsSet(PRICE_RAGNE_RES_KEY, action.payload.res)
    },

    /**
     *  update  tmp query
     * @param state 
     * @param action 
     */
    updateTmpQueryData(state, action) {

      state.tmpQuery = {
        ...state.tmpQuery,
        ...action.payload,
      }

    },
    /**
     *  更新懒加载配置
     * @param state  
     * @param action 
     */
    updateDisableLazyLoad(state, action) {
      state.disableLazyLoad = action.payload
    },

  },

})
export const {
  updatePriceRangePreviousData,
  updateTmpQueryData,
  updateDisableLazyLoad,
} = memoDataSlice.actions

// object
export const getMemoData = (state: RootState) => state.memo
export const disableLazyLoad = (state: RootState) => state.memo.disableLazyLoad

export default memoDataSlice.reducer
