import React, { CSSProperties } from 'react'
import { Text } from 'components/typography'
import { Flex } from 'componentsv2/Flex'
import { HorizontalLine } from 'components/HorizontalLine'

export const DropdownHeaderButton = ({
    onSelectAll,
    onClearAll,
    showClearAll = true,
    showSelectAll = true,
}: {
    onSelectAll?: () => void;
    onClearAll?: () => void;
    showClearAll?: boolean;
    showSelectAll?: boolean;
}) => {
    const sty: CSSProperties = {
        flex: 1, 
        display: 'flex', 
        alignItems: 'center',
    }

    return (
        <>
            <Flex style={{ padding: '10px 0 0' }}>
                {
                    showClearAll ? (
                        <div
                            onClick={onClearAll}
                            style={{ cursor: 'pointer', justifyContent: 'flex-start', ...sty }}
                            className="cell-mouse-effect-no-display-no-within-padding-lr-15"
                        >
                            <Text small secondary>
                                Clear all
                            </Text>
                        </div>
                    ) : (
                        <div style={sty} />
                    )
                }
                {
                    showSelectAll ? (
                        <div
                            onClick={onSelectAll}
                            style={{ cursor: 'pointer', justifyContent: 'flex-end', ...sty }}
                            className="cell-mouse-effect-no-display-no-within-padding-lr-15"
                        >
                            <Text small secondary>
                                Select all
                            </Text>
                        </div>
                    ) : (
                        <div style={sty} />
                    )
                }
            </Flex>
            <HorizontalLine />
        </>
    )
}
