import React, { FC, memo } from 'react'
import { CloseIcon } from 'assets/icons'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Text } from 'components/typography'
import { OptionHeaderProps } from '../types'
import styles from '../styles.module.scss'

export const DropdownHeader: FC<OptionHeaderProps> = memo(({
  title,                          // header 中的标题
  showClose = false,              // header 中是否显示关闭按钮
  onClick,                        // 关闭按钮点击事件             
}) => (
    <div className={styles.header}>
        <ShouldRender shouldRender={!!title}>
            <div className={styles.headerTitle}>
                <Text small bold>
                    {title}
                </Text>
            </div>
        </ShouldRender>
        <ShouldRender shouldRender={showClose}>
            <CloseIcon onClick={onClick} />
        </ShouldRender>
    </div>
))

DropdownHeader.displayName = 'DropdownHeader'
