import React from 'react'
import { Collection } from 'features/filters/collectionDataSlice.types'
import { ClockIcon } from 'assets/icons/Clock'
import { Text } from 'components/typography'
import { format } from 'date-fns'
import { HorizontalLine } from 'components/HorizontalLine'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { CloseIcon } from 'assets/icons'
import { formatCollection } from './utils'
import styles from './styles.module.scss'

export const CollectionItem = ({
    collection,
    onRemove,
    onClickName,
}: {
    collection: Collection;
    onRemove?: (collection: Collection) => void;
    onClickName?: (collection: Collection) => void;
}) => {
    const { name, createdAt } = collection
    const values = formatCollection(collection)

    return (
        <div className={styles.wrapper}>
            <div className={styles.closeIconWrapper}>
                <CloseIcon onClick={() => onRemove?.(collection)} />
            </div>
            <div className={styles.createdAt}>
                <ClockIcon />
                <Text small secondary>
                    {format(new Date(createdAt), 'yyyy/MM/dd kk:mm')}
                </Text>
            </div>
            <div
                className={styles.name}
                title={name}
                onClick={() => {
                    onClickName?.(collection)
                }}
            >
                <Text
                    onHoverChangeColor
                    large
                    semibold
                    title={name}
                >
                    {name}
                </Text>
            </div>
            <HorizontalLine />
            <div className={styles.queryWrapper}>
                <ShouldRender shouldRender={Boolean(values.categoryValue)}>
                    <div>
                        <label>Categories: </label>{values.categoryValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.propertyValue)}>
                    <div>
                        <label>Property: </label> {values.propertyValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.genderValue)}>
                    <div>
                        <label>Gender: </label>{values.genderValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.regionValue)}>
                    <div>
                        <label>Country: </label>{values.regionValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.currencyValue)}>
                    <div>
                        <label>Currency: </label>{values.currencyValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.competitorValue)}>
                    <div>
                        <label>Competitor: </label>{values.competitorValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.sortByValue)}>
                    <div>
                        <label>Sort by: </label>{values.sortByValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.pricePerspectiveValue)}>
                    <div>
                        <label>Price perspective: </label>{values.pricePerspectiveValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.packFilterValue)}>
                    <div>
                        <label>Pack filter: </label>{values.packFilterValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.priceRangeFilterValue)}>
                    <div>
                        <label>Price range filter: </label>{values.priceRangeFilterValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.launchedAfterValue)}>
                    <div>
                        <label>Launched after: </label>{values.launchedAfterValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.sizeFilterValue)}>
                    <div>
                        <label>Children size filter: </label>{values.sizeFilterValue}
                    </div>
                </ShouldRender>
                <ShouldRender shouldRender={Boolean(values.materialValue)}>
                    <div>
                        <label>Material filter: </label>{values.materialValue}
                    </div>
                </ShouldRender>
            </div>
            <div className={styles.borderBottom} />
        </div>
    )
}
