import { DYNAMIC_DASHBOARD_TOOLTIP_PREFIX, INTRO_MIX, NEWNESS_INDEX, NEW_OPTIONS } from 'pages/DynamicDashboard/const'

export const getTooltipText = (tooltipKey) => {
    let tooltipText = tooltipKey
    switch (tooltipKey) {
        case 'Product newness':
            tooltipText = 'The number of options introduced and reintroduced to the marketplace during the selected time period. '
            break
        case 'Assortment mix':
            tooltipText = 'The selected categories share of the full assortment.'
            break
        case 'Discount effect':
            tooltipText = 'The average price reduction effect on the full assortment. If products have an average sales price of 300 and an average full price of 400, then the discount effect is 25%.'
            break
        case 'Sales price performance':
            tooltipText = 'Average sales price per option during the selected time period.'
            break
        case 'Full price performance':
            tooltipText = 'Average full price per option during the selected time period.'
            break
        case 'Sold out status':
            tooltipText = 'The share of the assortment that was out of stock during the selected time period.'
            break

        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + 'Product newness':
            tooltipText = 'The number of options introduced and reintroduced during the selected time period. The Market includes yourself and all your competitors.'
            break
        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + 'Assortment mix':
            tooltipText = 'The ”self assortment split”, meaning the selected categories share of the full assortment of each group of companies/competitors that are shown. The split for yourself compares to your full assortment, and the split for the Market compares to the full assortment of the market including yourself and all your competitors. '
            break
        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + 'Discount effect':
            tooltipText = 'The average price reduction effect on the full assortment. If products have an average sales price of 300 and an average full price of 400, then the discount effect is 25%.'
            break
        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + 'Sales price performance':
            tooltipText = 'The average sales price (per option) during the selected time period.'
            break
        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + 'Full price performance':
            tooltipText = 'The average full price (per option) during the selected time period.'
            break
        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + 'Sold out status':
            tooltipText = 'The share of the selected categories that was sold out during the selected time period. Sold out is measured as the unavailable number of sizes for any given group of products compared to the full number of sizes for the same group of products.'
            break
        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + INTRO_MIX:
            tooltipText = 'The number of options introduced and reintroduced during the selected time period divided by the average number of options during the selected time period.'
            break
        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + NEWNESS_INDEX:
            tooltipText = 'The number of options introduced and reintroduced during the selected time period, indexed against the number of options introduced and reintroduced in the Market, using the Market as 100%. The Market includes yourself and all your competitors.'
            break
        case DYNAMIC_DASHBOARD_TOOLTIP_PREFIX + NEW_OPTIONS:
            tooltipText = 'The number of options introduced and reintroduced per week.'
            break

        case 'Size of line':
            tooltipText = 'The relative assortment size of each vendor or vendor category compared to the average assortment size of your competitors. If your size of line is 20%, it means that your assortment size is 20% of the average assortment size of your competitors.'
            break
        case 'newness index':
            tooltipText = 'The number of new options introduced or reintroduced compared to the average number of new options introduced or reintroduced in the market.'
            break
        case 'sold out index':
            tooltipText = 'The sold out ratio compared to the average sold out ratio in the market.'
            break
        default:
            tooltipText = tooltipKey
    }
    return tooltipText
}
