import React from 'react'
import { LineChart } from '../LineChart'
import { Title } from '../Title'
import { numberUtils } from 'norna-uikit'

export const DiscountLineChart = ({
    dataSource,
}) => {
    return (
        <>
            <Title>Discount history</Title>
            <LineChart
                label="Discounts"
                chartData={dataSource || []}
                customTooltipLabel={item => `Discounts: ${numberUtils.formatNumberByComma(item.yLabel)}%`}
                customYAxesTicks={value => `${numberUtils.formatNumberByComma(value.toFixed(0))}%`}
                yAxesMaxFixed
            />
        </>
    )
}
