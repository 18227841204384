import { ShouldRender } from 'componentsv2/ShouldRender'
import { IS_TEST_ENV, PERMISSIONS } from 'consts'
import React from 'react'
import { Collapse } from '../Collapse'
import { Descriptions } from '../Descriptions'
import { useQaPermission } from 'hooks/useQaPermission'

export const NornaCategory = ({
    nornaCategory,
    nornaCategoryTypeObj,
    bottom = 0,
}: {
    nornaCategory: any;
    nornaCategoryTypeObj: any;
    bottom?: number;
}) => {
    // 当前登录用户是否具有 qa 权限
    const qaPermission = useQaPermission()
    const whetherQaPermission = qaPermission.includes(PERMISSIONS.QA)
    
    return (
        <ShouldRender shouldRender={IS_TEST_ENV && whetherQaPermission}>
            <Collapse title="Norna Category" showArrow={false} bottom={bottom}>
                <Descriptions column={2}>
                    <Descriptions.Item label="CATEGORY" value={nornaCategory} />
                    <Descriptions.Item label="TYPES" value={nornaCategoryTypeObj} />
                </Descriptions>
            </Collapse>
        </ShouldRender>
    )
}
