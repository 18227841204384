import React, { useLayoutEffect, useRef, useState } from 'react'
import styles from './LineGraphModal.module.scss'
import { ModuleTitle, MultiSelect } from 'druikit';
import { platformAnalyticsExportFilename } from 'utils/exportUtils';
import FinancialChart, { FinancialChartRefProps } from 'componentsv2/FinancialChart';
import { usePlatformAnalyticsModuleData } from 'features/filters/moduleDataSlice.hook';
import { usePageDate } from 'hooks/usePageDate';
import { getVendorNameByCode } from 'utils';
import { cloneDeep } from 'lodash';
import { ExportDropdown } from 'componentsv2/ExportDropdown';
import { downloadFileByAxios } from 'export';
import Modal from 'componentsv2/Modal'
import { numberUtils } from 'norna-uikit';

const exportModalId = 'platform-analytics-modal-export'
const exportFilename = platformAnalyticsExportFilename

const LineGraphModal = ({
    onClose,
}: {
    onClose?: () => void;
}) => {
    const { pageDate } = usePageDate()
    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const {
        graphModalChartData: chartData,
        graphModalPriceScaleVisibleRange,
        graphModalPriceScaleMaxRange,
        shopOptions,
        graphMetricValue,
        graphApiPayload,
    } = moduleData

    const onExportExcel = async () => {
        const payload = cloneDeep(graphApiPayload)
        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    /* ******************************** 监听弹窗大小 ********************************** */
    const [ financialChartHeight, setFinancialChartHeight ] = useState(320)

    useLayoutEffect(() => {
        const child = document.querySelector(`#${exportModalId}`) as any
        let observer: ResizeObserver
        if (child) {
            observer = new ResizeObserver(function (mutations) {
                const rect = mutations[0].contentRect 
                setFinancialChartHeight(Math.max(rect.height - 150, 320))
            })
            observer.observe(child)
        }
        return () => {
            if (child && observer) {
                observer.unobserve(child)
            }
        }
    }, [])

    const chartRef = useRef<FinancialChartRefProps>(null)

    return (
        <Modal
            onCancel={onClose}
            className={styles.wrapper} 
            style={{ width: window.innerWidth - 80, height: window.innerHeight - 80 }} 
        >
            <div 
                className={styles.body}
                id={exportModalId}
                onMouseDown={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <div className={styles.form}>
                    <ModuleTitle 
                        category="ASSORTMENT"
                        title="Marketplace"
                    />
                    <MultiSelect
                        dropdownClassName={styles.shopDropdown}
                        dropdownTitle="Sellers"
                        options={cloneDeep(shopOptions)}
                        value={cloneDeep(graphMetricValue)}
                        onChange={value => {
                            chartRef.current?.updateVisibleTimeRange()
                            setModuleData({
                                graphMetricValue: value,
                            })
                        }}
                    />
                    <div style={{ flex: 1 }} />
                    <ExportDropdown
                        selector={`#${exportModalId}`}
                        excludeSelectors={[ '.line-graph-tooltip' ]}
                        fileName={exportFilename}
                        onExportExcel={onExportExcel}
                    />
                </div>
                <FinancialChart
                    ref={chartRef}
                    chartData={cloneDeep(chartData)}
                    defaultDateRange={pageDate}
                    defaultPriceScaleVisibleRange={graphModalPriceScaleVisibleRange}
                    defaultLeftPriceScaleMaxRange={graphModalPriceScaleMaxRange}
                    formatYAxis={value => {
                        return `${numberUtils.formatNumber(Math.abs(value), { isCentuple: true, decimal: 1, isPercentSymbol: true })}`
                    }}
                    formatTooltipValue={value => {
                        return `${numberUtils.formatNumber(value, { isCentuple: true, decimal: 1, isPercentSymbol: true })}`
                    }}
                    formatStep={() => 0.001}
                    height={financialChartHeight}
                    showShortcutDate={true}
                    leftPriceScaleOptions={{
                        maxValue: 1.05,
                        scaleMargins: {
                            top: 0,
                            bottom: 0.05,
                        },
                    }}
                />
            </div>
        </Modal>
    )
}

export default LineGraphModal
