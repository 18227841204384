import { SellerType, SortFieldType } from 'types'
import { formatDatePickerToDate, getDateObject } from 'utils/dateUtils'
import { RoutesObj } from '../global/Routes'
import { PRICE_PERSPECTIVE_ALL_ACTUAL, PRICE_PERSPECTIVE_ALL_FULL, PRICE_PERSPECTIVE_ALL_INTRO_FULL, PRICE_PERSPECTIVE_HIGHEST_PRICE, SORTBY_HIGHEST_PRICE, SORTBY_INTRO_DATE, SORTBY_LOWEST_PRICE, SORTBY_MOST_POPULAR, SORTBY_NAME } from './strings'

export * from './request'
export * from './strings'
export * from './license'

// the type of component
export const ComType = {
  LOOKBOOK: 'LOOKBOOK',
  SELECTION: 'SELECTION',

}

// share link key which value could be used to query the shared filter
export const SHARE_FILTER_KEY = 'linkkey'

// Open in new button
// ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/201
export const OPEN_IN_NEW_KEY = 'openinnew'
export const OPEN_IN_NEW_VALUE = 'yes'
export const OPEN_IN_NEW_DATE = 'openinnewdate'

/**
 * price filter obj : {[val]:label}
 */
export const PRICE_FILTER = {
  // 1. -Means: All options @ actual retail price -> actual_price=true;discount_only=false;special_price=null
  'All actual': PRICE_PERSPECTIVE_ALL_ACTUAL,

  // 2. Means: All options full price -> actual_price=false;discount_ony=false;special_price=null
  'All full': PRICE_PERSPECTIVE_ALL_FULL,

  // 3. Means: Only options @ actual retail price -> actual_price=true;discount_only=true;special_price=null
  // 'Only actual': 'Only options @ discounted price',

  // 'Only full': 'Only options @ full price',
  // 2022/07/16 新增两个 price filter 选项
  'All intro full': PRICE_PERSPECTIVE_ALL_INTRO_FULL,
  // 'All intro retail': 'All options @ intro retail price',
  // 6. Highest price -> actual_price=Any;discount_only=Any; special_price="max_price"
  'Highest price': PRICE_PERSPECTIVE_HIGHEST_PRICE,
}

export const PRICE_FILTER_VALS = {
  'All actual': { actual_price: true, special_price: '' },
  'All full': { actual_price: false, special_price: '' },
  'All intro full': { actual_price: false, special_price: 'intro_date' },
  'Highest price': { actual_price: true, special_price: 'max_price' },
}

export const PRICE_RANGE_VALS = {
  all: { activate: false },
  discount: { activate: true, actual_price: true },
  original: { activate: true, actual_price: false },
}

/**
 * price filter options array
 */
export const PRICE_FILTER_ARRAY = Object.keys(PRICE_FILTER).map(key => ({ val: key, label: PRICE_FILTER[key] }))

/**
 * 所有 Analytics 页面右上角 Filter > Category 硬编码新增选项
 * 如果选中改选项，是想要查询全部分类数据
 * 好几个地方使用到这个名称，定义常量，预防之后这个选项名称如果被修改，直接改此处常量值即可
 */
export const CATEGORY_TOTAL = 'All categories'
export const SELECTED_CATEGORY = 'Selected categories'
export const CATEGORY_TOTAL_ARRAY = [ CATEGORY_TOTAL, SELECTED_CATEGORY ]
export const ALL_VENDORS = 'All vendors'
export const SELECTED_VENDORS = 'Selected vendors'

/**
 * element splitor
 */
export const ELEMENT_SPLITOR = ',_,'

/**
 * Permissions
 */
export const PERMISSIONS = {
  QA: 'QA',
  QQ_RUN: 'qa_run',
}

export const MARKET_VENDOR: SellerType = { vendor: 'Market', region: 'World' }
export const SELECTED_VENDORS_OBJ: SellerType = { vendor: SELECTED_VENDORS, region: 'All Countries' }

export const MarketArray = [ MARKET_VENDOR.vendor, ALL_VENDORS, SELECTED_VENDORS ]

/**
 * marimekko customer
 */
export const MARIMEKKO = 'marimekko'

/**
 * DISABLE `Cancel Request`
 */
export const DISABLE_CANCEL_REQUEST = 'DISABLE_CANCEL_REQUEST'

/**
 * live env domain
 */
export const LIVE_DOMAIN = [
  'analytics.norna.ai',
  'analytics-backup.norna.ai',
  'staging.norna.ai',
]

/**
 * 是否生产环境
 */
export const IS_LIVE_ENV = LIVE_DOMAIN.includes(window.location.host)
// export const IS_LIVE_ENV = true

/**
 * test env domain
 */
export const TEST_DOMAIN = [
  'test-analytics.norna.ai',
  'localhost:3000',
]

/**
 * 是否是测试环境
 */
export const IS_TEST_ENV = TEST_DOMAIN.includes(window.location.host)

export const STAGING_DOMAIN = [
  'localhost:3000',
]

export const IS_STAGING_ENV = STAGING_DOMAIN.includes(window.location.host)

/**
 * default font family
 */
export const DEFAULT_FONT_FAMILIY = 'Nunito Sans'
export const DEFAULT_FONT_COLOR = '#484848'
/**
 * 默认排序规则
 */
export const defaultSort: SortFieldType = {
  field: 'date',
  order: 'desc',
}

export enum DateFormats {
  DEFAULT = 'yyyy-MM-dd',
  YYMMDD = 'yyMMdd',
}

export const currencies = {
  sek: {
    long: 'Sweden Krona',
    short: 'sek',
  },
  eur: {
    long: 'euro',
    short: 'eur',
  },
  gbp: {
    long: 'British Pound Sterling',
    short: 'gbp',
  },
  dkk: {
    long: 'Danish Krone',
    short: 'dkk',
  },
  nok: {
    long: 'Norwegian Krone',
    short: 'nok',
  },
  usd: {
    long: 'Us Dollar',
    short: 'usd',
  },
}

export const regionCurrency = {
  'United Kingdom': 'GBP',
  Sweden: 'SEK',
  Norway: 'NOK',
  Denmark: 'DKK',
  Germany: 'EUR',
  Netherlands: 'EUR',
}

/**
 * Filter.Gender  order rule
 */
export const GenderOrder = [
  'Men',
  'Women',
  'Children',
]

/**
 * 产品详情页，需要高亮颜色的Norna-Category
 */
export const WhiteCategory = [
  'Belts',
  'Gloves',
  'Scarves',
  'Headwear',
  'Socks',
  'Underwear',
  'Shoes',
  'Bags',
  'Wallets',
  'Eyewear',
  'Jewelry',
  'Other accessories',
  'Mix, kit and sets',
  'Ties & Bow ties',
]

/**
 * Marimekko sort rule
 */
export const MarimekkoCategoryOrder = [
  'Coats',
  'Wool coats',
  'Puffer coats',
  'Cotton coats',
  'Trenchcoats',
  'Other coats',

  'Jackets & Blazers',

  'Dresses',
  'Knit dresses - Short',// Knit dresses - Midi
  'Knit dresses - Midi',
  'Knit dresses - Maxi',
  'Other dresses - Short',
  'Other dresses - Midi',
  'Other dresses - Maxi',

  'Skirts',
  'Knit skirts - Short',
  'Knit skirts - Midi',
  'Knit skirts - Maxi',
  'Other skirts - Short',
  'Other skirts - Midi',
  'Other skirts - Maxi',

  'Tops',
  'Blouses & Tunics ls',
  'Blouses & Tunics ss',
  'Blouses & Tunics sleeveless',
  'T-shirts',
  'Jersey tunic',
  'Sweatshirts',
  'Hoodies',
  'Straptop/singlet',// Straptop/singlets/camisol
  'Cardigan',
  'Pullover',

  // 'Trousers',// Trousers & Shorts
  'Trousers & Shorts',// Trousers & Shorts
  'Trouser',
  'Ankle trouser', // Ankle/cropped trousers
  'Shorts',

  'Leather bags',
  'Leather cross body bags',
  'Leather tote bags',
  'Leather backpacks',
  'Leather purses',
  'Other leather bags',

  'Canvas bags',
  'Canvas cross body bags',// Crossbody
  'Canvas tote bags',//
  'Canvas backpacks',
  'Canvas purses',
  'Shopper bags',
  'Other canvas bags',
  'Other bags',
  'Scarves',
  'Wool scarves',
  'Cotton scarves',
  'Silk scarves',
  'Viscose scarves',

  'Gloves',

  'Headwear',
  'Hat',
  'Cap',
  'Beanies',
  'Bucket',

  'Socks',
  'Wool socks',
  'Cotton socks',
  'Sneaker socks',

  'Swimwear',
  'Swimsuit',
  'Bikini',

  'Shoes',
  'Cotton sneaker',
  'Leather sneaker',
  'Boots',  // High boots
  'Ankle boots',
  'Sandal',
  'Heels',
  'Other shoes',

  'Other accessories',
  'Keyring',
  'Hair accessories',
  'Umbrella',
  'Sunglasses',
  'Jewelry',
  'Others',

  CATEGORY_TOTAL,
]

/**
 * placeholder image
*/
export const IMG_PLACEHOLDER = 'imageplaceholder.png'

/** *
 * property need to mark annotation
 */
export const PropertyQaAnnotation = [
  'Age group',
  'Colors',
  'Color_dot',
  'Categories',
  'Fabrics',
  'material',
  'Neckline',
  'Pattern',
  'Skirt & Dress length',
  'Sleeve length',
  'Trouser length',
  'Fabric attributes',
  'first_image',
  'TargetGroup',
]

// =========================   BEGIN custom event here   =========================
export const CUSTOM_EVENTS = {

  // QA report trigger event 
  QA_REPORT: 'QA_REPORT',

  // QA confirm report trigger event 
  QA_CONFIRM_REPORT: 'QA_CONFIRM_REPORT',

  QA_PRELIMINARY_CONFIRM_REPORT: 'QA_PRELIMINARY_CONFIRM_REPORT',

  LOOKBOOK_ACTIONS: 'LOOKBOOK_ACTIONS',
}

// =========================  report `unset` value ==============================
export const UNSET = 'unset'

export const UNISEX = 'Unisex'

// =========================  current switched license key ======================
/**
 * current switched license key, shared between tabs
 */
export const CURRENT_SWITCHED_LICENSE = 'CURRENT_SWITCHED_LICENSE'

// =========================  all brands  ======================

/**
 * all brands
 */
export const ALL_BRANDS = 'Others'
export const ALL_BRANDS_VAL = ''

/**
 * 
 * lookbook permission name
 * 
 */
export const LOOKBOOK_PERMISSION = 'lookbook export'
export const LOAD_LOOKBOOKS_PERMISSION = 'lookbook'
/** *
 * Disabled menu items and filter items mapping
 * 
 * {
 *  [Unavaliable Menu & Filter Items from api]: Menu & Filter Items
 * }
 */

/**
 *  {
 *  [permission]:label   
 *  }
 * 
 */
/*  eslint-disable */
export const DISABLED_MENU_AND_PROPERTY = {
  [`Pricing jump table`]: `Cross region pricing`,
  [`Market dashboard`]: RoutesObj.DASHBOARD_ALIAS.LABEL,
  [`New Pricing dashboard`]: `New Pricing dashboard`,
  [`QA`]: `QA`,
  [`Category analytics`]:RoutesObj.CATEGORY_ANALYTICS.LABEL,
  [`Color analytics`]: RoutesObj.COLOR_ANALYTICS.LABEL, //`Color analytics`,
  [`Material analytics`]: RoutesObj.MATERIAL_ANALYTICS.LABEL,
  [`Popular options`]: RoutesObj.POPULAR_PRODUCTS.LABEL,
  [`Assortment size`]: RoutesObj.ASSORTMENT_SIZE.LABEL,
  [`Price history`]:RoutesObj.PRICE_HISTORY.LABEL,
  [`Price architecture`]: `Price architecture`,
  [`Discount depth and width`]:RoutesObj.DISCOUNT_DEPTH_AND_WIDTH.LABEL,
  [`Promotions`]: `Campaigns`,
  [`Search for Options`]: `Search for Options`,
  [`My saved filters`]: `My saved filters`,
  [`Create a new lookbook`]: `Create a new lookbook`,
  [`My saved lookbooks`]: `My saved lookbooks`,
  [`Colors`]: `Colors`,
  [`Material`]: `Material`,
  [`Pattern`]: `Pattern`,
  ["Eco label"]: "Eco label",
  ["Fabrics"]: "Fabrics",
  ["Neckline"]: "Neckline",
  ["Skirt & Dress length"]: "Skirt & Dress length",
  ["Sleeve length"]: "Sleeve length",
  ["Trouser length"]: "Trouser length",
  [`Intro date`]: SORTBY_INTRO_DATE,
  [`Name`]: SORTBY_NAME,
  [`Best seller`]: SORTBY_MOST_POPULAR,
  [`Higest price`]: SORTBY_HIGHEST_PRICE,
  [`Lowest price`]: SORTBY_LOWEST_PRICE,
  'All options @ actual retail price': 'All options @ actual retail price',
  'All options @ full price': `All options @ full price`,
  'Only options @ discounted price': 'Only options @ discounted price',
  'Only options @ full price': 'Only options @ full price',
  "Lanuched after": "Lanuched after",
  "Color filter": "Color filter",
  "Material filter": "Material filter",
  [LOOKBOOK_PERMISSION]: "lookbook export",
  [LOAD_LOOKBOOKS_PERMISSION]: 'Load lookbooks',
}

/*  eslint-enable */

/**
 * splitor between brand and vendor
 * 
 * eg: `@` is the splitor
 * 
 * `Coach @ El Palacio de Hierro`
 */
export const BRAND_VENDOR_SPLITOR = '@'
export const SPLITOR = ',,@'

/**
 * all countries in `test` env
 */
export const ALL_COUNTRIES = 'All Countries'

export const LICENSE_BIMBA = 'bimbaylola'

export const PRODUCT_CARD_COLORS = {
  // 'Beige': '#BDA491',
  Beige: [ 194, 164, 144 ],
  // 'Black': '#121212',
  Black: [ 18, 18, 18 ],
  // 'Blue': '#2861A8',
  Blue: [ 40, 97, 168 ],
  // 'Brown': '#7D3F21',
  Brown: [ 125, 63, 33 ],
  // 'Green': '#537D5D',
  Green: [ 83, 125, 93 ],
  // 'Grey': '#BDA491',
  Grey: [ 105, 105, 105 ],
  // 'Orange': '#FA7230',
  Orange: [ 250, 114, 48 ],
  // 'Pink': '#E48A8A',
  Pink: [ 228, 138, 138 ],
  // 'Purple': '#6C6493',
  Purple: [ 108, 100, 147 ],
  // 'Red': '#BF3535',
  Red: [ 191, 53, 53 ],
  // 'White': '#FCF8EE',
  White: [ 252, 248, 238 ],
  // 'Yellow': '#FABB30',
  Yellow: [ 250, 187, 48 ],
}

export const ONE_DAY: number = 1000 * 60 * 60 * 24

export const START_DATE = '2020-08-01'
export const TODAYD_DATE = () => formatDatePickerToDate(getDateObject(new Date().getTime()))

export const PRICE_RANGE_MODAL_CLASS = '.price-range-modal'

// filter 关联打开的弹框`class`, 查找到此`class` 对应的dom表示该弹框是从`Filter`中点开
export const FILTER_DIALOG_CLASS = 'filter-dialog'

// competitor 默认显示的长度，超出长度是自动省略号
export const COMPETITOR_DEFAULT_LENTH = 30

/**
 * ## `Vendor` 界面显示缩写映射
 */
export const VendorShortMapping = {
  'El Palacio de Hierro': 'El Palacio',
}

// color, material filter
export const ColorMaterialEmptyValue = { percent: 51, checked: false }
export const ColorMaterialDefaultValue = { ...ColorMaterialEmptyValue, checked: true }

// ProductCard 会定义一个自定义属性 data-type={PRODUCT_CARD_TYPE}
// 在 useWindow.ts 中也会使用到这个变量, 因此提取为公共常量使用
export const PRODUCT_CARD_TYPE = 'product-card'

export const COMPETITOR_MAX_COUNT = 99999
