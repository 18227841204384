import { format } from 'date-fns'
import { lsGet } from './ls'
import { CONST_VARS } from 'libs/const';
import AuthToken from './AuthToken';

const apiPrefix = CONST_VARS.API_URL

function maxRequest(url, data, headers, times = 3) {
    // 1. 闭包，保存私有属性
    function autoRetry(url, data, headers, times) {
        console.log('times = ', times);
        times--;
        // 2. fetch 本身返回值就是 Promise，不需要再次使用 Promise 包裹
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                ...headers,
                'Content-Type': 'application/json;charset=utf-8',
            },
        }).then(value => {
            if (value.status === 200) {
                // 3. 手动返回 Promise 的 value， 没有返回值 默认返回 undefined
                return value;
            } else {
                throw new Error(`❌  http code error: ${value.status}`);
            }
        }).catch((err) => {
            if (times < 1) {
                // 4. 方便后续的 thenable 处理 error
                throw new Error('💩  over max request times!');
            } else {
                // 5. 返回递归方法 
                return autoRetry(url, data, headers, times);
            }
        });
    }
    // 6. 返回一个 Promise 的结果 （成功 Promise 或失败 Promise）
    return autoRetry(url, data, headers, times);
}

/**
 * 记录错误日志
 */
export const recordErrorLog = (errorInfo: string) => {
    // 本地开发环境不记录错误日志
    if (window.location.host === 'localhost:3000') {
        return
    }
    try {
        const token = AuthToken.getAccessToken()
        const payload = {
            url: window.location.href,
            email: lsGet('email'),
            browser_info: window.navigator.userAgent,
            error_info: errorInfo,
            front_record: true,
            create_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        }
        maxRequest(
            `${apiPrefix}/service_error/`,
            payload,
            {
                Authorization: 'Bearer ' + token,
            },
            10,
        )
    } catch (e) {
        console.warn('recordErrorLog has error: ', e)
    }
}
