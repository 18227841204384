import React, { CSSProperties } from 'react'

export const MyPriceSpreadChart = ({
    style,
    className,
    // 最低价
    lowestPrice,
    // 平均价
    averagePrice,
    // 最高价
    highestPrice,
    // 范围最高价
    rangeHighestPrice,
    // 范围最低价
    rangeLowestPrice,
    color = '#74B6B6',
    formatter,
}: {
    style?: CSSProperties;
    className?: string;
    lowestPrice: number;
    averagePrice: number;
    highestPrice: number;
    rangeHighestPrice?: number;
    rangeLowestPrice?: number;
    color?: string;
    formatter?: (value: number) => string;
}) => {
    if (typeof rangeHighestPrice === 'undefined') {
        rangeHighestPrice = highestPrice
    }

    if (typeof rangeLowestPrice === 'undefined') {
        rangeLowestPrice = lowestPrice
    }

    const rangeWidth = rangeHighestPrice - rangeLowestPrice
    const lowestBarWidth = (averagePrice - lowestPrice) / rangeWidth * 100 + '%'
    const lowestBarLeft = Math.max(0, (lowestPrice - rangeLowestPrice) / rangeWidth) * 100 + '%'
    let highestBarWidth = Math.min((highestPrice - averagePrice) / rangeWidth, 100) * 100 + '%'
    highestBarWidth = `calc(${highestBarWidth} - 6px)`
    const hightestBarLeft = `calc(${lowestBarWidth} + ${lowestBarLeft} + 3px)`

    return (
        <div
            style={{
                background: '#F8FDFD',
                width: '100%',
                height: 64,
                position: 'relative',
                paddingLeft: 27,
                paddingRight: 35,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                ...style,
            }}
            className={className}
        >
            <div 
                style={{
                    height: '100%',
                    width: 6,
                    backgroundColor: color,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
            />
            <div 
                style={{
                    height: '100%',
                    width: 6,
                    backgroundColor: color,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }}
            />
            <div 
                style={{
                    borderWidth: 7,
                    borderStyle: 'solid',
                    borderColor: `transparent ${color} transparent transparent`,
                }}
            />
            <div 
                style={{
                    flex: 1,
                    marginLeft: 6,
                    marginRight: 6,
                    borderTopWidth: 1,
                    borderTopColor: '#D1D6DC',
                    borderTopStyle: 'dashed',
                    position: 'relative',
                }}
            >
                <div 
                    style={{
                        position: 'absolute',
                        left: lowestBarLeft,
                        top: '50%',
                        marginTop: -7,
                        width: lowestBarWidth,
                        height: 12,
                        borderTopLeftRadius: 12,
                        borderBottomLeftRadius: 12,
                        backgroundColor: color,
                    }}
                />
                <div 
                    style={{
                        position: 'absolute',
                        left: hightestBarLeft,
                        top: '50%',
                        marginTop: -7,
                        width: highestBarWidth,
                        height: 12,
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        backgroundColor: color,
                    }}
                />
                <div style={{
                    position: 'absolute',
                    left: `calc(${lowestBarLeft} - 16px)`,
                    bottom: 10,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#878787',
                }}>
                    {typeof formatter === 'function' ? formatter(lowestPrice) : lowestPrice}
                </div>
                <div style={{
                    position: 'absolute',
                    left: `calc(${hightestBarLeft} - 10px)`,
                    top: 10,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color,
                }}>
                    {typeof formatter === 'function' ? formatter(averagePrice) : averagePrice}
                </div>
                <div style={{
                    position: 'absolute',
                    left: '100%',
                    bottom: 10,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#878787',
                }}>
                    {typeof formatter === 'function' ? formatter(highestPrice) : highestPrice}
                </div>
            </div>
            <div 
                style={{
                    borderWidth: 7,
                    borderStyle: 'solid',
                    borderColor: `transparent transparent transparent ${color}`,
                }}
            />
        </div>
    )
}
