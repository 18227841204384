import { useDispatch, useSelector } from 'react-redux'
import * as config from 'configs/moduleName'
import { selectModuleData, updateModuleData2 } from './moduleDataSlice'
import { 
    CrossRegionSoldOutModuleDataType, 
    CsvDataModuleDataType, 
    GraphButtonModuleDataType, 
    ModuleDataReturnType, 
    MostPopularLaunchDaysModuleDataType, 
    OptionsInOutByMonthModuleDataType, 
    PlatformAnalyticsModuleDataType, 
    SoldOutVendorsModuleDataType, 
} from './moduleDataSlice.interface'
import { 
    CSV_DATA_MODULE_NAME, 
    GRAPH_BUTTON_MODULE_NAME, 
    MOST_POPULAR_LAUNCH_DAYS_MODULE_NAME, 
    OPTIONS_IN_OUT_BY_MONTH_MODULE_NAME, 
    PLATFORM_ANALYTICS_MODULE_NAME, 
    SOLD_OUT_VENDORS_TABLE, 
} from './moduleDataSlice.const'

export const useModuleData = (moduleName): [any, (value: any) => void] => {
    const dispath = useDispatch()
    const moduleData = useSelector(selectModuleData)

    const setModuleData = (value: any) => {
        dispath(updateModuleData2({
            moduleName,
            payload: value,
        }))
    }

    return [
        moduleData[moduleName],
        setModuleData,
    ]
}

export const useSoldOutVendorsModuleData = (): ModuleDataReturnType<SoldOutVendorsModuleDataType> => {
    return useModuleData(SOLD_OUT_VENDORS_TABLE)
}

export const useCrossRegionSoldOutModuleData = (): ModuleDataReturnType<CrossRegionSoldOutModuleDataType> => {
    return useModuleData(config.CROSS_REGION_SOLD_OUT_NAME)
}

export const useGraphButtonModuleData = (): ModuleDataReturnType<GraphButtonModuleDataType> => {
    return useModuleData(GRAPH_BUTTON_MODULE_NAME)
}

export const usePlatformAnalyticsModuleData = (): ModuleDataReturnType<PlatformAnalyticsModuleDataType> => {
    return useModuleData(PLATFORM_ANALYTICS_MODULE_NAME)
}

export const useCsvDataModuleData = (): ModuleDataReturnType<CsvDataModuleDataType> => {
    return useModuleData(CSV_DATA_MODULE_NAME)
}

export const useMostPopularLaunchDaysModuleData = (): ModuleDataReturnType<MostPopularLaunchDaysModuleDataType> => {
    return useModuleData(MOST_POPULAR_LAUNCH_DAYS_MODULE_NAME)
}

export const useOptionsInOutByMonthModuleData = (): ModuleDataReturnType<OptionsInOutByMonthModuleDataType> => {
    return useModuleData(OPTIONS_IN_OUT_BY_MONTH_MODULE_NAME)
}
