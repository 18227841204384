import React, { ReactNode, useRef, useState } from 'react'
import classnames from 'classnames'
import { OptionType } from 'types'
import { intersection } from 'utils/array'
import { uniq } from 'lodash'
import { TreeOption } from '../TreeOption'
import styles from './styles.module.scss'

interface TreeItemProps {
    zoom?: number;
    itemData: OptionType;
    value: string[];
    onClick: (itemData: OptionType) => void;
    multiple?: boolean;
    bordered?: boolean;
    minCount?: number;
    maxCount?: number;
    options: OptionType[];
    level1MaxCount?: number;
}

export const TreeItem = ({
    zoom = 1,
    itemData,
    value=[],
    onClick,
    multiple = false,
    bordered = false,
    minCount = 0,
    maxCount = 9999,
    level1MaxCount,
    options = [],
}: TreeItemProps) => {
    const isParent = Boolean(Array.isArray(itemData?.children) && itemData?.children?.length)
    const childrenVisibleRef = useRef<boolean>(false)
    const positionRef = useRef<{ left: number; top: number}>({ left: 0, top: 0 })
    const [ , setRefresh ] = useState({})

    const treeItemChildrenCls = classnames({
        [styles.treeItemChildren]: true,
        [styles.border]: bordered,
    })

    let disableClick = false
    if (value.length === minCount && value.includes(itemData.key)) {
        disableClick = true
    }
    if (value.length === maxCount && !value.includes(itemData.key)) {
        disableClick = true
    }
    if (level1MaxCount && getFirstLevelValues(options, value)?.length === level1MaxCount && !getAllValues(options, value).includes(itemData.key)) {
        disableClick = true
    }

    let childrenEl: ReactNode = null
    if (isParent) {
        childrenEl = itemData?.children?.map(item2 => {
            const isDisabled = itemData.isDisabled || item2.isDisabled
            const isParent = Array.isArray(item2?.children) && !!item2?.children?.length

            return (
                <div className={styles.treeItem} key={item2.key}>
                    <TreeOption
                        label={item2.description}
                        value={isActive(item2, value)}
                        onChange={checked => {
                            onClick(item2)
                        }}
                        disabled={isDisabled}
                        disabledClick={(value.length === minCount && value.includes(item2.key)) || (value.length === maxCount && !value.includes(item2.key))}
                        checkboxVisible={multiple && !isParent}
                        arrowVisible={isParent}
                    />
                </div>
            )
        })
    }

    return (
        <>
            <div
                className={styles.treeItem}
                onMouseEnter={e => {
                    if (!isParent) return
                    const rect = e.currentTarget.getBoundingClientRect()
                    childrenVisibleRef.current = true
                    positionRef.current = { left: rect.right / zoom + 1, top: rect.top / zoom - 9 }
                    setRefresh({})
                }}
                onMouseLeave={e => {
                    if (!isParent) return
                    childrenVisibleRef.current = false
                    setRefresh({})
                }}
            >
                <TreeOption
                    label={itemData.description}
                    value={isActive(itemData, value)}
                    onChange={() => {
                        onClick(itemData)
                    }}
                    disabled={itemData.isDisabled}
                    disabledClick={disableClick}
                    checkboxVisible={multiple && !isParent}
                    arrowVisible={isParent}
                />
                {
                    isParent && childrenVisibleRef.current ? (
                        <div 
                            className={treeItemChildrenCls} 
                            style={{ 
                                position: 'fixed',
                                left: positionRef.current.left + 'px',  
                                top: positionRef.current.top + 'px',
                                zIndex: 99999,
                            }}
                        >
                            {childrenEl}
                        </div>
                    ) : null
                }
            </div>
        </>
    )
}

function isActive(optionItem: OptionType, selectedKeys: string[] = []) {
    if (optionItem.isDisabled) {
        return false
    }
    if (Array.isArray(optionItem?.children) && optionItem?.children?.length) {
        const childrenKeys = optionItem?.children
            ?.filter(item => !item.isDisabled)
            ?.map(item => item.key)
        return !!intersection(selectedKeys, childrenKeys)?.length
    }
    return selectedKeys.includes(optionItem.key)
}

function getFirstLevelValues(options, value) {
    let data: string[] = []
    options.forEach(l1 => {
        if (l1?.children?.length) {
            const childrenValue = l1.children?.filter(l2 => value.includes(l2.key))
            if (childrenValue.length === l1.children.length) {
                data.push(l1.key)
            } else {
                data.push(...childrenValue)
            }
        } else {
            if (value.includes(l1.key)) {
                data.push(l1.key)
            }
        }
    })
    data = data.filter(d => d)
    return uniq(data)
}

function getAllValues(options, value) {
    let data: string[] = []
    options.forEach(l1 => {
        if (l1?.children?.length) {
            const childrenValue = l1.children?.filter(l2 => value.includes(l2.key))
            if (childrenValue.length === l1.children.length) {
                data.push(l1.key)
            }
            data.push(...childrenValue)
        } else {
            if (value.includes(l1.key)) {
                data.push(l1.key)
            }
        }
    })
    data = data.filter(d => d)
    return uniq(data)
}
