/**
* @file    useDialog
* @date   2022-01-25
* @author byte_su@163.com
*/
import { getLookbookData, updateCheckedLookbook, updateLatestLookbook, updateLookbookList, updateMyLookbookList } from 'features/filters/lookbookDataSlice'
import { RoutesObj } from 'global/Routes'
import { LookbookType } from 'libs'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { storage } from 'utils/storage'

export const useLookbook = () => {
    const dispatch = useDispatch()
    const { list, checked, myLookbookList, latestLookbook } = useSelector(getLookbookData)
    const location = useLocation()
    const isLookbook = !!checked.length
    
    const enabledLookbook = true

    return {
        setCheckedList: (lookbookIds: Array<string>) => {
            storage.setIsLookbook(Boolean(lookbookIds.length))
            dispatch(updateCheckedLookbook(lookbookIds))
        },
        setLookbookList: (list: Array<LookbookType>) => {
            dispatch(updateLookbookList(list))
        },
        setMyLookbookList: (value: any[]) => {
            dispatch(updateMyLookbookList(value))
        },
        setLatestLookbook: (value: any) => {
            dispatch(updateLatestLookbook(value))
        },
        latestLookbook,
        myLookbookList,
        list,
        isLookbook,
        enabledLookbook,
        isSearchOptions: location.pathname.startsWith(RoutesObj.SEARCH_OPTIONS.URL),
        isAttributeRanking: location.pathname.startsWith(RoutesObj.POPULAR_ATTRIBUTES.URL),
        checked,
        checkedLookbookLabels:list.filter((item:LookbookType) => checked.includes(item.id)).map(item => item.name),

    }
}

useLookbook.displayName = 'useLookbook'
