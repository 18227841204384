import { isShowWarnDataDialog } from './privUtils';
import { storage } from './storage';

/**
 * 外部会传一个日期范围值, 取开始时间
 */
export const isInclueInvalidVendors = ({
    dateRangeValue,
    compareDateRangeValue,
    vendorList,
    region,
}: {
    dateRangeValue: string;
    compareDateRangeValue?: string;
    vendorList: string[];
    region: string;
}) => {
    if (!isShowWarnDataDialog()) {
        return false
    }

    const startDate = dateRangeValue.split('_')[0]
    let date = startDate
    if (compareDateRangeValue) {
        const compareStartDate = compareDateRangeValue.split('_')[0]
        date = startDate > compareStartDate ? compareStartDate : startDate
    }
    
    const sellers = storage.getSellers()
    const dates = sellers
        .filter(s => s.region === region && vendorList.includes(s.vendor))
        .map(s => s.launch_date)

    return dates.some(d => d > date)
}

