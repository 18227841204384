import React, { CSSProperties, ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { useFilterCurrency } from 'hooks/useFilter'
import classnames from 'classnames'
import { getTableTitleTooltip } from 'utils'
import { formatDateStr2 } from 'utils/dateUtils'
import { usePageDate } from 'hooks/usePageDate'
import { PAGE_CONTAINER_CLASSNAME } from 'consts/strings'
import { throttle } from 'lodash'
import styles from './Th.module.scss'
import { BootstrapTooltip } from '../BootstrapTooltip'

export const Th = ({
    title,
    width,
    align = 'left',
    style = {},
    className,
    showTooltip = true,
}: {
    title: string;
    width: number;
    align?: 'left' | 'right' | 'center';
    style?: CSSProperties;
    className?: string;
    showTooltip?: boolean;
}) => {
    const tooltip = getTableTitleTooltip(title)

    const sty: CSSProperties = {
        width: width + 'px',
        textAlign: align,
        paddingBottom: '10px',
    }

    const contentEl = <div style={{ ...sty, ...style }} className={className}>{title}</div>

    if (!showTooltip) {
        return contentEl
    }

    return (
        <BootstrapTooltip title={<div dangerouslySetInnerHTML={{ __html: tooltip }}></div>}>
            {contentEl}
        </BootstrapTooltip>
    )
}

export const SubTh = ({
    width,
    innerWidth,
    align = 'left',
    showPercent = false,
    showPrice = false,
    showUnit = false,
    showSymbol = false,
    showCount = false,
    showCurrency = false,
    showComparisonTooltip = false,
    children,
    style = {},
    className = '',
}: {
    width: number;
    innerWidth?: number;
    align?: 'left' | 'right' | 'center';
    showPercent?: boolean;
    showPrice?: boolean;
    showUnit?: boolean;
    showSymbol?: boolean;
    showCount?: boolean;
    showCurrency?: boolean;
    showComparisonTooltip?: boolean;
    children?: ReactNode;
    style?: CSSProperties;
    className?: string;
}) => {
    const { pageDate, comparisonPageDate } = usePageDate()
    const [ filterCurrency ] = useFilterCurrency()

    const sty = {
        fontSize: '11px',
        color: '#484848',
        padding: '0 2px 0 8px',
        height: '20px',
    }

    const content = (
        <span style={{ display: 'inline-block', height: '100%' }}>
            { showUnit ? <span>Units&nbsp;</span> : null}
            { showPrice ? <span>Price&nbsp;</span> : null}
            { showCurrency ? <span>{filterCurrency}&nbsp;</span> : null}
            { showCount ? <span>#&nbsp;</span> : null}
            { showSymbol ? <span>+/-&nbsp;</span> : null }
            { showPercent ? <span style={{ color: '#01A699' }}>%&nbsp;</span> : null }
            { children }
        </span>
    )

    /* ************************************** 计算高度 ******************************************* */
    const textRef = useRef<HTMLDivElement>(null)
    const [ calendarWidth, setCalendarWidth ] = useState(0)

    useLayoutEffect(() => {
        if (!textRef.current) return
        const width = textRef.current?.getBoundingClientRect()?.width
        if (!width) return
        setCalendarWidth(width)
    }, [])

    /* ************************************** 监听弹窗是否显示 ******************************************* */
    const hideTooltipRef = useRef<boolean>(false)
    // eslint-disable-next-line
    const [ refresh, setRefresh ] = useState({})
    
    const onHideTooltip = throttle(() => {
        const top = textRef.current?.getBoundingClientRect()?.top || 0
        hideTooltipRef.current = top < 170
        setRefresh({})
    }, 100)
    
    /**
     * 监听页面滚动事件
     */
    useLayoutEffect(() => {
        const pageContainerEl = document.querySelector(`.${PAGE_CONTAINER_CLASSNAME}`)
        if (!pageContainerEl) return
        pageContainerEl.addEventListener('scroll', onHideTooltip)
        return () => {
            pageContainerEl.removeEventListener('scroll', onHideTooltip)
        }
        // eslint-disable-next-line
    }, [])

    if (showComparisonTooltip) {
        return (
            <div 
                style={{ width: width + 'px', textAlign: align, ...sty, ...style }} 
                className={classnames({
                    [className]: true, 
                    [styles.subTh]: true,
                    [styles.hideTooltop]: hideTooltipRef.current,
                })}
            >
                <div className={styles.text} style={{ height: '100%' }} ref={textRef}>
                    { showUnit ? <span>Units&nbsp;</span> : null}
                    { showPrice ? <span>Price&nbsp;</span> : null}
                    { showCurrency ? <span>{filterCurrency}&nbsp;</span> : null}
                    { showCount ? <span>#&nbsp;</span> : null}
                    { showSymbol ? <span>+/-&nbsp;</span> : null }
                    { showPercent ? <span style={{ color: '#01A699' }}>%</span> : null }
                    { children }
                </div>
                <div className={styles.calendarIcon} style={{ width: calendarWidth + 'px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="14px" viewBox="0 0 24 24" width="14px" fill="rgb(1, 166, 153)">
                        <g>
                            <rect fill="none" height="24" width="24" />
                        </g>
                        <g>
                            <path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z" />
                        </g>
                    </svg>
                    <div className={styles.comparisonTooltip}>
                        <div style={{ fontSize: '14px', fontWeight: 'normal', color: '#585858', marginBottom: '6px' }}>Comparison period</div>
                        <div style={{ fontSize: '12px', fontWeight: 'normal', color: '#999999' }}>{`${formatDateStr2(pageDate)} vs ${formatDateStr2(comparisonPageDate)}`}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ width: width + 'px', textAlign: align, ...sty, ...style }} className={className}>
            {content}
        </div>
    )
}
