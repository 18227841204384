import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { PlusProps } from './types'

export const PlusIcon = ({ className }: PlusProps) => 
    <svg
        className={classnames(
        styles.plus,
        className,
      )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </svg>

