import classNames from 'classnames'
import { InlineLoading } from 'components'
import React, { FC, CSSProperties, memo, useState, useEffect } from 'react'
import styles from './styles.module.scss'

interface GridProps {
  productsIn: number;
  productsOut: number;
  style?: CSSProperties;
  date: string;
  onClick?: (date: string) => void;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  loading?: boolean;
}

export enum GridModeEnum {
  fillBlank = 'fillBlank',    // productsIn 为 0，productsOut 为 0
  fillIn = 'fillIn',          // productsIn 不为 0，productsOut 为 0
  fillOut = 'fillOut',        // productsIn 为 0，productsOut 不为 0
  fillInOut = 'fillInOut',    // productsIn 不为 0，productsOut 不为 0
}

const Grid: FC<GridProps> = ({
  productsIn,
  productsOut,
  date,
  style,
  onClick,
  onMouseEnter,
  onMouseLeave,
  loading = false,
}) => {
  const [ mode, setMode ] = useState<GridModeEnum>(GridModeEnum.fillBlank)

  useEffect(() => {
    if (productsIn && productsOut) {
      setMode(GridModeEnum.fillInOut)
    } else if (productsIn && !productsOut) {
      setMode(GridModeEnum.fillIn)
    } else if (!productsIn && productsOut) {
      setMode(GridModeEnum.fillOut)
    } else {
      setMode(GridModeEnum.fillBlank)
    }
  }, [ productsIn, productsOut ])

  return (
      <div
          style={style}
          className={classNames(styles.grid, styles[mode]?styles.active:'')} 
          onClick={() => onClick?.(date)}
          onMouseEnter={e => onMouseEnter?.(e)}
          onMouseLeave={e => onMouseLeave?.(e)}
      >
          {
            loading ? (
              <div style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 10 }}>
                <InlineLoading sty={{ padding: 0, display: 'flex', alignItems: 'center' }} />
              </div> 
            ) : null
          }
          <div className={styles[mode]}>
              <div />
          </div>
          <div className={styles.day}>{new Date(date).getDate()}</div>
      </div>
  )
}

export default memo(Grid)
