import React from 'react'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useLoadingBar } from 'hooks'
import { ColorSplitPieGraph } from './components/ColorSplitPieGraph'
import styles from './styles.module.scss'

export const ColorSplit = () => {
    useScrollToTop()
    useLoadingBar()

    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.container}>
                <ColorSplitPieGraph />
            </div>
        </div>
    )
}

