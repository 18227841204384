import React, { useState } from 'react'
import classnames from 'classnames'
import { CloseIcon } from 'assets/icons'
import { FILTER_DIALOG_CLASS } from 'consts'
import { defaultTaxFilter } from 'features/filters/filtersSlice'
import { Button } from 'components/form-elements/Button'
import { Flex } from 'componentsv2/Flex'
import { Checkbox } from 'componentsv2/form-elements/Checkbox'
import { SizedBox } from 'componentsv2/SizedBox'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { cloneDeep } from 'lodash'
import { USA_VALUE } from 'configs/region'
import { useFilterTax } from 'hooks/useFilter'
import { TaxFilterProps } from './types' 
import styles from './styles.module.scss'

export const TaxFilterModal = ({
    onOk,
    onClose,
    left = 390,
    top = 200,
}: TaxFilterProps) => {
    const [ data, setData ] = useState<{checked: boolean; value: string | number}>(defaultTaxFilter)
    const [ filterTax, setFilterTax ] = useFilterTax()

    useDeepCompareEffect(() => {
        if (!filterTax) return
        const newValue = cloneDeep(filterTax)
        newValue.value *= 100
        setData({ ...newValue })
    }, [ filterTax, [] ])

    const onSave = () => {
        const newData = { 
            checked: data.checked,
            value: Number(data.value) / 100,
        }
        onOk?.({ ...newData })
        setFilterTax({ ...newData })
    }

    return (
        <>
            <div className={`material-filter ${FILTER_DIALOG_CLASS}`} onMouseDown={e => e.stopPropagation()}>
                <div className={styles.modal} />
                <div className={classnames(styles.taxFilter)} style={{ left: left + 'px', top: top + 'px' }}>
                    <div className={styles.close}>
                        <CloseIcon onClick={() => { onClose?.() }} />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            Add sales tax
                        </div>
                        <SizedBox height={22} />
                        <Flex 
                            alignItems="center" 
                            justifyContent="space-between" 
                            className={classnames({
                                [styles.regionItem]: true,
                                [styles.checked]: data.checked,
                            })}
                        >
                            <Checkbox
                                value={data.checked}
                                onChange={checked => {
                                    const newData = { ...data }
                                    newData.checked = checked
                                    setData({ ...newData })
                                }}
                            >
                                <div className={styles.region}>{USA_VALUE}</div>
                            </Checkbox>
                            <input 
                                className={styles.rate} 
                                value={data.value} 
                                onChange={e => {
                                    const newData = { ...data }
                                    newData.value = e.target.value
                                    setData({ ...newData })
                                }} 
                                onBlur={() => {
                                    const newData = { ...data }
                                    if (String(Number(newData.value)) !== 'NaN') {
                                        newData.value = parseFloat(newData.value.toString())
                                    } else {
                                        newData.value = 0
                                    }
                                    setData({ ...newData })
                                }}
                            />
                            <span className={styles.percentSymbol}>%</span>
                        </Flex>
                        <SizedBox height={49} />
                        <Button
                            primary
                            mini
                            className={styles.saveBtn}
                            onClick={onSave}
                        > 
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
