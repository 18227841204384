import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ProductsModal } from 'components/ProductsModalV2'
import { Spin } from 'componentsv2'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { ShowWhenOpenInNew, HideWhenOpenInNew, OpenInNewButton } from 'componentsv2/business/OpenInNewButton'
import { SplitPieChart } from 'componentsv2/business/SplitPieChart'
import { SplitPieList } from 'componentsv2/business/SplitPieList'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { MultipleDropdown } from 'componentsv2/form-elements/MultipleDropdown'
import { Switch } from 'componentsv2/form-elements/Switch'
import { LookbookWithoutData } from 'componentsv2/LookbookPlaceholder'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { SizedBox } from 'componentsv2/SizedBox'
import { NA, OPEN_IN_NEW_DATE, OPTIONS_NAME } from 'consts'
import { downloadFileByAxios } from 'export'
import { RoutesObj } from 'global/Routes'
import { useExcludeProductsComparisonQuery, useUrlParams } from 'hooks'
import useDialogTitle from 'hooks/useDialogTitle'
import { useLookbook } from 'hooks/useLookbook'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { useCategoryOptions, useCompetitorOptions } from 'hooks/useOptions'
import { usePageDate } from 'hooks/usePageDate'
import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { vendorShortMappingFn, regionShortMappingFn, formatBrandVendor, getVendorNameByCode } from 'utils'
import { formatDateStr2 } from 'utils/dateUtils'
import { formatVendorName } from 'utils/sellers'
import { setDashboardCategories } from 'features/filters/filtersSlice'
import { CATEGORY_SPLIT_PIE_GRAPH_NAME } from 'configs'
import { useSplitPieGraphData } from 'hooks/api/useSplitPieGraphData'
import { useModuleData } from 'hooks/useModuleData'
import { useFindProductIds } from 'hooks/api/useFindProductIds'
import { categorySplitExportFilename } from 'utils/exportUtils'
import { handleIsLock, handleQueryDateValid, handleVendorSort } from 'utils/pageUtils'
import { useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { handlePieColorObj, handlePieCategory, handlePiePercent, handlePieDataStructure, handleListDataToTreeData, handleFilterData, geneCsvData } from './utils'
import styles from './styles.module.scss'
import GraphButton from 'componentsv2/GraphButton'
import { getFilterCategoryFirstLevelList } from 'utils/lockSellerUtils'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_CATEGORY_SPLIT } from 'features/filters/moduleDataSlice.const'
import { arrayUtils, numberUtils, stringUtils } from 'norna-uikit'
import { handleColorOpacity } from 'utils/colorJson'

export const CategorySplitPieGraph = () => {
    const { fnUseAppendDialogTitlePriceFilter } = useDialogTitle()
    const { pageDate: dateRangeValue } = usePageDate()
    const [ filterCountry ] = useFilterCountry()
    const { competitorOptions } = useCompetitorOptions()
    const [ filterCompetitor ] = useFilterCompetitor()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })

    const dispatch = useDispatch()
    
    const [ moduleData, setModuleData ] = useModuleData(CATEGORY_SPLIT_PIE_GRAPH_NAME)
    const {
        fetchPayload = {},
        apiData = [],
        newCompetitorValue = [],
        switchValue = false,
        expandNamesObj = {},
    } = moduleData

    /* ************************************ Lookbook ******************************************* */
    const { isLookbook } = useLookbook()
    const { judgeActionOccurs, clearLookbookActions } = useLookbookAction(true, true)
    const [ lookbookWithoutData, setLookbookWithoutData ] = useState(false)

    /* ***************************** Open in new *************************************** */
    const urlParams = useUrlParams()

    useEffect(() => {
        if (typeof urlParams?.pieSwitchValue !== 'undefined') {
            setSwitchValue(urlParams.pieSwitchValue === 'true')
        }
        // eslint-disable-next-line
    }, [])

    /* ********************************** Export excel **************************************** */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        payload.data.groups = getGroupsPayload({ competitorSellers: filterCompetitor })
        payload.data.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
        payload.query.units = switchValue
        await downloadFileByAxios({
            filename: categorySplitExportFilename,
            payload,
        })
    }

    /* ********************************** Form **************************************** */
    // category dropdown datasource
    const { categoryOptions } = useCategoryOptions({ excludeTotal: true })
    // 页面上 Category 下拉框用来过滤实际显示哪些 category
    const [ categoryValue, setCategoryValue ] = useState<string[]>([])

    useDeepCompareEffect(() => {
        if (typeof urlParams?.pieCategoryValue !== 'undefined') {
            setCategoryValueFn(JSON.parse(urlParams?.pieCategoryValue))
            return
        }
        if (categoryOptions.length) {
            setCategoryValueFn([ ...categoryOptions.map(item => item.key) ])
        }
    }, [ categoryOptions ])

    // update selected category value in redux
    const setCategoryValueFn = (value: string[] = []) => {
        setCategoryValue(value)
        dispatch(setDashboardCategories(value.length === categoryOptions.length ? [] : value))
    }

    const setSwitchValue = (value: boolean) => {
        setModuleData({ switchValue: value })
    }

    /* ********************************** Data **************************************** */
    const { data, loading, fetchData, setData } = useSplitPieGraphData({ moduleName: CATEGORY_SPLIT_PIE_GRAPH_NAME })
    const [ dataSource, setDataSource ] = useState<any[]>([])
    const [ , setCsvData ] = useCsvDataModuleData()

    const handleData = apiData => {
        setLookbookWithoutData(isLookbook && Array.isArray(apiData) && apiData.length === 0)
        if (!Array.isArray(apiData) || !apiData.length) return

        // 复制一份
        apiData = cloneDeep(apiData)
        // seller 字段添加 queryDateValid
        apiData = handleQueryDateValid({ data: apiData, competitorValue: newCompetitorValue, filterCountry })
        // seller 字段添加 isLock
        apiData = handleIsLock({ data: apiData, competitorValue: newCompetitorValue, region: filterCountry })
        // seller.vendor 排序
        apiData = handleVendorSort({ data: apiData })
        // bucket_assortments 列表数据结构转换为树状数据结构
        apiData = handleListDataToTreeData({ data: apiData })
        // bucket_assortments 过滤数据 
        apiData = handleFilterData({ data: apiData, categoryValue })
        // color mapper
        const colorObj = handlePieColorObj(apiData)

        apiData = apiData.map(item => {
            const list = cloneDeep(item.bucket_assortments)
            // A maximum of 15 categories can be displayed in the list
            let commonList: any[] = handlePieCategory(cloneDeep(list), 15)
            // Recalculate the percentage
            commonList = handlePiePercent(commonList, 3)
            commonList = handlePieDataStructure(commonList, colorObj, item.seller)

            const unitList = commonList.map((item2: any) => {
                if (Array.isArray(item2?.children) && item2?.children?.length) {
                    item2.children = item2.children.map(item3 => {
                        return {
                            ...item3,
                            currentValue: item3.countCurrentValue,
                            changePercentage: item3.countChangePercentage,
                        }
                    })
                }
                return {
                    ...item2,
                    currentValue: item2.countCurrentValue,
                    changePercentage: item2.countChangePercentage,
                }
            })

            const percentList = commonList.map(item2 => {
                if (Array.isArray(item2?.children) && item2?.children?.length) {
                    item2.children = item2.children.map(item3 => {
                        return {
                            ...item3,
                            currentValue: item3.percentCurrentValue,
                            changePercentage: item3.percentChange,
                        }
                    })
                }
                return {
                    ...item2,
                    currentValue: item2.percentCurrentValue,
                    changePercentage: item2.percentChange,
                }
            })

            // num: 饼图上面显示的产品总数, 当使用 Category 下拉框过滤时, 就需要重新计算产品总数了
            let num = arrayUtils.fsum(list.map(item => item.count.current_value))
            // Category 下拉框选择全部, 那么总数量就使用接口返回的 num_products 字段值
            if (categoryValue.length === categoryOptions.length) {
                num = item.num_products
            }

            const pieChartList = commonList.map(item2 => {
                if (expandNamesObj[item.seller.vendor]?.includes(item2.name)) {
                    return item2.children
                }
                return item2
            }).flat(2)
            let datasets: any[] = [
                {
                    label: pieChartList.map(item2 => item2.label),
                    data: pieChartList.map(item2 => item2.percent.current_value * 100),
                    backgroundColor: pieChartList.map(_ => handleColorOpacity(colorObj[_.name], 0.7)),
                    hoverBackgroundColor: pieChartList.map(_ => handleColorOpacity(colorObj[_.name], 1)),
                    borderColor: Array(list.length).fill('rgba(255, 255, 255, 1)'),
                    borderWidth: 1,
                },
            ]
            if (item?.seller?.isLock) {
                const categoryList = getFilterCategoryFirstLevelList()
                item.bucket_assortments = categoryList.slice(0, 11).map(c => ({ name: c }))
                datasets = [
                    {
                        label: item.bucket_assortments.map(item2 => item2.name),
                        data: item.bucket_assortments.map(() => 1),
                        backgroundColor: item.bucket_assortments.map(item2 => handleColorOpacity(colorObj[item2.name], 0.7)),
                        hoverBackgroundColor: item.bucket_assortments.map(item2 => handleColorOpacity(colorObj[item2.name], 1)),
                        borderColor: Array(item.bucket_assortments.length).fill('rgba(255, 255, 255, 1)'),
                        borderWidth: 1,
                    },
                ]
            }

            return {
                charts: {
                    datasets,
                },
                list: cloneDeep(list),
                commonList: cloneDeep(commonList),
                percentList,
                unitList,
                pieList: pieChartList,
                num,        // 饼图上面显示的产品总数
                seller: item.seller,
                colorObj,
            }
        })

        setDataSource(cloneDeep(apiData))
        setCsvData({
            [CSV_DATA_CATEGORY_SPLIT]: geneCsvData({ dataSource: apiData, switchValue }),
        })
    }

    // handle data
    useEffect(() => {
        if (!data) return

        handleData(cloneDeep(data))
        // eslint-disable-next-line
    }, [data, categoryValue, expandNamesObj])

    /* ********************************** product detail modal **************************************** */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    const { fetchData: fetchProductIds } = useFindProductIds({ moduleName: CATEGORY_SPLIT_PIE_GRAPH_NAME })

    const onShowProductModal = async (item, seller) => {
        item.region = regionShortMappingFn(seller?.region === 'World' ? comparisonQuery?.customer_seller?.region : seller.region)
        item.vendor = vendorShortMappingFn(getVendorNameByCode(seller.vendor))

        let products: string[] = []
        if (item?.prod?.length) {
            products = [ ...item.prod ]
        } else {
            const result = await fetchProductIds({ seller })
            if (result?.error) return

            const newApiData = cloneDeep(apiData)
            newApiData.forEach(item => {
                if (item.seller.vendor === seller.vendor && item.seller.region === seller.region) {
                    item.bucket_assortments.forEach((categoryItem) => {
                        categoryItem.products = result.data[categoryItem.name] || []
                    })
                }
            })
            setData?.(newApiData)
            products = [ ...result.data[item.name] ]
        }

        if (!products?.length) return
        
        clearLookbookActions()
        const dialogId = `${RoutesObj.CATEGORY_ANALYTICS.LABEL}-${item.vendor}-${item.region}-${item.label}`
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog(dialogId, (
            <ProductsModal
                productUrls={products}
                onClose={() => {
                    closeFn(dialogId)
                    const res = judgeActionOccurs()
                    if (res) fetchData()
                }}
                sortField={comparisonQuery?.collection?.query?.sortField}
                headerLeading={numberUtils.formatNumberByComma(products.length)}
                headerTitle={fnUseAppendDialogTitlePriceFilter(`${item?.vendor}, ${item?.label} products, ${item?.region}`)}
                headerDate={formatDateStr2(dateRangeValue)}
            />
        ))
    }

    return (
        <div id="export-pie-wrapper">
            <ShowWhenOpenInNew>
                <FilterInfo />
            </ShowWhenOpenInNew>
            <ModuleTitle category="ASSORTMENT" title={CATEGORY_SPLIT_PIE_GRAPH_NAME} showComparisonDate top={0} />
            <SizedBox height={10} />
            <Spin spinning={loading} minHeight={600}>
                <ShouldRender shouldRender={lookbookWithoutData}>
                    <LookbookWithoutData />
                </ShouldRender>
                <ShouldRender shouldRender={!lookbookWithoutData}>
                    <div className={styles.filterGraphPie}>
                        <HideWhenOpenInNew>
                            <MultipleDropdown
                                label="Category"
                                title="Category"
                                placeholder="Category"
                                showSelectAll
                                showClearAll
                                minCount={1}
                                options={categoryOptions}
                                value={categoryValue}
                                dropdownStyle={{ height: '400px', maxHeight: 'none', width: '180px' }}
                                onOk={(value: string[]) => {
                                    setCategoryValueFn(value)
                                }}
                            />
                            <ExportDropdown
                                left
                                onExportExcel={onExportExcel}
                                showJPG
                                showCLIPBOARD
                                fileName={categorySplitExportFilename}
                                selector="#export-pie-wrapper"
                            />
                            <div style={{ width: 10 }} />
                            <GraphButton />
                            <OpenInNewButton
                                left={10}
                                onPageParams={() => ({
                                    pieCategoryValue: JSON.stringify(categoryValue),
                                    pieSwitchValue: switchValue,
                                    [OPEN_IN_NEW_DATE]: dateRangeValue,
                                })}
                            />
                        </HideWhenOpenInNew>
                        <div style={{ flex: 1 }} />
                        <Switch
                            leftLabel="%"
                            rightLabel="#"
                            right={0}
                            value={switchValue}
                            onChange={checked => setSwitchValue(checked)}
                        />
                    </div>
                    <PageContainer 
                        dataSource={useMemo(() => dataSource, [ dataSource ])}
                        onShowProductModal={onShowProductModal}
                    />
                </ShouldRender>
            </Spin>
            <Dialog ref={dialogRef} />
        </div>
    )
}

const PageContainer = memo(({
    dataSource,
    onShowProductModal,
}: any) => {
    return (
        <div className={styles.perVendorContent}>
            {dataSource.map((item: any, index: number) => {
                return (
                    <VendorItem
                        key={stringUtils.uuid()}
                        item={item}
                        onShowProductModal={onShowProductModal}
                    />
                )
            })}
        </div>
    )
})

const VendorItem = memo(({
    item,
    onShowProductModal,
}: any) => {
    const [ moduleData, setModuleData ] = useModuleData(CATEGORY_SPLIT_PIE_GRAPH_NAME)
    const {
        switchValue = false,
        expandNamesObj,
    } = moduleData
    const [ filterRegion ] = useFilterCountry()
    const name = vendorShortMappingFn(formatBrandVendor(item.seller?.brand, getVendorNameByCode(item.seller?.vendor)))
    const region = regionShortMappingFn(item.seller?.region === 'World' ? filterRegion : item.seller.region)
    const options = item.seller?.queryDateValid && !item.seller.isLock ? numberUtils.formatNumberByComma(item.num) : NA

    return (
        <div>
            <div className={styles.pieTitle}>
                <div>{formatVendorName(name).name}</div>
                <div style={{ fontSize: '12px', color: '#666', transform: 'scale(calc(11 / 12))' }}>
                    {formatVendorName(name).platform} ({region})
                </div>
                <div>{OPTIONS_NAME}: {options}</div>
            </div>
            <div 
                onClick={e => e.stopPropagation()}
                onMouseLeave={e => {
                    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/769#note_1269972397
                    e.stopPropagation()
                }}
            >
                <SplitPieChart
                    dataSource={item.charts}
                    queryDateValid={item.seller.queryDateValid}
                    onElementsClick={(_, sectorIndex) => onShowProductModal(item.pieList[sectorIndex], item.seller)}
                    isLock={item.seller.isLock}
                    pieList={item.pieList}
                    vendorCode={item.seller.vendor}
                />
            </div>
            <SplitPieList
                dataSource={switchValue ? item.unitList : item.percentList}
                queryDateValid={item.seller.queryDateValid}
                onItemClick={value => onShowProductModal(value, item.seller)}
                moduleName={CATEGORY_SPLIT_PIE_GRAPH_NAME}
                isLock={item.seller.isLock}
                expandedNames={expandNamesObj[item.seller.vendor]}
                onExpand={value => {
                    setModuleData({
                        expandNamesObj: {
                            ...expandNamesObj, 
                            [ item.seller.vendor ]: value, 
                        },
                    })
                }}
            />
        </div>
    )
})
