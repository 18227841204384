import React, { useEffect, useState } from 'react'
import { useRefreshLookbookList } from 'hooks/api/useLookbookData'
import { NavigationBarContextTypes, NavigationContainerProps } from './NavigationBarContextTypes'

export const NavigationBarContext = React.createContext<NavigationBarContextTypes>({
  isBgOrange: false,
  setIsBgOrange: () => {
  },
  /**
   * 2022/07/22
   * 
   * ## 问题
   * 
   * 导出图片和 excel 文件如果包含线图, 线图中可能会包含一条垂直线 (tooltip 那根线)
   * 这是由于 html2canvas 并不是实时的, 相当于有1-2s的延迟截图
   * 在这 1-2s 内鼠标如果悬浮在线图上就会出现垂直线, 导出的图片中就会包含这条垂直线
   * 在这 1-2s 内鼠标没有悬浮在线图上, 导出的图片就不会包含这条垂直线
   * 
   * ## 解决
   * 
   * 当点击 Export 按钮时, 给线图添加 point-events: none 属性
   * 这样鼠标悬浮也不会触发任何事件
   * 等到导出文件成功后, 再设置 point-events: auto
   */
  /**
   * 1. ExportDropdown 组件点击 Export JPG 按钮时, 设置 beforeExportLoading 为 true
   * 2. LineChart 组件中监听到 beforeExportLoading 为 true 时就设置 point-events: none
   * 3. LineChart 组件与此同时, 设置 exportLoading 为 true
   * 4. ExportDropdown 组件监听到 exportLoading 为 true 开始执行 html2canvas 生成图片
   * 此时线图上是没有任何事件的, 因此也就不会出现垂直线了
   */
  beforeExportLoading: false,
  setBeforeExportLoading: () => { },
  startExportLoading: false,
  setStartExportLoading: () => { },
})

export const NavigationBarContainer = ({ children }: NavigationContainerProps) => {
  const [ isBgOrange, setIsBgOrange ] = useState(false)

  /**
   * ExportDropdown + LineChart 使用, 具体业务场景见最上面注释
   */
  const [ beforeExportLoading, setBeforeExportLoading ] = useState(false)
  const [ startExportLoading, setStartExportLoading ] = useState(false)

  return (
    <NavigationBarContext.Provider
      value={{
        isBgOrange,
        setIsBgOrange,
        beforeExportLoading,
        setBeforeExportLoading,
        startExportLoading,
        setStartExportLoading,
      }}
    >
      {children}
    </NavigationBarContext.Provider>
  )
}
