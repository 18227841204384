/**
 * 查询参数
 */

import { ALL_VENDORS, CATEGORY_TOTAL, LICENSE_BOGNER, MARKET_VENDOR, PRICE_FILTER_VALS, PRICE_RANGE_VALS, SELECTED_VENDORS } from 'consts'
import { sortVendorList } from 'utils/array'
import { storage } from 'utils/storage'
import { CompetitorReturnType, CompetitorSellerType, FilterPriceRangeType, FilterSizeType, FilterSortByType, SizeFilterPayloadType, SortByPayloadType, TaxFilterType } from 'types'
import { uniqBy } from 'lodash'
import { groupsConfig, GROUP_BOGNER_NAME } from 'configs/groups'
import { getQueryPayloadV2 } from 'graphql/nornaapi'
import { USA_KEY } from 'configs/region'
import { isCrossRegionPricingPage, isPriceArchitecturePage } from 'global/Routes'
import { getDisplayGenderValue } from 'components/layout/Header/components/HeaderFilter/gender.util'

/* *********************************** API ************************************** */

/**
 * competitor 传参
 * 1. 排除 SELECTED_VENDORS
 * 2. 排序
 */
export function getCompetitorPayload({
    competitorSellers = [],
    returnType = 'seller',
    isDashboard = false,
}: {
    competitorSellers: CompetitorSellerType[];
    returnType?: CompetitorReturnType;
    isDashboard?: boolean;
}): any {
    // 不包含 SELECTED_VENDORS
    const excludeList = [
        SELECTED_VENDORS,
        MARKET_VENDOR.vendor,
        ALL_VENDORS,
    ]
    if (!isDashboard) {
        excludeList.push(MARKET_VENDOR.vendor)
        excludeList.push(ALL_VENDORS)
    }
    let value = competitorSellers.filter(item => !excludeList.includes(item.vendor))

    // 去重
    value = uniqBy(value, 'vendor')

    // 排序
    value = sortVendorList({
        vendorList: value,
        vendorField: 'vendor',
    })

    if (returnType === 'seller') {
        return value
    }

    return value.map(item => item.vendor)
}

/**
 * market 传参
 * 1. 选中 vendor 不包含 Market, 直接返回 []
 * 2. 排除 SELECTED_VENDORS, MARKET
 */
export function getMarketPayload({
    competitorSellers = [],
    competitorOptions = [],
    returnType = 'seller',
}: {
    competitorSellers: CompetitorSellerType[];
    competitorOptions: CompetitorSellerType[];
    returnType?: CompetitorReturnType;
}) {
    // 选中的 sellers 中不包含 MARKET, 直接返回 []
    const marketSeller = competitorSellers.find(seller => seller.vendor === MARKET_VENDOR.vendor)
    if (!marketSeller) return []

    let market: any[] = []
    // market 中不包含 SELECTED_VENDORS 和 MARKET
    const excludeList = [
        SELECTED_VENDORS, 
        MARKET_VENDOR.vendor,
    ]
    market = competitorOptions
        .filter(item => item)
        .filter(item => !excludeList.includes(item.vendor))

    // 去重
    market = uniqBy(market, 'vendor')

    market = market.map(item => ({ vendor: item.vendor, region: item.region }))

    if (returnType === 'seller') {
        return market
    }

    return market.map(item => item.vendor)
}

/**
 * 获取 groups 参数
 * 
 * 功能介绍: 对 vendor 进行合并分组
 * 
 * 比如 Competitor 下拉框选中了三个 vendor ['wera', 'Aaa@bogner', 'Bbb@bogner']
 * 之前的操作是线图和表格只会显示三个 vendor, 也就是勾选的三个 vendor
 * 现在会显示四个 vendor, 勾选的三个 vendor +【bogner subbrand 合并之后的 vendor: Bogner Selected】
 * 
 * 当出现 bogner license 的 subbrand 勾选大于1个时, 需要传参 groups, 格式为:
 * 
 * ``` js
 * // 这里的 Bogner Group 是自定义名称, 就是页面上将会显示的第四个 vendor 的名称
 * "groups": {
 *      "Bogner Selected": [{
 *		    "vendor": "Aaa@bogner",
 *			"region": "Germany"
 *		}, {
 *			"vendor": "Bbb@bogner",
 *			"region": "Germany"
 *		}]
 * }
 * ```
 */
 export function getGroupsPayload({
    competitorSellers = [],         // 当前选中的 competitor
}: {
    competitorSellers: CompetitorSellerType[];
}): Record<string, CompetitorSellerType[]> {
    const customerVendor = storage.getCustomerVendor()
    const res =  {}

    // add SELECTED_VENDORS
    const selectedSeller = competitorSellers.find(seller => seller.vendor === SELECTED_VENDORS)
    if (selectedSeller) {
        const excludeList = [
            SELECTED_VENDORS,
            MARKET_VENDOR.vendor,
        ]
        let selectedGroup = competitorSellers.filter(seller => !excludeList.includes(seller.vendor))
        selectedGroup = uniqBy(selectedGroup, 'vendor')
        res[SELECTED_VENDORS] =  selectedGroup
    }

    // add other groups
    groupsConfig.forEach(config => {
        const { license, suffix, name } = config
        if (customerVendor === license) {
            const subbrandList = competitorSellers.filter(seller => seller.vendor?.endsWith(suffix))
            if (subbrandList.length >= 2) {
                res[name] = subbrandList.map(seller => ({ vendor: seller.vendor, region: seller.region }))
            }
        }
    })

    return res
}

export function getCategoriesPayload(categories: string[] = []) {
    if (categories.length === 0) {
        return []
    }
    if (categories.includes(CATEGORY_TOTAL)) {
        const categoryList = storage.getCategoryTreeList()
        const data = categoryList.map(item => {
            if (Array.isArray(item.list) && item.list?.length) {
                return item.list.map(item2 => item2.name)
            }
            return item.name
        }).flat(10)
        return data
    }
    return Array.from(new Set(categories))
}

/**
 * 获取 sort_by 传参
 */
export function getSortByPayload(sortBy: FilterSortByType): SortByPayloadType {
    return {
        field: sortBy.sortField,
        order: sortBy.sortOrder,
    }
}

export function getDashboardPropertiesPayload(query: any) {
    const queryPayLoad = getQueryPayloadV2({
        ...query.collection.query,
        categoryTree: query.customer_seller?.vendor + '_category',
    })
    return {
        earliest_launch_date: queryPayLoad?.earliest_launch_date,
        latest_launch_date: queryPayLoad?.latest_launch_date,
        properties: queryPayLoad?.properties,
        black_properties: queryPayLoad?.black_properties,
        exclude_property_groups: queryPayLoad?.exclude_property_groups,
        material_filter: queryPayLoad?.material_filter,
        color_filter: queryPayLoad?.color_filter,
        size_filter: queryPayLoad?.size_filter,
    }
}

export function getTaxPayload({
    filterTax,
    region,
}: {
    filterTax?: TaxFilterType;
    region?: string;
}) {
    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/833#note_1304218583
    if ((isCrossRegionPricingPage() || isPriceArchitecturePage()) && filterTax?.checked) {
        return filterTax.value
    }
    if (region === USA_KEY && filterTax?.checked) {
        return filterTax.value
    }
    return 0
}

export function getPriceFilterPayload(query: {
    taxFilter: TaxFilterType;
    regions: { currency: string; key: string };
    priceFilter: { val: string };
    priceRange: FilterPriceRangeType;
}) {
    const taxRate = getTaxPayload({ filterTax: query.taxFilter, region: query.regions.key })

    return {
        currency: query.regions.currency,
        // price range
        min_price: query?.priceRange?.min || 0,
        max_price: query?.priceRange?.max || 0,
        activate_price_range: query?.priceRange?.activate_price_range || false,
        discount_only: PRICE_RANGE_VALS[query.priceRange.optionValue],
        // price perspective
        ...PRICE_FILTER_VALS[query.priceFilter.val],
        // tax
        tax_rate: taxRate,
    }
}

/**
 * 获取 size filter 传参
 */
export function getSizeFilterPayload({
    sizeFilter,
    targetGroups = [],
}: {
    sizeFilter: FilterSizeType;
    targetGroups: string[];
}): SizeFilterPayloadType {
    const payload: SizeFilterPayloadType = []
    if (sizeFilter?.checked) {
        payload.push({
            min_length: sizeFilter.isNoSizes ? -1 : sizeFilter?.min,
            max_length: sizeFilter.isNoSizes ? -1 : sizeFilter?.max,
            is_children_size_filter: true,
        })
    }
    const newTargetGroups = getDisplayGenderValue(targetGroups)
    const genderSize = newTargetGroups.filter(target => target.includes('-'))
    if (genderSize?.length) {
        genderSize.forEach(size => {
            // 根据空格分隔, 从后往前
            const [ min, max ] = size.split('-').map(s => Number(s))
            payload.push({
                min_length: min,
                max_length: max,
                is_children_size_filter: false,
            })
        })
    }
    return payload
}

/**
 * 获取 gender 传参
 * 
 * Gender 下拉框值可能 size group 比如 '40-91' 这种, 传参时需要过滤这些
 */
export function getTargetGroupPayload({
    targetGroups= [],
    returnType = 'string',
}: {
    targetGroups: string[];
    returnType?: 'string' | 'array';
}): any {
    const newTargetGroups = getDisplayGenderValue(targetGroups)
    const payload = newTargetGroups.filter(item => !item.includes('-'))
    if (returnType === 'array') {
        return payload
    }
    return payload.join(',')
}

/* *********************************** Excel ************************************** */

export function getExcelMarketPayload({
    competitorOptions = [],
    returnType = 'seller',
}: {
    competitorOptions: CompetitorSellerType[];
    returnType?: CompetitorReturnType; 
}) {
    return getMarketPayload({
        competitorSellers: [ { vendor: MARKET_VENDOR.vendor, region: '' } ],
        competitorOptions,
        returnType,
    })
}

export function getExcelCompetitorPayload({
    competitorOptions = [],
    returnType = 'seller',
}: {
    competitorOptions: CompetitorSellerType[],
    returnType?: CompetitorReturnType;
}) {
    const customerVendor = storage.getCustomerVendor()
    const competitorValue = competitorOptions.filter(item => item.vendor !== SELECTED_VENDORS)
    const { region } = competitorOptions[0]

    if (customerVendor === LICENSE_BOGNER) {
        competitorValue.push({ vendor: GROUP_BOGNER_NAME, region })
    }
    
    const value = sortVendorList({
        vendorList: competitorValue,
        vendorField: 'vendor',
    })

    if (returnType === 'seller') {
        return value
    }

    return value.map(item => item.vendor)
}

export function getExcelGroupsPayload({
    competitorOptions = [],
}: {
    competitorOptions: CompetitorSellerType[];
}) {
    const competitorValue = competitorOptions.filter(item => item.vendor !== SELECTED_VENDORS)
    const groups = getGroupsPayload({ competitorSellers: competitorValue })
    return groups
}
