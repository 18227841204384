import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { ObjectProps } from 'types/'

/**
 *
 * ## 使用示例
 *
 * ```
 * const snackRef = useRef<any>({});
 *
 * const onClick = () => {
 *    snackRef.current?.open?.('Copied share link to clipboard');
 *    snackRef.current?.error?.('error occurs');
 * }
 *
 * <button onClick={onClick}>按钮</button>
 * <SnackInfo ref={snackRef} />
 * ```
 */
export const SnackInfo = forwardRef(({ msg = '' }: ObjectProps, ref) => {
  const [ open, setOpen ] = useState({ msg, error: false })
  useImperativeHandle(ref, () => ({
    open: msg => setOpen({ msg, error: false }),
    error: msg => setOpen({ msg, error: true }),
  }))
  // if (!msg) return null;

  return (
    <Snackbar
      open={!!open.msg}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{ zIndex: 999999999   }}
      autoHideDuration={5000}
      onClose={() => setOpen({ msg: '', error: false })}
      message={open.msg}
    >
      <div
style={{
        padding: 10,
        minWidth: 150,
        background: open.error ? '#ec7765' : '#333',
        color: 'white',borderRadius: 5,
      }} className="MuiPaper-root MuiSnackbarContent-root MuiPaper-elevation6"
      >{open.msg}
      </div>
    </Snackbar>
  )
})

