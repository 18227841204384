export const width01 = 60
export const width02 = 96

export const tabWidth = 155
export const optionsWidth = 115
export const categoryWidth = 180
export const priceIndexWidth = 75

export const lowPriceValueWidth = width01 + 25
export const lowPriceChangePercentWidth = width02 - 25
export const avgPriceValueWidth = width01 + 25
export const avgPriceChangePercentWidth = width02 - 25
export const highPriceValueWidth = width01 + 25
export const highPriceChangePercentWidth = width02 - 25
export const lowIndexWidth = priceIndexWidth
export const avgIndexWidth = priceIndexWidth
export const highIndexWidth = priceIndexWidth
export const priceSpreadWidth = 300 - (priceIndexWidth - width01) * 3

export const lowPriceWidth = lowPriceValueWidth + lowPriceChangePercentWidth
export const avgPriceWidth = avgPriceValueWidth + avgPriceChangePercentWidth
export const highPriceWidth = highPriceValueWidth + highPriceChangePercentWidth
