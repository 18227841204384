import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCacheData } from '../../features/filters/cacheDataSlice'

export const useAllSellers = (selectParentRegion = true) => {
  const [ pageData, setPageData ] = useState({})

  const cacheData = useSelector(getCacheData)
  const data = cacheData.sellers
  const loading = false
  const error = null

  useEffect(() => {
    if (data && pageData[`${selectParentRegion}`]) {
      setPageData({
        ...pageData,
        [`${selectParentRegion}`]: data,
      })
    }
  }, [ data, pageData, selectParentRegion ])
  let resData = {
    data,
    loading,
    error,
  }
  if (pageData[`${selectParentRegion}`]) {
    resData = {
      data: pageData[`${selectParentRegion}`],
      loading: false,
      error: null,
    }
  }
  return resData
}
