import React, { FC, memo, useState, useEffect, useRef, CSSProperties } from 'react'
import { BaseSelect, BaseSelectRefProps } from 'componentsv2/BaseSelect'
import { MonthPanel } from './components/MonthPanel'
import { MonthPickerProps } from './types'
import styles from './styles.module.scss'

/**
 * ## 何时使用
 * 
 * 下拉框选择年、月
 * 
 * ## 使用示例
 * 
 * ### 基础用法
 * 
 * ```
 * <MonthPicker
 *    placeholder="Month"
 * />
 * ```
 * 
 * ### 监听下拉框值变化事件 onChange
 * 
 * onChange = (opt: { year: number, month: number }) => void
 * 
 * - year 表示年份，格式如 2021，可通过 new Date().getFullYear() 获取当前年份
 * - month 表示月份，可通过 new Date().getMonth() 获取当前月份，0表示1月，8表示9月
 * 
 * ```
 * <MonthPicker
 *    onChange={useCallback(({ year, month }) => {
 *        console.log('year', year)
 *        console.log('month', month)
 *    }, [])}
 * />
 * ```
 * 
 * ### 设置下拉框显示值 value 
 * 
 * - year 表示年份，格式如 2021，可通过 new Date().getFullYear() 获取当前年份
 * - month 表示月份，可通过 new Date().getMonth() 获取当前月份，0表示1月，8表示9月
 * 
 * ```
 * <MonthPicker
 *    value={{ year: 2021, month: 8 }}
 * />
 * ```
 * 
 * ### 格式化下拉框中显示的值 onFormat
 * 
 * onFormat = (opt: { year: number, month: number }) => string
 * 
 * ```
 * <MonthPicker
 *    onFormat={useCallback(({ year, month }) => {
 *         const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
 *         return `${year}-${months[month]}`
 *    }, [])}
 * />
 * ```
 * 
 * ## 为何 MonthPicker 内又使用了 MonthPanel 组件？
 * 
 * 如果希望直接在页面上显示面板用于选择月份和年份，而不是在下拉框中选择月份和年份，此时可以使用 MonthPanel 组件而非 MonthPicker 组件。
 * 
 * ## 属性
 * 
 * @param value {year: number; month: number;}  下拉框显示值
 * @param onChange (opt: {year: number; month: number;}) => void;   下拉框内容变化时触发事件
 * @param onFormat (opt: {year: number; month: number;}) => string;   自定义下拉框中显示文案
 * @param placeholder string 下拉框中没有内容时，显示 placeholder
 * @param disabled boolean 是否禁用下拉框
 * @param left number 下拉框的 marginLeft 值
 * @param right number 下拉框的 marginRight 值
 * @param style CSSProperties 下拉框样式
 */
export const MonthPicker: FC<MonthPickerProps> = memo(({
  value,
  onChange,
  onFormat,
  label = 'Month',
  left,
  right,
  ...otherProps
}) => {
  const selectRef = useRef<BaseSelectRefProps>(null)

  const monthArr = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
  const [ inputValue, setInputValue ] = useState('')

  useEffect(() => {
    if (!value) return
    if (typeof onFormat === 'function') {
      setInputValue(onFormat(value))
    } else {
      setInputValue(`${monthArr[value.month]}. ${value.year}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value ])

  const onChangeMonthPanel = ({ year, month }) => {
    if (typeof onFormat === 'function') {
      setInputValue(onFormat({ year, month }))
    } else {
      setInputValue(`${monthArr[month]}. ${year}`)
    }
    selectRef.current?.close()
    onChange?.({ year, month })
  }

  const defaultStyle: CSSProperties = {}
    
  if (typeof left !== 'undefined') {
      defaultStyle.marginLeft = left + 'px'
  }

  if (typeof right !== 'undefined') {
      defaultStyle.marginRight = right + 'px'
  }

  return (
      <div style={{ ...defaultStyle }}>
        {label?<div className={styles.label}>{label}</div>:null}
        <BaseSelect
          inputValue={inputValue}
          renderDropdown={(
              <MonthPanel 
                  months={monthArr}
                  value={value}
                  onChange={onChangeMonthPanel}
              />
            )}
          ref={selectRef}
          {...otherProps}
        />
      </div>
  )
})

MonthPicker.displayName = 'MonthPicker'
