import { getGenderOptions } from 'utils/optionsUtils'
import { DataType } from 'componentsv2/Tree/common'
import { cloneDeep } from 'lodash'
import { intersection, isSuperArray } from 'utils/array'
import { GENDER_CHILDREN } from 'consts'
import { storage } from 'utils/storage'

/**
 * 获取 Filter > Gender 子节点
 */
export function getFilterGenderChildren({
    filterGender,
    isGate,
}: {
    filterGender: string[];
    isGate: boolean;
}) {
    const genderOptions = getGenderOptions()
    const disabledGenderList = genderOptions.filter(g => g.isDisabled).map(g => g.key)
    const genderList = genderOptions.map(g => g.key)

    // Gate 开关打开并且 Gender 里不包含 Children, 要给硬编码加上一个 Children
    // https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/833#note_1303472121
    if (isGate && !genderList.includes('Children')) {
        genderList.push('Children')
    }

    // 生成数据源
    const genderData = genderList.map(item => {
        const gender = genderOptions.find(g => g.key === item)
        const option: any = {
            label: gender?.description || item,
            val: item,
            disabled: gender?.isDisabled,
            active: filterGender.includes(item),
            type: DataType.CHECK,
        }
        // Children 特殊处理
        if (Array.isArray(gender?.children) && gender?.children?.length) {
            // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/833#note_1307207866
            option.keepLast = !intersection(filterGender, [ 'Men', 'Women' ]).length
            option.enableSelectAll = true
            option.active = !!intersection(filterGender, gender?.children?.map(k => k.key))?.length
            option.type = DataType.ARROW_CHECK
            option.children = gender?.children.map(g2 => {
                return {
                    label: g2?.description,
                    val: g2.key,
                    disabled: g2?.isDisabled,
                    active: filterGender.includes(g2.key),
                    type: DataType.CHECK,
                }
            })
        }
        return option
    })

    // GATE  开关打开时, 所有 Gender 都要改为可操作状态
    if (isGate) {
        genderData.forEach(item => {
            item.disabled = false
        })
    }
    // GATE 开关关闭时, Gender 的可操作状态需要初始化
    else {
        genderData.forEach((item: any) => {
            if (disabledGenderList.includes(item?.val)) {
                item.disabled = true
                item.active = false
            }
        })
    }

    return cloneDeep(genderData)
}

/**
 * 获取 Filter > Gender 选中值
 */
export function getFilterGenderValue(genderTreeOptions) {
    const value: string[] = []

    genderTreeOptions?.children?.forEach(g1 => {
        if (g1.active && !Array.isArray(g1?.children)) {
            value.push(g1.val)
        }
        if (g1.active && Array.isArray(g1?.children) && g1?.children?.length) {
            g1.children.forEach(g2 => {
                if (g2.active) {
                    value.push(g2.val)
                }
            })
        }
    })

    return value
}

export function getFilterGenderDefaultValue() {
    const defaultValue: string[] = []
    const genderOptions = getGenderOptions()
    const firstGender = genderOptions.find(item => !item.isDisabled) as any
    if (Array.isArray(firstGender?.children) && firstGender?.children?.length) {
        firstGender?.children?.forEach(g => {
            if (!g?.isDisabled) {
                defaultValue.push(g.key)
            }
        })
    } else {
        if (!firstGender?.isDisabled) {
            defaultValue.push(firstGender?.key)
        }
    }
    return defaultValue?.sort()
}

export function getFilterGenderAllValue() {
    const allValue: string[] = []
    const genderOptions = getGenderOptions()
    genderOptions.forEach(g1 => {
        if (Array.isArray(g1?.children) && g1?.children?.length) {
            g1.children.forEach(g2 => {
                if (!g2?.isDisabled) {
                    allValue.push(g2.key)
                }
            })
        } else {
            if (!g1?.isDisabled) {
                allValue.push(g1.key)
            }
        }
    })
    return allValue?.sort()
}

/**
 * Children size filter 激活时, 需要只选中 Children
 * 如果 Children 有子节点, 那么就选中 Children 的所有子节点
 */
export function getFilterGenderChildrenValue() {
    let childrenValue: string[] = []
    const genderOptions = getGenderOptions()
    const childrenOption = genderOptions.find(g => g.key === GENDER_CHILDREN)
    if (!childrenOption) return childrenValue
    if (Array.isArray(childrenOption?.children) && childrenOption?.children?.length) {
        childrenValue = childrenOption?.children?.map(g => g.key) 
    } else {
        childrenValue = [ childrenOption.key ]
    }
    return childrenValue
}

/**
 * Browse options 页面切换 Gate 时初始化 Gender 问题
 */
export function getFilterGenderValueForGate(qaPage: boolean) {
    let allValue = getFilterGenderAllValue()
    const genderOptions = getGenderOptions()
    const isIncludeChildren = !!genderOptions.find(g => g.key === GENDER_CHILDREN)
    if (qaPage && !isIncludeChildren) {
        allValue.push(GENDER_CHILDREN)
    }

    if (!qaPage) {
        if (!getIsIncludeChildrenFromApi()) {
            allValue = allValue.filter(v => v !== GENDER_CHILDREN)
        }
    }

    return allValue
}

/**
 * Filter > Gender 全选/全不选
 */
export function handleFilterGenderSelecteAll(item: any) {
    const newItem = cloneDeep(item)
    const isSelectAll = newItem.children
        .filter(item => !item.disabled)
        .every(item => item.active === true)
    // 取消全选。第一个选中
    if (isSelectAll) {
        const index = newItem.children.findIndex(item => item.disabled === false)
        newItem.children.forEach((item, ind) => {
            if (ind === index) {
                item.active = true
                if (Array.isArray(item?.children) && item?.children?.length) {
                    item.children.forEach(item2 => {
                        if (!item2.disable) {
                            item2.active = true
                        } else {
                            item2.active = false
                        }
                    })
                }
            } else {
                item.active = false
            }
        })
    }
    // 全选
    else {
        newItem.children.forEach(item => {
            if (!item.disabled) {
                item.active = true
                if (Array.isArray(item?.children) && item?.children?.length) {
                    item.children.forEach(item2 => {
                        if (!item2.disable) {
                            item2.active = true
                        } else {
                            item2.active = false
                        }
                    })
                }
            } else {
                item.active = false
            }
        })
    }
    return newItem
}

export function getDisplayGenderValue(genders: string[]) {
    let newGenders = genders.map(g => g)
    const childrenValue = getFilterGenderChildrenValue()
    if (childrenValue?.length && isSuperArray(genders, childrenValue) && !genders.includes(GENDER_CHILDREN)) {
        newGenders = newGenders.filter(g => !childrenValue.includes(g))
        newGenders.push(GENDER_CHILDREN)
    }
    return newGenders
}

export function getIsIncludeChildrenFromApi() {
    const { targets = [], untargets = [] } = storage.getConfigs()
    const isIncludeChildren = [ ...targets, ...untargets ].includes(GENDER_CHILDREN)
    return isIncludeChildren
}
