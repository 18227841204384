import React, { useEffect, useRef, useState } from 'react'
import { NoCategory } from 'components'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ProductsModal } from 'components/ProductsModalV2'
import { Spin } from 'componentsv2'
import { FilterInfo } from 'componentsv2/business/FilterInfo'
import { ShowWhenOpenInNew, HideWhenOpenInNew, OpenInNewButton } from 'componentsv2/business/OpenInNewButton'
import { SplitPieChart } from 'componentsv2/business/SplitPieChart'
import { SplitPieList } from 'componentsv2/business/SplitPieList'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { Switch } from 'componentsv2/form-elements/Switch'
import { LookbookWithoutData } from 'componentsv2/LookbookPlaceholder'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { FILTERED_OUT, NA, OPEN_IN_NEW_DATE, OPTIONS_NAME } from 'consts'
import { downloadFileByAxios } from 'export'
import { RoutesObj } from 'global/Routes'
import { useExcludeProductsComparisonQuery, useUrlParams } from 'hooks'
import useDialogTitle from 'hooks/useDialogTitle'
import { useCategoryRequiredText } from 'hooks/useFilterRequiredText'
import { useLookbook } from 'hooks/useLookbook'
import { useLookbookAction } from 'hooks/useLookbookAction'
import { usePageDate } from 'hooks/usePageDate'
import { cloneDeep, groupBy } from 'lodash'
import { numberUtils, stringUtils } from 'norna-uikit'
import { vendorShortMappingFn, regionShortMappingFn, formatBrandVendor, getVendorNameByCode } from 'utils'
import { handleColorOpacity, paletteList } from 'utils/colorJson'
import { formatDateStr2 } from 'utils/dateUtils'
import { formatVendorName } from 'utils/sellers'
import { MaterialSplitMaterialFilter } from 'pages/MaterialSplit/components/MaterialSplitMaterialFilter'
import { MATERIAL_SPLIT_PIE_GRAPH_NAME } from 'configs'
import { useSplitPieGraphData } from 'hooks/api/useSplitPieGraphData'
import { useModuleData } from 'hooks/useModuleData'
import { useFindProductIds } from 'hooks/api/useFindProductIds'
import { materialSplitExportFilename } from 'utils/exportUtils'
import { getValueByQueryDateValid, handleIsLock, handleQueryDateValid, handleVendorSort } from 'utils/pageUtils'
import { useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { useCompetitorOptions } from 'hooks/useOptions'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { Box } from 'componentsv2/Box'
import { InfoBox } from 'components/common/InfoBox'
import { TipsContent } from 'components/layout/Header/components/Tips'
import styles from './styles.module.scss'
import GraphButton from 'componentsv2/GraphButton'
import { getFilterMaterialList } from 'utils/lockSellerUtils'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_MATERIA_SPLIT } from 'features/filters/moduleDataSlice.const'
import { geneCsvData } from './utils'

export const MaterialSplitPieGraph = () => {
    const { fnUseAppendDialogTitlePriceFilter } = useDialogTitle()
    const { pageDate: dateRangeValue } = usePageDate()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCompetitor ] = useFilterCompetitor()
    const { competitorOptions } = useCompetitorOptions()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const filterRequiredCategories = useCategoryRequiredText()

    const [ moduleData, setModuleData ] = useModuleData(MATERIAL_SPLIT_PIE_GRAPH_NAME)
    const { 
        fetchPayload = {}, 
        apiData = [],
        newCompetitorValue = [],
        material = {},
        switchValue = false,
        isLimitMaterial = false,
    } = moduleData
    
    /* ************************************ Lookbook ******************************************* */
    const { isLookbook } = useLookbook()
    const { judgeActionOccurs, clearLookbookActions } = useLookbookAction(true, true)
    const [ lookbookWithoutData, setLookbookWithoutData ] = useState(false)

    /* ***************************** Open in new *************************************** */
    const urlParams = useUrlParams()

    useEffect(() => {
        if (typeof urlParams?.pieMaterial !== 'undefined') {
            setMaterial(JSON.parse(urlParams.pieMaterial))
        }
        if (typeof urlParams?.pieSwitchValue !== 'undefined') {
            setSwitchValue(urlParams.pieSwitchValue === 'true')
        }
        // eslint-disable-next-line
    }, [])

    /* ********************************** Export excel **************************************** */
    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        payload.data.competitor_sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        payload.data.groups = getGroupsPayload({ competitorSellers: filterCompetitor })
        payload.data.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions })
        payload.query.units = switchValue
        await downloadFileByAxios({
            filename: materialSplitExportFilename,
            payload,
        })
    }

    /* ********************************** Form **************************************** */
    const setMaterial = (newMaterial = {}) => {
        setModuleData({ material: { ...material, ...newMaterial } })
    }

    const setSwitchValue = (value: boolean) => {
        setModuleData({ switchValue: value })
    }

    /* ********************************** 数据处理 **************************************** */
    const { data, loading, fetchData, setData } = useSplitPieGraphData({ moduleName: MATERIAL_SPLIT_PIE_GRAPH_NAME, materialPercent: material.percent, isLimitMaterial })
    const [ dataSource, setDataSource ] = useState([])
    const [ , setCsvData ] = useCsvDataModuleData()

    const handleData = apiData => {
        setLookbookWithoutData(isLookbook && Array.isArray(apiData) && apiData.length === 0)
        if (!Array.isArray(apiData)) return

        apiData = cloneDeep(apiData)

        apiData = handleQueryDateValid({ data: apiData, competitorValue: newCompetitorValue, filterCountry })
        apiData = handleIsLock({ data: apiData, competitorValue: newCompetitorValue, region: filterCountry })
        apiData = handleVendorSort({ data: apiData })

        const materialList = getFilterMaterialList()
        const materialObj = { length: 0 }
        const pieData: any = apiData.map(item => {
            const { queryDateValid, queryComparisonDateValid, isLock } = item.seller
            /**
             * filter Others in IS_LIVE_ENV
             */
            let bucketAssortments = item.bucket_assortments.filter(item => {
                // if (IS_LIVE_ENV) { // filter other in IS_LIVE_ENV 
                //     return item.name.toLowerCase() !== 'others'
                // }
                return true
            })
            let list = bucketAssortments
            // handle other、multi-material、no-material specially
            const others = groupBy(list, item2 => [ 'other', 'multi-material', 'no-material' ].includes(item2.name.toLowerCase()) ? 'next' : 'prev')
            list = [ 
                ...others.prev || [],
                ...(others.next || [])
                    .map(item => {
                        item.name = stringUtils.capitalize(item.name)
                        return item
                    })
                    .sort((a, b) => a.name > b.name ? 1 : -1),
            ]
            if (isLock) {
                materialList.forEach(item2 => {
                    if (!materialObj[item2]) {
                        // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/952
                        if (item2 === FILTERED_OUT) {
                            materialObj[item2] = '#000000'
                        } else {
                            materialObj[item2] = paletteList[materialObj.length]
                        }
                        materialObj.length += 1
                    }
                })
            }

            list = list.map(item2 => {
                if (!materialObj[item2.name]) {
                    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/952
                    if (item2.name === FILTERED_OUT) {
                        materialObj[item2.name] = '#000000'
                    } else {
                        materialObj[item2.name] = paletteList[materialObj.length]
                    }
                    materialObj.length += 1
                }

                let countCurrentValue = numberUtils.formatNumberByComma(item2.count.current_value)
                countCurrentValue = getValueByQueryDateValid(countCurrentValue, queryDateValid)

                let countChangePercentage = (item2.count.change_percentage * 100).toFixed(1) + '%'
                countChangePercentage = getValueByQueryDateValid(countChangePercentage, queryComparisonDateValid)

                let percentCurrentValue = (item2.percent.current_value * 100).toFixed(1) + '%'
                percentCurrentValue = getValueByQueryDateValid(percentCurrentValue, queryDateValid)

                let percentChange = (item2.percent.change * 100).toFixed(1) + '%'
                percentChange = getValueByQueryDateValid(percentChange, queryComparisonDateValid)

                let averagePriceCurrentValue = item2.average_price.current_value
                averagePriceCurrentValue = getValueByQueryDateValid(averagePriceCurrentValue, queryDateValid)

                let averagePriceChangePercentage = (item2.average_price.change_percentage * 100).toFixed(1) + '%'
                averagePriceChangePercentage = getValueByQueryDateValid(averagePriceChangePercentage, queryComparisonDateValid)

                return {
                    ...item2,
                    backgroundColor: materialObj[item2.name],
                    label: item2.name,
                    prod: item2.products,
                    /**
                     * disable 控制
                     * 产品数量大于0时，鼠标悬浮样式为手状，表示可点击
                     * 产品数量为0时，修改鼠标悬浮样式为 auto，表示不可点击
                     */
                    disabled: false,
                    countCurrentValue,
                    countChangePercentage,
                    percentCurrentValue,
                    percentChange,
                    averagePriceCurrentValue,
                    averagePriceChangePercentage,
                }
            })

            const unitList = list.map(item2 => ({
                ...item2,
                currentValue: item2.countCurrentValue,
                changePercentage: item2.countChangePercentage,
            }))

            const percentList = list.map(item2 => ({
                ...item2,
                currentValue: item2.percentCurrentValue,
                changePercentage: item2.percentChange,
            }))

            let datasets = [
                {
                    label: bucketAssortments.map(item2 => item2.name),
                    data: bucketAssortments.map(item2 => (item2?.percent.current_value || 0) * 100),
                    backgroundColor: bucketAssortments.map(item2 => handleColorOpacity(materialObj[item2.name], 0.7)),
                    hoverBackgroundColor: bucketAssortments.map(item2 => handleColorOpacity(materialObj[item2.name], 1)),
                    borderColor: Array(bucketAssortments.length).fill('rgba(255, 255, 255, 1)'),
                    borderWidth: 1,
                },
            ]
            if (isLock) {
                item.bucket_assortments = materialList.slice(0, 11).map(c => ({ name: c }))
                datasets = [
                    {
                        label: item.bucket_assortments.map(item2 => item2.name),
                        data: item.bucket_assortments.map(() => 1),
                        backgroundColor: item.bucket_assortments.map(item2 => handleColorOpacity(materialObj[item2.name], 0.7)),
                        hoverBackgroundColor: item.bucket_assortments.map(item2 => handleColorOpacity(materialObj[item2.name], 1)),
                        borderColor: Array(item.bucket_assortments.length).fill('rgba(255, 255, 255, 1)'),
                        borderWidth: 1,
                    },
                ]
            }

            return {
                charts: {
                    datasets,
                },
                list,
                percentList,
                unitList,
                num: item.num_products,
                seller: item.seller,
            }
        })
        setDataSource(pieData)
        setCsvData({
            [CSV_DATA_MATERIA_SPLIT]: geneCsvData({ dataSource: pieData, switchValue }),
        })
    }

    useEffect(() => {
        if (!data) return
        
        handleData(cloneDeep(data))
        // eslint-disable-next-line
    }, [data])

    /* ********************************** product detail modal **************************************** */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)
    const { fetchData: fetchProductIds } = useFindProductIds({ moduleName: MATERIAL_SPLIT_PIE_GRAPH_NAME })

    const onShowProductModal = async (item, seller) => {
        item.region = regionShortMappingFn(seller?.region === 'World' ? comparisonQuery?.customer_seller?.region : seller.region)
        item.vendor = vendorShortMappingFn(getVendorNameByCode(seller.vendor))
        
        let products: string[] = []
        if (item?.prod?.length) {
            products = [ ...item.prod ]
        } else {
            const result = await fetchProductIds({ seller })
            if (result?.error) return

            const newApiData = cloneDeep(apiData)
            newApiData.forEach(item => {
                if (item.seller.vendor === seller.vendor && item.seller.region === seller.region) {
                    item.bucket_assortments.forEach((item2, index) => {
                        item.bucket_assortments[index].products = result.data[item2.name] || []
                    })
                }
            })
            setData?.(newApiData)
            products = [ ...result.data[item.label] ]
        }

        if (!products?.length) return
        
        clearLookbookActions()
        const dialogId = `${RoutesObj.MATERIAL_ANALYTICS.LABEL}-${item.vendor}-${item.region}-${item.label}`
        const closeFn = dialogRef.current?.closeDialog
        dialogRef.current?.openDialog(dialogId, (
            <ProductsModal
              productUrls={products}
              onClose={() => {
                    closeFn(dialogId)
                    if (judgeActionOccurs()) fetchData()
                }}
              sortField={comparisonQuery?.collection?.query?.sortField}
              headerLeading={numberUtils.formatNumberByComma(products?.length)}
              headerTitle={fnUseAppendDialogTitlePriceFilter(`${item?.vendor}, ${item?.label} products, ${item?.region}`)}
              headerDate={formatDateStr2(dateRangeValue)}
            />
        ))
    }

    let noFullfillTips: any = filterRequiredCategories
    if (isLookbook) noFullfillTips = null

    return (
        <div id="export-pie-wrapper">
            <ShowWhenOpenInNew>
                <FilterInfo />
            </ShowWhenOpenInNew>
            <ModuleTitle category="ASSORTMENT" title={MATERIAL_SPLIT_PIE_GRAPH_NAME} top={0} showComparisonDate />
            <Spin spinning={loading} minHeight={700}>
                <ShouldRender shouldRender={lookbookWithoutData}>
                    <LookbookWithoutData />
                </ShouldRender>
                <ShouldRender shouldRender={!lookbookWithoutData}>
                    <div className={styles.filterGraphPie} style={{ paddingTop: '10px' }}>
                        <HideWhenOpenInNew>
                            <ExportDropdown
                              onExportExcel={onExportExcel}
                              selector="#export-pie-wrapper"
                              fileName={materialSplitExportFilename}
                            />
                            <div style={{ width: 10 }} />
                            <GraphButton />
                            <OpenInNewButton
                              left={10}
                              onPageParams={() => ({
                                    pieMaterial: JSON.stringify(material),
                                    pieSwitchValue: switchValue,
                                    [OPEN_IN_NEW_DATE]: dateRangeValue,
                                })}
                            />
                        </HideWhenOpenInNew>
                        <div style={{ flex: 1 }} />
                        <HideWhenOpenInNew>
                            <Switch
                              leftLabel={(
                                    <Box>
                                        Full assortment
                                        <InfoBox
                                          iconSty={{ margin: '0 10px' }}
                                          containerSty={{ left: '-150px' }}
                                          title="title"
                                          text="tips content"
                                        >
                                            <TipsContent
                                              sty={{ padding:0,margin:0 }}
                                            >
                                                If you choose the full assortment perspective, you will see the % (or #) of the different materials in the assortment as a whole. <br /><br /> If you have 100 products and 50 of them are 20% cotton and the other 50 are 100% cotton, then 60% ((20+100)/2) of your full assortment will be cotton according to this material distribution perspective.
                                            </TipsContent>
                                        </InfoBox>
                                    </Box>
                                )}
                              rightLabel={(
                                    <Box>
                                        Limited assortment
                                        <InfoBox
                                          iconSty={{ margin: '0 10px' }}
                                          containerSty={{ left: '-150px' }}
                                          title="title"
                                          text="tips content"
                                        >
                                            <TipsContent
                                              sty={{ padding:0,margin:0 }}
                                            >
                                                If you choose the limited assortment perspective, you decide the material % threshold that you think is relevant for a product to be counted into any specific material group. The reason is that you might want to consider only products of a certain material purity when you compare the material distribution of yourself and your competitors. <br /><br /> If you have 100 products and 50 of them are 20% cotton and the other 50 are 100% cotton - and you set the material % threshold to 99% - then 50% of your products will be counted as cotton and the remaining 50% will be allocated to multi-material, i.e. not be considered pure enough to be categorized as cotton products.
                                            </TipsContent>
                                        </InfoBox>
                                    </Box>
                                )}
                              value={isLimitMaterial}
                              onChange={checked => setModuleData({ isLimitMaterial: checked })}
                              right={30}
                            />
                            <MaterialSplitMaterialFilter
                              min={51}
                              onActivate={(percent: number) => {
                                    setMaterial({ checked: true, percent })
                                }}
                              onDeactivate={() => {
                                    setMaterial({ checked: false })
                                }}
                              percent={material.percent}
                              checked={!!material.checked}
                              style={{ position: 'relative', bottom: '-20px' }}
                              disabled={!isLimitMaterial}
                            />
                            <Box width={30} />
                        </HideWhenOpenInNew>
                        <Switch
                          leftLabel="%"
                          rightLabel="#"
                          value={switchValue}
                          onChange={checked => setSwitchValue(checked)}
                        />
                    </div>
                    {
                        noFullfillTips ? (
                            <NoCategory>{noFullfillTips}</NoCategory>
                        ) : (
                            <div className={styles.perVendorContent}>
                                {dataSource.map((item: any, index: number) => {
                                    const name = vendorShortMappingFn(formatBrandVendor(item.seller?.brand, getVendorNameByCode(item.seller?.vendor)))
                                    const region = regionShortMappingFn(item.seller?.region === 'World' ? comparisonQuery?.customer_seller?.region : item.seller.region)
                                    const options = item.seller?.queryDateValid && !item.seller.isLock ? numberUtils.formatNumberByComma(item.num) : NA
                                    return (
                                        <div key={formatBrandVendor(item?.seller?.brand, item?.seller?.vendor) || index.toString()}>
                                            <div className={styles.pieTitle}>
                                                <div>{formatVendorName(name).name}</div>
                                                <div style={{ fontSize: '12px', color: '#666', transform: 'scale(calc(11 / 12))' }}>{formatVendorName(name).platform} ({region})</div>
                                                <div>{OPTIONS_NAME}: {options}</div>
                                            </div>
                                            <div 
                                              onClick={e => e.stopPropagation()} 
                                              onMouseLeave={e => {
                                                    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/769#note_1269972397
                                                    e.stopPropagation()
                                                }}
                                            >
                                                <SplitPieChart
                                                  dataSource={item.charts}
                                                  queryDateValid={item.seller.queryDateValid}
                                                  onElementsClick={(_, sectorIndex) => onShowProductModal(item.unitList[sectorIndex], item.seller)}
                                                  isLock={item.seller.isLock}
                                                  pieList={item.unitList}
                                                  vendorCode={item.seller.vendor}
                                                />
                                            </div>
                                            <SplitPieList
                                              dataSource={switchValue ? item.unitList : item.percentList}
                                              queryDateValid={item.seller.queryDateValid}
                                              onItemClick={value => onShowProductModal(value, item.seller)}
                                              moduleName={MATERIAL_SPLIT_PIE_GRAPH_NAME}
                                              isLock={item.seller.isLock}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                </ShouldRender>
            </Spin>
            <Dialog ref={dialogRef} />
        </div>
    )
}
