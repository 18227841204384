import { NA, NAN } from 'consts'

/**
 * when license is one of [dashboardSoldOutNaLicenses]
 * set the sold out column value to N/A
 */
export const dashboardSoldOutNaLicenses = [ 'action' ]

export const getDashboardSoldOutValue = ({
    vendor,
    defaultValue,
}: {
    vendor: string;
    defaultValue: any;
}) => {
    if (dashboardSoldOutNaLicenses.includes(vendor)) {
        return NA
    }
    return defaultValue
}

/**
 * when options is 0, return N/A, else return value
 */
export const getDashboardValueByOptions = (value, options) => {
    if (Number(options) === 0) {
        return NA
    }
    return value
}

export const getDashboardPriceChangeValue = (value: any) => {
    if (String(Number(value)) === NAN) return value
    return `${Number(value) >= 0 ? '+' : '-'}${Math.abs(value)}`
}

export const getDashboardPriceChangePercentageValue = (value: any) => {
    if (value.indexOf('%') === -1) return value
    value = Number(value.replace('%', ''))
    return `${value >= 0 ? '+' : '-'}${Math.abs(value).toFixed(1)}%`
}
