import useDeepCompareEffect from 'use-deep-compare-effect'
import { cloneDeep } from 'lodash'
import { defaultPriceRange, selectCollection } from 'features/filters/filtersSlice'
import { getMemoData, updatePriceRangePreviousData } from 'features/filters/memoDataSlice'
import { argFnPriceRange, argFnTdPriceRange } from 'graphql/nornaapi'
import { useFetch } from 'libs/hookRequest'
import { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PriceRangeMemoryStateKey, TdPriceRangePreviousQueryType } from 'components/layout/Header/components/PriceFilter/types'
import { formatArgForNewPricePriceRange, formatArgForTdPriceRange } from 'utils/priceRange'
import { TdPriceRangeTypeEnum } from 'graphql/nornaapi/types'
import { getFilterTags } from 'features/filters/globalDataSlice'
import { usePageDate } from './usePageDate'
import { useReport } from './useReport'
import { useCustomMemoryState } from './useCustomState'
import { dateRangeUtils } from 'norna-uikit'

/**
 * ## 对比price range 的查询条件在`Filter` 改变后是否发生了变化
 *    用于判断是否禁用、启用price range
 * 
 * @returns 
 */
export const usePriceRangeQueryCompare = () => {

    // 获取系统统一时间
    const { pageDate } = usePageDate()
    const { qaPage } = useReport()
    const comparisonQuery = useSelector(selectCollection)
    const { priceRangePreviousQuery } = useSelector(getMemoData)
    const state = useSelector(getFilterTags)

    return {
        /**
         * ## 对比函数
         * 1. 对比上次price range 和 当前传入参数的price range 查询参数是否发生了变化
         * 
         * @param comparisonQueryData 当前要对比的`Filter`
         * 
         */
        compare: useCallback((comparisonQueryData?: any) => {
            if (!comparisonQueryData) comparisonQueryData = cloneDeep(comparisonQuery)
            if (Array.isArray(state.category)) {
                comparisonQueryData.collection.query.categories = state.category
            }
            if (Array.isArray(state.vendor)) {
                comparisonQueryData.sellers = state.vendor
            }
            if (Array.isArray(state.gender)) {
                comparisonQueryData.collection.query.targetGroups = state.gender
            }
            if (state?.region?.key) {
                comparisonQueryData.collection.query.regions = state.region
            }
            if (Object.keys(state.property || {}).length > 0) {
                comparisonQueryData.collection.query.properties = state.property
            }

            const argPriceRange = argFnPriceRange(comparisonQueryData, comparisonQueryData?.collection?.query?.regions?.currency, qaPage, dateRangeUtils.from(pageDate))
            const rangeQuery = cloneDeep(argPriceRange)

            delete rangeQuery.data.sort_by
            delete rangeQuery.data.query.price_filter.max_price
            delete rangeQuery.data.query.price_filter.max_price
            delete rangeQuery.data.query.price_filter.activate_price_range
            delete rangeQuery.data.query.price_filter.min_price

            const priceRangePreviousQueryData = JSON.parse(priceRangePreviousQuery)
            if(priceRangePreviousQueryData?.data?.market&&!priceRangePreviousQueryData.data.market.length)delete priceRangePreviousQueryData.data.market
            if(rangeQuery?.data?.market&&!rangeQuery.data.market.length)delete rangeQuery.data.market

            return {
                // rangeQuery exclude max,min,activate
                rangeQuery,

                // price range query
                argPriceRange,

                /**
                 *  ## Filter changed except `price_filter`
                 */
                changed: JSON.stringify(priceRangePreviousQueryData) !== JSON.stringify(rangeQuery),
            }
        }, [ comparisonQuery, pageDate, priceRangePreviousQuery, qaPage, state ]),
    }
}

export const useTdPriceRangeQueryCompare = (type=TdPriceRangeTypeEnum.TD) => {

    // 获取系统统一时间
    const { state:currentQuery } = useCustomMemoryState<TdPriceRangePreviousQueryType>(PriceRangeMemoryStateKey.TD)
    const { state } = useCustomMemoryState<TdPriceRangePreviousQueryType>(PriceRangeMemoryStateKey.TD_PRICE_RANGE)
    
    return {
        /**
         * ## 对比函数
         * 1. 对比上次price range 和 当前传入参数的price range 查询参数是否发生了变化
         */
        compare: useMemo(() => {

            const comparisonQueryData = cloneDeep(currentQuery)

            const priceRangePreviousQuery = cloneDeep(state.previousQuery)
            let arg
            if(type===TdPriceRangeTypeEnum.NEW_PRICE_TD){
                arg = formatArgForNewPricePriceRange(comparisonQueryData)
            }else{
                arg = formatArgForTdPriceRange(comparisonQueryData)
            }
            const argPriceRange = argFnTdPriceRange(arg) as any
            const currentRangeQuery = cloneDeep(argPriceRange)
            if (currentRangeQuery.data.price_filter) {
                delete currentRangeQuery.data.price_filter.actual_price
                delete currentRangeQuery.data.price_filter.max_price
                delete currentRangeQuery.data.price_filter.min_price
                delete currentRangeQuery.data.price_filter.activate_price_range
            }

            if (priceRangePreviousQuery?.data?.price_filter) {
                delete priceRangePreviousQuery.data.price_filter.actual_price
                delete priceRangePreviousQuery.data.price_filter.max_price
                delete priceRangePreviousQuery.data.price_filter.min_price
                delete priceRangePreviousQuery.data.price_filter.activate_price_range

            }

            return {
                // rangeQuery exclude max,min,activate
                rangeQuery:currentRangeQuery,

                // price range query
                argPriceRange,

                /**
                 *  ## Filter changed except `price_filter`
                 */
                changed: JSON.stringify(priceRangePreviousQuery??{}) !== JSON.stringify(currentRangeQuery),
            }
        }, [ currentQuery, state.previousQuery, type ]),
    }
}

export const priceRangeKeyFn = (type: number) => {
    let key = 'filter'
    return key
}

export default function usePriceRange() {
    const { postFn: priceRangeFn, loading } = useFetch()

    const dispatch = useDispatch()
    const { priceRangePreviousRes } = useSelector(getMemoData)

    // price range 的接口，默认取上次结果，当前接口可以覆盖上次结果
    const [ res, setRes ] = useState(priceRangePreviousRes)

    // 
    useDeepCompareEffect(() => {
        setRes(priceRangePreviousRes)
    }, [priceRangePreviousRes, []]) // eslint-disable-line

    const { compare } = usePriceRangeQueryCompare()

    /**
     * # 第一种方案
     *  price range 请求功能 
     *  触发来源有3种
     *  1. 初始化price range请求
     *  2. 直接的`Filter`改变请求，此时完成 price range 的请求后，再触发Filter的改变为佳
     *  3. 间接`Filter` 改变导致的请求，如其他分析页的Filter的联动, 此时 price range的请求和业务请求会冲突，导致两次业务请求
     * 
     * # 第二种方案(采用)
     *  1. 打开时Price range 时初始化及时请求，`第一次`会比较慢
     * 
     */
    const handleReq = useCallback(async () => {
        const { argPriceRange, rangeQuery, changed } = compare()

        /**
         *  ``` 
         *  排除 price_range 相关变量，判断Filter前后两次Filter 是否相同，
         *  如果不相同则请求新的range并且通过返回值设置activate_price_range=false
         *  ```
         */
        if (changed) {

            argPriceRange.data.query.price_filter.activate_price_range = false
            // 发生变化、或没有发生过请求
            const { data } = await priceRangeFn(argPriceRange.url, argPriceRange)
            let limit = data?.gap * 10
            if (limit === 0) limit = defaultPriceRange.max
            let bars: any = Object.keys(data?.price_ladder || {}).map((key: string) => data?.price_ladder?.[key].percentage)
            bars = handleBars(bars)
            const res = {
                limit,
                min: data.min_price ?? 0,
                max: data.max_price ?? 0 ?? limit,
                bars,
            }

            // 更新保存本次 price range 的 查询参数和结果，以便对比使用
            dispatch(updatePriceRangePreviousData({
                query: JSON.stringify(rangeQuery),
                res,
            }))
            setRes(res)

        }

    }, [ compare, dispatch, priceRangeFn ])

    return {
        limit: defaultPriceRange.max, bars: [], min: 0, max: defaultPriceRange.max,
        ...res ?? {},
        loading,

        updateActivate: handleReq,
    }
}

export function useTdPriceRange(type=TdPriceRangeTypeEnum.TD) {

    // 异步请求
    const { postFn: priceRangeFn, loading } = useFetch()

    // 数据仓库
    const { state, updateState } = useCustomMemoryState<TdPriceRangePreviousQueryType>(PriceRangeMemoryStateKey.TD_PRICE_RANGE)
    
    // price range 的接口，默认取上次结果，当前接口可以覆盖上次结果
    const [ , setRes ] = useState(state.previousQuery)

    // 
    useDeepCompareEffect(() => {
        setRes(state.previousQuery)
    }, [state.previousQuery, []]) // eslint-disable-line

    const { compare } = useTdPriceRangeQueryCompare(type)
    const { argPriceRange, rangeQuery, changed } = compare

    const handleReq = useCallback(async () => {
        /**
         *  ``` 
         *  排除 price_range 相关变量，判断Filter前后两次Filter 是否相同，
         *  如果不相同则请求新的range并且通过返回值设置activate_price_range=false
         *  ```
         */
        if (changed) {

            argPriceRange.data.query.price_filter.activate_price_range = false
            // 发生变化、或没有发生过请求
            const { data } = await priceRangeFn(argPriceRange.url, argPriceRange)
            let limit = data?.gap * 10
            if (limit === 0) limit = defaultPriceRange.max
            let bars: any = Object.keys(data?.price_ladder || {}).map((key: string) => data?.price_ladder?.[key].percentage)
            bars = handleBars(bars)
            const res = {
                limit,
                min: data.min_price ?? 0,
                max: data.max_price ?? 0 ?? limit,
                bars,
            }

            // 更新保存本次 price range 的 查询参数和结果，以便对比使用 
            updateState({
                previousQuery: rangeQuery,
                previousRes: res,
            })
            setRes(res)

        }

    }, [ argPriceRange, changed, priceRangeFn, rangeQuery, updateState ])

    return {
        limit: defaultPriceRange.max, bars: [], min: 0, max: defaultPriceRange.max,
        ...state.previousRes ?? {},
        loading,

        updateActivate: handleReq,
    }
}

/**
 * 2022/07/15 功能优化
 * 
 * ## 问题
 * 
 * Price range 柱状图
 * 最大值和最小值相差非常大时, 最小数量对应的柱子在图上压根看不到
 * 
 * ## 分析
 * 
 * 比如最大值是 1372, 最小值是 1
 * 柱状图高度固定是 72px (canvas 画布的高度)
 * 最大值对应的柱子高度是 72px, 
 * 最小值对应的柱子高度是 0.05px (72 / 1372 约等于 0.05), 这个高度的柱子在图上压根看不到
 * 经多次测试, 当值为 19 (1372 / 72) 时图上也能看到柱子, 值小于 19 时图上都是看不到柱子的
 * 
 * ## 解决
 * 
 * 找到所有柱子中最大值 maxValue
 * 计算出图上可以看到最小柱子的值 minValue = maxValue / 72
 * 遍历数据源
 * - 值大于 minValue 的, 返回自身值 
 * - 值小于 minValue 的, 返回 minValue, 保证图上可以看到这根柱子, 是有值的
 * - 值等于 0, 返回 0, 图上就是没有这根柱子
 */
export function handleBars(bars = []) {
    const maxValue = Math.max(...bars)
    const minValue = maxValue / 30   // 72 虽然可以看到, 还是太小了
    const data = bars.map(item => {
      if (item === 0) {
        return 0
      } if (item > minValue) {
        return item
      }
      return minValue
    })
    return data
}
