import React from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, LabelList } from 'recharts'
import { colors, getMaxTick } from './utils'

export const MyBarChart = ({
    width = 400,
    height = 230,
    dataSource = [],
    yAxisFormatter,
}: {
    width?: number;
    height?: number;
    dataSource?: {label: string, value: number;}[];
    yAxisFormatter?: (value: number) => string;
}) => {
    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, index, value } = props;
    
        const labelH = 28
        const labelW = 88
        // const labelT = 15   // label 距离柱子顶部的高度 15px
        const labelT2 = 3
        const color = colors[index]
    
        const p1 = [ x - (labelW - labelH - width) / 2, y - labelT2 - labelH ]
        const c1 = [ p1[0], p1[1] + labelH / 2 ]
        const c2 = [ p1[0] + labelW - labelH, p1[1] + labelH / 2 ]
        const r = labelH / 2
        const t = [ x + width / 2, p1[1] + labelH / 2 + 1 ]  // 文本坐标

        return (
            <g>
                <circle cx={c1[0]} cy={c1[1]} r={r} fill={color} />
                <circle cx={c2[0]} cy={c2[1]} r={r} fill={color} />
                <rect x={p1[0]} y={p1[1]} width={labelW - labelH} height={labelH} fill={color} />
                <text x={t[0]} y={t[1]} textAnchor="middle" dominantBaseline="middle" fill="white" fontSize={12}>
                    {typeof yAxisFormatter === 'function' ? yAxisFormatter(value) : value}
                </text>
            </g>
        )
    }

    return (
        <BarChart
            width={width}
            height={height}
            data={dataSource}
            margin={{
                top: 20,
                right: 30,
                left: 0,
                bottom: 0,
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" tick={false} axisLine={false} />
            <YAxis
                width={70}
                axisLine={false} 
                tickSize={0} 
                tick={{ fontSize: 12, color: '#666' }} 
                tickFormatter={yAxisFormatter}
                tickMargin={10} 
                domain={[ 0, getMaxTick(dataSource) ]}
            />
            <Bar 
                dataKey="value" 
                barSize={8}
            >
                <LabelList dataKey="value" content={renderCustomizedLabel} />
                {
                    dataSource.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} opacity={0.5} />
                    ))
                }
            </Bar>
        </BarChart>
    )
}
