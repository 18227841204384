import React from 'react'
import { ColorDot } from 'componentsv2/'
import { IS_LIVE_ENV } from 'consts'
import styles from './styles.module.scss'
import { ProductColorsProps } from './types'

export const ProductColors = ({
  colorList = [],
  onClick,
}: ProductColorsProps) => {
  const border = '2.5px solid #D0021B'
  return (
    <div className={styles['product-colors']}>
      <div className="flex flex-row flex-wrap">
        {
          colorList.map(item => (
            <ColorDot
              key={item.nornaid}
              click={() => onClick?.(item)}
              colors={item.colors}
              sortOrder={item.sortOrder}
              isSelected={item.isSelected}
              selectedBorder={border}
              style={{ marginRight: '10px', marginBottom: '10px' }}
              // 2022/07/25 生产环境不显示 tooltip, 非生产环境才显示 tooltip
              className={!IS_LIVE_ENV ? styles.colorDot : ''}
            />
          ))
        }
      </div>
    </div>
  )
}

ProductColors.displayName = 'ProductColors'
