//  export const metaCategory = {
//   'T-Shirts' : {
//     'composition':{
//       'Tops/Shirts' : {
//         'Tops/Shirts type' : {
//           'Polo shirt' : true,
//           'Henley' : true,
//           'T-Shirt' : true
//         }
//       }
//     },
//     'group': []
//   },
//
//   'Coats & Blazers' : {
//     'composition':
//       {
//         'Jackets' : {
//           'Jackets type' : { // {"Jackets type":['Tuxedo/Black tie']}
//             'Tuxedo/Black tie' : true,
//             'Club blazer' : true,
//             'Blazer' : true
//           }
//         },
//         'Coats' : {} = > {catgory:'Coats',properties:[] ,blackProperties:[]}
//       },
//     'group': ['T-Shirts', 'Shirts']
//   },
//
//   'Shirts' : {
//     'composition':
//       {
//         'Tops/Shirts' : {
//           'Tops/Shirts type' : {
//             'Shirt' : true
//           }
//         }
//       },
//     'group': []
//   }
// };
// {
//   group: 'Other coats',
//     options: [
//   { 'key': 'Coat', 'description': 'Coat', 'state': 1 },
//   { 'key': 'Duffle coat', 'description': 'Duffle coat', 'state': 0 },
// ],

import { filterDisabledCategoriesInLive } from 'utils/sortCategory'

// }
export const formatMetaCategory = metaCategory => {
  const obj = {}
  if(typeof metaCategory==='string')return {
    list:[],listObj:{},
    metaCategory:[],
  }
  const parentCategory = (metaCategory || []).map(item => {
    if (item.group.length) {
      item.group.forEach(key1 => {
        if (!obj[key1]) {
          obj[key1] = []
        }
        obj[key1].push(item.category_name)
      })
      return [ '' ]
    }
    return [ item.category_name ]
  }).flat().filter(item => item)

  /**
   * 过滤掉一级禁用的Category
   */
  const list = filterDisabledCategoriesInLive(parentCategory).map(key => {
    const list = Object.keys((obj[key] || []).reduce((res, current) => {
      res[current] = current
      return res
    }, {}))

    return { //eslint-disable-line
      name: key,
      isChecked: false,
      /**
       * 过滤掉一级禁用的Category
       */
      list: filterDisabledCategoriesInLive(list).map(item => ({
        name: item,
        isChecked: false,
      })),
    }
  })

  return {
    list,
    listObj: list.reduce((item, current) => {
      item[current.name] = current
      return item
    }, {}),
    metaCategory,
  }
}

