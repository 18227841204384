import { CUSTOM_EVENTS } from 'consts'
import { clearLookbookActions, getLookbookData, updateLookbookActions } from 'features/filters/lookbookDataSlice'
import { cloneDeep, debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import emitter from 'utils/event'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useLookbook } from './useLookbook'

export function useLookbookAction(addListener = false, isBatch = false) {

    const { lookbookActions } = useSelector(getLookbookData)
    const { checked, isLookbook } = useLookbook()
    const dispatch = useDispatch()

    const actionRef = useRef<Array<string>>(lookbookActions)

    useDeepCompareEffect(() => {
        actionRef.current= lookbookActions
    },[ lookbookActions ])
    // const listenerRef = useRef<(arg: any) => void>()

    const [ changing, setChanging ] = useState(0)

    // listener function
    const listenerFn = useCallback(() => {
        debounce(lookbooks => {
            if (isBatch) return
            const res = lookbookActions.filter((item: string) => lookbooks.includes(item))

            dispatch(updateLookbookActions(res))
            setChanging(Math.random())
        }, 1000)
    },[ isBatch,dispatch,lookbookActions ])

    useDeepCompareEffect(() => {

        setChanging(Math.random())

    }, [ lookbookActions ])

    // listener
    const listener = useCallback(() => {
        // listenerRef.current = listen/er
        emitter.addListener(CUSTOM_EVENTS.LOOKBOOK_ACTIONS, listenerFn)
    }, [ listenerFn ])

    // rm listener
    const rmListener = useCallback(() => {
        emitter.removeListener(CUSTOM_EVENTS.LOOKBOOK_ACTIONS, listenerFn)
    }, [ listenerFn ])

    useEffect(() => {
        if (addListener) listener?.()
        return () => {
            if (addListener) rmListener?.()
        }
    }, []) //eslint-disable-line

    const updateLookbookActionsFn = useCallback((lookbookIdNornaids: Array<string>) => {

        let adds: Array<string> = cloneDeep(lookbookActions)

        lookbookIdNornaids.forEach(lookbookIdNornaid => {

            // judge product in the current selected lookbook
            if (checked.includes(lookbookIdNornaid.split(/[+,-]/)[0])) {

                // case1: has removed product and now add it back
                if (lookbookIdNornaid.indexOf('+') > -1 && adds.includes(lookbookIdNornaid.replace('+', '-'))) {
                    adds = adds.filter(item => item !== lookbookIdNornaid.replace('+', '-'))

                    // case2: has added product and now remove it
                } else if (lookbookIdNornaid.indexOf('-') > -1 && adds.includes(lookbookIdNornaid.replace('-', '+'))) {
                    adds = adds.filter(item => item !== lookbookIdNornaid.replace('-', '+'))

                    // case3: new action
                } else {
                    adds = [
                        ...adds,
                        lookbookIdNornaid,
                    ]
                }
            } else {
                adds = [
                    ...adds,
                    lookbookIdNornaid,
                ]
            }
        })

        if (adds.sort().join() !== [ ...lookbookActions ].sort().join()) {
            dispatch(updateLookbookActions(adds))
            actionRef.current = adds
            emitter.emit(CUSTOM_EVENTS.LOOKBOOK_ACTIONS, adds)
            return true
        }
        return false

    }, [ checked, dispatch, lookbookActions ])

    const judgeActionOccurs = useCallback(() => 
        /**
         * 2022/06/17
         * 只有在 lookbook 页面并且 ProductsModal 中有产品添加到 lookbook 或从 lookbook 中移除时才返回 true
         */
         isLookbook && actionRef.current?.length
    , [ actionRef, isLookbook ])

    const clear = useCallback(() => {

        dispatch(clearLookbookActions())
    }, [ dispatch ])
    return {

        clearLookbookActions: clear,

        /**
         * ## 主动判断
         * 
         * 弹框操作时，关闭弹框时，主动判断是否有动作发生
         * @returns 
         */
        judgeActionOccurs,

        changing,

        /** 
         * judge the current lookbook analysis should refresh 
         * 
         * ## 根据lookbook action动态集合(删除、添加)判断当前analysis 是否应该刷新，适用两种场景
         * 1. product card
         * 2. 弹框操作集合
         * 
         * @param lookbookIdNornaids 
         * @returns 
         */
        updateLookbookActions: updateLookbookActionsFn,

    }
}
