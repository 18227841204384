import { useFetch } from 'libs/hookRequest'
import { REQUEST_RESET_PASSWORD } from 'consts/request'

export const useResetPassword = () => {
  const { postFn, data } = useFetch()
  const post = arg => {
    postFn(REQUEST_RESET_PASSWORD, arg)
  }
  return [ post, data ]
}
