import React from 'react'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useLoadingBar } from 'hooks'
import { MaterialSplitPieGraph } from './components/MaterialSplitPieGraph'
import styles from './styles.module.scss'

export const MaterialSplit = () => {
  useScrollToTop()
  useLoadingBar()

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.container}>
        <MaterialSplitPieGraph />
      </div>
    </div>
  )
}

