import React, { useRef } from 'react'
import { SnackInfo } from 'componentsv2/'
import { useSelector } from 'react-redux'
import { selectComparisonQuery } from 'features/filters/filtersSlice'
import copy from 'copy-to-clipboard'
import { MenuDropdown, MenuDropdownItem } from 'componentsv2/MenuDropdown/'
import { SHARE_FILTER_KEY } from 'consts'
import { useFetch } from 'libs/hookRequest'
import { argFnSaveFilter } from '../../../graphql/nornaapi'
import { useUrlParams } from '../../../hooks'
import { ObjectProps } from '../../../types'
import { Scissors, ShareIcon } from '../../../assets/icons'

/** *
 *
 * share filter buttton
 * @param props
 * @constructor
 */
export function ShareFilterButton(props) {
  const snackRef = useRef<ObjectProps>({})
  const filter = useSelector(selectComparisonQuery)
  const { postFn: updateData, error } = useFetch()
  const { pathObj, ...params } = useUrlParams()
  React.useEffect(() => {
    if (error) snackRef.current?.open?.('Failed to get share link')
  }, [ error, snackRef ])
  return (
    <>
      <MenuDropdown
        customClass="cell-mouse-effect-nav"
        panelSty={{ right: '-16px',paddingTop:10 }}
        list={<>
          <MenuDropdownItem 
          customClass="cell-mouse-effect-no-display"
          onClick={async () => {
            const query = argFnSaveFilter(JSON.stringify(filter))
            const updateDataRes = await updateData(query.url, query)
            if (!updateDataRes.error) {
              copy(pathObj.linkUrlParam({
                ...params,
                [SHARE_FILTER_KEY]: updateDataRes.data,
              }))
              snackRef.current?.open?.('Copied share link to clipboard')
            } else {
              snackRef.current?.open?.('Copied failed')
            }
          }}
          >
            <Scissors /> Copy link
          </MenuDropdownItem>
              </>}
      >
        <div style={{  height: 24, fill: '#999',color:'#666',display: 'inline-flex', alignItems:'center' }} >
          <ShareIcon /> &nbsp;&nbsp;Share
        </div>
      </MenuDropdown>
      <SnackInfo ref={snackRef} />
    </>
  )
}

