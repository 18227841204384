import { arrayUtils, numberUtils } from 'norna-uikit'
import { getVendorNameByCode } from 'utils'
import { difference, sortVendorList } from 'utils/array'
import { handleTableExpanded } from 'utils/dashboardPageUtils'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { whetherLock } from 'utils/lockSellerUtils'
import { storage } from 'utils/storage'

/**
 * 补全数据
 * full vt. 装满, 使充满
 * 
 * 处理前数据
 * arcteryx: {
 *      Colors: [
 *      ],
 *      Eco-label: [
 *          {name: 'Others', percent: 1},
 *      ]
 * }
 * 
 * 处理后数据
 * arcteryx: {
 *      Colors: [
 *          {name: 'N/A', percent: 'N/A'},
 *          {name: 'N/A', percent: 'N/A'},
 *          {name: 'N/A', percent: 'N/A'},
 *          {name: 'N/A', percent: 'N/A'},
 *          {name: 'N/A', percent: 'N/A'},
 *      ],
 *      Eco-label: [
 *          {name: 'Others', percent: 1},
 *          {name: 'N/A', percent: 'N/A'},
 *          {name: 'N/A', percent: 'N/A'},
 *          {name: 'N/A', percent: 'N/A'},
 *          {name: 'N/A', percent: 'N/A'},
 *      ]
 * }
 */
export function fullData(data) {
    const newData = JSON.parse(JSON.stringify(data))
    const emptyStr = 'N/A'
    const vendorList = Object.keys(newData)
    vendorList.forEach(vendor => {
        const vendorData = newData[vendor]
        const propertyList = Object.keys(vendorData)
        propertyList.forEach(property => {
            // 需要补齐的数量
            const padLength = 5 - vendorData[property].length
            newData[vendor][property].push(...Array(padLength).fill({ name: emptyStr, count: emptyStr, percent: emptyStr }))  
        })
    })
    return newData
}

/**
 * rowData = [
 *      {name: 'N/A', percent: 'N/A'},
 *      {name: 'N/A', percent: 'N/A'},
 *      {name: 'N/A', percent: 'N/A'},
 *      {name: 'N/A', percent: 'N/A'},
 *      {name: 'N/A', percent: 'N/A'},
 * ]
 */
export function handleRowData(rowData) {
    return {
        no1Name: rowData[0].name,
        no1Value: formatValue(rowData[0].percent),
        no1Products: rowData[0]?.products || [],
        no2Name: rowData[1].name,
        no2Value: formatValue(rowData[1].percent),
        no2Products: rowData[1]?.products || [],
        no3Name: rowData[2].name,
        no3Value: formatValue(rowData[2].percent),
        no3Products: rowData[2]?.products || [],
        no4Name: rowData[3].name,
        no4Value: formatValue(rowData[3].percent),
        no4Products: rowData[3]?.products || [],
        no5Name: rowData[4].name,
        no5Value: formatValue(rowData[4].percent),
        no5Products: rowData[4]?.products || [],
    }
}

export function formatValue(value) {
    const emptyStr = 'N/A'
    return value === emptyStr ? emptyStr : numberUtils.formatNumber(value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
}) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    const titleRow = [
        'Vendor',	
        'Attribute',	
        ...arrayUtils.range(5).map(item => {
            return [
                item + 1,
                '%',
            ]
        }).flat(2),
    ]
    data.push(titleRow)

    const handleRowData = (item) => {
        return [
            item?.name,
            item?.categoryKey,
            item?.no1Name,
            item?.no1Value,
            item?.no2Name,
            item?.no2Value,
            item?.no3Name,
            item?.no3Value,
            item?.no4Name,
            item?.no4Value,
            item?.no5Name,
            item?.no5Value,
        ]
    }

    dataSource.forEach(item => {
        const dataRow = handleRowData(item)
        data.push(dataRow)

        if (item?.other?.length) {
            item.other.forEach(item2 => {
                const dataRow2 = handleRowData(item2)
                data.push(dataRow2)
            })
        }
    })

    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}

export const handleTableData = ({
    apiData,
    competitorValue,
    region,
    expandedRowKeys = [],
}: {
    apiData: any,
    competitorValue: string[];
    region: string;
    expandedRowKeys: string[];
}): any => {
    if (!apiData || !Object.keys(apiData || {})?.length) {
        return {
            tabledata: [],
            metricsTab: [],
        }
    }
    apiData = { ...apiData }

    // 第一级标题
    const totalCategoryLabel = 'Colors'
    if (!storage.getIsLookbook()) {
        difference(Object.keys(apiData), competitorValue).forEach(vendor => {
            delete apiData[vendor]
        })
    }

    const tabledata: any = []
    const metricsTab: any[] = []

    const sellers = storage.getSellers()

    // vendor 进行排序
    let vendorList = Object.keys(apiData)
    vendorList = sortVendorList({ vendorList })
    
    // competitor 视图
    const metricsChildren: any[] = []

    vendorList.forEach(vendor => {
        // 查询日期是否有效
        const queryDateValid = isQueryDateValid({ vendor, region, competitorValue })
        // 是否显示锁图标
        const isLock = whetherLock({ vendorCode: vendor, region, sellers, selectedVendorCodeList: competitorValue })

        const vendorName = getVendorNameByCode(vendor)
        const vendorData = apiData[vendor]
        const firstLevelCategoryList = Object.keys(vendorData || {}).filter(item => item !== 'Colors')

        // 第一级展开 id: vendor + category
        const firstLevelExpandId = `${vendor}_Colors`

        // 第 0 级
        const level0Children: any[] = []
        // 获得第二级列表
        firstLevelCategoryList.forEach(property => {
            // 第 1 级
            const level1TreeItemData = {
                ...handleCellData(vendorData[property]),
                children: [],
                level: 1,
                vendorName,
                vendorCode: vendor,
                isLock,
                categoryName: property,
                queryDateValid,
                optionsNum:1,
            }
            level0Children.push(level1TreeItemData)
        })

        // 第 0 级, All categories
        const level0TreeItemData = {
            ...handleCellData(vendorData[totalCategoryLabel]),
            children: level0Children,
            level: 0,
            vendorName,
            vendorCode: vendor,
            isLock,
            categoryName: totalCategoryLabel,
            queryDateValid,
            optionsNum: 1,
        }
        tabledata.push(level0TreeItemData)

        metricsTab.push({
            expandId: firstLevelExpandId,
            active: vendor === vendorList[0],
            code: vendor,           // 小写名称
            title: vendorName,      // 大写名称
            categoryKey: totalCategoryLabel,        // category 名称
            level: 0,
            children: metricsChildren.filter(c => c.code === vendor),
            categorycount: firstLevelCategoryList.length + 1,
        })
    })

    handleTableExpanded(metricsTab, expandedRowKeys, [])

    return {
        tabledata,
        metricsTab,
    }
}

const handleCellData = (cellData: any[]) => {
    const obj = {}
    cellData.forEach((item, index) => {
        obj[`no${index + 1}Name`] = item.name
        obj[`no${index + 1}Value`] = numberUtils.formatNumber(item.percent, { isCentuple: true, decimal: 1, isPercentSymbol: true })
        obj[`no${index + 1}Products`] = item?.products || []
    })
    return obj
}

export const propertyWidth = 175
export const noNameWidth = 144
export const noValueWidth = 70
export const noWidth = noNameWidth + noValueWidth
