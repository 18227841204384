import { LICENSE_CANADAGOOSE } from 'consts'

export * from './moduleName'

/**
 * 这些 license 在全局日期框的 Period 下拉框中灰质 Same period last year 选项
 */
export const disabledSamePeriodLastYearLicenseList = [
    LICENSE_CANADAGOOSE,
]

export const chatApiPrefix = 'https://staging.norna.ai:9001/chatbot'
