import React, { ReactNode, useRef, useLayoutEffect, cloneElement } from 'react'
import classnames from 'classnames'
import { throttle } from 'lodash'
import { createPortal } from 'react-dom'
import { PAGE_CONTAINER_CLASSNAME } from 'consts'
import { addClassName, delClassName } from 'utils/domUtils'
import styles from './styles.module.scss'

const tooltipHide = 'tooltip-hide'

export const Tooltip = ({
  children,
  title,
  zoom = 1,
}: {
  children: any;
  title: ReactNode;
  zoom?: number;
}) => {
  const triggerRef = useRef<HTMLElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  const getRect = el => {
    if (!el) return
    const rect = el.getBoundingClientRect()
    return rect
  }

  const getMaxHeight = () => {
    const tooltips = document.querySelectorAll('.tooltip-customer')
    const tooltipHeightList: number[] = []
    tooltips.forEach(tooltip => {
      tooltipHeightList.push(getRect(tooltip).height)
    })
    const maxHeight = Math.max(...tooltipHeightList)
    return maxHeight
  }

  const onTriggerMouseEnter = () => {
    if (!tooltipRef.current) return
    const triggerRect = getRect(triggerRef.current) as any
    const tooltipRect = getRect(tooltipRef.current) as any

    const left = triggerRect.left * zoom + Math.abs(triggerRect.width * zoom - tooltipRect.width) / 2 * (triggerRect.width > tooltipRect.width ? 1 : -1)
    const top = triggerRect.top * zoom - tooltipRect.height - 10
    tooltipRef.current.style.left = left + 'px'
    tooltipRef.current.style.top = top + 'px'

    const tooltipMaxHeight = getMaxHeight()
    const limitTop = triggerRect.top * zoom - tooltipMaxHeight

    if (limitTop > 0) {
      delClassName(tooltipRef.current, tooltipHide)
    }
  }

  const onTriggerMouseLeave = () => {
    if (!tooltipRef.current) return
    addClassName(tooltipRef.current, tooltipHide)
  }

  const onPageContainerScroll = throttle(() => {
    if (!tooltipRef.current) return
    addClassName(tooltipRef.current, tooltipHide)
  }, 500)

  useLayoutEffect(() => {
    if (!triggerRef.current) return
    const triggerEl = triggerRef.current
    triggerEl.addEventListener('mouseenter', onTriggerMouseEnter)
    triggerEl.addEventListener('mouseleave', onTriggerMouseLeave)
    const pageContainer = document.querySelector(`.${PAGE_CONTAINER_CLASSNAME}`)
    pageContainer?.addEventListener('scroll', onPageContainerScroll)

    return () => {
      triggerEl?.removeEventListener('mouseenter', onTriggerMouseEnter)
      triggerEl?.removeEventListener('mouseleave', onTriggerMouseLeave)
      pageContainer?.removeEventListener('scroll', onPageContainerScroll)
    }
    // eslint-disable-next-line
  }, [zoom])

  return (
    <>
      {
        cloneElement(children, {
          ref: triggerRef,
        })
      }
      {
        createPortal((
          <div ref={tooltipRef} className={classnames([ styles.tooltip, 'tooltip-hide', 'tooltip-customer' ])}>
            {title}
          </div>
        ), document.querySelector('body') as HTMLElement)
      }
    </>
  )
}
