import React from 'react'
import { SizedBox } from 'componentsv2/SizedBox'
import { InOutMonitoringTable } from './components/InOutMonitoringTable'

export const InOutMonitoring = () => {

    return (
        <>
            <InOutMonitoringTable />
            <SizedBox height={30} />
        </>
    )
}
