/**
 * 各个页面都会使用到的公共函数放这个文件里
 */

import { ALL_VENDORS, MARKET_VENDOR, NA, SELECTED_VENDORS } from 'consts'
import { sortVendorList } from 'utils/array'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { storage } from './storage';
import { whetherLock } from './lockSellerUtils';
import { numberUtils } from 'norna-uikit';

/**
 * 处理 vendor 排序
 * 
 * data = [
 *      { seller: { vendor: '', region: '' }, xxxxxxxxxx },
 * ]
 */
export function handleVendorSort({
    data,
    vendorField = 'seller.vendor',
}: {
    data: any[];
    vendorField?: string;
}) {
    const newData = sortVendorList({
        vendorList: data,
        vendorField,
    })
    return newData
}

/**
 * 处理 queryDateValid
 * 
 * data = [
 *      { seller: { vendor: '', region: '' }, xxxxxxxxxx },
 * ]
 * 
 * newData = [
 *  { seller: { vendor: '', region: '', queryDateValid: true, queryComparisonDateValid: true }, xxxxxxxxxx },
 * ]
 */
export function handleQueryDateValid({
    data,
    competitorValue = [],
    filterCountry,
}: {
    data: any[];
    competitorValue: string[];
    filterCountry?: string;
}) {
    const newData = data.map(item => {
        const { vendor } = item.seller
        const region = filterCountry || item.seller.region
        const queryDateValid = isQueryDateValid({ vendor, region, competitorValue })
        const queryComparisonDateValid = isQueryDateValid({ vendor, region, competitorValue, isComparisonDate: true })
        item.seller.queryDateValid = queryDateValid
        item.seller.queryComparisonDateValid = queryDateValid && queryComparisonDateValid
        return item
    })
    return newData
}

export function handleIsLock({
    data,
    competitorValue = [],
    region,
}: {
    data: any[];
    competitorValue: string[];
    region: string;
}) {
    const sellers = storage.getSellers()
    const selectedVendorCodeList = competitorValue.filter(c => ![ SELECTED_VENDORS, ALL_VENDORS, MARKET_VENDOR.vendor ].includes(c))

    const newData = data.map(item => {
        item.seller.isLock = whetherLock({
            vendorCode: item.seller.vendor,
            region: region || item.seller.region,
            sellers,
            selectedVendorCodeList,
        })
        return item
    })
    return newData
}

/**
 * queryDateValid false 返回 NA
 */
export function getValueByQueryDateValid(value: any, queryDateValid: boolean) {
    return queryDateValid ? value : NA
}

/**
 * options 等于 0, 其它数值都是 NA
 */
export function getValueByOptions(value: any, options: any) {
    return Number(options) === 0 ? NA : value
}

export function handleNumber(value: number, {
    d = 0,
    x = 1,
    s = false,
    p = false,
}: {
    d?: number;         // 保留小数位数
    x?: number;         // 倍数
    s?: boolean;        // 是否显示分隔符 separator
    p?: boolean;        // 是否显示百分号 percent
}): any {
    let newValue: any = value

    // 倍数
    newValue *= x
    
    // 保留小数位数
    newValue = newValue.toFixed(d)

    // 千分位分隔符
    if (s) {
        newValue = numberUtils.formatNumberByComma(newValue)
    }

    if (p) {
        newValue += '%'
    }

    return newValue
}
