export const SLICE_NAME = 'moduleData'

export const SOLD_OUT_VENDORS_TABLE = 'soldOutVendorsTable'

export const GRAPH_BUTTON_MODULE_NAME = 'graphButton'

export const PLATFORM_ANALYTICS_MODULE_NAME = 'platformAnalytics'

export const MOST_POPULAR_LAUNCH_DAYS_MODULE_NAME = 'mostPopularLaunchDays'

export const OPTIONS_IN_OUT_BY_MONTH_MODULE_NAME = 'optionsInOutByMonth'

export const CSV_DATA_MODULE_NAME = 'csvData'

export const CSV_DATA_DYNAMIC_DASHBOARD = 'dynamicDashboard'
export const CSV_DATA_PRICE_INSIGHTS = 'priceInsights'
export const CSV_DATA_ASSORTMENT_INSIGHTS = 'assortmentInsights'
export const CSV_DATA_PRICE_SPREADS = 'priceSpreads'
export const CSV_DATA_IN_OUT_MONITORING = 'inOutMonitoring'
export const CSV_DATA_MOST_POPULAR_LAUNCH_DAYS = 'mostPopularLaunchDays'
export const CSV_DATA_OPTIONS_IN_OUT_BY_MONTH = 'optionsInOutByMonth'
export const CSV_DATA_PRICE_DRIVERS_INTRA_PERIOD = 'priceDriversIntroPeriod'
export const CSV_DATA_PRICE_DRIVERS_PERIOD_TO_PERIOD = 'priceDriversPeriodToPeriod'
export const CSV_DATA_PRICE_ARCHITECTURE = 'priceArchitecture'
export const CSV_DATA_DISCOUNTS = 'discounts'
export const CSV_DATA_CROSS_REGION_PRICING = 'crossRegionPricing'   // --
export const CSV_DATA_SOLD_OUT_ANALYTICS = 'soldOutAnalytics'
export const CSV_DATA_SOLD_OUT_CROSS_REGION = 'soldOutCrossRegion'
export const CSV_DATA_CATEGORY_SPLIT = 'categorySplit'
export const CSV_DATA_COLOR_SPLIT = 'colorSplit'
export const CSV_DATA_MATERIA_SPLIT = 'materialSplit'
export const CSV_DATA_PRODUCT_RANKING_LAST_WEEK = 'productRankingLastWeek'
export const CSV_DATA_ATTRIBUTE_RANKING = 'attributeRanking'
export const CSV_DATA_SIZE_OF_LINE = 'sizeOfLine'
export const CSV_DATA_MARKETPLACE_SELLER_ANALYTICS = 'marketplaceSellerAnalytics'
