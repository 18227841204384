import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { CATEGORY_TOTAL, SELECTED_CATEGORY, SELECTED_VENDORS, SUMMARY } from 'consts'
import { uniq } from 'lodash'
import { numberUtils } from 'norna-uikit'
import { getVendorNameByCode } from 'utils'
import { difference, sortVendorList } from 'utils/array'
import { getTotalCategoryLabel, getTotalVendorLabel, handleTableExpanded } from 'utils/dashboardPageUtils'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { whetherLock } from 'utils/lockSellerUtils'
import { storage } from 'utils/storage'

interface ApiData {
  [vendor: string]: {
    [category: string]: {
      [field: string]: any;
    }
  }
}

export const handleTableData = ({
  apiData,
  isCompetitorView,
  competitorValue,
  categoryValue,
  expandedRowKeys = [],
  subExpandedRowKeysRef = [],
  region,
}: {
  apiData: ApiData,
  isCompetitorView: boolean;
  competitorValue: string[];
  categoryValue: string[];
  expandedRowKeys: string[];
  subExpandedRowKeysRef: string[];
  region: string;
}): any => {
  if (!apiData || !Object.keys(apiData || {})?.length) {
    return {
      tabledata: [],
      metricsTab: [],
    }
  }
  apiData = { ...apiData }

  let totalVendorLabel = getTotalVendorLabel(apiData)
  const totalCategoryLabel = getTotalCategoryLabel(apiData[competitorValue[0]])
  const sellers = storage.getSellers()

  if (!storage.getIsLookbook()) {
    if (isCompetitorView) {
      difference(Object.keys(apiData), competitorValue).forEach(vendor => {
        delete apiData[vendor]
      })
    } else {
      difference(Object.keys(apiData), uniq([ ...competitorValue, SELECTED_VENDORS ])).forEach(vendor => {
        delete apiData[vendor]
      })
      if (!competitorValue.includes(SELECTED_VENDORS) && apiData[SELECTED_VENDORS]) {
        apiData[SUMMARY] = apiData[SELECTED_VENDORS]
        delete apiData[SELECTED_VENDORS]
        totalVendorLabel = SUMMARY
      }
    }
  }

  const maxOfMaxField = handleMaxOfMaxField(apiData)

  const tabledata: any = []
  const metricsTab: any[] = []

  const categoryTreeObj = storage.getCategoryTreeObj()
  const firstLevelCategoryList = getFirstLevelCategories({ selectedCategories: categoryValue })
  const categoryKeys = [ ...categoryValue ]

  // vendor 进行排序
  let vendorList = Object.keys(apiData)
  vendorList = sortVendorList({ vendorList })

  // competitor 视图
  if (isCompetitorView) {
    const metricsChildren: any[] = []

    vendorList.forEach(vendor => {
      const queryDateValid = isQueryDateValid({ vendor, region, competitorValue })
      const queryComparisonDateValid = isQueryDateValid({ vendor, region, competitorValue, isComparisonDate: true })
      const isLock = whetherLock({ vendorCode: vendor, region, sellers, selectedVendorCodeList: competitorValue })

      const vendorName = getVendorNameByCode(vendor)
      const vendorData = apiData[vendor]

      const firstLevelExpandId = `${vendor}_${SELECTED_CATEGORY}`

      // 第 0 级
      const level0Children: any[] = []
      firstLevelCategoryList.forEach(category => {
        const level1Children: any[] = []
        if (categoryTreeObj[category]?.list?.length) {
          const secondLevelExpandId = `${vendor}_${category}`
          metricsChildren.push({
            expandId: `${firstLevelExpandId}__${secondLevelExpandId}`,
            active: false,
            code: vendor,
            level: 1,
            categoryKey: category,
            title: vendorName,
            categorycount: categoryTreeObj[category].list?.filter(item => categoryKeys.includes(item.name))?.length,
          })

          // 第 2 级
          categoryTreeObj[category].list?.filter(item => categoryKeys.includes(item.name))?.forEach(subcategoryObj => {
            const subcategory = subcategoryObj.name
            const level2TreeItemData = {
              ...vendorData[subcategory],
              maxOfMaxField,
              level: 2,
              vendorName,
              vendorCode: vendor,
              isLock,
              categoryName: subcategory,
              queryDateValid,
              queryComparisonDateValid,
              optionsNum: vendorData[subcategory]?.options?.value || 0,
            }
            level1Children.push(level2TreeItemData)
          })
        }

        // 第 1 级
        const level1TreeItemData = {
          ...vendorData[category],
          maxOfMaxField,
          children: level1Children,
          level: 1,
          vendorName,
          vendorCode: vendor,
          isLock,
          categoryName: category,
          queryDateValid,
          queryComparisonDateValid,
          optionsNum: vendorData[category]?.options?.value || 0,
        }
        level0Children.push(level1TreeItemData)
      })

      // 第 0 级, All categories
      const lavel0TreeItemData = {
        ...vendorData[totalCategoryLabel],
        maxOfMaxField,
        children: level0Children,
        level: 0,
        vendorName,
        vendorCode: vendor,
        isLock,
        categoryName: totalCategoryLabel,
        queryDateValid,
        queryComparisonDateValid,
        optionsNum: vendorData[totalCategoryLabel]?.options?.value || 0,
      }
      tabledata.push(lavel0TreeItemData)

      metricsTab.push({
        expandId: firstLevelExpandId,
        active: false,
        code: vendor,           // 小写名称
        title: vendorName,      // 大写名称
        categoryKey: totalCategoryLabel,        // category 名称
        level: 0,
        children: metricsChildren.filter(c => c.code === vendor),
        categorycount: firstLevelCategoryList.length + 1,
      })
    })
  }

  // category 视图
  else {
    const vendorDateValidMapper = {}
    vendorList.forEach(vendor => {
        vendorDateValidMapper[vendor] = {
            queryDateValid: isQueryDateValid({ vendor, region, competitorValue }),
            queryComparisonDateValid: isQueryDateValid({ vendor, region, competitorValue, isComparisonDate: true }),
            isLock: whetherLock({ vendorCode: vendor, region, sellers, selectedVendorCodeList: competitorValue }),
        }
    })

    // totalCategoryLabel 放在数组最后面, 对应页面上表格最后一列
    const categoryList = [ ...firstLevelCategoryList, totalCategoryLabel ]
    categoryList.forEach(category => {
      const level0ChildrenData: any[] = []
      const level0Children: any[] = []
      const metricsChildren: any[] = []

      vendorList.forEach(vendor => {
        const queryDateValid = vendorDateValidMapper[vendor]?.queryDateValid
        const queryComparisonDateValid = vendorDateValidMapper[vendor]?.queryComparisonDateValid
        const isLock = vendorDateValidMapper[vendor]?.isLock

        const vendorName = getVendorNameByCode(vendor)
        const vendorData = apiData[vendor]
        const rowData = { ...vendorData[category] }

        // 第 1 级
        if (vendor !== totalVendorLabel) {
          const level1TreeItemData = {
            ...rowData,
            maxOfMaxField,
            level: 1,
            vendorName,
            vendorCode: vendor,
            isLock,
            categoryName: category,
            queryDateValid,
            queryComparisonDateValid,
            optionsNum: rowData?.options?.value || 0,
          }
          level0Children.push(level1TreeItemData)
        }
        // 第 0 级
        else {
          tabledata.push({
            ...rowData,
            maxOfMaxField,
            level: 0,
            children: level0Children,
            childrenData: level0ChildrenData,
            vendorCode: vendor,
            vendorName,
            isLock,
            categoryName: category,
            queryDateValid,
            queryComparisonDateValid,
            optionsNum: rowData?.options?.value || 0,
          })
        }
      })

      // 构造 childrenData 数据
      const categoryValue = categoryTreeObj[category]
      const firstLevelExpandId = [ CATEGORY_TOTAL, SELECTED_CATEGORY ].includes(category) ? SELECTED_CATEGORY : category
      if (categoryValue?.list?.filter(item => categoryKeys.includes(item.name))?.length) {
        categoryValue.list.filter(item => categoryKeys.includes(item.name)).forEach(subcategory => {
          const children: any[] = []

          vendorList.forEach(vendor => {
            const queryDateValid = vendorDateValidMapper[vendor]?.queryDateValid
            const queryComparisonDateValid = vendorDateValidMapper[vendor]?.queryComparisonDateValid
            const isLock = vendorDateValidMapper[vendor]?.isLock

            const vendorName = getVendorNameByCode(vendor)
            const vendorData = apiData[vendor]
            const rowData = { ...vendorData[subcategory.name] }

            // 第 1 级
            if (vendor !== totalVendorLabel) {
              children.push({
                ...rowData,
                maxOfMaxField,
                vendorCode: vendor,
                vendorName,
                isLock,
                categoryName: subcategory.name,
                parentCategoryName: category,
                queryDateValid,
                queryComparisonDateValid,
                optionsNum: rowData?.options?.value || 0,
              })
            }
            // 第 0 级
            else {
              level0ChildrenData.push({
                ...rowData,
                maxOfMaxField,
                level: 1,
                children,
                vendorCode: vendor,
                vendorName,
                isLock,
                categoryName: subcategory.name,
                parentCategoryName: category,
                queryDateValid,
                queryComparisonDateValid,
                optionsNum: rowData?.options?.value || 0,
              })
            }
          })

          metricsChildren.push({
            expandId: `${firstLevelExpandId}__${subcategory.name}`,
            active: false,
            code: subcategory.name,
            level: 1,
            pTitle: category,
            title: subcategory.name,
            categorycount: vendorList.length,
          })
        })
      }

      metricsTab.push({
        expandId: firstLevelExpandId,
        active: false,
        code: category,
        title: category,
        level: 0,
        children: metricsChildren,
        categorycount: vendorList.length,
      })
    })
  }

  handleTableExpanded(metricsTab, expandedRowKeys, subExpandedRowKeysRef)

  return {
    tabledata,
    metricsTab,
  }
}

export function handleMaxOfMaxField(data: any): number {
  let maxOfMaxField = 0
  Object.values(data).forEach((categoryObj: any) => {
    Object.values(categoryObj).forEach((categoryValue: any) => {
      maxOfMaxField = Math.max(maxOfMaxField, categoryValue.max.value)
    })
  })
  return maxOfMaxField
}

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
  dataSource,
  isCompetitorView = true,
}) {
  if (!dataSource?.length) return ''
  const data: any[] = []

  const titleRow = [
      isCompetitorView ? 'Vendors' : 'Categories',	
      'Options',	
      '# +/- %',	
      isCompetitorView ? 'Category' : 'Vendor',	
      'Low price',	
      '',	
      'Average price',	
      '',	
      'High price',	
      '',	
      'Low %',	
      '',	
      'Avg %',	
      '',	
      'High %',	
      '',
  ]
  data.push(titleRow)

  const handleRowData = (item) => {
      return [
          isCompetitorView ? item?.vendorName : item?.categoryName,
          item?.options?.value,
          numberUtils.formatNumber(item?.options?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
          isCompetitorView ? item?.categoryName : item?.vendorName,
          numberUtils.formatNumber(item?.min?.value, { decimal: 0 }),
          numberUtils.formatNumber(item?.min?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
          numberUtils.formatNumber(item?.avg?.value, { decimal: 0 }),
          numberUtils.formatNumber(item?.avg?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
          numberUtils.formatNumber(item?.max?.value, { decimal: 0 }),
          numberUtils.formatNumber(item?.max?.change_percent, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
          numberUtils.formatNumber(item?.min_index, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
          numberUtils.formatNumber(item?.avg_index, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
          numberUtils.formatNumber(item?.max_index, { isCentuple: true, isPercentSymbol: true, decimal: 1 }),
      ]
  }

  dataSource.forEach(item => {
      const dataRow = handleRowData(item)
      data.push(dataRow)

      if (item?.children?.length) {
          item.children.forEach(item2 => {
              const dataRow2 = handleRowData(item2)
              data.push(dataRow2)

              if (item2?.children?.length) {
                  item2.children.forEach(item3 => {
                      const dataRow3 = handleRowData(item3)
                      data.push(dataRow3)
                  })
              }
          })
      }
  })

  const csvString = data.map(data => data.join(',')).join('\r\n');
  return csvString
}
