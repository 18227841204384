import React from 'react'
import classNames from 'classnames'
import { CaretProps } from './types'
import styles from './styles.module.scss'

export const CaretIcon = ({ isReversed,color, onClick, className }: CaretProps) =>
    <svg
        style={color ? { fill: color } : {}}
        className={classNames(styles.caret, isReversed && styles.reverse, onClick && styles.clickable, className)}
        onClick={e => onClick && onClick(e)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path d="M7 10l5 5 5-5z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>

