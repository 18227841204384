import React, { useRef, useState, useEffect, useContext, useCallback, SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAllSellers } from 'graphql/sellers/getAllSellers'
import { updateFilerFn, updateFilters } from 'features/filters/filtersSlice'
import { getCacheData } from 'features/filters/cacheDataSlice'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ActionBoxContext } from 'components/common/InfoBox/context'
import { LockIcon } from 'assets/icons/LockIcon'
import { UnlockIcon } from 'assets/icons/UnlockIcon'
import { useFlatQueryObj } from 'hooks/'
import { storage } from 'utils/storage'
import { cloneDeep, isEqual } from 'lodash'
import { FILTER_DIALOG_CLASS, PRICE_RANGE_MODAL_CLASS } from 'consts'
import classnames from 'classnames'
import { useFilter } from 'hooks/useFilter'
import { Filter } from 'assets/icons/NewMenu/Filter'
import { TreeInteractiveType } from 'componentsv2/Tree'
import { updateFilterTags } from 'features/filters/globalDataSlice'
import styles from './styles.module.scss'
import { useSellers } from '../../../../../graphql/sellers/getSellers'
import { FilterRefType } from './HeaderFilter.type'
import { HeaderFilterContent } from './HeaderFilterContent'
import { parentshasClass } from '../../../../common/InfoBox/InfoActionContext'
import { HeaderFilterPropsType } from './types'

export const HeaderFilter = ({ updateFilterChangeStatus, interactiveType = TreeInteractiveType.HOVER, active: fifterOpen, updateActive: setFifterOpen, currentActive = '' }: HeaderFilterPropsType) => {
  const flatQuery = useFlatQueryObj()
  const dispatch = useDispatch()

  const { sellers, getSellerByCodeAndRegion, filterVendorEuropeOrCountry } = useSellers(flatQuery.sellers || [])
  const { data: regions } = useAllSellers(false)
  const cacheData = useSelector(getCacheData)

  const actionBox = useContext(ActionBoxContext)

  // 值为 true 时，下拉树不会消失
  const [ locked, setLocked ] = useState(false)

  /**
   * 需求：
   * 在右上角有 Filter 的页面中点击 lock 图标将 Filter 固定在页面上
   * 此时跳转到右上角没有 Filter 的页面，如 Dashboard 页面
   * 希望再次跳回右上角有 Filter 的页面时，Filter 自动展开，并且仍然是锁住状态
   */
  useEffect(() => {
    const locked = storage.getLocked()
    setLocked(locked)
  }, [])

  /**
   * 每次 locked 值变化时，都需要记录在 LocalStorage 中
   * 
   * 目的是：当 locked 值为 true 时，从没有 Filter 的页面跳转有 Filter 的页面时，
   * Filter 自动展开，并且仍然是锁住状态
   */
  useEffect(() => {
    storage.setLocked(locked)
  }, [ locked ])

  const filterRef = useRef<FilterRefType>(null)
  const { comparisonQuery } = flatQuery

  /**
   *  ## 判断 Filter 打开后是否发生了变化
   */
  const judgeChanged = React.useCallback((treeRes: any) => {
    if (!treeRes?.vendor) return {
      filterChanged: false,
    }
    treeRes.vendor = treeRes.vendor.map(item => getSellerByCodeAndRegion(item.vendor, item.region, item.brands))

    /**
     * 勾选了Makert时, 对应Market 传
     */
    treeRes.market = treeRes.market.map(item => getSellerByCodeAndRegion(item.vendor, item.region, item.brands))

    const query = cloneDeep(updateFilerFn(treeRes, comparisonQuery))
    const comparisonQueryData = cloneDeep(comparisonQuery)

    // ignore data to compare
    delete query.collection.query?.priceRange?.activate_price_range
    delete comparisonQueryData.collection?.query?.priceRange?.activate_price_range
    delete comparisonQueryData.market
    delete query.market

    let filterChanged = !isEqual(JSON.parse(JSON.stringify(query)), JSON.parse(JSON.stringify(comparisonQueryData)))
    if (!filterChanged && document.querySelector(`.${FILTER_DIALOG_CLASS}`)) {
      filterChanged = true
    }
    return {
      query,
      filterChanged,
    }
  }, [ comparisonQuery, getSellerByCodeAndRegion ])

  const clearFilterTags = () => {
    dispatch(updateFilterTags({}))
  }

  const updateFilterFn = React.useCallback((e = {}) => {
    const treeRes = filterRef?.current?.onChange(e)
    if (treeRes && fifterOpen) {
      const { filterChanged } = judgeChanged(treeRes)

      // 改变Filter条件时, 关闭当前弹框，否则不关闭
      if (filterChanged) actionBox.closeAll()

      clearFilterTags()

      /**
       *  ##  price range 启用与否判断
       *  此处对比前后两次price_range 查询参数是否除price_range外发生了变化，
       *  如果是发生了变化，则禁用price range, 否则启用 price range
       */
      // const { changed } = compare(query)
      // if (changed) treeRes.priceRange.activate_price_range = false

      // clear updated status
      updateFilterChangeStatus?.(false)

      /**
       *  ## 禁用 price range 判断
       *  当前条件禁用priceRange时，需清除price range 导致的 `Filter` 改变
       *  此时的Filter 改变需有 price range 来触发完成
       */
      dispatch(updateFilters(treeRes))

    }

    if (locked === false && fifterOpen === true) {
      const rangeFilter = document.querySelector(PRICE_RANGE_MODAL_CLASS)
      if (!rangeFilter) {
        clearFilterTags()
        setFifterOpen?.(false)
      }
    }
    // eslint-disable-next-line
  }, [fifterOpen, locked, judgeChanged, actionBox, updateFilterChangeStatus, dispatch, setFifterOpen])

  const { disabled } = useFilter()

  const onClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    if (disabled) return
    setFifterOpen?.(currentActive !== 'Filter')
  }, [ disabled, setFifterOpen, currentActive ])

  /**
   * ## hover leave `filter`
   */
  const hoverEvent = useCallback(event => {
    if (interactiveType === TreeInteractiveType.CLICK) return
    const target = event.target as any
    if (target) {
      const { clientX } = event

      const { x, width } = target.getBoundingClientRect()
      const treeRes = filterRef?.current?.onChange(null)
      const { filterChanged } = judgeChanged(treeRes)
      /**
       * 鼠标从元素的上、下、左方向移出, 并且没有`Filter`发生变化
       */
      if (clientX < x + width && !filterChanged) {
        setFifterOpen?.(false)
        clearFilterTags()
        updateFilterChangeStatus?.(false)
      }
    }
    // eslint-disable-next-line
  }, [interactiveType, judgeChanged, setFifterOpen, updateFilterChangeStatus])

  /**
   * ## mouse leave panel
   */
  const hoverLeavePanel = useCallback(() => {
    if (interactiveType === TreeInteractiveType.CLICK) return
    const treeRes = filterRef?.current?.onChange(null)
    const { filterChanged } = judgeChanged(treeRes)
    updateFilterChangeStatus?.(filterChanged)
    if (!filterChanged && !disabled) {
      clearFilterTags()
      setFifterOpen?.(false)
    }
    // eslint-disable-next-line
  }, [disabled, interactiveType, judgeChanged, setFifterOpen, updateFilterChangeStatus])

  const hideFilter = useCallback(() => {
    /**
     * 这个地方没法直接用当前页面 [locked, setLocked] = useState(false) 中的变量 locked
     * 因为内部有一个闭包，导致 locked 值永远是第一次的值
     * 但是可以从 localStorage 中获取 locked 值，这个每次都是最新的
     */
    const locked = storage.getLocked()
    // 锁住状态，不能关闭 Filter 下拉框
    if (locked) return
    setFifterOpen?.(false)
  }, [ setFifterOpen ])

  const clickAway = useCallback((e: any) => {
    const parEle = parentshasClass(e.target, 'material-filter')
    const dateEle = parentshasClass(e.target, 'DatePicker__calendarContainer')
    if (parEle || dateEle) return
    if (!fifterOpen) return
    e.stopPropagation?.()
    updateFilterFn(e)
  }, [ fifterOpen, updateFilterFn ])

  return (
    <>
      <ClickAwayListener
        onClickAway={clickAway}
      >
        <div
          className={styles.filterWrapper}
          onMouseEnter={(e: SyntheticEvent) => {
            if (disabled || !currentActive || currentActive === 'Filter') return
            setFifterOpen?.(true)
          }}
          onMouseLeave={hoverEvent}
          onClick={onClick}
        >
          <div
            className={classnames(styles['fifter-info'], 'menubarItem')}
          >
            <div className={styles.fiflterItemWrapper}>
              <button
                className={classnames({
                  'text-ellipsis': true,
                  [styles.filterTitle]: true,
                  [styles.disabled]: disabled,
                })}
              >
                <Filter />
              </button>
              <span className={styles.label}>Filter</span>
            </div>
            <div
              className={classnames(styles.fixedFifter, 'fixedFilter')}
              onClick={e => e.stopPropagation()}
            >
              <ShouldRender shouldRender={!!fifterOpen}>
                <div
                  style={{ position: 'relative' }}
                  onMouseLeave={hoverLeavePanel}
                >
                  <div >
                    <HeaderFilterContent
                      ref={filterRef}
                      interactiveType={interactiveType}
                      flatQuery={flatQuery}
                      hideFilter={hideFilter}
                      noticeFn={value => {
                        dispatch(updateFilterTags(value))
                      }}
                      filterVendorEuropeOrCountry={filterVendorEuropeOrCountry}
                      sellers={sellers}
                      regions={regions}
                      cacheData={cacheData}
                      onClickMask={updateFilterFn}
                    />
                  </div>
                  <div
                    className={styles.filterLockIcon}
                    onClick={() => setLocked(!locked)}
                  >
                    <ShouldRender shouldRender={locked}>
                      <LockIcon size={24} />
                    </ShouldRender>
                    <ShouldRender shouldRender={!locked}>
                      <UnlockIcon size={24} />
                    </ShouldRender>
                  </div>
                </div>
              </ShouldRender>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}

HeaderFilter.displayName = 'HeaderFilter'

