import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'druikit'
import { getDisplaySize } from '../HarmonizedSizeRanges/utils'
import { LineChart } from '../LineChart'
import { Title } from '../Title'
import { ProductVo } from '../../types'
import { numberUtils } from 'norna-uikit'

function getSizeOptions (sizeList: string[]) {
    const options = [
        '<S', 'S', 'M', 'L', 'XL', '>XL',
    ]
    .map(s => ({ value: s, label: getDisplaySize(s) }))
    .filter(item => sizeList.includes(item.value))
    return options
}

const HarmonizedSizeChart = ({
    product,
}: {
    product?: ProductVo;
}) => {
    const [ size, setSize ] = useState(product?.totalHarmonizingSizes?.[0] || '')
    const [ dataSource, setDataSource ] = useState<{x: string; y: number}[]>([])

    useEffect(() => {
        if (!product?.totalHarmonizingSizes?.length) return 
        setSize(product?.totalHarmonizingSizes?.[0])
    }, [ product?.totalHarmonizingSizes ])

    useEffect(() => {
        if (!Object.keys(product?.availableHarmonizingSizeGraph || {}).length || !size) return
        const dataObj = product?.availableHarmonizingSizeGraph[size] || {}
        const dataSource: {x: string; y: number}[] = []
        Object.keys(dataObj).forEach(date => {
            dataSource.push({
                x: date,
                y: 100 - (dataObj[date] === 1 ? 100 : 0),
            })
        })
        setDataSource(dataSource)
    }, [ product?.availableHarmonizingSizeGraph, size ])

    if (!product?.allowHarmonizingSize) return null

    return (
        <>
            <Flex justifyContent="space-between">
                <Title>Sold out history</Title>
                <Select
                    style={{ marginRight: 30 }}
                    size="small"
                    options={getSizeOptions(Object.keys(product?.availableHarmonizingSizeGraph || {}) || [])}
                    value={size}
                    onChange={value => setSize(value)}
                    placeholder="Sizes"
                />
            </Flex>
            {
                size ? (
                    <LineChart
                        label={getDisplaySize(size)}
                        chartData={dataSource || []}
                        customTooltipLabel={item => `Size: ${numberUtils.formatNumberByComma(item.yLabel)}%`}
                        customYAxesTicks={value => `${numberUtils.formatNumberByComma(value.toFixed(0))}%`}
                        yAxesMaxFixed
                        isHarmonizedSize
                    />
                ) : (
                    <LineChart
                        label=""
                        chartData={[]}
                        yAxesMaxFixed
                        isHarmonizedSize
                    />
                )
            }
        </>
    )
}

export default HarmonizedSizeChart
