/*
 * 写成纯函数便于单元测试
 */
import moment from 'moment'
import { differenceInDays } from 'date-fns'
import { sortBy } from 'lodash'
import { WhiteCategory, IS_LIVE_ENV, LICENSE_ZEEMAN, LICENSE_BOGNER, isZeemanLicense, PROPERTY_MULTI_PACK_VALUE, PROPERTY_MULTI_PACK_LABEL } from 'consts'
import { formatProductName } from 'utils'
import { storage } from 'utils/storage'
import { PriceInfo, PeerProduct, DiscountHistory, LineChartPoint, ColorRecord, ProductObj, ProductDto, ProductVo, CurrentPrice, URL_STATE } from './types'
import { numberUtils } from 'norna-uikit'

/**
* 处理数据结构
* 
* 将接口返回的产品数据结构转换为页面需要的数据结构，之后不管接口如果变化，
* 只要构造相同的页面数据结构即可
*/
export function handleData({
	productDto,
	latestDate,
	productObj,
	notShowSubGroupsMap = {},
}: {
	productDto: ProductDto,
	latestDate: string,
	productObj: ProductObj,
	notShowSubGroupsMap: any,
}): ProductVo {
	// 01 处理 url
	const { url, urlState } = handleUrl({
		url: productDto.url,
		priceInfo: productDto?.price_info,
		interpolate: productDto?.interpolate,
		latestDate,
	})

	// 02 处理 current price
	// 是否币种不一致，做了汇率转换，true 表示前后币种不一样做了汇率转换，false 表示未做汇率转换
	const whetherRateConverted = getWhetherRateConverted({ 
		currency: productDto.currency, 
		originalCurrency: productDto.original_currency, 
	})
	// const fractionDigits = getFractionDigits()
	const fractionDigits = 2

	// 取 price_info 字段最后一个值
	const currentPrice = handleSalesPrice({ priceInfo: productDto?.price_info, whetherRateConverted, fractionDigits })
	const fullPrice = handleFullPrice({ priceInfo: productDto?.price_info, whetherRateConverted, fractionDigits })

	// 05 firstObservedDate 和 firstDiscountDate 字段格式化日期，用 - 替代 /
	const firstDatePrice = Object.values(productDto.price_info)[0]
	/**
	 * 2022/08/30
	 * FIRST OBSERVED PRICE 不能显示两个价格
	 * 当折扣价不存在时, 显示原价
	 * 当折扣价存在时, 显示折扣价
	 */
	const firstDiscountPrice = handlePriceConvert(firstDatePrice?.discounted_price || 0, whetherRateConverted, fractionDigits)
	const firstOriginalPrice = handlePriceConvert(firstDatePrice?.original_price || 0, whetherRateConverted, fractionDigits)
	const firstPrice = {
		discountedPrice: handlePriceConvert(0, whetherRateConverted, fractionDigits),
		originalPrice: firstDatePrice?.discounted_price ? firstDiscountPrice : firstOriginalPrice,
	}
	const introductionPrice = { 
		discountedPrice: handlePriceConvert(0, whetherRateConverted, fractionDigits), 
		originalPrice: firstOriginalPrice, 
	}
	const highestPrice = {
		discountedPrice: handlePriceConvert(0, whetherRateConverted, fractionDigits),
		originalPrice: handlePriceConvert(productDto.max_price?.actual_price || 0, whetherRateConverted, fractionDigits),
	}

	/* **************************************** Date ********************************************* */
	// 处理 last relaunch date, 2022/06/21 新加字段
	const lastRelaunchDate = productDto.launch_date === productDto.last_launch_date ? '-' : handleDateConvert(productDto.last_launch_date)
	const firstObservedDate = handleDateConvert(productDto.launch_date)
	const firstDiscountDate = handleDateConvert(productDto.first_discount_date)

	/* **************************************** Product details ********************************************* */
	const materialObj = handleMaterial(productDto.materials.material_composition)

	const propertyList = handleProperty(productDto.properties, notShowSubGroupsMap)
	if (propertyList.find(item => item.key === 'Brands')) {
		propertyList.forEach(item => {
			if (item.key === 'Brands') {
				item.value = [ productDto?.brand || item?.value?.[0] ]
			}
		})
	}
	/**
	 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/1009
	 */
	if (isZeemanLicense()) {
		propertyList.forEach(item => {
			if (item.key === 'Packs' && item.value.length === 1 && item.value[0] === PROPERTY_MULTI_PACK_VALUE) {
				item.value = [ PROPERTY_MULTI_PACK_LABEL ]
			}
		})
	}

	// availableSizes 和 unavailableSizes 进行合并然后排序
	// 排序规则：数值字符串按数值大小排序，非数值字符串按字符排序
	const sizeList = handleSize([ ...productDto.available_size, ...productDto.unavailable_size ])

	let colorList = handleColor(productDto.peer_products, productDto.nornaid, productObj)
	/**
	 * modify at 2024年01月16日19:16:15
	 * 硬编码: zzegna && China 不显示 color dot
	 */
	if (productDto?.seller?.vendor === 'zzegna' && productDto?.seller?.region === 'China') {
		colorList = []
	}

	/* **************************************** Norna category ********************************************* */
	// norna_category = Nightwear
	// type: 取 properties 中以 ' type' 结尾的属性对象
	const nornaCategory = productDto.norna_category
	const nornaCategoryTypeObj = handleNornaCategoryType(productDto.properties)

	/* **************************************** Norna size ********************************************* */
	const nornaSize = productDto.norna_size || []

	/* **************************************** Price line chart ********************************************* */
	const priceHistoryList = handlePriceHistory(productDto.price_info, latestDate, whetherRateConverted, fractionDigits)

	/* **************************************** full price line chart ********************************************* */
	const fullPriceHistoryList = handleFullPriceHistory(productDto.price_info, latestDate, whetherRateConverted, fractionDigits)

	/* **************************************** Discount line chart ********************************************* */
	const discountHistoryList = handleDiscountHistory(productDto.discount_depths, latestDate, whetherRateConverted, fractionDigits)

	/* **************************************** Category details ********************************************* */
	const mainCategory = Object.keys(productDto.categories || {}).join(', ')
	const subCategory = Object.values(productDto.categories || {})
		.flat(10)
		.filter(item => item)
		.map(item => String(item))
		.join(', ')

	/* **************************************** Performance ********************************************* */
	// 计算 averagePrice
	const averagePrice = `${handlePriceConvert(productDto.average_price, whetherRateConverted, fractionDigits)} ${productDto.currency}`

	// discountDepth 乘以100，保留整数，然后加上百分号
	const discountDepth = (productDto.average_discount_depth * 100).toFixed(1) + '%'
	const nonzeroDiscountDepth = (productDto.average_nonzero_discount_depth * 100).toFixed(1) + '%'

	// 处理 days observed: 最近一天有产品的日期减去 first observed 的日期
	const daysObserved = differenceInDays(new Date(latestDate), new Date(productDto.launch_date)).toString()

	return {
		nornaid: productDto.nornaid,
		images: productDto.images,
		
		title: formatProductName(productDto.title),
		url,
		urlState,
		originalUrl: productDto.url,
		
		currentPrice,
		currency: productDto.currency,
		firstPrice,
		fullPrice,
		introductionPrice,
		highestPrice,
		vendor: productDto.seller.vendor,

		// date
		lastRelaunchDate,
		firstObservedDate,
		firstDiscountDate,

		// product details
		targetGroup: productDto.target_groups.join(', '),
		materialObj,
		propertyList,
		sizeList,
		colorList,

		// norna category
		nornaCategory,
		nornaCategoryTypeObj,

		// norna size
		nornaSize,

		// price line chart
		priceHistoryList,

		// full price line chart
		fullPriceHistoryList,

		// discount line chart
		discountHistoryList,

		// category details
		mainCategory,
		subCategory,

		// performance
		averagePrice,
		discountDepth,
		nonzeroDiscountDepth,
		daysObserved,

		// size
		allowHarmonizingSize: productDto.allow_harmonizing_size,
		totalHarmonizingSizes: productDto.total_harmonizing_sizes,
		availableHarmonizingSizeGraph: productDto.available_harmonizing_size_graph,
	}
}

export const handleSizePriceData = ({
	productDto,
	latestDate,
	productObj,
	notShowSubGroupsMap,
}: {
	productDto: any;
	latestDate: string;
	productObj: any;
	notShowSubGroupsMap: any;
}) => {
	const { url, urlState } = handleUrl({
		url: productDto.url,
		priceInfo: productDto?.price_info,
		interpolate: productDto?.interpolate,
		latestDate,
	})

	// 处理 current price
	// 是否币种不一致，做了汇率转换，true 表示前后币种不一样做了汇率转换，false 表示未做汇率转换
	const whetherRateConverted = getWhetherRateConverted({ 
		currency: productDto.currency, 
		originalCurrency: productDto.original_currency, 
	})
	// const fractionDigits = getFractionDigits()
	const fractionDigits = 2

	/* **************************************** Date ********************************************* */
	// 处理 last relaunch date, 2022/06/21 新加字段
	const lastRelaunchDate = productDto.launch_date === productDto.last_launch_date ? '-' : handleDateConvert(productDto.last_launch_date)
	const firstObservedDate = handleDateConvert(productDto.launch_date)
	const firstDiscountDate = handleDateConvert(productDto.first_discount_date)

	/* **************************************** Price ********************************************* */
	// Sales price range all sizes
	const latestActualPriceRange = handleLatestActualPriceRange(productDto?.latest_size_price)
	const salesPriceMinValue = handlePriceConvert(latestActualPriceRange?.min, whetherRateConverted, fractionDigits)
	const salesPriceMaxValue = handlePriceConvert(latestActualPriceRange?.max, whetherRateConverted, fractionDigits)
	// First observed price
	const firstObservedPrice = getFirstPrice(productDto?.price_info)?.original_price
	const firstObservedPriceMinValue = handlePriceConvert(firstObservedPrice, whetherRateConverted, fractionDigits)
	const firstObservedPriceMaxValue = handlePriceConvert(firstObservedPrice, whetherRateConverted, fractionDigits)
	// Full price
	const latestOriginalPriceRange = handleLatestOriginalPriceRange(productDto?.latest_size_price)
	const fullPriceMinValue = handlePriceConvert(latestOriginalPriceRange?.min, whetherRateConverted, fractionDigits)
	const fullPriceMaxValue = handlePriceConvert(latestOriginalPriceRange?.max, whetherRateConverted, fractionDigits)
	// Average price option
	const averagePriceOption = handlePriceConvert(productDto?.latest_average_price, whetherRateConverted, fractionDigits)
	// Discount
	const discount = (productDto?.discounts * 100).toFixed(1) + '%'
	const salesPrice = handleSalesPrice({ priceInfo: productDto?.price_info, whetherRateConverted, fractionDigits })
	const fullPrice = handleFullPrice({ priceInfo: productDto?.price_info, whetherRateConverted, fractionDigits })
	const averageFullPrice = fullPrice.originalPrice

	/* **************************************** Product details ********************************************* */
	const materialObj = handleMaterial(productDto.materials.material_composition)

	const propertyList = handleProperty(productDto.properties, notShowSubGroupsMap)
	if (propertyList.find(item => item.key === 'Brands')) {
		propertyList.forEach(item => {
			if (item.key === 'Brands') {
				item.value = [ productDto?.brand || item?.value?.[0] ]
			}
		})
	}

	const sizePriceList = handleSizePriceList({
		sizePrice: productDto?.latest_size_price,
		whetherRateConverted, 
		fractionDigits,
		availableSize: productDto?.available_size,
		priceInfo: productDto?.price_info,
	})
	const totalSizePrice = handleTotalSizePrice({
		sizePriceList,
		whetherRateConverted,
		fractionDigits,
		currency: productDto.currency || '',
	})

	const sellerPerSize = handleSellerPerSize({
		sizePrice: productDto?.latest_size_price,
	})

	const colorList = handleColor(productDto.peer_products, productDto.nornaid, productObj)

	/* **************************************** Norna category ********************************************* */
	// norna_category = Nightwear
	// type: 取 properties 中以 ' type' 结尾的属性对象
	const nornaCategory = productDto.norna_category
	const nornaCategoryTypeObj = handleNornaCategoryType(productDto.properties)

	/* **************************************** Norna size ********************************************* */
	const nornaSize = productDto.norna_size || []

	/* **************************************** Price line chart ********************************************* */
	const priceHistoryList = handlePriceHistory(productDto.price_info, latestDate, whetherRateConverted, fractionDigits)

	/* **************************************** full price line chart ********************************************* */
	const fullPriceHistoryList = handleFullPriceHistory(productDto.price_info, latestDate, whetherRateConverted, fractionDigits)

	/* **************************************** Discount line chart ********************************************* */
	const discountHistoryList = handleDiscountHistory(productDto.discount_depths, latestDate, whetherRateConverted, fractionDigits)

	/* **************************************** Category details ********************************************* */
	const mainCategory = Object.keys(productDto.categories || {}).join(', ')
	const subCategory = Object.values(productDto.categories || {})
		.flat(10)
		.filter(item => item)
		.map(item => String(item))
		.join(', ')

	/* **************************************** Performance ********************************************* */
	// 计算 averagePrice
	const averagePrice = `${handlePriceConvert(productDto.average_price, whetherRateConverted, fractionDigits)} ${productDto.currency}`

	// discountDepth 乘以100，保留整数，然后加上百分号
	const discountDepth = (productDto.average_discount_depth * 100).toFixed(1) + '%'
	const nonzeroDiscountDepth = (productDto.average_nonzero_discount_depth * 100).toFixed(1) + '%'

	// 处理 days observed: 最近一天有产品的日期减去 first observed 的日期
	const daysObserved = differenceInDays(new Date(latestDate), new Date(productDto.launch_date)).toString()

	return {
		nornaid: productDto.nornaid,
		images: productDto.images,
		currency: productDto.currency,

		title: formatProductName(productDto.title),
		url,
		urlState,
		originalUrl: productDto.url,
		vendor: productDto?.seller?.vendor,

		// date
		lastRelaunchDate,
		firstObservedDate,
		firstDiscountDate,

		// price
		salesPriceMinValue,
		salesPriceMaxValue,
		fullPriceMinValue,
		fullPriceMaxValue,
		firstObservedPriceMinValue,
		firstObservedPriceMaxValue,
		discount,
		averagePriceOption,
		salesPrice,
		fullPrice,
		isSizePriceNotEmpty: !isSizePriceEmpty(productDto?.latest_size_price),
		sizePriceRange: productDto?.size_price_range || {},
		averageFullPrice,

		// product details
		targetGroup: productDto.target_groups.join(', '),
		materialObj,
		propertyList,
		colorList,
		sizePriceList,
		totalSizePrice,
		sellerPerSize,

		// norna category
		nornaCategory,
		nornaCategoryTypeObj,

		// norna size
		nornaSize,

		// price line chart
		priceHistoryList,

		// full price line chart
		fullPriceHistoryList,

		// discount line chart
		discountHistoryList,

		// category details
		mainCategory,
		subCategory,

		// performance
		averagePrice,
		discountDepth,
		nonzeroDiscountDepth,
		daysObserved,
	}
}

/**
 * 实际价格: 有打折价就用打折价, 没有打折价就用原价
 */
function handleLatestActualPriceRange(sizePrice) {
	const actualPriceRange = { min: 0, max: 0 }
	const isEmpty = isSizePriceEmpty(sizePrice)
	if (isEmpty) return actualPriceRange

	const priceList = Object.keys(sizePrice).map(size => sizePrice[size]?.discounted_price || sizePrice[size]?.original_price)
	actualPriceRange.min = Math.min(...priceList)
	actualPriceRange.max = Math.max(...priceList)
	return actualPriceRange
}

/**
 * 原价
 */
function handleLatestOriginalPriceRange(sizePrice) {
	const originalPriceRange = { min: 0, max: 0 }
	const isEmpty = isSizePriceEmpty(sizePrice)
	if (isEmpty) return originalPriceRange

	const priceList = Object.keys(sizePrice).map(size => sizePrice[size]?.original_price)
	originalPriceRange.min = Math.min(...priceList)
	originalPriceRange.max = Math.max(...priceList)
	return originalPriceRange
}

function isSizePriceEmpty(sizePrice = {}) {
	return !Object.keys(sizePrice || {})?.length
}

function getFirstPrice(priceInfo = {}) {
	const priceInfoKeyList = Object.keys(priceInfo || {})
	return priceInfo[priceInfoKeyList[0]]
}

function getLastPrice(priceInfo = {}) {
	const priceInfoKeyList = Object.keys(priceInfo || {})
	if (!priceInfoKeyList?.length) return undefined
	const lastKey = priceInfoKeyList.pop() as string
	const lastPrice = priceInfo[lastKey]
	return lastPrice
}

/**
 * 获取 sales price
 */
function handleSalesPrice({
	priceInfo,
	whetherRateConverted,
	fractionDigits,
}) {
	let salesPrice: CurrentPrice = { discountedPrice: '', originalPrice: '' }
	const lastPrice = getLastPrice(priceInfo)
	if (!lastPrice) return salesPrice
	salesPrice = {
		discountedPrice: handlePriceConvert(lastPrice?.discounted_price || 0, whetherRateConverted, fractionDigits),
		originalPrice: handlePriceConvert(lastPrice?.original_price || 0, whetherRateConverted, fractionDigits),
	}
	return salesPrice
}

function handleFullPrice({
	priceInfo,
	whetherRateConverted,
	fractionDigits,
}) {
	let fullPrice: CurrentPrice = { discountedPrice: '', originalPrice: '' }
	const lastPrice = getLastPrice(priceInfo)
	if (!lastPrice) return fullPrice
	fullPrice = { 
		discountedPrice: handlePriceConvert(0, whetherRateConverted, fractionDigits),
		originalPrice: handlePriceConvert(lastPrice?.original_price || 0, whetherRateConverted, fractionDigits), 
	}
	return fullPrice
}

export function handleSizePriceList({
	sizePrice,
	availableSize = [],
	whetherRateConverted = false, 
	fractionDigits = 0,
	priceInfo = {},
}: {
	sizePrice: { [size: string]: { discounted_price: number; original_price: number; }};
	availableSize: string[];
	whetherRateConverted?: boolean; 
	fractionDigits?: number;
	priceInfo?: any;
}) {
	if (isSizePriceEmpty(sizePrice)) {
		const lastPrice = getLastPrice(priceInfo)
		const actualPrice = lastPrice?.discounted_price || lastPrice?.original_price || 0
		const originalPrice = lastPrice?.original_price
		const discount = (originalPrice - actualPrice) / originalPrice
		const discountPercentage = discount === 0 ? '' : (discount * 100).toFixed(1) + '%'
		const sizePriceList = availableSize.map(size => {
			return {
				size,
				actualPrice: handlePriceConvert(actualPrice, whetherRateConverted, fractionDigits),
				originalPrice: handlePriceConvert(originalPrice, whetherRateConverted, fractionDigits),
				discountPercentage,
			}
		})
		return sizePriceList
	}
	const sizePriceList = Object.keys(sizePrice).map(size => {
		const priceObj = sizePrice[size] || {}
		const originalPrice = priceObj?.original_price
		const actualPrice = priceObj?.discounted_price || originalPrice
		const discount = (originalPrice - actualPrice) / originalPrice
		const discountPercentage = discount === 0 ? '' : (discount * 100).toFixed(1) + '%'
		return {
			size,
			actualPrice: handlePriceConvert(actualPrice, whetherRateConverted, fractionDigits),
			originalPrice: handlePriceConvert(originalPrice, whetherRateConverted, fractionDigits),
			discountPercentage,
		}
	})
	return sizePriceList
}

export function handleSellerPerSize({
	sizePrice,
}) {
	if (isSizePriceEmpty(sizePrice)) {
		return []
	}
	const list = Object.keys(sizePrice).map(size => {
		return {
			size,
			shop: sizePrice[size].shop || '',
		}
	})
	return list
}

export function handleTotalSizePrice({
	sizePriceList = [],
	whetherRateConverted = false, 
	fractionDigits = 0,
	currency,
}: {
	sizePriceList: any[];
	whetherRateConverted?: boolean; 
	fractionDigits?: number;
	currency: string;
}) {
	if (!Array.isArray(sizePriceList) || !sizePriceList?.length) {
		return {
			fullPrice: '',
			salesPrice: '',
			discount: '',
		}
	}
	let totalFullPrice = 0
	let totalSalesPrice = 0
	let totalDiscount = 0
	sizePriceList.forEach(item => {
		totalFullPrice += String(Number(item?.originalPrice?.replace(',', ''))) !== 'NaN' ? Number(item?.originalPrice?.replace(',', '')) : 0
		totalSalesPrice += String(Number(item?.actualPrice?.replace(',', ''))) !== 'NaN' ? Number(item?.actualPrice?.replace(',', '')) : 0
		totalDiscount += String(Number(item?.discountPercentage?.replace('%', ''))) !== 'NaN' ? Number(item?.discountPercentage?.replace('%', '')) : 0
	})

	const fullPrice = handlePriceConvert(totalFullPrice / sizePriceList.length, whetherRateConverted, fractionDigits)
	const salesPrice = handlePriceConvert(totalSalesPrice / sizePriceList.length, whetherRateConverted, fractionDigits)
	const discount = totalDiscount / sizePriceList.length
	return {
		fullPrice: Number(fullPrice.replace(',', '')) ? `${fullPrice} ${currency}` : '',
		salesPrice: Number(salesPrice.replace(',', '')) ? `${salesPrice} ${currency}` : '',
		discount: Number(discount) ? discount.toFixed(1) + '%' : '',
	}
}

export function handleUrl({ 
	url, 
	priceInfo, 
	latestDate, 
	interpolate, 
}: { 
	url: string; 
	priceInfo: PriceInfo; 
	latestDate: string; 
	interpolate?: boolean; 
}) {
	let newUrl = urlWithoutRegion(url)
	let urlState = URL_STATE.valid
	if (IS_LIVE_ENV) {
		if (whetherOutProduct(priceInfo, latestDate)) {
			newUrl = 'Product discontinued, no URL available'
			urlState = URL_STATE.invalid
		} else {
			if (interpolate) {
				newUrl = 'Product unavailable, monitored for upcoming availability'
				urlState = URL_STATE.interpolated
			}	
		}
	}
	return {
		url: newUrl,
		urlState,
	}
}

export function getTitle(urlState) {
	if (urlState === URL_STATE.invalid) {
		return 'Last observed product information'
	}
	return 'Current product information'
}

export function getWhetherRateConverted({
	currency,
	originalCurrency,
}: {
	currency: string;
	originalCurrency: string;
}) {
	const customerVendor = storage.getCustomerVendor()
	let whetherRateConverted = currency !== originalCurrency
	whetherRateConverted = customerVendor === LICENSE_ZEEMAN ? false : whetherRateConverted
	return whetherRateConverted
}

/**
 * 处理 price history 线图数据
 */
export function handlePriceHistory(priceInfo: PriceInfo, latestDate: string, whetherRateConverted: boolean, fractionDigits: number): LineChartPoint[] {
	const dateList = Object.keys(priceInfo)
	if (dateList.length === 0) return []

	// 对象转数组
	let dataSource = dateList.map(date => {
		const price = Number(handlePriceConvert(priceInfo[date].discounted_price || priceInfo[date].original_price || 0, whetherRateConverted, fractionDigits).replace(/,/g, ''))
		return {
			x: date,
			y: price,
		}
	})
	// 补齐日期和价格
	dataSource = handlePadDate(dataSource, latestDate)
	return dataSource
}

/**
 * 处理 price history 线图数据
 */
export function handleFullPriceHistory(priceInfo: PriceInfo, latestDate: string, whetherRateConverted: boolean, fractionDigits: number): LineChartPoint[] {
	const dateList = Object.keys(priceInfo)
	if (dateList.length === 0) return []

	// 对象转数组
	let dataSource = dateList.map(date => {
		const price = Number(handlePriceConvert(priceInfo[date].original_price || 0, whetherRateConverted, fractionDigits).replace(/,/g, ''))
		return {
			x: date,
			y: price,
		}
	})
	// 补齐日期和价格
	dataSource = handlePadDate(dataSource, latestDate)
	return dataSource
}

/**
 * 处理 discount history 线图数据
 */
export function handleDiscountHistory(data: DiscountHistory, latestDate: string, whetherRateConverted: boolean, fractionDigits: number): LineChartPoint[] {
	let dataSource = Object.keys(data).map(date => ({
		x: date,
		y: Number(handlePriceConvert(data[date] * 100, whetherRateConverted, fractionDigits)?.replace(/,/g, '')),
	}))
	// 补齐日期和价格
	dataSource = handlePadDate(dataSource, latestDate)
	return dataSource
}

/**
 * 补齐日期及价格
 * 
 * 1. 如果接口返回的价格列表不是 latestDate(最近一天有产品的日期)，需要补齐后面的日期直到 latestDate，价格都为 0
 * 
 * ```
 * const dataSource = [{x: '2022-03-09', y: 1}]
 * const latestDate = '2022-03-10'
 * handlePadDate(dataSource, latestDate)	// => [{x: '2022-03-09', y: 1}, {x: '2022-03-10', y: 0}]
 * ```
 * 
 * 2. 实际发现中间也有可能会缺失日期和价格
 * 
 * [
 *  {x: '2021-12-01', y: 0},
 *  {x: '2021-12-04', y: 0},
 * ]
 * 
 * 需要将始末日期中间不存在的日期都给补齐
 * 
 * [
 *  {x: '2021-12-01', y: 0},
 *  {x: '2021-12-02', y: 0},
 *  {x: '2021-12-03', y: 0},
 *  {x: '2021-12-04', y: 0},
 * ]
 */
export function handlePadDate(dataSource, latestDate) {
	// 找 dataSource 最后一天的日期作为开始值
	if (dataSource.length === 0) return dataSource

	// 第一天日期
	const first = dataSource[0]
	const firstDate = first.x

	// 计算 startDate 与 latestDate 之间差几天
	// eslint-disable-next-line
	const days = (Date.parse(latestDate) - Date.parse(firstDate)) / (3600 * 24 * 1000)

	const newDataSource: { x: string; y: number }[] = []
	// eslint-disable-next-line
	for (let i = 0; i <= days; i++) {
		const d = moment(firstDate).add(i, 'days').format('YYYY-MM-DD')
		const y = dataSource.find(item => item.x === d)?.y || 0
		newDataSource.push({
			x: d,
			y,
		})
	}
	return newDataSource
}

/**
 * 格式化 TYPES 值
 * 
 * 取 properties 中以 ' type' 结尾的 key 的值
 */
export function handleNornaCategoryType(properties) {
	if (Object.prototype.toString.call(properties) !== '[object Object]') {
		return {}
	}

	const obj = {}
	Object.keys(properties)
		.filter(item => item.endsWith(' type'))     // 过滤只要 ' type' 结尾的 properties
		.forEach(item => {
			obj[item] = properties[item].join(', ')
		})
	return obj
}

/**
 * 格式化颜色值
 */
export function handleColor(peerProduct: PeerProduct, currentNornaid: string, projectObj?: ProductObj): ColorRecord[] {
	if (!peerProduct) return []
	let orderNum = 0
	return Object.keys(peerProduct).sort().map(nornaid => {
		/**
		 * 商品对应 NornaCategory 在 WhiteCategory 中，显示白色点
		 */
		let inWhiteList = !projectObj ? false : WhiteCategory.indexOf(projectObj?.[nornaid]?.norna_category ?? '') > -1

		if (inWhiteList && !IS_LIVE_ENV) inWhiteList = false

		if (inWhiteList) orderNum += 1
		else if (peerProduct[nornaid]?.length === 0) { // empty colors
			orderNum += 1
			inWhiteList = true
		}
		
		return {
			nornaid,
			isSelected: currentNornaid === nornaid,
			sortOrder: inWhiteList ? orderNum : 0, // 排序号
			colors: [ peerProduct[nornaid] ].map((item: any) => {
				let color
				if (inWhiteList) {
					color = 'rgba(255, 255, 255, 1)'
				} else if (Array.isArray(item.rgb_color)) {
					color = 'rgba(' + item.rgb_color.join(',') + ',1)'
				} else {
					color = item.rgb_color
				}
				return {
					id: Math.random(),
					color,		// 颜色值 
					v: inWhiteList ? 1 : item.quantity,
					nornaid,
					name: item.name,	// 颜色名称 Blue
				}
			}),
		}
	})
}

/**
 * 格式化 Material 数据
 * 
 * - 数值加百分号
 * - Material 如果是 'Material AI model in progress!'，值设置为空字符串
 * 
 * ```
 * const materialComposition = {"Cotton":100, "Material AI model in progress!": 100}
 * formatMaterial(materialComposition)  // => {"Cotton": "100%", "Material AI model in progress!": ""}
 * ```
 */
export function handleMaterial(materialComposition) {
	const newMaterialObj = {}
	Object.keys(materialComposition).forEach(item => {
		if (item === 'Material AI model in progress!') {
			newMaterialObj[item] = ''
		} else {
			newMaterialObj[item] = materialComposition[item]?.toString().concat('%')
		}
	})
	return newMaterialObj
}

/**
 * 处理 property
 */
export function handleProperty(properties, notShowSubGroupsMap = {}): { key: string, value: string[] }[] {
	const customerVendor = storage.getCustomerVendor()
	const newProperties: { key: string, value: string[] }[] = []
	/**
	 * 隐藏属性，这个列表里的 property 在页面不显示
	 * 
	 * 2022/02/28 新需求，Activity 在 live 环境不要，test 和 staging（非 live 环境）要
	 * 有 Activity 的产品地址: https://www.hunkemoller.de/langarm-pyjamatop-gruen-191810.html
	 * 
	 * 2022/05/02 新需求, live 环境, bogner license 可以显示以下 activity
	 */
	const hidePropertyList = [ 'Activity' ]
	Object.keys(properties || {}).forEach((key: string) => {
		// LIVE 环境需要排除 hidePropertyList 数组中的 key
		if (IS_LIVE_ENV) {
			/**
			 * live 环境, bogner license 可以显示以下 activity
			 * 测试地址: https://www.bogner.com/de-at/ski-latzhose-curt-rot-38251.html?nornaRegionInfo=Austria
			 */
			if (customerVendor === LICENSE_BOGNER && String(key).toLowerCase() === 'activity') {
				const showActivityList = [
					'Ballet', 'Biking', 'Climbing', 'Football', 'Golf', 'Gym', 'Hiking', 'Hunting',
					'Loungewear', 'Mountain', 'Pilates', 'Running', 'Sailing', 'Skiing', 'Sports',
					'Swimming', 'Tennis', 'Training', 'Trekking', 'Yoga',
				]
				let activityList = properties[key]
				activityList = activityList.filter(item => showActivityList.includes(item))
				if (activityList.length > 0) {
					newProperties.push({
						key,
						value: activityList,
					})
				}
			} else
				// key 在 hidePropertyList 里就不添加到 properties 里
				if (!hidePropertyList.map(item => String(item).toLowerCase()).includes(String(key).toLowerCase())) {
					newProperties.push({
						key,
						value: properties[key],
					})
				}
		} else {
			// 非 LIVE 环境，所有 key 都添加到 properties 中
			newProperties.push({
				key,
				value: properties[key],
			})
		}
	})

	/**
	 * 2022/04/20
	 * 只处理 Fabrics property
	 * {"Jersey Knit": ["Jersey"]}
	 * 有些 Property 在 Filter > Property 中只显示一级 property，不显示二级 property，但在传参时传的是二级 property
	 * 比如 Filter > Property > Fabrics 中只显示一级属性的 Jersey Knit，但是传参时传的实际是二级属性 Jersey
	 * 在 Product Card 中查询数据接口返回的是二级属性 Jersey，所以目前 Product Card 页面显示的 Fabrics 值是 Jersey
	 * 导致的问题: Product Card > Fabrics 和 Filter > Property > Fabrics 的值不一致
	 * 需求: 做个映射，让二者值保持一致
	 */
	// property 中包含 Fabrics
	if (newProperties.findIndex(item => item.key === 'Fabrics') !== -1) {
		// 找到当前 fabrics 对应的值
		let value = newProperties.find(item => item.key === 'Fabrics')?.value || []
		value = value?.map(item => {
			let newValue = item
			Object.keys(notShowSubGroupsMap).forEach(item2 => {
				if (notShowSubGroupsMap[item2].includes(item)) {
					newValue = item2
				}
			})
			return newValue
		})
		newProperties.forEach(item2 => {
			if (item2.key === 'Fabrics') {
				// 规则1: 要去重
				value = Array.from(new Set(value))
				// 规则2: 'No fabric extracted' 只有在没有其他fabric存在的时候显示, 如果有其他fabric  就过滤掉不显示它
				// 测试链接: https://www.bimbaylola.com/es_en/221BBBB5T.T2050.html
				if (value.length > 1 && value.includes('No fabric extracted')) {
					value = value.filter(item => item !== 'No fabric extracted')
				}
				item2.value = value
			}
		})
	}

	return newProperties.filter(item => ![ 'Sizes', 'Colors', 'Currency', 'Material' ].includes(item.key))
		.filter(item => !item.key.toString().endsWith('type'))
}

/**
 * 处理 size
 * 
 * availableSizes 和 unavailableSizes 进行合并然后排序
 * 排序规则：数值字符串按数值大小排序，非数值字符串按字符排序
 */
export function handleSize(data: string[]) {
	let newData = [ ...data ].filter(item => item)
	// 数组中每一项都是数值字符串
	const isNumberArray = newData.every(item => Number(item).toString() !== 'NaN')

	if (isNumberArray) {
		newData = data.map(item => Number(item)).sort((m, n) => m - n).map(item => item.toString())
	} else {
		newData = sortBy(newData)
	}
	return newData
}

/**
 * urlWithoutRegion
 * 
 * ```
 * urlWithoutRegion('123')  // => '123'
 * urlWithoutRegion('123?nornaRegionInfo=Sweden')  // => '123'
 * ```
 */
export function urlWithoutRegion(url = ''): string {
	const nornaRegionIndex = url.indexOf('nornaRegionInfo=')
	if (nornaRegionIndex !== -1) {
		// Clearing the nornaRegionInfo query parameter from the URL
		return url.substring(0, nornaRegionIndex - 1)
	}
	return url
}

/**
 * 产品是否已经 out
 * 
 * priceInfo: 接口返回的 price_info 字段
 * latestDate: 最近一天产品有数据的日期
 * 
 * 判断条件：latestDate 这一天的 discounted_price 价格和 original_price 价格都是 0 或不存在时说明是 out 产品
 */
export function whetherOutProduct(priceInfo: PriceInfo = {}, latestDate = ''): boolean {
	const discountedPrice = priceInfo?.[latestDate]?.discounted_price
	const originalPrice = priceInfo?.[latestDate]?.original_price
	return (discountedPrice || originalPrice || 0) === 0
}

/**
 * 处理价格格式问题
 * 
 * whetherRateConverted=true，说明做了币种转换，此时价格要做向上取整并每3位数字用逗号隔开
 * whetherRateConverted=false，说明未做币种转换，此时价格要保留2位小数并每3位数字用逗号隔开
 */
export function handlePriceConvert(price: number | null, whetherRateConverted: boolean, fractionDigits: number): string {
	return numberUtils.formatNumberByComma(Number(price).toFixed(2))
}

/**
 * 处理日期格式问题
 * 
 * ``` 
 * const dateStr = '2022-03-11'
 * handleDateConvert(dateStr)	// => '2022/03/11'
 * ```
 */
export function handleDateConvert(dateStr = '', separator = '/'): string {
	if (!dateStr) return ''
	return dateStr.replace(/-/g, separator)
}

/**
 * { Black: 0.7, White: 0.3 }
 * ->
 * 'Black: 70%; White: 30%'
 */
export function formatColorObj(colorObj = {}) {
	// 转数组
	return Object.keys(colorObj)
		.map(color => ({
			key: color,
			value: numberUtils.formatNumber(colorObj[color], { isCentuple: true, isPercentSymbol: true, decimal: 0 }),
		}))
		.map(item => `${item.key}: ${item.value}`)
		.join('; ')
}
