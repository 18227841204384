export const width01 = 62
export const width02 = 72

export const tabWidth = 155
export const optionsWidth = 100
export const categoryWidth = 195

export const categorySplitValueWidth = width01
export const categorySplitChangeWidth = width02
export const sizeOfLineValueWidth = width01
export const sizeOfLineChangeWidth = width02
export const sizeRatioValueWidth = width01
export const sizeRatioChangePercentWidth = width02
export const optionRatioValueWidth = width01
export const optionRatioChangePercentWidth = width02
export const soldOutValueWidth = width01
export const soldOutChangeWidth = width02
export const ecoLabelValueWidth = width01
export const ecoLabelChangeWidth = width02
export const solidPatternValueWidth = width01
export const solidPatternChangeWidth = width02

export const categorySplitWidth = categorySplitValueWidth + categorySplitChangeWidth
export const sizeOfLineWidth = sizeOfLineValueWidth + sizeOfLineChangeWidth
export const sizeRatioWidth = sizeRatioValueWidth + sizeRatioChangePercentWidth
export const optionRatioWidth = optionRatioValueWidth + optionRatioChangePercentWidth
export const soldOutWidth = soldOutValueWidth + soldOutChangeWidth
export const ecoLabelWidth = ecoLabelValueWidth + ecoLabelChangeWidth
export const solidWidth = solidPatternValueWidth + solidPatternChangeWidth
