import React, { CSSProperties, ReactNode, useLayoutEffect, useRef } from 'react'
import { CloseOutlined } from 'druikit'
import styles from './styles.module.scss'
import classNames from 'classnames';
import { createPortal } from 'react-dom';

const Modal = ({
    style,
    className = '',
    centered = true,
    resizable = true,  // 是否可改变大小
    title,
    headerStyle,
    headerClassName,
    top,
    left,
    children,
    onCancel,
}: {
    style?: CSSProperties;
    className?: string;
    centered?: boolean;
    resizable?: boolean;
    title?: ReactNode;
    headerStyle?: CSSProperties;
    headerClassName?: string;
    top?: number;
    left?: number;
    children?: ReactNode;
    onCancel?: () => void;
}) => {
    const contentRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        const drag = contentRef.current
        const headerEl = headerRef.current
        if (!drag || !headerEl) return

        if (centered) {
            const { width, height } = drag.getBoundingClientRect()
            drag.style.left = left !== undefined ? left + 'px' : (window.innerWidth - width) / 2 + 'px'
            drag.style.top = typeof top !== 'undefined' ? top + 'px' : (window.innerHeight - height) / 2 + 'px'
        }

        const handleMousedown = (event) => {
			const x = event.clientX - drag.offsetLeft
			const y = event.clientY - drag.offsetTop
			document.onmousemove = function(event) {
				event.preventDefault()
				let moveX = event.clientX - x;
				let moveY = event.clientY - y;
				if (moveX < 0) {
					moveX = 0
				} else if (moveX > window.innerWidth - drag.offsetWidth) {
					moveX = window.innerWidth - drag.offsetWidth
				}
				if (moveY < 0) {
					moveY = 0
				} else if (moveY > window.innerHeight - drag.offsetHeight) {
					moveY = window.innerHeight - drag.offsetHeight
				}
				drag.style.left = moveX + 'px'
                drag.style.top = moveY + 'px'
			}
			document.onmouseup = function(event) {
				// 多次点击会注册多次事件造成内存泄漏
				document.onmousemove = null;
				document.onmouseup = null;
			}
        }

        headerEl.addEventListener('mousedown', handleMousedown)

        return () => {
            if (!drag || !headerEl) return
            headerEl.removeEventListener('mousedown', handleMousedown)
        }
    }, [ contentRef.current ])

    return createPortal((
        <div className={styles.mdoalRoot}>
            <div
                ref={contentRef}
                style={{ ...style }} 
                className={classNames({
                    [styles.modalContent]: true, 
                    [styles.modalContentResize]: resizable,
                    [className]: true,
                })}
            >
                <div
                    ref={headerRef}
                    style={headerStyle}
                    className={classNames([ styles.modalHeader, headerClassName ])}
                >
                    <div style={{ flex: 1 }}>
                        {title}
                    </div>
                    <div onClick={onCancel}>
                        <CloseOutlined />
                    </div>
                </div>
                <div className={styles.modalBody}>
                    {children}
                </div>
            </div>
        </div>
    ), document.body)
}

export default Modal
