import React, { FC, ReactNode, memo, useRef, useLayoutEffect, useState, useDeferredValue } from 'react'
import { Doughnut } from 'libs/react-chartjs-lib'
import { PieChartProps, PieItemProps } from './types'
import styles from './styles.module.scss'
import { throttle } from 'lodash'
import { ClockIcon } from 'assets/icons'
import { usePageDate } from 'hooks/usePageDate'
import { formatterDateToEng, getFractionDigits, regionShortMappingFn, vendorShortMappingFn } from 'utils'
import classNames from 'classnames'
import { useFilterCountry } from 'hooks/useFilter'
import { numberUtils } from 'norna-uikit'
import { OPTIONS_NAME } from 'consts'

/**
 * ## PieChart
 *
 * 业务组件，Color / Category / Material Analytics 页面中使用。
 *
 * 根据数据源渲染饼图。
 *
 * ## 参数说明
 *
 * ### dataSource
 *
 * ```
 * // backgroundColor、borderColor、data、label 几个数组中相同索引的值表示一个扇形相关数据
 * // e.g backgroundColor[0]、borderColor[0]、data[0]、label[0]  表示第一个扇形相关数据
 * // e.g backgroundColor[1]、borderColor[1]、data[1]、label[1]  表示第二个扇形相关数据
 * dataSource = {
 *      datasets: [
 *          {
 *              backgroundColor: ['#fff'],  // 扇形背景颜色
 *              borderColor: ['#fff'],      // 扇形边框颜色
 *              borderWidth: 1,             // 扇形边框宽度
 *              data: [12.234],             // 扇形对应的数据值
 *              label: ['White'],           // 扇形对应的标签名
 *          }
 *      ]
 * }
 * ```
 *
 * 注意事项：datasets 中几个属性的数组长度需要一致。
 *
 * ### onElementsClick
 *
 * 点击饼图上的扇形时触发该事件，onElementsClick(item, index, dataset)
 *
 * - item 点击那个扇形的相关数据
 *
 * ```
 * item = {
 *      backgroundColor: '#4186B8',
 *      borderColor: 'rgba(255, 255, 255, 1)',
 *      data: 32.17138400739536,
 *      label: 'Blue'
 * }
 * ```
 *
 * - index 点击第几个扇形
 * - dataset 整个扇形的数据源
 */
export const SplitPieChart: FC<PieChartProps> = memo(({
    dataSource,
    queryDateValid = true,
    onElementsClick,
    isLock,
    pieList,
    vendorCode = '',
}) => {
    const tooltipClassName = `${vendorCode}-pieChartTooltip`.replaceAll('@', '_').replaceAll(' ', '_')
    const tooltipSelector = `.${tooltipClassName}`
    const pieWrapperRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (!pieWrapperRef.current) return
        const chart = pieWrapperRef.current.querySelector('canvas')
        if (!chart) return

        const handleStyle = e => {
            const box = chart.getBoundingClientRect()
            // eslint-disable-next-line
            const x = box.left + box.width / 2
            // eslint-disable-next-line
            const y = box.top + box.height / 2
            const radius = 140
            
            const distance = calcDistanceOfPointAndPoint({ x,y }, { x:e.x, y:e.y })
            // 锁住状态时, 鼠标悬浮不要出现手的图标
            if (distance <= radius && !isLock) {
                chart.style.cursor = 'pointer'
            } else {
                chart.style.cursor = 'auto'
            }
        }
        
        chart.addEventListener('mousemove', handleStyle)

        return () => {
            chart.removeEventListener('mousemove', handleStyle)
        }
    }, [])

    const [ filterCountry ] = useFilterCountry()

    const onShowPieTooltip = ({
        left,
        top,
        label,
        backgroundColor,
        percent,
        averagePrice = 20,
        count = 20,
    }) => {
        const tooltipEl = document.querySelector(tooltipSelector) as HTMLDivElement
        if (!tooltipEl) return
        
        const percentEl = tooltipEl.querySelector('[class*=numberFont]') as HTMLDivElement
        percentEl.innerText = percent

        const dotEl = tooltipEl.querySelector('[class*=dot]') as HTMLDivElement
        dotEl.style.backgroundColor = backgroundColor

        const labelEl = tooltipEl.querySelector('[class*=name]') as HTMLDivElement
        labelEl.title = label
        labelEl.innerText = label

        const regionEl = tooltipEl.querySelector('[class*=regionText]') as HTMLDivElement
        regionEl.title = filterCountry
        regionEl.innerText = filterCountry

        const avgPrice = typeof averagePrice === 'undefined' ? '-' : numberUtils.formatNumberByComma(averagePrice.toFixed(getFractionDigits()))
        const avgPriceEl = tooltipEl.querySelector('[class*=averagePriceText]') as HTMLDivElement
        avgPriceEl.innerText = `Average price: ${avgPrice}`

        const optionsEl = tooltipEl.querySelector('[class*=options]') as HTMLDivElement
        optionsEl.innerText = `${OPTIONS_NAME}: ${numberUtils.formatNumberByComma(count)}`

        tooltipEl.style.top = `${top}px`
        tooltipEl.style.left = `${left}px`
    }

    const onOutsideClick = () => {
        const tooltipEl = document.querySelector(tooltipSelector) as HTMLDivElement
        if (tooltipEl) {
            tooltipEl.style.top = '-200px'
            tooltipEl.style.left = '-200px'
        }
    }

    const { pageDate: dateRangeValue } = usePageDate()

    let body: ReactNode
    if (isLock) {
        body = (
            <>
                <Doughnut
                    data={dataSource}
                    options={{
                        tooltips: {
                            display: false,
                            enabled: false,
                        },
                        position: 'center',
                        responsive: true,
                        maintainAspectRatio: false,
                        cutoutPercentage: 6,
                        title: { display: false },
                        animation: {
                            duration: 1,
                        },
                        legend: { display: false },
                        scales: {
                            ticks: 5,
                        },
                    }}
                />
                <div className={styles.smallCover} />
                <div className={styles.mediumCover} />
                <div className={styles.bigCover} />
                <div className={styles.borderCover} />
            </>
        )
    }
    else if (Array.isArray(dataSource?.datasets[0]?.data) && dataSource.datasets[0].data.length && queryDateValid) {
        body = (
            <>
                <Doughnut
                    data={dataSource}
                    onElementsClick={elem => {
                        /**
                         * 对于饼图而言，页面上看到的是个圆，这个圆是绘制在 Canvas 画布上的
                         * Canvas 画布是方形的，饼图在 Canvas 正中间，Canvas 四个角落是空白的
                         * 点击 Canvas 四个角落，也会触发 onElementsClick 事件，此时 elem = []
                         * 点击饼图上任一扇形区域，elem 为扇形对应的元素 elem = [ChartElement]
                         */
                        if (elem.length > 0) {
                            // 整个饼图的数据
                            // eslint-disable-next-line
                            const dataset = dataSource.datasets[elem[0]._datasetIndex]
                            // 点击饼图上第几个扇形，索引从 0 开始
                            // eslint-disable-next-line
                            const index: number = elem[0]._index
                            // 点击的那个扇形相关数据
                            const currentItem: PieItemProps = {
                                backgroundColor: dataset.backgroundColor[index],
                                borderColor: dataset.borderColor[index],
                                data: dataset.data[index],
                                label: dataset.label[index],
                            }
                            // eslint-disable-next-line
                            onElementsClick?.(currentItem, elem[0]._index, dataset)
                            /**
                             * 鼠标悬浮在饼图上就会出现 tooltip
                             * 当点击饼图出现 ProductModal 是希望把 tooltip 给关掉的
                             * 这里的 onOutsideClick() 就是关掉 tooltip 作用
                             */
                            onOutsideClick?.()
                        }
                    }}
                    options={{
                        tooltips: {
                            display: false,
                            enabled: false,
                        },
                        position: 'center',
                        responsive: true,
                        maintainAspectRatio: false,
                        cutoutPercentage: 6,
                        title: { display: false },
                        animation: {
                            duration: 1,
                        },
                        legend: { display: false },
                        scales: {
                            ticks: 5,
                        },
                        events: [ 'click', 'mousemove', 'mouseout' ],
                        onHover: (event, item) => {
                            if (item.length > 0) {
                                // 整个饼图的数据
                                // eslint-disable-next-line
                                const dataset = dataSource.datasets[item[0]._datasetIndex]
                                // 点击饼图上第几个扇形，索引从 0 开始
                                // eslint-disable-next-line
                                const index: number = item[0]._index
                                onShowPieTooltip({
                                    left: event.clientX - 110,
                                    top: event.clientY + 40,
                                    percent: dataset.data[index].toFixed(1) + '%',
                                    label: vendorShortMappingFn(dataset.label[index]),
                                    backgroundColor: dataset.backgroundColor[index],
                                    count: pieList[index]?.countCurrentValue || 0,
                                    averagePrice: pieList[index]?.averagePriceCurrentValue || '-',
                                })
                            } else {
                                /**
                                 * 对于饼图而言，页面上看到的是个圆，这个圆是绘制在 Canvas 画布上的
                                 * Canvas 画布是方形的，饼图在 Canvas 正中间，Canvas 四个角落是空白的
                                 * 鼠标所在点距离饼图圆心距离大于半径, 说明鼠标Canvas四个角落（在圆外面）, 需要关闭 tooltip
                                 */
                                const chart = pieWrapperRef.current?.querySelector('canvas')
                                if (!chart) return
                                const box = chart.getBoundingClientRect()
                                // eslint-disable-next-line
                                const x = box.left + box.width / 2
                                // eslint-disable-next-line
                                const y = box.top + box.height / 2
                                const radius = 140
                                const distance = calcDistanceOfPointAndPoint({ x,y }, { x:event.x, y:event.y })
                                if (distance > radius) {
                                    onOutsideClick?.()
                                }
                            }
                        },
                    }}
                />
                <div className={styles.smallCover} />
                <div className={styles.mediumCover} />
                <div className={styles.bigCover} />
                <div className={styles.borderCover} />
            </>
        )
    } else {
        body = <div className={styles.noData}>Currently not applicable</div>
    }

    return (
        <div 
            className={styles.pieChart}
            onMouseLeave={() => {
                onOutsideClick?.()
            }}
            ref={pieWrapperRef}
        >
            {body}
            <div 
                className={classNames({ [tooltipClassName]: true, [ styles.pieModal ]: true })} 
                onClick={e => e.stopPropagation()}
            >
                <div className={styles.title}>
                    <ClockIcon />
                    {dateRangeValue && formatterDateToEng(dateRangeValue.split('_'))}
                </div>
                <div className={styles.secound}>
                    <div className={styles.numberFont}></div>
                    <div className={styles.dot} style={{ backgroundColor: '' }} />
                    <div className={styles.name}></div>
                </div>
                <div className={styles.category} style={{ marginTop: '16px' }}>
                    PRODUCT DETAILS
                </div>
                <div className={styles.category}>
                    <div className={styles.regionText}></div>
                </div>
                <div className={styles.category}>
                    <div className={styles.averagePriceText}>
                    </div>
                </div>
                <div className={styles.options}>
                </div>
            </div>
        </div>
    )
})

/**
 * 计算两点之间的距离
 */
 export function calcDistanceOfPointAndPoint(p1: {x: number, y: number}, p2: {x: number; y:number}) {
     // eslint-disable-next-line
    const distance = Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
    return distance
}
