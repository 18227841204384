import React, { CSSProperties, ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { Box, Card, Divider, Flex, Spin, Text } from 'druikit'
import styles from './styles.module.scss'
import { MyBarChart } from '../MyCharts'
import { colors } from '../MyCharts/utils'
import { SizedBox } from 'componentsv2/SizedBox'
import { handleName } from 'pages/DynamicDashboard/utils'
import { Tooltip } from 'antd'
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined'
import { getVendorNameByCode } from 'utils'
import { numberUtils } from 'norna-uikit'

export const MetricBarChartCard = ({
    style,
    className,
    title,
    description,
    loading = false,
    dataSource = [],
    yAxisFormatter,
}: {
    style?: CSSProperties;
    className?: string;
    title?: string | ReactNode; 
    description?: string | ReactNode;
    loading?: boolean;
    dataSource: any[];
    yAxisFormatter?: (value: number) => string;
}) => {
    /* ************************* 图表宽度 ******************************** */
    const divRef = useRef<HTMLDivElement>(null)
    const [ chartWidth, setChartWidth ] = useState(400)

    useLayoutEffect(() => {
        const wrapperEl = divRef.current
        if (!wrapperEl) return
        const rect = wrapperEl.getBoundingClientRect()
        setChartWidth(rect.width)
    }, [])

    return (
        <Spin spinning={loading} style={{ marginBottom: 34, ...style }} className={className}>
            <Flex style={{ marginBottom: 10, alignItems: 'center' }}>
                <div className={styles.cardTitle}>{title}</div>
                {
                    description ? (
                        <Tooltip 
                            title={description} 
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0}
                            zIndex={9999992}
                            placement="top"
                        >
                            <div className={styles.descTooltip}>
                                <InfoOutlined
                                    size={16}
                                    color="#bbb"
                                />
                            </div>
                        </Tooltip>
                    ) : null
                }
            </Flex>
            <Card fringed={false} height={330} style={{ paddingLeft: 33, paddingRight: 28 }}>
                <div ref={divRef} style={{ width: '100%' }}>
                    <Flex justifyContent="flex-end" alignItems="flex-end" height={38}>
                        {
                            dataSource.map((item, index) => {
                                const color = colors[index]
                                return (
                                    <Flex key={item.label} alignItems="center" style={{ marginRight: 10 }}>
                                        <Box width={12} height={12} backgroundColor={color} style={{ borderRadius: 12 }} />
                                        <Box width={6} />
                                        <Text fontSize12 color666>{handleName(getVendorNameByCode(item.label))}</Text>
                                    </Flex>
                                )
                            })
                        }
                    </Flex>
                    <Divider length="100%" />
                    <SizedBox height={10} />
                    <MyBarChart
                        width={chartWidth}
                        dataSource={dataSource}
                        yAxisFormatter={yAxisFormatter}
                    />
                </div>
            </Card>
        </Spin>
    )
}

export const percentFormat = (value) => {
    return numberUtils.formatNumber(value, { isCommaSymbol: true, isPercentSymbol: true, decimal: 1 })
}
