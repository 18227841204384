import { getVendorNameByCode } from 'utils'

/**
 * 生成 csv 格式的字符串
 */
export function geneCsvData({
    dataSource,
  }) {
    if (!dataSource?.length) return ''
    const data: any[] = []

    let dateList = dataSource?.[0]?.dailyProductsInOut?.map(item => item?.date)
    if (!dateList?.length) return

    dateList = dateList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    const titleRow = [
        'Competitors',	
        'Total option in',	
        'Total option out',
        ...dateList.map(item => ([ 'Day', 'Option in', 'Option out' ])).flat(2),
    ]
    data.push(titleRow)
  
    const handleRowData = (item) => {
        return [
            getVendorNameByCode(item?.vendor),
            item?.totalProductsIn,
            item?.totalProductsOut,
            ...dateList.map(date => {
                const dateData = item.dailyProductsInOut.find(item => item.date === date)
                return [
                    dateData?.date,
                    dateData?.productsIn,
                    dateData?.productsOut,
                ]
            }).flat(2),
        ]
    }
  
    dataSource.forEach(item => {
        const dataRow = handleRowData(item)
        data.push(dataRow)
    })
  
    const csvString = data.map(data => data.join(',')).join('\r\n');
    return csvString
}
