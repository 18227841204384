import React from 'react'
import { ProductVo } from '../../types';
import { Collapse } from '../Collapse';
import { ProductSizes } from '../ProductSizes';
import { getDisplaySize } from './utils';

const HarmonizedSizeRanges = ({
    product,
}: {
    product?: ProductVo;
}) => {
    if (!product?.allowHarmonizingSize) return null
    
    return (
        <Collapse title="Harmonized size ranges" showArrow={false} style={{ marginBlock: 20 }}>
            <ProductSizes 
                sizeList={product?.totalHarmonizingSizes?.map(size => getDisplaySize(size))} 
                isHarmonizingSize
            />
        </Collapse>
    )
}

export default HarmonizedSizeRanges
