import { useEffect, useState } from 'react'
import { useApiData, useCategoryValue, useCompetitorOptions, useCompetitorValue, useFetchPayload, useModalRequests, useApiLoading, useDynamicDashboardRequests, usePovValue, useExportPdfLoadingObj } from './useDynamicDashbordData'
import { useFetch } from 'libs'
import { isEqual, uniq } from 'lodash'
import { getCategoriesPayload, getCompetitorPayload, getGroupsPayload, getMarketPayload, getTargetGroupPayload } from 'utils/queryPayload'
import { DISABLE_CANCEL_REQUEST, MARKET_VENDOR, SELECTED_CATEGORY, SELECTED_VENDORS } from 'consts'
import { storage } from 'utils/storage'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getLeafCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { useFilterCountry, useFilterCurrency, useFilterGender } from 'hooks/useFilter'
import { useCalendarComparisonDate, useCalendarDate } from 'hooks/useGlobalData'

const getCompetitorVendors = (povValue, competitorValue) => {
    return uniq([ povValue, ...competitorValue, competitorValue.length > 1 ? SELECTED_VENDORS : '' ]).filter(item => item)
}

/**
 * 首页查询接口
 */
export const useDashboardData = () => {
    const [ competitorOptions ] = useCompetitorOptions()
    const [ competitorValue ] = useCompetitorValue()
    const [ povValue ] = usePovValue()
    const [ regionValue ] = useFilterCountry()
    const [ currencyValue ] = useFilterCurrency()
    const [ genderValue ] = useFilterGender()
    const [ categoryValue ] = useCategoryValue()
    const [ , setApiData ] = useApiData()
    const [ , setApiLoading ] = useApiLoading()
    const [ , setFetchPayload ] = useFetchPayload()
    const dateObj = useDateObj()

    const { postFn: fetch, data, loading } = useFetch()

    const fetchData = async () => {
        if (!competitorValue.length || !dateObj?.date) return
        const customerVendor = storage.getCustomerVendor()
        const competitorVendors = getCompetitorVendors(povValue, competitorValue)
        const competitorSellers = competitorVendors.map(vendor => ({ vendor, region: regionValue }))

        const market = getMarketPayload({
            competitorOptions: [ 
                ...competitorOptions.map(c => ({ vendor: c.key, region: regionValue })), 
            ],
            competitorSellers: [ { vendor: MARKET_VENDOR.vendor, region: regionValue } ],
            returnType: 'vendor',
        })

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers.filter(item => item.vendor !== povValue), { vendor: SELECTED_VENDORS, region: regionValue } ],
        })

        let competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        competitors = [ povValue, ...competitors.filter(item => item !== povValue) ]

        const target = getTargetGroupPayload({ targetGroups: genderValue, returnType: 'array' })
        const categories = getLeafCategories({ selectedCategories: categoryValue })

        const payload = {
            url: 'dashboard/comparison',
            query: {
              customer: customerVendor,
              region: regionValue,
              currency: currencyValue,
              date: dateObj.date,
              comparison_date: dateObj.comparisonDate,
              metric: 'Dynamic',
            },
            data: {
              target_group: target,
              competitors,
              market,
              groups,
              all_category: getCategoriesPayload(categories),
            },
        }

        setApiLoading(true)
        const result = await fetch(payload.url, payload)
        setApiData(result?.data)
        setApiLoading(false)
        setFetchPayload(payload)
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ dateObj, competitorOptions, competitorValue, povValue, regionValue, currencyValue, genderValue, categoryValue ])

    return {
        fetchData,
        data: data,
        loading,
    }
}

/**
 * 修改 setting 接口
 */
export const useModifySettings = () => {
    const { postFn: fetch, loading } = useFetch()

    const modifySettings = async (data: any) => {
        const payload = {
            url: '/dynamic_dashboard_settings',
            data,  
        }
        const result = await fetch(payload.url, payload)
        return result?.data || {}
    }

    return {
        modifySettings,
        loading,
    }
}

/**
 * 查询 category - logo mapper
 */
export const useFetchMetaImages = () => {
    const { getFn } = useFetch()

    const fetchMetaImages = async () => {
        const metaStructureId = storage.getMetaStructureId()
        const url = '/meta_structure/images'
        const result = await getFn(url, { meta_structure_id: metaStructureId })
        return result?.data || {}
    }

    useEffect(() => {
        fetchMetaImages()
    }, [])

    return {
        fetchMetaImages,
    }
}

/**
 * Product newness 详情页面柱状图
 */
export const useFetchWeeklyNewness = () => {
    const [ competitorOptions ] = useCompetitorOptions()
    const [ competitorValue ] = useCompetitorValue()
    const [ povValue ] = usePovValue()
    const [ regionValue ] = useFilterCountry()
    const [ currencyValue ] = useFilterCurrency()
    const [ genderValue ] = useFilterGender()
    const [ categoryValue ] = useCategoryValue()
    const [ , setModalRequests ] = useModalRequests()
    const dateObj = useDateObj()
    const [ requests, setRequests ] = useDynamicDashboardRequests()
    const [ , setExportPdfLoading ] = useExportPdfLoadingObj()
    const { postFn: fetch } = useFetch()

    const fetchWeeklyNewness = async () => {
        if (!dateObj?.date || !categoryValue.length) return

        const url = '/dashboard/weekly_newness'
        const customerVendor = storage.getCustomerVendor()
        const target = getTargetGroupPayload({ targetGroups: genderValue, returnType: 'array' })
        const categories = getLeafCategories({ selectedCategories: categoryValue })

        const competitorVendors = [ ...competitorValue, povValue, MARKET_VENDOR.vendor, SELECTED_VENDORS ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: regionValue }))

        const competitors = getCompetitorPayload({
            competitorSellers,
            returnType: 'vendor',
            isDashboard: true,
        }) as string[]

        const market = getMarketPayload({
            competitorSellers: [ ...competitorSellers ],
            competitorOptions: [ 
                ...competitorOptions.map(c => ({ vendor: c.key, region: regionValue })), 
            ],
            returnType: 'vendor',
        })

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers ].filter(seller => seller.vendor !== povValue),
        })

        const payload = {
            url,
            query: {
              customer: customerVendor,
              region: regionValue,
              currency: currencyValue,
              category: SELECTED_CATEGORY,
              date: dateObj.date,
              metric: '',
              vendor: '',
            },
            data: {
              target_group: target,
              competitors,
              market,
              groups,
              all_category: getCategoriesPayload(categories),
            },
        }

        setExportPdfLoading({ product_newness_graph: true })
        if (isEqual(payload, requests.newnessWeeklyApiPayload)) {
            setExportPdfLoading({ product_newness_graph: false })
            return
        }
        
        setModalRequests({ [url]: true })
        setRequests({ 
            newnessProductsApiData: { customerVendorProducts: [], comparisonVendorProducts: [] },
            newnessWeeklyApiLoading: true,
        })
        const result = await fetch(payload.url, payload)
        const data = result?.data
        setModalRequests({ [url]: false })
        setRequests({ 
            newnessWeeklyApiData: data,
            newnessWeeklyApiPayload: payload,
            newnessWeeklyApiLoading: false,
        })
        setExportPdfLoading({ product_newness_graph: false })
    }

    useDeepCompareEffect(() => {
        fetchWeeklyNewness()
    }, [ dateObj, competitorOptions, competitorValue, povValue, regionValue, currencyValue, genderValue, categoryValue ])

    return {
        fetchWeeklyNewness,
    }
}

/**
 * Product newness 详情页面产品卡片
 */
export const useFetchProductNewnessIds = () => {
    const [ competitorOptions ] = useCompetitorOptions()
    const [ competitorValue ] = useCompetitorValue()
    const [ povValue ] = usePovValue()
    const [ regionValue ] = useFilterCountry()
    const [ currencyValue ] = useFilterCurrency()
    const [ genderValue ] = useFilterGender()
    const [ categoryValue ] = useCategoryValue()
    const [ , setModalRequests ] = useModalRequests()
    const dateObj = useDateObj()
    const [ requests, setRequests ] = useDynamicDashboardRequests()
    const [ , setExportPdfLoading ] = useExportPdfLoadingObj()
    const { postFn: fetch } = useFetch()
    const { fetchProductsByIds } = useFetchProductsByIds()
    
    const fetchProductNewnessIds = async () => {
        if (!dateObj?.date || !categoryValue.length) return
        const result = {
            customerVendorProducts: [],
            comparisonVendorProducts: [],
        }
        const url = '/dashboard/dashboard_products_in_out_ids/'
        const customerVendor = storage.getCustomerVendor()
        const target = getTargetGroupPayload({ targetGroups: genderValue, returnType: 'array' })
        const categories = getLeafCategories({ selectedCategories: categoryValue })
        const date = dateObj.date

        // 请求 comparison vendor 数据
        const competitorVendors = [ ...competitorValue ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: regionValue }))

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers, { vendor: SELECTED_VENDORS, region: regionValue } ],
        })

        const competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        const comparisonVendorPayload = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                date,
                vendor: SELECTED_VENDORS,
                metric: 'Options in',
            },
            data: {
                target_group: target,
                competitors: competitors,
                groups: groups,
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }

        setExportPdfLoading({ product_newness_products: true })
        if (isEqual(comparisonVendorPayload, requests.newnessProductsApiPayload)) {
            setExportPdfLoading({ product_newness_products: false })
            return
        }
        
        setModalRequests({ [url]: true })
        setRequests({
            newnessProductsApiLoading: true,
        })
        const comparisonVendorResult = await fetch(comparisonVendorPayload.url, comparisonVendorPayload)
        const comparisonVendorIds = comparisonVendorResult?.data || []
        result.comparisonVendorProducts = await fetchProductsByIds(comparisonVendorIds)

        // 请求 customer 数据
        const customerVendorPayload = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                date,
                vendor: customerVendor,
                metric: 'Options in',
            },
            data: {
                target_group: target,
                competitors: [ povValue ],
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }
        const customerVendorResult = await fetch(customerVendorPayload.url, customerVendorPayload)
        const customerVendorIds = customerVendorResult?.data || []
        const customerVendorProducts = await fetchProductsByIds(customerVendorIds)
        result.customerVendorProducts = customerVendorProducts

        setModalRequests({ [url]: false })
        setRequests({
            newnessProductsApiData: result,
            newnessProductsApiPayload: comparisonVendorPayload,
            newnessProductsApiLoading: false,
        })
        setExportPdfLoading({ product_newness_products: false })
    }

    useDeepCompareEffect(() => {
        fetchProductNewnessIds()
    }, [ dateObj, competitorOptions, competitorValue, povValue, regionValue, currencyValue, genderValue, categoryValue ])

    return {
        fetchProductNewnessIds,
    }
}

/**
 * Discount effect 详情页面的线图: metric = 'Discount effect'
 * Assortment mix 详情页的线图: metric = 'Split'
 */
export const useFetchGraphData = (metric: 'Discount effect' | 'Split' | 'Sales average price' | 'Full price performance') => {
    const [ competitorOptions ] = useCompetitorOptions()
    const [ competitorValue ] = useCompetitorValue()
    const [ povValue ] = usePovValue()
    const [ regionValue ] = useFilterCountry()
    const [ currencyValue ] = useFilterCurrency()
    const [ genderValue ] = useFilterGender()
    const [ categoryValue ] = useCategoryValue()
    const [ , setModalRequests ] = useModalRequests()
    const dateObj = useDateObj()
    const [ requests, setRequests ] = useDynamicDashboardRequests()
    const [ , setExportPdfLoading ] = useExportPdfLoadingObj()
    const { postFn: fetch } = useFetch()
    const [ data, setData ] = useState({})

    const fetchDiscountEffectGraph = async () => {
        if (!dateObj?.date || !categoryValue.length) return

        const url = 'dashboard/graph_comparison'
        const customerVendor = storage.getCustomerVendor()
        const target = getTargetGroupPayload({ targetGroups: genderValue, returnType: 'array' })
        const categories = getLeafCategories({ selectedCategories: categoryValue })

        const competitorVendors = [ ...competitorValue, povValue, MARKET_VENDOR.vendor, SELECTED_VENDORS ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: regionValue }))

        const competitors = getCompetitorPayload({
            competitorSellers,
            returnType: 'vendor',
            isDashboard: true,
        }) as string[]

        const market = getMarketPayload({
            competitorSellers: [ ...competitorSellers ],
            competitorOptions: [
                ...competitorOptions.map(c => ({ vendor: c.key, region: regionValue })),
            ],
            returnType: 'vendor',
        })

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers ].filter(seller => seller.vendor !== povValue),
        })

        const payload = {
            url,
            query: {
              customer: customerVendor,
              region: regionValue,
              currency: currencyValue,
              date: dateObj.date,
              comparison_date: dateObj.date,
            },
            data: {
              target_group: target,
              competitors,
              metrics: [ metric ],
              all_category: getCategoriesPayload(categories),
              market,
              groups,
              [DISABLE_CANCEL_REQUEST]: true,
            },
        }

        setExportPdfLoading({ [ `${metric}_line_graph` ]: true })
        if (metric === 'Split') {
            if (isEqual(payload, requests.assortmentSplitLineGraphApiPayload)) {
                setExportPdfLoading({ [ `${metric}_line_graph` ]: false })
                return
            }

            setRequests({
                assortmentSplitLineGraphApiLoading: true,
            })
        } else if (metric === 'Discount effect') {
            if (isEqual(payload, requests.discountEffectLineGraphApiPayload)) {
                setExportPdfLoading({ [ `${metric}_line_graph` ]: false })
                return
            }

            setRequests({
                discountEffectLineGraphApiLoading: true,
            })
        } else if (metric === 'Sales average price') {
            if (isEqual(payload, requests.salesPriceLineGraphApiPayload)) {
                setExportPdfLoading({ [ `${metric}_line_graph` ]: false })
                return
            }

            setRequests({
                salesPriceLineGraphApiLoading: true,
            })
        } else if (metric === 'Full price performance') {
            if (isEqual(payload, requests.fullPriceLineGraphApiPayload)) {
                setExportPdfLoading({ [ `${metric}_line_graph` ]: false })
                return
            }

            setRequests({
                fullPriceLineGraphApiLoading: true,
            })
        }
        
        setModalRequests({ [url]: true })
        const result = await fetch(payload.url, payload)
        const data = result?.data
        setData(data)
        setModalRequests({ [url]: false })
        setExportPdfLoading({ [ `${metric}_line_graph` ]: false })

        if (metric === 'Split') {
            setRequests({
                assortmentSplitLineGraphApiData: data,
                assortmentSplitLineGraphApiPayload: payload,
                assortmentSplitLineGraphApiLoading: false,
            })
        } else if (metric === 'Discount effect') {
            setRequests({
                discountEffectLineGraphApiData: data,
                discountEffectLineGraphApiPayload: payload,
                discountEffectLineGraphApiLoading: false,
            })
        } else if (metric === 'Sales average price') {
            setRequests({
                salesPriceLineGraphApiData: data,
                salesPriceLineGraphApiPayload: payload,
                salesPriceLineGraphApiLoading: false,
            })
        } else if (metric === 'Full price performance') {
            setRequests({
                fullPriceLineGraphApiData: data,
                fullPriceLineGraphApiPayload: payload,
                fullPriceLineGraphApiLoading: false,
            })
        }
    }

    useDeepCompareEffect(() => {
        fetchDiscountEffectGraph()
    }, [ dateObj, competitorOptions, competitorValue, povValue, regionValue, currencyValue, genderValue, categoryValue ])

    return {
        fetchDiscountEffectGraph,
        loading: false,
        data,
    }
}

/**
 * 其它详情页面产品卡片
 */
export const useFetchDashboardProducts = (metric: string) => {
    const [ competitorOptions ] = useCompetitorOptions()
    const [ competitorValue ] = useCompetitorValue()
    const [ povValue ] = usePovValue()
    const [ regionValue ] = useFilterCountry()
    const [ currencyValue ] = useFilterCurrency()
    const [ genderValue ] = useFilterGender()
    const [ categoryValue ] = useCategoryValue()
    const [ , setModalRequests ] = useModalRequests()
    const dateObj = useDateObj()
    const [ requests, setRequests ] = useDynamicDashboardRequests()
    const [ , setExportPdfLoading ] = useExportPdfLoadingObj()
    const { postFn: fetch } = useFetch()
    const { fetchProductsByIds } = useFetchProductsByIds()
    const [ data, setData ] = useState({ customerVendorProducts: [], comparisonVendorProducts: [] })

    const fetchDashboardProducts = async () => {
        if (!dateObj?.date || !categoryValue.length) return
        const result = { 
            customerVendorProducts: [],
            customerVendorCount: 0,
            comparisonVendorProducts: [],
            comparisonVendorCount: 0,
        }
        const url = '/dashboard/dashboard_fetch_products'
        const customerVendor = storage.getCustomerVendor()
        const target = getTargetGroupPayload({ targetGroups: genderValue, returnType: 'array' })
        const categories = getLeafCategories({ selectedCategories: categoryValue })
        
        // 请求 comparison vendor 数据
        const competitorVendors = [ ...competitorValue ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: regionValue }))

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers, { vendor: SELECTED_VENDORS, region: regionValue } ],
        })

        const competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        const comparisonVendorPayload = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric,
                date: dateObj.date,
                vendor: SELECTED_VENDORS,
            },
            data: {
                target_group: target,
                competitors: competitors,
                groups: groups,
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }

        setExportPdfLoading({ [ `${metric}_product_card` ]: true })
        if (metric === 'All') {
            if (isEqual(comparisonVendorPayload, requests.assortmentProductsApiPayload)) {
                setExportPdfLoading({ [ `${metric}_product_card` ]: false })
                return
            }

            setRequests({
                assortmentProductsApiData: { customerVendorProducts: [], comparisonVendorProducts: [] },
                assortmentProductsApiLoading: true,
            })
        } else if (metric === 'Sold out') {
            if (isEqual(comparisonVendorPayload, requests.soldOutProductsApiPayload)) {
                setExportPdfLoading({ [ `${metric}_product_card` ]: false })
                return
            }

            setRequests({
                soldOutProductsApiData: { customerVendorProducts: [], comparisonVendorProducts: [] },
                soldOutProductsApiLoading: true,
            })
        }

        setModalRequests({ [url]: true })
        const comparisonVendorResult = await fetch(comparisonVendorPayload.url, comparisonVendorPayload)
        const comparisonVendorIds = comparisonVendorResult?.data || []
        result.comparisonVendorProducts = await fetchProductsByIds(comparisonVendorIds.slice(0, 20))
        result.comparisonVendorCount = comparisonVendorIds.length

        // 请求 customer 数据
        const customerVendorPayload = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric,
                date: dateObj.date,
                vendor: customerVendor,
            },
            data: {
                target_group: target,
                competitors: [ povValue ],
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }
        const customerVendorResult = await fetch(customerVendorPayload.url, customerVendorPayload)
        const customerVendorIds = customerVendorResult?.data || []
        const customerVendorProducts = await fetchProductsByIds(customerVendorIds.slice(0, 20))
        result.customerVendorProducts = customerVendorProducts
        result.customerVendorCount = customerVendorIds.length

        setData(result)
        setModalRequests({ [url]: false })
        setExportPdfLoading({ [ `${metric}_product_card` ]: false })
        
        if (metric === 'All') {
            setRequests({
                assortmentProductsApiData: result,
                assortmentProductsApiPayload: comparisonVendorPayload,
                assortmentProductsApiLoading: false,
            })
        } else if (metric === 'Sold out') {
            setRequests({
                soldOutProductsApiData: result,
                soldOutProductsApiPayload: comparisonVendorPayload,
                soldOutProductsApiLoading: false,
            })
        }
    }

    useDeepCompareEffect(() => {
        fetchDashboardProducts()
    }, [ dateObj, competitorOptions, competitorValue, povValue, regionValue, currencyValue, genderValue, categoryValue ])

    return {
        fetchDashboardProducts,
        loading: false,
        data,
    }
}

/**
 * Discount effect 详情页的产品卡片接口
 */
export const useDiscountEffectProducts = () => {
    const [ competitorOptions ] = useCompetitorOptions()
    const [ competitorValue ] = useCompetitorValue()
    const [ povValue ] = usePovValue()
    const [ regionValue ] = useFilterCountry()
    const [ currencyValue ] = useFilterCurrency()
    const [ genderValue ] = useFilterGender()
    const [ categoryValue ] = useCategoryValue()
    const [ , setModalRequests ] = useModalRequests()
    const dateObj = useDateObj()
    const [ requests, setRequests ] = useDynamicDashboardRequests()
    const [ , setExportPdfLoading ] = useExportPdfLoadingObj()
    const { postFn: fetch } = useFetch()
    const { fetchProductsByIds } = useFetchProductsByIds()

    const fetchProducts = async () => {
        if (!dateObj?.date || !categoryValue.length) return
        
        const metricList: string[] = [ 'Discount more than 50', 'Discount 25 to 50' ]
        const result = {
            customerVendorDiscountEffectMoreThan50Products: [],
            customerVendorDiscountEffectMoreThan50Count: 0,
            customerVendorDiscountEffectLessThan50Products: [],
            customerVendorDiscountEffectLessThan50Count: 0,
            comparisonVendorDiscountEffectMoreThan50Products: [],
            comparisonVendorDiscountEffectMoreThan50Count: [],
            comparisonVendorDiscountEffectLessThan50Products: [],
            comparisonVendorDiscountEffectLessThan50Count: [],
        }
        const url = '/dashboard/dashboard_fetch_products'
        const customerVendor = storage.getCustomerVendor()
        const target = getTargetGroupPayload({ targetGroups: genderValue, returnType: 'array' })
        const categories = getLeafCategories({ selectedCategories: categoryValue })
        const date = dateObj.date
        
        // 请求 comparison vendor 数据
        const competitorVendors = [ ...competitorValue ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: regionValue }))

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers, { vendor: SELECTED_VENDORS, region: regionValue } ],
        })

        const competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        const comparisonVendorPayload = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric: metricList[0],
                date,
                vendor: SELECTED_VENDORS,
            },
            data: {
                target_group: target,
                competitors: competitors,
                groups: groups,
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }

        setExportPdfLoading({ discount_effect_product_card: true })
        if (isEqual(comparisonVendorPayload, requests.discountEffectProductsApiPayload)) {
            setExportPdfLoading({ discount_effect_product_card: false })
            return
        }

        setModalRequests({ [url]: true })
        setRequests({
            discountEffectProductsApiData: {
                customerVendorDiscountEffectMoreThan50Products: [],
                customerVendorDiscountEffectMoreThan50Count: 0,
                customerVendorDiscountEffectLessThan50Products: [],
                customerVendorDiscountEffectLessThan50Count: 0,
                comparisonVendorDiscountEffectMoreThan50Products: [],
                comparisonVendorDiscountEffectMoreThan50Count: 0,
                comparisonVendorDiscountEffectLessThan50Products: [],
                comparisonVendorDiscountEffectLessThan50Count: 0,
            },
            discountEffectProductsApiLoading: true,
        })

        const comparisonVendorResult = await fetch(comparisonVendorPayload.url, comparisonVendorPayload)
        const comparisonVendorIds = comparisonVendorResult?.data || []
        result.comparisonVendorDiscountEffectMoreThan50Products = await fetchProductsByIds(comparisonVendorIds.slice(0, 20))
        result.comparisonVendorDiscountEffectMoreThan50Count = comparisonVendorIds.length
        
        const comparisonVendorPayload2 = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric: metricList[1],
                date,
                vendor: SELECTED_VENDORS,
            },
            data: {
                target_group: target,
                competitors: competitors,
                groups: groups,
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }
        const comparisonVendorResult2 = await fetch(comparisonVendorPayload2.url, comparisonVendorPayload2)
        const comparisonVendorIds2 = comparisonVendorResult2?.data || []
        result.comparisonVendorDiscountEffectLessThan50Products = await fetchProductsByIds(comparisonVendorIds2.slice(0, 20))
        result.comparisonVendorDiscountEffectLessThan50Count = comparisonVendorIds2.length

        // 请求 customer 数据
        const customerVendorPayload = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric: metricList[0],
                date,
                vendor: customerVendor,
            },
            data: {
                target_group: target,
                competitors: [ povValue ],
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }
        const customerVendorResult = await fetch(customerVendorPayload.url, customerVendorPayload)
        const customerVendorIds = customerVendorResult?.data || []
        const customerVendorProducts = await fetchProductsByIds(customerVendorIds.slice(0, 20))
        result.customerVendorDiscountEffectMoreThan50Products = customerVendorProducts
        result.customerVendorDiscountEffectMoreThan50Count = customerVendorIds.length

        const customerVendorPayload2 = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric: metricList[1],
                date,
                vendor: customerVendor,
            },
            data: {
                target_group: target,
                competitors: [ povValue ],
                all_category: categories,
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }
        const customerVendorResult2 = await fetch(customerVendorPayload2.url, customerVendorPayload2)
        const customerVendorIds2 = customerVendorResult2?.data || []
        const customerVendorProducts2 = await fetchProductsByIds(customerVendorIds2.slice(0, 20))
        result.customerVendorDiscountEffectLessThan50Products = customerVendorProducts2
        result.customerVendorDiscountEffectLessThan50Count = customerVendorIds2.length

        setModalRequests({ [url]: false })
        setRequests({
            discountEffectProductsApiData: result,
            discountEffectProductsApiPayload: comparisonVendorPayload,
            discountEffectProductsApiLoading: false,
        })
        setExportPdfLoading({ discount_effect_product_card: false })
    }

    useDeepCompareEffect(() => {
        fetchProducts()
    }, [ dateObj, competitorOptions, competitorValue, povValue, regionValue, currencyValue, genderValue, categoryValue ])

    return {
        fetchProducts,
    }
}

/**
 * Sales price performance 和 Full price performance 两个详情页的产品卡片接口
 */
export const usePricePerformanceProducts = (page: 'sales' | 'full') => {
    const [ competitorOptions ] = useCompetitorOptions()
    const [ competitorValue ] = useCompetitorValue()
    const [ povValue ] = usePovValue()
    const [ regionValue ] = useFilterCountry()
    const [ currencyValue ] = useFilterCurrency()
    const [ genderValue ] = useFilterGender()
    const [ categoryValue ] = useCategoryValue()
    const [ , setModalRequests ] = useModalRequests()
    const dateObj = useDateObj()
    const [ requests, setRequests ] = useDynamicDashboardRequests()
    const [ , setExportPdfLoading ] = useExportPdfLoadingObj()
    const { postFn: fetch } = useFetch()
    const { fetchProductsByIds } = useFetchProductsByIds()
    const [ data, setData ] = useState({ 
        customerVendorMinimumPriceProducts: [],
        customerVendorMinimumPriceCount: 0,
        customerVendorMaximumPriceProducts: [],
        customerVendorMaximumPriceCount: 0,
        comparisonVendorMinimumPriceProducts: [],
        comparisonVendorMinimumPriceCount: 0,
        comparisonVendorMaximumPriceProducts: [],
        comparisonVendorMaximumPriceCount: 0,
    })
    const [ loading ] = useState(false)

    const fetchProducts = async () => {
        if (!dateObj?.date || !categoryValue.length) return

        let metricList: string[] = [ 'Maximum full price', 'Minimum full price' ]
        if (page === 'sales') {
            metricList = [ 'Maximum sales price', 'Minimum sales price' ]
        }

        const result = {
            customerVendorMinimumPriceProducts: [],
            customerVendorMinimumPriceCount: 0,
            customerVendorMaximumPriceProducts: [],
            customerVendorMaximumPriceCount: 0,
            comparisonVendorMinimumPriceProducts: [],
            comparisonVendorMinimumPriceCount: 0,
            comparisonVendorMaximumPriceProducts: [],
            comparisonVendorMaximumPriceCount: 0,
        }
        const url = '/dashboard/dashboard_fetch_products'
        const customerVendor = storage.getCustomerVendor()
        const target = getTargetGroupPayload({ targetGroups: genderValue, returnType: 'array' })
        const categories = getLeafCategories({ selectedCategories: categoryValue })
        const date = dateObj.date

        // 请求 comparison vendor 数据
        const competitorVendors = [ ...competitorValue ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: regionValue }))

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers, { vendor: SELECTED_VENDORS, region: regionValue } ],
        })

        const competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        const comparisonVendorPayload = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric: metricList[0],
                date,
                vendor: SELECTED_VENDORS,
            },
            data: {
                target_group: target,
                competitors: competitors,
                groups: groups,
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }

        setExportPdfLoading({ [`${page}_product_card`]: true })
        if (page === 'sales') {
            if (isEqual(comparisonVendorPayload, requests.salesPriceProductsApiPayload)) {
                setExportPdfLoading({ [`${page}_product_card`]: false })
                return
            }

            setRequests({
                salesPriceProductsApiPayload: {
                    customerVendorMinimumPriceProducts: [],
                    customerVendorMaximumPriceProducts: [],
                    comparisonVendorMinimumPriceProducts: [],
                    comparisonVendorMaximumPriceProducts: [],
                },
                salesPriceProductsApiLoading: true,
            })
        } else if (page === 'full') {
            if (isEqual(comparisonVendorPayload, requests.fullPriceProductsApiPayload)) {
                setExportPdfLoading({ [`${page}_product_card`]: false })
                return
            }

            setRequests({
                fullPriceProductsApiPayload: {
                    customerVendorMinimumPriceProducts: [],
                    customerVendorMaximumPriceProducts: [],
                    comparisonVendorMinimumPriceProducts: [],
                    comparisonVendorMaximumPriceProducts: [],
                },
                fullPriceProductsApiLoading: true,
            })
        }
        
        setModalRequests({ [url]: true })
        const comparisonVendorResult = await fetch(comparisonVendorPayload.url, comparisonVendorPayload)
        const comparisonVendorIds = comparisonVendorResult?.data || []
        result.comparisonVendorMaximumPriceProducts = await fetchProductsByIds(comparisonVendorIds.slice(0, 20))
        result.comparisonVendorMaximumPriceCount = comparisonVendorIds.length

        const comparisonVendorPayload2 = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric: metricList[1],
                date,
                vendor: SELECTED_VENDORS,
            },
            data: {
                target_group: target,
                competitors: competitors,
                groups: groups,
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }
        const comparisonVendorResult2 = await fetch(comparisonVendorPayload2.url, comparisonVendorPayload2)
        const comparisonVendorIds2 = comparisonVendorResult2?.data || []
        result.comparisonVendorMinimumPriceProducts = await fetchProductsByIds(comparisonVendorIds2.slice(0, 20))
        result.comparisonVendorMinimumPriceCount = comparisonVendorIds2.length

        // 请求 customer 数据
        const customerVendorPayload = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric: metricList[0],
                date,
                vendor: customerVendor,
            },
            data: {
                target_group: target,
                competitors: [ povValue ],
                all_category: getCategoriesPayload(categories),
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }
        const customerVendorResult = await fetch(customerVendorPayload.url, customerVendorPayload)
        const customerVendorIds = customerVendorResult?.data || []
        const customerVendorProducts = await fetchProductsByIds(customerVendorIds.slice(0, 20))
        result.customerVendorMaximumPriceProducts = customerVendorProducts
        result.customerVendorMaximumPriceCount = customerVendorIds.length

        const customerVendorPayload2 = {
            url,
            query: {
                customer: customerVendor,
                region: regionValue,
                currency: currencyValue,
                category: SELECTED_CATEGORY,
                metric: metricList[1],
                date,
                vendor: customerVendor,
            },
            data: {
                target_group: target,
                competitors: [ povValue ],
                all_category: categories,
                [DISABLE_CANCEL_REQUEST]: true,
            },
        }
        const customerVendorResult2 = await fetch(customerVendorPayload2.url, customerVendorPayload2)
        const customerVendorIds2 = customerVendorResult2?.data || []
        const customerVendorProducts2 = await fetchProductsByIds(customerVendorIds2.slice(0, 20))
        result.customerVendorMinimumPriceProducts = customerVendorProducts2
        result.customerVendorMinimumPriceCount = customerVendorIds2.length

        setData(result)
        setModalRequests({ [url]: false })
        if (page === 'sales') {
            setRequests({
                salesPriceProductsApiData: result,
                salesPriceProductsApiPayload: comparisonVendorPayload,
                salesPriceProductsApiLoading: false,
            })
        } else if (page === 'full') {
            setRequests({
                fullPriceProductsApiData: result,
                fullPriceProductsApiPayload: comparisonVendorPayload,
                fullPriceProductsApiLoading: false,
            })
        }
        setExportPdfLoading({ [`${page}_product_card`]: false })
    }

    useDeepCompareEffect(() => {
        fetchProducts()
    }, [ dateObj, competitorOptions, competitorValue, povValue, regionValue, currencyValue, genderValue, categoryValue ])

    return {
        fetchProducts,
        loading,
        data,
    }
}

export const useFetchProductsByIds = () => {
    const [ currencyValue ] = useFilterCurrency()
    const { postFn: fetch } = useFetch()

    const fetchProductsByIds = async (ids: string[] = []) => {
        if (!ids.length) return
        const payload = {
            url: '/fetch_products',
            query: { to_currency: currencyValue },
            data: { ids, [DISABLE_CANCEL_REQUEST]: true },
        }
        const result = await fetch(payload.url, payload)
        return result?.data || []
    }

    return {
        fetchProductsByIds,
    }
}

export const useDateObj = () => {
    const [ date ] = useCalendarDate()
    const [ comparisonDate ] = useCalendarComparisonDate()

    return {
        date,
        comparisonDate,
    }
}
