const width01 = 75
const width02 = 70

export const tabWidth = 155
export const optionsWidth = 100
export const categoryWidth = 195
export const avgPriceValueWidth = width01
export const avgPriceChangePercentWidth = width02
export const avgPriceChangeWidth = width02
export const indexWidth = width01
export const indexChangeWidth = width02
export const frequentPriceValueWidth = width01
export const frequentPriceChangePercentWidth = width02
export const frequentPriceChangeWidth = width02
export const disEffectValueWidth = width01
export const disEffectChangeWidth = width02
export const disDepthValueWidth = width01
export const disDepthChangeWidth = width02
export const disWidthValueWidth = width01
export const disWidthChangeWidth = width02

export const averagePriceWidth = avgPriceValueWidth + avgPriceChangePercentWidth + avgPriceChangeWidth
export const indexWrapperWidth = indexWidth + indexChangeWidth
export const frequentPriceWidth = frequentPriceValueWidth + frequentPriceChangePercentWidth
export const disEffectWidth = disEffectValueWidth + disEffectChangeWidth
export const disDepthWidth = disDepthValueWidth + disDepthChangeWidth
export const disWidthWidth = disWidthValueWidth + disWidthChangeWidth
